import toolbarReactQuill from '@utils/toolbarReactQuill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FC, useState, useEffect, useCallback, useContext } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';

import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { Input, Switch, Select, Option } from '@components/Form';
import { EtapaContext } from '@contexts/EtapaContext';

const UploadArquivos: FC = () => {
  const { etapaPessoa, etapa, etapaIdx, tipoPessoa } = useContext(EtapaContext);
  const { setFieldValue, values } = useFormikContext<ConfigTypes>();
  const [fields, setFields] = useState<Campo[]>([]);

  const valuesCamposAdicionais = {
    label: 'Upload de Arquivos',
    type: 'opcoes',
    name: `adicional${fields?.length}`,
    habilitar: true,
    obrigatorio: true,
  };

  const handleAddCamposAdicionais = () => {
    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [
      ...etapaPessoa[etapaIdx].campos,
      valuesCamposAdicionais,
    ]);
  };

  const loadFields = useCallback(() => {
    const campos = etapaPessoa[etapaIdx]?.campos;

    setFields(campos);
  }, [values.etapas, etapa]);

  useEffect(() => { if (etapa) loadFields(); }, [loadFields, etapa]);

  useEffect(() => {
    // adicionando valor inicial em 'Insira as opções'
    if (etapaPessoa[etapaIdx].campos.length === 0)
      handleAddCamposAdicionais()
  }, []);

  return (
    <>
      {fields?.map((field, index) =>
      (
        <div className="form-row" key={index}>
          {field.type === 'opcoes' && (
            <div className="col-lg-7">
              <Select
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.opcoes`}
                label="Insira as opções"
                mode="tags"
                placeholder="Insira as opções"
                onChange={(value) => {
                  let pattern = /[\\/:*?"<>|]/g;
                  if (pattern.test(value) && value)
                    return message.info('Caracteres inválidos para nome de arquivo: \\ / : * ? " < > |');

                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.opcoes`, value);
                }}
              />
            </div>
          )}

          <div className="col-lg-3">
            <label />
            <Switch
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.habilitar`}
              checked={etapaPessoa[etapaIdx]?.campos[index]?.habilitar}
              label="Habilitar"
              onChange={(value) =>
                setFieldValue(
                  `etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.habilitar`,
                  value,
                )
              }
              size="small"
            />

            {/* checked abaixo: se nenhuma opção inserida, não obrigar! */}
            <Switch
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.obrigatorio`}
              checked={
                etapaPessoa[etapaIdx]?.campos[index]?.opcoes?.length == 0 ? false :
                  etapaPessoa[etapaIdx]?.campos[index]?.obrigatorio}
              disabled={etapaPessoa[etapaIdx]?.campos[index]?.opcoes?.length == 0}
              label="Obrigatório"
              onChange={(value) =>
                setFieldValue(
                  `etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.obrigatorio`,
                  value,
                )
              }
              size="small"
            />
          </div>
        </div>
      ))}

      <div className='col-lg-12'>
        <label className='col-lg-9 p-0 m-0 mb-1'>Mensagem Explicativa</label>
        <div style={{ minHeight: '200px' }}>
          <ReactQuill modules={toolbarReactQuill}
            value={etapaPessoa[etapaIdx]?.servico}
            onChange={(value) => {
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.servico`, value);
            }} />
        </div>
      </div>
    </>
  );
};

export default UploadArquivos;
