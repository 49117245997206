import { FC } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { MenuOutlined, MoreOutlined } from '@ant-design/icons';
import { Bloco } from '@pages/Configuracao/ConfigTypes';

import * as S from './styles';

type Props = {
  bloco: Bloco;
  active?: boolean;
  key: number;
  onSelect: () => void;
  onRemove: (etapa: Bloco) => void;
};

const CardBloco: FC<Props> = ({ bloco, active, onSelect, onRemove }) => {
  const menu = (
    <Menu>
      <Menu.Item onClick={() => onRemove(bloco)}>
        Excluir
      </Menu.Item>
    </Menu>
  );

  return (
    <S.Container active={!!active}>
      <div className="drag">
        <MenuOutlined />
      </div>
      <div className="title-menu" onClick={onSelect}>
        <div className="title">
          <span>{bloco.nome}</span>
        </div>
        <div className="options">
          <Dropdown overlay={menu} trigger={['click']}>
            <Button shape="circle" type="text">
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </S.Container>
  );
};

export default CardBloco;
