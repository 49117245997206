import { FC, useContext } from 'react';

import BlocoCamposProposta from './BlocoCamposProposta';
import { EtapaContext } from '@contexts/EtapaContext';
import { BlocoPJ } from '@constants/BlocoPJ';

const PessoaJuridica: FC = () => {
  const { errorEtapaPessoa, etapaIdx } = useContext(EtapaContext);
  //console.log("bloco.campos: ", BlocoPJ);

  return (
    <>
      {errorEtapaPessoa && errorEtapaPessoa[etapaIdx] && (
        <div className="form-row">
          <div className="col-lg-12">
            <small className="error">
              {(errorEtapaPessoa[etapaIdx] as any).campos}
            </small>
          </div>
        </div>
      )}

      {BlocoPJ.map((bloco) => (
        <>
          <BlocoCamposProposta title={bloco.title} camposArray={bloco.campos} />
          <hr />
        </>
      ))}
    </>
  );
};

export default PessoaJuridica;
