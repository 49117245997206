import { useContext, useEffect, useState } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import { Button, Tabs, message } from 'antd';

import { validationSchema } from './validationSchema';
import initialValues from './initialValues';
import { ConfigTypes } from './ConfigTypes';

import Card from '@components/Card';
import ModalValidacao from '@components/Modal/ModalValidacao';
import ModalLoading from '@components/Modal/ModalLoading';

import Integracao from './Tabs/Integracao';
import Interface from './Tabs/Interface';
import Etapas from './Tabs/Etapas';
import FollowUP from './Tabs/FollowUP';
import GestaoAcesso from './Tabs/GestaoAcesso';

import { GlobalContext } from '@contexts/GlobalContext';
import useInspinia from '@hooks/useInspinia';

import './styles.scss';
import api from '@services/api';

const { TabPane } = Tabs;

const removeSPC = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[ÀÁÂÃÄÅ]/g, 'A')
    .replace(/[àáâãäå]/g, 'a')
    .replace(/[ÈÉÊË]/g, 'E')
    .replace(/[éèêë]/g, 'e')
    .replace(/[ÍÌÎÏ]/g, 'I')
    .replace(/[íìîï]/g, 'i')
    .replace(/[ÓÒÔÕ]/g, 'O')
    .replace(/[óòôõ]/g, 'o')
    .replace(/[ÚÙÛÜ]/g, 'U')
    .replace(/[úùûü]/g, 'u')
    .replace(/[Ç]/g, 'C')
    .replace(/[ç]/g, 'c')
    .replace(/[Ñ]/g, 'N')
    .replace(/[ñ]/g, 'n')
    .replace(/[^A-Za-z0-9-]/g, '-')
    .replace(/[-]+/g, '-') // Remove multiple - with single -
    .replace(/^-+/, '') // Remove trailing - from start
    .replace(/-+$/, '') // Remove trailing - from end
}

function Configuracao() {
  useInspinia();
  const [disableSave, setDisableSave] = useState(false);
  const [isOpenValidateModal, setIsOpenValidateModal] = useState(false);
  const { loading, instituicao, configuracoes: c, salvarConfiguracoes } = useContext(GlobalContext);
  const instituicaoStoraged = sessionStorage.getItem('b2e:instituicao');
  const [activeTab, setActiveTab] = useState(`${sessionStorage.getItem('loginLogged')}`.includes('b2e.') ? '1' : '2');

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (c: ConfigTypes) => {
      const renameEtapas = (pessoa: any) =>
        pessoa?.map((e: any) => {
          if (e.tipo === 2)
            return {
              ...e,
              campos: e.campos.map((campo: any) => ({
                ...campo,
                name: removeSPC(e.nome?.trim() + '-' + campo.label).toLowerCase() || 'campo-adicional'
              })),
            }

          return e;
        });

      ['pf', 'pj', 'dlinkpf', 'dlinkpj'].forEach((i: any) => { // @ts-ignore
        c.etapas[i] = renameEtapas(c.etapas[i]);
      });

      try {
        if (sessionStorage.getItem('idsPareceresEditados')) {
          const idsPareceresEditados = JSON.parse(sessionStorage.getItem('idsPareceresEditados') || '[]');
          const pareceresEditados = JSON.parse(sessionStorage.getItem('pareceresEditados') || '[]');

          idsPareceresEditados.map((id: number) => {
            const { mensagem: m } = pareceresEditados.find((parecer: any) => parecer.idStatus === id);
            api.post(`ConfiguracaoForm/InserirMensagemResposta?guidInstituicao=${instituicao}`, { idStatus: id, mensagem: m || '' });
          });

          ['pareceresEditados', 'idsPareceresEditados'].forEach((i) => { sessionStorage.removeItem(i) })
        }
      } catch (error) {
        console.log("error: ", error);

      } finally {
        salvarConfiguracoes(c);
      }
    },
  });

  const { handleSubmit, setValues, setFieldValue, errors, values } = formik;

  const handleValidate = async () => {
    if (Object.keys(errors).length > 0)
      setIsOpenValidateModal(true);
    else { // Salvando Integrações Customizadas:
      const expHorasPropostaPendente = sessionStorage.getItem('expHorasPropostaPendente') || false;
      if (expHorasPropostaPendente)
        try {
          if (expHorasPropostaPendente === '0')
            await api.delete(`ProcessoProposta/RemoverConfiguracaoInstituicao?guidInstituicao=${instituicaoStoraged}`)
          else
            await api.post(`ProcessoProposta/SalvarConfiguracaoInstituicao?guidInstituicao=${instituicaoStoraged}&tempoExpiracaoHoras=${expHorasPropostaPendente}`)

          sessionStorage.removeItem('expHorasPropostaPendente');
        } catch (error) {
          console.log("error: ", error);
          message.error('Falha ao alterar Tempo de Expiração Proposta não Enviada (Horas)')
        }

      const travaProposta = JSON.parse(sessionStorage.getItem('travaProposta') || '{}');
      if (Object.keys(travaProposta).length > 0)
        try {
          await api.put(`TravaProposta/InserirEditarTravaProposta?id=${travaProposta.id}&guid=${instituicaoStoraged}&travaDocumento=${travaProposta.travaDocumento}&travaDias=${travaProposta.travaDias}`)
          sessionStorage.removeItem('travaProposta');
          //message.success('Restrição Trava de Dias Documento alterada com sucesso')
        } catch (error) {
          console.log("error: ", error);
          message.error('Falha ao alterar Restrição Trava de Dias Documento')
        }

      const idsNotifEmailEditados = JSON.parse(sessionStorage.getItem('idsNotifEmailEditados') || '[]');
      if (idsNotifEmailEditados.length > 0)
        try {
          const notifListEmail = JSON.parse(sessionStorage.getItem('notifListEmail') || '[]');

          idsNotifEmailEditados.map(async (id: number) => {
            let notif = notifListEmail.find((notif: any) => notif.idStatusProposta == id);
            await api.post(`NotificacaoProposta/InserirEditarNotificacaoProposta`, notif);
          });
        } catch (error) {
          console.log("error: ", error);
        }

      const idsNotifSMSEditados = JSON.parse(sessionStorage.getItem('idsNotifSMSEditados') || '[]');
      if (idsNotifSMSEditados.length > 0)
        try {
          const notifListSMS = JSON.parse(sessionStorage.getItem('notifListSMS') || '[]');

          idsNotifSMSEditados.map(async (id: number) => {
            let notif = notifListSMS.find((notif: any) => notif.idStatusProposta == id);
            // Remove quebras de linha e tags HTML
            notif.templateMensagem = notif.templateMensagem.replace(/(\r\n|\n|\r)/gm, '').replace(/<[^>]*>?/gm, '');
            await api.post(`NotificacaoProposta/InserirEditarNotificacaoProposta`, notif);
          });
        } catch (error) {
          console.log("error: ", error);
        }

      ['idsNotifSMSEditados', 'idsNotifEmailEditados', 'notifListEmail', 'notifListSMS'].map((i) => { sessionStorage.removeItem(i) })
      sessionStorage.setItem('restartEtapaNotif', 'true');
      setTimeout(() => { //@ts-ignore
        document.querySelector('#Notificação.active-true .title-menu')?.click();
      }, 333);

      handleSubmit();
    }
  };

  useEffect(() => {
    if (c) {
      setValues(c);
    } else {
      setValues(initialValues);
      setFieldValue('instituicao.codigo', instituicao);
    }
  }, [c]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "1":
        return <Integracao />;
      case "2":
        return <Interface />;
      case "3":
        return <Etapas />;
      case "4":
        return <FollowUP />;
      case "5":
        return <GestaoAcesso />;
      default:
        return <Interface />;
    }
  };

  const corTextoMenu = c?.interface?.corTextoMenu || c?.interface?.corPrimaria || '#8B2622';

  return (
    <>
      <Card>
        <ModalLoading open={loading} message="Carregando configurações..." />
        <FormikProvider value={formik}>
          <Form>
            <style>
              {`
              .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { color: ${corTextoMenu}; }
              .ant-tabs-tab:hover .ant-tabs-tab-btn { color: ${corTextoMenu}; }
              .ant-tabs-ink-bar { background-color: ${corTextoMenu}; }
              `}
            </style>
            <Tabs centered defaultActiveKey={activeTab} activeKey={activeTab}
              onChange={(key) => {
                setActiveTab(key);
                let btnConfig = document.getElementById('salvarConfigs')
                if (key == '5') {
                  btnConfig?.classList.add('d-none')
                  setDisableSave(true)
                } else {
                  btnConfig?.classList.remove('d-none');
                  setDisableSave(false);
                }
              }}>
              {(c?.acessoUser?.nome == "ADMIN B2E" || !c) &&
                <TabPane tab="Integração" key="1" />}
              <TabPane tab="Interface" key="2" disabled={!values?.instituicao?.codigo} />
              <TabPane tab="Etapas" key="3" disabled={!values?.instituicao?.codigo} />
              <TabPane tab="Follow UP" key="4" disabled={!values?.instituicao?.codigo} />
              {(c?.acessoUser?.nome == "ADMIN B2E" || c?.acessoUser?.acessarGestaoAcesso) &&
                <TabPane tab="Gestão de Acesso" key="5" disabled={!values?.instituicao?.codigo} />}
            </Tabs>
            {renderTabContent()}
            <ModalValidacao
              open={isOpenValidateModal}
              onCancel={() => setIsOpenValidateModal(false)}
              footer={null}
            />
          </Form>
        </FormikProvider>

        <div className="d-flex justify-content-end">
          <Button id='salvarConfigs' type="primary" onClick={handleValidate}
            className='mt-4' disabled={disableSave || !values?.instituicao?.codigo}>
            Salvar configurações
          </Button>
        </div>
      </Card>
    </>
  );
}

export default Configuracao;