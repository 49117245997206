// @ts-nocheck
import { useState, useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Tabs, Tooltip, Button, Upload, Input as AntdInput, message, Select as SelectAntd, Modal, Switch as AntdSwitch } from 'antd';
import { SketchPicker } from 'react-color';

import { Input, SectionTitle, Switch, Select, Option } from '@components/Form';
import Preview from './preview';
import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { border } from 'polished';
import { isUndefined } from 'util';
import ModalLoading from '@components/Modal/ModalLoading';
import { GlobalContext } from '@contexts/GlobalContext';
import api from '@services/api';
import { handleURL } from '@utils/handleURL';

//type Props = { onepage: boolean; };

const { TabPane } = Tabs;
const { TextArea } = AntdInput;

const Interface = (/* { onepage }: Props */) => {
  const [link1, setLink1] = useState('');
  const [link2, setLink2] = useState('');
  const [link3, setLink3] = useState('');
  const { values, setFieldValue } = useFormikContext<ConfigTypes>();
  const [imageUrl, setImageUrl] = useState('');
  const [displayColorPicker, setDisplayColorPicker] = useState('');

  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [corTextoMenu, setCorTextoMenu] = useState('');

  const [inputStatus, setInputStatus] = useState('');
  const [idStatusSelected, setIdStatusSelected] = useState(0);

  const [loading, setLoading] = useState({ status: false, message: '', });

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = ({ file }: any) => {
    getBase64(file.originFileObj, (base64: string) => {
      setImageUrl(base64);
      setFieldValue('interface.logo', base64);
    });
  };

  const handleColorPicker = (type: string) => setDisplayColorPicker(type);

  const handleColors = ({ hex }: any, type: string) => {
    if (hex != '' && !hex?.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)) {
      message.destroy();
      message.error('Cor inválida');
      hex = '';
    }

    switch (type) {
      case 'primary':
        setFieldValue('interface.corPrimaria', hex);
        return setPrimaryColor(hex);

      case 'secondary':
        setFieldValue('interface.corSecundaria', hex);
        return setSecondaryColor(hex);

      case 'textoMenu':
        setFieldValue('interface.corTextoMenu', hex);
        return setCorTextoMenu(hex);

      default: // 'background'
        setFieldValue('interface.corBackground', hex);
        return setBackgroundColor(hex);
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  useEffect(() => {
    setLink1(values.interface.link1Nome)
    setLink2(values.interface.link2Nome)
    setLink3(values.interface.link3Nome)

    setPrimaryColor(values.interface.corPrimaria);
    setSecondaryColor(values.interface.corSecundaria);
    setCorTextoMenu(values.interface.corTextoMenu);
    setImageUrl(values.interface.logo);
  }, [values.interface]);

  return (
    <>
      <SectionTitle title="Interface" />
      <div className="row">
        <div className="col-lg-4">
          <div className="form-row">
            <div className="col-md-12">
              <label htmlFor="">Upload de logomarca</label>
              <br />
              <Upload
                name="interface.logo"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="logo" style={{ width: '100%' }} />
                ) : (
                  <div>
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </div>
          </div>
          <SectionTitle title="Tema do site" />
          <div className="form-row">
            <div className="col-md-12">
              <Input
                name="interface.corPrimaria"
                label="Cor primária"
                placeholder="Selecione a cor primária"
                onClick={() => handleColorPicker('primary')}
                onBlur={({ target: { value } }) =>
                  handleColors({ hex: value }, 'primary')
                } />

              {displayColorPicker === 'primary' &&
                <div className="color-picker-popover">
                  <div className="cover-color-picker"
                    onClick={() => setDisplayColorPicker('')} />
                  <SketchPicker color={primaryColor || ''}
                    onChangeComplete={(newColor) =>
                      handleColors(newColor, 'primary')
                    } />
                </div>}
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <Input
                name="interface.corSecundaria"
                label="Cor secundária"
                placeholder="Selecione a cor secundária"
                onClick={() => handleColorPicker('secondary')}
                onBlur={({ target: { value } }) =>
                  handleColors({ hex: value }, 'secondary')
                } />

              {displayColorPicker === 'secondary' &&
                <div className="color-picker-popover">
                  <div className="cover-color-picker"
                    onClick={() => setDisplayColorPicker('')} />
                  <SketchPicker color={secondaryColor || ''}
                    onChangeComplete={(newColor) =>
                      handleColors(newColor, 'secondary')
                    } />
                </div>}
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <Input
                name="interface.corBackground"
                label="Cor do fundo"
                placeholder="Selecione a cor do fundo"
                onClick={() => handleColorPicker('background')}
                onBlur={({ target: { value } }) =>
                  handleColors({ hex: value }, 'background')
                } />

              {displayColorPicker === 'background' &&
                <div className="color-picker-popover">
                  <div className="cover-color-picker"
                    onClick={() => setDisplayColorPicker('')} />
                  <SketchPicker color={backgroundColor || ''}
                    onChangeComplete={(newColor) =>
                      handleColors(newColor, 'background')
                    } />
                </div>}
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <Input
                name="interface.corTextoMenu"
                label="Cor do texto de Menu"
                placeholder="Selecione a cor do texto de menu"
                onClick={() => handleColorPicker('textoMenu')}
                onBlur={({ target: { value } }) => {
                  console.log("value: ", value);
                  handleColors({ hex: value }, 'textoMenu')
                }} />

              {displayColorPicker === 'textoMenu' &&
                <div className="color-picker-popover">
                  <div className="cover-color-picker"
                    onClick={() => setDisplayColorPicker('')} />
                  <SketchPicker color={corTextoMenu || ''}
                    onChangeComplete={(newColor) =>
                      handleColors(newColor, 'textoMenu')
                    } />
                </div>}
            </div>
          </div>

          <div>
            <Tooltip placement="right" title="Habilita a opção de uso do chatbot na área logada do onboarding">
              <div className="section-title">
                <h2>Take Blip Chat</h2>
              </div>
            </Tooltip>

            <div className='col-md-12'>
              <Switch label="Habilitar" name="interface.takeBlip.habilitar"
                checked={values?.interface?.takeBlip?.habilitar}
                onChange={(value) => {
                  setFieldValue('interface.takeBlip.habilitar', value)
                }} />

              <div className='input-wrapper my-2'>
                <label htmlFor="">App Key</label>
                <AntdInput placeholder="App Key"
                  value={values?.interface?.takeBlip?.appKey}
                  onChange={(e) => {
                    setFieldValue('interface.takeBlip.appKey', e.target.value)
                  }} />

              </div>

              <div className='input-wrapper'>
                <label htmlFor="">Common URL</label>
                <AntdInput placeholder="Common URL"
                  value={values?.interface?.takeBlip?.commonUrl}
                  onChange={(e) => {
                    setFieldValue('interface.takeBlip.commonUrl', e.target.value)
                  }} />
              </div>
            </div>
          </div>

          {values.interface.tipo === 'site' &&
            <div className="mt-3">
              <SectionTitle title="Links Clicáveis" />
              <div className="col-md-12">
                <Tabs>
                  {[1, 2, 3].map((i: any) =>
                    <TabPane tab={`Link ${i}`} key={`item-${i}`}>
                      <Input name={`interface.link${i}Nome`} label=""
                        placeholder="Digite o Nome do Link" />
                      <Input name={`interface.link${i}URL`} label=""
                        placeholder="Digite o URL do Link"
                        onBlur={({ target: { value } }) => {
                          setFieldValue(`interface.link${i}URL`, value ? handleURL(value) : '')
                        }} />
                    </TabPane>)}
                </Tabs>
              </div>
            </div>
          }
        </div>

        <div className="col-lg-8">
          <div className='d-flex justify-content-between'>
            <Switch name="_"
              checked={values.interface.onepage}
              label="Estrutura OnePage"
              onChange={(value) =>
                setFieldValue('interface.onepage', value)
              }
            />

            <Switch name="_" label="Habilitar Botão Nova Proposta"
              checked={values.interface.novaProposta || values.interface.novaProposta === null ? true : false}
              onChange={(value) => setFieldValue('interface.novaProposta', value)} />
          </div>

          <div
            className='col-lg-4 mb-2 text-center'
            style={{ margin: '0 auto' }}>
            <Select
              name="interface.tipo"
              label="Tipo"
              placeholder="Selecione um tipo"
              onChange={(value) => setFieldValue('interface.tipo', value)}
            >
              <Option value="area_logada">Área logada</Option>
              <Option value="site">Site</Option>
              <Option value="iframe">Frame</Option>
            </Select>
          </div>

          <Preview
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            backgroundColor={backgroundColor}
            corTextoMenu={corTextoMenu}
            logo={imageUrl}
          />
        </div>
      </div>

    </>
  );
}

export default Interface;