import { FC, useContext, useEffect, useState } from 'react';
import { ModalProps } from 'antd';
import api from '@services/api';

import ModalBase from '..';
import { GlobalContext } from '@contexts/GlobalContext';
import ModalLoading from '../ModalLoading';

interface Props extends ModalProps {
  propostaSelecionada: string;
}

const ModalCred: FC<Props> = ({ propostaSelecionada, ...props }) => {
  const { instituicao } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<any>({})

  useEffect(() => {
    setKey('');
    if (propostaSelecionada && instituicao) {
      setLoading(true);
      api.get(`Proposta/ChaveAnalise?instituicaoguid=${instituicao}&codProposta=${propostaSelecionada}`)
        .then((res) => {
          if (res.data) setKey(res.data);
        })
        .finally(() => setLoading(false));
    }
  }, [propostaSelecionada, instituicao]);

  return (
    <ModalBase {...props} centered width={1920}>
      <div style={{ minHeight: 600 }}>
        {key && (
          <iframe
            src={`https://cred-portal${window.origin.includes('b2egroup') ?
              '.b2egroup' : '-hml.b2esistemas'}.com.br/onboarding/?q=${key.Id}&token=${key.token}`}
            title="Analisar Proposta"
            width="100%"
            height="600px"
            style={{ marginTop: 30 }}
          />
        )}
      </div>
      <ModalLoading open={loading} message="Carregando proposta..." />
    </ModalBase>
  );
};

export default ModalCred;
