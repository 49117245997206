import './styles.scss';

function Await() {
  return (
    <div className='await'>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Await;