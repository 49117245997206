import { useEffect } from 'react';

const useInspinia = () => {
  useEffect(() => {
    const inspinia = document.createElement('script');
    inspinia.src = '/js/inspinia.js';
    inspinia.id = 'inspinia';

    document.body.appendChild(inspinia);

    return () => {
      document.body.removeChild(inspinia);
    };
  }, []);
};

export default useInspinia;
