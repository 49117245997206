import { FC, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Select, Option, Switch } from '@components/Form';
import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { EtapaContext } from '@contexts/EtapaContext';
import { integracoes } from '@constants/Integracoes';
import { message, Switch as AntdSwitch, Input as AntdInput } from 'antd';
import Input from 'antd/lib/input/Input';

const Integracao: FC = () => {
  const { tipoPessoa, etapaIdx, etapaPessoa } = useContext(EtapaContext);

  const { setFieldValue } = useFormikContext<ConfigTypes>();

  return (
    <div className="form-row">
      <div className="col-lg-8">
        <Select
          showSearch
          optionFilterProp="children"
          name={`etapas.${tipoPessoa}.${etapaIdx}.integracao`}
          label="Integração"
          placeholder="Selecione a integração"
          onChange={(value) => {
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.integracao`, value)
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.servico`, '')
          }}>
          {integracoes
            .map((integracao) =>
              <Option key={integracao.id} value={integracao.id}
                disabled={integracao.id == 2 && tipoPessoa == 'pj'}>
                {integracao.nome}
              </Option>
            )}
        </Select>
        <br />
        <Select
          showSearch
          optionFilterProp="children"
          name={`etapas.${tipoPessoa}.${etapaIdx}.servico`}
          label="Serviço"
          placeholder="Selecione o Serviço"
          onChange={(value: string) => {
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.servico`, value)

            if (value == 'acertpix|documentoscopia pro') {
              message.info('Para utilizar este serviço, é necessário que o campo CPF esteja habilitado nas etapas anteriores.', 6)
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [{
                label: 'Anexos de Documentoscopia PRO',
                name: 'Anexos de Documentoscopia PRO',
                habilitar: true,
                obrigatorio: false,
                value: null,
                type: 'opcoes',
                tipoDado: null,
                integracao: null,
                opcoes: ['Imagem-Frente', 'Imagem-Verso', 'Imagem-Selfie']
              }])

            } else setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [])
          }}>
          {etapaPessoa[etapaIdx]?.integracao == 0 ?
            ['serpro|biometria facial', /*'unico|biometria facial',*/ 'b2e|biometria facial'].map(s => (
              <Option key={s} value={s}>{s.split('|')[0].toUpperCase()}</Option>
            ))
            : etapaPessoa[etapaIdx]?.integracao == 2 ?
              ['acertpix|documentoscopia pro'].map(s => (
                <Option key={s} value={s}>{s.split('|')[0].toUpperCase()}</Option>
              ))
              : null}
        </Select>
        <br />
        {etapaPessoa[etapaIdx]?.servico == 'b2e|biometria facial' ?
          <div className='mt-2'>
            <Switch label="Requerer dados de autenticação (CPF, Nome, Data de Nascimento)"
              checked={etapaPessoa[etapaIdx]?.tipoCalculo == 1 ? true : false}
              name={`_`} onChange={(value) =>
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.tipoCalculo`, value ? 1 : 0)
              } />
          </div>
          : etapaPessoa[etapaIdx]?.servico == 'acertpix|documentoscopia pro' ?
            <>
              <div className='mt-2'>
                <Switch label="Requerer dados de autenticação (CPF)"
                  checked={etapaPessoa[etapaIdx]?.tipoCalculo == 1 ? true : false}
                  name={`_`} onChange={(value) =>
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.tipoCalculo`, value ? 1 : 0)
                  } />
              </div>
              <div className='mt-2'>
                <Switch label="Solicitar Selfie"
                  checked={etapaPessoa[etapaIdx]?.expressao == 'imagemSelfieB64' ? true : false}
                  name={`_`} onChange={(value) =>
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.expressao`, value ? 'imagemSelfieB64' : 'imagemFrenteB64')
                  } />
              </div>
            </>
            : null}
      </div>
    </div>
  );
};

export default Integracao;