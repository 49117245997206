import { useQuery, UseQueryOptions } from 'react-query';
import { createUseInstituicoesKey } from './keys';
import api from '@services/api';
import { Instituicao } from '../../types/Instituicao';

export const useInstituicoes = (options?: UseQueryOptions<Instituicao[]>) => {
  return useQuery(
    createUseInstituicoesKey(),
    () =>
      api.get<Instituicao[]>('ConfiguracaoForm/Instituicoes').then((response) =>
        response.data.filter(
          (
            (set) => (i: Instituicao) =>
              set.has(i.instituicaoGuid) ? false : set.add(i.instituicaoGuid)
          )(new Set()),
        ),
      ),
    options,
  );
};
