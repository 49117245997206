const { pathname: url, href } = window.location;

function isNotEmpty(value: any): boolean {
	const isDlink = href.includes('dlink');

	return value !== '' &&
		value !== null && value !== undefined &&
		value !== '0,00' && value !== '0' && value !== '0.00' && '-0,00' &&
		value !== "0001-01-01T00:00:00Z" &&
		(!isDlink || value !== 'N/I')
}

export function removeEmptyKeys(obj: any): any {
	if (typeof obj !== 'object' || obj === null) return obj;

	if (Array.isArray(obj)) {
		// Para arrays, remove itens vazios e objetos vazios usando filter
		return obj.filter((item) => {
			if (typeof item === 'object' && !Array.isArray(item)) {
				// Se for um objeto, processa recursivamente e verifica se está vazio
				const processedObj = removeEmptyKeys(item);
				return Object.keys(processedObj).length > 0;
			}
			// Usa a função isNotEmpty para a comparação
			return isNotEmpty(item);
		});
	}

	// Para objetos, processa recursivamente cada chave
	const processedObj: { [key: string]: any } = {};
	for (const key in obj) {
		const value = removeEmptyKeys(obj[key]);
		// Usa a função isNotEmpty para a comparação
		if (isNotEmpty(value)) processedObj[key] = value;
	}

	return processedObj;
}