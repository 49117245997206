import { FC, useCallback, useState, useEffect, Dispatch, SetStateAction, useContext, } from 'react';
import { ModalProps, Button, message } from 'antd';
// import * as acessoWebFrame from 'unico-webframe';
import api from '@services/api';
import { GlobalContext } from '@contexts/GlobalContext';

import ModalBase from '..';

import './styles.scss';
import { useFormikContext } from 'formik';
import initialValues from '@pages/Cadastrar/initialValues';
import Login from '@components/Spinner/Login';

interface ModalBiometriaProps extends ModalProps {
  base64: string;
  setBase64: Dispatch<SetStateAction<string>>;
  setValidated: Dispatch<SetStateAction<boolean>>;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

const ModalBiometria: FC<ModalBiometriaProps> = ({
  base64,
  setBase64,
  setValidated,
  setIsModalVisible,
  ...props
}) => {
  const { open } = props;
  const { instituicao } = useContext(GlobalContext);
  const { values, setFieldValue } = useFormikContext<typeof initialValues>();
  //console.log("values: ", values);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInterval(() => {
      //console.log('setando atributos');
      document.getElementById('background')?.remove()
      document.getElementById('focus-silhouette')?.setAttribute('rx', '100')
    }, 5000)
  }, [])

  // const initUnico = useCallback(async () => {
  //   const callback = {
  //     on: {
  //       click: function () {
  //         alert('click');
  //       },
  //       success: function (obj: any) {
  //         setBase64(obj.base64);
  //       },
  //       error: function (error: any) {
  //         console.error(error);
  //       },
  //       support: function (error: any) {
  //         //console.log(error);

  //         window.alert(`
  //         Browser não suportado
  //         ------------------------------------
  //         iOS: Safari
  //         Android/Windows: Chrome, Firefox
  //       `);
  //         setIsModalVisible(false);
  //       },
  //     },
  //   };


  //   const layout = {
  //     buttonCapture: {
  //       backgroundColor: '#2980ff',
  //       iconColor: '#fff',
  //     },
  //     popupLoadingHtml:
  //       '<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Loading...</div>',
  //     boxMessage: {
  //       backgroundColor: '#2980ff',
  //       fontColor: '#fff',
  //     },
  //     boxDocument: {
  //       backgroundColor: '#2980ff',
  //       fontColor: '#fff',
  //     },
  //   };

  //   const configurations = { TYPE: 1, };
  //   acessoWebFrame.initCamera(configurations, callback, layout);
  // }, [setBase64]);

  useEffect(() => {
    if (open && !base64) {
      // initUnico();

      if (sessionStorage.getItem('bioServico') !== 'b2e|biometria facial') return;
      document.getElementById('camera--trigger')?.remove()

      // create new for control camera icon in the center of the screen
      const cameraIcon = document.createElement('img')
      cameraIcon.id = 'camera-icon'
      cameraIcon.src = "https://cdn.onlinewebfonts.com/svg/img_211436.png";
      cameraIcon.style.position = 'absolute'
      cameraIcon.style.top = '50%'
      cameraIcon.style.left = '50%'
      cameraIcon.style.marginTop = '137px'
      cameraIcon.style.transform = 'translate(-50%, -50%)'
      cameraIcon.style.zIndex = '10'
      cameraIcon.style.width = '45px'
      cameraIcon.style.height = '45px'
      cameraIcon.style.display = 'flex'
      cameraIcon.style.justifyContent = 'center'
      cameraIcon.style.alignItems = 'center'
      cameraIcon.style.cursor = 'pointer'
      cameraIcon.style.filter = "invert(1)";

      // create new for control camera icon in the center of the screen
      const loadingIcon = document.createElement('img')
      loadingIcon.id = 'camera-icon'
      loadingIcon.src = "https://tjrj-apps-balcao-virtual.azurefd.net/images/typing.gif";
      loadingIcon.style.position = 'absolute'
      loadingIcon.style.top = '50%'
      loadingIcon.style.left = '50%'
      loadingIcon.style.marginTop = '137px'
      loadingIcon.style.transform = 'translate(-50%, -50%)'
      loadingIcon.style.zIndex = '10'
      loadingIcon.style.height = '60px'
      loadingIcon.style.display = 'flex'
      loadingIcon.style.justifyContent = 'center'
      loadingIcon.style.alignItems = 'center'

      cameraIcon.onclick = async () => {
        cameraIcon.style.display = 'none'
        document.querySelector('#box-camera')?.appendChild(loadingIcon)

        const videoFrames: any = []

        // in 6 times and each 400ms get frame from tag video, then push into videoFrames, and at the final call function sendBase64
        for (let i = 0; i < 6; i++) {
          await new Promise((resolve) => setTimeout(resolve, 400))
          const video = document.querySelector('#camera--video')
          const canvas = document.createElement('canvas')
          canvas.width = video?.clientWidth || 0
          canvas.height = video?.clientHeight || 0
          const ctx = canvas.getContext('2d')
          ctx?.drawImage(video as HTMLVideoElement, 0, 0, canvas.width, canvas.height)
          videoFrames.push(canvas.toDataURL('image/jpeg'))
        }
        sendBase64(videoFrames)
      }

      document.querySelector('#box-camera')?.appendChild(cameraIcon)
    }
    // }, [open, initUnico, base64]);
  }, [open, base64]);

  const newCapture = () => {
    setBase64('');
  };

  const sendBase64 = async (bases64: [] = []) => {
    setLoading(true);
    try {
      const res = await api.post(`Proposta/BiometriaFacial?instituicao=${sessionStorage.getItem('b2e:instituicao')}`, {
        cpf: values.cpf, nome: values.nome, image: base64,
        servico: sessionStorage.getItem('bioServico')
      })
      setFieldValue('acessoBioId', res.data)
      handleOk();

    } catch (error) {
      console.log("error: ", error);
      message.error('Falha ao enviar biometria');

    } finally {
      setLoading(false);
      setIsModalVisible(false);
    }
  };

  const handleOk = () => {
    setValidated(true);
    sessionStorage.setItem('bioVerficada', 'true');
  };

  return (
    <ModalBase {...props}>
      <h3>Reconhecimento Facial</h3>
      <p>
        É importante remover qualquer tipo de acessório (óculos, chapéus,
        máscaras e etc.)
      </p>

      <div className="camera-unico">
        {base64 ? (
          <>
            <div className="row">
              <div className="col-lg-12">
                <img src={base64} alt="Captura facial" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                {/* @ts-ignore */}
                <Button type="primary" onClick={sendBase64} disabled={loading}>
                  {!loading ? 'A foto ficou boa' : 'Processando...'}
                </Button>
              </div>
              <div className="col-lg-12 mt-2">
                <Button onClick={newCapture}>Quero tirar outra</Button>
              </div>
            </div>
          </>
        ) : (
          <div id="box-camera"></div>
        )}
      </div>
    </ModalBase>
  );
};

export default ModalBiometria;
