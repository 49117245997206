import React, { createContext, useState, useCallback, useEffect, FC } from 'react';
import { ConfigProvider, message } from 'antd';
import { comprovanteOptions, formaPagamentoOptions, profissaoOptions, sexoOptions, parentescoOptions, tipoRelacaoOptions } from '@services/mock';
import api from '@services/api';
import themeDefault from '@styles/theme';
import { ThemeProvider } from 'styled-components';
import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { Instituicao } from '../types/Instituicao';
import mockBancos from './mockBancos';
import mockNaturezasJuridicas from './mockNaturezasJuridicas';
import mockEstadosCivil from './mockEstadosCivil';
import mockNacionalidades from './mockNacionalidades';

type Option = {
  id: string | number;
  descricao: string;
};

type PropsContext = {
  regimeTributario: Option[];
  configuracoes: any;
  bancos: Option[];
  comprovantes: Option[];
  estadosCivil: Option[];
  formasPagamento: Option[];
  modalidades: Option[];
  nacionalidades: Option[];
  naturezasOcupacao: Option[];
  //categorias: Option[];
  profissoes: Option[];
  sexos: Option[];
  parentescos: Option[];
  tipoRelacao: Option[];
  instituicao: string;
  instituicoes: Instituicao[];
  logo: string | undefined;
  loading: boolean;
  subdominio: string;
  pareceresInstituicao: any[];
  setPareceresInstituicao: (value: any[]) => void;
  setInstituicao: (value: string) => void;
  setInstituicoes: (instituicoes: Instituicao[]) => void;
  salvarConfiguracoes: (value: ConfigTypes) => void;
  setThemeConfig: () => void;
  loadFormOptions: () => void;
  setSubdominio: (value: string) => void;
  handleTheme: (value: any) => void;
};

const INITIAL_CONTEXT = {
  regimeTributario: [],
  configuracoes: {},
  bancos: [],
  comprovantes: [],
  estadosCivil: [],
  formasPagamento: [],
  modalidades: [],
  nacionalidades: [],
  naturezasOcupacao: [],
  profissoes: [],
  sexos: [],
  parentescos: [],
  tipoRelacao: [],
  //categorias: [],
  instituicao: '',
  instituicoes: [],
  logo: '',
  loading: false,
  subdominio: '',
  pareceresInstituicao: [],
  setPareceresInstituicao: () => { },
  setInstituicao: () => { },
  setInstituicoes: () => { },
  salvarConfiguracoes: () => { },
  setThemeConfig: () => { },
  loadFormOptions: () => { },
  setSubdominio: () => { },
  handleTheme: () => { },
};

export const GlobalContext = createContext<PropsContext>(INITIAL_CONTEXT);

const DEFAULT_SUBDOMAINS = ['login', 'configuracao', 'follow-up'];

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [configuracoes, setConfiguracoes] = useState<ConfigTypes>();
  sessionStorage.setItem('YjJlOmludGVyZmFjZQ==', configuracoes?.interface?.corPrimaria?.toString() || 'dW5kZWZpbmVk'); /* hide with base64 */

  const [instituicao, setInstituicao] = useState<string>('');
  const [instituicoes, setInstituicoes] = useState<Instituicao[]>([]);
  const [logo, setLogo] = useState<string | undefined>('');

  const [regimeTributario, setRegimeTriutario] = useState<Option[]>([]);
  const [bancos, setBancos] = useState<Option[]>([]);
  const [comprovantes, setComprovantes] = useState<Option[]>([]);
  const [estadosCivil, setEstadosCivil] = useState<Option[]>([]);
  const [formasPagamento, setFormasPagamento] = useState<Option[]>([]);
  const [modalidades, setModalidades] = useState<Option[]>([]);
  const [nacionalidades, setNacionalidades] = useState<Option[]>([]);
  const [naturezasOcupacao, setNaturezasOcupacao] = useState<Option[]>([]);
  const [profissoes, setProfissoes] = useState<Option[]>([]);
  const [sexos, setSexos] = useState<Option[]>([]);
  const [parentescos, setParentescos] = useState<Option[]>([]);
  const [tipoRelacao, setTipoRelacao] = useState<Option[]>([]);
  //const [categorias, setCategorias] = useState<Option[]>([]);
  const [theme, setTheme] = useState(themeDefault);
  const [loading, setLoading] = useState(true);
  const [subdominio, setSubdominio] = useState('');
  const [pareceresInstituicao, setPareceresInstituicao]: any = useState([]);
  const instituicaoStoraged = sessionStorage.getItem('b2e:instituicao');
  const tokenStoraged = localStorage.getItem('token');

  const loadRegimeTributario = useCallback(async () => {
    // const { data } = await api.get('Proposta/RegimeTributario');
    setRegimeTriutario([
      { "id": "1", "descricao": "Simples Nacional" },
      { "id": "2", "descricao": "Lucro Presumido" },
      { "id": "3", "descricao": "Lucro Real" },
      { "id": "4", "descricao": "MEI" }
    ]);
  }, []);

  const loadPareceresInstituicao = useCallback(async (inst: any) => {
    const { data } = await api.get(`ConfiguracaoForm/ObterStatusInstituicao?guidInstituicao=${inst}`);
    setPareceresInstituicao(data);
  }, []);

  const loadBancos = useCallback(async () => {
    setBancos(mockBancos); // usando mock até que o GET abaixo não retorne erros 500..
    api.get('Proposta/Bancos');
  }, []);

  const loadNaturezasJuridicas = useCallback(async () => {
    setNaturezasOcupacao(mockNaturezasJuridicas); // usando mock até que o GET abaixo não retorne erros 500..
    api.get('Proposta/NaturezasJuridicas');
  }, []);

  const loadEstadosCivil = useCallback(async () => {
    setEstadosCivil(mockEstadosCivil); // usando mock até que o GET abaixo não retorne erros 500..
    api.get('Proposta/EstadoCivil');
  }, []);

  const loadNacionalidades = useCallback(async () => {
    setNacionalidades(mockNacionalidades); // usando mock até que o GET abaixo não retorne erros 500..
    api.get('Proposta/Nacionalidades');

    // set Brasileiro to Brasileira
    /* data = data.map((item: any) => {
      if (item.descricao === 'Brasileiro') {
        return { ...item, descricao: 'Brasileira' };
      }
      return item;
    }); */
  }, []);

  /* const loadModalidades = useCallback(async () => {
    let { data: varModalidades } = await api.get(`Proposta/Modalidades?guid_instituicao=${sessionStorage.getItem('b2e:instituicao')}`);
    setModalidades(varModalidades);
  }, []); */

  /* const loadCategorias = useCallback(async () => {
    if (!configuracoes?.idInstituicao) return;
    let { data: varModalidades } = await api.get('Proposta/Modalidades?id_tb_instituicao=' + configuracoes?.idInstituicao);
    let { data: varCategorias } = await api.get('Proposta/Categorias?id_modalidade=' + varModalidades[0]?.id ?? -1);
    setCategorias(varCategorias);
  }, []); */

  const loadFormOptions = () => {
    loadRegimeTributario();
    loadBancos();
    loadNaturezasJuridicas();
    loadEstadosCivil();
    loadNacionalidades();
    //loadModalidades();
    //loadCategorias();

    setComprovantes(comprovanteOptions);
    setFormasPagamento(formaPagamentoOptions);
    setProfissoes(profissaoOptions);
    setSexos(sexoOptions);
    setParentescos(parentescoOptions);
    setTipoRelacao(tipoRelacaoOptions);
  };

  useEffect(() => {
    if (instituicaoStoraged && tokenStoraged) {
      setInstituicao(instituicaoStoraged);
      loadPareceresInstituicao(instituicaoStoraged);
    }
  }, [instituicaoStoraged]);

  const getConfigForm = async (subdominio: string) => {
    try {
      let { data: d1 }: any = await api.get(`ConfiguracaoForm/${subdominio}`)
      let { data: d2 }: any = await api.get(`ConfiguracaoForm?instituicao=${d1.codigoInsituicao}`)
      // setInstituicao(res.data.codigoInsituicao);
      sessionStorage.setItem('b2e:instituicao', d1.codigoInsituicao);
      setConfiguracoes((config: any) => ({ ...config, ...d2, interface: d1 }));
      setLoading(false)
    } catch (error) {
      console.log("error: ", error);
      setConfiguracoes(undefined);
    }
  }

  useEffect(() => {
    ////console.log('Chamada');
    if (subdominio && !DEFAULT_SUBDOMAINS.includes(subdominio)) {
      setLoading(true);
      getConfigForm(subdominio);
    } else {
      setLoading(false);
    }
  }, [subdominio]);

  useEffect(() => {
    if (instituicao)
      try {
        const getConfigs = async () => {
          let { data: allFromInstituicao } = await api.get(`ConfiguracaoForm?instituicao=${instituicao}`)
          if (allFromInstituicao.instituicao.subdominio)
            sessionStorage.setItem('b2e:subdominio', allFromInstituicao.instituicao.subdominio)

          let { status, data } = await api.get(`/ConfiguracaoForm/GrupoInstituicao?guid=${sessionStorage.getItem('b2e:instituicao')}`)
          if (status != 200) sessionStorage.removeItem('b2e:grupoSubdominio')
          else sessionStorage.setItem('b2e:grupoSubdominio', data.nomeGrupo)

          let { data: idInstituicao } = await api.get(`ConfiguracaoForm/InstituicoesGuid?guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}`)

          let acessoUser: any = null
          if (localStorage.getItem('token')) {
            let { data: configUserLogged } = await api.get(`GestaoAcesso/ObterPerfilUsuario?usuario=${sessionStorage.getItem('loginLogged')}&guidEmpresa=${sessionStorage.getItem('b2e:instituicao')}`,
              { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            let { data: perfisInstituicao } = await api.get(`GestaoAcesso/PerfisInstituicao?guidEmpresa=${sessionStorage.getItem('b2e:instituicao')}`,
              { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })

            if (configUserLogged?.perfil?.desc == 'ADMIN B2E') {
              acessoUser = configUserLogged.perfil
            } else if (configUserLogged.idPerfil)
              perfisInstituicao.map((perfil: any) => {
                if (perfil.id == configUserLogged.idPerfil)
                  acessoUser = perfil.info
              })
          }

          ['pfEtapasFinais', 'pjEtapasFinais'].forEach((ef) => {
            // declaração de etapas finais
            const efPagamento = {
              "id": 0,
              "nome": "Pagamento",
              "tipo": 201,
              "botaoEnvio": false,
              "habilitar": false,
              "campos": [
                {
                  "label": "Status Habilitados",
                  "name": `${ef}-status-habilitados`,
                  "habilitar": false,
                  "obrigatorio": false,
                  "type": "text",
                  "tipoDado": null,
                  "opcoes": null,
                  "value": null
                },
                {
                  "label": "API Token",
                  "name": `${ef}-api-token`,
                  "habilitar": false,
                  "obrigatorio": false,
                  "type": "text",
                  "tipoDado": null,
                  "opcoes": null,
                  "value": null
                },
                {
                  "label": "Account ID - SubConta",
                  "name": `${ef}-account-id`,
                  "habilitar": false,
                  "obrigatorio": false,
                  "type": "text",
                  "tipoDado": null,
                  "opcoes": null,
                  "value": null
                },
              ],
              "integracao": 0,
              "Pre-Analise": false,
              "StatusProsseguirPreAnalise": null,
              "Aguardar-Resposta": false,
              "chosen": false,
              "servico": null,
              "expressao": null,
              "tipoCalculo": null
            };

            const efNotificacao = {
              "id": 1,
              "nome": "Notificação",
              "tipo": 201,
              "botaoEnvio": false,
              "habilitar": false,
              "campos": [],
              "integracao": 0,
              "Pre-Analise": false,
              "StatusProsseguirPreAnalise": null,
              "Aguardar-Resposta": false,
              "chosen": false,
              "servico": null,
              "expressao": null,
              "tipoCalculo": null
            };

            if (!(ef in allFromInstituicao?.etapas) || allFromInstituicao?.etapas?.[ef]?.length == 0) {
              allFromInstituicao.etapas = { ...allFromInstituicao.etapas, [ef]: [efPagamento, efNotificacao] };

            } else { // se já houver etapas finais, verificar se estão completas
              let efAtuais = allFromInstituicao.etapas[ef].map(({ nome }: any) => nome);

              [efPagamento, efNotificacao].map((variavel: any) => {
                if (!efAtuais.includes(variavel.nome))
                  allFromInstituicao.etapas[ef].push(variavel);
              });
            }
          })

          setConfiguracoes({ ...allFromInstituicao, idInstituicao, acessoUser });
        }

        setLoading(true);

        setTimeout(() => {
          getConfigs();
        }, 1000);

      } catch (error) {
        console.warn("error: ", error);
        setConfiguracoes(undefined);
      } finally {
        setLoading(false);
      }
  }, [instituicao, sessionStorage.getItem('b2e:instituicao')]);

  useEffect(() => {
    setThemeConfig();
    setLogo(configuracoes?.interface.logo);
  }, [configuracoes]);

  const salvarConfiguracoes = useCallback(async (configuracoes: ConfigTypes) => {
    message.loading('Salvando configurações, aguarde...');

    try {
      var { status }: any = await api.post('ConfiguracaoForm', configuracoes)
      message.success({ content: 'Configurações salvas com sucesso!', duration: 3, });
      setConfiguracoes(configuracoes);

    } catch (error) {
      message.error('Falha ao salvar configurações.', 3)
    }

  }, []);

  const setThemeConfig = () => {
    ConfigProvider.config({
      theme: {
        primaryColor:
          configuracoes?.interface.corPrimaria || themeDefault.colors.navy,
      },
    });
    const newTheme = {
      ...theme,
      colors: {
        ...theme.colors,
        navy:
          configuracoes?.interface.corSecundaria || themeDefault.colors.navy,
        secondary:
          configuracoes?.interface.corPrimaria || themeDefault.colors.secondary,
        'nav-bg':
          configuracoes?.interface.corSecundaria ||
          themeDefault.colors['nav-bg'],
        'custom-bg': configuracoes?.interface.corBackground || '#f3f3f4',
      },
    };

    handleTheme(newTheme);
  };

  const handleTheme = (newTheme: typeof themeDefault) => {
    setTheme(newTheme);
  };

  return (
    <GlobalContext.Provider
      value={{
        regimeTributario,
        configuracoes,
        bancos,
        comprovantes,
        estadosCivil,
        formasPagamento,
        modalidades,
        nacionalidades,
        naturezasOcupacao,
        profissoes,
        sexos,
        parentescos,
        tipoRelacao,
        //categorias,
        instituicao,
        instituicoes,
        logo,
        loading: (configuracoes) ? false : (sessionStorage.getItem('b2e:instituicao') ? true : false),
        subdominio,
        pareceresInstituicao,
        loadFormOptions,
        setPareceresInstituicao,
        setInstituicao,
        setInstituicoes,
        salvarConfiguracoes,
        setThemeConfig,
        setSubdominio,
        handleTheme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
