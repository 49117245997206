import { FC } from 'react';
import { useField } from 'formik';
import { Switch as AntdSwitch, SwitchProps } from 'antd';

import './styles.scss';

interface Props extends SwitchProps {
  name: string;
  label?: string;
}

const Switch: FC<Props> = ({ label, ...rest }) => {
  const [field, meta] = useField(rest.name);

  return (
    <div className="switch-wrapper">
      <div>
        <AntdSwitch {...field} {...rest} />
        <span>{label}</span>
      </div>
      {meta.touched && meta.error ? (
        <span className="error">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default Switch;
