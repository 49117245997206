import { formatMoney } from '@/utils/formatMoney';
import axios from 'axios';

const baseURLs = new Map([
  ['https://onboarding.b2egroup.com.br', 'https://onboarding-api.b2egroup.com.br/'],
  ['https://preprod-onboarding.b2egroup.com.br', 'https://preprod-onboarding-api.b2egroup.com.br/'],
  ['https://pre-prod-onboarding.b2egroup.com.br', 'https://pre-prod-onboarding-api.b2egroup.com.br/'],
  ['https://onboarding-web-hml.b2esistemas.com.br', 'https://onboarding-api-hml.b2esistemas.com.br/'],
  ['https://onboarding-web-hml2.b2esistemas.com.br', 'https://onboarding-api-hml2.b2esistemas.com.br/'],
  ['https://onboarding-web-dev.b2elab.com.br', 'https://onboarding-api-dev.b2elab.com.br/'],
  ['https://onboarding-web-dev.b2esistemas.com.br', 'https://onboarding-api-dev.b2esistemas.com.br/'],
  ['https://onboarding-p2p.b2egroup.com.br', 'https://onboarding-api-p2p.b2egroup.com.br/'],
]);

let { href } = window.location;
const apiBaseURL = baseURLs.get(window.origin) || 'https://localhost:44340/';
const api = axios.create({ baseURL: apiBaseURL });
const isPropostaURL = (u: string): boolean => /Proposta/.test(u);

const formatMoneyToFrontend = (data: any) => {
  const formatData = (obj: any) => {
    return Object.entries(obj)
      .reduce((acc: any, [key, value]: any) => {
        if (value instanceof Array) // Se o valor for um array, formata cada item
          acc[key] = value.map(item => formatData(item));

        else if (value instanceof Object && value !== null) // Se o valor for um objeto, chama a função recursivamente
          acc[key] = formatData(value);

        else if (typeof value === 'string' && /^-?\d+(?:[.,]\d{2})$/.test(value)) // Formata o valor se for uma string decimal
          acc[key] = formatMoney(value);

        else // Mantém o valor original para outros tipos
          acc[key] = value;

        return acc;
      }, {});
  };

  // Inicia a formatação com os dados fornecidos
  return formatData(data);
};

const formatMoneyToBackend = (data: any) => {
  const formatData = (obj: any) => {
    return Object.entries(obj)
      .reduce((acc: any, [key, value]: any) => {
        if (value instanceof Array) // Se o valor for um array, formata cada item
          acc[key] = value.map(item => formatData(item));

        else if (value instanceof Object && value !== null)  // Se o valor for um objeto, chama a função recursivamente
          acc[key] = formatData(value);

        else if (typeof value === 'string' && /^-?\d{1,3}(?:\.\d{3})*,\d{2}$/.test(value)) // Formata o valor se for uma string decimal
          acc[key] = value.replace(/\./g, '');

        else // Mantém o valor original para outros tipos
          acc[key] = value;

        return acc;
      }, {});
  };

  // Inicia a formatação com os dados fornecidos
  return formatData(data);
};

api.interceptors.request.use((config) => {
  if (isPropostaURL(`${config.url}`) && config.method === 'post')
    if (config.data && typeof config.data === 'object')
      config.data = formatMoneyToBackend(config.data);

  if (config.headers) {
    config.headers.guidInstituicao = `${sessionStorage.getItem('b2e:instituicao')}`;

    // não usar Bearer Token para propostas em área não logada:
    if (/site|frame|dlink/.test(href)) return config;

    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    const { config } = response;
    if (isPropostaURL(`${config.url}`) && config.method === 'get')
      if (response.data && typeof response.data === 'object')
        response.data = formatMoneyToFrontend(response.data);

    return response;
  },
  (error) => {
    if (error.response?.status === 401)
      localStorage.removeItem('token');

    let appRoute = sessionStorage.getItem('b2e:subdominio') || 'login';
    // se não estiver na página de login, não fazer isto:
    if (href.includes('login') == false)
      href = `${window.origin}/${appRoute}`;

    return Promise.reject(error);
  },
);

export default api;