import Layout from '@components/Layout';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...otherProps }: any) => {
  ////console.log('PublicRoute Component: ', Component);
  ////console.log('PublicRoute otherProps: ', otherProps);

  return (
    <Layout>
      <Route
        {...otherProps}
        render={(routeProps) => <Component {...routeProps} />}
      />
    </Layout>
  );
};

export default PublicRoute;
