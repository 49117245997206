// @ts-nocheck
import toolbarReactQuill from '@utils/toolbarReactQuill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Modal, Tooltip, Select as AntdSelect, Input as AntdInput, Dropdown, Menu, Switch as AntdSwitch, Tag, Tabs } from 'antd';
import { useFormikContext } from 'formik';

import { ReactSortable } from 'react-sortablejs';
import { MenuOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';

import { SectionTitle, Select, Option, Switch, InputGroup, TextArea } from '@components/Form';

import CardBloco from '@components/CardBloco';
import ModalEtapa from '@components/Modal/ModalEtapa';
import AddEditEtapa from '@components/AddEditEtapa';

import { ConfigTypes, Bloco, EtapaType } from '@pages/Configuracao/ConfigTypes';
import camposIntegracao from '@pages/Configuracao/camposIntegracao';
import EtapaProvider from '@contexts/EtapaContext';
import { camposPessoaJuridica } from '@pages/Configuracao/camposPessoaJuridica';
import * as S from '@components/CardBloco/styles';

import { FC, useContext } from 'react';
import { EtapaContext } from '@contexts/EtapaContext';
import { GlobalContext } from '@contexts/GlobalContext';
import { integracoes } from '@constants/Integracoes';
import { message, Input } from 'antd';
import Tags from "@yaireo/tagify/dist/react.tagify";
import api from '@services/api';
import { validateBr } from 'js-brasil';

const baseURL = window.location.origin;

export default function Etapas() {
  const baseTagifySettings = { backspace: "none", placeholder: "digite aqui.." };
  const quillRef = useRef(null);
  const handleInsertChar = (char: any = '') => { // @ts-ignore
    const quill = quillRef?.current?.getEditor();
    const cursorPosition = quill.getSelection(true).index || 0;
    quill.insertText(cursorPosition, `{{${char}}}`);
  };

  const { values, setValues, setFieldValue } = useFormikContext<ConfigTypes>();
  const { etapaIdx, etapaPessoa } = useContext(EtapaContext);
  const { pareceresInstituicao, instituicao, configuracoes: c } = useContext(GlobalContext);
  const [pareceresInstituicaoMsg, setPareceresInstituicaoMsg] = useState([]);
  const [idStatusSelected, setIdStatusSelected] = useState(-1);

  const handleNotifEmailEditados = (id: any) => {
    const idsNotifEmailEditados = new Set([...JSON.parse(sessionStorage.getItem('idsNotifEmailEditados') || '[]'), id]);
    sessionStorage.setItem('idsNotifEmailEditados', JSON.stringify(Array.from(idsNotifEmailEditados)));
  }

  const handleNotifSMSEditados = (id: any) => {
    const idsNotifSMSEditados = new Set([...JSON.parse(sessionStorage.getItem('idsNotifSMSEditados') || '[]'), id]);
    sessionStorage.setItem('idsNotifSMSEditados', JSON.stringify(Array.from(idsNotifSMSEditados)));
  }

  const [blocoList, setBlocoList] = useState<Bloco[]>([]);
  const [etapa, setEtapa] = useState<Bloco>();
  const [tipoPessoa, setTipoPessoa] = useState('');
  const [previewDlink, setPreviewDlink] = useState(false);

  const mockEtapasFinais = [{ active: false, nome: 'Resposta Customizada', id: 0 }, { active: false, nome: 'Pagamento', id: 1 }, { active: false, nome: 'Notificação', id: 2 }];
  const [etapasFinais, setEtapasFinais] = useState(mockEtapasFinais);
  const [etapaFinal, setEtapaFinal] = useState(false)

  const [isModalEtapaVisible, setIsModalEtapaVisible] =
    useState<boolean>(false);

  const [notifTab, setNotifTab] = useState('email');
  const [notifListEmail, setNotifListEmail] = useState([]);
  const [notifListSMS, setNotifListSMS] = useState([]);
  useEffect(() => {
    sessionStorage.setItem('notifListEmail', JSON.stringify(notifListEmail));
    sessionStorage.setItem('notifListSMS', JSON.stringify(notifListSMS));
  }, [notifListEmail, notifListSMS]);

  const loadBlocoList = useCallback(() => {
    setBlocoList((values.etapas as EtapaType)[tipoPessoa] || []);
  }, [values.etapas, tipoPessoa]);

  // useEffect(() => {
  //   setFieldValue('etapas', { pf: [], pj: [] });
  // }, []);

  const handleModalEtapa = () => {
    setIsModalEtapaVisible(!isModalEtapaVisible);
  };

  const addEtapa = () => {
    setEtapasFinais(mockEtapasFinais);
    setEtapaFinal(false);

    const novaEtapa = {
      id: Math.floor(Math.random() * 9999999) + 1,
      nome: 'Nova etapa',
      tipo: tipoPessoa.includes('pf') ? 0 : 4,
      botaoEnvio: false,
      'Pre-Analise': false,
      'Aguardar-Resposta': false,
      habilitar: true,
      campos: tipoPessoa.includes('pf') ? camposIntegracao : camposPessoaJuridica,
    };

    setEtapa(novaEtapa);

    setValues({
      ...values,
      etapas: {
        ...values?.etapas,
        [tipoPessoa]: [...(values?.etapas as EtapaType)[tipoPessoa] || [], novaEtapa],
      },
    });

    handleModalEtapa();
  };

  const handleEditEtapa = (bloco: any = false) => {
    setEtapasFinais(mockEtapasFinais);
    setEtapaFinal(false);

    if (bloco)
      setEtapa(bloco);
  };

  const handleRemoveEtapa = (bloco: Bloco) => {
    setEtapa(undefined);

    const filteredArray = (values.etapas as EtapaType)[tipoPessoa].filter(
      (el: Bloco) => el.id !== bloco.id,
    );

    setFieldValue(`etapas.${tipoPessoa}`, filteredArray);

    loadBlocoList();
  };

  const handleChangeTipoPessoa = (value: string) => {
    setTipoPessoa(value);
    setEtapa(undefined);
  };

  const handleStatusSelectedEtapaNotif = () =>
    setTimeout(() => {
      let lastStatusSelectedEtapaNotif = parseInt(sessionStorage.getItem('lastStatusSelectedEtapaNotif') || '-1');
      pareceresInstituicaoMsg.map((i: any) => {
        if (i.idStatus == lastStatusSelectedEtapaNotif)
          setIdStatusSelected(lastStatusSelectedEtapaNotif);
      })
    }, 100);

  /* ETAPAS FINAIS */
  const handleClickEtapaFinal = async (e: any, i: number) => {
    setEtapa(undefined);
    const etapasFinais = [...mockEtapasFinais];
    etapasFinais[i].active = !etapasFinais[i].active;
    setEtapasFinais(etapasFinais);
    setEtapaFinal(true);

    // verificar se há etapa Pagamento e Parcelamento ativas em conjunto:
    if (e.nome == 'Pagamento' && !values.etapas[tipoPessoa].find((c: any) => c.tipo == 5))
      message.info('Para habilitar a etapa Pagamento, é necessário habilitar a etapa Parcelamento');

    else if (e.nome == 'Notificação') {
      if (!sessionStorage.getItem('restartEtapaNotif')) {
        setNotifTab('email');
        setIdStatusSelected(-1);
        handleStatusSelectedEtapaNotif();
      }
      sessionStorage.removeItem('restartEtapaNotif');

      const { data } = await api.get(`NotificacaoProposta/ObterListaNotificacaoProposta?guidInstituicao=${instituicao}&tipoPessoa=${tipoPessoa}`);

      let notifListEmailComplete = JSON.parse(sessionStorage.getItem('notifListEmail') || '[]');
      if (notifListEmailComplete.length == 0) notifListEmailComplete = data.filter((n: any) => n.tipoEnvio == "email");

      let notifListSMSComplete = JSON.parse(sessionStorage.getItem('notifListSMS') || '[]');
      if (notifListSMSComplete.length == 0) notifListSMSComplete = data.filter((n: any) => n.tipoEnvio == "sms");

      let idsPareceres = pareceresInstituicaoMsg.map((p: any) => { return p.idStatus });
      let idsEmail = notifListEmailComplete.map((n: any) => { return n.idStatusProposta });
      let idsSMS = notifListSMSComplete.map((n: any) => { return n.idStatusProposta });

      idsPareceres.map((id: any) => {
        let notifListMock = {
          guidInstituicao: instituicao, ativo: false,
          tipoPessoa: tipoPessoa, idStatusProposta: id,
          destinatario: "", destinatarioRefProposta: false,
          destinatarioCc: "", destinatarioCcRefProposta: false,
          templateMensagem: ""
        };

        if (!idsEmail.includes(id)) {
          notifListEmailComplete.push({ ...notifListMock, tipoEnvio: 'email' });
          handleNotifEmailEditados(id);
        }

        if (!idsSMS.includes(id)) {
          notifListSMSComplete.push({ ...notifListMock, tipoEnvio: 'sms' });
          handleNotifSMSEditados(id);
        }
      })

      setNotifListSMS(notifListSMSComplete);
      setNotifListEmail(notifListEmailComplete);
    }
  }

  const handleRemoveEtapaFinal = (e: any) => {
    setEtapasFinais(mockEtapasFinais);
    setEtapaFinal(false);

    if (e.nome == 'Resposta Customizada')
      setFieldValue('interface.respostaCadastro.mensagemResposta.habilitar', false);
    else
      setFieldValue(`etapas.${tipoPessoa}EtapasFinais.${e.id - 1}.habilitar`, false);
  }

  const etapasFinaisHabilitadas = () => {
    return etapasFinais
      .sort((a: any, b: any) => a.nome - b.nome)
      .map((e: any, i: number) => {
        if (!((e.nome == 'Resposta Customizada' && values?.interface?.respostaCadastro?.mensagemResposta?.habilitar) || (values?.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.habilitar)))
          return e;
      })
      .filter((e: any) => e != undefined);
  }

  const loadPareceresInstituicaoMsg = async () => {
    try {
      let { data } = await api.get(`ConfiguracaoForm/ObterMensagemResposta?guidInstituicao=${instituicao}`)
      setPareceresInstituicaoMsg(data)
    } catch (error) {
      message.error('Falha ao atualizar lista de Status, recarregue a página')
    }
  }

  useEffect(() => {
    loadPareceresInstituicaoMsg();
    ['pareceresEditados', 'idsPareceresEditados'].forEach((i) => { sessionStorage.removeItem(i) })
  }, [pareceresInstituicao])

  useEffect(() => {
    loadBlocoList();
  }, [loadBlocoList]);

  return (
    <EtapaProvider tipoPessoa={tipoPessoa} etapa={etapa}>
      <div className="select-wrapper">
        <label>Selecione o tipo de onboarding</label>
        <div className="d-flex align-items-center">
          <AntdSelect showSearch optionFilterProp="children"
            style={{ width: 400 }}
            placeholder="Pessoa Física ou Jurídica"
            onChange={handleChangeTipoPessoa}>
            <Option value="pf">Pessoa Física</Option>
            <Option value="pj">Pessoa Jurídica</Option>
            <Option value="dlinkpj">DisplayLink PJ</Option>
            <Option value="dlinkpf">DisplayLink PF</Option>
          </AntdSelect>
          {tipoPessoa?.includes('dlink') && blocoList.length > 0 &&
            <div className="mt-auto float-left my-auto">
              <Tooltip title="Preview Display Link">
                <i className='fas fa-external-link ml-3'
                  onClick={() => { setPreviewDlink(!previewDlink) }} />
              </Tooltip>
            </div>}
        </div>
      </div>

      {tipoPessoa && <div className="row">
        <div className="col-lg-3">
          <SectionTitle title="Etapas" />
          <ReactSortable
            list={blocoList}
            setList={setBlocoList}
            onEnd={() => {
              setFieldValue(`etapas.${tipoPessoa}`, blocoList);
            }}
          >
            {blocoList.map((bloco: Bloco) => (
              <CardBloco
                key={bloco.id}
                bloco={bloco}
                active={bloco.id === etapa?.id}
                onSelect={() => {
                  handleEditEtapa(bloco);
                }}
                onRemove={handleRemoveEtapa}
              />
            ))}
          </ReactSortable>

          {tipoPessoa && (
            <>
              <Button type="dashed" block
                icon={<PlusOutlined />}
                onClick={addEtapa}>
                Adicionar Etapa
              </Button>

              <br />

              {!tipoPessoa.includes('dlink') &&
                <>
                  <SectionTitle title="Etapas Finais" />
                  {etapasFinais.map((e, i) => {
                    if ((e.nome == 'Resposta Customizada' && values.interface.respostaCadastro?.mensagemResposta?.habilitar) || (values.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.habilitar))
                      return <S.Container key={i} active={e.active} className={`mt-2 py-3 active-${e.active}`} id={e.nome}
                        style={{ cursor: 'pointer' }} onClick={() => { handleClickEtapaFinal(e, i) }}>
                        <div style={{ transform: 'rotate(90deg)', transformOrigin: 'center' }}>
                          <MenuOutlined />
                        </div>
                        <div className="title-menu">
                          <div className="title">
                            <span>{e.nome}</span>
                          </div>
                          <div className="options">
                            <Dropdown trigger={['click']}
                              overlay={<Menu key={'drop' + i}>
                                <Menu.Item onMouseDown={() => handleRemoveEtapaFinal(e)}>
                                  Excluir
                                </Menu.Item>
                              </Menu>}>
                              <Button shape="circle" type="text">
                                <MoreOutlined />
                              </Button>
                            </Dropdown>
                          </div>
                        </div>
                      </S.Container>
                  })}

                  {etapasFinaisHabilitadas().length > 0 &&
                    <Button type="dashed" block
                      icon={<PlusOutlined />}
                      onClick={() => {
                        handleEditEtapa();
                        Modal.confirm({
                          title: 'Selecione uma Etapa Final',
                          content: <div className="mb-2 col-lg-12">
                            <AntdSelect showSearch
                              className='w-100' optionFilterProp="children"
                              placeholder="Selecione uma Etapa Final"
                              onChange={async (value: any) => {
                                sessionStorage.setItem('newEtapaFinalField', value);
                              }}>
                              {etapasFinais
                                .sort((a: any, b: any) => a.nome - b.nome)
                                .map((e: any, i: number) => {
                                  if (!((e.nome == 'Resposta Customizada' && values?.interface?.respostaCadastro?.mensagemResposta?.habilitar) || (values?.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.habilitar)))
                                    return <Option key={e.id} value={e.nome}>
                                      {e.nome}
                                    </Option>
                                })}
                            </AntdSelect>
                          </div>,
                          onOk: async () => {
                            let newEtapaFinalField = sessionStorage.getItem('newEtapaFinalField');
                            if (!newEtapaFinalField) return message.error('Selecione uma Etapa Final');

                            let allEtapasFinais = [...mockEtapasFinais];
                            let newEtapaFinal: any = allEtapasFinais.filter((e: any) => e.nome == newEtapaFinalField)[0];
                            newEtapaFinal.active = true;

                            // Habilita a nova etapa final
                            setEtapasFinais(allEtapasFinais);
                            setEtapaFinal(true);

                            // Desabilita as outras etapas normais
                            setEtapa(undefined);

                            if (newEtapaFinal.nome == 'Resposta Customizada') {
                              setFieldValue('interface.respostaCadastro.mensagemResposta.habilitar', true);

                            } else if (newEtapaFinal.nome == 'Notificação') {
                              setFieldValue(`etapas.${tipoPessoa}EtapasFinais.${newEtapaFinal.id - 1}.habilitar`, true);
                              setTimeout(() => { //@ts-ignore
                                document.querySelector('#Notificação.active-true .title-menu')?.click();
                              }, 333);

                            } else {
                              setFieldValue(`etapas.${tipoPessoa}EtapasFinais.${newEtapaFinal.id - 1}.habilitar`, true);
                            }
                          },
                          afterClose: () => {
                            sessionStorage.removeItem('newEtapaFinalField');
                          }
                        })
                      }}>
                      Adicionar Etapa Final
                    </Button>}
                </>
              }
            </>
          )}
        </div>

        {etapa && <div className="col-lg-9 editar-etapas">
          <AddEditEtapa />
        </div>}

        {etapaFinal ?
          <div className="col-lg-9 editar-etapas">
            {etapasFinais.map((e, i) => {
              if (e.active)
                if (e.nome == 'Resposta Customizada')
                  return <div className="form-row" key={i}>
                    <div className='col-lg-12 mb-2'>
                      <label htmlFor="">Status Habilitados</label>
                      <br />
                      <AntdSelect showSearch value={idStatusSelected}
                        className='col-lg-6' optionFilterProp="children"
                        onChange={async (value: any) => {
                          setIdStatusSelected(value);
                          sessionStorage.setItem('lastStatusSelectedEtapaNotif', value);
                        }}>
                        <Option value={-1} disabled>Selecione um Status</Option>
                        {pareceresInstituicaoMsg
                          .sort((a: any, b: any) => a.idStatus - b.idStatus)
                          .map((item: any) =>
                            <Option key={item.idStatus} value={item.idStatus}>
                              {item.idStatus + ' - ' + item.descricaoStatus}
                            </Option>)}
                      </AntdSelect>

                      {idStatusSelected >= 0 && <div className='float-right'>
                        <Button type="primary" onClick={() => {
                          Modal.confirm({
                            title: 'Selecionar variável ao parecer',
                            content: (
                              <AntdSelect showSearch className='w-100'
                                optionFilterProp="children"
                                placeholder="Selecione a variável"
                                onChange={(value) => {
                                  sessionStorage.setItem('varInterfaceSelected', value);
                                }}>
                                {['Score', 'LimiteConcedido', 'RegraDecisora', 'Parecer', 'CodigoProposta'].map((v: any) => <Option value={v}>{v}</Option>)}
                              </AntdSelect>
                            ),
                            okText: 'Adicionar',
                            cancelText: 'Cancelar',
                            onOk: () => {
                              handleInsertChar(sessionStorage.getItem('varInterfaceSelected'));
                            },
                            afterClose: () => {
                              sessionStorage.removeItem('varInterfaceSelected');
                            }
                          })
                        }}>
                          <i className="fa fa-plus mr-1" /> Adicionar variável
                        </Button>
                      </div>}
                    </div>

                    {pareceresInstituicaoMsg
                      .map((i: any) => {
                        if (i.idStatus == idStatusSelected)
                          return <>
                            <div className='col-lg-12'>
                              <div style={{ minHeight: '200px' }}>
                                <ReactQuill modules={toolbarReactQuill}
                                  ref={quillRef} value={i.mensagem}
                                  onChange={(value) => {
                                    let temp: any = pareceresInstituicaoMsg.map((item: any) => {
                                      if (item.idStatus == idStatusSelected)
                                        item.mensagem = value;
                                      return item;
                                    })

                                    setPareceresInstituicaoMsg(temp);
                                    sessionStorage.setItem('pareceresEditados', JSON.stringify(temp));

                                    // Convert Set to an Array before storing in sessionStorage
                                    const idsPareceresEditados = new Set([...JSON.parse(sessionStorage.getItem('idsPareceresEditados') || '[]'), idStatusSelected]);
                                    sessionStorage.setItem('idsPareceresEditados', JSON.stringify(Array.from(idsPareceresEditados)));
                                  }} />
                              </div>
                            </div>
                          </>
                      })}
                  </div >

                else if (e.nome == 'Pagamento')
                  return <div className="form-row" key={i}>
                    <div className="col-lg-8">
                      <div className='select-wrapper'>
                        <label>Integração</label>
                        <AntdSelect
                          value={values.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.integracao}
                          showSearch optionFilterProp="children"
                          onChange={(value) =>
                            setFieldValue(`etapas.${tipoPessoa}EtapasFinais.${i - 1}.integracao`, value)
                          }
                        >
                          <Option value={0} disabled>Selecione a integração</Option>
                          {[{ id: 1, nome: 'IUGU' }].map((integracao) => (
                            <Option key={integracao.id} value={integracao.id}>
                              {integracao.nome}
                            </Option>
                          ))}
                        </AntdSelect>
                      </div>

                      {values.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.integracao == 1 &&
                        <div>
                          <div className='select-wrapper mt-2'>
                            <label>
                              Status Habilitados
                              <Tooltip title="Dentro do Follow UP, o botão de reimpressão de faturas só será exibido para estes Status Habilitados">
                                <i className='fas fa-info-circle ml-2' />
                              </Tooltip>
                            </label>
                            <AntdSelect
                              value={JSON.parse(values.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.campos[0]?.value || '[]')}
                              mode="multiple" showSearch optionFilterProp="children"
                              placeholder="Status Habilitados"
                              onChange={(value) =>
                                setFieldValue(`etapas.${tipoPessoa}EtapasFinais.${i - 1}.campos[0].value`, JSON.stringify(value))
                              }
                            >
                              {pareceresInstituicao?.sort((a, b) => a.idStatus - b.idStatus).map(
                                (option: any) => (
                                  <Option key={option.idStatus} value={option.idStatus}>
                                    {option.idStatus} - {option.descricao}
                                  </Option>
                                ))}
                            </AntdSelect>
                          </div>
                          <div className='input-wrapper mt-2'>
                            <label>API Token</label>
                            <AntdInput
                              value={values.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.campos[1]?.value}
                              placeholder="API Token"
                              onChange={({ target: { value } }) =>
                                setFieldValue(`etapas.${tipoPessoa}EtapasFinais.${i - 1}.campos[1].value`, value)
                              } />
                          </div>
                          <div className='input-wrapper mt-2'>
                            <label>Account ID - SubConta</label>
                            <AntdInput
                              value={values.etapas[`${tipoPessoa}EtapasFinais`]?.[i - 1]?.campos[2]?.value}
                              placeholder="Account ID - SubConta"
                              onChange={({ target: { value } }) =>
                                setFieldValue(`etapas.${tipoPessoa}EtapasFinais.${i - 1}.campos[2].value`, value)
                              } />
                          </div>
                          <div className='mt-2 row'>
                            <div className="col-md-9">
                              <label>
                                Link Reimpressão de Faturas
                              </label>
                              <AntdInput value={baseURL + '/' + values.instituicao.subdominio + '/pagamentos'} />
                            </div>
                            <div className="col-md-auto mt-4" onMouseEnter={({ currentTarget: c }) => c.style.cursor = 'pointer'}>
                              <i className="fa fa-clipboard col-md-auto"
                                onClick={() => {
                                  navigator.clipboard.writeText(baseURL + '/' + values.instituicao.subdominio + '/pagamentos');
                                  message.success('Copiado para área de transferência');
                                }}
                              />
                              <i className="fa fa-external-link col-md-auto"
                                onClick={() => window.open(baseURL + '/' + values.instituicao.subdominio + '/pagamentos')}
                              />
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>

                else if (e.nome == 'Notificação') {
                  const tabs: TabsProps['tabs'] = [{ key: 'email', label: 'E-mail' }, { key: 'sms', label: 'SMS' },];

                  return <>
                    <Tabs defaultActiveKey="email" items={tabs} centered
                      onChange={(key) => {
                        setNotifTab(key);
                        setIdStatusSelected(-1);
                        handleStatusSelectedEtapaNotif();
                      }} />

                    <label htmlFor="">Status Habilitados</label>
                    <br />
                    <AntdSelect showSearch value={idStatusSelected}
                      className='col-lg-6 float-left' optionFilterProp="children"
                      onChange={async (value: any) => {
                        setIdStatusSelected(value);
                        sessionStorage.setItem('lastStatusSelectedEtapaNotif', value);
                      }}>
                      <Option value={-1} disabled>Selecione um Status</Option>
                      {pareceresInstituicaoMsg
                        .sort((a: any, b: any) => a.idStatus - b.idStatus)
                        .map((item: any) =>
                          <Option key={item.idStatus} value={item.idStatus}>
                            {item.idStatus + ' - ' + item.descricaoStatus}
                          </Option>)}
                    </AntdSelect>

                    {notifTab == 'email' ?
                      notifListEmail.map((n: any) => {
                        if (idStatusSelected == n.idStatusProposta) {
                          function TagsDestinatario() {
                            const handleChangeTag = (e: any) => {
                              let tags = e.detail.tagify.value.map((tag) => {
                                if (validateBr.email(tag.value)) return tag.value
                              });
                              let temp: any = notifListEmail.map((item: any) => {
                                if (item.idStatusProposta == idStatusSelected)
                                  item.destinatario = tags.join(',');
                                return item;
                              })

                              setNotifListEmail(temp);
                              handleNotifEmailEditados(idStatusSelected);
                            };

                            const handleRemoveTag = async (e: any) => {
                              let newTags = e.detail.tagify.value.map((tag: any) => tag.value)
                              let temp: any = notifListEmail.map((item: any) => {
                                if (item.idStatusProposta == idStatusSelected)
                                  item.destinatario = newTags.join(',');
                                return item;
                              })

                              setNotifListEmail(temp);
                              handleNotifEmailEditados(idStatusSelected);
                            };

                            const settings = {
                              ...baseTagifySettings, editTags: false,
                              callbacks: {
                                add: (e) => { handleChangeTag(e); },
                                remove: (e) => { handleRemoveTag(e); },
                                invalid: () => { },
                              }
                            };

                            return <Tags settings={settings} initialValue={n.destinatario || []} />;
                          }

                          function TagsDestinatarioCc() {
                            const handleChangeTag = (e: any) => {
                              let tags = e.detail.tagify.value.map((tag) => { if (validateBr.email(tag.value)) return tag.value });
                              let temp: any = notifListEmail.map((item: any) => {
                                if (item.idStatusProposta == idStatusSelected)
                                  item.destinatarioCc = tags.join(',');
                                return item;
                              })

                              setNotifListEmail(temp);
                              handleNotifEmailEditados(idStatusSelected);
                            };

                            const handleRemoveTag = async (e: any) => {
                              let newTags = e.detail.tagify.value.map((tag: any) => tag.value)
                              let temp: any = notifListEmail.map((item: any) => {
                                if (item.idStatusProposta == idStatusSelected)
                                  item.destinatarioCc = newTags.join(',');
                                return item;
                              })

                              setNotifListEmail(temp);
                              handleNotifEmailEditados(idStatusSelected);
                            };

                            const settings = {
                              ...baseTagifySettings, editTags: false,
                              callbacks: {
                                add: (e) => { handleChangeTag(e); },
                                remove: (e) => { handleRemoveTag(e); },
                                invalid: () => { },
                              }
                            };

                            return <Tags settings={settings} initialValue={n.destinatarioCc || []} />;
                          }

                          return <>
                            <div className='col-lg-6 float-right'>
                              <div className='float-left ml-2'>
                                <AntdSwitch checked={n?.ativo} onChange={(checked) => {
                                  let temp: any = notifListEmail.map((item: any) => {
                                    if (item.idStatusProposta == idStatusSelected)
                                      item.ativo = checked;
                                    return item;
                                  })

                                  setNotifListEmail(temp);
                                  handleNotifEmailEditados(idStatusSelected);
                                }} />
                                <span className='ml-2'>Ativo</span>
                              </div>

                              <div className='float-right'>
                                <Button type="primary" onClick={() => {
                                  Modal.confirm({
                                    title: 'Selecionar variável ao parecer',
                                    content: (
                                      <AntdSelect showSearch className='w-100'
                                        optionFilterProp="children"
                                        placeholder="Selecione a variável"
                                        onChange={(value) => {
                                          sessionStorage.setItem('varNotifSelected', value);
                                        }}>
                                        {['Score', 'LimiteConcedido', 'RegraDecisora', 'Parecer', 'CodigoProposta'].map((v: any) => <Option value={v}>{v}</Option>)}
                                      </AntdSelect>
                                    ),
                                    okText: 'Adicionar',
                                    cancelText: 'Cancelar',
                                    onOk: () => {
                                      handleInsertChar(sessionStorage.getItem('varNotifSelected'));
                                    },
                                    afterClose: () => {
                                      sessionStorage.removeItem('varNotifSelected');
                                    }
                                  })
                                }}>
                                  <i className="fa fa-plus mr-1" /> Adicionar variável
                                </Button>
                              </div>
                            </div>

                            <div className='mt-5 w-100' style={{ minHeight: '200px' }}>
                              <ReactQuill modules={toolbarReactQuill}
                                ref={quillRef} value={n?.templateMensagem}
                                onChange={(value, delta, source, editor) => {
                                  let temp: any = notifListEmail.map((item: any) => {
                                    if (item.idStatusProposta == idStatusSelected)
                                      item.templateMensagem = value;
                                    return item;
                                  })

                                  setNotifListEmail(temp);
                                  handleNotifEmailEditados(idStatusSelected);
                                }} />
                            </div>

                            <div className='mt-3'>
                              <div className='float-left'>Destinatário:</div>
                              <div className='switch-wrapper float-right'>
                                <div>
                                  <AntdSwitch checked={n.destinatarioRefProposta}
                                    onChange={(checked) => {
                                      let temp: any = notifListEmail.map((item: any) => {
                                        if (item.idStatusProposta == idStatusSelected)
                                          item.destinatarioRefProposta = checked;
                                        return item;
                                      })

                                      setNotifListEmail(temp);
                                      handleNotifEmailEditados(idStatusSelected);
                                    }} />
                                  <span>E-mail Proposta</span>
                                </div>
                              </div>
                              <br />
                              <div className='mt-2'>
                                <TagsDestinatario />
                              </div>
                            </div>

                            <div className='mt-3'>
                              <div className='float-left'>Cc:</div>
                              <div className='switch-wrapper float-right'>
                                <div>
                                  <AntdSwitch checked={n.destinatarioCcRefProposta}
                                    onChange={(checked) => {
                                      let temp: any = notifListEmail.map((item: any) => {
                                        if (item.idStatusProposta == idStatusSelected)
                                          item.destinatarioCcRefProposta = checked;
                                        return item;
                                      })

                                      setNotifListEmail(temp);
                                      handleNotifEmailEditados(idStatusSelected);
                                    }} />
                                  <span>E-mail Proposta</span>
                                </div>
                              </div>
                              <br />
                              <div className='mt-2'>
                                <TagsDestinatarioCc />
                              </div>
                            </div>
                          </>
                        }
                      })
                      :
                      notifListSMS.map((n: any) => {
                        if (idStatusSelected == n.idStatusProposta) {
                          function TagsDestinatario() {
                            const handleChangeTag = (e: any) => {
                              let tags = e.detail.tagify.value.map((tag) => {
                                if (tag.value.replace(/\D/g, '').match(/^\d{10,11}$/)) return tag.value
                              });
                              let temp: any = notifListSMS.map((item: any) => {
                                if (item.idStatusProposta == idStatusSelected)
                                  item.destinatario = tags.join(',');
                                return item;
                              })

                              setNotifListSMS(temp);
                              handleNotifSMSEditados(idStatusSelected);
                            };

                            const handleRemoveTag = async (e: any) => {
                              let newTags = e.detail.tagify.value.map((tag: any) => tag.value)
                              let temp: any = notifListSMS.map((item: any) => {
                                if (item.idStatusProposta == idStatusSelected)
                                  item.destinatario = newTags.join(',');
                                return item;
                              })

                              setNotifListSMS(temp);
                              handleNotifSMSEditados(idStatusSelected);
                            };

                            const settings = {
                              ...baseTagifySettings, editTags: false,
                              callbacks: {
                                add: (e) => { handleChangeTag(e); },
                                remove: (e) => { handleRemoveTag(e); },
                                invalid: () => { },
                              }
                            };

                            return <Tags settings={settings} initialValue={n.destinatario || []} />;
                          }

                          return <>
                            <div className='col-lg-6 float-right'>
                              <div className='float-left ml-2'>
                                <AntdSwitch checked={n?.ativo} onChange={(checked) => {
                                  let temp: any = notifListSMS.map((item: any) => {
                                    if (item.idStatusProposta == idStatusSelected)
                                      item.ativo = checked;
                                    return item;
                                  })

                                  setNotifListSMS(temp);
                                  handleNotifSMSEditados(idStatusSelected);
                                }} />
                                <span className='ml-2'>Ativo</span>
                              </div>

                              <div className='float-right'>
                                <Button type="primary" onClick={() => {
                                  Modal.confirm({
                                    title: 'Selecionar variável ao parecer',
                                    content: (
                                      <AntdSelect showSearch className='w-100'
                                        optionFilterProp="children"
                                        placeholder="Selecione a variável"
                                        onChange={(value) => {
                                          sessionStorage.setItem('varNotifSelected', value);
                                        }}>
                                        {['Score', 'LimiteConcedido', 'RegraDecisora', 'Parecer', 'CodigoProposta'].map((v: any) => <Option value={v}>{v}</Option>)}
                                      </AntdSelect>
                                    ),
                                    okText: 'Adicionar',
                                    cancelText: 'Cancelar',
                                    onOk: () => {
                                      handleInsertChar(sessionStorage.getItem('varNotifSelected'));
                                    },
                                    afterClose: () => {
                                      sessionStorage.removeItem('varNotifSelected');
                                    }
                                  })
                                }}>
                                  <i className="fa fa-plus mr-1" /> Adicionar variável
                                </Button>
                              </div>
                            </div>

                            <div className='mt-5 w-100' style={{ minHeight: '200px' }}>
                              <ReactQuill modules={{ toolbar: false }}
                                ref={quillRef} value={n?.templateMensagem}
                                onChange={(value, delta, source, editor) => {
                                  let temp: any = notifListSMS.map((item: any) => {
                                    if (item.idStatusProposta == idStatusSelected)
                                      item.templateMensagem = value;
                                    return item;
                                  })

                                  setNotifListSMS(temp);
                                  handleNotifSMSEditados(idStatusSelected);
                                }} />
                            </div>

                            <div className='mt-3'>
                              <div className='float-left'>Destinatário:</div>
                              <div className='switch-wrapper float-right'>
                                <div>
                                  <AntdSwitch checked={n.destinatarioRefProposta}
                                    onChange={(checked) => {
                                      let temp: any = notifListSMS.map((item: any) => {
                                        if (item.idStatusProposta == idStatusSelected)
                                          item.destinatarioRefProposta = checked;
                                        return item;
                                      })

                                      setNotifListSMS(temp);
                                      handleNotifSMSEditados(idStatusSelected);
                                    }} />
                                  <span>Celular Proposta</span>
                                </div>
                              </div>
                              <br />
                              <div className='mt-2'>
                                <TagsDestinatario />
                              </div>
                            </div>
                          </>
                        }
                      })
                    }
                  </>
                }

                else return <></>
            })}
          </div >
          : <></>}
      </div >}

      <Modal open={previewDlink} closable={true} footer={null} destroyOnClose={true}
        onCancel={() => { setPreviewDlink(!previewDlink) }}>
        <style>
          {`.ant-modal,.ant-modal-content,.ant-modal-body {
              height: 100vh; width: 100vw; margin: 0; top: 0; }`}
        </style>
        <iframe src={window.location.origin + '/' + sessionStorage.getItem('b2e:subdominio') + '/' + tipoPessoa + '-preview'}
          style={{ width: '100%', height: '100%', border: 'none' }} />
      </Modal>
    </EtapaProvider>
  );
}
