import React, { useState, useEffect } from 'react';
import { Button, Modal, Select, message } from 'antd';
import api from '@services/api';
import moment from 'moment';

const ModalPagamentoIugu = ({ invoices, modalInvoice, setModalInvoice }: any) => {
  const [invoiceGuid, setInvoiceGuid]: any = useState('about:blank');

  const boolEntradaPix = (invoice: any) =>
    invoice.parcela == 0 && invoice.pixCode && invoice.pixQr;

  const showInvoice = (guid: any) => {
    const invoice: any = invoices.find((i: any) => i.guid === guid);
    if (!invoice) return null;
    //console.log("invoice: ", invoice);

    if (boolEntradaPix(invoice)) {
      return <div className='text-center'>
        <img src={invoice?.pixQr + '.png'} className='img-fluid' max-width='400px' />
        <div className='my-3'><b>Valor:</b> {invoice?.vlrTotal.replace('.', ',').replace(/^[0-9,]*$/, '')}</div>
        <Button onClick={() => {
          try {
            navigator.clipboard.writeText(invoice?.pixCode);
            message.success('Pix copiado para a área de transferência');
          } catch (error) {
            message.error('Erro ao copiar Pix');
          }
        }}>
          Pix Copia e Cola
        </Button>
      </div>
    } else {
      return <iframe src={invoice?.secureUrl + '.pdf'} style={{ width: '100%', height: '85%', overflow: 'hidden' }} />
    }
  }

  useEffect(() => {
    const invoice: any = invoices.find((i: any) => i.guid === invoiceGuid);
    if (!invoice) return; // Se invoice for null ou undefined, interrompe a execução do useEffect

    if (boolEntradaPix(invoice)) {
      //console.log('Iniciando setInterval...');
      const iStatus = setInterval(async () => {
        //console.log('Verificando status da fatura:', invoice);
        try {
          let { data }: any = await api.get(`Faturamento/ObterStatusFaturaPorId?id=${invoiceGuid}`);
          if (['paid', 'externally_paid'].includes(data.status)) {
            // avoid repetiotions of this message
            message.destroy();
            message.success('Pagamento confirmado');
            clearInterval(iStatus);
          }
        } catch (error) {
          console.error('Erro ao obter status da fatura:', error);
          clearInterval(iStatus);
        }
      }, 10000); // Verifica a cada 10 segundos

      return () => {
        //console.log('Limpando setInterval...');
        clearInterval(iStatus);
      }; // Limpa o intervalo quando o componente for desmontado
    } else {
      //console.log('Fatura não é de entrada Pix');
    }
  }, [invoiceGuid]); // Executa quando o valor do 'invoice' mudar

  return (
    <Modal
      centered
      closable={true}
      destroyOnClose
      width={1000}
      bodyStyle={{ height: '97vh' }}
      footer={null}
      open={modalInvoice}
      afterClose={() => setInvoiceGuid('about:blank')}
      onCancel={() => setModalInvoice(false)}
    >
      <div className='select-wrapper my-3'>
        <label>Selecione um pagamento:</label>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Selecione um pagamento"
          value={invoiceGuid}
          onChange={(i) => setInvoiceGuid(i)}
        >
          <Select.Option value={'about:blank'} disabled>Selecione um pagamento</Select.Option>
          {Array.isArray(invoices) && invoices
            ?.sort((a, b) => a?.parcela - b?.parcela)
            ?.map((i) => {
              // tabela de status: https://dev.azure.com/b2esistemas/B2E/_workitems/edit/39011/#14232087
              return <Select.Option key={i?.guid} value={i?.guid} disabled={[0, 2, 11].includes(i?.idStatus) || i?.secureUrl == null}>
                {i?.parcela === 0 ? `Entrada` : `Parcela ${i?.parcela}`}
                {i?.vlrTotal ? ' | R$ ' + i?.vlrTotal?.replace('.', ',')?.replace(/[^0-9,]/g, '') : ''}
                {i?.dataVencimento ? ` | Vencimento ${moment(i?.dataVencimento).format('DD/MM/YYYY')}` : ''}
                {i?.dataPagamento ? ' | Pag. ' + i?.dataPagamento?.replace(/-/g, '/').split('T')[0] : ''}
                {[0].includes(i?.idStatus) || i?.secureUrl == null ? ' | Aguardando Pagamento de Entrada' :
                  [2, 11].includes(i?.idStatus) ? ' | Pagamento confirmado' : ' | Pagamento Pendente'}
              </Select.Option>
            })}
        </Select>
      </div>

      {showInvoice(invoiceGuid)}
    </Modal>
  );
};

export default ModalPagamentoIugu;