import React from 'react';
import ReactDOM from 'react-dom';
import ptBR from 'antd/lib/locale/pt_BR';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.variable.min.css';
import './styles/main.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-credit-cards-2/dist/lib/styles.scss'


import App from './App';

ConfigProvider.config({
  theme: {
    primaryColor: '#881220',
  },
});

const consoleError = console.error.bind(console);

console.error = (errObj, ...args) => {
  const NODE_ENV = process.env.NODE_ENV;
  if (NODE_ENV === 'development' && typeof errObj.message === 'string' && args.includes('findDOMNode')) {
    return;
  }
  consoleError(errObj, ...args);
};

ReactDOM.render(
  <ConfigProvider csp={{nonce: 'XNONCETAG'}} locale={ptBR}>
    <App />
  </ConfigProvider>,
  document.getElementById('root'),
);
