// @ts-nocheck
import { FC, useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PageWrapper from './Wrapper';
import PageWrapperFrame from './WrapperFrame';
import PageWrapperSite from './WrapperSite';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { GlobalContext } from '@contexts/GlobalContext';

import logob2e from '@assets/images/B2ELogo.png';
import exclamation from '@assets/images/exclamation-circle.svg';
import { Input, Modal, message } from 'antd';
import api from '@services/api';
import Spinner from '@components/Spinner/PreAnalise';
import AwaitFull from '@components/Spinner/AwaitFull';
import ReactInputMask from 'react-input-mask';
import { handleURL } from '@utils/handleURL';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Footer = ({ children, ...props }) => {
  return <div style={{ zIndex: 11 }} className={`footer ${props.position || ''}`}>
    <div className="pull-right">
      <img src={logob2e} width={40} alt="" />
    </div>
    <div>
      <strong>Powered by</strong> B2E Group &copy; 2022
    </div>
  </div>
};

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { pathname: url, href } = window.location;
  const { subdominio } = useParams<{ subdominio: string }>();
  const params = useParams<{ codProposta: string }>();

  const { setSubdominio, configuracoes: c } = useContext(GlobalContext);
  const [loadingComp, setLoadingComp] = useState(true);

  useEffect(() => {
    setSubdominio(subdominio || '');

    if (href.includes('configuracao') || sessionStorage.getItem('YjJlOmludGVyZmFjZQ==') != 'dW5kZWZpbmVk') /* hide with base64 */
      setLoadingComp(false);
  }, [subdominio, c]);

  // alertando usuário sobre o pq ele foi redirecionado anteriormente
  /* if (sessionStorage.getItem('b2e:msgFeedback')) {
    alert('aqui')
    message.warning(sessionStorage.getItem('b2e:msgFeedback'));
    sessionStorage.removeItem('b2e:msgFeedback');
  } */

  const HideGRecaptcha = () => <style>{`.grecaptcha-badge {    display: none !important;  }`}</style>

  return url?.includes('/expired') ? <>
    <div class="d-flex justify-content-center align-items-center flex-column"
      style={{ backgroundColor: '#ffbf00', height: '100vh' }}>
      <img src={exclamation} width={170} style={{ filter: 'invert(1)' }} />
      <h2 className='text-white'>
        <strong>Ops! o link está expirado.</strong>
      </h2>
    </div>
    <Footer position="position-fixed" />
  </>
    : loadingComp ? <AwaitFull />
      : url?.includes('/frame') || url?.includes('/acesso-negado') ?
        <GoogleReCaptchaProvider reCaptchaKey={window.origin.includes('b2egroup') ? '6LdidjonAAAAADHpcVuVRHpMFtjfwDeD1ZAuwkQv' : '6LdgI4QmAAAAAAvutQBWLkCT26QmuL0ZJmEydq7D'}>
          {HideGRecaptcha()}
          <PageWrapperFrame>
            <div className="wrapper wrapper-content animated fadeInRight">
              {children}
            </div>
          </PageWrapperFrame>
        </GoogleReCaptchaProvider>

        : url?.includes('dlink') || url?.includes('/site') || url?.includes('/pagamentos') ?
          <GoogleReCaptchaProvider reCaptchaKey={window.origin.includes('b2egroup') ? '6LdidjonAAAAADHpcVuVRHpMFtjfwDeD1ZAuwkQv' : '6LdgI4QmAAAAAAvutQBWLkCT26QmuL0ZJmEydq7D'}>
            {HideGRecaptcha()}
            <PageWrapperSite>
              <nav id='onb' className="navbar navbar-expand-lg navbar-light"
                style={{ backgroundColor: c?.interface?.corSecundaria }}>
                <div className="container m-0 pl-0">
                  <a className="navbar-brand">
                    <img src={c?.interface?.logo} width={150} />
                  </a>
                  <ul className="navbar-nav mr-auto">
                    {[1, 2, 3].map((i: any) => {
                      return (c?.interface?.[`link${i}Nome`] && c?.interface?.[`link${i}URL`]) &&
                        <a key={i} className="nav-link" href={c?.interface?.[`link${i}URL`]} target="_blank"
                          style={{ color: c?.interface?.corTextoMenu || c?.interface?.corPrimaria || '#8B2622', fontSize: '15px' }}>
                          {c?.interface?.[`link${i}Nome`]}
                        </a>
                    })}
                  </ul>
                </div>
              </nav>

              <div className="wrapper wrapper-content animated fadeInRight">
                {children}
              </div>

              <Footer position="position-fixed" />
            </PageWrapperSite>
          </GoogleReCaptchaProvider>

          : // layout normal (com barra lateral)
          <div id="wrapper">
            <Sidebar />

            <PageWrapper>
              <Navbar />

              <div className="wrapper wrapper-content animated fadeInRight">
                {children}
              </div>

              <Footer />
            </PageWrapper>
          </div>
};

export default Layout;
