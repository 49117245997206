import './styles.scss';

function AwaitFull() {
  return (
    <>
      <style>{`#onb { display: none !important`}</style>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
        <div className='await'>
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </>
  );
}

export default AwaitFull;