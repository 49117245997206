import { FC, useState, useEffect, useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { EtapaContext } from '@contexts/EtapaContext';
import { SectionTitle, Switch, Option, Select } from '@components/Form';
import { Button, message, Modal, Select as SelectAntd, Input as InputAntd, Tooltip, Upload } from 'antd';
import api from '@services/api';
import { GlobalContext } from '@contexts/GlobalContext';
import ModalLoading from '@components/Modal/ModalLoading';

const { TextArea } = InputAntd;

export default function () {
  const [loading, setLoading] = useState({ status: false, message: '' })

  const { values, setFieldValue } = useFormikContext<ConfigTypes>();
  const { etapaPessoa, etapa, etapaIdx, tipoPessoa } = useContext(EtapaContext);

  const [fields, setFields] = useState<Campo[]>([]);

  const loadFields = useCallback(() => {
    const campos = etapaPessoa[etapaIdx]?.campos;
    setFields(campos);
  }, [values.etapas, etapa]);

  useEffect(() => { if (etapa) loadFields(); }, [loadFields, etapa]);

  useEffect(() => { // inicialmente, sempre trazer valores já definidos
    if (etapaPessoa[etapaIdx]?.campos?.length != 0) return

    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [
      { name: `de-acordo-com-termos-condicoes`, label: `Declaro ter ciência das Condições e Termos de Uso.`, value: '', type: 'text', habilitar: true, obrigatorio: true },
      { name: `condicoes-termo`, label: `Condições e Termos de Uso`, value: '', type: 'text', habilitar: false, obrigatorio: false }
    ])

  }, [etapaPessoa[etapaIdx]]);

  function fileToB64(file: any) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
    });
  }

  return <>
    <ModalLoading open={loading.status} message={loading.message} />
    <div className="form-row">
      <div className="col-lg-6">
        <label>Texto Confirmação de Leitura</label>
        <TextArea className="col-lg-12" placeholder="Digite aqui.."
          autoSize={{ minRows: 1, maxRows: 6 }} value={fields[0]?.label}
          onChange={({ target: { value: value } }) => {
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[0]}.label`, value);
          }}
          onBlur={({ target: { value: value } }) => {
            if (value.trim() === '')
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[0]}.label`,
                'Declaro ter ciência das Condições e Termos de Uso.');
          }} />
      </div>
      <div className="col-lg-3 text-center">
        <label>Importar documento PDF</label>
        <br />
        <input id='importDoc' type='file' accept=".pdf" className='d-none'
          onChange={async (e: any) => {
            //setLoading({ status: true, message: 'Importando documento...' });
            try {
              const file = e.target.files[0];
              let base64 = await fileToB64(file);
              //base64 = `data:application/pdf;base64,${base64}`;
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.value`, base64);
              message.success('Documento importado com sucesso');

            } catch (error) {
              message.error('Erro ao importar documento');
              //console.log("error: ", error);
            } finally {
              e.target.value = '';
              //setLoading({ ...loading, status: false, });
            }
          }}
        />
        <Button type="primary" onClick={() => document.getElementById('importDoc')?.click()}>
          Importar
        </Button>
      </div>

      <div className="mx-auto my-auto">
        <Tooltip title="Limpar documento">
          <Button type="primary" className="mr-2"
            onClick={() => {
              try {
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.value`, '');
                message.success('Documento excluído com sucesso');
              } catch (error) {
                message.error('Erro ao excluir documento');
              }
            }}>
            <i className="fa fa-trash" />
          </Button>
        </Tooltip>
      </div>
    </div>
  </>
}