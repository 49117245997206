const primary = '#ffffff';
const secondary = '#ffffff';

const theme = {
  colors: {
    navy: secondary,
    secondary: primary,
    blue: '#1c84c6',
    lazur: '#23c6c8',
    yellow: '#f8ac59',
    red: '#ed5565',
    gray: '#f3f3f4',
    'custom-bg': '#f3f3f4',
    'dark-gray': '#c2c2c2',
    'text-color': '#676a6c',
    'light-gray': '#d1dade',
    'label-badge-color': '#5e5e5e',
    'light-blue': '#f3f6fb',
    'spin-color': '#f8ac59',
    'basic-link-color': '#337ab7',
    'border-color': '#e7eaec',
    'ibox-title-bg': '#ffffff',
    'ibox-content-bg': '#ffffff',
    'nav-bg': secondary,
    'nav-text-color': '#ffffff',
  },
  sizes: {
    'sidebar-width': '220px',
    'boxed-width': '1200px',
    'btn-border-radius': '3px',
    'spin-margin': '0 auto',
  },
};

export default theme;
