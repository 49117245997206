import { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Tag, Tooltip, Select, Modal, message } from 'antd';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import { GlobalContext } from '@contexts/GlobalContext';
import Card from '@components/Card';
import { SectionTitle, Option } from '@components/Form';
import ModalCred from '@components/Modal/ModalCred';
import { Bloco, Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { cnpjFormatter, cpfFormatter } from '@utils/textFormatter';
import { formaPagamentoOptions as f } from '@services/mock';

import api from '@services/api';
import Filter from './Filter';
import { val } from 'jodit/types/core/helpers';
import { string } from 'yup/lib/locale';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { act } from 'react-dom/test-utils';
import parse from 'html-react-parser'
import BillImg from '@assets/images/bill.png';
import { removeEmptyKeys } from '@utils/removeEmptyKeys';
import ModalLoading from '@components/Modal/ModalLoading';
import ModalPagamentoIugu from '@components/Modal/ModalPagamentoIugu';

type ColumnType = { title: string; dataIndex: string; key: string; };

const DEFAULT_COLUMNS_NAMES = ['data-solicitacao', 'codigo-proposta', 'status', 'usuario-logado', 'limite-concedido', 'limite-sugerido', 'pagamentos'];

function FollowUP() {
  const [invoices, setInvoices] = useState([]);
  const [invoiceSelected, setInvoiceSelected]: any = useState('about:blank');
  const [modalInvoice, setModalInvoice] = useState(false);

  const { configuracoes: c, instituicao, subdominio, pareceresInstituicao, setPareceresInstituicao } = useContext(GlobalContext);
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [propostas, setPropostas] = useState([]);
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [loading, setLoading] = useState({ status: false, message: '' })
  const [campos, setCampos] = useState<Campo[]>([]);
  const [modalCredVisible, setModalCredVisible] = useState(false);
  const [propostaSelecionada, setPropostaSelecionada] = useState('');
  const [defaultColumns, setDefaultColumns] = useState<any>([]);
  const [actionsColumns, setActionsColumns] = useState<any>([]);
  const [tipoPessoa, setTipoPessoa] = useState('');
  let tipoPessoaFollowUp: any = sessionStorage.getItem('tipoPessoaFollowUp')
  const history = useHistory();
  const [filter, setFilter] = useState({});

  const formik = useFormik({
    initialValues: {},
    onSubmit: (data: any) => {
    },
  });

  const { setFieldValue, setValues } = formik;

  const gerarRevisao = (codProposta: string) => {
    history.push(
      subdominio
        ? `/${subdominio}/cadastrar/${codProposta}`
        : `/cadastrar/${codProposta}`,
    );
  };

  const fieldFormatter = (column: string, field: string) => {
    switch (column) {
      case 'cpf':
      case 'cpf-conjuge':
      case 'socio-cpf':
        return cpfFormatter(field);
      case 'cnpj':
      case 'cnpj-pf':
        return cnpjFormatter(field);
      default:
        return field;
    }
  };

  const resetFollowUp = (v: string, defaults: any, actions: any) => {
    document.getElementById('atualizar')?.click();
  };

  useEffect(() => {
    if (tipoPessoa !== '')
      filterFollowUp();
  }, [filter])

  const filterFollowUp = () => {
    if (instituicao) {
      setLoadingGrid(true);
      api.post(`followUp`, removeEmptyKeys(filter), {
        params: {
          instituicao,
          quantidade: 10000,
          pagina: 1,
          tipo: tipoPessoa
        },
      })
        .then(async (res) => {
          res.data = await removeEmptyKeys(res.data)

          let statusHabPagamento = JSON.parse(c.etapas?.[`${tipoPessoaFollowUp}EtapasFinais`]?.[0]?.campos?.[0]?.value || '[]')
          if (statusHabPagamento)
            res.data.forEach((p: any) => {
              if (statusHabPagamento.includes(parseInt(p['status-pg']))) // se status da proposta estiver habilitado na etapa final pagamentos
                if (['3', '10'].includes(p['forma-pagamento-pg'])) // se for boleto ou cartão
                  p.pagamentos = { codigoProposta: p['codigo-proposta-pg'], formaPagamento: p['forma-pagamento-pg'] }
            })

          if (res.data.length > 0) {
            const filteredColumns = Object.keys(res.data[0])
              .filter((c) => c !== 'key')
              .filter((c) => !DEFAULT_COLUMNS_NAMES.includes(c))
              .map((column) => ({
                title: campos.find((campo: Campo) => campo.name === column)?.label || column,
                dataIndex: column,
                key: column,
                render: (field: string) => fieldFormatter(column, field),
              }));

            let colunasDefaults: any = []
            await defaultColumns.forEach((campo: any) => {
              if (c?.followUp[tipoPessoaFollowUp]?.colunasGrid?.includes(campo.key))
                colunasDefaults.push(campo)
            })

            let colunasOrdenadas: any = []
            await c?.followUp[tipoPessoaFollowUp]?.colunasGrid?.forEach((campo: any) => {
              [...colunasDefaults, ...filteredColumns].forEach((c: any) => {
                if (c.key === campo) colunasOrdenadas.push(c)
              })
            })

            setColumns([...colunasOrdenadas, ...actionsColumns]);

            res.data.forEach((proposta: any) => {
              Object.keys(proposta).forEach((key) => {
                if (proposta[key]?.['0'] == '$')
                  proposta[key] = <div className='text-right'>{'R$ ' + proposta[key].replace(/[^0-9.]/g, "").slice(0, -1).replace('.', ',')}</div>

                if (proposta[key] == 'N/I') proposta[key] = ''
              });
            });

            setPropostas(res.data);

          } else {
            setPropostas([]);
          }

          setLoadingGrid(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          setLoadingGrid(false);
        });
    }
  };

  const resetFilter = (followUp: any, etapas: any, tipo: string) => {
    const todosCampos: Campo[] = [];

    etapas?.[tipo || tipoPessoa]?.forEach((bloco: Bloco) =>
      bloco.campos.forEach((campo: Campo) => {
        todosCampos.push(campo);
      }),
    );

    setCampos(todosCampos);

    const filterCampos: any = followUp[tipo]?.camposFiltro.map((campo: any) => {
      return { name: campo, label: todosCampos.filter((c: Campo) => c.name === campo)[0]?.label }
    });

    setFilterOptions(filterCampos);
  }

  const renderTable = (tipo: string, cb: any) => {
    if (c && tipo !== '') {
      const { followUp, etapas } = c;
      //resetFilter(followUp, etapas, tipoPessoa);
      const DEFAULT_COLUMNS = [
        { title: 'Data de solicitação', dataIndex: 'data-solicitacao', key: 'data-solicitacao', },
        { title: 'Usuário Envio', dataIndex: 'usuario-logado', key: 'usuario-logado', },
        { title: 'Limite concedido', dataIndex: 'limite-concedido', key: 'limite-concedido', },
        { title: 'Limite sugerido', dataIndex: 'limite-sugerido', key: 'limite-sugerido', },
        {
          title: 'Código da proposta', dataIndex: 'codigo-proposta', key: 'codigo-proposta',
          render: (codProposta: string) =>
            followUp[tipo]?.analisarProposta &&
              c && c.acessoUser &&
              c.acessoUser.verFollowUp.status &&
              c.acessoUser.verFollowUp.labelAprovar
              ?
              <Tooltip title="Analisar proposta" placement="right">
                <Button
                  type="link"
                  onClick={() => {
                    setPropostaSelecionada(codProposta);
                    setModalCredVisible(true);
                  }}
                >
                  {codProposta}
                </Button>
              </Tooltip>

              : followUp[tipo]?.exibirResultado ?
                <Tooltip title="Exibir resultado" placement="right">
                  <Button type="link"
                    onClick={async () => {
                      await document.querySelectorAll('.ant-btn-link').forEach((el: any) => el.setAttribute('disabled', 'true'))
                      try {
                        const { data: d }: any = await api.get(`Proposta/ObterParecer?guidInstituicao=${instituicao}&codigoProposta=${codProposta}`)
                        Modal.info({
                          width: 1000,
                          centered: true,
                          content: <>
                            <style>{`.anticon-info-circle { display: none; }`}</style>
                            <div>{d.mensagemParecer ? parse(d.mensagemParecer) :
                              <div className="form-row">
                                <style>
                                  {`.centerImgMsgRecusa {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                  }`}
                                </style>
                                <div className="col-lg-12 centerImgMsgRecusa">
                                  <img src={BillImg} className="rejectImg" alt="Proposta enviada com sucesso" width={200} height={200} />
                                  <h2 className="msgRecusa ml-auto mr-auto text-center">
                                    <b>Proposta enviada com sucesso!</b>
                                    <br />
                                    <br />
                                    <b>Status da sua Proposta:</b>
                                    <br />
                                    <b>{d.statusDescricao}</b>
                                  </h2>
                                </div>
                              </div>
                            }</div>
                          </>,
                        });
                      } catch (error) {
                        console.log("error: ", error);
                        message.error('Erro ao obter Status da proposta');
                      } finally {
                        await document.querySelectorAll('.ant-btn-link').forEach((el: any) => el.removeAttribute('disabled'))
                      }
                    }}>
                    {codProposta}
                  </Button>
                </Tooltip>

                : codProposta
        },
        {
          title: 'Status', dataIndex: 'status', key: 'status',
          render: (status: string) => (
            <Tag key={status} color="geekblue">{status}</Tag>
          )
        },
        {
          title: 'Pagamentos', dataIndex: 'pagamentos', key: 'pagamentos',
          render: (obj: any) => {
            if (obj) // se houver faturas, exibir:
              return <i className="fa fa-print" style={{ cursor: 'pointer', fontSize: '20px' }}
                onClick={async () => {
                  try {
                    setLoadingGrid(true);
                    setLoading({ status: true, message: 'Carregando faturas...' });
                    let { data: obterFaturas }: any = await api.get(`Faturamento/ObterFaturasPorCodigoProposta?codigoProposta=${obj.codigoProposta}`);
                    if (obterFaturas.length > 0) {
                      setInvoices(obterFaturas);
                      setModalInvoice(true);

                    } else // se não houver faturas, gerar:
                      if (c.etapas[`${tipoPessoaFollowUp}EtapasFinais`]?.[0]?.habilitar)
                        switch (c.etapas[`${tipoPessoaFollowUp}EtapasFinais`]?.[0]?.integracao) {
                          case 1: // IUGU
                            setLoading({ status: true, message: 'Gerando faturas...' });
                            let { data: gerarFatura }: any = await api.post(`Proposta/GerarFaturaIugu?guid=${sessionStorage.getItem('b2e:instituicao')}&codigoProposta=${obj.codigoProposta}&formasPagamento=${f.find((f: any) => f.id == obj.formaPagamento)?.ref || ''}`);

                            if (gerarFatura.length == 0)
                              throw new Error();

                            setInvoices(gerarFatura);
                            setModalInvoice(true);
                            break;
                        }

                  } catch (error) {
                    console.log("error: ", error);
                    message.error('Não foram encontradas faturas para essa proposta');
                  } finally {
                    setLoadingGrid(false);
                    setLoading({ ...loading, status: false, });
                  }
                }}
              />
          }
        }];

      const ACTIONS_COLUMNS = [];

      if (followUp[tipo]?.revisao?.habilitar)
        (c?.acessoUser?.verFollowUp?.status &&
          c?.acessoUser?.verFollowUp?.gerarRevisao) &&
          ACTIONS_COLUMNS.push({
            title: '', dataIndex: 'revisao', key: 'revisao', width: 120,
            render: (_: any, row: any) => c?.followUp[tipo]?.revisao?.idStatus?.includes(row.status,) &&
              <Button type="link" onClick={() => gerarRevisao(row['codigo-proposta'])}>
                {c?.followUp[tipo]?.revisao?.nome}
              </Button>
          });

      if (followUp[tipo]?.redirect?.habilitar)
        (c?.acessoUser?.verFollowUp?.status &&
          c?.acessoUser?.verFollowUp?.labelRedirect) &&
          ACTIONS_COLUMNS.push({
            title: '', dataIndex: 'redirect', key: 'redirect', width: 120,
            render: (_: any, row: any) => c?.followUp[tipo]?.redirect?.idStatus?.includes(row.status) &&
              <a style={{ textTransform: 'none', color: c?.interface?.corPrimaria }}
                href={c?.followUp[tipo]?.redirect?.urlRota} target="_blank">
                {c?.followUp[tipo]?.redirect?.nome}
              </a>
          });

      setDefaultColumns(DEFAULT_COLUMNS);
      setActionsColumns(ACTIONS_COLUMNS);
      cb(DEFAULT_COLUMNS, ACTIONS_COLUMNS);
    }
  };

  const handleChangeTipoPessoa = (value: string) => {
    Promise.resolve(setTipoPessoa(value))
      .then(() => {
        renderTable(value, (defaults: any, actions: any) => {
          const { followUp, etapas } = c;
          resetFilter(followUp, etapas, value);
          resetFollowUp(value, defaults, actions)
          sessionStorage.setItem('tipoPessoaFollowUp', value);
        })
      });
  };

  /* Se houver só 1 proposta (pf, pj) clicar nela, auto. */
  useEffect(() => {
    if (c?.followUp?.exibirMenu == 'pj') handleChangeTipoPessoa('pj')
    if (c?.followUp?.exibirMenu == 'pf') handleChangeTipoPessoa('pf')
  }, [c]);

  return <>
    <ModalLoading open={loading.status} message={loading.message} />
    <Card>
      <div className="form-row">
        <div className="col-lg-12">
          <SectionTitle title={`Follow UP
          ${c?.followUp?.exibirMenu == 'pj' ? '(Pessoa Jurídica)' : ''}
          ${c?.followUp?.exibirMenu == 'pf' ? '(Pessoa Física)' : ''}
          `} />
        </div>
      </div>
      {['pfpj', 'pjpf'].includes(c?.followUp?.exibirMenu) && <>
        <div>
          <FormikProvider value={formik}>
            <Form>
              <div className='mb-1'>Selecione o tipo de onboarding</div>
              <div className="select-container">
                <Select value={tipoPessoa} className="col-md-4"
                  showSearch optionFilterProp="children"
                  placeholder="Pessoa Física ou Jurídica"
                  onChange={handleChangeTipoPessoa}>
                  <Option value="pf" id="pf">Pessoa Física</Option>
                  <Option value="pj" id="pj">Pessoa Jurídica</Option>
                </Select>
              </div>
            </Form>
          </FormikProvider>
        </div>
        <br></br>
      </>}

      {tipoPessoa !== '' && c.followUp[tipoPessoa] &&
        (<div><Filter
          filterOptions={filterOptions}
          resetFollowUp={() => resetFollowUp(tipoPessoa, defaultColumns, actionsColumns)}
          filter={filter}
          setFilter={setFilter}
        />
          <div className="row">
            <div className="col-lg-12">
              <Table bordered={true}
                loading={loadingGrid}
                dataSource={propostas}
                columns={columns}
                size="small"
                tableLayout="auto"
                scroll={{ x: 'max-content' }}
              />
            </div>
          </div>

          <ModalCred
            open={modalCredVisible}
            propostaSelecionada={propostaSelecionada}
            destroyOnClose
            onCancel={() => setModalCredVisible(false)}
            onOk={() => setModalCredVisible(false)}
            footer={null}
          />

          <ModalPagamentoIugu invoices={invoices} modalInvoice={modalInvoice} setModalInvoice={setModalInvoice} />
        </div>)}

    </Card>
  </>
}

export default FollowUP;
