import { css } from 'styled-components';

export const elements = css`
  .input-group-addon {
    background-color: #fff;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 9px 12px 4px 12px;
    text-align: center;
  }

  /* PEACE */

  .pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .pace-inactive {
    display: none;
  }

  .pace .pace-progress {
    background: ${(props) => props.theme.colors.navy};
    position: fixed;
    z-index: 2040;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
  }

  .pace-inactive {
    display: none;
  }
`;
