import { FC } from 'react';
import { useField } from 'formik';
import { Select as AntdSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import parse from 'html-react-parser'

import './styles.scss';

interface SelectComponentProps extends SelectProps<'children' | 'options'> {
  name: string;
  label: string;
}

export const { Option } = AntdSelect;

const Select: FC<SelectComponentProps> = ({
  label,
  name,
  children,
  ...rest
}) => {
  const [field, meta] = useField(name);

  return (
    <div className="select-wrapper">
      {label.includes('required-field') ? parse(label) : <label htmlFor={name}>{parse(label)}</label>}
      <AntdSelect {...field} {...rest}>
        {children}
      </AntdSelect>
      {meta.touched && meta.error ? (
        <span className="error">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default Select;
