import { GlobalContext } from '@contexts/GlobalContext';
import { FC, useEffect, useContext } from 'react';

const Wrapper = ({ children }: { children: React.ReactNode }) => {

  const { setSubdominio, configuracoes } = useContext(GlobalContext);
  //console.log("configuracoes2: ", configuracoes);

  useEffect(() => {
    if (configuracoes)
      document.body.style.backgroundColor = configuracoes.interface.corBackground
  }, [configuracoes])

  return (
    <div className="bg-white">
      {children}
    </div>
  );
};

export default Wrapper;
