export const integracoes = [
  {
    id: 0,
    nome: 'Biometria',
  },
  /* {
    id: 1, // não usar esse id
    nome: 'Upload',
  }, */
  {
    id: 2,
    nome: 'Documentoscopia PRO',
  },
];
