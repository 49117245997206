export const initCap = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeAllWords = (string: string) => {
  if (!string) return '';

  const words = string.split(" ");
  const capitalizedWords = words.map(word => {
    if (['cep', 'cpf', 'cnpj', 'rg', 'cnh', 'cic'].includes(word)) return word.toUpperCase();
    else return initCap(word)
  });
  return capitalizedWords.join(" ");
}