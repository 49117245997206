/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CardLogin from './CardLogin';
import CardRedefinePass from './CardRedefinePass';
import logoMin from '../../assets/images/b2e-default.png';
import { GlobalContext } from '@contexts/GlobalContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Await from '@components/Spinner/Await';
import * as S from './styles';

export default function () {
  const { pathname: url, href } = window.location;
  const params = useParams<{ subdominio: string }>();
  const [redefinePass, setRedefinePass] = useState(false);
  const { logo, loading, configuracoes, subdominio, setSubdominio } = useContext(GlobalContext);

  const handleRedefinePass = () => {
    setRedefinePass(!redefinePass);
  };

  // useEffect(() => {
  //   sessionStorage.removeItem('b2e:instituicao');
  //   localStorage.removeItem('token');
  // }, []);
  useEffect(() => {
    //sessionStorage.removeItem('b2e:instituicao');
    localStorage.removeItem('token');
    if (params?.subdominio) {
      ////console.log('setSubdominio');
      setSubdominio(params?.subdominio);
    } else {
      setSubdominio('');
    }
  }, []);

  return (<>
    {params?.subdominio && !configuracoes ?
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Await />
      </div>
      :
      <S.Container>
        <div className="row">
          <div className="col-lg-6">
            <div className="logo">
              <img src={logo || logoMin} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="wrapper-card-login">
              {redefinePass || href.includes('?rec=') ?
                <CardRedefinePass handleRedefinePass={handleRedefinePass} /> :
                <GoogleReCaptchaProvider reCaptchaKey={window.origin.includes('b2egroup') ? '6LdidjonAAAAADHpcVuVRHpMFtjfwDeD1ZAuwkQv' : '6LdgI4QmAAAAAAvutQBWLkCT26QmuL0ZJmEydq7D'}>
                  <CardLogin handleRedefinePass={handleRedefinePass} />
                </GoogleReCaptchaProvider>}
            </div>
          </div>
        </div>
      </S.Container>
    }
  </>)
}
