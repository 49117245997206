import { FC } from 'react';
import { useField } from 'formik';
import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd';
import parse from 'html-react-parser'

import './styles.scss';
import moment from 'moment';

type Props = {
  name: string;
  label: string;
  type?: string;
};

export const defaultDateFormats = ['DD/MM/YYYY', 'DDMMYYYY'];
export const defaultMonthFormats = ['MM/YYYY', 'MMYYYY'];

const DatePicker: FC<Props & DatePickerProps> = ({ label, name, type, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <div className="datepicker-wrapper">
      {label.includes('required-field') ? parse(label) : <label htmlFor={name}>{parse(label)}</label>}
      {['data-de-faturamento'].includes(name) ?
        <AntdDatePicker format={defaultDateFormats} {...field} {...rest} placeholder="11/11/1111"
          disabledDate={current => current && current < moment().startOf('day')} /> /* datas a partir de hj */

        : ['data-faturamento-', 'desde-referencia-', 'avalista-desde', 'socio-desde'].includes(`${name}`.replace(/[0-9]/g, '')) ?
          <AntdDatePicker format={defaultMonthFormats} {...field} {...rest} picker="month" placeholder="11/1111"
            disabledDate={current => current && current > moment().endOf('day')} /> /* datas até hj */

          : (type !== null && type === 'dateFromToday') ?
            <AntdDatePicker format={defaultDateFormats} {...field} {...rest} placeholder="11/11/1111" />

            : <AntdDatePicker format={defaultDateFormats} {...field} {...rest} placeholder="11/11/1111"
              disabledDate={current => current && current > moment().endOf('day')} /> /* datas até hj */
      }
      {meta.touched && meta.error ? (
        <span className="error">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default DatePicker;