import { Bloco as BlocoType, Campo } from '@pages/Configuracao/ConfigTypes';

import { SectionTitle, Switch, } from '@components/Form';
import Icon from '@components/Icon';
import BioImg from '@assets/images/scan-facial.png';
import DocImg from '@assets/images/doc.png';
import OkImg from '@assets/images/check-circle.png';

import LabelRequired from '@components/Form/LabelRequired';
import Biometria from './Biometria';
import parse from 'html-react-parser'
import renderFields from './renderFields';
import UploadDinamico from './UploadDinamico';
import { Button, DatePicker, Select, message, Modal, InputNumber, Tooltip, Input as AntdInput, Switch as AntdSwitch } from 'antd';
import { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '@contexts/GlobalContext';
import { ufs } from './ufs';
import { profissaoOptions as p, naturezaOcupacaoOptions as o, formaPagamentoOptions as f, modalidadeOptions as m } from '@services/mock'
import moment from 'moment';
import InputMask from "react-input-mask";
import axios from 'axios';
import VerificationInput from "react-verification-input";

import api from '@services/api';
import ModalLoading from '@components/Modal/ModalLoading';
import { useFormikContext } from 'formik';
import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';

import CurrencyInput from 'react-currency-input-field';
import ReactIntlCurrencyInput from 'react-intl-currency-input';
import { currencyConfig } from './currencyConfig';
import { useLocation } from 'react-router-dom';

import SucessImg from '@assets/images/accept.png'
import Countdown from 'antd/lib/statistic/Countdown';
import { validateBr } from 'js-brasil';
import { CheckOutlined, MinusOutlined, PlusOutlined, CameraOutlined, UploadOutlined, DownOutlined } from '@ant-design/icons';

import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import mt from 'mime-types';
import { removeEmptyKeys } from '@utils/removeEmptyKeys';
import { compareDates } from '@utils/compareDates';
import { EtapaContext } from '@contexts/EtapaContext';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer: React.FC<{ fileUrl: string }> = ({ fileUrl }) =>
  <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
    <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPlugin()]} />
  </Worker>

const { Option } = Select;

type BlocoProps = {
  bloco: BlocoType;
};

const LabelRequiredTemplate = (label: any = '', id: any = '') =>
  parse(`<label ${id ? `id=${id}` : ''}>
    <span className='required-field'>
      <i>*</i>${label}</span>
  </label>`)

const DivRequiredTemplate:
  React.FC<{ label?: string; id?: string; content?: React.ReactNode; }> =
  ({ label = '', id = '', content = '' }) =>
    <div className='pb-1'>
      {content}
      <span className='required-field'>
        <i>*</i>{label}</span>
    </div>


function Bloco({ bloco }: BlocoProps) {
  const { pathname: url } = window.location;
  const allParams = useLocation();

  const { values, setFieldValue } = useFormikContext<ConfigTypes>();
  const { configuracoes: c, nacionalidades: n, estadosCivil: e,
    comprovantes: com, naturezasOcupacao: o, profissoes: p, sexos: s
  } = useContext(GlobalContext);
  const { tipoPessoa, etapaPessoa, etapa, etapaIdx } = useContext(EtapaContext);

  const [loading, setLoading] = useState({ status: false, message: '' })
  const [modalOpen, setModalOpen] = useState(false)



  /* states CodigoVerif */
  const [tipoEnvio, setTipoEnvio] = useState(0)

  const [codEmailEnviado, setCodEmailEnviado] = useState(false)
  const [codSMSEnviado, setCodSMSEnviado] = useState(false)

  const [codEmailValidado, setCodEmailValidado] = useState(false)
  const [codSMSValidado, setCodSMSValidado] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem('nestedDataCodVerif')) {
      let nestedDataCodVerif = JSON.parse(sessionStorage.getItem('nestedDataCodVerif') || '{}')

      sessionStorage.setItem('b2e:smsCodigoVerif', nestedDataCodVerif['fone-celular'] || '')
      sessionStorage.setItem('b2e:emailCodigoVerif', nestedDataCodVerif['email'] || '')

      sessionStorage.removeItem('nestedDataCodVerif')
    }
  }, [sessionStorage.getItem('nestedDataCodVerif')])



  /* states Parcelamento */
  const [parcelasList, setParcelasList] = useState([]);
  const [parcelasInfo, setParcelasInfo]: any = useState({});
  const [vlrSolicitado, setVlrSolicitado] = useState(0);

  const SelectParcelamentoB2e = (idLabel: string, disabled: boolean = false, valueLabel: string) =>
    <button type="button" style={{ border: '1px solid #D0C4C3' }} data-toggle={disabled ? '' : 'dropdown'}
      className={'w-100 p-1 ' + (disabled ? 'bg-gray cursor-not-allowed text-light-gray' : 'bg-white')}>
      <label id={idLabel} className='m-0 float-left pl-2'>{valueLabel}</label>
      <DownOutlined className='float-right pr-2 mt-1 text-light-gray' />
    </button>

  function calcDiasAcrescimoBMP(value: number) {
    let dataHoje = new Date();
    let dia = dataHoje.getDate();
    let diasAcrescimo: number;
    let nmrDiasMesSeguinte: number = new Date(dataHoje.getFullYear(), dataHoje.getMonth() + 2, 0).getDate();
    diasAcrescimo = (dia > value) ? nmrDiasMesSeguinte - (dia - value) : value - dia;
    return diasAcrescimo;
  }

  function getDataPrimeiroVencimentoBMP(option: number) {
    const diasAcresc = calcDiasAcrescimoBMP(option);
    let date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() + diasAcresc);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function getValorPreDefinido() {
    let valorSolicitadoInicial = parseFloat(sessionStorage.getItem('b2e:valorSolicitadoInicial') || '0');
    let productsTotalValue = parseFloat(sessionStorage.getItem('productsTotalValue') || '0');

    const valorPreDefinido = productsTotalValue || valorSolicitadoInicial || 0;
    const valorSolicDisabled = document.querySelector('#valorSolicDisabled');
    if (valorSolicDisabled) valorSolicDisabled.innerHTML = `R$ ${valorPreDefinido.toFixed(2)}`.replace('.', ',');

    return valorPreDefinido;
  }

  async function simularParcela() {
    setLoading({ status: true, message: 'Simulando parcelas...' });

    try {
      //if (await validateVlrSolicitado())
      let listParcelas: any = [];
      let v = getValorPreDefinido() || sessionStorage.getItem('b2e:vlrSolicitado');

      let { data: { parcelas, informacoesParcelamento } }: any = await api.post(`Proposta/SimularParcelaB2e?valorSolicitado=${v}&tipoPessoa=${sessionStorage.getItem('b2e:tipoPessoa')}&guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}&dtPrimeiroVencimento=${sessionStorage.getItem('b2e:dtPrimeiroVencimento')}`,
        { ...JSON.parse(sessionStorage.getItem('b2e:varsParcelaB2e') || '{}') });

      parcelas.map((parcela: any) => { listParcelas.push({ ...parcela, ...informacoesParcelamento }) })

      setParcelasList(listParcelas);
      setParcelasInfo(informacoesParcelamento);

      // resetar escolhas de parcelamento
      handleResetQtdParcelas();
    } catch (e) {
      console.log(e)
      message.error('Erro ao simular parcelas, tente novamente');
      handleResetParcelamentoB2e();
    } finally {
      setLoading({ ...loading, status: false, });
    }
  }

  async function validateVlrSolicitado(campo: any = null) {
    /* validações valor solicitado: */
    const vlrFinal = vlrSolicitado || getValorPreDefinido()
    if (campo != null)
      if (vlrFinal == 0) {
        message.info('Informe um valor para simular')
        return false;

      } else if (vlrFinal < parseFloat(campo?.value ?? '0')) {
        message.error(`Valor Solicitado menor que o mínimo permitido de R$ ${parseFloat(campo.value || '0').toFixed(2)}`.replace('.', ','));
        return false;

      } else if (vlrFinal > parseFloat(campo?.label ?? '0')) {
        message.error(`Valor Solicitado maior que o máximo permitido de R$ ${parseFloat(campo.label || '0').toFixed(2)}`.replace('.', ','));
        return false;
      }

    sessionStorage.setItem('b2e:vlrSolicitado', `${vlrFinal}`);
    return true;
  }

  useEffect(() => {
    const productsSelected = sessionStorage.getItem('productsSelected') || false;

    if (productsSelected != '[]')
      setVlrSolicitado(getValorPreDefinido())
    else // se não houver produtos, zerar valor solicitado
      setTimeout(() => { setVlrSolicitado(0); }, 500);
  }, [values, tipoPessoa, etapaPessoa, etapa, etapaIdx])



  /* states tabs Referências Comerciais */
  const [showTabRefC, setShowTabRefC] = useState(1);
  const [totalTabRefC, setTotalTabRefC] = useState(1);
  const addTabRefC = () => {
    if (totalTabRefC <= 2) {
      setShowTabRefC(totalTabRefC + 1)
      setTotalTabRefC(totalTabRefC + 1)
    }
  }
  const delTabRefC = async () => {
    //await setShowTabRefC(1) /* click() faz este papel */
    await setTotalTabRefC(1)
    await document.getElementById(`tab1RefC`)?.click()
  }
  useEffect(() => { // motivo: durante post enviar só abas presentes no DOM
    sessionStorage.setItem('totalTabRefC', totalTabRefC.toString())
  }, [totalTabRefC])




  /* states tabs Referências Bancária */
  const [showTabRefB, setShowTabRefB] = useState(1);
  const [totalTabRefB, setTotalTabRefB] = useState(1);
  const addTabRefB = () => {
    if (totalTabRefB <= 2) {
      setShowTabRefB(totalTabRefB + 1)
      setTotalTabRefB(totalTabRefB + 1)
    }
  }
  const delTabRefB = async () => {
    //await setShowTabRefB(1) /* click() faz este papel */
    await setTotalTabRefB(1)
    await document.getElementById(`tab1RefB`)?.click()
  }
  useEffect(() => { // motivo: durante post enviar só abas presentes no DOM
    sessionStorage.setItem('totalTabRefB', totalTabRefB.toString())
  }, [totalTabRefB])




  /* states etapa de produtos */
  // cupom:
  const [cupom, setCupom] = useState('')

  const aplicarCupom = async (valorBase = 0) => {
    let valorDesconto = parseFloat(sessionStorage.getItem('b2e:valorDesconto') || '0');
    let idCupomTipo = sessionStorage.getItem('b2e:idCupomTipo') || '';
    let valorDescontado = valorBase <= 0 ? 0 : // se valorBase for 0, valorDescontado também será 0
      valorBase - (idCupomTipo == '2' ? valorDesconto : valorBase * (valorDesconto / 100));

    setTotalPrice(valorDescontado)

    let valorSolicitadoInicial = parseFloat(sessionStorage.getItem('b2e:valorSolicitadoInicial') || '0');
    let productsTotalValue = parseFloat(sessionStorage.getItem('productsTotalValue') || '0');

    if (valorSolicitadoInicial)
      sessionStorage.setItem('b2e:valorSolicitadoInicial', `${valorDescontado}`);
    if (productsTotalValue)
      sessionStorage.setItem('productsTotalValue', `${valorDescontado}`);

    //document.getElementById('totalOriginal')?.setAttribute('style', 'text-decoration: line-through;')
    handleResetParcelamentoB2e()
  }

  const desaplicarCupom = () => {
    let valorBase = parseFloat(sessionStorage.getItem('b2e:valorBase') || '0');

    // repassando valores para variáveis auxiliares:
    setTotalPrice(valorBase)
    let valorSolicitadoInicial = parseFloat(sessionStorage.getItem('b2e:valorSolicitadoInicial') || '0');
    let productsTotalValue = parseFloat(sessionStorage.getItem('productsTotalValue') || '0');

    if (valorSolicitadoInicial)
      sessionStorage.setItem('b2e:valorSolicitadoInicial', `${valorBase}`);
    if (productsTotalValue)
      sessionStorage.setItem('productsTotalValue', `${valorBase}`);

    ['b2e:idCupomTipo', 'b2e:valorDesconto', 'b2e:cpfCupomPreValidado']
      .map((s: any) => sessionStorage.removeItem(s))
  }

  //produto:
  const [products, setProducts] = useState([])

  const [productQtd, setProductQtd] = useState<{ [key: number]: number }>(
    products?.reduce((quantityObj: any, product: any) => {
      quantityObj[product?.id] = product?.quantity;
      return quantityObj;
    }, {})
  );

  const [activeSlide, setActiveSlide] = useState(0);

  const handleQtdChange = async (productId: number, value: number | undefined) => {

    const updatedQtd = {
      ...productQtd,
      [productId]: value || 0
    };
    setProductQtd(updatedQtd);

  };

  const handleResetDiaVenc = async () => {
    const optionDiaVencDefault = document.querySelector('#optionDiaVenc h4'); // @ts-ignore
    if (optionDiaVencDefault) await optionDiaVencDefault.click();

    ['selectDiaVenc', 'b2e:Dia-de-Vencimento', 'b2e:dtPrimeiroVencimento']
      .map((s: any) => sessionStorage.removeItem(s))
  }

  const handleResetQtdParcelas = async () => {
    const optionQtdParcelasDefault = document.querySelector('#optionQtdParcelas h4'); // @ts-ignore
    if (optionQtdParcelasDefault) await optionQtdParcelasDefault.click();

    ['b2e:Quantidade-de-Parcelas', 'parcelaEscolhida']
      .map((s: any) => sessionStorage.removeItem(s))
  }

  const handleResetParcelamentoB2e = (prevProductQtd: any = true, updatedQtd: any = false) => {
    if (JSON.stringify(prevProductQtd) != JSON.stringify(updatedQtd)) {
      handleResetDiaVenc();
      handleResetQtdParcelas();
    }
  }

  const handleQtdDecrease = (product: any) => {
    const prevProductQtd = productQtd

    let { id: productId }: any = product;

    const currentQtd = productQtd[productId] || 0;
    const updatedQtd = {
      ...productQtd,
      [productId]: currentQtd - 1
    };

    /* if (updatedQtd[productId] <= 0)
      delete updatedQtd[productId]; */

    setProductQtd(updatedQtd);

    handleResetParcelamentoB2e(prevProductQtd, updatedQtd);
  };

  const handleQtdIncrease = (product: any) => {
    const prevProductQtd = productQtd

    let { id: productId, maxSelection: max }: any = product;

    const currentQtd = productQtd[productId] || 0;
    if (currentQtd >= max)
      return message.error(`Limite de ${max} unidade${max > 1 ? 's' : ''} neste produto`);

    const updatedQtd = {
      ...productQtd, [productId]: currentQtd + 1
    };
    setProductQtd(updatedQtd);

    handleResetParcelamentoB2e(prevProductQtd, updatedQtd);
  };

  const groupSize = 4;
  const groupedProducts: any = [];

  for (let i = 0; i < products?.length; i += groupSize) {
    groupedProducts?.push(products?.slice(i, i + groupSize));
  }

  const handlePrevSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide > 0 ? prevSlide - 1 : groupedProducts?.length - 1));
  };

  const handleNextSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide < groupedProducts?.length - 1 ? prevSlide + 1 : 0));
  };

  const [totalValue, setTotalPrice] = useState(0);

  useEffect(() => {
    // handle total price:
    const tt = products?.reduce((total: any, product: any) => {
      return total + parseFloat(product?.price) * (productQtd[product?.id] || 0);
    }, 0);

    setTotalPrice(tt);
    setFieldValue('valor-solicitado', `${tt?.toFixed(2)}`)
    sessionStorage?.setItem('productsTotalValue', `${tt?.toFixed(2)}`)

    // handle selected products:
    const productsSelected: any = []
    products?.map((product: any) => {
      if (productQtd[product?.id] > 0)
        productsSelected?.push({ id: product?.id, quantidade: productQtd[product?.id], preco: product?.price, nome: product?.name, })
    });

    sessionStorage?.setItem('productsSelected', JSON.stringify(productsSelected))

    if (sessionStorage.getItem('b2e:cpfCupomPreValidado')) aplicarCupom(tt)

    sessionStorage.setItem('b2e:valorBase', `${tt?.toFixed(2)}`)
  }, [productQtd]);

  const [paramsProducts, setParamsProducts] = useState(Object.fromEntries(new URLSearchParams(window?.location?.search))) // 1=10&2=10

  useEffect(() => {
    // merge paramsProducts with productQtd:
    const productQtdUpdated: any = { ...productQtd }
    Object.keys(paramsProducts)?.map((key: any) => {
      productQtdUpdated[key] = parseFloat(paramsProducts[key])
    })
    setProductQtd(productQtdUpdated)
  }, [])

  useEffect(() => {
    if (products?.length === 1)
      setTimeout(() => { document.getElementById('+')?.click() }, 500)
  }, [products])



  /* states tabs Dados Comerciais (Faturamentos) */
  const [showTabFats, setShowTabFats] = useState(1);
  const [totalTabFats, setTotalTabFats] = useState(1);
  const addTabFats = () => {
    if (totalTabFats <= 6) {
      setShowTabFats(totalTabFats + 1)
      setTotalTabFats(totalTabFats + 1)
    }
  }
  const delTabFats = async () => {
    await setTotalTabFats(totalTabFats - 1) // sempre irá para aba antecessora
    if (totalTabFats == 2) return document.getElementById(`tabFats1`)?.click()
    document.querySelector('.tabFats :last-child')?.parentElement?.click()
  }
  useEffect(() => { // motivo: durante post enviar só abas presentes no DOM
    sessionStorage.setItem('totalTabFats', totalTabFats.toString())
  }, [totalTabFats])




  /* states tabs Referências Bancária */
  const [showTabRefP, setShowTabRefP] = useState(1);
  const [totalTabRefP, setTotalTabRefP] = useState(1);
  const addTabRefP = () => {
    if (totalTabRefP <= 2) {
      setShowTabRefP(totalTabRefP + 1)
      setTotalTabRefP(totalTabRefP + 1)
    }
  }
  const delTabRefP = async () => {
    //await setShowTabRefP(1) /* click() faz este papel */
    await setTotalTabRefP(1)
    await document.getElementById(`tab1RefP`)?.click()
  }
  useEffect(() => { // motivo: durante post enviar só abas presentes no DOM
    sessionStorage.setItem('totalTabRefP', totalTabRefP.toString())
  }, [totalTabRefP])





  /* states tabs Socio */
  const [tabSocio, setTabSocio]: any = useState(0);
  const [allSocios, setAllSocios]: any = useState([]);

  const addTabSocio = async () => {
    // fazendo carga de dados de sócios após + ser clicado
    const storedData = JSON.parse(sessionStorage.getItem('nestedDataSocios') || '{}');
    if (storedData != '{}')
      setTimeout(() => {
        sessionStorage.removeItem('nestedDataSocios');

        if (storedData?.socios?.length > 0) {
          const nestedSocios = storedData.socios.map((s: any) => {
            return bloco.campos.map((campo: any) => {
              if (campo.habilitar)
                return { ...campo, value: s[campo.name] };
            });
          });
          setTabSocio(nestedSocios.length - 1);
          setAllSocios([...nestedSocios]);
        }
      }, 500);


    const mockSocio: any = bloco.campos
      .filter((campo: Campo) => { return campo.habilitar })
      .map((campo: Campo) => { return { ...campo, value: '' } })

    if (url.includes('dlink') && url.includes('preview') == false)
      try {
        const { data }: any = JSON.parse(sessionStorage.getItem('nestedDataRev') || '{}')
        if (data?.socios?.length > 0) {
          const socios = data.socios.map((s: any) => {
            return mockSocio.map((campo: any) => {
              return { ...campo, value: s[campo.name] }
            })
          })
          setTabSocio(socios.length - 1)
          setAllSocios([...socios])
        }

      } catch (error) {
        console.log("error: ", error);
      }

    setTabSocio([...allSocios].length)
    setAllSocios([...allSocios, [...mockSocio]])
  }

  const delTabSocio = async (idx: number) => {
    setAllSocios(allSocios.filter((_: any, i: number) => i != idx))

    // voltando para aba antecessora à deletada
    setTabSocio(idx - 1)
    await document.getElementById(`tab${idx}Socio`)?.click()
  }

  // garantindo que sempre haja uma aba aberta (form onePage)
  useEffect(() => { if (allSocios.length == 0) document.getElementById('startSocios')?.click() }, [allSocios])





  /* states tabs Avalista */
  const [tabAvalista, setTabAvalista]: any = useState(0);
  const [allAvalistas, setAllAvalistas]: any = useState([]);

  const addTabAvalista = async () => {
    // fazendo carga de dados de avalistas após + ser clicado
    const storedData = JSON.parse(sessionStorage.getItem('nestedDataAvalistas') || '{}');
    if (storedData != '{}')
      setTimeout(() => {
        sessionStorage.removeItem('nestedDataAvalistas');

        if (storedData?.avalistas?.length > 0) {
          const nestedAvalistas = storedData.avalistas.map((a: any) => {
            return bloco.campos.map((campo: any) => {
              if (campo.habilitar)
                return { ...campo, value: a[campo.name] };
            });
          });
          setTabAvalista(nestedAvalistas.length - 1);
          setAllAvalistas([...nestedAvalistas]);
        }
      }, 500);

    const mockAvalista: any = bloco.campos
      .filter((campo: Campo) => { return campo.habilitar })
      .map((campo: Campo) => { return { ...campo, value: '' } })

    if (url.includes('dlink') && url.includes('preview') == false)
      try {
        const { data }: any = JSON.parse(sessionStorage.getItem('nestedDataRev') || '{}')
        if (data?.avalistas?.length > 0) {
          const avalistas = data.avalistas.map((a: any) => {
            return mockAvalista.map((campo: any) => {
              return { ...campo, value: a[campo.name] }
            })
          })
          setTabAvalista(avalistas.length - 1)
          setAllAvalistas([...avalistas])
        }

      } catch (error) {
        console.log("error: ", error);
      }

    setTabAvalista([...allAvalistas].length)
    setAllAvalistas([...allAvalistas, [...mockAvalista]])
  }

  const delTabAvalista = async (idx: number) => {
    setAllAvalistas(allAvalistas.filter((_: any, i: number) => i != idx))

    // voltando para aba antecessora à deletada
    setTabAvalista(idx - 1)
    await document.getElementById(`tab${idx}Avalista`)?.click()
  }

  // garantindo que sempre haja uma aba aberta (form onePage)
  useEffect(() => { if (allAvalistas.length == 0) document.getElementById('startAvalistas')?.click() }, [allAvalistas])





  // função abaixo: opções de upload e validar em sendFormOnePage se foram preenchidos todos aqueles obrigatórios:
  const uploadsPending = (blocoSelecionadoCampos: any) => {
    if (blocoSelecionadoCampos.obrigatorio && blocoSelecionadoCampos.opcoes) {
      // salvando nomes dos arquivos de upload para usar no formOnePage
      const saveOpcUpload = (arrayOpc: any) => {
        let camposStoraged: any = JSON.parse(sessionStorage.getItem('uploadsPending') || '[]')

        //remove duplicates
        camposStoraged = [...camposStoraged, ...arrayOpc].filter((item: any, pos: any, self: any) => {
          return self.indexOf(item) == pos;
        })

        sessionStorage.setItem('uploadsPending', JSON.stringify(camposStoraged))
      }

      saveOpcUpload(blocoSelecionadoCampos.opcoes || [])
    }
  }





  /* states tabs Etapa Adicional */
  const [tabEtapaAdicional, setTabEtapaAdicional]: any = useState(0);
  const [allTabEtapaAdicional, setAllTabEtapaAdicional]: any = useState([]);

  // armazenando todos os campos de etapas adicionais:
  let allCamposEtapasAdicionais = JSON.parse(sessionStorage.getItem('allCamposEtapasAdicionais') || '[]');

  const addTabEtapaAdicional = async (blocoSelecionado: any) => {
    const nomeEtapa = blocoSelecionado?.nome?.replace(/ /g, '-');
    let lengthAnterior = JSON.parse(sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapa}`) || '0');
    sessionStorage.setItem(`tabEtapaAdicionalLength-${nomeEtapa}`, `${lengthAnterior + 1}`);

    const mockTabEtapaAdicional: any = bloco.campos
      .filter((campo: Campo) => { return campo.habilitar })
      .map((campo: Campo) => { return { ...campo, value: '' } })

    setTabEtapaAdicional([...allTabEtapaAdicional].length)
    setAllTabEtapaAdicional([...allTabEtapaAdicional, [...mockTabEtapaAdicional]])

    setTimeout(() => { // indo para aba recém criada:
      document.getElementById(`btnTabEtapaAdicionalId-${nomeEtapa}-${lengthAnterior + 1}`)?.click()
    }, 100)
  }

  const delTabEtapaAdicional = async (idx: number, blocoSelecionado: any) => {
    const numEtapaDeletada = idx + 1;
    const nomeEtapa = blocoSelecionado?.nome?.replace(/ /g, '-');
    let lengthAnterior = JSON.parse(sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapa}`) || '0');
    sessionStorage.setItem(`tabEtapaAdicionalLength-${nomeEtapa}`, `${lengthAnterior - 1}`);

    // reposicionando campos de etapas adicionais restantes
    let arrayCampos = JSON.parse(sessionStorage.getItem(`tabEtapaAdicionalCampos-${nomeEtapa}`) || '[]');
    if (arrayCampos.length > 0) {
      let arrayNames = arrayCampos.map((c: any) => c.name)
      for (let i = 1; i <= lengthAnterior; i++)
        arrayNames.map((name: string) => {
          // a partir da etapa deletada (numEtapaDeletada), reposicionar campos:
          if (i > numEtapaDeletada) // @ts-ignore
            setFieldValue(`${name}-${i - 1}`, values[`${name}-${i}`])

          // limpando campos última etapa, já que acima todas foram reposicionadas:
          if (i == lengthAnterior)
            setFieldValue(`${name}-${i}`, '')
        })
    }

    // voltando para aba antecessora à deletada
    document.getElementById(`btnTabEtapaAdicionalId-${nomeEtapa}-${idx}`)?.click()
  }

  // garantindo que sempre haja uma aba aberta (form onePage)
  /* useEffect(() => {
    if (allTabEtapaAdicional.length == 0)
      document.getElementById(`startTabEtapaAdicional`)?.click()
  }, [allTabEtapaAdicional]) */




  /* states Documentoscopia Pro */
  const [faseDocPro, setFaseDocPro] = useState<any>('');
  const [cameraError, setCameraError] = useState<any>('');
  const [dataUri, setDataUri] = useState('');
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  const [objDocProEnvio, setObjDocProEnvio] = useState<any>({});

  useEffect(() => {
    if (!sessionStorage.getItem('docProEnviado') && faseDocPro == 'Finalizado')
      sendDocPro();

  }, [objDocProEnvio, faseDocPro]);

  async function sendDocPro() {
    try {
      setLoading({ status: true, message: 'Enviando Documentoscopia PRO...' });
      let cpf: string = sessionStorage.getItem('b2e:cpfTempDocProEtapa') || sessionStorage.getItem('b2e:cpfDocProEtapa') || '';

      let { data }: any = await api.post('Proposta/Documentoscopia', {
        ...objDocProEnvio, cpf,
        pathArquivos: sessionStorage.getItem('path_arquivos'),
        instituicao: sessionStorage.getItem('b2e:instituicao')
      });
      message.success('Documentoscopia PRO enviado com sucesso', 6);
      sessionStorage.setItem('docProEnviado', 'true');
      sessionStorage.setItem('path_arquivos', data.pathArquivos);

      if (data.sucessoBureau)
        sessionStorage.setItem('objDocProFinal', JSON.stringify(data.documentoscopia));

    } catch (error) {
      ['objDocProFinal', 'docProEnviado'].forEach((item) => sessionStorage.removeItem(item));
      setObjDocProEnvio({});
      setFaseDocPro('');
      return message.error('Falha em seus dados, tente novamente', 6);
    } finally {
      setLoading({ ...loading, status: false });
    }
  }

  async function handleErrorPhoto(err: any) {
    if (`${err}`.includes('Permission denied'))
      return 'Sem permissão de acesso a câmera, para usá-la, faça a liberação no seu navegador.'
    else
      return 'Erro ao acessar sua câmera, verifique se ela está conectada corretamente e tente novamente.'
  }

  async function handleTakePhoto(dataUri: string) {
    Modal.destroyAll();

    try {
      const base64 = dataUri.split(',')[1];
      const mimeType = dataUri.split(';')[0].slice(5);
      const extension = mt.extension(mimeType);

      // Armazenando base64 e extensão separadamente:
      setObjDocProEnvio({ ...objDocProEnvio, [faseDocPro]: base64, [`${faseDocPro}EXT`]: extension });

      // Avançando para próxima fase:
      switch (faseDocPro) {
        case 'imagemSelfieB64':
          setFaseDocPro('imagemFrenteB64'); break;
        case 'imagemFrenteB64':
          setFaseDocPro('imagemVersoB64'); break;
        default:
          setFaseDocPro('Finalizado');
      }
    } catch (error) {
      console.log("error: ", error);
      //alert(error);
      message.error('Erro ao salvar arquivo, tente novamente', 6);
    } finally {
      setLoading({ status: false, message: '' });
    }
  }


  const ModalDocPro = (authCPF: number = 0, origin: string = 'webcam', uri: string = '') => {
    let cpfDocProEtapa = sessionStorage.getItem('b2e:cpfDocProEtapa') || ''
    let cpfTempDocProEtapa = sessionStorage.getItem('b2e:cpfTempDocProEtapa') || ''

    switch (true) {
      case (!authCPF && !!!cpfDocProEtapa):
        return message.error('Informe o CPF nas etapas anteriores')

      case (['imagemSelfieB64', 'imagemFrenteB64'].includes(faseDocPro) && authCPF && !cpfTempDocProEtapa):
        return Modal.info({
          centered: true,
          closable: true,
          icon: null,
          onCancel: () => { sessionStorage.removeItem('b2e:cpfTempDocProEtapa') },
          wrapClassName: 'remove-antd-footer',
          content: <>
            <div className='input-wrapper'>
              <label>Informe o CPF para Autenticação</label>
              <InputMask id='b2e:cpfTempDocProEtapa'
                placeholder='Informe o CPF para Autenticação'
                maskChar={null} mask="999.999.999-99"
                onChange={({ target: { value: v } }) =>
                  sessionStorage.setItem('b2e:cpfTempDocProEtapa', v)} />
            </div>

            <Button type="primary" className='float-right mt-3'
              onClick={async () => {
                cpfTempDocProEtapa = sessionStorage.getItem('b2e:cpfTempDocProEtapa') || ''

                if (!validateBr.cpf(cpfTempDocProEtapa)) {
                  sessionStorage.removeItem('b2e:cpfTempDocProEtapa')
                  message.error('CPF inválido')
                } else {
                  Modal.destroyAll()
                  ModalDocPro(authCPF, origin, uri)
                }
              }}>
              Prosseguir
            </Button>
          </>
        })

      case (origin == 'upload' && !!uri):
        return handleTakePhoto(uri)

      default:
        return Modal.info({
          centered: true,
          width: 740,
          icon: null,
          okText: 'Cancelar',
          content: <>
            {cameraError ? <div className='text-center'>{cameraError}</div> :
              <div style={{ transform: 'scaleX(-1)' }} className='upload-box'>
                <Camera imageType={IMAGE_TYPES.JPG} imageCompression={1}
                  onTakePhotoAnimationDone={(dataUri) => {
                    Modal.confirm({
                      centered: true,
                      closable: true,
                      width: 700,
                      icon: null,
                      content: <img src={dataUri} className='image-fluid' />,
                      okText: 'Confirmar',
                      cancelText: 'Repetir',
                      onOk: () => {
                        handleTakePhoto(dataUri)
                      }
                    })
                  }}
                  onCameraError={async (err) => {
                    await Modal.destroyAll()
                    Modal.error({
                      centered: true,
                      width: 700,
                      icon: null,
                      content: <div className='text-center'>{await handleErrorPhoto(err)}</div>,
                      okText: 'Fechar',
                    })
                  }}
                />
              </div>}
          </>
        })
    }
  }

  const RenderBloco = (blocoSelecionado: BlocoType): any => {
    if (blocoSelecionado.tipo === 1) {
      if (blocoSelecionado.integracao === 0) {
        sessionStorage.setItem('bioServico', blocoSelecionado.servico || '' /* || 'unico|biometria facial' */)
        sessionStorage.setItem('bioAuth', `${blocoSelecionado.tipoCalculo ? true : false}`)
        return <Biometria />;

      } else if (blocoSelecionado.integracao === 1) {
        return <UploadDinamico arrayOptions={[]} // array vazio faz mostrar somente 'Documento' para os tipos
          msgHelp={blocoSelecionado.servico || ''} />;

      } else if (blocoSelecionado.integracao === 2 /* && blocoSelecionado.servico == 'acertpix|documentoscopia pro' */) {
        if (faseDocPro === '') {
          setFaseDocPro(blocoSelecionado.expressao || 'imagemSelfieB64');
        }

        const handleUploadFile = async ({ target: { files } }: any) => {
          if (!files || files.length === 0) {
            return message.error('Erro ao carregar arquivo, tente novamente');
          }

          const file = files[0];

          if (!(file instanceof Blob)) {
            return message.error('O arquivo não é válido. Tente novamente com um arquivo diferente.');
          }

          // Verificar tamanho do arquivo
          const maxSize = 4.5 * 1024 * 1024; // 4.5MB
          const minSize = 100 * 1024; // 100KB

          if (file.size < minSize || file.size > maxSize)
            return message.error('Tamanho de arquivo inválido. O arquivo deve ter entre 100KB e 4.5MB.', 6);

          const reader = new FileReader();

          reader.onload = (e: any) =>
            ModalDocPro(blocoSelecionado.tipoCalculo, 'upload', e.target.result);

          reader.readAsDataURL(file);
        };

        return (
          <div className='mx-auto'>
            {faseDocPro === 'imagemSelfieB64' ? (
              <div className='text-center' id='pendingDocPro'>
                <div style={{ fontSize: 20, color: 'black' }}>
                  Precisamos Tirar sua Selfie
                </div>
                <div className='my-3'>
                  <img src={BioImg} className='col-md-5' alt='Bio Img' />
                </div>
                <Button type="primary" onClick={() => { ModalDocPro(blocoSelecionado.tipoCalculo) }}>
                  Tirar Selfie
                </Button>
              </div>
            ) : faseDocPro === 'imagemFrenteB64' ? (
              <div className='text-center' id='pendingDocPro'>
                <div style={{ fontSize: 20, color: 'black' }}>
                  Envie a frente do Documento
                </div>
                <div className='my-3'>
                  <img src={DocImg} className='col-md-5' alt='Doc Img' />
                </div>
                <Button type="primary" className='mr-2'
                  onClick={() => { ModalDocPro(blocoSelecionado.tipoCalculo) }}>
                  Tirar Foto
                </Button>
                <input
                  type="file"
                  className='d-none'
                  id='imagemFrenteB64'
                  accept=".jpeg, .jpg, .png, .pdf"
                  onChange={(e: any) => {
                    try {
                      handleUploadFile(e);
                    } catch (error) {
                      console.log("error: ", error);
                    } finally {
                      document.getElementById('imagemFrenteB64')?.setAttribute('type', 'text');
                      document.getElementById('imagemFrenteB64')?.setAttribute('type', 'file');
                    }
                  }}
                />
                <Button type="primary" icon={<UploadOutlined />}
                  onClick={() => { document.getElementById('imagemFrenteB64')?.click() }} />
              </div>
            ) : faseDocPro === 'imagemVersoB64' ? (
              <div className='text-center' id='pendingDocPro'>
                <div style={{ fontSize: 20, color: 'black' }}>
                  Agora envie o Verso do Documento
                </div>
                <div className='my-3'>
                  <img src={DocImg} className='col-md-5' alt='Doc Img' />
                </div>
                <Button type="primary" className='mr-2'
                  onClick={() => { ModalDocPro(blocoSelecionado.tipoCalculo) }}>
                  Tirar Foto
                </Button>
                <input
                  type="file"
                  className='d-none'
                  id='imagemVersoB64'
                  accept=".jpeg, .jpg, .png, .pdf"
                  onChange={(e: any) => {
                    try {
                      handleUploadFile(e);
                    } catch (error) {
                      console.log("error: ", error);
                    } finally {
                      document.getElementById('imagemVersoB64')?.setAttribute('type', 'text');
                      document.getElementById('imagemVersoB64')?.setAttribute('type', 'file');
                    }
                  }}
                />
                <Button type="primary" icon={<UploadOutlined />}
                  onClick={() => { document.getElementById('imagemVersoB64')?.click() }} />
              </div>
            ) : (
              <div className='text-center'>
                <div style={{ fontSize: 20, color: 'black' }}>
                  Documentos enviados com sucesso!
                </div>
                <div className='my-3'>
                  <img src={OkImg} className='col-md-3' alt='Ok Img' />
                </div>
              </div>
            )}
          </div>
        );
      }
    } else if (blocoSelecionado.tipo === 3) {
      uploadsPending(blocoSelecionado.campos[0])

      if (blocoSelecionado.campos.length > 0) {
        return <UploadDinamico arrayOptions={blocoSelecionado.campos[0].opcoes?.join()}
          msgHelp={blocoSelecionado.servico || ''} />;
      } else {
        return <UploadDinamico arrayOptions={[]}  // array vazio faz mostrar somente 'Documento' para os tipos
          msgHelp={blocoSelecionado.servico || ''} />;
      }

    } else if (blocoSelecionado.tipo === 46) {

      let campos1 = /* obtendo somente campos que devem ser exibidos */
        blocoSelecionado.campos.map((campo: Campo) => {
          if (campo.name.includes('-1') && campo.habilitar)
            return { ...campo, name: campo.name.replace('-1', '') }
        })
          .filter((campo: any) => campo); // removendo undefined

      const RefComerciais = (position: number) =>
        blocoSelecionado.campos
          .filter((campo: Campo) => {
            return campos1?.find((c: any) => campo.name.includes(c.name))
          })
          .filter((campo: Campo) => { /* removendo numerais no label se houver */
            campo.label = campo.label.replace(/\d+/g, '').trim();
            return campo.name.includes(`-${position}`)
          })
          .map((campo: Campo, idx: any) => renderFields(campo, idx))
          .filter(function (item, pos, self) { // remove duplicados
            return self.indexOf(item) == pos;
          })

      // previnindo que se nenhum campo for selecionado em etapas, não apareça as tabs:
      const etapaAtiva = blocoSelecionado.campos.filter((campo: Campo) => {
        return campo.name.includes('-1') && campo.habilitar
      }).length > 0;

      return (etapaAtiva && <>

        <div className='mb-2 ml-3 d-flex justify-content-start w-100'>
          <button onClick={() => setShowTabRefC(1)} id='tab1RefC' type="button" className='p-2 mr-1 tabAntd'
            style={{
              backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
              borderBottom: showTabRefC === 1 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
              color: showTabRefC === 1 && c?.interface?.corPrimaria || '#636363'
            }}>
            Referências Comerciais
          </button>

          {totalTabRefC >= 2 &&
            <button onClick={() => setShowTabRefC(2)} type="button" className='p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabRefC === 2 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabRefC === 2 && c?.interface?.corPrimaria || '#636363'
              }}>
              <i className='pr-2 fa fa-times' onClick={delTabRefC} />
              Referências Comerciais 2
            </button>}

          {totalTabRefC < 2 && <i className='fa fa-plus p-2 mr-1 tabAntd my-auto' onClick={addTabRefC}
            style={{ color: showTabRefC === 1 && c?.interface?.corPrimaria || '#ffffff' }} />}
        </div>

        <div className='col-md-12'>
          <div className={showTabRefC == 1 ? 'd-flex flex-wrap' : 'd-none'}>{RefComerciais(1)}</div>
          <div className={showTabRefC == 2 ? 'd-flex flex-wrap' : 'd-none'}>{RefComerciais(2)}</div>
        </div>
      </>);

    } else if (blocoSelecionado.tipo === 45) {

      let campos1 = /* obtendo somente campos que devem ser exibidos */
        blocoSelecionado.campos.map((campo: Campo) => {
          if (campo.name.includes('-1') && campo.habilitar)
            return { ...campo, name: campo.name.replace('-1', '') }
        })
          .filter((campo: any) => campo); // removendo undefined

      const RefBancarias = (position: number) =>
        blocoSelecionado.campos
          .filter((campo: Campo) => {
            return campos1?.find((c: any) => campo.name.includes(c.name))
          })
          .filter((campo: Campo) => { /* removendo numerais no label se houver */
            campo.label = campo.label.replace(/\d+/g, '').trim();
            return campo.name.includes(`-${position}`)
          })
          .map((campo: Campo, idx: any) => renderFields(campo, idx))
          .filter(function (item, pos, self) { // remove duplicados
            return self.indexOf(item) == pos;
          })

      return (campos1.length > 0 && <>
        <div className='mb-2 ml-3 d-flex justify-content-start w-100'>
          <button onClick={() => setShowTabRefB(1)} id='tab1RefB' type="button" className='p-2 mr-1 tabAntd'
            style={{
              backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
              borderBottom: showTabRefB === 1 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
              color: showTabRefB === 1 && c?.interface?.corPrimaria || '#636363'
            }}>
            Referências Bancárias
          </button>

          {totalTabRefB >= 2 &&
            <button onClick={() => setShowTabRefB(2)} type="button" className='p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabRefB === 2 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabRefB === 2 && c?.interface?.corPrimaria || '#636363'
              }}>
              <i className='pr-2 fa fa-times' onClick={delTabRefB} />
              Referências Bancárias 2
            </button>}

          {totalTabRefB < 2 && <i className='fa fa-plus p-2 mr-1 tabAntd my-auto' onClick={addTabRefB}
            style={{ color: showTabRefB === 1 && c?.interface?.corPrimaria || '#ffffff' }} />}
        </div>

        <div className='col-md-12'>
          <div className={showTabRefB == 1 ? 'd-flex flex-wrap' : 'd-none'}>{RefBancarias(1)}</div>
          <div className={showTabRefB == 2 ? 'd-flex flex-wrap' : 'd-none'}>{RefBancarias(2)}</div>
        </div>

      </>);

    } else if (blocoSelecionado.tipo === 43) {

      let campos1 = /* obtendo somente campos que devem ser exibidos */
        blocoSelecionado.campos.map((campo: Campo) => {
          if (campo.name.includes('-1') && campo.habilitar)
            return { ...campo, name: campo.name.replace('-1', '') }
        })
          .filter((campo: any) => campo); // removendo undefined

      const Faturamentos = (position: number) =>
        blocoSelecionado.campos
          .filter((campo: Campo) => {
            return campos1?.find((c: any) => campo.name.includes(c.name))
          })
          .filter((campo: Campo) => { /* removendo numerais no label se houver */
            campo.label = campo.label.replace(/\d+/g, '').trim();
            return campo.name.includes(`-${position}`)
          })
          .map((campo: Campo, idx: any) => renderFields(campo, idx))
          .filter(function (item, pos, self) { // remove duplicados
            return self.indexOf(item) == pos;
          })


      return (campos1.length > 0 && <>
        <div className='mb-2 ml-3 d-flex justify-content-start w-100'>
          <button onClick={() => setShowTabFats(1)}
            type="button" id='tabFats1' className='tabFats p-2 mr-1 tabAntd'
            style={{
              backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
              borderBottom: showTabFats === 1 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
              color: showTabFats === 1 && c?.interface?.corPrimaria || '#636363',
            }}>
            Faturamento
          </button>

          {totalTabFats >= 2 &&
            <button onClick={() => setShowTabFats(2)}
              type="button" className='tabFats p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabFats === 2 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabFats === 2 && c?.interface?.corPrimaria || '#636363',
              }}>
              {totalTabFats == 2 && <i className='pr-2 fa fa-times' onClick={delTabFats} />}
              Faturamento 2
            </button>}

          {totalTabFats >= 3 &&
            <button onClick={() => setShowTabFats(3)}
              type="button" className='tabFats p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabFats === 3 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabFats === 3 && c?.interface?.corPrimaria || '#636363',
              }}>
              {totalTabFats == 3 && <i className='pr-2 fa fa-times' onClick={delTabFats} />}
              Faturamento 3
            </button>}

          {totalTabFats >= 4 &&
            <button onClick={() => setShowTabFats(4)}
              type="button" className='tabFats p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabFats === 4 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabFats === 4 && c?.interface?.corPrimaria || '#636363',
              }}>
              {totalTabFats == 4 && <i className='pr-2 fa fa-times' onClick={delTabFats} />}
              Faturamento 4
            </button>}

          {totalTabFats >= 5 &&
            <button onClick={() => setShowTabFats(5)}
              type="button" className='tabFats p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabFats === 5 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabFats === 5 && c?.interface?.corPrimaria || '#636363',
              }}>
              {totalTabFats == 5 && <i className='pr-2 fa fa-times' onClick={delTabFats} />}
              Faturamento 5
            </button>}

          {totalTabFats >= 6 &&
            <button onClick={() => setShowTabFats(6)}
              type="button" className='tabFats p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabFats === 6 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabFats === 6 && c?.interface?.corPrimaria || '#636363',
              }}>
              {totalTabFats == 6 && <i className='pr-2 fa fa-times' onClick={delTabFats} />}
              Faturamento 6
            </button>}

          {totalTabFats < 6 && <i className='fa fa-plus p-2 mr-1 tabAntd my-auto' onClick={addTabFats}
            style={{ color: c?.interface?.corPrimaria || '#636363' }} />}
        </div>

        <div className='col-md-12'>
          <div className={showTabFats == 1 ? 'd-flex flex-wrap' : 'd-none'}>{Faturamentos(1)}</div>
          <div className={showTabFats == 2 ? 'd-flex flex-wrap' : 'd-none'}>{Faturamentos(2)}</div>
          <div className={showTabFats == 3 ? 'd-flex flex-wrap' : 'd-none'}>{Faturamentos(3)}</div>
          <div className={showTabFats == 4 ? 'd-flex flex-wrap' : 'd-none'}>{Faturamentos(4)}</div>
          <div className={showTabFats == 5 ? 'd-flex flex-wrap' : 'd-none'}>{Faturamentos(5)}</div>
          <div className={showTabFats == 6 ? 'd-flex flex-wrap' : 'd-none'}>{Faturamentos(6)}</div>
        </div>

      </>);


    } else if (blocoSelecionado.tipo === 44) {

      // previnindo que se nenhum campo for selecionado em etapas, não apareça as tabs:
      const etapaAtiva = blocoSelecionado.campos.filter((campo: Campo) => { return campo.habilitar }).length > 0;

      // garantindo que sempre haja uma aba aberta (form wizard)
      if (allSocios.length == 0) document.getElementById('startSocios')?.click()

      return (etapaAtiva && <>
        <div className='my-2 ml-3 d-flex justify-content-start w-100'>
          {allSocios?.map((f: any, idx: any, arr: any) => {
            return <>
              {idx != 0 && //se a tab não for a primeira, mostra o botão de deletar
                <button id={`tab${idx + 1}Socio`} type="button" className='tabAntd' style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                  borderBottom: tabSocio === idx && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: tabSocio === idx && c?.interface?.corPrimaria || '#636363',
                  marginRight: '-5px'
                }}>
                  <i className='fa fa-times my-auto' onClick={() => delTabSocio(idx)} />
                </button>}

              <button id={`tab${idx + 1}Socio`} type="button" className='mr-1 tabAntd'
                onClick={() => setTabSocio(idx)} style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                  borderBottom: tabSocio === idx && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: tabSocio === idx && c?.interface?.corPrimaria || '#636363',
                }}>
                Sócio {idx + 1}
              </button>
            </>
          })}

          {totalTabRefC < 2 && <i className='fa fa-plus p-2 mr-1 my-auto' id='startSocios' onClick={addTabSocio}
            style={{ color: showTabRefC === 1 && c?.interface?.corPrimaria || '#ffffff' }} />}
        </div>

        {allSocios.length > 0 && renderSocios(allSocios[tabSocio], tabSocio)}
      </>);

      // @ts-ignore
      function updateValueSocios(pos: any, newValue: any) {
        allSocios[tabSocio][pos].value = newValue
        const tabChanges = allSocios;
        setAllSocios([...tabChanges]);
      }

      // @ts-ignore
      function renderSocios(campos: any, idx: any) {
        // setando para usar ao enviar o formulário:
        sessionStorage.setItem('allSocios', JSON.stringify(allSocios));

        return campos?.map((campo: Campo, i: number) => {
          if (campo && campo.habilitar)
            switch (campo.type) {
              case 'date':
                return <div className='col-md-6 mb-3 datepicker-wrapper'>
                  {LabelRequired(null, campo.label, campo.obrigatorio)}
                  <DatePicker
                    format={['MM/YYYY', 'MMYYYY']}
                    picker="month"
                    value={campo?.value && campo?.value != 'N/I' ?
                      moment(campo?.value) : undefined}
                    placeholder="11/1111"
                    suffixIcon={<Icon name="date" size={15} color="#3A434D" />}
                    disabledDate={current => current && current > moment().endOf('day')}  /* datas até hj */
                    onChange={(date: any) => {
                      const isoDate = date?.toISOString();
                      updateValueSocios(i, isoDate); //.format('YYYY-MM-DD 00:00:00')
                    }}
                  />
                </div>

              default: //
                return <>
                  {campo.name.includes('uf') ?
                    <div className='col-md-6 mb-3 select-wrapper'>
                      {LabelRequired(null, campo.label, campo.obrigatorio)}
                      <Select value={campo.value || ''}
                        optionFilterProp="children" showSearch
                        placeholder="Selecione uma opção"
                        onChange={(v) => { updateValueSocios(i, v); }}>
                        <Option value='' disabled>Selecione uma opção</Option>
                        {Object.keys(ufs).map((uf) =>
                          <Option key={uf} value={uf}>{`${uf} - ${ufs[uf]}`}</Option>)}
                      </Select>
                    </div>
                    :
                    ['socio-profissao'].includes(campo.name) ?
                      <div className='col-md-6 mb-3 select-wrapper'>
                        {LabelRequired(null, campo.label, campo.obrigatorio)}
                        <Select value={campo.value || ''}
                          optionFilterProp="children" showSearch
                          placeholder="Selecione uma opção"
                          onChange={(v) => { updateValueSocios(i, v); }}>
                          <Option value='' disabled>Selecione uma opção</Option>
                          {p.map(({ id, descricao: desc }: any) =>
                            <Option key={id} value={id}>{desc}</Option>)}
                        </Select>
                      </div>
                      :
                      ['socio-natureza-ocupacao'].includes(campo.name) ?
                        <div className='col-md-6 mb-3 select-wrapper'>
                          {LabelRequired(null, campo.label, campo.obrigatorio)}
                          <Select value={`${campo.value || ''}`}
                            optionFilterProp="children" showSearch
                            placeholder="Selecione uma opção"
                            onChange={(v) => { updateValueSocios(i, v); }}>
                            <Option value='' disabled>Selecione uma opção</Option>
                            {o.map(({ id, descricao: desc }: any) =>
                              <Option key={id} value={`${id}`}>{desc}</Option>)}
                          </Select>
                        </div>
                        :
                        ['socio-nacionalidade'].includes(campo.name) ?
                          <div className='col-md-6 mb-3 select-wrapper'>
                            {LabelRequired(null, campo.label, campo.obrigatorio)}
                            <Select value={`${campo.value || ''}`}
                              optionFilterProp="children" showSearch
                              placeholder="Selecione uma opção"
                              onChange={(v) => { updateValueSocios(i, v); }}>
                              <Option value='' disabled>Selecione uma opção</Option>
                              {n?.map(({ id, descricao: desc }: any) =>
                                <Option key={id} value={`${id}`}>{desc}</Option>)}
                            </Select>
                          </div>
                          :
                          <div className='col-md-6 mb-3 input-wrapper'>
                            {LabelRequired(null, campo.label, campo.obrigatorio)}
                            {campo.name == 'socio-cpf' ?
                              <InputMask maskChar={null} mask="999.999.999-99"
                                placeholder={campo.label} value={campo.value || ''}
                                onChange={({ target: { value: v } }: any) => {
                                  updateValueSocios(i, v);
                                }} />
                              :
                              campo.name == 'socio-cep' ?
                                <InputMask maskChar={null} mask="99999-999"
                                  placeholder={campo.label} value={campo.value || ''}
                                  onChange={async ({ target: { value: v } }: any) => {
                                    updateValueSocios(i, v);
                                    if (v.length != 9) return;
                                    try {
                                      const { data }: any = await axios.get(`https://viacep.com.br/ws/${v}/json/`)
                                      if (data?.erro) return message.error('erro ao buscar CEP');
                                      allSocios[tabSocio].map(({ name, value }: Campo, i: number) => {
                                        if (value) return; //se já tiver valor, não atualiza
                                        if (name == 'socio-logradouro') updateValueSocios(i, data?.logradouro);
                                        if (name == 'socio-bairro') updateValueSocios(i, data?.bairro);
                                        if (name == 'socio-cidade') updateValueSocios(i, data?.localidade);
                                        if (name == 'socio-uf') updateValueSocios(i, data?.uf);
                                        if (name == 'socio-codigo-ibge') updateValueSocios(i, data?.ibge);
                                      })
                                    } catch (e) {
                                      //console.log("e: ", e);
                                      message.error('erro ao buscar CEP');
                                    }
                                  }} />
                                :
                                ['socio-celular', 'socio-telefone'].includes(campo.name) ?
                                  <InputMask maskChar={null} mask="(99) 999999999"
                                    placeholder={campo.label} value={campo.value || ''}
                                    onChange={({ target: { value: v } }: any) => {
                                      updateValueSocios(i, v);
                                    }} />
                                  :
                                  campo.name == 'socio-codigo-ibge' ?
                                    <input placeholder={campo.label} value={campo.value || ''} disabled
                                      onChange={({ target: { value: v } }: any) => {
                                        // only allow numbers
                                        updateValueSocios(i, v.replace(/\D/g, ''));
                                      }} />
                                    :
                                    campo.name == 'socio-percentual' ?
                                      <input placeholder={campo.label} value={campo.value || ''}
                                        onChange={({ target: { value: v } }: any) => {
                                          // only allow values between 0 and 100
                                          if (+v.replace(/\D/g, '') >= 100) updateValueSocios(i, `${100}`);
                                          else if (+v.replace(/\D/g, '') <= 0) updateValueSocios(i, `${0}`);
                                          else updateValueSocios(i, `${v.replace(/\D/g, '')}`);
                                        }} />
                                      :
                                      <input placeholder={campo.label} value={campo.value || ''}
                                        onChange={({ target: { value: v } }: any) => {
                                          if (campo.name.includes('mail'))
                                            updateValueSocios(i, `${v}`?.replace(/[^a-zA-Z0-9@._-]/g, ''));

                                          else updateValueSocios(i, v);
                                        }} />
                            }
                          </div>
                  }
                </>
            }
        });
      }
    } else if (blocoSelecionado.tipo === 0) {
      // BLOCO DE SOMENTE PESSOA FÍSICA, COM MAP ESPECÍFICO, POIS SOMENTE PF TEM ETAPAS JUNTAS

      let camposRefB: Campo[] = [] // referencias bancarias
      let camposRefP: Campo[] = [] // referencias pessoais
      let camposPF: Campo[] = [] // campos normais do pf

      // MAP:
      blocoSelecionado.campos.map((campo: Campo) => {
        if (['banco-referencia-1', 'agencia-referencia-1', 'conta-referencia-1', 'desde-referencia-1',
          'banco-referencia-2', 'agencia-referencia-2', 'conta-referencia-2', 'desde-referencia-2']
          .includes(campo.name)) camposRefB.push(campo);

        else if (['nome-referencia-1', 'tel-referencia-1', 'grau-parentesco-1',
          'nome-referencia-2', 'tel-referencia-2', 'grau-parentesco-2']
          .includes(campo.name)) camposRefP.push(campo);

        else camposPF.push(campo);
      });

      const RenderRefBancarias = () => {
        let campos1 = /* obtendo somente campos que devem ser exibidos */
          camposRefB.map((campo: Campo) => {
            if (campo.name.includes('-1') && campo.habilitar)
              return { ...campo, name: campo.name.replace('-1', '') }
          })
            .filter((campo: any) => campo); // removendo undefined

        const RefBancarias = (position: any) => {
          return <>
            {camposRefB
              .filter((campo: Campo) => {
                return campos1?.find((c: any) => campo.name.includes(c.name))
              })
              .filter((campo: Campo) => { /* removendo numerais no label se houver */
                campo.label = campo.label.replace(/\d+/g, '').trim();
                return campo.name.includes('-' + position)
              })
              .map((campo: Campo, idx: any) => renderFields(campo, idx))
              .filter(function (item, pos, self) { // remove duplicados
                return self.indexOf(item) == pos;
              })}
          </>
        }

        return (campos1.length > 0 && <>
          <div className='mb-2 ml-3 d-flex justify-content-start w-100'>
            <button onClick={() => setShowTabRefB(1)} id='tab1RefB' type="button" className='p-2 mr-1 tabAntd tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabRefB === 1 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabRefB === 1 && c?.interface?.corPrimaria || '#636363'
              }}>
              Referências Bancárias
            </button>

            {totalTabRefB >= 2 &&
              <button onClick={() => setShowTabRefB(2)} type="button" className='p-2 mr-1 tabAntd'
                style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                  borderBottom: showTabRefB === 2 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: showTabRefB === 2 && c?.interface?.corPrimaria || '#636363'
                }}>
                <i className='pr-2 fa fa-times' onClick={delTabRefB} />
                Referências Bancárias 2
              </button>}

            {totalTabRefB < 2 && <i className='fa fa-plus p-2 mr-1 tabAntd my-auto' onClick={addTabRefB}
              style={{ color: showTabRefB === 1 && c?.interface?.corPrimaria || '#ffffff' }} />}
          </div>

          <div className='col-md-12'>
            <div className={showTabRefB == 1 ? 'd-flex flex-wrap' : 'd-none'}>{RefBancarias(1)}</div>
            <div className={showTabRefB == 2 ? 'd-flex flex-wrap' : 'd-none'}>{RefBancarias(2)}</div>
          </div>

        </>);
      }

      const RenderRefPessoais = () => {
        let campos1 = /* obtendo somente campos que devem ser exibidos */
          camposRefP.map((campo: Campo) => {
            if (campo.name.includes('-1') && campo.habilitar)
              return { ...campo, name: campo.name.replace('-1', '') }
          })
            .filter((campo: any) => campo); // removendo undefined

        const RefPessoais = (position: any) => {
          return <>
            {camposRefP
              .filter((campo: Campo) => {
                return campos1?.find((c: any) => campo.name.includes(c.name))
              })
              .filter((campo: Campo) => { /* removendo numerais no label se houver */
                campo.label = campo.label.replace(/\d+/g, '').trim();
                return campo.name.includes('-' + position)
              })
              .map((campo: Campo, idx: any) => renderFields(campo, idx))
              .filter(function (item, pos, self) { // remove duplicados
                return self.indexOf(item) == pos;
              })}
          </>
        }

        return (campos1.length > 0 && <>
          <div className='mb-2 ml-3 d-flex justify-content-start w-100'>
            <button onClick={() => setShowTabRefP(1)} id='tab1RefP' type="button" className='p-2 mr-1 tabAntd'
              style={{
                backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                borderBottom: showTabRefP === 1 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                color: showTabRefP === 1 && c?.interface?.corPrimaria || '#636363'
              }}>
              Referências Pessoais
            </button>

            {totalTabRefP >= 2 &&
              <button onClick={() => setShowTabRefP(2)} type="button" className='p-2 mr-1 tabAntd'
                style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                  borderBottom: showTabRefP === 2 && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: showTabRefP === 2 && c?.interface?.corPrimaria || '#636363'
                }}>
                <i className='pr-2 fa fa-times' onClick={delTabRefP} />
                Referências Pessoais 2
              </button>}

            {totalTabRefP < 2 && <i className='fa fa-plus p-2 mr-1 tabAntd my-auto' onClick={addTabRefP}
              style={{ color: showTabRefP === 1 && c?.interface?.corPrimaria || '#ffffff' }} />}
          </div>

          <div className='col-md-12'>
            <div className={showTabRefP == 1 ? 'd-flex flex-wrap' : 'd-none'}>{RefPessoais(1)}</div>
            <div className={showTabRefP == 2 ? 'd-flex flex-wrap' : 'd-none'}>{RefPessoais(2)}</div>
          </div>

        </>);
      }

      return (<>
        {camposPF.map((campo: Campo, idx: any) => renderFields(campo, idx))}

        {RenderRefBancarias()}

        {RenderRefPessoais()}
      </>);


    } else if (blocoSelecionado.tipo === 5) {
      let campoDiaVencimento: any = blocoSelecionado.campos.find((campo: Campo) => campo.name == 'diaVencimento')
      let campoQtdParcelas: any = blocoSelecionado.campos.find((campo: Campo) => campo.name == 'qtdParcelas')
      let campoTipoEntrada: any = blocoSelecionado.campos.find((campo: Campo) => campo.name == 'entrada')
      let campoFormasPagamento: any = JSON.parse(blocoSelecionado?.expressao || '[]')
      let diaHoje = new Date().getDate()

      if (blocoSelecionado.tipoCalculo === 0) // Memória de Cálculo
        return <>
          {blocoSelecionado.campos.map((campo: Campo, idx: any) => {

            if (campo.name == 'vlrSolicitado' && campo.habilitar) {
              if (sessionStorage.getItem('nestedDataParcelamento')) {
                let nestedDataParcelamento = JSON.parse(sessionStorage.getItem('nestedDataParcelamento') || '{}')
                return <div className='w-100 mb-2 d-flex flex-wrap'>
                  <div className='col-md-6 input-wrapper'>
                    <label>Valor Solicitado</label>
                    <input disabled value={`R$ ${nestedDataParcelamento['valor-solicitado']}`.replace('.', ',')} />
                  </div>
                  <div className='col-md-6 input-wrapper'>
                    <label>Vencimento 1ª parcela</label>
                    <input disabled value={`${nestedDataParcelamento['info-parcelamento'].primeiraParcela}` || ''} />
                  </div>
                  <div className='col-md-6 mt-2 input-wrapper'>
                    <label>Quantidade de parcelas</label>
                    <input disabled value={`${nestedDataParcelamento['info-parcelamento']?.entrada ? `Entrada de ${nestedDataParcelamento['info-parcelamento']?.entrada} + ` : ''}${nestedDataParcelamento['info-parcelamento'].prazo}x de R$ ${`${nestedDataParcelamento['info-parcelamento']?.vlrParcela.toFixed(2)}`.replace('.', ',')}` || ''} />
                  </div>
                  {nestedDataParcelamento['forma-pagamento'] ? <div className='col-md-6 mt-2 input-wrapper'>
                    <label>Forma de Pagamento</label>
                    <input disabled value={`${f.find((f: any) => f.id == nestedDataParcelamento['forma-pagamento'])?.descricao}`} />
                  </div> : null}
                </div>
              } else {
                var selectDiaVenc = document.getElementById('selectDiaVenc');
                var selectQtdParcelas = document.getElementById('selectQtdParcelas');
                var selectFormaPagamento = document.getElementById('selectFormaPagamento');

                setTimeout(() => { // se Forma de Pagamento tiver somente uma opção, seta o valor auto.
                  const optionFormaPagamento: any = document.querySelectorAll('#optionFormaPagamento h4');
                  if (optionFormaPagamento && optionFormaPagamento?.length == 2)
                    optionFormaPagamento[1]?.click();
                }, 500);

                const handleSelectDiaVenc =
                  async (e: any, value: number = 0) => {
                    var selectDiaVenc = document.getElementById('selectDiaVenc');

                    const { target: { innerText: texto } } = e;

                    const items = document.querySelectorAll('#b2e\\:Dia-de-Vencimento .item-parcelamento');
                    if (items) items.forEach((item: any) => item.style.backgroundColor = 'white');

                    // change background color of selected item
                    e.target.style.backgroundColor = '#f0f0f0';

                    sessionStorage.setItem('selectDiaVenc', texto);
                    if (selectDiaVenc) selectDiaVenc.innerText = texto;

                    // return usado em handleResetParcelamentoB2e
                    if (!value) return sessionStorage.removeItem('b2e:Dia-de-Vencimento');
                    else sessionStorage.setItem('b2e:Dia-de-Vencimento', `${value}`);

                    switch (campoDiaVencimento?.label) {
                      case 'Carência': // add value to diaHoje and convert to YYYY-MM-DD 
                        sessionStorage.setItem('b2e:dtPrimeiroVencimento', moment().add(value, 'days').format('YYYY-MM-DD'))
                        break;

                      case 'Dia Fixo': // get value day in next month and convert to YYYY-MM-DD
                        sessionStorage.setItem('b2e:dtPrimeiroVencimento', moment().add(1, 'months').set('date', value).format('YYYY-MM-DD'))
                        break;
                    }

                    if (await validateVlrSolicitado(campo))
                      simularParcela()
                  }

                const handleSelectQtdParcelas =
                  async (e: any, idxEscolha: number = 0) => {
                    var selectDiaVenc = document.getElementById('selectDiaVenc');
                    var selectQtdParcelas = document.getElementById('selectQtdParcelas');

                    const { target: { innerText: texto } } = e;

                    const items = document.querySelectorAll('#b2e\\:Quantidade-de-Parcelas .item-parcelamento');
                    if (items) items.forEach((item: any) => item.style.backgroundColor = 'white');

                    // change background color of selected item
                    e.target.style.backgroundColor = '#f0f0f0';

                    //sessionStorage.setItem('selectQtdParcelas', texto);
                    if (selectQtdParcelas) selectQtdParcelas.innerHTML = texto;

                    // caso seja resetado o parcelamento (handleResetParcelamentoB2e > handleResetQtdParcelas)
                    if (selectDiaVenc?.innerText == 'Selecione uma opção' /* || texto == 'Selecione uma opção' */)
                      return setParcelasList([])

                    sessionStorage.setItem('b2e:Quantidade-de-Parcelas', texto);
                    sessionStorage.setItem('etapaParcelamento', 'true');

                    // any key with vlr set to 2 decimal places:
                    let parcelaEscolhida: any = parcelasList[idxEscolha];
                    if (!parcelaEscolhida) return;
                    Object.keys(parcelaEscolhida).map((key: any) => {
                      if (key.includes('vlr')) parcelaEscolhida[key] = +(parcelaEscolhida[key]).toFixed(2)
                    })

                    sessionStorage.setItem('parcelaEscolhida', JSON.stringify(parcelaEscolhida));
                  };

                const handleSelectFormaPagamento =
                  async (e: any) => {
                    var selectFormaPagamento = document.getElementById('selectFormaPagamento');

                    const { target: { innerText: texto } } = e;

                    const items = document.querySelectorAll('#b2e\\:Forma-de-Pagamento .item-parcelamento');
                    if (items) items.forEach((item: any) => item.style.backgroundColor = 'white');

                    // change background color of selected item
                    e.target.style.backgroundColor = '#f0f0f0';

                    //sessionStorage.setItem('selectFormaPagamento', texto);
                    if (selectFormaPagamento) selectFormaPagamento.innerText = texto;

                    sessionStorage.setItem('b2e:Forma-de-Pagamento', texto);
                  };

                return <div className='w-100 d-flex flex-wrap'>
                  <div className='col-md-6 input-wrapper mb-2'
                    onBlur={async () => {
                      if (await validateVlrSolicitado(campo))
                        if (sessionStorage.getItem('b2e:diasAcrescimo'))
                          simularParcela()
                    }}>
                    {getValorPreDefinido() ? //@ts-ignore
                      <>
                        {LabelRequiredTemplate('Valor Solicitado')}
                        <Tooltip title="Valor definido em etapa anterior">
                          <button type="button" style={{ border: '1px solid #D0C4C3' }}
                            className='w-100 p-1 bg-gray cursor-not-allowed avoidCorTextoMenu'>
                            <label id='valorSolicDisabled' className='m-0 float-left pl-2'>
                              {`R$ ${getValorPreDefinido().toFixed(2)}`.replace('.', ',')}
                            </label>
                          </button>
                        </Tooltip>
                      </>
                      :
                      <>
                        {LabelRequiredTemplate('Valor Solicitado', 'b2e:Valor-Solicitado')}
                        <ReactIntlCurrencyInput defaultValue={vlrSolicitado}
                          currency='BRL' value={vlrSolicitado}
                          max={parseFloat(campo?.label) || 999999999.99}
                          config={currencyConfig} autoSelect={true}
                          onChange={async (_, currentValue: number) => {
                            handleResetParcelamentoB2e();

                            sessionStorage.setItem('b2e:Valor-Solicitado', `${currentValue}`);
                            setVlrSolicitado(currentValue);
                          }} />
                      </>}
                  </div >

                  <div id='b2e:Dia-de-Vencimento' className="dropdown col-md-6 w-100">
                    {LabelRequiredTemplate('Dia de Vencimento')}
                    {SelectParcelamentoB2e('selectDiaVenc', false,
                      sessionStorage.getItem('selectDiaVenc') || 'Selecione uma opção')}
                    <div id='optionDiaVenc' className="dropdown-menu" style={{ width: '90%' }}>
                      <h4 className="d-none" onClick={(e) => handleSelectDiaVenc(e, 0)}>
                        Selecione uma opção
                      </h4>
                      {[...campoDiaVencimento.opcoes]
                        .sort((a: any, b: any) => a - b)
                        .map((option: any, idx: any) => {
                          const contentLabel: any =
                            campoDiaVencimento?.label == 'Carência' ? `${option} dias de carência` : `Todo dia ${option}`

                          return <h4 key={idx} className="dropdown-item item-parcelamento cursor-pointer" style={{
                            backgroundColor: sessionStorage.getItem('selectDiaVenc')
                              == contentLabel ? '#f0f0f0' : 'white'
                          }} onClick={(e) => handleSelectDiaVenc(e, option)}>
                            {contentLabel}
                          </h4>
                        })}
                    </div>
                  </div>

                  <div id='b2e:Quantidade-de-Parcelas' className="dropdown col-md-6 mt-2">
                    <DivRequiredTemplate label="Escolha a quantidade de parcelas" />
                    {selectDiaVenc?.innerText == 'Selecione uma opção' || parcelasList.length == 0 ?
                      SelectParcelamentoB2e('selectQtdParcelas', true,
                        sessionStorage.getItem('b2e:Quantidade-de-Parcelas') || 'Selecione uma opção')
                      : <>
                        {SelectParcelamentoB2e('selectQtdParcelas', false,
                          sessionStorage.getItem('b2e:Quantidade-de-Parcelas') || 'Selecione uma opção')}
                        <div id='optionQtdParcelas' className="dropdown-menu" style={{ width: '90%' }}>
                          <h4 className="d-none" onClick={(e) => handleSelectQtdParcelas(e, 0)}>
                            Selecione uma opção
                          </h4>
                          {parcelasList
                            .map((parcela: any, idx: any) => {
                              let { prazo: p, vlrParcela: v, entrada: e }: any = parcela
                              v = v?.toFixed(2).replace('.', ',')

                              const Item = (): any => {

                                const contentLabel: any =
                                  ['1ª Parcela'].includes(campoTipoEntrada?.label) ?
                                    p == 1 ? `${p}x de R$ ${v}` : `Entrada de R$ ${v} + ${p - 1}x de R$ ${v}`

                                    : ['Valor Fixo', 'Percentual'].includes(campoTipoEntrada?.label) ?
                                      `Entrada de ${e} + ${p}x de R$ ${v}`

                                      : // Sem entrada (zero)
                                      `${p}x de R$ ${v}`

                                return <h4 key={idx} className="dropdown-item item-parcelamento cursor-pointer" style={{
                                  backgroundColor: sessionStorage.getItem('b2e:Quantidade-de-Parcelas')
                                    == contentLabel ? '#f0f0f0' : 'white'
                                }} onClick={(e) => handleSelectQtdParcelas(e, idx)}>
                                  {contentLabel}
                                </h4>
                              }

                              // verificar limitação de Parcelas Permitidas:
                              if ((campoQtdParcelas?.opcoes || []).length) {
                                if (campoQtdParcelas?.opcoes.includes(`${p}`))
                                  return Item()

                                // se nenhum limite for definido, retorna todas as parcelas:
                              } else return Item()
                            })}
                        </div>
                      </>
                    }
                  </div >

                  {
                    campoFormasPagamento.length ?
                      <div id='b2e:Forma-de-Pagamento' className="dropdown col-md-6 w-100 mt-2">
                        <DivRequiredTemplate label="Forma de Pagamento" />
                        {SelectParcelamentoB2e('selectFormaPagamento', false,
                          sessionStorage.getItem('b2e:Forma-de-Pagamento') || 'Selecione uma opção')}
                        <div id='optionFormaPagamento' className="dropdown-menu" style={{ width: '90%' }}>
                          <h4 className="d-none" onClick={(e) => handleSelectFormaPagamento(e)}>
                            Selecione uma opção
                          </h4>
                          {campoFormasPagamento
                            .map((fId: any) => { // id forma de pagamento
                              const contentLabel: any =
                                f.find((f: any) => f.id == fId)?.descricao

                              return <h4 key={fId} className="dropdown-item item-parcelamento cursor-pointer" style={{
                                backgroundColor: sessionStorage.getItem('b2e:Forma-de-Pagamento')
                                  == contentLabel ? '#f0f0f0' : 'white'
                              }} onClick={(e) => handleSelectFormaPagamento(e)}>
                                {contentLabel}
                              </h4>
                            })}
                        </div>
                      </div > : null
                  }
                </div >
              }
            } else {
              if (idx > 3)
                return <div onBlur={simularParcela} className='mb-2 col-md-6'>
                  {renderFields({
                    ...campo, tipoDado: 'SimularParcelaB2e',
                    label: campo.label.replace(/_/g, ' ').replace(/\w\S*/g, (w: any) => (w.replace(/^\w/, (c: any) => c.toUpperCase())))
                  }, idx)}
                </div>
            }
          })}

          <div className='col-md-12 pb-0 mb-0'>
            {parse((blocoSelecionado?.servico?.replace(/<img /g, "<img class='img-fluid'") || '<div></div>')
              + '<style>.cadastrar .steps-action { margin-top: 0px; }</style>') || ''}
          </div>
        </>

      else if (blocoSelecionado.tipoCalculo === 1) // Money+
        return <>
          {blocoSelecionado.campos.map((campo: Campo, idx: any) => {
            async function simularParcelaMoney() {
              setLoading({ status: true, message: 'Simulando parcelas...' });
              try {
                //if (await validateVlrSolicitado())
                let listParcelas: any = [];
                let { data: { parcelas, informacoesParcelamento } }: any = await api.post('Proposta/SimularParcela', {
                  guidInstituicao: sessionStorage.getItem('b2e:instituicao'),
                  tipoPessoa: sessionStorage.getItem('b2e:tipoPessoa'),
                  valorSolicitado: sessionStorage.getItem('b2e:vlrSolicitado'),
                  diasAcrescimo: sessionStorage.getItem('b2e:diasAcrescimo') || '0'
                });

                parcelas.map((parcela: any) => { listParcelas.push({ ...parcela, ...informacoesParcelamento }) })

                setParcelasList(listParcelas);
              } catch (e) {
                //console.log(e)
                message.error('Erro ao simular parcelas, digite o valor novamente');
              } finally {
                setLoading({ ...loading, status: false, });
              }
            }

            async function validateVlrSolicitadoMoney() {
              let vlrSolicitado = parseFloat(sessionStorage.getItem('money+:Valor-Solicitado') || '0');

              /* validações valor solicitado: */
              if (vlrSolicitado == 0) {
                message.info('Informe um valor para simular')
                return false;
              }
              if (vlrSolicitado < parseFloat(campo?.value ?? '0')) {
                message.error(`Valor Solicitado menor que o mínimo permitido de R$ ${parseFloat(campo.value || '0').toFixed(2)}`.replace('.', ','));
                return false;

              } else if (vlrSolicitado > parseFloat(campo?.label ?? '0')) {
                message.error(`Valor Solicitado maior que o máximo permitido de R$ ${parseFloat(campo.label || '0').toFixed(2)}`.replace('.', ','));
                return false;
              }

              sessionStorage.setItem('b2e:vlrSolicitado', `${vlrSolicitado}`);
              return true;
            }

            if (campo.name == 'vlrSolicitado' && campo.habilitar) {
              if (sessionStorage.getItem('nestedDataParcelamento')) {
                let nestedDataParcelamento = JSON.parse(sessionStorage.getItem('nestedDataParcelamento') || '{}')
                return <div className='w-100 mb-2 d-flex flex-wrap'>
                  <div className='col-md-6 input-wrapper'>
                    <label>Valor Solicitado</label>
                    <input disabled value={`R$ ${nestedDataParcelamento['valor-solicitado']}`.replace('.', ',')} />
                  </div>
                  <div className='col-md-6 input-wrapper'>
                    <label>Quantidade de parcelas</label>
                    <input disabled value={`${nestedDataParcelamento['info-parcelamento']?.entrada ? `Entrada de ${nestedDataParcelamento['info-parcelamento']?.entrada} + ` : ''}${nestedDataParcelamento['info-parcelamento'].prazo}x de R$ ${`${nestedDataParcelamento['info-parcelamento']?.vlrParcela.toFixed(2)}`.replace('.', ',')}` || ''} />
                  </div>
                  {nestedDataParcelamento['forma-pagamento'] ? <div className='col-md-6 mt-2 input-wrapper'>
                    <label>Forma de Pagamento</label>
                    <input disabled value={`${f.find((f: any) => f.id == nestedDataParcelamento['forma-pagamento'])?.descricao}`} />
                  </div> : null}
                </div>
              } else
                return <div className="w-100">
                  <div className='col-md-3 float-left d-flex input-wrapper'
                    onBlur={async () => {
                      if (await validateVlrSolicitadoMoney())
                        if (sessionStorage.getItem('b2e:diasAcrescimo'))
                          simularParcelaMoney()
                    }}>
                    {LabelRequiredTemplate('Valor Solicitado', 'money+:Valor-Solicitado')}
                    <ReactIntlCurrencyInput defaultValue={parseFloat(campo?.value || '0')}
                      currency='BRL' /* value={vlrSolicitado} */
                      max={parseFloat(campo?.label) || 999999999.99}
                      config={currencyConfig} autoSelect={true}
                      onChange={(_, currentValue: number) => {
                        sessionStorage.setItem('money+:Valor-Solicitado', `${currentValue}`)
                      }} />
                  </div>

                  <div className='col-md-3 select-wrapper float-left d-flex'
                    onBlur={async () => {
                      if (await validateVlrSolicitadoMoney())
                        simularParcelaMoney()
                    }}>
                    {LabelRequiredTemplate('Vencimento 1ª parcela', 'money+:Dia-de-Vencimento')}
                    <Select defaultValue={sessionStorage.getItem('money+:Dia-de-Vencimento') || ''}
                      onChange={async (value: any) => {
                        sessionStorage.setItem('money+:Dia-de-Vencimento', value)
                        let date = new Date();
                        let nmrDiasMesAtual: any = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

                        // garantindo que todos sejam number:
                        nmrDiasMesAtual = parseInt(nmrDiasMesAtual);
                        value = parseInt(value);

                        switch (campoDiaVencimento?.label) {
                          case 'Carência':
                            if (value < nmrDiasMesAtual)
                              sessionStorage.setItem('b2e:diasAcrescimo', `-${nmrDiasMesAtual - value}`)
                            else
                              sessionStorage.setItem('b2e:diasAcrescimo', `${value - nmrDiasMesAtual}`)
                            break;

                          case 'Dia Fixo':
                            sessionStorage.setItem('b2e:diasAcrescimo', `${calcDiasAcrescimoBMP(value)}`);
                            break;
                        }
                      }}>
                      <Option value={''} disabled>Selecione uma opção</Option>
                      {[...campoDiaVencimento?.opcoes/* , diaHoje */]
                        .sort((a: any, b: any) => a - b).map((option: any, idx: any) => {
                          return <Option key={idx} value={option}>
                            {campoDiaVencimento?.label == 'Carência' ? `${option} dias de carência` : getDataPrimeiroVencimentoBMP(option)}
                          </Option>
                        })}
                    </Select>
                  </div>

                  <div className="col-md-6 select-wrapper float-right d-flex">
                    {LabelRequiredTemplate('Escolha a quantidade de parcelas', 'money+:Quantidade-de-Parcelas')}
                    <Select defaultValue={sessionStorage.getItem('money+:Quantidade-de-Parcelas') || ''}
                      disabled={parcelasList.length == 0} onChange={(value: any, { children: c }: any) => {
                        sessionStorage.setItem('money+:Quantidade-de-Parcelas', c.join(' '))
                        sessionStorage.setItem('etapaParcelamento', 'true');
                        sessionStorage.setItem('parcelaEscolhida', JSON.stringify(parcelasList[value]));
                      }}>
                      <Option value={''} disabled>Selecione uma opção</Option>
                      {parcelasList.map((p: any, idx: any) => {
                        return <Option key={idx} value={idx}>
                          {p?.prazo}x de R$ {`${p?.vlrParcela.toFixed(2)}`.replace('.', ',')}
                        </Option>
                      })}
                    </Select>
                  </div>
                </div>
            }
          })}
        </>

    } else if (blocoSelecionado.tipo === 6) {
      const contentModal = blocoSelecionado?.campos[1]?.value || ''

      return <div className='col-md-12'>
        <Modal open={modalOpen} width={1000}
          closable={false} centered={true}
          onCancel={() => setModalOpen(false)}
          footer={<Button onClick={() => setModalOpen(false)}>Fechar</Button>}>
          {(contentModal).includes('data:application/pdf;base64') ?
            <PdfViewer fileUrl={contentModal} />
            : !contentModal ? 'Nenhum Termo de Uso encontrado, entre em contato com o suporte.'
              : parse(contentModal) /* to support old html content */}
        </Modal>
        <div className="mr-3 mb-2">
          <div style={{ color: c?.interface?.corPrimaria || '#881220', cursor: 'pointer' }}
            onClick={() => { setModalOpen(true) }}>
            Visualizar Condições e Termos de Uso <i className="fas fa-external-link-alt ml-1" />
          </div>

          <DivRequiredTemplate label={blocoSelecionado.campos[0].label}
            content={<AntdSwitch className='mr-1' // @ts-ignore
              checked={/^S$/i.test(values[blocoSelecionado?.campos[0]?.name] || '')}
              onChange={(value) => {
                let valueForValidate = value ? 'S' : '';
                setFieldValue(blocoSelecionado.campos[0].name, `${valueForValidate}`)
              }} />} />

          <div className='d-none'>{renderFields(blocoSelecionado.campos[0], 0)}</div>
        </div >
      </div >

    } else if (blocoSelecionado.tipo === 7) {
      let servico = blocoSelecionado.servico || ''
      let msgExplicativa = blocoSelecionado.expressao || 'Você receberá um token de nosso provedor de segurança "B2e". Favor copiar e inserir no campo adequado.'

      if (servico.includes('E-mail'))
        sessionStorage.setItem('codEmailEtapa', 'true')
      else if (servico.includes('SMS'))
        sessionStorage.setItem('codSMSEtapa', 'true')

      const valueEmail = () => sessionStorage.getItem('b2e:emailCodigoVerif') || ''
      const valueSMS = () => sessionStorage.getItem('b2e:smsCodigoVerif') || ''

      const getCacheCrono = (ss: string) => {
        let crono = parseInt(sessionStorage.getItem(ss) || '0')
        return crono > 0 ? crono : 0
      }

      const obterCodigoVerif = async (tipoEnvio: number) => {
        try {
          let { data: d }: any = await api.post('Notificacao/ObterCodigoValidacao', {
            "destinatario": tipoEnvio == 0 ? valueEmail() : valueSMS(),
            "documentoCliente": "", tipoEnvio,
            "guidInstituicao": sessionStorage.getItem('b2e:instituicao'),
          })

          /* let fakeRecipient = Math.random().toString(36).substring(2, 15) + '@b2e.com.br'
          await api.post('Notificacao/ObterCodigoValidacao', {
            "destinatario": tipoEnvio == 0 ? fakeRecipient : fakeRecipient,
            "documentoCliente": "", tipoEnvio: 0,
            "guidInstituicao": sessionStorage.getItem('b2e:instituicao'),
          }) */

          if (d.sucesso == false) throw new Error();
          setTipoEnvio(tipoEnvio)
          message.success('Código enviado com sucesso')

          if (tipoEnvio == 0) {
            sessionStorage.setItem('codEmailEnviado', 'true');
            setCodEmailEnviado(true);
          } else {
            sessionStorage.setItem('codSMSEnviado', 'true');
            setCodSMSEnviado(true);
          }
        } catch (error) {
          message.error('Erro ao enviar código de verificação, tente novamente mais tarde')
          resetEnvioCodigoVerif(tipoEnvio)
        }
      }

      const validarCodigoVerif = async (cod: any) => {
        try {
          let { data: d }: any = await api.post('Notificacao/ValidarCodigo?codigo=' + cod, {
            "destinatario": tipoEnvio == 0 ? valueEmail() : valueSMS(),
            "documentoCliente": "", tipoEnvio,
            "guidInstituicao": sessionStorage.getItem('b2e:instituicao'),
          })

          if (d.sucesso == false) throw new Error()
          message.success('Código validado com sucesso')

          // onePage: não permitir alterar o email/celular
          if (tipoEnvio == 0) { // usado em botão voltar/enviar/prox.:
            setCodEmailValidado(true)
            sessionStorage.setItem('codEmailValidado', 'true')
            document.getElementById('email')?.setAttribute('disabled', 'true');
          } else { // usado em botão voltar/enviar/prox.:
            setCodSMSValidado(true)
            sessionStorage.setItem('codSMSValidado', 'true')
            document.getElementById('fone-celular')?.setAttribute('disabled', 'true');
          }
        } catch (error) {
          message.error('Erro ao validar código de verificação')
        }
      }

      const resetEnvioCodigoVerif = (tipoEnvio: number) => {
        if (tipoEnvio == 0) {
          sessionStorage.removeItem('codEmailEnviado')
          sessionStorage.removeItem('cronoEmail')
          setCodEmailEnviado(false)
        } else {
          sessionStorage.removeItem('codSMSEnviado')
          sessionStorage.removeItem('cronoSMS')
          setCodSMSEnviado(false)
        }
      }

      if (servico.includes('E-mail')) {
        return <div className="col-lg-12">
          {codEmailValidado ?
            <div className="animated fadeIn mt-3 text-center">
              <h2 style={{ fontWeight: 'normal' }}>
                <i className="fa fa-check-circle mr-2 text-success" />
                Código validado com sucesso através do E-mail
              </h2>
            </div>
            : !validateBr.email(valueEmail()) ?
              <h2 style={{ fontWeight: 'normal' }} className="mt-2 text-center">
                <i className="fa fa-exclamation-triangle mr-2 text-danger" />
                Erro ao gerar código de verificação,
                <br />
                preencha corretamente os dados de cadastro
              </h2>
              : <>
                {codEmailEnviado ? <h3>Confirme o código de verificação (E-mail)</h3> : <h3>Clique abaixo para enviar o código de verificação:</h3>}
                {codEmailEnviado && !codEmailValidado ?
                  <div className="mt-1">
                    <span style={{ color: 'red' }}>Código de verificação expira em:</span>
                    <Countdown format="mm:ss" value={getCacheCrono('cronoEmail') ?
                      Date.now() + getCacheCrono('cronoEmail') || '0' : Date.now() + 60000}
                      onChange={(v: any) => {
                        if (v > 0) sessionStorage.setItem('cronoEmail', v)
                      }}
                      onFinish={() => {
                        if (codEmailValidado) return;
                        resetEnvioCodigoVerif(tipoEnvio);
                        message.error('Código expirado, solicite um novo envio')
                      }} />

                    <VerificationInput autoFocus validChars='0-9' onComplete={(v) => validarCodigoVerif(v)} />
                  </div>
                  :
                  <div className="mt-1">
                    {<a onClick={() => obterCodigoVerif(0)}>
                      <i className="fa fa-envelope mr-2" />Enviar código por E-mail</a>}
                  </div>}

                <p className='mt-4 mb-0'>{msgExplicativa}</p>
              </>}
        </div>

      } else if (servico.includes('SMS')) {
        return <div className="col-lg-12">
          {codSMSValidado ?
            <div className="animated fadeIn mt-3 text-center">
              <h2 style={{ fontWeight: 'normal' }}>
                <i className="fa fa-check-circle mr-2 text-success" />
                Código validado com sucesso através do SMS
              </h2>
            </div>
            : !valueSMS() ?
              <h2 style={{ fontWeight: 'normal' }} className="mt-2 text-center">
                <i className="fa fa-exclamation-triangle mr-2 text-danger" />
                Erro ao gerar código de verificação,
                <br />
                preencha corretamente os dados de cadastro
              </h2>
              : <>
                {codSMSEnviado ? <h3>Confirme o código de verificação (SMS)</h3> : <h3>Clique abaixo para enviar o código de verificação:</h3>}
                {codSMSEnviado && !codSMSValidado ?
                  <div className="mt-1">
                    <span style={{ color: 'red' }}>Código de verificação expira em:</span>
                    <Countdown format="mm:ss" value={getCacheCrono('cronoSMS') ?
                      Date.now() + getCacheCrono('cronoSMS') || '0' : Date.now() + 60000}
                      onChange={(v: any) => {
                        if (v > 0) sessionStorage.setItem('cronoSMS', v)
                      }}
                      onFinish={() => {
                        if (codSMSValidado) return
                        resetEnvioCodigoVerif(tipoEnvio);
                        message.error('Código expirado, solicite um novo envio')
                      }} />

                    <VerificationInput autoFocus validChars='0-9' onComplete={(v) => validarCodigoVerif(v)} />
                  </div>
                  :
                  <div className="mt-1">
                    {<a onClick={() => obterCodigoVerif(1)}>
                      <i className="fa fa-envelope mr-2" />Enviar código por SMS</a>}
                  </div>}

                <p className='mt-4 mb-0'>{msgExplicativa}</p>
              </>}
        </div>
      }


    } else if (blocoSelecionado?.tipo === 8) {
      if (!blocoSelecionado?.servico || blocoSelecionado?.campos?.length == 0) {
        message?.error('Nenhum produto cadastrado para esta etapa')
        return <></>
      }

      if (products?.length === 0) {
        let tempProducts: any = blocoSelecionado?.campos?.map((campo: Campo) => {
          if (sessionStorage?.getItem('nestedDataProduto')) {
            let nestedDataProduto = JSON.parse(sessionStorage?.getItem('nestedDataProduto') || '[]')
            let idsProdutosRev = nestedDataProduto?.produtos?.reduce((acc: any, cur: any) => {
              acc?.push(cur?.id)
              return acc
            }, [])

            if (idsProdutosRev?.includes(campo?.label?.split('§')[0])) {
              return {
                id: campo?.label?.split('§')[0],
                name: campo?.label?.split('§')[1],
                price: campo?.label?.split('§')[2],
                description: campo?.label?.split('§')[3],
                image: campo?.name,
              }
            }
          } else // split label: id_produto§name§price§description            
            return {
              id: campo?.label?.split('§')[0],
              name: campo?.label?.split('§')[1],
              price: campo?.label?.split('§')[2],
              description: campo?.label?.split('§')[3],
              maxSelection: campo?.label?.split('§')[4] || '',
              image: campo?.name,
            }
        });

        setProducts(tempProducts);
      }

      // complete each key with 4 products if dont have 4
      const groupedProductsFinal = (g: any) =>
        g?.map((group: any) => {
          if (group?.length >= 4) return group;

          let diff = 4 - group?.length;
          for (let i = 0; i < diff; i++)
            group?.push({});

          return group; // using this for complete visibility carrousel
        });

      const VerificarCupom = async () => {
        try {
          setLoading({ status: true, message: 'Validando cupom...' });
          const { data, status } = await api.get(`Cupom/VerificarCupom?codigoCupom=${cupom}&guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}`);

          if (status == 204) {
            setLoading({ status: false, message: '' });
            return message.error('Cupom usado com status inválido ou expirado')
          }

          if (parseInt(data?.qtdLimite) <= data?.uso) {
            setLoading({ status: false, message: '' });
            return message.error('Cupom atingiu limite de uso')
          }

          Modal.info({
            closable: true, icon: null,
            wrapClassName: 'remove-antd-footer',
            title: 'Digite seu CPF',
            afterClose: () => setLoading({ status: false, message: '' }),
            content: <>
              <div className='input-wrapper'>
                <InputMask id='b2e:cpfCupom' mask="999.999.999-99" maskChar={null}
                  defaultValue={sessionStorage.getItem('b2e:cpfCupom') || ''}
                  onChange={({ target: { value } }) => {
                    desaplicarCupom()

                    if (value == '') return sessionStorage.removeItem('b2e:cpfCupom')
                    sessionStorage.setItem('b2e:cpfCupom', value.replace(/\D/g, ''))
                  }} />
              </div>

              <Button type="primary" className='float-right mt-2'
                onClick={async () => {
                  if (!validateBr.cpf(sessionStorage.getItem('b2e:cpfCupom') || ''))
                    return message.error('CPF inválido')

                  try {
                    if (await VerificarCupomCpf())
                      aplicarCupom(parseFloat(sessionStorage.getItem('b2e:valorBase') || '0'))
                  } catch (error) {
                    console.log("error: ", error);
                    message.error('Erro ao validar cupom')
                  } finally {
                    setLoading({ status: false, message: '' });
                    Modal.destroyAll();
                  }
                }}>
                Prosseguir
              </Button>

              <div dangerouslySetInnerHTML={{
                __html: `<script>setTimeout(() => { document.getElementById('b2e:cpfCupom')?.focus();}, 500);</script>`,
              }} />

            </>
          });
        } catch (error) {
          console.log("error: ", error);
          message.error('Erro ao validar cupom');
          setLoading({ status: true, message: 'Validando cupom...' });
          Modal.destroyAll();
        }
      }

      const VerificarCupomCpf = async () => {
        try {
          const { data } = await api.get(`Cupom/VerificarCupomCpf?codigoCupom=${cupom}&guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}&cpf=${sessionStorage.getItem('b2e:cpfCupom')}`);

          if (data?.usoCpf != 0) {
            message.error('Cupom já usado ou indisponível para este CPF');
            return false;
          }

          message.success('Cupom validado com sucesso')
          sessionStorage.setItem('b2e:cpfCupomPreValidado', 'true')
          sessionStorage.setItem('b2e:idCupomTipo', data?.idCupomTipo)
          sessionStorage.setItem('b2e:valorDesconto', data?.valorDesconto)
          return true;
        } catch (error) {
          console.log("error: ", error);
          message.error('Erro ao validar CPF para este cupom');
          return false;
        }
      }

      return (<div className="carousel mb-2 w-100" id="carouselProducts">
        <div className="carousel-inner"> {/* Slides */}
          {groupedProductsFinal(groupedProducts)
            ?.map((group: any, index: any) => {
              if (removeEmptyKeys(group)?.length == 1)
                group = removeEmptyKeys(group)

              return (
                <div key={index} className={`carousel-item ${index === activeSlide ? 'active' : ''}`}>
                  {JSON.parse(sessionStorage?.getItem('nestedDataProduto') || '[]')?.['valor-solicitado'] &&
                    <div className="container">
                      <h4>Valor Solicitado já definido, portanto não é possível adicionar ou subtrair produtos:</h4>
                    </div>}
                  <div className="row">
                    {group?.map((product: any) => {
                      return (
                        <div className="col-md-3" key={product?.id}
                          style={{ visibility: product?.id ? 'visible' : 'hidden' }}>
                          <div className="card">
                            <div className="square-container">
                              <img src={product?.image} alt={product?.name} className="card-img-center w-100" />
                            </div>
                            <div className="card-body pb-2">
                              <h4 className="card-title">{product?.name}</h4>
                              <p className="card-text">R$ {parseFloat(product?.price)?.toFixed(2)?.replace('.', ',')}</p>
                              <div className="quantity-selector">
                                {sessionStorage?.getItem('nestedDataProduto') ?
                                  <InputNumber min={0} controls={false} disabled style={{ textAlign: 'center', width: '40px', margin: '0 5px' }}
                                    value={parseInt(JSON.parse(sessionStorage?.getItem('nestedDataProduto') || '[]')?.produtos
                                      ?.find((p: any) => p?.id == product?.id)?.quantidade || '0')} />
                                  : JSON.parse(sessionStorage?.getItem('nestedDataRev') || '[]')?.['valor-solicitado'] ? <></> :
                                    <>
                                      <Button id='-' className='avoidCorTextoMenu' icon={<MinusOutlined />} onClick={() => handleQtdDecrease(product)}
                                        disabled={productQtd[product?.id] === undefined || productQtd[product?.id] === 0} />

                                      <InputNumber min={0} controls={false} readOnly={true}
                                        style={{ textAlign: 'center', width: '40px', margin: '0 5px' }}
                                        value={productQtd[product?.id] || 0}
                                        onChange={(value: any) => handleQtdChange(product?.id, value?.replace(/\D/g, ''))}
                                      />

                                      <Button id='+' className='avoidCorTextoMenu' icon={<PlusOutlined />} onClick={() => handleQtdIncrease(product)} />
                                    </>}

                                {product?.description && <>
                                  <style>{`.anticon-info-circle { display: none; }`}</style>
                                  <i className='fa fa-external-link-alt ml-2 float-right mt-2'
                                    onClick={() => {
                                      Modal.info({
                                        closable: true,
                                        centered: true,
                                        content: <>
                                          <h3>Descrição: {product?.name}</h3>
                                          <img src={product?.image} className="card-img-center w-100 mb-2" />
                                          <p>{product?.description}</p>
                                        </>,
                                        okText: 'Fechar'
                                      });
                                    }} />
                                </>}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }
            )}
        </div>

        {groupedProductsFinal(groupedProducts)?.length > 1 && <> {/* Controls */}
          <a className="carousel-control-prev" onClick={handlePrevSlide}>
            <span className="carousel-control-prev-icon" />
          </a>
          <a className="carousel-control-next" onClick={handleNextSlide}>
            <span className="carousel-control-next-icon" />
          </a>
        </>}

        <style>
          {`.carousel-control-next, .carousel-control-prev  { width: 2%; }
              .carousel-control-next-icon, .carousel-control-prev-icon { filter: invert(100%); }
              a.carousel-control-next { right: -17px !important; }
              a.carousel-control-prev { left: -17px !important; }
            `}
        </style>


        {!sessionStorage?.getItem('nestedDataProduto') && // não permitir em revisões
          !sessionStorage?.getItem('nestedDataRev') && // não permitir em revisões
          getValorPreDefinido() > 0 ?
          <div className='float-right input-wrapper-custom mt-2'>
            <div>Insira um cupom de desconto</div>
            <input value={cupom} onChange={async ({ target: { value } }) => {
              handleResetParcelamentoB2e()
              desaplicarCupom()

              if (!value.trim()) {
                setCupom('')
                sessionStorage.removeItem('b2e:cupom')
              } else {
                setCupom(value.toUpperCase())
                sessionStorage.setItem('b2e:cupom', value.toUpperCase())
              }
            }} />
            <Button type="primary" disabled={!cupom} className='mx-2'
              onClick={() => {
                if (sessionStorage.getItem('b2e:cpfCupomPreValidado'))
                  message.error('Cupom já validado')
                else VerificarCupom()
              }}>
              Validar
            </Button>
          </div> : null}

        {blocoSelecionado?.tipoCalculo ? // Exibir Valor Total dos Produtos (Rodapé)
          <div className='d-flex justify-content-end w-100 mt-2'>
            {sessionStorage?.getItem('nestedDataProduto') ?
              <h3 className="col-md-12 text-right" style={{ color: c?.interface?.corPrimaria || '#881220' }}>
                Total: R$ {parseFloat(JSON.parse(sessionStorage?.getItem('nestedDataProduto') || '[]')?.['valor-solicitado'] || '0')?.toFixed(2)?.replace('.', ',')}
              </h3>

              : JSON.parse(sessionStorage?.getItem('nestedDataRev') || '[]')?.['valor-solicitado'] ? <></> :

                <h3 className="col-md-12 text-right" style={{ color: c?.interface?.corPrimaria || '#881220' }}>
                  Total: R$ <span id='totalOriginal'>{totalValue?.toFixed(2)?.replace('.', ',')}</span>
                </h3>}
          </div>
          : null}
      </div >)






    } else if (blocoSelecionado.tipo === 9) {
      // previnindo que se nenhum campo for selecionado em etapas, não apareça as tabs:
      const etapaAtiva = blocoSelecionado.campos.filter((campo: Campo) => { return campo.habilitar }).length > 0;

      // garantindo que sempre haja uma aba aberta (form wizard)
      if (allAvalistas.length == 0) document.getElementById('startAvalistas')?.click()

      return (etapaAtiva && <>
        <div className='my-2 ml-3 d-flex justify-content-start w-100'>
          {allAvalistas?.map((f: any, idx: any, arr: any) => {
            return <>
              {idx != 0 && //se a tab não for a primeira, mostra o botão de deletar
                <button id={`tab${idx + 1}Avalista`} type="button" className='tabAntd' style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                  borderBottom: tabAvalista === idx && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: tabAvalista === idx && c?.interface?.corPrimaria || '#636363',
                  marginRight: '-5px'
                }}>
                  <i className='fa fa-times my-auto' onClick={() => delTabAvalista(idx)} />
                </button>}

              <button id={`tab${idx + 1}Avalista`} type="button" className='mr-1 tabAntd'
                onClick={() => setTabAvalista(idx)} style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                  borderBottom: tabAvalista === idx && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: tabAvalista === idx && c?.interface?.corPrimaria || '#636363',
                }}>
                Avalista {idx + 1}
              </button>
            </>
          })}

          {totalTabRefC < 2 && <i className='fa fa-plus p-2 mr-1 my-auto' id='startAvalistas' onClick={addTabAvalista}
            style={{ color: showTabRefC === 1 && c?.interface?.corPrimaria || '#ffffff' }} />}
        </div>

        {allAvalistas.length > 0 && renderAvalistas(allAvalistas[tabAvalista])}
      </>);

      // @ts-ignore
      function updateValueAvalistas(pos: any, newValue: any) {
        allAvalistas[tabAvalista][pos].value = newValue
        const tabChanges = allAvalistas;
        setAllAvalistas([...tabChanges]);
      }

      // @ts-ignore
      function renderAvalistas(campos: any) {
        // setando para usar ao enviar o formulário:
        sessionStorage.setItem('allAvalistas', JSON.stringify(allAvalistas));

        return campos?.map((campo: Campo, i: number) => {
          if (campo && campo.habilitar)
            switch (campo.type) {
              case 'date':
                return <div className='col-md-6 mb-3 datepicker-wrapper'>
                  {LabelRequired(null, campo.label, campo.obrigatorio)}
                  <DatePicker
                    format={campo.name.includes('desde') ?
                      ['MM/YYYY', 'MMYYYY'] : ['DD/MM/YYYY', 'DDMMYYYY']}
                    picker={campo.name.includes('desde') ? 'month' : 'date'}
                    value={campo?.value && campo?.value != 'N/I' ?
                      moment(campo?.value) : undefined}
                    placeholder="11/1111"
                    suffixIcon={<Icon name="date" size={15} color="#3A434D" />}
                    disabledDate={current => current && current > moment().endOf('day')}  /* datas até hj */
                    onChange={(date: any) => {
                      const isoDate = date?.toISOString();
                      updateValueAvalistas(i, isoDate); //.format('YYYY-MM-DD 00:00:00')
                    }}
                  />
                </div>

              default: //
                return <>
                  {campo.name.includes('uf') ?
                    <div className='col-md-6 mb-3 select-wrapper'>
                      {LabelRequired(null, campo.label, campo.obrigatorio)}
                      <Select value={campo.value || ''}
                        optionFilterProp="children" showSearch
                        placeholder="Selecione uma opção"
                        onChange={(v) => { updateValueAvalistas(i, v); }}>
                        <Option value='' disabled>Selecione uma opção</Option>
                        {Object.keys(ufs).map((uf) =>
                          <Option key={uf} value={uf}>{`${uf} - ${ufs[uf]}`}</Option>)}
                      </Select>
                    </div>
                    :
                    ['avalista-forma-pagamento'].includes(campo.name) ?
                      <div className='col-md-6 mb-3 select-wrapper'>
                        {LabelRequired(null, campo.label, campo.obrigatorio)}
                        <Select value={campo.value || ''}
                          optionFilterProp="children" showSearch
                          placeholder="Selecione uma opção"
                          onChange={(v) => { updateValueAvalistas(i, v); }}>
                          <Option value='' disabled>Selecione uma opção</Option>
                          {f.map(({ id, descricao: desc }: any) =>
                            <Option key={id} value={id}>{desc}</Option>)}
                        </Select>
                      </div>
                      :
                      ['avalista-modalidade'].includes(campo.name) ?
                        <div className='col-md-6 mb-3 select-wrapper'>
                          {LabelRequired(null, campo.label, campo.obrigatorio)}
                          <Select value={campo.value || ''}
                            optionFilterProp="children" showSearch
                            placeholder="Selecione uma opção"
                            onChange={(v) => { updateValueAvalistas(i, v); }}>
                            <Option value='' disabled>Selecione uma opção</Option>
                            {m.map(({ id, descricao: desc }: any) =>
                              <Option key={id} value={id}>{desc}</Option>)}
                          </Select>
                        </div>
                        :
                        ['avalista-profissao'].includes(campo.name) ?
                          <div className='col-md-6 mb-3 select-wrapper'>
                            {LabelRequired(null, campo.label, campo.obrigatorio)}
                            <Select value={campo.value || ''}
                              optionFilterProp="children" showSearch
                              placeholder="Selecione uma opção"
                              onChange={(v) => { updateValueAvalistas(i, v); }}>
                              <Option value='' disabled>Selecione uma opção</Option>
                              {p.map(({ id, descricao: desc }: any) =>
                                <Option key={id} value={id}>{desc}</Option>)}
                            </Select>
                          </div>
                          :
                          ['avalista-natureza-ocupacao'].includes(campo.name) ?
                            <div className='col-md-6 mb-3 select-wrapper'>
                              {LabelRequired(null, campo.label, campo.obrigatorio)}
                              <Select value={`${campo.value || ''}`}
                                optionFilterProp="children" showSearch
                                placeholder="Selecione uma opção"
                                onChange={(v) => { updateValueAvalistas(i, v); }}>
                                <Option value='' disabled>Selecione uma opção</Option>
                                {o.map(({ id, descricao: desc }: any) =>
                                  <Option key={id} value={`${id}`}>{desc}</Option>)}
                              </Select>
                            </div >
                            :
                            ['avalista-nacionalidade'].includes(campo.name) ?
                              <div className='col-md-6 mb-3 select-wrapper'>
                                {LabelRequired(null, campo.label, campo.obrigatorio)}
                                <Select value={`${campo.value || ''}`}
                                  optionFilterProp="children" showSearch
                                  placeholder="Selecione uma opção"
                                  onChange={(v) => { updateValueAvalistas(i, `${v}`); }}>
                                  <Option value='' disabled>Selecione uma opção</Option>
                                  {n?.map(({ id, descricao: desc }: any) =>
                                    <Option key={id} value={`${id}`}>{desc}</Option>)}
                                </Select>
                              </div >
                              :
                              ['avalista-sexo'].includes(campo.name) ?
                                <div className='col-md-6 mb-3 select-wrapper'>
                                  {LabelRequired(null, campo.label, campo.obrigatorio)}
                                  <Select value={campo.value || ''}
                                    optionFilterProp="children" showSearch
                                    placeholder="Selecione uma opção"
                                    onChange={(v) => { updateValueAvalistas(i, v); }}>
                                    <Option value='' disabled>Selecione uma opção</Option>
                                    {s?.map(({ id, descricao: desc }: any) =>
                                      <Option key={id} value={id}>{desc}</Option>)}
                                  </Select>
                                </div>
                                :
                                ['avalista-estado-civil'].includes(campo.name) ?
                                  <div className='col-md-6 mb-3 select-wrapper'>
                                    {LabelRequired(null, campo.label, campo.obrigatorio)}
                                    <Select value={`${campo.value || ''}`}
                                      optionFilterProp="children" showSearch
                                      placeholder="Selecione uma opção"
                                      onChange={(v) => { updateValueAvalistas(i, v); }}>
                                      <Option value='' disabled>Selecione uma opção</Option>
                                      {e?.map(({ id, descricao: desc }: any) =>
                                        <Option key={id} value={`${id}`}>{desc}</Option>)}
                                    </Select>
                                  </div >
                                  :
                                  ['avalista-comprovante-pf'].includes(campo.name) ?
                                    <div className='col-md-6 mb-3 select-wrapper'>
                                      {LabelRequired(null, campo.label, campo.obrigatorio)}
                                      <Select value={campo.value || ''}
                                        optionFilterProp="children" showSearch
                                        placeholder="Selecione uma opção"
                                        onChange={(v) => { updateValueAvalistas(i, v); }}>
                                        <Option value='' disabled>Selecione uma opção</Option>
                                        {com?.map(({ id, descricao: desc }: any) =>
                                          <Option key={id} value={id}>{desc}</Option>)}
                                      </Select>
                                    </div>
                                    :
                                    <div className='col-md-6 mb-3 input-wrapper'>
                                      {LabelRequired(null, campo.label, campo.obrigatorio)}
                                      {campo.name.includes('cpf') ?
                                        <InputMask maskChar={null} mask="999.999.999-99"
                                          placeholder={campo.label} value={campo.value || ''}
                                          onChange={({ target: { value: v } }: any) => {
                                            updateValueAvalistas(i, v);
                                          }} />
                                        :
                                        campo.name.includes('cnpj') ?
                                          <InputMask maskChar={null} mask="99.999.999/9999-99"
                                            placeholder={campo.label} value={campo.value || ''}
                                            onChange={({ target: { value: v } }: any) => {
                                              updateValueAvalistas(i, v);
                                            }} />
                                          :
                                          ['avalista-cep-dadoscomerciais-pf'].includes(campo.name) ?
                                            <InputMask maskChar={null} mask="99999-999"
                                              placeholder={campo.label} value={campo.value || ''}
                                              onChange={async ({ target: { value: v } }: any) => {
                                                updateValueAvalistas(i, v);
                                                if (v.length != 9) return;
                                                try {
                                                  const { data }: any = await axios.get(`https://viacep.com.br/ws/${v}/json/`)
                                                  if (data?.erro) return message.error('erro ao buscar CEP');
                                                  allAvalistas[tabAvalista].map(({ name, value }: Campo, i: number) => {
                                                    if (value) return; //se já tiver valor, não atualiza
                                                    if (name == 'avalista-logradouro-dadoscomerciais-pf') updateValueAvalistas(i, data?.logradouro);
                                                    if (name == 'avalista-bairro-dadoscomerciais-pf') updateValueAvalistas(i, data?.bairro);
                                                    if (name == 'avalista-cidade-dadoscomerciais-pf') updateValueAvalistas(i, data?.localidade);
                                                    if (name == 'avalista-uf-dadoscomerciais-pf') updateValueAvalistas(i, data?.uf);
                                                    if (name == 'avalista-codigo-ibge-dadoscomerciais-pf') updateValueAvalistas(i, data?.ibge);
                                                  })
                                                } catch (e) {
                                                  //console.log("e: ", e);
                                                  message.error('erro ao buscar CEP');
                                                }
                                              }} />
                                            :
                                            ['avalista-cep-pf'].includes(campo.name) ?
                                              <InputMask maskChar={null} mask="99999-999"
                                                placeholder={campo.label} value={campo.value || ''}
                                                onChange={async ({ target: { value: v } }: any) => {
                                                  updateValueAvalistas(i, v);
                                                  if (v.length != 9) return;
                                                  try {
                                                    const { data }: any = await axios.get(`https://viacep.com.br/ws/${v}/json/`)
                                                    if (data?.erro) return message.error('erro ao buscar CEP');
                                                    allAvalistas[tabAvalista].map(({ name, value }: Campo, i: number) => {
                                                      if (value) return; //se já tiver valor, não atualiza
                                                      if (name == 'avalista-logradouro-pf') updateValueAvalistas(i, data?.logradouro);
                                                      if (name == 'avalista-bairro-pf') updateValueAvalistas(i, data?.bairro);
                                                      if (name == 'avalista-cidade-pf') updateValueAvalistas(i, data?.localidade);
                                                      if (name == 'avalista-uf-pf') updateValueAvalistas(i, data?.uf);
                                                      if (name == 'avalista-codigo-ibge-pf') updateValueAvalistas(i, data?.ibge);
                                                    })
                                                  } catch (e) {
                                                    //console.log("e: ", e);
                                                    message.error('erro ao buscar CEP');
                                                  }
                                                }} />
                                              :
                                              ['avalista-fone-celular', 'avalista-fone-residencial', 'avalista-tel-dadoscomerciais-pf'].includes(campo.name) ?
                                                <InputMask maskChar={null} mask="(99) 999999999"
                                                  placeholder={campo.label} value={campo.value || ''}
                                                  onChange={({ target: { value: v } }: any) => {
                                                    updateValueAvalistas(i, v);
                                                  }} />
                                                :
                                                ['avalista-codigo-ibge', 'avalista-cnh', 'avalista-numero_serguranca_cnh'].includes(campo.name) ?
                                                  <input placeholder={campo.label} value={campo.value || ''}
                                                    disabled={'avalista-codigo-ibge' == campo.name}
                                                    onChange={({ target: { value: v } }: any) => {
                                                      // only allow numbers
                                                      updateValueAvalistas(i, v.replace(/\D/g, ''));
                                                    }} />
                                                  :
                                                  campo.name == 'avalista-percentual' ?
                                                    <input placeholder={campo.label} value={campo.value || ''}
                                                      onChange={({ target: { value: v } }: any) => {
                                                        // only allow values between 0 and 100
                                                        if (+v.replace(/\D/g, '') >= 100) updateValueAvalistas(i, `${100}`);
                                                        else if (+v.replace(/\D/g, '') <= 0) updateValueAvalistas(i, `${0}`);
                                                        else updateValueAvalistas(i, `${v.replace(/\D/g, '')}`);
                                                      }} />
                                                    :
                                                    campo.name == 'avalista-renda-mensal' ?
                                                      <ReactIntlCurrencyInput defaultValue={0}
                                                        value={parseFloat(campo.value || '0')}
                                                        max={999999999.99} currency='BRL'
                                                        config={currencyConfig} autoSelect={true}
                                                        onBlur={(_, currentValue: number) => {
                                                          if (currentValue == 0)
                                                            updateValueAvalistas(i, '');
                                                        }}
                                                        onChange={(_, currentValue: number) => {
                                                          updateValueAvalistas(i, `${currentValue.toFixed(2)}`);
                                                        }} />
                                                      :
                                                      <input placeholder={campo.label} value={campo.value || ''}
                                                        onChange={({ target: { value: v } }: any) => {
                                                          if (campo.name.includes('mail'))
                                                            updateValueAvalistas(i, `${v}`?.replace(/[^a-zA-Z0-9@._-]/g, ''));

                                                          else updateValueAvalistas(i, v);
                                                        }} />}
                                    </div>
                  }
                </>
            }
        });
      }

    } else if (blocoSelecionado.tipo === 15) {
      return parse(blocoSelecionado?.servico?.replace(/<img /g, "<img class='img-fluid'") || '')

    } else if (blocoSelecionado.tipo === 2 && blocoSelecionado.expressao?.includes('true|')) { // etapa adicional com abas
      const nomeEtapa = blocoSelecionado?.nome?.replace(/ /g, '-');

      // registrando aba adicional e seus campos:
      sessionStorage.setItem(`tabEtapaAdicionalCampos-${nomeEtapa}`,
        JSON.stringify(blocoSelecionado.campos))

      allCamposEtapasAdicionais = [...allCamposEtapasAdicionais, blocoSelecionado.campos.map(({ name }: any) => name)];
      sessionStorage.setItem('allCamposEtapasAdicionais', // removendo duplicados:
        JSON.stringify(new Set(allCamposEtapasAdicionais.flat())));

      // armazenando o nome da aba:
      sessionStorage.setItem(`tabEtapaAdicionalNomeAba-${nomeEtapa}`,
        blocoSelecionado?.expressao?.split('|')[1]?.replace(/ /g, '-') || blocoSelecionado?.nome || 'Etapa Adicional')

      // previnindo que se nenhum campo for selecionado em etapas, não apareça as tabs:
      const etapaAtiva = blocoSelecionado.campos.filter((campo: Campo) => { return campo.habilitar }).length > 0;

      const QtdAbasEtapaAtual =
        JSON.parse(sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapa}`) || "0");

      // iniciando abas quando for uma revisão:
      let nestedDataRevKeys: any = Object.keys(JSON.parse(sessionStorage?.getItem('nestedDataRev') || '{}'));
      if (nestedDataRevKeys.length > 0) {
        if (!sessionStorage.getItem(`startedTabEtapaAdicional-${nomeEtapa}`))
          try {
            let tabEtapaAdicionalRev = nestedDataRevKeys?.filter((k: any) => k.includes(`${blocoSelecionado.campos[0].name}-`));
            tabEtapaAdicionalRev.map((k: any, idx: any) => {
              setTimeout(() => {
                document.getElementById(`startTabEtapaAdicional-${nomeEtapa}`)?.click()
              }, 500);
            })
          } catch (error) {
            console.log("error: ", error);

          } finally {
            sessionStorage.setItem(`startedTabEtapaAdicional-${nomeEtapa}`, 'true')
          }

      } else { // garantindo que sempre haja uma aba aberta (form wizard)
        sessionStorage.setItem(`tabEtapaAdicionalAtual`, nomeEtapa)

        if (QtdAbasEtapaAtual == 0)
          document.getElementById(`startTabEtapaAdicional-${nomeEtapa}`)?.click()
      }

      return (etapaAtiva && <>
        <div className='my-2 ml-3 d-flex justify-content-start w-100'>
          {Array.from({ length: QtdAbasEtapaAtual }, (_, idx) => {
            return <>
              {idx != 0 && //se a tab não for a primeira, mostra o botão de deletar
                <button id={`delTabEtapaAdicionalId-${idx + 1}`} type="button" className='tabAntd' style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0, marginRight: '-5px',
                  borderBottom: tabEtapaAdicional === idx && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: tabEtapaAdicional === idx && c?.interface?.corPrimaria || '#636363',
                }}>
                  <i className='fa fa-times my-auto' onClick={() => delTabEtapaAdicional(idx, blocoSelecionado)} />
                </button>}

              <button id={`btnTabEtapaAdicionalId-${nomeEtapa + '-' + (idx + 1)}`} type="button" className='mr-1 tabAntd'
                onClick={() => setTabEtapaAdicional(idx)} style={{
                  backgroundColor: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0,
                  borderBottom: tabEtapaAdicional === idx && `2px solid ${c?.interface?.corPrimaria}` || `2px solid #ffffff`,
                  color: tabEtapaAdicional === idx && c?.interface?.corPrimaria || '#636363',
                }}>
                {blocoSelecionado?.expressao?.split('|')[1]} {idx + 1}
              </button>
            </>
          })}

          <i className='fa fa-plus p-2 mr-1 my-auto' id={`startTabEtapaAdicional-${nomeEtapa}`}
            onClick={() => addTabEtapaAdicional(blocoSelecionado)}
            style={{ color: showTabRefC === 1 && c?.interface?.corPrimaria || '#ffffff' }} />
        </div>


        {JSON.parse(sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapa}`) || '0') > 0
          && renderTabEtapaAdicional()}
      </>);

      // @ts-ignore
      function renderTabEtapaAdicional() {
        const campos = JSON.parse(sessionStorage.getItem(`tabEtapaAdicionalCampos-${nomeEtapa}`) || '[]')

        return campos.map((campo: Campo, idx: any) => {
          return renderFields({ ...campo, name: `${campo.name}-${tabEtapaAdicional + 1}` }, idx)
        });
      }

    } else {
      return blocoSelecionado.campos.map((campo: Campo, idx: any) => {
        if (campo.name == 'cnpj' && blocoSelecionado.integracao != 0 && blocoSelecionado.tipo == 4)
          sessionStorage.setItem('autoCNPJ', `${blocoSelecionado.integracao}`)

        return renderFields(campo, idx)
      })
    }
  };

  return <>
    <ModalLoading open={loading.status} message={loading.message} />

    <div className="form-row">
      <div className="col-lg-12 m-0">
        <SectionTitle title={bloco?.nome} />
      </div>
    </div>

    {bloco.tipo === 15 ? // etapa informativa
      <div className="col-md-12">{bloco && RenderBloco(bloco)}</div> :
      <div className="form-row">{bloco && RenderBloco(bloco)}</div>}
  </>
}

export default Bloco;