import { css } from 'styled-components';

export const custom = css`
  /* Only demo */
  @media (max-width: 1000px) {
    .welcome-message {
      display: none;
    }
  }

  .profile-element {
    display: flex;
    justify-content: center;

    .logo {
      width: 70%;
    }
  }

  .logout-menu > a {
    font-weight: 400 !important;
    font-size: 14px;
    color: #535463 !important;

    display: flex !important;
    align-items: center;
  }

  label {
    margin-bottom: 4px;
  }

  .form-row > .col,
  .form-row > [class*='col-'] {
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .error {
    font-size: 10px;
    color: #ed5565;
    margin-top: 3px;
    font-weight: 600;
  }
  .custom-bg {
    background-color: ${(props) => props.theme.colors['custom-bg']};
  }
  svg {
    vertical-align: initial;
  }
  .ant-table-thead {
    th {
      font-weight: bold !important;
      text-transform: uppercase !important;
    }
  }
  .ant-table-cell {
    font-size: 12px;
    text-transform: uppercase !important;
  }
  iframe {
    border: 0 !important;
  }
`;
