import { FC, InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import parse from 'html-react-parser'

import './styles.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

const Input: FC<Props> = ({ label, ...rest }) => {
  const [field, meta] = useField(rest as any);

  return (
    <div className="input-wrapper">
      {label.includes('required-field') ? parse(label) : <label htmlFor={rest.name}>{parse(label)}</label>}
      <input id={rest.name} {...field} {...rest} />
      {meta.touched && meta.error ? (
        <span className="error">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default Input;
