import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { Switch } from '@components/Form';
import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { EtapaContext } from '@contexts/EtapaContext';

type BlocoCamposPropostaTabsProps = {
  camposArray: any;
  title: string;
};

const BlocoCamposPropostaTabs: React.FC<BlocoCamposPropostaTabsProps> = ({
  camposArray,
  title,
}) => {
  const { etapaPessoa, tipoPessoa, etapaIdx } = useContext(EtapaContext);

  const { setFieldValue, values } = useFormikContext<ConfigTypes>();
  /* const [camposHabilitados, setCamposHabilitados] = useState<Boolean[]>([]);
  const [camposRequeridos, setCamposRequeridos] = useState<Boolean[]>([]); */

  const habilitarCampo = (habilitar: boolean, field: Campo) => {
    setFieldValue(
      `etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field)}`,
      { ...field, habilitar },
    );
  };

  const requererCampo = (obrigatorio: boolean, field: Campo) => {
    setFieldValue(
      `etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field)}.obrigatorio`,
      obrigatorio,
    );
  };

  const getIndex = (field: Campo) =>
    etapaPessoa[etapaIdx]?.campos.findIndex((el) => el.name === field.name);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <h3>{title}</h3>
        </div>
        {/* <div className="col-lg-3">
          <Switch
            name="habilitarTodos"
            checked={camposHabilitados.every((el) => el)}
            label="Selecionar Todos"
            onChange={(value) => selectAll(value)}
          />
        </div>
        <div className="col-lg-3">
          <Switch
            name="obrigarTodos"
            checked={camposRequeridos.every((el) => el)}
            label="Requerer Todos"
            disabled={!camposHabilitados.every((el) => el)}
            onChange={(value) => requireAll(value)}
          />
        </div> */}
      </div>
      {camposArray
        .filter((field: Campo) => field.name.includes('-1') && !field.name.includes('data-faturamento-1')) // mostrar só os 1ºs campos
        .map((field: Campo, index: number) => {

          return (<div className="form-row" key={index}>
            <div className="col-lg-6">{field.label.replace(/\d+/g, '')}</div>
            <div className="col-lg-3">
              <Switch
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field,)}.habilitar`}
                checked={etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.habilitar}
                label="Habilitar"
                onChange={(value) => habilitarCampo(value, field)}
              />
            </div>
            <div className="col-lg-3">
              <Switch
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field,)}.obrigatorio`}
                checked={etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.obrigatorio}
                label="Obrigatório"
                onChange={(value) => requererCampo(value, field)}
                disabled={!etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.habilitar}
              />
            </div>
          </div>)
        })}

      {camposArray // 
        .filter((field: Campo) => !field.name.includes('-1') || field.name.includes('data-faturamento-1')) // ocultar só os 1ºs campos
        .map((field: Campo, index: number) => {

          etapaPessoa[etapaIdx].campos[getIndex(field)].obrigatorio = false;
          etapaPessoa[etapaIdx].campos[getIndex(field)].habilitar = true;

          return (<div className="form-row d-none" key={index}>
            <div className="col-lg-6">{field.label.replace(/\d+/g, '')}</div>
            <div className="col-lg-3">
              <Switch
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field,)}.habilitar`}
                checked={etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.habilitar}
                label="Habilitar"
                onChange={(value) => habilitarCampo(value, field)}
              />
            </div>
            <div className="col-lg-3">
              <Switch
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field,)}.obrigatorio`}
                checked={etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.obrigatorio}
                label="Obrigatório"
                onChange={(value) => requererCampo(value, field)}
                disabled={
                  !etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.habilitar
                }
              />
            </div>
          </div>
          )
        })}
    </>
  );
};

export default BlocoCamposPropostaTabs;