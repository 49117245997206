// @ts-nocheck
import { useEffect, useState, useContext } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import { Helmet } from 'react-helmet';
import Cards from 'react-credit-cards-2';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { formaPagamentoOptions as f } from '@services/mock';

import moment from 'moment';
import { validateBr } from 'js-brasil';

import { Button, message, Modal, Select as AntdSelect, Tooltip, Input } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined, } from '@ant-design/icons';

import Card from '@components/Card';
import ModalLoading from '@components/Modal/ModalLoading';

import Bloco from './Bloco';
import { Bloco as BlocoType, EtapaType } from '@pages/Configuracao/ConfigTypes';
import { Campo } from '@pages/Configuracao/ConfigTypes';
import ModalValidacao from '@components/Modal/ModalValidacao';

import { initialValues } from './initialValues';

import api from '@services/api';
import customValidation from '@utils/customValidation';
import useInspinia from '@hooks/useInspinia';
import { GlobalContext } from '@contexts/GlobalContext';
import { useParams, useHistory, useLocation, Redirect } from 'react-router-dom';
import './styles.scss';
import { Select, Option, SectionTitle } from '@components/Form';
import AwaitFull from '@components/Spinner/AwaitFull';

import RejectImg from '@assets/images/reject.png';
import AcceptImg from '@assets/images/accept.png';
import BillImg from '@assets/images/bill.png';

import './styles.scss';
import parse from 'html-react-parser'
import { removeEmptyKeys } from '@utils/removeEmptyKeys';
import { boolean } from 'yup';

import ModalPagamentoIugu from '@components/Modal/ModalPagamentoIugu';
import { camposSocios } from '@pages/Configuracao/camposPessoaJuridica';
import { camposAvalistaDadosComerciais, camposAvalistaEndereco, camposAvalistaIdentificacao, camposAvalistaProposta } from '../Configuracao/camposAvalistaIntegracao';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import emptyValues from '@/utils/emptyValues';

type CampoType = {
  [field: string]: string | number;
};

type EtapasType = {
  pf: BlocoType[];
  pj: BlocoType[];
  dlinkpj: BlocoType[];
  dlinkpf: BlocoType[];
};

type TipoPropostaType = {
  tipo: string;
};

type tipoPessoaType = {
  label: string;
  value: string;
};

let sendDataWizardPage: any = {};
let sendDataOnePage: any = {};

function Cadastrar() {
  const boolCodVerificadoNext = () => { // não permite PRÓXIMO/ENVIAR se não houver código verificado
    const { habilitar: codObrigatorio, servico, tipo }: any = etapaPessoa[current]

    if (tipo != 7) // se a etapa for diferente de 'Código de Verificação',
      return false
    else if (codObrigatorio && servico?.includes('E-mail') && !sessionStorage.getItem('codEmailValidado'))
      return true
    else if (codObrigatorio && servico?.includes('SMS') && !sessionStorage.getItem('codSMSValidado'))
      return true
    else
      return false
  }

  const boolCodVerificadoPrevious = () => { // não permite ANTERIOR se houver código verificado
    if (etapaPessoa[current]?.servico?.includes('E-mail') && sessionStorage.getItem('codEmailValidado'))
      return true
    else if (etapaPessoa[current]?.servico?.includes('SMS') && sessionStorage.getItem('codSMSValidado'))
      return true
    else return false
  }

  useInspinia();
  const { pathname: url, href } = window.location;
  const { userAgent } = navigator;
  const sessionStorageEncryptSupport = userAgent.includes("Chrome") || userAgent.includes("Edge") || userAgent.includes("OPR");

  const [tipoPessoa, setTipoPessoa] = useState<tipoPessoaType[] | []>([]);
  const [tipoProposta, setTipoProposta] = useState<string>('');
  const [selectTipoProposta, setSelectTipoProposta] = useState<TipoPropostaType | null>(null);
  const [propostaEnviada, setPropostaEnviada] = useState(false);
  const [onePage, setOnePage] = useState(false);
  var nestedDataRev = JSON.parse(sessionStorage.getItem('nestedDataRev') || '{}')

  const boolDlinkProposta: boolean = url.includes('/dlink');
  const boolRevisaoProposta: boolean = href.toLowerCase().split('/cadastrar/')[1]?.replace(/[^\w\s]/gi, '') !== ('' || undefined);
  const boolSiteFrameProposta: boolean = ['site', 'frame'].some(h => href.includes(h));

  const boolProcessoProposta: boolean =
    !!localStorage.getItem('token') && // prosseguir se houver token 
    boolSiteFrameProposta == false && // parar se site ou frame
    boolDlinkProposta == false && // parar se dlink
    boolRevisaoProposta == false; // parar se revisão

  const RouteEnvioProposta = () => {
    switch (true) {
      case boolSiteFrameProposta || boolDlinkProposta:
        return 'Proposta/Public'
      case boolRevisaoProposta:
        return 'Proposta/Revisao'
      default:
        return 'Proposta/Private'
    }
  }

  const { executeRecaptcha } = useGoogleReCaptcha();
  if (RouteEnvioProposta().includes('Public')) {
    const handleRecaptcha = async () => {
      // Execute o reCAPTCHA v3 e obtenha o token
      if (!executeRecaptcha) return;
      const token = await executeRecaptcha();
      sessionStorage.setItem('tokenCaptchaPublic', token);
    };  // Execute o reCAPTCHA v3 assim que o componente for montado
    handleRecaptcha();
  }

  const currSendData = () => removeEmptyKeys({
    ...sendDataWizardPage, ...sendDataOnePage, // setando valores padrões abaixo:
    'tipo-proposta': sessionStorage.getItem('b2e:tipoPessoa') || tipoProposta?.replace('dlink', '') || '',
    'codigo-instituicao': sessionStorage.getItem('b2e:instituicao'),
    'usuario-logado': boolSiteFrameProposta ? '' : sessionStorage.getItem('loginLogged'),
  });

  useEffect(() => {
    const timerSalvarProcessoProposta = setInterval(async () => {

      // se atender as condições, não prosseguir:
      if (!boolProcessoProposta || !!sessionStorage.getItem('propostaEnviada')) return;

      // se OnePage, obter dados do form: 
      if (sessionStorage.getItem('formOnePage') == 'true')
        await sendFormOnePage(true);

      // false: sem erros, true: com erros
      else if (await processFormWizard()) return;

      // se não houver novos dados para enviar:
      if (sessionStorage.getItem('currSendData') == JSON.stringify(currSendData())) return;

      // atualizar dados e salvar, para verificar no próximo ciclo:
      sessionStorage.setItem('currSendData', JSON.stringify(currSendData()));

      // verificar se length é maior do que a ocupação de valores padrões
      if (Object.keys(currSendData()).length > 3)
        api.post(`/ProcessoProposta/SalvarProcesso/${sessionStorage.getItem('b2e:instituicao')}`, currSendData());
    }, 10000);

    return () => clearInterval(timerSalvarProcessoProposta);
  }, []);

  useEffect(() => {
    const timerDescartarProcessoProposta = setInterval(async () => {
      if (!!sessionStorage.getItem('descartarProcessoProposta')) return;
      if (boolProcessoProposta && !!sessionStorage.getItem('propostaEnviada')) {
        api.delete(`/ProcessoProposta/DescartarProcesso/${sessionStorage.getItem('b2e:instituicao')}`);
        sessionStorage.setItem('descartarProcessoProposta', 'true');
      }
    }, 1000);

    return () => clearInterval(timerDescartarProcessoProposta);
  }, [propostaEnviada]);

  const history = useHistory();
  const { subdominio } = useParams<{ subdominio: string }>();

  const [etapasFinais, setEtapasFinais] = useState<any>({});
  const [codigoProposta, setCodigoProposta] = useState('');
  const [statusIdProposta, setStatusIdProposta] = useState('');
  const [resetFormulario, setResetFormulario] = useState(false);
  const [iframeRedirect, setIframeRedirect] = useState(false);
  const [iframeURL, setIframeURL] = useState('');

  const [selectDlink, setSelectDlink] = useState(false);

  const params = useParams<{ codProposta: string }>();
  const allParams = useLocation();

  const [etapas, setEtapas] = useState<EtapasType>({ pf: [], pj: [], dlinkpj: [] });

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState({ status: false, message: '' })

  const [noConfig, setNoConfig] = useState(false);
  const [isOpenValidateModal, setIsOpenValidateModal] = useState(false);
  const { instituicao, configuracoes: c, loadFormOptions } = useContext(GlobalContext);

  const [msgRecusa, setMsgRecusa] = useState('');

  const instituicaoStoraged = sessionStorage.getItem('b2e:instituicao');
  const etapaPessoa = (etapas as EtapaType)[tipoProposta];

  const [sendButton, setSendButton] = useState(false);
  const [modalFormOnePage, setModalFormOnePage] = useState(false);
  const [labelsError, setLabelsError] = useState<any>([])
  const [idsErrors, setIdsErrors] = useState<any>([])

  const [interfaceInstituicao, setInterfaceInstituicao] = useState<any>(null)

  const pendingDocProRegex = /(Precisamos\s*Tirar\s*sua\s*|\s*Envie\s*a\s*|Agora\s*envie\s*o\s*)/g;
  const customValidates = ['b2e:Dia-de-Vencimento', 'b2e:Valor-Solicitado', 'b2e:Quantidade-de-Parcelas', 'b2e:Forma-de-Pagamento',
    'money+:Dia-de-Vencimento', 'money+:Valor-Solicitado', 'money+:Quantidade-de-Parcelas']
  const isoDateRegex = /\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z\b/;

  // usando nas validações onePage e wizard:
  let allCamposEtapasAdicionais = JSON.parse(sessionStorage.getItem('allCamposEtapasAdicionais') || '[]');
  const ignoreValidate = (id: string): boolean =>
    camposSocios.some(c => id == c.name) ||
    camposAvalistaEndereco.some(c => id == c.name) ||
    camposAvalistaProposta.some(c => id == c.name) ||
    camposAvalistaIdentificacao.some(c => id == c.name) ||
    camposAvalistaDadosComerciais.some(c => id == c.name) ||
    allCamposEtapasAdicionais?.some(campoName => id.includes(campoName)) ||
    id == '';

  const EtapaAtual = etapaPessoa?.[current];
  const EtapaFinal = etapaPessoa?.[etapaPessoa?.length - 1];
  const boolHasRespostaCustomizada = interfaceInstituicao?.respostaCadastro?.mensagemResposta?.habilitar || false;
  const boolIsEtapaFinal = current === etapaPessoa?.length - 1;

  const handleReturnProposta = (d: any) => {
    setStatusIdProposta(`${d?.statusId}` || '0');

    if (!d?.codProposta) return;
    setFieldValue('codigo-proposta', d?.codProposta);
    sessionStorage.setItem('codigo-proposta', d?.codProposta);
    setCodigoProposta(d?.codProposta || '');

    sessionStorage.setItem('mensagemParecer', d?.mensagemParecer || '');
  }

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (data, { setFieldValue }) => {
      try {
        setLoading({ message: 'Processando os dados da proposta...', status: true, });
        await processFormWizard();
        setLoading({ message: 'Enviando proposta...', status: true, });

        if (boolIsEtapaFinal == false && sessionStorage.getItem('revisaoProposta'))
          return next(); // se revisão de proposta, enviar somente na última etapa

        if (EtapaAtual?.botaoEnvio || boolIsEtapaFinal) {
          const { data } = await api.post(RouteEnvioProposta(), {
            ...removeEmptyKeys(nestedDataRev),
            ...removeEmptyKeys(sendDataWizardPage),
            "codigo-proposta": sessionStorage.getItem('codigo-proposta') || params.codProposta,
            "codigo-instituicao": sessionStorage.getItem('b2e:instituicao'),
            'tipo-proposta': `${tipoProposta.replace('dlink', '')}`,
            'aguardar-resposta': EtapaAtual?.['Aguardar-Resposta'] ? 'S' : 'N',
            'pre-analise': EtapaAtual?.['Pre-Analise'] ? 'S' : 'N',
            'grecaptcha': RouteEnvioProposta().includes('Public') ? sessionStorage.getItem('tokenCaptchaPublic') : '',
          });

          if (data?.statusId == -1)
            message.error(data?.mensagensErro[0] || 'Erro ao enviar proposta', 6);

          /* if (data?.sucesso == false)
            throw new Error(); */

          await handleReturnProposta(data);

          if (EtapaAtual?.['Pre-Analise'] || EtapaAtual?.['Aguardar-Resposta'] || data?.sucesso == false) {
            const { data: data2 } = await api.get(`Proposta/ObterParecer?guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}&codigoProposta=${data?.codProposta}`);
            await handleReturnProposta(data2);

            if (data2?.sucesso == false)
              throw new Error();

            if (data2?.statusId == 14 && boolIsEtapaFinal == false)
              return next(); // se 14 == PROSSEGUIR CADASTRO, avança para a próxima etapa
          }

          setMsgRecusa(sessionStorage.getItem('mensagemParecer') || '');

          // se houver cupom validado, salvar na tabela de histórico
          if (sessionStorage.getItem('b2e:cpfCupomValidado'))
            if (!sessionStorage.getItem('b2e:cpfCupomHistorico')) {
              await api.post(`Cupom/InserirCupomHistorico?codigoProposta=${data?.codProposta}&idCupom=${sessionStorage.getItem('b2e:idCupom')}&cpf=${sessionStorage.getItem('b2e:cpfCupom')}`);
              sessionStorage.setItem('b2e:cpfCupomHistorico', 'true');
            }

          // POC: testando cvc, somente no envio final
          if (url.includes('cvc'))
            window.parent.postMessage('{sucesso: true}',
              `https://b2b.cvccorp.com.br/seja-cliente${window.origin.includes('b2egroup') ? '' : '-2'}/`);

          sessionStorage.setItem('propostaEnviada', 'true');
          message.success('Proposta enviada com sucesso', 6);
          return setPropostaEnviada(true);
        }

        // se não for a última etapa, avança para a próxima
        return next();

      } catch (err) {
        console.warn(err);
        message.error('Falha no envio da proposta', 6);

        // se der erro na hr de integrar limpar form!
        await startNewForm();

      } finally {
        setLoading({ ...loading, status: false, });
        setSendButton(false);
      }
    },
    validate: (values: CampoType) => {
      let errors = {};

      if (!sessionStorage.getItem('nestedDataRev'))
        if (document.getElementById('carouselProducts'))
          if (emptyValues.includes(sessionStorage.getItem('productsTotalValue')))
            errors = { ...errors, productsTotalValue: 'Selecione ao menos um produto' }

      const pendingDocPro: any = document.getElementById('pendingDocPro')?.querySelector('div:first-child')?.innerHTML;
      if (!!pendingDocPro)
        errors = { ...errors, pendingDocPro: initCap(pendingDocPro.replace(pendingDocProRegex, '')) + ' obrigatório(a)' }

      customValidates.map((e: any) => {
        if (document.getElementById(e))
          if ((sessionStorage.getItem(e) || '') == '')
            errors = { ...errors, [e]: e.split(':')[1].replace(/-/g, ' ') + ' obrigatório(a)' }
      })

      /* breve validação de valores no arr sócios: */
      const allSocios: any = JSON.parse(sessionStorage.getItem('allSocios') || '[]')
      if (document.getElementById('tab1Socio'))
        if (allSocios.length != 0)
          allSocios.forEach((arrayCampos: any, i: number) => {
            arrayCampos.forEach(({ value, name, label, obrigatorio }: Campo, j: number, arr: any) => {
              if (value && value != '')
                if (name == 'socio-cpf' && !validateBr.cpf(value ? `${value}`.replace(/\D/g, '') : value))
                  errors = { ...errors, [name]: `(Sócio ${i + 1}) CPF Inválido` }
                else if (name == 'socio-email' && !validateBr.email(value))
                  errors = { ...errors, [name]: `(Sócio ${i + 1}) E-mail Inválido` }
                else if (name == 'socio-cep' && !validateBr.cep(value ? `${value}`.replace(/\D/g, '') : value))
                  errors = { ...errors, [name]: `(Sócio ${i + 1}) CEP Inválido` }

              if (obrigatorio && (!value || value == ''))
                errors = { ...errors, [name]: `(Sócio ${i + 1}) ${label} obrigatório(a)` }
            })
          })

      /* breve validação de valores no arr avalistas: */
      const allAvalistas: any = JSON.parse(sessionStorage.getItem('allAvalistas') || '[]')
      if (document.getElementById('tab1Avalista'))
        if (allAvalistas.length != 0)
          allAvalistas.forEach((arrayCampos: any, i: number) => {
            arrayCampos.forEach(({ value, name, label, obrigatorio }: Campo, j: number, arr: any) => {
              if (value && value != '')
                if (name.includes('cpf') && !validateBr.cpf(value ? `${value}`.replace(/\D/g, '') : value))
                  errors = { ...errors, [name]: `(Avalista ${i + 1}) ${label} Inválido` }
                else if (name.includes('email') && !validateBr.email(value))
                  errors = { ...errors, [name]: `(Avalista ${i + 1}) ${label} Inválido` }
                else if (name.includes('cep') && !validateBr.cep(value ? `${value}`.replace(/\D/g, '') : value))
                  errors = { ...errors, [name]: `(Avalista ${i + 1}) ${label} Inválido` }

              if (obrigatorio && (!value || value == ''))
                errors = { ...errors, [name]: `(Avalista ${i + 1}) ${label} obrigatório(a)` }
            })
          })

      /* breve validação de valores nas etapas adicionais: */
      Object.keys(sessionStorage).forEach((base64Key) => {
        var normalKey = sessionStorageEncryptSupport ? atob(base64Key) : base64Key;
        if (!normalKey.includes('tabEtapaAdicionalCampos-')) return;
        let tabEtapaAdicionalAtual = sessionStorage.getItem('tabEtapaAdicionalAtual') || '';
        let nomeEtapaAdicional = normalKey.replace('tabEtapaAdicionalCampos-', '');

        // Ajuste para quando próximo for clicado, e não houver etapa adicional no DOM atual
        if (!document.getElementById(`startTabEtapaAdicional-${nomeEtapaAdicional}`)) return;

        if (tabEtapaAdicionalAtual != nomeEtapaAdicional) return; // validação somente em wizard!
        let camposTabEtapaAdicional = JSON.parse(sessionStorage.getItem(normalKey) || '[]');
        let lengthTabEtapaAdicional = +sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapaAdicional}`);
        let nomeAba = sessionStorage.getItem(`tabEtapaAdicionalNomeAba-${nomeEtapaAdicional}`);
        for (let i = 1; i <= lengthTabEtapaAdicional; i++)
          camposTabEtapaAdicional.forEach(({ name, label, obrigatorio }: Campo, j: number, arr: any) => {
            let value = formik.values[`${name}-${i}`] || '';
            if (value && value != '')
              if (name.includes('cpf') && !validateBr.cpf(value ? `${value}`.replace(/\D/g, '') : value))
                errors = { ...errors, [`${name}-${i}`]: `(${nomeAba} ${i}) ${label} Inválido` }
              else if (name.includes('email') && !validateBr.email(value))
                errors = { ...errors, [`${name}-${i}`]: `(${nomeAba} ${i}) ${label} Inválido` }
              else if (name.includes('cep') && !validateBr.cep(value ? `${value}`.replace(/\D/g, '') : value))
                errors = { ...errors, [`${name}-${i}`]: `(${nomeAba} ${i}) ${label} Inválido` }

            if (obrigatorio && (!value || emptyValues.includes(value)))
              errors = { ...errors, [`${name}-${i}`]: `(${nomeAba} ${i}) ${label} obrigatório(a)` }
          })
      });

      tipoProposta && [...etapas[tipoProposta][current]?.campos]
        .forEach((campo: Campo) => {
          if (ignoreValidate(campo.name)) return;

          if ((!(values[campo.name] || campo.value) || emptyValues.includes(values[campo.name])) && campo.obrigatorio)
            if (['Upload de Arquivos'].includes(campo.label)) {
              let uploadedFiles: any = sessionStorage.getItem('uploadedFiles')?.split(',') || [];

              campo.opcoes?.forEach((v: any) => {
                if (!uploadedFiles.includes(v))
                  errors = { ...errors, [campo.name]: `Anexo ${v} obrigatório(a)`, };
              })

            } else errors = {
              ...errors, [campo.name]:
                ['de-acordo-com-termos-condicoes'].includes(campo.name) ?
                  'Condições e Termos de Uso obrigatório' :
                  `${campo.label} obrigatório(a)`,
            };

          if (values[campo.name] && !customValidation(campo.name, values[campo.name]))
            errors = { ...errors, [campo.name]: `${campo.label} inválido(a)`, };

        });

      // pega os inputs com tipos customizados e valida eles:
      document.querySelectorAll('input[alt]').forEach((e: any, i) => {
        if (ignoreValidate(e.id)) return;

        const eSelected = (document.getElementById(e.id) as unknown as HTMLInputElement);
        const label = () => document.querySelector(`label[for="${eSelected.id}"]`)?.innerHTML;

        if (eSelected.id.includes('cpf§') && eSelected.value.trim()) {
          if (!validateBr.cpf(eSelected.value))
            errors = { ...errors, [eSelected.id]: `${label()} inválido(a)` }

        } else if (eSelected.id.includes('cnpj§') && eSelected.value.trim()) {
          if (!validateBr.cnpj(eSelected.value))
            errors = { ...errors, [eSelected.id]: `${label()} inválido(a)` }

        } else if (eSelected.id.includes('email§') && eSelected.value.trim()) {
          if (!validateBr.email(eSelected.value))
            errors = { ...errors, [eSelected.id]: `${label()} inválido(a)` }
        }
      })

      sessionStorage.setItem('formHasErrors', JSON.stringify(Object.keys(errors).length > 0))
      return errors;
    },
  });

  const { values, setFieldValue, resetForm } = formik;
  sessionStorage.setItem('wizardValues', JSON.stringify(values));

  const getPropostaPendente = async () => {
    try {
      let { status, data } = await api.get(`/ProcessoProposta/ObterPropostaProcesso/${sessionStorage.getItem('b2e:instituicao')}`);
      if (status !== 200) return;
      if (!data?.['tipo-proposta']) return;

      sessionStorage.setItem('propostaPendente', 'true');

      Modal.confirm({
        title: 'Proposta Pendente',
        content: 'Você possui uma proposta pendente, deseja continuar?',
        okText: 'Sim',
        cancelText: 'Descartar',
        onCancel: async () => {
          sessionStorage.removeItem('propostaPendente');
          await api.delete(`/ProcessoProposta/DescartarProcesso/${sessionStorage.getItem('b2e:instituicao')}`);
        },
        onOk: () => {
          data = removeEmptyKeys(data);

          if (data?.socios?.length)
            data.socios = data.socios.map((s: any) => removeEmptyKeys(s));

          if (data?.avalistas?.length)
            data.avalistas = data.avalistas.map((a: any) => removeEmptyKeys(a));

          if (data) {
            if (data['path_arquivos']) {
              sessionStorage.setItem('path_arquivos', data['path_arquivos'] + 'temp/');
            }

            data = normalizeFields(data);

            sessionStorage.setItem('nestedDataSocios', JSON.stringify(data));
            sessionStorage.setItem('nestedDataAvalistas', JSON.stringify(data));
            sessionStorage.setItem('nestedDataCodVerif', JSON.stringify(data));

            /* OBS.: nesteds abaixo comentados, para não serem preenchidos ao obter a proposta do Processo
  
            sessionStorage.setItem('nestedDataRev', JSON.stringify(data));
  
            if ('info-parcelamento' in data)
              sessionStorage.setItem('nestedDataParcelamento', JSON.stringify(data));
  
            if ('produtos' in data)
              sessionStorage.setItem('nestedDataProduto', JSON.stringify(data)); */

            // seta tipo proposta:
            setTipoProposta(`${data['tipo-proposta']}`);

            formik.setValues({ ...values, ...data, });
          }
        }
      });

    } catch (error) {
      console.log('error', error);
    }
  }
  useEffect(() => {
    if (href.includes('-preview')) return;
    limitePropostasAtingido()

    // sessions para manter:
    const token = localStorage.getItem('token') || ''
    const tokenExp = sessionStorage.getItem('tokenExp') || ''
    const loginLogged = sessionStorage.getItem('loginLogged') || ''
    const b2eSubdominio = sessionStorage.getItem('b2e:subdominio') || ''
    const b2eInstituicao = sessionStorage.getItem('b2e:instituicao') || ''
    const b2eGrupoSubdominio = sessionStorage.getItem('b2e:grupoSubdominio') || ''
    const revisaoProposta = sessionStorage.getItem('revisaoProposta') || ''

    sessionStorage.clear()

    // setando sessions de volta:
    localStorage.setItem('token', token)
    sessionStorage.setItem('tokenExp', tokenExp)
    sessionStorage.setItem('loginLogged', loginLogged)
    sessionStorage.setItem('b2e:subdominio', b2eSubdominio)
    sessionStorage.setItem('b2e:instituicao', b2eInstituicao)
    sessionStorage.setItem('b2e:grupoSubdominio', b2eGrupoSubdominio)

    // em caso de revisão de proposta: (evita bug de fazer revisão e acessar em seguida nova proposta)
    if (boolRevisaoProposta)
      sessionStorage.setItem('revisaoProposta', 'true')
  }, [propostaEnviada, resetFormulario]);

  useEffect(() => {
    if (!sessionStorage.getItem('propostaPendente') && boolProcessoProposta)
      getPropostaPendente()
  }, []);

  useEffect(() => {
    sessionStorage.setItem('formOnePage', JSON.stringify(onePage));
  }, [onePage]);

  useEffect(() => {
    if (instituicaoStoraged)
      setFieldValue('codigo-instituicao', instituicaoStoraged);
  }, [instituicaoStoraged, setFieldValue]);

  useEffect(() => {
    // se a página for dlink, setar auto se é dlinkpf ou dlinkpj:
    if (url.includes('dlink')) return setSelectDlink(true);

    // seta tipo de pessoa (usado em parcelamento):
    sessionStorage.setItem('b2e:tipoPessoa', tipoProposta.replace('dlink', '') || '');

    /* ao iniciar nova proposta e houver só pf, seta pf como tipo auto. */
    if (tipoPessoa.length === 1 && !params.codProposta) {
      setTipoProposta(tipoPessoa[0].value);
      document.getElementById('tipoPessoa')?.click();
    }
  }, [tipoPessoa, tipoProposta]);

  const loadConfigForm = async (config: any) => {
    try {
      if (config) {
        if (config?.interface.onepage) setOnePage(config?.interface.onepage);
        setMsgRecusa('');
        setNoConfig(false);
        setEtapas(config?.etapas);
        handleInitialValues(config?.etapas);
        setCurrent(0);
        loadFormOptions();

        if (config?.etapas.pf.length > 0 && config?.etapas.pj.length > 0) {
          setTipoPessoa([
            { label: 'Pessoa Fisica', value: 'pf' },
            { label: 'Pessoa Juridica', value: 'pj' },
          ]);
        } else if (config?.etapas.pf.length > 0) {
          setTipoPessoa([
            { label: 'Pessoa Fisica', value: 'pf' },
          ]);
        } else if (config?.etapas.pj.length > 0) {
          setTipoPessoa([
            { label: 'Pessoa Juridica', value: 'pj' },
          ]);
        }
      } else {
        setNoConfig(true);
        setEtapas({ pf: [], pj: [], dlinkpj: [], dlinkpf: [] });
      }
    } catch (error) {
      document.location.reload()
    } finally {
      setTimeout(() => { setLoading({ ...loading, status: false, }); }, 2000);
    }
  }

  const loadEffect = async () => {
    setLoading({ status: true, message: 'Carregando nova proposta...', });

    if (values['codigo-instituicao'])
      if (c) {
        loadConfigForm(c)
      } else {
        const { data } = await api.get(`ConfiguracaoForm?instituicao=${values['codigo-instituicao']}`)
        loadConfigForm(data)
      }
  }

  useEffect(() => { // usado para re/carregar a proposta:
    loadEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values['codigo-instituicao']]);

  const loadTravaProposta = async () => {
    try {
      let { data, status } = await api.get(`TravaProposta/ObterTravaProposta?guid=${instituicaoStoraged}`)
      sessionStorage.setItem('hasTravaProposta', data?.travaDocumento || false)
    } catch (error) {
      console.log("error: ", error);
    }
  }

  useEffect(() => {
    loadTravaProposta();
  }, []);

  useEffect(() => {
    if (url.includes('/dlink')) {
      const dlinkRegex = /dlink(pf|pj)-preview/;
      const match = url.match(dlinkRegex);
      if (match) {
        setSelectDlink(true);
        setTipoProposta(`dlink${match[1]}`); // Extracts 'pf' or 'pj'
        return;
      }

      // get query params:
      api.get(`Notificacao/PropostaDisplayLink${allParams.search}`)
        .then((res) => {
          res.data = removeEmptyKeys(res.data);

          // task 41277, verify if data has any key with 'mail' included, and value os this key is 'N/I', then remove this key
          Object.keys(res.data).forEach((key) => {
            if (['mail', 'cpf', 'cnpj'].some((e) => key.includes(e)) && res.data[key] == 'N/I')
              delete res.data[key];

            if (res.data[key]?.includes('00:00:00'))
              res.data[key] = res.data[key]?.replace('00:00:00', '23:59:59');
          });

          if (res.data?.socios?.length)
            res.data.socios = res.data.socios.map((s: any) => removeEmptyKeys(s));

          if (res.data?.avalistas?.length)
            res.data.avalistas = res.data.avalistas.map((a: any) => removeEmptyKeys(a));

          if (res.data) {
            if (res.data['path_arquivos']) {
              sessionStorage.setItem('path_arquivos', res.data['path_arquivos']);
              delete res.data['path_arquivos'];
            }

            sessionStorage.setItem('loadDataDlink', JSON.stringify(removeEmptyKeys(res.data)));
            sessionStorage.setItem('codigo-proposta', res.data['codigo-proposta']);

            res.data = normalizeFields(res.data);

            if ('anexos' in res.data)
              sessionStorage.setItem('uploadedFiles', `${res.data.anexos.map((a: any) => { return a.Nome.split('.').shift(); }) || ''}`)

            sessionStorage.setItem('nestedDataRev', JSON.stringify(res.data));
            sessionStorage.setItem('nestedDataSocios', JSON.stringify(res.data));
            sessionStorage.setItem('nestedDataAvalistas', JSON.stringify(res.data));
            sessionStorage.setItem('nestedDataCodVerif', JSON.stringify(res.data));

            if ('info-parcelamento' in res.data)
              sessionStorage.setItem('nestedDataParcelamento', JSON.stringify(res.data));

            if ('produtos' in res.data)
              sessionStorage.setItem('nestedDataProduto', JSON.stringify(res.data));

            // seta tipo proposta:
            setTipoProposta(`dlink${res.data['tipo-proposta']}`);
            formik.setValues({ ...values, ...res.data, });
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          window.location.href = '/expired';
        })
        .finally(() => {
          setLoading({ ...loading, status: false, });
          setSendButton(false);
        });

    } else if (params.codProposta && instituicao) {
      // setLoading({ status: true, message: 'Carregando revisão...', });
      sessionStorage.setItem('revisaoProposta', 'true');
      api.get(`Proposta?codProposta=${params.codProposta}&instituicaoGuid=${instituicao}`,)
        .then((res) => {
          res.data = removeEmptyKeys(res.data);

          // task 41277, verify if data has any key with 'mail' included, and value os this key is 'N/I', then remove this key
          Object.keys(res.data).forEach((key) => {
            if (['mail', 'cpf', 'cnpj'].some((e) => key.includes(e)) && res.data[key] == 'N/I')
              delete res.data[key];

            if (res.data[key]?.includes('00:00:00'))
              res.data[key] = res.data[key]?.replace('00:00:00', '23:59:59');
          });

          if (res.data?.socios?.length)
            res.data.socios = res.data.socios.map((s: any) => removeEmptyKeys(s));

          if (res.data?.avalistas?.length)
            res.data.avalistas = res.data.avalistas.map((a: any) => removeEmptyKeys(a));

          if (res.data) {
            if (res.data['path_arquivos']) {
              sessionStorage.setItem('path_arquivos', res.data['path_arquivos']);
              delete res.data['path_arquivos'];
            }

            //sessionStorage.setItem('loadDataDlink', JSON.stringify(removeEmptyKeys(res.data)));
            sessionStorage.setItem('codigo-proposta', res.data['codigo-proposta']);

            res.data = normalizeFields(res.data);

            if ('anexos' in res.data)
              sessionStorage.setItem('uploadedFiles', `${res.data.anexos.map((a: any) => { return a.Nome.split('.').shift() }) || ''}`)

            sessionStorage.setItem('nestedDataRev', JSON.stringify(res.data));
            sessionStorage.setItem('nestedDataSocios', JSON.stringify(res.data));
            sessionStorage.setItem('nestedDataAvalistas', JSON.stringify(res.data));
            sessionStorage.setItem('nestedDataCodVerif', JSON.stringify(res.data));

            if ('info-parcelamento' in res.data)
              sessionStorage.setItem('nestedDataParcelamento', JSON.stringify(res.data));

            if ('produtos' in res.data)
              sessionStorage.setItem('nestedDataProduto', JSON.stringify(res.data));

            // seta tipo proposta:
            setTipoProposta(`${res.data['tipo-proposta']}`);
            formik.setValues({ ...values, ...res.data, });
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          message.error('Erro ao carregar dados da proposta, proposta inexistente ou link expirado', 6);
        })
        .finally(() => {
          setLoading({ ...loading, status: false, });
          setSendButton(false);
        });
    }
  }, [params.codProposta, instituicao, selectDlink, resetFormulario]);


  function next() { setCurrent(current + 1); }
  function prev() { setCurrent(current - 1); }
  function initCap(string: string) { return string.charAt(0).toUpperCase() + string.slice(1) }

  function normalizeFields(obj: any) {
    //dynamic normalize fields
    Object.keys(obj).map((key) => {
      // convert all dates to moment, identify dates in your string:
      if (typeof obj[key] === 'string' && obj[key]?.includes('00:00:00' || '23:59:59' || obj[key]?._isAMomentObject || isoDateRegex.test(obj[key]))) {
        obj[key] = moment(obj[key]);

      } else if (Array.isArray(obj[key])) {
        //if its an Array, then call normalize again for each item
        obj[key].forEach((element: any) => {
          element = normalizeFields(element);
        });

      } else if (typeof obj[key] === 'object') {
        //if its an object, then call normalize again
        obj[key] = normalizeFields(obj[key]);
      }
    });
    return obj;
  }

  async function startNewForm() {
    setEtapasFinais({});
    setCurrent(0);
    resetForm();
    setFieldValue('codigo-instituicao', instituicao);
    setSelectTipoProposta(null);
    setTipoProposta('');
    setResetFormulario(!resetFormulario);

    setMsgRecusa('');
  }

  async function limitePropostasAtingido() {
    let subdominio = sessionStorage.getItem('b2e:subdominio') || url.split('/')[1];
    let { data: interfaceInstituicao } = await api.get(`ConfiguracaoForm/${subdominio}`);
    let { data: idInstituicao } = await api.get(`ConfiguracaoForm/InstituicoesGuid?guidInstituicao=${interfaceInstituicao.codigoInsituicao}`);
    let { data: totalPropostas } = await api.get(`Proposta/TotalPropostas?id_tb_instituicao=${idInstituicao}`);
    setInterfaceInstituicao(interfaceInstituicao);

    if (interfaceInstituicao.limitePropostas != null &&
      interfaceInstituicao.limitePropostas != 0 &&
      interfaceInstituicao.limitePropostas != '' &&
      totalPropostas >= interfaceInstituicao.limitePropostas) {
      return message.info('Essa instituição não pode receber mais propostas, pois atingiu o limite definido', 6);
    } else
      return false;
  }

  function handleInitialValues(etapas: EtapasType): { [value: string]: any } {
    // não executar handle se for dlink ou se for revisão de proposta
    if (url.includes('/dlink') || params.codProposta) return;

    const dinamicInitialValues: any =
      Object.values(etapas)
        .map((etapa: any) => {
          if (etapa.length > 0)
            return etapa.map((campo: Campo) =>
              campo.campos.map((c: any) => { // não fazer auto-fill nestes campos:
                if (!['opcoes', 'select'].includes(c.type))
                  return c.name
              }))
        })
        .flat(2)
        .reduce((acc: any, key: string) => ({ ...acc, [key]: ''.replace('00:00:00', '23:59:59') }), {});

    return formik.setValues({ ...initialValues, ...dinamicInitialValues });
  }

  async function validateAndSubmit() {
    if (sessionStorage.getItem('formHasErrors') == 'true' || formik.errors) {
      setIsOpenValidateModal(true);
    }

    formik.submitForm();
  }

  async function processFormWizard() {
    const wizardValues = JSON.parse(sessionStorage.getItem('wizardValues') || '{}');

    try {
      const sendData: any = Object.assign({}, wizardValues);
      if (sendData["codigo-instituicao"] === '' || !sendData["codigo-instituicao"]) {
        sendData["codigo-instituicao"] = instituicaoStoraged || instituicao;
      }

      // setando todas datas para 00:00:00
      Object.keys(sendData).forEach((key) => {
        if (sendData[key] && sendData[key]?._isAMomentObject) {
          sendData[key] = sendData[key]._i

        } else if (sendData[key] && isoDateRegex.test(sendData[key])) {
          sendData[key] = moment(sendData[key]).format('YYYY-MM-DD 00:00:00');
        }
      })

      const allSocios: any = JSON.parse(sessionStorage.getItem('allSocios') || '[]')
      if (allSocios.length != 0) { // abas (Sócios): mesclando array de sócios com objeto de envio
        sendData.socios = []
        removeEmptyKeys(allSocios).forEach((arrayCampos: any, i: number) => {
          arrayCampos?.forEach((campo: Campo) => {
            if (emptyValues.includes(campo)) return;
            var { name, value } = campo;

            if (value?._isAMomentObject || isoDateRegex.test(value))
              value = moment(value).format('YYYY-MM-DD 00:00:00')

            if (value !== undefined && value !== null && value !== '')
              sendData.socios[i] = { ...sendData.socios[i], [name]: value }
          })
        })
      }

      const allAvalistas: any = JSON.parse(sessionStorage.getItem('allAvalistas') || '[]')
      if (allAvalistas.length != 0) { // abas (Avalistas): mesclando array de avalistas com objeto de envio
        sendData.avalistas = []
        removeEmptyKeys(allAvalistas).forEach((arrayCampos: any, i: number) => {
          arrayCampos?.forEach((campo: Campo) => {
            if (emptyValues.includes(campo)) return;
            var { name, value } = campo;

            if (value?._isAMomentObject || isoDateRegex.test(value))
              value = moment(value).format('YYYY-MM-DD 00:00:00')
            sendData.avalistas[i] = { ...sendData.avalistas[i], [name]: value }
          })
        })
      }

      sendDataWizardPage = sendData;
      if (sessionStorage.getItem('etapaParcelamento')) {
        let parcelaEscolhida: any = JSON.parse(sessionStorage.getItem('parcelaEscolhida') || '{}')

        // montando objeto de envio, com a parcelaEscolhida
        sendDataWizardPage = {
          ...sendDataWizardPage,
          'valor-solicitado': parseFloat(parcelaEscolhida?.valorSolicitado).toFixed(2),
          'info-parcelamento': parcelaEscolhida
        };

        ['valorSolicitado', 'vlrIOF', 'vlrTAC'].forEach(e => delete sendDataWizardPage['info-parcelamento'][e]);
      }

      // mantendo somente nums. em: 'cnpj-pf', 'cnpj', 'cpf'
      ['cnpj-pf', 'cnpj', 'cpf'].forEach((e: any) => sendDataWizardPage[e] = sendDataWizardPage[e]?.replace(/\D/g, ''));

      if (sessionStorage.getItem('loadDataDlink'))
        sendDataWizardPage = { ...JSON.parse(sessionStorage.getItem('loadDataDlink') || '{}'), ...removeEmptyKeys(sendDataWizardPage), dlink: true }

      if (sessionStorage.getItem('b2e:Forma-de-Pagamento')) {
        let formaSelected: any = f.find((f: any) => f.descricao == sessionStorage.getItem('b2e:Forma-de-Pagamento'))
        sendDataWizardPage = { ...sendDataWizardPage, 'forma-pagamento': `${formaSelected?.id}` }
        setEtapasFinais({ ...etapasFinais, 'b2e:Forma-de-Pagamento': formaSelected?.ref })
      }

      if ('avalistas' in sendDataWizardPage) // removendo avalistas vazios
        sendDataWizardPage.avalistas = sendDataWizardPage.avalistas.map((a: any) => removeEmptyKeys(a));

      if ('socios' in sendDataWizardPage)
        sendDataWizardPage.socios = sendDataWizardPage.socios.map((s: any) => removeEmptyKeys(s));

      // obtendo valores das etapas adicionais com abas:
      Object.keys(sessionStorage).forEach((base64Key) => {
        var normalKey = sessionStorageEncryptSupport ? atob(base64Key) : base64Key;
        if (!normalKey.includes('tabEtapaAdicionalCampos-')) return;
        let nomeEtapaAdicional = normalKey.replace('tabEtapaAdicionalCampos-', '');
        let camposTabEtapaAdicional = JSON.parse(sessionStorage.getItem(normalKey) || '[]');
        let lengthTabEtapaAdicional = +sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapaAdicional}`);

        for (let i = 1; i <= lengthTabEtapaAdicional; i++)
          camposTabEtapaAdicional.forEach((campo: Campo) => {
            sendDataWizardPage[`${campo.name}-${i}`] = formik.values[`${campo.name}-${i}`] || '';
          })
      });

      // setendo mfa usados na proposta:
      if (sessionStorage.getItem('codEmailEtapa') && sessionStorage.getItem('codEmailValidado'))
        sendDataWizardPage['mfa-email'] = 'S'
      if (sessionStorage.getItem('codSMSEtapa') && sessionStorage.getItem('codSMSValidado'))
        sendDataWizardPage['mfa-sms'] = 'S'

      if (sessionStorage.getItem('b2e:ProcessoBio'))
        sendDataWizardPage = { ...sendDataWizardPage, 'processo-biometria-b2e': sessionStorage.getItem('b2e:ProcessoBio') }

      // rm § usado na definição de tipo na etapa adicional
      Object.keys(sendDataWizardPage).forEach((key) => {
        if (!key?.includes('§')) return;
        sendDataWizardPage[key.split('§')[1]] = sendDataWizardPage[key];
        delete sendDataWizardPage[key];
      })

      // se houver cupom validado, enviar na proposta -> CRED
      if (sessionStorage.getItem('b2e:cpfCupomValidado'))
        sendDataWizardPage['cupom'] = sessionStorage.getItem('b2e:cupom');

      // setando anexos da proposta
      if (sessionStorage.getItem('path_arquivos'))
        sendDataWizardPage['path_arquivos'] = sessionStorage.getItem('path_arquivos');

      // anexando retorno Documentoscopia
      if (sessionStorage.getItem('objDocProFinal'))
        sendDataWizardPage['documentoscopia'] = JSON.parse(sessionStorage.getItem('objDocProFinal') || '{}');

      return false; // false, sem erros
    } catch (error) {
      console.log("error: ", error);
      message.error('Erro ao gerar proposta, tente novamente', 6);
      return true; // true, com erros
    }
  }

  async function validateOnePage() {
    const labelsErrorTemp: any = [];
    const idsErrorsTemp: any = [];

    function setNewError(id: string, label: string) {
      labelsErrorTemp.push(label);
      idsErrorsTemp.push(id);
    }

    if (!sessionStorage.getItem('nestedDataRev'))
      if (document.getElementById('carouselProducts'))
        if (emptyValues.includes(sessionStorage.getItem('productsTotalValue')))
          setNewError('productsTotalValue', 'Selecione ao menos um produto');

    const pendingDocPro: any = document.getElementById('pendingDocPro')?.querySelector('div:first-child')?.innerHTML;
    if (!!pendingDocPro)
      setNewError('pendingDocPro', initCap(pendingDocPro.replace(pendingDocProRegex, '') + ' obrigatório(a)'))

    customValidates.map(async (e: any) => {
      if (document.getElementById(e))
        if ((sessionStorage.getItem(e) || '') == '')
          setNewError(e, e.split(':')[1].replace(/-/g, ' ') + ' obrigatório(a)')
    })

    /* breve validação de valores no arr sócios: */
    if (document.getElementById('tab1Socio'))
      JSON.parse(sessionStorage.getItem('allSocios') || '[]')?.forEach(
        (arrayCampos: any, i: number) => {
          arrayCampos?.forEach((campo: Campo) => {
            if (emptyValues.includes(campo)) return;
            var { name, value, obrigatorio, label } = campo;

            if (value && value != '')
              if (name == 'socio-cpf' && !validateBr.cpf(value ? `${value}`.replace(/\D/g, '') : value))
                setNewError('socio-cpf', `(Sócio ${i + 1}) CPF inválido(a)`);
              else if (name == 'socio-email' && !validateBr.email(value))
                setNewError('socio-email', `(Sócio ${i + 1}) E-mail inválido(a)`);
              else if (name == 'socio-cep' && !validateBr.cep(value ? `${value}`.replace(/\D/g, '') : value))
                setNewError('socio-cep', `(Sócio ${i + 1}) CEP inválido(a)`);

            if (obrigatorio && (!value || value == ''))
              setNewError(name, `(Sócio ${i + 1}) ${label} obrigatório(a)`);
          })
        })

    /* breve validação de valores no arr avalistas: */
    if (document.getElementById('tab1Avalista'))
      JSON.parse(sessionStorage.getItem('allAvalistas') || '[]')?.forEach(
        (arrayCampos: any, i: number) => {
          arrayCampos?.forEach((campo: Campo) => {
            if (emptyValues.includes(campo)) return;
            var { name, value, obrigatorio, label } = campo;

            if (value && value != '')
              if (name.includes('cpf') && !validateBr.cpf(value ? `${value}`.replace(/\D/g, '') : value))
                setNewError(name, `(Avalista ${i + 1}) ${label} Inválido`);
              else if (name.includes('email') && !validateBr.email(value))
                setNewError(name, `(Avalista ${i + 1}) ${label} Inválido`);
              else if (name.includes('cep') && !validateBr.cep(value ? `${value}`.replace(/\D/g, '') : value))
                setNewError(name, `(Avalista ${i + 1}) ${label} Inválido`);

            if (obrigatorio && (!value || value == ''))
              setNewError(name, `(Avalista ${i + 1}) ${label} obrigatório(a)`);
          })
        })

    /* breve validação de valores nas etapas adicionais: */
    Object.keys(sessionStorage).forEach((base64Key) => {
      var normalKey = sessionStorageEncryptSupport ? atob(base64Key) : base64Key;
      if (!normalKey.includes('tabEtapaAdicionalCampos-')) return;
      let nomeEtapaAdicional = normalKey.replace('tabEtapaAdicionalCampos-', '');

      // Ajuste para quando próximo for clicado, e não houver etapa adicional no DOM atual
      if (!document.getElementById(`startTabEtapaAdicional-${nomeEtapaAdicional}`)) return;

      let camposTabEtapaAdicional = JSON.parse(sessionStorage.getItem(normalKey) || '[]');
      let lengthTabEtapaAdicional = +sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapaAdicional}`);
      let nomeAba = sessionStorage.getItem(`tabEtapaAdicionalNomeAba-${nomeEtapaAdicional}`);
      for (let i = 1; i <= lengthTabEtapaAdicional; i++)
        camposTabEtapaAdicional.forEach(({ name, label, obrigatorio }: Campo, j: number, arr: any) => {
          let value = formik.values[`${name}-${i}`] || '';
          if (value && value != '')
            if (name.includes('cpf') && !validateBr.cpf(value ? `${value}`.replace(/\D/g, '') : value))
              setNewError(`${name}-${i}`, `(${nomeAba} ${i}) ${label} Inválido`);
            else if (name.includes('email') && !validateBr.email(value))
              setNewError(`${name}-${i}`, `(${nomeAba} ${i}) ${label} Inválido`);
            else if (name.includes('cep') && !validateBr.cep(value ? `${value}`.replace(/\D/g, '') : value))
              setNewError(`${name}-${i}`, `(${nomeAba} ${i}) ${label} Inválido`);
          if (obrigatorio && (!value || emptyValues.includes(value)))
            setNewError(`${name}-${i}`, `(${nomeAba} ${i}) ${label} obrigatório(a)`);
        })
    });

    // pega todos os inputs obrigatórios (alt="true")
    document.querySelectorAll('input[alt="true"]').forEach((e, i) => {
      if (ignoreValidate(e.id)) return;

      // é necessário rebuscar o elemento(e) para ter acesso ao value :(
      const eSelected = (document.getElementById(e.id) as unknown as HTMLInputElement);
      if (!eSelected.value.trim() || eSelected.value == 'R$ 0,00') {
        if (!!sessionStorage.getItem('revisaoProposta'))
          if (!['N/I', ''].includes(nestedDataRev[eSelected.id])) return;

        setNewError(eSelected.id,
          ['de-acordo-com-termos-condicoes'].includes(eSelected.id) ?
            'Condições e Termos de Uso obrigatório' :
            document.querySelector(`label[for="${eSelected.id}"]`)?.innerHTML + ' obrigatório(a)')
      }
    });

    // pega os inputs com tipos customizados e valida eles:
    document.querySelectorAll('input[alt]').forEach((e: any, i) => {
      if (ignoreValidate(e.id)) return;

      const eSelected = (document.getElementById(e.id) as unknown as HTMLInputElement);
      const label = () => document.querySelector(`label[for="${eSelected.id}"]`)?.innerHTML;

      if (eSelected.id.includes('cpf§') && eSelected.value.trim()) {
        if (!validateBr.cpf(eSelected.value))
          setNewError(eSelected.id, `${label()} inválido(a)`)

      } else if (eSelected.id.includes('cnpj§') && eSelected.value.trim()) {
        if (!validateBr.cnpj(eSelected.value))
          setNewError(eSelected.id, `${label()} inválido(a)`)

      } else if (eSelected.id.includes('email§') && eSelected.value.trim()) {
        if (!validateBr.email(eSelected.value))
          setNewError(eSelected.id, `${label()} inválido(a)`)
      }
    })

    /* início validações customizadas */
    let cnh = (document.getElementById('cnh') as unknown as HTMLInputElement);
    if (cnh && cnh.value != '' && !validateBr.cnh(cnh.value))
      setNewError('cnh', 'CNH inválido(a)');

    let cpf = (document.getElementById('cpf') as unknown as HTMLInputElement);
    if (cpf && cpf.value != '' && !validateBr.cpf(cpf.value))
      setNewError('cpf', 'CPF inválido(a)');

    let cpfConjuge = (document.getElementById('cpf-conjuge') as unknown as HTMLInputElement);
    if (cpfConjuge && cpfConjuge.value != '' && !validateBr.cpf(cpfConjuge.value))
      setNewError('cpf-conjuge', 'CPF Cônjuge inválido(a)');

    let cnpj = (document.getElementById('cnpj') as unknown as HTMLInputElement);
    if (cnpj && cnpj.value != '' && !validateBr.cnpj(cnpj.value))
      setNewError('cnpj', 'CNPJ inválido(a)');

    let cnpjPf = (document.getElementById('cnpj-pf') as unknown as HTMLInputElement);
    if (cnpjPf && cnpjPf.value != '' && !validateBr.cnpj(cnpjPf.value))
      setNewError('cnpj-pf', 'CNPJ inválido(a)');

    let cepPf = (document.getElementById('cep-pf') as unknown as HTMLInputElement);
    if (cepPf && cepPf.value != '' && !validateBr.cep(cepPf.value))
      setNewError('cep-pf', 'CEP inválido(a)');

    let cepDadosComerciaisPf = (document.getElementById('cep-dadoscomerciais-pf') as unknown as HTMLInputElement);
    if (cepDadosComerciaisPf && cepDadosComerciaisPf.value != '' && !validateBr.cep(cepDadosComerciaisPf.value))
      setNewError('cep-dadoscomerciais-pf', 'CEP Dados Comerciais inválido(a)');

    let email = (document.getElementById('email') as unknown as HTMLInputElement);
    if (email && email.value != '' && !validateBr.email(email.value))
      setNewError('email', 'E-mail inválido(a)');

    let emailPj = (document.getElementById('email-pj') as unknown as HTMLInputElement);
    if (emailPj && emailPj.value != '' && !validateBr.email(emailPj.value))
      setNewError('email-pj', 'E-mail Pessoa Jurídica inválido(a)');

    let uploadsPending: any = JSON.parse(sessionStorage.getItem('uploadsPending') || '[]');
    //console.log("uploadsPending: ", uploadsPending);
    let uploadedFiles: any = sessionStorage.getItem('uploadedFiles')?.split(',') || [];
    //console.log("uploadedFiles: ", uploadedFiles);
    // show files remaining to upload
    uploadsPending.forEach((e: any) => {
      if (!uploadedFiles.includes(e)) {
        labelsErrorTemp.push(`Anexo ${e} obrigatório(a)`);
        //idsErrorsTemp.push(e)
      }
    });

    /* fim validações customizadas */
    setLabelsError(labelsErrorTemp);
    setIdsErrors(idsErrorsTemp);

    if (labelsErrorTemp.length > 0) {
      setModalFormOnePage(true);
      return false;
    } else return true;
  }

  async function sendFormOnePage(handleProcessoProposta: boolean = false) {
    try {
      if (handleProcessoProposta == false) {
        setLoading({ status: true, message: 'Enviando proposta..' });
        if (await validateOnePage() == false) return;
      }

      // se não houver erros, prossegue abaixo, envia o form:
      sendDataOnePage = {
        "codigo-proposta": sessionStorage.getItem('codigo-proposta') || params.codProposta,
        "codigo-instituicao": sessionStorage.getItem('b2e:instituicao')
      };

      const allSocios: any = JSON.parse(sessionStorage.getItem('allSocios') || '[]')
      if (allSocios.length != 0) { // mesclando array de sócios com objeto de envio
        sendDataOnePage.socios = []
        removeEmptyKeys(allSocios).forEach((arrayCampos: any, i: number) => {
          arrayCampos?.forEach((campo: Campo) => {
            if (emptyValues.includes(campo)) return;
            var { name, value } = campo;

            if (value?._isAMomentObject || isoDateRegex.test(value))
              sendDataOnePage.socios[i] =
                { ...sendDataOnePage.socios[i], [name]: moment(value).format('YYYY-MM-DD 00:00:00') }

            else if (value !== undefined && value !== null && value !== '')
              sendDataOnePage.socios[i] =
                { ...sendDataOnePage.socios[i], [name]: value ? `${value}`.trim() : value }
          })
        })
      }

      const allAvalistas: any = JSON.parse(sessionStorage.getItem('allAvalistas') || '[]')
      if (allAvalistas.length != 0) { // mesclando array de avalistas com objeto de envio
        sendDataOnePage.avalistas = []
        removeEmptyKeys(allAvalistas).forEach((arrayCampos: any, i: number) => {
          arrayCampos?.forEach((campo: Campo) => {
            if (emptyValues.includes(campo)) return;
            var { name, value } = campo;

            if (value?._isAMomentObject || isoDateRegex.test(value))
              sendDataOnePage.avalistas[i] =
                { ...sendDataOnePage.avalistas[i], [name]: moment(value).format('YYYY-MM-DD 00:00:00') }

            else if (value !== undefined && value !== null && value !== '')
              sendDataOnePage.avalistas[i] =
                { ...sendDataOnePage.avalistas[i], [name]: value ? `${value}`.trim() : value }
          })
        })
      }

      // pegando todos os inputs e setando no objeto de envio sendDataOnePage
      document.querySelectorAll('input[alt]').forEach((e, i) => {
        const eSelected = (document.getElementById(e.id) as unknown as HTMLInputElement);

        // formatando valor(R$) -> backend
        if (eSelected.value.includes('R$ '))
          eSelected.value = eSelected.value?.replace(/[^0-9,-]/g, "");

        // setando todas datas para 00:00:00
        if (eSelected.placeholder == 'input-datepicker')
          return sendDataOnePage[e.id] =
            eSelected.value ? moment(eSelected.value, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00') : '';

        // limpando o temp- vindo do InputCurrency
        sendDataOnePage[e.id.replace('temp-', '')] = eSelected.value || '';
      });

      if (sessionStorage.getItem('etapaParcelamento')) {
        let parcelaEscolhida: any = JSON.parse(sessionStorage.getItem('parcelaEscolhida') || '{}')

        // montando objeto de envio, com a parcelaEscolhida
        sendDataOnePage = {
          ...sendDataOnePage,
          'valor-solicitado': parseFloat(parcelaEscolhida?.valorSolicitado).toFixed(2),
          'info-parcelamento': parcelaEscolhida
        };

        ['valorSolicitado', 'vlrIOF', 'vlrTAC'].forEach(e => delete sendDataOnePage['info-parcelamento'][e]);
      }

      // mantendo somente nums. em: 'cnpj-pf', 'cnpj', 'cpf'
      ['cnpj-pf', 'cnpj', 'cpf'].forEach((e: any) => sendDataOnePage[e] = sendDataOnePage[e]?.replace(/\D/g, ''));

      // setando produtos da proposta
      const productsSelected = JSON.parse(sessionStorage.getItem('productsSelected') || '[]');
      if (productsSelected.length > 0)
        sendDataOnePage = { ...sendDataOnePage, 'produtos': productsSelected, 'valor-solicitado': sessionStorage.getItem('productsTotalValue') }

      if (sessionStorage.getItem('loadDataDlink'))
        sendDataOnePage = { ...JSON.parse(sessionStorage.getItem('loadDataDlink') || '{}'), ...removeEmptyKeys(sendDataOnePage), dlink: true }

      if (sessionStorage.getItem('b2e:Forma-de-Pagamento')) {
        let formaSelected: any = f.find((f: any) => f.descricao == sessionStorage.getItem('b2e:Forma-de-Pagamento'))
        sendDataOnePage = { ...sendDataOnePage, 'forma-pagamento': `${formaSelected?.id}` }
        setEtapasFinais({ ...etapasFinais, 'b2e:Forma-de-Pagamento': formaSelected?.ref })
      }

      if ('avalistas' in sendDataOnePage)
        sendDataOnePage.avalistas = sendDataOnePage.avalistas.map((a: any) => removeEmptyKeys(a));

      if ('socios' in sendDataOnePage)
        sendDataOnePage.socios = sendDataOnePage.socios.map((s: any) => removeEmptyKeys(s));

      // obtendo valores das etapas adicionais com abas:
      Object.keys(sessionStorage).forEach((base64Key) => {
        var normalKey = sessionStorageEncryptSupport ? atob(base64Key) : base64Key;
        if (!normalKey.includes('tabEtapaAdicionalCampos-')) return;
        let nomeEtapaAdicional = normalKey.replace('tabEtapaAdicionalCampos-', '');
        let camposTabEtapaAdicional = JSON.parse(sessionStorage.getItem(normalKey) || '[]');
        let lengthTabEtapaAdicional = +sessionStorage.getItem(`tabEtapaAdicionalLength-${nomeEtapaAdicional}`);
        for (let i = 1; i <= lengthTabEtapaAdicional; i++)
          camposTabEtapaAdicional.forEach((campo: Campo) => {
            sendDataOnePage[`${campo.name}-${i}`] = formik.values[`${campo.name}-${i}`] || '';
          })
      });

      // setendo mfa usados na proposta:
      if (sessionStorage.getItem('codEmailEtapa') && sessionStorage.getItem('codEmailValidado'))
        sendDataOnePage['mfa-email'] = 'S'
      if (sessionStorage.getItem('codSMSEtapa') && sessionStorage.getItem('codSMSValidado'))
        sendDataOnePage['mfa-sms'] = 'S'

      if (sessionStorage.getItem('b2e:ProcessoBio'))
        sendDataOnePage = { ...sendDataOnePage, 'processo-biometria-b2e': sessionStorage.getItem('b2e:ProcessoBio') }

      // rm § usado na definição de tipo na etapa adicional
      Object.keys(sendDataOnePage).forEach((key) => {
        if (!key?.includes('§')) return;
        sendDataOnePage[key.split('§')[1]] = sendDataOnePage[key];
        delete sendDataOnePage[key];
      })

      // setando anexos da proposta
      if (sessionStorage.getItem('path_arquivos'))
        sendDataOnePage['path_arquivos'] = sessionStorage.getItem('path_arquivos');

      // anexando retorno Documentoscopia
      if (sessionStorage.getItem('objDocProFinal'))
        sendDataOnePage['documentoscopia'] = JSON.parse(sessionStorage.getItem('objDocProFinal') || '{}');

      if (handleProcessoProposta) return; // se foi somente para obter o processo, não enviar proposta

      // se houver cupom validado, enviar na proposta -> CRED
      if (sessionStorage.getItem('b2e:cpfCupomValidado'))
        sendDataOnePage['cupom'] = sessionStorage.getItem('b2e:cupom');

      // rm § usado na definição de tipo na etapa adicional
      Object.keys(sendDataOnePage).forEach((key) => {
        if (!key?.includes('§')) return;
        sendDataOnePage[key.split('§')[1]] = sendDataOnePage[key];
        delete sendDataOnePage[key];
      })

      const { data } = await api.post(RouteEnvioProposta(), {
        ...removeEmptyKeys(nestedDataRev),
        ...removeEmptyKeys(sendDataOnePage),
        "codigo-proposta": sessionStorage.getItem('codigo-proposta') || params.codProposta,
        "codigo-instituicao": sessionStorage.getItem('b2e:instituicao'),
        'tipo-proposta': `${tipoProposta.replace('dlink', '')}`,
        'aguardar-resposta': EtapaAtual?.['Aguardar-Resposta'] ? 'S' : 'N',
        'pre-analise': EtapaAtual?.['Pre-Analise'] ? 'S' : 'N',
        'grecaptcha': RouteEnvioProposta().includes('Public') ? sessionStorage.getItem('tokenCaptchaPublic') : '',
      });

      if (data?.statusId == -1)
        message.error(data?.mensagensErro[0] || 'Erro ao enviar proposta', 6);

      /* if (data?.sucesso == false)
        throw new Error(); */

      await handleReturnProposta(data);

      if (EtapaFinal?.['Pre-Analise'] || EtapaFinal?.['Aguardar-Resposta'] || data?.sucesso == false) {
        const { data: data2 } = await api.get(`Proposta/ObterParecer?guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}&codigoProposta=${data?.codProposta}`);
        await handleReturnProposta(data2);

        if (data2?.sucesso == false)
          throw new Error();
      }

      setMsgRecusa(sessionStorage.getItem('mensagemParecer') || '');

      // se houver cupom validado, salvar na tabela de histórico
      if (sessionStorage.getItem('b2e:cpfCupomValidado'))
        if (!sessionStorage.getItem('b2e:cpfCupomHistorico')) {
          await api.post(`Cupom/InserirCupomHistorico?codigoProposta=${data?.codProposta}&idCupom=${sessionStorage.getItem('b2e:idCupom')}&cpf=${sessionStorage.getItem('b2e:cpfCupom')}`);
          sessionStorage.setItem('b2e:cpfCupomHistorico', 'true');
        }

      // POC: testando cvc, somente no envio final
      if (url.includes('cvc'))
        window.parent.postMessage('{sucesso: true}',
          `https://b2b.cvccorp.com.br/seja-cliente${window.origin.includes('b2egroup') ? '' : '-2'}/`);

      sessionStorage.setItem('propostaEnviada', 'true');
      message.success('Proposta enviada com sucesso', 6);
      setPropostaEnviada(true);

    } catch (error) {
      console.error("error: ", error);
      message.error('Falha ao salvar proposta', 6);

      // se der erro na hr de integrar limpar form!
      await startNewForm();
    } finally {
      if (handleProcessoProposta == false) {
        setLoading({ ...loading, status: false, });
        setSendButton(false);
      }
    }
  }

  function propostaRedirector(statusId: any) {
    // realizar redirecionamentos se houver (habilitar: true)
    // isto será feito se for a última etapa da proposta

    const { redirecionamento: r } = interfaceInstituicao.respostaCadastro;

    if (!r.habilitar) return;

    if (r.todos.url)
      setIframeURL(r.todos.url);
    else if (statusId == 1 && r.aguardandoAnalise.url)
      setIframeURL(r.aguardandoAnalise.url);
    else if (statusId == 12 && r.recusadoAuto.url)
      setIframeURL(r.recusadoAuto.url);

    setIframeRedirect(true);
  }

  const [invoices, setInvoices] = useState([]);
  const [invoiceSelected, setInvoiceSelected]: any = useState('about:blank');
  const [modalInvoice, setModalInvoice] = useState(false);

  const [modalCC, setModalCC] = useState(false);
  const [stateCC, setStateCC] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });
  const handleChangeCC = (({ target: { value, name } }: any) => {
    setStateCC((prev) => ({ ...prev, [name]: value }));
  });

  const handleFocusCC = (({ target: { value, name } }: any) => {
    setStateCC((prev) => ({ ...prev, focus: name }));
  });

  async function getTokensCC() {
    try {
      // @ts-ignore
      if (!Iugu.utils.validateCreditCardNumber(stateCC.number))
        return message.error('Número do cartão inválido')

      // @ts-ignore
      if (!Iugu.utils.validateCVV(stateCC.cvc, Iugu.utils.getBrandByCreditCardNumber(stateCC.number)))
        return message.error('Código de segurança inválido')

      // @ts-ignore
      if (!Iugu.utils.validateExpirationString(stateCC.expiry))
        return message.error('Data de validade inválida')

      if (c.etapas[`${tipoProposta}EtapasFinais`]?.[0]?.campos[2]?.value) // @ts-ignore
        Iugu.setAccountID(c.etapas[`${tipoProposta}EtapasFinais`]?.[0]?.campos[2]?.value);
      else return message.warn('Account ID - Subconta não encontrada nas configurações')

      if (!window.location.origin.includes('b2egroup')) // @ts-ignore
        Iugu.setTestMode(true);

      // @ts-ignore
      let cc = Iugu.CreditCard(
        stateCC.number.replace(/\s/g, ''),
        stateCC.expiry.split('/')[0],
        stateCC.expiry.split('/')[1],
        stateCC.name,
        stateCC.name,
        stateCC.cvc
      );

      // @ts-ignore
      Iugu.createPaymentToken(cc, async (res: any) => {
        if (res.errors) {
          sessionStorage.setItem('tokensCC', JSON.stringify([]));
          throw new Error(res.errors);
        } else {
          let tokensCC = JSON.parse(sessionStorage.getItem('tokensCC') || '[]');
          sessionStorage.setItem('tokensCC', JSON.stringify([...tokensCC, res.id]));
        }
      });

    } catch (error) {
      console.log("error: ", error);
      message.error('Falha ao validar cartão, tente novamente')
    }
  }

  async function getInvoices() {
    // se já tiver invoices, não buscar novamente
    if (invoices.length > 0) return true;

    try {
      setLoading({ status: true, message: 'Obtendo pagamentos...', });

      if (c.etapas[`${tipoProposta}EtapasFinais`][0]?.habilitar)
        switch (c.etapas[`${tipoProposta}EtapasFinais`][0].integracao) {
          case 1: // IUGU
            const { data } = await api.post(`Proposta/GerarFaturaIugu?guid=${sessionStorage.getItem('b2e:instituicao')}
&codigoProposta=${codigoProposta}&formasPagamento=${etapasFinais['b2e:Forma-de-Pagamento']}`);
            setInvoices(data);
            break;
        }
      return true;

    } catch (error) {
      console.error("error: ", error);
      message.error(error.response.data || 'Falha ao obter pagamentos, tente novamente', 6);
      Modal.destroyAll();
      return false;
    } finally {
      setLoading({ ...loading, status: false, });
    }
  }

  async function prosseguirPagamento() {
    if (etapasFinais['b2e:Forma-de-Pagamento'] == 'credit_card')
      return setModalCC(true)

    if (await getInvoices() == false) return;
    setModalInvoice(true);
  }

  const showButtonPagamento: boolean =
    c?.etapas?.[`${tipoProposta}EtapasFinais`]?.[0]?.habilitar &&
    !href.includes('dlink') &&  // se for dlink, não mostrar botão de prosseguir pagamento 
    !(href.toLowerCase().split('/cadastrar/')[1]?.replace(/[^\w\s]/gi, '') !== ('' || undefined)) && // se for revisão 
    JSON.parse(c?.etapas?.[`${tipoProposta}EtapasFinais`]?.[0]?.campos[0]?.value || '[]')?.includes(parseInt(statusIdProposta));

  async function VerificarCupomEtapaFinal() {
    try {
      const { data, status } = await api.get(`Cupom/VerificarCupomCpf?codigoCupom=${sessionStorage.getItem('b2e:cupom')}&guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}&cpf=${sessionStorage.getItem('b2e:cpfCupom')}`);

      if (status == 204) {
        setLoading({ status: false, message: '' });
        message.error('Cupom usado com status inválido ou expirado')
        return false;
      }

      if (parseInt(data?.qtdLimite) <= data?.uso) {
        setLoading({ status: false, message: '' });
        message.error('Cupom atingiu limite de uso')
        return false;
      }

      if (data?.usoCpf != 0) {
        message.error('Cupom já usado ou indisponível para este CPF');
        return false;
      }

      sessionStorage.setItem('b2e:idCupom', data?.id)
      sessionStorage.setItem('b2e:cpfCupomValidado', 'true');
      return true;
    } catch (error) {
      console.log("error: ", error);
      message.error('Erro ao validar CPF para este cupom');
      return false;
    }
  }

  return <>
    {iframeRedirect && <iframe src={iframeURL} style={{ width: '100vw', height: '100vh', border: 'none' }} />}
    {!tipoProposta && (params.codProposta || url.includes('/dlink')) ?
      <AwaitFull />
      : iframeRedirect == false && iframeURL?.trim() == '' &&
      <div className="row d-flex justify-content-center">
        <div className="col-lg-10">
          <Card>
            <FormikProvider value={formik}>
              <Form className="cadastrar">
                {etapas?.pf?.length > 0 || etapas?.pj?.length > 0 ? (
                  msgRecusa == '' ? (
                    propostaEnviada == false ? (
                      <div className="steps-content">
                        {/* <h3>{tipoProposta}:</h3> */}

                        {!tipoProposta && <>
                          <div className="form-row">
                            <div className="col-lg-12">
                              <SectionTitle title="Cadastrar Proposta" />
                            </div>
                          </div>

                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <Select
                              style={{ minWidth: 300 }}
                              showSearch
                              optionFilterProp="children"
                              name="Tipo Proposta"
                              label=""
                              placeholder="Selecione o tipo de solicitação"
                              onChange={(value) =>
                                setSelectTipoProposta({ tipo: value })
                              }>
                              {tipoPessoa.map((type, idx) => (
                                <Option key={idx} value={type.value}>
                                  {type.label}
                                </Option>
                              ))}
                            </Select>

                            <Button type="primary" style={{ marginTop: 10 }}
                              onClick={() => {
                                if (selectTipoProposta != null) {
                                  setTipoProposta(selectTipoProposta.tipo);
                                }
                              }}>
                              Próximo
                            </Button>
                          </div>
                        </>}

                        {onePage && <>
                          {document.querySelector('.error')?.classList.add('d-none')}

                          {tipoProposta && <>
                            {etapas[`${tipoProposta}`]?.map((etapa, idx) =>
                              <Bloco bloco={etapa} key={idx} />)}

                            <Button type="primary" icon={<CheckOutlined />}
                              disabled={sendButton} className="float-right"
                              onClick={async () => {
                                if (href.includes('-preview'))
                                  return message.error('Esta tela (Preview DisplayLink) não permite envio de proposta');

                                if (sessionStorage.getItem('codEmailEtapa') && !sessionStorage.getItem('codEmailValidado'))
                                  return message.warning('Ação não permitida. Código de verificação não validado.')
                                else if (sessionStorage.getItem('codSMSEtapa') && !sessionStorage.getItem('codSMSValidado'))
                                  return message.warning('Ação não permitida. Código de verificação não validado.')

                                setSendButton(true)

                                try {
                                  if (await limitePropostasAtingido())
                                    throw new Error('Limite de propostas atingido');

                                  if (sessionStorage.getItem('b2e:cpfCupomPreValidado'))
                                    if (await VerificarCupomEtapaFinal() == false)
                                      throw new Error('Cupom inválido');

                                  await sendFormOnePage()
                                } catch (error) {
                                  console.error("error: ", error);
                                } finally {
                                  setSendButton(false)
                                }
                              }}>
                              {sendButton ? 'Enviando...' : 'Enviar'}
                            </Button>
                          </>}

                          <Modal centered open={modalFormOnePage} footer={null}
                            onCancel={() => setModalFormOnePage(false)}>
                            <div className='text-center'>
                              <h3>Campos pendentes ou inválidos:</h3>
                              {labelsError && labelsError.map((label: any, idx: any) => (
                                <p style={{ color: '#800000' }} key={label + idx}>{label}</p>
                              ))}
                            </div>
                          </Modal>
                        </>}

                        {!onePage && tipoProposta &&
                          <Bloco bloco={etapas[`${tipoProposta}`]?.[current]} />}
                      </div>
                    ) : ( // exibição tela de sucesso default
                      <div className="form-row">
                        <div className="col-lg-12 centerImgMsgRecusa">
                          <img
                            src={BillImg}
                            className="rejectImg"
                            alt="Proposta enviada com sucesso"
                          />
                          <h2 className="msgRecusa" id='msgFeedback'>
                            <b>Proposta enviada com sucesso!</b>
                            <br />
                            <br />
                          </h2>
                          {(c?.interface.novaProposta || c?.interface.novaProposta == null) &&
                            <Button id='NovaProposta' type="primary" onClick={() => { window.location.reload() }}>
                              Nova Proposta
                            </Button>}
                        </div>

                        {showButtonPagamento &&
                          <div className='ml-auto mr-2' id='prosseguirPagamento'>
                            <Button type="primary" icon={<ArrowRightOutlined />} onClick={prosseguirPagamento}>
                              Prosseguir Pagamento
                            </Button>
                          </div>}
                      </div>
                    )
                  ) : ( // exibição tela de sucesso resposta customizada
                    <div className="row">
                      <div className="col-lg-12 centerImgMsgRecusa">
                        <h2 className="col-lg-12 mw-100 msgRecusa" id='msgFeedback'>
                          <b>Status da sua proposta:</b>
                          <br />
                          <b>{parse(msgRecusa.replace(/<img /g, "<img class='img-fluid'"))}</b>
                        </h2>
                        {(c?.interface.novaProposta || c?.interface.novaProposta == null) &&
                          <Button id='NovaProposta' type="primary" onClick={() => { window.location.reload() }}>
                            Nova Proposta
                          </Button>}
                      </div>

                      {showButtonPagamento &&
                        <div className='ml-auto mr-2' id='prosseguirPagamento'>
                          <Button type="primary" icon={<ArrowRightOutlined />} onClick={prosseguirPagamento}>
                            Prosseguir Pagamento
                          </Button>
                        </div>}
                    </div>
                  )
                ) : ( // sem etapas configuradas
                  <div className="noConfig">
                    {noConfig && (
                      <h2>Nenhuma configuração disponível para a instituição selecionada</h2>
                    )}
                  </div>
                )}

                {!onePage && !msgRecusa && !propostaEnviada &&
                  tipoProposta && (
                    <div className="steps-action">
                      <div className="form-row">
                        <div className="col-lg-12">
                          {/* caso a etapa atual for Código de Verificação, não permitir voltar */}

                          {current > 0 && etapaPessoa[current - 1]?.botaoEnvio == false &&
                            <Button icon={<ArrowLeftOutlined />} className="mr-2" onClick={() => {
                              if (href.includes('-preview'))
                                return prev();

                              if (boolCodVerificadoPrevious())
                                return message.warning('Ação não permitida. Código de verificação já validado.');

                              if (etapaPessoa[current].tipo == 5)
                                ['selectDiaVenc', 'b2e:Quantidade-de-Parcelas', 'parcelaEscolhida', 'b2e:Dia-de-Vencimento', 'b2e:dtPrimeiroVencimento']
                                  .map((s: any) => sessionStorage.removeItem(s))

                              // se a etapa anterior for adicional com abas, iniciar exibindo a 1ª aba
                              if (etapaPessoa[current - 1].expressao?.includes('true|')) {
                                const nomeEtapa = etapaPessoa[current - 1]?.nome?.replace(/ /g, '-');
                                setTimeout(() => {
                                  document.getElementById(`btnTabEtapaAdicionalId-${nomeEtapa}-1`)?.click()
                                }, 100);
                              }

                              prev()
                            }}>
                              Anterior
                            </Button>}

                          {current < etapaPessoa?.length - 1 && (etapaPessoa[current]?.botaoEnvio == false || sessionStorage.getItem('revisaoProposta')) &&
                            <Button icon={<ArrowRightOutlined />} id='nextStepONB' type="primary" onClick={async () => {
                              if (href.includes('-preview'))
                                return next();

                              if (boolCodVerificadoNext())
                                return message.warning('Ação não permitida. Código de verificação não validado.');

                              try {
                                await validateAndSubmit();

                                // se a próxima etapa for adicional com abas, iniciar exibindo a 1ª aba
                                if (etapaPessoa[current + 1].expressao?.includes('true|')) {
                                  const nomeEtapa = etapaPessoa[current + 1]?.nome?.replace(/ /g, '-');
                                  setTimeout(() => {
                                    document.getElementById(`btnTabEtapaAdicionalId-${nomeEtapa}-1`)?.click()
                                  }, 100);
                                }
                              } catch (error) {
                                console.error("error: ", error);
                              }
                            }}>
                              Próximo
                            </Button>}

                          {(etapaPessoa?.[current]?.botaoEnvio || current === etapaPessoa?.length - 1) && !sessionStorage.getItem('revisaoProposta') &&
                            <Button id='nextStepONB' type="primary" icon={<CheckOutlined />} disabled={sendButton}
                              onClick={async () => {
                                if (href.includes('-preview'))
                                  return message.error('Esta tela (Preview DisplayLink) não permite envio de proposta');

                                if (boolCodVerificadoNext())
                                  return message.warning('Ação não permitida. Código de verificação não validado.');

                                setSendButton(true)

                                try {
                                  if (await limitePropostasAtingido())
                                    throw new Error('Limite de propostas atingido');

                                  if (sessionStorage.getItem('b2e:cpfCupomPreValidado'))
                                    if (await VerificarCupomEtapaFinal() == false)
                                      throw new Error('Cupom inválido');

                                  await validateAndSubmit();
                                } catch (error) {
                                  console.error("error: ", error);
                                } finally {
                                  setSendButton(false)
                                }
                              }}>
                              {sendButton ? 'Enviando...' : 'Enviar'}
                            </Button>}

                          {current === etapaPessoa?.length - 1 && sessionStorage.getItem('revisaoProposta') &&
                            <Button id='nextStepONB' type="primary" icon={<CheckOutlined />} disabled={sendButton}
                              onClick={async () => {
                                if (href.includes('-preview'))
                                  return message.error('Esta tela (Preview DisplayLink) não permite envio de proposta');

                                if (boolCodVerificadoNext())
                                  return message.warning('Ação não permitida. Código de verificação não validado.');

                                setSendButton(true)

                                try {
                                  if (await limitePropostasAtingido())
                                    throw new Error('Limite de propostas atingido');

                                  await validateAndSubmit();
                                } catch (error) {
                                  console.error("error: ", error);
                                } finally {
                                  setSendButton(false)
                                }
                              }}>
                              {sendButton ? 'Enviando...' : 'Enviar'}
                            </Button>}
                        </div >
                      </div >
                    </div >
                  )
                }

                <ModalValidacao register footer={null}
                  open={isOpenValidateModal &&
                    (sessionStorage.getItem('formHasErrors') == 'true')}
                  onCancel={() => setIsOpenValidateModal(false)} />

                <Modal centered closable={true} destroyOnClose
                  bodyStyle={{ height: '85vh' }}
                  footer={null} open={modalCC}
                  onCancel={() => setModalCC(false)}>
                  <Cards
                    focused={stateCC.focus}
                    number={stateCC.number}
                    expiry={stateCC.expiry}
                    cvc={stateCC.cvc}
                    name={stateCC.name}
                    placeholders={{ name: 'Digite seu nome' }}
                    locale={{ valid: 'válido até' }}
                  />
                  <div className='text-center mt-3'>
                    <div className='input-wrapper my-3 col-lg-6 mx-auto'>
                      <label>Número do Cartão:</label>
                      <ReactInputMask autoComplete='off' name="number"
                        maskChar="" mask="9999 9999 9999 9999"
                        value={stateCC.number}
                        onChange={handleChangeCC}
                        onFocus={handleFocusCC}
                      />
                    </div>

                    <div className='input-wrapper my-3 col-lg-6 mx-auto'>
                      <label>Nome do Titular:</label>
                      <Input autoComplete='off' name="name"
                        value={stateCC.name}
                        onChange={handleChangeCC}
                        onFocus={handleFocusCC}
                      />
                    </div>

                    <div className='input-wrapper my-3 col-lg-6 mx-auto'>
                      <label>Validade:</label>
                      <ReactInputMask autoComplete='off' name="expiry"
                        maskChar="" mask="99/9999"
                        value={stateCC.expiry}
                        onChange={handleChangeCC}
                        onFocus={handleFocusCC}
                      />
                    </div>

                    <div className='input-wrapper my-3 col-lg-6 mx-auto'>
                      <label>Código de Segurança:</label>
                      <ReactInputMask autoComplete='off' name="cvc"
                        maskChar="" mask="9999"
                        value={stateCC.cvc}
                        onChange={handleChangeCC}
                        onFocus={handleFocusCC}
                      />
                    </div>

                    <Helmet>
                      <script type="text/javascript" src="https://js.iugu.com/v2"></script>
                    </Helmet>
                    <Button type="primary"
                      icon={<ArrowRightOutlined />}
                      onClick={async (e) => {
                        setModalCC(false);
                        setLoading({ status: true, message: 'Validando cartão...' });

                        await getTokensCC();

                        setTimeout(async () => {
                          await getTokensCC();
                        }, 4444);

                        setTimeout(async () => {
                          setLoading({ status: true, message: 'Gerando cobrança...' });

                          try {
                            await document.getElementById('prosseguirPagamento')?.classList.add('d-none');
                            await api.post(`Proposta/GerarCobrancaDiretaIugu?guid=${sessionStorage.getItem('b2e:instituicao')}
&codigoProposta=${codigoProposta}&token=${JSON.parse(sessionStorage.getItem('tokensCC') || '[]').join('&token=')}`);
                            message.success('Cobrança gerada com sucesso!', 6);

                          } catch (error) {
                            console.error("error: ", error);
                            message.error('Falha ao validar cartão, tente novamente', 6);
                            setModalCC(true);
                            await document.getElementById('prosseguirPagamento')?.classList.remove('d-none');
                          } finally {
                            setLoading({ ...loading, status: false, });
                          }
                        }, 7777);
                      }}>
                      Continuar
                    </Button>
                  </div>
                </Modal>

                <ModalPagamentoIugu invoices={invoices} modalInvoice={modalInvoice} setModalInvoice={setModalInvoice} />
              </Form >
            </FormikProvider >
          </Card >
        </div >
        <ModalLoading open={loading.status} message={loading.message} />
      </div >
    }
  </>
};

export default Cadastrar;