export const camposProposta = [
  {
    label: 'Valor solicitado',
    name: 'valor-solicitado',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  /* {
    label: 'Categoria',
    name: 'categoria-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, */
  {
    label: 'Modalidade',
    name: 'modalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Forma de pagamento',
    name: 'forma-pagamento',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Parecer',
    name: 'parecer',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const camposIdentificacao = [
  {
    label: 'CNPJ',
    name: 'cnpj',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Razão Social',
    name: 'razao-social',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nome Fantasia',
    name: 'nome-fantasia',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Inscrição Estadual',
    name: 'incricao-estadual',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNAE',
    name: 'cnae',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nº de filiais',
    name: 'num-filiais',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Ramo de atividade',
    name: 'ramo-atividade',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'E-mail',
    name: 'email-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fundação',
    name: 'fundacao',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Nº de funcionários',
    name: 'num-funcionarios',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fone comercial',
    name: 'fone-comercial',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Ramal',
    name: 'ramal-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
    tipoDado: 'number'
  },
];

export const camposEnderecoComercial = [
  {
    label: 'CEP',
    name: 'cep-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'logradouro-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'bairro-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'complemento-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'codigo-ibge-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'num-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'cidade-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'uf-cobranca-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const camposEnderecoEntrega = [
  {
    label: 'CEP',
    name: 'cep-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'logradouro-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'bairro-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'complemento-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'codigo-ibge-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'num-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'cidade-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'uf-entrega-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const camposSocios = [
  {
    label: '% Participação',
    name: 'socio-percentual',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'socio-desde',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Profissão',
    name: 'socio-profissao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nacionalidade',
    name: 'socio-nacionalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  /* {
    label: 'Natureza ocupação',
    name: 'socio-natureza-ocupacao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, */
  {
    label: 'CPF',
    name: 'socio-cpf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Nome',
    name: 'socio-nome',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CEP',
    name: 'socio-cep',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'socio-logradouro',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'socio-num',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'socio-complemento',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  /* {
    label: 'Codigo IBGE',
    name: 'socio-codigo-ibge',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
  {
    label: 'Bairro',
    name: 'socio-bairro',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'socio-cidade',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'socio-uf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'socio-telefone',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Celular',
    name: 'socio-celular',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'E-mail',
    name: 'socio-email',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const camposDadosComerciais = [
  {
    label: 'Regime Tributário',
    name: 'regime-tributario',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Faturamento',
    name: 'faturamento-1',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Data Faturamento',
    name: 'data-faturamento-1',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Faturamento',
    name: 'faturamento-2',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Data Faturamento',
    name: 'data-faturamento-2',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Faturamento',
    name: 'faturamento-3',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Data Faturamento',
    name: 'data-faturamento-3',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Faturamento',
    name: 'faturamento-4',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Data Faturamento',
    name: 'data-faturamento-4',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Faturamento',
    name: 'faturamento-5',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Data Faturamento',
    name: 'data-faturamento-5',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Faturamento',
    name: 'faturamento-6',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Data Faturamento',
    name: 'data-faturamento-6',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
];

export const camposReferenciasBancarias = [
  {
    label: 'Banco',
    name: 'banco-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'agencia-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'conta-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'desde-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Banco',
    name: 'banco-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'agencia-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'conta-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'desde-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
];

export const camposReferenciasComerciais = [
  {
    label: 'Empresa',
    name: 'nome-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Contato',
    name: 'contato-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'tel-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Relação',
    name: 'tipo-relacao-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Empresa',
    name: 'nome-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Contato',
    name: 'contato-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'tel-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Relação',
    name: 'tipo-relacao-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
];

export const camposPessoaJuridica = [
  {
    label: 'Valor solicitado',
    name: 'valor-solicitado',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  /* {
    label: 'Categoria',
    name: 'categoria-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, */
  {
    label: 'Modalidade',
    name: 'modalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Forma de pagamento',
    name: 'forma-pagamento',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Parecer',
    name: 'parecer',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNPJ',
    name: 'cnpj',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Razão Social',
    name: 'razao-social',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nome Fantasia',
    name: 'nome-fantasia',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Inscrição Estadual',
    name: 'incricao-estadual',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNAE',
    name: 'cnae',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nº de filiais',
    name: 'num-filiais',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Ramo de atividade',
    name: 'ramo-atividade',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'E-mail',
    name: 'email-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fundação',
    name: 'fundacao',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Nº de funcionários',
    name: 'num-funcionarios',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fone comercial',
    name: 'fone-comercial',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Ramal',
    name: 'ramal-pj',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }
];
