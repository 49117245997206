import { FC } from 'react';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div id="page-wrapper" className="custom-bg">
      {children}
    </div>
  );
};

export default Wrapper;
