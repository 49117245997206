import { FC, createContext } from 'react';
import { useFormikContext } from 'formik';
import { Bloco, ConfigTypes, EtapaType } from '@pages/Configuracao/ConfigTypes';

type EtapaProviderProps = {
  tipoPessoa: string;
  etapa: Bloco | undefined;
};

type EtapaContextProps = {
  etapaPessoa: Bloco[];
  tipoPessoa: string;
  errorEtapaPessoa: any;
  etapa: Bloco | undefined;
  etapaIdx: number;
};

export const EtapaContext = createContext<EtapaContextProps>({
  tipoPessoa: '',
  etapaPessoa: [],
  errorEtapaPessoa: [],
  etapa: undefined,
  etapaIdx: -1,
});

const EtapaProvider = ({ children, tipoPessoa, etapa, }:
  { children: React.ReactNode, tipoPessoa: string, etapa: Bloco | undefined }) => {
  const { values, errors } = useFormikContext<ConfigTypes>();

  const etapaPessoa = (values.etapas as EtapaType)[tipoPessoa];
  const errorEtapaPessoa = (errors?.etapas as EtapaType)?.[tipoPessoa];

  const getEtapaIndex = () =>
    etapaPessoa?.findIndex((el) => el.id === etapa?.id);

  return (
    <EtapaContext.Provider
      value={{
        tipoPessoa,
        etapaPessoa,
        errorEtapaPessoa,
        etapa,
        etapaIdx: getEtapaIndex(),
      }}
    >
      {children}
    </EtapaContext.Provider>
  );
};

export default EtapaProvider;
