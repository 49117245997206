import { FormEvent, useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import CepPromise, { CEP } from 'cep-promise';

import Icon from '@components/Icon';
import { DatePicker, Input, InputCurrency, InputMask, Select, Option, InputForm, } from '@components/Form';

import { Select as AntdSelect, message, Modal, Input as AntdInput } from 'antd';

import masks from '@utils/masks';
import Options from '@utils/options';
import { Campo } from '@pages/Configuracao/ConfigTypes';
import { initialValues } from './initialValues';
import { render } from '@testing-library/react';
import { val } from 'jodit/types/core/helpers';
import api from '@services/api';
import axios from 'axios';
import { ufs } from './ufs';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import ReactIntlCurrencyInput from 'react-intl-currency-input';
import { currencyConfig } from './currencyConfig';
import { removeEmptyKeys } from '@utils/removeEmptyKeys';
import ModalLoading from '@components/Modal/ModalLoading';
import Spinner from '@components/Spinner/PreAnalise';
import { validateBr } from 'js-brasil';
import LabelRequired from '@components/Form/LabelRequired';
import emptyValues from '@/utils/emptyValues';
import { formatMoney } from '@/utils/formatMoney';

type CampoType = {
  [field: string]: JSX.Element;
};

type Endereco = {
  logradouro: string;
  bairro: string;
  cidade: string;
  estado: string;
  ibge: string;
};

const SpanRequiredTemplate = (label: any = '', htmlFor: any = '') =>
  `<span className='required-field'>
    <i>*</i><label htmlFor=${htmlFor}>${label}</label>
  </span>`

const RenderFields = (campo: Campo, idx: any) => {
  const history = useHistory();
  const { subdominio } = useParams<{ subdominio: string }>();
  const params = useParams<{ codProposta: string }>();
  const { href } = window.location;

  const { setFieldValue, setValues, setFieldTouched, setFieldError, values } = useFormikContext<typeof initialValues>();
  const { habilitar, type, label, name, tipoDado, obrigatorio, value: initValueCampo } = campo;

  const nestedDataRev = JSON.parse(sessionStorage.getItem('nestedDataRev') || '{}');
  const isRevisao = Object.keys(nestedDataRev).length > 0;

  const handleCep = async (e: FormEvent<EventTarget>, cepField: string, endereco: Endereco) => {
    setFieldTouched(cepField, true);

    const { value: cepValue } = e.target as HTMLInputElement;
    const cepNumbers = cepValue.replace(/\D/g, '');
    const setCEP = (cepData: any) =>
      setValues({
        ...values,
        [cepField]: cepValue,
        [endereco.bairro]: cepData.bairro,
        [endereco.logradouro]: cepData.logradouro,
        [endereco.estado]: cepData.uf,
        [endereco.cidade]: cepData.localidade,
        [endereco.ibge]: cepData.ibge || '',
      });

    if (cepNumbers.length < 8) return;

    try {
      const { data: cepDataViaCEP }: any = await axios.get(`https://viacep.com.br/ws/${cepNumbers}/json/`);
      if ('erro' in cepDataViaCEP)
        throw new Error('CEP não encontrado em ViaCEP');

      setCEP(cepDataViaCEP);

    } catch (error) {
      console.log("error: ", error);

      try {
        const cepData = await CepPromise(cepNumbers);
        setCEP(cepData);

      } catch (error) {
        console.log("error: ", error);
        message.destroy(); // evitar duplicata de alerta
        message.error('CEP não encontrado');
      }
    }
  };

  const handleCnpj = async (v: string) => {
    const val = v.trim();
    if (!val || val === '' || name !== 'cnpj') return
    if (sessionStorage.getItem('autoCNPJ') && href.includes('cadastrar'))
      try {
        Modal.info({
          icon: null, centered: true, closable: false,
          wrapClassName: 'remove-antd-footer',
          content: <div className="modal-loading">
            <Spinner />
            <p>Obtendo dados de Pessoa Jurídica...</p>
          </div>,
        });

        const { data: props }: any = await api.post(`Integracao/RealizarIntegracao?tipoIntegracao=${sessionStorage.getItem('autoCNPJ')}`, { cnpj: val })
        let allProps: any = {};
        Object.keys(removeEmptyKeys(props))
          .map(f => allProps[f] = props[f])
        setValues({ ...values, ...allProps })

      } catch (error) {
        console.log("error: ", error);
      } finally {
        Modal.destroyAll();
      }
  }

  const handleCepInput = async (e: any) => {
    switch (name) {
      case 'cep-pf':
        const enderecoPf = {
          bairro: 'bairro-pf',
          logradouro: 'logradouro-pf',
          cidade: 'cidade-pf',
          estado: 'uf-pf',
          ibge: 'codigo-ibge-pf',
        };

        handleCep(e, 'cep-pf', enderecoPf);
        break;

      case 'cep-dadoscomerciais-pf':
        const enderecoPj = {
          bairro: 'bairro-dadoscomerciais-pf',
          logradouro: 'logradouro-dadoscomerciais-pf',
          cidade: 'cidade-dadoscomerciais-pf',
          estado: 'uf-dadoscomerciais-pf',
          ibge: 'codigo-ibge-dadoscomerciais-pf',
        };

        handleCep(e, 'cep-dadoscomerciais-pf', enderecoPj);
        break;

      case 'cep-cobranca-pj':
        const enderecoCobrancaPj = {
          bairro: 'bairro-cobranca-pj',
          logradouro: 'logradouro-cobranca-pj',
          cidade: 'cidade-cobranca-pj',
          estado: 'uf-cobranca-pj',
          ibge: 'codigo-ibge-cobranca-pj',
        };

        handleCep(e, 'cep-cobranca-pj', enderecoCobrancaPj);
        break;

      case 'cep-entrega-pj':
        const enderecoEntregaPj = {
          bairro: 'bairro-entrega-pj',
          logradouro: 'logradouro-entrega-pj',
          cidade: 'cidade-entrega-pj',
          estado: 'uf-entrega-pj',
          ibge: 'codigo-ibge-entrega-pj',
        };

        handleCep(e, 'cep-entrega-pj', enderecoEntregaPj);
        break;

      case 'cnpj':
        const t = e.target as HTMLInputElement;
        handleCnpj(t.value);
        break;

      default:
    }
  };

  const RenderDataField = () => {
    return (
      <>
        <input className='d-none' placeholder='input-datepicker' id={name} alt={`${obrigatorio}`}
          value={values[name] && values[name] != 'N/I' ?
            moment(values[name]._i || values[name])?.format('DD/MM/YYYY') : undefined} />
        <DatePicker name={name} type={type}
          label={(!!obrigatorio ? SpanRequiredTemplate(label, name) : label)}
          value={values[name] && values[name] != 'N/I' ?
            moment(values[name]._i?.replace('00:00:00', '23:59:59')
              || values[name]?.replace('00:00:00', '23:59:59'))
            : undefined}
          suffixIcon={<Icon name="date" size={15} color="#3A434D" />}
          onChange={(v: any, vStr: string) => {
            if (name == 'dt-nascimento')
              sessionStorage.setItem('b2e:dtNascBioEtapa', vStr)

            const isoDate = v?.toISOString();
            setFieldValue(name, isoDate)
            document.querySelector(`input[id="${name}"]`)?.setAttribute('value', v?.toString() ?? '')
          }} />
      </>);
  }

  const renderObj: CampoType = {
    cpf: <div className='input-wrapper'>
      <input value={values[name] || ''} className='d-none' id={'cpf§' + name} alt={`${obrigatorio}`} />
      {LabelRequired('cpf§' + name, label, obrigatorio)}
      <ReactInputMask value={values[name] || ''} placeholder={label}
        maskChar={null} mask="999.999.999-99"
        onChange={({ target: { value: v } }: any) => {
          setFieldValue(name, v)
          document.querySelector(`input[id="${'cpf§' + name}"]`)?.setAttribute('value', v?.toString() ?? '')
        }} />
    </div>,

    cnpj: <div className='input-wrapper'>
      <input value={values[name] || ''} className='d-none' id={'cnpj§' + name} alt={`${obrigatorio}`} />
      {LabelRequired('cnpj§' + name, label, obrigatorio)}
      <ReactInputMask value={values[name] || ''} placeholder={label}
        maskChar={null} mask="99.999.999/9999-99"
        onChange={({ target: { value: v } }: any) => {
          setFieldValue(name, v)
          document.querySelector(`input[id="${'cnpj§' + name}"]`)?.setAttribute('value', v?.toString() ?? '')
        }} />
    </div>,

    email: <div className='input-wrapper'>
      {LabelRequired('email§' + name, label, obrigatorio)}
      <input value={values[name] || ''} placeholder={label}
        type='email' id={'email§' + name} alt={`${obrigatorio}`}
        onChange={({ target: { value: v } }: any) => {
          setFieldValue(name, `${v}`?.replace(/[^a-zA-Z0-9@._-]/g, ''))
        }} />
    </div>,

    text: <>
      <div className={name.includes('uf') ? 'd-none' : ''}>
        <InputForm label={(!!obrigatorio ? SpanRequiredTemplate(label, name) : label)}
          obrigatorioAlt={obrigatorio} name={name} value={values[name] || ''}
          disabled={name.includes('codigo-ibge') ? true : false}
          placeholder={label} type={/* tipoDado ??  */ 'text'}
          onChange={({ target: { value: v } }) => {
            if (tipoDado == 'SimularParcelaB2e') {
              const varsParcelaB2e = JSON.parse(sessionStorage.getItem('b2e:varsParcelaB2e') || '{}')
              sessionStorage.setItem('b2e:varsParcelaB2e', JSON.stringify({ ...varsParcelaB2e, [name]: v }))
            }

            if (name == 'nome')
              sessionStorage.setItem('b2e:nomeBioEtapa', v)

            if (name == 'email')
              sessionStorage.setItem('b2e:emailCodigoVerif', v)

            // allow only numbers
            if (['cnh', 'cpf', 'cnpj',
              'agencia-referencia-1', 'agencia-referencia-2',
              'conta-referencia-1', 'conta-referencia-2',
              'incricao-estadual', 'ramal']
              .some(term => name.includes(term)))
              setFieldValue(name, `${v}`?.replace(/[^0-9]/g, ''))

            else if (name.includes('mail'))
              setFieldValue(name, `${v}`?.replace(/[^a-zA-Z0-9@._-]/g, ''))

            else setFieldValue(name, v)
          }} />
      </div>

      {name.includes('uf') && <>
        <div className='select-wrapper'>
          {LabelRequired(name, label, obrigatorio)}
          <AntdSelect value={values[name]}
            optionFilterProp="children" showSearch
            placeholder="Selecione uma opção"
            onChange={(v) => { setFieldValue(name, v.toString()) }}>
            <Option value='' disabled>
              Selecione uma opção
            </Option>
            {Object.keys(ufs).map((uf) =>
              <Option key={uf} value={uf}>
                {`${uf} - ${ufs[uf]}`}
              </Option>)}
          </AntdSelect>
        </div>
      </>}
    </>,

    date: <>
      {type === 'date' && RenderDataField()}</>,

    dateFromToday: <>
      {type === 'dateFromToday' && RenderDataField()}</>,

    currency: <>
      {name == 'valor-solicitado' && emptyValues.includes(nestedDataRev?.[name]) == false ?
        <div className='input-wrapper'>
          {LabelRequired(name, label, obrigatorio)}
          <AntdInput disabled value={`R$ ${nestedDataRev?.[name] || '0,00'}`.replace('.', ',')} />
        </div>
        :
        <div className="input-wrapper">
          {LabelRequired(name, label, obrigatorio)}
          <AntdInput alt={`${obrigatorio}`} name={name} id={name} prefix="R$"
            value={values?.[name] || '0,00'}
            onChange={({ target: { value: v } }) => {
              if (v?.endsWith('-')) // negativando
                return setFieldValue(name, `-${v?.replace(/[^0-9,.]/g, '') || '0,00'}`);

              else if (v?.endsWith('+')) // positivando
                return setFieldValue(name, `${v?.replace(/[^0-9,.]/g, '') || '0,00'}`);

              // Remove caracteres não numéricos e limita a 15 dígitos, permitindo o hífen (negativo) no início
              const rawValue = v.replace(/(?!^)-|[^0-9-]/g, '').slice(0, 15);

              // Formata diretamente o valor como string numérica usando Intl.NumberFormat
              const formattedValue = formatMoney(rawValue);

              if (name === 'valor-solicitado')
                sessionStorage.setItem('b2e:valorSolicitadoInicial', `${formattedValue}`);

              setFieldValue(`${name}`, formattedValue);
            }}
            onClick={(e: any) => { // Seleciona todo o texto
              e.target.select();
            }}
            onFocus={(e: any) => { // Move o cursor para o final do texto
              e.target.setSelectionRange(e.target.value.length, e.target.value.length);
            }}
            onPaste={(e) => {
              e.preventDefault();
              const pasteData = `${e.clipboardData.getData('Text')}`.replace(/[^0-9,-]/g, '').slice(0, 15);
              if (isNaN(parseFloat(pasteData))) return;

              const formattedValue = formatMoney(parseFloat(pasteData.replace(/,/g, '.')), 'paste');
              setFieldValue(name, formattedValue);
            }} />
        </div>}
    </>,

    select: <>
      <input className='d-none' id={name} alt={`${obrigatorio}`} value={values[name] || ''} />
      <Select label={(!!obrigatorio ? SpanRequiredTemplate(label, name) : label)}
        name={name} showSearch optionFilterProp="children"
        placeholder="Selecione uma opção" value={values[name] || ''}
        onChange={(v) => {
          setFieldValue(name, v.toString())
          document.querySelector(`input[id="${name}"]`)?.setAttribute('value', v?.toString() ?? '')
        }}>
        <Option value='' disabled>
          Selecione uma opção
        </Option>
        {Options(name)?.map((option) => (
          <Option key={option.id} value={option.id.toString()}>
            {option.descricao}
          </Option>
        ))}
      </Select>
    </>,

    opcoes: <>
      <input className='d-none' id={name} alt={`${obrigatorio}`} value={values[name] || ''} />
      {tipoDado == 'multiselect' ?
        <Select label={(!!obrigatorio ? SpanRequiredTemplate(label, name) : label)}
          name={name} showSearch optionFilterProp="children"
          placeholder="Selecione uma opção" mode={'tags'}
          value={(values[name] || '')?.includes(';') ? values[name].split(';') : values[name] || []}
          onChange={(v) => {
            setFieldValue(name, v)
            document.querySelector(`input[id="${name}"]`)?.setAttribute('value', v?.toString() ?? '')
          }}>
          {campo?.opcoes?.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        :
        <Select label={(!!obrigatorio ? SpanRequiredTemplate(label, name) : label)}
          name={name} showSearch optionFilterProp="children"
          placeholder="Selecione uma opção" value={values[name] || ''}
          onChange={(v) => {
            setFieldValue(name, v.toString())
            document.querySelector(`input[id="${name}"]`)?.setAttribute('value', v?.toString() ?? '')
          }}>
          <Option value='' disabled>
            Selecione uma opção
          </Option>
          {campo?.opcoes?.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>}
    </>,

    mask: <InputMask label={(!!obrigatorio ? SpanRequiredTemplate(label, name) : label)}
      obrigatorioAlt={obrigatorio} mask={masks[name]}
      name={name} maskChar="" placeholder={label}
      onChange={e => {
        if (name.includes('cep')) handleCepInput(e);
        if (name == 'cpf') {
          sessionStorage.setItem('b2e:cpfBioEtapa', e.target.value)
          sessionStorage.setItem('b2e:cpfDocProEtapa', e.target.value)
        }

        if (name == 'fone-celular')
          sessionStorage.setItem('b2e:smsCodigoVerif', e.target.value)

        setFieldValue(name, e.target.value)
      }}
      onBlur={async (e) => {
        if (name.includes('cep')) handleCepInput(e);
        if (name != 'cpf' && name != 'cnpj') return;
        if (sessionStorage.getItem('hasTravaProposta') == 'false') return;

        if (name && values?.[name])
          try {
            Modal.info({
              icon: null, centered: true, closable: false,
              wrapClassName: 'remove-antd-footer',
              content: <div className="modal-loading">
                <Spinner />
                <p>Validando documento..</p>
              </div>,
            });

            if (!validateBr[name](values[name]))
              return Modal.destroyAll(), message.error(name.toLocaleUpperCase() + ' inválido');

            const { data, status } = await api.get(`TravaProposta/ObterTravaDocumentoProposta?guid=${sessionStorage.getItem('b2e:instituicao')}&documento=${values?.[name]?.replace(/\D/g, '')}&tipoPessoa=${name.replace(/c|n/g, '')}&codigoProposta=${params.codProposta || 0}`)
            if (status == 204) return Modal.destroyAll(); // Trava de proposta não configurada

            Modal.error({
              icon: null, centered: true, 'title': null, okText: 'Fechar',
              'content': `Existe uma proposta com o documento informado, e data inferior ao permitido. ${data.codigoProposta}`,
              afterClose: () => {
                setFieldValue(name, '')
                document.querySelector(`input[id="${name}"]`)?.setAttribute('value', '')
                Modal.destroyAll();
              },
            })
          } catch (error) {
            console.log("error: ", error);
            message.destroy();
            message.error('Falha ao validar documento');
            Modal.destroyAll();
          }
      }}
    />,
  };

  return habilitar ?
    tipoDado == 'SimularParcelaB2e' ?
      <div key={idx}>{renderObj[type]}</div>
      : ['vlrSolicitado'].includes(name) ?
        <div key={idx} className=''>
          {renderObj[type]}
        </div>
        : <div key={idx} className='col-md-6'>
          {renderObj[type]}
        </div>
    : null
}

export default RenderFields;