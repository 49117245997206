import { FC } from 'react';

import './styles.scss';

type Props = {
  title: string;
};

const SectionTitle: FC<Props> = ({ title }) => {
  return (
      <div className="section-title">
        <h2>{title}</h2>
      </div>
  );
};

export default SectionTitle;
