import { useState, useEffect, useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Input, Option, Switch, Select, SectionTitle } from '@components/Form';
import { Select as AntdSelect, Button, Modal, Input as AntdInput, Tooltip, Switch as AntdSwitch, message, Radio, Tabs } from 'antd';

import { useHistory } from 'react-router-dom';
import { InputMask } from '@components/Form';
import api from '@services/api';

import { Bloco, Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import tr from 'date-fns/esm/locale/tr/index.js';
import { GlobalContext, } from '@contexts/GlobalContext';
import { border } from 'polished';
import { validateBr } from 'js-brasil';

// @ts-ignore
import Tags from "@yaireo/tagify/dist/react.tagify";
import ModalLoading from '@components/Modal/ModalLoading';

export default function () {
  const history = useHistory();
  const { values, setFieldValue } = useFormikContext<ConfigTypes>();
  const { configuracoes: c } = useContext(GlobalContext);

  const [loading, setLoading] = useState({ status: false, message: '' })
  const [selectedPerfil, setSelectedPerfil] = useState('0');
  const [handlePerfil, setHandlePerfil] = useState('');
  const mockPerfil: any = {
    id: '0',
    nome: '',
    desc: '',
    infoAdicionais: {},
    enviarCadastro: true,
    exibirConfiguracoes: true,
    acessarGestaoAcesso: true,
    verFollowUp: {
      status: true,
      verPropostasAlheias: true,
      gerarRevisao: true,
      labelRedirect: true,
      labelAprovar: true,
    },
    flagEditar: true
  }
  const [newPerfil, setNewPerfil] = useState(mockPerfil);
  const [modalPerfil, setModalPerfil] = useState(false);
  const showModalPerfil = (handle: any) => {
    //console.log("handle: ", handle);
    setHandlePerfil(handle);
    setModalPerfil(true);
  };
  const OkModalPerfil = async () => {
    if (newPerfil.nome.trim() == '') return message.error('Nome do perfil é obrigatório');
    if (newPerfil.desc.trim() == '') return message.error('Descrição do perfil é obrigatório');

    //console.log("handlePerfil == 'Editar Perfil': ", handlePerfil == 'Editar Perfil');
    //console.log("selectedPerfil: ", selectedPerfil);

    let { status } = await api.post(`GestaoAcesso/InserirPerfil`, {
      instituicao: values.instituicao.codigo, info: { ...newPerfil },
      id: handlePerfil == 'Editar Perfil' ? +selectedPerfil : 0
    })

    if (status !== 200) return message.error('Erro ao salvar perfil!')

    message.success('Perfil salvo com sucesso!');
    setModalPerfil(false);
    GetPerfis();
  };

  const [selectedUsuario, setSelectedUsuario] = useState('0');
  const [handleUsuario, setHandleUsuario] = useState('Adicionar Usuário');
  const mockUsuario: any = {
    username: "", username2: "", email: "",
    celular: "", status: true,
    mfa: false, /* switch TRUE OR FALSE */
    tipoMFA: 0, /* radio 0 = EMAIL, 1 = CELULAR */
    userAttributes: [],
  }
  const [newUsuario, setnewUsuario] = useState(mockUsuario);
  const [modalUsuario, setModalUsuario] = useState(false);
  const showModalUsuario = (handle: any) => {
    setHandleUsuario(handle);
    setModalUsuario(true);
  };

  const [userCampoAdicional, setUserCampoAdicional] = useState(false);
  const verifUsername = async () => {
    if (getFullUsername().trim().charAt(getFullUsername().trim().length - 1) == '.') return;

    try {
      setLoading({ status: true, message: 'Verificando se login já existe...' })
      let { data: { usernameValido: u } } = await api.get(`Usuario/VerificarUsername?username=${getFullUsername()}&guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}&email=${newUsuario.email}`)
      setUserCampoAdicional(!u)
      if (!u) message.error('Login já existe');
      return u

    } catch (error) {
      message.error('Erro ao verificar se login já existe, contate o suporte.')
    } finally {
      setLoading({ status: false, message: '' })
    }
  }

  const OkModalUsuario = async () => {
    if (newUsuario.email.trim() == '') return message.error('Email do usuário é obrigatório');
    if (newUsuario.username.trim() == '') return message.error('Nome do usuário é obrigatório');
    if (!validateBr.email(newUsuario.email)) return message.error('Email inválido');

    let userField = await verifUsername()
    if (!userField) return

    let cpfField = newUsuario.userAttributes?.find((attr: any) => attr.name == 'custom:cpf')
    if (cpfField && !validateBr.cpf(cpfField.value)) return message.error('CPF inválido');

    try {
      setLoading({ status: true, message: 'Salvando usuário...' })
      let { data } = await api.post(`Usuario/Cadastrar?guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}`,
        {
          ...newUsuario, username: getFullUsername(),
          celular: newUsuario.celular.trim() == '' ? '' : '+55' + newUsuario.celular.replace(/\D/g, '')
        },
        { headers: { 'Client-Url': history.location.pathname + '?rec=' + btoa(newUsuario.username) } });

      message.success(data.mensagemErro || 'Usuário salvo com sucesso! Dados de acesso enviados para o email cadastrado.');

      setModalUsuario(false);
      GetPerfis();

    } catch (error) { // @ts-ignore
      message.error(error?.response?.data?.mensagemErro || 'Erro ao salvar usuário!')
    } finally {
      setLoading({ status: false, message: '' })
    }
  };



  useEffect(() => {
    allPerfis.map((perfil: any) => {
      if (perfil.idPerfil == selectedPerfil) setNewPerfil(perfil);
    })
  }, [selectedPerfil])

  // obter perfis/usuarios da instituição ao carregar a página
  useEffect(() => { GetPerfis(); GetUsuarios(); }, [modalPerfil, modalUsuario, sessionStorage.getItem('b2e:instituicao')])

  // limpar perfis/usuarios ao trocar de instituição
  useEffect(() => {
    setAllPerfis([]); setSelectedPerfil('0');
    setAllUsuarios([]); setSelectedUsuario('0');
  }, [sessionStorage.getItem('b2e:instituicao')])

  const [allPerfis, setAllPerfis] = useState([])
  async function GetPerfis() {
    let { data: perfisInstituicao } = await api.get(`GestaoAcesso/PerfisInstituicao?guidEmpresa=${values.instituicao.codigo}`);

    if (perfisInstituicao.length == 0) return

    setAllPerfis(perfisInstituicao
      .filter((perfil: any) => perfil.id !== null)
      .map((perfil: any) => {
        return {
          ...perfil,
          flagEditar: ['Administrador Padrão', 'Operador Padrão'].includes(perfil.info.nome) ? false : true,
          info: { ...perfil.info, infoAdicionais: { ...perfil.info.infoAdicionais || {} } }
        }
      })
      .sort((a: any, b: any) => a.info.nome.localeCompare(b.info.nome)))
  }

  const [allUsuarios, setAllUsuarios] = useState([])
  async function GetUsuarios() {
    let { data: idInstituicao } = await api.get(`ConfiguracaoForm/InstituicoesGuid?guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}`)
    let { data: usersInstituicao } = await api.get(`Usuario/UsuariosEmpresa?idempresa=${idInstituicao}`)
    setAllUsuarios(usersInstituicao)
  }

  const syncPerfilUser = async () => {
    if (selectedUsuario == '0' || selectedPerfil == '0') return message.error('Selecione um usuário e um perfil para vincular!')

    let { status } = await api.post(`GestaoAcesso/InserirUsuarioPerfil`, {
      idPerfil: selectedPerfil, username: selectedUsuario,
    })

    status !== 200 ? message.error('Erro ao vincular perfil ao usuário!') : message.success('Perfil vinculado ao usuário com sucesso!')
  }

  const getDominio = () => {
    return sessionStorage.getItem('b2e:grupoSubdominio') || sessionStorage.getItem('b2e:subdominio')
  }

  const getFullUsername = () => {
    return getDominio()?.toLowerCase() + '.' + newUsuario.username + (userCampoAdicional || newUsuario.username2 != '' ? newUsuario.username2 : '')
  }

  return (
    <>
      {/* <SectionTitle title="Gestão de Acesso" /> */}
      <Modal title={handlePerfil} open={modalPerfil} centered footer={null} onCancel={() => setModalPerfil(false)} destroyOnClose>
        {handlePerfil == 'Editar Perfil' && <>
          <label>Perfil em Edição</label>
          <AntdSelect className='col-md-12' placeholder="Selecione um Perfil" value={newPerfil.id}
            onChange={(value: any) => {
              setSelectedPerfil(`${value}`)
              setSelectedUsuario('0')
              allPerfis.filter((perfil: any) => {
                perfil.id == value && setNewPerfil({ ...perfil.info, id: perfil.id, flagEditar: perfil.flagEditar })
              })
            }}>
            <Option disabled value={'0'}>
              Selecione um Perfil
            </Option>
            {allPerfis && allPerfis.map((perfil: any) =>
              <Option value={perfil.id}>
                {perfil.info.nome} | {perfil.info.desc}
              </Option>)}
          </AntdSelect>
          <br /><br />
        </>}

        <label>Nome</label>
        <AntdInput value={newPerfil.nome}
          onChange={(e) => setNewPerfil({ ...newPerfil, nome: e.target.value })}
          disabled={!newPerfil.flagEditar}
          placeholder="Nome Perfil" />
        <br /><br />

        <label>Descrição</label>
        <AntdInput value={newPerfil.desc}
          disabled={!newPerfil.flagEditar}
          onChange={(e) => setNewPerfil({ ...newPerfil, desc: e.target.value })}
          placeholder="Descrição Perfil" />
        <br /><br />

        {(values?.gestaoAcesso?.camposCustomPerfil || []).length != 0
          && <><br /><SectionTitle title="Atributos" /></>}
        {values?.gestaoAcesso?.camposCustomPerfil?.map((campo: any, idx: number) => {
          return <div key={campo + idx}>
            <label>{campo}</label>
            <AntdInput value={campo in newPerfil.infoAdicionais ? newPerfil.infoAdicionais[campo] : ''}
              onChange={({ target: { value: v } }) =>
                setNewPerfil({ ...newPerfil, infoAdicionais: { ...newPerfil.infoAdicionais, [campo]: v } })} />
            <br /><br />
          </div>
        })}

        <br />
        <SectionTitle title="Permissões" />

        <Switch checked={newPerfil.exibirConfiguracoes || newPerfil.exibirConfiguracoes == null}
          disabled={!newPerfil.flagEditar}
          name="name" label="Exibir Configurações (Sidebar)"
          onChange={(value) => {
            setNewPerfil({ ...newPerfil, exibirConfiguracoes: value })
          }} />

        <Switch
          checked={newPerfil.acessarGestaoAcesso || newPerfil.acessarGestaoAcesso == null}
          disabled={!newPerfil.flagEditar}
          name="name" label="Acessar Gestão de Acesso"
          onChange={(value) => {
            setNewPerfil({ ...newPerfil, acessarGestaoAcesso: value })
          }} />

        <Switch className='my-1'
          checked={newPerfil.enviarCadastro}
          disabled={!newPerfil.flagEditar}
          name="name" label="Enviar Cadastros (Propostas)"
          onChange={(value) => {
            setNewPerfil({ ...newPerfil, enviarCadastro: value })
          }} />

        <Switch checked={newPerfil.verFollowUp.status}
          disabled={!newPerfil.flagEditar}
          name="name" label="Visualizar Follow Up"
          onChange={(value) => {
            setNewPerfil({
              ...newPerfil,
              verFollowUp: {
                ...newPerfil.verFollowUp,
                status: value,
                verPropostasAlheias: !value && false,
                gerarRevisao: !value && false,
                labelRedirect: !value && false,
                labelAprovar: !value && false,
              }
            })
          }} />

        <>
          <AntdSwitch checked={newPerfil.verFollowUp.verPropostasAlheias}
            disabled={!newPerfil.flagEditar}
            className='ml-5 mr-2'
            onChange={(value) => {
              setNewPerfil({
                ...newPerfil,
                verFollowUp: {
                  ...newPerfil.verFollowUp,
                  verPropostasAlheias: value,
                }
              })
            }}
          />
          <label>Visualizar propostas de outros usuários</label>
        </>
        <br />
        <Tooltip placement="right" title="Permite acessar URL com código de proposta e revisar">
          <AntdSwitch disabled={!newPerfil.verFollowUp.status || !newPerfil.flagEditar}
            checked={newPerfil.verFollowUp.gerarRevisao}
            className='ml-5 mr-2'
            onChange={(value) => {
              setNewPerfil({
                ...newPerfil,
                verFollowUp: {
                  ...newPerfil.verFollowUp,
                  gerarRevisao: value,
                }
              })
            }}
          />
          <label>Gerar Revisão</label>
        </Tooltip>
        <br />
        <Tooltip placement="right" title="Na aba Follow Up, permite ver últ. coluna da tabela de propostas">
          <AntdSwitch disabled={!newPerfil.verFollowUp.status || !newPerfil.flagEditar}
            checked={newPerfil.verFollowUp.labelRedirect}
            className='ml-5 mr-2'
            onChange={(value) => {
              setNewPerfil({
                ...newPerfil,
                verFollowUp: {
                  ...newPerfil.verFollowUp,
                  labelRedirect: value,
                }
              })
            }}
          />
          <label>Botão Redirect</label>
        </Tooltip>
        <br />
        <Tooltip placement="right" title="Na aba Follow Up, permite ver 2ª coluna da tabela de propostas">
          <AntdSwitch disabled={!newPerfil.verFollowUp.status || !newPerfil.flagEditar}
            checked={newPerfil.verFollowUp.labelAprovar}
            className='ml-5 mr-2'
            onChange={(value) => {
              setNewPerfil({
                ...newPerfil,
                verFollowUp: {
                  ...newPerfil.verFollowUp,
                  labelAprovar: value,
                }
              })
            }} />
          <label>Analisar Proposta</label>
        </Tooltip>

        <div className='text-right'>
          <Button type='primary' id='salvarPerfis'
            onClick={async () => {
              let btnGo: any = document.getElementById('salvarPerfis')
              try {
                btnGo?.setAttribute('disabled', 'true')
                await OkModalPerfil()
              } finally {
                btnGo?.removeAttribute('disabled')
              }
            }}>
            Salvar
          </Button>
        </div>
      </Modal>

      <Modal title={handleUsuario} open={modalUsuario} centered footer={null} destroyOnClose
        onCancel={() => {
          setModalUsuario(false);
          setnewUsuario(mockUsuario);
        }}>
        <div className='mb-3'>
          <label>Email</label>
          <AntdInput value={newUsuario.email} placeholder="Email Usuário"
            onBlur={verifUsername} onChange={({ target: { value: e } }) => {
              let regexEmail = `${e}`?.replace(/[^a-zA-Z0-9@._-]/g, '');
              setnewUsuario({ ...newUsuario, email: regexEmail, username: regexEmail.split('@')[0], username2: '' })
            }} />
        </div>

        <div className='row'>
          <div className={(userCampoAdicional || newUsuario.username2 != '') ? 'col-md-8' : 'col-md-12'}>
            <label>Login</label>
            <div>
              <AntdInput disabled value={getFullUsername()} />
            </div>
          </div>

          {(userCampoAdicional || newUsuario.username2 != '') && <div className='col-md-4 pl-0'>
            <label className='invisible'>Nome Adicional</label>
            <div>
              <AntdInput placeholder='Nome Adicional'
                value={newUsuario.username2 || ''} maxLength={12}
                onBlur={verifUsername} onChange={({ target: { value: v } }) => {
                  setnewUsuario({ ...newUsuario, username2: v.replace(/[^a-zA-Z0-9]/g, '') })
                }} />
            </div>
          </div>}
        </div>

        <div className='my-3'>
          <InputMask
            obrigatorioAlt={false}
            mask='(99) 99999-9999'
            name='celular' label='Celular'
            maskChar="" placeholder="Celular Usuário"
            onChange={({ target: { value: v } }) => {
              setnewUsuario({ ...newUsuario, celular: v })
            }} />
        </div>

        <InputMask
          obrigatorioAlt={false}
          name='cpf' label='CPF'
          mask='999.999.999-99'
          maskChar="" placeholder="CPF Usuário"
          onChange={({ target: { value: v } }) => {
            if (v == '') {
              setnewUsuario({ ...newUsuario, userAttributes: newUsuario.userAttributes.filter((attr: any) => attr.name != 'custom:cpf') })
            } else {
              setnewUsuario({
                ...newUsuario, userAttributes:
                  [...newUsuario.userAttributes.filter((attr: any) => attr.name != 'custom:cpf'),
                  { name: 'custom:cpf', value: v }]
              })
            }
          }} />

        <div className='mt-3'>
          <label>Nome Completo</label>
          <AntdInput placeholder="Nome Completo"
            onChange={({ target: { value: v } }) => {
              if (v == '') {
                setnewUsuario({ ...newUsuario, userAttributes: newUsuario.userAttributes.filter((attr: any) => attr.name != 'custom:NomeCompleto') })
              } else {
                setnewUsuario({
                  ...newUsuario, userAttributes:
                    [...newUsuario.userAttributes.filter((attr: any) => attr.name != 'custom:NomeCompleto'),
                    { name: 'custom:NomeCompleto', value: v }]
                })
              }
            }} />
        </div>

        {/* <Switch checked={newUsuario.mfa} name="name" label="MFA" className='mt-3'
          onChange={(value) => setnewUsuario({ ...newUsuario, mfa: value })} />

        <label className='mr-3'>Tipo MFA:</label>
        <Radio.Group value={newUsuario.tipoMFA}
          onChange={(e) =>
            setnewUsuario({ ...newUsuario, tipoMFA: e.target.value })}>
          <Radio value={0}>Email</Radio>
          <Radio value={1}>SMS (Celular)</Radio>
        </Radio.Group> */}

        <div className='text-right mt-3'>
          <Button type='primary' id='salvarPerfis'
            onClick={async () => {
              let btnGo: any = document.getElementById('salvarPerfis')
              try {
                btnGo?.setAttribute('disabled', 'true')
                await OkModalUsuario()
              } finally {
                btnGo?.removeAttribute('disabled')
              }
            }}>
            Salvar
          </Button>
        </div>
      </Modal >

      <div className='mx-auto'>
        <div className='col-md-6 mx-auto'>
          <div className='mt-2'>
            <div>
              <label className='float-left'>Usuário</label>
              <label className='float-right mr-4'>
                {selectedUsuario && selectedUsuario != '0' &&
                  <>
                    <Tooltip title="Reenviar 1ª Senha de Acesso">
                      <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-repeat mr-4'
                        onClick={async () => {
                          try {
                            setLoading({ status: true, message: 'Reenviando Senha de Acesso...' })
                            await api.put(`Usuario/ReenviarAcesso?username=${selectedUsuario}&guidInstituicao=${values.instituicao.codigo}`)
                            message.success('Senha de acesso reenviada com sucesso!')

                          } catch (error) {
                            message.error('Erro ao reenviar ou usuário já definiu sua senha.')
                          } finally {
                            setLoading({ status: false, message: '' })
                          }
                        }} />
                    </Tooltip>
                    <Tooltip title="Inativar Usuário">
                      <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-user-times mr-4'
                        onClick={async () => {
                          return Modal.confirm({
                            okText: 'Confirmar', cancelText: 'Cancelar',
                            title: 'Ao Inativar usuário ele perderá o acesso total, deseja realmente inativar o usuário?',
                            onOk: async () => {
                              try {
                                setLoading({ status: true, message: 'Inativando Usuário...' })
                                let usuarioLogado = sessionStorage.getItem('loginLogged')
                                let { data: d }: any = await api.put(`Usuario/InativarUsuario?username=${selectedUsuario}&guidInstituicao=${values.instituicao.codigo}&usuarioLogado=${usuarioLogado}`)
                                if (!d?.sucesso) throw new Error()

                                message.success(d?.mensagemErro || 'Usuário inativado com sucesso!')
                                GetUsuarios()
                                setSelectedUsuario('0')

                              } catch (error) {
                                message.error('Erro ao inativar usuário, contate o suporte.')
                              } finally {
                                setLoading({ status: false, message: '' })
                              }
                            }
                          })
                        }} />
                    </Tooltip>
                  </>}
                <Tooltip title="Baixar Lista de Usuários">
                  <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-download mr-4'
                    onClick={async () => {
                      setLoading({ status: true, message: 'Baixando Lista de Usuários...' })

                      try {
                        let { data }: any = await api.get(`Usuario/ObterRelatorioUsuarios?guidInstituicao=${values.instituicao.codigo}`, { responseType: 'arraybuffer' })

                        // processar retorno de arquivo xlsx
                        let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        let fileURL = URL.createObjectURL(file);
                        let a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'Lista de Usuários.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                        message.success('Lista de Usuários baixada com sucesso!')
                      } catch (error) {
                        message.error('Erro ao baixar Lista de Usuários')
                      } finally {
                        setLoading({ status: false, message: '' })
                      }
                    }} />
                </Tooltip>
                <Tooltip title="Adicionar Usuário">
                  <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-plus'
                    onClick={() => { showModalUsuario('Adicionar Usuário'); setnewUsuario(mockUsuario) }} />
                </Tooltip>
              </label>
            </div>
            <br />
            <AntdSelect className='col-md-12'
              placeholder="Selecione um Usuário"
              value={selectedUsuario} showSearch
              onChange={async (value: any) => {
                const vParsed: any = JSON.parse(value)
                setSelectedUsuario(vParsed.username)
                let { data: { idPerfil } } = await api.get(`GestaoAcesso/ObterPerfilUsuario?usuario=${vParsed.username}&guidEmpresa=${values.instituicao.codigo}`)
                setSelectedPerfil(idPerfil || '0')
              }}>
              <Option disabled value={'0'}>
                Selecione um Usuário
              </Option>
              {allUsuarios?.map((user: any) =>
                <Option key={user.username} value={`${JSON.stringify(user)}`}>
                  {user.username} | {user.email}
                </Option>)}
            </AntdSelect>
          </div>
          <br />
          <div className='mt-2'>
            <div>
              <label className='float-left'>Perfil</label>
              <label className='float-right'>
                <Tooltip title="Apagar Perfil">
                  <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-trash mr-4'
                    onClick={() => {
                      if (selectedPerfil == '0')
                        return message.error('Selecione um perfil para apagar')

                      //else if (selectedPerfil.flagEditar == false)

                      Modal.confirm({
                        title: 'Apagar Perfil',
                        content: 'Deseja realmente apagar este perfil?',
                        cancelText: 'Não', okText: 'Sim',
                        onOk: async () => {
                          try {
                            setLoading({ status: true, message: 'Apagando Perfil...' })
                            await api.post(`GestaoAcesso/RemoverPerfil?IdPerfil=${selectedPerfil}`)
                            setSelectedPerfil('0')
                            await GetPerfis()
                            message.success('Perfil apagado com sucesso!')

                          } catch (error: any) {
                            if (error?.response && error?.response?.status === 400) {
                              setLoading({ status: false, message: '' })
                              return Modal.error({
                                afterClose: () => Modal.destroyAll(),
                                title: 'Erro ao apagar perfil',
                                content: error?.response?.data?.charAt(0)?.toUpperCase() + error?.response?.data?.slice(1) || 'Erro ao apagar perfil'
                              })
                            }

                            message.error('Erro ao apagar perfil')
                          } finally {
                            setLoading({ status: false, message: '' })
                          }
                        }
                      })
                    }} />
                </Tooltip>
                <Tooltip title="Editar Perfil">
                  <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-pencil mr-4'
                    onClick={() => {
                      showModalPerfil('Editar Perfil');
                      allPerfis.filter((perfil: any) => {
                        perfil.id == selectedPerfil && setNewPerfil({ ...perfil.info, id: perfil.id, flagEditar: perfil.flagEditar })
                      })
                    }} />
                </Tooltip>
                <Tooltip title="Atributos de Perfil">
                  <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-info mr-4'
                    onClick={async () => {
                      const { data } = await api.get(`ConfiguracaoForm?instituicao=${sessionStorage.getItem('b2e:instituicao')}`)
                      var atribPerfis: any = data?.gestaoAcesso?.camposCustomPerfil || []

                      function TagsInput() {
                        const baseTagifySettings = { backspace: "none", placeholder: "digite aqui.." };

                        const handleChangeTag = (e: any) => {
                          atribPerfis = e.detail.tagify.value.map((tag: any) => tag.value)
                        };

                        const handleRemoveTag = async (e: any) => {
                          try {
                            await api.delete(`GestaoAcesso/RemoverInfoPerfil?guidInstituicao=${sessionStorage.getItem('b2e:instituicao')}&chaveInfo=${e.detail.data.value}`)
                            atribPerfis = atribPerfis.filter((tag: any) => tag != e.detail.data.value)
                            //message.success('Info. Adicional de Perfil removida com sucesso')
                          } catch (error) {
                            message.error('Erro ao remover Info. Adicional de Perfil')
                          }
                        };

                        const settings = {
                          ...baseTagifySettings,
                          editTags: false,
                          callbacks: {
                            add: handleChangeTag,
                            remove: handleRemoveTag,
                            invalid: () => { message.error("Atributo inválido ou já existente") },
                          }
                        };

                        return <Tags settings={settings} initialValue={values?.gestaoAcesso?.camposCustomPerfil} />
                      }

                      Modal.info({
                        closable: true,
                        title: 'Insira um novo Atributo de Perfil:',
                        content: <TagsInput />,
                        async onOk() {
                          try {
                            await api.post(`ConfiguracaoForm`, { ...c, gestaoAcesso: { camposCustomPerfil: atribPerfis } })
                            setFieldValue('gestaoAcesso.camposCustomPerfil', atribPerfis);
                            message.success('Info. Adicional de Perfil editada com sucesso')
                          } catch (error) {
                            console.log("error: ", error);
                            message.error('Erro ao adicionar Info. Adicional de Perfil')
                          }
                        },
                      });
                    }} />
                </Tooltip>
                <Tooltip title="Adicionar Perfil">
                  <i style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220' }} className='fa fa-plus mr-4'
                    onClick={() => { showModalPerfil('Adicionar Perfil'); setNewPerfil(mockPerfil) }} />
                </Tooltip>
              </label>
            </div>
            <div>
              <AntdSelect className='col-md-12'
                placeholder="Selecione um Perfil"
                value={selectedPerfil} showSearch
                onChange={(value: any) => setSelectedPerfil(value)}>
                <Option disabled value={'0'}>
                  Selecione um Perfil
                </Option>
                {allPerfis && allPerfis.map((perfil: any) =>
                  <Option value={`${perfil.id}`} key={perfil.id}>
                    {perfil.info.nome} | {perfil.info.desc}
                  </Option>)}
              </AntdSelect>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-4">
        <Button onClick={syncPerfilUser} style={{
          backgroundColor: c?.interface?.corPrimaria || '#881220',
          border: 0, color: '#ffffff'
        }}>
          Salvar configurações
        </Button>
      </div>

      <ModalLoading open={loading.status} message={loading.message} />
    </>
  );
}
