function validaCep(cep: string) {
  cep = cep.replace(/\D/g, '');

  if (
    cep === '00000000' ||
    cep === '11111111' ||
    cep === '22222222' ||
    cep === '33333333' ||
    cep === '44444444' ||
    cep === '55555555' ||
    cep === '66666666' ||
    cep === '77777777' ||
    cep === '88888888' ||
    cep === '99999999'
  ) {
    return false;
  }

  const validacep = /\d{8}/;

  return !!validacep.test(cep);
}

export default validaCep;
