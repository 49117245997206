import * as S from './styles';

function Spinner() {
  return (
    <S.Container>
      <div className="dot1" />
      <div className="dot2" />
    </S.Container>
  );
}

export default Spinner;
