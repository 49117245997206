import { useEffect, useState } from 'react';
import { Button, Modal, DatePicker, message } from 'antd';
import Icon, { CheckCircleOutlined } from '@ant-design/icons';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { validateBr } from 'js-brasil';

import ModalBiometria from '@components/Modal/ModalBiometria';
import BioImg from '@assets/images/scan-facial.png';
import moment from 'moment';
import api from '@services/api';
import axios from 'axios';
import ModalLoading from '@components/Modal/ModalLoading';

const Biometria = () => {
  let nestedDataRev: any = JSON.parse(sessionStorage?.getItem('nestedDataRev') || '[]')
  const [validated, setValidated] = useState<boolean>(false);
  const [validatedInitData, setValidatedInitData] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleB2E, setIsModalVisibleB2E]: any = useState(false);
  const [base64, setBase64] = useState<string>('');
  const [dtValue, setDtValue] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isModalVisibleB2E)
      window.addEventListener('message', ({ data }: any) => {
        if (data != 'sucess') return;

        setTimeout(() => {
          setIsModalVisibleB2E(false)
          setValidated(true)
          Modal.destroyAll();
        }, 3333);
      })
  }, [isModalVisibleB2E])

  return (
    <>
      <ModalLoading open={loading} message='Aguarde, estamos processando sua solicitação..' />
      {!validated ? (
        <>
          <div className="col-lg-12 d-flex justify-content-center">
            <h3>Precisamos validar sua identidade</h3>
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <img src={BioImg} alt="" width={130} className="m-3" />
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <Button type="primary" onClick={async ({ currentTarget: btnGo }) => {
              btnGo.setAttribute('disabled', 'disabled')
              setTimeout(() => btnGo.removeAttribute('disabled'), 3333)

              // Verifica se o navegador suporta a API getUserMedia
              if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                // Tenta acessar a webcam
                await navigator.mediaDevices.getUserMedia({ video: true })
                  .then(function (stream) {
                    // O usuário possui uma webcam
                    sessionStorage.setItem('webcamStatus', 'true');
                  })
                  .catch(function (error) {
                    // O usuário não possui uma webcam ou o acesso foi negado
                    sessionStorage.setItem('webcamStatus', 'false');
                  });
              } else {
                // O navegador não suporta a API getUserMedia
                sessionStorage.setItem('webcamStatus', 'false');
              }

              if (sessionStorage.getItem('webcamStatus') !== 'true') {
                btnGo.removeAttribute('disabled')
                return message.error('Webcam não encontrada ou permissão negada');
              }


              switch (sessionStorage.getItem('bioServico')) {
                case 'b2e|biometria facial':
                  const handleSubmit = async () => {
                    try {
                      setLoading(true);
                      let { data: d }: any = await api.post(`Biometria/GerarUrl`, {
                        "guidInstituicao": sessionStorage.getItem('b2e:instituicao'),
                        "parametros": {
                          "nome": sessionStorage.getItem('b2e:nomeBioTemp') || sessionStorage.getItem('b2e:nomeBioEtapa') || nestedDataRev?.nome,
                          "cpf": sessionStorage.getItem('b2e:cpfBioTemp') || sessionStorage.getItem('b2e:cpfBioEtapa') || nestedDataRev?.cpf,
                          "dataNascimento": moment(
                            sessionStorage.getItem('b2e:dtNascBioTemp') ||
                            sessionStorage.getItem('b2e:dtNascBioEtapa') ||
                            new Date(nestedDataRev?.['dt-nascimento']).toLocaleDateString("pt-BR")
                            , 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
                        }
                      })

                      setIsModalVisibleB2E(true)
                      sessionStorage.setItem('b2e:ProcessoBio', d.processo)

                    } catch (error) {
                      message.error('Erro ao gerar processo, tente novamente mais tarde');

                    } finally {
                      await Modal.destroyAll();
                      setLoading(false);
                    }
                  }

                  const handleValidate = async (cpf: any, nome: any, dtNascimento: any) => {
                    if (!cpf) {
                      message.error('Para prosseguir com a Biometria, informe o CPF');
                      return false;

                    } else if (!nome) {
                      message.error('Para prosseguir com a Biometria, informe o Nome');
                      return false;

                    } else if (!dtNascimento) {
                      message.error('Para prosseguir com a Biometria, informe a Data de Nascimento');
                      return false;

                    } else if (!validateBr.cpf(cpf)) {
                      message.error('CPF inválido');
                      return false;
                    }

                    return true;
                  }

                  if (sessionStorage.getItem('bioAuth') == 'false') {
                    if (await handleValidate(sessionStorage.getItem('b2e:cpfBioEtapa'), sessionStorage.getItem('b2e:nomeBioEtapa'), sessionStorage.getItem('b2e:dtNascBioEtapa')))
                      return await handleSubmit()

                  } else {
                    const handleContinue = async () => {
                      if (await handleValidate(sessionStorage.getItem('b2e:cpfBioTemp'), sessionStorage.getItem('b2e:nomeBioTemp'), sessionStorage.getItem('b2e:dtNascBioTemp')))
                        await handleSubmit()
                    }

                    return Modal.info({
                      title: 'Insira os dados de autenticação',
                      okText: 'Continuar', centered: true, closable: true,
                      okButtonProps: { style: { display: 'none' } },
                      content: (
                        <>
                          <div className="input-wrapper w-75">
                            <label>CPF</label>
                            <ReactInputMask id="cpf" mask="999.999.999-99" placeholder="CPF" maskChar=""
                              onChange={({ target: { value } }: any) => {
                                sessionStorage.setItem('b2e:cpfBioTemp', value?.replace(/\D/g, ''))
                              }} />
                          </div>
                          <div className="input-wrapper w-75 my-2">
                            <label>Nome</label>
                            <input placeholder="Nome"
                              onChange={({ target: { value } }: any) => {
                                sessionStorage.setItem('b2e:nomeBioTemp', value)
                              }} />
                          </div>
                          <div className="datepicker-wrapper w-75">
                            <label>Data de Nascimento</label>
                            <DatePicker format={['DD/MM/YYYY', 'DDMMYYYY']} placeholder="Data de Nascimento"
                              onChange={(date: any, dateString: any) => {
                                sessionStorage.setItem('b2e:dtNascBioTemp', dateString)
                              }} />
                          </div>

                          <Button type="primary" className='mt-3 ml-auto'
                            onClick={async ({ currentTarget: btnGo }) => {
                              btnGo.setAttribute('disabled', 'disabled')
                              await handleContinue()
                              btnGo.removeAttribute('disabled')
                            }}>
                            Continuar
                          </Button>
                        </>
                      ),
                      afterClose() {
                        ['cpf', 'nome', 'dataNasc'].forEach((item) => {
                          sessionStorage.removeItem(`b2e:${item}BioTemp`)
                        })
                      },
                    });
                  }

                  break;

                default:
                  setIsModalVisible(true)
              }
            }}>
              Iniciar Validação
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="col-lg-12">
            <div className="validation-icon">
              <CheckCircleOutlined />
            </div>
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <h3>Identidade Enviada com Sucesso</h3>
          </div>
        </>
      )
      }

      <ModalBiometria
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        width={700}
        style={{ top: 20 }}
        footer={null}
        base64={base64}
        setBase64={setBase64}
        setValidated={setValidated}
        setIsModalVisible={setIsModalVisible}
      />

      <Modal open={isModalVisibleB2E} footer={null} centered destroyOnClose
        onCancel={() => { setIsModalVisibleB2E(false); Modal.destroyAll(); }}
        bodyStyle={{ margin: 0, padding: 0, height: '97vh' }}>
        <iframe src={`https://${window.origin.includes('b2egroup') ?
          'autenticar-biometria.b2egroup.com.br/' :
          'assets.b2esistemas.com.br/'}
?q=${sessionStorage.getItem('b2e:ProcessoBio')}`}
          allow="camera; microphone" width="100%" height="100%" />
        <style>{`.antd-modal-header { display: none; }`}</style>
      </Modal>
    </>
  );
};

export default Biometria;