export const estadoCivilOptions = [
  {
    id: 1,
    descricao: 'Solteiro',
  },
  {
    id: 2,
    descricao: 'Casado',
  },
  {
    id: 3,
    descricao: 'Divorciado',
  },
  {
    id: 4,
    descricao: 'Desquitado',
  },
  {
    id: 5,
    descricao: 'Viúvo',
  },
  {
    id: 6,
    descricao: 'União Estável',
  },
  {
    id: 7,
    descricao: 'Separado',
  },
  {
    id: 8,
    descricao: 'Amasiado',
  },
];

export const modalidadeOptions = [
  {
    id: 139,
    descricao: 'Limite',
  },
];

export const formaPagamentoOptions = [
  /* {
    id: 1,
    descricao: 'Cartão',
  },
  {
    id: 2,
    descricao: 'Cheque',
  }, */
  {
    id: 4,
    descricao: 'À Vista',
    ref: 'cash',
  },
  {
    id: 3,
    descricao: 'Boleto',
    ref: 'bank_slip',
  },
  {
    id: 8,
    descricao: 'Cartão de Crédito',
    ref: 'credit_card',
  },
  {
    id: 9,
    descricao: 'Cartão de Débito',
    ref: 'debit_card',
  },
  {
    id: 5,
    descricao: 'Depósito',
    ref: 'deposit',
  },
  {
    id: 6,
    descricao: 'Dinheiro',
    ref: 'dinheiro',
  },
  {
    id: 10,
    descricao: 'PIX',
    ref: 'pix',
  },
];

export const nacionalidadeOptions = [
  {
    id: 16,
    descricao: 'Brasileiro',
  },
  {
    id: 1,
    descricao: 'Afegão',
  },
  {
    id: 2,
    descricao: 'Africano',
  },
  {
    id: 3,
    descricao: 'Albanês',
  },
];

export const sexoOptions = [
  {
    id: 'FEMININO',
    descricao: 'Feminino',
  },
  {
    id: 'MASCULINO',
    descricao: 'Masculino',
  },
];

export const naturezaOcupacaoOptions = [
  { id: 1, descricao: 'Assalariado', },
  { id: 2, descricao: 'Autônomo', },
  { id: 3, descricao: 'Profissional Liberal', },
  { id: 4, descricao: 'Sócio-Proprietário', },
  { id: 5, descricao: 'Aposentado/Pensionista', },
  { id: 6, descricao: 'Outros', },
];



let profArray = [
  { id: '612510 Abacaxicultor', descricao: 'Abacaxicultor' },
  { id: '263105 Abade', descricao: 'Abade' },
  { id: '263105 Abadessa', descricao: 'Abadessa' },
  { id: '622020 Abanador na agricultura', descricao: 'Abanador na agricultura' },
  { id: '862120 Abastecedor de caldeira', descricao: 'Abastecedor de caldeira' },
  { id: '862160 Abastecedor de combustível de aeronave', descricao: 'Abastecedor de combustível de aeronave' },
  { id: '784205 Abastecedor de linha de produção', descricao: 'Abastecedor de linha de produção' },
  { id: '784205 Abastecedor de máquinas de linha de produção', descricao: 'Abastecedor de máquinas de linha de produção' },
  { id: '632605 Abastecedor de silos de carvão', descricao: 'Abastecedor de silos de carvão' },
  { id: '848505 Abatedor', descricao: 'Abatedor' },
  { id: '848505 Abatedor em matadouro', descricao: 'Abatedor em matadouro' },
  { id: '632125 Abatedor - na extração de madeira', descricao: 'Abatedor - na extração de madeira' },
  { id: '848505 Abatedor de animais', descricao: 'Abatedor de animais' },
  { id: '848505 Abatedor de aves', descricao: 'Abatedor de aves' },
  { id: '848505 Abatedor de gado', descricao: 'Abatedor de gado' },
  { id: '848505 Abatedor de galinha', descricao: 'Abatedor de galinha' },
  { id: '848505 Abatedor de porco', descricao: 'Abatedor de porco' },
  { id: '613405 Abelheiro', descricao: 'Abelheiro' },
  { id: '711410 Abridor - nas salinas', descricao: 'Abridor - nas salinas' },
  { id: '761205 Abridor de lã', descricao: 'Abridor de lã' },
  { id: '764305 Acabador de calçados', descricao: 'Acabador de calçados' },
  { id: '768125 Acabador de chapéus de palha', descricao: 'Acabador de chapéus de palha' },
  { id: '766305 Acabador de embalagens (flexíveis e cartotécnicas)', descricao: 'Acabador de embalagens (flexíveis e cartotécnicas)' },
  { id: '722305 Acabador de macho - na fundição', descricao: 'Acabador de macho - na fundição' },
  { id: '716525 Acabador de mármore e granito', descricao: 'Acabador de mármore e granito' },
  { id: '722315 Acabador de moldes (fundição)', descricao: 'Acabador de moldes (fundição)' },
  { id: '712220 Acabador de pedras', descricao: 'Acabador de pedras' },
  { id: '716105 Acabador de superfícies de concreto', descricao: 'Acabador de superfícies de concreto' },
  { id: '715205 Acafelador', descricao: 'Acafelador' },
  { id: '632405 Açaizeiro', descricao: 'Açaizeiro' },
  { id: '721325 Acepilhador de metais', descricao: 'Acepilhador de metais' },
  { id: '761240 Acertador de maçaroqueira', descricao: 'Acertador de maçaroqueira' },
  { id: '516210 Acompanhante de idosos', descricao: 'Acompanhante de idosos' },
  { id: '848510 Açougueiro', descricao: 'Açougueiro' },
  { id: '848520 Açougueiro classificador (exclusive comércio)', descricao: 'Açougueiro classificador (exclusive comércio)' },
  { id: '848525 Açougueiro cortador (exclusive comércio)', descricao: 'Açougueiro cortador (exclusive comércio)' },
  { id: '848515 Açougueiro desossador', descricao: 'Açougueiro desossador' },
  { id: '848510 Açougueiro retalhista', descricao: 'Açougueiro retalhista' },
  { id: '376205 Acrobata', descricao: 'Acrobata' },
  { id: '322105 Acupuntor', descricao: 'Acupuntor' },
  { id: '322105 Acupunturista', descricao: 'Acupunturista' },
  { id: '261505 Adaptador de obras para teatro, cinema e televisão', descricao: 'Adaptador de obras para teatro, cinema e televisão' },
  { id: '141410 Adegueiro', descricao: 'Adegueiro' },
  { id: '141405 Adeleiro (comércio atacadista)', descricao: 'Adeleiro (comércio atacadista)' },
  { id: '141410 Adeleiro (comércio varejista)', descricao: 'Adeleiro (comércio varejista)' },
  { id: '523120 Adesivador', descricao: 'Adesivador' },
  { id: '623125 Adestrador (eqüinos)', descricao: 'Adestrador (eqüinos)' },
  { id: '623005 Adestrador de animais', descricao: 'Adestrador de animais' },
  { id: '623105 Adestrador de animais de trabalho ( asininos e muares)', descricao: 'Adestrador de animais de trabalho ( asininos e muares)' },
  { id: '252105 Administrador', descricao: 'Administrador' },
  { id: '141505 Administrador (serviço de hospedagem)', descricao: 'Administrador (serviço de hospedagem)' },
  { id: '263105 Administrador apostólico', descricao: 'Administrador apostólico' },
  { id: '131210 Administrador de ambulatório', descricao: 'Administrador de ambulatório' },
  { id: '261305 Administrador de arquivos', descricao: 'Administrador de arquivos' },
  { id: '212305 Administrador de banco de dados', descricao: 'Administrador de banco de dados' },
  { id: '141505 Administrador de camping', descricao: 'Administrador de camping' },
  { id: '141715 Administrador de carteiras de câmbio e comércio exterior', descricao: 'Administrador de carteiras de câmbio e comércio exterior' },
  { id: '141720 Administrador de carteiras de crédito e cobrança', descricao: 'Administrador de carteiras de crédito e cobrança' },
  { id: '141725 Administrador de carteiras de crédito imobiliário', descricao: 'Administrador de carteiras de crédito imobiliário' },
  { id: '142405 Administrador de compras', descricao: 'Administrador de compras' },
  { id: '252210 Administrador de contadorias e registros fiscais', descricao: 'Administrador de contadorias e registros fiscais' },
  { id: '510110 Administrador de edifícios', descricao: 'Administrador de edifícios' },
  { id: '252105 Administrador de empresas', descricao: 'Administrador de empresas' },
  { id: '141505 Administrador de empresas de hospedagem', descricao: 'Administrador de empresas de hospedagem' },
  { id: '141120 Administrador de empresas de pesca', descricao: 'Administrador de empresas de pesca' },
  { id: '252505 Administrador de fundos e carteiras de investimento', descricao: 'Administrador de fundos e carteiras de investimento' },
  { id: '131115 Administrador de instituições culturais', descricao: 'Administrador de instituições culturais' },
  { id: '252105 Administrador de marketing', descricao: 'Administrador de marketing' },
  { id: '142415 Administrador de materiais', descricao: 'Administrador de materiais' },
  { id: '252105 Administrador de orçamento', descricao: 'Administrador de orçamento' },
  { id: '252105 Administrador de patrimônio', descricao: 'Administrador de patrimônio' },
  { id: '252105 Administrador de pequena e média empresa', descricao: 'Administrador de pequena e média empresa' },
  { id: '142210 Administrador de pessoal', descricao: 'Administrador de pessoal' },
  { id: '510110 Administrador de prédios', descricao: 'Administrador de prédios' },
  { id: '252105 Administrador de recursos humanos', descricao: 'Administrador de recursos humanos' },
  { id: '252105 Administrador de recursos tecnológicos', descricao: 'Administrador de recursos tecnológicos' },
  { id: '212310 Administrador de rede e de sistemas computacionais', descricao: 'Administrador de rede e de sistemas computacionais' },
  { id: '212310 Administrador de redes', descricao: 'Administrador de redes' },
  { id: '141510 Administrador de refeitório', descricao: 'Administrador de refeitório' },
  { id: '141510 Administrador de restaurante', descricao: 'Administrador de restaurante' },
  { id: '212310 Administrador de sistema operacional de rede', descricao: 'Administrador de sistema operacional de rede' },
  { id: '212315 Administrador de sistemas computacionais', descricao: 'Administrador de sistemas computacionais' },
  { id: '212315 Administrador de sistemas operacionais', descricao: 'Administrador de sistemas operacionais' },
  { id: '212315 Administrador de sistemas operacionais de rede', descricao: 'Administrador de sistemas operacionais de rede' },
  { id: '212320 Administrador em segurança da informação', descricao: 'Administrador em segurança da informação' },
  { id: '252105 Administrador financeiro', descricao: 'Administrador financeiro' },
  { id: '321210 Administrador florestal', descricao: 'Administrador florestal' },
  { id: '252105 Administrador hospitalar', descricao: 'Administrador hospitalar' },
  { id: '141415 Administrador no comércio de mercadorias', descricao: 'Administrador no comércio de mercadorias' },
  { id: '263105 Administrador paroquial', descricao: 'Administrador paroquial' },
  { id: '252105 Administrador público', descricao: 'Administrador público' },
  { id: '2521 ADMINISTRADORES', descricao: 'ADMINISTRADORES' },
  { id: '2123 ADMINISTRADORES DE TECNOLOGIA DA INFORMAÇÃO', descricao: 'ADMINISTRADORES DE TECNOLOGIA DA INFORMAÇÃO' },
  { id: '622020 Adubador', descricao: 'Adubador' },
  { id: '241005 Advogado', descricao: 'Advogado' },
  { id: '241030 Advogado (abuso do poder econômico)', descricao: 'Advogado (abuso do poder econômico)' },
  { id: '241030 Advogado (aeroespacial)', descricao: 'Advogado (aeroespacial)' },
  { id: '241030 Advogado (agente de propriedade industrial)', descricao: 'Advogado (agente de propriedade industrial)' },
  { id: '241030 Advogado (ambientalista)', descricao: 'Advogado (ambientalista)' },
  { id: '241030 Advogado (arbitragem)', descricao: 'Advogado (arbitragem)' },
  { id: '241030 Advogado (áreas especiais)', descricao: 'Advogado (áreas especiais)' },
  { id: '241030 Advogado (biodireito)', descricao: 'Advogado (biodireito)' },
  { id: '241030 Advogado (concorrência desleal)', descricao: 'Advogado (concorrência desleal)' },
  { id: '241030 Advogado (desportivo)', descricao: 'Advogado (desportivo)' },
  { id: '241020 Advogado (direito administrativo)', descricao: 'Advogado (direito administrativo)' },
  { id: '241015 Advogado (direito civil)', descricao: 'Advogado (direito civil)' },
  { id: '241035 Advogado (direito do trabalho)', descricao: 'Advogado (direito do trabalho)' },
  { id: '241030 Advogado (direito internacional)', descricao: 'Advogado (direito internacional)' },
  { id: '241025 Advogado (direito penal)', descricao: 'Advogado (direito penal)' },
  { id: '241020 Advogado (direito público)', descricao: 'Advogado (direito público)' },
  { id: '241030 Advogado (direitos da criança e do adolescente)', descricao: 'Advogado (direitos da criança e do adolescente)' },
  { id: '241030 Advogado (direitos do consumidor)', descricao: 'Advogado (direitos do consumidor)' },
  { id: '241030 Advogado (energia elétrica)', descricao: 'Advogado (energia elétrica)' },
  { id: '241030 Advogado (propriedade intelectual)', descricao: 'Advogado (propriedade intelectual)' },
  { id: '241030 Advogado (recursos hídricos e minerais)', descricao: 'Advogado (recursos hídricos e minerais)' },
  { id: '241030 Advogado (telecomunicações)', descricao: 'Advogado (telecomunicações)' },
  { id: '241015 Advogado civilista', descricao: 'Advogado civilista' },
  { id: '241015 Advogado comercial', descricao: 'Advogado comercial' },
  { id: '241020 Advogado constitucionalista', descricao: 'Advogado constitucionalista' },
  { id: '241015 Advogado contratualista', descricao: 'Advogado contratualista' },
  { id: '241025 Advogado criminalista', descricao: 'Advogado criminalista' },
  { id: '241205 Advogado da união', descricao: 'Advogado da união' },
  { id: '241010 Advogado de empresa', descricao: 'Advogado de empresa' },
  { id: '241010 Advogado empresarial', descricao: 'Advogado empresarial' },
  { id: '241020 Advogado fiscal (direito fiscal)', descricao: 'Advogado fiscal (direito fiscal)' },
  { id: '241005 Advogado generalista', descricao: 'Advogado generalista' },
  { id: '241020 Advogado previdenciário', descricao: 'Advogado previdenciário' },
  { id: '241035 Advogado trabalhista', descricao: 'Advogado trabalhista' },
  { id: '241020 Advogado tributarista', descricao: 'Advogado tributarista' },
  { id: '2410 ADVOGADOS', descricao: 'ADVOGADOS' },
  { id: '241030 Advogados (direito eletrônico)', descricao: 'Advogados (direito eletrônico)' },
  { id: '511105 Aeromoça', descricao: 'Aeromoça' },
  { id: '721315 Afiador - na fabricação', descricao: 'Afiador - na fabricação' },
  { id: '721310 Afiador ambulante', descricao: 'Afiador ambulante' },
  { id: '721315 Afiador de brocas e coroas', descricao: 'Afiador de brocas e coroas' },
  { id: '721305 Afiador de cardas', descricao: 'Afiador de cardas' },
  { id: '721310 Afiador de cutelaria', descricao: 'Afiador de cutelaria' },
  { id: '721310 Afiador de facas', descricao: 'Afiador de facas' },
  { id: '721310 Afiador de facas ( inclusive ambulante)', descricao: 'Afiador de facas ( inclusive ambulante)' },
  { id: '721315 Afiador de ferramentas', descricao: 'Afiador de ferramentas' },
  { id: '721320 Afiador de lâminas', descricao: 'Afiador de lâminas' },
  { id: '721320 Afiador de serras', descricao: 'Afiador de serras' },
  { id: '721315 Afiador de talhadeira', descricao: 'Afiador de talhadeira' },
  { id: '721310 Afiador de tesouras ( inclusive ambulante)', descricao: 'Afiador de tesouras ( inclusive ambulante)' },
  { id: '721320 Afiador e laminador de serras', descricao: 'Afiador e laminador de serras' },
  { id: '721315 Afiador manual', descricao: 'Afiador manual' },
  { id: '721315 Afiador, a máquina', descricao: 'Afiador, a máquina' },
  { id: '7213 AFIADORES E POLIDORES DE METAIS', descricao: 'AFIADORES E POLIDORES DE METAIS' },
  { id: '742105 Afinador de instrumentos musicais', descricao: 'Afinador de instrumentos musicais' },
  { id: '914425 Afinador de motores a diesel', descricao: 'Afinador de motores a diesel' },
  { id: '914405 Afinador de motores de automóveis', descricao: 'Afinador de motores de automóveis' },
  { id: '911120 Afinador de motores diesel (exceto de veículos automotores)', descricao: 'Afinador de motores diesel (exceto de veículos automotores)' },
  { id: '915210 Afinador de piano', descricao: 'Afinador de piano' },
  { id: '725010 Afinador mecânico', descricao: 'Afinador mecânico' },
  { id: '342120 Afretador', descricao: 'Afretador' },
  { id: '263105 Agaipi', descricao: 'Agaipi' },
  { id: '263115 Agbá', descricao: 'Agbá' },
  { id: '263105 Agbagigan', descricao: 'Agbagigan' },
  { id: '342120 Agenciador de cargas', descricao: 'Agenciador de cargas' },
  { id: '253140 Agenciador de propaganda', descricao: 'Agenciador de propaganda' },
  { id: '253140 Agenciador de publicidade', descricao: 'Agenciador de publicidade' },
  { id: '411010 Agente administrativo', descricao: 'Agente administrativo' },
  { id: '410105 Agente administrativo supervisor', descricao: 'Agente administrativo supervisor' },
  { id: '515105 Agente comunitário de saúde', descricao: 'Agente comunitário de saúde' },
  { id: '517235 Agente da guarda portuária', descricao: 'Agente da guarda portuária' },
  { id: '515310 Agente de ação social', descricao: 'Agente de ação social' },
  { id: '515325 Agente de apoio socioeducativo', descricao: 'Agente de apoio socioeducativo' },
  { id: '341235 Agente de atuidade marítima e fluvial', descricao: 'Agente de atuidade marítima e fluvial' },
  { id: '342120 Agente de carga', descricao: 'Agente de carga' },
  { id: '424105 Agente de coleta ( censo e pesquisas amostrais)', descricao: 'Agente de coleta ( censo e pesquisas amostrais)' },
  { id: '514205 Agente de coleta de lixo', descricao: 'Agente de coleta de lixo' },
  { id: '420120 Agente de coleta supervisor', descricao: 'Agente de coleta supervisor' },
  { id: '515140 Agente de combate às endemias', descricao: 'Agente de combate às endemias' },
  { id: '342105 Agente de comércio exterior', descricao: 'Agente de comércio exterior' },
  { id: '515140 Agente de controle de vetores', descricao: 'Agente de controle de vetores' },
  { id: '515140 Agente de contrrole de dengue', descricao: 'Agente de contrrole de dengue' },
  { id: '352205 Agente de defesa ambiental', descricao: 'Agente de defesa ambiental' },
  { id: '352205 Agente de defesa florestal', descricao: 'Agente de defesa florestal' },
  { id: '352405 Agente de direitos autorais', descricao: 'Agente de direitos autorais' },
  { id: '342405 Agente de estação (ferrovia e metrô)', descricao: 'Agente de estação (ferrovia e metrô)' },
  { id: '783105 Agente de estação (manobras)', descricao: 'Agente de estação (manobras)' },
  { id: '342605 Agente de estação portuária', descricao: 'Agente de estação portuária' },
  { id: '517230 Agente de execução penal', descricao: 'Agente de execução penal' },
  { id: '352205 Agente de fiscalização ambiental', descricao: 'Agente de fiscalização ambiental' },
  { id: '254310 Agente de higiene e segurança', descricao: 'Agente de higiene e segurança' },
  { id: '391205 Agente de inspeção (qualidade)', descricao: 'Agente de inspeção (qualidade)' },
  { id: '352205 Agente de inspeção de pesca', descricao: 'Agente de inspeção de pesca' },
  { id: '351905 Agente de inteligência', descricao: 'Agente de inteligência' },
  { id: '517110 Agente de investigação de incêndio', descricao: 'Agente de investigação de incêndio' },
  { id: '351805 Agente de investigação privada', descricao: 'Agente de investigação privada' },
  { id: '215105 Agente de manobra e docagem', descricao: 'Agente de manobra e docagem' },
  { id: '914305 Agente de manutenção de veículos ferroviários', descricao: 'Agente de manutenção de veículos ferroviários' },
  { id: '411050 Agente de microcrédito', descricao: 'Agente de microcrédito' },
  { id: '411050 Agente de microfinanças', descricao: 'Agente de microfinanças' },
  { id: '141605 Agente de navio - no transporte marítimo', descricao: 'Agente de navio - no transporte marítimo' },
  { id: '334110 Agente de organização escolar', descricao: 'Agente de organização escolar' },
  { id: '421120 Agente de passagens', descricao: 'Agente de passagens' },
  { id: '421120 Agente de passagens aéreas', descricao: 'Agente de passagens aéreas' },
  { id: '263105 Agente de pastoral', descricao: 'Agente de pastoral' },
  { id: '783105 Agente de pátio', descricao: 'Agente de pátio' },
  { id: '342405 Agente de pátio e terminais (ferrovia e metrô)', descricao: 'Agente de pátio e terminais (ferrovia e metrô)' },
  { id: '424105 Agente de pesquisa', descricao: 'Agente de pesquisa' },
  { id: '517205 Agente de polícia federal', descricao: 'Agente de polícia federal' },
  { id: '517225 Agente de polícia legislativa', descricao: 'Agente de polícia legislativa' },
  { id: '517415 Agente de portaria', descricao: 'Agente de portaria' },
  { id: '342550 Agente de proteção de aviação civil', descricao: 'Agente de proteção de aviação civil' },
  { id: '515310 Agente de proteção social', descricao: 'Agente de proteção social' },
  { id: '515310 Agente de proteção social de rua', descricao: 'Agente de proteção social de rua' },
  { id: '519205 Agente de reciclagem de materiais', descricao: 'Agente de reciclagem de materiais' },
  { id: '351315 Agente de recrutamento e seleção', descricao: 'Agente de recrutamento e seleção' },
  { id: '421120 Agente de reserva de passagens aéreas', descricao: 'Agente de reserva de passagens aéreas' },
  { id: '421120 Agente de reserva de passagens rodoviárias', descricao: 'Agente de reserva de passagens rodoviárias' },
  { id: '354810 Agente de reservas', descricao: 'Agente de reservas' },
  { id: '515330 Agente de ressocialização prisional', descricao: 'Agente de ressocialização prisional' },
  { id: '352210 Agente de saneamento', descricao: 'Agente de saneamento' },
  { id: '515105 Agente de saúde', descricao: 'Agente de saúde' },
  { id: '352210 Agente de saúde pública', descricao: 'Agente de saúde pública' },
  { id: '517310 Agente de segurança', descricao: 'Agente de segurança' },
  { id: '517330 Agente de segurança ferroviária', descricao: 'Agente de segurança ferroviária' },
  { id: '517230 Agente de segurança penitenciária', descricao: 'Agente de segurança penitenciária' },
  { id: '515325 Agente de segurança socioeducativa', descricao: 'Agente de segurança socioeducativa' },
  { id: '511205 Agente de serviços de fiscalização de transportes coletivos e licenciamento de veículos', descricao: 'Agente de serviços de fiscalização de transportes coletivos e licenciamento de veículos' },
  { id: '422105 Agente de tráfego', descricao: 'Agente de tráfego' },
  { id: '517220 Agente de trânsito', descricao: 'Agente de trânsito' },
  { id: '342120 Agente de transporte', descricao: 'Agente de transporte' },
  { id: '517220 Agente de transporte e trânsito', descricao: 'Agente de transporte e trânsito' },
  { id: '421120 Agente de venda de passagens', descricao: 'Agente de venda de passagens' },
  { id: '354120 Agente de vendas de serviços', descricao: 'Agente de vendas de serviços' },
  { id: '354815 Agente de viagem', descricao: 'Agente de viagem' },
  { id: '951320 Agente de vistoria de alarmes', descricao: 'Agente de vistoria de alarmes' },
  { id: '515325 Agente educacional', descricao: 'Agente educacional' },
  { id: '334110 Agente educador', descricao: 'Agente educador' },
  { id: '352310 Agente fiscal de qualidade', descricao: 'Agente fiscal de qualidade' },
  { id: '352315 Agente fiscal metrológico', descricao: 'Agente fiscal metrológico' },
  { id: '352320 Agente fiscal têxtil', descricao: 'Agente fiscal têxtil' },
  { id: '254505 Agente fiscal(atividades urbanas)', descricao: 'Agente fiscal(atividades urbanas)' },
  { id: '516505 Agente funerário', descricao: 'Agente funerário' },
  { id: '516505 Agente funerário - tanatopraxista', descricao: 'Agente funerário - tanatopraxista' },
  { id: '515130 Agente indígena de saneamento', descricao: 'Agente indígena de saneamento' },
  { id: '515125 Agente indígena de saúde', descricao: 'Agente indígena de saúde' },
  { id: '421110 Agente operacional de estação', descricao: 'Agente operacional de estação' },
  { id: '517230 Agente penitenciário', descricao: 'Agente penitenciário' },
  { id: '351810 Agente policial', descricao: 'Agente policial' },
  { id: '342310 Agente rodoviário', descricao: 'Agente rodoviário' },
  { id: '352210 Agente sanitarista', descricao: 'Agente sanitarista' },
  { id: '515310 Agente social', descricao: 'Agente social' },
  { id: '351910 Agente técnico de inteligência', descricao: 'Agente técnico de inteligência' },
  { id: '862205 Agente técnico de operação na captação, tratamento e distribuição de água', descricao: 'Agente técnico de operação na captação, tratamento e distribuição de água' },
  { id: '254505 Agente vistor', descricao: 'Agente vistor' },
  { id: '3523 AGENTES FISCAIS METROLÓGICOS E DE QUALIDADE', descricao: 'AGENTES FISCAIS METROLÓGICOS E DE QUALIDADE' },
  { id: '3522 AGENTES DA SAÚDE E DO MEIO AMBIENTE', descricao: 'AGENTES DA SAÚDE E DO MEIO AMBIENTE' },
  { id: '3518 AGENTES DE INVESTIGAÇÃO E IDENTIFICAÇÃO', descricao: 'AGENTES DE INVESTIGAÇÃO E IDENTIFICAÇÃO' },
  { id: '4110 AGENTES, ASSISTENTES E AUXILIARES ADMINISTRATIVOS', descricao: 'AGENTES, ASSISTENTES E AUXILIARES ADMINISTRATIVOS' },
  { id: '263105 Agonjaí', descricao: 'Agonjaí' },
  { id: '621005 Agregado - na agropecuária', descricao: 'Agregado - na agropecuária' },
  { id: '612005 Agricultor familiar polivalente', descricao: 'Agricultor familiar polivalente' },
  { id: '612110 Agricultor na produção da cana-de-açúcar', descricao: 'Agricultor na produção da cana-de-açúcar' },
  { id: '612105 Agricultor na produção de arroz', descricao: 'Agricultor na produção de arroz' },
  { id: '612115 Agricultor na produção de cereais de inverno', descricao: 'Agricultor na produção de cereais de inverno' },
  { id: '612120 Agricultor na produção de gramíneas forrageiras', descricao: 'Agricultor na produção de gramíneas forrageiras' },
  { id: '612125 Agricultor na produção de milho e sorgo', descricao: 'Agricultor na produção de milho e sorgo' },
  { id: '612005 Agricultor polivalente', descricao: 'Agricultor polivalente' },
  { id: '612005 Agricultor polivalente - conta própria', descricao: 'Agricultor polivalente - conta própria' },
  { id: '612005 Agricultor polivalente - empregador', descricao: 'Agricultor polivalente - empregador' },
  { id: '214805 Agrimensor', descricao: 'Agrimensor' },
  { id: '222110 Agrônomo', descricao: 'Agrônomo' },
  { id: '611005 Agropecuarista', descricao: 'Agropecuarista' },
  { id: '203025 Agrostólogo', descricao: 'Agrostólogo' },
  { id: '321105 Agrotécnico', descricao: 'Agrotécnico' },
  { id: '841705 Aguardenteiro (fabricação)', descricao: 'Aguardenteiro (fabricação)' },
  { id: '722105 Aguilhoeiro', descricao: 'Aguilhoeiro' },
  { id: '722215 Ajudante de acabamento de fundição', descricao: 'Ajudante de acabamento de fundição' },
  { id: '848510 Ajudante de açougueiro (comércio)', descricao: 'Ajudante de açougueiro (comércio)' },
  { id: '721320 Ajudante de afiação de serras', descricao: 'Ajudante de afiação de serras' },
  { id: '721315 Ajudante de afiador', descricao: 'Ajudante de afiador' },
  { id: '823235 Ajudante de banhos (vidros)', descricao: 'Ajudante de banhos (vidros)' },
  { id: '623110 Ajudante de boiadeiro', descricao: 'Ajudante de boiadeiro' },
  { id: '712120 Ajudante de britador', descricao: 'Ajudante de britador' },
  { id: '516110 Ajudante de cabeleireiro', descricao: 'Ajudante de cabeleireiro' },
  { id: '732110 Ajudante de cabista', descricao: 'Ajudante de cabista' },
  { id: '715205 Ajudante de calceteiro', descricao: 'Ajudante de calceteiro' },
  { id: '783225 Ajudante de carga e descarga de mercadoria', descricao: 'Ajudante de carga e descarga de mercadoria' },
  { id: '632615 Ajudante de carvoaria', descricao: 'Ajudante de carvoaria' },
  { id: '513505 Ajudante de churrasqueiro', descricao: 'Ajudante de churrasqueiro' },
  { id: '772105 Ajudante de classificador de folhas (madeira)', descricao: 'Ajudante de classificador de folhas (madeira)' },
  { id: '772105 Ajudante de classificador de madeira', descricao: 'Ajudante de classificador de madeira' },
  { id: '763125 Ajudante de confecção', descricao: 'Ajudante de confecção' },
  { id: '513505 Ajudante de confeiteiro', descricao: 'Ajudante de confeiteiro' },
  { id: '513505 Ajudante de cozinha', descricao: 'Ajudante de cozinha' },
  { id: '632120 Ajudante de derrubada', descricao: 'Ajudante de derrubada' },
  { id: '342205 Ajudante de despachante aduaneiro', descricao: 'Ajudante de despachante aduaneiro' },
  { id: '342205 Ajudante de despachante alfandegário', descricao: 'Ajudante de despachante alfandegário' },
  { id: '811615 Ajudante de destilação de amônia', descricao: 'Ajudante de destilação de amônia' },
  { id: '715615 Ajudante de eletricista', descricao: 'Ajudante de eletricista' },
  { id: '784105 Ajudante de embalador', descricao: 'Ajudante de embalador' },
  { id: '784105 Ajudante de encaixotador', descricao: 'Ajudante de encaixotador' },
  { id: '784125 Ajudante de enfardamento', descricao: 'Ajudante de enfardamento' },
  { id: '784120 Ajudante de engarrafamento', descricao: 'Ajudante de engarrafamento' },
  { id: '784110 Ajudante de ensacador, a máquina', descricao: 'Ajudante de ensacador, a máquina' },
  { id: '415215 Ajudante de entregador de publicações', descricao: 'Ajudante de entregador de publicações' },
  { id: '841440 Ajudante de esterilização (indústria alimentícia)', descricao: 'Ajudante de esterilização (indústria alimentícia)' },
  { id: '724220 Ajudante de estruturas metálicas', descricao: 'Ajudante de estruturas metálicas' },
  { id: '772115 Ajudante de estufador (madeira)', descricao: 'Ajudante de estufador (madeira)' },
  { id: '811430 Ajudante de evaporador na destilação', descricao: 'Ajudante de evaporador na destilação' },
  { id: '823210 Ajudante de extrusão de fibras de vidro', descricao: 'Ajudante de extrusão de fibras de vidro' },
  { id: '828110 Ajudante de fábrica de tijolos', descricao: 'Ajudante de fábrica de tijolos' },
  { id: '841745 Ajudante de fabricação - na indústria de bebidas', descricao: 'Ajudante de fabricação - na indústria de bebidas' },
  { id: '831125 Ajudante de fabricação de celulose', descricao: 'Ajudante de fabricação de celulose' },
  { id: '521130 Ajudante de farmácia', descricao: 'Ajudante de farmácia' },
  { id: '722105 Ajudante de ferreiro', descricao: 'Ajudante de ferreiro' },
  { id: '811330 Ajudante de filtrador (filtro-prensa)', descricao: 'Ajudante de filtrador (filtro-prensa)' },
  { id: '722105 Ajudante de forjador', descricao: 'Ajudante de forjador' },
  { id: '821205 Ajudante de forneiro', descricao: 'Ajudante de forneiro' },
  { id: '722205 Ajudante de fundidor', descricao: 'Ajudante de fundidor' },
  { id: '724130 Ajudante de gasista', descricao: 'Ajudante de gasista' },
  { id: '782205 Ajudante de guincheiro', descricao: 'Ajudante de guincheiro' },
  { id: '766220 Ajudante de impressor de formulários contínuos', descricao: 'Ajudante de impressor de formulários contínuos' },
  { id: '752230 Ajudante de lapidador (vidros)', descricao: 'Ajudante de lapidador (vidros)' },
  { id: '519935 Ajudante de lavador de automóvel', descricao: 'Ajudante de lavador de automóvel' },
  { id: '516315 Ajudante de lavador de tapetes', descricao: 'Ajudante de lavador de tapetes' },
  { id: '516315 Ajudante de lavanderia de artefatos da tapeçaria', descricao: 'Ajudante de lavanderia de artefatos da tapeçaria' },
  { id: '722210 Ajudante de lingotamento', descricao: 'Ajudante de lingotamento' },
  { id: '919105 Ajudante de lubrificação (indústria)', descricao: 'Ajudante de lubrificação (indústria)' },
  { id: '775115 Ajudante de lustrador', descricao: 'Ajudante de lustrador' },
  { id: '722305 Ajudante de macheiro', descricao: 'Ajudante de macheiro' },
  { id: '914105 Ajudante de manutenção de aeronave', descricao: 'Ajudante de manutenção de aeronave' },
  { id: '782625 Ajudante de maquinista de trem', descricao: 'Ajudante de maquinista de trem' },
  { id: '717010 Ajudante de marteleteiro', descricao: 'Ajudante de marteleteiro' },
  { id: '711130 Ajudante de mineiro', descricao: 'Ajudante de mineiro' },
  { id: '766125 Ajudante de montagem e fotolito', descricao: 'Ajudante de montagem e fotolito' },
  { id: '783225 Ajudante de motorista', descricao: 'Ajudante de motorista' },
  { id: '717020 Ajudante de obras', descricao: 'Ajudante de obras' },
  { id: '828110 Ajudante de oleiro', descricao: 'Ajudante de oleiro' },
  { id: '811305 Ajudante de operador de centrífugas', descricao: 'Ajudante de operador de centrífugas' },
  { id: '862405 Ajudante de operador de gás', descricao: 'Ajudante de operador de gás' },
  { id: '782205 Ajudante de operador de guincho', descricao: 'Ajudante de operador de guincho' },
  { id: '773340 Ajudante de operador de serra', descricao: 'Ajudante de operador de serra' },
  { id: '513505 Ajudante de padeiro', descricao: 'Ajudante de padeiro' },
  { id: '848315 Ajudante de pasteleiro', descricao: 'Ajudante de pasteleiro' },
  { id: '821445 Ajudante de pátio de sucata', descricao: 'Ajudante de pátio de sucata' },
  { id: '716610 Ajudante de pintor', descricao: 'Ajudante de pintor' },
  { id: '723330 Ajudante de pintor à pistola', descricao: 'Ajudante de pintor à pistola' },
  { id: '723330 Ajudante de pintor de móveis', descricao: 'Ajudante de pintor de móveis' },
  { id: '723330 Ajudante de pintor de produção', descricao: 'Ajudante de pintor de produção' },
  { id: '513505 Ajudante de pizzaiolo', descricao: 'Ajudante de pizzaiolo' },
  { id: '519935 Ajudante de polidor de veículos', descricao: 'Ajudante de polidor de veículos' },
  { id: '721325 Ajudante de polimento', descricao: 'Ajudante de polimento' },
  { id: '722235 Ajudante de preparador de lingotamento', descricao: 'Ajudante de preparador de lingotamento' },
  { id: '841745 Ajudante de produção - na indústria de bebidas', descricao: 'Ajudante de produção - na indústria de bebidas' },
  { id: '841448 Ajudante de produção de óleos vegetais - na ind. Alimentar', descricao: 'Ajudante de produção de óleos vegetais - na ind. Alimentar' },
  { id: '848210 Ajudante de queijeiro', descricao: 'Ajudante de queijeiro' },
  { id: '821450 Ajudante de rebarbação (metais)', descricao: 'Ajudante de rebarbação (metais)' },
  { id: '632125 Ajudante de reflorestamento', descricao: 'Ajudante de reflorestamento' },
  { id: '732130 Ajudante de reparador (telecomunicações)', descricao: 'Ajudante de reparador (telecomunicações)' },
  { id: '717020 Ajudante de saneamento', descricao: 'Ajudante de saneamento' },
  { id: '773340 Ajudante de serrador', descricao: 'Ajudante de serrador' },
  { id: '724440 Ajudante de serralheiro', descricao: 'Ajudante de serralheiro' },
  { id: '622020 Ajudante de serviço de (aplicação de produtos agroquímicos)', descricao: 'Ajudante de serviço de (aplicação de produtos agroquímicos)' },
  { id: '513505 Ajudante de sushiman', descricao: 'Ajudante de sushiman' },
  { id: '841745 Ajudante de tratamento de preparo de xarope', descricao: 'Ajudante de tratamento de preparo de xarope' },
  { id: '723125 Ajudante de tratamento térmico', descricao: 'Ajudante de tratamento térmico' },
  { id: '623110 Ajudante de vaqueiro', descricao: 'Ajudante de vaqueiro' },
  { id: '841745 Ajudante xaropeiro', descricao: 'Ajudante xaropeiro' },
  { id: '513435 Ajudante, auxiliar de bar', descricao: 'Ajudante, auxiliar de bar' },
  { id: '513435 Ajudante, auxiliar de lanchonete', descricao: 'Ajudante, auxiliar de lanchonete' },
  { id: '914105 Ajudante, auxiliar de mecânico de avião', descricao: 'Ajudante, auxiliar de mecânico de avião' },
  { id: '7170 AJUDANTES DE OBRAS CIVIS', descricao: 'AJUDANTES DE OBRAS CIVIS' },
  { id: '632305 Ajuntador de andiroba', descricao: 'Ajuntador de andiroba' },
  { id: '632320 Ajuntador de buriti', descricao: 'Ajuntador de buriti' },
  { id: '632355 Ajuntador de ouricuri', descricao: 'Ajuntador de ouricuri' },
  { id: '725020 Ajustador de agulhas', descricao: 'Ajustador de agulhas' },
  { id: '715535 Ajustador de fôrmas (concreto)', descricao: 'Ajustador de fôrmas (concreto)' },
  { id: '741105 Ajustador de instrumentos de precisão', descricao: 'Ajustador de instrumentos de precisão' },
  { id: '911305 Ajustador de máquinas de embalagem', descricao: 'Ajustador de máquinas de embalagem' },
  { id: '725010 Ajustador de oficina de cilindros', descricao: 'Ajustador de oficina de cilindros' },
  { id: '821450 Ajustador de rebarbador', descricao: 'Ajustador de rebarbador' },
  { id: '725010 Ajustador de válvulas', descricao: 'Ajustador de válvulas' },
  { id: '725005 Ajustador ferramenteiro', descricao: 'Ajustador ferramenteiro' },
  { id: '725020 Ajustador limador de fresas', descricao: 'Ajustador limador de fresas' },
  { id: '725020 Ajustador limador de tornos', descricao: 'Ajustador limador de tornos' },
  { id: '725010 Ajustador mecânico', descricao: 'Ajustador mecânico' },
  { id: '725015 Ajustador mecânico (usinagem em bancada e em máquinas-ferramentas)', descricao: 'Ajustador mecânico (usinagem em bancada e em máquinas-ferramentas)' },
  { id: '725010 Ajustador mecânico de ferramentas', descricao: 'Ajustador mecânico de ferramentas' },
  { id: '725010 Ajustador mecânico de manutenção', descricao: 'Ajustador mecânico de manutenção' },
  { id: '725020 Ajustador mecânico em bancada', descricao: 'Ajustador mecânico em bancada' },
  { id: '725025 Ajustador naval (reparo e construção)', descricao: 'Ajustador naval (reparo e construção)' },
  { id: '7250 AJUSTADORES MECÂNICOS POLIVALENTES E AFINS', descricao: 'AJUSTADORES MECÂNICOS POLIVALENTES E AFINS' },
  { id: '263105 Alabê', descricao: 'Alabê' },
  { id: '841705 Alambiqueiro', descricao: 'Alambiqueiro' },
  { id: '722415 Alambrador - na fabricação de arame', descricao: 'Alambrador - na fabricação de arame' },
  { id: '263105 Alapini', descricao: 'Alapini' },
  { id: '263105 Alayan', descricao: 'Alayan' },
  { id: '811405 Alcatroeiro', descricao: 'Alcatroeiro' },
  { id: '225110 Alergista', descricao: 'Alergista' },
  { id: '225110 Alergista e imunologista', descricao: 'Alergista e imunologista' },
  { id: '225110 Alergologista', descricao: 'Alergologista' },
  { id: '612315 Alfaceiro', descricao: 'Alfaceiro' },
  { id: '763005 Alfaiate', descricao: 'Alfaiate' },
  { id: '763005 Alfaite modelista', descricao: 'Alfaite modelista' },
  { id: '141405 Alfarrabista', descricao: 'Alfarrabista' },
  { id: '141410 Alfarrabista (comércio varejista)', descricao: 'Alfarrabista (comércio varejista)' },
  { id: '848310 Alfeloeiro', descricao: 'Alfeloeiro' },
  { id: '141405 Alheiro (comércio atacadista)', descricao: 'Alheiro (comércio atacadista)' },
  { id: '141410 Alheiro (comércio varejista)', descricao: 'Alheiro (comércio varejista)' },
  { id: '263115 Álim', descricao: 'Álim' },
  { id: '784205 Alimentador de esteiras (preparação de alimentos e bebidas)', descricao: 'Alimentador de esteiras (preparação de alimentos e bebidas)' },
  { id: '784205 Alimentador de linha de produção', descricao: 'Alimentador de linha de produção' },
  { id: '784205 Alimentador de máquina automática', descricao: 'Alimentador de máquina automática' },
  { id: '631325 Alimentador de peixe', descricao: 'Alimentador de peixe' },
  { id: '766220 Alimentador de rotativas', descricao: 'Alimentador de rotativas' },
  { id: '773130 Alimentador de serra de fita', descricao: 'Alimentador de serra de fita' },
  { id: '7842 ALIMENTADORES DE LINHAS DE PRODUÇÃO', descricao: 'ALIMENTADORES DE LINHAS DE PRODUÇÃO' },
  { id: '914405 Alinhador de direção', descricao: 'Alinhador de direção' },
  { id: '992105 Alinhador de pneus', descricao: 'Alinhador de pneus' },
  { id: '914405 Alinhador de rodas', descricao: 'Alinhador de rodas' },
  { id: '721325 Alisador de metal', descricao: 'Alisador de metal' },
  { id: '10115 Almirante', descricao: 'Almirante' },
  { id: '10115 Almirante-de-esquadra', descricao: 'Almirante-de-esquadra' },
  { id: '414105 Almoxarife', descricao: 'Almoxarife' },
  { id: '4141 ALMOXARIFES E ARMAZENISTAS', descricao: 'ALMOXARIFES E ARMAZENISTAS' },
  { id: '141410 Alugador (comerciante varejista)', descricao: 'Alugador (comerciante varejista)' },
  { id: '715230 Alvanel', descricao: 'Alvanel' },
  { id: '715230 Alvaner', descricao: 'Alvaner' },
  { id: '761405 Alvejador (tecidos)', descricao: 'Alvejador (tecidos)' },
  { id: '311725 Alvejador de couros', descricao: 'Alvejador de couros' },
  { id: '311725 Alvejador de sola', descricao: 'Alvejador de sola' },
  { id: '762335 Amaciador de couros e peles', descricao: 'Amaciador de couros e peles' },
  { id: '623005 Amansador', descricao: 'Amansador' },
  { id: '632210 Amapateiro', descricao: 'Amapateiro' },
  { id: '784105 Amarrador de embalagens', descricao: 'Amarrador de embalagens' },
  { id: '783240 Amarrador de embarcações', descricao: 'Amarrador de embarcações' },
  { id: '772105 Amarrador de madeira', descricao: 'Amarrador de madeira' },
  { id: '632365 Amarrador de piaçava', descricao: 'Amarrador de piaçava' },
  { id: '783240 Amarrador e desamarrado de embarcações', descricao: 'Amarrador e desamarrado de embarcações' },
  { id: '783240 Amarrador e desatracados de embarcações', descricao: 'Amarrador e desatracados de embarcações' },
  { id: '828110 Amassador - em olaria', descricao: 'Amassador - em olaria' },
  { id: '262905 Ambientador', descricao: 'Ambientador' },
  { id: '524305 Ambulante', descricao: 'Ambulante' },
  { id: '623005 Amestrador', descricao: 'Amestrador' },
  { id: '721315 Amolador - na fabricação', descricao: 'Amolador - na fabricação' },
  { id: '721310 Amolador ambulante', descricao: 'Amolador ambulante' },
  { id: '721305 Amolador de cardas', descricao: 'Amolador de cardas' },
  { id: '721315 Amolador de cilindros', descricao: 'Amolador de cilindros' },
  { id: '721310 Amolador de cutelaria', descricao: 'Amolador de cutelaria' },
  { id: '721310 Amolador de facas', descricao: 'Amolador de facas' },
  { id: '721315 Amolador de ferramentas', descricao: 'Amolador de ferramentas' },
  { id: '721305 Amolador de guarnição - na fabricação têxtil', descricao: 'Amolador de guarnição - na fabricação têxtil' },
  { id: '721320 Amolador de serras', descricao: 'Amolador de serras' },
  { id: '721315 Amolador de talhadeira', descricao: 'Amolador de talhadeira' },
  { id: '721310 Amolador de tesouras ( inclusive ambulante)', descricao: 'Amolador de tesouras ( inclusive ambulante)' },
  { id: '711105 Amostrador de minérios', descricao: 'Amostrador de minérios' },
  { id: '211205 Amostrista', descricao: 'Amostrista' },
  { id: '711130 Amostrista de minérios', descricao: 'Amostrista de minérios' },
  { id: '752115 Ampoleiro', descricao: 'Ampoleiro' },
  { id: '823245 Ampoleiro (jarras e garrafas térmicas)', descricao: 'Ampoleiro (jarras e garrafas térmicas)' },
  { id: '313315 Analisador de tráfego telefônico', descricao: 'Analisador de tráfego telefônico' },
  { id: '251550 Analista (psicanálise)', descricao: 'Analista (psicanálise)' },
  { id: '252105 Analista administrativo', descricao: 'Analista administrativo' },
  { id: '251210 Analista agroindustrial (economista)', descricao: 'Analista agroindustrial (economista)' },
  { id: '352205 Analista ambiental', descricao: 'Analista ambiental' },
  { id: '252210 Analista contábil', descricao: 'Analista contábil' },
  { id: '251210 Analista de agronegócios (economista)', descricao: 'Analista de agronegócios (economista)' },
  { id: '212315 Analista de aplicativo básico (software)', descricao: 'Analista de aplicativo básico (software)' },
  { id: '301110 Analista de areias em fundição', descricao: 'Analista de areias em fundição' },
  { id: '252210 Analista de balanço', descricao: 'Analista de balanço' },
  { id: '251220 Analista de cadeias produtivas (economista)', descricao: 'Analista de cadeias produtivas (economista)' },
  { id: '252510 Analista de câmbio', descricao: 'Analista de câmbio' },
  { id: '252405 Analista de cargos e salários', descricao: 'Analista de cargos e salários' },
  { id: '421310 Analista de cobrança', descricao: 'Analista de cobrança' },
  { id: '252515 Analista de cobrança (instituições financeiras)', descricao: 'Analista de cobrança (instituições financeiras)' },
  { id: '142125 Analista de compliance', descricao: 'Analista de compliance' },
  { id: '212410 Analista de comunicação (teleprocessamento)', descricao: 'Analista de comunicação (teleprocessamento)' },
  { id: '252210 Analista de contabilidade', descricao: 'Analista de contabilidade' },
  { id: '252210 Analista de contas', descricao: 'Analista de contas' },
  { id: '252210 Analista de contas a pagar', descricao: 'Analista de contas a pagar' },
  { id: '391210 Analista de controle de qualidade', descricao: 'Analista de controle de qualidade' },
  { id: '251225 Analista de controle e gestão (economista)', descricao: 'Analista de controle e gestão (economista)' },
  { id: '251215 Analista de controle orçamentário (economista)', descricao: 'Analista de controle orçamentário (economista)' },
  { id: '391145 Analista de controle técnico de manutenção', descricao: 'Analista de controle técnico de manutenção' },
  { id: '251215 Analista de crédito (economista)', descricao: 'Analista de crédito (economista)' },
  { id: '252525 Analista de crédito (instituições financeiras)', descricao: 'Analista de crédito (instituições financeiras)' },
  { id: '252530 Analista de crédito rural', descricao: 'Analista de crédito rural' },
  { id: '252210 Analista de custos', descricao: 'Analista de custos' },
  { id: '342215 Analista de desembaraço aduaneiro', descricao: 'Analista de desembaraço aduaneiro' },
  { id: '212405 Analista de desenvolvimento de sistemas', descricao: 'Analista de desenvolvimento de sistemas' },
  { id: '212405 Analista de desenvolvimento de software', descricao: 'Analista de desenvolvimento de software' },
  { id: '251235 Analista de desenvolvimento regional (economista)', descricao: 'Analista de desenvolvimento regional (economista)' },
  { id: '261210 Analista de documentação', descricao: 'Analista de documentação' },
  { id: '142355 Analista de e-commerce', descricao: 'Analista de e-commerce' },
  { id: '251230 Analista de ecodesenvolvimento (economista)', descricao: 'Analista de ecodesenvolvimento (economista)' },
  { id: '251205 Analista de economia internacional', descricao: 'Analista de economia internacional' },
  { id: '311520 Analista de estação de tratamento de efluentes', descricao: 'Analista de estação de tratamento de efluentes' },
  { id: '252725 Analista de estoque', descricao: 'Analista de estoque' },
  { id: '142335 Analista de estudos de mercado', descricao: 'Analista de estudos de mercado' },
  { id: '251205 Analista de estudos econômicos', descricao: 'Analista de estudos econômicos' },
  { id: '354305 Analista de exportação e importação', descricao: 'Analista de exportação e importação' },
  { id: '251225 Analista de finanças públicas (economista)', descricao: 'Analista de finanças públicas (economista)' },
  { id: '413105 Analista de folha de pagamento', descricao: 'Analista de folha de pagamento' },
  { id: '251215 Analista de fundos de investimento', descricao: 'Analista de fundos de investimento' },
  { id: '142130 Analista de gerenciamento de riscos', descricao: 'Analista de gerenciamento de riscos' },
  { id: '252725 Analista de gestão de estoque', descricao: 'Analista de gestão de estoque' },
  { id: '251230 Analista de impactos ambientais (economista)', descricao: 'Analista de impactos ambientais (economista)' },
  { id: '415310 Analista de informação em saúde', descricao: 'Analista de informação em saúde' },
  { id: '261215 Analista de informações (pesquisador de informações de rede)', descricao: 'Analista de informações (pesquisador de informações de rede)' },
  { id: '142335 Analista de informações de mercado', descricao: 'Analista de informações de mercado' },
  { id: '212410 Analista de infraestrutura', descricao: 'Analista de infraestrutura' },
  { id: '142125 Analista de integridade (compliance)', descricao: 'Analista de integridade (compliance)' },
  { id: '142335 Analista de inteligência de mercado', descricao: 'Analista de inteligência de mercado' },
  { id: '252725 Analista de inventário', descricao: 'Analista de inventário' },
  { id: '391210 Analista de laboratório de controle de qualidade', descricao: 'Analista de laboratório de controle de qualidade' },
  { id: '311105 Analista de laboratório químico', descricao: 'Analista de laboratório químico' },
  { id: '301115 Analista de laboratório químico (petróleo)', descricao: 'Analista de laboratório químico (petróleo)' },
  { id: '252535 Analista de leasing', descricao: 'Analista de leasing' },
  { id: '252715 Analista de logistica', descricao: 'Analista de logistica' },
  { id: '342125 Analista de logística de transporte', descricao: 'Analista de logística de transporte' },
  { id: '391140 Analista de manutenção (equipamentos aéreos)', descricao: 'Analista de manutenção (equipamentos aéreos)' },
  { id: '391130 Analista de manutenção (equipamentos industriais)', descricao: 'Analista de manutenção (equipamentos industriais)' },
  { id: '142335 Analista de marketing', descricao: 'Analista de marketing' },
  { id: '251230 Analista de meio ambiente (economista)', descricao: 'Analista de meio ambiente (economista)' },
  { id: '142335 Analista de mercado', descricao: 'Analista de mercado' },
  { id: '251210 Analista de mercado agrícola (economista)', descricao: 'Analista de mercado agrícola (economista)' },
  { id: '251205 Analista de mercado de trabalho (economista)', descricao: 'Analista de mercado de trabalho (economista)' },
  { id: '251205 Analista de mercado e produtos (economista)', descricao: 'Analista de mercado e produtos (economista)' },
  { id: '251220 Analista de mercado industrial (economista)', descricao: 'Analista de mercado industrial (economista)' },
  { id: '142350 Analista de mercado internacional', descricao: 'Analista de mercado internacional' },
  { id: '251205 Analista de mercado internacional', descricao: 'Analista de mercado internacional' },
  { id: '251205 Analista de mercadologia (economista)', descricao: 'Analista de mercadologia (economista)' },
  { id: '221105 Analista de micróbios', descricao: 'Analista de micróbios' },
  { id: '253405 Analista de mídias digitais', descricao: 'Analista de mídias digitais' },
  { id: '253405 Analista de mídias sociais', descricao: 'Analista de mídias sociais' },
  { id: '142330 Analista de negócios', descricao: 'Analista de negócios' },
  { id: '252405 Analista de ocupações', descricao: 'Analista de ocupações' },
  { id: '252705 Analista de pcp (programação e controle da produção)', descricao: 'Analista de pcp (programação e controle da produção)' },
  { id: '142335 Analista de pesquisa de mercado', descricao: 'Analista de pesquisa de mercado' },
  { id: '211110 Analista de pesquisa operacional', descricao: 'Analista de pesquisa operacional' },
  { id: '391145 Analista de planejamento de manutenção', descricao: 'Analista de planejamento de manutenção' },
  { id: '252710 Analista de planejamento de materias', descricao: 'Analista de planejamento de materias' },
  { id: '111510 Analista de planejamento e orçamento - apo', descricao: 'Analista de planejamento e orçamento - apo' },
  { id: '252545 Analista de planejamento financeiro', descricao: 'Analista de planejamento financeiro' },
  { id: '251220 Analista de planejamento industrial (economista)', descricao: 'Analista de planejamento industrial (economista)' },
  { id: '251225 Analista de políticas públicas (economista)', descricao: 'Analista de políticas públicas (economista)' },
  { id: '251210 Analista de produto agropecuário (economista)', descricao: 'Analista de produto agropecuário (economista)' },
  { id: '251220 Analista de produto industrial (economista)', descricao: 'Analista de produto industrial (economista)' },
  { id: '252540 Analista de produtos bancários', descricao: 'Analista de produtos bancários' },
  { id: '311105 Analista de produtos químicos', descricao: 'Analista de produtos químicos' },
  { id: '251220 Analista de projetos industriais (economista)', descricao: 'Analista de projetos industriais (economista)' },
  { id: '252720 Analista de projetos logisticos', descricao: 'Analista de projetos logisticos' },
  { id: '214270 Analista de projetos viários', descricao: 'Analista de projetos viários' },
  { id: '212430 Analista de qualidade de ti', descricao: 'Analista de qualidade de ti' },
  { id: '252405 Analista de recursos humanos', descricao: 'Analista de recursos humanos' },
  { id: '251230 Analista de recursos naturais (economista)', descricao: 'Analista de recursos naturais (economista)' },
  { id: '212410 Analista de rede', descricao: 'Analista de rede' },
  { id: '212410 Analista de redes e de comunicação de dados', descricao: 'Analista de redes e de comunicação de dados' },
  { id: '253405 Analista de redes sociais', descricao: 'Analista de redes sociais' },
  { id: '142350 Analista de relações internacionais', descricao: 'Analista de relações internacionais' },
  { id: '142130 Analista de riscos', descricao: 'Analista de riscos' },
  { id: '142130 Analista de riscos corporativos', descricao: 'Analista de riscos corporativos' },
  { id: '142130 Analista de riscos e controles internos', descricao: 'Analista de riscos e controles internos' },
  { id: '351705 Analista de seguros (técnico)', descricao: 'Analista de seguros (técnico)' },
  { id: '317210 Analista de service desk', descricao: 'Analista de service desk' },
  { id: '351710 Analista de sinistros', descricao: 'Analista de sinistros' },
  { id: '212405 Analista de sistemas', descricao: 'Analista de sistemas' },
  { id: '212415 Analista de sistemas de automação', descricao: 'Analista de sistemas de automação' },
  { id: '212405 Analista de sistemas web', descricao: 'Analista de sistemas web' },
  { id: '251215 Analista de subscrição de títulos', descricao: 'Analista de subscrição de títulos' },
  { id: '212420 Analista de suporte', descricao: 'Analista de suporte' },
  { id: '242910 Analista de suporte à inteligência ( grupo apoio)', descricao: 'Analista de suporte à inteligência ( grupo apoio)' },
  { id: '212420 Analista de suporte computacional', descricao: 'Analista de suporte computacional' },
  { id: '212420 Analista de suporte de banco de dados', descricao: 'Analista de suporte de banco de dados' },
  { id: '212420 Analista de suporte de sistema', descricao: 'Analista de suporte de sistema' },
  { id: '212420 Analista de suporte técnico', descricao: 'Analista de suporte técnico' },
  { id: '212410 Analista de telecomunicação', descricao: 'Analista de telecomunicação' },
  { id: '212430 Analista de testes automatizados de ti', descricao: 'Analista de testes automatizados de ti' },
  { id: '212430 Analista de testes de tecnologia da informação', descricao: 'Analista de testes de tecnologia da informação' },
  { id: '214270 Analista de tráfego', descricao: 'Analista de tráfego' },
  { id: '342105 Analista de transporte em comércio exterior', descricao: 'Analista de transporte em comércio exterior' },
  { id: '342110 Analista de transporte multimodal', descricao: 'Analista de transporte multimodal' },
  { id: '214270 Analista de transportes e trânsito', descricao: 'Analista de transportes e trânsito' },
  { id: '311105 Analista de tratamento de água', descricao: 'Analista de tratamento de água' },
  { id: '122520 Analista de turismo (turismólogo)', descricao: 'Analista de turismo (turismólogo)' },
  { id: '251205 Analista econômico', descricao: 'Analista econômico' },
  { id: '251215 Analista econômico-financeiro', descricao: 'Analista econômico-financeiro' },
  { id: '212320 Analista em segurança da informação', descricao: 'Analista em segurança da informação' },
  { id: '251215 Analista financeiro (economista)', descricao: 'Analista financeiro (economista)' },
  { id: '252545 Analista financeiro (instituições financeiras)', descricao: 'Analista financeiro (instituições financeiras)' },
  { id: '251225 Analista fiscal (economista)', descricao: 'Analista fiscal (economista)' },
  { id: '516805 Analista kirlian', descricao: 'Analista kirlian' },
  { id: '374155 Analista musical', descricao: 'Analista musical' },
  { id: '311105 Analista químico', descricao: 'Analista químico' },
  { id: '301115 Analista químico (petróleo)', descricao: 'Analista químico (petróleo)' },
  { id: '351705 Analista técnico de seguros', descricao: 'Analista técnico de seguros' },
  { id: '351710 Analista técnico de sinistros', descricao: 'Analista técnico de sinistros' },
  { id: '242910 Analista técnico em inteligência (grupo informações)', descricao: 'Analista técnico em inteligência (grupo informações)' },
  { id: '251225 Analista tributário (economista)', descricao: 'Analista tributário (economista)' },
  { id: '3543 ANALISTAS DE COMÉRCIO EXTERIOR', descricao: 'ANALISTAS DE COMÉRCIO EXTERIOR' },
  { id: '352205 Analistas de projetos ambientais', descricao: 'Analistas de projetos ambientais' },
  { id: '2124 ANALISTAS DE TECNOLOGIA DA INFORMAÇÃO', descricao: 'ANALISTAS DE TECNOLOGIA DA INFORMAÇÃO' },
  { id: '263105 Ancião', descricao: 'Ancião' },
  { id: '261705 Âncora de mídias audiovisuais', descricao: 'Âncora de mídias audiovisuais' },
  { id: '261705 Âncora de rádio e tv', descricao: 'Âncora de rádio e tv' },
  { id: '225285 Andrologista', descricao: 'Andrologista' },
  { id: '225151 Anestesiologista', descricao: 'Anestesiologista' },
  { id: '225151 Anestesista', descricao: 'Anestesista' },
  { id: '225115 Angiologista', descricao: 'Angiologista' },
  { id: '376325 Animador de circo', descricao: 'Animador de circo' },
  { id: '376305 Animador de eventos', descricao: 'Animador de eventos' },
  { id: '376310 Animador de festas populares', descricao: 'Animador de festas populares' },
  { id: '376315 Animador de rádio', descricao: 'Animador de rádio' },
  { id: '376320 Animador de televisão', descricao: 'Animador de televisão' },
  { id: '723235 Anodizador e anodizador de metais', descricao: 'Anodizador e anodizador de metais' },
  { id: '377225 Anotador (futebol de salão)', descricao: 'Anotador (futebol de salão)' },
  { id: '377230 Anotador (judô)', descricao: 'Anotador (judô)' },
  { id: '377235 Anotador (karatê)', descricao: 'Anotador (karatê)' },
  { id: '519940 Anotador de consumo de energia elétrica, água e gás', descricao: 'Anotador de consumo de energia elétrica, água e gás' },
  { id: '414205 Anotador de mão-de-obra', descricao: 'Anotador de mão-de-obra' },
  { id: '414205 Anotador de pessoal', descricao: 'Anotador de pessoal' },
  { id: '377235 Anotador de pontuação (karatê)', descricao: 'Anotador de pontuação (karatê)' },
  { id: '414210 Anotador de processo de produção', descricao: 'Anotador de processo de produção' },
  { id: '414210 Anotador de produção', descricao: 'Anotador de produção' },
  { id: '954210 Antenista', descricao: 'Antenista' },
  { id: '141405 Antiquário (comércio atacadista)', descricao: 'Antiquário (comércio atacadista)' },
  { id: '141410 Antiquário comércio varejista', descricao: 'Antiquário comércio varejista' },
  { id: '251105 Antropólogo', descricao: 'Antropólogo' },
  { id: '622020 Apanhador - na cultura', descricao: 'Apanhador - na cultura' },
  { id: '622205 Apanhador de algodão', descricao: 'Apanhador de algodão' },
  { id: '632310 Apanhador de babaçu', descricao: 'Apanhador de babaçu' },
  { id: '632315 Apanhador de bacaba', descricao: 'Apanhador de bacaba' },
  { id: '632320 Apanhador de buriti', descricao: 'Apanhador de buriti' },
  { id: '622610 Apanhador de café', descricao: 'Apanhador de café' },
  { id: '622505 Apanhador de laranja', descricao: 'Apanhador de laranja' },
  { id: '632325 Aparador - na extração da carnaúba', descricao: 'Aparador - na extração da carnaúba' },
  { id: '632335 Aparador de óleo de copaíba', descricao: 'Aparador de óleo de copaíba' },
  { id: '712220 Aparelhador de pedra (mármore)', descricao: 'Aparelhador de pedra (mármore)' },
  { id: '711115 Aparelhador de pedra (mina)', descricao: 'Aparelhador de pedra (mina)' },
  { id: '623115 Apartador de gado', descricao: 'Apartador de gado' },
  { id: '613405 Apicultor', descricao: 'Apicultor' },
  { id: '613405 Apicultor - empregador', descricao: 'Apicultor - empregador' },
  { id: '623410 Apicultor - exclusive conta própria e empregador', descricao: 'Apicultor - exclusive conta própria e empregador' },
  { id: '712205 Aplainador de mármore', descricao: 'Aplainador de mármore' },
  { id: '622020 Aplicador agrícola', descricao: 'Aplicador agrícola' },
  { id: '523120 Aplicador de adesivos', descricao: 'Aplicador de adesivos' },
  { id: '523120 Aplicador de adesivos em superfícies', descricao: 'Aplicador de adesivos em superfícies' },
  { id: '715705 Aplicador de asfalto impermeabilizante (coberturas)', descricao: 'Aplicador de asfalto impermeabilizante (coberturas)' },
  { id: '523120 Aplicador de autoadesivo', descricao: 'Aplicador de autoadesivo' },
  { id: '752405 Aplicador de decalque em cerâmica', descricao: 'Aplicador de decalque em cerâmica' },
  { id: '752405 Aplicador de impressos em cerâmica', descricao: 'Aplicador de impressos em cerâmica' },
  { id: '519910 Aplicador de inseticida', descricao: 'Aplicador de inseticida' },
  { id: '715705 Aplicador de isolante (coberturas)', descricao: 'Aplicador de isolante (coberturas)' },
  { id: '715715 Aplicador de isolante térmico', descricao: 'Aplicador de isolante térmico' },
  { id: '715720 Aplicador de material isolante em caldeiras e tubulações', descricao: 'Aplicador de material isolante em caldeiras e tubulações' },
  { id: '424210 Aplicador de provas (concurso, avaliação,exame)', descricao: 'Aplicador de provas (concurso, avaliação,exame)' },
  { id: '716520 Aplicador de resinas em pisos', descricao: 'Aplicador de resinas em pisos' },
  { id: '766205 Aplicador de serigrafia', descricao: 'Aplicador de serigrafia' },
  { id: '766205 Aplicador de silkscreen', descricao: 'Aplicador de silkscreen' },
  { id: '766205 Aplicador de silkscreen, à máquina', descricao: 'Aplicador de silkscreen, à máquina' },
  { id: '716520 Aplicador de sinteco e bona', descricao: 'Aplicador de sinteco e bona' },
  { id: '523120 Aplicador de vinil autoadesivo', descricao: 'Aplicador de vinil autoadesivo' },
  { id: '752205 Aplicador serigráfico em vidros', descricao: 'Aplicador serigráfico em vidros' },
  { id: '7157 APLICADORES DE MATERIAIS ISOLANTES', descricao: 'APLICADORES DE MATERIAIS ISOLANTES' },
  { id: '4242 APLICADORES DE PROVAS E AFINS', descricao: 'APLICADORES DE PROVAS E AFINS' },
  { id: '7165 APLICADORES DE REVESTIMENTOS CERÂMICOS, PASTILHAS, PEDRAS E MADEIRAS', descricao: 'APLICADORES DE REVESTIMENTOS CERÂMICOS, PASTILHAS, PEDRAS E MADEIRAS' },
  { id: '411010 Apoiador político partidário', descricao: 'Apoiador político partidário' },
  { id: '377215 Apontador (basquete)', descricao: 'Apontador (basquete)' },
  { id: '377245 Apontador (vôlei)', descricao: 'Apontador (vôlei)' },
  { id: '764210 Apontador de bicos (calçados)', descricao: 'Apontador de bicos (calçados)' },
  { id: '414210 Apontador de campo', descricao: 'Apontador de campo' },
  { id: '411030 Apontador de cartões de ponto', descricao: 'Apontador de cartões de ponto' },
  { id: '414205 Apontador de mão-de-obra', descricao: 'Apontador de mão-de-obra' },
  { id: '414205 Apontador de obras', descricao: 'Apontador de obras' },
  { id: '414205 Apontador de pessoal', descricao: 'Apontador de pessoal' },
  { id: '414210 Apontador de produção', descricao: 'Apontador de produção' },
  { id: '391110 Apontador de tempo (produção)', descricao: 'Apontador de tempo (produção)' },
  { id: '414210 Apontador industrial', descricao: 'Apontador industrial' },
  { id: '4142 APONTADORES E CONFERENTES', descricao: 'APONTADORES E CONFERENTES' },
  { id: '263105 Apóstolo', descricao: 'Apóstolo' },
  { id: '848315 Aprendiz de macarroneiro', descricao: 'Aprendiz de macarroneiro' },
  { id: '911305 Aprendiz de mecânica de manutenção', descricao: 'Aprendiz de mecânica de manutenção' },
  { id: '725010 Aprendiz de mecânico ajustador', descricao: 'Aprendiz de mecânico ajustador' },
  { id: '376325 Apresentador animador de circo', descricao: 'Apresentador animador de circo' },
  { id: '376305 Apresentador animador de eventos', descricao: 'Apresentador animador de eventos' },
  { id: '376310 Apresentador animador de festas populares', descricao: 'Apresentador animador de festas populares' },
  { id: '376315 Apresentador animador de programas de rádio', descricao: 'Apresentador animador de programas de rádio' },
  { id: '376320 Apresentador animador de programas de televisão', descricao: 'Apresentador animador de programas de televisão' },
  { id: '376325 Apresentador de circo', descricao: 'Apresentador de circo' },
  { id: '376305 Apresentador de convenções', descricao: 'Apresentador de convenções' },
  { id: '376305 Apresentador de espetáculos', descricao: 'Apresentador de espetáculos' },
  { id: '376305 Apresentador de eventos', descricao: 'Apresentador de eventos' },
  { id: '376310 Apresentador de festas populares', descricao: 'Apresentador de festas populares' },
  { id: '376315 Apresentador de programas de rádio', descricao: 'Apresentador de programas de rádio' },
  { id: '376320 Apresentador de programas de televisão', descricao: 'Apresentador de programas de televisão' },
  { id: '261705 Apresentador de rádio e televisão', descricao: 'Apresentador de rádio e televisão' },
  { id: '376320 Apresentador de telejornal', descricao: 'Apresentador de telejornal' },
  { id: '3763 APRESENTADORES DE EVENTOS, PROGRAMAS E ESPETÁCULOS', descricao: 'APRESENTADORES DE EVENTOS, PROGRAMAS E ESPETÁCULOS' },
  { id: '414205 Apropriador de mão-de-obra', descricao: 'Apropriador de mão-de-obra' },
  { id: '262405 Aquarelista', descricao: 'Aquarelista' },
  { id: '641015 Arador', descricao: 'Arador' },
  { id: '722415 Aramador - na fabricação de arame', descricao: 'Aramador - na fabricação de arame' },
  { id: '621005 Arameiro (colocador de arames)', descricao: 'Arameiro (colocador de arames)' },
  { id: '376230 Aramista (equilibrista)', descricao: 'Aramista (equilibrista)' },
  { id: '377220 Árbitro assistente (futebol)', descricao: 'Árbitro assistente (futebol)' },
  { id: '377220 Árbitro central (futebol)', descricao: 'Árbitro central (futebol)' },
  { id: '377230 Árbitro central (judô)', descricao: 'Árbitro central (judô)' },
  { id: '377235 Árbitro central (karatê)', descricao: 'Árbitro central (karatê)' },
  { id: '377235 Árbitro chefe de área (karatê)', descricao: 'Árbitro chefe de área (karatê)' },
  { id: '377210 Árbitro de atletismo', descricao: 'Árbitro de atletismo' },
  { id: '377215 Árbitro de basquete', descricao: 'Árbitro de basquete' },
  { id: '377215 Árbitro de basquetebol', descricao: 'Árbitro de basquetebol' },
  { id: '377220 Árbitro de futebol', descricao: 'Árbitro de futebol' },
  { id: '377225 Árbitro de futebol de salão', descricao: 'Árbitro de futebol de salão' },
  { id: '377225 Árbitro de futsal', descricao: 'Árbitro de futsal' },
  { id: '377230 Árbitro de judô', descricao: 'Árbitro de judô' },
  { id: '377235 Árbitro de karatê', descricao: 'Árbitro de karatê' },
  { id: '377240 Árbitro de poló aquático', descricao: 'Árbitro de poló aquático' },
  { id: '377245 Árbitro de vôlei', descricao: 'Árbitro de vôlei' },
  { id: '377205 Árbitro desportivo', descricao: 'Árbitro desportivo' },
  { id: '251205 Árbitro econômico', descricao: 'Árbitro econômico' },
  { id: '351440 Árbitro extrajudicial', descricao: 'Árbitro extrajudicial' },
  { id: '377210 Árbitro geral de campo', descricao: 'Árbitro geral de campo' },
  { id: '377210 Árbitro geral de pista', descricao: 'Árbitro geral de pista' },
  { id: '377210 Árbitro geral de provas fora do estádio', descricao: 'Árbitro geral de provas fora do estádio' },
  { id: '377230 Árbitro lateral (judô)', descricao: 'Árbitro lateral (judô)' },
  { id: '377215 Árbitro reserva (basquete)', descricao: 'Árbitro reserva (basquete)' },
  { id: '377220 Árbitro reserva (futebol)', descricao: 'Árbitro reserva (futebol)' },
  { id: '377235 Árbitro reserva (karatê)', descricao: 'Árbitro reserva (karatê)' },
  { id: '3772 ÁRBITROS DESPORTIVOS', descricao: 'ÁRBITROS DESPORTIVOS' },
  { id: '141405 Arborário', descricao: 'Arborário' },
  { id: '263105 Arcebispo', descricao: 'Arcebispo' },
  { id: '263105 Arcipreste', descricao: 'Arcipreste' },
  { id: '261505 Argumentista-roteirista de história em quadrinhos', descricao: 'Argumentista-roteirista de história em quadrinhos' },
  { id: '764210 Armador de calçados', descricao: 'Armador de calçados' },
  { id: '715305 Armador de estrutura de concreto', descricao: 'Armador de estrutura de concreto' },
  { id: '715315 Armador de estrutura de concreto armado', descricao: 'Armador de estrutura de concreto armado' },
  { id: '715315 Armador de ferragens na construção civil', descricao: 'Armador de ferragens na construção civil' },
  { id: '715315 Armador de ferros', descricao: 'Armador de ferros' },
  { id: '724105 Armador de manilhas', descricao: 'Armador de manilhas' },
  { id: '776420 Armador de móveis de vime', descricao: 'Armador de móveis de vime' },
  { id: '715530 Armador de telhados', descricao: 'Armador de telhados' },
  { id: '141405 Armarinheiro (comércio atacadista)', descricao: 'Armarinheiro (comércio atacadista)' },
  { id: '141410 Armarinheiro (comércio varejista)', descricao: 'Armarinheiro (comércio varejista)' },
  { id: '141405 Armazenário (comércio atacadista)', descricao: 'Armazenário (comércio atacadista)' },
  { id: '141410 Armazenário (comércio varejista)', descricao: 'Armazenário (comércio varejista)' },
  { id: '414110 Armazenista', descricao: 'Armazenista' },
  { id: '141405 Armeiro (comércio atacadista)', descricao: 'Armeiro (comércio atacadista)' },
  { id: '141410 Armeiro (comércio varejista)', descricao: 'Armeiro (comércio varejista)' },
  { id: '325010 Aromista', descricao: 'Aromista' },
  { id: '724440 Arqueador', descricao: 'Arqueador' },
  { id: '724440 Arqueador de molas', descricao: 'Arqueador de molas' },
  { id: '724440 Arqueador e temperador de molas', descricao: 'Arqueador e temperador de molas' },
  { id: '251110 Arqueólogo', descricao: 'Arqueólogo' },
  { id: '214120 Arquiteto da paisagem', descricao: 'Arquiteto da paisagem' },
  { id: '214105 Arquiteto de edificações', descricao: 'Arquiteto de edificações' },
  { id: '214110 Arquiteto de interiores', descricao: 'Arquiteto de interiores' },
  { id: '214115 Arquiteto de patrimônio', descricao: 'Arquiteto de patrimônio' },
  { id: '214115 Arquiteto de restauro', descricao: 'Arquiteto de restauro' },
  { id: '212425 Arquiteto de sistemas de ti', descricao: 'Arquiteto de sistemas de ti' },
  { id: '212425 Arquiteto de soluções de tecnologia da informação', descricao: 'Arquiteto de soluções de tecnologia da informação' },
  { id: '214120 Arquiteto paisagista', descricao: 'Arquiteto paisagista' },
  { id: '214115 Arquiteto restaurador', descricao: 'Arquiteto restaurador' },
  { id: '214125 Arquiteto urbanista', descricao: 'Arquiteto urbanista' },
  { id: '2141 ARQUITETOS E URBANISTAS', descricao: 'ARQUITETOS E URBANISTAS' },
  { id: '415105 Arquivador', descricao: 'Arquivador' },
  { id: '261305 Arquivista', descricao: 'Arquivista' },
  { id: '415105 Arquivista de documentos', descricao: 'Arquivista de documentos' },
  { id: '261105 Arquivista pesquisador (jornalismo)', descricao: 'Arquivista pesquisador (jornalismo)' },
  { id: '2613 ARQUIVISTAS E MUSEÓLOGOS', descricao: 'ARQUIVISTAS E MUSEÓLOGOS' },
  { id: '623015 Arraçoador (pecuária polivalente)', descricao: 'Arraçoador (pecuária polivalente)' },
  { id: '631305 Arraçoador de camarões', descricao: 'Arraçoador de camarões' },
  { id: '631325 Arraçoador de peixe', descricao: 'Arraçoador de peixe' },
  { id: '622020 Arrancador - na cultura', descricao: 'Arrancador - na cultura' },
  { id: '848520 Arrancador em matadouro', descricao: 'Arrancador em matadouro' },
  { id: '632125 Arrastador de árvores', descricao: 'Arrastador de árvores' },
  { id: '782810 Arreador', descricao: 'Arreador' },
  { id: '848520 Arreador em matadouro', descricao: 'Arreador em matadouro' },
  { id: '623110 Arrebanhador', descricao: 'Arrebanhador' },
  { id: '768325 Arreeiro de couro - na fabricação', descricao: 'Arreeiro de couro - na fabricação' },
  { id: '768325 Arreeiro na fabricação', descricao: 'Arreeiro na fabricação' },
  { id: '763305 Arrematadeira', descricao: 'Arrematadeira' },
  { id: '812105 Arrematador de fogos', descricao: 'Arrematador de fogos' },
  { id: '354205 Arrematador, no comércio', descricao: 'Arrematador, no comércio' },
  { id: '613005 Arrendatário (pecuária)', descricao: 'Arrendatário (pecuária)' },
  { id: '621005 Arrendatário - na agropecuária', descricao: 'Arrendatário - na agropecuária' },
  { id: '611005 Arrendatário na agropecuária', descricao: 'Arrendatário na agropecuária' },
  { id: '612005 Arrendatário polivalente', descricao: 'Arrendatário polivalente' },
  { id: '761230 Arriador de filatório', descricao: 'Arriador de filatório' },
  { id: '782810 Arrieiro', descricao: 'Arrieiro' },
  { id: '784120 Arrolhador', descricao: 'Arrolhador' },
  { id: '141405 Arrombador (comércio de gado)', descricao: 'Arrombador (comércio de gado)' },
  { id: '141405 Arrombador - no comércio de gado - empregador', descricao: 'Arrombador - no comércio de gado - empregador' },
  { id: '612105 Arrozeiro', descricao: 'Arrozeiro' },
  { id: '141410 Arrozeiro (comércio)', descricao: 'Arrozeiro (comércio)' },
  { id: '622105 Arrozeiro - na cultura - conta própria', descricao: 'Arrozeiro - na cultura - conta própria' },
  { id: '622610 Arruador de café', descricao: 'Arruador de café' },
  { id: '513315 Arrumadeira de hotel', descricao: 'Arrumadeira de hotel' },
  { id: '513315 Arrumador de hotel', descricao: 'Arrumador de hotel' },
  { id: '521125 Arrumador de prateleiras - em supermercados', descricao: 'Arrumador de prateleiras - em supermercados' },
  { id: '521125 Arrumador de prateleiras - no comércio', descricao: 'Arrumador de prateleiras - no comércio' },
  { id: '512110 Arrumador no serviço doméstico', descricao: 'Arrumador no serviço doméstico' },
  { id: '515305 Arte educador', descricao: 'Arte educador' },
  { id: '766120 Arte-finalista', descricao: 'Arte-finalista' },
  { id: '318405 Arte-finalista (desenhista técnico de artes gráficas)', descricao: 'Arte-finalista (desenhista técnico de artes gráficas)' },
  { id: '262405 Artesão (artista visual)', descricao: 'Artesão (artista visual)' },
  { id: '791105 Artesão bordador', descricao: 'Artesão bordador' },
  { id: '791110 Artesão ceramista', descricao: 'Artesão ceramista' },
  { id: '791115 Artesão com material reciclável', descricao: 'Artesão com material reciclável' },
  { id: '791120 Artesão confeccionador de biojóias e ecojóias', descricao: 'Artesão confeccionador de biojóias e ecojóias' },
  { id: '791150 Artesão crocheteiro', descricao: 'Artesão crocheteiro' },
  { id: '791125 Artesão do couro', descricao: 'Artesão do couro' },
  { id: '791130 Artesão escultor', descricao: 'Artesão escultor' },
  { id: '752105 Artesão modelador (vidros)', descricao: 'Artesão modelador (vidros)' },
  { id: '791135 Artesão moveleiro (exceto reciclado)', descricao: 'Artesão moveleiro (exceto reciclado)' },
  { id: '791160 Artesão rendeiro', descricao: 'Artesão rendeiro' },
  { id: '791140 Artesão tecelão', descricao: 'Artesão tecelão' },
  { id: '791145 Artesão trançador', descricao: 'Artesão trançador' },
  { id: '791155 Artesão tricoteiro', descricao: 'Artesão tricoteiro' },
  { id: '7911 ARTESÃOS', descricao: 'ARTESÃOS' },
  { id: '7511 ARTESÃOS DE METAIS PRECIOSOS E SEMI-PRECIOSOS', descricao: 'ARTESÃOS DE METAIS PRECIOSOS E SEMI-PRECIOSOS' },
  { id: '226310 Arteterapeuta', descricao: 'Arteterapeuta' },
  { id: '914305 Artífice de manutenção', descricao: 'Artífice de manutenção' },
  { id: '773110 Artífice de serra', descricao: 'Artífice de serra' },
  { id: '776420 Artífice de vime', descricao: 'Artífice de vime' },
  { id: '768305 Artífice do couro', descricao: 'Artífice do couro' },
  { id: '262405 Artista (artes visuais)', descricao: 'Artista (artes visuais)' },
  { id: '376210 Artista aéreo', descricao: 'Artista aéreo' },
  { id: '262505 Artista de cinema', descricao: 'Artista de cinema' },
  { id: '376215 Artista de circo (outros)', descricao: 'Artista de circo (outros)' },
  { id: '376210 Artista de corda', descricao: 'Artista de corda' },
  { id: '376210 Artista de força capilar', descricao: 'Artista de força capilar' },
  { id: '376210 Artista de lira', descricao: 'Artista de lira' },
  { id: '262505 Artista de rádio', descricao: 'Artista de rádio' },
  { id: '262505 Artista de teatro', descricao: 'Artista de teatro' },
  { id: '262505 Artista de televisão', descricao: 'Artista de televisão' },
  { id: '262505 Artista dramático', descricao: 'Artista dramático' },
  { id: '262405 Artista plástico', descricao: 'Artista plástico' },
  { id: '2628 ARTISTAS DA DANÇA (EXCETO DANÇA TRADICIONAL E POPULAR)', descricao: 'ARTISTAS DA DANÇA (EXCETO DANÇA TRADICIONAL E POPULAR)' },
  { id: '3762 ARTISTAS DE CIRCO (CIRCENSES)', descricao: 'ARTISTAS DE CIRCO (CIRCENSES)' },
  { id: '2624 ARTISTAS VISUAIS,DESENHISTAS INDUSTRIAIS E CONSERVADORES-RESTAURADORES DE BENS CULTURAIS', descricao: 'ARTISTAS VISUAIS,DESENHISTAS INDUSTRIAIS E CONSERVADORES-RESTAURADORES DE BENS CULTURAIS' },
  { id: '514105 Ascensorista', descricao: 'Ascensorista' },
  { id: '715205 Asfaltador', descricao: 'Asfaltador' },
  { id: '992225 Asfaltador na conservação de vias permanentes (exceto trilhos)', descricao: 'Asfaltador na conservação de vias permanentes (exceto trilhos)' },
  { id: '764305 Asperador de couro no acabamento de calçados', descricao: 'Asperador de couro no acabamento de calçados' },
  { id: '764120 Asperador de solas e palmilhas', descricao: 'Asperador de solas e palmilhas' },
  { id: '716510 Assentador cerâmico', descricao: 'Assentador cerâmico' },
  { id: '724105 Assentador de canalização (edificações)', descricao: 'Assentador de canalização (edificações)' },
  { id: '724105 Assentador de manilhas', descricao: 'Assentador de manilhas' },
  { id: '716510 Assentador de revestimentos cerâmicos', descricao: 'Assentador de revestimentos cerâmicos' },
  { id: '716305 Assentador de vidros', descricao: 'Assentador de vidros' },
  { id: '252310 Assessor bilíngüe', descricao: 'Assessor bilíngüe' },
  { id: '252305 Assessor de diretoria', descricao: 'Assessor de diretoria' },
  { id: '261110 Assessor de imprensa', descricao: 'Assessor de imprensa' },
  { id: '411050 Assessor de microcrédito', descricao: 'Assessor de microcrédito' },
  { id: '411050 Assessor de microfinanças', descricao: 'Assessor de microfinanças' },
  { id: '252305 Assessor de presidência', descricao: 'Assessor de presidência' },
  { id: '354605 Assessor imobiliário', descricao: 'Assessor imobiliário' },
  { id: '241040 Assessor jurídico', descricao: 'Assessor jurídico' },
  { id: '114105 Assessor político partidário', descricao: 'Assessor político partidário' },
  { id: '252315 Assessor trilíngüe', descricao: 'Assessor trilíngüe' },
  { id: '114105 Assessr', descricao: 'Assessr' },
  { id: '623015 Assinalador - na pecuária', descricao: 'Assinalador - na pecuária' },
  { id: '241005 Assistente Jurídico', descricao: 'Assistente Jurídico' },
  { id: '411010 Assistente administrativo', descricao: 'Assistente administrativo' },
  { id: '411010 Assistente administrativo sindical', descricao: 'Assistente administrativo sindical' },
  { id: '252310 Assistente bilíngüe', descricao: 'Assistente bilíngüe' },
  { id: '351715 Assistente comercial de seguros', descricao: 'Assistente comercial de seguros' },
  { id: '351720 Assistente de analista de produtos', descricao: 'Assistente de analista de produtos' },
  { id: '377215 Assistente de apontador (basquete)', descricao: 'Assistente de apontador (basquete)' },
  { id: '371105 Assistente de biblioteca', descricao: 'Assistente de biblioteca' },
  { id: '711120 Assistente de cabo de fogo', descricao: 'Assistente de cabo de fogo' },
  { id: '373145 Assistente de câmera externa', descricao: 'Assistente de câmera externa' },
  { id: '421310 Assistente de cobrança', descricao: 'Assistente de cobrança' },
  { id: '142125 Assistente de compliance', descricao: 'Assistente de compliance' },
  { id: '411010 Assistente de compras', descricao: 'Assistente de compras' },
  { id: '252210 Assistente de contabilidade industrial', descricao: 'Assistente de contabilidade industrial' },
  { id: '252210 Assistente de contador de custos', descricao: 'Assistente de contador de custos' },
  { id: '252210 Assistente de contadoria fiscal', descricao: 'Assistente de contadoria fiscal' },
  { id: '351715 Assistente de contas de seguros', descricao: 'Assistente de contas de seguros' },
  { id: '252210 Assistente de controladoria', descricao: 'Assistente de controladoria' },
  { id: '391145 Assistente de controle técnico de manutenção', descricao: 'Assistente de controle técnico de manutenção' },
  { id: '424205 Assistente de coordenação (concurso, avaliação, exame)', descricao: 'Assistente de coordenação (concurso, avaliação, exame)' },
  { id: '262805 Assistente de coreografia', descricao: 'Assistente de coreografia' },
  { id: '841408 Assistente de cozinhador', descricao: 'Assistente de cozinhador' },
  { id: '342215 Assistente de desembaraço aduaneiro', descricao: 'Assistente de desembaraço aduaneiro' },
  { id: '262220 Assistente de direção', descricao: 'Assistente de direção' },
  { id: '261910 Assistente de direção (tv)', descricao: 'Assistente de direção (tv)' },
  { id: '262205 Assistente de direção de cinema', descricao: 'Assistente de direção de cinema' },
  { id: '141510 Assistente de direção de restaurante', descricao: 'Assistente de direção de restaurante' },
  { id: '262215 Assistente de direção de teatro', descricao: 'Assistente de direção de teatro' },
  { id: '252305 Assistente de diretoria', descricao: 'Assistente de diretoria' },
  { id: '261125 Assistente de editorial', descricao: 'Assistente de editorial' },
  { id: '312105 Assistente de engenharia (construção civil)', descricao: 'Assistente de engenharia (construção civil)' },
  { id: '411010 Assistente de escritório', descricao: 'Assistente de escritório' },
  { id: '342405 Assistente de estação (metrô e ferrovia)', descricao: 'Assistente de estação (metrô e ferrovia)' },
  { id: '374210 Assistente de estúdio (cinema e vídeo)', descricao: 'Assistente de estúdio (cinema e vídeo)' },
  { id: '374105 Assistente de estúdio (gravação de áudio)', descricao: 'Assistente de estúdio (gravação de áudio)' },
  { id: '513115 Assistente de governanta', descricao: 'Assistente de governanta' },
  { id: '818105 Assistente de laboratório industrial', descricao: 'Assistente de laboratório industrial' },
  { id: '342125 Assistente de logística de transporte', descricao: 'Assistente de logística de transporte' },
  { id: '253405 Assistente de mídias sociais', descricao: 'Assistente de mídias sociais' },
  { id: '351715 Assistente de negócios de seguros', descricao: 'Assistente de negócios de seguros' },
  { id: '373145 Assistente de operações audiovisuais', descricao: 'Assistente de operações audiovisuais' },
  { id: '515115 Assistente de parto', descricao: 'Assistente de parto' },
  { id: '391145 Assistente de planejamento de manutenção', descricao: 'Assistente de planejamento de manutenção' },
  { id: '252305 Assistente de presidência', descricao: 'Assistente de presidência' },
  { id: '517425 Assistente de prevenção de perdas', descricao: 'Assistente de prevenção de perdas' },
  { id: '375125 Assistente de produção (moda)', descricao: 'Assistente de produção (moda)' },
  { id: '351720 Assistente de produtos (técnico de seguros)', descricao: 'Assistente de produtos (técnico de seguros)' },
  { id: '711410 Assistente de salinas', descricao: 'Assistente de salinas' },
  { id: '517330 Assistente de segurança', descricao: 'Assistente de segurança' },
  { id: '317210 Assistente de service desk', descricao: 'Assistente de service desk' },
  { id: '413110 Assistente de serviço de contabilidade', descricao: 'Assistente de serviço de contabilidade' },
  { id: '342305 Assistente de tráfego rodoviário', descricao: 'Assistente de tráfego rodoviário' },
  { id: '354125 Assistente de vendas', descricao: 'Assistente de vendas' },
  { id: '114205 Assistente sindical de entidades de trabalhadores', descricao: 'Assistente sindical de entidades de trabalhadores' },
  { id: '114210 Assistente sindical de entidades patronais', descricao: 'Assistente sindical de entidades patronais' },
  { id: '251605 Assistente social', descricao: 'Assistente social' },
  { id: '411010 Assistente técnico - no serviço público', descricao: 'Assistente técnico - no serviço público' },
  { id: '312205 Assistente técnico de engenharia (obras de infra-estrutura de estrada)', descricao: 'Assistente técnico de engenharia (obras de infra-estrutura de estrada)' },
  { id: '312210 Assistente técnico de engenharia de obras de saneamento', descricao: 'Assistente técnico de engenharia de obras de saneamento' },
  { id: '314715 Assistente técnico de fundição em siderurgia', descricao: 'Assistente técnico de fundição em siderurgia' },
  { id: '351720 Assistente técnico de seguros', descricao: 'Assistente técnico de seguros' },
  { id: '321205 Assistente técnico em madeira', descricao: 'Assistente técnico em madeira' },
  { id: '321210 Assistente técnico florestal', descricao: 'Assistente técnico florestal' },
  { id: '820205 Assistente técnico na fabricação de produtos de cerâmica, porcelanatos e afins', descricao: 'Assistente técnico na fabricação de produtos de cerâmica, porcelanatos e afins' },
  { id: '252315 Assistente trilíngüe', descricao: 'Assistente trilíngüe' },
  { id: '2619 ASSISTENTES DE DIREÇÃO (TV) E CONTINUÍSTAS', descricao: 'ASSISTENTES DE DIREÇÃO (TV) E CONTINUÍSTAS' },
  { id: '2516 ASSISTENTES SOCIAIS E ECONOMISTAS DOMÉSTICOS', descricao: 'ASSISTENTES SOCIAIS E ECONOMISTAS DOMÉSTICOS' },
  { id: '716505 Assoalhador', descricao: 'Assoalhador' },
  { id: '516705 Astrólogo', descricao: 'Astrólogo' },
  { id: '5167 ASTRÓLOGOS E NUMERÓLOGOS', descricao: 'ASTRÓLOGOS E NUMERÓLOGOS' },
  { id: '213305 Astrônomo', descricao: 'Astrônomo' },
  { id: '141405 Atacadista', descricao: 'Atacadista' },
  { id: '377110 Atacante - no futebol', descricao: 'Atacante - no futebol' },
  { id: '521140 Atendente balconista', descricao: 'Atendente balconista' },
  { id: '513440 Atendente barista', descricao: 'Atendente barista' },
  { id: '422315 Atendente central telemarketing', descricao: 'Atendente central telemarketing' },
  { id: '421105 Atendente comercial (agência postal)', descricao: 'Atendente comercial (agência postal)' },
  { id: '421105 Atendente comercial em agência postal', descricao: 'Atendente comercial em agência postal' },
  { id: '342535 Atendente de aeroporto', descricao: 'Atendente de aeroporto' },
  { id: '413205 Atendente de agência', descricao: 'Atendente de agência' },
  { id: '422110 Atendente de ambulatório', descricao: 'Atendente de ambulatório' },
  { id: '521140 Atendente de balcão', descricao: 'Atendente de balcão' },
  { id: '513435 Atendente de balcão de café', descricao: 'Atendente de balcão de café' },
  { id: '513420 Atendente de bar', descricao: 'Atendente de bar' },
  { id: '515110 Atendente de berçário', descricao: 'Atendente de berçário' },
  { id: '513405 Atendente de buffet', descricao: 'Atendente de buffet' },
  { id: '513440 Atendente de cafeteria', descricao: 'Atendente de cafeteria' },
  { id: '515110 Atendente de centro cirúrgico', descricao: 'Atendente de centro cirúrgico' },
  { id: '422110 Atendente de clínica médica', descricao: 'Atendente de clínica médica' },
  { id: '422105 Atendente de clínica veterinária', descricao: 'Atendente de clínica veterinária' },
  { id: '322415 Atendente de clínica dentária', descricao: 'Atendente de clínica dentária' },
  { id: '422110 Atendente de consultório médico', descricao: 'Atendente de consultório médico' },
  { id: '422105 Atendente de consultório veterinário', descricao: 'Atendente de consultório veterinário' },
  { id: '331110 Atendente de creche', descricao: 'Atendente de creche' },
  { id: '422330 Atendente de emergência', descricao: 'Atendente de emergência' },
  { id: '515110 Atendente de enfermagem', descricao: 'Atendente de enfermagem' },
  { id: '515110 Atendente de enfermagem no serviço doméstico', descricao: 'Atendente de enfermagem no serviço doméstico' },
  { id: '513410 Atendente de estabelecimento com cervejas artesanais', descricao: 'Atendente de estabelecimento com cervejas artesanais' },
  { id: '521130 Atendente de farmácia - balconista', descricao: 'Atendente de farmácia - balconista' },
  { id: '322415 Atendente de gabinete dentário', descricao: 'Atendente de gabinete dentário' },
  { id: '515110 Atendente de hospital', descricao: 'Atendente de hospital' },
  { id: '422315 Atendente de informações (telemarketing)', descricao: 'Atendente de informações (telemarketing)' },
  { id: '411015 Atendente de judiciário', descricao: 'Atendente de judiciário' },
  { id: '513435 Atendente de lanchonete', descricao: 'Atendente de lanchonete' },
  { id: '516340 Atendente de lavanderia', descricao: 'Atendente de lavanderia' },
  { id: '521140 Atendente de lojas', descricao: 'Atendente de lojas' },
  { id: '521140 Atendente de lojas e mercados', descricao: 'Atendente de lojas e mercados' },
  { id: '513405 Atendente de mesa', descricao: 'Atendente de mesa' },
  { id: '521140 Atendente de padaria', descricao: 'Atendente de padaria' },
  { id: '421125 Atendente de pedágio', descricao: 'Atendente de pedágio' },
  { id: '521140 Atendente de peixaria', descricao: 'Atendente de peixaria' },
  { id: '517405 Atendente de portaria de hotel', descricao: 'Atendente de portaria de hotel' },
  { id: '521135 Atendente de posto de gasolina', descricao: 'Atendente de posto de gasolina' },
  { id: '515325 Atendente de reintegração social', descricao: 'Atendente de reintegração social' },
  { id: '422115 Atendente de seguro saúde', descricao: 'Atendente de seguro saúde' },
  { id: '515110 Atendente de serviço de saúde', descricao: 'Atendente de serviço de saúde' },
  { id: '515110 Atendente de serviço médico', descricao: 'Atendente de serviço médico' },
  { id: '322415 Atendente de serviço odontológico', descricao: 'Atendente de serviço odontológico' },
  { id: '422315 Atendente de telemarketing', descricao: 'Atendente de telemarketing' },
  { id: '521140 Atendente do setor de frios e laticínios', descricao: 'Atendente do setor de frios e laticínios' },
  { id: '521140 Atendente do setor de hortifrutigranjeiros', descricao: 'Atendente do setor de hortifrutigranjeiros' },
  { id: '521140 Atendente do setor de rotisserie (pratos prontos)', descricao: 'Atendente do setor de rotisserie (pratos prontos)' },
  { id: '521140 Atendente do setor de vinhos', descricao: 'Atendente do setor de vinhos' },
  { id: '421105 Atendente em agência de correios', descricao: 'Atendente em agência de correios' },
  { id: '516505 Atendente funerário', descricao: 'Atendente funerário' },
  { id: '515110 Atendente hospitalar', descricao: 'Atendente hospitalar' },
  { id: '322415 Atendente odontológico', descricao: 'Atendente odontológico' },
  { id: '515110 Atendente-enfermeiro', descricao: 'Atendente-enfermeiro' },
  { id: '376215 Atirador de facas', descricao: 'Atirador de facas' },
  { id: '377105 Atleta da ginástica', descricao: 'Atleta da ginástica' },
  { id: '377105 Atleta da natação', descricao: 'Atleta da natação' },
  { id: '377105 Atleta de bodybare', descricao: 'Atleta de bodybare' },
  { id: '377105 Atleta de canoagem', descricao: 'Atleta de canoagem' },
  { id: '377105 Atleta de ciclismo', descricao: 'Atleta de ciclismo' },
  { id: '377105 Atleta de escalada', descricao: 'Atleta de escalada' },
  { id: '377105 Atleta de esgrima', descricao: 'Atleta de esgrima' },
  { id: '377110 Atleta de futebol sete', descricao: 'Atleta de futebol sete' },
  { id: '377110 Atleta de futsal', descricao: 'Atleta de futsal' },
  { id: '377105 Atleta de futvôlei', descricao: 'Atleta de futvôlei' },
  { id: '377105 Atleta de handebol', descricao: 'Atleta de handebol' },
  { id: '377120 Atleta de judô', descricao: 'Atleta de judô' },
  { id: '377120 Atleta de karatê', descricao: 'Atleta de karatê' },
  { id: '377105 Atleta de nado sincronizado', descricao: 'Atleta de nado sincronizado' },
  { id: '377105 Atleta de paraquedismo', descricao: 'Atleta de paraquedismo' },
  { id: '377105 Atleta de patinação', descricao: 'Atleta de patinação' },
  { id: '377105 Atleta de peteca', descricao: 'Atleta de peteca' },
  { id: '377105 Atleta de pólo aquático', descricao: 'Atleta de pólo aquático' },
  { id: '377105 Atleta de remo', descricao: 'Atleta de remo' },
  { id: '377105 Atleta de skate', descricao: 'Atleta de skate' },
  { id: '377105 Atleta de squash', descricao: 'Atleta de squash' },
  { id: '377105 Atleta de surf e windsurf', descricao: 'Atleta de surf e windsurf' },
  { id: '377120 Atleta de taichichuan', descricao: 'Atleta de taichichuan' },
  { id: '377125 Atleta de tênis profissional', descricao: 'Atleta de tênis profissional' },
  { id: '377140 Atleta de triatlo', descricao: 'Atleta de triatlo' },
  { id: '377105 Atleta de vela', descricao: 'Atleta de vela' },
  { id: '377105 Atleta de voleibol', descricao: 'Atleta de voleibol' },
  { id: '377105 Atleta do basquete', descricao: 'Atleta do basquete' },
  { id: '377105 Atleta do beisebol', descricao: 'Atleta do beisebol' },
  { id: '377130 Atleta do hipismo', descricao: 'Atleta do hipismo' },
  { id: '377105 Atleta do hóquei', descricao: 'Atleta do hóquei' },
  { id: '377105 Atleta profissional (outras modalidades)', descricao: 'Atleta profissional (outras modalidades)' },
  { id: '377110 Atleta profissional de futebol', descricao: 'Atleta profissional de futebol' },
  { id: '377115 Atleta profissional de golfe', descricao: 'Atleta profissional de golfe' },
  { id: '377120 Atleta profissional de luta', descricao: 'Atleta profissional de luta' },
  { id: '377125 Atleta profissional de tênis', descricao: 'Atleta profissional de tênis' },
  { id: '3771 ATLETAS PROFISSIONAIS', descricao: 'ATLETAS PROFISSIONAIS' },
  { id: '262505 Ator', descricao: 'Ator' },
  { id: '262505 Ator bonequeiro', descricao: 'Ator bonequeiro' },
  { id: '262505 Ator de cinema', descricao: 'Ator de cinema' },
  { id: '262505 Ator de rádio', descricao: 'Ator de rádio' },
  { id: '262505 Ator de teatro', descricao: 'Ator de teatro' },
  { id: '262505 Ator de televisão', descricao: 'Ator de televisão' },
  { id: '262505 Ator dramático', descricao: 'Ator dramático' },
  { id: '262505 Ator dublador', descricao: 'Ator dublador' },
  { id: '2625 ATORES', descricao: 'ATORES' },
  { id: '211105 Atuário', descricao: 'Atuário' },
  { id: '261430 Audiodescritor', descricao: 'Audiodescritor' },
  { id: '223820 Audiologista (fonoaudiólogo)', descricao: 'Audiologista (fonoaudiólogo)' },
  { id: '252205 Auditor (contadores e afins)', descricao: 'Auditor (contadores e afins)' },
  { id: '252205 Auditor contábil', descricao: 'Auditor contábil' },
  { id: '254505 Auditor de atividades urbanas', descricao: 'Auditor de atividades urbanas' },
  { id: '252205 Auditor de contabilidade e orçamento', descricao: 'Auditor de contabilidade e orçamento' },
  { id: '252205 Auditor externo (contadores e afins)', descricao: 'Auditor externo (contadores e afins)' },
  { id: '252205 Auditor financeiro', descricao: 'Auditor financeiro' },
  { id: '252205 Auditor fiscal (em contabilidade)', descricao: 'Auditor fiscal (em contabilidade)' },
  { id: '254505 Auditor fiscal de atividades urbanas', descricao: 'Auditor fiscal de atividades urbanas' },
  { id: '252205 Auditor independente (contadores e afins)', descricao: 'Auditor independente (contadores e afins)' },
  { id: '252205 Auditor interno (contadores e afins)', descricao: 'Auditor interno (contadores e afins)' },
  { id: '254205 Auditor-fiscal da previdência social', descricao: 'Auditor-fiscal da previdência social' },
  { id: '254105 Auditor-fiscal da receita federal', descricao: 'Auditor-fiscal da receita federal' },
  { id: '254305 Auditor-fiscal do trabalho', descricao: 'Auditor-fiscal do trabalho' },
  { id: '2542 AUDITORES FISCAIS DA PREVIDÊNCIA SOCIAL', descricao: 'AUDITORES FISCAIS DA PREVIDÊNCIA SOCIAL' },
  { id: '2543 AUDITORES FISCAIS DO TRABALHO', descricao: 'AUDITORES FISCAIS DO TRABALHO' },
  { id: '2541 AUDITORES FISCAIS E TÉCNICOS DA RECEITA FEDERAL', descricao: 'AUDITORES FISCAIS E TÉCNICOS DA RECEITA FEDERAL' },
  { id: '751125 Aurifice', descricao: 'Aurifice' },
  { id: '261515 Autor de ficção', descricao: 'Autor de ficção' },
  { id: '262605 Autor de música', descricao: 'Autor de música' },
  { id: '261505 Autor-roteirista', descricao: 'Autor-roteirista' },
  { id: '261505 Autor-roteirista de cinema', descricao: 'Autor-roteirista de cinema' },
  { id: '261505 Autor-roteirista de rádio', descricao: 'Autor-roteirista de rádio' },
  { id: '261505 Autor-roteirista de teatro', descricao: 'Autor-roteirista de teatro' },
  { id: '261505 Autor-roteirista de televisão', descricao: 'Autor-roteirista de televisão' },
  { id: '261505 Autor-roteirista multimídia', descricao: 'Autor-roteirista multimídia' },
  { id: '242305 Autoridade policial (delegado)', descricao: 'Autoridade policial (delegado)' },
  { id: '411005 Auxiliar administrativo', descricao: 'Auxiliar administrativo' },
  { id: '252310 Auxiliar administrativo bilíngüe', descricao: 'Auxiliar administrativo bilíngüe' },
  { id: '252305 Auxiliar administrativo de diretoria', descricao: 'Auxiliar administrativo de diretoria' },
  { id: '252305 Auxiliar administrativo de presidência', descricao: 'Auxiliar administrativo de presidência' },
  { id: '252315 Auxiliar administrativo trilíngüe', descricao: 'Auxiliar administrativo trilíngüe' },
  { id: '413110 Auxiliar contábil', descricao: 'Auxiliar contábil' },
  { id: '721315 Auxiliar de afiador de ferramentas', descricao: 'Auxiliar de afiador de ferramentas' },
  { id: '622020 Auxiliar de agricultura', descricao: 'Auxiliar de agricultura' },
  { id: '414105 Auxiliar de almoxarifado', descricao: 'Auxiliar de almoxarifado' },
  { id: '322230 Auxiliar de ambulatório', descricao: 'Auxiliar de ambulatório' },
  { id: '421210 Auxiliar de apostas', descricao: 'Auxiliar de apostas' },
  { id: '414110 Auxiliar de armazenamento', descricao: 'Auxiliar de armazenamento' },
  { id: '318505 Auxiliar de arquitetura', descricao: 'Auxiliar de arquitetura' },
  { id: '515205 Auxiliar de banco de sangue', descricao: 'Auxiliar de banco de sangue' },
  { id: '513420 Auxiliar de barman', descricao: 'Auxiliar de barman' },
  { id: '371105 Auxiliar de biblioteca', descricao: 'Auxiliar de biblioteca' },
  { id: '371105 Auxiliar de bibliotecário', descricao: 'Auxiliar de bibliotecário' },
  { id: '731165 Auxiliar de bobinagem, à mão', descricao: 'Auxiliar de bobinagem, à mão' },
  { id: '516110 Auxiliar de cabeleireiro', descricao: 'Auxiliar de cabeleireiro' },
  { id: '711120 Auxiliar de cabo de fogo', descricao: 'Auxiliar de cabo de fogo' },
  { id: '411025 Auxiliar de cartório', descricao: 'Auxiliar de cartório' },
  { id: '513505 Auxiliar de churrasqueiro', descricao: 'Auxiliar de churrasqueiro' },
  { id: '373145 Auxiliar de cinegrafia', descricao: 'Auxiliar de cinegrafia' },
  { id: '848525 Auxiliar de classificador de carne', descricao: 'Auxiliar de classificador de carne' },
  { id: '421310 Auxiliar de cobrança', descricao: 'Auxiliar de cobrança' },
  { id: '716305 Auxiliar de colocador de vidros', descricao: 'Auxiliar de colocador de vidros' },
  { id: '411005 Auxiliar de compras', descricao: 'Auxiliar de compras' },
  { id: '763125 Auxiliar de confecção', descricao: 'Auxiliar de confecção' },
  { id: '848310 Auxiliar de confeitaria', descricao: 'Auxiliar de confeitaria' },
  { id: '513505 Auxiliar de confeiteiro', descricao: 'Auxiliar de confeiteiro' },
  { id: '514325 Auxiliar de conservação de barragens', descricao: 'Auxiliar de conservação de barragens' },
  { id: '514325 Auxiliar de conservação de obras civis', descricao: 'Auxiliar de conservação de obras civis' },
  { id: '413110 Auxiliar de contabilidade', descricao: 'Auxiliar de contabilidade' },
  { id: '413110 Auxiliar de contas a pagar', descricao: 'Auxiliar de contas a pagar' },
  { id: '413110 Auxiliar de contas a receber', descricao: 'Auxiliar de contas a receber' },
  { id: '239405 Auxiliar de coordenação de ensino fundamental de primeira a quarta séries', descricao: 'Auxiliar de coordenação de ensino fundamental de primeira a quarta séries' },
  { id: '239405 Auxiliar de coordenador escolar', descricao: 'Auxiliar de coordenador escolar' },
  { id: '716305 Auxiliar de cortador de vidros', descricao: 'Auxiliar de cortador de vidros' },
  { id: '763105 Auxiliar de corte (preparação da confecção de roupas)', descricao: 'Auxiliar de corte (preparação da confecção de roupas)' },
  { id: '763210 Auxiliar de costura', descricao: 'Auxiliar de costura' },
  { id: '763320 Auxiliar de costureira (no acabamento)', descricao: 'Auxiliar de costureira (no acabamento)' },
  { id: '513505 Auxiliar de cozinha', descricao: 'Auxiliar de cozinha' },
  { id: '331110 Auxiliar de creche', descricao: 'Auxiliar de creche' },
  { id: '410225 Auxiliar de crédito', descricao: 'Auxiliar de crédito' },
  { id: '762205 Auxiliar de curtimento', descricao: 'Auxiliar de curtimento' },
  { id: '413110 Auxiliar de custos', descricao: 'Auxiliar de custos' },
  { id: '632110 Auxiliar de dendrometria', descricao: 'Auxiliar de dendrometria' },
  { id: '632110 Auxiliar de dendrometrista', descricao: 'Auxiliar de dendrometrista' },
  { id: '322415 Auxiliar de dentista', descricao: 'Auxiliar de dentista' },
  { id: '331110 Auxiliar de desenvolvimento infantil', descricao: 'Auxiliar de desenvolvimento infantil' },
  { id: '821250 Auxiliar de desgaseificação', descricao: 'Auxiliar de desgaseificação' },
  { id: '848515 Auxiliar de desossador', descricao: 'Auxiliar de desossador' },
  { id: '821235 Auxiliar de dessulfuração e estação de mistura', descricao: 'Auxiliar de dessulfuração e estação de mistura' },
  { id: '223705 Auxiliar de dietista', descricao: 'Auxiliar de dietista' },
  { id: '724510 Auxiliar de dobrador (metais)', descricao: 'Auxiliar de dobrador (metais)' },
  { id: '848310 Auxiliar de doceiro', descricao: 'Auxiliar de doceiro' },
  { id: '313215 Auxiliar de eletrônica', descricao: 'Auxiliar de eletrônica' },
  { id: '313105 Auxiliar de eletrotécnico', descricao: 'Auxiliar de eletrotécnico' },
  { id: '724110 Auxiliar de encanador', descricao: 'Auxiliar de encanador' },
  { id: '322230 Auxiliar de enfermagem', descricao: 'Auxiliar de enfermagem' },
  { id: '322230 Auxiliar de enfermagem de central de material esterelizado (cme)', descricao: 'Auxiliar de enfermagem de central de material esterelizado (cme)' },
  { id: '322230 Auxiliar de enfermagem de centro cirúrgico', descricao: 'Auxiliar de enfermagem de centro cirúrgico' },
  { id: '322230 Auxiliar de enfermagem de clínica médica', descricao: 'Auxiliar de enfermagem de clínica médica' },
  { id: '322230 Auxiliar de enfermagem de hospital', descricao: 'Auxiliar de enfermagem de hospital' },
  { id: '322230 Auxiliar de enfermagem de saúde pública', descricao: 'Auxiliar de enfermagem de saúde pública' },
  { id: '322235 Auxiliar de enfermagem do trabalho', descricao: 'Auxiliar de enfermagem do trabalho' },
  { id: '322230 Auxiliar de enfermagem em hemodiálise', descricao: 'Auxiliar de enfermagem em hemodiálise' },
  { id: '322230 Auxiliar de enfermagem em home care', descricao: 'Auxiliar de enfermagem em home care' },
  { id: '322230 Auxiliar de enfermagem em nefrologia', descricao: 'Auxiliar de enfermagem em nefrologia' },
  { id: '322230 Auxiliar de enfermagem em saúde mental', descricao: 'Auxiliar de enfermagem em saúde mental' },
  { id: '322235 Auxiliar de enfermagem em saúde ocupacional', descricao: 'Auxiliar de enfermagem em saúde ocupacional' },
  { id: '322235 Auxiliar de enfermagem ocupacional', descricao: 'Auxiliar de enfermagem ocupacional' },
  { id: '515135 Auxiliar de enfermagem socorrista', descricao: 'Auxiliar de enfermagem socorrista' },
  { id: '318510 Auxiliar de engenheiro da construção civil', descricao: 'Auxiliar de engenheiro da construção civil' },
  { id: '411005 Auxiliar de escritório', descricao: 'Auxiliar de escritório' },
  { id: '413110 Auxiliar de escrituração fiscal', descricao: 'Auxiliar de escrituração fiscal' },
  { id: '411035 Auxiliar de estatística', descricao: 'Auxiliar de estatística' },
  { id: '414125 Auxiliar de estoque', descricao: 'Auxiliar de estoque' },
  { id: '374105 Auxiliar de estúdio', descricao: 'Auxiliar de estúdio' },
  { id: '414135 Auxiliar de expedição', descricao: 'Auxiliar de expedição' },
  { id: '342105 Auxiliar de exportação e importação', descricao: 'Auxiliar de exportação e importação' },
  { id: '823320 Auxiliar de fabricação nas indústrias de artefatos de cimento', descricao: 'Auxiliar de fabricação nas indústrias de artefatos de cimento' },
  { id: '515210 Auxiliar de farmácia de manipulação', descricao: 'Auxiliar de farmácia de manipulação' },
  { id: '341250 Auxiliar de faroleiro', descricao: 'Auxiliar de faroleiro' },
  { id: '413115 Auxiliar de faturamento', descricao: 'Auxiliar de faturamento' },
  { id: '524205 Auxiliar de feirante', descricao: 'Auxiliar de feirante' },
  { id: '841715 Auxiliar de fermentação', descricao: 'Auxiliar de fermentação' },
  { id: '516505 Auxiliar de funerária', descricao: 'Auxiliar de funerária' },
  { id: '723215 Auxiliar de galvanização', descricao: 'Auxiliar de galvanização' },
  { id: '513415 Auxiliar de garçom', descricao: 'Auxiliar de garçom' },
  { id: '322230 Auxiliar de ginecologia', descricao: 'Auxiliar de ginecologia' },
  { id: '322230 Auxiliar de hipodermia', descricao: 'Auxiliar de hipodermia' },
  { id: '373145 Auxiliar de iluminação', descricao: 'Auxiliar de iluminação' },
  { id: '623315 Auxiliar de incubação', descricao: 'Auxiliar de incubação' },
  { id: '374110 Auxiliar de instalação (equipamentos de rádio)', descricao: 'Auxiliar de instalação (equipamentos de rádio)' },
  { id: '632115 Auxiliar de inventário', descricao: 'Auxiliar de inventário' },
  { id: '992225 Auxiliar de jardinagem na conservação de vias permanentes', descricao: 'Auxiliar de jardinagem na conservação de vias permanentes' },
  { id: '411020 Auxiliar de judiciário', descricao: 'Auxiliar de judiciário' },
  { id: '515215 Auxiliar de laboratório de análises clínicas', descricao: 'Auxiliar de laboratório de análises clínicas' },
  { id: '818110 Auxiliar de laboratório de análises físico-químicas', descricao: 'Auxiliar de laboratório de análises físico-químicas' },
  { id: '515220 Auxiliar de laboratório de imunobiológicos', descricao: 'Auxiliar de laboratório de imunobiológicos' },
  { id: '766405 Auxiliar de laboratório fotográfico', descricao: 'Auxiliar de laboratório fotográfico' },
  { id: '301105 Auxiliar de laboratorista (indústria)', descricao: 'Auxiliar de laboratorista (indústria)' },
  { id: '301110 Auxiliar de laboratorista de solo', descricao: 'Auxiliar de laboratorista de solo' },
  { id: '301110 Auxiliar de laboratorista de solos e de concreto', descricao: 'Auxiliar de laboratorista de solos e de concreto' },
  { id: '516310 Auxiliar de lavador de roupas, a máquina industrial', descricao: 'Auxiliar de lavador de roupas, a máquina industrial' },
  { id: '516315 Auxiliar de lavador de tapetes', descricao: 'Auxiliar de lavador de tapetes' },
  { id: '516345 Auxiliar de lavanderia', descricao: 'Auxiliar de lavanderia' },
  { id: '514320 Auxiliar de limpeza', descricao: 'Auxiliar de limpeza' },
  { id: '784205 Auxiliar de linha de produção', descricao: 'Auxiliar de linha de produção' },
  { id: '414140 Auxiliar de logistica', descricao: 'Auxiliar de logistica' },
  { id: '919110 Auxiliar de lubrificador de veículos', descricao: 'Auxiliar de lubrificador de veículos' },
  { id: '262615 Auxiliar de maestro', descricao: 'Auxiliar de maestro' },
  { id: '848520 Auxiliar de magarefe', descricao: 'Auxiliar de magarefe' },
  { id: '513405 Auxiliar de maître', descricao: 'Auxiliar de maître' },
  { id: '514310 Auxiliar de manutenção de edificações', descricao: 'Auxiliar de manutenção de edificações' },
  { id: '514325 Auxiliar de manutenção de edifícios', descricao: 'Auxiliar de manutenção de edifícios' },
  { id: '991105 Auxiliar de manutenção de linha férrea', descricao: 'Auxiliar de manutenção de linha férrea' },
  { id: '725030 Auxiliar de manutenção e recarga de extintor de incêndio', descricao: 'Auxiliar de manutenção e recarga de extintor de incêndio' },
  { id: '514310 Auxiliar de manutenção elétrica e hidráulica', descricao: 'Auxiliar de manutenção elétrica e hidráulica' },
  { id: '514310 Auxiliar de manutenção predial', descricao: 'Auxiliar de manutenção predial' },
  { id: '782625 Auxiliar de maquinista de trem', descricao: 'Auxiliar de maquinista de trem' },
  { id: '771105 Auxiliar de marceneiro', descricao: 'Auxiliar de marceneiro' },
  { id: '914405 Auxiliar de mecânico de autos', descricao: 'Auxiliar de mecânico de autos' },
  { id: '911120 Auxiliar de mecânico diesel (exceto de veículos automotores)', descricao: 'Auxiliar de mecânico diesel (exceto de veículos automotores)' },
  { id: '513415 Auxiliar de mesa em restaurantes, hotéis e outros', descricao: 'Auxiliar de mesa em restaurantes, hotéis e outros' },
  { id: '766125 Auxiliar de montador de fotolito', descricao: 'Auxiliar de montador de fotolito' },
  { id: '731160 Auxiliar de montagem de transformadores', descricao: 'Auxiliar de montagem de transformadores' },
  { id: '223705 Auxiliar de nutrição e dietética', descricao: 'Auxiliar de nutrição e dietética' },
  { id: '322230 Auxiliar de obstetrícia', descricao: 'Auxiliar de obstetrícia' },
  { id: '322230 Auxiliar de oftalmologia', descricao: 'Auxiliar de oftalmologia' },
  { id: '511210 Auxiliar de operação', descricao: 'Auxiliar de operação' },
  { id: '763310 Auxiliar de operador de máquina de bordar', descricao: 'Auxiliar de operador de máquina de bordar' },
  { id: '632120 Auxiliar de operador de motosserra', descricao: 'Auxiliar de operador de motosserra' },
  { id: '773125 Auxiliar de operador de serra circular múltipla', descricao: 'Auxiliar de operador de serra circular múltipla' },
  { id: '715420 Auxiliar de operador de usina de asfalto', descricao: 'Auxiliar de operador de usina de asfalto' },
  { id: '239410 Auxiliar de orientação educacional', descricao: 'Auxiliar de orientação educacional' },
  { id: '239415 Auxiliar de orientação pedagógica', descricao: 'Auxiliar de orientação pedagógica' },
  { id: '239415 Auxiliar de orientação pedagógica em educação fundamental de primeira a quarta séries', descricao: 'Auxiliar de orientação pedagógica em educação fundamental de primeira a quarta séries' },
  { id: '513505 Auxiliar de padeiro', descricao: 'Auxiliar de padeiro' },
  { id: '322230 Auxiliar de parteira', descricao: 'Auxiliar de parteira' },
  { id: '717020 Auxiliar de pedreiro', descricao: 'Auxiliar de pedreiro' },
  { id: '411030 Auxiliar de pessoal', descricao: 'Auxiliar de pessoal' },
  { id: '723320 Auxiliar de pintor de automóveis', descricao: 'Auxiliar de pintor de automóveis' },
  { id: '513505 Auxiliar de pizzaiolo', descricao: 'Auxiliar de pizzaiolo' },
  { id: '722325 Auxiliar de preparação de areia', descricao: 'Auxiliar de preparação de areia' },
  { id: '842120 Auxiliar de processamento de fumo', descricao: 'Auxiliar de processamento de fumo' },
  { id: '763125 Auxiliar de produção - na confecção de roupas', descricao: 'Auxiliar de produção - na confecção de roupas' },
  { id: '842120 Auxiliar de produção de fumo', descricao: 'Auxiliar de produção de fumo' },
  { id: '841448 Auxiliar de produção de gorduras vegetais comestíveis', descricao: 'Auxiliar de produção de gorduras vegetais comestíveis' },
  { id: '515225 Auxiliar de produção farmacêutica', descricao: 'Auxiliar de produção farmacêutica' },
  { id: '322420 Auxiliar de prótese dentária', descricao: 'Auxiliar de prótese dentária' },
  { id: '766420 Auxiliar de radiologia (revelação fotográfica)', descricao: 'Auxiliar de radiologia (revelação fotográfica)' },
  { id: '422110 Auxiliar de recepção', descricao: 'Auxiliar de recepção' },
  { id: '848115 Auxiliar de salsicheiro', descricao: 'Auxiliar de salsicheiro' },
  { id: '515120 Auxiliar de sanitarista', descricao: 'Auxiliar de sanitarista' },
  { id: '322240 Auxiliar de saúde (navegação marítima)', descricao: 'Auxiliar de saúde (navegação marítima)' },
  { id: '322240 Auxiliar de saúde marítimo', descricao: 'Auxiliar de saúde marítimo' },
  { id: '517330 Auxiliar de segurança', descricao: 'Auxiliar de segurança' },
  { id: '411040 Auxiliar de seguros', descricao: 'Auxiliar de seguros' },
  { id: '766205 Auxiliar de serigrafia', descricao: 'Auxiliar de serigrafia' },
  { id: '513425 Auxiliar de serviço de copa', descricao: 'Auxiliar de serviço de copa' },
  { id: '517330 Auxiliar de serviço de segurança', descricao: 'Auxiliar de serviço de segurança' },
  { id: '371105 Auxiliar de serviços bibliotecários', descricao: 'Auxiliar de serviços bibliotecários' },
  { id: '411045 Auxiliar de serviços de importação e exportação', descricao: 'Auxiliar de serviços de importação e exportação' },
  { id: '763125 Auxiliar de serviços gerais - na confecção de roupas', descricao: 'Auxiliar de serviços gerais - na confecção de roupas' },
  { id: '351430 Auxiliar de serviços jurídicos', descricao: 'Auxiliar de serviços jurídicos' },
  { id: '783205 Auxiliar de serviços no aeroporto', descricao: 'Auxiliar de serviços no aeroporto' },
  { id: '239430 Auxiliar de supervisão de ensino', descricao: 'Auxiliar de supervisão de ensino' },
  { id: '239430 Auxiliar de supervisor escolar', descricao: 'Auxiliar de supervisor escolar' },
  { id: '513505 Auxiliar de sushiman', descricao: 'Auxiliar de sushiman' },
  { id: '391215 Auxiliar de técnico de controle de qualidade', descricao: 'Auxiliar de técnico de controle de qualidade' },
  { id: '313215 Auxiliar de técnico de eletrônica', descricao: 'Auxiliar de técnico de eletrônica' },
  { id: '312320 Auxiliar de topógrafo', descricao: 'Auxiliar de topógrafo' },
  { id: '721215 Auxiliar de torneiro mecânico', descricao: 'Auxiliar de torneiro mecânico' },
  { id: '517220 Auxiliar de tráfego', descricao: 'Auxiliar de tráfego' },
  { id: '411045 Auxiliar de tráfego de exportação e importação', descricao: 'Auxiliar de tráfego de exportação e importação' },
  { id: '373145 Auxiliar de unidade portátil externa', descricao: 'Auxiliar de unidade portátil externa' },
  { id: '623110 Auxiliar de vaqueiro', descricao: 'Auxiliar de vaqueiro' },
  { id: '519305 Auxiliar de veterinário', descricao: 'Auxiliar de veterinário' },
  { id: '716305 Auxiliar de vidraceiro', descricao: 'Auxiliar de vidraceiro' },
  { id: '716315 Auxiliar de vitralista', descricao: 'Auxiliar de vitralista' },
  { id: '322230 Auxiliar em hemotransfusão', descricao: 'Auxiliar em hemotransfusão' },
  { id: '322415 Auxiliar em saúde bucal', descricao: 'Auxiliar em saúde bucal' },
  { id: '413110 Auxiliar financeiro', descricao: 'Auxiliar financeiro' },
  { id: '992225 Auxiliar geral de conservação de vias permanentes (exceto trilhos)', descricao: 'Auxiliar geral de conservação de vias permanentes (exceto trilhos)' },
  { id: '373145 Auxiliar iluminador', descricao: 'Auxiliar iluminador' },
  { id: '351430 Auxiliar jurídico', descricao: 'Auxiliar jurídico' },
  { id: '911205 Auxiliar mecânico de ar condicionado', descricao: 'Auxiliar mecânico de ar condicionado' },
  { id: '911205 Auxiliar mecânico de refrigeração', descricao: 'Auxiliar mecânico de refrigeração' },
  { id: '513505 Auxiliar nos serviços de alimentação', descricao: 'Auxiliar nos serviços de alimentação' },
  { id: '414140 Auxiliar operacional de logística', descricao: 'Auxiliar operacional de logística' },
  { id: '311515 Auxiliar técnico de centro de operação do sistema de energia', descricao: 'Auxiliar técnico de centro de operação do sistema de energia' },
  { id: '312205 Auxiliar técnico de conservação de estradas, caminhos e pontes', descricao: 'Auxiliar técnico de conservação de estradas, caminhos e pontes' },
  { id: '311515 Auxiliar técnico de distribuição de energia', descricao: 'Auxiliar técnico de distribuição de energia' },
  { id: '334110 Auxiliar técnico de educação', descricao: 'Auxiliar técnico de educação' },
  { id: '732105 Auxiliar técnico de eletricidade de linhas de transmissão', descricao: 'Auxiliar técnico de eletricidade de linhas de transmissão' },
  { id: '312105 Auxiliar técnico de engenharia (construção civil)', descricao: 'Auxiliar técnico de engenharia (construção civil)' },
  { id: '314715 Auxiliar técnico de fundição em siderurgia', descricao: 'Auxiliar técnico de fundição em siderurgia' },
  { id: '311515 Auxiliar técnico de geração de energia', descricao: 'Auxiliar técnico de geração de energia' },
  { id: '312315 Auxiliar técnico de hidrografia', descricao: 'Auxiliar técnico de hidrografia' },
  { id: '300305 Auxiliar técnico de instalações eletromecânicas', descricao: 'Auxiliar técnico de instalações eletromecânicas' },
  { id: '325105 Auxiliar técnico de manipulação em laboratório de farmácia', descricao: 'Auxiliar técnico de manipulação em laboratório de farmácia' },
  { id: '314110 Auxiliar técnico de mecânica', descricao: 'Auxiliar técnico de mecânica' },
  { id: '374110 Auxiliar técnico de montagem', descricao: 'Auxiliar técnico de montagem' },
  { id: '312210 Auxiliar técnico de obras saneamento', descricao: 'Auxiliar técnico de obras saneamento' },
  { id: '314115 Auxiliar técnico de refrigeração', descricao: 'Auxiliar técnico de refrigeração' },
  { id: '411040 Auxiliar técnico de seguros', descricao: 'Auxiliar técnico de seguros' },
  { id: '341250 Auxiliar técnico de sinalização nautica', descricao: 'Auxiliar técnico de sinalização nautica' },
  { id: '313215 Auxiliar técnico eletrônico', descricao: 'Auxiliar técnico eletrônico' },
  { id: '325105 Auxiliar técnico em laboratório de farmácia', descricao: 'Auxiliar técnico em laboratório de farmácia' },
  { id: '312205 Auxiliar técnico em obras de infra-estrutura de estradas', descricao: 'Auxiliar técnico em obras de infra-estrutura de estradas' },
  { id: '515215 Auxiliar técnico em patologia cínica', descricao: 'Auxiliar técnico em patologia cínica' },
  { id: '321210 Auxiliar técnico florestal', descricao: 'Auxiliar técnico florestal' },
  { id: '314120 Auxiliar técnico na mecânica de máquinas', descricao: 'Auxiliar técnico na mecânica de máquinas' },
  { id: '915405 Auxiliar técnico na reparação de equipamentos fotográficos', descricao: 'Auxiliar técnico na reparação de equipamentos fotográficos' },
  { id: '224135 Auxiliar técnico- no futebol', descricao: 'Auxiliar técnico- no futebol' },
  { id: '224135 Auxiliar técnico- nos esportes', descricao: 'Auxiliar técnico- nos esportes' },
  { id: '761430 Auxiliar-ajudante de operador de máquinas de lavar fibras, fios, tecidos e peças confeccionadas', descricao: 'Auxiliar-ajudante de operador de máquinas de lavar fibras, fios, tecidos e peças confeccionadas' },
  { id: '4131 AUXILIARES DE CONTABILIDADE', descricao: 'AUXILIARES DE CONTABILIDADE' },
  { id: '5152 AUXILIARES DE LABORATÓRIO DA SAÚDE', descricao: 'AUXILIARES DE LABORATÓRIO DA SAÚDE' },
  { id: '841210 Auxiliares de produção de refinação de sal', descricao: 'Auxiliares de produção de refinação de sal' },
  { id: '4151 AUXILIARES DE SERVIÇOS DE DOCUMENTAÇÃO, INFORMAÇÃO E PESQUISA', descricao: 'AUXILIARES DE SERVIÇOS DE DOCUMENTAÇÃO, INFORMAÇÃO E PESQUISA' },
  { id: '354415 Avaliador de artes e antiguidades', descricao: 'Avaliador de artes e antiguidades' },
  { id: '354415 Avaliador de automóveis', descricao: 'Avaliador de automóveis' },
  { id: '354415 Avaliador de bens', descricao: 'Avaliador de bens' },
  { id: '354415 Avaliador de bens móveis', descricao: 'Avaliador de bens móveis' },
  { id: '354410 Avaliador de imóveis', descricao: 'Avaliador de imóveis' },
  { id: '354415 Avaliador de jóias', descricao: 'Avaliador de jóias' },
  { id: '354410 Avaliador de prédios', descricao: 'Avaliador de prédios' },
  { id: '352410 Avaliador de produtos dos meios de comunicação', descricao: 'Avaliador de produtos dos meios de comunicação' },
  { id: '354410 Avaliador de propriedade', descricao: 'Avaliador de propriedade' },
  { id: '224105 Avaliador físico', descricao: 'Avaliador físico' },
  { id: '354415 Avaliador judicial', descricao: 'Avaliador judicial' },
  { id: '351410 Avaliador judicial (escrivão)', descricao: 'Avaliador judicial (escrivão)' },
  { id: '141410 Aviador (comércio varejista)', descricao: 'Aviador (comércio varejista)' },
  { id: '215305 Aviador civil', descricao: 'Aviador civil' },
  { id: '613305 Avícola - empregador', descricao: 'Avícola - empregador' },
  { id: '613305 Avicultor', descricao: 'Avicultor' },
  { id: '623310 Avicultor de postura - exclusive conta própria e empregador', descricao: 'Avicultor de postura - exclusive conta própria e empregador' },
  { id: '613305 Avicultor - empregador', descricao: 'Avicultor - empregador' },
  { id: '623310 Avicultor - exclusive conta própria na avicultura de postura', descricao: 'Avicultor - exclusive conta própria na avicultura de postura' },
  { id: '613305 Avicultor avozeiro', descricao: 'Avicultor avozeiro' },
  { id: '613305 Avicultor comercial', descricao: 'Avicultor comercial' },
  { id: '623305 Avicultor de corte - exclusive conta própria e empregador', descricao: 'Avicultor de corte - exclusive conta própria e empregador' },
  { id: '613305 Avicultor matrizeiro', descricao: 'Avicultor matrizeiro' },
  { id: '263105 Axogum', descricao: 'Axogum' },
  { id: '716510 Azulejador', descricao: 'Azulejador' },
  { id: '716510 Azulejista', descricao: 'Azulejista' },
  { id: '516205 Babá', descricao: 'Babá' },
  { id: '263105 Babá de umbanda', descricao: 'Babá de umbanda' },
  { id: '263105 Babakekerê', descricao: 'Babakekerê' },
  { id: '263105 Babalawô', descricao: 'Babalawô' },
  { id: '263105 Babalorixá', descricao: 'Babalorixá' },
  { id: '263105 Babalossain', descricao: 'Babalossain' },
  { id: '263105 Babaojé', descricao: 'Babaojé' },
  { id: '516205 Baby-sitter', descricao: 'Baby-sitter' },
  { id: '141410 Bacalhoeiro', descricao: 'Bacalhoeiro' },
  { id: '203015 Bacteriologista', descricao: 'Bacteriologista' },
  { id: '622215 Bagaceiro de rami', descricao: 'Bagaceiro de rami' },
  { id: '622210 Bagaceiro de sisal', descricao: 'Bagaceiro de sisal' },
  { id: '632325 Baganeiro - na extração da carnaúba', descricao: 'Baganeiro - na extração da carnaúba' },
  { id: '783220 Bagrinho (estiva)', descricao: 'Bagrinho (estiva)' },
  { id: '524315 Baiana de acarajé', descricao: 'Baiana de acarajé' },
  { id: '262810 Bailarino (exceto danças populares)', descricao: 'Bailarino (exceto danças populares)' },
  { id: '262815 Bailarino coreógrafo', descricao: 'Bailarino coreógrafo' },
  { id: '262810 Bailarino criador', descricao: 'Bailarino criador' },
  { id: '376105 Bailarino de danças folclóricas', descricao: 'Bailarino de danças folclóricas' },
  { id: '262810 Bailarino intérprete', descricao: 'Bailarino intérprete' },
  { id: '376110 Bailarinos de danças parafolclóricas', descricao: 'Bailarinos de danças parafolclóricas' },
  { id: '376110 Bailarinos étnicos', descricao: 'Bailarinos étnicos' },
  { id: '376110 Bailarinos populares', descricao: 'Bailarinos populares' },
  { id: '768325 Bainheiro - em selaria', descricao: 'Bainheiro - em selaria' },
  { id: '141410 Baiuqueiro', descricao: 'Baiuqueiro' },
  { id: '776405 Balaieiro', descricao: 'Balaieiro' },
  { id: '992110 Balanceador', descricao: 'Balanceador' },
  { id: '725010 Balanceador de motores', descricao: 'Balanceador de motores' },
  { id: '414115 Balanceiro', descricao: 'Balanceiro' },
  { id: '715415 Balanceiro de concreto', descricao: 'Balanceiro de concreto' },
  { id: '632205 Balateiro', descricao: 'Balateiro' },
  { id: '632205 Balateiro na extração de látex', descricao: 'Balateiro na extração de látex' },
  { id: '521140 Balconista', descricao: 'Balconista' },
  { id: '848510 Balconista de açougue', descricao: 'Balconista de açougue' },
  { id: '513420 Balconista de bar', descricao: 'Balconista de bar' },
  { id: '410225 Balconista de crediário', descricao: 'Balconista de crediário' },
  { id: '513435 Balconista de lanchonete', descricao: 'Balconista de lanchonete' },
  { id: '524305 Baleiro (ambulante)', descricao: 'Baleiro (ambulante)' },
  { id: '141410 Baleiro (exclusive no comércio ambulante)', descricao: 'Baleiro (exclusive no comércio ambulante)' },
  { id: '841810 Baleiro (fabricação)', descricao: 'Baleiro (fabricação)' },
  { id: '632115 Balizador em inventário florestal', descricao: 'Balizador em inventário florestal' },
  { id: '811705 Bamburista', descricao: 'Bamburista' },
  { id: '612505 Bananicultor', descricao: 'Bananicultor' },
  { id: '141205 Banguezeiro - empregador', descricao: 'Banguezeiro - empregador' },
  { id: '141205 Banguezista - empregador', descricao: 'Banguezista - empregador' },
  { id: '519315 Banhista de animais domésticos', descricao: 'Banhista de animais domésticos' },
  { id: '761354 Barbanteiro', descricao: 'Barbanteiro' },
  { id: '516105 Barbeiro', descricao: 'Barbeiro' },
  { id: '622605 Barcaceiro - na cultura de cacau', descricao: 'Barcaceiro - na cultura de cacau' },
  { id: '513440 Barista', descricao: 'Barista' },
  { id: '513420 Barman', descricao: 'Barman' },
  { id: '141410 Barraconista', descricao: 'Barraconista' },
  { id: '711405 Barranqueiro - no garimpo', descricao: 'Barranqueiro - no garimpo' },
  { id: '141410 Barraqueiro (barracão comercial)', descricao: 'Barraqueiro (barracão comercial)' },
  { id: '828110 Barreiro', descricao: 'Barreiro' },
  { id: '828110 Barrerista', descricao: 'Barrerista' },
  { id: '612310 Bataticultor', descricao: 'Bataticultor' },
  { id: '751105 Bate-folha a máquina', descricao: 'Bate-folha a máquina' },
  { id: '711405 Bateador', descricao: 'Bateador' },
  { id: '828110 Batedor - em olaria', descricao: 'Batedor - em olaria' },
  { id: '632325 Batedor de palha de carnaúba', descricao: 'Batedor de palha de carnaúba' },
  { id: '623110 Batedor de pasto', descricao: 'Batedor de pasto' },
  { id: '632365 Batedor de piaçava', descricao: 'Batedor de piaçava' },
  { id: '622215 Batedor de rami', descricao: 'Batedor de rami' },
  { id: '622210 Batedor de sisal - na cultura', descricao: 'Batedor de sisal - na cultura' },
  { id: '828110 Batedor de tijolos - na fabricação', descricao: 'Batedor de tijolos - na fabricação' },
  { id: '711405 Bateeiro', descricao: 'Bateeiro' },
  { id: '141410 Bauleiro', descricao: 'Bauleiro' },
  { id: '141410 Bazareiro', descricao: 'Bazareiro' },
  { id: '334110 Bedel', descricao: 'Bedel' },
  { id: '141410 Beiradeiro', descricao: 'Beiradeiro' },
  { id: '141410 Belchior', descricao: 'Belchior' },
  { id: '752230 Beneficiador de cristal', descricao: 'Beneficiador de cristal' },
  { id: '632365 Beneficiador de piaçava', descricao: 'Beneficiador de piaçava' },
  { id: '841210 Beneficiador de sal', descricao: 'Beneficiador de sal' },
  { id: '261205 Bibliógrafo', descricao: 'Bibliógrafo' },
  { id: '141410 Bibliopola', descricao: 'Bibliopola' },
  { id: '261205 Bibliotecário', descricao: 'Bibliotecário' },
  { id: '261205 Biblioteconomista', descricao: 'Biblioteconomista' },
  { id: '519105 Bikeboy', descricao: 'Bikeboy' },
  { id: '263105 Bikkhu', descricao: 'Bikkhu' },
  { id: '263110 Bikku - bikkhuni', descricao: 'Bikku - bikkhuni' },
  { id: '263105 Bikkuni', descricao: 'Bikkuni' },
  { id: '511220 Bilheteiro (estações de metrô, ferroviárias e assemelhadas)', descricao: 'Bilheteiro (estações de metrô, ferroviárias e assemelhadas)' },
  { id: '421120 Bilheteiro de agência', descricao: 'Bilheteiro de agência' },
  { id: '421115 Bilheteiro de cinema', descricao: 'Bilheteiro de cinema' },
  { id: '421115 Bilheteiro de circo', descricao: 'Bilheteiro de circo' },
  { id: '421115 Bilheteiro de parques de diversão', descricao: 'Bilheteiro de parques de diversão' },
  { id: '421115 Bilheteiro de teatro', descricao: 'Bilheteiro de teatro' },
  { id: '421110 Bilheteiro de transportes coletivos', descricao: 'Bilheteiro de transportes coletivos' },
  { id: '421115 Bilheteiro no serviço de diversões', descricao: 'Bilheteiro no serviço de diversões' },
  { id: '201105 Bioengenheiro', descricao: 'Bioengenheiro' },
  { id: '211210 Bioestatístico', descricao: 'Bioestatístico' },
  { id: '261520 Biógrafo', descricao: 'Biógrafo' },
  { id: '221105 Biologista', descricao: 'Biologista' },
  { id: '221105 Biólogo', descricao: 'Biólogo' },
  { id: '2211 BIÓLOGOS E AFINS', descricao: 'BIÓLOGOS E AFINS' },
  { id: '221205 Biomédico', descricao: 'Biomédico' },
  { id: '2212 BIOMÉDICOS', descricao: 'BIOMÉDICOS' },
  { id: '201110 Biotecnologista', descricao: 'Biotecnologista' },
  { id: '752230 Biselador de cristais', descricao: 'Biselador de cristais' },
  { id: '263105 Bispo', descricao: 'Bispo' },
  { id: '263105 Bispo auxiliar', descricao: 'Bispo auxiliar' },
  { id: '263105 Bispo coadjutor', descricao: 'Bispo coadjutor' },
  { id: '263105 Bispo emérito', descricao: 'Bispo emérito' },
  { id: '711120 Blaster', descricao: 'Blaster' },
  { id: '142610 Blender (cigarros)', descricao: 'Blender (cigarros)' },
  { id: '842115 Blender de fumo', descricao: 'Blender de fumo' },
  { id: '783230 Bloqueiro (trabalhador portuário)', descricao: 'Bloqueiro (trabalhador portuário)' },
  { id: '783230 Bloquista (trabalhador portuário)', descricao: 'Bloquista (trabalhador portuário)' },
  { id: '731170 Bobinador - eletricista', descricao: 'Bobinador - eletricista' },
  { id: '731165 Bobinador eletricista, à mão', descricao: 'Bobinador eletricista, à mão' },
  { id: '731170 Bobinador eletricista, à máquina', descricao: 'Bobinador eletricista, à máquina' },
  { id: '141410 Bodegueiro', descricao: 'Bodegueiro' },
  { id: '631405 Bodegueiro (gelador industrial)', descricao: 'Bodegueiro (gelador industrial)' },
  { id: '631410 Bodegueiro (gelador profissional)', descricao: 'Bodegueiro (gelador profissional)' },
  { id: '622020 Bóia-fria', descricao: 'Bóia-fria' },
  { id: '621005 Bóia-fria - na agropecuária', descricao: 'Bóia-fria - na agropecuária' },
  { id: '782815 Boiadeiro', descricao: 'Boiadeiro' },
  { id: '141405 Boiadeiro (comércio de gado)', descricao: 'Boiadeiro (comércio de gado)' },
  { id: '141405 Boiadeiro - no comércio de gado - empregador', descricao: 'Boiadeiro - no comércio de gado - empregador' },
  { id: '141405 Boieiro', descricao: 'Boieiro' },
  { id: '263115 Bokonô', descricao: 'Bokonô' },
  { id: '841810 Bolacheiro', descricao: 'Bolacheiro' },
  { id: '768315 Boleiro (fabricação de bolas de couro)', descricao: 'Boleiro (fabricação de bolas de couro)' },
  { id: '768305 Bolseiro (bolsas de couro)', descricao: 'Bolseiro (bolsas de couro)' },
  { id: '811510 Bombeador (refinação de petróleo)', descricao: 'Bombeador (refinação de petróleo)' },
  { id: '517110 Bombeiro civil', descricao: 'Bombeiro civil' },
  { id: '517105 Bombeiro de aeródromo', descricao: 'Bombeiro de aeródromo' },
  { id: '517105 Bombeiro de aeroporto', descricao: 'Bombeiro de aeroporto' },
  { id: '517110 Bombeiro de empresas particulares', descricao: 'Bombeiro de empresas particulares' },
  { id: '517110 Bombeiro de estabelecimentos comerciais', descricao: 'Bombeiro de estabelecimentos comerciais' },
  { id: '517110 Bombeiro de estabelecimentos industriais', descricao: 'Bombeiro de estabelecimentos industriais' },
  { id: '521135 Bombeiro de posto de gasolina', descricao: 'Bombeiro de posto de gasolina' },
  { id: '517110 Bombeiro de segurança do trabalho', descricao: 'Bombeiro de segurança do trabalho' },
  { id: '724115 Bombeiro eletricista', descricao: 'Bombeiro eletricista' },
  { id: '724110 Bombeiro hidráulico', descricao: 'Bombeiro hidráulico' },
  { id: '515135 Bombeiro resgatista', descricao: 'Bombeiro resgatista' },
  { id: '515135 Bombeiro socorrista', descricao: 'Bombeiro socorrista' },
  { id: '5171 BOMBEIROS, SALVA-VIDAS E AFINS', descricao: 'BOMBEIROS, SALVA-VIDAS E AFINS' },
  { id: '715410 Bombista', descricao: 'Bombista' },
  { id: '848310 Bomboneiro', descricao: 'Bomboneiro' },
  { id: '765015 Boneleiro', descricao: 'Boneleiro' },
  { id: '376250 Bonequeiro', descricao: 'Bonequeiro' },
  { id: '768205 Bordadeira', descricao: 'Bordadeira' },
  { id: '768205 Bordador, a mão', descricao: 'Bordador, a mão' },
  { id: '763310 Bordador, à máquina', descricao: 'Bordador, à máquina' },
  { id: '992115 Borracheiro', descricao: 'Borracheiro' },
  { id: '632205 Borracheiro - na extração de látex', descricao: 'Borracheiro - na extração de látex' },
  { id: '992115 Borracheiro auxiliar', descricao: 'Borracheiro auxiliar' },
  { id: '203025 Botânico', descricao: 'Botânico' },
  { id: '141410 Botequineiro', descricao: 'Botequineiro' },
  { id: '613110 Bovinocultor - empregador', descricao: 'Bovinocultor - empregador' },
  { id: '377145 Boxeador', descricao: 'Boxeador' },
  { id: '377145 Boxeur', descricao: 'Boxeur' },
  { id: '632205 Brabo', descricao: 'Brabo' },
  { id: '831110 Branqueador de pasta de celulose para fabricação de papel', descricao: 'Branqueador de pasta de celulose para fabricação de papel' },
  { id: '724305 Brasador', descricao: 'Brasador' },
  { id: '311725 Brazidor de curtume', descricao: 'Brazidor de curtume' },
  { id: '141410 Bricabraquista', descricao: 'Bricabraquista' },
  { id: '141410 Bricabraquista - empregador', descricao: 'Bricabraquista - empregador' },
  { id: '10105 Brigadeiro', descricao: 'Brigadeiro' },
  { id: '517120 Brigadista florestal', descricao: 'Brigadista florestal' },
  { id: '712120 Britador - na extração de pedras', descricao: 'Britador - na extração de pedras' },
  { id: '723215 Bronzeador de metais', descricao: 'Bronzeador de metais' },
  { id: '721210 Broqueador de cilindros', descricao: 'Broqueador de cilindros' },
  { id: '716610 Broxador-caiador', descricao: 'Broxador-caiador' },
  { id: '782810 Bruaqueiro', descricao: 'Bruaqueiro' },
  { id: '721210 Brunidor de cilindros', descricao: 'Brunidor de cilindros' },
  { id: '613120 Bubalinocultor - corte', descricao: 'Bubalinocultor - corte' },
  { id: '613125 Bubalinocultor - leite', descricao: 'Bubalinocultor - leite' },
  { id: '992225 Buerista na conservação de vias permanentes', descricao: 'Buerista na conservação de vias permanentes' },
  { id: '763005 Buteiro', descricao: 'Buteiro' },
  { id: '613110 Cabanheiro - corte', descricao: 'Cabanheiro - corte' },
  { id: '613115 Cabanheiro - leite', descricao: 'Cabanheiro - leite' },
  { id: '776405 Cabazeiro', descricao: 'Cabazeiro' },
  { id: '622020 Cabeça-de-campo', descricao: 'Cabeça-de-campo' },
  { id: '848515 Cabeceiro em matadouro', descricao: 'Cabeceiro em matadouro' },
  { id: '516110 Cabeleireiro', descricao: 'Cabeleireiro' },
  { id: '516110 Cabeleireiro afro', descricao: 'Cabeleireiro afro' },
  { id: '516110 Cabeleireiro escovista', descricao: 'Cabeleireiro escovista' },
  { id: '516110 Cabeleireiro feminino', descricao: 'Cabeleireiro feminino' },
  { id: '516110 Cabeleireiro masculino', descricao: 'Cabeleireiro masculino' },
  { id: '516110 Cabeleireiro penteador', descricao: 'Cabeleireiro penteador' },
  { id: '516110 Cabeleireiro tinturista', descricao: 'Cabeleireiro tinturista' },
  { id: '516110 Cabeleireiro unissex', descricao: 'Cabeleireiro unissex' },
  { id: '723305 Cabineiro', descricao: 'Cabineiro' },
  { id: '514105 Cabineiro de elevador', descricao: 'Cabineiro de elevador' },
  { id: '991310 Cabineiro de veículos', descricao: 'Cabineiro de veículos' },
  { id: '732110 Cabista', descricao: 'Cabista' },
  { id: '722405 Cableador', descricao: 'Cableador' },
  { id: '724610 Cableador (cabos de aço)', descricao: 'Cableador (cabos de aço)' },
  { id: '31205 Cabo bombeiro militar', descricao: 'Cabo bombeiro militar' },
  { id: '10305 Cabo da aeronáutica', descricao: 'Cabo da aeronáutica' },
  { id: '10315 Cabo da marinha', descricao: 'Cabo da marinha' },
  { id: '21205 Cabo da polícia militar', descricao: 'Cabo da polícia militar' },
  { id: '711120 Cabo de fogo', descricao: 'Cabo de fogo' },
  { id: '10310 Cabo do exército', descricao: 'Cabo do exército' },
  { id: '212 CABOS E SOLDADOS DA POLÍCIA MILITAR', descricao: 'CABOS E SOLDADOS DA POLÍCIA MILITAR' },
  { id: '312 CABOS E SOLDADOS DO CORPO DE BOMBEIROS MILITAR', descricao: 'CABOS E SOLDADOS DO CORPO DE BOMBEIROS MILITAR' },
  { id: '711130 Caboucador (minas)', descricao: 'Caboucador (minas)' },
  { id: '711130 Cabouqueiro - na extração de pedras', descricao: 'Cabouqueiro - na extração de pedras' },
  { id: '768325 Cabresteador', descricao: 'Cabresteador' },
  { id: '715205 Cabuqueiro', descricao: 'Cabuqueiro' },
  { id: '782510 Caçambeiro', descricao: 'Caçambeiro' },
  { id: '612610 Cacauicultor - empregador', descricao: 'Cacauicultor - empregador' },
  { id: '622605 Cacauicultor - exclusive conta própria e empregador', descricao: 'Cacauicultor - exclusive conta própria e empregador' },
  { id: '141410 Cacaulista', descricao: 'Cacaulista' },
  { id: '513410 Cachacier', descricao: 'Cachacier' },
  { id: '513410 Cachacista', descricao: 'Cachacista' },
  { id: '613010 Cachorreiro', descricao: 'Cachorreiro' },
  { id: '783220 Cacimbeiro (estivador)', descricao: 'Cacimbeiro (estivador)' },
  { id: '717015 Cacimbeiro (poço)', descricao: 'Cacimbeiro (poço)' },
  { id: '113005 Cacique', descricao: 'Cacique' },
  { id: '263115 Cádi', descricao: 'Cádi' },
  { id: '318105 Cadista (desenhista técnico de arquitetura)', descricao: 'Cadista (desenhista técnico de arquitetura)' },
  { id: '612605 Cafeicultor', descricao: 'Cafeicultor' },
  { id: '612605 Cafeicultor - empregador', descricao: 'Cafeicultor - empregador' },
  { id: '622610 Cafeicultor - exclusive conta própria e empregador', descricao: 'Cafeicultor - exclusive conta própria e empregador' },
  { id: '513435 Cafeteiro', descricao: 'Cafeteiro' },
  { id: '141410 Caga-sebista', descricao: 'Caga-sebista' },
  { id: '716610 Caiador', descricao: 'Caiador' },
  { id: '716610 Caieiro (pintor de paredes)', descricao: 'Caieiro (pintor de paredes)' },
  { id: '712105 Caieiro - na fabricação de cal', descricao: 'Caieiro - na fabricação de cal' },
  { id: '421125 Caixa (supermercado)', descricao: 'Caixa (supermercado)' },
  { id: '413210 Caixa de banco', descricao: 'Caixa de banco' },
  { id: '421125 Caixa de bar, lanchonetes e restaurantes', descricao: 'Caixa de bar, lanchonetes e restaurantes' },
  { id: '421125 Caixa de loja', descricao: 'Caixa de loja' },
  { id: '421210 Caixa de turfe', descricao: 'Caixa de turfe' },
  { id: '421205 Caixa lotérico', descricao: 'Caixa lotérico' },
  { id: '421125 Caixa no comércio', descricao: 'Caixa no comércio' },
  { id: '421125 Caixa no serviço de alimentação', descricao: 'Caixa no serviço de alimentação' },
  { id: '4211 CAIXAS E BILHETEIROS (EXCETO CAIXA DE BANCO)', descricao: 'CAIXAS E BILHETEIROS (EXCETO CAIXA DE BANCO)' },
  { id: '848310 Cake designer', descricao: 'Cake designer' },
  { id: '716605 Calafate', descricao: 'Calafate' },
  { id: '716605 Calafetador', descricao: 'Calafetador' },
  { id: '811745 Calandreiro de plástico', descricao: 'Calandreiro de plástico' },
  { id: '811710 Calandrista de borracha', descricao: 'Calandrista de borracha' },
  { id: '832105 Calandrista de papel', descricao: 'Calandrista de papel' },
  { id: '764210 Calceirista', descricao: 'Calceirista' },
  { id: '715205 Calceteiro', descricao: 'Calceteiro' },
  { id: '716515 Calceteiro (pastilheiro)', descricao: 'Calceteiro (pastilheiro)' },
  { id: '811205 Calcinador', descricao: 'Calcinador' },
  { id: '413105 Calculista de folha de pagamento', descricao: 'Calculista de folha de pagamento' },
  { id: '391125 Calculista de produção', descricao: 'Calculista de produção' },
  { id: '391105 Calculista de tempos', descricao: 'Calculista de tempos' },
  { id: '724405 Caldeireiro (chapas de cobre)', descricao: 'Caldeireiro (chapas de cobre)' },
  { id: '724410 Caldeireiro (chapas de ferro e aço)', descricao: 'Caldeireiro (chapas de ferro e aço)' },
  { id: '724410 Caldeireiro de manutenção', descricao: 'Caldeireiro de manutenção' },
  { id: '724425 Caldeireiro em arsenal', descricao: 'Caldeireiro em arsenal' },
  { id: '724425 Caldeireiro em estaleiro', descricao: 'Caldeireiro em estaleiro' },
  { id: '724410 Caldeireiro instalador', descricao: 'Caldeireiro instalador' },
  { id: '724410 Caldeireiro montador', descricao: 'Caldeireiro montador' },
  { id: '724410 Caldeireiro serralheiro', descricao: 'Caldeireiro serralheiro' },
  { id: '862120 Caldeirista', descricao: 'Caldeirista' },
  { id: '632310 Caldereiro - na exploração de babaçu', descricao: 'Caldereiro - na exploração de babaçu' },
  { id: '752115 Calibrador de tubos de vidro', descricao: 'Calibrador de tubos de vidro' },
  { id: '752115 Calibrador na fabricação de ampolas', descricao: 'Calibrador na fabricação de ampolas' },
  { id: '516140 Calista', descricao: 'Calista' },
  { id: '621005 Camarada - na agropecuária', descricao: 'Camarada - na agropecuária' },
  { id: '513315 Camareira de hotel', descricao: 'Camareira de hotel' },
  { id: '513315 Camareiro de hotel', descricao: 'Camareiro de hotel' },
  { id: '513320 Camareiro de embarcações', descricao: 'Camareiro de embarcações' },
  { id: '513305 Camareiro de teatro', descricao: 'Camareiro de teatro' },
  { id: '513310 Camareiro de televisão', descricao: 'Camareiro de televisão' },
  { id: '513320 Camareiro no transporte marítimo e fluvial', descricao: 'Camareiro no transporte marítimo e fluvial' },
  { id: '513305 Camareiro(a) de teatro', descricao: 'Camareiro(a) de teatro' },
  { id: '513310 Camareiro(a) de televisão', descricao: 'Camareiro(a) de televisão' },
  { id: '5133 CAMAREIROS, ROUPEIROS E AFINS', descricao: 'CAMAREIROS, ROUPEIROS E AFINS' },
  { id: '631305 Camaroneiro', descricao: 'Camaroneiro' },
  { id: '782810 Cambiteiro', descricao: 'Cambiteiro' },
  { id: '632325 Cambiteiro - na extração da carnaúba', descricao: 'Cambiteiro - na extração da carnaúba' },
  { id: '263105 Cambono', descricao: 'Cambono' },
  { id: '524305 Camelô (comércio ambulante)', descricao: 'Camelô (comércio ambulante)' },
  { id: '524215 Camelô (permissionário em ponto fixo)', descricao: 'Camelô (permissionário em ponto fixo)' },
  { id: '372115 Cameraman (cinema)', descricao: 'Cameraman (cinema)' },
  { id: '782505 Caminhoneiro', descricao: 'Caminhoneiro' },
  { id: '782505 Caminhoneiro autônomo (rotas regionais e internacionais)', descricao: 'Caminhoneiro autônomo (rotas regionais e internacionais)' },
  { id: '782505 Caminhoneiro caçambeiro', descricao: 'Caminhoneiro caçambeiro' },
  { id: '782505 Caminhoneiro carreteiro', descricao: 'Caminhoneiro carreteiro' },
  { id: '782505 Caminhoneiro carreteiro (transporte animal)', descricao: 'Caminhoneiro carreteiro (transporte animal)' },
  { id: '782505 Caminhoneiro de basculante', descricao: 'Caminhoneiro de basculante' },
  { id: '782505 Caminhoneiro de caminhão basculante', descricao: 'Caminhoneiro de caminhão basculante' },
  { id: '782505 Caminhoneiro de caminhão leve', descricao: 'Caminhoneiro de caminhão leve' },
  { id: '782505 Caminhoneiro de caminhão pipa', descricao: 'Caminhoneiro de caminhão pipa' },
  { id: '782505 Caminhoneiro de caminhão-betoneira', descricao: 'Caminhoneiro de caminhão-betoneira' },
  { id: '782505 Caminhoneiro de caminhão-tanque', descricao: 'Caminhoneiro de caminhão-tanque' },
  { id: '782505 Caminhoneiro gaioleiro (gado)', descricao: 'Caminhoneiro gaioleiro (gado)' },
  { id: '782505 Caminhoneiro operador de caminhão-betoneira', descricao: 'Caminhoneiro operador de caminhão-betoneira' },
  { id: '623105 Campeiro (asininos e muares)', descricao: 'Campeiro (asininos e muares)' },
  { id: '623110 Campeiro (bovinos de corte)', descricao: 'Campeiro (bovinos de corte)' },
  { id: '623120 Campeiro (bubalinos)', descricao: 'Campeiro (bubalinos)' },
  { id: '623125 Campeiro (eqüinos)', descricao: 'Campeiro (eqüinos)' },
  { id: '621005 Campeiro - na agropecuária', descricao: 'Campeiro - na agropecuária' },
  { id: '623015 Campeiro - na pecuária', descricao: 'Campeiro - na pecuária' },
  { id: '621005 Camponês na agropecuária', descricao: 'Camponês na agropecuária' },
  { id: '223212 Canalista', descricao: 'Canalista' },
  { id: '612110 Canavieiro', descricao: 'Canavieiro' },
  { id: '225121 Cancerologista clínico', descricao: 'Cancerologista clínico' },
  { id: '782810 Cangalheiro', descricao: 'Cangalheiro' },
  { id: '768325 Cangalheiro - na fabricação', descricao: 'Cangalheiro - na fabricação' },
  { id: '848520 Cangoteiro em matadouro', descricao: 'Cangoteiro em matadouro' },
  { id: '782805 Cangueiro - carro de boi', descricao: 'Cangueiro - carro de boi' },
  { id: '613010 Canicultor', descricao: 'Canicultor' },
  { id: '711110 Canteiro', descricao: 'Canteiro' },
  { id: '141410 Cantineiro (comércio varejista)', descricao: 'Cantineiro (comércio varejista)' },
  { id: '513435 Cantineiro (escola)', descricao: 'Cantineiro (escola)' },
  { id: '841720 Cantineiro (fabricação de vidros)', descricao: 'Cantineiro (fabricação de vidros)' },
  { id: '623015 Capataz', descricao: 'Capataz' },
  { id: '620115 Capataz (criação de gado bovino)', descricao: 'Capataz (criação de gado bovino)' },
  { id: '620105 Capataz da exploração agrícola', descricao: 'Capataz da exploração agrícola' },
  { id: '620110 Capataz da exploração agropecuária', descricao: 'Capataz da exploração agropecuária' },
  { id: '620115 Capataz de currais bovinos', descricao: 'Capataz de currais bovinos' },
  { id: '620105 Capataz de horticultura', descricao: 'Capataz de horticultura' },
  { id: '620115 Capataz na exploração de pecuária', descricao: 'Capataz na exploração de pecuária' },
  { id: '620105 Capataz na fruticultura e na floricultura', descricao: 'Capataz na fruticultura e na floricultura' },
  { id: '620105 Capataz na lavoura (exceto na floricultura, fruticultura e horticultura)', descricao: 'Capataz na lavoura (exceto na floricultura, fruticultura e horticultura)' },
  { id: '620115 Capataz na pecuária', descricao: 'Capataz na pecuária' },
  { id: '620110 Capataz rural', descricao: 'Capataz rural' },
  { id: '842230 Capeador de charutos', descricao: 'Capeador de charutos' },
  { id: '263105 Capelão', descricao: 'Capelão' },
  { id: '622020 Capinador - na cultura', descricao: 'Capinador - na cultura' },
  { id: '622020 Capinador - na lavoura', descricao: 'Capinador - na lavoura' },
  { id: '622020 Capineiro - na cultura', descricao: 'Capineiro - na cultura' },
  { id: '622020 Capinheiro - na cultura', descricao: 'Capinheiro - na cultura' },
  { id: '622020 Capinzeiro - na cultura', descricao: 'Capinzeiro - na cultura' },
  { id: '202 CAPITÃES DA POLÍCIA MILITAR', descricao: 'CAPITÃES DA POLÍCIA MILITAR' },
  { id: '30205 Capitão bombeiro militar', descricao: 'Capitão bombeiro militar' },
  { id: '10205 Capitão da aeronáutica', descricao: 'Capitão da aeronáutica' },
  { id: '20205 Capitão da polícia militar', descricao: 'Capitão da polícia militar' },
  { id: '215140 Capitão de cabotagem', descricao: 'Capitão de cabotagem' },
  { id: '215115 Capitão de cabotagem (comandante)', descricao: 'Capitão de cabotagem (comandante)' },
  { id: '215125 Capitão de cabotagem (imediato)', descricao: 'Capitão de cabotagem (imediato)' },
  { id: '215140 Capitão de longo curso', descricao: 'Capitão de longo curso' },
  { id: '215115 Capitão de longo curso (comandante)', descricao: 'Capitão de longo curso (comandante)' },
  { id: '215125 Capitão de longo curso (imediato)', descricao: 'Capitão de longo curso (imediato)' },
  { id: '215110 Capitão de manobra da marinha mercante', descricao: 'Capitão de manobra da marinha mercante' },
  { id: '341210 Capitão de pequena cabotagem', descricao: 'Capitão de pequena cabotagem' },
  { id: '30205 Capitão do corpo de bombeiros militar', descricao: 'Capitão do corpo de bombeiros militar' },
  { id: '10210 Capitão do exército', descricao: 'Capitão do exército' },
  { id: '517405 Capitão porteiro', descricao: 'Capitão porteiro' },
  { id: '10215 Capitão-de-corveta', descricao: 'Capitão-de-corveta' },
  { id: '10215 Capitão-de-fragata', descricao: 'Capitão-de-fragata' },
  { id: '10215 Capitão-de-mar-e-guerra', descricao: 'Capitão-de-mar-e-guerra' },
  { id: '10215 Capitão-tenente da marinha', descricao: 'Capitão-tenente da marinha' },
  { id: '765240 Capoteiro', descricao: 'Capoteiro' },
  { id: '991310 Capoteiro', descricao: 'Capoteiro' },
  { id: '613205 Caprinocultor', descricao: 'Caprinocultor' },
  { id: '613205 Caprinocultor - empregador', descricao: 'Caprinocultor - empregador' },
  { id: '623205 Caprinocultor - exclusive conta própria', descricao: 'Caprinocultor - exclusive conta própria' },
  { id: '613205 Caprinocultor cabanheiro', descricao: 'Caprinocultor cabanheiro' },
  { id: '613205 Caprinocultor de corte', descricao: 'Caprinocultor de corte' },
  { id: '613205 Caprinocultor de leite', descricao: 'Caprinocultor de leite' },
  { id: '613205 Caprinocultor matrizeiro', descricao: 'Caprinocultor matrizeiro' },
  { id: '411055 Captador de recursos', descricao: 'Captador de recursos' },
  { id: '3721 CAPTADORES DE IMAGENS EM MOVIMENTO', descricao: 'CAPTADORES DE IMAGENS EM MOVIMENTO' },
  { id: '848310 Carameleiro', descricao: 'Carameleiro' },
  { id: '631005 Caranguejeiro', descricao: 'Caranguejeiro' },
  { id: '715505 Carapina', descricao: 'Carapina' },
  { id: '632610 Carbonizador', descricao: 'Carbonizador' },
  { id: '631305 Carcinicultor', descricao: 'Carcinicultor' },
  { id: '203010 Carcinologista', descricao: 'Carcinologista' },
  { id: '263105 Cardeal', descricao: 'Cardeal' },
  { id: '225120 Cardiologista', descricao: 'Cardiologista' },
  { id: '761220 Cardista', descricao: 'Cardista' },
  { id: '782810 Cargueiro', descricao: 'Cargueiro' },
  { id: '262405 Caricaturista', descricao: 'Caricaturista' },
  { id: '784105 Carimbador, a mão', descricao: 'Carimbador, a mão' },
  { id: '784115 Carimbador, a máquina', descricao: 'Carimbador, a máquina' },
  { id: '848525 Carneador em matadouro e açougue', descricao: 'Carneador em matadouro e açougue' },
  { id: '141410 Carniceiro', descricao: 'Carniceiro' },
  { id: '833105 Caroneiro - em cartonagem', descricao: 'Caroneiro - em cartonagem' },
  { id: '622020 Carpidor - na cultura', descricao: 'Carpidor - na cultura' },
  { id: '715505 Carpinteiro', descricao: 'Carpinteiro' },
  { id: '715515 Carpinteiro (cenários)', descricao: 'Carpinteiro (cenários)' },
  { id: '715510 Carpinteiro (esquadrias)', descricao: 'Carpinteiro (esquadrias)' },
  { id: '715520 Carpinteiro (mineração)', descricao: 'Carpinteiro (mineração)' },
  { id: '715525 Carpinteiro (obras)', descricao: 'Carpinteiro (obras)' },
  { id: '715530 Carpinteiro (telhados)', descricao: 'Carpinteiro (telhados)' },
  { id: '777110 Carpinteiro (tripulante de embarcações)', descricao: 'Carpinteiro (tripulante de embarcações)' },
  { id: '715505 Carpinteiro auxiliar', descricao: 'Carpinteiro auxiliar' },
  { id: '777205 Carpinteiro de carretas', descricao: 'Carpinteiro de carretas' },
  { id: '777210 Carpinteiro de carrocerias', descricao: 'Carpinteiro de carrocerias' },
  { id: '715505 Carpinteiro de estruturas', descricao: 'Carpinteiro de estruturas' },
  { id: '715515 Carpinteiro de estúdio', descricao: 'Carpinteiro de estúdio' },
  { id: '715535 Carpinteiro de fôrmas (concreto)', descricao: 'Carpinteiro de fôrmas (concreto)' },
  { id: '715535 Carpinteiro de fôrmas para concreto', descricao: 'Carpinteiro de fôrmas para concreto' },
  { id: '715505 Carpinteiro de manutenção', descricao: 'Carpinteiro de manutenção' },
  { id: '715525 Carpinteiro de obras', descricao: 'Carpinteiro de obras' },
  { id: '715540 Carpinteiro de obras civis de arte (pontes, túneis, barragens)', descricao: 'Carpinteiro de obras civis de arte (pontes, túneis, barragens)' },
  { id: '715540 Carpinteiro de pontes', descricao: 'Carpinteiro de pontes' },
  { id: '777105 Carpinteiro naval (construção de pequenas embarcações)', descricao: 'Carpinteiro naval (construção de pequenas embarcações)' },
  { id: '777110 Carpinteiro naval (embarcações)', descricao: 'Carpinteiro naval (embarcações)' },
  { id: '777115 Carpinteiro naval (estaleiros)', descricao: 'Carpinteiro naval (estaleiros)' },
  { id: '7772 CARPINTEIROS DE CARROCERIAS E CARRETAS', descricao: 'CARPINTEIROS DE CARROCERIAS E CARRETAS' },
  { id: '7771 CARPINTEIROS NAVAIS', descricao: 'CARPINTEIROS NAVAIS' },
  { id: '783205 Carregador (aeronaves)', descricao: 'Carregador (aeronaves)' },
  { id: '783210 Carregador (armazém)', descricao: 'Carregador (armazém)' },
  { id: '783215 Carregador (veículos de transportes terrestres)', descricao: 'Carregador (veículos de transportes terrestres)' },
  { id: '821205 Carregador de alto-forno', descricao: 'Carregador de alto-forno' },
  { id: '632305 Carregador de andiroba', descricao: 'Carregador de andiroba' },
  { id: '632315 Carregador de bacaba', descricao: 'Carregador de bacaba' },
  { id: '632320 Carregador de buriti', descricao: 'Carregador de buriti' },
  { id: '783215 Carregador de caminhão', descricao: 'Carregador de caminhão' },
  { id: '822125 Carregador de forno de segunda fusão e reaquecimento', descricao: 'Carregador de forno de segunda fusão e reaquecimento' },
  { id: '622215 Carregador de rami', descricao: 'Carregador de rami' },
  { id: '513415 Carregador de utensílios de cozinha', descricao: 'Carregador de utensílios de cozinha' },
  { id: '783215 Carregador de vagões', descricao: 'Carregador de vagões' },
  { id: '783215 Carregador e descarregador de caminhões', descricao: 'Carregador e descarregador de caminhões' },
  { id: '782810 Carreiro', descricao: 'Carreiro' },
  { id: '782505 Carreteiro (caminhoneiro de caminhão-carreta)', descricao: 'Carreteiro (caminhoneiro de caminhão-carreta)' },
  { id: '782510 Carreteiro (motorista de caminhão-carreta)', descricao: 'Carreteiro (motorista de caminhão-carreta)' },
  { id: '782510 Carreteiro (transporte de animal)', descricao: 'Carreteiro (transporte de animal)' },
  { id: '782805 Carroceiro', descricao: 'Carroceiro' },
  { id: '519905 Cartazeiro', descricao: 'Cartazeiro' },
  { id: '415205 Carteiro', descricao: 'Carteiro' },
  { id: '415205 Carteiro motorizado', descricao: 'Carteiro motorizado' },
  { id: '214810 Cartógrafo', descricao: 'Cartógrafo' },
  { id: '516805 Cartomante', descricao: 'Cartomante' },
  { id: '833105 Cartonageiro - em cartonagem', descricao: 'Cartonageiro - em cartonagem' },
  { id: '833105 Cartonageiro - nas indústrias gráficas', descricao: 'Cartonageiro - nas indústrias gráficas' },
  { id: '833205 Cartonageiro, a mão (caixas de papelão)', descricao: 'Cartonageiro, a mão (caixas de papelão)' },
  { id: '833105 Cartonageiro, a máquina', descricao: 'Cartonageiro, a máquina' },
  { id: '262405 Cartunista', descricao: 'Cartunista' },
  { id: '632605 Carvoeiro', descricao: 'Carvoeiro' },
  { id: '141410 Carvoeiro (comércio varejista)', descricao: 'Carvoeiro (comércio varejista)' },
  { id: '141410 Carvoeiro - no comércio - empregador', descricao: 'Carvoeiro - no comércio - empregador' },
  { id: '862105 Carvoeiro - no transporte ferroviário', descricao: 'Carvoeiro - no transporte ferroviário' },
  { id: '6326 CARVOEJADORES', descricao: 'CARVOEJADORES' },
  { id: '512105 Caseiro', descricao: 'Caseiro' },
  { id: '622005 Caseiro (agricultura)', descricao: 'Caseiro (agricultura)' },
  { id: '623025 Casqueador de animais', descricao: 'Casqueador de animais' },
  { id: '623025 Casqueador de bovinos', descricao: 'Casqueador de bovinos' },
  { id: '632125 Casqueador de toras', descricao: 'Casqueador de toras' },
  { id: '632410 Castanheiro', descricao: 'Castanheiro' },
  { id: '623015 Castrador', descricao: 'Castrador' },
  { id: '623015 Castrador - na pecuária', descricao: 'Castrador - na pecuária' },
  { id: '622020 Catadeira - na cultura', descricao: 'Catadeira - na cultura' },
  { id: '622020 Catador - na cultura', descricao: 'Catador - na cultura' },
  { id: '622205 Catador de algodão', descricao: 'Catador de algodão' },
  { id: '622610 Catador de café', descricao: 'Catador de café' },
  { id: '631005 Catador de caranguejos', descricao: 'Catador de caranguejos' },
  { id: '631005 Catador de caranguejos e siris', descricao: 'Catador de caranguejos e siris' },
  { id: '631005 Catador de crustáceos', descricao: 'Catador de crustáceos' },
  { id: '519205 Catador de ferro-velho', descricao: 'Catador de ferro-velho' },
  { id: '631010 Catador de mariscos', descricao: 'Catador de mariscos' },
  { id: '519205 Catador de material reciclável', descricao: 'Catador de material reciclável' },
  { id: '711405 Catador de ouro', descricao: 'Catador de ouro' },
  { id: '519205 Catador de papel e papelão', descricao: 'Catador de papel e papelão' },
  { id: '632365 Catador de piaçava', descricao: 'Catador de piaçava' },
  { id: '632415 Catador de pinhão', descricao: 'Catador de pinhão' },
  { id: '631005 Catador de siris', descricao: 'Catador de siris' },
  { id: '519205 Catador de sucata', descricao: 'Catador de sucata' },
  { id: '519205 Catador de vasilhame', descricao: 'Catador de vasilhame' },
  { id: '263105 Catequista', descricao: 'Catequista' },
  { id: '632205 Caucheiro', descricao: 'Caucheiro' },
  { id: '622020 Cavador - na cultura', descricao: 'Cavador - na cultura' },
  { id: '717015 Cavador de poço', descricao: 'Cavador de poço' },
  { id: '623125 Cavalariço', descricao: 'Cavalariço' },
  { id: '623125 Cavaleiro', descricao: 'Cavaleiro' },
  { id: '711130 Cavouqueiro - na extração de pedras', descricao: 'Cavouqueiro - na extração de pedras' },
  { id: '612310 Cebolecultor', descricao: 'Cebolecultor' },
  { id: '782510 Cegonheiro ( motorista de caminhão)', descricao: 'Cegonheiro ( motorista de caminhão)' },
  { id: '622020 Ceifador', descricao: 'Ceifador' },
  { id: '622020 Ceifador - na cultura', descricao: 'Ceifador - na cultura' },
  { id: '622020 Ceifeiro', descricao: 'Ceifeiro' },
  { id: '842225 Celofanista na fabricação de charutos', descricao: 'Celofanista na fabricação de charutos' },
  { id: '723105 Cementador de metais', descricao: 'Cementador de metais' },
  { id: '262305 Cenógrafo carnavalesco e festas populares', descricao: 'Cenógrafo carnavalesco e festas populares' },
  { id: '262310 Cenógrafo de cinema', descricao: 'Cenógrafo de cinema' },
  { id: '262315 Cenógrafo de eventos', descricao: 'Cenógrafo de eventos' },
  { id: '262320 Cenógrafo de teatro', descricao: 'Cenógrafo de teatro' },
  { id: '262325 Cenógrafo de tv', descricao: 'Cenógrafo de tv' },
  { id: '2623 CENÓGRAFOS', descricao: 'CENÓGRAFOS' },
  { id: '374205 Cenotécnico (cinema, vídeo, televisão, teatro e espetáculos)', descricao: 'Cenotécnico (cinema, vídeo, televisão, teatro e espetáculos)' },
  { id: '374210 Cenotécnico de filmagem', descricao: 'Cenotécnico de filmagem' },
  { id: '725010 Centralizador de canos', descricao: 'Centralizador de canos' },
  { id: '725010 Centralizador de fusos', descricao: 'Centralizador de fusos' },
  { id: '725010 Centralizador de rodas', descricao: 'Centralizador de rodas' },
  { id: '811305 Centrifuguista', descricao: 'Centrifuguista' },
  { id: '752305 Cerâmico', descricao: 'Cerâmico' },
  { id: '752305 Ceramista', descricao: 'Ceramista' },
  { id: '262405 Ceramista (artes visuais)', descricao: 'Ceramista (artes visuais)' },
  { id: '752325 Ceramista (confecção de moldes)', descricao: 'Ceramista (confecção de moldes)' },
  { id: '752310 Ceramista (torno de pedal e motor)', descricao: 'Ceramista (torno de pedal e motor)' },
  { id: '752315 Ceramista (torno semi-automático)', descricao: 'Ceramista (torno semi-automático)' },
  { id: '752310 Ceramista artesanal', descricao: 'Ceramista artesanal' },
  { id: '752310 Ceramista artístico', descricao: 'Ceramista artístico' },
  { id: '752330 Ceramista batedor de prensa extrusora', descricao: 'Ceramista batedor de prensa extrusora' },
  { id: '752405 Ceramista escultor (acabamento)', descricao: 'Ceramista escultor (acabamento)' },
  { id: '752430 Ceramista fileteiro', descricao: 'Ceramista fileteiro' },
  { id: '752325 Ceramista fundidor de molde', descricao: 'Ceramista fundidor de molde' },
  { id: '752320 Ceramista modelador', descricao: 'Ceramista modelador' },
  { id: '752325 Ceramista moldador', descricao: 'Ceramista moldador' },
  { id: '752305 Ceramista multifuncional', descricao: 'Ceramista multifuncional' },
  { id: '752330 Ceramista operador de prensa hidráulica', descricao: 'Ceramista operador de prensa hidráulica' },
  { id: '752330 Ceramista prensador', descricao: 'Ceramista prensador' },
  { id: '752330 Ceramista prensador (prensa a fricção)', descricao: 'Ceramista prensador (prensa a fricção)' },
  { id: '752330 Ceramista prensador (prensa extrusora)', descricao: 'Ceramista prensador (prensa extrusora)' },
  { id: '752330 Ceramista prensador (prensa hidráulica)', descricao: 'Ceramista prensador (prensa hidráulica)' },
  { id: '752330 Ceramista prensista (prensa extrusora)', descricao: 'Ceramista prensista (prensa extrusora)' },
  { id: '752330 Ceramista prensista (prensa hidráulica)', descricao: 'Ceramista prensista (prensa hidráulica)' },
  { id: '7523 CERAMISTAS (PREPARAÇÃO E FABRICAÇÃO)', descricao: 'CERAMISTAS (PREPARAÇÃO E FABRICAÇÃO)' },
  { id: '354825 Cerimonialista', descricao: 'Cerimonialista' },
  { id: '622020 Cerqueiro', descricao: 'Cerqueiro' },
  { id: '841710 Cervejeiro (fabricação)', descricao: 'Cervejeiro (fabricação)' },
  { id: '141205 Cervejeiro - empregador', descricao: 'Cervejeiro - empregador' },
  { id: '768210 Cerzideira de malha', descricao: 'Cerzideira de malha' },
  { id: '768210 Cerzideira de meias', descricao: 'Cerzideira de meias' },
  { id: '768210 Cerzideira de roupas', descricao: 'Cerzideira de roupas' },
  { id: '768210 Cerzideira de tecidos', descricao: 'Cerzideira de tecidos' },
  { id: '768210 Cerzidor', descricao: 'Cerzidor' },
  { id: '776405 Cesteiro', descricao: 'Cesteiro' },
  { id: '225150 Ceteísta', descricao: 'Ceteísta' },
  { id: '623015 Cevador (pecuária)', descricao: 'Cevador (pecuária)' },
  { id: '622005 Chacareiro - exclusive conta própria e empregador', descricao: 'Chacareiro - exclusive conta própria e empregador' },
  { id: '782815 Chamador de bois', descricao: 'Chamador de bois' },
  { id: '141410 Chanfaneiro', descricao: 'Chanfaneiro' },
  { id: '764115 Chanfrador de calçados', descricao: 'Chanfrador de calçados' },
  { id: '783215 Chapa (movimentador de mercadoria)', descricao: 'Chapa (movimentador de mercadoria)' },
  { id: '783215 Chapa arrumador de caminhões', descricao: 'Chapa arrumador de caminhões' },
  { id: '783215 Chapa de caminhão', descricao: 'Chapa de caminhão' },
  { id: '724415 Chapeador', descricao: 'Chapeador' },
  { id: '724430 Chapeador de aeronaves', descricao: 'Chapeador de aeronaves' },
  { id: '724420 Chapeador de automóveis', descricao: 'Chapeador de automóveis' },
  { id: '724430 Chapeador de aviões', descricao: 'Chapeador de aviões' },
  { id: '724420 Chapeador de carrocerias metálicas (fabricação)', descricao: 'Chapeador de carrocerias metálicas (fabricação)' },
  { id: '775110 Chapeador de fórmica', descricao: 'Chapeador de fórmica' },
  { id: '775110 Chapeador de móveis de madeira', descricao: 'Chapeador de móveis de madeira' },
  { id: '724415 Chapeador de móveis metálicos', descricao: 'Chapeador de móveis metálicos' },
  { id: '828110 Chapeador de tijolos', descricao: 'Chapeador de tijolos' },
  { id: '724420 Chapeador de veículos', descricao: 'Chapeador de veículos' },
  { id: '724415 Chapeador metalúrgico', descricao: 'Chapeador metalúrgico' },
  { id: '724410 Chapeador montador', descricao: 'Chapeador montador' },
  { id: '724425 Chapeador naval', descricao: 'Chapeador naval' },
  { id: '724415 Chapeiro', descricao: 'Chapeiro' },
  { id: '724410 Chapeiro (chapas de ferrro e aço)', descricao: 'Chapeiro (chapas de ferrro e aço)' },
  { id: '768125 Chapeleiro (chapéus de palha)', descricao: 'Chapeleiro (chapéus de palha)' },
  { id: '141410 Chapeleiro (comércio varejista)', descricao: 'Chapeleiro (comércio varejista)' },
  { id: '765010 Chapeleiro - exclusive de palha', descricao: 'Chapeleiro - exclusive de palha' },
  { id: '765010 Chapeleiro de chapéus de luxo', descricao: 'Chapeleiro de chapéus de luxo' },
  { id: '768125 Chapeleiro de palha', descricao: 'Chapeleiro de palha' },
  { id: '765010 Chapeleiro de senhoras', descricao: 'Chapeleiro de senhoras' },
  { id: '724430 Chapista de aeronaves', descricao: 'Chapista de aeronaves' },
  { id: '724420 Chapista de automóveis', descricao: 'Chapista de automóveis' },
  { id: '724430 Chapista de aviões', descricao: 'Chapista de aviões' },
  { id: '724420 Chapista de carrocerias metálicas', descricao: 'Chapista de carrocerias metálicas' },
  { id: '513435 Chapista de lanchonete', descricao: 'Chapista de lanchonete' },
  { id: '991305 Chapista de veículos', descricao: 'Chapista de veículos' },
  { id: '724420 Chapista de veículos (fabricação)', descricao: 'Chapista de veículos (fabricação)' },
  { id: '766145 Chapista gráfico', descricao: 'Chapista gráfico' },
  { id: '768620 Chapista paginador', descricao: 'Chapista paginador' },
  { id: '766145 Chapista tipográfico', descricao: 'Chapista tipográfico' },
  { id: '141410 Charcuteiro (comércio varejista)', descricao: 'Charcuteiro (comércio varejista)' },
  { id: '141410 Charcuteiro - no comércio - conta própria', descricao: 'Charcuteiro - no comércio - conta própria' },
  { id: '262405 Chargista', descricao: 'Chargista' },
  { id: '848105 Charqueador', descricao: 'Charqueador' },
  { id: '141410 Charqueador (comércio varejista)', descricao: 'Charqueador (comércio varejista)' },
  { id: '782805 Charreteiro', descricao: 'Charreteiro' },
  { id: '141410 Charuteiro (comércio varejista)', descricao: 'Charuteiro (comércio varejista)' },
  { id: '141410 Charuteiro - no comércio - empregador', descricao: 'Charuteiro - no comércio - empregador' },
  { id: '842230 Charuteiro a mão', descricao: 'Charuteiro a mão' },
  { id: '8422 CHARUTEIROS', descricao: 'CHARUTEIROS' },
  { id: '412205 Chasquil', descricao: 'Chasquil' },
  { id: '523115 Chaveiro', descricao: 'Chaveiro' },
  { id: '271105 Chef', descricao: 'Chef' },
  { id: '510135 Chef executivo nos serviços de alimentação', descricao: 'Chef executivo nos serviços de alimentação' },
  { id: '410105 Chefe administrativo', descricao: 'Chefe administrativo' },
  { id: '513425 Chefe da copa', descricao: 'Chefe da copa' },
  { id: '830105 Chefe de acabamento de papel', descricao: 'Chefe de acabamento de papel' },
  { id: '410220 Chefe de administração patrimonial', descricao: 'Chefe de administração patrimonial' },
  { id: '342315 Chefe de armazém (técnicos em transportes rodoviários)', descricao: 'Chefe de armazém (técnicos em transportes rodoviários)' },
  { id: '111415 Chefe de assessoria técnica do serviço público municipal', descricao: 'Chefe de assessoria técnica do serviço público municipal' },
  { id: '510130 Chefe de bar', descricao: 'Chefe de bar' },
  { id: '510130 Chefe de barewomen', descricao: 'Chefe de barewomen' },
  { id: '510130 Chefe de bartander', descricao: 'Chefe de bartander' },
  { id: '510105 Chefe de bilheteria', descricao: 'Chefe de bilheteria' },
  { id: '517125 Chefe de brigada', descricao: 'Chefe de brigada' },
  { id: '342315 Chefe de carga e descarga no transporte rodoviário', descricao: 'Chefe de carga e descarga no transporte rodoviário' },
  { id: '810110 Chefe de centrifugação', descricao: 'Chefe de centrifugação' },
  { id: '131315 Chefe de centro de formação profissional privado', descricao: 'Chefe de centro de formação profissional privado' },
  { id: '720110 Chefe de chaparia', descricao: 'Chefe de chaparia' },
  { id: '420110 Chefe de cobrança', descricao: 'Chefe de cobrança' },
  { id: '840120 Chefe de confeitaria', descricao: 'Chefe de confeitaria' },
  { id: '351110 Chefe de contabilidade (técnico)', descricao: 'Chefe de contabilidade (técnico)' },
  { id: '271105 Chefe de cozinha', descricao: 'Chefe de cozinha' },
  { id: '711410 Chefe de cristalização', descricao: 'Chefe de cristalização' },
  { id: '342305 Chefe de departamento de motoristas', descricao: 'Chefe de departamento de motoristas' },
  { id: '410105 Chefe de departamento de pessoal', descricao: 'Chefe de departamento de pessoal' },
  { id: '131315 Chefe de departamento em escolas privadas', descricao: 'Chefe de departamento em escolas privadas' },
  { id: '342315 Chefe de depósito', descricao: 'Chefe de depósito' },
  { id: '142605 Chefe de desenvolvimento de novos produtos', descricao: 'Chefe de desenvolvimento de novos produtos' },
  { id: '412120 Chefe de digitação', descricao: 'Chefe de digitação' },
  { id: '760605 Chefe de divisão gráfica', descricao: 'Chefe de divisão gráfica' },
  { id: '410105 Chefe de escritório', descricao: 'Chefe de escritório' },
  { id: '517120 Chefe de esquadrão', descricao: 'Chefe de esquadrão' },
  { id: '342405 Chefe de estação (ferrovia)', descricao: 'Chefe de estação (ferrovia)' },
  { id: '342605 Chefe de estação portuária', descricao: 'Chefe de estação portuária' },
  { id: '410105 Chefe de expediente - no serviço público', descricao: 'Chefe de expediente - no serviço público' },
  { id: '840105 Chefe de fabricação de chocolate', descricao: 'Chefe de fabricação de chocolate' },
  { id: '510135 Chefe de fila nos serviços de alimentação', descricao: 'Chefe de fila nos serviços de alimentação' },
  { id: '111415 Chefe de gabinete do serviço público municipal', descricao: 'Chefe de gabinete do serviço público municipal' },
  { id: '342310 Chefe de garagem (transporte rodoviário)', descricao: 'Chefe de garagem (transporte rodoviário)' },
  { id: '510205 Chefe de lavagem de equipamentos de proteção individual (epi)', descricao: 'Chefe de lavagem de equipamentos de proteção individual (epi)' },
  { id: '510205 Chefe de lavanderia', descricao: 'Chefe de lavanderia' },
  { id: '783110 Chefe de manobra - no transporte ferroviário', descricao: 'Chefe de manobra - no transporte ferroviário' },
  { id: '950205 Chefe de manutenção eletroeletrônica veicular', descricao: 'Chefe de manutenção eletroeletrônica veicular' },
  { id: '950110 Chefe de manutenção eletromecânica', descricao: 'Chefe de manutenção eletromecânica' },
  { id: '910105 Chefe de manutenção mecânica de sistemas operacionais', descricao: 'Chefe de manutenção mecânica de sistemas operacionais' },
  { id: '862110 Chefe de máquina - no transporte marítimo', descricao: 'Chefe de máquina - no transporte marítimo' },
  { id: '215205 Chefe de máquinas da marinha mercante', descricao: 'Chefe de máquinas da marinha mercante' },
  { id: '725030 Chefe de oficina de manutenção e recarga de extintor de incêndio', descricao: 'Chefe de oficina de manutenção e recarga de extintor de incêndio' },
  { id: '373220 Chefe de operações de sistemas de televisão e produtoras de vídeo', descricao: 'Chefe de operações de sistemas de televisão e produtoras de vídeo' },
  { id: '342610 Chefe de operações portuárias', descricao: 'Chefe de operações portuárias' },
  { id: '410230 Chefe de orçamento', descricao: 'Chefe de orçamento' },
  { id: '271105 Chefe de partida', descricao: 'Chefe de partida' },
  { id: '510110 Chefe de portaria (edifício)', descricao: 'Chefe de portaria (edifício)' },
  { id: '510120 Chefe de portaria de hotel', descricao: 'Chefe de portaria de hotel' },
  { id: '720215 Chefe de produção (indústria de máquinas e outros equipamentos mecânicos)', descricao: 'Chefe de produção (indústria de máquinas e outros equipamentos mecânicos)' },
  { id: '730105 Chefe de produção (indústria de material elétrico e eletrônico)', descricao: 'Chefe de produção (indústria de material elétrico e eletrônico)' },
  { id: '820205 Chefe de produção na fabricação de produtos de cerâmica, porcelanatos e afins', descricao: 'Chefe de produção na fabricação de produtos de cerâmica, porcelanatos e afins' },
  { id: '820210 Chefe de produção na fabricação de produtos de vidro', descricao: 'Chefe de produção na fabricação de produtos de vidro' },
  { id: '420125 Chefe de recepção', descricao: 'Chefe de recepção' },
  { id: '351305 Chefe de recurso de glosa de contas de convênios (administração de hospital)', descricao: 'Chefe de recurso de glosa de contas de convênios (administração de hospital)' },
  { id: '351305 Chefe de recurso de glosas de contas nos hostpitais (administração de convênios e fonte pagadora)', descricao: 'Chefe de recurso de glosas de contas nos hostpitais (administração de convênios e fonte pagadora)' },
  { id: '424210 Chefe de sala (concurso, avaliação, exame)', descricao: 'Chefe de sala (concurso, avaliação, exame)' },
  { id: '410105 Chefe de seção - no serviço público', descricao: 'Chefe de seção - no serviço público' },
  { id: '760605 Chefe de seção de artes gráficas', descricao: 'Chefe de seção de artes gráficas' },
  { id: '410105 Chefe de seção de expedição', descricao: 'Chefe de seção de expedição' },
  { id: '860105 Chefe de seção de manutenção eletromecânica (utilidades)', descricao: 'Chefe de seção de manutenção eletromecânica (utilidades)' },
  { id: '710120 Chefe de seção de mina', descricao: 'Chefe de seção de mina' },
  { id: '410105 Chefe de seção de serviços administrativos', descricao: 'Chefe de seção de serviços administrativos' },
  { id: '410105 Chefe de seção de serviços gerais - exclusive no serviço público', descricao: 'Chefe de seção de serviços gerais - exclusive no serviço público' },
  { id: '410105 Chefe de serviço de limpeza', descricao: 'Chefe de serviço de limpeza' },
  { id: '131120 Chefe de serviço de pesquisa e estatística', descricao: 'Chefe de serviço de pesquisa e estatística' },
  { id: '342305 Chefe de serviço de transporte rodoviário', descricao: 'Chefe de serviço de transporte rodoviário' },
  { id: '342305 Chefe de serviço de transporte rodoviário (passageiros e cargas)', descricao: 'Chefe de serviço de transporte rodoviário (passageiros e cargas)' },
  { id: '410235 Chefe de serviço financeiro', descricao: 'Chefe de serviço financeiro' },
  { id: '353235 Chefe de serviços bancários', descricao: 'Chefe de serviços bancários' },
  { id: '410105 Chefe de serviços de coordenação de contratos', descricao: 'Chefe de serviços de coordenação de contratos' },
  { id: '131210 Chefe de serviços de saúde', descricao: 'Chefe de serviços de saúde' },
  { id: '410105 Chefe de setor - exclusive no serviço público', descricao: 'Chefe de setor - exclusive no serviço público' },
  { id: '410105 Chefe de setor - no serviço público', descricao: 'Chefe de setor - no serviço público' },
  { id: '720130 Chefe de setor de cromação', descricao: 'Chefe de setor de cromação' },
  { id: '720125 Chefe de setor de fundição de metais', descricao: 'Chefe de setor de fundição de metais' },
  { id: '810205 Chefe de setor de plásticos', descricao: 'Chefe de setor de plásticos' },
  { id: '342305 Chefe de transporte - no serviço público', descricao: 'Chefe de transporte - no serviço público' },
  { id: '342305 Chefe de transportes - exclusive no serviço público', descricao: 'Chefe de transportes - exclusive no serviço público' },
  { id: '622020 Chefe de turma volante - na cultura', descricao: 'Chefe de turma volante - na cultura' },
  { id: '111410 Chefe do serviço público estadual e distrital', descricao: 'Chefe do serviço público estadual e distrital' },
  { id: '271105 Chefe executivo de cozinha', descricao: 'Chefe executivo de cozinha' },
  { id: '2711 CHEFES DE COZINHA E AFINS', descricao: 'CHEFES DE COZINHA E AFINS' },
  { id: '782305 Chofer', descricao: 'Chofer' },
  { id: '782315 Chofer de praça', descricao: 'Chofer de praça' },
  { id: '841710 Chopeiro', descricao: 'Chopeiro' },
  { id: '141410 Chumbeiro (comércio varejista)', descricao: 'Chumbeiro (comércio varejista)' },
  { id: '513605 Churrasqueiro', descricao: 'Churrasqueiro' },
  { id: '5136 CHURRASQUEIROS, PIZZAIOLOS E SUSHIMEN', descricao: 'CHURRASQUEIROS, PIZZAIOLOS E SUSHIMEN' },
  { id: '782820 Ciclista de carga', descricao: 'Ciclista de carga' },
  { id: '519105 Ciclista mensageiro', descricao: 'Ciclista mensageiro' },
  { id: '261205 Cientista de informação', descricao: 'Cientista de informação' },
  { id: '251115 Cientista político', descricao: 'Cientista político' },
  { id: '251115 Cientista social', descricao: 'Cientista social' },
  { id: '141410 Cigarreiro (comércio varejista)', descricao: 'Cigarreiro (comércio varejista)' },
  { id: '8423 CIGARREIROS', descricao: 'CIGARREIROS' },
  { id: '8421 CIGARREIROS E BENEFICIADORES DE FUMO', descricao: 'CIGARREIROS E BENEFICIADORES DE FUMO' },
  { id: '848315 Cilindreiro de preparação de massa alimentícia', descricao: 'Cilindreiro de preparação de massa alimentícia' },
  { id: '831105 Cilindreiro na preparação de pasta para fabricação de papel', descricao: 'Cilindreiro na preparação de pasta para fabricação de papel' },
  { id: '813105 Cilindrista (petroquímica e afins)', descricao: 'Cilindrista (petroquímica e afins)' },
  { id: '811710 Cilindrista de borracha', descricao: 'Cilindrista de borracha' },
  { id: '766250 Cilindrista de imprensa', descricao: 'Cilindrista de imprensa' },
  { id: '811745 Cilindrista de plástico', descricao: 'Cilindrista de plástico' },
  { id: '316340 Cimentador (poços de petróleo)', descricao: 'Cimentador (poços de petróleo)' },
  { id: '262205 Cineasta', descricao: 'Cineasta' },
  { id: '224110 Cinesiólogo ludomotricista', descricao: 'Cinesiólogo ludomotricista' },
  { id: '768305 Cinteiro (correeiro)', descricao: 'Cinteiro (correeiro)' },
  { id: '751125 Cinzelador de metais preciosos', descricao: 'Cinzelador de metais preciosos' },
  { id: '773125 Circuleiro', descricao: 'Circuleiro' },
  { id: '225225 Cirurgião', descricao: 'Cirurgião' },
  { id: '225210 Cirurgião cardiovascular', descricao: 'Cirurgião cardiovascular' },
  { id: '225295 Cirurgião da mão', descricao: 'Cirurgião da mão' },
  { id: '225215 Cirurgião de cabeça e pescoço', descricao: 'Cirurgião de cabeça e pescoço' },
  { id: '225255 Cirurgião de mama', descricao: 'Cirurgião de mama' },
  { id: '223204 Cirurgião dentista - auditor', descricao: 'Cirurgião dentista - auditor' },
  { id: '223208 Cirurgião dentista - clínico geral', descricao: 'Cirurgião dentista - clínico geral' },
  { id: '223280 Cirurgião dentista - dentística', descricao: 'Cirurgião dentista - dentística' },
  { id: '223284 Cirurgião dentista - disfunção temporomandibular e dor orofacial', descricao: 'Cirurgião dentista - disfunção temporomandibular e dor orofacial' },
  { id: '223212 Cirurgião dentista - endodontista', descricao: 'Cirurgião dentista - endodontista' },
  { id: '223216 Cirurgião dentista - epidemiologista', descricao: 'Cirurgião dentista - epidemiologista' },
  { id: '223220 Cirurgião dentista - estomatologista', descricao: 'Cirurgião dentista - estomatologista' },
  { id: '223224 Cirurgião dentista - implantodontista', descricao: 'Cirurgião dentista - implantodontista' },
  { id: '223228 Cirurgião dentista - odontogeriatra', descricao: 'Cirurgião dentista - odontogeriatra' },
  { id: '223276 Cirurgião dentista - odontologia do trabalho', descricao: 'Cirurgião dentista - odontologia do trabalho' },
  { id: '223288 Cirurgião dentista - odontologia para pacientes com necessidades especiais', descricao: 'Cirurgião dentista - odontologia para pacientes com necessidades especiais' },
  { id: '223232 Cirurgião dentista - odontologista legal', descricao: 'Cirurgião dentista - odontologista legal' },
  { id: '223236 Cirurgião dentista - odontopediatra', descricao: 'Cirurgião dentista - odontopediatra' },
  { id: '223240 Cirurgião dentista - ortopedista e ortodontista', descricao: 'Cirurgião dentista - ortopedista e ortodontista' },
  { id: '223244 Cirurgião dentista - patologista bucal', descricao: 'Cirurgião dentista - patologista bucal' },
  { id: '223248 Cirurgião dentista - periodontista', descricao: 'Cirurgião dentista - periodontista' },
  { id: '223252 Cirurgião dentista - protesiólogo bucomaxilofacial', descricao: 'Cirurgião dentista - protesiólogo bucomaxilofacial' },
  { id: '223256 Cirurgião dentista - protesista', descricao: 'Cirurgião dentista - protesista' },
  { id: '223260 Cirurgião dentista - radiologista', descricao: 'Cirurgião dentista - radiologista' },
  { id: '223264 Cirurgião dentista - reabilitador oral', descricao: 'Cirurgião dentista - reabilitador oral' },
  { id: '223268 Cirurgião dentista - traumatologista bucomaxilofacial', descricao: 'Cirurgião dentista - traumatologista bucomaxilofacial' },
  { id: '223272 Cirurgião dentista de saúde coletiva', descricao: 'Cirurgião dentista de saúde coletiva' },
  { id: '225220 Cirurgião do aparelho digestivo', descricao: 'Cirurgião do aparelho digestivo' },
  { id: '225220 Cirurgião gastroenterológico', descricao: 'Cirurgião gastroenterológico' },
  { id: '225225 Cirurgião geral', descricao: 'Cirurgião geral' },
  { id: '225250 Cirurgião ginecológico', descricao: 'Cirurgião ginecológico' },
  { id: '225255 Cirurgião mastologista', descricao: 'Cirurgião mastologista' },
  { id: '225265 Cirurgião oftalmológico', descricao: 'Cirurgião oftalmológico' },
  { id: '223268 Cirurgião oral e maxilofacial', descricao: 'Cirurgião oral e maxilofacial' },
  { id: '225270 Cirurgião ortopedista', descricao: 'Cirurgião ortopedista' },
  { id: '225275 Cirurgião otorrinolaringologista', descricao: 'Cirurgião otorrinolaringologista' },
  { id: '225230 Cirurgião pediátrico', descricao: 'Cirurgião pediátrico' },
  { id: '225235 Cirurgião plástico', descricao: 'Cirurgião plástico' },
  { id: '225280 Cirurgião proctologista', descricao: 'Cirurgião proctologista' },
  { id: '225240 Cirurgião torácico', descricao: 'Cirurgião torácico' },
  { id: '225270 Cirurgião traumatologista', descricao: 'Cirurgião traumatologista' },
  { id: '225285 Cirurgião urológico', descricao: 'Cirurgião urológico' },
  { id: '225285 Cirurgião urologista', descricao: 'Cirurgião urologista' },
  { id: '225203 Cirurgião vascular', descricao: 'Cirurgião vascular' },
  { id: '2232 CIRURGIÕES-DENTISTAS', descricao: 'CIRURGIÕES-DENTISTAS' },
  { id: '717015 Cisterneiro', descricao: 'Cisterneiro' },
  { id: '221105 Citologista', descricao: 'Citologista' },
  { id: '225305 Citopatologista', descricao: 'Citopatologista' },
  { id: '324215 Citotécnico', descricao: 'Citotécnico' },
  { id: '324215 Citotecnologista', descricao: 'Citotecnologista' },
  { id: '612505 Citricultor', descricao: 'Citricultor' },
  { id: '841720 Clarificador de vinhos', descricao: 'Clarificador de vinhos' },
  { id: '761105 Classificador de algodão - no beneficiamento de fibras têxteis', descricao: 'Classificador de algodão - no beneficiamento de fibras têxteis' },
  { id: '761105 Classificador de algodão em caroço', descricao: 'Classificador de algodão em caroço' },
  { id: '761105 Classificador de algodão em pluma', descricao: 'Classificador de algodão em pluma' },
  { id: '711105 Classificador de amostras - na mineração', descricao: 'Classificador de amostras - na mineração' },
  { id: '848420 Classificador de bebida', descricao: 'Classificador de bebida' },
  { id: '848405 Classificador de café', descricao: 'Classificador de café' },
  { id: '848520 Classificador de carnes', descricao: 'Classificador de carnes' },
  { id: '848520 Classificador de carnes em matadouro', descricao: 'Classificador de carnes em matadouro' },
  { id: '632325 Classificador de cera de carnaúba - na extração', descricao: 'Classificador de cera de carnaúba - na extração' },
  { id: '842215 Classificador de charutos', descricao: 'Classificador de charutos' },
  { id: '415210 Classificador de correspondências (correios e telégrafos)', descricao: 'Classificador de correspondências (correios e telégrafos)' },
  { id: '762210 Classificador de couros', descricao: 'Classificador de couros' },
  { id: '784105 Classificador de embalagens (manual)', descricao: 'Classificador de embalagens (manual)' },
  { id: '761105 Classificador de fibras na indústria têxtil', descricao: 'Classificador de fibras na indústria têxtil' },
  { id: '761105 Classificador de fibras têxteis', descricao: 'Classificador de fibras têxteis' },
  { id: '842115 Classificador de fumo', descricao: 'Classificador de fumo' },
  { id: '848425 Classificador de grãos', descricao: 'Classificador de grãos' },
  { id: '761105 Classificador de lã na indústria têxtil', descricao: 'Classificador de lã na indústria têxtil' },
  { id: '772105 Classificador de lâminas (madeira)', descricao: 'Classificador de lâminas (madeira)' },
  { id: '848420 Classificador de licores', descricao: 'Classificador de licores' },
  { id: '772105 Classificador de madeira', descricao: 'Classificador de madeira' },
  { id: '772105 Classificador de madeira na indústria', descricao: 'Classificador de madeira na indústria' },
  { id: '711105 Classificador de minérios', descricao: 'Classificador de minérios' },
  { id: '762105 Classificador de peles', descricao: 'Classificador de peles' },
  { id: '761105 Classificador de sisal - no beneficiamento', descricao: 'Classificador de sisal - no beneficiamento' },
  { id: '761815 Classificador de tecidos', descricao: 'Classificador de tecidos' },
  { id: '632105 Classificador de toras', descricao: 'Classificador de toras' },
  { id: '848420 Classificador de vinhos', descricao: 'Classificador de vinhos' },
  { id: '823305 Classificador e empilhador de tijolos e telhas', descricao: 'Classificador e empilhador de tijolos e telhas' },
  { id: '823305 Classificador e empilhador de tijolos refratários', descricao: 'Classificador e empilhador de tijolos refratários' },
  { id: '263105 Clérigo', descricao: 'Clérigo' },
  { id: '225125 Clínico geral', descricao: 'Clínico geral' },
  { id: '376245 Clown', descricao: 'Clown' },
  { id: '262505 Coadjuvante (artístico)', descricao: 'Coadjuvante (artístico)' },
  { id: '421305 Cobrador de aluguéis', descricao: 'Cobrador de aluguéis' },
  { id: '511215 Cobrador de transportes coletivos (exceto trem)', descricao: 'Cobrador de transportes coletivos (exceto trem)' },
  { id: '421305 Cobrador externo', descricao: 'Cobrador externo' },
  { id: '421310 Cobrador interno', descricao: 'Cobrador interno' },
  { id: '421305 Cobrador pracista', descricao: 'Cobrador pracista' },
  { id: '421305 Cobrador viajante', descricao: 'Cobrador viajante' },
  { id: '4213 COBRADORES E AFINS', descricao: 'COBRADORES E AFINS' },
  { id: '623405 Cobreiro', descricao: 'Cobreiro' },
  { id: '782805 Cocheiro', descricao: 'Cocheiro' },
  { id: '415115 Codificador de dados', descricao: 'Codificador de dados' },
  { id: '415310 Codificador de dados clínicos', descricao: 'Codificador de dados clínicos' },
  { id: '415310 Codificador hospitalar', descricao: 'Codificador hospitalar' },
  { id: '613310 Coelheiro', descricao: 'Coelheiro' },
  { id: '763325 Coladeira (confecção em série)', descricao: 'Coladeira (confecção em série)' },
  { id: '784105 Colador de caixas', descricao: 'Colador de caixas' },
  { id: '764115 Colador de calçados', descricao: 'Colador de calçados' },
  { id: '519905 Colador de cartazes', descricao: 'Colador de cartazes' },
  { id: '768325 Colador de couro - exclusive calçados', descricao: 'Colador de couro - exclusive calçados' },
  { id: '768305 Colador de couro, a mão', descricao: 'Colador de couro, a mão' },
  { id: '764120 Colador de palmilhas', descricao: 'Colador de palmilhas' },
  { id: '784115 Colador de rótulos em caixas', descricao: 'Colador de rótulos em caixas' },
  { id: '765205 Colchoeiro (confecção de colchões)', descricao: 'Colchoeiro (confecção de colchões)' },
  { id: '371205 Colecionador de selos e moedas', descricao: 'Colecionador de selos e moedas' },
  { id: '424120 Coletador de preços', descricao: 'Coletador de preços' },
  { id: '4212 COLETADORES DE APOSTAS E DE JOGOS', descricao: 'COLETADORES DE APOSTAS E DE JOGOS' },
  { id: '632310 Coletor de babaçu', descricao: 'Coletor de babaçu' },
  { id: '632205 Coletor de látex', descricao: 'Coletor de látex' },
  { id: '514205 Coletor de lixo', descricao: 'Coletor de lixo' },
  { id: '514205 Coletor de lixo domiciliar', descricao: 'Coletor de lixo domiciliar' },
  { id: '514230 Coletor de lixo hospitalar', descricao: 'Coletor de lixo hospitalar' },
  { id: '519205 Coletor de materiais recicláveis', descricao: 'Coletor de materiais recicláveis' },
  { id: '514230 Coletor de resíduos de saúde', descricao: 'Coletor de resíduos de saúde' },
  { id: '514230 Coletor de resíduos hospitalares', descricao: 'Coletor de resíduos hospitalares' },
  { id: '514230 Coletor de resíduos sólidos de serviços de saúde', descricao: 'Coletor de resíduos sólidos de serviços de saúde' },
  { id: '632215 Coletor de resinas', descricao: 'Coletor de resinas' },
  { id: '632015 Coletor de sementes (floresta)', descricao: 'Coletor de sementes (floresta)' },
  { id: '622020 Coletor na cultura', descricao: 'Coletor na cultura' },
  { id: '752115 Colhedor - em fábrica de vidro', descricao: 'Colhedor - em fábrica de vidro' },
  { id: '622020 Colhedor - na cultura', descricao: 'Colhedor - na cultura' },
  { id: '622205 Colhedor de algodão', descricao: 'Colhedor de algodão' },
  { id: '711105 Colhedor de amostras - em minas', descricao: 'Colhedor de amostras - em minas' },
  { id: '622105 Colhedor de arroz', descricao: 'Colhedor de arroz' },
  { id: '622505 Colhedor de banana', descricao: 'Colhedor de banana' },
  { id: '622605 Colhedor de cacau', descricao: 'Colhedor de cacau' },
  { id: '622610 Colhedor de café', descricao: 'Colhedor de café' },
  { id: '622505 Colhedor de caju', descricao: 'Colhedor de caju' },
  { id: '622110 Colhedor de cana-de-açúcar', descricao: 'Colhedor de cana-de-açúcar' },
  { id: '622715 Colhedor de coco', descricao: 'Colhedor de coco' },
  { id: '622615 Colhedor de erva-mate', descricao: 'Colhedor de erva-mate' },
  { id: '622620 Colhedor de fumo', descricao: 'Colhedor de fumo' },
  { id: '622625 Colhedor de guaraná', descricao: 'Colhedor de guaraná' },
  { id: '622505 Colhedor de laranja', descricao: 'Colhedor de laranja' },
  { id: '622020 Colhedor de lavoura (exceto na floricultura, fruticultura e horticultura)', descricao: 'Colhedor de lavoura (exceto na floricultura, fruticultura e horticultura)' },
  { id: '622505 Colhedor de manga', descricao: 'Colhedor de manga' },
  { id: '622505 Colhedor de pêssego', descricao: 'Colhedor de pêssego' },
  { id: '622015 Colhedor de sementes', descricao: 'Colhedor de sementes' },
  { id: '622515 Colhedor de uva', descricao: 'Colhedor de uva' },
  { id: '716505 Colocador de assoalho', descricao: 'Colocador de assoalho' },
  { id: '519905 Colocador de cartazes', descricao: 'Colocador de cartazes' },
  { id: '716510 Colocador de cerâmica', descricao: 'Colocador de cerâmica' },
  { id: '716510 Colocador de cerâmica (pastilhas)', descricao: 'Colocador de cerâmica (pastilhas)' },
  { id: '724205 Colocador de estruturas metálicas', descricao: 'Colocador de estruturas metálicas' },
  { id: '519905 Colocador de painéis', descricao: 'Colocador de painéis' },
  { id: '716615 Colocador de papel de parede', descricao: 'Colocador de papel de parede' },
  { id: '716615 Colocador de piso vinílico', descricao: 'Colocador de piso vinílico' },
  { id: '716615 Colocador de pisos emborrachados', descricao: 'Colocador de pisos emborrachados' },
  { id: '716535 Colocador de tacos', descricao: 'Colocador de tacos' },
  { id: '716615 Colocador de tapetes, carpetes e pisos plásticos', descricao: 'Colocador de tapetes, carpetes e pisos plásticos' },
  { id: '621005 Colono - na agropecuária', descricao: 'Colono - na agropecuária' },
  { id: '612105 Colono na produção de arroz', descricao: 'Colono na produção de arroz' },
  { id: '612115 Colono na produção de cereais de inverno', descricao: 'Colono na produção de cereais de inverno' },
  { id: '612120 Colono na produção de gramíneas forrageiras', descricao: 'Colono na produção de gramíneas forrageiras' },
  { id: '612125 Colono na produção de milho e sorgo', descricao: 'Colono na produção de milho e sorgo' },
  { id: '225280 Coloproctologista', descricao: 'Coloproctologista' },
  { id: '311715 Colorista', descricao: 'Colorista' },
  { id: '311725 Colorista - na indústria do couro', descricao: 'Colorista - na indústria do couro' },
  { id: '311725 Colorista de couro', descricao: 'Colorista de couro' },
  { id: '311705 Colorista de papel', descricao: 'Colorista de papel' },
  { id: '311710 Colorista têxtil', descricao: 'Colorista têxtil' },
  { id: '3117 COLORISTAS', descricao: 'COLORISTAS' },
  { id: '261125 Colunista', descricao: 'Colunista' },
  { id: '261125 Colunista de jornal', descricao: 'Colunista de jornal' },
  { id: '215115 Comandante da marinha mercante', descricao: 'Comandante da marinha mercante' },
  { id: '782810 Comboieiro', descricao: 'Comboieiro' },
  { id: '262505 Comediante', descricao: 'Comediante' },
  { id: '376215 Comedor de espada', descricao: 'Comedor de espada' },
  { id: '376215 Comedor de fogo (pirofagista)', descricao: 'Comedor de fogo (pirofagista)' },
  { id: '261710 Comentarista cultural', descricao: 'Comentarista cultural' },
  { id: '261710 Comentarista de esportes', descricao: 'Comentarista de esportes' },
  { id: '261710 Comentarista de jornal', descricao: 'Comentarista de jornal' },
  { id: '261710 Comentarista de mídias audiovisuais', descricao: 'Comentarista de mídias audiovisuais' },
  { id: '261710 Comentarista de moda', descricao: 'Comentarista de moda' },
  { id: '261710 Comentarista de rádio e tv', descricao: 'Comentarista de rádio e tv' },
  { id: '376310 Comentarista de rodeio', descricao: 'Comentarista de rodeio' },
  { id: '261710 Comentarista econômico', descricao: 'Comentarista econômico' },
  { id: '261710 Comentarista esportivo', descricao: 'Comentarista esportivo' },
  { id: '261710 Comentarista político', descricao: 'Comentarista político' },
  { id: '141405 Comerciante atacadista', descricao: 'Comerciante atacadista' },
  { id: '141410 Comerciante varejista', descricao: 'Comerciante varejista' },
  { id: '376245 Cômico de circo', descricao: 'Cômico de circo' },
  { id: '351730 Comissário de avarias', descricao: 'Comissário de avarias' },
  { id: '511105 Comissário de bordo', descricao: 'Comissário de bordo' },
  { id: '513320 Comissário de bordo no transporte marítimo', descricao: 'Comissário de bordo no transporte marítimo' },
  { id: '141405 Comissário de café', descricao: 'Comissário de café' },
  { id: '141405 Comissário de mercadorias', descricao: 'Comissário de mercadorias' },
  { id: '351810 Comissário de polícia', descricao: 'Comissário de polícia' },
  { id: '141410 Comissário de transporte (comércio varejista)', descricao: 'Comissário de transporte (comércio varejista)' },
  { id: '511110 Comissário de trem', descricao: 'Comissário de trem' },
  { id: '511105 Comissário de vôo', descricao: 'Comissário de vôo' },
  { id: '413215 Compensador de banco', descricao: 'Compensador de banco' },
  { id: '262605 Compositor', descricao: 'Compositor' },
  { id: '374415 Compositor de imagens', descricao: 'Compositor de imagens' },
  { id: '262605 Compositor de música', descricao: 'Compositor de música' },
  { id: '768610 Compositor de texto', descricao: 'Compositor de texto' },
  { id: '766145 Compositor, a máquina', descricao: 'Compositor, a máquina' },
  { id: '848310 Compoteiro', descricao: 'Compoteiro' },
  { id: '354205 Comprador', descricao: 'Comprador' },
  { id: '842115 Comprador de fumo', descricao: 'Comprador de fumo' },
  { id: '3542 COMPRADORES', descricao: 'COMPRADORES' },
  { id: '862130 Compressorista de ar', descricao: 'Compressorista de ar' },
  { id: '391110 Computometrista', descricao: 'Computometrista' },
  { id: '261715 Comunicador de mídias audiovisuais', descricao: 'Comunicador de mídias audiovisuais' },
  { id: '261715 Comunicador de rádio e televisão', descricao: 'Comunicador de rádio e televisão' },
  { id: '422130 Concierge', descricao: 'Concierge' },
  { id: '715415 Concreteiro', descricao: 'Concreteiro' },
  { id: '623005 Condicionador de animais', descricao: 'Condicionador de animais' },
  { id: '832125 Condutor em fábrica de papel', descricao: 'Condutor em fábrica de papel' },
  { id: '782320 Condutor de ambulância', descricao: 'Condutor de ambulância' },
  { id: '715140 Condutor de asfaltadora', descricao: 'Condutor de asfaltadora' },
  { id: '715105 Condutor de bate-estaca', descricao: 'Condutor de bate-estaca' },
  { id: '715405 Condutor de betoneira', descricao: 'Condutor de betoneira' },
  { id: '519105 Condutor de bicicleta no transporte de mercadorias', descricao: 'Condutor de bicicleta no transporte de mercadorias' },
  { id: '782815 Condutor de boiada', descricao: 'Condutor de boiada' },
  { id: '782815 Condutor de bois', descricao: 'Condutor de bois' },
  { id: '623015 Condutor de bois - na criação', descricao: 'Condutor de bois - na criação' },
  { id: '623015 Condutor de bovinos', descricao: 'Condutor de bovinos' },
  { id: '782805 Condutor de carroça', descricao: 'Condutor de carroça' },
  { id: '782805 Condutor de charrete', descricao: 'Condutor de charrete' },
  { id: '632005 Condutor de ecotrilha', descricao: 'Condutor de ecotrilha' },
  { id: '715115 Condutor de escavadeira', descricao: 'Condutor de escavadeira' },
  { id: '711220 Condutor de máquina contínua de extração', descricao: 'Condutor de máquina contínua de extração' },
  { id: '715120 Condutor de máquina de abrir valas', descricao: 'Condutor de máquina de abrir valas' },
  { id: '832125 Condutor de máquina de fabricar papel e papelão', descricao: 'Condutor de máquina de fabricar papel e papelão' },
  { id: '832115 Condutor de máquina de papel (fase úmida)', descricao: 'Condutor de máquina de papel (fase úmida)' },
  { id: '831125 Condutor de máquina de secagem de celulose', descricao: 'Condutor de máquina de secagem de celulose' },
  { id: '831105 Condutor de máquina de secagem na fabricação de pasta/celulose', descricao: 'Condutor de máquina de secagem na fabricação de pasta/celulose' },
  { id: '341310 Condutor de máquinas', descricao: 'Condutor de máquinas' },
  { id: '341320 Condutor de máquinas (bombeador)', descricao: 'Condutor de máquinas (bombeador)' },
  { id: '341325 Condutor de máquinas (mecânico)', descricao: 'Condutor de máquinas (mecânico)' },
  { id: '715130 Condutor de motoniveladora', descricao: 'Condutor de motoniveladora' },
  { id: '715130 Condutor de niveladora', descricao: 'Condutor de niveladora' },
  { id: '715115 Condutor de pá mecânica', descricao: 'Condutor de pá mecânica' },
  { id: '715140 Condutor de pavimentadora', descricao: 'Condutor de pavimentadora' },
  { id: '711315 Condutor de perfuradora de precisão', descricao: 'Condutor de perfuradora de precisão' },
  { id: '781105 Condutor de processos robotizados de pintura', descricao: 'Condutor de processos robotizados de pintura' },
  { id: '781110 Condutor de processos robotizados de soldagem', descricao: 'Condutor de processos robotizados de soldagem' },
  { id: '782315 Condutor de táxi', descricao: 'Condutor de táxi' },
  { id: '782320 Condutor de transporte de pacientes', descricao: 'Condutor de transporte de pacientes' },
  { id: '511505 Condutor de turismo de aventura', descricao: 'Condutor de turismo de aventura' },
  { id: '511510 Condutor de turismo de pesca', descricao: 'Condutor de turismo de pesca' },
  { id: '511505 Condutor de turismo náutico', descricao: 'Condutor de turismo náutico' },
  { id: '782310 Condutor de veículo de carga', descricao: 'Condutor de veículo de carga' },
  { id: '782820 Condutor de veículos a pedais', descricao: 'Condutor de veículos a pedais' },
  { id: '782320 Condutor de veículos ambulatoriais', descricao: 'Condutor de veículos ambulatoriais' },
  { id: '782805 Condutor de veículos de tração animal (ruas e estradas)', descricao: 'Condutor de veículos de tração animal (ruas e estradas)' },
  { id: '341305 Condutor maquinista motorista fluvial', descricao: 'Condutor maquinista motorista fluvial' },
  { id: '7828 CONDUTORES DE ANIMAIS E DE VEÍCULOS DE TRAÇÃO ANIMAL E PEDAIS', descricao: 'CONDUTORES DE ANIMAIS E DE VEÍCULOS DE TRAÇÃO ANIMAL E PEDAIS' },
  { id: '7811 CONDUTORES DE PROCESSOS ROBOTIZADOS', descricao: 'CONDUTORES DE PROCESSOS ROBOTIZADOS' },
  { id: '5115 CONDUTORES DE TURISMO', descricao: 'CONDUTORES DE TURISMO' },
  { id: '263105 Cônega', descricao: 'Cônega' },
  { id: '263105 Cônego', descricao: 'Cônego' },
  { id: '742110 Confeccionador de acordeão', descricao: 'Confeccionador de acordeão' },
  { id: '765005 Confeccionador de artefatos de couro (exceto sapatos)', descricao: 'Confeccionador de artefatos de couro (exceto sapatos)' },
  { id: '833110 Confeccionador de artigos de papel e papelão', descricao: 'Confeccionador de artigos de papel e papelão' },
  { id: '833110 Confeccionador de bolsas de papel', descricao: 'Confeccionador de bolsas de papel' },
  { id: '833110 Confeccionador de bolsas, sacos e sacolas e papel, a máquina', descricao: 'Confeccionador de bolsas, sacos e sacolas e papel, a máquina' },
  { id: '765215 Confeccionador de brinquedos de pano', descricao: 'Confeccionador de brinquedos de pano' },
  { id: '768325 Confeccionador de cabrestos', descricao: 'Confeccionador de cabrestos' },
  { id: '833105 Confeccionador de caixas de papelão', descricao: 'Confeccionador de caixas de papelão' },
  { id: '833205 Confeccionador de caixas de papelão, a mão', descricao: 'Confeccionador de caixas de papelão, a mão' },
  { id: '833105 Confeccionador de caixas de papelão, a máquina', descricao: 'Confeccionador de caixas de papelão, a máquina' },
  { id: '768320 Confeccionador de calçados de couro', descricao: 'Confeccionador de calçados de couro' },
  { id: '811715 Confeccionador de câmaras de ar', descricao: 'Confeccionador de câmaras de ar' },
  { id: '768630 Confeccionador de carimbos', descricao: 'Confeccionador de carimbos' },
  { id: '768630 Confeccionador de carimbos de borracha', descricao: 'Confeccionador de carimbos de borracha' },
  { id: '768125 Confeccionador de chapéus de palha', descricao: 'Confeccionador de chapéus de palha' },
  { id: '766145 Confeccionador de clichês de flexografia', descricao: 'Confeccionador de clichês de flexografia' },
  { id: '776415 Confeccionador de escovas', descricao: 'Confeccionador de escovas' },
  { id: '776410 Confeccionador de escovas, pincéis e produtos similares (a mão)', descricao: 'Confeccionador de escovas, pincéis e produtos similares (a mão)' },
  { id: '776415 Confeccionador de escovas, pincéis e produtos similares (a máquina)', descricao: 'Confeccionador de escovas, pincéis e produtos similares (a máquina)' },
  { id: '812105 Confeccionador de estalinho', descricao: 'Confeccionador de estalinho' },
  { id: '776425 Confeccionador de esteiras', descricao: 'Confeccionador de esteiras' },
  { id: '776425 Confeccionador de esteiras de palha', descricao: 'Confeccionador de esteiras de palha' },
  { id: '812105 Confeccionador de foguetes', descricao: 'Confeccionador de foguetes' },
  { id: '742115 Confeccionador de instrumentos de corda', descricao: 'Confeccionador de instrumentos de corda' },
  { id: '742120 Confeccionador de instrumentos de percussão (pele, couro ou plástico)', descricao: 'Confeccionador de instrumentos de percussão (pele, couro ou plástico)' },
  { id: '742125 Confeccionador de instrumentos de sopro (madeira)', descricao: 'Confeccionador de instrumentos de sopro (madeira)' },
  { id: '742130 Confeccionador de instrumentos de sopro (metal)', descricao: 'Confeccionador de instrumentos de sopro (metal)' },
  { id: '823265 Confeccionador de lixas', descricao: 'Confeccionador de lixas' },
  { id: '766145 Confeccionador de matrizes tipográficas', descricao: 'Confeccionador de matrizes tipográficas' },
  { id: '776420 Confeccionador de móveis de vime, junco e bambu', descricao: 'Confeccionador de móveis de vime, junco e bambu' },
  { id: '742135 Confeccionador de órgão', descricao: 'Confeccionador de órgão' },
  { id: '742140 Confeccionador de piano', descricao: 'Confeccionador de piano' },
  { id: '776415 Confeccionador de pincéis', descricao: 'Confeccionador de pincéis' },
  { id: '776415 Confeccionador de pincéis, escovas e vassouras', descricao: 'Confeccionador de pincéis, escovas e vassouras' },
  { id: '811715 Confeccionador de pneumáticos', descricao: 'Confeccionador de pneumáticos' },
  { id: '811715 Confeccionador de pneus', descricao: 'Confeccionador de pneus' },
  { id: '833110 Confeccionador de produtos de papel e papelão', descricao: 'Confeccionador de produtos de papel e papelão' },
  { id: '833110 Confeccionador de sacolas de papel', descricao: 'Confeccionador de sacolas de papel' },
  { id: '833115 Confeccionador de sacos de celofane, a máquina', descricao: 'Confeccionador de sacos de celofane, a máquina' },
  { id: '833110 Confeccionador de sacos de papel', descricao: 'Confeccionador de sacos de papel' },
  { id: '742120 Confeccionador de tambores (música)', descricao: 'Confeccionador de tambores (música)' },
  { id: '761345 Confeccionador de tapetes e passadeiras, a máquina', descricao: 'Confeccionador de tapetes e passadeiras, a máquina' },
  { id: '776430 Confeccionador de vassouras de piaçaba, palha, cerdas plásticas e náilon', descricao: 'Confeccionador de vassouras de piaçaba, palha, cerdas plásticas e náilon' },
  { id: '765225 Confeccionador de velas náuticas, barracas e toldos', descricao: 'Confeccionador de velas náuticas, barracas e toldos' },
  { id: '811725 Confeccionador de velas por imersão', descricao: 'Confeccionador de velas por imersão' },
  { id: '811735 Confeccionador de velas por moldagem', descricao: 'Confeccionador de velas por moldagem' },
  { id: '7764 CONFECCIONADORES DE ARTEFATOS DE MADEIRA, MÓVEIS DE VIME E AFINS', descricao: 'CONFECCIONADORES DE ARTEFATOS DE MADEIRA, MÓVEIS DE VIME E AFINS' },
  { id: '7421 CONFECCIONADORES DE INSTRUMENTOS MUSICAIS', descricao: 'CONFECCIONADORES DE INSTRUMENTOS MUSICAIS' },
  { id: '848310 Confeiteiro', descricao: 'Confeiteiro' },
  { id: '414215 Conferente de carga e descarga', descricao: 'Conferente de carga e descarga' },
  { id: '414210 Conferente de controle de produção', descricao: 'Conferente de controle de produção' },
  { id: '411045 Conferente de documentação de importação e exportação', descricao: 'Conferente de documentação de importação e exportação' },
  { id: '411045 Conferente de exportação', descricao: 'Conferente de exportação' },
  { id: '414215 Conferente de faturas e notas fiscais', descricao: 'Conferente de faturas e notas fiscais' },
  { id: '414120 Conferente de logística', descricao: 'Conferente de logística' },
  { id: '413220 Conferente de serviços bancários', descricao: 'Conferente de serviços bancários' },
  { id: '414120 Conferente mercadoria (exceto carga e descarga)', descricao: 'Conferente mercadoria (exceto carga e descarga)' },
  { id: '414215 Conferente portuário', descricao: 'Conferente portuário' },
  { id: '516335 Conferente-expedidor de roupas (lavanderias)', descricao: 'Conferente-expedidor de roupas (lavanderias)' },
  { id: '263105 Confessor', descricao: 'Confessor' },
  { id: '765010 Conformador de chapéus', descricao: 'Conformador de chapéus' },
  { id: '263115 Consagrado', descricao: 'Consagrado' },
  { id: '111405 Conselheiro (diplomacia)', descricao: 'Conselheiro (diplomacia)' },
  { id: '241405 Conselheiro classista', descricao: 'Conselheiro classista' },
  { id: '263115 Conselheiro correicional eclesiástico', descricao: 'Conselheiro correicional eclesiástico' },
  { id: '515315 Conselheiro de dependente químico', descricao: 'Conselheiro de dependente químico' },
  { id: '263115 Conselheiro do tribunal eclesiástico', descricao: 'Conselheiro do tribunal eclesiástico' },
  { id: '371405 Conselheiro em acantonamento', descricao: 'Conselheiro em acantonamento' },
  { id: '241405 Conselheiro julgador', descricao: 'Conselheiro julgador' },
  { id: '241405 Conselheiro julgador relator', descricao: 'Conselheiro julgador relator' },
  { id: '241405 Conselheiro relator', descricao: 'Conselheiro relator' },
  { id: '515320 Conselheiro tutelar', descricao: 'Conselheiro tutelar' },
  { id: '2414 CONSELHEIROS JULGADORES', descricao: 'CONSELHEIROS JULGADORES' },
  { id: '731205 Consertador de equipamentos eletrônicos', descricao: 'Consertador de equipamentos eletrônicos' },
  { id: '992115 Consertador de pneus', descricao: 'Consertador de pneus' },
  { id: '954210 Consertador de rádio', descricao: 'Consertador de rádio' },
  { id: '768210 Consertador de roupas', descricao: 'Consertador de roupas' },
  { id: '214115 Conservador de edificações', descricao: 'Conservador de edificações' },
  { id: '715140 Conservador de estradas de rodagem', descricao: 'Conservador de estradas de rodagem' },
  { id: '514315 Conservador de fachadas', descricao: 'Conservador de fachadas' },
  { id: '732105 Conservador de linhas elétricas e telefônicas', descricao: 'Conservador de linhas elétricas e telefônicas' },
  { id: '631405 Conservador de pescado em barco de pesca (gelador industrial)', descricao: 'Conservador de pescado em barco de pesca (gelador industrial)' },
  { id: '631410 Conservador de pescado em barco de pesca (gelador profissional)', descricao: 'Conservador de pescado em barco de pesca (gelador profissional)' },
  { id: '991105 Conservador de via permanente (trilhos)', descricao: 'Conservador de via permanente (trilhos)' },
  { id: '262415 Conservador-restaurador de bens culturais', descricao: 'Conservador-restaurador de bens culturais' },
  { id: '9911 CONSERVADORES DE VIAS PERMANENTES (TRILHOS)', descricao: 'CONSERVADORES DE VIAS PERMANENTES (TRILHOS)' },
  { id: '710205 Construtor civil', descricao: 'Construtor civil' },
  { id: '374205 Construtor de cenários', descricao: 'Construtor de cenários' },
  { id: '241040 Consultor', descricao: 'Consultor' },
  { id: '252105 Consultor administrativo', descricao: 'Consultor administrativo' },
  { id: '351115 Consultor contábil (técnico)', descricao: 'Consultor contábil (técnico)' },
  { id: '421305 Consultor de cobrança', descricao: 'Consultor de cobrança' },
  { id: '375125 Consultor de estilo', descricao: 'Consultor de estilo' },
  { id: '261205 Consultor de informação', descricao: 'Consultor de informação' },
  { id: '252105 Consultor de organização', descricao: 'Consultor de organização' },
  { id: '354150 Consultor de produtos farmacêuticos', descricao: 'Consultor de produtos farmacêuticos' },
  { id: '212405 Consultor de sistemas de ti', descricao: 'Consultor de sistemas de ti' },
  { id: '212405 Consultor de tecnologia da informação', descricao: 'Consultor de tecnologia da informação' },
  { id: '521110 Consultor de vendas', descricao: 'Consultor de vendas' },
  { id: '354815 Consultor de viagem', descricao: 'Consultor de viagem' },
  { id: '515315 Consultor em dependência química', descricao: 'Consultor em dependência química' },
  { id: '375130 Consultor em organização', descricao: 'Consultor em organização' },
  { id: '122520 Consultor em turismo (turismólogo)', descricao: 'Consultor em turismo (turismólogo)' },
  { id: '354605 Consultor imobiliário', descricao: 'Consultor imobiliário' },
  { id: '241040 Consultor jurídico', descricao: 'Consultor jurídico' },
  { id: '214705 Consultor técnico - na extração mineral', descricao: 'Consultor técnico - na extração mineral' },
  { id: '222115 Consultor técnico em aqüicultura', descricao: 'Consultor técnico em aqüicultura' },
  { id: '222120 Consultor técnico em exploração vegetal', descricao: 'Consultor técnico em exploração vegetal' },
  { id: '222115 Consultor técnico em pesca', descricao: 'Consultor técnico em pesca' },
  { id: '252210 Contabilista', descricao: 'Contabilista' },
  { id: '252210 Contador', descricao: 'Contador' },
  { id: '262505 Contador de história', descricao: 'Contador de história' },
  { id: '252210 Contador judicial', descricao: 'Contador judicial' },
  { id: '2522 CONTADORES E AFINS', descricao: 'CONTADORES E AFINS' },
  { id: '322305 Contatólogo', descricao: 'Contatólogo' },
  { id: '261905 Continuista', descricao: 'Continuista' },
  { id: '412205 Contínuo', descricao: 'Contínuo' },
  { id: '4122 CONTÍNUOS', descricao: 'CONTÍNUOS' },
  { id: '261515 Contista', descricao: 'Contista' },
  { id: '376220 Contorcionista', descricao: 'Contorcionista' },
  { id: '10115 Contra-almirante', descricao: 'Contra-almirante' },
  { id: '860110 Contramestre (distribuição de energia elétrica, gás e água)', descricao: 'Contramestre (distribuição de energia elétrica, gás e água)' },
  { id: '830105 Contramestre (indústria de celulose, papel e papelão)', descricao: 'Contramestre (indústria de celulose, papel e papelão)' },
  { id: '720215 Contramestre (indústria de máquinas e outros equipamentos mecânicos)', descricao: 'Contramestre (indústria de máquinas e outros equipamentos mecânicos)' },
  { id: '860115 Contramestre (produção de energia elétrica, gás e captação de água)', descricao: 'Contramestre (produção de energia elétrica, gás e captação de água)' },
  { id: '760405 Contramestre da indústria de calçados', descricao: 'Contramestre da indústria de calçados' },
  { id: '860115 Contramestre de abastecimento, captação e distribuição de água', descricao: 'Contramestre de abastecimento, captação e distribuição de água' },
  { id: '760105 Contramestre de acabamento (indústria têxtil)', descricao: 'Contramestre de acabamento (indústria têxtil)' },
  { id: '341205 Contramestre de cabotagem', descricao: 'Contramestre de cabotagem' },
  { id: '910905 Contramestre de conservação de vias permanentes', descricao: 'Contramestre de conservação de vias permanentes' },
  { id: '760110 Contramestre de fiação (indústria têxtil)', descricao: 'Contramestre de fiação (indústria têxtil)' },
  { id: '720215 Contramestre de indústrias de máquinas', descricao: 'Contramestre de indústrias de máquinas' },
  { id: '760120 Contramestre de indústrias têxtil', descricao: 'Contramestre de indústrias têxtil' },
  { id: '760115 Contramestre de malharia (indústria têxtil)', descricao: 'Contramestre de malharia (indústria têxtil)' },
  { id: '860105 Contramestre de manutenção (serviço de esgotos sanitários)', descricao: 'Contramestre de manutenção (serviço de esgotos sanitários)' },
  { id: '860105 Contramestre de manutenção de serviço de esgoto', descricao: 'Contramestre de manutenção de serviço de esgoto' },
  { id: '860110 Contramestre de produção e distribuição de energia elétrica', descricao: 'Contramestre de produção e distribuição de energia elétrica' },
  { id: '760120 Contramestre de tecelagem (indústria têxtil)', descricao: 'Contramestre de tecelagem (indústria têxtil)' },
  { id: '722105 Contramestre ferreiro', descricao: 'Contramestre ferreiro' },
  { id: '782705 Contramestre fluvial', descricao: 'Contramestre fluvial' },
  { id: '841310 Controlador da velocidade de equipamentos na refinação de açúcar', descricao: 'Controlador da velocidade de equipamentos na refinação de açúcar' },
  { id: '517415 Controlador de acesso', descricao: 'Controlador de acesso' },
  { id: '414105 Controlador de almoxarifado', descricao: 'Controlador de almoxarifado' },
  { id: '862120 Controlador de caldeira', descricao: 'Controlador de caldeira' },
  { id: '821245 Controlador de carro torpedo', descricao: 'Controlador de carro torpedo' },
  { id: '342410 Controlador de centro de controle operacional', descricao: 'Controlador de centro de controle operacional' },
  { id: '342410 Controlador de centro de controle operacional (metrô e ferrovia)', descricao: 'Controlador de centro de controle operacional (metrô e ferrovia)' },
  { id: '841468 Controlador de dosadores de rações', descricao: 'Controlador de dosadores de rações' },
  { id: '391115 Controlador de entrada e saída', descricao: 'Controlador de entrada e saída' },
  { id: '411035 Controlador de estatística', descricao: 'Controlador de estatística' },
  { id: '821305 Controlador de laminação', descricao: 'Controlador de laminação' },
  { id: '377245 Controlador de líbero (vôlei)', descricao: 'Controlador de líbero (vôlei)' },
  { id: '414205 Controlador de mão-de-obra', descricao: 'Controlador de mão-de-obra' },
  { id: '862110 Controlador de motores - no transporte marítimo', descricao: 'Controlador de motores - no transporte marítimo' },
  { id: '410230 Controlador de orçamento', descricao: 'Controlador de orçamento' },
  { id: '821445 Controlador de pátio de sucata', descricao: 'Controlador de pátio de sucata' },
  { id: '519910 Controlador de pragas', descricao: 'Controlador de pragas' },
  { id: '414210 Controlador de produção', descricao: 'Controlador de produção' },
  { id: '373140 Controlador de programação', descricao: 'Controlador de programação' },
  { id: '391205 Controlador de qualidade', descricao: 'Controlador de qualidade' },
  { id: '342310 Controlador de serviço de transporte rodoviário', descricao: 'Controlador de serviço de transporte rodoviário' },
  { id: '342115 Controlador de serviços de máquinas e veículos', descricao: 'Controlador de serviços de máquinas e veículos' },
  { id: '414210 Controlador de serviços de produção', descricao: 'Controlador de serviços de produção' },
  { id: '342410 Controlador de tráfego', descricao: 'Controlador de tráfego' },
  { id: '711130 Controlador de tráfego -na mineração', descricao: 'Controlador de tráfego -na mineração' },
  { id: '342505 Controlador de tráfego aéreo', descricao: 'Controlador de tráfego aéreo' },
  { id: '342505 Controlador de vôo', descricao: 'Controlador de vôo' },
  { id: '373135 Controle mestre', descricao: 'Controle mestre' },
  { id: '252210 Controller (contador)', descricao: 'Controller (contador)' },
  { id: '111405 Coordenador do serviço público federal', descricao: 'Coordenador do serviço público federal' },
  { id: '410105 Coordenador administrativo', descricao: 'Coordenador administrativo' },
  { id: '239405 Coordenador auxiliar de curso', descricao: 'Coordenador auxiliar de curso' },
  { id: '142210 Coordenador de administração de pessoal', descricao: 'Coordenador de administração de pessoal' },
  { id: '377230 Coordenador de área (judô)', descricao: 'Coordenador de área (judô)' },
  { id: '760605 Coordenador de artes gráficas', descricao: 'Coordenador de artes gráficas' },
  { id: '113015 Coordenador de associação quilombola', descricao: 'Coordenador de associação quilombola' },
  { id: '760310 Coordenador de bordado', descricao: 'Coordenador de bordado' },
  { id: '720110 Coordenador de caldeiraria', descricao: 'Coordenador de caldeiraria' },
  { id: '377210 Coordenador de câmara de chegada', descricao: 'Coordenador de câmara de chegada' },
  { id: '420110 Coordenador de cobrança', descricao: 'Coordenador de cobrança' },
  { id: '142405 Coordenador de compras', descricao: 'Coordenador de compras' },
  { id: '252210 Coordenador de contabilidade', descricao: 'Coordenador de contabilidade' },
  { id: '760305 Coordenador de corte na confecção do vestuário', descricao: 'Coordenador de corte na confecção do vestuário' },
  { id: '760310 Coordenador de costura do vestuário', descricao: 'Coordenador de costura do vestuário' },
  { id: '142510 Coordenador de desenvolvimento de ti', descricao: 'Coordenador de desenvolvimento de ti' },
  { id: '412120 Coordenador de digitação', descricao: 'Coordenador de digitação' },
  { id: '239405 Coordenador de disciplina e área de estudo', descricao: 'Coordenador de disciplina e área de estudo' },
  { id: '239405 Coordenador de ensino', descricao: 'Coordenador de ensino' },
  { id: '114305 Coordenador de entidade religiosa', descricao: 'Coordenador de entidade religiosa' },
  { id: '131115 Coordenador de eventos', descricao: 'Coordenador de eventos' },
  { id: '224135 Coordenador de futebol', descricao: 'Coordenador de futebol' },
  { id: '142505 Coordenador de infraestrutura de tecnologia da informação', descricao: 'Coordenador de infraestrutura de tecnologia da informação' },
  { id: '910115 Coordenador de manutenção eletrônica', descricao: 'Coordenador de manutenção eletrônica' },
  { id: '910105 Coordenador de manutenção mecânica de sistemas operacionais', descricao: 'Coordenador de manutenção mecânica de sistemas operacionais' },
  { id: '411050 Coordenador de microcrédito', descricao: 'Coordenador de microcrédito' },
  { id: '253120 Coordenador de mídia', descricao: 'Coordenador de mídia' },
  { id: '141305 Coordenador de obras (construção civil)', descricao: 'Coordenador de obras (construção civil)' },
  { id: '142515 Coordenador de operação de tecnologia da informação', descricao: 'Coordenador de operação de tecnologia da informação' },
  { id: '215120 Coordenador de operações de combate à poluição no meio aquaviário', descricao: 'Coordenador de operações de combate à poluição no meio aquaviário' },
  { id: '373220 Coordenador de operações de sistemas de televisão e produtoras de vídeo', descricao: 'Coordenador de operações de sistemas de televisão e produtoras de vídeo' },
  { id: '342610 Coordenador de operações portuárias', descricao: 'Coordenador de operações portuárias' },
  { id: '239415 Coordenador de orientação pedagógica', descricao: 'Coordenador de orientação pedagógica' },
  { id: '141110 Coordenador de produção de madeira', descricao: 'Coordenador de produção de madeira' },
  { id: '373140 Coordenador de programação', descricao: 'Coordenador de programação' },
  { id: '142705 Coordenador de projetos de manutenção', descricao: 'Coordenador de projetos de manutenção' },
  { id: '142520 Coordenador de projetos de tecnologia da informação', descricao: 'Coordenador de projetos de tecnologia da informação' },
  { id: '131120 Coordenador de projetos sociais', descricao: 'Coordenador de projetos sociais' },
  { id: '424205 Coordenador de provas (concurso, avaliação, exame)', descricao: 'Coordenador de provas (concurso, avaliação, exame)' },
  { id: '424205 Coordenador de provas de local de aplicação (concurso, avaliação, exame)', descricao: 'Coordenador de provas de local de aplicação (concurso, avaliação, exame)' },
  { id: '424205 Coordenador de provas estadual (concurso, avaliação, exame)', descricao: 'Coordenador de provas estadual (concurso, avaliação, exame)' },
  { id: '424205 Coordenador de provas municipal (concurso, avaliação, exame)', descricao: 'Coordenador de provas municipal (concurso, avaliação, exame)' },
  { id: '420125 Coordenador de recepção', descricao: 'Coordenador de recepção' },
  { id: '141510 Coordenador de restaurante', descricao: 'Coordenador de restaurante' },
  { id: '142205 Coordenador de rh', descricao: 'Coordenador de rh' },
  { id: '142525 Coordenador de segurança da informação', descricao: 'Coordenador de segurança da informação' },
  { id: '239415 Coordenador de serviço de orientação pedagógica', descricao: 'Coordenador de serviço de orientação pedagógica' },
  { id: '142705 Coordenador de serviços de manutenção', descricao: 'Coordenador de serviços de manutenção' },
  { id: '142530 Coordenador de suporte técnico de tecnologia da informação', descricao: 'Coordenador de suporte técnico de tecnologia da informação' },
  { id: '342305 Coordenador de tráfego rodoviário', descricao: 'Coordenador de tráfego rodoviário' },
  { id: '354815 Coordenador de turismo', descricao: 'Coordenador de turismo' },
  { id: '830105 Coordenador de turno - área de fibras', descricao: 'Coordenador de turno - área de fibras' },
  { id: '830105 Coordenador de turno - área de papel', descricao: 'Coordenador de turno - área de papel' },
  { id: '141510 Coordenador de vendas (restaurante)', descricao: 'Coordenador de vendas (restaurante)' },
  { id: '111410 Coordenador do serviço público estadual e distrital', descricao: 'Coordenador do serviço público estadual e distrital' },
  { id: '239405 Coordenador escolar', descricao: 'Coordenador escolar' },
  { id: '131115 Coordenador executivo', descricao: 'Coordenador executivo' },
  { id: '122605 Coordenador geral de operações de correios', descricao: 'Coordenador geral de operações de correios' },
  { id: '111405 Coordenador geral do serviço público federal', descricao: 'Coordenador geral do serviço público federal' },
  { id: '239405 Coordenador pedagógico', descricao: 'Coordenador pedagógico' },
  { id: '950110 Coordenador técnico de eletromecânica', descricao: 'Coordenador técnico de eletromecânica' },
  { id: '513425 Copeiro', descricao: 'Copeiro' },
  { id: '513425 Copeiro de bar', descricao: 'Copeiro de bar' },
  { id: '513425 Copeiro de eventos', descricao: 'Copeiro de eventos' },
  { id: '513425 Copeiro de hotel', descricao: 'Copeiro de hotel' },
  { id: '513425 Copeiro de lanchonete', descricao: 'Copeiro de lanchonete' },
  { id: '513425 Copeiro de restaurante', descricao: 'Copeiro de restaurante' },
  { id: '513430 Copeiro de hospital', descricao: 'Copeiro de hospital' },
  { id: '766105 Copiador de chapa', descricao: 'Copiador de chapa' },
  { id: '766125 Copiador de chapas de off-set', descricao: 'Copiador de chapas de off-set' },
  { id: '766145 Copiador de clicheria', descricao: 'Copiador de clicheria' },
  { id: '766145 Copiador de clichês tipográficos', descricao: 'Copiador de clichês tipográficos' },
  { id: '766145 Copiador de desenhos sobre pedras litográficas', descricao: 'Copiador de desenhos sobre pedras litográficas' },
  { id: '415130 Copiador de documentos', descricao: 'Copiador de documentos' },
  { id: '766125 Copiador de fotolito', descricao: 'Copiador de fotolito' },
  { id: '768605 Copiador de texto na tipografia', descricao: 'Copiador de texto na tipografia' },
  { id: '766145 Copiador gravador', descricao: 'Copiador gravador' },
  { id: '318010 Copista de croqui', descricao: 'Copista de croqui' },
  { id: '514305 Cordeiro - limpeza de vidros', descricao: 'Cordeiro - limpeza de vidros' },
  { id: '514315 Cordista', descricao: 'Cordista' },
  { id: '262815 Coreógrafo', descricao: 'Coreógrafo' },
  { id: '262815 Coreógrafo bailarino', descricao: 'Coreógrafo bailarino' },
  { id: '30105 Coronel bombeiro militar', descricao: 'Coronel bombeiro militar' },
  { id: '10205 Coronel da aeronáutica', descricao: 'Coronel da aeronáutica' },
  { id: '20105 Coronel da polícia militar', descricao: 'Coronel da polícia militar' },
  { id: '30105 Coronel do corpo de bombeiros militar', descricao: 'Coronel do corpo de bombeiros militar' },
  { id: '10210 Coronel do exército', descricao: 'Coronel do exército' },
  { id: '377140 Corredor fundista', descricao: 'Corredor fundista' },
  { id: '768325 Correeiro (peças para animais)', descricao: 'Correeiro (peças para animais)' },
  { id: '141205 Correeiro - empregador', descricao: 'Correeiro - empregador' },
  { id: '141205 Correiro - empregador', descricao: 'Correiro - empregador' },
  { id: '261125 Correspondente de jornal', descricao: 'Correspondente de jornal' },
  { id: '261125 Correspondente de línguas estrangeiras', descricao: 'Correspondente de línguas estrangeiras' },
  { id: '253140 Corretor de anúncios (publicidade)', descricao: 'Corretor de anúncios (publicidade)' },
  { id: '354610 Corretor de cereais', descricao: 'Corretor de cereais' },
  { id: '342120 Corretor de frete', descricao: 'Corretor de frete' },
  { id: '354610 Corretor de grãos', descricao: 'Corretor de grãos' },
  { id: '354605 Corretor de imóveis', descricao: 'Corretor de imóveis' },
  { id: '253305 Corretor de mercadorias', descricao: 'Corretor de mercadorias' },
  { id: '253305 Corretor de mercadorias e futuros', descricao: 'Corretor de mercadorias e futuros' },
  { id: '354505 Corretor de seguros', descricao: 'Corretor de seguros' },
  { id: '253305 Corretor de valores, ativos financeiros, mercadorias e derivativos', descricao: 'Corretor de valores, ativos financeiros, mercadorias e derivativos' },
  { id: '3546 CORRETORES DE IMÓVEIS E DE GRÃOS', descricao: 'CORRETORES DE IMÓVEIS E DE GRÃOS' },
  { id: '3545 CORRETORES DE SEGUROS', descricao: 'CORRETORES DE SEGUROS' },
  { id: '2533 CORRETORES DE VALORES, ATIVOS FINANCEIROS, MERCADORIAS E DERIVATIVOS', descricao: 'CORRETORES DE VALORES, ATIVOS FINANCEIROS, MERCADORIAS E DERIVATIVOS' },
  { id: '141205 Corrieiro - empregador', descricao: 'Corrieiro - empregador' },
  { id: '766320 Cortador de papel à guilhotina', descricao: 'Cortador de papel à guilhotina' },
  { id: '773125 Cortador a serra circular', descricao: 'Cortador a serra circular' },
  { id: '622105 Cortador de arroz', descricao: 'Cortador de arroz' },
  { id: '765105 Cortador de artefatos de couro (exceto roupas e calçados)', descricao: 'Cortador de artefatos de couro (exceto roupas e calçados)' },
  { id: '632120 Cortador de árvores', descricao: 'Cortador de árvores' },
  { id: '828110 Cortador de barro', descricao: 'Cortador de barro' },
  { id: '766320 Cortador de bobina de papel', descricao: 'Cortador de bobina de papel' },
  { id: '764105 Cortador de calçados', descricao: 'Cortador de calçados' },
  { id: '768310 Cortador de calçados, a mão (exceto solas)', descricao: 'Cortador de calçados, a mão (exceto solas)' },
  { id: '764105 Cortador de calçados, a máquina (exceto solas e palmilhas)', descricao: 'Cortador de calçados, a máquina (exceto solas e palmilhas)' },
  { id: '622110 Cortador de cana-de-açúcar', descricao: 'Cortador de cana-de-açúcar' },
  { id: '764105 Cortador de capas de saltos para calçados', descricao: 'Cortador de capas de saltos para calçados' },
  { id: '848510 Cortador de carne em açougue', descricao: 'Cortador de carne em açougue' },
  { id: '848525 Cortador de carne em matadouro', descricao: 'Cortador de carne em matadouro' },
  { id: '632120 Cortador de cepos', descricao: 'Cortador de cepos' },
  { id: '773105 Cortador de chapas de madeiras', descricao: 'Cortador de chapas de madeiras' },
  { id: '765010 Cortador de chapéus', descricao: 'Cortador de chapéus' },
  { id: '842220 Cortador de charutos', descricao: 'Cortador de charutos' },
  { id: '764105 Cortador de couro na fabricação de calçados', descricao: 'Cortador de couro na fabricação de calçados' },
  { id: '752235 Cortador de cristais de óptica', descricao: 'Cortador de cristais de óptica' },
  { id: '622720 Cortador de dendê', descricao: 'Cortador de dendê' },
  { id: '724310 Cortador de ferro', descricao: 'Cortador de ferro' },
  { id: '773105 Cortador de laminados de madeira', descricao: 'Cortador de laminados de madeira' },
  { id: '632120 Cortador de lenha', descricao: 'Cortador de lenha' },
  { id: '632120 Cortador de madeira - na extração', descricao: 'Cortador de madeira - na extração' },
  { id: '712205 Cortador de mármore', descricao: 'Cortador de mármore' },
  { id: '724310 Cortador de metais', descricao: 'Cortador de metais' },
  { id: '632355 Cortador de ouricuri', descricao: 'Cortador de ouricuri' },
  { id: '632325 Cortador de palha de carnaúba', descricao: 'Cortador de palha de carnaúba' },
  { id: '632355 Cortador de palha de ouricuri', descricao: 'Cortador de palha de ouricuri' },
  { id: '632365 Cortador de palha de piaçava', descricao: 'Cortador de palha de piaçava' },
  { id: '764110 Cortador de palmilhas', descricao: 'Cortador de palmilhas' },
  { id: '766320 Cortador de papel', descricao: 'Cortador de papel' },
  { id: '766320 Cortador de papel em máquina eletrônica', descricao: 'Cortador de papel em máquina eletrônica' },
  { id: '712205 Cortador de pedras', descricao: 'Cortador de pedras' },
  { id: '712205 Cortador de pedras - lavra de pedras', descricao: 'Cortador de pedras - lavra de pedras' },
  { id: '711110 Cortador de pedras - na extração de pedras', descricao: 'Cortador de pedras - na extração de pedras' },
  { id: '632365 Cortador de piaçava', descricao: 'Cortador de piaçava' },
  { id: '622215 Cortador de rami', descricao: 'Cortador de rami' },
  { id: '763110 Cortador de roupas', descricao: 'Cortador de roupas' },
  { id: '763110 Cortador de roupas (couro e pele)', descricao: 'Cortador de roupas (couro e pele)' },
  { id: '848115 Cortador de salsichas', descricao: 'Cortador de salsichas' },
  { id: '764110 Cortador de solas e palmilhas, a máquina', descricao: 'Cortador de solas e palmilhas, a máquina' },
  { id: '764110 Cortador de solas, a máquina', descricao: 'Cortador de solas, a máquina' },
  { id: '724310 Cortador de sucata à oxigênio', descricao: 'Cortador de sucata à oxigênio' },
  { id: '765110 Cortador de tapeçaria', descricao: 'Cortador de tapeçaria' },
  { id: '765110 Cortador de tapetes', descricao: 'Cortador de tapetes' },
  { id: '828105 Cortador de telhas', descricao: 'Cortador de telhas' },
  { id: '828110 Cortador de tijolos', descricao: 'Cortador de tijolos' },
  { id: '752210 Cortador de vidro', descricao: 'Cortador de vidro' },
  { id: '752210 Cortador de vidro - no comércio - exclusive conta própria e empregador', descricao: 'Cortador de vidro - no comércio - exclusive conta própria e empregador' },
  { id: '716305 Cortador de vidros', descricao: 'Cortador de vidros' },
  { id: '632120 Cortador descascador de toras', descricao: 'Cortador descascador de toras' },
  { id: '724310 Cortador oxiacetilênico', descricao: 'Cortador oxiacetilênico' },
  { id: '765105 Cortador, a balancim', descricao: 'Cortador, a balancim' },
  { id: '765105 Cortador, a mão', descricao: 'Cortador, a mão' },
  { id: '516705 Cosmoanalista', descricao: 'Cosmoanalista' },
  { id: '213120 Cosmológo', descricao: 'Cosmológo' },
  { id: '768315 Costurador de artefatos de couro, a mão (exceto roupas e calçados)', descricao: 'Costurador de artefatos de couro, a mão (exceto roupas e calçados)' },
  { id: '765310 Costurador de artefatos de couro, a máquina (exceto roupas e calçados)', descricao: 'Costurador de artefatos de couro, a máquina (exceto roupas e calçados)' },
  { id: '764205 Costurador de calçados, a máquina', descricao: 'Costurador de calçados, a máquina' },
  { id: '768320 Costurador de calçados, à mão', descricao: 'Costurador de calçados, à mão' },
  { id: '784125 Costurador de fardos', descricao: 'Costurador de fardos' },
  { id: '765225 Costurador de lonas e encerados', descricao: 'Costurador de lonas e encerados' },
  { id: '763215 Costureira de máquina overloque', descricao: 'Costureira de máquina overloque' },
  { id: '763215 Costureira de máquina reta', descricao: 'Costureira de máquina reta' },
  { id: '763215 Costureira de máquinas industriais', descricao: 'Costureira de máquinas industriais' },
  { id: '763010 Costureira de peças sob encomenda', descricao: 'Costureira de peças sob encomenda' },
  { id: '763015 Costureira de reparação de roupas', descricao: 'Costureira de reparação de roupas' },
  { id: '763210 Costureira em geral', descricao: 'Costureira em geral' },
  { id: '763210 Costureiro de amostra', descricao: 'Costureiro de amostra' },
  { id: '765215 Costureiro de artefatos de tecido (brinquedos)', descricao: 'Costureiro de artefatos de tecido (brinquedos)' },
  { id: '764205 Costureiro de calçados a máquina', descricao: 'Costureiro de calçados a máquina' },
  { id: '765205 Costureiro de colchões', descricao: 'Costureiro de colchões' },
  { id: '763020 Costureiro de roupa de couro e pele', descricao: 'Costureiro de roupa de couro e pele' },
  { id: '763210 Costureiro de roupas (confecção em série)', descricao: 'Costureiro de roupas (confecção em série)' },
  { id: '763205 Costureiro de roupas de couro e pele, a máquina na confecção em série', descricao: 'Costureiro de roupas de couro e pele, a máquina na confecção em série' },
  { id: '763210 Costureiro na confecção em série', descricao: 'Costureiro na confecção em série' },
  { id: '765225 Costureiro velamista', descricao: 'Costureiro velamista' },
  { id: '763215 Costureiro, a máquina na confecção em série', descricao: 'Costureiro, a máquina na confecção em série' },
  { id: '622205 Cotonicultor', descricao: 'Cotonicultor' },
  { id: '612205 Cotonicultor - empregador', descricao: 'Cotonicultor - empregador' },
  { id: '516610 Coveiro', descricao: 'Coveiro' },
  { id: '848520 Coxãozeiro em matadouro', descricao: 'Coxãozeiro em matadouro' },
  { id: '848115 Cozedor de salsichas', descricao: 'Cozedor de salsichas' },
  { id: '841408 Cozinhador (conservação de alimentos)', descricao: 'Cozinhador (conservação de alimentos)' },
  { id: '841408 Cozinhador de alimentos', descricao: 'Cozinhador de alimentos' },
  { id: '632305 Cozinhador de andiroba', descricao: 'Cozinhador de andiroba' },
  { id: '841416 Cozinhador de carnes', descricao: 'Cozinhador de carnes' },
  { id: '632325 Cozinhador de cera de carnaúba - na extração', descricao: 'Cozinhador de cera de carnaúba - na extração' },
  { id: '841408 Cozinhador de conservas', descricao: 'Cozinhador de conservas' },
  { id: '841730 Cozinhador de extrato de cerveja', descricao: 'Cozinhador de extrato de cerveja' },
  { id: '841420 Cozinhador de frutas e legumes', descricao: 'Cozinhador de frutas e legumes' },
  { id: '841730 Cozinhador de malte', descricao: 'Cozinhador de malte' },
  { id: '841408 Cozinhador de molhos e condimentos', descricao: 'Cozinhador de molhos e condimentos' },
  { id: '841428 Cozinhador de pescado', descricao: 'Cozinhador de pescado' },
  { id: '632315 Cozinhador de vinho de bacaba', descricao: 'Cozinhador de vinho de bacaba' },
  { id: '513225 Cozinheiro de bordo', descricao: 'Cozinheiro de bordo' },
  { id: '841730 Cozinheiro de cerveja', descricao: 'Cozinheiro de cerveja' },
  { id: '513225 Cozinheiro de embarcações', descricao: 'Cozinheiro de embarcações' },
  { id: '513220 Cozinheiro de hospital', descricao: 'Cozinheiro de hospital' },
  { id: '513205 Cozinheiro de restaurante', descricao: 'Cozinheiro de restaurante' },
  { id: '513215 Cozinheiro de restaurante de indústria', descricao: 'Cozinheiro de restaurante de indústria' },
  { id: '513210 Cozinheiro do serviço doméstico', descricao: 'Cozinheiro do serviço doméstico' },
  { id: '513205 Cozinheiro geral', descricao: 'Cozinheiro geral' },
  { id: '513220 Cozinheiro hospitalar', descricao: 'Cozinheiro hospitalar' },
  { id: '513215 Cozinheiro industrial', descricao: 'Cozinheiro industrial' },
  { id: '841408 Cozinheiro salgador', descricao: 'Cozinheiro salgador' },
  { id: '5132 COZINHEIROS', descricao: 'COZINHEIROS' },
  { id: '751005 Cravador de jóias', descricao: 'Cravador de jóias' },
  { id: '724230 Cravador de metais', descricao: 'Cravador de metais' },
  { id: '722105 Craveiro (forjaria)', descricao: 'Craveiro (forjaria)' },
  { id: '751005 Cravejador de jóias', descricao: 'Cravejador de jóias' },
  { id: '751005 Cravejador de metais preciosos', descricao: 'Cravejador de metais preciosos' },
  { id: '751005 Cravejador de ourivesaria', descricao: 'Cravejador de ourivesaria' },
  { id: '331110 Crecheira', descricao: 'Crecheira' },
  { id: '516605 Crematório', descricao: 'Crematório' },
  { id: '623410 Criador de abelhas - exclusive conta própia e empregador', descricao: 'Criador de abelhas - exclusive conta própia e empregador' },
  { id: '613010 Criador de animais domésticos', descricao: 'Criador de animais domésticos' },
  { id: '613410 Criador de animais produtores de veneno', descricao: 'Criador de animais produtores de veneno' },
  { id: '613105 Criador de asininos e muares', descricao: 'Criador de asininos e muares' },
  { id: '613105 Criador de asininos e muares - empregador', descricao: 'Criador de asininos e muares - empregador' },
  { id: '613305 Criador de aves', descricao: 'Criador de aves' },
  { id: '613005 Criador de aves e bovinos', descricao: 'Criador de aves e bovinos' },
  { id: '623420 Criador de bicho-da-seda - conta própria', descricao: 'Criador de bicho-da-seda - conta própria' },
  { id: '613420 Criador de bicho-da-seda - empregador', descricao: 'Criador de bicho-da-seda - empregador' },
  { id: '613205 Criador de bode', descricao: 'Criador de bode' },
  { id: '613110 Criador de bovinos (corte)', descricao: 'Criador de bovinos (corte)' },
  { id: '613115 Criador de bovinos (leite)', descricao: 'Criador de bovinos (leite)' },
  { id: '613120 Criador de bubalinos (corte)', descricao: 'Criador de bubalinos (corte)' },
  { id: '613125 Criador de bubalinos (leite)', descricao: 'Criador de bubalinos (leite)' },
  { id: '613120 Criador de búfalos - corte', descricao: 'Criador de búfalos - corte' },
  { id: '613125 Criador de búfalos - leite', descricao: 'Criador de búfalos - leite' },
  { id: '613205 Criador de cabras', descricao: 'Criador de cabras' },
  { id: '613010 Criador de cachorros', descricao: 'Criador de cachorros' },
  { id: '613010 Criador de cães', descricao: 'Criador de cães' },
  { id: '631305 Criador de camarões', descricao: 'Criador de camarões' },
  { id: '613205 Criador de caprinos', descricao: 'Criador de caprinos' },
  { id: '613205 Criador de caprinos - empregador', descricao: 'Criador de caprinos - empregador' },
  { id: '613210 Criador de carneiro', descricao: 'Criador de carneiro' },
  { id: '613130 Criador de cavalo de raça', descricao: 'Criador de cavalo de raça' },
  { id: '613130 Criador de cavalos - conta própria', descricao: 'Criador de cavalos - conta própria' },
  { id: '613130 Criador de cavalos - empregador', descricao: 'Criador de cavalos - empregador' },
  { id: '613310 Criador de coelhos', descricao: 'Criador de coelhos' },
  { id: '253410 Criador de conteúdo digital', descricao: 'Criador de conteúdo digital' },
  { id: '374415 Criador de efeitos especiais', descricao: 'Criador de efeitos especiais' },
  { id: '374415 Criador de efeitos visuais', descricao: 'Criador de efeitos visuais' },
  { id: '613130 Criador de eqüideos eqüinos - empregador', descricao: 'Criador de eqüideos eqüinos - empregador' },
  { id: '613130 Criador de eqüínos', descricao: 'Criador de eqüínos' },
  { id: '613005 Criador de gado - bovino, caprino, suíno', descricao: 'Criador de gado - bovino, caprino, suíno' },
  { id: '613005 Criador de gado - eqüino, ovino, muar - conta própria', descricao: 'Criador de gado - eqüino, ovino, muar - conta própria' },
  { id: '613110 Criador de gado bovino (exceto gado leiteiro) - empregador', descricao: 'Criador de gado bovino (exceto gado leiteiro) - empregador' },
  { id: '613010 Criador de gatos', descricao: 'Criador de gatos' },
  { id: '631310 Criador de jacarés', descricao: 'Criador de jacarés' },
  { id: '613310 Criador de matrizes de coelhos', descricao: 'Criador de matrizes de coelhos' },
  { id: '631315 Criador de mexilhões', descricao: 'Criador de mexilhões' },
  { id: '613415 Criador de minhocas', descricao: 'Criador de minhocas' },
  { id: '631320 Criador de ostras', descricao: 'Criador de ostras' },
  { id: '613210 Criador de ovelhas - empregador', descricao: 'Criador de ovelhas - empregador' },
  { id: '613210 Criador de ovinos', descricao: 'Criador de ovinos' },
  { id: '613210 Criador de ovinos - empregador', descricao: 'Criador de ovinos - empregador' },
  { id: '631325 Criador de peixes', descricao: 'Criador de peixes' },
  { id: '631325 Criador de peixes ornamentais', descricao: 'Criador de peixes ornamentais' },
  { id: '613010 Criador de pequenos animais', descricao: 'Criador de pequenos animais' },
  { id: '613215 Criador de porcos - empregador', descricao: 'Criador de porcos - empregador' },
  { id: '631330 Criador de quelônios', descricao: 'Criador de quelônios' },
  { id: '631335 Criador de rãs', descricao: 'Criador de rãs' },
  { id: '613215 Criador de suínos', descricao: 'Criador de suínos' },
  { id: '613215 Criador de suínos - empregador', descricao: 'Criador de suínos - empregador' },
  { id: '631330 Criador de tartarugas', descricao: 'Criador de tartarugas' },
  { id: '613110 Criador de zebu', descricao: 'Criador de zebu' },
  { id: '613110 Criador de zebu - conta própria', descricao: 'Criador de zebu - conta própria' },
  { id: '613110 Criador de zebu - empregador', descricao: 'Criador de zebu - empregador' },
  { id: '613005 Criador em pecuária polivalente', descricao: 'Criador em pecuária polivalente' },
  { id: '6313 CRIADORES DE ANIMAIS AQUÁTICOS', descricao: 'CRIADORES DE ANIMAIS AQUÁTICOS' },
  { id: '613310 Criadores de coelhos integrados', descricao: 'Criadores de coelhos integrados' },
  { id: '325015 Criadores de perfumes', descricao: 'Criadores de perfumes' },
  { id: '241025 Criminalista', descricao: 'Criminalista' },
  { id: '752210 Cristaleiro (corte de vidros)', descricao: 'Cristaleiro (corte de vidros)' },
  { id: '516805 Cristalomante', descricao: 'Cristalomante' },
  { id: '261510 Crítico', descricao: 'Crítico' },
  { id: '261510 Crítico de artes plásticas', descricao: 'Crítico de artes plásticas' },
  { id: '261510 Crítico de cinema', descricao: 'Crítico de cinema' },
  { id: '261510 Crítico de dança', descricao: 'Crítico de dança' },
  { id: '261510 Crítico de jornal (ombudsman)', descricao: 'Crítico de jornal (ombudsman)' },
  { id: '261510 Crítico de música', descricao: 'Crítico de música' },
  { id: '261510 Crítico de rádio', descricao: 'Crítico de rádio' },
  { id: '261510 Crítico de teatro', descricao: 'Crítico de teatro' },
  { id: '261510 Crítico de televisão', descricao: 'Crítico de televisão' },
  { id: '261510 Crítico literário', descricao: 'Crítico literário' },
  { id: '261405 Crítico textual', descricao: 'Crítico textual' },
  { id: '768205 Criveira', descricao: 'Criveira' },
  { id: '768130 Crocheteiro', descricao: 'Crocheteiro' },
  { id: '768130 Crocheteiro, a mão', descricao: 'Crocheteiro, a mão' },
  { id: '723225 Cromador de metais', descricao: 'Cromador de metais' },
  { id: '311715 Cromista', descricao: 'Cromista' },
  { id: '261125 Cronista', descricao: 'Cronista' },
  { id: '261515 Cronista de ficção', descricao: 'Cronista de ficção' },
  { id: '261520 Cronista de não ficção', descricao: 'Cronista de não ficção' },
  { id: '391105 Cronoanalista', descricao: 'Cronoanalista' },
  { id: '391110 Cronometrista', descricao: 'Cronometrista' },
  { id: '377215 Cronometrista (basquete)', descricao: 'Cronometrista (basquete)' },
  { id: '377225 Cronometrista (futebol de salão)', descricao: 'Cronometrista (futebol de salão)' },
  { id: '377230 Cronometrista (judô)', descricao: 'Cronometrista (judô)' },
  { id: '377235 Cronometrista (karatê)', descricao: 'Cronometrista (karatê)' },
  { id: '377240 Cronometrista de pólo aquático', descricao: 'Cronometrista de pólo aquático' },
  { id: '377240 Cronometrista de trinta e cinco segundos (poló aquático)', descricao: 'Cronometrista de trinta e cinco segundos (poló aquático)' },
  { id: '632110 Cubador de madeira', descricao: 'Cubador de madeira' },
  { id: '516210 Cuidador de pessoas idosas e dependentes', descricao: 'Cuidador de pessoas idosas e dependentes' },
  { id: '623020 Cuidador de animais', descricao: 'Cuidador de animais' },
  { id: '516210 Cuidador de idosos', descricao: 'Cuidador de idosos' },
  { id: '516210 Cuidador de idosos domiciliar', descricao: 'Cuidador de idosos domiciliar' },
  { id: '516210 Cuidador de idosos institucional', descricao: 'Cuidador de idosos institucional' },
  { id: '516220 Cuidador em saúde', descricao: 'Cuidador em saúde' },
  { id: '5162 CUIDADORES DE CRIANÇAS, JOVENS, ADULTOS E IDOSOS', descricao: 'CUIDADORES DE CRIANÇAS, JOVENS, ADULTOS E IDOSOS' },
  { id: '622210 Cultivador de agave - conta própria', descricao: 'Cultivador de agave - conta própria' },
  { id: '622210 Cultivador de agave - exclusive conta própria e empregador', descricao: 'Cultivador de agave - exclusive conta própria e empregador' },
  { id: '622205 Cultivador de algodão - conta própria', descricao: 'Cultivador de algodão - conta própria' },
  { id: '622205 Cultivador de algodão - exclusive conta própria e empregador', descricao: 'Cultivador de algodão - exclusive conta própria e empregador' },
  { id: '622020 Cultivador de cultura permanente', descricao: 'Cultivador de cultura permanente' },
  { id: '622020 Cultivador de cultura temporária', descricao: 'Cultivador de cultura temporária' },
  { id: '622620 Cultivador de fumo - exclusive conta própria e empregador', descricao: 'Cultivador de fumo - exclusive conta própria e empregador' },
  { id: '622625 Cultivador de guaraná - exclusive conta própria e empregador', descricao: 'Cultivador de guaraná - exclusive conta própria e empregador' },
  { id: '632340 Cultivador de malva - exclusive conta própria e empregador', descricao: 'Cultivador de malva - exclusive conta própria e empregador' },
  { id: '622215 Cultivador de rami - conta própria', descricao: 'Cultivador de rami - conta própria' },
  { id: '622215 Cultivador de rami - exclusive conta própria e empregador', descricao: 'Cultivador de rami - exclusive conta própria e empregador' },
  { id: '622210 Cultivador de sisal - conta própria', descricao: 'Cultivador de sisal - conta própria' },
  { id: '622210 Cultivador de sisal - exclusive conta própria e empregador', descricao: 'Cultivador de sisal - exclusive conta própria e empregador' },
  { id: '513415 Cumim', descricao: 'Cumim' },
  { id: '711115 Cunhador - na extração de pedras', descricao: 'Cunhador - na extração de pedras' },
  { id: '613310 Cunicultor', descricao: 'Cunicultor' },
  { id: '623320 Cunicultor - exclusive conta própria e empregador', descricao: 'Cunicultor - exclusive conta própria e empregador' },
  { id: '263105 Cura', descricao: 'Cura' },
  { id: '621005 Curador de animais - na agropecuária', descricao: 'Curador de animais - na agropecuária' },
  { id: '263105 Curimbeiro', descricao: 'Curimbeiro' },
  { id: '631020 Curraleiro de pesca artesanal de peixes e camarões', descricao: 'Curraleiro de pesca artesanal de peixes e camarões' },
  { id: '762205 Curtidor (couros e peles)', descricao: 'Curtidor (couros e peles)' },
  { id: '141205 Curtidor - empregador', descricao: 'Curtidor - empregador' },
  { id: '762205 Curtidor de couro', descricao: 'Curtidor de couro' },
  { id: '752120 Curvador de tubos de vidro', descricao: 'Curvador de tubos de vidro' },
  { id: '141410 Cuteleiro (comércio varejista)', descricao: 'Cuteleiro (comércio varejista)' },
  { id: '721310 Cuteleiro (na fabricação)', descricao: 'Cuteleiro (na fabricação)' },
  { id: '263105 Dabôce', descricao: 'Dabôce' },
  { id: '263105 Dada voduno', descricao: 'Dada voduno' },
  { id: '263105 Dáia', descricao: 'Dáia' },
  { id: '263105 Daiosho', descricao: 'Daiosho' },
  { id: '262810 Dançarino', descricao: 'Dançarino' },
  { id: '376105 Dançarino brincante', descricao: 'Dançarino brincante' },
  { id: '376105 Dançarino de danças de raiz', descricao: 'Dançarino de danças de raiz' },
  { id: '376105 Dançarino de danças folclóricas', descricao: 'Dançarino de danças folclóricas' },
  { id: '376105 Dançarino de danças rituais', descricao: 'Dançarino de danças rituais' },
  { id: '376110 Dançarino de rua', descricao: 'Dançarino de rua' },
  { id: '376110 Dançarino de salão', descricao: 'Dançarino de salão' },
  { id: '376110 Dançarino popular', descricao: 'Dançarino popular' },
  { id: '376105 Dançarino tradicional', descricao: 'Dançarino tradicional' },
  { id: '376110 Dançarinos de danças parafolclóricas', descricao: 'Dançarinos de danças parafolclóricas' },
  { id: '376110 Dançarinos étnicos', descricao: 'Dançarinos étnicos' },
  { id: '376110 Dançarinos populares', descricao: 'Dançarinos populares' },
  { id: '3761 DANÇARINOS TRADICIONAIS E POPULARES', descricao: 'DANÇARINOS TRADICIONAIS E POPULARES' },
  { id: '412105 Datilógrafo', descricao: 'Datilógrafo' },
  { id: '351815 Datiloscopista', descricao: 'Datiloscopista' },
  { id: '212305 Dba', descricao: 'Dba' },
  { id: '764115 Debruador de calçados', descricao: 'Debruador de calçados' },
  { id: '622020 Debulhador - na cultura', descricao: 'Debulhador - na cultura' },
  { id: '752405 Decalcador em cerâmica', descricao: 'Decalcador em cerâmica' },
  { id: '723205 Decapador', descricao: 'Decapador' },
  { id: '765010 Decatizador', descricao: 'Decatizador' },
  { id: '262505 Declamador', descricao: 'Declamador' },
  { id: '752405 Decorador ceramista', descricao: 'Decorador ceramista' },
  { id: '375120 Decorador com balões', descricao: 'Decorador com balões' },
  { id: '375120 Decorador de arte com balões', descricao: 'Decorador de arte com balões' },
  { id: '375120 Decorador de arte floral', descricao: 'Decorador de arte floral' },
  { id: '752405 Decorador de cerâmica', descricao: 'Decorador de cerâmica' },
  { id: '768125 Decorador de chapéus de palha', descricao: 'Decorador de chapéus de palha' },
  { id: '375120 Decorador de eventos', descricao: 'Decorador de eventos' },
  { id: '375120 Decorador de festas', descricao: 'Decorador de festas' },
  { id: '375120 Decorador de festas infantis', descricao: 'Decorador de festas infantis' },
  { id: '262905 Decorador de interiores', descricao: 'Decorador de interiores' },
  { id: '262905 Decorador de interiores de nível superior', descricao: 'Decorador de interiores de nível superior' },
  { id: '375120 Decorador de lycra tensionada', descricao: 'Decorador de lycra tensionada' },
  { id: '752410 Decorador de vidro', descricao: 'Decorador de vidro' },
  { id: '752410 Decorador de vidro a decalque', descricao: 'Decorador de vidro a decalque' },
  { id: '752410 Decorador de vidro a pistola', descricao: 'Decorador de vidro a pistola' },
  { id: '752415 Decorador de vidro à pincel', descricao: 'Decorador de vidro à pincel' },
  { id: '519910 Dedetizador', descricao: 'Dedetizador' },
  { id: '374145 Dee jay', descricao: 'Dee jay' },
  { id: '142345 Defensor de interesses ( relações governamentais)', descricao: 'Defensor de interesses ( relações governamentais)' },
  { id: '242405 Defensor público', descricao: 'Defensor público' },
  { id: '242405 Defensor público estadual', descricao: 'Defensor público estadual' },
  { id: '242405 Defensor público federal', descricao: 'Defensor público federal' },
  { id: '2424 DEFENSORES PÚBLICOS E PROCURADORES DA ASSISTÊNCIA JUDICIÁRIA', descricao: 'DEFENSORES PÚBLICOS E PROCURADORES DA ASSISTÊNCIA JUDICIÁRIA' },
  { id: '848105 Defumador de carnes e pescados', descricao: 'Defumador de carnes e pescados' },
  { id: '632205 Defumador de látex', descricao: 'Defumador de látex' },
  { id: '848105 Defumador de pescados', descricao: 'Defumador de pescados' },
  { id: '848505 Degolador em matadouro', descricao: 'Degolador em matadouro' },
  { id: '848420 Degustador de bebidas', descricao: 'Degustador de bebidas' },
  { id: '848405 Degustador de café', descricao: 'Degustador de café' },
  { id: '848410 Degustador de chá', descricao: 'Degustador de chá' },
  { id: '842235 Degustador de charutos', descricao: 'Degustador de charutos' },
  { id: '848415 Degustador de derivados de cacau', descricao: 'Degustador de derivados de cacau' },
  { id: '513410 Degustador de vinho', descricao: 'Degustador de vinho' },
  { id: '848420 Degustador de vinhos ou licores', descricao: 'Degustador de vinhos ou licores' },
  { id: '242305 Delegado de polícia', descricao: 'Delegado de polícia' },
  { id: '242305 Delegado distrital de polícia', descricao: 'Delegado distrital de polícia' },
  { id: '242305 Delegado regional de polícia', descricao: 'Delegado regional de polícia' },
  { id: '2423 DELEGADOS DE POLÍCIA', descricao: 'DELEGADOS DE POLÍCIA' },
  { id: '211210 Demógrafo', descricao: 'Demógrafo' },
  { id: '717005 Demolidor de casas', descricao: 'Demolidor de casas' },
  { id: '717005 Demolidor de edificações', descricao: 'Demolidor de edificações' },
  { id: '717005 Demolidor de prédios', descricao: 'Demolidor de prédios' },
  { id: '521120 Demonstrador de mercadorias', descricao: 'Demonstrador de mercadorias' },
  { id: '612720 Dendeicultor', descricao: 'Dendeicultor' },
  { id: '632110 Dendrometrista', descricao: 'Dendrometrista' },
  { id: '223208 Dentista', descricao: 'Dentista' },
  { id: '223284 Dentista da dor', descricao: 'Dentista da dor' },
  { id: '223240 Dentista de aparelho', descricao: 'Dentista de aparelho' },
  { id: '223236 Dentista de criança', descricao: 'Dentista de criança' },
  { id: '223288 Dentista de cuidados especiais', descricao: 'Dentista de cuidados especiais' },
  { id: '223288 Dentista de excepcionais', descricao: 'Dentista de excepcionais' },
  { id: '223248 Dentista de gengivas', descricao: 'Dentista de gengivas' },
  { id: '223228 Dentista de idosos', descricao: 'Dentista de idosos' },
  { id: '223288 Dentista de pacientes com necessidades especiais', descricao: 'Dentista de pacientes com necessidades especiais' },
  { id: '223288 Dentista de pacientes especiais', descricao: 'Dentista de pacientes especiais' },
  { id: '223272 Dentista de saúde coletiva', descricao: 'Dentista de saúde coletiva' },
  { id: '223228 Dentista de terceira idade', descricao: 'Dentista de terceira idade' },
  { id: '223288 Dentistas de deficientes', descricao: 'Dentistas de deficientes' },
  { id: '223280 Dentística restauradora', descricao: 'Dentística restauradora' },
  { id: '762205 Depilador de couros e peles', descricao: 'Depilador de couros e peles' },
  { id: '351410 Depositário público', descricao: 'Depositário público' },
  { id: '111115 Deputado estadual e distrital', descricao: 'Deputado estadual e distrital' },
  { id: '111110 Deputado federal', descricao: 'Deputado federal' },
  { id: '263105 Deré', descricao: 'Deré' },
  { id: '225135 Dermatologista', descricao: 'Dermatologista' },
  { id: '632120 Derrubador - na extração de madeira', descricao: 'Derrubador - na extração de madeira' },
  { id: '632120 Derrubador de árvores', descricao: 'Derrubador de árvores' },
  { id: '828110 Desbarbeador de tijolos', descricao: 'Desbarbeador de tijolos' },
  { id: '762110 Descarnador de couros e peles, à maquina', descricao: 'Descarnador de couros e peles, à maquina' },
  { id: '848525 Descarnador em matadouro', descricao: 'Descarnador em matadouro' },
  { id: '622020 Descascador - na cultura', descricao: 'Descascador - na cultura' },
  { id: '632125 Descascador de árvores', descricao: 'Descascador de árvores' },
  { id: '519910 Descupinizador', descricao: 'Descupinizador' },
  { id: '773105 Desdobrador de madeira', descricao: 'Desdobrador de madeira' },
  { id: '111325 Desembargadores', descricao: 'Desembargadores' },
  { id: '828110 Desempenador em olaria', descricao: 'Desempenador em olaria' },
  { id: '722325 Desenformador - na fundição', descricao: 'Desenformador - na fundição' },
  { id: '828110 Desenfornador de tijolo e telha', descricao: 'Desenfornador de tijolo e telha' },
  { id: '318405 Desenhista técnico de arte-final', descricao: 'Desenhista técnico de arte-final' },
  { id: '318505 Desenhista calculista', descricao: 'Desenhista calculista' },
  { id: '318510 Desenhista calculista na construção civil', descricao: 'Desenhista calculista na construção civil' },
  { id: '318010 Desenhista copista', descricao: 'Desenhista copista' },
  { id: '318010 Desenhista copista (cad)', descricao: 'Desenhista copista (cad)' },
  { id: '318105 Desenhista copista (arquitetura)', descricao: 'Desenhista copista (arquitetura)' },
  { id: '318105 Desenhista de arquitetura', descricao: 'Desenhista de arquitetura' },
  { id: '318205 Desenhista de carrocerias', descricao: 'Desenhista de carrocerias' },
  { id: '318110 Desenhista de cartografia', descricao: 'Desenhista de cartografia' },
  { id: '318410 Desenhista de desenho animado', descricao: 'Desenhista de desenho animado' },
  { id: '262410 Desenhista de editorial', descricao: 'Desenhista de editorial' },
  { id: '318430 Desenhista de embalagens', descricao: 'Desenhista de embalagens' },
  { id: '318115 Desenhista de estrutura metálica (construção civil)', descricao: 'Desenhista de estrutura metálica (construção civil)' },
  { id: '318205 Desenhista de estruturas metálicas (mecânica)', descricao: 'Desenhista de estruturas metálicas (mecânica)' },
  { id: '318205 Desenhista de ferramentas (mecânica)', descricao: 'Desenhista de ferramentas (mecânica)' },
  { id: '318410 Desenhista de filatelia (selos)', descricao: 'Desenhista de filatelia (selos)' },
  { id: '318420 Desenhista de fotoestamparia', descricao: 'Desenhista de fotoestamparia' },
  { id: '262410 Desenhista de identidade visual', descricao: 'Desenhista de identidade visual' },
  { id: '318120 Desenhista de instalações de combate a incêndio', descricao: 'Desenhista de instalações de combate a incêndio' },
  { id: '318120 Desenhista de instalações hidráulicas', descricao: 'Desenhista de instalações hidráulicas' },
  { id: '318205 Desenhista de instalações mecânicas', descricao: 'Desenhista de instalações mecânicas' },
  { id: '318305 Desenhista de leiaute de circuito impresso', descricao: 'Desenhista de leiaute de circuito impresso' },
  { id: '318430 Desenhista de leiautes', descricao: 'Desenhista de leiautes' },
  { id: '318425 Desenhista de móveis', descricao: 'Desenhista de móveis' },
  { id: '262410 Desenhista de páginas da internet (web designer)', descricao: 'Desenhista de páginas da internet (web designer)' },
  { id: '262420 Desenhista de produto (artigos esportivos)', descricao: 'Desenhista de produto (artigos esportivos)' },
  { id: '262420 Desenhista de produto (brinquedos)', descricao: 'Desenhista de produto (brinquedos)' },
  { id: '262420 Desenhista de produto (construção civil)', descricao: 'Desenhista de produto (construção civil)' },
  { id: '262420 Desenhista de produto (cuidados pessoais)', descricao: 'Desenhista de produto (cuidados pessoais)' },
  { id: '262420 Desenhista de produto (eletroeletrônicos e eletrodomésticos)', descricao: 'Desenhista de produto (eletroeletrônicos e eletrodomésticos)' },
  { id: '262420 Desenhista de produto (embalagem)', descricao: 'Desenhista de produto (embalagem)' },
  { id: '262420 Desenhista de produto (iluminação)', descricao: 'Desenhista de produto (iluminação)' },
  { id: '262420 Desenhista de produto (jóias)', descricao: 'Desenhista de produto (jóias)' },
  { id: '262420 Desenhista de produto (máquinas e equipamentos)', descricao: 'Desenhista de produto (máquinas e equipamentos)' },
  { id: '262420 Desenhista de produto (material promocional)', descricao: 'Desenhista de produto (material promocional)' },
  { id: '262420 Desenhista de produto (mobiliário)', descricao: 'Desenhista de produto (mobiliário)' },
  { id: '262420 Desenhista de produto (transporte)', descricao: 'Desenhista de produto (transporte)' },
  { id: '262420 Desenhista de produto (utensílios domésticos e escritório)', descricao: 'Desenhista de produto (utensílios domésticos e escritório)' },
  { id: '374120 Desenhista de som', descricao: 'Desenhista de som' },
  { id: '318205 Desenhista de tubulação (mecânica)', descricao: 'Desenhista de tubulação (mecânica)' },
  { id: '318015 Desenhista detalhista', descricao: 'Desenhista detalhista' },
  { id: '318015 Desenhista detalhista (cad)', descricao: 'Desenhista detalhista (cad)' },
  { id: '318505 Desenhista detalhista na arquitetura', descricao: 'Desenhista detalhista na arquitetura' },
  { id: '318510 Desenhista detalhista na construção civil', descricao: 'Desenhista detalhista na construção civil' },
  { id: '262410 Desenhista gráfico de embalagem', descricao: 'Desenhista gráfico de embalagem' },
  { id: '262410 Desenhista gráfico de sinalização', descricao: 'Desenhista gráfico de sinalização' },
  { id: '262410 Desenhista gráfico de superfície', descricao: 'Desenhista gráfico de superfície' },
  { id: '262410 Desenhista gráfico promocional', descricao: 'Desenhista gráfico promocional' },
  { id: '262425 Desenhista industrial de acessórios', descricao: 'Desenhista industrial de acessórios' },
  { id: '262425 Desenhista industrial de calçados', descricao: 'Desenhista industrial de calçados' },
  { id: '262420 Desenhista industrial de produto (designer de produto)', descricao: 'Desenhista industrial de produto (designer de produto)' },
  { id: '262425 Desenhista industrial de produto de moda (designer de moda)', descricao: 'Desenhista industrial de produto de moda (designer de moda)' },
  { id: '262410 Desenhista industrial gráfico (designer gráfico)', descricao: 'Desenhista industrial gráfico (designer gráfico)' },
  { id: '262425 Desenhista industrial têxtil', descricao: 'Desenhista industrial têxtil' },
  { id: '239435 Desenhista instrucional', descricao: 'Desenhista instrucional' },
  { id: '318415 Desenhista letrista', descricao: 'Desenhista letrista' },
  { id: '318430 Desenhista maquetista', descricao: 'Desenhista maquetista' },
  { id: '318205 Desenhista mecânico', descricao: 'Desenhista mecânico' },
  { id: '318505 Desenhista projetista de arquitetura', descricao: 'Desenhista projetista de arquitetura' },
  { id: '318710 Desenhista projetista de circuitos impressos', descricao: 'Desenhista projetista de circuitos impressos' },
  { id: '318510 Desenhista projetista de construção civil', descricao: 'Desenhista projetista de construção civil' },
  { id: '318705 Desenhista projetista de eletricidade', descricao: 'Desenhista projetista de eletricidade' },
  { id: '318610 Desenhista projetista de ferramentas', descricao: 'Desenhista projetista de ferramentas' },
  { id: '318605 Desenhista projetista de máquinas', descricao: 'Desenhista projetista de máquinas' },
  { id: '318610 Desenhista projetista de moldes', descricao: 'Desenhista projetista de moldes' },
  { id: '318610 Desenhista projetista de produtos (mecânica)', descricao: 'Desenhista projetista de produtos (mecânica)' },
  { id: '318705 Desenhista projetista eletroeletrônico', descricao: 'Desenhista projetista eletroeletrônico' },
  { id: '318710 Desenhista projetista eletrônico', descricao: 'Desenhista projetista eletrônico' },
  { id: '318610 Desenhista projetista mecânico', descricao: 'Desenhista projetista mecânico' },
  { id: '318005 Desenhista técnico', descricao: 'Desenhista técnico' },
  { id: '318105 Desenhista técnico (arquitetura)', descricao: 'Desenhista técnico (arquitetura)' },
  { id: '318405 Desenhista técnico (artes gráficas)', descricao: 'Desenhista técnico (artes gráficas)' },
  { id: '318310 Desenhista técnico (calefação, ventilação e refrigeração)', descricao: 'Desenhista técnico (calefação, ventilação e refrigeração)' },
  { id: '318110 Desenhista técnico (cartografia)', descricao: 'Desenhista técnico (cartografia)' },
  { id: '318115 Desenhista técnico (construção civil)', descricao: 'Desenhista técnico (construção civil)' },
  { id: '318305 Desenhista técnico (eletricidade e eletrônica)', descricao: 'Desenhista técnico (eletricidade e eletrônica)' },
  { id: '318410 Desenhista técnico (ilustrações artísticas)', descricao: 'Desenhista técnico (ilustrações artísticas)' },
  { id: '318415 Desenhista técnico (ilustrações técnicas)', descricao: 'Desenhista técnico (ilustrações técnicas)' },
  { id: '318420 Desenhista técnico (indústria têxtil)', descricao: 'Desenhista técnico (indústria têxtil)' },
  { id: '318120 Desenhista técnico (instalações hidrossanitárias)', descricao: 'Desenhista técnico (instalações hidrossanitárias)' },
  { id: '318425 Desenhista técnico (mobiliário)', descricao: 'Desenhista técnico (mobiliário)' },
  { id: '318210 Desenhista técnico aeronáutico', descricao: 'Desenhista técnico aeronáutico' },
  { id: '318005 Desenhista técnico auxiliar', descricao: 'Desenhista técnico auxiliar' },
  { id: '318210 Desenhista técnico de aeronaves', descricao: 'Desenhista técnico de aeronaves' },
  { id: '318430 Desenhista técnico de embalagens, maquetes e leiautes', descricao: 'Desenhista técnico de embalagens, maquetes e leiautes' },
  { id: '318115 Desenhista técnico de engenharia civil', descricao: 'Desenhista técnico de engenharia civil' },
  { id: '318205 Desenhista técnico de ferramentas', descricao: 'Desenhista técnico de ferramentas' },
  { id: '318120 Desenhista técnico de hidráulica', descricao: 'Desenhista técnico de hidráulica' },
  { id: '318205 Desenhista técnico de máquinas', descricao: 'Desenhista técnico de máquinas' },
  { id: '318110 Desenhista técnico de topografia', descricao: 'Desenhista técnico de topografia' },
  { id: '318205 Desenhista técnico de tubulação', descricao: 'Desenhista técnico de tubulação' },
  { id: '318015 Desenhista técnico detalhista', descricao: 'Desenhista técnico detalhista' },
  { id: '318305 Desenhista técnico eletromecânica', descricao: 'Desenhista técnico eletromecânica' },
  { id: '318310 Desenhista técnico em calefação', descricao: 'Desenhista técnico em calefação' },
  { id: '318305 Desenhista técnico em eletricidade', descricao: 'Desenhista técnico em eletricidade' },
  { id: '318305 Desenhista técnico em eletroeletrônica', descricao: 'Desenhista técnico em eletroeletrônica' },
  { id: '318305 Desenhista técnico em eletrônica', descricao: 'Desenhista técnico em eletrônica' },
  { id: '318310 Desenhista técnico em refrigeração', descricao: 'Desenhista técnico em refrigeração' },
  { id: '318310 Desenhista técnico em ventilação', descricao: 'Desenhista técnico em ventilação' },
  { id: '318205 Desenhista técnico mecânico', descricao: 'Desenhista técnico mecânico' },
  { id: '318215 Desenhista técnico naval', descricao: 'Desenhista técnico naval' },
  { id: '318420 Desenhista têxtil', descricao: 'Desenhista têxtil' },
  { id: '3187 DESENHISTAS PROJETISTAS DA ELETRÔNICA', descricao: 'DESENHISTAS PROJETISTAS DA ELETRÔNICA' },
  { id: '3186 DESENHISTAS PROJETISTAS DA MECÂNICA', descricao: 'DESENHISTAS PROJETISTAS DA MECÂNICA' },
  { id: '3185 DESENHISTAS PROJETISTAS DE CONSTRUÇÃO CIVIL E ARQUITETURA', descricao: 'DESENHISTAS PROJETISTAS DE CONSTRUÇÃO CIVIL E ARQUITETURA' },
  { id: '3188 DESENHISTAS PROJETISTAS E MODELISTAS DE PRODUTOS E SERVIÇOS DIVERSOS', descricao: 'DESENHISTAS PROJETISTAS E MODELISTAS DE PRODUTOS E SERVIÇOS DIVERSOS' },
  { id: '3181 DESENHISTAS TÉCNICOS DA CONSTRUÇÃO CIVIL E ARQUITETURA', descricao: 'DESENHISTAS TÉCNICOS DA CONSTRUÇÃO CIVIL E ARQUITETURA' },
  { id: '3182 DESENHISTAS TÉCNICOS DA MECÂNICA', descricao: 'DESENHISTAS TÉCNICOS DA MECÂNICA' },
  { id: '3184 DESENHISTAS TÉCNICOS DE PRODUTOS E SERVIÇOS DIVERSOS', descricao: 'DESENHISTAS TÉCNICOS DE PRODUTOS E SERVIÇOS DIVERSOS' },
  { id: '3183 DESENHISTAS TÉCNICOS EM ELETRICIDADE, ELETRÔNICA, ELETROMECÂNICA, CALEFAÇÃO, VENTILAÇÃO E', descricao: 'DESENHISTAS TÉCNICOS EM ELETRICIDADE, ELETRÔNICA, ELETROMECÂNICA, CALEFAÇÃO, VENTILAÇÃO E' },
  { id: '3180 DESENHISTAS TÉCNICOS, EM GERAL', descricao: 'DESENHISTAS TÉCNICOS, EM GERAL' },
  { id: '317120 Desenvolvedor de games', descricao: 'Desenvolvedor de games' },
  { id: '317105 Desenvolvedor de internet (técnico)', descricao: 'Desenvolvedor de internet (técnico)' },
  { id: '317120 Desenvolvedor de jogos', descricao: 'Desenvolvedor de jogos' },
  { id: '317120 Desenvolvedor de jogos para computador', descricao: 'Desenvolvedor de jogos para computador' },
  { id: '317120 Desenvolvedor de multimídia', descricao: 'Desenvolvedor de multimídia' },
  { id: '317105 Desenvolvedor de plataforma web (técnico)', descricao: 'Desenvolvedor de plataforma web (técnico)' },
  { id: '317110 Desenvolvedor de sistemas de tecnologia da informação (técnico)', descricao: 'Desenvolvedor de sistemas de tecnologia da informação (técnico)' },
  { id: '317110 Desenvolvedor de sustentação de ti (técnico)', descricao: 'Desenvolvedor de sustentação de ti (técnico)' },
  { id: '212405 Desenvolvedor de ti', descricao: 'Desenvolvedor de ti' },
  { id: '317105 Desenvolvedor web (técnico)', descricao: 'Desenvolvedor web (técnico)' },
  { id: '622210 Desfibrador de agave', descricao: 'Desfibrador de agave' },
  { id: '841432 Desidratador de alimentos', descricao: 'Desidratador de alimentos' },
  { id: '622210 Desifbrador de sisal - na cultura', descricao: 'Desifbrador de sisal - na cultura' },
  { id: '375120 Designer de eventos', descricao: 'Designer de eventos' },
  { id: '375105 Designer de interiores', descricao: 'Designer de interiores' },
  { id: '2629 DESIGNER DE INTERIORES DE NÍVEL SUPERIOR', descricao: 'DESIGNER DE INTERIORES DE NÍVEL SUPERIOR' },
  { id: '375110 Designer de vitrines', descricao: 'Designer de vitrines' },
  { id: '239435 Designer educacional', descricao: 'Designer educacional' },
  { id: '239435 Designer instrucional', descricao: 'Designer instrucional' },
  { id: '262905 Designers de ambientes', descricao: 'Designers de ambientes' },
  { id: '262905 Designers de interiores', descricao: 'Designers de interiores' },
  { id: '3751 DESIGNERS DE INTERIORES, DE VITRINES E VISUAL MERCHANDISER E AFINS (NÍVEL MÉDIO)', descricao: 'DESIGNERS DE INTERIORES, DE VITRINES E VISUAL MERCHANDISER E AFINS (NÍVEL MÉDIO)' },
  { id: '316335 Desincrustador (poços de petróleo)', descricao: 'Desincrustador (poços de petróleo)' },
  { id: '316335 Desincrustador de poços de petróleo e gás', descricao: 'Desincrustador de poços de petróleo e gás' },
  { id: '519910 Desinfestador', descricao: 'Desinfestador' },
  { id: '376220 Deslocador', descricao: 'Deslocador' },
  { id: '848525 Desmanchador em matadouro', descricao: 'Desmanchador em matadouro' },
  { id: '848525 Desmanchador exclusive em matadouro', descricao: 'Desmanchador exclusive em matadouro' },
  { id: '848525 Desmembrador de mocotó dianteiro', descricao: 'Desmembrador de mocotó dianteiro' },
  { id: '722315 Desmoldador', descricao: 'Desmoldador' },
  { id: '848515 Desnucador em matadouro', descricao: 'Desnucador em matadouro' },
  { id: '848515 Desorelhador em matadouro', descricao: 'Desorelhador em matadouro' },
  { id: '848515 Desossador', descricao: 'Desossador' },
  { id: '342210 Despachante aduaneiro', descricao: 'Despachante aduaneiro' },
  { id: '342210 Despachante alfandegário', descricao: 'Despachante alfandegário' },
  { id: '783205 Despachante de bagagens em aeroportos', descricao: 'Despachante de bagagens em aeroportos' },
  { id: '423105 Despachante de documentos', descricao: 'Despachante de documentos' },
  { id: '511210 Despachante de embarque', descricao: 'Despachante de embarque' },
  { id: '711325 Despachante de plataforma (petróleo)', descricao: 'Despachante de plataforma (petróleo)' },
  { id: '511210 Despachante de tráfego', descricao: 'Despachante de tráfego' },
  { id: '423110 Despachante de trânsito', descricao: 'Despachante de trânsito' },
  { id: '511210 Despachante de transportes coletivos (exceto trem)', descricao: 'Despachante de transportes coletivos (exceto trem)' },
  { id: '423110 Despachante de veículos', descricao: 'Despachante de veículos' },
  { id: '423105 Despachante documentalista', descricao: 'Despachante documentalista' },
  { id: '423110 Despachante emplacador', descricao: 'Despachante emplacador' },
  { id: '511210 Despachante nos transportes', descricao: 'Despachante nos transportes' },
  { id: '342510 Despachante operacional de vôo', descricao: 'Despachante operacional de vôo' },
  { id: '423105 Despachante policial', descricao: 'Despachante policial' },
  { id: '3422 DESPACHANTES ADUANEIROS', descricao: 'DESPACHANTES ADUANEIROS' },
  { id: '4231 DESPACHANTES DOCUMENTALISTAS E AFINS', descricao: 'DESPACHANTES DOCUMENTALISTAS E AFINS' },
  { id: '848520 Despansador em matadouro', descricao: 'Despansador em matadouro' },
  { id: '721325 Despolidor', descricao: 'Despolidor' },
  { id: '848515 Desqueixador em matadouro', descricao: 'Desqueixador em matadouro' },
  { id: '519910 Desratizador', descricao: 'Desratizador' },
  { id: '848515 Dessebador em matadouro e açougues', descricao: 'Dessebador em matadouro e açougues' },
  { id: '841735 Dessecador de malte', descricao: 'Dessecador de malte' },
  { id: '622020 Destalador - na cultura', descricao: 'Destalador - na cultura' },
  { id: '811415 Destilador', descricao: 'Destilador' },
  { id: '811425 Destilador de álcool', descricao: 'Destilador de álcool' },
  { id: '841705 Destilador de bebidas', descricao: 'Destilador de bebidas' },
  { id: '811410 Destilador de glicerina', descricao: 'Destilador de glicerina' },
  { id: '841705 Destilador de licores', descricao: 'Destilador de licores' },
  { id: '811405 Destilador de madeira', descricao: 'Destilador de madeira' },
  { id: '811410 Destilador de produtos de perfumaria', descricao: 'Destilador de produtos de perfumaria' },
  { id: '811410 Destilador de produtos químicos (exceto petróleo)', descricao: 'Destilador de produtos químicos (exceto petróleo)' },
  { id: '811410 Destilador de produtos químicos e afins', descricao: 'Destilador de produtos químicos e afins' },
  { id: '811410 Destilador de resinas', descricao: 'Destilador de resinas' },
  { id: '621005 Destocador - na agropecuária', descricao: 'Destocador - na agropecuária' },
  { id: '773115 Destopador (madeira)', descricao: 'Destopador (madeira)' },
  { id: '711115 Destroçador de pedra', descricao: 'Destroçador de pedra' },
  { id: '318015 Detalhista em desenho técnico', descricao: 'Detalhista em desenho técnico' },
  { id: '351810 Detetive de polícia', descricao: 'Detetive de polícia' },
  { id: '351805 Detetive particular', descricao: 'Detetive particular' },
  { id: '351805 Detetive profissional', descricao: 'Detetive profissional' },
  { id: '711120 Detonador', descricao: 'Detonador' },
  { id: '225155 Diabetólogo', descricao: 'Diabetólogo' },
  { id: '263105 Diácono', descricao: 'Diácono' },
  { id: '263105 Diácono permanente', descricao: 'Diácono permanente' },
  { id: '766120 Diagramador ( jornalismo)', descricao: 'Diagramador ( jornalismo)' },
  { id: '766120 Diagramador e desenhista', descricao: 'Diagramador e desenhista' },
  { id: '766120 Diagramador em terminal de vídeo', descricao: 'Diagramador em terminal de vídeo' },
  { id: '141410 Diamantário (comércio varejista)', descricao: 'Diamantário (comércio varejista)' },
  { id: '621005 Diarista - na agropecuária', descricao: 'Diarista - na agropecuária' },
  { id: '622020 Diarista na agricultura', descricao: 'Diarista na agricultura' },
  { id: '261125 Diarista-em jornal', descricao: 'Diarista-em jornal' },
  { id: '223705 Dietista', descricao: 'Dietista' },
  { id: '412110 Digitador', descricao: 'Digitador' },
  { id: '766145 Digitador de fotocomposição', descricao: 'Digitador de fotocomposição' },
  { id: '412110 Digitador de terminal', descricao: 'Digitador de terminal' },
  { id: '711120 Dinamitador', descricao: 'Dinamitador' },
  { id: '261115 Diretor adjunto', descricao: 'Diretor adjunto' },
  { id: '122705 Diretor adjunto comercial em operações de intermediação financeira', descricao: 'Diretor adjunto comercial em operações de intermediação financeira' },
  { id: '131305 Diretor adjunto de escolas privadas', descricao: 'Diretor adjunto de escolas privadas' },
  { id: '123105 Diretor administrativo', descricao: 'Diretor administrativo' },
  { id: '123110 Diretor administrativo e financeiro', descricao: 'Diretor administrativo e financeiro' },
  { id: '262235 Diretor artistíco', descricao: 'Diretor artistíco' },
  { id: '262205 Diretor cinematográfico', descricao: 'Diretor cinematográfico' },
  { id: '262220 Diretor circense', descricao: 'Diretor circense' },
  { id: '131205 Diretor clínico', descricao: 'Diretor clínico' },
  { id: '123305 Diretor comercial', descricao: 'Diretor comercial' },
  { id: '122705 Diretor comercial em operações de intermediação financeira', descricao: 'Diretor comercial em operações de intermediação financeira' },
  { id: '122505 Diretor de produção e operações de alimentação', descricao: 'Diretor de produção e operações de alimentação' },
  { id: '122510 Diretor de produção e operações de hotel', descricao: 'Diretor de produção e operações de hotel' },
  { id: '122515 Diretor de produção e operações de turismo', descricao: 'Diretor de produção e operações de turismo' },
  { id: '262330 Diretor de arte', descricao: 'Diretor de arte' },
  { id: '253125 Diretor de arte (publicidade)', descricao: 'Diretor de arte (publicidade)' },
  { id: '131105 Diretor de biblioteca', descricao: 'Diretor de biblioteca' },
  { id: '131310 Diretor de caic público', descricao: 'Diretor de caic público' },
  { id: '122720 Diretor de câmbio e comércio exterior', descricao: 'Diretor de câmbio e comércio exterior' },
  { id: '123205 Diretor de capital humano', descricao: 'Diretor de capital humano' },
  { id: '131305 Diretor de centro de educação infantil (creche e pré-escola) privado', descricao: 'Diretor de centro de educação infantil (creche e pré-escola) privado' },
  { id: '131310 Diretor de centro de educação infantil público', descricao: 'Diretor de centro de educação infantil público' },
  { id: '131310 Diretor de centro de ensino especial público', descricao: 'Diretor de centro de ensino especial público' },
  { id: '131310 Diretor de centro de ensino fundamental público', descricao: 'Diretor de centro de ensino fundamental público' },
  { id: '131310 Diretor de centro de ensino médio profissionalizante público', descricao: 'Diretor de centro de ensino médio profissionalizante público' },
  { id: '131310 Diretor de centro de ensino médio público', descricao: 'Diretor de centro de ensino médio público' },
  { id: '131310 Diretor de centro de ensino supletivo público', descricao: 'Diretor de centro de ensino supletivo público' },
  { id: '131305 Diretor de centro de formação profissional privado', descricao: 'Diretor de centro de formação profissional privado' },
  { id: '262205 Diretor de cinema', descricao: 'Diretor de cinema' },
  { id: '131305 Diretor de colégio privado', descricao: 'Diretor de colégio privado' },
  { id: '131310 Diretor de colégio público', descricao: 'Diretor de colégio público' },
  { id: '122725 Diretor de compliance', descricao: 'Diretor de compliance' },
  { id: '123405 Diretor de compras', descricao: 'Diretor de compras' },
  { id: '253135 Diretor de contas (publicidade)', descricao: 'Diretor de contas (publicidade)' },
  { id: '122305 Diretor de contratos de obras pública e civil', descricao: 'Diretor de contratos de obras pública e civil' },
  { id: '122605 Diretor de correios', descricao: 'Diretor de correios' },
  { id: '374425 Diretor de corte', descricao: 'Diretor de corte' },
  { id: '122730 Diretor de crédito (exceto crédito imobiliário)', descricao: 'Diretor de crédito (exceto crédito imobiliário)' },
  { id: '122735 Diretor de crédito imobiliário', descricao: 'Diretor de crédito imobiliário' },
  { id: '122715 Diretor de crédito rural', descricao: 'Diretor de crédito rural' },
  { id: '253130 Diretor de criação', descricao: 'Diretor de criação' },
  { id: '131110 Diretor de departamento de esportes', descricao: 'Diretor de departamento de esportes' },
  { id: '131205 Diretor de departamento de saúde', descricao: 'Diretor de departamento de saúde' },
  { id: '111415 Diretor de departamento do serviço público municipal', descricao: 'Diretor de departamento do serviço público municipal' },
  { id: '131205 Diretor de departamento médico', descricao: 'Diretor de departamento médico' },
  { id: '111415 Diretor de divisão do serviço público municipal', descricao: 'Diretor de divisão do serviço público municipal' },
  { id: '131205 Diretor de divisão médica', descricao: 'Diretor de divisão médica' },
  { id: '114305 Diretor de entidade religiosa', descricao: 'Diretor de entidade religiosa' },
  { id: '131310 Diretor de escola de classe pública', descricao: 'Diretor de escola de classe pública' },
  { id: '131305 Diretor de escola privada', descricao: 'Diretor de escola privada' },
  { id: '131310 Diretor de escola pública', descricao: 'Diretor de escola pública' },
  { id: '131305 Diretor de escola religiosa privada', descricao: 'Diretor de escola religiosa privada' },
  { id: '131305 Diretor de estabelecimento de ensino fundamental privado', descricao: 'Diretor de estabelecimento de ensino fundamental privado' },
  { id: '131305 Diretor de estabelecimento de ensino médio privado', descricao: 'Diretor de estabelecimento de ensino médio privado' },
  { id: '131305 Diretor de estabelecimento de ensino superior privado', descricao: 'Diretor de estabelecimento de ensino superior privado' },
  { id: '262205 Diretor de estúdio cinematográfico', descricao: 'Diretor de estúdio cinematográfico' },
  { id: '123115 Diretor de finanças', descricao: 'Diretor de finanças' },
  { id: '372105 Diretor de fotografia', descricao: 'Diretor de fotografia' },
  { id: '131120 Diretor de futebol', descricao: 'Diretor de futebol' },
  { id: '122610 Diretor de gestão portuária', descricao: 'Diretor de gestão portuária' },
  { id: '374425 Diretor de imagens (tv)', descricao: 'Diretor de imagens (tv)' },
  { id: '123605 Diretor de informática', descricao: 'Diretor de informática' },
  { id: '123605 Diretor de infraestrutura de tecnologia da informação', descricao: 'Diretor de infraestrutura de tecnologia da informação' },
  { id: '131305 Diretor de instituição educacional da área privada', descricao: 'Diretor de instituição educacional da área privada' },
  { id: '131310 Diretor de instituição educacional pública', descricao: 'Diretor de instituição educacional pública' },
  { id: '131305 Diretor de internato privado', descricao: 'Diretor de internato privado' },
  { id: '122740 Diretor de leasing', descricao: 'Diretor de leasing' },
  { id: '123405 Diretor de logística e de suprimentos', descricao: 'Diretor de logística e de suprimentos' },
  { id: '122620 Diretor de logística em operações de transportes', descricao: 'Diretor de logística em operações de transportes' },
  { id: '123805 Diretor de manutenção', descricao: 'Diretor de manutenção' },
  { id: '123310 Diretor de marketing', descricao: 'Diretor de marketing' },
  { id: '122745 Diretor de mercado de capitais', descricao: 'Diretor de mercado de capitais' },
  { id: '253120 Diretor de mídia (publicidade)', descricao: 'Diretor de mídia (publicidade)' },
  { id: '131105 Diretor de museu', descricao: 'Diretor de museu' },
  { id: '122305 Diretor de obras pública e civil', descricao: 'Diretor de obras pública e civil' },
  { id: '131105 Diretor de ong (organização não-governamental)', descricao: 'Diretor de ong (organização não-governamental)' },
  { id: '122405 Diretor de operações comerciais (comércio atacadista e varejista)', descricao: 'Diretor de operações comerciais (comércio atacadista e varejista)' },
  { id: '122605 Diretor de operações de correios', descricao: 'Diretor de operações de correios' },
  { id: '122305 Diretor de operações de obras pública e civil', descricao: 'Diretor de operações de obras pública e civil' },
  { id: '122615 Diretor de operações de radiodifusão', descricao: 'Diretor de operações de radiodifusão' },
  { id: '122610 Diretor de operações de serviços de armazenamento', descricao: 'Diretor de operações de serviços de armazenamento' },
  { id: '122615 Diretor de operações de serviços de telecomunicações', descricao: 'Diretor de operações de serviços de telecomunicações' },
  { id: '122620 Diretor de operações de serviços de transporte', descricao: 'Diretor de operações de serviços de transporte' },
  { id: '123805 Diretor de operações e manutenção', descricao: 'Diretor de operações e manutenção' },
  { id: '131105 Diretor de organização social e interesse público', descricao: 'Diretor de organização social e interesse público' },
  { id: '131110 Diretor de osip (organização social de interesse público)', descricao: 'Diretor de osip (organização social de interesse público)' },
  { id: '131105 Diretor de pesquisa', descricao: 'Diretor de pesquisa' },
  { id: '123705 Diretor de pesquisa e desenvolvimento (p&d)', descricao: 'Diretor de pesquisa e desenvolvimento (p&d)' },
  { id: '121005 Diretor de planejamento estratégico', descricao: 'Diretor de planejamento estratégico' },
  { id: '123115 Diretor de planejamento financeiro', descricao: 'Diretor de planejamento financeiro' },
  { id: '122705 Diretor de plataforma em operações de intermediação financeira', descricao: 'Diretor de plataforma em operações de intermediação financeira' },
  { id: '123605 Diretor de processos de tecnologia da informação', descricao: 'Diretor de processos de tecnologia da informação' },
  { id: '262230 Diretor de produção', descricao: 'Diretor de produção' },
  { id: '122305 Diretor de produção de obras pública e civil', descricao: 'Diretor de produção de obras pública e civil' },
  { id: '122205 Diretor de produção e operações da indústria de transformação, extração mineral e utilidades', descricao: 'Diretor de produção e operações da indústria de transformação, extração mineral e utilidades' },
  { id: '122105 Diretor de produção e operações em empresa agropecuária', descricao: 'Diretor de produção e operações em empresa agropecuária' },
  { id: '122110 Diretor de produção e operações em empresa aqüícola', descricao: 'Diretor de produção e operações em empresa aqüícola' },
  { id: '122115 Diretor de produção e operações em empresa florestal', descricao: 'Diretor de produção e operações em empresa florestal' },
  { id: '122120 Diretor de produção e operações em empresa pesqueira', descricao: 'Diretor de produção e operações em empresa pesqueira' },
  { id: '122115 Diretor de produção em empresa florestal', descricao: 'Diretor de produção em empresa florestal' },
  { id: '122120 Diretor de produção em empresa pesqueira', descricao: 'Diretor de produção em empresa pesqueira' },
  { id: '122205 Diretor de produção em utilidades', descricao: 'Diretor de produção em utilidades' },
  { id: '122105 Diretor de produção na agropecuária', descricao: 'Diretor de produção na agropecuária' },
  { id: '122205 Diretor de produção na indústria de transformação', descricao: 'Diretor de produção na indústria de transformação' },
  { id: '122205 Diretor de produção na mineração', descricao: 'Diretor de produção na mineração' },
  { id: '122710 Diretor de produtos bancários', descricao: 'Diretor de produtos bancários' },
  { id: '262225 Diretor de programação', descricao: 'Diretor de programação' },
  { id: '262210 Diretor de programas de rádio', descricao: 'Diretor de programas de rádio' },
  { id: '262215 Diretor de programas de televisão', descricao: 'Diretor de programas de televisão' },
  { id: '122750 Diretor de recuperação de créditos em operações de intermediação financeira', descricao: 'Diretor de recuperação de créditos em operações de intermediação financeira' },
  { id: '123205 Diretor de recursos humanos', descricao: 'Diretor de recursos humanos' },
  { id: '261115 Diretor de redação', descricao: 'Diretor de redação' },
  { id: '122615 Diretor de redes (telecomunicações)', descricao: 'Diretor de redes (telecomunicações)' },
  { id: '123210 Diretor de relações de trabalho', descricao: 'Diretor de relações de trabalho' },
  { id: '131105 Diretor de relações interinstitucionais', descricao: 'Diretor de relações interinstitucionais' },
  { id: '122755 Diretor de riscos de mercado', descricao: 'Diretor de riscos de mercado' },
  { id: '131105 Diretor de serviços culturais', descricao: 'Diretor de serviços culturais' },
  { id: '123605 Diretor de serviços de informática', descricao: 'Diretor de serviços de informática' },
  { id: '131205 Diretor de serviços de saúde', descricao: 'Diretor de serviços de saúde' },
  { id: '123605 Diretor de serviços de tecnologia da informação', descricao: 'Diretor de serviços de tecnologia da informação' },
  { id: '131205 Diretor de serviços médicos', descricao: 'Diretor de serviços médicos' },
  { id: '131110 Diretor de serviços sociais', descricao: 'Diretor de serviços sociais' },
  { id: '114205 Diretor de sindicato de trabalhadores', descricao: 'Diretor de sindicato de trabalhadores' },
  { id: '114210 Diretor de sindicato patronal', descricao: 'Diretor de sindicato patronal' },
  { id: '374120 Diretor de som', descricao: 'Diretor de som' },
  { id: '123405 Diretor de suprimentos', descricao: 'Diretor de suprimentos' },
  { id: '123410 Diretor de suprimentos no serviço público', descricao: 'Diretor de suprimentos no serviço público' },
  { id: '123605 Diretor de tecnologia', descricao: 'Diretor de tecnologia' },
  { id: '123705 Diretor de tecnologia (pesquisa e desenvolvimento)', descricao: 'Diretor de tecnologia (pesquisa e desenvolvimento)' },
  { id: '123605 Diretor de tecnologia da informação', descricao: 'Diretor de tecnologia da informação' },
  { id: '262220 Diretor de televisão', descricao: 'Diretor de televisão' },
  { id: '374425 Diretor de tv', descricao: 'Diretor de tv' },
  { id: '131205 Diretor de unidade assistencial', descricao: 'Diretor de unidade assistencial' },
  { id: '131205 Diretor de unidade de saúde', descricao: 'Diretor de unidade de saúde' },
  { id: '131205 Diretor de unidade hospitalar', descricao: 'Diretor de unidade hospitalar' },
  { id: '131305 Diretor de unidade integrada de pré-escola privada', descricao: 'Diretor de unidade integrada de pré-escola privada' },
  { id: '123305 Diretor de vendas', descricao: 'Diretor de vendas' },
  { id: '111405 Diretor do serviço público federal', descricao: 'Diretor do serviço público federal' },
  { id: '131105 Diretor executivo de instituições culturais', descricao: 'Diretor executivo de instituições culturais' },
  { id: '123115 Diretor financeiro', descricao: 'Diretor financeiro' },
  { id: '121010 Diretor geral de empresa e organizações (exceto de interesse público)', descricao: 'Diretor geral de empresa e organizações (exceto de interesse público)' },
  { id: '123305 Diretor geral de vendas', descricao: 'Diretor geral de vendas' },
  { id: '131205 Diretor médico-hospitalar', descricao: 'Diretor médico-hospitalar' },
  { id: '261125 Diretor noticiarista', descricao: 'Diretor noticiarista' },
  { id: '262615 Diretor regente de bateria', descricao: 'Diretor regente de bateria' },
  { id: '262615 Diretor regente musical', descricao: 'Diretor regente musical' },
  { id: '122615 Diretor regional (telecomunicações)', descricao: 'Diretor regional (telecomunicações)' },
  { id: '122705 Diretor regional em operações de intermediação financeira', descricao: 'Diretor regional em operações de intermediação financeira' },
  { id: '262220 Diretor teatral', descricao: 'Diretor teatral' },
  { id: '123705 Diretor técnico (pesquisa e desenvolvimento)', descricao: 'Diretor técnico (pesquisa e desenvolvimento)' },
  { id: '131205 Diretor técnico de unidade hospitalar', descricao: 'Diretor técnico de unidade hospitalar' },
  { id: '1231 DIRETORES ADMINISTRATIVOS E FINANCEIROS', descricao: 'DIRETORES ADMINISTRATIVOS E FINANCEIROS' },
  { id: '1233 DIRETORES DE COMERCIALIZAÇÃO E MARKETING', descricao: 'DIRETORES DE COMERCIALIZAÇÃO E MARKETING' },
  { id: '2622 DIRETORES DE ESPETÁCULOS E AFINS', descricao: 'DIRETORES DE ESPETÁCULOS E AFINS' },
  { id: '1238 DIRETORES DE MANUTENÇÃO', descricao: 'DIRETORES DE MANUTENÇÃO' },
  { id: '1223 DIRETORES DE OPERAÇÕES DE OBRAS EM EMPRESA DE CONSTRUÇÃO', descricao: 'DIRETORES DE OPERAÇÕES DE OBRAS EM EMPRESA DE CONSTRUÇÃO' },
  { id: '1226 DIRETORES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE ARMAZENAMENTO, DE TRANSPORTE E DE', descricao: 'DIRETORES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE ARMAZENAMENTO, DE TRANSPORTE E DE' },
  { id: '1227 DIRETORES DE OPERAÇÕES DE SERVIÇOS EM INSTITUIÇÃO DE INTERMEDIAÇÃO FINANCEIRA', descricao: 'DIRETORES DE OPERAÇÕES DE SERVIÇOS EM INSTITUIÇÃO DE INTERMEDIAÇÃO FINANCEIRA' },
  { id: '1224 DIRETORES DE OPERAÇÕES EM EMPRESA DO COMÉRCIO', descricao: 'DIRETORES DE OPERAÇÕES EM EMPRESA DO COMÉRCIO' },
  { id: '1237 DIRETORES DE PESQUISA E DESENVOLVIMENTO', descricao: 'DIRETORES DE PESQUISA E DESENVOLVIMENTO' },
  { id: '1221 DIRETORES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA AGROPECUÁRIA, PESQUEIRA , AQÜÍCOLA E FLORESTAL', descricao: 'DIRETORES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA AGROPECUÁRIA, PESQUEIRA , AQÜÍCOLA E FLORESTAL' },
  { id: '1222 DIRETORES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA DA INDÚSTRIA EXTRATIVA, TRANSFORMAÇÃO E DE', descricao: 'DIRETORES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA DA INDÚSTRIA EXTRATIVA, TRANSFORMAÇÃO E DE' },
  { id: '1232 DIRETORES DE RECURSOS HUMANOS E RELAÇÕES DE TRABALHO', descricao: 'DIRETORES DE RECURSOS HUMANOS E RELAÇÕES DE TRABALHO' },
  { id: '1225 DIRETORES DE SERVIÇOS DE TURISMO, DE ALOJAMENTO E DE ALIMENTAÇÃO', descricao: 'DIRETORES DE SERVIÇOS DE TURISMO, DE ALOJAMENTO E DE ALIMENTAÇÃO' },
  { id: '1234 DIRETORES DE SUPRIMENTOS E AFINS', descricao: 'DIRETORES DE SUPRIMENTOS E AFINS' },
  { id: '1236 DIRETORES DE TECNOLOGIA DA INFORMAÇÃO', descricao: 'DIRETORES DE TECNOLOGIA DA INFORMAÇÃO' },
  { id: '1313 DIRETORES E GERENTES DE INSTITUIÇÃO DE SERVIÇOS EDUCACIONAIS', descricao: 'DIRETORES E GERENTES DE INSTITUIÇÃO DE SERVIÇOS EDUCACIONAIS' },
  { id: '1311 DIRETORES E GERENTES DE OPERAÇÕES EM EMPRESA DE SERVIÇOS PESSOAIS, SOCIAIS E CULTURAIS', descricao: 'DIRETORES E GERENTES DE OPERAÇÕES EM EMPRESA DE SERVIÇOS PESSOAIS, SOCIAIS E CULTURAIS' },
  { id: '1210 DIRETORES GERAIS', descricao: 'DIRETORES GERAIS' },
  { id: '114405 Dirigente de clube e associação esportiva', descricao: 'Dirigente de clube e associação esportiva' },
  { id: '114405 Dirigente de organização de defesa de direitos', descricao: 'Dirigente de organização de defesa de direitos' },
  { id: '114405 Dirigente de organização de desenvolvimento cultural', descricao: 'Dirigente de organização de desenvolvimento cultural' },
  { id: '114405 Dirigente de organização de proteção ao meio ambiente', descricao: 'Dirigente de organização de proteção ao meio ambiente' },
  { id: '114405 Dirigente de organização filantrópica', descricao: 'Dirigente de organização filantrópica' },
  { id: '114405 Dirigente de organização humanitária', descricao: 'Dirigente de organização humanitária' },
  { id: '114405 Dirigente de organização não-governamental', descricao: 'Dirigente de organização não-governamental' },
  { id: '114105 Dirigente de partido político', descricao: 'Dirigente de partido político' },
  { id: '111410 Dirigente do serviço público estadual e distrital', descricao: 'Dirigente do serviço público estadual e distrital' },
  { id: '111405 Dirigente do serviço público federal', descricao: 'Dirigente do serviço público federal' },
  { id: '111415 Dirigente do serviço público municipal', descricao: 'Dirigente do serviço público municipal' },
  { id: '114405 Dirigente e administrador de organização da sociedade civil sem fins lucrativos', descricao: 'Dirigente e administrador de organização da sociedade civil sem fins lucrativos' },
  { id: '114305 Dirigente e administrador de organização religiosa', descricao: 'Dirigente e administrador de organização religiosa' },
  { id: '263105 Dirigente espiritual de umbanda', descricao: 'Dirigente espiritual de umbanda' },
  { id: '114105 Dirigente partidário', descricao: 'Dirigente partidário' },
  { id: '114105 Dirigente político', descricao: 'Dirigente político' },
  { id: '114205 Dirigentes de entidades de trabalhadores', descricao: 'Dirigentes de entidades de trabalhadores' },
  { id: '114210 Dirigentes de entidades patronais', descricao: 'Dirigentes de entidades patronais' },
  { id: '1141 DIRIGENTES DE PARTIDOS POLÍTICOS', descricao: 'DIRIGENTES DE PARTIDOS POLÍTICOS' },
  { id: '1130 DIRIGENTES DE POVOS INDÍGENAS, DE QUILOMBOLAS E CAIÇARAS', descricao: 'DIRIGENTES DE POVOS INDÍGENAS, DE QUILOMBOLAS E CAIÇARAS' },
  { id: '1114 DIRIGENTES DO SERVIÇO PÚBLICO', descricao: 'DIRIGENTES DO SERVIÇO PÚBLICO' },
  { id: '1142 DIRIGENTES E ADMINISTRADORES DE ENTIDADES PATRONAIS E DOS TRABALHADORES E DE OUTROS', descricao: 'DIRIGENTES E ADMINISTRADORES DE ENTIDADES PATRONAIS E DOS TRABALHADORES E DE OUTROS' },
  { id: '1143 DIRIGENTES E ADMINISTRADORES DE ENTIDADES RELIGIOSAS', descricao: 'DIRIGENTES E ADMINISTRADORES DE ENTIDADES RELIGIOSAS' },
  { id: '1144 DIRIGENTES E ADMINISTRADORES DE ORGANIZAÇÕES DA SOCIEDADE CIVIL SEM FINS LUCRATIVOS', descricao: 'DIRIGENTES E ADMINISTRADORES DE ORGANIZAÇÕES DA SOCIEDADE CIVIL SEM FINS LUCRATIVOS' },
  { id: '1112 DIRIGENTES GERAIS DA ADMINISTRAÇÃO PÚBLICA', descricao: 'DIRIGENTES GERAIS DA ADMINISTRAÇÃO PÚBLICA' },
  { id: '374145 Disc jóquei', descricao: 'Disc jóquei' },
  { id: '374145 Discotecário', descricao: 'Discotecário' },
  { id: '223284 Disfunção de atm', descricao: 'Disfunção de atm' },
  { id: '223284 Disfunção e dor', descricao: 'Disfunção e dor' },
  { id: '141405 Distribuidor de fumo', descricao: 'Distribuidor de fumo' },
  { id: '761240 Distribuidor de maçarocas', descricao: 'Distribuidor de maçarocas' },
  { id: '141405 Distribuidor de produtos químicos', descricao: 'Distribuidor de produtos químicos' },
  { id: '141405 Distribuidor de revistas', descricao: 'Distribuidor de revistas' },
  { id: '351410 Distribuidor público', descricao: 'Distribuidor público' },
  { id: '848525 Divisor de cortes em matadouro', descricao: 'Divisor de cortes em matadouro' },
  { id: '374145 Dj (disc jockey)', descricao: 'Dj (disc jockey)' },
  { id: '766310 Dobrador gráfico', descricao: 'Dobrador gráfico' },
  { id: '761815 Dobrador-revisor - em fábrica de tecidos', descricao: 'Dobrador-revisor - em fábrica de tecidos' },
  { id: '524305 Doceiro (comércio ambulante)', descricao: 'Doceiro (comércio ambulante)' },
  { id: '141410 Doceiro (exclusive no comércio ambulante)', descricao: 'Doceiro (exclusive no comércio ambulante)' },
  { id: '234505 Docente de ensino superior na área de didática', descricao: 'Docente de ensino superior na área de didática' },
  { id: '234510 Docente de ensino superior na área de orientação educacional', descricao: 'Docente de ensino superior na área de orientação educacional' },
  { id: '234515 Docente de ensino superior na área de pesquisa educacional', descricao: 'Docente de ensino superior na área de pesquisa educacional' },
  { id: '331305 Docente de nível médio no ensino profissionalizante', descricao: 'Docente de nível médio no ensino profissionalizante' },
  { id: '234520 Docente do ensino superior na área de prática de ensino', descricao: 'Docente do ensino superior na área de prática de ensino' },
  { id: '261210 Documentalista', descricao: 'Documentalista' },
  { id: '263105 Dom', descricao: 'Dom' },
  { id: '623005 Domador (asininos e muares)', descricao: 'Domador (asininos e muares)' },
  { id: '623005 Domador (eqüinos)', descricao: 'Domador (eqüinos)' },
  { id: '623005 Domador - na pecuária', descricao: 'Domador - na pecuária' },
  { id: '376225 Domador de animais (circense)', descricao: 'Domador de animais (circense)' },
  { id: '623005 Domador de animais domésticos', descricao: 'Domador de animais domésticos' },
  { id: '263105 Doné', descricao: 'Doné' },
  { id: '141505 Dono de hotel - conta própria', descricao: 'Dono de hotel - conta própria' },
  { id: '141410 Dono de lavanderia automática - conta própria', descricao: 'Dono de lavanderia automática - conta própria' },
  { id: '141505 Dono de motel - conta própria', descricao: 'Dono de motel - conta própria' },
  { id: '141520 Dono de pensão - conta própria', descricao: 'Dono de pensão - conta própria' },
  { id: '141520 Dono de pensão - empregador', descricao: 'Dono de pensão - empregador' },
  { id: '782210 Doqueiro', descricao: 'Doqueiro' },
  { id: '841745 Dosador de xaropes', descricao: 'Dosador de xaropes' },
  { id: '263105 Doté', descricao: 'Doté' },
  { id: '322135 Doula', descricao: 'Doula' },
  { id: '768705 Dourador', descricao: 'Dourador' },
  { id: '723215 Dourador (metais)', descricao: 'Dourador (metais)' },
  { id: '632010 Dr. Raiz', descricao: 'Dr. Raiz' },
  { id: '811810 Drageador', descricao: 'Drageador' },
  { id: '811810 Drageador (medicamentos)', descricao: 'Drageador (medicamentos)' },
  { id: '811810 Drageador manipulador', descricao: 'Drageador manipulador' },
  { id: '261505 Dramaturgista', descricao: 'Dramaturgista' },
  { id: '261515 Dramaturgo', descricao: 'Dramaturgo' },
  { id: '262820 Dramaturgo de dança', descricao: 'Dramaturgo de dança' },
  { id: '141410 Droguista (comércio varejista)', descricao: 'Droguista (comércio varejista)' },
  { id: '223284 Dtm/dof', descricao: 'Dtm/dof' },
  { id: '374425 Dtv', descricao: 'Dtv' },
  { id: '724130 Duteiro', descricao: 'Duteiro' },
  { id: '203005 Ecologista', descricao: 'Ecologista' },
  { id: '203005 Ecólogo', descricao: 'Ecólogo' },
  { id: '211210 Econometrista', descricao: 'Econometrista' },
  { id: '251205 Economista', descricao: 'Economista' },
  { id: '251210 Economista agrícola', descricao: 'Economista agrícola' },
  { id: '251210 Economista agroindustrial', descricao: 'Economista agroindustrial' },
  { id: '251230 Economista ambiental', descricao: 'Economista ambiental' },
  { id: '251225 Economista do setor público', descricao: 'Economista do setor público' },
  { id: '251610 Economista doméstico', descricao: 'Economista doméstico' },
  { id: '251215 Economista financeiro', descricao: 'Economista financeiro' },
  { id: '251220 Economista industrial', descricao: 'Economista industrial' },
  { id: '251235 Economista regional e urbano', descricao: 'Economista regional e urbano' },
  { id: '251235 Economista urbano', descricao: 'Economista urbano' },
  { id: '2512 ECONOMISTAS', descricao: 'ECONOMISTAS' },
  { id: '710205 Edificador - mestre de obras', descricao: 'Edificador - mestre de obras' },
  { id: '724210 Edificador de casco (navios)', descricao: 'Edificador de casco (navios)' },
  { id: '261120 Editor', descricao: 'Editor' },
  { id: '261120 Editor assistente', descricao: 'Editor assistente' },
  { id: '261120 Editor de área', descricao: 'Editor de área' },
  { id: '261120 Editor de arte', descricao: 'Editor de arte' },
  { id: '261120 Editor de fotografia', descricao: 'Editor de fotografia' },
  { id: '261120 Editor de imagem', descricao: 'Editor de imagem' },
  { id: '374405 Editor de imagens', descricao: 'Editor de imagens' },
  { id: '261605 Editor de jornal', descricao: 'Editor de jornal' },
  { id: '261610 Editor de livro', descricao: 'Editor de livro' },
  { id: '374405 Editor de mídia audiovisual', descricao: 'Editor de mídia audiovisual' },
  { id: '261615 Editor de mídia eletrônica', descricao: 'Editor de mídia eletrônica' },
  { id: '374405 Editor de pós-produção', descricao: 'Editor de pós-produção' },
  { id: '261120 Editor de rádio', descricao: 'Editor de rádio' },
  { id: '261620 Editor de revista', descricao: 'Editor de revista' },
  { id: '261625 Editor de revista científica', descricao: 'Editor de revista científica' },
  { id: '766120 Editor de texto e imagem', descricao: 'Editor de texto e imagem' },
  { id: '374405 Editor de tv e vídeo', descricao: 'Editor de tv e vídeo' },
  { id: '374405 Editor de vt', descricao: 'Editor de vt' },
  { id: '261120 Editor de web', descricao: 'Editor de web' },
  { id: '261120 Editor executivo', descricao: 'Editor executivo' },
  { id: '374405 Editor finalizador', descricao: 'Editor finalizador' },
  { id: '2616 EDITORES', descricao: 'EDITORES' },
  { id: '261125 Editorialista', descricao: 'Editorialista' },
  { id: '623005 Educador de animais', descricao: 'Educador de animais' },
  { id: '515305 Educador de rua', descricao: 'Educador de rua' },
  { id: '331105 Educador infantil de nível médio', descricao: 'Educador infantil de nível médio' },
  { id: '515120 Educador sanitário', descricao: 'Educador sanitário' },
  { id: '515305 Educador social', descricao: 'Educador social' },
  { id: '515305 Educador social de rua', descricao: 'Educador social de rua' },
  { id: '263105 Egbonmi', descricao: 'Egbonmi' },
  { id: '263105 Ekêdi', descricao: 'Ekêdi' },
  { id: '951105 Eletricista', descricao: 'Eletricista' },
  { id: '715615 Eletricista auxiliar', descricao: 'Eletricista auxiliar' },
  { id: '731170 Eletricista bobinador', descricao: 'Eletricista bobinador' },
  { id: '732120 Eletricista de alta-tensão', descricao: 'Eletricista de alta-tensão' },
  { id: '732120 Eletricista de baixa-tensão', descricao: 'Eletricista de baixa-tensão' },
  { id: '341315 Eletricista de bordo', descricao: 'Eletricista de bordo' },
  { id: '953110 Eletricista de embarcações', descricao: 'Eletricista de embarcações' },
  { id: '732120 Eletricista de iluminação pública', descricao: 'Eletricista de iluminação pública' },
  { id: '715615 Eletricista de instalações', descricao: 'Eletricista de instalações' },
  { id: '953105 Eletricista de instalações (aeronaves)', descricao: 'Eletricista de instalações (aeronaves)' },
  { id: '715605 Eletricista de instalações (cenários)', descricao: 'Eletricista de instalações (cenários)' },
  { id: '715610 Eletricista de instalações (edifícios)', descricao: 'Eletricista de instalações (edifícios)' },
  { id: '953110 Eletricista de instalações (embarcações)', descricao: 'Eletricista de instalações (embarcações)' },
  { id: '715615 Eletricista de instalações (iluminação a gás neon)', descricao: 'Eletricista de instalações (iluminação a gás neon)' },
  { id: '715615 Eletricista de instalações (semáforos)', descricao: 'Eletricista de instalações (semáforos)' },
  { id: '953115 Eletricista de instalações (veículos automotores e máquinas operatrizes, exceto aeronaves e', descricao: 'Eletricista de instalações (veículos automotores e máquinas operatrizes, exceto aeronaves e' },
  { id: '715610 Eletricista de instalações comerciais e residenciais', descricao: 'Eletricista de instalações comerciais e residenciais' },
  { id: '715610 Eletricista de instalações de prédios', descricao: 'Eletricista de instalações de prédios' },
  { id: '953115 Eletricista de instalações de veículos automotores', descricao: 'Eletricista de instalações de veículos automotores' },
  { id: '715615 Eletricista de instalações elétricas de minas', descricao: 'Eletricista de instalações elétricas de minas' },
  { id: '715615 Eletricista de instalações industriais', descricao: 'Eletricista de instalações industriais' },
  { id: '732120 Eletricista de linha de alta-tensão', descricao: 'Eletricista de linha de alta-tensão' },
  { id: '732120 Eletricista de linha de baixa-tensão', descricao: 'Eletricista de linha de baixa-tensão' },
  { id: '732105 Eletricista de linha de transmissão', descricao: 'Eletricista de linha de transmissão' },
  { id: '732105 Eletricista de manutenção de linhas elétricas', descricao: 'Eletricista de manutenção de linhas elétricas' },
  { id: '732105 Eletricista de manutenção de linhas elétricas, telefônicas e de comunicação de dados', descricao: 'Eletricista de manutenção de linhas elétricas, telefônicas e de comunicação de dados' },
  { id: '732105 Eletricista de manutenção de linhas telefônicas', descricao: 'Eletricista de manutenção de linhas telefônicas' },
  { id: '991205 Eletricista de manutenção de parques de diversões', descricao: 'Eletricista de manutenção de parques de diversões' },
  { id: '951105 Eletricista de manutenção eletroeletrônica', descricao: 'Eletricista de manutenção eletroeletrônica' },
  { id: '951105 Eletricista de manutenção em geral', descricao: 'Eletricista de manutenção em geral' },
  { id: '951105 Eletricista de manutenção industrial', descricao: 'Eletricista de manutenção industrial' },
  { id: '715615 Eletricista de planejamento de instalações elétricas', descricao: 'Eletricista de planejamento de instalações elétricas' },
  { id: '732120 Eletricista de rede', descricao: 'Eletricista de rede' },
  { id: '732125 Eletricista de rede aérea de tração de veículos', descricao: 'Eletricista de rede aérea de tração de veículos' },
  { id: '715605 Eletricista de teatro e televisão', descricao: 'Eletricista de teatro e televisão' },
  { id: '953115 Eletricista de veículos de máquinas operatrizes', descricao: 'Eletricista de veículos de máquinas operatrizes' },
  { id: '731125 Eletricista instalador de alta e baixa tensão', descricao: 'Eletricista instalador de alta e baixa tensão' },
  { id: '9511 ELETRICISTAS DE MANUTENÇÃO ELETROELETRÔNICA', descricao: 'ELETRICISTAS DE MANUTENÇÃO ELETROELETRÔNICA' },
  { id: '9531 ELETRICISTAS ELETRÔNICOS DE MANUTENÇÃO VEICULAR (AÉREA, TERRESTRE E NAVAL)', descricao: 'ELETRICISTAS ELETRÔNICOS DE MANUTENÇÃO VEICULAR (AÉREA, TERRESTRE E NAVAL)' },
  { id: '954105 Eletromecânico de manutenção de elevadores', descricao: 'Eletromecânico de manutenção de elevadores' },
  { id: '954110 Eletromecânico de manutenção de escadas rolantes', descricao: 'Eletromecânico de manutenção de escadas rolantes' },
  { id: '954115 Eletromecânico de manutenção de portas automáticas', descricao: 'Eletromecânico de manutenção de portas automáticas' },
  { id: '313205 Eletrônico de manutenção', descricao: 'Eletrônico de manutenção' },
  { id: '991205 Eletrônico de manutenção de parques de diversões', descricao: 'Eletrônico de manutenção de parques de diversões' },
  { id: '313215 Eletrônico de rádio e televisão', descricao: 'Eletrônico de rádio e televisão' },
  { id: '313105 Eletrotécnico', descricao: 'Eletrotécnico' },
  { id: '313110 Eletrotécnico (produção de energia)', descricao: 'Eletrotécnico (produção de energia)' },
  { id: '313115 Eletrotécnico na fabricação, montagem e instalação de máquinas e equipamentos', descricao: 'Eletrotécnico na fabricação, montagem e instalação de máquinas e equipamentos' },
  { id: '954210 Eletrotécnico reparador de aparelhos de som', descricao: 'Eletrotécnico reparador de aparelhos de som' },
  { id: '622015 Embalador de mudas', descricao: 'Embalador de mudas' },
  { id: '784105 Embalador, a mão', descricao: 'Embalador, a mão' },
  { id: '784110 Embalador, a máquina', descricao: 'Embalador, a máquina' },
  { id: '328105 Embalsamador', descricao: 'Embalsamador' },
  { id: '622605 Embandeirador de cacau', descricao: 'Embandeirador de cacau' },
  { id: '762125 Embocador', descricao: 'Embocador' },
  { id: '623015 Embretador', descricao: 'Embretador' },
  { id: '784105 Embrulhador', descricao: 'Embrulhador' },
  { id: '732110 Emendador de cabos elétricos e telefônicos (aéreos e subterrâneos)', descricao: 'Emendador de cabos elétricos e telefônicos (aéreos e subterrâneos)' },
  { id: '992115 Emendador de câmaras de ar', descricao: 'Emendador de câmaras de ar' },
  { id: '519905 Emendador de cartazes', descricao: 'Emendador de cartazes' },
  { id: '732110 Emendador de fios (elétricos e telefônicos)', descricao: 'Emendador de fios (elétricos e telefônicos)' },
  { id: '421120 Emissor de passagens', descricao: 'Emissor de passagens' },
  { id: '421120 Emissor de passagens aéreas', descricao: 'Emissor de passagens aéreas' },
  { id: '421120 Emissor de passagens no transporte rodoviário', descricao: 'Emissor de passagens no transporte rodoviário' },
  { id: '784105 Empacotador, a mão', descricao: 'Empacotador, a mão' },
  { id: '784110 Empacotador, a máquina', descricao: 'Empacotador, a máquina' },
  { id: '776420 Empalhador de cadeiras', descricao: 'Empalhador de cadeiras' },
  { id: '512105 Empregado doméstico nos serviços gerais', descricao: 'Empregado doméstico nos serviços gerais' },
  { id: '512110 Empregado doméstico arrumador', descricao: 'Empregado doméstico arrumador' },
  { id: '512115 Empregado doméstico faxineiro', descricao: 'Empregado doméstico faxineiro' },
  { id: '512120 Empregado doméstico diarista', descricao: 'Empregado doméstico diarista' },
  { id: '622020 Empreiteiro - na cultura', descricao: 'Empreiteiro - na cultura' },
  { id: '262105 Empresário de espetáculo, tecnólogo em produção cultural', descricao: 'Empresário de espetáculo, tecnólogo em produção cultural' },
  { id: '611005 Empresário rural na agropecuária', descricao: 'Empresário rural na agropecuária' },
  { id: '7687 ENCADERNADORES E RECUPERADORES DE LIVROS (PEQUENOS LOTES OU A UNIDADE)', descricao: 'ENCADERNADORES E RECUPERADORES DE LIVROS (PEQUENOS LOTES OU A UNIDADE)' },
  { id: '828110 Encaierador de tijolos', descricao: 'Encaierador de tijolos' },
  { id: '784105 Encaixotador, a mão', descricao: 'Encaixotador, a mão' },
  { id: '724110 Encanador', descricao: 'Encanador' },
  { id: '724115 Encanador de manutenção', descricao: 'Encanador de manutenção' },
  { id: '724115 Encanador industrial', descricao: 'Encanador industrial' },
  { id: '7241 ENCANADORES E INSTALADORES DE TUBULAÇÕES', descricao: 'ENCANADORES E INSTALADORES DE TUBULAÇÕES' },
  { id: '410105 Encarregado administrativo', descricao: 'Encarregado administrativo' },
  { id: '630105 Encarregado da aqüicultura', descricao: 'Encarregado da aqüicultura' },
  { id: '630110 Encarregado da área florestal', descricao: 'Encarregado da área florestal' },
  { id: '422105 Encarregado da recepção', descricao: 'Encarregado da recepção' },
  { id: '840115 Encarregado de abridores (fumo)', descricao: 'Encarregado de abridores (fumo)' },
  { id: '780105 Encarregado de acabamento (embalagem e etiquetagem)', descricao: 'Encarregado de acabamento (embalagem e etiquetagem)' },
  { id: '760105 Encarregado de acabamento (indústria têxtil)', descricao: 'Encarregado de acabamento (indústria têxtil)' },
  { id: '821405 Encarregado de acabamento de chapas e metais (têmpera)', descricao: 'Encarregado de acabamento de chapas e metais (têmpera)' },
  { id: '760105 Encarregado de acabamento de estampa', descricao: 'Encarregado de acabamento de estampa' },
  { id: '848510 Encarregado de açougue', descricao: 'Encarregado de açougue' },
  { id: '410205 Encarregado de almoxarifado', descricao: 'Encarregado de almoxarifado' },
  { id: '710205 Encarregado de alvenaria', descricao: 'Encarregado de alvenaria' },
  { id: '510115 Encarregado de andar', descricao: 'Encarregado de andar' },
  { id: '841210 Encarregado de armazenagem de sal', descricao: 'Encarregado de armazenagem de sal' },
  { id: '410220 Encarregado de ativo fixo', descricao: 'Encarregado de ativo fixo' },
  { id: '510130 Encarregado de bar e restaurante', descricao: 'Encarregado de bar e restaurante' },
  { id: '510305 Encarregado de bombeiros', descricao: 'Encarregado de bombeiros' },
  { id: '410210 Encarregado de câmbio', descricao: 'Encarregado de câmbio' },
  { id: '510130 Encarregado de cantina', descricao: 'Encarregado de cantina' },
  { id: '342315 Encarregado de carga e descarga no transporte rodoviário', descricao: 'Encarregado de carga e descarga no transporte rodoviário' },
  { id: '770110 Encarregado de carpintaria', descricao: 'Encarregado de carpintaria' },
  { id: '420110 Encarregado de cobrança', descricao: 'Encarregado de cobrança' },
  { id: '910905 Encarregado de conservação de vias permanentes', descricao: 'Encarregado de conservação de vias permanentes' },
  { id: '710205 Encarregado de construção civil', descricao: 'Encarregado de construção civil' },
  { id: '710205 Encarregado de construção civil e carpintaria', descricao: 'Encarregado de construção civil e carpintaria' },
  { id: '710205 Encarregado de construção civil e manutenção', descricao: 'Encarregado de construção civil e manutenção' },
  { id: '410215 Encarregado de contas a pagar', descricao: 'Encarregado de contas a pagar' },
  { id: '410235 Encarregado de controle financeiro', descricao: 'Encarregado de controle financeiro' },
  { id: '760305 Encarregado de corte na confecção do vestuário', descricao: 'Encarregado de corte na confecção do vestuário' },
  { id: '760310 Encarregado de costura na confecção do vestuário', descricao: 'Encarregado de costura na confecção do vestuário' },
  { id: '271105 Encarregado de cozinha', descricao: 'Encarregado de cozinha' },
  { id: '410225 Encarregado de crédito e cobrança', descricao: 'Encarregado de crédito e cobrança' },
  { id: '412120 Encarregado de digitação e operação', descricao: 'Encarregado de digitação e operação' },
  { id: '510110 Encarregado de edifício', descricao: 'Encarregado de edifício' },
  { id: '514105 Encarregado de elevador', descricao: 'Encarregado de elevador' },
  { id: '992210 Encarregado de equipe de conservação de vias permanentes (exceto trilhos)', descricao: 'Encarregado de equipe de conservação de vias permanentes (exceto trilhos)' },
  { id: '410105 Encarregado de escritório - exclusive no serviço público', descricao: 'Encarregado de escritório - exclusive no serviço público' },
  { id: '414125 Encarregado de estoque', descricao: 'Encarregado de estoque' },
  { id: '414135 Encarregado de expedição', descricao: 'Encarregado de expedição' },
  { id: '710120 Encarregado de extração (mineração)', descricao: 'Encarregado de extração (mineração)' },
  { id: '710120 Encarregado de extração de magnésio', descricao: 'Encarregado de extração de magnésio' },
  { id: '710120 Encarregado de extração de pedreira', descricao: 'Encarregado de extração de pedreira' },
  { id: '710120 Encarregado de extração de talco', descricao: 'Encarregado de extração de talco' },
  { id: '848115 Encarregado de frios', descricao: 'Encarregado de frios' },
  { id: '840115 Encarregado de fumo desfiado', descricao: 'Encarregado de fumo desfiado' },
  { id: '514110 Encarregado de garagem', descricao: 'Encarregado de garagem' },
  { id: '760605 Encarregado de gráfica', descricao: 'Encarregado de gráfica' },
  { id: '513325 Encarregado de guarda-roupa de cinema', descricao: 'Encarregado de guarda-roupa de cinema' },
  { id: '513310 Encarregado de guarda-roupa de televisão', descricao: 'Encarregado de guarda-roupa de televisão' },
  { id: '620105 Encarregado de horticultura', descricao: 'Encarregado de horticultura' },
  { id: '620105 Encarregado de hortifrutigrangeiros', descricao: 'Encarregado de hortifrutigrangeiros' },
  { id: '510205 Encarregado de lavanderia', descricao: 'Encarregado de lavanderia' },
  { id: '342305 Encarregado de linha de transporte rodoviário', descricao: 'Encarregado de linha de transporte rodoviário' },
  { id: '910910 Encarregado de lubrificação de vias permanentes', descricao: 'Encarregado de lubrificação de vias permanentes' },
  { id: '415210 Encarregado de malote', descricao: 'Encarregado de malote' },
  { id: '313115 Encarregado de manutenção', descricao: 'Encarregado de manutenção' },
  { id: '313415 Encarregado de manutenção de instrumentos de controle, medição e similares', descricao: 'Encarregado de manutenção de instrumentos de controle, medição e similares' },
  { id: '725030 Encarregado de manutenção e recarga de extintor de incêndio', descricao: 'Encarregado de manutenção e recarga de extintor de incêndio' },
  { id: '950205 Encarregado de manutenção elétrica de veículos', descricao: 'Encarregado de manutenção elétrica de veículos' },
  { id: '910105 Encarregado de manutenção mecânica de sistemas operacionais', descricao: 'Encarregado de manutenção mecânica de sistemas operacionais' },
  { id: '848505 Encarregado de matadouro', descricao: 'Encarregado de matadouro' },
  { id: '760105 Encarregado de mecerização', descricao: 'Encarregado de mecerização' },
  { id: '810110 Encarregado de misturas de divisão de corantes e produtos químicos', descricao: 'Encarregado de misturas de divisão de corantes e produtos químicos' },
  { id: '840105 Encarregado de moinho', descricao: 'Encarregado de moinho' },
  { id: '313115 Encarregado de montagem', descricao: 'Encarregado de montagem' },
  { id: '914405 Encarregado de montagem de caixa diferencial', descricao: 'Encarregado de montagem de caixa diferencial' },
  { id: '770105 Encarregado de montagem de móveis', descricao: 'Encarregado de montagem de móveis' },
  { id: '720145 Encarregado de montagem de tubos', descricao: 'Encarregado de montagem de tubos' },
  { id: '710205 Encarregado de obras', descricao: 'Encarregado de obras' },
  { id: '710205 Encarregado de obras de manutenção', descricao: 'Encarregado de obras de manutenção' },
  { id: '710205 Encarregado de obras e instalações', descricao: 'Encarregado de obras e instalações' },
  { id: '710205 Encarregado de obras, manutenção e segurança', descricao: 'Encarregado de obras, manutenção e segurança' },
  { id: '141605 Encarregado de operação de porto', descricao: 'Encarregado de operação de porto' },
  { id: '342610 Encarregado de operações portuárias', descricao: 'Encarregado de operações portuárias' },
  { id: '410230 Encarregado de orçamento', descricao: 'Encarregado de orçamento' },
  { id: '720140 Encarregado de oxicorte', descricao: 'Encarregado de oxicorte' },
  { id: '848305 Encarregado de padaria', descricao: 'Encarregado de padaria' },
  { id: '410220 Encarregado de patrimônio', descricao: 'Encarregado de patrimônio' },
  { id: '710120 Encarregado de pedreira (mineração)', descricao: 'Encarregado de pedreira (mineração)' },
  { id: '414115 Encarregado de pesagem', descricao: 'Encarregado de pesagem' },
  { id: '720135 Encarregado de pintura (tratamento de superfícies)', descricao: 'Encarregado de pintura (tratamento de superfícies)' },
  { id: '510120 Encarregado de portaria de hotel', descricao: 'Encarregado de portaria de hotel' },
  { id: '517330 Encarregado de portaria e segurança', descricao: 'Encarregado de portaria e segurança' },
  { id: '840115 Encarregado de preparação de talo (indústria de fumo)', descricao: 'Encarregado de preparação de talo (indústria de fumo)' },
  { id: '770105 Encarregado de produção (indústria de madeira e mobiliário)', descricao: 'Encarregado de produção (indústria de madeira e mobiliário)' },
  { id: '820205 Encarregado de produção na fabricação de produtos de cerâmica, porcelanatos e afins', descricao: 'Encarregado de produção na fabricação de produtos de cerâmica, porcelanatos e afins' },
  { id: '820210 Encarregado de produção na fabricação de produtos de vidros', descricao: 'Encarregado de produção na fabricação de produtos de vidros' },
  { id: '810110 Encarregado de produção química', descricao: 'Encarregado de produção química' },
  { id: '142135 Encarregado de proteção de dados pessoais', descricao: 'Encarregado de proteção de dados pessoais' },
  { id: '840115 Encarregado de qualidade de fumo', descricao: 'Encarregado de qualidade de fumo' },
  { id: '420125 Encarregado de recepção', descricao: 'Encarregado de recepção' },
  { id: '414210 Encarregado de seção de controle de produção', descricao: 'Encarregado de seção de controle de produção' },
  { id: '780105 Encarregado de seção de empacotamento', descricao: 'Encarregado de seção de empacotamento' },
  { id: '720125 Encarregado de seção de fundição', descricao: 'Encarregado de seção de fundição' },
  { id: '517330 Encarregado de segurança', descricao: 'Encarregado de segurança' },
  { id: '770105 Encarregado de serraria', descricao: 'Encarregado de serraria' },
  { id: '710205 Encarregado de servente', descricao: 'Encarregado de servente' },
  { id: '410105 Encarregado de serviço - exclusive no serviço público', descricao: 'Encarregado de serviço - exclusive no serviço público' },
  { id: '261305 Encarregado de serviço de arquivo médico e estatística', descricao: 'Encarregado de serviço de arquivo médico e estatística' },
  { id: '783235 Encarregado de serviço de capatazia', descricao: 'Encarregado de serviço de capatazia' },
  { id: '412120 Encarregado de serviço de digitação', descricao: 'Encarregado de serviço de digitação' },
  { id: '783235 Encarregado de serviços de cais (capatazia)', descricao: 'Encarregado de serviços de cais (capatazia)' },
  { id: '519940 Encarregado de serviços de leitura e verificação de consumo de energia elétrica, água e gás', descricao: 'Encarregado de serviços de leitura e verificação de consumo de energia elétrica, água e gás' },
  { id: '770105 Encarregado de serviços de serras e tornos', descricao: 'Encarregado de serviços de serras e tornos' },
  { id: '810205 Encarregado de setor de borracha', descricao: 'Encarregado de setor de borracha' },
  { id: '710220 Encarregado de setor de concreto', descricao: 'Encarregado de setor de concreto' },
  { id: '622020 Encarregado de silos', descricao: 'Encarregado de silos' },
  { id: '720140 Encarregado de soldagem', descricao: 'Encarregado de soldagem' },
  { id: '142305 Encarregado de supermercado', descricao: 'Encarregado de supermercado' },
  { id: '420130 Encarregado de telefonistas', descricao: 'Encarregado de telefonistas' },
  { id: '420135 Encarregado de telemarketing', descricao: 'Encarregado de telemarketing' },
  { id: '410235 Encarregado de tesouraria', descricao: 'Encarregado de tesouraria' },
  { id: '511205 Encarregado de tráfego', descricao: 'Encarregado de tráfego' },
  { id: '342305 Encarregado de tráfego rodoviário', descricao: 'Encarregado de tráfego rodoviário' },
  { id: '510105 Encarregado de tráfego rodoviário e ferroviário', descricao: 'Encarregado de tráfego rodoviário e ferroviário' },
  { id: '511210 Encarregado de transporte coletivo (exceto trem)', descricao: 'Encarregado de transporte coletivo (exceto trem)' },
  { id: '780105 Encarregado de turma de acondicionamento', descricao: 'Encarregado de turma de acondicionamento' },
  { id: '910105 Encarregado de turma de manutenção mecânica de sistemas operacionais', descricao: 'Encarregado de turma de manutenção mecânica de sistemas operacionais' },
  { id: '950110 Encarregado de turmas de eletromecânicos', descricao: 'Encarregado de turmas de eletromecânicos' },
  { id: '950110 Encarregado de turno de manutenção eletromecânica', descricao: 'Encarregado de turno de manutenção eletromecânica' },
  { id: '720150 Encarregado de usinagem de metais', descricao: 'Encarregado de usinagem de metais' },
  { id: '720125 Encarregado de vazamento de fundição de ferro', descricao: 'Encarregado de vazamento de fundição de ferro' },
  { id: '517330 Encarregado de vigilância - organizações particulares de segurança', descricao: 'Encarregado de vigilância - organizações particulares de segurança' },
  { id: '780105 Encarregado do setor de embalagem', descricao: 'Encarregado do setor de embalagem' },
  { id: '950105 Encarregado eletricista de instalações', descricao: 'Encarregado eletricista de instalações' },
  { id: '950110 Encarregado eletromecânico de instalações', descricao: 'Encarregado eletromecânico de instalações' },
  { id: '840115 Encarregado fabricação de cigarros', descricao: 'Encarregado fabricação de cigarros' },
  { id: '321210 Encarregado florestal', descricao: 'Encarregado florestal' },
  { id: '760110 Encarregado geral de fiação', descricao: 'Encarregado geral de fiação' },
  { id: '720125 Encarregado geral de fundição', descricao: 'Encarregado geral de fundição' },
  { id: '760115 Encarregado geral de malharia', descricao: 'Encarregado geral de malharia' },
  { id: '992205 Encarregado geral de operações de conservação de vias permanentes (exceto trilhos)', descricao: 'Encarregado geral de operações de conservação de vias permanentes (exceto trilhos)' },
  { id: '760120 Encarregado geral de tecelagem', descricao: 'Encarregado geral de tecelagem' },
  { id: '620110 Encarregado na agropecuária', descricao: 'Encarregado na agropecuária' },
  { id: '620115 Encarregado na exploração de pecuária', descricao: 'Encarregado na exploração de pecuária' },
  { id: '510205 Encarregado operacional de lavanderia', descricao: 'Encarregado operacional de lavanderia' },
  { id: '262220 Encenador teatral', descricao: 'Encenador teatral' },
  { id: '784105 Enchedor de bandejas', descricao: 'Enchedor de bandejas' },
  { id: '848115 Enchedor de lingüiças', descricao: 'Enchedor de lingüiças' },
  { id: '848115 Enchedor de salame', descricao: 'Enchedor de salame' },
  { id: '261520 Enciclopedista', descricao: 'Enciclopedista' },
  { id: '623125 Encilhador', descricao: 'Encilhador' },
  { id: '622020 Encoivarador - na cultura', descricao: 'Encoivarador - na cultura' },
  { id: '742115 Encordoador de instrumentos musicais', descricao: 'Encordoador de instrumentos musicais' },
  { id: '711115 Encunhador - na extração de pedras', descricao: 'Encunhador - na extração de pedras' },
  { id: '711115 Encunhador de pedreira', descricao: 'Encunhador de pedreira' },
  { id: '225155 Endocrinologista', descricao: 'Endocrinologista' },
  { id: '223212 Endodontólogo', descricao: 'Endodontólogo' },
  { id: '225310 Endoscopista', descricao: 'Endoscopista' },
  { id: '784125 Enfardador', descricao: 'Enfardador' },
  { id: '519215 Enfardador de material de sucata (cooperativa)', descricao: 'Enfardador de material de sucata (cooperativa)' },
  { id: '519205 Enfardador de sucata (cooperativa)', descricao: 'Enfardador de sucata (cooperativa)' },
  { id: '632325 Enfeixador de palha de carnaúba', descricao: 'Enfeixador de palha de carnaúba' },
  { id: '223545 Enfermeira parteira', descricao: 'Enfermeira parteira' },
  { id: '223505 Enfermeiro', descricao: 'Enfermeiro' },
  { id: '223510 Enfermeiro auditor', descricao: 'Enfermeiro auditor' },
  { id: '223540 Enfermeiro de berçário', descricao: 'Enfermeiro de berçário' },
  { id: '223515 Enfermeiro de bordo', descricao: 'Enfermeiro de bordo' },
  { id: '223520 Enfermeiro de centro cirúrgico', descricao: 'Enfermeiro de centro cirúrgico' },
  { id: '223560 Enfermeiro de saúde publica', descricao: 'Enfermeiro de saúde publica' },
  { id: '223525 Enfermeiro de terapia intensiva', descricao: 'Enfermeiro de terapia intensiva' },
  { id: '223530 Enfermeiro do trabalho', descricao: 'Enfermeiro do trabalho' },
  { id: '223525 Enfermeiro intensivista', descricao: 'Enfermeiro intensivista' },
  { id: '223535 Enfermeiro nefrologista', descricao: 'Enfermeiro nefrologista' },
  { id: '223540 Enfermeiro neonatologista', descricao: 'Enfermeiro neonatologista' },
  { id: '223545 Enfermeiro obstétrico', descricao: 'Enfermeiro obstétrico' },
  { id: '223550 Enfermeiro psiquiátrico', descricao: 'Enfermeiro psiquiátrico' },
  { id: '223555 Enfermeiro puericultor e pediátrico', descricao: 'Enfermeiro puericultor e pediátrico' },
  { id: '223560 Enfermeiro sanitarista', descricao: 'Enfermeiro sanitarista' },
  { id: '2235 ENFERMEIROS E AFINS', descricao: 'ENFERMEIROS E AFINS' },
  { id: '763115 Enfestador de roupas', descricao: 'Enfestador de roupas' },
  { id: '761327 Enformador de malharia', descricao: 'Enformador de malharia' },
  { id: '761336 Enformador de meias', descricao: 'Enformador de meias' },
  { id: '841805 Enfornador de pão', descricao: 'Enfornador de pão' },
  { id: '828110 Enfornador de tijolos', descricao: 'Enfornador de tijolos' },
  { id: '784120 Engarrafador', descricao: 'Engarrafador' },
  { id: '751005 Engastador (jóias)', descricao: 'Engastador (jóias)' },
  { id: '214425 Engenheiro aeronáutico', descricao: 'Engenheiro aeronáutico' },
  { id: '222105 Engenheiro agrícola', descricao: 'Engenheiro agrícola' },
  { id: '214805 Engenheiro agrimensor', descricao: 'Engenheiro agrimensor' },
  { id: '222110 Engenheiro agrônomo', descricao: 'Engenheiro agrônomo' },
  { id: '222110 Engenheiro agrônomo (agricultura)', descricao: 'Engenheiro agrônomo (agricultura)' },
  { id: '222110 Engenheiro agrônomo (solos)', descricao: 'Engenheiro agrônomo (solos)' },
  { id: '214005 Engenheiro ambiental', descricao: 'Engenheiro ambiental' },
  { id: '222115 Engenheiro aqüicultor', descricao: 'Engenheiro aqüicultor' },
  { id: '214105 Engenheiro arquiteto', descricao: 'Engenheiro arquiteto' },
  { id: '214380 Engenheiro biomédico', descricao: 'Engenheiro biomédico' },
  { id: '214810 Engenheiro cartógrafo', descricao: 'Engenheiro cartógrafo' },
  { id: '214205 Engenheiro civil', descricao: 'Engenheiro civil' },
  { id: '214210 Engenheiro civil (aeroportos)', descricao: 'Engenheiro civil (aeroportos)' },
  { id: '214215 Engenheiro civil (edificações)', descricao: 'Engenheiro civil (edificações)' },
  { id: '214220 Engenheiro civil (estruturas metálicas)', descricao: 'Engenheiro civil (estruturas metálicas)' },
  { id: '214225 Engenheiro civil (ferrovias e metrovias)', descricao: 'Engenheiro civil (ferrovias e metrovias)' },
  { id: '214230 Engenheiro civil (fundações)', descricao: 'Engenheiro civil (fundações)' },
  { id: '214230 Engenheiro civil (geotécnia)', descricao: 'Engenheiro civil (geotécnia)' },
  { id: '214240 Engenheiro civil (hidráulica)', descricao: 'Engenheiro civil (hidráulica)' },
  { id: '214235 Engenheiro civil (hidrologia)', descricao: 'Engenheiro civil (hidrologia)' },
  { id: '214230 Engenheiro civil (mecânica de solos)', descricao: 'Engenheiro civil (mecânica de solos)' },
  { id: '214260 Engenheiro civil (obras sanitárias)', descricao: 'Engenheiro civil (obras sanitárias)' },
  { id: '214245 Engenheiro civil (pontes e viadutos)', descricao: 'Engenheiro civil (pontes e viadutos)' },
  { id: '214250 Engenheiro civil (portos e vias navegáveis)', descricao: 'Engenheiro civil (portos e vias navegáveis)' },
  { id: '214255 Engenheiro civil (rodovias)', descricao: 'Engenheiro civil (rodovias)' },
  { id: '214260 Engenheiro civil (saneamento)', descricao: 'Engenheiro civil (saneamento)' },
  { id: '214255 Engenheiro civil (terraplanagem)', descricao: 'Engenheiro civil (terraplanagem)' },
  { id: '214270 Engenheiro civil (transportes e trânsito)', descricao: 'Engenheiro civil (transportes e trânsito)' },
  { id: '214265 Engenheiro civil (túneis)', descricao: 'Engenheiro civil (túneis)' },
  { id: '214380 Engenheiro clínico', descricao: 'Engenheiro clínico' },
  { id: '222205 Engenheiro de alimentos', descricao: 'Engenheiro de alimentos' },
  { id: '214925 Engenheiro de análise de trabalho', descricao: 'Engenheiro de análise de trabalho' },
  { id: '212205 Engenheiro de aplicativos em computação', descricao: 'Engenheiro de aplicativos em computação' },
  { id: '202110 Engenheiro de automação', descricao: 'Engenheiro de automação' },
  { id: '214705 Engenheiro de beneficiamento de minério', descricao: 'Engenheiro de beneficiamento de minério' },
  { id: '214520 Engenheiro de celulose e papel', descricao: 'Engenheiro de celulose e papel' },
  { id: '214340 Engenheiro de comutação', descricao: 'Engenheiro de comutação' },
  { id: '214705 Engenheiro de concentração', descricao: 'Engenheiro de concentração' },
  { id: '214430 Engenheiro de construção naval', descricao: 'Engenheiro de construção naval' },
  { id: '202110 Engenheiro de controle', descricao: 'Engenheiro de controle' },
  { id: '214910 Engenheiro de controle de qualidade', descricao: 'Engenheiro de controle de qualidade' },
  { id: '202110 Engenheiro de controle e automação', descricao: 'Engenheiro de controle e automação' },
  { id: '214505 Engenheiro de desenvolvimento químico', descricao: 'Engenheiro de desenvolvimento químico' },
  { id: '214375 Engenheiro de energia', descricao: 'Engenheiro de energia' },
  { id: '212210 Engenheiro de equipamentos em computação', descricao: 'Engenheiro de equipamentos em computação' },
  { id: '214255 Engenheiro de estradas', descricao: 'Engenheiro de estradas' },
  { id: '222120 Engenheiro de fauna e flora', descricao: 'Engenheiro de fauna e flora' },
  { id: '214810 Engenheiro de geodésia e topografia', descricao: 'Engenheiro de geodésia e topografia' },
  { id: '214255 Engenheiro de geometria', descricao: 'Engenheiro de geometria' },
  { id: '212210 Engenheiro de hardware computacional', descricao: 'Engenheiro de hardware computacional' },
  { id: '214510 Engenheiro de indústria química', descricao: 'Engenheiro de indústria química' },
  { id: '202110 Engenheiro de instrumentação', descricao: 'Engenheiro de instrumentação' },
  { id: '222105 Engenheiro de irrigação e drenagem', descricao: 'Engenheiro de irrigação e drenagem' },
  { id: '214945 Engenheiro de logistica', descricao: 'Engenheiro de logistica' },
  { id: '214270 Engenheiro de logística', descricao: 'Engenheiro de logística' },
  { id: '214335 Engenheiro de manutenção de telecomunicações', descricao: 'Engenheiro de manutenção de telecomunicações' },
  { id: '214410 Engenheiro de manutenção de veículos', descricao: 'Engenheiro de manutenção de veículos' },
  { id: '214315 Engenheiro de manutenção elétrica', descricao: 'Engenheiro de manutenção elétrica' },
  { id: '214325 Engenheiro de manutenção eletrônica', descricao: 'Engenheiro de manutenção eletrônica' },
  { id: '374115 Engenheiro de master', descricao: 'Engenheiro de master' },
  { id: '214605 Engenheiro de materiais', descricao: 'Engenheiro de materiais' },
  { id: '214605 Engenheiro de materiais (cerâmica)', descricao: 'Engenheiro de materiais (cerâmica)' },
  { id: '214605 Engenheiro de materiais (metais)', descricao: 'Engenheiro de materiais (metais)' },
  { id: '214605 Engenheiro de materiais (polímeros)', descricao: 'Engenheiro de materiais (polímeros)' },
  { id: '214005 Engenheiro de meio ambiente', descricao: 'Engenheiro de meio ambiente' },
  { id: '214705 Engenheiro de minas', descricao: 'Engenheiro de minas' },
  { id: '214710 Engenheiro de minas (beneficiamento)', descricao: 'Engenheiro de minas (beneficiamento)' },
  { id: '214705 Engenheiro de minas (carvão)', descricao: 'Engenheiro de minas (carvão)' },
  { id: '214715 Engenheiro de minas (lavra a céu aberto)', descricao: 'Engenheiro de minas (lavra a céu aberto)' },
  { id: '214720 Engenheiro de minas (lavra subterrânea)', descricao: 'Engenheiro de minas (lavra subterrânea)' },
  { id: '214725 Engenheiro de minas (pesquisa mineral)', descricao: 'Engenheiro de minas (pesquisa mineral)' },
  { id: '214730 Engenheiro de minas (planejamento)', descricao: 'Engenheiro de minas (planejamento)' },
  { id: '214735 Engenheiro de minas (processo)', descricao: 'Engenheiro de minas (processo)' },
  { id: '214740 Engenheiro de minas (projeto)', descricao: 'Engenheiro de minas (projeto)' },
  { id: '214705 Engenheiro de mineração', descricao: 'Engenheiro de mineração' },
  { id: '214270 Engenheiro de operação (transporte rodoviário)', descricao: 'Engenheiro de operação (transporte rodoviário)' },
  { id: '214905 Engenheiro de organização e métodos', descricao: 'Engenheiro de organização e métodos' },
  { id: '214905 Engenheiro de organização industrial', descricao: 'Engenheiro de organização industrial' },
  { id: '214255 Engenheiro de pavimentação', descricao: 'Engenheiro de pavimentação' },
  { id: '222115 Engenheiro de pesca', descricao: 'Engenheiro de pesca' },
  { id: '214205 Engenheiro de planejamento', descricao: 'Engenheiro de planejamento' },
  { id: '214905 Engenheiro de planejamento industrial', descricao: 'Engenheiro de planejamento industrial' },
  { id: '214905 Engenheiro de processamento', descricao: 'Engenheiro de processamento' },
  { id: '214505 Engenheiro de processamento químico', descricao: 'Engenheiro de processamento químico' },
  { id: '214905 Engenheiro de processos', descricao: 'Engenheiro de processos' },
  { id: '214905 Engenheiro de produção', descricao: 'Engenheiro de produção' },
  { id: '214345 Engenheiro de projetos de telecomunicações', descricao: 'Engenheiro de projetos de telecomunicações' },
  { id: '214345 Engenheiro de projetos de telefonia', descricao: 'Engenheiro de projetos de telefonia' },
  { id: '214320 Engenheiro de projetos elétricos', descricao: 'Engenheiro de projetos elétricos' },
  { id: '214330 Engenheiro de projetos eletrônicos', descricao: 'Engenheiro de projetos eletrônicos' },
  { id: '214255 Engenheiro de projetos viários', descricao: 'Engenheiro de projetos viários' },
  { id: '214910 Engenheiro de qualidade', descricao: 'Engenheiro de qualidade' },
  { id: '214350 Engenheiro de redes de comunicação', descricao: 'Engenheiro de redes de comunicação' },
  { id: '214920 Engenheiro de riscos', descricao: 'Engenheiro de riscos' },
  { id: '214915 Engenheiro de segurança do trabalho', descricao: 'Engenheiro de segurança do trabalho' },
  { id: '214915 Engenheiro de segurança industrial', descricao: 'Engenheiro de segurança industrial' },
  { id: '214255 Engenheiro de segurança viária', descricao: 'Engenheiro de segurança viária' },
  { id: '214255 Engenheiro de sinalização viária', descricao: 'Engenheiro de sinalização viária' },
  { id: '212205 Engenheiro de sistemas computacionais - aplicativos', descricao: 'Engenheiro de sistemas computacionais - aplicativos' },
  { id: '212210 Engenheiro de sistemas computacionais - equipamentos', descricao: 'Engenheiro de sistemas computacionais - equipamentos' },
  { id: '214350 Engenheiro de sistemas de comunicação', descricao: 'Engenheiro de sistemas de comunicação' },
  { id: '212215 Engenheiro de software computacional básico', descricao: 'Engenheiro de software computacional básico' },
  { id: '212205 Engenheiro de softwares computacionais', descricao: 'Engenheiro de softwares computacionais' },
  { id: '212215 Engenheiro de suporte de sistemas operacionais em computação', descricao: 'Engenheiro de suporte de sistemas operacionais em computação' },
  { id: '214340 Engenheiro de telecomunicações', descricao: 'Engenheiro de telecomunicações' },
  { id: '214925 Engenheiro de tempos e movimentos', descricao: 'Engenheiro de tempos e movimentos' },
  { id: '214270 Engenheiro de tráfego', descricao: 'Engenheiro de tráfego' },
  { id: '214340 Engenheiro de tráfego telefônico', descricao: 'Engenheiro de tráfego telefônico' },
  { id: '214270 Engenheiro de trânsito', descricao: 'Engenheiro de trânsito' },
  { id: '214340 Engenheiro de transmissão', descricao: 'Engenheiro de transmissão' },
  { id: '214270 Engenheiro de transportes', descricao: 'Engenheiro de transportes' },
  { id: '214705 Engenheiro de tratamento de minério', descricao: 'Engenheiro de tratamento de minério' },
  { id: '214305 Engenheiro eletricista', descricao: 'Engenheiro eletricista' },
  { id: '214315 Engenheiro eletricista de manutenção', descricao: 'Engenheiro eletricista de manutenção' },
  { id: '214320 Engenheiro eletricista de projetos', descricao: 'Engenheiro eletricista de projetos' },
  { id: '214305 Engenheiro elétrico', descricao: 'Engenheiro elétrico' },
  { id: '214310 Engenheiro eletrônico', descricao: 'Engenheiro eletrônico' },
  { id: '214325 Engenheiro eletrônico de manutenção', descricao: 'Engenheiro eletrônico de manutenção' },
  { id: '214330 Engenheiro eletrônico de projetos', descricao: 'Engenheiro eletrônico de projetos' },
  { id: '214305 Engenheiro eletrotécnico', descricao: 'Engenheiro eletrotécnico' },
  { id: '214320 Engenheiro eletrotécnico de projetos', descricao: 'Engenheiro eletrotécnico de projetos' },
  { id: '222105 Engenheiro especialista em construções rurais', descricao: 'Engenheiro especialista em construções rurais' },
  { id: '222105 Engenheiro especialista em construções rurais e ambiência', descricao: 'Engenheiro especialista em construções rurais e ambiência' },
  { id: '222105 Engenheiro especialista em mecanização agrícola', descricao: 'Engenheiro especialista em mecanização agrícola' },
  { id: '222120 Engenheiro especialista em preservação florestal', descricao: 'Engenheiro especialista em preservação florestal' },
  { id: '222105 Engenheiro especialista em secagem e armazenagem de grãos', descricao: 'Engenheiro especialista em secagem e armazenagem de grãos' },
  { id: '222120 Engenheiro especialista em tecnologia de madeira', descricao: 'Engenheiro especialista em tecnologia de madeira' },
  { id: '222115 Engenheiro especialista em tecnologia de pesca', descricao: 'Engenheiro especialista em tecnologia de pesca' },
  { id: '222115 Engenheiro especialista em tecnologia de pescado', descricao: 'Engenheiro especialista em tecnologia de pescado' },
  { id: '222120 Engenheiro florestal', descricao: 'Engenheiro florestal' },
  { id: '222110 Engenheiro horticultor', descricao: 'Engenheiro horticultor' },
  { id: '214405 Engenheiro mecânico', descricao: 'Engenheiro mecânico' },
  { id: '214415 Engenheiro mecânico (energia nuclear)', descricao: 'Engenheiro mecânico (energia nuclear)' },
  { id: '214410 Engenheiro mecânico (veículos automotores)', descricao: 'Engenheiro mecânico (veículos automotores)' },
  { id: '214410 Engenheiro mecânico automobilístico', descricao: 'Engenheiro mecânico automobilístico' },
  { id: '214410 Engenheiro mecânico automotivo', descricao: 'Engenheiro mecânico automotivo' },
  { id: '214420 Engenheiro mecânico industrial', descricao: 'Engenheiro mecânico industrial' },
  { id: '214415 Engenheiro mecânico nuclear', descricao: 'Engenheiro mecânico nuclear' },
  { id: '202105 Engenheiro mecatrônico', descricao: 'Engenheiro mecatrônico' },
  { id: '214610 Engenheiro metalúrgico', descricao: 'Engenheiro metalúrgico' },
  { id: '214610 Engenheiro metalúrgico (produção de metais)', descricao: 'Engenheiro metalúrgico (produção de metais)' },
  { id: '214610 Engenheiro metalúrgico (siderurgia)', descricao: 'Engenheiro metalúrgico (siderurgia)' },
  { id: '214610 Engenheiro metalúrgico (tratamento de metais)', descricao: 'Engenheiro metalúrgico (tratamento de metais)' },
  { id: '214610 Engenheiro metalurgista', descricao: 'Engenheiro metalurgista' },
  { id: '214430 Engenheiro naval', descricao: 'Engenheiro naval' },
  { id: '214360 Engenheiro operacional em eletrotécnica', descricao: 'Engenheiro operacional em eletrotécnica' },
  { id: '214205 Engenheiro orçamentista', descricao: 'Engenheiro orçamentista' },
  { id: '203205 Engenheiro pesquisador (engenharia civil)', descricao: 'Engenheiro pesquisador (engenharia civil)' },
  { id: '203215 Engenheiro pesquisador (engenharia elétrica e eletrônica)', descricao: 'Engenheiro pesquisador (engenharia elétrica e eletrônica)' },
  { id: '203220 Engenheiro pesquisador (engenharia mecânica)', descricao: 'Engenheiro pesquisador (engenharia mecânica)' },
  { id: '203230 Engenheiro pesquisador (engenharia química)', descricao: 'Engenheiro pesquisador (engenharia química)' },
  { id: '203225 Engenheiro pesquisador (materiais)', descricao: 'Engenheiro pesquisador (materiais)' },
  { id: '203225 Engenheiro pesquisador (metalurgia)', descricao: 'Engenheiro pesquisador (metalurgia)' },
  { id: '203225 Engenheiro pesquisador (minas)', descricao: 'Engenheiro pesquisador (minas)' },
  { id: '203210 Engenheiro pesquisador (outras áreas da engenharia)', descricao: 'Engenheiro pesquisador (outras áreas da engenharia)' },
  { id: '203215 Engenheiro pesquisador (telecomunicações)', descricao: 'Engenheiro pesquisador (telecomunicações)' },
  { id: '214205 Engenheiro projetista', descricao: 'Engenheiro projetista' },
  { id: '374135 Engenheiro projetista de áudio', descricao: 'Engenheiro projetista de áudio' },
  { id: '214345 Engenheiro projetista de telecomunicações', descricao: 'Engenheiro projetista de telecomunicações' },
  { id: '214505 Engenheiro químico', descricao: 'Engenheiro químico' },
  { id: '214510 Engenheiro químico (ácidos e bases)', descricao: 'Engenheiro químico (ácidos e bases)' },
  { id: '214510 Engenheiro químico (açúcar e álcool)', descricao: 'Engenheiro químico (açúcar e álcool)' },
  { id: '214505 Engenheiro químico (alimentos e bebidas)', descricao: 'Engenheiro químico (alimentos e bebidas)' },
  { id: '214525 Engenheiro químico (borracha)', descricao: 'Engenheiro químico (borracha)' },
  { id: '214520 Engenheiro químico (celulose)', descricao: 'Engenheiro químico (celulose)' },
  { id: '214515 Engenheiro químico (cerâmica)', descricao: 'Engenheiro químico (cerâmica)' },
  { id: '214515 Engenheiro químico (cimento)', descricao: 'Engenheiro químico (cimento)' },
  { id: '214505 Engenheiro químico (controle de qualidade)', descricao: 'Engenheiro químico (controle de qualidade)' },
  { id: '214510 Engenheiro químico (cosméticos, higiene e limpeza)', descricao: 'Engenheiro químico (cosméticos, higiene e limpeza)' },
  { id: '214525 Engenheiro químico (derivados de petróleo)', descricao: 'Engenheiro químico (derivados de petróleo)' },
  { id: '214510 Engenheiro químico (fertilizantes)', descricao: 'Engenheiro químico (fertilizantes)' },
  { id: '214510 Engenheiro químico (indústria química)', descricao: 'Engenheiro químico (indústria química)' },
  { id: '214530 Engenheiro químico (meio ambiente)', descricao: 'Engenheiro químico (meio ambiente)' },
  { id: '214515 Engenheiro químico (metalurgia)', descricao: 'Engenheiro químico (metalurgia)' },
  { id: '214515 Engenheiro químico (mineração)', descricao: 'Engenheiro químico (mineração)' },
  { id: '214515 Engenheiro químico (mineração, metalurgia, siderurgia, cimenteira e cerâmica)', descricao: 'Engenheiro químico (mineração, metalurgia, siderurgia, cimenteira e cerâmica)' },
  { id: '214520 Engenheiro químico (papel e celulose)', descricao: 'Engenheiro químico (papel e celulose)' },
  { id: '214520 Engenheiro químico (papel)', descricao: 'Engenheiro químico (papel)' },
  { id: '214525 Engenheiro químico (petróleo e borracha)', descricao: 'Engenheiro químico (petróleo e borracha)' },
  { id: '214525 Engenheiro químico (petróleo e petroquímica)', descricao: 'Engenheiro químico (petróleo e petroquímica)' },
  { id: '214525 Engenheiro químico (plástico)', descricao: 'Engenheiro químico (plástico)' },
  { id: '214510 Engenheiro químico (produtos farmacêuticos)', descricao: 'Engenheiro químico (produtos farmacêuticos)' },
  { id: '214515 Engenheiro químico (siderurgia)', descricao: 'Engenheiro químico (siderurgia)' },
  { id: '214510 Engenheiro químico (solventes e tintas)', descricao: 'Engenheiro químico (solventes e tintas)' },
  { id: '214540 Engenheiro químico (têxtil)', descricao: 'Engenheiro químico (têxtil)' },
  { id: '214530 Engenheiro químico (utilidades e meio ambiente)', descricao: 'Engenheiro químico (utilidades e meio ambiente)' },
  { id: '214530 Engenheiro químico (utilidades)', descricao: 'Engenheiro químico (utilidades)' },
  { id: '214505 Engenheiro químico de processos', descricao: 'Engenheiro químico de processos' },
  { id: '214505 Engenheiro químico de produção', descricao: 'Engenheiro químico de produção' },
  { id: '214505 Engenheiro químico de projetos', descricao: 'Engenheiro químico de projetos' },
  { id: '214505 Engenheiro químico, em geral', descricao: 'Engenheiro químico, em geral' },
  { id: '214255 Engenheiro rodoviário', descricao: 'Engenheiro rodoviário' },
  { id: '214260 Engenheiro sanitarista', descricao: 'Engenheiro sanitarista' },
  { id: '222120 Engenheiro silvicultor', descricao: 'Engenheiro silvicultor' },
  { id: '214540 Engenheiro têxtil', descricao: 'Engenheiro têxtil' },
  { id: '214705 Engenheiro tuneleiro', descricao: 'Engenheiro tuneleiro' },
  { id: '2148 ENGENHEIROS AGRIMENSORES E ENGENHEIROS CARTÓGRAFOS', descricao: 'ENGENHEIROS AGRIMENSORES E ENGENHEIROS CARTÓGRAFOS' },
  { id: '2221 ENGENHEIROS AGROSSILVIPECUÁRIOS', descricao: 'ENGENHEIROS AGROSSILVIPECUÁRIOS' },
  { id: '2140 ENGENHEIROS AMBIENTAIS E AFINS', descricao: 'ENGENHEIROS AMBIENTAIS E AFINS' },
  { id: '2142 ENGENHEIROS CIVIS E AFINS', descricao: 'ENGENHEIROS CIVIS E AFINS' },
  { id: '2222 ENGENHEIROS DE ALIMENTOS E AFINS', descricao: 'ENGENHEIROS DE ALIMENTOS E AFINS' },
  { id: '2021 ENGENHEIROS DE CONTROLE E AUTOMAÇÃO, ENGENHEIROS MECATRÔNICOS E AFINS', descricao: 'ENGENHEIROS DE CONTROLE E AUTOMAÇÃO, ENGENHEIROS MECATRÔNICOS E AFINS' },
  { id: '2147 ENGENHEIROS DE MINAS E AFINS', descricao: 'ENGENHEIROS DE MINAS E AFINS' },
  { id: '2149 ENGENHEIROS DE PRODUÇÃO, QUALIDADE, SEGURANÇA E AFINS', descricao: 'ENGENHEIROS DE PRODUÇÃO, QUALIDADE, SEGURANÇA E AFINS' },
  { id: '212215 Engenheiros de sistemas operacionais em computação', descricao: 'Engenheiros de sistemas operacionais em computação' },
  { id: '2143 ENGENHEIROS ELETRICISTAS, ELETRÔNICOS E AFINS', descricao: 'ENGENHEIROS ELETRICISTAS, ELETRÔNICOS E AFINS' },
  { id: '2122 ENGENHEIROS EM COMPUTAÇÃO', descricao: 'ENGENHEIROS EM COMPUTAÇÃO' },
  { id: '2144 ENGENHEIROS MECÂNICOS E AFINS', descricao: 'ENGENHEIROS MECÂNICOS E AFINS' },
  { id: '2146 ENGENHEIROS METALURGISTAS, DE MATERIAIS E AFINS', descricao: 'ENGENHEIROS METALURGISTAS, DE MATERIAIS E AFINS' },
  { id: '2145 ENGENHEIROS QUÍMICOS E AFINS', descricao: 'ENGENHEIROS QUÍMICOS E AFINS' },
  { id: '613110 Engordador de gado bovino - empregador', descricao: 'Engordador de gado bovino - empregador' },
  { id: '613005 Engordador de gado bovino, suíno, caprino - conta própria', descricao: 'Engordador de gado bovino, suíno, caprino - conta própria' },
  { id: '613110 Engordador de zebu - empregador', descricao: 'Engordador de zebu - empregador' },
  { id: '784105 Engradador', descricao: 'Engradador' },
  { id: '919105 Engraxador de máquinas', descricao: 'Engraxador de máquinas' },
  { id: '519915 Engraxate', descricao: 'Engraxate' },
  { id: '325005 Enólogo', descricao: 'Enólogo' },
  { id: '3250 ENÓLOGOS, PERFUMISTAS E AROMISTAS', descricao: 'ENÓLOGOS, PERFUMISTAS E AROMISTAS' },
  { id: '325005 Enotécnico', descricao: 'Enotécnico' },
  { id: '712105 Enquadrador de pedra - na mineração', descricao: 'Enquadrador de pedra - na mineração' },
  { id: '766325 Enramador gráfico de corte e vinco', descricao: 'Enramador gráfico de corte e vinco' },
  { id: '842230 Enrolador de charutos', descricao: 'Enrolador de charutos' },
  { id: '784105 Ensacador', descricao: 'Ensacador' },
  { id: '262825 Ensaiador de dança', descricao: 'Ensaiador de dança' },
  { id: '262220 Ensaiador de teatro', descricao: 'Ensaiador de teatro' },
  { id: '261515 Ensaísta de ficção', descricao: 'Ensaísta de ficção' },
  { id: '261520 Ensaísta de não ficção', descricao: 'Ensaísta de não ficção' },
  { id: '715210 Entaipador', descricao: 'Entaipador' },
  { id: '775105 Entalhador de madeira', descricao: 'Entalhador de madeira' },
  { id: '715210 Entijolador', descricao: 'Entijolador' },
  { id: '203010 Entomologista', descricao: 'Entomologista' },
  { id: '203010 Entomólogo', descricao: 'Entomólogo' },
  { id: '415215 Entregador de assinaturas (jornais e revistas)', descricao: 'Entregador de assinaturas (jornais e revistas)' },
  { id: '783225 Entregador de bebidas (ajudante de caminhão)', descricao: 'Entregador de bebidas (ajudante de caminhão)' },
  { id: '415205 Entregador de correspondências (correios e telégrafos)', descricao: 'Entregador de correspondências (correios e telégrafos)' },
  { id: '783225 Entregador de gás (ajudante de caminhão)', descricao: 'Entregador de gás (ajudante de caminhão)' },
  { id: '415215 Entregador de jornais e revistas', descricao: 'Entregador de jornais e revistas' },
  { id: '415215 Entregador de publicações', descricao: 'Entregador de publicações' },
  { id: '415215 Entregador de venda avulsa (jornais e revistas)', descricao: 'Entregador de venda avulsa (jornais e revistas)' },
  { id: '424105 Entrevistador censitário e de pesquisas amostrais', descricao: 'Entrevistador censitário e de pesquisas amostrais' },
  { id: '424105 Entrevistador de campo', descricao: 'Entrevistador de campo' },
  { id: '424110 Entrevistador de cati (computer assisted telephone interviewing)', descricao: 'Entrevistador de cati (computer assisted telephone interviewing)' },
  { id: '424110 Entrevistador de mídia', descricao: 'Entrevistador de mídia' },
  { id: '424110 Entrevistador de pesquisa de opinião e mídia', descricao: 'Entrevistador de pesquisa de opinião e mídia' },
  { id: '424110 Entrevistador de pesquisa em profundidade', descricao: 'Entrevistador de pesquisa em profundidade' },
  { id: '424115 Entrevistador de pesquisas de mercado', descricao: 'Entrevistador de pesquisas de mercado' },
  { id: '424120 Entrevistador de preços', descricao: 'Entrevistador de preços' },
  { id: '424130 Entrevistador social', descricao: 'Entrevistador social' },
  { id: '4241 ENTREVISTADORES E RECENSEADORES', descricao: 'ENTREVISTADORES E RECENSEADORES' },
  { id: '523120 Envelopador', descricao: 'Envelopador' },
  { id: '622020 Enxadeiro', descricao: 'Enxadeiro' },
  { id: '622020 Enxadeiro - na cultura', descricao: 'Enxadeiro - na cultura' },
  { id: '622605 Enxertador de cacau', descricao: 'Enxertador de cacau' },
  { id: '762215 Enxugador de couros', descricao: 'Enxugador de couros' },
  { id: '762205 Enxugador de couros e peles', descricao: 'Enxugador de couros e peles' },
  { id: '519920 Enxugador de quadra', descricao: 'Enxugador de quadra' },
  { id: '519935 Enxugador de veículos', descricao: 'Enxugador de veículos' },
  { id: '519935 Enxugador e acabador na lavagem de veículos', descricao: 'Enxugador e acabador na lavagem de veículos' },
  { id: '225139 Epidemiologista', descricao: 'Epidemiologista' },
  { id: '263105 Episcopiza', descricao: 'Episcopiza' },
  { id: '613105 Eqüideocultor de asininos e muares', descricao: 'Eqüideocultor de asininos e muares' },
  { id: '376230 Equilibrista', descricao: 'Equilibrista' },
  { id: '613130 Eqüinocultor', descricao: 'Eqüinocultor' },
  { id: '226315 Equoterapeuta', descricao: 'Equoterapeuta' },
  { id: '141410 Ervanário (comércio varejista)', descricao: 'Ervanário (comércio varejista)' },
  { id: '632010 Erveiro', descricao: 'Erveiro' },
  { id: '511205 Escalante', descricao: 'Escalante' },
  { id: '513410 Escanção', descricao: 'Escanção' },
  { id: '821410 Escarfador', descricao: 'Escarfador' },
  { id: '848515 Escarnador em matadouro e açougue', descricao: 'Escarnador em matadouro e açougue' },
  { id: '715115 Escavadeirista', descricao: 'Escavadeirista' },
  { id: '622020 Escolhedor - na cultura', descricao: 'Escolhedor - na cultura' },
  { id: '391225 Escolhedor de papel', descricao: 'Escolhedor de papel' },
  { id: '711125 Escorador de minas', descricao: 'Escorador de minas' },
  { id: '721325 Escovador de fundição', descricao: 'Escovador de fundição' },
  { id: '764305 Escovador de solas no acabamento de calçados', descricao: 'Escovador de solas no acabamento de calçados' },
  { id: '351405 Escrevente', descricao: 'Escrevente' },
  { id: '351405 Escrevente extra - judicial', descricao: 'Escrevente extra - judicial' },
  { id: '351405 Escrevente judicial', descricao: 'Escrevente judicial' },
  { id: '351515 Escrevente técnico estenotipista', descricao: 'Escrevente técnico estenotipista' },
  { id: '261515 Escritor de cordel', descricao: 'Escritor de cordel' },
  { id: '261515 Escritor de ficção', descricao: 'Escritor de ficção' },
  { id: '261515 Escritor de folhetim', descricao: 'Escritor de folhetim' },
  { id: '261515 Escritor de histórias em quadrinhos', descricao: 'Escritor de histórias em quadrinhos' },
  { id: '261520 Escritor de não ficção', descricao: 'Escritor de não ficção' },
  { id: '261515 Escritor de novela de rádio', descricao: 'Escritor de novela de rádio' },
  { id: '261515 Escritor de novela de televisão', descricao: 'Escritor de novela de televisão' },
  { id: '261520 Escritor de obra didática', descricao: 'Escritor de obra didática' },
  { id: '261520 Escritor de obras científicas', descricao: 'Escritor de obras científicas' },
  { id: '261515 Escritor de obras educativas de ficção', descricao: 'Escritor de obras educativas de ficção' },
  { id: '261520 Escritor de obras educativas de não ficção', descricao: 'Escritor de obras educativas de não ficção' },
  { id: '261520 Escritor de obras técnicas', descricao: 'Escritor de obras técnicas' },
  { id: '411005 Escriturário', descricao: 'Escriturário' },
  { id: '424125 Escriturário em estatística', descricao: 'Escriturário em estatística' },
  { id: '413225 Escriturário de banco', descricao: 'Escriturário de banco' },
  { id: '4132 ESCRITURÁRIOS DE SERVIÇOS BANCÁRIOS', descricao: 'ESCRITURÁRIOS DE SERVIÇOS BANCÁRIOS' },
  { id: '351410 Escrivão civil', descricao: 'Escrivão civil' },
  { id: '351410 Escrivão criminal', descricao: 'Escrivão criminal' },
  { id: '351415 Escrivão de cartório', descricao: 'Escrivão de cartório' },
  { id: '351420 Escrivão de polícia', descricao: 'Escrivão de polícia' },
  { id: '351415 Escrivão extra - judicial', descricao: 'Escrivão extra - judicial' },
  { id: '351410 Escrivão judicial', descricao: 'Escrivão judicial' },
  { id: '262405 Escultor', descricao: 'Escultor' },
  { id: '775105 Escultor de madeira', descricao: 'Escultor de madeira' },
  { id: '752405 Escultor de vidro', descricao: 'Escultor de vidro' },
  { id: '752410 Escultor de vidro (acabamento)', descricao: 'Escultor de vidro (acabamento)' },
  { id: '848505 Esfolador em matadouro', descricao: 'Esfolador em matadouro' },
  { id: '723215 Esmaltador', descricao: 'Esmaltador' },
  { id: '752420 Esmaltador cerâmico', descricao: 'Esmaltador cerâmico' },
  { id: '723215 Esmaltador de banho fundente', descricao: 'Esmaltador de banho fundente' },
  { id: '752420 Esmaltador de cerâmica por imersão', descricao: 'Esmaltador de cerâmica por imersão' },
  { id: '723215 Esmaltador de metais não-preciosos', descricao: 'Esmaltador de metais não-preciosos' },
  { id: '821450 Esmerilador de metais', descricao: 'Esmerilador de metais' },
  { id: '516805 Esotérico', descricao: 'Esotérico' },
  { id: '5168 ESOTÉRICOS E PARANORMAIS', descricao: 'ESOTÉRICOS E PARANORMAIS' },
  { id: '622020 Esparramador de adubos', descricao: 'Esparramador de adubos' },
  { id: '252210 Especialista contábil', descricao: 'Especialista contábil' },
  { id: '142325 Especialista de comunicação em relações públicas', descricao: 'Especialista de comunicação em relações públicas' },
  { id: '261210 Especialista de documentação', descricao: 'Especialista de documentação' },
  { id: '261205 Especialista de informação', descricao: 'Especialista de informação' },
  { id: '391130 Especialista de manutenção (equipamentos industriais)', descricao: 'Especialista de manutenção (equipamentos industriais)' },
  { id: '111505 Especialista de políticas públicas e gestão governamental - eppgg', descricao: 'Especialista de políticas públicas e gestão governamental - eppgg' },
  { id: '212405 Especialista de sistemas de ti', descricao: 'Especialista de sistemas de ti' },
  { id: '766120 Especialista em arte final', descricao: 'Especialista em arte final' },
  { id: '201210 Especialista em calibrações metrológicas', descricao: 'Especialista em calibrações metrológicas' },
  { id: '752305 Especialista em cerâmica', descricao: 'Especialista em cerâmica' },
  { id: '513410 Especialista em cerveja', descricao: 'Especialista em cerveja' },
  { id: '142125 Especialista em compliance', descricao: 'Especialista em compliance' },
  { id: '261310 Especialista em conservação de acervos', descricao: 'Especialista em conservação de acervos' },
  { id: '214910 Especialista em controle de qualidade e planejamento', descricao: 'Especialista em controle de qualidade e planejamento' },
  { id: '142610 Especialista em desenvolvimento de cigarros', descricao: 'Especialista em desenvolvimento de cigarros' },
  { id: '261305 Especialista em documentação arquivística', descricao: 'Especialista em documentação arquivística' },
  { id: '261310 Especialista em documentação museológica', descricao: 'Especialista em documentação museológica' },
  { id: '261310 Especialista em educação em museus', descricao: 'Especialista em educação em museus' },
  { id: '203205 Especialista em engenharia civil', descricao: 'Especialista em engenharia civil' },
  { id: '203210 Especialista em engenharia e tecnologia (outras áreas)', descricao: 'Especialista em engenharia e tecnologia (outras áreas)' },
  { id: '203215 Especialista em engenharia elétrica e eletrônica', descricao: 'Especialista em engenharia elétrica e eletrônica' },
  { id: '203220 Especialista em engenharia mecânica', descricao: 'Especialista em engenharia mecânica' },
  { id: '203225 Especialista em engenharia metalúrgica, de minas e de materiais', descricao: 'Especialista em engenharia metalúrgica, de minas e de materiais' },
  { id: '203230 Especialista em engenharia química', descricao: 'Especialista em engenharia química' },
  { id: '201215 Especialista em ensaios metrológicos', descricao: 'Especialista em ensaios metrológicos' },
  { id: '314205 Especialista em ferramentas (técnico mecânico na fabricação de ferramentas)', descricao: 'Especialista em ferramentas (técnico mecânico na fabricação de ferramentas)' },
  { id: '314210 Especialista em ferramentas (técnico mecânico na manutenção de ferramentas)', descricao: 'Especialista em ferramentas (técnico mecânico na manutenção de ferramentas)' },
  { id: '263115 Especialista em história da tradição, doutrina e textos sagrados', descricao: 'Especialista em história da tradição, doutrina e textos sagrados' },
  { id: '201220 Especialista em instrumentação metrológica', descricao: 'Especialista em instrumentação metrológica' },
  { id: '731305 Especialista em linha de transmissão', descricao: 'Especialista em linha de transmissão' },
  { id: '201225 Especialista em materiais de referência metrológica', descricao: 'Especialista em materiais de referência metrológica' },
  { id: '261310 Especialista em museografia de exposição', descricao: 'Especialista em museografia de exposição' },
  { id: '261305 Especialista em organização de arquivos', descricao: 'Especialista em organização de arquivos' },
  { id: '211110 Especialista em pesquisa operacional', descricao: 'Especialista em pesquisa operacional' },
  { id: '212320 Especialista em segurança da informação', descricao: 'Especialista em segurança da informação' },
  { id: '953105 Especialista em sistema elétrico de aviões', descricao: 'Especialista em sistema elétrico de aviões' },
  { id: '214370 Especialista em telecomunicações (tecnólogo )', descricao: 'Especialista em telecomunicações (tecnólogo )' },
  { id: '513410 Especialista em vinho', descricao: 'Especialista em vinho' },
  { id: '3148 ESPECIALISTAS EM INSPEÇÕES INDUSTRIAIS', descricao: 'ESPECIALISTAS EM INSPEÇÕES INDUSTRIAIS' },
  { id: '3421 ESPECIALISTAS EM LOGÍSTICA DE TRANSPORTES', descricao: 'ESPECIALISTAS EM LOGÍSTICA DE TRANSPORTES' },
  { id: '3541 ESPECIALISTAS EM PROMOÇÃO DE PRODUTOS E VENDAS', descricao: 'ESPECIALISTAS EM PROMOÇÃO DE PRODUTOS E VENDAS' },
  { id: '752425 Espelhador', descricao: 'Espelhador' },
  { id: '752425 Espelhador (esmaltador)', descricao: 'Espelhador (esmaltador)' },
  { id: '752425 Espelhador - na fabricação de espelhos', descricao: 'Espelhador - na fabricação de espelhos' },
  { id: '761815 Espinçador (produção têxtil)', descricao: 'Espinçador (produção têxtil)' },
  { id: '377110 Esportista no futebol', descricao: 'Esportista no futebol' },
  { id: '377125 Esportista profissional de tênis', descricao: 'Esportista profissional de tênis' },
  { id: '724610 Espulador (cabos de aço)', descricao: 'Espulador (cabos de aço)' },
  { id: '773115 Esquadriador (madeira)', descricao: 'Esquadriador (madeira)' },
  { id: '412205 Estafeta', descricao: 'Estafeta' },
  { id: '141520 Estalageiro', descricao: 'Estalageiro' },
  { id: '523125 Estampador de placa de identificação veicular (epiv)', descricao: 'Estampador de placa de identificação veicular (epiv)' },
  { id: '761410 Estampador de tecido', descricao: 'Estampador de tecido' },
  { id: '141410 Estancieiro (comércio varejista)', descricao: 'Estancieiro (comércio varejista)' },
  { id: '613110 Estancieiro - na criação de gado bovino - empregador', descricao: 'Estancieiro - na criação de gado bovino - empregador' },
  { id: '613115 Estancieiro de leite', descricao: 'Estancieiro de leite' },
  { id: '613110 Estancieiro na criação de gado bovino (gado de corte)', descricao: 'Estancieiro na criação de gado bovino (gado de corte)' },
  { id: '723215 Estanhador', descricao: 'Estanhador' },
  { id: '211205 Estatístico', descricao: 'Estatístico' },
  { id: '211210 Estatístico (estatística aplicada)', descricao: 'Estatístico (estatística aplicada)' },
  { id: '211205 Estatístico - analista', descricao: 'Estatístico - analista' },
  { id: '211215 Estatístico teórico', descricao: 'Estatístico teórico' },
  { id: '376405 Estátua viva', descricao: 'Estátua viva' },
  { id: '776425 Esteireiro', descricao: 'Esteireiro' },
  { id: '351510 Estenógrafo', descricao: 'Estenógrafo' },
  { id: '351515 Estenotipista', descricao: 'Estenotipista' },
  { id: '351515 Estenotipista em ´close caption´', descricao: 'Estenotipista em ´close caption´' },
  { id: '351515 Estenotipista ´free lancer´', descricao: 'Estenotipista ´free lancer´' },
  { id: '351515 Estenotipista técnico judiciário', descricao: 'Estenotipista técnico judiciário' },
  { id: '351515 Estenotipista técnico parlamentar', descricao: 'Estenotipista técnico parlamentar' },
  { id: '622020 Estercador', descricao: 'Estercador' },
  { id: '841440 Esterilizador de alimentos', descricao: 'Esterilizador de alimentos' },
  { id: '322130 Esteticista', descricao: 'Esteticista' },
  { id: '322130 Esteticista corporal', descricao: 'Esteticista corporal' },
  { id: '519310 Esteticista de animais domésticos', descricao: 'Esteticista de animais domésticos' },
  { id: '322130 Esteticista facial', descricao: 'Esteticista facial' },
  { id: '262425 Estilista de moda', descricao: 'Estilista de moda' },
  { id: '762305 Estirador de couros e peles (acabamento)', descricao: 'Estirador de couros e peles (acabamento)' },
  { id: '762115 Estirador de couros e peles (preparação)', descricao: 'Estirador de couros e peles (preparação)' },
  { id: '722410 Estirador de tubo de metal', descricao: 'Estirador de tubo de metal' },
  { id: '722410 Estirador de tubos de metal sem costura', descricao: 'Estirador de tubos de metal sem costura' },
  { id: '783220 Estivador', descricao: 'Estivador' },
  { id: '765230 Estofador de aviões', descricao: 'Estofador de aviões' },
  { id: '765235 Estofador de móveis', descricao: 'Estofador de móveis' },
  { id: '765240 Estofador de veículos', descricao: 'Estofador de veículos' },
  { id: '414125 Estoquista', descricao: 'Estoquista' },
  { id: '848525 Estripador (matadouro)', descricao: 'Estripador (matadouro)' },
  { id: '715210 Estucador', descricao: 'Estucador' },
  { id: '784115 Etiquetador', descricao: 'Etiquetador' },
  { id: '784115 Etiquetador de embalagem', descricao: 'Etiquetador de embalagem' },
  { id: '784105 Etiquetador, a mão', descricao: 'Etiquetador, a mão' },
  { id: '263105 Evangelista', descricao: 'Evangelista' },
  { id: '848525 Eviscerador em matadouro', descricao: 'Eviscerador em matadouro' },
  { id: '732115 Examinador de cabos, linhas elétricas e telefônicas', descricao: 'Examinador de cabos, linhas elétricas e telefônicas' },
  { id: '761815 Examinador de meias (produção têxtil)', descricao: 'Examinador de meias (produção têxtil)' },
  { id: '376245 Excêntrico', descricao: 'Excêntrico' },
  { id: '263115 Exegeta', descricao: 'Exegeta' },
  { id: '764305 Expedidor de calçados', descricao: 'Expedidor de calçados' },
  { id: '414135 Expedidor de mercadorias', descricao: 'Expedidor de mercadorias' },
  { id: '711120 Explodidor - em minas e pedreiras', descricao: 'Explodidor - em minas e pedreiras' },
  { id: '632125 Explorador de madeira, em geral', descricao: 'Explorador de madeira, em geral' },
  { id: '141405 Exportador', descricao: 'Exportador' },
  { id: '141405 Exportador - empregador', descricao: 'Exportador - empregador' },
  { id: '621005 Exterminador de insetos - na agropecuária', descricao: 'Exterminador de insetos - na agropecuária' },
  { id: '6321 EXTRATIVISTAS E REFLORESTADORES DE ESPÉCIES PRODUTORAS DE MADEIRA', descricao: 'EXTRATIVISTAS E REFLORESTADORES DE ESPÉCIES PRODUTORAS DE MADEIRA' },
  { id: '6324 EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE ALIMENTOS SILVESTRES', descricao: 'EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE ALIMENTOS SILVESTRES' },
  { id: '6323 EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE FIBRAS, CERAS E ÓLEOS', descricao: 'EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE FIBRAS, CERAS E ÓLEOS' },
  { id: '6322 EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE GOMAS E RESINAS', descricao: 'EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE GOMAS E RESINAS' },
  { id: '6325 EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE SUBSTÂNCIAS AROMÁTICAS, MEDICINAIS E', descricao: 'EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE SUBSTÂNCIAS AROMÁTICAS, MEDICINAIS E' },
  { id: '632310 Extrator - na extração de babaçu', descricao: 'Extrator - na extração de babaçu' },
  { id: '712120 Extrator de britagem', descricao: 'Extrator de britagem' },
  { id: '632205 Extrator de látex', descricao: 'Extrator de látex' },
  { id: '632210 Extrator de látex (gomas não elásticas)', descricao: 'Extrator de látex (gomas não elásticas)' },
  { id: '848525 Extrator de miolos em matadouro', descricao: 'Extrator de miolos em matadouro' },
  { id: '632365 Extrator de piaçava', descricao: 'Extrator de piaçava' },
  { id: '632215 Extrator de resinas', descricao: 'Extrator de resinas' },
  { id: '766310 Extrusor (artes gráficas)', descricao: 'Extrusor (artes gráficas)' },
  { id: '811775 Extrusor de borracha', descricao: 'Extrusor de borracha' },
  { id: '823210 Extrusor de fios ou fibras de vidro', descricao: 'Extrusor de fios ou fibras de vidro' },
  { id: '812105 Fabricador de baladas', descricao: 'Fabricador de baladas' },
  { id: '812105 Fabricador de fogos de artifício', descricao: 'Fabricador de fogos de artifício' },
  { id: '261515 Fabulista', descricao: 'Fabulista' },
  { id: '711405 Faiscador - no garimpo', descricao: 'Faiscador - no garimpo' },
  { id: '773105 Falquejador', descricao: 'Falquejador' },
  { id: '376215 Faquir', descricao: 'Faquir' },
  { id: '223440 Farmacêutica criminalista', descricao: 'Farmacêutica criminalista' },
  { id: '223405 Farmacêutico', descricao: 'Farmacêutico' },
  { id: '223425 Farmacêutico acupunturista', descricao: 'Farmacêutico acupunturista' },
  { id: '223415 Farmacêutico analista clínico', descricao: 'Farmacêutico analista clínico' },
  { id: '223415 Farmacêutico analista clínico (bioquímico)', descricao: 'Farmacêutico analista clínico (bioquímico)' },
  { id: '223425 Farmacêutico antroposófico', descricao: 'Farmacêutico antroposófico' },
  { id: '223405 Farmacêutico auditor', descricao: 'Farmacêutico auditor' },
  { id: '223420 Farmacêutico bromatologista', descricao: 'Farmacêutico bromatologista' },
  { id: '223445 Farmacêutico clínico', descricao: 'Farmacêutico clínico' },
  { id: '223445 Farmacêutico clínico domiciliar', descricao: 'Farmacêutico clínico domiciliar' },
  { id: '223445 Farmacêutico clínico em cardiologia', descricao: 'Farmacêutico clínico em cardiologia' },
  { id: '223445 Farmacêutico clínico em cuidados paliativos', descricao: 'Farmacêutico clínico em cuidados paliativos' },
  { id: '223445 Farmacêutico clínico em famarcocinética clinica', descricao: 'Farmacêutico clínico em famarcocinética clinica' },
  { id: '223445 Farmacêutico clínico em farmacovigilância', descricao: 'Farmacêutico clínico em farmacovigilância' },
  { id: '223445 Farmacêutico clínico em geriatria', descricao: 'Farmacêutico clínico em geriatria' },
  { id: '223445 Farmacêutico clínico em hematologia', descricao: 'Farmacêutico clínico em hematologia' },
  { id: '223445 Farmacêutico clínico em oncologia', descricao: 'Farmacêutico clínico em oncologia' },
  { id: '223445 Farmacêutico clínico em pediatria', descricao: 'Farmacêutico clínico em pediatria' },
  { id: '223445 Farmacêutico clínico em reumatologia', descricao: 'Farmacêutico clínico em reumatologia' },
  { id: '223445 Farmacêutico clínico em terapia antineoplásica', descricao: 'Farmacêutico clínico em terapia antineoplásica' },
  { id: '223420 Farmacêutico de alimentos', descricao: 'Farmacêutico de alimentos' },
  { id: '223405 Farmacêutico de manipulação', descricao: 'Farmacêutico de manipulação' },
  { id: '223435 Farmacêutico em alfândega', descricao: 'Farmacêutico em alfândega' },
  { id: '223420 Farmacêutico em alimentos funcionais e nutracêuticos', descricao: 'Farmacêutico em alimentos funcionais e nutracêuticos' },
  { id: '223420 Farmacêutico em análise de alimentos', descricao: 'Farmacêutico em análise de alimentos' },
  { id: '223440 Farmacêutico em análise de solo', descricao: 'Farmacêutico em análise de solo' },
  { id: '223415 Farmacêutico em análises clínicas', descricao: 'Farmacêutico em análises clínicas' },
  { id: '223435 Farmacêutico em armazenamento', descricao: 'Farmacêutico em armazenamento' },
  { id: '223445 Farmacêutico em assistência domiciliar', descricao: 'Farmacêutico em assistência domiciliar' },
  { id: '223405 Farmacêutico em atenção farmacêutica', descricao: 'Farmacêutico em atenção farmacêutica' },
  { id: '223415 Farmacêutico em bacteriologia clínica', descricao: 'Farmacêutico em bacteriologia clínica' },
  { id: '223420 Farmacêutico em banco de leite', descricao: 'Farmacêutico em banco de leite' },
  { id: '223415 Farmacêutico em banco de materiais biológicos', descricao: 'Farmacêutico em banco de materiais biológicos' },
  { id: '223415 Farmacêutico em banco de órgãos, tecidos e células', descricao: 'Farmacêutico em banco de órgãos, tecidos e células' },
  { id: '223415 Farmacêutico em banco de sangue', descricao: 'Farmacêutico em banco de sangue' },
  { id: '223415 Farmacêutico em banco de sêmem', descricao: 'Farmacêutico em banco de sêmem' },
  { id: '223405 Farmacêutico em biofarmácia', descricao: 'Farmacêutico em biofarmácia' },
  { id: '223415 Farmacêutico em biologia molecular', descricao: 'Farmacêutico em biologia molecular' },
  { id: '223415 Farmacêutico em bioquímica clínica', descricao: 'Farmacêutico em bioquímica clínica' },
  { id: '223405 Farmacêutico em biossegurança', descricao: 'Farmacêutico em biossegurança' },
  { id: '223435 Farmacêutico em biotecnologia industrial', descricao: 'Farmacêutico em biotecnologia industrial' },
  { id: '223415 Farmacêutico em citogenética', descricao: 'Farmacêutico em citogenética' },
  { id: '223415 Farmacêutico em citologia clínica e diagnóstica', descricao: 'Farmacêutico em citologia clínica e diagnóstica' },
  { id: '223415 Farmacêutico em citopatologia', descricao: 'Farmacêutico em citopatologia' },
  { id: '223415 Farmacêutico em citoquímica', descricao: 'Farmacêutico em citoquímica' },
  { id: '223420 Farmacêutico em controle de qualidade de alimentos', descricao: 'Farmacêutico em controle de qualidade de alimentos' },
  { id: '223435 Farmacêutico em controle de qualidade de insumos, medicamentos, cosméticos e produtos veterinários', descricao: 'Farmacêutico em controle de qualidade de insumos, medicamentos, cosméticos e produtos veterinários' },
  { id: '223430 Farmacêutico em controle de qualidade e tratamento de água', descricao: 'Farmacêutico em controle de qualidade e tratamento de água' },
  { id: '223430 Farmacêutico em controle de vetores e pragas urbanas', descricao: 'Farmacêutico em controle de vetores e pragas urbanas' },
  { id: '223405 Farmacêutico em cosmetologia', descricao: 'Farmacêutico em cosmetologia' },
  { id: '223445 Farmacêutico em cuidados paliativos', descricao: 'Farmacêutico em cuidados paliativos' },
  { id: '223435 Farmacêutico em distribuidora', descricao: 'Farmacêutico em distribuidora' },
  { id: '223435 Farmacêutico em domissanitários', descricao: 'Farmacêutico em domissanitários' },
  { id: '223405 Farmacêutico em farmácia comunitária', descricao: 'Farmacêutico em farmácia comunitária' },
  { id: '223430 Farmacêutico em farmácia pública', descricao: 'Farmacêutico em farmácia pública' },
  { id: '223405 Farmacêutico em farmácia veterinária', descricao: 'Farmacêutico em farmácia veterinária' },
  { id: '223405 Farmacêutico em farmacocinética clínica', descricao: 'Farmacêutico em farmacocinética clínica' },
  { id: '223430 Farmacêutico em farmacoepidemiologia', descricao: 'Farmacêutico em farmacoepidemiologia' },
  { id: '223405 Farmacêutico em farmacotécnica', descricao: 'Farmacêutico em farmacotécnica' },
  { id: '223430 Farmacêutico em farmacovigilância', descricao: 'Farmacêutico em farmacovigilância' },
  { id: '223445 Farmacêutico em gases e misturas de usos terapêuticos', descricao: 'Farmacêutico em gases e misturas de usos terapêuticos' },
  { id: '223415 Farmacêutico em genética', descricao: 'Farmacêutico em genética' },
  { id: '223430 Farmacêutico em gerenciamento dos resíduos em serviços de saúde', descricao: 'Farmacêutico em gerenciamento dos resíduos em serviços de saúde' },
  { id: '223430 Farmacêutico em gestão ambiental', descricao: 'Farmacêutico em gestão ambiental' },
  { id: '223430 Farmacêutico em gestão de assistência farmacêutica', descricao: 'Farmacêutico em gestão de assistência farmacêutica' },
  { id: '223415 Farmacêutico em hematologia clínica', descricao: 'Farmacêutico em hematologia clínica' },
  { id: '223415 Farmacêutico em hemoterapia', descricao: 'Farmacêutico em hemoterapia' },
  { id: '223415 Farmacêutico em histocompatibilidade', descricao: 'Farmacêutico em histocompatibilidade' },
  { id: '223415 Farmacêutico em histoquímica', descricao: 'Farmacêutico em histoquímica' },
  { id: '223445 Farmacêutico em homecare', descricao: 'Farmacêutico em homecare' },
  { id: '223445 Farmacêutico em homoderivados', descricao: 'Farmacêutico em homoderivados' },
  { id: '223415 Farmacêutico em imunocitoquímica', descricao: 'Farmacêutico em imunocitoquímica' },
  { id: '223415 Farmacêutico em imunogenética', descricao: 'Farmacêutico em imunogenética' },
  { id: '223415 Farmacêutico em imunologia clínica', descricao: 'Farmacêutico em imunologia clínica' },
  { id: '223415 Farmacêutico em imunopatologia', descricao: 'Farmacêutico em imunopatologia' },
  { id: '223420 Farmacêutico em indústria de alimentos', descricao: 'Farmacêutico em indústria de alimentos' },
  { id: '223435 Farmacêutico em indústria de cosméticos', descricao: 'Farmacêutico em indústria de cosméticos' },
  { id: '223435 Farmacêutico em indústria farmacêutica', descricao: 'Farmacêutico em indústria farmacêutica' },
  { id: '223435 Farmacêutico em indústria químico-farmacêutico', descricao: 'Farmacêutico em indústria químico-farmacêutico' },
  { id: '223435 Farmacêutico em indústria veterinária', descricao: 'Farmacêutico em indústria veterinária' },
  { id: '223415 Farmacêutico em micologia clínica', descricao: 'Farmacêutico em micologia clínica' },
  { id: '223415 Farmacêutico em microbiologia clínica', descricao: 'Farmacêutico em microbiologia clínica' },
  { id: '223420 Farmacêutico em microbiologia de alimentos', descricao: 'Farmacêutico em microbiologia de alimentos' },
  { id: '223420 Farmacêutico em nutrição animal', descricao: 'Farmacêutico em nutrição animal' },
  { id: '223445 Farmacêutico em nutrição parenteral', descricao: 'Farmacêutico em nutrição parenteral' },
  { id: '223415 Farmacêutico em parasitologia clínica', descricao: 'Farmacêutico em parasitologia clínica' },
  { id: '223420 Farmacêutico em pesquisa e desenvolvimento de alimentos', descricao: 'Farmacêutico em pesquisa e desenvolvimento de alimentos' },
  { id: '223435 Farmacêutico em pesquisa e desenvolvimento de fármacos', descricao: 'Farmacêutico em pesquisa e desenvolvimento de fármacos' },
  { id: '223445 Farmacêutico em pesquisas clínicas', descricao: 'Farmacêutico em pesquisas clínicas' },
  { id: '223425 Farmacêutico em plantas medicinais e fitoterapia', descricao: 'Farmacêutico em plantas medicinais e fitoterapia' },
  { id: '223420 Farmacêutico em produção de alimentos', descricao: 'Farmacêutico em produção de alimentos' },
  { id: '223445 Farmacêutico em radioisótopos', descricao: 'Farmacêutico em radioisótopos' },
  { id: '223430 Farmacêutico em saúde ambiental', descricao: 'Farmacêutico em saúde ambiental' },
  { id: '223430 Farmacêutico em saúde ocupacional', descricao: 'Farmacêutico em saúde ocupacional' },
  { id: '223430 Farmacêutico em saúde pública', descricao: 'Farmacêutico em saúde pública' },
  { id: '223430 Farmacêutico em segurança do trabalho', descricao: 'Farmacêutico em segurança do trabalho' },
  { id: '223425 Farmacêutico em termalismo social/crenoterapia', descricao: 'Farmacêutico em termalismo social/crenoterapia' },
  { id: '223440 Farmacêutico em toxicologia ambiental', descricao: 'Farmacêutico em toxicologia ambiental' },
  { id: '223440 Farmacêutico em toxicologia analítica', descricao: 'Farmacêutico em toxicologia analítica' },
  { id: '223440 Farmacêutico em toxicologia clínica', descricao: 'Farmacêutico em toxicologia clínica' },
  { id: '223440 Farmacêutico em toxicologia de alimentos', descricao: 'Farmacêutico em toxicologia de alimentos' },
  { id: '223440 Farmacêutico em toxicologia de cosméticos', descricao: 'Farmacêutico em toxicologia de cosméticos' },
  { id: '223440 Farmacêutico em toxicologia de emergência', descricao: 'Farmacêutico em toxicologia de emergência' },
  { id: '223440 Farmacêutico em toxicologia de medicamentos', descricao: 'Farmacêutico em toxicologia de medicamentos' },
  { id: '223440 Farmacêutico em toxicologia desportiva', descricao: 'Farmacêutico em toxicologia desportiva' },
  { id: '223440 Farmacêutico em toxicologia experimental', descricao: 'Farmacêutico em toxicologia experimental' },
  { id: '223440 Farmacêutico em toxicologia forense', descricao: 'Farmacêutico em toxicologia forense' },
  { id: '223440 Farmacêutico em toxicologia ocupacional', descricao: 'Farmacêutico em toxicologia ocupacional' },
  { id: '223440 Farmacêutico em toxicologia veterinária', descricao: 'Farmacêutico em toxicologia veterinária' },
  { id: '223435 Farmacêutico em transportadora', descricao: 'Farmacêutico em transportadora' },
  { id: '223430 Farmacêutico em vigilância epidemiológica', descricao: 'Farmacêutico em vigilância epidemiológica' },
  { id: '223415 Farmacêutico em virologia clínica', descricao: 'Farmacêutico em virologia clínica' },
  { id: '223405 Farmacêutico farmacologista', descricao: 'Farmacêutico farmacologista' },
  { id: '223425 Farmacêutico fitoterapeuta', descricao: 'Farmacêutico fitoterapeuta' },
  { id: '223425 Farmacêutico homeopata', descricao: 'Farmacêutico homeopata' },
  { id: '223445 Farmacêutico hospitalar e clínico', descricao: 'Farmacêutico hospitalar e clínico' },
  { id: '223435 Farmacêutico industrial', descricao: 'Farmacêutico industrial' },
  { id: '223435 Farmacêutico logístico', descricao: 'Farmacêutico logístico' },
  { id: '223405 Farmacêutico magistral', descricao: 'Farmacêutico magistral' },
  { id: '223445 Farmacêutico nuclear', descricao: 'Farmacêutico nuclear' },
  { id: '223425 Farmacêutico práticas integrativas e complementares', descricao: 'Farmacêutico práticas integrativas e complementares' },
  { id: '223445 Farmacêutico pré-hospitalar em serviços de urgência e emergência', descricao: 'Farmacêutico pré-hospitalar em serviços de urgência e emergência' },
  { id: '223440 Farmacêutico toxicologista', descricao: 'Farmacêutico toxicologista' },
  { id: '2234 FARMACÊUTICOS', descricao: 'FARMACÊUTICOS' },
  { id: '341235 Faroleiro (meio militar)', descricao: 'Faroleiro (meio militar)' },
  { id: '848515 Fateiro em matadouro', descricao: 'Fateiro em matadouro' },
  { id: '413115 Faturista', descricao: 'Faturista' },
  { id: '514320 Faxineiro', descricao: 'Faxineiro' },
  { id: '512115 Faxineiro no serviço doméstico', descricao: 'Faxineiro no serviço doméstico' },
  { id: '621005 Fazedor de cerca - inclusive na agropecuária', descricao: 'Fazedor de cerca - inclusive na agropecuária' },
  { id: '613105 Fazendeiro - na criação de asininos e muares', descricao: 'Fazendeiro - na criação de asininos e muares' },
  { id: '613110 Fazendeiro - na criação de bovinos - empregador', descricao: 'Fazendeiro - na criação de bovinos - empregador' },
  { id: '613205 Fazendeiro - na criação de caprinos - empregador', descricao: 'Fazendeiro - na criação de caprinos - empregador' },
  { id: '613130 Fazendeiro - na criação de eqüinos - empregador', descricao: 'Fazendeiro - na criação de eqüinos - empregador' },
  { id: '613210 Fazendeiro - na criação de ovinos', descricao: 'Fazendeiro - na criação de ovinos' },
  { id: '613120 Fazendeiro de búfalos - corte', descricao: 'Fazendeiro de búfalos - corte' },
  { id: '613125 Fazendeiro de búfalos - leite', descricao: 'Fazendeiro de búfalos - leite' },
  { id: '611005 Fazendeiro na agropecuária - conta própria', descricao: 'Fazendeiro na agropecuária - conta própria' },
  { id: '613215 Fazendeiro na criação de suínos - empregador', descricao: 'Fazendeiro na criação de suínos - empregador' },
  { id: '752115 Fechador de ampolas (garrafas térmicas)', descricao: 'Fechador de ampolas (garrafas térmicas)' },
  { id: '722320 Fechador de moldes - na fundição', descricao: 'Fechador de moldes - na fundição' },
  { id: '524205 Feirante', descricao: 'Feirante' },
  { id: '712120 Feitor de britagem', descricao: 'Feitor de britagem' },
  { id: '711410 Feitor de salinas', descricao: 'Feitor de salinas' },
  { id: '710210 Feitor de turma (ferrovias)', descricao: 'Feitor de turma (ferrovias)' },
  { id: '710210 Feitor de turma de ferrovia', descricao: 'Feitor de turma de ferrovia' },
  { id: '710210 Feitor de vias férreas', descricao: 'Feitor de vias férreas' },
  { id: '203025 Fenologista', descricao: 'Fenologista' },
  { id: '841715 Fermentador', descricao: 'Fermentador' },
  { id: '841715 Fermentador de cerveja', descricao: 'Fermentador de cerveja' },
  { id: '848605 Fermentador de fumo', descricao: 'Fermentador de fumo' },
  { id: '841715 Fermentador de vinho', descricao: 'Fermentador de vinho' },
  { id: '623030 Ferrador de animais', descricao: 'Ferrador de animais' },
  { id: '623030 Ferrador de equinos', descricao: 'Ferrador de equinos' },
  { id: '623030 Ferrageador de equinos', descricao: 'Ferrageador de equinos' },
  { id: '141410 Ferrageiro (comércio varejista)', descricao: 'Ferrageiro (comércio varejista)' },
  { id: '141410 Ferragista (comércio varejista)', descricao: 'Ferragista (comércio varejista)' },
  { id: '721105 Ferramenteiro', descricao: 'Ferramenteiro' },
  { id: '721105 Ferramenteiro de bancada', descricao: 'Ferramenteiro de bancada' },
  { id: '721105 Ferramenteiro de coquilhas', descricao: 'Ferramenteiro de coquilhas' },
  { id: '721105 Ferramenteiro de injeção termofixo', descricao: 'Ferramenteiro de injeção termofixo' },
  { id: '721105 Ferramenteiro de injeção termoplástico', descricao: 'Ferramenteiro de injeção termoplástico' },
  { id: '721105 Ferramenteiro de injeção zamak e alumínio', descricao: 'Ferramenteiro de injeção zamak e alumínio' },
  { id: '721110 Ferramenteiro de mandris, calibradores e outros dispositivos', descricao: 'Ferramenteiro de mandris, calibradores e outros dispositivos' },
  { id: '721105 Ferramenteiro de manutenção', descricao: 'Ferramenteiro de manutenção' },
  { id: '721105 Ferramenteiro de matrizes e estampos', descricao: 'Ferramenteiro de matrizes e estampos' },
  { id: '721105 Ferramenteiro de molde para borracha', descricao: 'Ferramenteiro de molde para borracha' },
  { id: '721105 Ferramenteiro de moldes plásticos (sopro)', descricao: 'Ferramenteiro de moldes plásticos (sopro)' },
  { id: '7211 FERRAMENTEIROS E AFINS', descricao: 'FERRAMENTEIROS E AFINS' },
  { id: '722105 Ferreiro', descricao: 'Ferreiro' },
  { id: '715315 Ferreiro armador na construção civil', descricao: 'Ferreiro armador na construção civil' },
  { id: '722105 Ferreiro de molas', descricao: 'Ferreiro de molas' },
  { id: '848115 Fiambreiro na conservação de alimentos', descricao: 'Fiambreiro na conservação de alimentos' },
  { id: '761230 Fiandeiro', descricao: 'Fiandeiro' },
  { id: '823210 Fibrador (fabricação de fibra de vidro)', descricao: 'Fibrador (fabricação de fibra de vidro)' },
  { id: '622215 Fibreiro de rami', descricao: 'Fibreiro de rami' },
  { id: '622210 Fibreiro de sisal', descricao: 'Fibreiro de sisal' },
  { id: '415125 Ficharista', descricao: 'Ficharista' },
  { id: '203025 Ficologista', descricao: 'Ficologista' },
  { id: '414110 Fiel de depósito', descricao: 'Fiel de depósito' },
  { id: '262505 Figurante', descricao: 'Figurante' },
  { id: '751125 Filigraneiro', descricao: 'Filigraneiro' },
  { id: '751125 Filigranista', descricao: 'Filigranista' },
  { id: '261405 Filólogo', descricao: 'Filólogo' },
  { id: '261405 Filólogo dicionarista', descricao: 'Filólogo dicionarista' },
  { id: '2614 FILÓLOGOS,TRADUTORES ,INTÉRPRETES E AFINS', descricao: 'FILÓLOGOS,TRADUTORES ,INTÉRPRETES E AFINS' },
  { id: '251405 Filósofo', descricao: 'Filósofo' },
  { id: '2514 FILÓSOFOS', descricao: 'FILÓSOFOS' },
  { id: '841710 Filtrador de cerveja', descricao: 'Filtrador de cerveja' },
  { id: '811330 Filtrador de goma', descricao: 'Filtrador de goma' },
  { id: '841710 Filtrador na indústria de bebidas', descricao: 'Filtrador na indústria de bebidas' },
  { id: '374410 Finalizador de filmes', descricao: 'Finalizador de filmes' },
  { id: '374415 Finalizador de vídeo', descricao: 'Finalizador de vídeo' },
  { id: '2544 FISCAIS DE TRIBUTOS ESTADUAIS E MUNICIPAIS', descricao: 'FISCAIS DE TRIBUTOS ESTADUAIS E MUNICIPAIS' },
  { id: '5112 FISCAIS E COBRADORES DOS TRANSPORTES COLETIVOS', descricao: 'FISCAIS E COBRADORES DOS TRANSPORTES COLETIVOS' },
  { id: '254505 Fiscal de atividades econômicas', descricao: 'Fiscal de atividades econômicas' },
  { id: '254505 Fiscal de atividades urbanas', descricao: 'Fiscal de atividades urbanas' },
  { id: '342515 Fiscal de aviação civil (fac)', descricao: 'Fiscal de aviação civil (fac)' },
  { id: '414115 Fiscal de balanças', descricao: 'Fiscal de balanças' },
  { id: '424210 Fiscal de banheiro (concurso, avaliação, exame)', descricao: 'Fiscal de banheiro (concurso, avaliação, exame)' },
  { id: '377215 Fiscal de basquetebol', descricao: 'Fiscal de basquetebol' },
  { id: '421125 Fiscal de caixa', descricao: 'Fiscal de caixa' },
  { id: '514115 Fiscal de capela', descricao: 'Fiscal de capela' },
  { id: '710205 Fiscal de construção', descricao: 'Fiscal de construção' },
  { id: '254505 Fiscal de feiras livres', descricao: 'Fiscal de feiras livres' },
  { id: '352210 Fiscal de higiene', descricao: 'Fiscal de higiene' },
  { id: '620105 Fiscal de lavoura', descricao: 'Fiscal de lavoura' },
  { id: '511205 Fiscal de linha', descricao: 'Fiscal de linha' },
  { id: '517425 Fiscal de loja', descricao: 'Fiscal de loja' },
  { id: '312105 Fiscal de medição (obras civis)', descricao: 'Fiscal de medição (obras civis)' },
  { id: '352210 Fiscal de obras', descricao: 'Fiscal de obras' },
  { id: '511205 Fiscal de ônibus', descricao: 'Fiscal de ônibus' },
  { id: '511205 Fiscal de operação', descricao: 'Fiscal de operação' },
  { id: '342555 Fiscal de pátio (aeroporto)', descricao: 'Fiscal de pátio (aeroporto)' },
  { id: '710225 Fiscal de pátio de usina de concreto', descricao: 'Fiscal de pátio de usina de concreto' },
  { id: '352310 Fiscal de pesos e medidas', descricao: 'Fiscal de pesos e medidas' },
  { id: '517425 Fiscal de piso', descricao: 'Fiscal de piso' },
  { id: '342555 Fiscal de pista de aeroporto', descricao: 'Fiscal de pista de aeroporto' },
  { id: '254505 Fiscal de posturas', descricao: 'Fiscal de posturas' },
  { id: '517425 Fiscal de prevenção de perdas', descricao: 'Fiscal de prevenção de perdas' },
  { id: '620110 Fiscal de propriedade agropecuária', descricao: 'Fiscal de propriedade agropecuária' },
  { id: '424210 Fiscal de sala (concurso, avaliação, exame)', descricao: 'Fiscal de sala (concurso, avaliação, exame)' },
  { id: '517330 Fiscal de segurança', descricao: 'Fiscal de segurança' },
  { id: '511205 Fiscal de tráfego', descricao: 'Fiscal de tráfego' },
  { id: '510105 Fiscal de transporte rodoviário', descricao: 'Fiscal de transporte rodoviário' },
  { id: '511205 Fiscal de transportes coletivos (exceto trem)', descricao: 'Fiscal de transportes coletivos (exceto trem)' },
  { id: '254405 Fiscal de tributos estadual', descricao: 'Fiscal de tributos estadual' },
  { id: '254410 Fiscal de tributos municipal', descricao: 'Fiscal de tributos municipal' },
  { id: '511205 Fiscal de viagens', descricao: 'Fiscal de viagens' },
  { id: '517330 Fiscal de vigilância - organizações particulares de segurança', descricao: 'Fiscal de vigilância - organizações particulares de segurança' },
  { id: '517330 Fiscal de vigilância bancária', descricao: 'Fiscal de vigilância bancária' },
  { id: '352205 Fiscal do meio ambiente', descricao: 'Fiscal do meio ambiente' },
  { id: '352205 Fiscal florestal', descricao: 'Fiscal florestal' },
  { id: '254505 Fiscal integrado', descricao: 'Fiscal integrado' },
  { id: '254505 Fiscal municipal', descricao: 'Fiscal municipal' },
  { id: '511205 Fiscal nos transportes', descricao: 'Fiscal nos transportes' },
  { id: '511205 Fiscal rodoviário', descricao: 'Fiscal rodoviário' },
  { id: '860115 Fiscal técnico na geração, transmissão e distribuição de energia elétrica', descricao: 'Fiscal técnico na geração, transmissão e distribuição de energia elétrica' },
  { id: '352320 Fiscal têxtil', descricao: 'Fiscal têxtil' },
  { id: '254505 Fiscal urbano', descricao: 'Fiscal urbano' },
  { id: '424210 Fiscal volante (concurso, avaliação, exame)', descricao: 'Fiscal volante (concurso, avaliação, exame)' },
  { id: '225160 Fisiatra', descricao: 'Fisiatra' },
  { id: '213105 Físico', descricao: 'Físico' },
  { id: '213110 Físico (acústica)', descricao: 'Físico (acústica)' },
  { id: '213115 Físico (atômica e molecular)', descricao: 'Físico (atômica e molecular)' },
  { id: '213120 Físico (cosmologia)', descricao: 'Físico (cosmologia)' },
  { id: '213140 Físico (estado sólido)', descricao: 'Físico (estado sólido)' },
  { id: '213125 Físico (estatística e matemática)', descricao: 'Físico (estatística e matemática)' },
  { id: '213130 Físico (fluidos)', descricao: 'Físico (fluidos)' },
  { id: '213135 Físico (instrumentação)', descricao: 'Físico (instrumentação)' },
  { id: '213140 Físico (matéria condensada)', descricao: 'Físico (matéria condensada)' },
  { id: '213145 Físico (materiais)', descricao: 'Físico (materiais)' },
  { id: '213150 Físico (medicina)', descricao: 'Físico (medicina)' },
  { id: '213155 Físico (nuclear e reatores)', descricao: 'Físico (nuclear e reatores)' },
  { id: '213160 Físico (óptica)', descricao: 'Físico (óptica)' },
  { id: '213165 Físico (partículas e campos)', descricao: 'Físico (partículas e campos)' },
  { id: '213170 Físico (plasma)', descricao: 'Físico (plasma)' },
  { id: '213175 Físico (térmica)', descricao: 'Físico (térmica)' },
  { id: '213150 Físico hospitalar', descricao: 'Físico hospitalar' },
  { id: '213150 Físico médico', descricao: 'Físico médico' },
  { id: '213155 Físico nuclear', descricao: 'Físico nuclear' },
  { id: '2131 FÍSICOS', descricao: 'FÍSICOS' },
  { id: '203020 Fisiologista (exceto médico)', descricao: 'Fisiologista (exceto médico)' },
  { id: '223660 Fisioterapeuta do trabalho', descricao: 'Fisioterapeuta do trabalho' },
  { id: '223650 Fisioterapeuta acupunturista', descricao: 'Fisioterapeuta acupunturista' },
  { id: '223655 Fisioterapeuta esportivo', descricao: 'Fisioterapeuta esportivo' },
  { id: '223605 Fisioterapeuta geral', descricao: 'Fisioterapeuta geral' },
  { id: '223630 Fisioterapeuta neurofuncional', descricao: 'Fisioterapeuta neurofuncional' },
  { id: '223640 Fisioterapeuta osteopata', descricao: 'Fisioterapeuta osteopata' },
  { id: '223645 Fisioterapeuta quiropraxista', descricao: 'Fisioterapeuta quiropraxista' },
  { id: '223625 Fisioterapeuta respiratória', descricao: 'Fisioterapeuta respiratória' },
  { id: '223635 Fisioterapeuta traumato-ortopédica funcional', descricao: 'Fisioterapeuta traumato-ortopédica funcional' },
  { id: '2236 FISIOTERAPEUTAS', descricao: 'FISIOTERAPEUTAS' },
  { id: '415120 Fitotecário', descricao: 'Fitotecário' },
  { id: '722320 Fixador de moldes - na fundição', descricao: 'Fixador de moldes - na fundição' },
  { id: '724435 Flandeiro', descricao: 'Flandeiro' },
  { id: '519925 Flanelinha', descricao: 'Flanelinha' },
  { id: '325010 Flavorista', descricao: 'Flavorista' },
  { id: '142610 Flavourist (cigarros)', descricao: 'Flavourist (cigarros)' },
  { id: '142610 Flavourista (cigarros)', descricao: 'Flavourista (cigarros)' },
  { id: '515205 Flebotomista', descricao: 'Flebotomista' },
  { id: '612405 Floricultor de flores de corte', descricao: 'Floricultor de flores de corte' },
  { id: '612410 Floricultor de flores em vaso', descricao: 'Floricultor de flores em vaso' },
  { id: '612415 Floricultor de forrações', descricao: 'Floricultor de forrações' },
  { id: '612420 Floricultor de plantas ornamentais', descricao: 'Floricultor de plantas ornamentais' },
  { id: '622405 Floricultor no cultivo de flores e folhagens de corte', descricao: 'Floricultor no cultivo de flores e folhagens de corte' },
  { id: '622410 Floricultor no cultivo de flores em vaso', descricao: 'Floricultor no cultivo de flores em vaso' },
  { id: '622415 Floricultor no cultivo de forragens', descricao: 'Floricultor no cultivo de forragens' },
  { id: '622420 Floricultor no cultivo de mudas', descricao: 'Floricultor no cultivo de mudas' },
  { id: '622425 Floricultor no cultivo de plantas ornamentais', descricao: 'Floricultor no cultivo de plantas ornamentais' },
  { id: '141410 Florista (comércio varejista)', descricao: 'Florista (comércio varejista)' },
  { id: '862105 Foguista (locomotivas a vapor)', descricao: 'Foguista (locomotivas a vapor)' },
  { id: '862105 Foguista de caldeira de trem', descricao: 'Foguista de caldeira de trem' },
  { id: '862105 Foguista de locomotiva', descricao: 'Foguista de locomotiva' },
  { id: '862105 Foguista de trem', descricao: 'Foguista de trem' },
  { id: '622020 Foiceiro', descricao: 'Foiceiro' },
  { id: '622020 Foiceiro - na cultura', descricao: 'Foiceiro - na cultura' },
  { id: '632325 Foiceiro - na extração da carnaúba', descricao: 'Foiceiro - na extração da carnaúba' },
  { id: '261515 Folclorista de ficção', descricao: 'Folclorista de ficção' },
  { id: '261520 Folclorista de não ficção', descricao: 'Folclorista de não ficção' },
  { id: '376105 Folgazão', descricao: 'Folgazão' },
  { id: '775110 Folheador de móveis', descricao: 'Folheador de móveis' },
  { id: '775110 Folheador de móveis de madeira', descricao: 'Folheador de móveis de madeira' },
  { id: '225275 Foniatra', descricao: 'Foniatra' },
  { id: '223815 Fonoaudiólogo educacional', descricao: 'Fonoaudiólogo educacional' },
  { id: '223820 Fonoaudiólogo em audiologia', descricao: 'Fonoaudiólogo em audiologia' },
  { id: '223825 Fonoaudiólogo em disfagia', descricao: 'Fonoaudiólogo em disfagia' },
  { id: '223830 Fonoaudiólogo em linguagem', descricao: 'Fonoaudiólogo em linguagem' },
  { id: '223835 Fonoaudiólogo em motricidade orofacial', descricao: 'Fonoaudiólogo em motricidade orofacial' },
  { id: '223840 Fonoaudiólogo em saúde coletiva', descricao: 'Fonoaudiólogo em saúde coletiva' },
  { id: '223845 Fonoaudiólogo em voz', descricao: 'Fonoaudiólogo em voz' },
  { id: '223810 Fonoaudiólogo geral', descricao: 'Fonoaudiólogo geral' },
  { id: '2238 FONOAUDIÓLOGOS', descricao: 'FONOAUDIÓLOGOS' },
  { id: '722105 Forjador', descricao: 'Forjador' },
  { id: '722105 Forjador a mão (molas helicoidais)', descricao: 'Forjador a mão (molas helicoidais)' },
  { id: '722110 Forjador a martelete', descricao: 'Forjador a martelete' },
  { id: '722110 Forjador a martelo', descricao: 'Forjador a martelo' },
  { id: '722110 Forjador a martelo de queda livre', descricao: 'Forjador a martelo de queda livre' },
  { id: '722110 Forjador a martelo-pilão', descricao: 'Forjador a martelo-pilão' },
  { id: '722105 Forjador de molas', descricao: 'Forjador de molas' },
  { id: '722105 Forjador de talhadeira', descricao: 'Forjador de talhadeira' },
  { id: '722105 Forjador manual', descricao: 'Forjador manual' },
  { id: '722115 Forjador prensista', descricao: 'Forjador prensista' },
  { id: '622020 Formador - na cultura', descricao: 'Formador - na cultura' },
  { id: '622020 Formigueiro (combate às formigas)', descricao: 'Formigueiro (combate às formigas)' },
  { id: '752325 Formista (cerâmica)', descricao: 'Formista (cerâmica)' },
  { id: '722325 Formista - na fundição', descricao: 'Formista - na fundição' },
  { id: '722315 Formista manual', descricao: 'Formista manual' },
  { id: '141410 Fornecedor', descricao: 'Fornecedor' },
  { id: '821220 Forneiro (fundição)', descricao: 'Forneiro (fundição)' },
  { id: '823315 Forneiro (materiais de construção)', descricao: 'Forneiro (materiais de construção)' },
  { id: '828110 Forneiro - em olaria', descricao: 'Forneiro - em olaria' },
  { id: '821205 Forneiro auxiliar (alto-forno)', descricao: 'Forneiro auxiliar (alto-forno)' },
  { id: '822105 Forneiro auxiliar de cubilô', descricao: 'Forneiro auxiliar de cubilô' },
  { id: '821210 Forneiro conversor a oxigênio', descricao: 'Forneiro conversor a oxigênio' },
  { id: '821205 Forneiro de alto-forno', descricao: 'Forneiro de alto-forno' },
  { id: '822105 Forneiro de cubilô', descricao: 'Forneiro de cubilô' },
  { id: '822125 Forneiro de forno revérbero', descricao: 'Forneiro de forno revérbero' },
  { id: '822110 Forneiro de forno-poço', descricao: 'Forneiro de forno-poço' },
  { id: '822115 Forneiro de fundição', descricao: 'Forneiro de fundição' },
  { id: '822115 Forneiro de fundição (forno de redução)', descricao: 'Forneiro de fundição (forno de redução)' },
  { id: '823315 Forneiro de material de construção (telhas e tijolos)', descricao: 'Forneiro de material de construção (telhas e tijolos)' },
  { id: '822125 Forneiro de metais ferrosos e não-ferrosos (preparação de ligas)', descricao: 'Forneiro de metais ferrosos e não-ferrosos (preparação de ligas)' },
  { id: '841805 Forneiro de padaria', descricao: 'Forneiro de padaria' },
  { id: '822120 Forneiro de reaquecimento e tratamento térmico na metalurgia', descricao: 'Forneiro de reaquecimento e tratamento térmico na metalurgia' },
  { id: '821205 Forneiro de recuperação de resíduos', descricao: 'Forneiro de recuperação de resíduos' },
  { id: '822125 Forneiro de retêmpera', descricao: 'Forneiro de retêmpera' },
  { id: '822125 Forneiro de revérbero', descricao: 'Forneiro de revérbero' },
  { id: '822125 Forneiro de têmpera', descricao: 'Forneiro de têmpera' },
  { id: '723120 Forneiro de tratamento térmico de metais', descricao: 'Forneiro de tratamento térmico de metais' },
  { id: '823215 Forneiro de vidro', descricao: 'Forneiro de vidro' },
  { id: '821205 Forneiro e operador (alto-forno)', descricao: 'Forneiro e operador (alto-forno)' },
  { id: '821210 Forneiro e operador (conversor a oxigênio)', descricao: 'Forneiro e operador (conversor a oxigênio)' },
  { id: '821215 Forneiro e operador (forno elétrico)', descricao: 'Forneiro e operador (forno elétrico)' },
  { id: '821220 Forneiro e operador (refino de metais não-ferrosos)', descricao: 'Forneiro e operador (refino de metais não-ferrosos)' },
  { id: '821225 Forneiro e operador de forno de redução direta', descricao: 'Forneiro e operador de forno de redução direta' },
  { id: '822115 Forneiro fundidor de metais', descricao: 'Forneiro fundidor de metais' },
  { id: '822115 Forneiro metalúrgico', descricao: 'Forneiro metalúrgico' },
  { id: '823215 Forneiro na fundição de vidro', descricao: 'Forneiro na fundição de vidro' },
  { id: '841210 Forneiro na refrigeração de sal', descricao: 'Forneiro na refrigeração de sal' },
  { id: '823220 Forneiro no recozimento de vidro', descricao: 'Forneiro no recozimento de vidro' },
  { id: '823255 Forneiro temperador', descricao: 'Forneiro temperador' },
  { id: '513505 Forneiro(pizzaria)', descricao: 'Forneiro(pizzaria)' },
  { id: '821220 Forneiros de não-ferrosos', descricao: 'Forneiros de não-ferrosos' },
  { id: '8221 FORNEIROS METALÚRGICOS (SEGUNDA FUSÃO E REAQUECIMENTO)', descricao: 'FORNEIROS METALÚRGICOS (SEGUNDA FUSÃO E REAQUECIMENTO)' },
  { id: '765235 Forrador de móveis', descricao: 'Forrador de móveis' },
  { id: '764305 Forrador de saltos no acabamento de calçados', descricao: 'Forrador de saltos no acabamento de calçados' },
  { id: '721210 Foscador de cilindros (laminação)', descricao: 'Foscador de cilindros (laminação)' },
  { id: '752425 Foscador de espelhos', descricao: 'Foscador de espelhos' },
  { id: '752215 Foscador de vidro a ácido', descricao: 'Foscador de vidro a ácido' },
  { id: '752225 Foscador de vidro a jato de areia', descricao: 'Foscador de vidro a jato de areia' },
  { id: '723210 Fosfatizador', descricao: 'Fosfatizador' },
  { id: '766145 Fotocompositor', descricao: 'Fotocompositor' },
  { id: '261805 Fotógrafo', descricao: 'Fotógrafo' },
  { id: '766150 Fotógrafo (pré-impressão gráfica)', descricao: 'Fotógrafo (pré-impressão gráfica)' },
  { id: '261805 Fotógrafo científico', descricao: 'Fotógrafo científico' },
  { id: '261805 Fotógrafo de aerofotografia', descricao: 'Fotógrafo de aerofotografia' },
  { id: '261805 Fotógrafo de arquitetura', descricao: 'Fotógrafo de arquitetura' },
  { id: '261805 Fotógrafo de foto submarina', descricao: 'Fotógrafo de foto submarina' },
  { id: '261805 Fotógrafo de sensoriamento remoto', descricao: 'Fotógrafo de sensoriamento remoto' },
  { id: '261805 Fotógrafo documentarista', descricao: 'Fotógrafo documentarista' },
  { id: '261805 Fotógrafo industrial', descricao: 'Fotógrafo industrial' },
  { id: '766415 Fotógrafo laboratorista filmes fotográficos, em cores', descricao: 'Fotógrafo laboratorista filmes fotográficos, em cores' },
  { id: '766410 Fotógrafo laboratorista filmes fotográficos, em preto e branco', descricao: 'Fotógrafo laboratorista filmes fotográficos, em preto e branco' },
  { id: '261810 Fotógrafo publicitário', descricao: 'Fotógrafo publicitário' },
  { id: '261815 Fotógrafo retratista', descricao: 'Fotógrafo retratista' },
  { id: '261815 Fotógrafo social', descricao: 'Fotógrafo social' },
  { id: '2618 FOTÓGRAFOS PROFISSIONAIS', descricao: 'FOTÓGRAFOS PROFISSIONAIS' },
  { id: '261820 Fotojornalista', descricao: 'Fotojornalista' },
  { id: '766145 Fotolitógrafo', descricao: 'Fotolitógrafo' },
  { id: '766145 Fototipista', descricao: 'Fototipista' },
  { id: '263105 Frade', descricao: 'Frade' },
  { id: '811820 Fraldeiro', descricao: 'Fraldeiro' },
  { id: '848505 Frangueiro em matadouro', descricao: 'Frangueiro em matadouro' },
  { id: '263105 Frei', descricao: 'Frei' },
  { id: '263105 Freira', descricao: 'Freira' },
  { id: '516805 Frenólogo', descricao: 'Frenólogo' },
  { id: '521135 Frentista', descricao: 'Frentista' },
  { id: '721215 Fresador (fresadora universal)', descricao: 'Fresador (fresadora universal)' },
  { id: '721410 Fresador cnc', descricao: 'Fresador cnc' },
  { id: '141410 Fruteiro (comércio varejista)', descricao: 'Fruteiro (comércio varejista)' },
  { id: '765010 Fulista', descricao: 'Fulista' },
  { id: '762120 Fuloneiro', descricao: 'Fuloneiro' },
  { id: '762310 Fuloneiro no acabamento de couros e peles', descricao: 'Fuloneiro no acabamento de couros e peles' },
  { id: '622620 Fumeiro', descricao: 'Fumeiro' },
  { id: '612620 Fumicultor - empregador', descricao: 'Fumicultor - empregador' },
  { id: '622620 Fumicultor - exclusive conta própria e empregador', descricao: 'Fumicultor - exclusive conta própria e empregador' },
  { id: '376230 Funâmbulo', descricao: 'Funâmbulo' },
  { id: '422105 Funcionário de setor de informação', descricao: 'Funcionário de setor de informação' },
  { id: '111410 Funcionário público estadual e distrital superior', descricao: 'Funcionário público estadual e distrital superior' },
  { id: '111405 Funcionário público federal superior', descricao: 'Funcionário público federal superior' },
  { id: '111415 Funcionário público municipal superior', descricao: 'Funcionário público municipal superior' },
  { id: '821220 Fundidor (depuração e refinação de metais não-ferrosos)', descricao: 'Fundidor (depuração e refinação de metais não-ferrosos)' },
  { id: '751110 Fundidor (joalheria e ourivesaria)', descricao: 'Fundidor (joalheria e ourivesaria)' },
  { id: '722225 Fundidor a pressão', descricao: 'Fundidor a pressão' },
  { id: '752325 Fundidor de barbotina', descricao: 'Fundidor de barbotina' },
  { id: '722205 Fundidor de ligas metálicas', descricao: 'Fundidor de ligas metálicas' },
  { id: '722225 Fundidor de máquina de pressão', descricao: 'Fundidor de máquina de pressão' },
  { id: '722205 Fundidor de metais', descricao: 'Fundidor de metais' },
  { id: '823330 Fundidor de pedras artificiais', descricao: 'Fundidor de pedras artificiais' },
  { id: '722205 Fundidor de placas', descricao: 'Fundidor de placas' },
  { id: '768615 Fundidor de tipos', descricao: 'Fundidor de tipos' },
  { id: '841720 Fundidor de vinheta', descricao: 'Fundidor de vinheta' },
  { id: '722205 Fundidor moldador de metais', descricao: 'Fundidor moldador de metais' },
  { id: '823215 Fundidor vidreiro', descricao: 'Fundidor vidreiro' },
  { id: '991305 Funileiro de automóveis (reparação)', descricao: 'Funileiro de automóveis (reparação)' },
  { id: '724435 Funileiro de bancada', descricao: 'Funileiro de bancada' },
  { id: '724435 Funileiro de manutenção', descricao: 'Funileiro de manutenção' },
  { id: '991305 Funileiro de veículos (reparação)', descricao: 'Funileiro de veículos (reparação)' },
  { id: '724435 Funileiro industrial', descricao: 'Funileiro industrial' },
  { id: '724435 Funileiro montador', descricao: 'Funileiro montador' },
  { id: '724435 Funileiro soldador', descricao: 'Funileiro soldador' },
  { id: '711405 Furão - no garimpo', descricao: 'Furão - no garimpo' },
  { id: '263105 Gaiaku', descricao: 'Gaiaku' },
  { id: '782510 Gaioleiro (gado)', descricao: 'Gaioleiro (gado)' },
  { id: '141410 Galinheiro (exceto no comércio ambulante)', descricao: 'Galinheiro (exceto no comércio ambulante)' },
  { id: '766145 Galvanista (rotogravura)', descricao: 'Galvanista (rotogravura)' },
  { id: '723215 Galvanizador', descricao: 'Galvanizador' },
  { id: '723215 Galvanizador (sherardização)', descricao: 'Galvanizador (sherardização)' },
  { id: '752105 Gambista (artesão de vidros)', descricao: 'Gambista (artesão de vidros)' },
  { id: '317120 Game designer', descricao: 'Game designer' },
  { id: '519920 Gandula', descricao: 'Gandula' },
  { id: '514110 Garagista', descricao: 'Garagista' },
  { id: '513405 Garção', descricao: 'Garção' },
  { id: '513405 Garçom', descricao: 'Garçom' },
  { id: '513410 Garçom (serviços de vinhos)', descricao: 'Garçom (serviços de vinhos)' },
  { id: '513405 Garçom de bar', descricao: 'Garçom de bar' },
  { id: '711405 Garfeiro - no garimpo', descricao: 'Garfeiro - no garimpo' },
  { id: '514215 Gari', descricao: 'Gari' },
  { id: '711405 Garimpeiro', descricao: 'Garimpeiro' },
  { id: '7114 GARIMPEIROS E OPERADORES DE SALINAS', descricao: 'GARIMPEIROS E OPERADORES DE SALINAS' },
  { id: '519805 Garota de programa', descricao: 'Garota de programa' },
  { id: '519805 Garoto de programa', descricao: 'Garoto de programa' },
  { id: '524305 Garrafeiro (comércio ambulante)', descricao: 'Garrafeiro (comércio ambulante)' },
  { id: '823240 Garrafeiro (fabricação)', descricao: 'Garrafeiro (fabricação)' },
  { id: '848525 Garreador de mocotó', descricao: 'Garreador de mocotó' },
  { id: '724130 Gasista', descricao: 'Gasista' },
  { id: '225165 Gastroenterologista', descricao: 'Gastroenterologista' },
  { id: '271110 Gastrólogo', descricao: 'Gastrólogo' },
  { id: '613010 Gateiro', descricao: 'Gateiro' },
  { id: '263105 Gãtó', descricao: 'Gãtó' },
  { id: '631405 Gelador industrial', descricao: 'Gelador industrial' },
  { id: '631410 Gelador profissional', descricao: 'Gelador profissional' },
  { id: '141410 Geleiro', descricao: 'Geleiro' },
  { id: '10110 General-de-brigada', descricao: 'General-de-brigada' },
  { id: '10110 General-de-divisão', descricao: 'General-de-divisão' },
  { id: '10110 General-de-exército', descricao: 'General-de-exército' },
  { id: '201115 Geneticista', descricao: 'Geneticista' },
  { id: '213415 Geofísico', descricao: 'Geofísico' },
  { id: '213310 Geofísico espacial', descricao: 'Geofísico espacial' },
  { id: '251305 Geógrafo', descricao: 'Geógrafo' },
  { id: '213405 Geólogo', descricao: 'Geólogo' },
  { id: '213410 Geólogo de engenharia', descricao: 'Geólogo de engenharia' },
  { id: '2134 GEÓLOGOS, OCEANÓGRAFOS, GEOFÍSICOS E AFINS', descricao: 'GEÓLOGOS, OCEANÓGRAFOS, GEOFÍSICOS E AFINS' },
  { id: '213420 Geoquímico', descricao: 'Geoquímico' },
  { id: '253410 Gerador de conteúdo digital', descricao: 'Gerador de conteúdo digital' },
  { id: '142105 Gerente administrativo', descricao: 'Gerente administrativo' },
  { id: '141515 Gerente administrativo de lanchonete', descricao: 'Gerente administrativo de lanchonete' },
  { id: '142105 Gerente administrativo e financeiro', descricao: 'Gerente administrativo e financeiro' },
  { id: '141825 Gerente avsec (segurança da aviação civil)', descricao: 'Gerente avsec (segurança da aviação civil)' },
  { id: '142305 Gerente comercial', descricao: 'Gerente comercial' },
  { id: '131210 Gerente da área de saúde mental', descricao: 'Gerente da área de saúde mental' },
  { id: '141520 Gerente de albergue', descricao: 'Gerente de albergue' },
  { id: '141725 Gerente de administração de carteira de crédito imobiliário', descricao: 'Gerente de administração de carteira de crédito imobiliário' },
  { id: '141715 Gerente de administração de carteiras de câmbio e comércio exterior', descricao: 'Gerente de administração de carteiras de câmbio e comércio exterior' },
  { id: '141720 Gerente de administração de carteiras de crédito e cobrança', descricao: 'Gerente de administração de carteiras de crédito e cobrança' },
  { id: '142210 Gerente de administração de pessoal', descricao: 'Gerente de administração de pessoal' },
  { id: '141805 Gerente de administração em aeroportos', descricao: 'Gerente de administração em aeroportos' },
  { id: '142115 Gerente de administração financeira', descricao: 'Gerente de administração financeira' },
  { id: '141805 Gerente de aeródromo', descricao: 'Gerente de aeródromo' },
  { id: '141810 Gerente de aeroportos', descricao: 'Gerente de aeroportos' },
  { id: '141710 Gerente de agência', descricao: 'Gerente de agência' },
  { id: '141115 Gerente de agropecuária', descricao: 'Gerente de agropecuária' },
  { id: '142415 Gerente de almoxarifado', descricao: 'Gerente de almoxarifado' },
  { id: '131210 Gerente de ambulatório', descricao: 'Gerente de ambulatório' },
  { id: '141505 Gerente de apart hotel', descricao: 'Gerente de apart hotel' },
  { id: '131210 Gerente de apoio e diagnóstico de saúde', descricao: 'Gerente de apoio e diagnóstico de saúde' },
  { id: '131315 Gerente de apoio educacional em escolas privadas', descricao: 'Gerente de apoio educacional em escolas privadas' },
  { id: '142320 Gerente de área de vendas', descricao: 'Gerente de área de vendas' },
  { id: '141615 Gerente de armazém', descricao: 'Gerente de armazém' },
  { id: '262220 Gerente de arte teatral', descricao: 'Gerente de arte teatral' },
  { id: '141610 Gerente de assistência técnica nas operações de correios e telecomunicações', descricao: 'Gerente de assistência técnica nas operações de correios e telecomunicações' },
  { id: '142310 Gerente de assuntos corporativos', descricao: 'Gerente de assuntos corporativos' },
  { id: '141810 Gerente de atendimento a passageiros', descricao: 'Gerente de atendimento a passageiros' },
  { id: '142530 Gerente de atendimento de tecnologia da informação', descricao: 'Gerente de atendimento de tecnologia da informação' },
  { id: '141810 Gerente de aviação executiva', descricao: 'Gerente de aviação executiva' },
  { id: '141415 Gerente de balconista', descricao: 'Gerente de balconista' },
  { id: '141515 Gerente de bar', descricao: 'Gerente de bar' },
  { id: '141515 Gerente de bar e lanchonete', descricao: 'Gerente de bar e lanchonete' },
  { id: '141515 Gerente de bar, cantina e restaurante', descricao: 'Gerente de bar, cantina e restaurante' },
  { id: '141410 Gerente de box de mercado público', descricao: 'Gerente de box de mercado público' },
  { id: '141715 Gerente de câmbio e comércio exterior', descricao: 'Gerente de câmbio e comércio exterior' },
  { id: '141510 Gerente de cantina', descricao: 'Gerente de cantina' },
  { id: '253205 Gerente de captação (fundos e investimentos institucionais)', descricao: 'Gerente de captação (fundos e investimentos institucionais)' },
  { id: '141820 Gerente de cargas', descricao: 'Gerente de cargas' },
  { id: '141410 Gerente de carrocerias (vendas a varejo)', descricao: 'Gerente de carrocerias (vendas a varejo)' },
  { id: '141410 Gerente de casa de peixe', descricao: 'Gerente de casa de peixe' },
  { id: '131315 Gerente de centro de educação profissional em escolas privadas', descricao: 'Gerente de centro de educação profissional em escolas privadas' },
  { id: '131315 Gerente de centro de formação profissional em escolas privadas', descricao: 'Gerente de centro de formação profissional em escolas privadas' },
  { id: '253210 Gerente de clientes especiais (private)', descricao: 'Gerente de clientes especiais (private)' },
  { id: '142405 Gerente de compras', descricao: 'Gerente de compras' },
  { id: '142310 Gerente de comunicação', descricao: 'Gerente de comunicação' },
  { id: '142310 Gerente de comunicação corporativa', descricao: 'Gerente de comunicação corporativa' },
  { id: '142310 Gerente de comunicação social', descricao: 'Gerente de comunicação social' },
  { id: '252210 Gerente de contabilidade', descricao: 'Gerente de contabilidade' },
  { id: '253215 Gerente de contas - pessoa física e jurídica', descricao: 'Gerente de contas - pessoa física e jurídica' },
  { id: '141305 Gerente de contratos (construção civil)', descricao: 'Gerente de contratos (construção civil)' },
  { id: '142315 Gerente de coordenação de marketing', descricao: 'Gerente de coordenação de marketing' },
  { id: '141610 Gerente de correio expresso - internacional', descricao: 'Gerente de correio expresso - internacional' },
  { id: '141720 Gerente de crédito e cobrança', descricao: 'Gerente de crédito e cobrança' },
  { id: '141725 Gerente de crédito imobiliário', descricao: 'Gerente de crédito imobiliário' },
  { id: '141730 Gerente de crédito rural', descricao: 'Gerente de crédito rural' },
  { id: '142525 Gerente de cyber security', descricao: 'Gerente de cyber security' },
  { id: '141205 Gerente de departamento de fabricação', descricao: 'Gerente de departamento de fabricação' },
  { id: '142315 Gerente de departamento de marketing', descricao: 'Gerente de departamento de marketing' },
  { id: '131115 Gerente de departamento de planejamento e análise', descricao: 'Gerente de departamento de planejamento e análise' },
  { id: '131115 Gerente de departamento de planejamento e pesquisa', descricao: 'Gerente de departamento de planejamento e pesquisa' },
  { id: '141205 Gerente de departamento de produção', descricao: 'Gerente de departamento de produção' },
  { id: '142320 Gerente de departamento de vendas', descricao: 'Gerente de departamento de vendas' },
  { id: '141110 Gerente de departamento florestal', descricao: 'Gerente de departamento florestal' },
  { id: '141205 Gerente de departamento industrial', descricao: 'Gerente de departamento industrial' },
  { id: '142210 Gerente de departamento pessoal', descricao: 'Gerente de departamento pessoal' },
  { id: '141615 Gerente de depósito', descricao: 'Gerente de depósito' },
  { id: '142605 Gerente de desenvolvimento (tecnologia)', descricao: 'Gerente de desenvolvimento (tecnologia)' },
  { id: '142510 Gerente de desenvolvimento de aplicações', descricao: 'Gerente de desenvolvimento de aplicações' },
  { id: '142510 Gerente de desenvolvimento de sistemas', descricao: 'Gerente de desenvolvimento de sistemas' },
  { id: '142510 Gerente de desenvolvimento de software', descricao: 'Gerente de desenvolvimento de software' },
  { id: '142510 Gerente de desenvolvimento de ti', descricao: 'Gerente de desenvolvimento de ti' },
  { id: '141615 Gerente de distribuição de mercadorias', descricao: 'Gerente de distribuição de mercadorias' },
  { id: '142305 Gerente de divisão comercial', descricao: 'Gerente de divisão comercial' },
  { id: '142605 Gerente de divisão de desenvolvimento de novos produtos', descricao: 'Gerente de divisão de desenvolvimento de novos produtos' },
  { id: '142315 Gerente de divisão de marketing', descricao: 'Gerente de divisão de marketing' },
  { id: '131115 Gerente de divisão de pesquisas e análise', descricao: 'Gerente de divisão de pesquisas e análise' },
  { id: '142210 Gerente de divisão de pessoal', descricao: 'Gerente de divisão de pessoal' },
  { id: '261210 Gerente de documentação', descricao: 'Gerente de documentação' },
  { id: '141505 Gerente de ecoresort', descricao: 'Gerente de ecoresort' },
  { id: '141410 Gerente de editora', descricao: 'Gerente de editora' },
  { id: '141810 Gerente de empresa aérea e empresa de serviços auxiliares ao transporte aéreo (esata) em aeroportos', descricao: 'Gerente de empresa aérea e empresa de serviços auxiliares ao transporte aéreo (esata) em aeroportos' },
  { id: '141120 Gerente de empresa de pesca', descricao: 'Gerente de empresa de pesca' },
  { id: '141415 Gerente de empresas comerciais', descricao: 'Gerente de empresas comerciais' },
  { id: '141305 Gerente de empresas de construção civil', descricao: 'Gerente de empresas de construção civil' },
  { id: '141610 Gerente de encomendas nas operações de correios e telecomunicações', descricao: 'Gerente de encomendas nas operações de correios e telecomunicações' },
  { id: '131210 Gerente de enfermagem', descricao: 'Gerente de enfermagem' },
  { id: '141505 Gerente de estância', descricao: 'Gerente de estância' },
  { id: '142605 Gerente de estudos e projetos', descricao: 'Gerente de estudos e projetos' },
  { id: '142320 Gerente de exportação', descricao: 'Gerente de exportação' },
  { id: '142305 Gerente de exportação e importação', descricao: 'Gerente de exportação e importação' },
  { id: '141205 Gerente de fábrica', descricao: 'Gerente de fábrica' },
  { id: '141410 Gerente de farmácia', descricao: 'Gerente de farmácia' },
  { id: '142115 Gerente de finanças', descricao: 'Gerente de finanças' },
  { id: '517125 Gerente de fogo', descricao: 'Gerente de fogo' },
  { id: '141605 Gerente de frota', descricao: 'Gerente de frota' },
  { id: '342305 Gerente de frota (transporte rodoviário)', descricao: 'Gerente de frota (transporte rodoviário)' },
  { id: '141120 Gerente de frota pesqueira', descricao: 'Gerente de frota pesqueira' },
  { id: '253220 Gerente de grandes contas (corporate)', descricao: 'Gerente de grandes contas (corporate)' },
  { id: '141410 Gerente de granja', descricao: 'Gerente de granja' },
  { id: '141505 Gerente de hotel', descricao: 'Gerente de hotel' },
  { id: '141505 Gerente de hotel fazenda', descricao: 'Gerente de hotel fazenda' },
  { id: '141505 Gerente de hotel residencial', descricao: 'Gerente de hotel residencial' },
  { id: '261205 Gerente de informação', descricao: 'Gerente de informação' },
  { id: '142505 Gerente de infraestrutura de tecnologia da informação', descricao: 'Gerente de infraestrutura de tecnologia da informação' },
  { id: '131315 Gerente de instituição educacional da área privada', descricao: 'Gerente de instituição educacional da área privada' },
  { id: '141830 Gerente de investigação', descricao: 'Gerente de investigação' },
  { id: '141615 Gerente de logística (armazenagem e distribuição)', descricao: 'Gerente de logística (armazenagem e distribuição)' },
  { id: '141415 Gerente de loja e supermercado', descricao: 'Gerente de loja e supermercado' },
  { id: '141415 Gerente de magazine', descricao: 'Gerente de magazine' },
  { id: '141205 Gerente de manufatura', descricao: 'Gerente de manufatura' },
  { id: '142315 Gerente de marketing', descricao: 'Gerente de marketing' },
  { id: '142315 Gerente de marketing e vendas', descricao: 'Gerente de marketing e vendas' },
  { id: '142405 Gerente de materiais', descricao: 'Gerente de materiais' },
  { id: '142320 Gerente de mercado', descricao: 'Gerente de mercado' },
  { id: '141415 Gerente de mercearia', descricao: 'Gerente de mercearia' },
  { id: '141205 Gerente de montagem', descricao: 'Gerente de montagem' },
  { id: '141615 Gerente de movimentação de materiais', descricao: 'Gerente de movimentação de materiais' },
  { id: '131210 Gerente de nutrição em unidades de saúde', descricao: 'Gerente de nutrição em unidades de saúde' },
  { id: '141305 Gerente de obras (construção civil)', descricao: 'Gerente de obras (construção civil)' },
  { id: '131115 Gerente de ong (organização não-governamental)', descricao: 'Gerente de ong (organização não-governamental)' },
  { id: '142530 Gerente de operação de suporte de tecnologia da informação', descricao: 'Gerente de operação de suporte de tecnologia da informação' },
  { id: '142515 Gerente de operação de tecnologia da informação', descricao: 'Gerente de operação de tecnologia da informação' },
  { id: '141820 Gerente de operações de cargas', descricao: 'Gerente de operações de cargas' },
  { id: '141610 Gerente de operações de correios e telecomunicações', descricao: 'Gerente de operações de correios e telecomunicações' },
  { id: '141420 Gerente de operações de serviços de assistência técnica', descricao: 'Gerente de operações de serviços de assistência técnica' },
  { id: '141605 Gerente de operações de transportes', descricao: 'Gerente de operações de transportes' },
  { id: '141525 Gerente de operações de turismo', descricao: 'Gerente de operações de turismo' },
  { id: '141610 Gerente de operações e manutenção nos sistemas de correios e telecomunicações', descricao: 'Gerente de operações e manutenção nos sistemas de correios e telecomunicações' },
  { id: '141605 Gerente de operações e transportes', descricao: 'Gerente de operações e transportes' },
  { id: '141815 Gerente de operações em aeroportos', descricao: 'Gerente de operações em aeroportos' },
  { id: '142115 Gerente de operações financeiras', descricao: 'Gerente de operações financeiras' },
  { id: '131120 Gerente de organização social de interesse público', descricao: 'Gerente de organização social de interesse público' },
  { id: '141415 Gerente de papelaria', descricao: 'Gerente de papelaria' },
  { id: '141520 Gerente de pensão', descricao: 'Gerente de pensão' },
  { id: '141520 Gerente de pensionato', descricao: 'Gerente de pensionato' },
  { id: '142605 Gerente de pesquisa (tecnologia)', descricao: 'Gerente de pesquisa (tecnologia)' },
  { id: '142605 Gerente de pesquisa e desenvolvimento (p&d)', descricao: 'Gerente de pesquisa e desenvolvimento (p&d)' },
  { id: '131120 Gerente de pesquisas no ibge (instituto brasileiro de geografia e estatística)', descricao: 'Gerente de pesquisas no ibge (instituto brasileiro de geografia e estatística)' },
  { id: '142605 Gerente de pesquisas técnicas', descricao: 'Gerente de pesquisas técnicas' },
  { id: '142605 Gerente de pesquisas tecnológicas', descricao: 'Gerente de pesquisas tecnológicas' },
  { id: '142405 Gerente de planejamento de compras', descricao: 'Gerente de planejamento de compras' },
  { id: '142205 Gerente de planejamento de salários e benefícios', descricao: 'Gerente de planejamento de salários e benefícios' },
  { id: '131115 Gerente de planejamento e estatística', descricao: 'Gerente de planejamento e estatística' },
  { id: '142705 Gerente de planejamento e manutenção', descricao: 'Gerente de planejamento e manutenção' },
  { id: '142605 Gerente de planejamento e novos projetos', descricao: 'Gerente de planejamento e novos projetos' },
  { id: '141415 Gerente de posto de venda', descricao: 'Gerente de posto de venda' },
  { id: '141520 Gerente de pousada', descricao: 'Gerente de pousada' },
  { id: '141830 Gerente de prevenção', descricao: 'Gerente de prevenção' },
  { id: '141205 Gerente de processos', descricao: 'Gerente de processos' },
  { id: '141205 Gerente de produção', descricao: 'Gerente de produção' },
  { id: '142515 Gerente de produção de tecnologia da informação', descricao: 'Gerente de produção de tecnologia da informação' },
  { id: '141205 Gerente de produção e operações', descricao: 'Gerente de produção e operações' },
  { id: '141105 Gerente de produção e operações aqüícolas', descricao: 'Gerente de produção e operações aqüícolas' },
  { id: '141110 Gerente de produção e operações florestais', descricao: 'Gerente de produção e operações florestais' },
  { id: '141115 Gerente de produção e operações agropecuárias', descricao: 'Gerente de produção e operações agropecuárias' },
  { id: '141305 Gerente de produção e operações da construção civil e obras públicas', descricao: 'Gerente de produção e operações da construção civil e obras públicas' },
  { id: '141120 Gerente de produção e operações pesqueiras', descricao: 'Gerente de produção e operações pesqueiras' },
  { id: '141110 Gerente de produção florestal', descricao: 'Gerente de produção florestal' },
  { id: '141115 Gerente de produção na agropecuária', descricao: 'Gerente de produção na agropecuária' },
  { id: '820205 Gerente de produção na fabricação de produtos de cerâmica, porcelanatos e afins', descricao: 'Gerente de produção na fabricação de produtos de cerâmica, porcelanatos e afins' },
  { id: '820210 Gerente de produção na fabricação de produtos de vidro', descricao: 'Gerente de produção na fabricação de produtos de vidro' },
  { id: '142315 Gerente de produtos', descricao: 'Gerente de produtos' },
  { id: '141705 Gerente de produtos bancários', descricao: 'Gerente de produtos bancários' },
  { id: '141525 Gerente de produtos de turismo', descricao: 'Gerente de produtos de turismo' },
  { id: '262225 Gerente de programação', descricao: 'Gerente de programação' },
  { id: '131210 Gerente de programas de saúde', descricao: 'Gerente de programas de saúde' },
  { id: '142520 Gerente de programas de ti', descricao: 'Gerente de programas de ti' },
  { id: '142605 Gerente de projeto de pesquisa', descricao: 'Gerente de projeto de pesquisa' },
  { id: '142520 Gerente de projetos de tecnologia da informação', descricao: 'Gerente de projetos de tecnologia da informação' },
  { id: '142705 Gerente de projetos e serviços de manutenção', descricao: 'Gerente de projetos e serviços de manutenção' },
  { id: '131210 Gerente de pronto-socorro', descricao: 'Gerente de pronto-socorro' },
  { id: '141810 Gerente de rampa', descricao: 'Gerente de rampa' },
  { id: '141615 Gerente de recebimento e expedição de materiais', descricao: 'Gerente de recebimento e expedição de materiais' },
  { id: '141735 Gerente de recuperação de crédito', descricao: 'Gerente de recuperação de crédito' },
  { id: '142205 Gerente de recursos humanos', descricao: 'Gerente de recursos humanos' },
  { id: '142505 Gerente de rede', descricao: 'Gerente de rede' },
  { id: '141610 Gerente de rede nas operações de correios e telecomunicações', descricao: 'Gerente de rede nas operações de correios e telecomunicações' },
  { id: '142310 Gerente de relações corporativas', descricao: 'Gerente de relações corporativas' },
  { id: '142205 Gerente de relações de recursos humanos', descricao: 'Gerente de relações de recursos humanos' },
  { id: '142205 Gerente de relações humanas', descricao: 'Gerente de relações humanas' },
  { id: '142205 Gerente de relações industriais', descricao: 'Gerente de relações industriais' },
  { id: '142310 Gerente de relações institucionais', descricao: 'Gerente de relações institucionais' },
  { id: '142310 Gerente de relações públicas', descricao: 'Gerente de relações públicas' },
  { id: '141505 Gerente de resort', descricao: 'Gerente de resort' },
  { id: '141510 Gerente de restaurante', descricao: 'Gerente de restaurante' },
  { id: '142110 Gerente de riscos', descricao: 'Gerente de riscos' },
  { id: '142110 Gerente de riscos e seguros', descricao: 'Gerente de riscos e seguros' },
  { id: '141830 Gerente de safety', descricao: 'Gerente de safety' },
  { id: '141515 Gerente de salão de café', descricao: 'Gerente de salão de café' },
  { id: '141825 Gerente de security', descricao: 'Gerente de security' },
  { id: '141825 Gerente de segurança da aviação civil', descricao: 'Gerente de segurança da aviação civil' },
  { id: '142525 Gerente de segurança da informação', descricao: 'Gerente de segurança da informação' },
  { id: '141825 Gerente de segurança e resposta à emergência', descricao: 'Gerente de segurança e resposta à emergência' },
  { id: '252605 Gerente de segurança empresarial', descricao: 'Gerente de segurança empresarial' },
  { id: '141830 Gerente de segurança operacional (aviação civil)', descricao: 'Gerente de segurança operacional (aviação civil)' },
  { id: '142110 Gerente de seguros', descricao: 'Gerente de seguros' },
  { id: '142705 Gerente de serviço de manutenção', descricao: 'Gerente de serviço de manutenção' },
  { id: '131115 Gerente de serviços culturais', descricao: 'Gerente de serviços culturais' },
  { id: '141420 Gerente de serviços de oficina (assistência técnica)', descricao: 'Gerente de serviços de oficina (assistência técnica)' },
  { id: '131210 Gerente de serviços de saúde', descricao: 'Gerente de serviços de saúde' },
  { id: '141420 Gerente de serviços e peças', descricao: 'Gerente de serviços e peças' },
  { id: '131320 Gerente de serviços educacionais da área pública', descricao: 'Gerente de serviços educacionais da área pública' },
  { id: '141610 Gerente de serviços operacionais de correios e telecomunicações', descricao: 'Gerente de serviços operacionais de correios e telecomunicações' },
  { id: '131120 Gerente de serviços sociais', descricao: 'Gerente de serviços sociais' },
  { id: '142210 Gerente de setor de pessoal', descricao: 'Gerente de setor de pessoal' },
  { id: '141830 Gerente de sistema de gerenciamento de segurança operacional (sgso)', descricao: 'Gerente de sistema de gerenciamento de segurança operacional (sgso)' },
  { id: '142210 Gerente de sistemas administrativos de pessoal', descricao: 'Gerente de sistemas administrativos de pessoal' },
  { id: '142105 Gerente de sistemas administrativos e finanças', descricao: 'Gerente de sistemas administrativos e finanças' },
  { id: '142510 Gerente de sistemas de ti', descricao: 'Gerente de sistemas de ti' },
  { id: '142105 Gerente de sistemas e métodos administrativos', descricao: 'Gerente de sistemas e métodos administrativos' },
  { id: '141415 Gerente de supermercado', descricao: 'Gerente de supermercado' },
  { id: '141610 Gerente de suporte operacional de correios e telecomunicações', descricao: 'Gerente de suporte operacional de correios e telecomunicações' },
  { id: '142530 Gerente de suporte técnico de tecnologia da informação', descricao: 'Gerente de suporte técnico de tecnologia da informação' },
  { id: '142410 Gerente de suprimentos', descricao: 'Gerente de suprimentos' },
  { id: '141810 Gerente de taxi aéreo', descricao: 'Gerente de taxi aéreo' },
  { id: '141505 Gerente de termas', descricao: 'Gerente de termas' },
  { id: '122610 Gerente de terminal em operações de armazenamento', descricao: 'Gerente de terminal em operações de armazenamento' },
  { id: '141605 Gerente de tráfego', descricao: 'Gerente de tráfego' },
  { id: '141820 Gerente de tráfego de cargas', descricao: 'Gerente de tráfego de cargas' },
  { id: '342305 Gerente de tráfego rodoviário', descricao: 'Gerente de tráfego rodoviário' },
  { id: '141605 Gerente de transportes', descricao: 'Gerente de transportes' },
  { id: '141525 Gerente de turismo', descricao: 'Gerente de turismo' },
  { id: '141205 Gerente de unidade de fabricação', descricao: 'Gerente de unidade de fabricação' },
  { id: '141415 Gerente de utilidades (operações comerciais)', descricao: 'Gerente de utilidades (operações comerciais)' },
  { id: '141415 Gerente de varejo', descricao: 'Gerente de varejo' },
  { id: '142320 Gerente de vendas', descricao: 'Gerente de vendas' },
  { id: '142320 Gerente distrital de vendas', descricao: 'Gerente distrital de vendas' },
  { id: '111410 Gerente do serviço público estadual e distrital', descricao: 'Gerente do serviço público estadual e distrital' },
  { id: '111405 Gerente do serviço público federal', descricao: 'Gerente do serviço público federal' },
  { id: '142505 Gerente executivo de infraestrutura de ti', descricao: 'Gerente executivo de infraestrutura de ti' },
  { id: '142115 Gerente financeiro', descricao: 'Gerente financeiro' },
  { id: '142405 Gerente geral de compras', descricao: 'Gerente geral de compras' },
  { id: '142320 Gerente geral de vendas', descricao: 'Gerente geral de vendas' },
  { id: '141205 Gerente industrial', descricao: 'Gerente industrial' },
  { id: '142405 Gerente nacional de compras', descricao: 'Gerente nacional de compras' },
  { id: '142320 Gerente nacional de vendas', descricao: 'Gerente nacional de vendas' },
  { id: '141810 Gerente operacional', descricao: 'Gerente operacional' },
  { id: '141820 Gerente operacional de cargas', descricao: 'Gerente operacional de cargas' },
  { id: '141525 Gerente operacional de turismo', descricao: 'Gerente operacional de turismo' },
  { id: '141110 Gerente operacional florestal', descricao: 'Gerente operacional florestal' },
  { id: '141115 Gerente operacional na agropecuária', descricao: 'Gerente operacional na agropecuária' },
  { id: '141810 Gerente regional de aeroportos', descricao: 'Gerente regional de aeroportos' },
  { id: '141820 Gerente regional de cargas', descricao: 'Gerente regional de cargas' },
  { id: '142320 Gerente regional de vendas', descricao: 'Gerente regional de vendas' },
  { id: '142305 Gerente técnico comercial', descricao: 'Gerente técnico comercial' },
  { id: '710115 Gerente técnico de produção (mineração)', descricao: 'Gerente técnico de produção (mineração)' },
  { id: '141610 Gerente técnico nas operações de correios e telecomunicações', descricao: 'Gerente técnico nas operações de correios e telecomunicações' },
  { id: '141605 Gerente técnico operacional de transporte', descricao: 'Gerente técnico operacional de transporte' },
  { id: '1421 GERENTES ADMINISTRATIVOS, FINANCEIROS, DE RISCOS E AFINS', descricao: 'GERENTES ADMINISTRATIVOS, FINANCEIROS, DE RISCOS E AFINS' },
  { id: '1423 GERENTES DE COMERCIALIZAÇÃO, MARKETING E COMUNICAÇÃO', descricao: 'GERENTES DE COMERCIALIZAÇÃO, MARKETING E COMUNICAÇÃO' },
  { id: '1427 GERENTES DE MANUTENÇÃO E AFINS', descricao: 'GERENTES DE MANUTENÇÃO E AFINS' },
  { id: '1413 GERENTES DE OBRAS EM EMPRESA DE CONSTRUÇÃO', descricao: 'GERENTES DE OBRAS EM EMPRESA DE CONSTRUÇÃO' },
  { id: '1414 GERENTES DE OPERAÇÕES COMERCIAIS E DE ASSISTÊNCIA TÉCNICA', descricao: 'GERENTES DE OPERAÇÕES COMERCIAIS E DE ASSISTÊNCIA TÉCNICA' },
  { id: '1416 GERENTES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE TRANSPORTE, DE COMUNICAÇÃO E DE LOGÍSTICA', descricao: 'GERENTES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE TRANSPORTE, DE COMUNICAÇÃO E DE LOGÍSTICA' },
  { id: '1415 GERENTES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE TURISMO, DE ALOJAMENTO E ALIMENTAÇÃO', descricao: 'GERENTES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE TURISMO, DE ALOJAMENTO E ALIMENTAÇÃO' },
  { id: '1417 GERENTES DE OPERAÇÕES DE SERVIÇOS EM INSTITUIÇÃO DE INTERMEDIAÇÃO FINANCEIRA', descricao: 'GERENTES DE OPERAÇÕES DE SERVIÇOS EM INSTITUIÇÃO DE INTERMEDIAÇÃO FINANCEIRA' },
  { id: '1426 GERENTES DE PESQUISA E DESENVOLVIMENTO E AFINS', descricao: 'GERENTES DE PESQUISA E DESENVOLVIMENTO E AFINS' },
  { id: '1411 GERENTES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA AGROPECUÁRIA, PESQUEIRA, AQÜÍCOLA E FLORESTAL', descricao: 'GERENTES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA AGROPECUÁRIA, PESQUEIRA, AQÜÍCOLA E FLORESTAL' },
  { id: '1412 GERENTES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA DA INDÚSTRIA EXTRATIVA, DE TRANSFORMAÇÃO E DE', descricao: 'GERENTES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA DA INDÚSTRIA EXTRATIVA, DE TRANSFORMAÇÃO E DE' },
  { id: '1422 GERENTES DE RECURSOS HUMANOS E DE RELAÇÕES DO TRABALHO', descricao: 'GERENTES DE RECURSOS HUMANOS E DE RELAÇÕES DO TRABALHO' },
  { id: '1424 GERENTES DE SUPRIMENTOS E AFINS', descricao: 'GERENTES DE SUPRIMENTOS E AFINS' },
  { id: '1425 GERENTES DE TECNOLOGIA DA INFORMAÇÃO', descricao: 'GERENTES DE TECNOLOGIA DA INFORMAÇÃO' },
  { id: '1418 GERENTES OPERACIONAIS DA AVIAÇÃO CIVIL', descricao: 'GERENTES OPERACIONAIS DA AVIAÇÃO CIVIL' },
  { id: '225180 Geriatra', descricao: 'Geriatra' },
  { id: '516210 Gero-sitter', descricao: 'Gero-sitter' },
  { id: '225180 Gerontologista', descricao: 'Gerontologista' },
  { id: '131220 Gerontólogo', descricao: 'Gerontólogo' },
  { id: '225180 Gerontólogo', descricao: 'Gerontólogo' },
  { id: '716405 Gesseiro', descricao: 'Gesseiro' },
  { id: '716405 Gesseiro de decoração', descricao: 'Gesseiro de decoração' },
  { id: '716405 Gesseiro de revestimento', descricao: 'Gesseiro de revestimento' },
  { id: '716405 Gesseiro forrador', descricao: 'Gesseiro forrador' },
  { id: '716405 Gesseiro fundidor', descricao: 'Gesseiro fundidor' },
  { id: '716405 Gesseiro modelador', descricao: 'Gesseiro modelador' },
  { id: '716405 Gesseiro moldureiro', descricao: 'Gesseiro moldureiro' },
  { id: '716405 Gesseiro montador', descricao: 'Gesseiro montador' },
  { id: '716405 Gesseiro plaqueiro', descricao: 'Gesseiro plaqueiro' },
  { id: '7164 GESSEIROS', descricao: 'GESSEIROS' },
  { id: '415310 Gestor de dados clínicos', descricao: 'Gestor de dados clínicos' },
  { id: '261305 Gestor de documentos', descricao: 'Gestor de documentos' },
  { id: '114305 Gestor de entidade religiosa', descricao: 'Gestor de entidade religiosa' },
  { id: '354820 Gestor de evento', descricao: 'Gestor de evento' },
  { id: '261205 Gestor de informação', descricao: 'Gestor de informação' },
  { id: '142705 Gestor de manutenção', descricao: 'Gestor de manutenção' },
  { id: '142350 Gestor de negócios internacionais', descricao: 'Gestor de negócios internacionais' },
  { id: '142350 Gestor de projetos internacionais', descricao: 'Gestor de projetos internacionais' },
  { id: '142110 Gestor de riscos', descricao: 'Gestor de riscos' },
  { id: '252605 Gestor em segurança', descricao: 'Gestor em segurança' },
  { id: '122520 Gestor em turismo (turismólogo)', descricao: 'Gestor em turismo (turismólogo)' },
  { id: '252105 Gestor público (administrador)', descricao: 'Gestor público (administrador)' },
  { id: '1312 GESTORES E ESPECIALISTAS DE OPERAÇÕES EM EMPRESAS, SECRETARIAS E UNIDADES DE SERVIÇOS DE SAÚDE', descricao: 'GESTORES E ESPECIALISTAS DE OPERAÇÕES EM EMPRESAS, SECRETARIAS E UNIDADES DE SERVIÇOS DE SAÚDE' },
  { id: '1115 GESTORES PÚBLICOS', descricao: 'GESTORES PÚBLICOS' },
  { id: '263105 Gheshe', descricao: 'Gheshe' },
  { id: '225250 Ginecologista', descricao: 'Ginecologista' },
  { id: '376215 Globista', descricao: 'Globista' },
  { id: '261530 Glossarista', descricao: 'Glossarista' },
  { id: '371410 Go - gentil organizador', descricao: 'Go - gentil organizador' },
  { id: '377110 Goleiro', descricao: 'Goleiro' },
  { id: '377115 Golfista profissional', descricao: 'Golfista profissional' },
  { id: '111230 Governador de estado', descricao: 'Governador de estado' },
  { id: '111235 Governador do distrito federal', descricao: 'Governador do distrito federal' },
  { id: '513115 Governanta de hotelaria', descricao: 'Governanta de hotelaria' },
  { id: '513105 Governanta de residência', descricao: 'Governanta de residência' },
  { id: '513115 Governanta executiva', descricao: 'Governanta executiva' },
  { id: '828110 Gradeiro', descricao: 'Gradeiro' },
  { id: '262405 Grafiteiro (artes visuais)', descricao: 'Grafiteiro (artes visuais)' },
  { id: '841460 Graneador', descricao: 'Graneador' },
  { id: '823330 Graniteiro', descricao: 'Graniteiro' },
  { id: '711110 Graniteiro - na extração', descricao: 'Graniteiro - na extração' },
  { id: '612105 Granjeiro na produção de arroz', descricao: 'Granjeiro na produção de arroz' },
  { id: '612120 Granjeiro na produção de gramíneas forrageiras', descricao: 'Granjeiro na produção de gramíneas forrageiras' },
  { id: '612125 Granjeiro na produção de milho e sorgo', descricao: 'Granjeiro na produção de milho e sorgo' },
  { id: '262405 Gravador (artes visuais)', descricao: 'Gravador (artes visuais)' },
  { id: '751115 Gravador (joalheria e ourivesaria)', descricao: 'Gravador (joalheria e ourivesaria)' },
  { id: '766145 Gravador a ácido', descricao: 'Gravador a ácido' },
  { id: '766145 Gravador com pantógrafo', descricao: 'Gravador com pantógrafo' },
  { id: '766145 Gravador de chapa', descricao: 'Gravador de chapa' },
  { id: '766145 Gravador de chapas e cilindros com ácido', descricao: 'Gravador de chapas e cilindros com ácido' },
  { id: '766145 Gravador de chapas, cliches e cilindros de impressão', descricao: 'Gravador de chapas, cliches e cilindros de impressão' },
  { id: '766145 Gravador de cilindros, a máquina', descricao: 'Gravador de cilindros, a máquina' },
  { id: '766115 Gravador de clichês (madeira, borracha ou linóleo)', descricao: 'Gravador de clichês (madeira, borracha ou linóleo)' },
  { id: '766115 Gravador de clichês, a mão (matrizes de borracha ou linóleo)', descricao: 'Gravador de clichês, a mão (matrizes de borracha ou linóleo)' },
  { id: '766145 Gravador de fotogravura ou de rotogravura', descricao: 'Gravador de fotogravura ou de rotogravura' },
  { id: '766125 Gravador de fotolito', descricao: 'Gravador de fotolito' },
  { id: '712210 Gravador de inscrições em mármore', descricao: 'Gravador de inscrições em mármore' },
  { id: '712210 Gravador de inscrições em mármore e em pedras', descricao: 'Gravador de inscrições em mármore e em pedras' },
  { id: '712210 Gravador de inscrições em pedra', descricao: 'Gravador de inscrições em pedra' },
  { id: '751115 Gravador de jóias', descricao: 'Gravador de jóias' },
  { id: '768705 Gravador de lombadas de livros', descricao: 'Gravador de lombadas de livros' },
  { id: '766135 Gravador de matriz calcográfica', descricao: 'Gravador de matriz calcográfica' },
  { id: '766115 Gravador de matriz para flexografia (clicherista)', descricao: 'Gravador de matriz para flexografia (clicherista)' },
  { id: '766130 Gravador de matriz para rotogravura (eletromecânico e químico)', descricao: 'Gravador de matriz para rotogravura (eletromecânico e químico)' },
  { id: '766140 Gravador de matriz serigráfica', descricao: 'Gravador de matriz serigráfica' },
  { id: '766145 Gravador de matrizes de impressão', descricao: 'Gravador de matrizes de impressão' },
  { id: '712215 Gravador de relevos em pedra', descricao: 'Gravador de relevos em pedra' },
  { id: '766145 Gravador de rotogravura', descricao: 'Gravador de rotogravura' },
  { id: '752215 Gravador de vidro a água-forte', descricao: 'Gravador de vidro a água-forte' },
  { id: '752220 Gravador de vidro a esmeril', descricao: 'Gravador de vidro a esmeril' },
  { id: '752225 Gravador de vidro a jato de areia', descricao: 'Gravador de vidro a jato de areia' },
  { id: '751115 Gravador em ouriversaria', descricao: 'Gravador em ouriversaria' },
  { id: '768705 Gravador, à mão (encadernação)', descricao: 'Gravador, à mão (encadernação)' },
  { id: '312320 Greidista', descricao: 'Greidista' },
  { id: '141405 Grossista', descricao: 'Grossista' },
  { id: '711405 Gruneiro', descricao: 'Gruneiro' },
  { id: '612625 Guaranaicultor', descricao: 'Guaranaicultor' },
  { id: '622625 Guaranazeiro', descricao: 'Guaranazeiro' },
  { id: '612625 Guaranazeiro - empregador', descricao: 'Guaranazeiro - empregador' },
  { id: '352205 Guarda ambiental', descricao: 'Guarda ambiental' },
  { id: '517330 Guarda de banco - organizações particulares de segurança', descricao: 'Guarda de banco - organizações particulares de segurança' },
  { id: '515140 Guarda de endemias', descricao: 'Guarda de endemias' },
  { id: '517230 Guarda de presídio', descricao: 'Guarda de presídio' },
  { id: '517330 Guarda de segurança', descricao: 'Guarda de segurança' },
  { id: '517330 Guarda de segurança - empresa particular de segurança', descricao: 'Guarda de segurança - empresa particular de segurança' },
  { id: '517330 Guarda de vigilância', descricao: 'Guarda de vigilância' },
  { id: '517330 Guarda ferroviário', descricao: 'Guarda ferroviário' },
  { id: '352205 Guarda florestal', descricao: 'Guarda florestal' },
  { id: '517235 Guarda portuário', descricao: 'Guarda portuário' },
  { id: '517330 Guarda valores', descricao: 'Guarda valores' },
  { id: '517330 Guarda vigia', descricao: 'Guarda vigia' },
  { id: '517330 Guarda-civil', descricao: 'Guarda-civil' },
  { id: '517215 Guarda-civil metropolitano', descricao: 'Guarda-civil metropolitano' },
  { id: '517215 Guarda-civil municipal', descricao: 'Guarda-civil municipal' },
  { id: '517330 Guarda-costas', descricao: 'Guarda-costas' },
  { id: '352205 Guarda-parques', descricao: 'Guarda-parques' },
  { id: '513325 Guarda-roupeiro de cinema', descricao: 'Guarda-roupeiro de cinema' },
  { id: '513305 Guarda-roupeiro de teatro', descricao: 'Guarda-roupeiro de teatro' },
  { id: '513310 Guarda-roupeiro de televisão', descricao: 'Guarda-roupeiro de televisão' },
  { id: '517320 Guarda-rural', descricao: 'Guarda-rural' },
  { id: '517320 Guarda-territorial', descricao: 'Guarda-territorial' },
  { id: '517115 Guarda-vidas', descricao: 'Guarda-vidas' },
  { id: '519925 Guardador autônomo de veículos', descricao: 'Guardador autônomo de veículos' },
  { id: '519925 Guardador de carro', descricao: 'Guardador de carro' },
  { id: '519925 Guardador de veículos', descricao: 'Guardador de veículos' },
  { id: '517410 Guariteiro', descricao: 'Guariteiro' },
  { id: '422120 Guest relations', descricao: 'Guest relations' },
  { id: '511405 Guia de turismo', descricao: 'Guia de turismo' },
  { id: '511405 Guia de turismo especializado em atrativo turístico', descricao: 'Guia de turismo especializado em atrativo turístico' },
  { id: '511405 Guia de turismo especializado em excursão internacional', descricao: 'Guia de turismo especializado em excursão internacional' },
  { id: '511405 Guia de turismo especializado em excursão nacional', descricao: 'Guia de turismo especializado em excursão nacional' },
  { id: '511405 Guia de turismo especializado em turismo regional', descricao: 'Guia de turismo especializado em turismo regional' },
  { id: '632005 Guia florestal', descricao: 'Guia florestal' },
  { id: '261425 Guia-intérprete', descricao: 'Guia-intérprete' },
  { id: '5114 GUIAS DE TURISMO', descricao: 'GUIAS DE TURISMO' },
  { id: '782815 Guieiro', descricao: 'Guieiro' },
  { id: '766320 Guilhotineiro - na indústria gráfica', descricao: 'Guilhotineiro - na indústria gráfica' },
  { id: '782110 Guincheiro', descricao: 'Guincheiro' },
  { id: '782205 Guincheiro (construção civil)', descricao: 'Guincheiro (construção civil)' },
  { id: '782110 Guindasteiro', descricao: 'Guindasteiro' },
  { id: '225135 Hansenólogo', descricao: 'Hansenólogo' },
  { id: '225124 Hebeatra', descricao: 'Hebeatra' },
  { id: '225185 Hematologista', descricao: 'Hematologista' },
  { id: '225340 Hemoterapeuta', descricao: 'Hemoterapeuta' },
  { id: '141410 Herborista (comércio varejista)', descricao: 'Herborista (comércio varejista)' },
  { id: '752120 Hialotécnico', descricao: 'Hialotécnico' },
  { id: '221105 Hidrobiologista', descricao: 'Hidrobiologista' },
  { id: '841444 Hidrogenador de óleos e gorduras', descricao: 'Hidrogenador de óleos e gorduras' },
  { id: '213425 Hidrogeólogo', descricao: 'Hidrogeólogo' },
  { id: '519940 Hidrometrista', descricao: 'Hidrometrista' },
  { id: '312315 Hidrotécnico', descricao: 'Hidrotécnico' },
  { id: '214940 Higienista industrial', descricao: 'Higienista industrial' },
  { id: '214940 Higienista ocupacional', descricao: 'Higienista ocupacional' },
  { id: '221105 Histologista', descricao: 'Histologista' },
  { id: '203520 Historiador', descricao: 'Historiador' },
  { id: '262620 Historiador em música', descricao: 'Historiador em música' },
  { id: '376215 Homem-bala', descricao: 'Homem-bala' },
  { id: '322125 Homeopata (não médico)', descricao: 'Homeopata (não médico)' },
  { id: '612320 Horticultor de frutos e sementes', descricao: 'Horticultor de frutos e sementes' },
  { id: '612305 Horticultor de legumes', descricao: 'Horticultor de legumes' },
  { id: '612310 Horticultor de raízes, bulbos e tubérculos', descricao: 'Horticultor de raízes, bulbos e tubérculos' },
  { id: '612315 Horticultor de talos, folhas e flores', descricao: 'Horticultor de talos, folhas e flores' },
  { id: '263105 Humbono', descricao: 'Humbono' },
  { id: '262505 Humorista', descricao: 'Humorista' },
  { id: '263105 Hunjaí', descricao: 'Hunjaí' },
  { id: '263105 Huntó', descricao: 'Huntó' },
  { id: '632115 Identificador florestal', descricao: 'Identificador florestal' },
  { id: '372110 Iluminador (televisão)', descricao: 'Iluminador (televisão)' },
  { id: '376235 Ilusionista', descricao: 'Ilusionista' },
  { id: '262405 Ilustrador (artes visuais)', descricao: 'Ilustrador (artes visuais)' },
  { id: '263115 Imã', descricao: 'Imã' },
  { id: '375125 Image designer', descricao: 'Image designer' },
  { id: '225320 Imaginologista', descricao: 'Imaginologista' },
  { id: '215125 Imediato da marinha mercante', descricao: 'Imediato da marinha mercante' },
  { id: '141405 Importador', descricao: 'Importador' },
  { id: '772110 Impregnador de madeira', descricao: 'Impregnador de madeira' },
  { id: '772110 Impregnador de madeira (produtos antiinflamáveis)', descricao: 'Impregnador de madeira (produtos antiinflamáveis)' },
  { id: '766205 Impressor (serigrafia)', descricao: 'Impressor (serigrafia)' },
  { id: '766250 Impressor automático', descricao: 'Impressor automático' },
  { id: '766210 Impressor calcográfico', descricao: 'Impressor calcográfico' },
  { id: '766215 Impressor de cartazes', descricao: 'Impressor de cartazes' },
  { id: '766310 Impressor de corte e vinco', descricao: 'Impressor de corte e vinco' },
  { id: '766250 Impressor de etiqueta', descricao: 'Impressor de etiqueta' },
  { id: '766220 Impressor de formulários contínuos', descricao: 'Impressor de formulários contínuos' },
  { id: '766215 Impressor de máquina ofsete', descricao: 'Impressor de máquina ofsete' },
  { id: '766250 Impressor de máquina plana', descricao: 'Impressor de máquina plana' },
  { id: '766215 Impressor de ofsete (plano e rotativo)', descricao: 'Impressor de ofsete (plano e rotativo)' },
  { id: '766225 Impressor de plásticos', descricao: 'Impressor de plásticos' },
  { id: '766220 Impressor de rotativa', descricao: 'Impressor de rotativa' },
  { id: '766225 Impressor de rotogravura', descricao: 'Impressor de rotogravura' },
  { id: '766225 Impressor de rótulos', descricao: 'Impressor de rótulos' },
  { id: '766210 Impressor de segurança', descricao: 'Impressor de segurança' },
  { id: '766250 Impressor de selo', descricao: 'Impressor de selo' },
  { id: '766205 Impressor de silkscreen', descricao: 'Impressor de silkscreen' },
  { id: '766210 Impressor de talho doce', descricao: 'Impressor de talho doce' },
  { id: '766245 Impressor de tipografia', descricao: 'Impressor de tipografia' },
  { id: '766230 Impressor digital', descricao: 'Impressor digital' },
  { id: '766235 Impressor flexográfico', descricao: 'Impressor flexográfico' },
  { id: '766250 Impressor gráfico manual', descricao: 'Impressor gráfico manual' },
  { id: '766240 Impressor letterset', descricao: 'Impressor letterset' },
  { id: '766215 Impressor multilight', descricao: 'Impressor multilight' },
  { id: '766205 Impressor serigráfico', descricao: 'Impressor serigráfico' },
  { id: '752205 Impressor serigráfico em vidros', descricao: 'Impressor serigráfico em vidros' },
  { id: '752205 Impressor silk screen em vidros', descricao: 'Impressor silk screen em vidros' },
  { id: '766245 Impressor tampográfico', descricao: 'Impressor tampográfico' },
  { id: '766250 Impressor tipográfico', descricao: 'Impressor tipográfico' },
  { id: '515120 Imunizador', descricao: 'Imunizador' },
  { id: '623315 Incubador de ovos', descricao: 'Incubador de ovos' },
  { id: '519945 Indicador', descricao: 'Indicador' },
  { id: '225103 Infectologista', descricao: 'Infectologista' },
  { id: '253410 Influencer', descricao: 'Influencer' },
  { id: '253410 Influenciador digital', descricao: 'Influenciador digital' },
  { id: '811770 Injetor de plástico', descricao: 'Injetor de plástico' },
  { id: '623010 Inseminador', descricao: 'Inseminador' },
  { id: '623010 Inseminador de animais', descricao: 'Inseminador de animais' },
  { id: '221105 Insetologista', descricao: 'Insetologista' },
  { id: '352205 Inspetor agrícola', descricao: 'Inspetor agrícola' },
  { id: '252210 Inspetor de agência bancária', descricao: 'Inspetor de agência bancária' },
  { id: '780105 Inspetor de alimentador de linhas de rotulagem', descricao: 'Inspetor de alimentador de linhas de rotulagem' },
  { id: '334110 Inspetor de alunos', descricao: 'Inspetor de alunos' },
  { id: '334105 Inspetor de alunos de escola privada', descricao: 'Inspetor de alunos de escola privada' },
  { id: '334110 Inspetor de alunos de escola pública', descricao: 'Inspetor de alunos de escola pública' },
  { id: '252205 Inspetor de auditoria', descricao: 'Inspetor de auditoria' },
  { id: '351730 Inspetor de avarias', descricao: 'Inspetor de avarias' },
  { id: '342530 Inspetor de aviação civil', descricao: 'Inspetor de aviação civil' },
  { id: '510105 Inspetor de bilheteria de transportes', descricao: 'Inspetor de bilheteria de transportes' },
  { id: '761810 Inspetor de bobinas (produção têxtil)', descricao: 'Inspetor de bobinas (produção têxtil)' },
  { id: '511105 Inspetor de bordo', descricao: 'Inspetor de bordo' },
  { id: '342310 Inspetor de carga e descarga', descricao: 'Inspetor de carga e descarga' },
  { id: '342310 Inspetor de carregadoria de embarque rodoviário', descricao: 'Inspetor de carregadoria de embarque rodoviário' },
  { id: '313315 Inspetor de centrais privadas de comutação telefônica', descricao: 'Inspetor de centrais privadas de comutação telefônica' },
  { id: '391205 Inspetor de chopp', descricao: 'Inspetor de chopp' },
  { id: '352210 Inspetor de comercialização de produtos', descricao: 'Inspetor de comercialização de produtos' },
  { id: '314830 Inspetor de controle dimensional', descricao: 'Inspetor de controle dimensional' },
  { id: '314830 Inspetor de controle dimensional (caldeiraria e tubulação)', descricao: 'Inspetor de controle dimensional (caldeiraria e tubulação)' },
  { id: '314830 Inspetor de controle dimensional (mecânica)', descricao: 'Inspetor de controle dimensional (mecânica)' },
  { id: '314830 Inspetor de controle dimensional (montagem de máquinas)', descricao: 'Inspetor de controle dimensional (montagem de máquinas)' },
  { id: '314830 Inspetor de controle dimensional (topografia)', descricao: 'Inspetor de controle dimensional (topografia)' },
  { id: '334110 Inspetor de disciplina', descricao: 'Inspetor de disciplina' },
  { id: '314825 Inspetor de dutos', descricao: 'Inspetor de dutos' },
  { id: '314825 Inspetor de dutos terrestres', descricao: 'Inspetor de dutos terrestres' },
  { id: '201215 Inspetor de ensaios metrológicos', descricao: 'Inspetor de ensaios metrológicos' },
  { id: '314815 Inspetor de ensaios não destrutivos', descricao: 'Inspetor de ensaios não destrutivos' },
  { id: '314815 Inspetor de ensaios não destrutivos (correntes parasitas)', descricao: 'Inspetor de ensaios não destrutivos (correntes parasitas)' },
  { id: '314815 Inspetor de ensaios não destrutivos (emissão acústica)', descricao: 'Inspetor de ensaios não destrutivos (emissão acústica)' },
  { id: '314815 Inspetor de ensaios não destrutivos (estanqueidade)', descricao: 'Inspetor de ensaios não destrutivos (estanqueidade)' },
  { id: '314815 Inspetor de ensaios não destrutivos (líquido penetrante)', descricao: 'Inspetor de ensaios não destrutivos (líquido penetrante)' },
  { id: '314815 Inspetor de ensaios não destrutivos (partícula magnética)', descricao: 'Inspetor de ensaios não destrutivos (partícula magnética)' },
  { id: '314815 Inspetor de ensaios não destrutivos (radiográfico)', descricao: 'Inspetor de ensaios não destrutivos (radiográfico)' },
  { id: '314815 Inspetor de ensaios não destrutivos (termografia)', descricao: 'Inspetor de ensaios não destrutivos (termografia)' },
  { id: '314815 Inspetor de ensaios não destrutivos (teste por pontos)', descricao: 'Inspetor de ensaios não destrutivos (teste por pontos)' },
  { id: '314815 Inspetor de ensaios não destrutivos (ultrassom)', descricao: 'Inspetor de ensaios não destrutivos (ultrassom)' },
  { id: '314815 Inspetor de ensaios não destrutivos (visual)', descricao: 'Inspetor de ensaios não destrutivos (visual)' },
  { id: '314805 Inspetor de equipamentos', descricao: 'Inspetor de equipamentos' },
  { id: '352310 Inspetor de equipamentos', descricao: 'Inspetor de equipamentos' },
  { id: '314805 Inspetor de equipamentos (estruturas metálicas)', descricao: 'Inspetor de equipamentos (estruturas metálicas)' },
  { id: '761805 Inspetor de estamparia (produção têxtil)', descricao: 'Inspetor de estamparia (produção têxtil)' },
  { id: '314810 Inspetor de fabricação', descricao: 'Inspetor de fabricação' },
  { id: '314810 Inspetor de fabricação (acessórios de tubulação)', descricao: 'Inspetor de fabricação (acessórios de tubulação)' },
  { id: '314810 Inspetor de fabricação (caldeiraria e tubulação)', descricao: 'Inspetor de fabricação (caldeiraria e tubulação)' },
  { id: '314810 Inspetor de fabricação (elétrica/instrumentação)', descricao: 'Inspetor de fabricação (elétrica/instrumentação)' },
  { id: '314810 Inspetor de fabricação (mecânica)', descricao: 'Inspetor de fabricação (mecânica)' },
  { id: '314810 Inspetor de fabricação (perfuração e produção de petróleo)', descricao: 'Inspetor de fabricação (perfuração e produção de petróleo)' },
  { id: '314810 Inspetor de fabricação (tubos flexíveis e umbilicais)', descricao: 'Inspetor de fabricação (tubos flexíveis e umbilicais)' },
  { id: '391205 Inspetor de ferramentas', descricao: 'Inspetor de ferramentas' },
  { id: '391205 Inspetor de fundição', descricao: 'Inspetor de fundição' },
  { id: '517235 Inspetor de guarda portuária', descricao: 'Inspetor de guarda portuária' },
  { id: '517320 Inspetor de guarda-territorial', descricao: 'Inspetor de guarda-territorial' },
  { id: '391205 Inspetor de linha de montagem', descricao: 'Inspetor de linha de montagem' },
  { id: '391205 Inspetor de linha de produção', descricao: 'Inspetor de linha de produção' },
  { id: '391205 Inspetor de linha de transmissão', descricao: 'Inspetor de linha de transmissão' },
  { id: '391205 Inspetor de linhas de cabos aéreos', descricao: 'Inspetor de linhas de cabos aéreos' },
  { id: '314840 Inspetor de manutenção', descricao: 'Inspetor de manutenção' },
  { id: '314840 Inspetor de manutenção (elétrica e instrumentação)', descricao: 'Inspetor de manutenção (elétrica e instrumentação)' },
  { id: '314840 Inspetor de manutenção (mecânica)', descricao: 'Inspetor de manutenção (mecânica)' },
  { id: '215220 Inspetor de manutenção de máquinas aquaviárias', descricao: 'Inspetor de manutenção de máquinas aquaviárias' },
  { id: '911120 Inspetor de manutenção de motores diesel (exceto de veículos automotores)', descricao: 'Inspetor de manutenção de motores diesel (exceto de veículos automotores)' },
  { id: '314125 Inspetor de máquinas e motores', descricao: 'Inspetor de máquinas e motores' },
  { id: '784110 Inspetor de material de embalagem', descricao: 'Inspetor de material de embalagem' },
  { id: '352305 Inspetor de medição', descricao: 'Inspetor de medição' },
  { id: '391205 Inspetor de montagem', descricao: 'Inspetor de montagem' },
  { id: '312105 Inspetor de obras', descricao: 'Inspetor de obras' },
  { id: '761815 Inspetor de pano', descricao: 'Inspetor de pano' },
  { id: '314835 Inspetor de pintura', descricao: 'Inspetor de pintura' },
  { id: '351810 Inspetor de polícia', descricao: 'Inspetor de polícia' },
  { id: '517210 Inspetor de polícia rodoviária federal', descricao: 'Inspetor de polícia rodoviária federal' },
  { id: '710115 Inspetor de produção (mineração)', descricao: 'Inspetor de produção (mineração)' },
  { id: '351715 Inspetor de produção de seguros', descricao: 'Inspetor de produção de seguros' },
  { id: '391205 Inspetor de qualidade', descricao: 'Inspetor de qualidade' },
  { id: '391205 Inspetor de qualidade - nas indústrias', descricao: 'Inspetor de qualidade - nas indústrias' },
  { id: '391205 Inspetor de qualidade material', descricao: 'Inspetor de qualidade material' },
  { id: '351725 Inspetor de risco', descricao: 'Inspetor de risco' },
  { id: '352210 Inspetor de saneamento', descricao: 'Inspetor de saneamento' },
  { id: '510310 Inspetor de segurança', descricao: 'Inspetor de segurança' },
  { id: '342310 Inspetor de serviços de transportes rodoviários (passageiros e cargas)', descricao: 'Inspetor de serviços de transportes rodoviários (passageiros e cargas)' },
  { id: '351730 Inspetor de sinistros', descricao: 'Inspetor de sinistros' },
  { id: '314845 Inspetor de soldagem', descricao: 'Inspetor de soldagem' },
  { id: '711105 Inspetor de sondagem - na mineração', descricao: 'Inspetor de sondagem - na mineração' },
  { id: '761820 Inspetor de tecidos crus', descricao: 'Inspetor de tecidos crus' },
  { id: '215130 Inspetor de terminal', descricao: 'Inspetor de terminal' },
  { id: '710215 Inspetor de terraplenagem', descricao: 'Inspetor de terraplenagem' },
  { id: '342310 Inspetor de tráfego rodoviário', descricao: 'Inspetor de tráfego rodoviário' },
  { id: '352310 Inspetor de transporte de produtos perigosos', descricao: 'Inspetor de transporte de produtos perigosos' },
  { id: '342310 Inspetor de transporte rodoviário', descricao: 'Inspetor de transporte rodoviário' },
  { id: '991110 Inspetor de via permanente (trilhos)', descricao: 'Inspetor de via permanente (trilhos)' },
  { id: '517330 Inspetor de vigilância', descricao: 'Inspetor de vigilância' },
  { id: '314615 Inspetor dimensional (estruturas metálicas)', descricao: 'Inspetor dimensional (estruturas metálicas)' },
  { id: '314805 Inspetor dimensional (estruturas metálicas)', descricao: 'Inspetor dimensional (estruturas metálicas)' },
  { id: '254505 Inspetor fiscal', descricao: 'Inspetor fiscal' },
  { id: '141410 Inspetor geral (comércio de mercadorias a varejo)', descricao: 'Inspetor geral (comércio de mercadorias a varejo)' },
  { id: '391205 Inspetor geral de produção', descricao: 'Inspetor geral de produção' },
  { id: '215135 Inspetor naval', descricao: 'Inspetor naval' },
  { id: '517230 Inspetor penitenciário', descricao: 'Inspetor penitenciário' },
  { id: '141410 Inspetor regional (comércio de mercadorias a varejo)', descricao: 'Inspetor regional (comércio de mercadorias a varejo)' },
  { id: '352310 Inspetor técnico de qualidade', descricao: 'Inspetor técnico de qualidade' },
  { id: '3341 INSPETORES DE ALUNOS E AFINS', descricao: 'INSPETORES DE ALUNOS E AFINS' },
  { id: '7618 INSPETORES E REVISORES DE PRODUÇÃO TÊXTIL', descricao: 'INSPETORES E REVISORES DE PRODUÇÃO TÊXTIL' },
  { id: '724115 Instalador de águas, esgotos e gás', descricao: 'Instalador de águas, esgotos e gás' },
  { id: '951305 Instalador de alarme', descricao: 'Instalador de alarme' },
  { id: '951305 Instalador de alarmes residenciais', descricao: 'Instalador de alarmes residenciais' },
  { id: '715615 Instalador de antenas de televisão', descricao: 'Instalador de antenas de televisão' },
  { id: '731320 Instalador de aparelhos telefônicos', descricao: 'Instalador de aparelhos telefônicos' },
  { id: '724115 Instalador de canos', descricao: 'Instalador de canos' },
  { id: '731305 Instalador de centrais telefônicas', descricao: 'Instalador de centrais telefônicas' },
  { id: '523105 Instalador de cortinas e persianas, portas sanfonadas e boxe', descricao: 'Instalador de cortinas e persianas, portas sanfonadas e boxe' },
  { id: '374110 Instalador de equipamentos de áudio', descricao: 'Instalador de equipamentos de áudio' },
  { id: '731305 Instalador de equipamentos de comunicação', descricao: 'Instalador de equipamentos de comunicação' },
  { id: '731205 Instalador de equipamentos de radar', descricao: 'Instalador de equipamentos de radar' },
  { id: '731205 Instalador de estação de rádio', descricao: 'Instalador de estação de rádio' },
  { id: '731205 Instalador de estação de tv', descricao: 'Instalador de estação de tv' },
  { id: '731305 Instalador de estações telefônicas', descricao: 'Instalador de estações telefônicas' },
  { id: '715710 Instalador de isolantes acústicos', descricao: 'Instalador de isolantes acústicos' },
  { id: '715715 Instalador de isolantes térmicos (refrigeração e climatização)', descricao: 'Instalador de isolantes térmicos (refrigeração e climatização)' },
  { id: '715720 Instalador de isolantes térmicos de caldeira e tubulações', descricao: 'Instalador de isolantes térmicos de caldeira e tubulações' },
  { id: '732120 Instalador de linhas elétricas de alta e baixa - tensão (rede aérea e subterrânea)', descricao: 'Instalador de linhas elétricas de alta e baixa - tensão (rede aérea e subterrânea)' },
  { id: '732120 Instalador de linhas subterrâneas (transmissão de energia elétrica)', descricao: 'Instalador de linhas subterrâneas (transmissão de energia elétrica)' },
  { id: '725205 Instalador de máquinas', descricao: 'Instalador de máquinas' },
  { id: '725225 Instalador de máquinas-ferramentas (usinagem de metais)', descricao: 'Instalador de máquinas-ferramentas (usinagem de metais)' },
  { id: '715725 Instalador de material isolante, a mão (edificações)', descricao: 'Instalador de material isolante, a mão (edificações)' },
  { id: '715730 Instalador de material isolante, à máquina (edificações)', descricao: 'Instalador de material isolante, à máquina (edificações)' },
  { id: '519905 Instalador de painéis', descricao: 'Instalador de painéis' },
  { id: '523110 Instalador de película solar (insulfilm)', descricao: 'Instalador de película solar (insulfilm)' },
  { id: '951305 Instalador de sistemas eletroeletrônicos de segurança', descricao: 'Instalador de sistemas eletroeletrônicos de segurança' },
  { id: '732140 Instalador de sistemas fotovoltaicos', descricao: 'Instalador de sistemas fotovoltaicos' },
  { id: '731150 Instalador de som', descricao: 'Instalador de som' },
  { id: '523110 Instalador de som e acessórios de veículos', descricao: 'Instalador de som e acessórios de veículos' },
  { id: '731320 Instalador de telefones', descricao: 'Instalador de telefones' },
  { id: '724115 Instalador de tubulações', descricao: 'Instalador de tubulações' },
  { id: '724120 Instalador de tubulações (aeronaves)', descricao: 'Instalador de tubulações (aeronaves)' },
  { id: '724125 Instalador de tubulações (embarcações)', descricao: 'Instalador de tubulações (embarcações)' },
  { id: '724130 Instalador de tubulações de gás combustível (produção e distribuição)', descricao: 'Instalador de tubulações de gás combustível (produção e distribuição)' },
  { id: '724135 Instalador de tubulações de vapor', descricao: 'Instalador de tubulações de vapor' },
  { id: '724135 Instalador de tubulações de vapor (produção e distribuição)', descricao: 'Instalador de tubulações de vapor (produção e distribuição)' },
  { id: '523120 Instalador de vinil adesivo', descricao: 'Instalador de vinil adesivo' },
  { id: '731125 Instalador e mantenedor de placas fotovoltaicas', descricao: 'Instalador e mantenedor de placas fotovoltaicas' },
  { id: '732125 Instalador eletricista (tração de veículos)', descricao: 'Instalador eletricista (tração de veículos)' },
  { id: '732140 Instalador fotovoltaico', descricao: 'Instalador fotovoltaico' },
  { id: '724110 Instalador hidráulico', descricao: 'Instalador hidráulico' },
  { id: '731320 Instalador mantenedor de telefonia', descricao: 'Instalador mantenedor de telefonia' },
  { id: '731305 Instalador reparador de central', descricao: 'Instalador reparador de central' },
  { id: '715610 Instalador reparador de equipamento de força', descricao: 'Instalador reparador de equipamento de força' },
  { id: '731305 Instalador-reparador de equipamentos de comutação em telefonia', descricao: 'Instalador-reparador de equipamentos de comutação em telefonia' },
  { id: '731310 Instalador-reparador de equipamentos de energia em telefonia', descricao: 'Instalador-reparador de equipamentos de energia em telefonia' },
  { id: '731315 Instalador-reparador de equipamentos de transmissão em telefonia', descricao: 'Instalador-reparador de equipamentos de transmissão em telefonia' },
  { id: '732130 Instalador-reparador de linhas de comunicação de dados', descricao: 'Instalador-reparador de linhas de comunicação de dados' },
  { id: '731320 Instalador-reparador de linhas e aparelhos de telecomunicações', descricao: 'Instalador-reparador de linhas e aparelhos de telecomunicações' },
  { id: '732130 Instalador-reparador de linhas telefônicas aéreas e subterrâneas', descricao: 'Instalador-reparador de linhas telefônicas aéreas e subterrâneas' },
  { id: '732130 Instalador-reparador de linhas telefônicas e telegráficas', descricao: 'Instalador-reparador de linhas telefônicas e telegráficas' },
  { id: '732120 Instalador-reparador de rede elétrica', descricao: 'Instalador-reparador de rede elétrica' },
  { id: '731325 Instalador-reparador de redes e cabos telefônicos', descricao: 'Instalador-reparador de redes e cabos telefônicos' },
  { id: '732130 Instalador-reparador de redes telefônicas e de comunicação de dados', descricao: 'Instalador-reparador de redes telefônicas e de comunicação de dados' },
  { id: '7257 INSTALADORES DE EQUIPAMENTOS DE REFRIGERAÇÃO E VENTILAÇÃO', descricao: 'INSTALADORES DE EQUIPAMENTOS DE REFRIGERAÇÃO E VENTILAÇÃO' },
  { id: '5231 INSTALADORES DE PRODUTOS E ACESSÓRIOS', descricao: 'INSTALADORES DE PRODUTOS E ACESSÓRIOS' },
  { id: '9513 INSTALADORES E MANTENEDORES DE SISTEMAS ELETROELETRÔNICOS DE SEGURANÇA', descricao: 'INSTALADORES E MANTENEDORES DE SISTEMAS ELETROELETRÔNICOS DE SEGURANÇA' },
  { id: '9541 INSTALADORES E MANTENEDORES ELETROMECÂNICOS DE ELEVADORES, ESCADAS E PORTAS AUTOMÁTICAS', descricao: 'INSTALADORES E MANTENEDORES ELETROMECÂNICOS DE ELEVADORES, ESCADAS E PORTAS AUTOMÁTICAS' },
  { id: '731320 Instaladores e reparadores de equipamentos e linhas telefônicas', descricao: 'Instaladores e reparadores de equipamentos e linhas telefônicas' },
  { id: '7321 INSTALADORES E REPARADORES DE LINHAS E CABOS ELÉTRICOS, TELEFÔNICOS E DE COMUNICAÇÃO DE', descricao: 'INSTALADORES E REPARADORES DE LINHAS E CABOS ELÉTRICOS, TELEFÔNICOS E DE COMUNICAÇÃO DE' },
  { id: '7313 INSTALADORES-REPARADORES DE LINHAS E EQUIPAMENTOS DE TELECOMUNICAÇÕES', descricao: 'INSTALADORES-REPARADORES DE LINHAS E EQUIPAMENTOS DE TELECOMUNICAÇÕES' },
  { id: '322225 Instrumentador cirúrgico', descricao: 'Instrumentador cirúrgico' },
  { id: '223520 Instrumentador cirúrgico (enfermeiro)', descricao: 'Instrumentador cirúrgico (enfermeiro)' },
  { id: '322225 Instrumentador em cirurgia', descricao: 'Instrumentador em cirurgia' },
  { id: '322225 Instrumentadora cirúrgica', descricao: 'Instrumentadora cirúrgica' },
  { id: '953105 Instrumentista de aviões', descricao: 'Instrumentista de aviões' },
  { id: '915105 Instrumentista de laboratório (manutenção)', descricao: 'Instrumentista de laboratório (manutenção)' },
  { id: '741105 Instrumentista de precisão', descricao: 'Instrumentista de precisão' },
  { id: '233205 Instrutor agrícola', descricao: 'Instrutor agrícola' },
  { id: '623005 Instrutor de animais', descricao: 'Instrutor de animais' },
  { id: '233220 Instrutor de aprendizagem agroindustrial e florestal', descricao: 'Instrutor de aprendizagem agroindustrial e florestal' },
  { id: '233205 Instrutor de aprendizagem agropecuária', descricao: 'Instrutor de aprendizagem agropecuária' },
  { id: '233215 Instrutor de aprendizagem comercial', descricao: 'Instrutor de aprendizagem comercial' },
  { id: '233205 Instrutor de aprendizagem e treinamento agropecuário', descricao: 'Instrutor de aprendizagem e treinamento agropecuário' },
  { id: '233215 Instrutor de aprendizagem e treinamento comercial', descricao: 'Instrutor de aprendizagem e treinamento comercial' },
  { id: '233210 Instrutor de aprendizagem e treinamento industrial', descricao: 'Instrutor de aprendizagem e treinamento industrial' },
  { id: '233225 Instrutor de aprendizagem em comunicação', descricao: 'Instrutor de aprendizagem em comunicação' },
  { id: '233225 Instrutor de aprendizagem em informática', descricao: 'Instrutor de aprendizagem em informática' },
  { id: '233225 Instrutor de aprendizagem em transportes', descricao: 'Instrutor de aprendizagem em transportes' },
  { id: '233210 Instrutor de aprendizagem industrial', descricao: 'Instrutor de aprendizagem industrial' },
  { id: '333105 Instrutor de auto-escola', descricao: 'Instrutor de auto-escola' },
  { id: '262615 Instrutor de banda', descricao: 'Instrutor de banda' },
  { id: '263105 Instrutor de curimba', descricao: 'Instrutor de curimba' },
  { id: '333110 Instrutor de cursos livres', descricao: 'Instrutor de cursos livres' },
  { id: '233210 Instrutor de educação profissional industrial', descricao: 'Instrutor de educação profissional industrial' },
  { id: '333110 Instrutor de equitação para equoterapia', descricao: 'Instrutor de equitação para equoterapia' },
  { id: '262615 Instrutor de fanfarra', descricao: 'Instrutor de fanfarra' },
  { id: '233225 Instrutor de informática', descricao: 'Instrutor de informática' },
  { id: '342310 Instrutor de motoristas', descricao: 'Instrutor de motoristas' },
  { id: '331305 Instrutor de nível médio no ensino profissionalizante', descricao: 'Instrutor de nível médio no ensino profissionalizante' },
  { id: '233210 Instrutor de ofícios industriais', descricao: 'Instrutor de ofícios industriais' },
  { id: '215315 Instrutor de pilotagem (aviação)', descricao: 'Instrutor de pilotagem (aviação)' },
  { id: '215315 Instrutor de pilotagem de aviões', descricao: 'Instrutor de pilotagem de aviões' },
  { id: '215315 Instrutor de pilotagem de helicópteros', descricao: 'Instrutor de pilotagem de helicópteros' },
  { id: '233205 Instrutor de treinamento agropecuário', descricao: 'Instrutor de treinamento agropecuário' },
  { id: '233215 Instrutor de treinamento comercial', descricao: 'Instrutor de treinamento comercial' },
  { id: '233210 Instrutor de treinamento industrial (formação profissional)', descricao: 'Instrutor de treinamento industrial (formação profissional)' },
  { id: '233210 Instrutor de treinamentos industriais', descricao: 'Instrutor de treinamentos industriais' },
  { id: '215315 Instrutor de vôo', descricao: 'Instrutor de vôo' },
  { id: '515305 Instrutor educacional', descricao: 'Instrutor educacional' },
  { id: '263105 Instrutor leigo de meditação budista', descricao: 'Instrutor leigo de meditação budista' },
  { id: '332205 Instrutor no ensino profissionalizante', descricao: 'Instrutor no ensino profissionalizante' },
  { id: '2332 INSTRUTORES DE ENSINO PROFISSIONAL', descricao: 'INSTRUTORES DE ENSINO PROFISSIONAL' },
  { id: '3331 INSTRUTORES E PROFESSORES DE CURSOS LIVRES', descricao: 'INSTRUTORES E PROFESSORES DE CURSOS LIVRES' },
  { id: '225150 Intensivista', descricao: 'Intensivista' },
  { id: '142350 Internacionalista', descricao: 'Internacionalista' },
  { id: '261410 Intérprete', descricao: 'Intérprete' },
  { id: '261410 Intérprete comercial', descricao: 'Intérprete comercial' },
  { id: '261410 Intérprete de comunicação eletrônica', descricao: 'Intérprete de comunicação eletrônica' },
  { id: '261410 Intérprete de conferência', descricao: 'Intérprete de conferência' },
  { id: '261425 Intérprete de libras', descricao: 'Intérprete de libras' },
  { id: '261425 Intérprete de língua de sinais', descricao: 'Intérprete de língua de sinais' },
  { id: '261425 Intérprete educacional', descricao: 'Intérprete educacional' },
  { id: '261410 Intérprete simultâneo', descricao: 'Intérprete simultâneo' },
  { id: '225355 Intervencionista', descricao: 'Intervencionista' },
  { id: '632115 Inventariante florestal', descricao: 'Inventariante florestal' },
  { id: '613110 Invernador - na criação de gado bovino - empregador', descricao: 'Invernador - na criação de gado bovino - empregador' },
  { id: '613005 Invernador de gado', descricao: 'Invernador de gado' },
  { id: '613005 Invernador de gado bovino, suíno, caprino, ovino - conta própria', descricao: 'Invernador de gado bovino, suíno, caprino, ovino - conta própria' },
  { id: '613110 Inverneiro - na criação de gado bovino - empregador', descricao: 'Inverneiro - na criação de gado bovino - empregador' },
  { id: '613110 Invernista - na criação de gado bovino - empregador', descricao: 'Invernista - na criação de gado bovino - empregador' },
  { id: '351810 Investigador de polícia', descricao: 'Investigador de polícia' },
  { id: '351805 Investigador particular', descricao: 'Investigador particular' },
  { id: '263105 Irmã', descricao: 'Irmã' },
  { id: '263105 Irmão', descricao: 'Irmão' },
  { id: '715720 Isolador refratarista (caldeira e tubulações)', descricao: 'Isolador refratarista (caldeira e tubulações)' },
  { id: '715715 Isolador térmico', descricao: 'Isolador térmico' },
  { id: '263105 Iyakekerê', descricao: 'Iyakekerê' },
  { id: '263105 Iyalorixá', descricao: 'Iyalorixá' },
  { id: '263105 Iyamorô', descricao: 'Iyamorô' },
  { id: '263105 Iyawo', descricao: 'Iyawo' },
  { id: '263105 Izadioncoé', descricao: 'Izadioncoé' },
  { id: '631310 Jacarecultor', descricao: 'Jacarecultor' },
  { id: '776405 Jacazeiro', descricao: 'Jacazeiro' },
  { id: '631020 Jangadeiro, na pesca de peixes e camarões', descricao: 'Jangadeiro, na pesca de peixes e camarões' },
  { id: '622010 Jardineiro', descricao: 'Jardineiro' },
  { id: '622010 Jardineiro (árvores para ornamentação urbana)', descricao: 'Jardineiro (árvores para ornamentação urbana)' },
  { id: '711405 Jateador - no garimpo', descricao: 'Jateador - no garimpo' },
  { id: '752225 Jateador de materiais abrasivos', descricao: 'Jateador de materiais abrasivos' },
  { id: '752225 Jateador de vidro', descricao: 'Jateador de vidro' },
  { id: '752410 Jateador de vidro não plano', descricao: 'Jateador de vidro não plano' },
  { id: '751010 Joalheiro', descricao: 'Joalheiro' },
  { id: '141410 Joalheiro (comércio varejista)', descricao: 'Joalheiro (comércio varejista)' },
  { id: '751015 Joalheiro (reparações)', descricao: 'Joalheiro (reparações)' },
  { id: '141205 Joalheiro - na fabricação - empregador', descricao: 'Joalheiro - na fabricação - empregador' },
  { id: '751010 Joalheiro na confecção de bijuterias e jóias de fantasia', descricao: 'Joalheiro na confecção de bijuterias e jóias de fantasia' },
  { id: '751010 Joalheiro na fabricação', descricao: 'Joalheiro na fabricação' },
  { id: '751015 Joalheiro na reparação', descricao: 'Joalheiro na reparação' },
  { id: '751010 Joalheiro ourives na fabricação e reparação', descricao: 'Joalheiro ourives na fabricação e reparação' },
  { id: '7510 JOALHEIROS E LAPIDADORES DE GEMAS', descricao: 'JOALHEIROS E LAPIDADORES DE GEMAS' },
  { id: '377120 Jodoísta', descricao: 'Jodoísta' },
  { id: '377110 Jogador de futebol', descricao: 'Jogador de futebol' },
  { id: '377115 Jogador de golfe profissional', descricao: 'Jogador de golfe profissional' },
  { id: '377125 Jogador de tênis profissional', descricao: 'Jogador de tênis profissional' },
  { id: '377125 Jogador profissional de tênis', descricao: 'Jogador profissional de tênis' },
  { id: '377130 Jóquei', descricao: 'Jóquei' },
  { id: '377130 Jóquei profissional', descricao: 'Jóquei profissional' },
  { id: '524305 Jornaleiro (ambulante)', descricao: 'Jornaleiro (ambulante)' },
  { id: '524210 Jornaleiro (em banca de jornal)', descricao: 'Jornaleiro (em banca de jornal)' },
  { id: '261125 Jornalista', descricao: 'Jornalista' },
  { id: '261125 Jornalista exclusive empregador', descricao: 'Jornalista exclusive empregador' },
  { id: '261125 Jornalista-empregador', descricao: 'Jornalista-empregador' },
  { id: '377120 Judoca', descricao: 'Judoca' },
  { id: '241405 Juiz (administrativo)', descricao: 'Juiz (administrativo)' },
  { id: '111340 Juiz auditor estadual - justiça militar', descricao: 'Juiz auditor estadual - justiça militar' },
  { id: '111335 Juiz auditor federal - justiça militar', descricao: 'Juiz auditor federal - justiça militar' },
  { id: '377235 Juiz auxiliar (karatê)', descricao: 'Juiz auxiliar (karatê)' },
  { id: '111325 Juiz de alçada', descricao: 'Juiz de alçada' },
  { id: '111325 Juiz de direito', descricao: 'Juiz de direito' },
  { id: '111325 Juiz de direito de primeira instância', descricao: 'Juiz de direito de primeira instância' },
  { id: '377240 Juiz de gol (poloaquático)', descricao: 'Juiz de gol (poloaquático)' },
  { id: '241405 Juiz de impostos e tributos', descricao: 'Juiz de impostos e tributos' },
  { id: '377245 Juiz de linha (vôlei)', descricao: 'Juiz de linha (vôlei)' },
  { id: '111345 Juiz do trabalho', descricao: 'Juiz do trabalho' },
  { id: '263115 Juiz do tribunal eclesiástico', descricao: 'Juiz do tribunal eclesiástico' },
  { id: '111320 Juiz do tribunal regional do trabalho', descricao: 'Juiz do tribunal regional do trabalho' },
  { id: '111330 Juiz do tribunal regional federal', descricao: 'Juiz do tribunal regional federal' },
  { id: '111340 Juiz dos tribunais militares estaduais', descricao: 'Juiz dos tribunais militares estaduais' },
  { id: '111330 Juiz federal', descricao: 'Juiz federal' },
  { id: '111320 Juiz federal de segunda instância', descricao: 'Juiz federal de segunda instância' },
  { id: '632325 Juntador - na extração da carnaúba', descricao: 'Juntador - na extração da carnaúba' },
  { id: '241040 Jurisconsulto', descricao: 'Jurisconsulto' },
  { id: '241040 Jurista', descricao: 'Jurista' },
  { id: '263110 Jushoku', descricao: 'Jushoku' },
  { id: '612215 Juteiro - conta própria', descricao: 'Juteiro - conta própria' },
  { id: '612215 Juticultor - conta própria', descricao: 'Juticultor - conta própria' },
  { id: '263110 Kaikyôshi', descricao: 'Kaikyôshi' },
  { id: '263105 Kambondo pokó', descricao: 'Kambondo pokó' },
  { id: '263105 Kantoku (diretor de missão)', descricao: 'Kantoku (diretor de missão)' },
  { id: '377120 Karateca', descricao: 'Karateca' },
  { id: '415125 Kardexista', descricao: 'Kardexista' },
  { id: '263105 Kunhã-karaí', descricao: 'Kunhã-karaí' },
  { id: '263105 Kyôshi (mestre)', descricao: 'Kyôshi (mestre)' },
  { id: '761306 Labirinteira (rendas e bordados)', descricao: 'Labirinteira (rendas e bordados)' },
  { id: '768205 Labirinteira bordadeira, a mão.', descricao: 'Labirinteira bordadeira, a mão.' },
  { id: '301110 Laboratorista (ensaios de areia)', descricao: 'Laboratorista (ensaios de areia)' },
  { id: '301110 Laboratorista (solos e materiais de construção)', descricao: 'Laboratorista (solos e materiais de construção)' },
  { id: '301105 Laboratorista - exclusive análises clínicas', descricao: 'Laboratorista - exclusive análises clínicas' },
  { id: '311515 Laboratorista de água e controle ambiental', descricao: 'Laboratorista de água e controle ambiental' },
  { id: '301110 Laboratorista de concreto', descricao: 'Laboratorista de concreto' },
  { id: '301110 Laboratorista de ensaios de areia de fundição', descricao: 'Laboratorista de ensaios de areia de fundição' },
  { id: '313215 Laboratorista de ensaios eletrônicos', descricao: 'Laboratorista de ensaios eletrônicos' },
  { id: '301105 Laboratorista de ensaios mecânicos', descricao: 'Laboratorista de ensaios mecânicos' },
  { id: '301105 Laboratorista de ensaios químicos', descricao: 'Laboratorista de ensaios químicos' },
  { id: '766410 Laboratorista de filmes fotográficos, em preto e branco', descricao: 'Laboratorista de filmes fotográficos, em preto e branco' },
  { id: '301110 Laboratorista de materiais de construção', descricao: 'Laboratorista de materiais de construção' },
  { id: '301110 Laboratorista de solos', descricao: 'Laboratorista de solos' },
  { id: '766405 Laboratorista fotográfico', descricao: 'Laboratorista fotográfico' },
  { id: '301105 Laboratorista industrial', descricao: 'Laboratorista industrial' },
  { id: '314620 Laboratorista metalográfico (soldagem)', descricao: 'Laboratorista metalográfico (soldagem)' },
  { id: '301105 Laboratorista químico', descricao: 'Laboratorista químico' },
  { id: '301105 Laboratorista têxtil', descricao: 'Laboratorista têxtil' },
  { id: '8181 LABORATORISTAS INDUSTRIAIS AUXILIARES', descricao: 'LABORATORISTAS INDUSTRIAIS AUXILIARES' },
  { id: '724605 Laceiro de cabos de aço', descricao: 'Laceiro de cabos de aço' },
  { id: '784110 Lacrador de embalagens, a máquina', descricao: 'Lacrador de embalagens, a máquina' },
  { id: '716510 Ladrilheiro', descricao: 'Ladrilheiro' },
  { id: '841448 Lagareiro', descricao: 'Lagareiro' },
  { id: '631015 Lagosteiro', descricao: 'Lagosteiro' },
  { id: '263105 Lama budista tibetano', descricao: 'Lama budista tibetano' },
  { id: '263110 Lama tibetano', descricao: 'Lama tibetano' },
  { id: '773105 Laminador de madeira', descricao: 'Laminador de madeira' },
  { id: '841810 Laminador de massas alimentícias', descricao: 'Laminador de massas alimentícias' },
  { id: '821320 Laminador de metais não-ferrosos', descricao: 'Laminador de metais não-ferrosos' },
  { id: '751120 Laminador de metais preciosos', descricao: 'Laminador de metais preciosos' },
  { id: '751120 Laminador de metais preciosos a mão', descricao: 'Laminador de metais preciosos a mão' },
  { id: '751105 Laminador de metais preciosos, à máquina', descricao: 'Laminador de metais preciosos, à máquina' },
  { id: '751120 Laminador de ouro', descricao: 'Laminador de ouro' },
  { id: '811745 Laminador de plástico', descricao: 'Laminador de plástico' },
  { id: '751120 Laminador de prata', descricao: 'Laminador de prata' },
  { id: '766310 Laminador gráfico', descricao: 'Laminador gráfico' },
  { id: '991305 Lanterneiro de automóveis (reparação)', descricao: 'Lanterneiro de automóveis (reparação)' },
  { id: '519945 Lanterninha', descricao: 'Lanterninha' },
  { id: '751020 Lapidador (jóias)', descricao: 'Lapidador (jóias)' },
  { id: '752235 Lapidador de cristais de ótica', descricao: 'Lapidador de cristais de ótica' },
  { id: '751020 Lapidador de diamantes', descricao: 'Lapidador de diamantes' },
  { id: '751020 Lapidador de pedras preciosas', descricao: 'Lapidador de pedras preciosas' },
  { id: '751020 Lapidador de pedras semi-preciosas', descricao: 'Lapidador de pedras semi-preciosas' },
  { id: '752410 Lapidador de vidros (acabamento)', descricao: 'Lapidador de vidros (acabamento)' },
  { id: '752230 Lapidador de vidros e cristais', descricao: 'Lapidador de vidros e cristais' },
  { id: '751020 Lapidário', descricao: 'Lapidário' },
  { id: '751020 Lapidário de diamantes', descricao: 'Lapidário de diamantes' },
  { id: '751020 Lapidário de pedras preciosas', descricao: 'Lapidário de pedras preciosas' },
  { id: '751020 Lapidário de pedras semi-preciosas', descricao: 'Lapidário de pedras semi-preciosas' },
  { id: '775115 Laqueador de madeira', descricao: 'Laqueador de madeira' },
  { id: '775115 Laqueador de móveis', descricao: 'Laqueador de móveis' },
  { id: '632325 Lasteiro - na extração da carnaúba', descricao: 'Lasteiro - na extração da carnaúba' },
  { id: '991305 Latoeiro de veículos (reparação)', descricao: 'Latoeiro de veículos (reparação)' },
  { id: '516305 Lavadeiro, em geral', descricao: 'Lavadeiro, em geral' },
  { id: '516315 Lavador de artefatos de tapeçaria', descricao: 'Lavador de artefatos de tapeçaria' },
  { id: '519935 Lavador de automóveis', descricao: 'Lavador de automóveis' },
  { id: '519935 Lavador de carros', descricao: 'Lavador de carros' },
  { id: '514305 Lavador de fachadas', descricao: 'Lavador de fachadas' },
  { id: '761110 Lavador de fibras na indústria têxtil', descricao: 'Lavador de fibras na indústria têxtil' },
  { id: '519930 Lavador de garrafas, vidros e outros utensílios', descricao: 'Lavador de garrafas, vidros e outros utensílios' },
  { id: '761110 Lavador de lã', descricao: 'Lavador de lã' },
  { id: '519935 Lavador de ônibus', descricao: 'Lavador de ônibus' },
  { id: '992120 Lavador de peças', descricao: 'Lavador de peças' },
  { id: '513505 Lavador de pratos', descricao: 'Lavador de pratos' },
  { id: '516405 Lavador de roupa a seco', descricao: 'Lavador de roupa a seco' },
  { id: '516310 Lavador de roupa hospitalar', descricao: 'Lavador de roupa hospitalar' },
  { id: '516405 Lavador de roupas', descricao: 'Lavador de roupas' },
  { id: '516310 Lavador de roupas a maquina', descricao: 'Lavador de roupas a maquina' },
  { id: '516310 Lavador de roupas à maquina industrial', descricao: 'Lavador de roupas à maquina industrial' },
  { id: '516315 Lavador de tapetes', descricao: 'Lavador de tapetes' },
  { id: '519935 Lavador de veículos', descricao: 'Lavador de veículos' },
  { id: '514305 Lavador de vidros', descricao: 'Lavador de vidros' },
  { id: '5164 LAVADORES E PASSADORES DE ROUPA, A MÃO', descricao: 'LAVADORES E PASSADORES DE ROUPA, A MÃO' },
  { id: '612105 Lavoureiro na produção de arroz', descricao: 'Lavoureiro na produção de arroz' },
  { id: '612115 Lavoureiro na produção de cereais de inverno', descricao: 'Lavoureiro na produção de cereais de inverno' },
  { id: '612125 Lavoureiro na produção de milho e sorgo', descricao: 'Lavoureiro na produção de milho e sorgo' },
  { id: '622020 Lavrador - na cultura - exclusive conta própria e empregador', descricao: 'Lavrador - na cultura - exclusive conta própria e empregador' },
  { id: '622805 Lavrador da cultura de especiarias', descricao: 'Lavrador da cultura de especiarias' },
  { id: '622020 Lavrador de cultura permanente - exclusive conta própria e empregador', descricao: 'Lavrador de cultura permanente - exclusive conta própria e empregador' },
  { id: '622020 Lavrador de cultura temporária - exclusive conta própria e empregador', descricao: 'Lavrador de cultura temporária - exclusive conta própria e empregador' },
  { id: '622020 Lavrador na horticultura e na floricultura - exclusive conta própria e empregador', descricao: 'Lavrador na horticultura e na floricultura - exclusive conta própria e empregador' },
  { id: '1111 LEGISLADORES', descricao: 'LEGISLADORES' },
  { id: '225106 Legista', descricao: 'Legista' },
  { id: '318710 Leiautista de circuitos impressos', descricao: 'Leiautista de circuitos impressos' },
  { id: '263115 Leigo consagrado', descricao: 'Leigo consagrado' },
  { id: '354405 Leiloeiro', descricao: 'Leiloeiro' },
  { id: '3544 LEILOEIROS E AVALIADORES', descricao: 'LEILOEIROS E AVALIADORES' },
  { id: '524305 Leiteiro (comércio ambulante)', descricao: 'Leiteiro (comércio ambulante)' },
  { id: '613115 Leiteiro - na criação de gado bovino - empregador', descricao: 'Leiteiro - na criação de gado bovino - empregador' },
  { id: '519940 Leitor de hidrômetro', descricao: 'Leitor de hidrômetro' },
  { id: '519940 Leitor de medidores de água, gás e energia elétrica', descricao: 'Leitor de medidores de água, gás e energia elétrica' },
  { id: '516805 Leitor de oráculos', descricao: 'Leitor de oráculos' },
  { id: '519940 Leiturista', descricao: 'Leiturista' },
  { id: '141410 Lenheiro ( comércio varejista)', descricao: 'Lenheiro ( comércio varejista)' },
  { id: '261525 Letrista', descricao: 'Letrista' },
  { id: '261515 Letrista (música)', descricao: 'Letrista (música)' },
  { id: '711410 Levantador de sal', descricao: 'Levantador de sal' },
  { id: '261415 Lexicógrafo', descricao: 'Lexicógrafo' },
  { id: '261415 Lexicólogo', descricao: 'Lexicólogo' },
  { id: '261515 Libretista', descricao: 'Libretista' },
  { id: '841720 Licorista', descricao: 'Licorista' },
  { id: '720220 Líder (construção de fornos)', descricao: 'Líder (construção de fornos)' },
  { id: '720210 Líder (indústria de automotores e de material de transporte)', descricao: 'Líder (indústria de automotores e de material de transporte)' },
  { id: '630110 Líder da área florestal', descricao: 'Líder da área florestal' },
  { id: '821245 Líder de área de corrida', descricao: 'Líder de área de corrida' },
  { id: '510305 Líder de bombeiros', descricao: 'Líder de bombeiros' },
  { id: '113010 Líder de comunidade caiçara', descricao: 'Líder de comunidade caiçara' },
  { id: '910905 Líder de conservação de vias permanentes', descricao: 'Líder de conservação de vias permanentes' },
  { id: '821255 Líder de convertedor', descricao: 'Líder de convertedor' },
  { id: '721105 Líder de ferramentaria', descricao: 'Líder de ferramentaria' },
  { id: '821440 Líder de linhas de tesoura de tiras a quente e a frio', descricao: 'Líder de linhas de tesoura de tiras a quente e a frio' },
  { id: '821440 Líder de linhas de tesoura, no acabamento de chapas e metais (tiras a frio)', descricao: 'Líder de linhas de tesoura, no acabamento de chapas e metais (tiras a frio)' },
  { id: '910910 Líder de lubrificação de vias permanentes', descricao: 'Líder de lubrificação de vias permanentes' },
  { id: '911305 Líder de manutenção mecânica', descricao: 'Líder de manutenção mecânica' },
  { id: '821405 Líder de produção, no acabamento de chapas e metais', descricao: 'Líder de produção, no acabamento de chapas e metais' },
  { id: '821240 Líder de recebimento de gusa', descricao: 'Líder de recebimento de gusa' },
  { id: '420125 Líder de recepção', descricao: 'Líder de recepção' },
  { id: '114105 Líder partidário', descricao: 'Líder partidário' },
  { id: '950205 Líder produtivo de manutenção eletroeletrônica veicular', descricao: 'Líder produtivo de manutenção eletroeletrônica veicular' },
  { id: '113015 Líder quilombola', descricao: 'Líder quilombola' },
  { id: '732135 Ligador de linhas telefônicas', descricao: 'Ligador de linhas telefônicas' },
  { id: '516320 Limpador a seco, à máquina', descricao: 'Limpador a seco, à máquina' },
  { id: '848525 Limpador de aves', descricao: 'Limpador de aves' },
  { id: '519935 Limpador de aviões, bondes, coletivos, ônibus e trens', descricao: 'Limpador de aviões, bondes, coletivos, ônibus e trens' },
  { id: '848525 Limpador de carne', descricao: 'Limpador de carne' },
  { id: '514315 Limpador de fachadas', descricao: 'Limpador de fachadas' },
  { id: '514315 Limpador de fachadas com jato', descricao: 'Limpador de fachadas com jato' },
  { id: '841420 Limpador de frutas e legumes para conserva', descricao: 'Limpador de frutas e legumes para conserva' },
  { id: '514305 Limpador de janelas', descricao: 'Limpador de janelas' },
  { id: '821450 Limpador de metais', descricao: 'Limpador de metais' },
  { id: '621005 Limpador de pasto - na agropecuária', descricao: 'Limpador de pasto - na agropecuária' },
  { id: '632365 Limpador de piaçava', descricao: 'Limpador de piaçava' },
  { id: '514330 Limpador de piscinas', descricao: 'Limpador de piscinas' },
  { id: '516410 Limpador de roupas a seco, à mão', descricao: 'Limpador de roupas a seco, à mão' },
  { id: '848525 Limpador de tripas', descricao: 'Limpador de tripas' },
  { id: '514305 Limpador de vidros', descricao: 'Limpador de vidros' },
  { id: '722210 Lingotador', descricao: 'Lingotador' },
  { id: '848115 Lingüiceiros na conservação de alimentos', descricao: 'Lingüiceiros na conservação de alimentos' },
  { id: '261415 Lingüista', descricao: 'Lingüista' },
  { id: '261415 Lingüista dicionarista', descricao: 'Lingüista dicionarista' },
  { id: '768610 Linotipista', descricao: 'Linotipista' },
  { id: '141410 Livreiro (comércio varejista)', descricao: 'Livreiro (comércio varejista)' },
  { id: '764120 Lixador de calçados', descricao: 'Lixador de calçados' },
  { id: '762315 Lixador de couros e peles', descricao: 'Lixador de couros e peles' },
  { id: '721325 Lixador de móveis metálicos', descricao: 'Lixador de móveis metálicos' },
  { id: '721325 Lixador de peças de metal', descricao: 'Lixador de peças de metal' },
  { id: '764120 Lixador de saltos de calçados', descricao: 'Lixador de saltos de calçados' },
  { id: '764120 Lixador de solas na confecção de calçados', descricao: 'Lixador de solas na confecção de calçados' },
  { id: '514205 Lixeiro', descricao: 'Lixeiro' },
  { id: '141410 Locador (serviços de diversões)', descricao: 'Locador (serviços de diversões)' },
  { id: '141410 Locador de animais para lazer', descricao: 'Locador de animais para lazer' },
  { id: '141410 Locador de barco para lazer', descricao: 'Locador de barco para lazer' },
  { id: '141410 Locador de bicicletas para lazer', descricao: 'Locador de bicicletas para lazer' },
  { id: '141410 Locador de bóia para lazer', descricao: 'Locador de bóia para lazer' },
  { id: '141410 Locador de cadeira de praia', descricao: 'Locador de cadeira de praia' },
  { id: '141410 Locador de cadeira para festa', descricao: 'Locador de cadeira para festa' },
  { id: '141410 Locador de caiaque para lazer', descricao: 'Locador de caiaque para lazer' },
  { id: '141410 Locador de eletrodomésticos', descricao: 'Locador de eletrodomésticos' },
  { id: '141410 Locador de mesas para festa', descricao: 'Locador de mesas para festa' },
  { id: '141410 Locador de móveis', descricao: 'Locador de móveis' },
  { id: '141410 Locador de patins para lazer', descricao: 'Locador de patins para lazer' },
  { id: '141410 Locador de pedalinho para lazer', descricao: 'Locador de pedalinho para lazer' },
  { id: '141410 Locador de televisão', descricao: 'Locador de televisão' },
  { id: '421315 Localizador (cobrador)', descricao: 'Localizador (cobrador)' },
  { id: '421315 Localizador externo', descricao: 'Localizador externo' },
  { id: '261715 Locutor anunciador', descricao: 'Locutor anunciador' },
  { id: '261715 Locutor apresentador animador', descricao: 'Locutor apresentador animador' },
  { id: '261715 Locutor comercial', descricao: 'Locutor comercial' },
  { id: '261715 Locutor de chamadas (promocionais e institucionais)', descricao: 'Locutor de chamadas (promocionais e institucionais)' },
  { id: '261715 Locutor de mídias audiovisuais', descricao: 'Locutor de mídias audiovisuais' },
  { id: '261715 Locutor de notícias', descricao: 'Locutor de notícias' },
  { id: '261715 Locutor de rádio e tv', descricao: 'Locutor de rádio e tv' },
  { id: '376310 Locutor de rodeio', descricao: 'Locutor de rodeio' },
  { id: '261715 Locutor de telejornal', descricao: 'Locutor de telejornal' },
  { id: '261715 Locutor esportivo', descricao: 'Locutor esportivo' },
  { id: '261715 Locutor noticiarista', descricao: 'Locutor noticiarista' },
  { id: '261715 Locutor publicitário', descricao: 'Locutor publicitário' },
  { id: '261715 Locutor-operador', descricao: 'Locutor-operador' },
  { id: '2617 LOCUTORES, COMENTARISTAS E REPÓRTERES DE MÍDIAS AUDIOVISUAIS', descricao: 'LOCUTORES, COMENTARISTAS E REPÓRTERES DE MÍDIAS AUDIOVISUAIS' },
  { id: '141405 Lojista (comércio atacadista)', descricao: 'Lojista (comércio atacadista)' },
  { id: '141410 Lojista (comércio varejista)', descricao: 'Lojista (comércio varejista)' },
  { id: '848520 Lombador em matadouro', descricao: 'Lombador em matadouro' },
  { id: '141205 Lombilheiro - empregador', descricao: 'Lombilheiro - empregador' },
  { id: '919105 Lubrificador auxiliar mecânico', descricao: 'Lubrificador auxiliar mecânico' },
  { id: '919110 Lubrificador de automóveis', descricao: 'Lubrificador de automóveis' },
  { id: '919115 Lubrificador de embarcações', descricao: 'Lubrificador de embarcações' },
  { id: '919105 Lubrificador de máquinas', descricao: 'Lubrificador de máquinas' },
  { id: '919110 Lubrificador de máquinas de terraplenagem', descricao: 'Lubrificador de máquinas de terraplenagem' },
  { id: '919110 Lubrificador de veículos automotores (exceto embarcações)', descricao: 'Lubrificador de veículos automotores (exceto embarcações)' },
  { id: '919105 Lubrificador industrial', descricao: 'Lubrificador industrial' },
  { id: '919115 Lubrificador naval', descricao: 'Lubrificador naval' },
  { id: '9191 LUBRIFICADORES', descricao: 'LUBRIFICADORES' },
  { id: '224110 Ludomotricista', descricao: 'Ludomotricista' },
  { id: '519915 Lustrador - engraxate', descricao: 'Lustrador - engraxate' },
  { id: '775115 Lustrador de madeira', descricao: 'Lustrador de madeira' },
  { id: '775115 Lustrador de móveis', descricao: 'Lustrador de móveis' },
  { id: '775115 Lustrador de peças de madeira', descricao: 'Lustrador de peças de madeira' },
  { id: '716520 Lustrador de piso', descricao: 'Lustrador de piso' },
  { id: '764305 Lustrador no acabamento de calçados', descricao: 'Lustrador no acabamento de calçados' },
  { id: '377120 Lutador de aikidô', descricao: 'Lutador de aikidô' },
  { id: '377120 Lutador de capoeira', descricao: 'Lutador de capoeira' },
  { id: '377120 Lutador de fullcontact', descricao: 'Lutador de fullcontact' },
  { id: '377120 Lutador de hapkidô', descricao: 'Lutador de hapkidô' },
  { id: '377120 Lutador de karatê', descricao: 'Lutador de karatê' },
  { id: '377120 Lutador de kendô', descricao: 'Lutador de kendô' },
  { id: '377120 Lutador de sumô', descricao: 'Lutador de sumô' },
  { id: '377120 Lutador de taekwondô', descricao: 'Lutador de taekwondô' },
  { id: '377145 Lutador profissional de boxe', descricao: 'Lutador profissional de boxe' },
  { id: '742115 Luthier (confeccionador)', descricao: 'Luthier (confeccionador)' },
  { id: '915215 Luthier (restauração de cordas arcadas)', descricao: 'Luthier (restauração de cordas arcadas)' },
  { id: '711115 Macaqueiro - na extração de pedras', descricao: 'Macaqueiro - na extração de pedras' },
  { id: '632210 Maçarandubeiro', descricao: 'Maçarandubeiro' },
  { id: '711230 Maçariqueiro', descricao: 'Maçariqueiro' },
  { id: '711110 Maçariqueiro - na mineração', descricao: 'Maçariqueiro - na mineração' },
  { id: '761240 Maçaroqueiro', descricao: 'Maçaroqueiro' },
  { id: '848315 Macarroneiro', descricao: 'Macarroneiro' },
  { id: '722310 Macheiro, a máquina', descricao: 'Macheiro, a máquina' },
  { id: '722305 Macheiro, a mão', descricao: 'Macheiro, a mão' },
  { id: '141405 Madeireiro (comércio atacadista)', descricao: 'Madeireiro (comércio atacadista)' },
  { id: '141410 Madeireiro (comércio varejista)', descricao: 'Madeireiro (comércio varejista)' },
  { id: '711125 Madeireiro de subsolo - na mineração', descricao: 'Madeireiro de subsolo - na mineração' },
  { id: '263105 Madre superiora', descricao: 'Madre superiora' },
  { id: '263105 Madrinha de umbanda', descricao: 'Madrinha de umbanda' },
  { id: '782815 Madrinheiro', descricao: 'Madrinheiro' },
  { id: '516215 Mãe crecheira', descricao: 'Mãe crecheira' },
  { id: '516215 Mãe social', descricao: 'Mãe social' },
  { id: '516215 Mãe substituta', descricao: 'Mãe substituta' },
  { id: '262615 Maestro', descricao: 'Maestro' },
  { id: '262615 Maestro correpetidor', descricao: 'Maestro correpetidor' },
  { id: '262615 Maestro de banda', descricao: 'Maestro de banda' },
  { id: '848520 Magarefe', descricao: 'Magarefe' },
  { id: '8485 MAGAREFES E AFINS', descricao: 'MAGAREFES E AFINS' },
  { id: '376235 Mágico', descricao: 'Mágico' },
  { id: '1113 MAGISTRADOS', descricao: 'MAGISTRADOS' },
  { id: '510135 Maître', descricao: 'Maître' },
  { id: '510135 Maître - no serviço de alimentação', descricao: 'Maître - no serviço de alimentação' },
  { id: '262830 Maître de ballet', descricao: 'Maître de ballet' },
  { id: '510135 Maître de bar', descricao: 'Maître de bar' },
  { id: '510135 Maître de boate', descricao: 'Maître de boate' },
  { id: '510135 Maître de hotel', descricao: 'Maître de hotel' },
  { id: '510135 Maître de restaurante', descricao: 'Maître de restaurante' },
  { id: '510135 Maître executivo', descricao: 'Maître executivo' },
  { id: '30110 Major bombeiro militar', descricao: 'Major bombeiro militar' },
  { id: '10205 Major da aeronáutica', descricao: 'Major da aeronáutica' },
  { id: '20115 Major da polícia militar', descricao: 'Major da polícia militar' },
  { id: '30110 Major do corpo de bombeiros militar', descricao: 'Major do corpo de bombeiros militar' },
  { id: '10210 Major do exército', descricao: 'Major do exército' },
  { id: '10105 Major-brigadeiro', descricao: 'Major-brigadeiro' },
  { id: '376240 Malabarista', descricao: 'Malabarista' },
  { id: '141410 Maleiro (comércio varejista )', descricao: 'Maleiro (comércio varejista )' },
  { id: '841725 Malteiro (germinação)', descricao: 'Malteiro (germinação)' },
  { id: '263105 Mameto ndenge', descricao: 'Mameto ndenge' },
  { id: '263105 Mameto nkisi', descricao: 'Mameto nkisi' },
  { id: '768120 Mamucabeira', descricao: 'Mamucabeira' },
  { id: '376250 Mamulengueiro', descricao: 'Mamulengueiro' },
  { id: '721215 Mandrilador', descricao: 'Mandrilador' },
  { id: '721415 Mandrilador cnc', descricao: 'Mandrilador cnc' },
  { id: '376410 Manequim', descricao: 'Manequim' },
  { id: '516120 Manicure', descricao: 'Manicure' },
  { id: '516120 Manicuro', descricao: 'Manicuro' },
  { id: '828105 Manilheiro - na fabricação', descricao: 'Manilheiro - na fabricação' },
  { id: '376235 Manipulador', descricao: 'Manipulador' },
  { id: '376250 Manipulador de bonecos', descricao: 'Manipulador de bonecos' },
  { id: '415210 Manipulador de correspondências (correios e telégrafos)', descricao: 'Manipulador de correspondências (correios e telégrafos)' },
  { id: '842105 Manipulador de essência e melado (fumo)', descricao: 'Manipulador de essência e melado (fumo)' },
  { id: '862150 Manipulador de máquinas fixas', descricao: 'Manipulador de máquinas fixas' },
  { id: '841720 Manipulador de misturas (vinhos)', descricao: 'Manipulador de misturas (vinhos)' },
  { id: '823230 Manipulador de moldados (abrasivos)', descricao: 'Manipulador de moldados (abrasivos)' },
  { id: '811810 Manipulador de seção de comprimidos e drágeas', descricao: 'Manipulador de seção de comprimidos e drágeas' },
  { id: '325110 Manipulador em laboratório de farmácia', descricao: 'Manipulador em laboratório de farmácia' },
  { id: '415210 Manipulador postal', descricao: 'Manipulador postal' },
  { id: '783110 Manobrador', descricao: 'Manobrador' },
  { id: '783110 Manobrador de ferrovia', descricao: 'Manobrador de ferrovia' },
  { id: '783110 Manobrador de trem', descricao: 'Manobrador de trem' },
  { id: '341240 Manobras e reparos', descricao: 'Manobras e reparos' },
  { id: '783110 Manobreiro de ferrovia', descricao: 'Manobreiro de ferrovia' },
  { id: '783110 Manobreiro de trem', descricao: 'Manobreiro de trem' },
  { id: '514110 Manobrista', descricao: 'Manobrista' },
  { id: '783110 Manobrista de ferrovia', descricao: 'Manobrista de ferrovia' },
  { id: '783110 Manobrista de trem', descricao: 'Manobrista de trem' },
  { id: '782510 Manobrista de veículos pesados sobre rodas', descricao: 'Manobrista de veículos pesados sobre rodas' },
  { id: '623015 Manoseador', descricao: 'Manoseador' },
  { id: '848110 Manteador na conservação de alimentos', descricao: 'Manteador na conservação de alimentos' },
  { id: '848215 Manteigueiro na fabricação de laticínio', descricao: 'Manteigueiro na fabricação de laticínio' },
  { id: '991205 Mantenedor de equipamentos de parques de diversões e similares', descricao: 'Mantenedor de equipamentos de parques de diversões e similares' },
  { id: '951310 Mantenedor de sistemas eletroeletrônicos de segurança', descricao: 'Mantenedor de sistemas eletroeletrônicos de segurança' },
  { id: '811005 Mantenedor operacional de processos químicos e petroquímicos', descricao: 'Mantenedor operacional de processos químicos e petroquímicos' },
  { id: '9914 MANTENEDORES DE EDIFICAÇÕES', descricao: 'MANTENEDORES DE EDIFICAÇÕES' },
  { id: '9912 MANTENEDORES DE EQUIPAMENTOS DE PARQUES DE DIVERSÕES E SIMILARES', descricao: 'MANTENEDORES DE EQUIPAMENTOS DE PARQUES DE DIVERSÕES E SIMILARES' },
  { id: '515110 Maqueiro de hospital', descricao: 'Maqueiro de hospital' },
  { id: '515110 Maqueiro hospitalar', descricao: 'Maqueiro hospitalar' },
  { id: '771115 Maquetista na marcenaria', descricao: 'Maquetista na marcenaria' },
  { id: '516125 Maquiador', descricao: 'Maquiador' },
  { id: '516130 Maquiador artístico', descricao: 'Maquiador artístico' },
  { id: '516130 Maquiador de caracterização', descricao: 'Maquiador de caracterização' },
  { id: '516130 Maquiador de cinema, teatro e tv', descricao: 'Maquiador de cinema, teatro e tv' },
  { id: '516125 Maquiador social', descricao: 'Maquiador social' },
  { id: '516125 Maquilador', descricao: 'Maquilador' },
  { id: '374215 Maquinista de cenário', descricao: 'Maquinista de cenário' },
  { id: '374210 Maquinista de cinema e vídeo', descricao: 'Maquinista de cinema e vídeo' },
  { id: '761225 Maquinista de conicaleira', descricao: 'Maquinista de conicaleira' },
  { id: '862110 Maquinista de embarcações', descricao: 'Maquinista de embarcações' },
  { id: '761230 Maquinista de filatório', descricao: 'Maquinista de filatório' },
  { id: '722220 Maquinista de fundição (centrífuga)', descricao: 'Maquinista de fundição (centrífuga)' },
  { id: '782610 Maquinista de locomotiva', descricao: 'Maquinista de locomotiva' },
  { id: '783110 Maquinista de manobras', descricao: 'Maquinista de manobras' },
  { id: '862150 Maquinista de máquina fixa', descricao: 'Maquinista de máquina fixa' },
  { id: '766215 Maquinista de ofsete', descricao: 'Maquinista de ofsete' },
  { id: '711320 Maquinista de perfuradora de subsolo', descricao: 'Maquinista de perfuradora de subsolo' },
  { id: '374215 Maquinista de teatro e espetáculos', descricao: 'Maquinista de teatro e espetáculos' },
  { id: '782610 Maquinista de trem', descricao: 'Maquinista de trem' },
  { id: '782615 Maquinista de trem metropolitano', descricao: 'Maquinista de trem metropolitano' },
  { id: '861115 Maquinista de usina termoelétrica', descricao: 'Maquinista de usina termoelétrica' },
  { id: '782120 Maquinista ferroviário', descricao: 'Maquinista ferroviário' },
  { id: '862105 Maquinista foguista', descricao: 'Maquinista foguista' },
  { id: '711405 Maraqueiro - no garimpo', descricao: 'Maraqueiro - no garimpo' },
  { id: '377140 Maratonista', descricao: 'Maratonista' },
  { id: '632105 Marcador de árvores', descricao: 'Marcador de árvores' },
  { id: '784115 Marcador de caixas', descricao: 'Marcador de caixas' },
  { id: '724415 Marcador de chapas', descricao: 'Marcador de chapas' },
  { id: '784115 Marcador de embalagem', descricao: 'Marcador de embalagem' },
  { id: '784115 Marcador de fardos', descricao: 'Marcador de fardos' },
  { id: '763315 Marcador de peças confeccionadas para bordar', descricao: 'Marcador de peças confeccionadas para bordar' },
  { id: '784115 Marcador de preços', descricao: 'Marcador de preços' },
  { id: '821415 Marcador de produtos (siderúrgico e metalúrgico)', descricao: 'Marcador de produtos (siderúrgico e metalúrgico)' },
  { id: '764120 Marcador de sola na confecção de calçados', descricao: 'Marcador de sola na confecção de calçados' },
  { id: '632105 Marcador de toras', descricao: 'Marcador de toras' },
  { id: '771105 Marceneiro', descricao: 'Marceneiro' },
  { id: '771120 Marceneiro adueleiro', descricao: 'Marceneiro adueleiro' },
  { id: '771120 Marceneiro barriqueiro', descricao: 'Marceneiro barriqueiro' },
  { id: '771120 Marceneiro boteiro', descricao: 'Marceneiro boteiro' },
  { id: '771105 Marceneiro de móveis', descricao: 'Marceneiro de móveis' },
  { id: '771110 Marceneiro modelista', descricao: 'Marceneiro modelista' },
  { id: '777110 Marceneiro naval', descricao: 'Marceneiro naval' },
  { id: '771120 Marceneiro pipeiro', descricao: 'Marceneiro pipeiro' },
  { id: '7711 MARCENEIROS E AFINS', descricao: 'MARCENEIROS E AFINS' },
  { id: '848505 Marchante', descricao: 'Marchante' },
  { id: '141405 Marchante (comércio de gado)', descricao: 'Marchante (comércio de gado)' },
  { id: '775120 Marcheteiro', descricao: 'Marcheteiro' },
  { id: '10110 Marechal', descricao: 'Marechal' },
  { id: '10105 Marechal-do-ar', descricao: 'Marechal-do-ar' },
  { id: '514215 Margarida', descricao: 'Margarida' },
  { id: '766325 Margeador gráfico de corte e vinco', descricao: 'Margeador gráfico de corte e vinco' },
  { id: '10315 Marinheiro', descricao: 'Marinheiro' },
  { id: '782730 Marinheiro auxiliar de convés (marítimo e aquaviario)', descricao: 'Marinheiro auxiliar de convés (marítimo e aquaviario)' },
  { id: '782735 Marinheiro auxiliar de máquinas (marítimo e aquaviário)', descricao: 'Marinheiro auxiliar de máquinas (marítimo e aquaviário)' },
  { id: '782705 Marinheiro de convés', descricao: 'Marinheiro de convés' },
  { id: '782705 Marinheiro de convés (marítimo e fluviário)', descricao: 'Marinheiro de convés (marítimo e fluviário)' },
  { id: '782725 Marinheiro de esporte e recreio', descricao: 'Marinheiro de esporte e recreio' },
  { id: '782710 Marinheiro de máquinas', descricao: 'Marinheiro de máquinas' },
  { id: '782715 Marinheiro fluvial de convés', descricao: 'Marinheiro fluvial de convés' },
  { id: '782720 Marinheiro fluvial de máquinas', descricao: 'Marinheiro fluvial de máquinas' },
  { id: '376250 Marionetistas', descricao: 'Marionetistas' },
  { id: '631010 Mariscador', descricao: 'Mariscador' },
  { id: '631010 Marisqueiro', descricao: 'Marisqueiro' },
  { id: '716525 Marmorista (construção)', descricao: 'Marmorista (construção)' },
  { id: '712205 Marmorista - exclusive empregador em marmoraria', descricao: 'Marmorista - exclusive empregador em marmoraria' },
  { id: '141205 Marmorista - exclusive na extração - empregador', descricao: 'Marmorista - exclusive na extração - empregador' },
  { id: '141205 Marmorista - na extração - empregador', descricao: 'Marmorista - na extração - empregador' },
  { id: '711410 Marnoteiro', descricao: 'Marnoteiro' },
  { id: '828110 Marombeiro', descricao: 'Marombeiro' },
  { id: '711110 Marqueiro - na mineração', descricao: 'Marqueiro - na mineração' },
  { id: '848505 Marreteiro (indústria alimentar)', descricao: 'Marreteiro (indústria alimentar)' },
  { id: '711115 Marreteiro - na extração de pedras', descricao: 'Marreteiro - na extração de pedras' },
  { id: '848505 Marreteiro em matadouro', descricao: 'Marreteiro em matadouro' },
  { id: '711115 Marroeiro', descricao: 'Marroeiro' },
  { id: '311725 Marroquineiro', descricao: 'Marroquineiro' },
  { id: '722110 Marteleiro (forjaria)', descricao: 'Marteleiro (forjaria)' },
  { id: '722110 Marteleiro (metalúrgica)', descricao: 'Marteleiro (metalúrgica)' },
  { id: '711115 Marteleiro - na extração de pedras', descricao: 'Marteleiro - na extração de pedras' },
  { id: '722110 Marteleiro a carga de fogo', descricao: 'Marteleiro a carga de fogo' },
  { id: '722110 Marteleiro de cutelaria', descricao: 'Marteleiro de cutelaria' },
  { id: '717010 Marteleteiro', descricao: 'Marteleteiro' },
  { id: '717010 Marteleteiro - na construção civil', descricao: 'Marteleteiro - na construção civil' },
  { id: '711225 Marteleteiro de rocha', descricao: 'Marteleteiro de rocha' },
  { id: '711225 Marteleteiro detonador', descricao: 'Marteleteiro detonador' },
  { id: '322120 Massagista', descricao: 'Massagista' },
  { id: '848315 Masseiro (massas alimentícias)', descricao: 'Masseiro (massas alimentícias)' },
  { id: '848305 Masseiro (padeiro)', descricao: 'Masseiro (padeiro)' },
  { id: '848315 Masseiro de biscoito, macarrão e pão', descricao: 'Masseiro de biscoito, macarrão e pão' },
  { id: '322120 Massoprevencionista', descricao: 'Massoprevencionista' },
  { id: '322120 Massoterapeuta', descricao: 'Massoterapeuta' },
  { id: '225255 Mastologista', descricao: 'Mastologista' },
  { id: '848505 Matador (magarefe)', descricao: 'Matador (magarefe)' },
  { id: '622020 Matador de formiga - na cultura', descricao: 'Matador de formiga - na cultura' },
  { id: '612615 Mateicultor - empregador', descricao: 'Mateicultor - empregador' },
  { id: '632115 Mateiro', descricao: 'Mateiro' },
  { id: '632115 Mateiro (inventário de florestas)', descricao: 'Mateiro (inventário de florestas)' },
  { id: '632005 Mateiro - guia', descricao: 'Mateiro - guia' },
  { id: '517320 Mateiro-guarda florestal', descricao: 'Mateiro-guarda florestal' },
  { id: '211115 Matemático', descricao: 'Matemático' },
  { id: '211120 Matemático aplicado', descricao: 'Matemático aplicado' },
  { id: '211120 Matemático computacional', descricao: 'Matemático computacional' },
  { id: '211120 Matemático industrial', descricao: 'Matemático industrial' },
  { id: '762320 Matizador de couros e peles', descricao: 'Matizador de couros e peles' },
  { id: '721105 Matrizeiro', descricao: 'Matrizeiro' },
  { id: '766145 Matrizeiro - na indústrias gráfica', descricao: 'Matrizeiro - na indústrias gráfica' },
  { id: '613310 Matrizeiro de coelho', descricao: 'Matrizeiro de coelho' },
  { id: '919205 Mecânico', descricao: 'Mecânico' },
  { id: '721315 Mecânico afiador', descricao: 'Mecânico afiador' },
  { id: '725010 Mecânico ajustador', descricao: 'Mecânico ajustador' },
  { id: '914105 Mecânico de aeronaves', descricao: 'Mecânico de aeronaves' },
  { id: '911120 Mecânico de afinação de motores diesel (exceto de veículos automotores)', descricao: 'Mecânico de afinação de motores diesel (exceto de veículos automotores)' },
  { id: '914405 Mecânico de amortecedores', descricao: 'Mecânico de amortecedores' },
  { id: '741105 Mecânico de aparelhos de precisão', descricao: 'Mecânico de aparelhos de precisão' },
  { id: '954205 Mecânico de aparelhos domésticos', descricao: 'Mecânico de aparelhos domésticos' },
  { id: '911205 Mecânico de ar-condicionado e refrigeração', descricao: 'Mecânico de ar-condicionado e refrigeração' },
  { id: '914405 Mecânico de auto em geral', descricao: 'Mecânico de auto em geral' },
  { id: '914425 Mecânico de automóveis e caminhões', descricao: 'Mecânico de automóveis e caminhões' },
  { id: '914405 Mecânico de automóvel', descricao: 'Mecânico de automóvel' },
  { id: '725020 Mecânico de bancada', descricao: 'Mecânico de bancada' },
  { id: '919310 Mecânico de bicicletas', descricao: 'Mecânico de bicicletas' },
  { id: '911105 Mecânico de bomba injetora (exceto de veículos automotores)', descricao: 'Mecânico de bomba injetora (exceto de veículos automotores)' },
  { id: '911105 Mecânico de bombas diesel (exceto de veículos automotores)', descricao: 'Mecânico de bombas diesel (exceto de veículos automotores)' },
  { id: '911110 Mecânico de bombas hidráulicas', descricao: 'Mecânico de bombas hidráulicas' },
  { id: '724410 Mecânico de caldeiraria pesada', descricao: 'Mecânico de caldeiraria pesada' },
  { id: '911305 Mecânico de caldeiras', descricao: 'Mecânico de caldeiras' },
  { id: '914405 Mecânico de câmbio', descricao: 'Mecânico de câmbio' },
  { id: '913115 Mecânico de campo', descricao: 'Mecânico de campo' },
  { id: '914405 Mecânico de chassis', descricao: 'Mecânico de chassis' },
  { id: '914425 Mecânico de diesel e eletricidade', descricao: 'Mecânico de diesel e eletricidade' },
  { id: '914405 Mecânico de direção e freios de automóveis', descricao: 'Mecânico de direção e freios de automóveis' },
  { id: '914410 Mecânico de empilhadeira', descricao: 'Mecânico de empilhadeira' },
  { id: '913120 Mecânico de equipamento pesado', descricao: 'Mecânico de equipamento pesado' },
  { id: '911305 Mecânico de equipamento pneumático', descricao: 'Mecânico de equipamento pneumático' },
  { id: '911305 Mecânico de equipamentos industriais', descricao: 'Mecânico de equipamentos industriais' },
  { id: '913120 Mecânico de escavadeira', descricao: 'Mecânico de escavadeira' },
  { id: '911320 Mecânico de estamparia (máquinas têxteis)', descricao: 'Mecânico de estamparia (máquinas têxteis)' },
  { id: '913105 Mecânico de estiva', descricao: 'Mecânico de estiva' },
  { id: '954205 Mecânico de fogão', descricao: 'Mecânico de fogão' },
  { id: '914405 Mecânico de freios de automóveis', descricao: 'Mecânico de freios de automóveis' },
  { id: '911305 Mecânico de fundição (manutenção)', descricao: 'Mecânico de fundição (manutenção)' },
  { id: '911305 Mecânico de gerador', descricao: 'Mecânico de gerador' },
  { id: '914405 Mecânico de injeção eletrônica', descricao: 'Mecânico de injeção eletrônica' },
  { id: '914405 Mecânico de instalação de freios', descricao: 'Mecânico de instalação de freios' },
  { id: '911305 Mecânico de instalações industriais (manutenção)', descricao: 'Mecânico de instalações industriais (manutenção)' },
  { id: '741105 Mecânico de instrumentos de precisão', descricao: 'Mecânico de instrumentos de precisão' },
  { id: '911305 Mecânico de laminação (manutenção)', descricao: 'Mecânico de laminação (manutenção)' },
  { id: '954205 Mecânico de lavadora e secadora', descricao: 'Mecânico de lavadora e secadora' },
  { id: '914305 Mecânico de locomotivas e vagões', descricao: 'Mecânico de locomotivas e vagões' },
  { id: '911320 Mecânico de malharia (máquinas)', descricao: 'Mecânico de malharia (máquinas)' },
  { id: '919115 Mecânico de manutenção (embarcações)', descricao: 'Mecânico de manutenção (embarcações)' },
  { id: '954120 Mecânico de manutenção (equipamento de edifícios)', descricao: 'Mecânico de manutenção (equipamento de edifícios)' },
  { id: '911305 Mecânico de manutenção (máquinas hidráulicas)', descricao: 'Mecânico de manutenção (máquinas hidráulicas)' },
  { id: '725605 Mecânico de manutenção aeronáutica', descricao: 'Mecânico de manutenção aeronáutica' },
  { id: '914105 Mecânico de manutenção de aeronaves, em geral', descricao: 'Mecânico de manutenção de aeronaves, em geral' },
  { id: '913105 Mecânico de manutenção de aparelhos de levantamento', descricao: 'Mecânico de manutenção de aparelhos de levantamento' },
  { id: '911205 Mecânico de manutenção de aparelhos de refrigeração', descricao: 'Mecânico de manutenção de aparelhos de refrigeração' },
  { id: '919305 Mecânico de manutenção de aparelhos esportivos e de ginástica', descricao: 'Mecânico de manutenção de aparelhos esportivos e de ginástica' },
  { id: '911205 Mecânico de manutenção de ar condicionado', descricao: 'Mecânico de manutenção de ar condicionado' },
  { id: '914405 Mecânico de manutenção de automóveis', descricao: 'Mecânico de manutenção de automóveis' },
  { id: '914405 Mecânico de manutenção de automóveis, motocicletas e veículos similares', descricao: 'Mecânico de manutenção de automóveis, motocicletas e veículos similares' },
  { id: '914105 Mecânico de manutenção de aviões', descricao: 'Mecânico de manutenção de aviões' },
  { id: '919310 Mecânico de manutenção de bicicletas', descricao: 'Mecânico de manutenção de bicicletas' },
  { id: '919310 Mecânico de manutenção de bicicletas e veículos similares', descricao: 'Mecânico de manutenção de bicicletas e veículos similares' },
  { id: '911320 Mecânico de manutenção de bobinadeira (indústria têxtil)', descricao: 'Mecânico de manutenção de bobinadeira (indústria têxtil)' },
  { id: '911105 Mecânico de manutenção de bomba injetora (exceto de veículos automotores)', descricao: 'Mecânico de manutenção de bomba injetora (exceto de veículos automotores)' },
  { id: '911110 Mecânico de manutenção de bombas', descricao: 'Mecânico de manutenção de bombas' },
  { id: '911110 Mecânico de manutenção de bombas centrífugas', descricao: 'Mecânico de manutenção de bombas centrífugas' },
  { id: '911305 Mecânico de manutenção de bombas de refrigeração e hidráulicas', descricao: 'Mecânico de manutenção de bombas de refrigeração e hidráulicas' },
  { id: '911110 Mecânico de manutenção de bombas hidráulicas', descricao: 'Mecânico de manutenção de bombas hidráulicas' },
  { id: '913110 Mecânico de manutenção de britagem', descricao: 'Mecânico de manutenção de britagem' },
  { id: '914425 Mecânico de manutenção de caminhão a diesel', descricao: 'Mecânico de manutenção de caminhão a diesel' },
  { id: '911320 Mecânico de manutenção de cardas', descricao: 'Mecânico de manutenção de cardas' },
  { id: '911115 Mecânico de manutenção de compressores de ar', descricao: 'Mecânico de manutenção de compressores de ar' },
  { id: '913105 Mecânico de manutenção de empilhadeiras', descricao: 'Mecânico de manutenção de empilhadeiras' },
  { id: '914410 Mecânico de manutenção de empilhadeiras e outros veículos de cargas leves', descricao: 'Mecânico de manutenção de empilhadeiras e outros veículos de cargas leves' },
  { id: '913110 Mecânico de manutenção de equipamento de mineração', descricao: 'Mecânico de manutenção de equipamento de mineração' },
  { id: '911305 Mecânico de manutenção de equipamentos hidropneumáticos', descricao: 'Mecânico de manutenção de equipamentos hidropneumáticos' },
  { id: '911320 Mecânico de manutenção de espuladeira', descricao: 'Mecânico de manutenção de espuladeira' },
  { id: '913105 Mecânico de manutenção de guindaste', descricao: 'Mecânico de manutenção de guindaste' },
  { id: '914105 Mecânico de manutenção de helicópteros', descricao: 'Mecânico de manutenção de helicópteros' },
  { id: '913115 Mecânico de manutenção de implementos agrícolas', descricao: 'Mecânico de manutenção de implementos agrícolas' },
  { id: '954120 Mecânico de manutenção de instalações mecânicas de edifícios', descricao: 'Mecânico de manutenção de instalações mecânicas de edifícios' },
  { id: '914305 Mecânico de manutenção de locomotiva', descricao: 'Mecânico de manutenção de locomotiva' },
  { id: '911305 Mecânico de manutenção de máquina de calçado', descricao: 'Mecânico de manutenção de máquina de calçado' },
  { id: '911305 Mecânico de manutenção de máquina de curtume', descricao: 'Mecânico de manutenção de máquina de curtume' },
  { id: '911305 Mecânico de manutenção de máquina de embalagem', descricao: 'Mecânico de manutenção de máquina de embalagem' },
  { id: '911305 Mecânico de manutenção de máquina de rotular', descricao: 'Mecânico de manutenção de máquina de rotular' },
  { id: '911305 Mecânico de manutenção de máquina industrial', descricao: 'Mecânico de manutenção de máquina industrial' },
  { id: '913115 Mecânico de manutenção de máquinas agrícolas', descricao: 'Mecânico de manutenção de máquinas agrícolas' },
  { id: '914410 Mecânico de manutenção de máquinas agrícolas (tratores)', descricao: 'Mecânico de manutenção de máquinas agrícolas (tratores)' },
  { id: '919205 Mecânico de manutenção de máquinas cortadoras de grama, roçadeiras, motosserras e similares', descricao: 'Mecânico de manutenção de máquinas cortadoras de grama, roçadeiras, motosserras e similares' },
  { id: '911305 Mecânico de manutenção de máquinas de acondicionar', descricao: 'Mecânico de manutenção de máquinas de acondicionar' },
  { id: '913120 Mecânico de manutenção de máquinas de construção civil', descricao: 'Mecânico de manutenção de máquinas de construção civil' },
  { id: '913120 Mecânico de manutenção de máquinas de construção e terraplenagem', descricao: 'Mecânico de manutenção de máquinas de construção e terraplenagem' },
  { id: '911320 Mecânico de manutenção de máquinas de costura', descricao: 'Mecânico de manutenção de máquinas de costura' },
  { id: '911305 Mecânico de manutenção de máquinas de embalagem', descricao: 'Mecânico de manutenção de máquinas de embalagem' },
  { id: '911325 Mecânico de manutenção de máquinas de fundição (usinagem de metais)', descricao: 'Mecânico de manutenção de máquinas de fundição (usinagem de metais)' },
  { id: '911310 Mecânico de manutenção de máquinas gráficas', descricao: 'Mecânico de manutenção de máquinas gráficas' },
  { id: '911305 Mecânico de manutenção de máquinas industriais', descricao: 'Mecânico de manutenção de máquinas industriais' },
  { id: '911320 Mecânico de manutenção de máquinas maçaroqueiras', descricao: 'Mecânico de manutenção de máquinas maçaroqueiras' },
  { id: '911325 Mecânico de manutenção de máquinas operatrizes', descricao: 'Mecânico de manutenção de máquinas operatrizes' },
  { id: '911315 Mecânico de manutenção de máquinas operatrizes (lavra de madeira)', descricao: 'Mecânico de manutenção de máquinas operatrizes (lavra de madeira)' },
  { id: '911320 Mecânico de manutenção de máquinas têxteis', descricao: 'Mecânico de manutenção de máquinas têxteis' },
  { id: '911305 Mecânico de manutenção de máquinas, em geral', descricao: 'Mecânico de manutenção de máquinas, em geral' },
  { id: '911325 Mecânico de manutenção de máquinas-ferramentas (usinagem de metais)', descricao: 'Mecânico de manutenção de máquinas-ferramentas (usinagem de metais)' },
  { id: '914415 Mecânico de manutenção de motocicletas', descricao: 'Mecânico de manutenção de motocicletas' },
  { id: '914405 Mecânico de manutenção de motores a álcool', descricao: 'Mecânico de manutenção de motores a álcool' },
  { id: '911120 Mecânico de manutenção de motores diesel (exceto de veículos automotores)', descricao: 'Mecânico de manutenção de motores diesel (exceto de veículos automotores)' },
  { id: '914205 Mecânico de manutenção de motores e equipamentos navais', descricao: 'Mecânico de manutenção de motores e equipamentos navais' },
  { id: '914425 Mecânico de manutenção de ônibus', descricao: 'Mecânico de manutenção de ônibus' },
  { id: '991205 Mecânico de manutenção de parques de diversões', descricao: 'Mecânico de manutenção de parques de diversões' },
  { id: '913105 Mecânico de manutenção de pontes-rolantes', descricao: 'Mecânico de manutenção de pontes-rolantes' },
  { id: '911125 Mecânico de manutenção de redutores', descricao: 'Mecânico de manutenção de redutores' },
  { id: '911205 Mecânico de manutenção de refrigerador', descricao: 'Mecânico de manutenção de refrigerador' },
  { id: '919205 Mecânico de manutenção de roçadeiras, motosserras e similares', descricao: 'Mecânico de manutenção de roçadeiras, motosserras e similares' },
  { id: '914110 Mecânico de manutenção de sistema hidráulico de aeronaves (serviços de pista e hangar)', descricao: 'Mecânico de manutenção de sistema hidráulico de aeronaves (serviços de pista e hangar)' },
  { id: '911320 Mecânico de manutenção de teares', descricao: 'Mecânico de manutenção de teares' },
  { id: '911125 Mecânico de manutenção de transmissão', descricao: 'Mecânico de manutenção de transmissão' },
  { id: '914420 Mecânico de manutenção de tratores', descricao: 'Mecânico de manutenção de tratores' },
  { id: '911130 Mecânico de manutenção de turbinas (exceto de aeronaves)', descricao: 'Mecânico de manutenção de turbinas (exceto de aeronaves)' },
  { id: '911135 Mecânico de manutenção de turbocompressores', descricao: 'Mecânico de manutenção de turbocompressores' },
  { id: '914305 Mecânico de manutenção de veículos ferroviários', descricao: 'Mecânico de manutenção de veículos ferroviários' },
  { id: '954125 Mecânico de manutenção e eletricista', descricao: 'Mecânico de manutenção e eletricista' },
  { id: '911205 Mecânico de manutenção e instalação de aparelhos de climatização e refrigeração', descricao: 'Mecânico de manutenção e instalação de aparelhos de climatização e refrigeração' },
  { id: '911305 Mecânico de manutenção e instalação elétrica', descricao: 'Mecânico de manutenção e instalação elétrica' },
  { id: '911305 Mecânico de manutenção hidráulica', descricao: 'Mecânico de manutenção hidráulica' },
  { id: '914205 Mecânico de manutenção mariner', descricao: 'Mecânico de manutenção mariner' },
  { id: '913115 Mecânico de máquina agrícola', descricao: 'Mecânico de máquina agrícola' },
  { id: '911205 Mecânico de máquina de ventilação', descricao: 'Mecânico de máquina de ventilação' },
  { id: '913110 Mecânico de máquina para trabalho de minas', descricao: 'Mecânico de máquina para trabalho de minas' },
  { id: '954305 Mecânico de máquinas de escrever', descricao: 'Mecânico de máquinas de escrever' },
  { id: '954305 Mecânico de máquinas de escrever e calcular', descricao: 'Mecânico de máquinas de escrever e calcular' },
  { id: '911320 Mecânico de máquinas de fiação', descricao: 'Mecânico de máquinas de fiação' },
  { id: '911305 Mecânico de máquinas de pasteurização', descricao: 'Mecânico de máquinas de pasteurização' },
  { id: '915405 Mecânico de máquinas fotográficas', descricao: 'Mecânico de máquinas fotográficas' },
  { id: '911305 Mecânico de máquinas operatrizes (manutenção)', descricao: 'Mecânico de máquinas operatrizes (manutenção)' },
  { id: '913120 Mecânico de máquinas pesadas (manutenção)', descricao: 'Mecânico de máquinas pesadas (manutenção)' },
  { id: '913110 Mecânico de mineração', descricao: 'Mecânico de mineração' },
  { id: '725205 Mecânico de montagem de máquinas', descricao: 'Mecânico de montagem de máquinas' },
  { id: '914415 Mecânico de motocicletas', descricao: 'Mecânico de motocicletas' },
  { id: '914415 Mecânico de motonetas', descricao: 'Mecânico de motonetas' },
  { id: '914425 Mecânico de motor a diesel', descricao: 'Mecânico de motor a diesel' },
  { id: '914405 Mecânico de motor a gasolina', descricao: 'Mecânico de motor a gasolina' },
  { id: '914405 Mecânico de motor de explosão', descricao: 'Mecânico de motor de explosão' },
  { id: '913115 Mecânico de motores agrícolas', descricao: 'Mecânico de motores agrícolas' },
  { id: '725410 Mecânico de motores marítimos', descricao: 'Mecânico de motores marítimos' },
  { id: '725420 Mecânico de motores turboalimentadores', descricao: 'Mecânico de motores turboalimentadores' },
  { id: '919205 Mecânico de motosserra', descricao: 'Mecânico de motosserra' },
  { id: '911320 Mecânico de penteadeiras (máquinas têxteis)', descricao: 'Mecânico de penteadeiras (máquinas têxteis)' },
  { id: '913120 Mecânico de perfuratrizes', descricao: 'Mecânico de perfuratrizes' },
  { id: '913105 Mecânico de ponte-rolante', descricao: 'Mecânico de ponte-rolante' },
  { id: '911325 Mecânico de prensas', descricao: 'Mecânico de prensas' },
  { id: '914405 Mecânico de radiadores', descricao: 'Mecânico de radiadores' },
  { id: '913110 Mecânico de recravadeira', descricao: 'Mecânico de recravadeira' },
  { id: '725705 Mecânico de refrigeração', descricao: 'Mecânico de refrigeração' },
  { id: '954205 Mecânico de refrigeração doméstica', descricao: 'Mecânico de refrigeração doméstica' },
  { id: '911120 Mecânico de regulagem de motores diesel (exceto de veículos automotores)', descricao: 'Mecânico de regulagem de motores diesel (exceto de veículos automotores)' },
  { id: '911310 Mecânico de rotativa (indústria gráfica)', descricao: 'Mecânico de rotativa (indústria gráfica)' },
  { id: '914405 Mecânico de socorro', descricao: 'Mecânico de socorro' },
  { id: '914405 Mecânico de suspensão', descricao: 'Mecânico de suspensão' },
  { id: '914405 Mecânico de testes de automotores', descricao: 'Mecânico de testes de automotores' },
  { id: '911130 Mecânico de turbinas e caldeiras', descricao: 'Mecânico de turbinas e caldeiras' },
  { id: '911130 Mecânico de turbinas exclusive de avião', descricao: 'Mecânico de turbinas exclusive de avião' },
  { id: '911320 Mecânico de urdideira e engomadeira', descricao: 'Mecânico de urdideira e engomadeira' },
  { id: '911325 Mecânico de usinagem (manutenção)', descricao: 'Mecânico de usinagem (manutenção)' },
  { id: '914405 Mecânico de veículos', descricao: 'Mecânico de veículos' },
  { id: '914425 Mecânico de veículos automotores a diesel (exceto tratores)', descricao: 'Mecânico de veículos automotores a diesel (exceto tratores)' },
  { id: '341115 Mecânico de vôo', descricao: 'Mecânico de vôo' },
  { id: '911120 Mecânico diesel (exceto de veículos automotores)', descricao: 'Mecânico diesel (exceto de veículos automotores)' },
  { id: '953115 Mecânico eletricista de automóveis', descricao: 'Mecânico eletricista de automóveis' },
  { id: '953105 Mecânico eletricista de aviões', descricao: 'Mecânico eletricista de aviões' },
  { id: '914425 Mecânico eletricista de diesel (veículos automotores)', descricao: 'Mecânico eletricista de diesel (veículos automotores)' },
  { id: '953115 Mecânico eletricista de veículos automotores', descricao: 'Mecânico eletricista de veículos automotores' },
  { id: '914105 Mecânico encarregado de manutenção de aeronave', descricao: 'Mecânico encarregado de manutenção de aeronave' },
  { id: '914305 Mecânico ferroviário', descricao: 'Mecânico ferroviário' },
  { id: '919115 Mecânico lubrificador (embarcações)', descricao: 'Mecânico lubrificador (embarcações)' },
  { id: '919105 Mecânico lubrificador de manutenção industrial', descricao: 'Mecânico lubrificador de manutenção industrial' },
  { id: '919105 Mecânico lubrificador industrial', descricao: 'Mecânico lubrificador industrial' },
  { id: '725205 Mecânico montador', descricao: 'Mecânico montador' },
  { id: '725605 Mecânico montador de aeronaves', descricao: 'Mecânico montador de aeronaves' },
  { id: '725405 Mecânico montador de motores de aeronaves', descricao: 'Mecânico montador de motores de aeronaves' },
  { id: '725410 Mecânico montador de motores de embarcações', descricao: 'Mecânico montador de motores de embarcações' },
  { id: '725415 Mecânico montador de motores de explosão e diesel', descricao: 'Mecânico montador de motores de explosão e diesel' },
  { id: '725420 Mecânico montador de motores turboalimentadores', descricao: 'Mecânico montador de motores turboalimentadores' },
  { id: '725420 Mecânico montador de turbinas', descricao: 'Mecânico montador de turbinas' },
  { id: '725420 Mecânico montador de turboalimentadores', descricao: 'Mecânico montador de turboalimentadores' },
  { id: '725415 Mecânico montador especializado em motores de explosão e diesel', descricao: 'Mecânico montador especializado em motores de explosão e diesel' },
  { id: '914205 Mecânico naval', descricao: 'Mecânico naval' },
  { id: '911110 Mecânico reparador de bombas hidráulicas', descricao: 'Mecânico reparador de bombas hidráulicas' },
  { id: '911305 Mecânico reparador de máquinas', descricao: 'Mecânico reparador de máquinas' },
  { id: '914305 Mecânico reparador de vagões', descricao: 'Mecânico reparador de vagões' },
  { id: '725420 Mecânico turboalimentador', descricao: 'Mecânico turboalimentador' },
  { id: '842135 Mecânico-operador (cigarros)', descricao: 'Mecânico-operador (cigarros)' },
  { id: '7411 MECÂNICOS DE INSTRUMENTOS DE PRECISÃO', descricao: 'MECÂNICOS DE INSTRUMENTOS DE PRECISÃO' },
  { id: '9141 MECÂNICOS DE MANUTENÇÃO AERONÁUTICA', descricao: 'MECÂNICOS DE MANUTENÇÃO AERONÁUTICA' },
  { id: '9193 MECÂNICOS DE MANUTENÇÃO DE BICICLETAS E EQUIPAMENTOS ESPORTIVOS E DE GINÁSTICA', descricao: 'MECÂNICOS DE MANUTENÇÃO DE BICICLETAS E EQUIPAMENTOS ESPORTIVOS E DE GINÁSTICA' },
  { id: '9111 MECÂNICOS DE MANUTENÇÃO DE BOMBAS, MOTORES, COMPRESSORES E EQUIPAMENTOS DE TRANSMISSÃO', descricao: 'MECÂNICOS DE MANUTENÇÃO DE BOMBAS, MOTORES, COMPRESSORES E EQUIPAMENTOS DE TRANSMISSÃO' },
  { id: '9113 MECÂNICOS DE MANUTENÇÃO DE MÁQUINAS INDUSTRIAIS', descricao: 'MECÂNICOS DE MANUTENÇÃO DE MÁQUINAS INDUSTRIAIS' },
  { id: '9131 MECÂNICOS DE MANUTENÇÃO DE MÁQUINAS PESADAS E EQUIPAMENTOS AGRÍCOLAS', descricao: 'MECÂNICOS DE MANUTENÇÃO DE MÁQUINAS PESADAS E EQUIPAMENTOS AGRÍCOLAS' },
  { id: '9142 MECÂNICOS DE MANUTENÇÃO DE MOTORES E EQUIPAMENTOS NAVAIS', descricao: 'MECÂNICOS DE MANUTENÇÃO DE MOTORES E EQUIPAMENTOS NAVAIS' },
  { id: '9144 MECÂNICOS DE MANUTENÇÃO DE VEÍCULOS AUTOMOTORES', descricao: 'MECÂNICOS DE MANUTENÇÃO DE VEÍCULOS AUTOMOTORES' },
  { id: '9112 MECÂNICOS DE MANUTENÇÃO E INSTALAÇÃO DE APARELHOS DE CLIMATIZAÇÃO E REFRIGERAÇÃO', descricao: 'MECÂNICOS DE MANUTENÇÃO E INSTALAÇÃO DE APARELHOS DE CLIMATIZAÇÃO E REFRIGERAÇÃO' },
  { id: '9143 MECÂNICOS DE MANUTENÇÃO METROFERROVIÁRIA', descricao: 'MECÂNICOS DE MANUTENÇÃO METROFERROVIÁRIA' },
  { id: '911130 Mecânicos de turbinas a vapor e gás', descricao: 'Mecânicos de turbinas a vapor e gás' },
  { id: '914405 Mecânicos de veículos automotores', descricao: 'Mecânicos de veículos automotores' },
  { id: '7254 MECÂNICOS MONTADORES DE MOTORES E TURBOALIMENTADORES', descricao: 'MECÂNICOS MONTADORES DE MOTORES E TURBOALIMENTADORES' },
  { id: '954305 Mecanógrafo (mecânico de máquina de escrever)', descricao: 'Mecanógrafo (mecânico de máquina de escrever)' },
  { id: '351435 Mediador de conflitos', descricao: 'Mediador de conflitos' },
  { id: '251205 Mediador econômico', descricao: 'Mediador econômico' },
  { id: '351435 Mediador extrajudicial', descricao: 'Mediador extrajudicial' },
  { id: '225105 Médico acupunturista', descricao: 'Médico acupunturista' },
  { id: '225110 Médico alergista', descricao: 'Médico alergista' },
  { id: '225110 Médico alergista e imunologista', descricao: 'Médico alergista e imunologista' },
  { id: '225170 Médico alopata', descricao: 'Médico alopata' },
  { id: '225148 Médico anatomopatologista', descricao: 'Médico anatomopatologista' },
  { id: '225325 Médico anatomopatologista', descricao: 'Médico anatomopatologista' },
  { id: '225151 Médico anestesiologista', descricao: 'Médico anestesiologista' },
  { id: '225151 Médico anestesista', descricao: 'Médico anestesista' },
  { id: '225115 Médico angiologista', descricao: 'Médico angiologista' },
  { id: '225154 Médico antroposófico', descricao: 'Médico antroposófico' },
  { id: '225310 Médico broncoesofagologista', descricao: 'Médico broncoesofagologista' },
  { id: '225310 Médico broncoscopista', descricao: 'Médico broncoscopista' },
  { id: '225121 Médico cancerologista (clínico)', descricao: 'Médico cancerologista (clínico)' },
  { id: '225290 Médico cancerologista cirurgíco', descricao: 'Médico cancerologista cirurgíco' },
  { id: '225122 Médico cancerologista pediátrico', descricao: 'Médico cancerologista pediátrico' },
  { id: '225120 Médico cardiologista', descricao: 'Médico cardiologista' },
  { id: '225225 Médico cirurgião', descricao: 'Médico cirurgião' },
  { id: '225210 Médico cirurgião cardiovascular', descricao: 'Médico cirurgião cardiovascular' },
  { id: '225295 Médico cirurgião da mão', descricao: 'Médico cirurgião da mão' },
  { id: '225215 Médico cirurgião de cabeça e pescoço', descricao: 'Médico cirurgião de cabeça e pescoço' },
  { id: '225220 Médico cirurgião do aparelho digestivo', descricao: 'Médico cirurgião do aparelho digestivo' },
  { id: '225225 Médico cirurgião geral', descricao: 'Médico cirurgião geral' },
  { id: '225230 Médico cirurgião pediátrico', descricao: 'Médico cirurgião pediátrico' },
  { id: '225235 Médico cirurgião plástico', descricao: 'Médico cirurgião plástico' },
  { id: '225240 Médico cirurgião torácico', descricao: 'Médico cirurgião torácico' },
  { id: '225305 Médico citopatologista', descricao: 'Médico citopatologista' },
  { id: '225125 Médico clínico', descricao: 'Médico clínico' },
  { id: '225125 Médico clínico geral', descricao: 'Médico clínico geral' },
  { id: '225280 Médico coloproctologista', descricao: 'Médico coloproctologista' },
  { id: '225130 Médico comunitário', descricao: 'Médico comunitário' },
  { id: '225124 Médico de criança', descricao: 'Médico de criança' },
  { id: '225103 Médico de doenças infecciosas e parasitárias', descricao: 'Médico de doenças infecciosas e parasitárias' },
  { id: '225250 Médico de mulheres', descricao: 'Médico de mulheres' },
  { id: '225139 Médico de saúde pública', descricao: 'Médico de saúde pública' },
  { id: '225135 Médico dermatologista', descricao: 'Médico dermatologista' },
  { id: '225120 Médico do coração', descricao: 'Médico do coração' },
  { id: '225140 Médico do trabalho', descricao: 'Médico do trabalho' },
  { id: '225145 Médico do tráfego', descricao: 'Médico do tráfego' },
  { id: '225203 Médico em cirurgia vascular', descricao: 'Médico em cirurgia vascular' },
  { id: '225310 Médico em endoscopia', descricao: 'Médico em endoscopia' },
  { id: '225340 Médico em hemoterapia', descricao: 'Médico em hemoterapia' },
  { id: '225145 Médico em medicina de tráfego', descricao: 'Médico em medicina de tráfego' },
  { id: '225345 Médico em medicina hiperbárica', descricao: 'Médico em medicina hiperbárica' },
  { id: '225150 Médico em medicina intensiva', descricao: 'Médico em medicina intensiva' },
  { id: '225170 Médico em medicina interna', descricao: 'Médico em medicina interna' },
  { id: '225335 Médico em medicina laboratorial', descricao: 'Médico em medicina laboratorial' },
  { id: '225315 Médico em medicina nuclear', descricao: 'Médico em medicina nuclear' },
  { id: '225330 Médico em radio-oncologia', descricao: 'Médico em radio-oncologia' },
  { id: '225320 Médico em radiologia e diagnóstico por imagem', descricao: 'Médico em radiologia e diagnóstico por imagem' },
  { id: '225330 Médico em radioterapia', descricao: 'Médico em radioterapia' },
  { id: '225155 Médico endocrinologista', descricao: 'Médico endocrinologista' },
  { id: '225155 Médico endocrinologista e metabologista', descricao: 'Médico endocrinologista e metabologista' },
  { id: '225310 Médico endoscopista', descricao: 'Médico endoscopista' },
  { id: '225139 Médico epidemiologista', descricao: 'Médico epidemiologista' },
  { id: '225125 Médico especialista em clínica médica', descricao: 'Médico especialista em clínica médica' },
  { id: '225125 Médico especialista em medicina interna', descricao: 'Médico especialista em medicina interna' },
  { id: '225160 Médico fisiatra', descricao: 'Médico fisiatra' },
  { id: '225275 Médico foniatra', descricao: 'Médico foniatra' },
  { id: '225165 Médico gastroenterologista', descricao: 'Médico gastroenterologista' },
  { id: '225170 Médico generalista', descricao: 'Médico generalista' },
  { id: '225175 Médico geneticista', descricao: 'Médico geneticista' },
  { id: '225180 Médico geriatra', descricao: 'Médico geriatra' },
  { id: '225250 Médico ginecologista', descricao: 'Médico ginecologista' },
  { id: '225250 Médico ginecologista e obstetra', descricao: 'Médico ginecologista e obstetra' },
  { id: '225185 Médico hematologista', descricao: 'Médico hematologista' },
  { id: '225340 Médico hemoterapeuta', descricao: 'Médico hemoterapeuta' },
  { id: '225139 Médico higienista', descricao: 'Médico higienista' },
  { id: '225345 Médico hiperbaricista', descricao: 'Médico hiperbaricista' },
  { id: '225345 Médico hiperbárico', descricao: 'Médico hiperbárico' },
  { id: '225345 Médico hiperbarista', descricao: 'Médico hiperbarista' },
  { id: '225195 Médico homeopata', descricao: 'Médico homeopata' },
  { id: '225110 Médico imunologista', descricao: 'Médico imunologista' },
  { id: '225103 Médico infectologista', descricao: 'Médico infectologista' },
  { id: '225125 Médico internista', descricao: 'Médico internista' },
  { id: '225355 Medico intervencionista', descricao: 'Medico intervencionista' },
  { id: '225335 Médico laboratorista', descricao: 'Médico laboratorista' },
  { id: '225106 Médico legista', descricao: 'Médico legista' },
  { id: '225255 Médico mastologista', descricao: 'Médico mastologista' },
  { id: '225155 Médico metabolista', descricao: 'Médico metabolista' },
  { id: '225170 Médico militar', descricao: 'Médico militar' },
  { id: '225109 Médico nefrologista', descricao: 'Médico nefrologista' },
  { id: '225260 Médico neurocirurgião', descricao: 'Médico neurocirurgião' },
  { id: '225260 Médico neurocirurgião pediátrico', descricao: 'Médico neurocirurgião pediátrico' },
  { id: '225350 Médico neurofisiologista', descricao: 'Médico neurofisiologista' },
  { id: '225350 Médico neurofisiologista clínico', descricao: 'Médico neurofisiologista clínico' },
  { id: '225112 Médico neurologista', descricao: 'Médico neurologista' },
  { id: '225112 Médico neuropediatra', descricao: 'Médico neuropediatra' },
  { id: '225315 Médico nuclear', descricao: 'Médico nuclear' },
  { id: '225118 Médico nutrologista', descricao: 'Médico nutrologista' },
  { id: '225118 Médico nutrólogo', descricao: 'Médico nutrólogo' },
  { id: '225250 Médico obstetra', descricao: 'Médico obstetra' },
  { id: '225265 Médico oftalmologista', descricao: 'Médico oftalmologista' },
  { id: '225290 Médico oncologista cirúrgico', descricao: 'Médico oncologista cirúrgico' },
  { id: '225121 Médico oncologista clínico', descricao: 'Médico oncologista clínico' },
  { id: '225270 Médico ortopedista', descricao: 'Médico ortopedista' },
  { id: '225270 Médico ortopedista e traumatologista', descricao: 'Médico ortopedista e traumatologista' },
  { id: '225275 Médico otorrinolaringologista', descricao: 'Médico otorrinolaringologista' },
  { id: '225325 Médico patologista', descricao: 'Médico patologista' },
  { id: '225335 Médico patologista clínico', descricao: 'Médico patologista clínico' },
  { id: '225335 Médico patologista clínico / medicina laboratorial', descricao: 'Médico patologista clínico / medicina laboratorial' },
  { id: '225124 Médico pediatra', descricao: 'Médico pediatra' },
  { id: '225127 Médico pneumologista', descricao: 'Médico pneumologista' },
  { id: '225127 Médico pneumotisiologista', descricao: 'Médico pneumotisiologista' },
  { id: '225280 Médico proctologista', descricao: 'Médico proctologista' },
  { id: '225133 Médico psicanalista', descricao: 'Médico psicanalista' },
  { id: '225133 Médico psicoterapeuta', descricao: 'Médico psicoterapeuta' },
  { id: '225133 Médico psiquiatra', descricao: 'Médico psiquiatra' },
  { id: '225320 Médico radiologista', descricao: 'Médico radiologista' },
  { id: '225355 Médico radiologista intervencionista', descricao: 'Médico radiologista intervencionista' },
  { id: '225330 Médico radioterapeuta', descricao: 'Médico radioterapeuta' },
  { id: '225136 Médico reumatologista', descricao: 'Médico reumatologista' },
  { id: '225139 Médico sanitarista', descricao: 'Médico sanitarista' },
  { id: '225270 Médico traumatologista', descricao: 'Médico traumatologista' },
  { id: '225285 Médico urologista', descricao: 'Médico urologista' },
  { id: '223305 Médico veterinário', descricao: 'Médico veterinário' },
  { id: '223305 Médico veterinário de saúde pública', descricao: 'Médico veterinário de saúde pública' },
  { id: '223305 Médico veterinário sanitarista', descricao: 'Médico veterinário sanitarista' },
  { id: '2251 MÉDICOS CLÍNICOS', descricao: 'MÉDICOS CLÍNICOS' },
  { id: '2252 MÉDICOS EM ESPECIALIDADES CIRÚRGICAS', descricao: 'MÉDICOS EM ESPECIALIDADES CIRÚRGICAS' },
  { id: '2253 MÉDICOS EM MEDICINA DIAGNÓSTICA E TERAPÊUTICA', descricao: 'MÉDICOS EM MEDICINA DIAGNÓSTICA E TERAPÊUTICA' },
  { id: '517415 Medidor de temperatura corporal', descricao: 'Medidor de temperatura corporal' },
  { id: '621005 Meeiro - na agropecuária - exclusive conta própria e empregador', descricao: 'Meeiro - na agropecuária - exclusive conta própria e empregador' },
  { id: '611005 Meeiro na agropecuária - conta própria', descricao: 'Meeiro na agropecuária - conta própria' },
  { id: '612005 Meeiro polivalente', descricao: 'Meeiro polivalente' },
  { id: '717020 Meia-colher', descricao: 'Meia-colher' },
  { id: '711405 Meia-praça (garimpo)', descricao: 'Meia-praça (garimpo)' },
  { id: '377110 Meio campista no futebol', descricao: 'Meio campista no futebol' },
  { id: '911205 Meio oficial de mecânico de refrigeração', descricao: 'Meio oficial de mecânico de refrigeração' },
  { id: '911205 Meio oficial mecânico de ar condicionado', descricao: 'Meio oficial mecânico de ar condicionado' },
  { id: '263105 Mejitó', descricao: 'Mejitó' },
  { id: '842105 Melaceador (cultura do fumo)', descricao: 'Melaceador (cultura do fumo)' },
  { id: '613405 Meleiro', descricao: 'Meleiro' },
  { id: '113015 Membro de liderança quilombola', descricao: 'Membro de liderança quilombola' },
  { id: '111225 Membro superior do poder executivo', descricao: 'Membro superior do poder executivo' },
  { id: '2422 MEMBROS DO MINISTÉRIO PÚBLICO', descricao: 'MEMBROS DO MINISTÉRIO PÚBLICO' },
  { id: '261515 Memorialista de ficção', descricao: 'Memorialista de ficção' },
  { id: '261520 Memorialista de não-ficção', descricao: 'Memorialista de não-ficção' },
  { id: '421210 Mensageira', descricao: 'Mensageira' },
  { id: '412205 Mensageiro', descricao: 'Mensageiro' },
  { id: '415205 Mensageiro (correios e telégrafos)', descricao: 'Mensageiro (correios e telégrafos)' },
  { id: '412205 Mensageiro externo', descricao: 'Mensageiro externo' },
  { id: '412205 Mensageiro interno', descricao: 'Mensageiro interno' },
  { id: '263105 Meôncia', descricao: 'Meôncia' },
  { id: '141410 Merceeiro', descricao: 'Merceeiro' },
  { id: '761425 Mercerizador de fios e tecidos', descricao: 'Mercerizador de fios e tecidos' },
  { id: '513205 Merendeiro', descricao: 'Merendeiro' },
  { id: '519805 Meretriz', descricao: 'Meretriz' },
  { id: '781705 Mergulhador', descricao: 'Mergulhador' },
  { id: '631015 Mergulhador - pescador de lagosta', descricao: 'Mergulhador - pescador de lagosta' },
  { id: '781705 Mergulhador profissional (raso e profundo)', descricao: 'Mergulhador profissional (raso e profundo)' },
  { id: '519805 Messalina', descricao: 'Messalina' },
  { id: '720105 Mestre (afiador de ferramentas)', descricao: 'Mestre (afiador de ferramentas)' },
  { id: '710205 Mestre (construção civil)', descricao: 'Mestre (construção civil)' },
  { id: '720205 Mestre (construção naval)', descricao: 'Mestre (construção naval)' },
  { id: '750105 Mestre (fabricação de artigos de ourivesaria e joalheria)', descricao: 'Mestre (fabricação de artigos de ourivesaria e joalheria)' },
  { id: '720210 Mestre (indústria de automotores e material de transportes)', descricao: 'Mestre (indústria de automotores e material de transportes)' },
  { id: '810205 Mestre (indústria de borracha e plástico)', descricao: 'Mestre (indústria de borracha e plástico)' },
  { id: '760405 Mestre (indústria de calçados e artefatos de couro)', descricao: 'Mestre (indústria de calçados e artefatos de couro)' },
  { id: '830105 Mestre (indústria de celulose, papel e papelão)', descricao: 'Mestre (indústria de celulose, papel e papelão)' },
  { id: '770105 Mestre (indústria de madeira e mobiliário)', descricao: 'Mestre (indústria de madeira e mobiliário)' },
  { id: '720215 Mestre (indústria de máquinas e outros equipamentos mecânicos)', descricao: 'Mestre (indústria de máquinas e outros equipamentos mecânicos)' },
  { id: '810105 Mestre (indústria petroquímica e carboquímica)', descricao: 'Mestre (indústria petroquímica e carboquímica)' },
  { id: '760125 Mestre (indústria têxtil e de confecções)', descricao: 'Mestre (indústria têxtil e de confecções)' },
  { id: '750105 Mestre (lapidação de pedras preciosas)', descricao: 'Mestre (lapidação de pedras preciosas)' },
  { id: '720145 Mestre acabador de produtos extrusados', descricao: 'Mestre acabador de produtos extrusados' },
  { id: '720110 Mestre caldeireiro', descricao: 'Mestre caldeireiro' },
  { id: '770110 Mestre carpinteiro', descricao: 'Mestre carpinteiro' },
  { id: '840110 Mestre cervejeiro', descricao: 'Mestre cervejeiro' },
  { id: '840105 Mestre chocolateiro', descricao: 'Mestre chocolateiro' },
  { id: '840110 Mestre da indústria de bebidas', descricao: 'Mestre da indústria de bebidas' },
  { id: '840115 Mestre da indústria de fumo', descricao: 'Mestre da indústria de fumo' },
  { id: '730105 Mestre da indústria de material elétrico e eletrônico', descricao: 'Mestre da indústria de material elétrico e eletrônico' },
  { id: '750205 Mestre da indústria de minerais não metálicos (exceto os derivados de petróleo e carvão)', descricao: 'Mestre da indústria de minerais não metálicos (exceto os derivados de petróleo e carvão)' },
  { id: '840105 Mestre da indústria de produtos alimentícios', descricao: 'Mestre da indústria de produtos alimentícios' },
  { id: '760605 Mestre das artes gráficas (indústria editorial e gráfica)', descricao: 'Mestre das artes gráficas (indústria editorial e gráfica)' },
  { id: '760125 Mestre de acabamento de fios', descricao: 'Mestre de acabamento de fios' },
  { id: '810205 Mestre de acabamento de peças (plástico e borracha)', descricao: 'Mestre de acabamento de peças (plástico e borracha)' },
  { id: '760125 Mestre de acabamento em confecção', descricao: 'Mestre de acabamento em confecção' },
  { id: '820110 Mestre de aciaria', descricao: 'Mestre de aciaria' },
  { id: '720105 Mestre de afiação (ferramentas)', descricao: 'Mestre de afiação (ferramentas)' },
  { id: '720150 Mestre de ajustagem e usinagem', descricao: 'Mestre de ajustagem e usinagem' },
  { id: '820115 Mestre de alto-forno', descricao: 'Mestre de alto-forno' },
  { id: '760125 Mestre de alvejamento de tecidos', descricao: 'Mestre de alvejamento de tecidos' },
  { id: '720130 Mestre de anodização', descricao: 'Mestre de anodização' },
  { id: '262615 Mestre de banda', descricao: 'Mestre de banda' },
  { id: '262615 Mestre de bateria', descricao: 'Mestre de bateria' },
  { id: '760125 Mestre de bobinadeira têxtil', descricao: 'Mestre de bobinadeira têxtil' },
  { id: '341210 Mestre de cabotagem', descricao: 'Mestre de cabotagem' },
  { id: '720110 Mestre de caldeiraria', descricao: 'Mestre de caldeiraria' },
  { id: '376330 Mestre de cerimonias', descricao: 'Mestre de cerimonias' },
  { id: '760125 Mestre de cerzideira', descricao: 'Mestre de cerzideira' },
  { id: '840105 Mestre de conservas', descricao: 'Mestre de conservas' },
  { id: '710205 Mestre de construção civil', descricao: 'Mestre de construção civil' },
  { id: '720115 Mestre de construção de ferramentas e dispositivos', descricao: 'Mestre de construção de ferramentas e dispositivos' },
  { id: '720220 Mestre de construção de fornos', descricao: 'Mestre de construção de fornos' },
  { id: '720115 Mestre de construção de matrizes', descricao: 'Mestre de construção de matrizes' },
  { id: '720115 Mestre de correção de ferramentas', descricao: 'Mestre de correção de ferramentas' },
  { id: '720130 Mestre de cromação e lapidação de anéis', descricao: 'Mestre de cromação e lapidação de anéis' },
  { id: '840110 Mestre de engarrafamento (bebidas)', descricao: 'Mestre de engarrafamento (bebidas)' },
  { id: '760125 Mestre de espularia', descricao: 'Mestre de espularia' },
  { id: '760125 Mestre de estampagem', descricao: 'Mestre de estampagem' },
  { id: '720130 Mestre de estanhamento', descricao: 'Mestre de estanhamento' },
  { id: '830105 Mestre de fabricação e montagem de caixas (papelão)', descricao: 'Mestre de fabricação e montagem de caixas (papelão)' },
  { id: '720115 Mestre de ferramentaria', descricao: 'Mestre de ferramentaria' },
  { id: '720115 Mestre de ferramentas', descricao: 'Mestre de ferramentas' },
  { id: '760125 Mestre de fiação', descricao: 'Mestre de fiação' },
  { id: '760125 Mestre de fiação de algodão', descricao: 'Mestre de fiação de algodão' },
  { id: '760125 Mestre de fiação de juta', descricao: 'Mestre de fiação de juta' },
  { id: '760125 Mestre de fiação de lã', descricao: 'Mestre de fiação de lã' },
  { id: '760125 Mestre de fiação de linho', descricao: 'Mestre de fiação de linho' },
  { id: '760125 Mestre de fiação de tecelagem', descricao: 'Mestre de fiação de tecelagem' },
  { id: '760125 Mestre de fiação e tecelagem de rami', descricao: 'Mestre de fiação e tecelagem de rami' },
  { id: '720120 Mestre de forjaria', descricao: 'Mestre de forjaria' },
  { id: '820120 Mestre de forno de resistência', descricao: 'Mestre de forno de resistência' },
  { id: '820120 Mestre de forno elétrico', descricao: 'Mestre de forno elétrico' },
  { id: '720125 Mestre de forno fundição', descricao: 'Mestre de forno fundição' },
  { id: '760605 Mestre de fotogravuras', descricao: 'Mestre de fotogravuras' },
  { id: '760605 Mestre de fotolito', descricao: 'Mestre de fotolito' },
  { id: '720125 Mestre de fundição', descricao: 'Mestre de fundição' },
  { id: '720125 Mestre de fundição de alumínio', descricao: 'Mestre de fundição de alumínio' },
  { id: '720125 Mestre de fundição de materiais', descricao: 'Mestre de fundição de materiais' },
  { id: '720130 Mestre de galvanização', descricao: 'Mestre de galvanização' },
  { id: '720130 Mestre de galvanoplastia', descricao: 'Mestre de galvanoplastia' },
  { id: '760605 Mestre de gravação (indústria gráfica)', descricao: 'Mestre de gravação (indústria gráfica)' },
  { id: '760125 Mestre de indústria têxtil', descricao: 'Mestre de indústria têxtil' },
  { id: '720135 Mestre de inspeção de pinturas (tratamento de superfícies)', descricao: 'Mestre de inspeção de pinturas (tratamento de superfícies)' },
  { id: '710205 Mestre de instalações mecânicas de edifícios', descricao: 'Mestre de instalações mecânicas de edifícios' },
  { id: '820125 Mestre de laminação', descricao: 'Mestre de laminação' },
  { id: '720125 Mestre de lingotamento', descricao: 'Mestre de lingotamento' },
  { id: '720125 Mestre de lingoteiras', descricao: 'Mestre de lingoteiras' },
  { id: '720210 Mestre de linha de montagem (indústria automobilística)', descricao: 'Mestre de linha de montagem (indústria automobilística)' },
  { id: '710210 Mestre de linha férrea', descricao: 'Mestre de linha férrea' },
  { id: '710210 Mestre de linhas (ferrovias)', descricao: 'Mestre de linhas (ferrovias)' },
  { id: '720125 Mestre de macharia', descricao: 'Mestre de macharia' },
  { id: '760125 Mestre de malharia', descricao: 'Mestre de malharia' },
  { id: '911305 Mestre de manutenção de equipamento de solda', descricao: 'Mestre de manutenção de equipamento de solda' },
  { id: '910125 Mestre de manutenção de máquinas de cardas', descricao: 'Mestre de manutenção de máquinas de cardas' },
  { id: '910125 Mestre de manutenção de máquinas de estampar tecidos', descricao: 'Mestre de manutenção de máquinas de estampar tecidos' },
  { id: '910125 Mestre de manutenção de máquinas de fiação', descricao: 'Mestre de manutenção de máquinas de fiação' },
  { id: '910105 Mestre de manutenção de máquinas de sistemas operacionais', descricao: 'Mestre de manutenção de máquinas de sistemas operacionais' },
  { id: '910130 Mestre de manutenção de máquinas operatrizes', descricao: 'Mestre de manutenção de máquinas operatrizes' },
  { id: '710205 Mestre de manutenção de obras civis', descricao: 'Mestre de manutenção de obras civis' },
  { id: '710205 Mestre de manutenção de prédios', descricao: 'Mestre de manutenção de prédios' },
  { id: '910125 Mestre de manutenção de tecelagem', descricao: 'Mestre de manutenção de tecelagem' },
  { id: '730105 Mestre de manutenção elétrica', descricao: 'Mestre de manutenção elétrica' },
  { id: '950105 Mestre de manutenção elétrica (alta tensão)', descricao: 'Mestre de manutenção elétrica (alta tensão)' },
  { id: '950105 Mestre de manutenção elétrica e equipamentos', descricao: 'Mestre de manutenção elétrica e equipamentos' },
  { id: '950110 Mestre de manutenção eletromecânica', descricao: 'Mestre de manutenção eletromecânica' },
  { id: '950110 Mestre de manutenção eletromecânica de equipamentos', descricao: 'Mestre de manutenção eletromecânica de equipamentos' },
  { id: '730105 Mestre de manutenção eletrônica', descricao: 'Mestre de manutenção eletrônica' },
  { id: '910105 Mestre de manutenção industrial de máquinas', descricao: 'Mestre de manutenção industrial de máquinas' },
  { id: '910105 Mestre de manutenção mecânica de sistemas operacionais', descricao: 'Mestre de manutenção mecânica de sistemas operacionais' },
  { id: '840105 Mestre de massas alimentícias', descricao: 'Mestre de massas alimentícias' },
  { id: '720125 Mestre de molde manual', descricao: 'Mestre de molde manual' },
  { id: '730105 Mestre de montagem de motores elétricos', descricao: 'Mestre de montagem de motores elétricos' },
  { id: '720130 Mestre de niquelagem', descricao: 'Mestre de niquelagem' },
  { id: '710205 Mestre de obras', descricao: 'Mestre de obras' },
  { id: '710205 Mestre de obras civis', descricao: 'Mestre de obras civis' },
  { id: '341210 Mestre de pequena cabotagem', descricao: 'Mestre de pequena cabotagem' },
  { id: '720135 Mestre de pintura (tratamento de superfícies)', descricao: 'Mestre de pintura (tratamento de superfícies)' },
  { id: '720130 Mestre de pintura galvanoplástica', descricao: 'Mestre de pintura galvanoplástica' },
  { id: '720130 Mestre de polimento e banho (metais)', descricao: 'Mestre de polimento e banho (metais)' },
  { id: '840115 Mestre de preparação de fumo', descricao: 'Mestre de preparação de fumo' },
  { id: '760125 Mestre de preparação de tear', descricao: 'Mestre de preparação de tear' },
  { id: '760125 Mestre de preparação de tecelagem', descricao: 'Mestre de preparação de tecelagem' },
  { id: '760125 Mestre de preparação e revisão de bordados', descricao: 'Mestre de preparação e revisão de bordados' },
  { id: '720215 Mestre de produção (indústria de máquinas e outros equipamentos mecânicos)', descricao: 'Mestre de produção (indústria de máquinas e outros equipamentos mecânicos)' },
  { id: '760605 Mestre de produção (indústria gráfica)', descricao: 'Mestre de produção (indústria gráfica)' },
  { id: '720125 Mestre de produção de fundição de alumínio', descricao: 'Mestre de produção de fundição de alumínio' },
  { id: '810305 Mestre de produção farmacêutica', descricao: 'Mestre de produção farmacêutica' },
  { id: '810110 Mestre de produção química', descricao: 'Mestre de produção química' },
  { id: '760125 Mestre de retorcedeira', descricao: 'Mestre de retorcedeira' },
  { id: '720110 Mestre de seção de caldeiraria', descricao: 'Mestre de seção de caldeiraria' },
  { id: '720110 Mestre de seção de caldeiraria leve', descricao: 'Mestre de seção de caldeiraria leve' },
  { id: '720110 Mestre de seção de caldeiraria pesada', descricao: 'Mestre de seção de caldeiraria pesada' },
  { id: '720120 Mestre de seção de forjaria (fornos)', descricao: 'Mestre de seção de forjaria (fornos)' },
  { id: '720125 Mestre de seção de fundição', descricao: 'Mestre de seção de fundição' },
  { id: '720150 Mestre de seção de usinagem', descricao: 'Mestre de seção de usinagem' },
  { id: '760605 Mestre de serviços gráficos', descricao: 'Mestre de serviços gráficos' },
  { id: '820105 Mestre de siderurgia', descricao: 'Mestre de siderurgia' },
  { id: '720140 Mestre de solda e corte', descricao: 'Mestre de solda e corte' },
  { id: '720140 Mestre de soldagem', descricao: 'Mestre de soldagem' },
  { id: '710210 Mestre de supervisão de linhas (ferrovias)', descricao: 'Mestre de supervisão de linhas (ferrovias)' },
  { id: '760125 Mestre de tapeçaria', descricao: 'Mestre de tapeçaria' },
  { id: '760125 Mestre de tecelagem', descricao: 'Mestre de tecelagem' },
  { id: '760125 Mestre de tecelagem de juta', descricao: 'Mestre de tecelagem de juta' },
  { id: '760125 Mestre de tecelagem de malhas', descricao: 'Mestre de tecelagem de malhas' },
  { id: '760125 Mestre de tecelagem de máquina circular', descricao: 'Mestre de tecelagem de máquina circular' },
  { id: '760125 Mestre de tecelagem de rendas', descricao: 'Mestre de tecelagem de rendas' },
  { id: '760125 Mestre de tecelagem de telas', descricao: 'Mestre de tecelagem de telas' },
  { id: '760125 Mestre de texturização de fios', descricao: 'Mestre de texturização de fios' },
  { id: '760125 Mestre de tingimento (indústria têxtil e de confecções)', descricao: 'Mestre de tingimento (indústria têxtil e de confecções)' },
  { id: '760125 Mestre de torção de fios', descricao: 'Mestre de torção de fios' },
  { id: '720145 Mestre de trefilação (metal)', descricao: 'Mestre de trefilação (metal)' },
  { id: '720145 Mestre de trefilação de metais', descricao: 'Mestre de trefilação de metais' },
  { id: '760125 Mestre de urdimento', descricao: 'Mestre de urdimento' },
  { id: '720150 Mestre de usinagem', descricao: 'Mestre de usinagem' },
  { id: '720150 Mestre de usinagem e montagem', descricao: 'Mestre de usinagem e montagem' },
  { id: '720130 Mestre de zincagem', descricao: 'Mestre de zincagem' },
  { id: '840120 Mestre doceiro', descricao: 'Mestre doceiro' },
  { id: '860105 Mestre e contramestre de manutenção de serviço de esgoto', descricao: 'Mestre e contramestre de manutenção de serviço de esgoto' },
  { id: '860115 Mestre e contramestre de produção e distribuição de água', descricao: 'Mestre e contramestre de produção e distribuição de água' },
  { id: '730105 Mestre eletricista de bobinas estacionárias', descricao: 'Mestre eletricista de bobinas estacionárias' },
  { id: '950105 Mestre eletricista de manutenção', descricao: 'Mestre eletricista de manutenção' },
  { id: '730105 Mestre eletricista enrolador de bobinas', descricao: 'Mestre eletricista enrolador de bobinas' },
  { id: '750105 Mestre em artigos de ourivesaria e joalheria', descricao: 'Mestre em artigos de ourivesaria e joalheria' },
  { id: '720120 Mestre ferreiro', descricao: 'Mestre ferreiro' },
  { id: '341215 Mestre fluvial', descricao: 'Mestre fluvial' },
  { id: '720125 Mestre fundidor a pressão', descricao: 'Mestre fundidor a pressão' },
  { id: '760605 Mestre gráfico', descricao: 'Mestre gráfico' },
  { id: '760605 Mestre gravador de clichê', descricao: 'Mestre gravador de clichê' },
  { id: '760125 Mestre gravador têxtil', descricao: 'Mestre gravador têxtil' },
  { id: '760605 Mestre impressor', descricao: 'Mestre impressor' },
  { id: '760605 Mestre impressor calcográfico', descricao: 'Mestre impressor calcográfico' },
  { id: '760605 Mestre impressor de off-set', descricao: 'Mestre impressor de off-set' },
  { id: '760605 Mestre litográfico', descricao: 'Mestre litográfico' },
  { id: '910125 Mestre mecânico de conicaleira', descricao: 'Mestre mecânico de conicaleira' },
  { id: '720145 Mestre mecânico de extrusão de alumínio', descricao: 'Mestre mecânico de extrusão de alumínio' },
  { id: '720130 Mestre niquelador', descricao: 'Mestre niquelador' },
  { id: '840105 Mestre padeiro', descricao: 'Mestre padeiro' },
  { id: '760405 Mestre sapateiro', descricao: 'Mestre sapateiro' },
  { id: '720155 Mestre serralheiro', descricao: 'Mestre serralheiro' },
  { id: '720155 Mestre serralheiro de alumínio', descricao: 'Mestre serralheiro de alumínio' },
  { id: '720155 Mestre serralheiro de estamparia', descricao: 'Mestre serralheiro de estamparia' },
  { id: '760605 Mestre tipógrafo', descricao: 'Mestre tipógrafo' },
  { id: '752105 Mestre vidreiro', descricao: 'Mestre vidreiro' },
  { id: '225155 Metabolista', descricao: 'Metabolista' },
  { id: '225155 Metabologista', descricao: 'Metabologista' },
  { id: '723225 Metalizador (banho quente)', descricao: 'Metalizador (banho quente)' },
  { id: '723220 Metalizador a pistola', descricao: 'Metalizador a pistola' },
  { id: '314620 Metalógrafo (técnico)', descricao: 'Metalógrafo (técnico)' },
  { id: '213315 Meteorologista', descricao: 'Meteorologista' },
  { id: '352305 Metrologista', descricao: 'Metrologista' },
  { id: '352305 Metrologista auxiliar', descricao: 'Metrologista auxiliar' },
  { id: '201210 Metrologista de calibrações', descricao: 'Metrologista de calibrações' },
  { id: '201215 Metrologista de ensaios', descricao: 'Metrologista de ensaios' },
  { id: '263105 Metropolita', descricao: 'Metropolita' },
  { id: '519805 Michê', descricao: 'Michê' },
  { id: '221105 Microbiologista', descricao: 'Microbiologista' },
  { id: '374140 Microfonista', descricao: 'Microfonista' },
  { id: '262505 Mímico', descricao: 'Mímico' },
  { id: '711130 Mineiro', descricao: 'Mineiro' },
  { id: '711130 Minerador', descricao: 'Minerador' },
  { id: '711405 Minerador - no garimpo', descricao: 'Minerador - no garimpo' },
  { id: '613415 Minhocultor', descricao: 'Minhocultor' },
  { id: '613415 Minhoqueiro', descricao: 'Minhoqueiro' },
  { id: '623415 Minhoqueiro - exclusive conta própria e empregador', descricao: 'Minhoqueiro - exclusive conta própria e empregador' },
  { id: '752105 Miniaturista (artesão de vidros)', descricao: 'Miniaturista (artesão de vidros)' },
  { id: '111405 Ministro (diplomacia)', descricao: 'Ministro (diplomacia)' },
  { id: '263105 Ministro da eucaristia', descricao: 'Ministro da eucaristia' },
  { id: '263105 Ministro das ezéquias', descricao: 'Ministro das ezéquias' },
  { id: '263105 Ministro de culto religioso', descricao: 'Ministro de culto religioso' },
  { id: '111215 Ministro de estado', descricao: 'Ministro de estado' },
  { id: '111320 Ministro do superior tribunal do trabalho', descricao: 'Ministro do superior tribunal do trabalho' },
  { id: '111315 Ministro do superior tribunal militar', descricao: 'Ministro do superior tribunal militar' },
  { id: '111310 Ministro do superior tribunal de justiça', descricao: 'Ministro do superior tribunal de justiça' },
  { id: '111305 Ministro do supremo tribunal federal', descricao: 'Ministro do supremo tribunal federal' },
  { id: '2631 MINISTROS DE CULTO, MISSIONÁRIOS, TEÓLOGOS E PROFISSIONAIS ASSEMELHADOS', descricao: 'MINISTROS DE CULTO, MISSIONÁRIOS, TEÓLOGOS E PROFISSIONAIS ASSEMELHADOS' },
  { id: '263110 Missionário', descricao: 'Missionário' },
  { id: '263110 Missionário leigo', descricao: 'Missionário leigo' },
  { id: '263110 Missionário religioso', descricao: 'Missionário religioso' },
  { id: '263110 Missionário sacerdote', descricao: 'Missionário sacerdote' },
  { id: '722325 Misturador de areias - em fundição', descricao: 'Misturador de areias - em fundição' },
  { id: '841605 Misturador de café', descricao: 'Misturador de café' },
  { id: '841630 Misturador de chá ou mate', descricao: 'Misturador de chá ou mate' },
  { id: '842105 Misturador de essência (fumo)', descricao: 'Misturador de essência (fumo)' },
  { id: '823210 Misturador de fios de fibras de vidro', descricao: 'Misturador de fios de fibras de vidro' },
  { id: '841810 Misturador de massas alimentícias', descricao: 'Misturador de massas alimentícias' },
  { id: '811130 Misturador de tintas', descricao: 'Misturador de tintas' },
  { id: '841740 Misturador de vinagre', descricao: 'Misturador de vinagre' },
  { id: '841720 Misturador de vinhos', descricao: 'Misturador de vinhos' },
  { id: '631315 Mitilicutor', descricao: 'Mitilicutor' },
  { id: '848525 Miudeiro em matadouro', descricao: 'Miudeiro em matadouro' },
  { id: '374130 Mixador', descricao: 'Mixador' },
  { id: '841115 Moageiro', descricao: 'Moageiro' },
  { id: '411055 Mobilizador de recursos', descricao: 'Mobilizador de recursos' },
  { id: '782715 Moço de convés', descricao: 'Moço de convés' },
  { id: '782715 Moço de convés (marítimo e fluviário)', descricao: 'Moço de convés (marítimo e fluviário)' },
  { id: '782720 Moço de máquinas', descricao: 'Moço de máquinas' },
  { id: '782720 Moço de máquinas (marítimo e fluviário)', descricao: 'Moço de máquinas (marítimo e fluviário)' },
  { id: '848525 Mocotozeiro em matadouro', descricao: 'Mocotozeiro em matadouro' },
  { id: '811750 Modador-prensista (borracha)', descricao: 'Modador-prensista (borracha)' },
  { id: '752320 Modelador ceramista', descricao: 'Modelador ceramista' },
  { id: '765010 Modelador de chapéus', descricao: 'Modelador de chapéus' },
  { id: '721115 Modelador de ferramentaria', descricao: 'Modelador de ferramentaria' },
  { id: '771110 Modelador de fundição (madeira)', descricao: 'Modelador de fundição (madeira)' },
  { id: '771110 Modelador de madeira', descricao: 'Modelador de madeira' },
  { id: '721115 Modelador de metais (fundição)', descricao: 'Modelador de metais (fundição)' },
  { id: '811715 Modelador de pneumáticos', descricao: 'Modelador de pneumáticos' },
  { id: '752320 Modelador de porcelana', descricao: 'Modelador de porcelana' },
  { id: '752320 Modelador em cerâmica', descricao: 'Modelador em cerâmica' },
  { id: '763010 Modelista', descricao: 'Modelista' },
  { id: '318815 Modelista de calçados', descricao: 'Modelista de calçados' },
  { id: '771110 Modelista de madeira', descricao: 'Modelista de madeira' },
  { id: '318810 Modelista de roupas', descricao: 'Modelista de roupas' },
  { id: '752320 Modelista em cerâmica', descricao: 'Modelista em cerâmica' },
  { id: '376410 Modelo "fashion"', descricao: 'Modelo "fashion"' },
  { id: '376405 Modelo artístico', descricao: 'Modelo artístico' },
  { id: '376415 Modelo comercial', descricao: 'Modelo comercial' },
  { id: '376415 Modelo de detalhes', descricao: 'Modelo de detalhes' },
  { id: '376415 Modelo de editorial de moda', descricao: 'Modelo de editorial de moda' },
  { id: '376410 Modelo de modas', descricao: 'Modelo de modas' },
  { id: '376410 Modelo de passarela', descricao: 'Modelo de passarela' },
  { id: '376415 Modelo fotográfico', descricao: 'Modelo fotográfico' },
  { id: '376405 Modelo fotográfico de nu artístico', descricao: 'Modelo fotográfico de nu artístico' },
  { id: '376415 Modelo fotográfico de workshop', descricao: 'Modelo fotográfico de workshop' },
  { id: '376415 Modelo publicitário', descricao: 'Modelo publicitário' },
  { id: '376405 Modelo vivo', descricao: 'Modelo vivo' },
  { id: '3764 MODELOS', descricao: 'MODELOS' },
  { id: '841615 Moedor de café', descricao: 'Moedor de café' },
  { id: '841110 Moedor de condimento', descricao: 'Moedor de condimento' },
  { id: '841110 Moedor de especiarias', descricao: 'Moedor de especiarias' },
  { id: '841115 Moedor de farinha', descricao: 'Moedor de farinha' },
  { id: '841205 Moedor de sal', descricao: 'Moedor de sal' },
  { id: '841115 Moendeiro', descricao: 'Moendeiro' },
  { id: '841105 Moendeiro (farinheiro)', descricao: 'Moendeiro (farinheiro)' },
  { id: '632310 Moezeiro - na exploração do babaçu', descricao: 'Moezeiro - na exploração do babaçu' },
  { id: '752110 Moldador (vidros)', descricao: 'Moldador (vidros)' },
  { id: '823230 Moldador de abrasivos na fabricação de cerâmica, vidro e porcelana', descricao: 'Moldador de abrasivos na fabricação de cerâmica, vidro e porcelana' },
  { id: '722320 Moldador de bloco - na fundição', descricao: 'Moldador de bloco - na fundição' },
  { id: '811750 Moldador de borracha', descricao: 'Moldador de borracha' },
  { id: '811750 Moldador de borracha por compressão', descricao: 'Moldador de borracha por compressão' },
  { id: '752325 Moldador de cerâmica', descricao: 'Moldador de cerâmica' },
  { id: '768125 Moldador de chapéus de palha', descricao: 'Moldador de chapéus de palha' },
  { id: '715310 Moldador de corpos de prova em usinas de concreto', descricao: 'Moldador de corpos de prova em usinas de concreto' },
  { id: '722320 Moldador de fundição (metais)', descricao: 'Moldador de fundição (metais)' },
  { id: '752110 Moldador de lentes', descricao: 'Moldador de lentes' },
  { id: '722305 Moldador de machos (manual)', descricao: 'Moldador de machos (manual)' },
  { id: '722320 Moldador de metal', descricao: 'Moldador de metal' },
  { id: '811735 Moldador de parafinas', descricao: 'Moldador de parafinas' },
  { id: '811760 Moldador de plástico', descricao: 'Moldador de plástico' },
  { id: '811760 Moldador de plástico por compressão', descricao: 'Moldador de plástico por compressão' },
  { id: '811770 Moldador de plástico por injeção', descricao: 'Moldador de plástico por injeção' },
  { id: '318810 Moldador de roupas', descricao: 'Moldador de roupas' },
  { id: '722315 Moldador em areia', descricao: 'Moldador em areia' },
  { id: '752325 Moldador em barbotina', descricao: 'Moldador em barbotina' },
  { id: '752325 Moldador em cerâmica', descricao: 'Moldador em cerâmica' },
  { id: '722305 Moldador macheiro (manual)', descricao: 'Moldador macheiro (manual)' },
  { id: '811750 Moldador prensista (borracha)', descricao: 'Moldador prensista (borracha)' },
  { id: '722315 Moldador, a mão', descricao: 'Moldador, a mão' },
  { id: '722320 Moldador, a máquina', descricao: 'Moldador, a máquina' },
  { id: '848210 Moldeador de queijo', descricao: 'Moldeador de queijo' },
  { id: '811105 Moleiro (tratamentos químicos e afins)', descricao: 'Moleiro (tratamentos químicos e afins)' },
  { id: '841105 Moleiro de cereais (exceto arroz)', descricao: 'Moleiro de cereais (exceto arroz)' },
  { id: '841110 Moleiro de especiarias', descricao: 'Moleiro de especiarias' },
  { id: '712105 Moleiro de minérios', descricao: 'Moleiro de minérios' },
  { id: '263105 Monge', descricao: 'Monge' },
  { id: '263105 Monge budista', descricao: 'Monge budista' },
  { id: '263105 Monge oficial responsável por templo budista (Jushoku)', descricao: 'Monge oficial responsável por templo budista (Jushoku)' },
  { id: '620105 Monitor agrícola', descricao: 'Monitor agrícola' },
  { id: '371405 Monitor de acampamento', descricao: 'Monitor de acampamento' },
  { id: '371405 Monitor de acantonamento', descricao: 'Monitor de acantonamento' },
  { id: '334110 Monitor de alunos', descricao: 'Monitor de alunos' },
  { id: '422335 Monitor de apoio ao teleatendimento', descricao: 'Monitor de apoio ao teleatendimento' },
  { id: '239225 Monitor de braile', descricao: 'Monitor de braile' },
  { id: '421310 Monitor de cobrança', descricao: 'Monitor de cobrança' },
  { id: '515315 Monitor de dependente químico', descricao: 'Monitor de dependente químico' },
  { id: '371410 Monitor de entretenimento', descricao: 'Monitor de entretenimento' },
  { id: '371410 Monitor de esportes e lazer', descricao: 'Monitor de esportes e lazer' },
  { id: '331305 Monitor de nível médio no ensino profissionalizante', descricao: 'Monitor de nível médio no ensino profissionalizante' },
  { id: '517425 Monitor de prevenção de perdas', descricao: 'Monitor de prevenção de perdas' },
  { id: '371410 Monitor de recreação', descricao: 'Monitor de recreação' },
  { id: '515330 Monitor de ressocialização prisional', descricao: 'Monitor de ressocialização prisional' },
  { id: '951320 Monitor de sistemas eletrônicos de segurança externo', descricao: 'Monitor de sistemas eletrônicos de segurança externo' },
  { id: '951315 Monitor de sistemas eletrônicos de segurança interno', descricao: 'Monitor de sistemas eletrônicos de segurança interno' },
  { id: '422335 Monitor de teleatendimento', descricao: 'Monitor de teleatendimento' },
  { id: '334115 Monitor de transporte escolar', descricao: 'Monitor de transporte escolar' },
  { id: '515330 Monitor disciplinar prisional', descricao: 'Monitor disciplinar prisional' },
  { id: '951320 Monitor externo de alarmes', descricao: 'Monitor externo de alarmes' },
  { id: '371410 Monitor infantil', descricao: 'Monitor infantil' },
  { id: '951315 Monitor interno de alarmes', descricao: 'Monitor interno de alarmes' },
  { id: '768615 Monotipista', descricao: 'Monotipista' },
  { id: '263105 Monsenhor', descricao: 'Monsenhor' },
  { id: '374420 Montador', descricao: 'Montador' },
  { id: '374420 Montador cinematográfico', descricao: 'Montador cinematográfico' },
  { id: '725105 Montador de acessórios', descricao: 'Montador de acessórios' },
  { id: '715515 Montador de andaimes (cenários)', descricao: 'Montador de andaimes (cenários)' },
  { id: '715545 Montador de andaimes (edificações)', descricao: 'Montador de andaimes (edificações)' },
  { id: '731135 Montador de aparelhos elétricos', descricao: 'Montador de aparelhos elétricos' },
  { id: '731150 Montador de aparelhos eletroeletrônicos', descricao: 'Montador de aparelhos eletroeletrônicos' },
  { id: '731150 Montador de aparelhos eletrônicos', descricao: 'Montador de aparelhos eletrônicos' },
  { id: '766120 Montador de arte final', descricao: 'Montador de arte final' },
  { id: '765315 Montador de artefatos de couro (exceto roupas e calçados)', descricao: 'Montador de artefatos de couro (exceto roupas e calçados)' },
  { id: '774105 Montador de artefatos de madeira', descricao: 'Montador de artefatos de madeira' },
  { id: '725505 Montador de automóveis', descricao: 'Montador de automóveis' },
  { id: '919315 Montador de bicicletas', descricao: 'Montador de bicicletas' },
  { id: '731170 Montador de bobinas', descricao: 'Montador de bobinas' },
  { id: '765215 Montador de brinquedos de pano', descricao: 'Montador de brinquedos de pano' },
  { id: '784105 Montador de caixa de papelão', descricao: 'Montador de caixa de papelão' },
  { id: '774105 Montador de caixas de madeira', descricao: 'Montador de caixas de madeira' },
  { id: '774105 Montador de caixotes de madeira', descricao: 'Montador de caixotes de madeira' },
  { id: '764210 Montador de calçados', descricao: 'Montador de calçados' },
  { id: '764115 Montador de calçados (parte superior)', descricao: 'Montador de calçados (parte superior)' },
  { id: '724410 Montador de caldeira', descricao: 'Montador de caldeira' },
  { id: '775110 Montador de capas trabalhadas', descricao: 'Montador de capas trabalhadas' },
  { id: '725505 Montador de carroceria', descricao: 'Montador de carroceria' },
  { id: '731125 Montador de centrais elétricas', descricao: 'Montador de centrais elétricas' },
  { id: '731305 Montador de centrais telefônicas', descricao: 'Montador de centrais telefônicas' },
  { id: '725505 Montador de chassi', descricao: 'Montador de chassi' },
  { id: '766145 Montador de clichês', descricao: 'Montador de clichês' },
  { id: '766145 Montador de clichês sobre suportes', descricao: 'Montador de clichês sobre suportes' },
  { id: '731140 Montador de comandos e sinalização', descricao: 'Montador de comandos e sinalização' },
  { id: '731110 Montador de computadores e equipamentos auxiliares', descricao: 'Montador de computadores e equipamentos auxiliares' },
  { id: '731130 Montador de dínamos', descricao: 'Montador de dínamos' },
  { id: '731120 Montador de eletrodomésticos', descricao: 'Montador de eletrodomésticos' },
  { id: '731155 Montador de elevadores e similares', descricao: 'Montador de elevadores e similares' },
  { id: '784105 Montador de embalagens', descricao: 'Montador de embalagens' },
  { id: '774105 Montador de engradados de madeira', descricao: 'Montador de engradados de madeira' },
  { id: '725105 Montador de equipamento de escritório', descricao: 'Montador de equipamento de escritório' },
  { id: '725305 Montador de equipamento de levantamento', descricao: 'Montador de equipamento de levantamento' },
  { id: '725305 Montador de equipamentos de levantamento e movimentação', descricao: 'Montador de equipamentos de levantamento e movimentação' },
  { id: '731205 Montador de equipamentos de radar', descricao: 'Montador de equipamentos de radar' },
  { id: '731205 Montador de equipamentos de telecomunicações', descricao: 'Montador de equipamentos de telecomunicações' },
  { id: '731135 Montador de equipamentos elétricos', descricao: 'Montador de equipamentos elétricos' },
  { id: '731120 Montador de equipamentos elétricos (aparelhos eletrodomésticos)', descricao: 'Montador de equipamentos elétricos (aparelhos eletrodomésticos)' },
  { id: '731125 Montador de equipamentos elétricos (centrais elétricas)', descricao: 'Montador de equipamentos elétricos (centrais elétricas)' },
  { id: '731155 Montador de equipamentos elétricos (elevadores e equipamentos similares)', descricao: 'Montador de equipamentos elétricos (elevadores e equipamentos similares)' },
  { id: '731115 Montador de equipamentos elétricos (instrumentos de medição)', descricao: 'Montador de equipamentos elétricos (instrumentos de medição)' },
  { id: '731130 Montador de equipamentos elétricos (motores e dínamos)', descricao: 'Montador de equipamentos elétricos (motores e dínamos)' },
  { id: '731160 Montador de equipamentos elétricos (transformadores)', descricao: 'Montador de equipamentos elétricos (transformadores)' },
  { id: '731150 Montador de equipamentos eletrônicos', descricao: 'Montador de equipamentos eletrônicos' },
  { id: '731105 Montador de equipamentos eletrônicos (aparelhos médicos)', descricao: 'Montador de equipamentos eletrônicos (aparelhos médicos)' },
  { id: '731110 Montador de equipamentos eletrônicos (computadores e equipamentos auxiliares)', descricao: 'Montador de equipamentos eletrônicos (computadores e equipamentos auxiliares)' },
  { id: '731205 Montador de equipamentos eletrônicos (estação de rádio, tv e equipamentos de radar)', descricao: 'Montador de equipamentos eletrônicos (estação de rádio, tv e equipamentos de radar)' },
  { id: '731140 Montador de equipamentos eletrônicos (instalações de sinalização)', descricao: 'Montador de equipamentos eletrônicos (instalações de sinalização)' },
  { id: '731145 Montador de equipamentos eletrônicos (máquinas industriais)', descricao: 'Montador de equipamentos eletrônicos (máquinas industriais)' },
  { id: '731205 Montador de equipamentos eletrônicos (rádio e tv )', descricao: 'Montador de equipamentos eletrônicos (rádio e tv )' },
  { id: '715510 Montador de esquadrias de madeira', descricao: 'Montador de esquadrias de madeira' },
  { id: '724205 Montador de estruturas de aço', descricao: 'Montador de estruturas de aço' },
  { id: '725605 Montador de estruturas de aeronaves', descricao: 'Montador de estruturas de aeronaves' },
  { id: '724205 Montador de estruturas metálicas', descricao: 'Montador de estruturas metálicas' },
  { id: '724210 Montador de estruturas metálicas de embarcações', descricao: 'Montador de estruturas metálicas de embarcações' },
  { id: '724210 Montador de estruturas navais', descricao: 'Montador de estruturas navais' },
  { id: '523115 Montador de fechaduras', descricao: 'Montador de fechaduras' },
  { id: '374420 Montador de filmes', descricao: 'Montador de filmes' },
  { id: '374420 Montador de filmes de cinema', descricao: 'Montador de filmes de cinema' },
  { id: '766125 Montador de fotocomposição', descricao: 'Montador de fotocomposição' },
  { id: '766125 Montador de fotolito (analógico e digital)', descricao: 'Montador de fotolito (analógico e digital)' },
  { id: '766125 Montador de fotolito em cores', descricao: 'Montador de fotolito em cores' },
  { id: '725705 Montador de instalação de calefação, ventilação e refrigeração', descricao: 'Montador de instalação de calefação, ventilação e refrigeração' },
  { id: '731115 Montador de instrumentos elétricos de medição', descricao: 'Montador de instrumentos elétricos de medição' },
  { id: '742115 Montador de instrumentos de corda', descricao: 'Montador de instrumentos de corda' },
  { id: '741110 Montador de instrumentos de óptica', descricao: 'Montador de instrumentos de óptica' },
  { id: '741115 Montador de instrumentos de precisão', descricao: 'Montador de instrumentos de precisão' },
  { id: '775110 Montador de lâminas', descricao: 'Montador de lâminas' },
  { id: '731125 Montador de linha de transmissão e rede de distribuição', descricao: 'Montador de linha de transmissão e rede de distribuição' },
  { id: '725105 Montador de máquina de escrever', descricao: 'Montador de máquina de escrever' },
  { id: '725205 Montador de máquinas', descricao: 'Montador de máquinas' },
  { id: '725105 Montador de máquinas (montagem em série)', descricao: 'Montador de máquinas (montagem em série)' },
  { id: '725310 Montador de máquinas agrícolas', descricao: 'Montador de máquinas agrícolas' },
  { id: '725315 Montador de máquinas de extração e beneficiamento de minérios', descricao: 'Montador de máquinas de extração e beneficiamento de minérios' },
  { id: '725220 Montador de máquinas de fiação', descricao: 'Montador de máquinas de fiação' },
  { id: '725315 Montador de máquinas de minas e pedreiras', descricao: 'Montador de máquinas de minas e pedreiras' },
  { id: '725320 Montador de máquinas de terraplenagem', descricao: 'Montador de máquinas de terraplenagem' },
  { id: '725210 Montador de máquinas gráficas', descricao: 'Montador de máquinas gráficas' },
  { id: '725215 Montador de máquinas operatrizes para madeira', descricao: 'Montador de máquinas operatrizes para madeira' },
  { id: '725220 Montador de máquinas têxteis', descricao: 'Montador de máquinas têxteis' },
  { id: '725105 Montador de máquinas, motores e acessórios (montagem em série)', descricao: 'Montador de máquinas, motores e acessórios (montagem em série)' },
  { id: '725225 Montador de máquinas-ferramentas (usinagem de metais)', descricao: 'Montador de máquinas-ferramentas (usinagem de metais)' },
  { id: '712205 Montador de mármore', descricao: 'Montador de mármore' },
  { id: '725505 Montador de motores (grupo motopropulsor-gmp)', descricao: 'Montador de motores (grupo motopropulsor-gmp)' },
  { id: '725415 Montador de motores a diesel', descricao: 'Montador de motores a diesel' },
  { id: '725415 Montador de motores a explosão', descricao: 'Montador de motores a explosão' },
  { id: '725415 Montador de motores a gasolina', descricao: 'Montador de motores a gasolina' },
  { id: '774105 Montador de móveis de madeira', descricao: 'Montador de móveis de madeira' },
  { id: '774105 Montador de móveis e artefatos de madeira', descricao: 'Montador de móveis e artefatos de madeira' },
  { id: '774105 Montador de paletes', descricao: 'Montador de paletes' },
  { id: '992115 Montador de pneus', descricao: 'Montador de pneus' },
  { id: '725305 Montador de pontes rolantes', descricao: 'Montador de pontes rolantes' },
  { id: '768120 Montador de punho de rede', descricao: 'Montador de punho de rede' },
  { id: '764120 Montador de saltos de calçados', descricao: 'Montador de saltos de calçados' },
  { id: '766145 Montador de seleção de cores', descricao: 'Montador de seleção de cores' },
  { id: '725610 Montador de sistemas de combustível de aeronaves', descricao: 'Montador de sistemas de combustível de aeronaves' },
  { id: '951305 Montador de sistemas eletroeletrônicos de segurança', descricao: 'Montador de sistemas eletroeletrônicos de segurança' },
  { id: '768110 Montador de tapetes', descricao: 'Montador de tapetes' },
  { id: '771120 Montador de tonéis', descricao: 'Montador de tonéis' },
  { id: '731160 Montador de transformadores', descricao: 'Montador de transformadores' },
  { id: '725420 Montador de turbinas', descricao: 'Montador de turbinas' },
  { id: '725505 Montador de veículos (linha de montagem)', descricao: 'Montador de veículos (linha de montagem)' },
  { id: '991310 Montador de veículos (reparação)', descricao: 'Montador de veículos (reparação)' },
  { id: '725505 Montador de veículos automotores (linha de montagem)', descricao: 'Montador de veículos automotores (linha de montagem)' },
  { id: '716305 Montador de vidros', descricao: 'Montador de vidros' },
  { id: '766325 Montador gráfico de corte e vinco', descricao: 'Montador gráfico de corte e vinco' },
  { id: '725105 Montador instalador de acessórios', descricao: 'Montador instalador de acessórios' },
  { id: '725205 Montador mecânico (máquinas industriais)', descricao: 'Montador mecânico (máquinas industriais)' },
  { id: '725310 Montador mecânico de máquinas agrícolas', descricao: 'Montador mecânico de máquinas agrícolas' },
  { id: '725320 Montador mecânico de máquinas de terraplenagem', descricao: 'Montador mecânico de máquinas de terraplenagem' },
  { id: '725310 Montador multifuncional em máquinas agrícolas', descricao: 'Montador multifuncional em máquinas agrícolas' },
  { id: '724210 Montador naval', descricao: 'Montador naval' },
  { id: '724210 Montador naval (estruturas)', descricao: 'Montador naval (estruturas)' },
  { id: '724315 Montador soldador', descricao: 'Montador soldador' },
  { id: '725105 Montador, a mão', descricao: 'Montador, a mão' },
  { id: '7312 MONTADORES DE APARELHOS DE TELECOMUNICAÇÕES', descricao: 'MONTADORES DE APARELHOS DE TELECOMUNICAÇÕES' },
  { id: '7311 MONTADORES DE EQUIPAMENTOS ELETROELETRÔNICOS', descricao: 'MONTADORES DE EQUIPAMENTOS ELETROELETRÔNICOS' },
  { id: '7153 MONTADORES DE ESTRUTURAS DE CONCRETO ARMADO', descricao: 'MONTADORES DE ESTRUTURAS DE CONCRETO ARMADO' },
  { id: '7252 MONTADORES DE MÁQUINAS INDUSTRIAIS', descricao: 'MONTADORES DE MÁQUINAS INDUSTRIAIS' },
  { id: '7253 MONTADORES DE MÁQUINAS PESADAS E EQUIPAMENTOS AGRÍCOLAS', descricao: 'MONTADORES DE MÁQUINAS PESADAS E EQUIPAMENTOS AGRÍCOLAS' },
  { id: '7251 MONTADORES DE MÁQUINAS, APARELHOS E ACESSÓRIOS EM LINHAS DE MONTAGEM', descricao: 'MONTADORES DE MÁQUINAS, APARELHOS E ACESSÓRIOS EM LINHAS DE MONTAGEM' },
  { id: '7741 MONTADORES DE MÓVEIS E ARTEFATOS DE MADEIRA', descricao: 'MONTADORES DE MÓVEIS E ARTEFATOS DE MADEIRA' },
  { id: '7256 MONTADORES DE SISTEMAS E ESTRUTURAS DE AERONAVES', descricao: 'MONTADORES DE SISTEMAS E ESTRUTURAS DE AERONAVES' },
  { id: '7255 MONTADORES DE VEÍCULOS AUTOMOTORES (LINHA DE MONTAGEM)', descricao: 'MONTADORES DE VEÍCULOS AUTOMOTORES (LINHA DE MONTAGEM)' },
  { id: '513110 Mordomo de hotelaria', descricao: 'Mordomo de hotelaria' },
  { id: '513105 Mordomo de residência', descricao: 'Mordomo de residência' },
  { id: '5131 MORDOMOS E GOVERNANTAS', descricao: 'MORDOMOS E GOVERNANTAS' },
  { id: '113005 Morubixaba', descricao: 'Morubixaba' },
  { id: '716530 Mosaísta', descricao: 'Mosaísta' },
  { id: '263105 Mosoyoyó', descricao: 'Mosoyoyó' },
  { id: '5191 MOTOCICLISTAS E CICLISTAS DE ENTREGAS RÁPIDAS', descricao: 'MOTOCICLISTAS E CICLISTAS DE ENTREGAS RÁPIDAS' },
  { id: '519110 Motofretista', descricao: 'Motofretista' },
  { id: '782305 Motorista no serviço doméstico', descricao: 'Motorista no serviço doméstico' },
  { id: '782310 Motorista auxiliar', descricao: 'Motorista auxiliar' },
  { id: '782310 Motorista auxiliar de tráfego', descricao: 'Motorista auxiliar de tráfego' },
  { id: '782510 Motorista carreteiro', descricao: 'Motorista carreteiro' },
  { id: '782320 Motorista de ambulância', descricao: 'Motorista de ambulância' },
  { id: '782305 Motorista de automóveis', descricao: 'Motorista de automóveis' },
  { id: '782510 Motorista de basculante', descricao: 'Motorista de basculante' },
  { id: '782510 Motorista de caminhão', descricao: 'Motorista de caminhão' },
  { id: '782510 Motorista de caminhão (entrega de bebidas)', descricao: 'Motorista de caminhão (entrega de bebidas)' },
  { id: '782510 Motorista de caminhão (rotas regionais e internacionais)', descricao: 'Motorista de caminhão (rotas regionais e internacionais)' },
  { id: '782510 Motorista de caminhão leve', descricao: 'Motorista de caminhão leve' },
  { id: '782510 Motorista de caminhão-basculante', descricao: 'Motorista de caminhão-basculante' },
  { id: '782510 Motorista de caminhão-betoneira', descricao: 'Motorista de caminhão-betoneira' },
  { id: '782515 Motorista de caminhão-guincho leve', descricao: 'Motorista de caminhão-guincho leve' },
  { id: '782515 Motorista de caminhão-guincho médio', descricao: 'Motorista de caminhão-guincho médio' },
  { id: '782515 Motorista de caminhão-guincho pesado', descricao: 'Motorista de caminhão-guincho pesado' },
  { id: '782515 Motorista de caminhão-guincho pesado com munk', descricao: 'Motorista de caminhão-guincho pesado com munk' },
  { id: '782515 Motorista de caminhão-guindaste', descricao: 'Motorista de caminhão-guindaste' },
  { id: '782510 Motorista de caminhão-pipa', descricao: 'Motorista de caminhão-pipa' },
  { id: '782510 Motorista de caminhão-tanque', descricao: 'Motorista de caminhão-tanque' },
  { id: '782310 Motorista de carga a frete', descricao: 'Motorista de carga a frete' },
  { id: '782305 Motorista de carro de passeio', descricao: 'Motorista de carro de passeio' },
  { id: '782310 Motorista de carro forte', descricao: 'Motorista de carro forte' },
  { id: '782220 Motorista de empilhadeira', descricao: 'Motorista de empilhadeira' },
  { id: '782310 Motorista de furgão', descricao: 'Motorista de furgão' },
  { id: '782310 Motorista de furgão ou veículo similar', descricao: 'Motorista de furgão ou veículo similar' },
  { id: '782310 Motorista de kombi', descricao: 'Motorista de kombi' },
  { id: '782405 Motorista de ônibus rodoviário', descricao: 'Motorista de ônibus rodoviário' },
  { id: '782410 Motorista de ônibus urbano', descricao: 'Motorista de ônibus urbano' },
  { id: '782310 Motorista de perua', descricao: 'Motorista de perua' },
  { id: '782315 Motorista de praça', descricao: 'Motorista de praça' },
  { id: '782315 Motorista de táxi', descricao: 'Motorista de táxi' },
  { id: '782415 Motorista de trólebus', descricao: 'Motorista de trólebus' },
  { id: '782310 Motorista entregador', descricao: 'Motorista entregador' },
  { id: '782310 Motorista manipulador', descricao: 'Motorista manipulador' },
  { id: '782515 Motorista operacional de guincho', descricao: 'Motorista operacional de guincho' },
  { id: '715405 Motorista operador de betoneira', descricao: 'Motorista operador de betoneira' },
  { id: '715410 Motorista operador de bomba de concreto', descricao: 'Motorista operador de bomba de concreto' },
  { id: '782510 Motorista operador de caminhão-betoneira', descricao: 'Motorista operador de caminhão-betoneira' },
  { id: '782305 Motorista particular', descricao: 'Motorista particular' },
  { id: '782305 Motorista segurança', descricao: 'Motorista segurança' },
  { id: '782305 Motorista vigilante', descricao: 'Motorista vigilante' },
  { id: '7824 MOTORISTAS DE ÔNIBUS URBANOS, METROPOLITANOS E RODOVIÁRIOS', descricao: 'MOTORISTAS DE ÔNIBUS URBANOS, METROPOLITANOS E RODOVIÁRIOS' },
  { id: '7825 MOTORISTAS DE VEÍCULOS DE CARGAS EM GERAL', descricao: 'MOTORISTAS DE VEÍCULOS DE CARGAS EM GERAL' },
  { id: '7823 MOTORISTAS DE VEÍCULOS DE PEQUENO E MÉDIO PORTE', descricao: 'MOTORISTAS DE VEÍCULOS DE PEQUENO E MÉDIO PORTE' },
  { id: '782620 Motorneiro', descricao: 'Motorneiro' },
  { id: '519115 Mototaxista', descricao: 'Mototaxista' },
  { id: '771105 Moveleiro - exclusive empregador', descricao: 'Moveleiro - exclusive empregador' },
  { id: '263105 Muézin', descricao: 'Muézin' },
  { id: '263115 Mufti', descricao: 'Mufti' },
  { id: '782810 Muladeiro', descricao: 'Muladeiro' },
  { id: '141410 Muladeiro (comércio varejista)', descricao: 'Muladeiro (comércio varejista)' },
  { id: '141410 Mulandeiro', descricao: 'Mulandeiro' },
  { id: '519805 Mulher da vida', descricao: 'Mulher da vida' },
  { id: '261310 Museólogo', descricao: 'Museólogo' },
  { id: '262610 Músico arranjador', descricao: 'Músico arranjador' },
  { id: '262705 Músico intérprete cantor', descricao: 'Músico intérprete cantor' },
  { id: '262705 Músico intérprete cantor erudito', descricao: 'Músico intérprete cantor erudito' },
  { id: '262705 Músico intérprete cantor popular', descricao: 'Músico intérprete cantor popular' },
  { id: '262710 Músico intérprete instrumentista', descricao: 'Músico intérprete instrumentista' },
  { id: '262710 Músico intérprete instrumentista clássico', descricao: 'Músico intérprete instrumentista clássico' },
  { id: '262710 Músico intérprete instrumentista erudito', descricao: 'Músico intérprete instrumentista erudito' },
  { id: '262710 Músico intérprete instrumentista popular', descricao: 'Músico intérprete instrumentista popular' },
  { id: '262615 Músico regente', descricao: 'Músico regente' },
  { id: '262620 Musicólogo', descricao: 'Musicólogo' },
  { id: '2626 MÚSICOS COMPOSITORES, ARRANJADORES, REGENTES E MUSICÓLOGOS', descricao: 'MÚSICOS COMPOSITORES, ARRANJADORES, REGENTES E MUSICÓLOGOS' },
  { id: '2627 MÚSICOS INTÉRPRETES', descricao: 'MÚSICOS INTÉRPRETES' },
  { id: '226305 Musicoterapeuta', descricao: 'Musicoterapeuta' },
  { id: '263105 Muzenza', descricao: 'Muzenza' },
  { id: '261715 Narrador esportivo', descricao: 'Narrador esportivo' },
  { id: '226320 Naturólogo', descricao: 'Naturólogo' },
  { id: '322125 Naturopata', descricao: 'Naturopata' },
  { id: '225109 Nefrologista', descricao: 'Nefrologista' },
  { id: '141405 Negociante (comércio atacadista )', descricao: 'Negociante (comércio atacadista )' },
  { id: '141410 Negociante (comércio varejista - empregador)', descricao: 'Negociante (comércio varejista - empregador)' },
  { id: '141410 Negociante (comércio varejista)', descricao: 'Negociante (comércio varejista)' },
  { id: '225124 Neonatologista', descricao: 'Neonatologista' },
  { id: '225260 Neurocirurgião', descricao: 'Neurocirurgião' },
  { id: '225260 Neurocirurgião pediátrico', descricao: 'Neurocirurgião pediátrico' },
  { id: '225350 Neurofisiologista clínico', descricao: 'Neurofisiologista clínico' },
  { id: '225112 Neurologista', descricao: 'Neurologista' },
  { id: '225112 Neuropediatra', descricao: 'Neuropediatra' },
  { id: '251545 Neuropsicólogo', descricao: 'Neuropsicólogo' },
  { id: '239440 Neuropsicopedagogo clinico', descricao: 'Neuropsicopedagogo clinico' },
  { id: '239445 Neuropsicopedagogo institucional', descricao: 'Neuropsicopedagogo institucional' },
  { id: '225133 Neuropsiquiatra', descricao: 'Neuropsiquiatra' },
  { id: '263105 Nhanderú arandú', descricao: 'Nhanderú arandú' },
  { id: '723225 Niquelador', descricao: 'Niquelador' },
  { id: '263105 Nisosan', descricao: 'Nisosan' },
  { id: '263105 Nochê', descricao: 'Nochê' },
  { id: '723110 Normalizador de metais e de compósitos', descricao: 'Normalizador de metais e de compósitos' },
  { id: '241335 Notário', descricao: 'Notário' },
  { id: '421315 Notificador', descricao: 'Notificador' },
  { id: '261515 Novelista (escritor)', descricao: 'Novelista (escritor)' },
  { id: '263105 Noviço', descricao: 'Noviço' },
  { id: '516710 Numerólogo', descricao: 'Numerólogo' },
  { id: '371205 Numismata', descricao: 'Numismata' },
  { id: '223710 Nutricionista', descricao: 'Nutricionista' },
  { id: '223710 Nutricionista (saúde pública)', descricao: 'Nutricionista (saúde pública)' },
  { id: '2237 NUTRICIONISTAS', descricao: 'NUTRICIONISTAS' },
  { id: '225118 Nutrologista', descricao: 'Nutrologista' },
  { id: '263115 Obá', descricao: 'Obá' },
  { id: '263105 Oboosan', descricao: 'Oboosan' },
  { id: '263110 Obreiro bíblico', descricao: 'Obreiro bíblico' },
  { id: '377220 Observador (futebol)', descricao: 'Observador (futebol)' },
  { id: '225250 Obstetra', descricao: 'Obstetra' },
  { id: '223575 Obstetriz', descricao: 'Obstetriz' },
  { id: '213440 Oceanógrafo', descricao: 'Oceanógrafo' },
  { id: '213440 Oceanólogo', descricao: 'Oceanólogo' },
  { id: '225265 Oculista', descricao: 'Oculista' },
  { id: '223276 Odontologia ocupacional', descricao: 'Odontologia ocupacional' },
  { id: '223208 Odontologista', descricao: 'Odontologista' },
  { id: '223272 Odontologista social', descricao: 'Odontologista social' },
  { id: '223208 Odontólogo', descricao: 'Odontólogo' },
  { id: '223268 Odontólogo (cirurgia e traumatologia bucomaxilofacial)', descricao: 'Odontólogo (cirurgia e traumatologia bucomaxilofacial)' },
  { id: '223272 Odontólogo de saúde coletiva', descricao: 'Odontólogo de saúde coletiva' },
  { id: '223272 Odontólogo de saúde pública', descricao: 'Odontólogo de saúde pública' },
  { id: '223256 Odontólogo protesista', descricao: 'Odontólogo protesista' },
  { id: '223212 Odontólogo-endodontista', descricao: 'Odontólogo-endodontista' },
  { id: '223236 Odontopediatra', descricao: 'Odontopediatra' },
  { id: '223260 Odontoradiologista', descricao: 'Odontoradiologista' },
  { id: '412205 Office-boy', descricao: 'Office-boy' },
  { id: '412205 Office-girl', descricao: 'Office-girl' },
  { id: '102 OFICIAIS DAS FORÇAS ARMADAS', descricao: 'OFICIAIS DAS FORÇAS ARMADAS' },
  { id: '2151 OFICIAIS DE CONVÉS E AFINS', descricao: 'OFICIAIS DE CONVÉS E AFINS' },
  { id: '2152 OFICIAIS DE MÁQUINAS DA MARINHA MERCANTE', descricao: 'OFICIAIS DE MÁQUINAS DA MARINHA MERCANTE' },
  { id: '101 OFICIAIS GENERAIS DAS FORÇAS ARMADAS', descricao: 'OFICIAIS GENERAIS DAS FORÇAS ARMADAS' },
  { id: '302 OFICIAIS INTERMEDIÁRIOS DO CORPO DE BOMBEIROS MILITAR', descricao: 'OFICIAIS INTERMEDIÁRIOS DO CORPO DE BOMBEIROS MILITAR' },
  { id: '201 OFICIAIS SUPERIORES DA POLÍCIA MILITAR', descricao: 'OFICIAIS SUPERIORES DA POLÍCIA MILITAR' },
  { id: '301 OFICIAIS SUPERIORES DO CORPO DE BOMBEIROS MILITAR', descricao: 'OFICIAIS SUPERIORES DO CORPO DE BOMBEIROS MILITAR' },
  { id: '715505 Oficial carpinteiro', descricao: 'Oficial carpinteiro' },
  { id: '10205 Oficial da aeronáutica', descricao: 'Oficial da aeronáutica' },
  { id: '10215 Oficial da marinha', descricao: 'Oficial da marinha' },
  { id: '377225 Oficial de arbitragem (futebol de salão)', descricao: 'Oficial de arbitragem (futebol de salão)' },
  { id: '377240 Oficial de arbitragem (poloaquático)', descricao: 'Oficial de arbitragem (poloaquático)' },
  { id: '242905 Oficial de inteligência', descricao: 'Oficial de inteligência' },
  { id: '351425 Oficial de justiça', descricao: 'Oficial de justiça' },
  { id: '514325 Oficial de manutenção', descricao: 'Oficial de manutenção' },
  { id: '715725 Oficial de manutenção civil', descricao: 'Oficial de manutenção civil' },
  { id: '514325 Oficial de manutenção predial', descricao: 'Oficial de manutenção predial' },
  { id: '377215 Oficial de mesa (basquete)', descricao: 'Oficial de mesa (basquete)' },
  { id: '377230 Oficial de mesa (judô)', descricao: 'Oficial de mesa (judô)' },
  { id: '516610 Oficial de obras - sepultador', descricao: 'Oficial de obras - sepultador' },
  { id: '142135 Oficial de proteção de dados pessoais (dpo)', descricao: 'Oficial de proteção de dados pessoais (dpo)' },
  { id: '377215 Oficial de quadra (basquete)', descricao: 'Oficial de quadra (basquete)' },
  { id: '215140 Oficial de quarto de navegação da marinha mercante', descricao: 'Oficial de quarto de navegação da marinha mercante' },
  { id: '351415 Oficial de registro', descricao: 'Oficial de registro' },
  { id: '241305 Oficial de registro de contratos marítimos', descricao: 'Oficial de registro de contratos marítimos' },
  { id: '773340 Oficial de serra', descricao: 'Oficial de serra' },
  { id: '514325 Oficial de serviços diversos na manutenção de edificações', descricao: 'Oficial de serviços diversos na manutenção de edificações' },
  { id: '514325 Oficial de serviços gerais na manutenção de edificações', descricao: 'Oficial de serviços gerais na manutenção de edificações' },
  { id: '10210 Oficial do exército', descricao: 'Oficial do exército' },
  { id: '241310 Oficial do registro civil de pessoas jurídicas', descricao: 'Oficial do registro civil de pessoas jurídicas' },
  { id: '241315 Oficial do registro civil de pessoas naturais', descricao: 'Oficial do registro civil de pessoas naturais' },
  { id: '241320 Oficial do registro de distribuições', descricao: 'Oficial do registro de distribuições' },
  { id: '241325 Oficial do registro de imóveis', descricao: 'Oficial do registro de imóveis' },
  { id: '241330 Oficial do registro de títulos e documentos', descricao: 'Oficial do registro de títulos e documentos' },
  { id: '377210 Oficial encarregado de painel de advertência', descricao: 'Oficial encarregado de painel de advertência' },
  { id: '10105 Oficial general da aeronáutica', descricao: 'Oficial general da aeronáutica' },
  { id: '10115 Oficial general da marinha', descricao: 'Oficial general da marinha' },
  { id: '10110 Oficial general do exército', descricao: 'Oficial general do exército' },
  { id: '351425 Oficial judiciário', descricao: 'Oficial judiciário' },
  { id: '351425 Oficial legislativo', descricao: 'Oficial legislativo' },
  { id: '351405 Oficial maior', descricao: 'Oficial maior' },
  { id: '914405 Oficial mecânico de veículos', descricao: 'Oficial mecânico de veículos' },
  { id: '30305 Oficial subalterno do corpo de bombeiros militar', descricao: 'Oficial subalterno do corpo de bombeiros militar' },
  { id: '215205 Oficial superior de máquinas da marinha mercante', descricao: 'Oficial superior de máquinas da marinha mercante' },
  { id: '242910 Oficial técnico de inteligência', descricao: 'Oficial técnico de inteligência' },
  { id: '203010 Ofiologista', descricao: 'Ofiologista' },
  { id: '225265 Oftalmologista', descricao: 'Oftalmologista' },
  { id: '828105 Oleiro (fabricação de telhas)', descricao: 'Oleiro (fabricação de telhas)' },
  { id: '828110 Oleiro (fabricação de tijolos)', descricao: 'Oleiro (fabricação de tijolos)' },
  { id: '752310 Oleiro em torno de pedal', descricao: 'Oleiro em torno de pedal' },
  { id: '612310 Olericultor', descricao: 'Olericultor' },
  { id: '612320 Olericultor de frutos e sementes', descricao: 'Olericultor de frutos e sementes' },
  { id: '612305 Olericultor de legumes', descricao: 'Olericultor de legumes' },
  { id: '612315 Olericultor de talos, folhas e flores', descricao: 'Olericultor de talos, folhas e flores' },
  { id: '263105 Olorixá', descricao: 'Olorixá' },
  { id: '142340 Ombudsman', descricao: 'Ombudsman' },
  { id: '225121 Oncologista (clínico)', descricao: 'Oncologista (clínico)' },
  { id: '225290 Oncologista cirúrgico', descricao: 'Oncologista cirúrgico' },
  { id: '752425 Opacador', descricao: 'Opacador' },
  { id: '773510 Operador de seccionadora', descricao: 'Operador de seccionadora' },
  { id: '811410 Operador auxiliar de destilação', descricao: 'Operador auxiliar de destilação' },
  { id: '761205 Operador batedor de fibras', descricao: 'Operador batedor de fibras' },
  { id: '422210 Operador bilíngüe (telefonia)', descricao: 'Operador bilíngüe (telefonia)' },
  { id: '841210 Operador braçal do refino de sal', descricao: 'Operador braçal do refino de sal' },
  { id: '712120 Operador britador', descricao: 'Operador britador' },
  { id: '374305 Operador cinematográfico', descricao: 'Operador cinematográfico' },
  { id: '253305 Operador de mercadorias e derivativos', descricao: 'Operador de mercadorias e derivativos' },
  { id: '862160 Operador de abastecimento de combustível de aeronave', descricao: 'Operador de abastecimento de combustível de aeronave' },
  { id: '848505 Operador de abate em matadouro', descricao: 'Operador de abate em matadouro' },
  { id: '761205 Operador de abertura (fiação)', descricao: 'Operador de abertura (fiação)' },
  { id: '761205 Operador de abridor de fibras', descricao: 'Operador de abridor de fibras' },
  { id: '766315 Operador de acabamento (indústria gráfica)', descricao: 'Operador de acabamento (indústria gráfica)' },
  { id: '722215 Operador de acabamento de peças fundidas', descricao: 'Operador de acabamento de peças fundidas' },
  { id: '821230 Operador de aciaria (basculamento de convertedor)', descricao: 'Operador de aciaria (basculamento de convertedor)' },
  { id: '821235 Operador de aciaria (dessulfuração de gusa)', descricao: 'Operador de aciaria (dessulfuração de gusa)' },
  { id: '821240 Operador de aciaria (recebimento de gusa)', descricao: 'Operador de aciaria (recebimento de gusa)' },
  { id: '841715 Operador de adegas', descricao: 'Operador de adegas' },
  { id: '641015 Operador de adubadeira', descricao: 'Operador de adubadeira' },
  { id: '781310 Operador de aeronaves não tripuladas', descricao: 'Operador de aeronaves não tripuladas' },
  { id: '781310 Operador de aeronaves pilotadas remotamente', descricao: 'Operador de aeronaves pilotadas remotamente' },
  { id: '811415 Operador de alambique de funcionamento contínuo (produtos químicos, exceto petróleo)', descricao: 'Operador de alambique de funcionamento contínuo (produtos químicos, exceto petróleo)' },
  { id: '821205 Operador de alto-forno', descricao: 'Operador de alto-forno' },
  { id: '712110 Operador de aparelho de flotação', descricao: 'Operador de aparelho de flotação' },
  { id: '712115 Operador de aparelho de precipitação (minas de ouro ou prata)', descricao: 'Operador de aparelho de precipitação (minas de ouro ou prata)' },
  { id: '811420 Operador de aparelho de reação e conversão (produtos químicos, exceto petróleo)', descricao: 'Operador de aparelho de reação e conversão (produtos químicos, exceto petróleo)' },
  { id: '711315 Operador de aparelho de sonda por rotação (poço de petróleo)', descricao: 'Operador de aparelho de sonda por rotação (poço de petróleo)' },
  { id: '766125 Operador de aparelhos de preparação', descricao: 'Operador de aparelhos de preparação' },
  { id: '821245 Operador de área de corrida', descricao: 'Operador de área de corrida' },
  { id: '342535 Operador de atendimento aeroviário', descricao: 'Operador de atendimento aeroviário' },
  { id: '422315 Operador de atendimento receptivo (telemarketing)', descricao: 'Operador de atendimento receptivo (telemarketing)' },
  { id: '823135 Operador de atomizador', descricao: 'Operador de atomizador' },
  { id: '373105 Operador de áudio', descricao: 'Operador de áudio' },
  { id: '841440 Operador de autoclave (conservação de alimentos)', descricao: 'Operador de autoclave (conservação de alimentos)' },
  { id: '782620 Operador de automotriz', descricao: 'Operador de automotriz' },
  { id: '715415 Operador de balança (concreto)', descricao: 'Operador de balança (concreto)' },
  { id: '414115 Operador de balanças rodoviárias', descricao: 'Operador de balanças rodoviárias' },
  { id: '514315 Operador de balancim', descricao: 'Operador de balancim' },
  { id: '764105 Operador de balancim na confecção de calçados', descricao: 'Operador de balancim na confecção de calçados' },
  { id: '764105 Operador de balancim no corte de couro', descricao: 'Operador de balancim no corte de couro' },
  { id: '811705 Operador de bambury', descricao: 'Operador de bambury' },
  { id: '724315 Operador de banho de solda', descricao: 'Operador de banho de solda' },
  { id: '823235 Operador de banho metálico de vidro por flutuação', descricao: 'Operador de banho metálico de vidro por flutuação' },
  { id: '723215 Operador de banhos (níquel, zinco, cromoprata, ouro)', descricao: 'Operador de banhos (níquel, zinco, cromoprata, ouro)' },
  { id: '821230 Operador de basculamento de convertedor', descricao: 'Operador de basculamento de convertedor' },
  { id: '715105 Operador de bate-estacas', descricao: 'Operador de bate-estacas' },
  { id: '848215 Operador de batedeira na fabricação de laticínio', descricao: 'Operador de batedeira na fabricação de laticínio' },
  { id: '622210 Operador de batedor de fibras', descricao: 'Operador de batedor de fibras' },
  { id: '862115 Operador de bateria de gás de hulha', descricao: 'Operador de bateria de gás de hulha' },
  { id: '712110 Operador de beneficiamento de minérios', descricao: 'Operador de beneficiamento de minérios' },
  { id: '715405 Operador de betoneira', descricao: 'Operador de betoneira' },
  { id: '761210 Operador de binadeira', descricao: 'Operador de binadeira' },
  { id: '761215 Operador de bobinadeira', descricao: 'Operador de bobinadeira' },
  { id: '821420 Operador de bobinadeira de tiras a quente, no acabamento de chapas e metais', descricao: 'Operador de bobinadeira de tiras a quente, no acabamento de chapas e metais' },
  { id: '253305 Operador de bolsa - pregão', descricao: 'Operador de bolsa - pregão' },
  { id: '253305 Operador de bolsa de mercadorias e futuros', descricao: 'Operador de bolsa de mercadorias e futuros' },
  { id: '811510 Operador de bomba (refinação de petróleo)', descricao: 'Operador de bomba (refinação de petróleo)' },
  { id: '715410 Operador de bomba de concreto', descricao: 'Operador de bomba de concreto' },
  { id: '862140 Operador de bombas (estação de bombeamento)', descricao: 'Operador de bombas (estação de bombeamento)' },
  { id: '862305 Operador de bombas no tratamento de água e efluentes', descricao: 'Operador de bombas no tratamento de água e efluentes' },
  { id: '782620 Operador de bonde', descricao: 'Operador de bonde' },
  { id: '831110 Operador de branqueador de pasta para fabricação de papel', descricao: 'Operador de branqueador de pasta para fabricação de papel' },
  { id: '811115 Operador de britadeira (tratamentos químicos e afins)', descricao: 'Operador de britadeira (tratamentos químicos e afins)' },
  { id: '811605 Operador de britador de coque', descricao: 'Operador de britador de coque' },
  { id: '712120 Operador de britador de mandíbulas', descricao: 'Operador de britador de mandíbulas' },
  { id: '374305 Operador de cabine cinematográfica', descricao: 'Operador de cabine cinematográfica' },
  { id: '821425 Operador de cabine de laminação (fio-máquina)', descricao: 'Operador de cabine de laminação (fio-máquina)' },
  { id: '373145 Operador de cabos', descricao: 'Operador de cabos' },
  { id: '318010 Operador de cad (copista)', descricao: 'Operador de cad (copista)' },
  { id: '421125 Operador de caixa', descricao: 'Operador de caixa' },
  { id: '421205 Operador de caixa lotérico', descricao: 'Operador de caixa lotérico' },
  { id: '813110 Operador de calandra (química, petroquímica e afins)', descricao: 'Operador de calandra (química, petroquímica e afins)' },
  { id: '724505 Operador de calandra (siderurgia)', descricao: 'Operador de calandra (siderurgia)' },
  { id: '761415 Operador de calandras (tecidos)', descricao: 'Operador de calandras (tecidos)' },
  { id: '811205 Operador de calcinação (tratamento químico e afins)', descricao: 'Operador de calcinação (tratamento químico e afins)' },
  { id: '862120 Operador de caldeira', descricao: 'Operador de caldeira' },
  { id: '862120 Operador de caldeira a vapor', descricao: 'Operador de caldeira a vapor' },
  { id: '841310 Operador de caleadeira na refinação de açúcar', descricao: 'Operador de caleadeira na refinação de açúcar' },
  { id: '841456 Operador de câmaras frias', descricao: 'Operador de câmaras frias' },
  { id: '841456 Operador de câmaras frigoríficas', descricao: 'Operador de câmaras frigoríficas' },
  { id: '253305 Operador de câmbio/comércio exterior', descricao: 'Operador de câmbio/comércio exterior' },
  { id: '372115 Operador de câmera', descricao: 'Operador de câmera' },
  { id: '372115 Operador de câmera de televisão', descricao: 'Operador de câmera de televisão' },
  { id: '372115 Operador de câmera de vídeo', descricao: 'Operador de câmera de vídeo' },
  { id: '711205 Operador de caminhão (minas e pedreiras)', descricao: 'Operador de caminhão (minas e pedreiras)' },
  { id: '711205 Operador de caminhão fora de estrada', descricao: 'Operador de caminhão fora de estrada' },
  { id: '811615 Operador de carboquímico', descricao: 'Operador de carboquímico' },
  { id: '761220 Operador de cardas', descricao: 'Operador de cardas' },
  { id: '821315 Operador de cardas de chapas grossas', descricao: 'Operador de cardas de chapas grossas' },
  { id: '711210 Operador de carregadeira', descricao: 'Operador de carregadeira' },
  { id: '821205 Operador de carregamento de alto-forno', descricao: 'Operador de carregamento de alto-forno' },
  { id: '374210 Operador de carrinho (travelling)', descricao: 'Operador de carrinho (travelling)' },
  { id: '811610 Operador de carro de apagamento e coque', descricao: 'Operador de carro de apagamento e coque' },
  { id: '821440 Operador de carro de aparas', descricao: 'Operador de carro de aparas' },
  { id: '722210 Operador de carro de lingotes', descricao: 'Operador de carro de lingotes' },
  { id: '253305 Operador de carteiras internacionais', descricao: 'Operador de carteiras internacionais' },
  { id: '862140 Operador de casa de bomba', descricao: 'Operador de casa de bomba' },
  { id: '862150 Operador de casa de máquinas - exceto embarcações', descricao: 'Operador de casa de máquinas - exceto embarcações' },
  { id: '992215 Operador de ceifadeira na conservação de vias permanentes', descricao: 'Operador de ceifadeira na conservação de vias permanentes' },
  { id: '712110 Operador de célula (aparelho de flotação)', descricao: 'Operador de célula (aparelho de flotação)' },
  { id: '715420 Operador de central de asfalto', descricao: 'Operador de central de asfalto' },
  { id: '811115 Operador de central de britagem (tratamentos químicos e afins)', descricao: 'Operador de central de britagem (tratamentos químicos e afins)' },
  { id: '715415 Operador de central de concreto', descricao: 'Operador de central de concreto' },
  { id: '862505 Operador de central de refrigeração', descricao: 'Operador de central de refrigeração' },
  { id: '861105 Operador de central hidrelétrica', descricao: 'Operador de central hidrelétrica' },
  { id: '861115 Operador de central termoelétrica', descricao: 'Operador de central termoelétrica' },
  { id: '861120 Operador de central termonuclear', descricao: 'Operador de central termonuclear' },
  { id: '811305 Operador de centrífuga (tratamentos químicos e afins)', descricao: 'Operador de centrífuga (tratamentos químicos e afins)' },
  { id: '516310 Operador de centrífuga de roupas', descricao: 'Operador de centrífuga de roupas' },
  { id: '811305 Operador de centrifugadora (tratamentos químicos e afins)', descricao: 'Operador de centrifugadora (tratamentos químicos e afins)' },
  { id: '821105 Operador de centro de controle ( sinterização)', descricao: 'Operador de centro de controle ( sinterização)' },
  { id: '342410 Operador de centro de controle (ferrovia e metrô)', descricao: 'Operador de centro de controle (ferrovia e metrô)' },
  { id: '821205 Operador de centro de controle de alto-forno', descricao: 'Operador de centro de controle de alto-forno' },
  { id: '821105 Operador de centro de controle de sinterização e pátios', descricao: 'Operador de centro de controle de sinterização e pátios' },
  { id: '821105 Operador de centro de controle e equipamentos da sinterização', descricao: 'Operador de centro de controle e equipamentos da sinterização' },
  { id: '821105 Operador de centro de controle e matéria-prima para sinterizar', descricao: 'Operador de centro de controle e matéria-prima para sinterizar' },
  { id: '721405 Operador de centro de usinagem com comando numérico', descricao: 'Operador de centro de usinagem com comando numérico' },
  { id: '773505 Operador de centro de usinagem de madeira (cnc)', descricao: 'Operador de centro de usinagem de madeira (cnc)' },
  { id: '422205 Operador de centro telefônico', descricao: 'Operador de centro telefônico' },
  { id: '823250 Operador de cerâmica (fundição de vidro)', descricao: 'Operador de cerâmica (fundição de vidro)' },
  { id: '761420 Operador de chamuscadeira de tecidos', descricao: 'Operador de chamuscadeira de tecidos' },
  { id: '761420 Operador de chamuscadeira/desengomagem de tecidos', descricao: 'Operador de chamuscadeira/desengomagem de tecidos' },
  { id: '773125 Operador de circular automática (madeira)', descricao: 'Operador de circular automática (madeira)' },
  { id: '862310 Operador de co-processamento de resíduos', descricao: 'Operador de co-processamento de resíduos' },
  { id: '421310 Operador de cobrança', descricao: 'Operador de cobrança' },
  { id: '413230 Operador de cobrança bancária', descricao: 'Operador de cobrança bancária' },
  { id: '723215 Operador de cobreamento', descricao: 'Operador de cobreamento' },
  { id: '642005 Operador de colhedor florestal', descricao: 'Operador de colhedor florestal' },
  { id: '641005 Operador de colheitadeira', descricao: 'Operador de colheitadeira' },
  { id: '712110 Operador de coluna de flotação', descricao: 'Operador de coluna de flotação' },
  { id: '715110 Operador de compactadora de solos', descricao: 'Operador de compactadora de solos' },
  { id: '711315 Operador de compressor - na extração de petróleo e gás', descricao: 'Operador de compressor - na extração de petróleo e gás' },
  { id: '862130 Operador de compressor de ar', descricao: 'Operador de compressor de ar' },
  { id: '862505 Operador de compressor de refrigeração', descricao: 'Operador de compressor de refrigeração' },
  { id: '317205 Operador de computador', descricao: 'Operador de computador' },
  { id: '317205 Operador de computador (inclusive microcomputador', descricao: 'Operador de computador (inclusive microcomputador' },
  { id: '811120 Operador de concentração', descricao: 'Operador de concentração' },
  { id: '712130 Operador de cone de separação (minas)', descricao: 'Operador de cone de separação (minas)' },
  { id: '761225 Operador de conicaleira', descricao: 'Operador de conicaleira' },
  { id: '842110 Operador de conjunto de secador de fumo', descricao: 'Operador de conjunto de secador de fumo' },
  { id: '821245 Operador de conservação de canais', descricao: 'Operador de conservação de canais' },
  { id: '811635 Operador de controle de produção de carboquímico', descricao: 'Operador de controle de produção de carboquímico' },
  { id: '373135 Operador de controle mestre', descricao: 'Operador de controle mestre' },
  { id: '811635 Operador de controle térmico', descricao: 'Operador de controle térmico' },
  { id: '766320 Operador de corta-riscadeira de papel', descricao: 'Operador de corta-riscadeira de papel' },
  { id: '832110 Operador de cortadeira de papel', descricao: 'Operador de cortadeira de papel' },
  { id: '841310 Operador de cozedor a vácuo na refinação de açúcar', descricao: 'Operador de cozedor a vácuo na refinação de açúcar' },
  { id: '841305 Operador de cristalização na refinação de açucar', descricao: 'Operador de cristalização na refinação de açucar' },
  { id: '842110 Operador de debulhador de fumo', descricao: 'Operador de debulhador de fumo' },
  { id: '841310 Operador de decantador na refinação de açúcar', descricao: 'Operador de decantador na refinação de açúcar' },
  { id: '821420 Operador de desbobinadeira da linha de decapagem', descricao: 'Operador de desbobinadeira da linha de decapagem' },
  { id: '821420 Operador de desbobinadeiras de tiras a quente e a frio', descricao: 'Operador de desbobinadeiras de tiras a quente e a frio' },
  { id: '632125 Operador de descascador de madeira', descricao: 'Operador de descascador de madeira' },
  { id: '724515 Operador de desempenadeira (conformação de metais)', descricao: 'Operador de desempenadeira (conformação de metais)' },
  { id: '773305 Operador de desempenadeira na usinagem convencional de madeira', descricao: 'Operador de desempenadeira na usinagem convencional de madeira' },
  { id: '821250 Operador de desgaseificação', descricao: 'Operador de desgaseificação' },
  { id: '711115 Operador de desintegrador de pedras', descricao: 'Operador de desintegrador de pedras' },
  { id: '711115 Operador de desmineralizadora', descricao: 'Operador de desmineralizadora' },
  { id: '841505 Operador de desnatadeira (fabricação de laticínios e afins)', descricao: 'Operador de desnatadeira (fabricação de laticínios e afins)' },
  { id: '821235 Operador de dessulfurador de gusa', descricao: 'Operador de dessulfurador de gusa' },
  { id: '811410 Operador de destilação (produtos químicos)', descricao: 'Operador de destilação (produtos químicos)' },
  { id: '811615 Operador de destilação de amônia', descricao: 'Operador de destilação de amônia' },
  { id: '811615 Operador de destilação de enxofre', descricao: 'Operador de destilação de enxofre' },
  { id: '811615 Operador de destilação e subprodutos de coque', descricao: 'Operador de destilação e subprodutos de coque' },
  { id: '831115 Operador de digestor de pasta para fabricação de papel', descricao: 'Operador de digestor de pasta para fabricação de papel' },
  { id: '766310 Operador de dobradeira na indústria gráfica', descricao: 'Operador de dobradeira na indústria gráfica' },
  { id: '782210 Operador de docagem', descricao: 'Operador de docagem' },
  { id: '782105 Operador de draga', descricao: 'Operador de draga' },
  { id: '781310 Operador de drone', descricao: 'Operador de drone' },
  { id: '861205 Operador de eclusa', descricao: 'Operador de eclusa' },
  { id: '324110 Operador de eletrocardiógrafo', descricao: 'Operador de eletrocardiógrafo' },
  { id: '324105 Operador de eletroencefalógrafo', descricao: 'Operador de eletroencefalógrafo' },
  { id: '721205 Operador de eletroerosão a fio', descricao: 'Operador de eletroerosão a fio' },
  { id: '721205 Operador de eletroerosão por penetração', descricao: 'Operador de eletroerosão por penetração' },
  { id: '721325 Operador de eletropolimento', descricao: 'Operador de eletropolimento' },
  { id: '514105 Operador de elevador', descricao: 'Operador de elevador' },
  { id: '784110 Operador de embalagem, a máquina', descricao: 'Operador de embalagem, a máquina' },
  { id: '782220 Operador de empilhadeira', descricao: 'Operador de empilhadeira' },
  { id: '782220 Operador de empilhadeira elétrica', descricao: 'Operador de empilhadeira elétrica' },
  { id: '811620 Operador de enfornamento e desenfornamento de coque', descricao: 'Operador de enfornamento e desenfornamento de coque' },
  { id: '621005 Operador de engenho', descricao: 'Operador de engenho' },
  { id: '761348 Operador de engomadeira de urdume', descricao: 'Operador de engomadeira de urdume' },
  { id: '201215 Operador de ensaios na metrologia', descricao: 'Operador de ensaios na metrologia' },
  { id: '773310 Operador de entalhadeira (usinagem de madeira)', descricao: 'Operador de entalhadeira (usinagem de madeira)' },
  { id: '841310 Operador de enxofreira na refinação de açúcar', descricao: 'Operador de enxofreira na refinação de açúcar' },
  { id: '762205 Operador de enxugador (couros)', descricao: 'Operador de enxugador (couros)' },
  { id: '862130 Operador de equipamento de compressor de ar', descricao: 'Operador de equipamento de compressor de ar' },
  { id: '811425 Operador de equipamento de destilação de álcool', descricao: 'Operador de equipamento de destilação de álcool' },
  { id: '782105 Operador de equipamento de dragagem', descricao: 'Operador de equipamento de dragagem' },
  { id: '715115 Operador de equipamento de escavadeira', descricao: 'Operador de equipamento de escavadeira' },
  { id: '712110 Operador de equipamento de flotação', descricao: 'Operador de equipamento de flotação' },
  { id: '715130 Operador de equipamento de motoniveladora', descricao: 'Operador de equipamento de motoniveladora' },
  { id: '723305 Operador de equipamento de secagem de pintura', descricao: 'Operador de equipamento de secagem de pintura' },
  { id: '723115 Operador de equipamento para resfriamento', descricao: 'Operador de equipamento para resfriamento' },
  { id: '842135 Operador de equipamentos (cigarros)', descricao: 'Operador de equipamentos (cigarros)' },
  { id: '811310 Operador de equipamentos (exploração de petróleo)', descricao: 'Operador de equipamentos (exploração de petróleo)' },
  { id: '811330 Operador de equipamentos (filtro-prensa para tratamentos químicos)', descricao: 'Operador de equipamentos (filtro-prensa para tratamentos químicos)' },
  { id: '811305 Operador de equipamentos de centrifugar', descricao: 'Operador de equipamentos de centrifugar' },
  { id: '412110 Operador de equipamentos de entrada de dados', descricao: 'Operador de equipamentos de entrada de dados' },
  { id: '811335 Operador de equipamentos de parafinar', descricao: 'Operador de equipamentos de parafinar' },
  { id: '712115 Operador de equipamentos de precipitação', descricao: 'Operador de equipamentos de precipitação' },
  { id: '722325 Operador de equipamentos de preparação de areia', descricao: 'Operador de equipamentos de preparação de areia' },
  { id: '841310 Operador de equipamentos de refinação de açúcar (processo contínuo)', descricao: 'Operador de equipamentos de refinação de açúcar (processo contínuo)' },
  { id: '811315 Operador de equipamentos de secagem (mineração)', descricao: 'Operador de equipamentos de secagem (mineração)' },
  { id: '954125 Operador de equipamentos elétricos', descricao: 'Operador de equipamentos elétricos' },
  { id: '711130 Operador de equipamentos pesados e móveis - na mineração', descricao: 'Operador de equipamentos pesados e móveis - na mineração' },
  { id: '725510 Operador de equipe de montagem (veiculos automotores)', descricao: 'Operador de equipe de montagem (veiculos automotores)' },
  { id: '766150 Operador de escâner (pré-impressão gráfica)', descricao: 'Operador de escâner (pré-impressão gráfica)' },
  { id: '715115 Operador de escavadeira', descricao: 'Operador de escavadeira' },
  { id: '821430 Operador de escória e sucata', descricao: 'Operador de escória e sucata' },
  { id: '752420 Operador de esmaltadeira', descricao: 'Operador de esmaltadeira' },
  { id: '821450 Operador de esmeril e serra de disco, no acabamento de metal', descricao: 'Operador de esmeril e serra de disco, no acabamento de metal' },
  { id: '821450 Operador de esmeril fixo, no rebarbamento de metal', descricao: 'Operador de esmeril fixo, no rebarbamento de metal' },
  { id: '821450 Operador de esmeril, no rebarbamento de metal', descricao: 'Operador de esmeril, no rebarbamento de metal' },
  { id: '821450 Operador de esmerilador de trilhos, no acabamento de metais', descricao: 'Operador de esmerilador de trilhos, no acabamento de metais' },
  { id: '752425 Operador de espelhamento', descricao: 'Operador de espelhamento' },
  { id: '712125 Operador de espessador', descricao: 'Operador de espessador' },
  { id: '761351 Operador de espuladeira', descricao: 'Operador de espuladeira' },
  { id: '724610 Operador de espulagem', descricao: 'Operador de espulagem' },
  { id: '862140 Operador de estação de bombeamento', descricao: 'Operador de estação de bombeamento' },
  { id: '862205 Operador de estação de captação, tratamento e distribuição de água', descricao: 'Operador de estação de captação, tratamento e distribuição de água' },
  { id: '811330 Operador de estação de filtragem (filtro-prensa)', descricao: 'Operador de estação de filtragem (filtro-prensa)' },
  { id: '811110 Operador de estação de mistura (tratamentos químicos e afins)', descricao: 'Operador de estação de mistura (tratamentos químicos e afins)' },
  { id: '862305 Operador de estação de tratamento de água', descricao: 'Operador de estação de tratamento de água' },
  { id: '862305 Operador de estação de tratamento de água e efluentes', descricao: 'Operador de estação de tratamento de água e efluentes' },
  { id: '862305 Operador de estação de tratamento de esgoto', descricao: 'Operador de estação de tratamento de esgoto' },
  { id: '862305 Operador de estação de tratamento de esgotos e resíduos industriais', descricao: 'Operador de estação de tratamento de esgotos e resíduos industriais' },
  { id: '862305 Operador de estação de tratamento de resíduos industriais', descricao: 'Operador de estação de tratamento de resíduos industriais' },
  { id: '862305 Operador de estação elevatória', descricao: 'Operador de estação elevatória' },
  { id: '722410 Operador de esticadeira (metal sem costura)', descricao: 'Operador de esticadeira (metal sem costura)' },
  { id: '374105 Operador de estúdio (gravação de áudio)', descricao: 'Operador de estúdio (gravação de áudio)' },
  { id: '723320 Operador de estufa de pintura', descricao: 'Operador de estufa de pintura' },
  { id: '841735 Operador de estufa de secagem de malte', descricao: 'Operador de estufa de secagem de malte' },
  { id: '641010 Operador de estufas mecânicas', descricao: 'Operador de estufas mecânicas' },
  { id: '811430 Operador de evaporador na destilação', descricao: 'Operador de evaporador na destilação' },
  { id: '841305 Operador de evaporador para concentração do xarope na refinação de açúcar', descricao: 'Operador de evaporador para concentração do xarope na refinação de açúcar' },
  { id: '811625 Operador de exaustor (coqueria)', descricao: 'Operador de exaustor (coqueria)' },
  { id: '811310 Operador de exploração de petróleo', descricao: 'Operador de exploração de petróleo' },
  { id: '373130 Operador de externas', descricao: 'Operador de externas' },
  { id: '711315 Operador de extração (petróleo)', descricao: 'Operador de extração (petróleo)' },
  { id: '841620 Operador de extração de café solúvel', descricao: 'Operador de extração de café solúvel' },
  { id: '841448 Operador de extração de oleos e gorduras vegetais', descricao: 'Operador de extração de oleos e gorduras vegetais' },
  { id: '766310 Operador de extrusora (arte gráfica)', descricao: 'Operador de extrusora (arte gráfica)' },
  { id: '813115 Operador de extrusora (química, petroquímica e afins)', descricao: 'Operador de extrusora (química, petroquímica e afins)' },
  { id: '823210 Operador de extrusora (vidro)', descricao: 'Operador de extrusora (vidro)' },
  { id: '811775 Operador de extrusora de borracha e plástico', descricao: 'Operador de extrusora de borracha e plástico' },
  { id: '841210 Operador de fábrica de refino de sal', descricao: 'Operador de fábrica de refino de sal' },
  { id: '841468 Operador de fábrica de rações', descricao: 'Operador de fábrica de rações' },
  { id: '811130 Operador de fabricação de tintas', descricao: 'Operador de fabricação de tintas' },
  { id: '811125 Operador de fabricação de tintas e vernizes', descricao: 'Operador de fabricação de tintas e vernizes' },
  { id: '761230 Operador de filatório', descricao: 'Operador de filatório' },
  { id: '811310 Operador de filtragem (exploração de petróleo)', descricao: 'Operador de filtragem (exploração de petróleo)' },
  { id: '811320 Operador de filtragem (filtro de tambor rotativo)', descricao: 'Operador de filtragem (filtro de tambor rotativo)' },
  { id: '811315 Operador de filtragem (mineração)', descricao: 'Operador de filtragem (mineração)' },
  { id: '811335 Operador de filtragem de parafina', descricao: 'Operador de filtragem de parafina' },
  { id: '841710 Operador de filtro (cerveja)', descricao: 'Operador de filtro (cerveja)' },
  { id: '811315 Operador de filtro de secagem (mineração)', descricao: 'Operador de filtro de secagem (mineração)' },
  { id: '811320 Operador de filtro de tambor rotativo (tratamentos químicos e afins)', descricao: 'Operador de filtro de tambor rotativo (tratamentos químicos e afins)' },
  { id: '841310 Operador de filtro na refinação de açúcar', descricao: 'Operador de filtro na refinação de açúcar' },
  { id: '811320 Operador de filtro rotativo', descricao: 'Operador de filtro rotativo' },
  { id: '811325 Operador de filtro-esteira (mineração)', descricao: 'Operador de filtro-esteira (mineração)' },
  { id: '811330 Operador de filtro-prensa', descricao: 'Operador de filtro-prensa' },
  { id: '811330 Operador de filtro-prensa (tratamentos químicos e afins)', descricao: 'Operador de filtro-prensa (tratamentos químicos e afins)' },
  { id: '811335 Operador de filtros de parafina (tratamentos químicos e afins)', descricao: 'Operador de filtros de parafina (tratamentos químicos e afins)' },
  { id: '773415 Operador de fingirjoint', descricao: 'Operador de fingirjoint' },
  { id: '712110 Operador de flotação', descricao: 'Operador de flotação' },
  { id: '841310 Operador de flotador na refinação de açúcar', descricao: 'Operador de flotador na refinação de açúcar' },
  { id: '722105 Operador de forja', descricao: 'Operador de forja' },
  { id: '821210 Operador de forno (conversor a oxigênio)', descricao: 'Operador de forno (conversor a oxigênio)' },
  { id: '841805 Operador de forno (fabricação de pães, biscoitos e similares)', descricao: 'Operador de forno (fabricação de pães, biscoitos e similares)' },
  { id: '823215 Operador de forno (fundição de vidro)', descricao: 'Operador de forno (fundição de vidro)' },
  { id: '821220 Operador de forno (refino de metais não-ferrosos)', descricao: 'Operador de forno (refino de metais não-ferrosos)' },
  { id: '516605 Operador de forno (serviços funerários)', descricao: 'Operador de forno (serviços funerários)' },
  { id: '822105 Operador de forno cubilô', descricao: 'Operador de forno cubilô' },
  { id: '811205 Operador de forno de calcinação', descricao: 'Operador de forno de calcinação' },
  { id: '823315 Operador de forno de cerâmica (materiais de construção)', descricao: 'Operador de forno de cerâmica (materiais de construção)' },
  { id: '822125 Operador de forno de espera', descricao: 'Operador de forno de espera' },
  { id: '862310 Operador de forno de incineração no tratamento de água, efluentes e resíduos industriais', descricao: 'Operador de forno de incineração no tratamento de água, efluentes e resíduos industriais' },
  { id: '821215 Operador de forno de indução elétrica', descricao: 'Operador de forno de indução elétrica' },
  { id: '823220 Operador de forno de recozimento (vidro)', descricao: 'Operador de forno de recozimento (vidro)' },
  { id: '841735 Operador de forno de secagem (malte)', descricao: 'Operador de forno de secagem (malte)' },
  { id: '821110 Operador de forno de sinterizar', descricao: 'Operador de forno de sinterizar' },
  { id: '822120 Operador de forno de tratamento térmico', descricao: 'Operador de forno de tratamento térmico' },
  { id: '723120 Operador de forno de tratamento térmico de metais', descricao: 'Operador de forno de tratamento térmico de metais' },
  { id: '821215 Operador de forno de tratamento térmico elétrico', descricao: 'Operador de forno de tratamento térmico elétrico' },
  { id: '821215 Operador de forno elétrico', descricao: 'Operador de forno elétrico' },
  { id: '822115 Operador de forno metalúrgico', descricao: 'Operador de forno metalúrgico' },
  { id: '811205 Operador de forno termoelétrico para elétrodos de grafite', descricao: 'Operador de forno termoelétrico para elétrodos de grafite' },
  { id: '822110 Operador de forno-poço', descricao: 'Operador de forno-poço' },
  { id: '766145 Operador de fotocomposição', descricao: 'Operador de fotocomposição' },
  { id: '773315 Operador de fresadora (usinagem de madeira)', descricao: 'Operador de fresadora (usinagem de madeira)' },
  { id: '721410 Operador de fresadora com comando numérico', descricao: 'Operador de fresadora com comando numérico' },
  { id: '762205 Operador de fulão', descricao: 'Operador de fulão' },
  { id: '721215 Operador de furadeiras', descricao: 'Operador de furadeiras' },
  { id: '723215 Operador de galvanização', descricao: 'Operador de galvanização' },
  { id: '862405 Operador de gás', descricao: 'Operador de gás' },
  { id: '862115 Operador de gaseificação de carvão', descricao: 'Operador de gaseificação de carvão' },
  { id: '841725 Operador de germinação', descricao: 'Operador de germinação' },
  { id: '862405 Operador de glp', descricao: 'Operador de glp' },
  { id: '711110 Operador de granitadeira', descricao: 'Operador de granitadeira' },
  { id: '373105 Operador de gravação', descricao: 'Operador de gravação' },
  { id: '374105 Operador de gravação (trilha sonora para filmes)', descricao: 'Operador de gravação (trilha sonora para filmes)' },
  { id: '374210 Operador de grua', descricao: 'Operador de grua' },
  { id: '782115 Operador de grua (móvel)', descricao: 'Operador de grua (móvel)' },
  { id: '766320 Operador de guilhotina (corte de papel)', descricao: 'Operador de guilhotina (corte de papel)' },
  { id: '782205 Operador de guincho', descricao: 'Operador de guincho' },
  { id: '782110 Operador de guindaste (fixo)', descricao: 'Operador de guindaste (fixo)' },
  { id: '782110 Operador de guindaste fixo sobre vagões de plataformas flutuantes', descricao: 'Operador de guindaste fixo sobre vagões de plataformas flutuantes' },
  { id: '782115 Operador de guindaste móvel', descricao: 'Operador de guindaste móvel' },
  { id: '715710 Operador de gunite', descricao: 'Operador de gunite' },
  { id: '861105 Operador de hidrelétrica e subestações', descricao: 'Operador de hidrelétrica e subestações' },
  { id: '841444 Operador de hidrogenação', descricao: 'Operador de hidrogenação' },
  { id: '862205 Operador de hidrogenação e cloroficação da água', descricao: 'Operador de hidrogenação e cloroficação da água' },
  { id: '761425 Operador de impermeabilizador de tecidos', descricao: 'Operador de impermeabilizador de tecidos' },
  { id: '641015 Operador de implementos agrícolas', descricao: 'Operador de implementos agrícolas' },
  { id: '766210 Operador de impressão de valores', descricao: 'Operador de impressão de valores' },
  { id: '766225 Operador de impressora de rotogravura', descricao: 'Operador de impressora de rotogravura' },
  { id: '766215 Operador de impressora ofsete', descricao: 'Operador de impressora ofsete' },
  { id: '766250 Operador de impressora platina', descricao: 'Operador de impressora platina' },
  { id: '862310 Operador de incinerador', descricao: 'Operador de incinerador' },
  { id: '623315 Operador de incubadora', descricao: 'Operador de incubadora' },
  { id: '773120 Operador de industrialização de madeiras', descricao: 'Operador de industrialização de madeiras' },
  { id: '811770 Operador de injetora de plástico', descricao: 'Operador de injetora de plástico' },
  { id: '821205 Operador de inspeção de alto-forno', descricao: 'Operador de inspeção de alto-forno' },
  { id: '391215 Operador de inspeção de qualidade', descricao: 'Operador de inspeção de qualidade' },
  { id: '862515 Operador de instalação de ar-condicionado', descricao: 'Operador de instalação de ar-condicionado' },
  { id: '712110 Operador de instalação de beneficiamento', descricao: 'Operador de instalação de beneficiamento' },
  { id: '862405 Operador de instalação de extração, processamento, envasamento e distribuição de gases', descricao: 'Operador de instalação de extração, processamento, envasamento e distribuição de gases' },
  { id: '862310 Operador de instalação de incineração de resíduos', descricao: 'Operador de instalação de incineração de resíduos' },
  { id: '861105 Operador de instalação de produção de energia elétrica', descricao: 'Operador de instalação de produção de energia elétrica' },
  { id: '862505 Operador de instalação de refrigeração', descricao: 'Operador de instalação de refrigeração' },
  { id: '862405 Operador de instalação de transferência de gases', descricao: 'Operador de instalação de transferência de gases' },
  { id: '862205 Operador de instalação de tratamento de água', descricao: 'Operador de instalação de tratamento de água' },
  { id: '841444 Operador de instalação para produção de resfriamento de banha', descricao: 'Operador de instalação para produção de resfriamento de banha' },
  { id: '821435 Operador de jato abrasivo', descricao: 'Operador de jato abrasivo' },
  { id: '712130 Operador de jato de água - em minas', descricao: 'Operador de jato de água - em minas' },
  { id: '821435 Operador de jato de areia', descricao: 'Operador de jato de areia' },
  { id: '821435 Operador de jato de granalha', descricao: 'Operador de jato de granalha' },
  { id: '711230 Operador de jet flame', descricao: 'Operador de jet flame' },
  { id: '712130 Operador de jig (minas)', descricao: 'Operador de jig (minas)' },
  { id: '724605 Operador de laços de cabos de aço', descricao: 'Operador de laços de cabos de aço' },
  { id: '821305 Operador de laminação', descricao: 'Operador de laminação' },
  { id: '761235 Operador de laminadeira e reunideira', descricao: 'Operador de laminadeira e reunideira' },
  { id: '821305 Operador de laminador', descricao: 'Operador de laminador' },
  { id: '821310 Operador de laminador de barras a frio', descricao: 'Operador de laminador de barras a frio' },
  { id: '821315 Operador de laminador de barras a quente', descricao: 'Operador de laminador de barras a quente' },
  { id: '821305 Operador de laminador de encruamento', descricao: 'Operador de laminador de encruamento' },
  { id: '821320 Operador de laminador de metais não-ferrosos', descricao: 'Operador de laminador de metais não-ferrosos' },
  { id: '821305 Operador de laminador de placas', descricao: 'Operador de laminador de placas' },
  { id: '821315 Operador de laminador de tiras a quente', descricao: 'Operador de laminador de tiras a quente' },
  { id: '821325 Operador de laminador de tubos', descricao: 'Operador de laminador de tubos' },
  { id: '519935 Operador de lavador de veículos', descricao: 'Operador de lavador de veículos' },
  { id: '516315 Operador de lavadora de tapetes', descricao: 'Operador de lavadora de tapetes' },
  { id: '831120 Operador de lavagem e depuração de pasta para fabricação de papel', descricao: 'Operador de lavagem e depuração de pasta para fabricação de papel' },
  { id: '821315 Operador de leito de chapas grossas', descricao: 'Operador de leito de chapas grossas' },
  { id: '722230 Operador de lingotamento', descricao: 'Operador de lingotamento' },
  { id: '722210 Operador de lingoteira contínua', descricao: 'Operador de lingoteira contínua' },
  { id: '722235 Operador de lingoteiras', descricao: 'Operador de lingoteiras' },
  { id: '723205 Operador de linha de decapagem', descricao: 'Operador de linha de decapagem' },
  { id: '731175 Operador de linha de montagem (aparelhos elétricos)', descricao: 'Operador de linha de montagem (aparelhos elétricos)' },
  { id: '731180 Operador de linha de montagem (aparelhos eletrônicos)', descricao: 'Operador de linha de montagem (aparelhos eletrônicos)' },
  { id: '842135 Operador de linha de produção de preparação de matéria prima para produção de cigarros', descricao: 'Operador de linha de produção de preparação de matéria prima para produção de cigarros' },
  { id: '773320 Operador de lixadeira (usinagem de madeira)', descricao: 'Operador de lixadeira (usinagem de madeira)' },
  { id: '773415 Operador de lixadeira na usinagem de madeira', descricao: 'Operador de lixadeira na usinagem de madeira' },
  { id: '782610 Operador de locomotiva', descricao: 'Operador de locomotiva' },
  { id: '782615 Operador de locomotiva (transporte metropolitano)', descricao: 'Operador de locomotiva (transporte metropolitano)' },
  { id: '724320 Operador de maçarico', descricao: 'Operador de maçarico' },
  { id: '724310 Operador de maçarico de corte', descricao: 'Operador de maçarico de corte' },
  { id: '761240 Operador de maçaroqueira', descricao: 'Operador de maçaroqueira' },
  { id: '761327 Operador de malhas, a máquina', descricao: 'Operador de malhas, a máquina' },
  { id: '721415 Operador de mandriladora com comando numérico', descricao: 'Operador de mandriladora com comando numérico' },
  { id: '783110 Operador de manobras (auxiliar de ferrovias)', descricao: 'Operador de manobras (auxiliar de ferrovias)' },
  { id: '813125 Operador de manufaturado (química, petroquímica e afins)', descricao: 'Operador de manufaturado (química, petroquímica e afins)' },
  { id: '725030 Operador de manutenção e recarga de extintor de incêndio', descricao: 'Operador de manutenção e recarga de extintor de incêndio' },
  { id: '954125 Operador de manutenção eletromecânica', descricao: 'Operador de manutenção eletromecânica' },
  { id: '782220 Operador de máquina empilhadeira', descricao: 'Operador de máquina empilhadeira' },
  { id: '842125 Operador de máquina (fabricação de cigarros)', descricao: 'Operador de máquina (fabricação de cigarros)' },
  { id: '724610 Operador de máquina (pernas, fechamento, repassamento, alma)', descricao: 'Operador de máquina (pernas, fechamento, repassamento, alma)' },
  { id: '841428 Operador de máquina - na industrialização do pescado', descricao: 'Operador de máquina - na industrialização do pescado' },
  { id: '711110 Operador de máquina a fio diamantado', descricao: 'Operador de máquina a fio diamantado' },
  { id: '784110 Operador de máquina a vácuo', descricao: 'Operador de máquina a vácuo' },
  { id: '641015 Operador de máquina agrícola', descricao: 'Operador de máquina agrícola' },
  { id: '773405 Operador de máquina bordatriz', descricao: 'Operador de máquina bordatriz' },
  { id: '752420 Operador de máquina cerâmica (pintor)', descricao: 'Operador de máquina cerâmica (pintor)' },
  { id: '761330 Operador de máquina circular de malharia', descricao: 'Operador de máquina circular de malharia' },
  { id: '415130 Operador de máquina copiadora (exceto operador de gráfica rápida)', descricao: 'Operador de máquina copiadora (exceto operador de gráfica rápida)' },
  { id: '711215 Operador de máquina cortadora (minas e pedreiras)', descricao: 'Operador de máquina cortadora (minas e pedreiras)' },
  { id: '715120 Operador de máquina de abrir valas', descricao: 'Operador de máquina de abrir valas' },
  { id: '311725 Operador de máquina de alvejar (couros)', descricao: 'Operador de máquina de alvejar (couros)' },
  { id: '764210 Operador de máquina de apontar bico', descricao: 'Operador de máquina de apontar bico' },
  { id: '813125 Operador de máquina de arame', descricao: 'Operador de máquina de arame' },
  { id: '722325 Operador de máquina de areia para fundição', descricao: 'Operador de máquina de areia para fundição' },
  { id: '763310 Operador de máquina de bordar', descricao: 'Operador de máquina de bordar' },
  { id: '722405 Operador de máquina de cablear', descricao: 'Operador de máquina de cablear' },
  { id: '833105 Operador de máquina de cartonagem', descricao: 'Operador de máquina de cartonagem' },
  { id: '842125 Operador de máquina de cigarros', descricao: 'Operador de máquina de cigarros' },
  { id: '724505 Operador de máquina de cilindrar chapas', descricao: 'Operador de máquina de cilindrar chapas' },
  { id: '862130 Operador de máquina de compressão de ar', descricao: 'Operador de máquina de compressão de ar' },
  { id: '773415 Operador de máquina de confeccionar palitos', descricao: 'Operador de máquina de confeccionar palitos' },
  { id: '761354 Operador de máquina de cordoalha', descricao: 'Operador de máquina de cordoalha' },
  { id: '833120 Operador de máquina de cortar e dobrar papelão', descricao: 'Operador de máquina de cortar e dobrar papelão' },
  { id: '828110 Operador de máquina de cortar tijolos e telhas', descricao: 'Operador de máquina de cortar tijolos e telhas' },
  { id: '833115 Operador de máquina de cortar, colar e dobrar celofane e similar', descricao: 'Operador de máquina de cortar, colar e dobrar celofane e similar' },
  { id: '833110 Operador de máquina de cortar, colar e dobrar papel', descricao: 'Operador de máquina de cortar, colar e dobrar papel' },
  { id: '752210 Operador de máquina de corte (vidros)', descricao: 'Operador de máquina de corte (vidros)' },
  { id: '821440 Operador de máquina de corte a gás', descricao: 'Operador de máquina de corte a gás' },
  { id: '763110 Operador de máquina de corte de roupas', descricao: 'Operador de máquina de corte de roupas' },
  { id: '773410 Operador de máquina de cortina d´água (produção de móveis)', descricao: 'Operador de máquina de cortina d´água (produção de móveis)' },
  { id: '763320 Operador de máquina de costura de acabamento', descricao: 'Operador de máquina de costura de acabamento' },
  { id: '724510 Operador de máquina de curvar metais', descricao: 'Operador de máquina de curvar metais' },
  { id: '712205 Operador de máquina de desdobramento de mármores', descricao: 'Operador de máquina de desdobramento de mármores' },
  { id: '773120 Operador de máquina de desdobrar madeira', descricao: 'Operador de máquina de desdobrar madeira' },
  { id: '724510 Operador de máquina de dobrar chapas', descricao: 'Operador de máquina de dobrar chapas' },
  { id: '721205 Operador de máquina de eletroerosão', descricao: 'Operador de máquina de eletroerosão' },
  { id: '784110 Operador de máquina de embalar', descricao: 'Operador de máquina de embalar' },
  { id: '784110 Operador de máquina de embrulhar', descricao: 'Operador de máquina de embrulhar' },
  { id: '784110 Operador de máquina de empacotar', descricao: 'Operador de máquina de empacotar' },
  { id: '842125 Operador de máquina de encarteiramento (cigarros)', descricao: 'Operador de máquina de encarteiramento (cigarros)' },
  { id: '765205 Operador de máquina de encher colchões', descricao: 'Operador de máquina de encher colchões' },
  { id: '776415 Operador de máquina de encher escovas, pincéis e vassouras', descricao: 'Operador de máquina de encher escovas, pincéis e vassouras' },
  { id: '763115 Operador de máquina de enfestar', descricao: 'Operador de máquina de enfestar' },
  { id: '784120 Operador de máquina de engarrafamento', descricao: 'Operador de máquina de engarrafamento' },
  { id: '784110 Operador de máquina de enlatar', descricao: 'Operador de máquina de enlatar' },
  { id: '784120 Operador de máquina de envasar líquidos', descricao: 'Operador de máquina de envasar líquidos' },
  { id: '848515 Operador de máquina de escarnar', descricao: 'Operador de máquina de escarnar' },
  { id: '412105 Operador de máquina de escrever', descricao: 'Operador de máquina de escrever' },
  { id: '752420 Operador de máquina de esmaltação cerâmica', descricao: 'Operador de máquina de esmaltação cerâmica' },
  { id: '823245 Operador de máquina de esticar varetas ou tubos de vidro', descricao: 'Operador de máquina de esticar varetas ou tubos de vidro' },
  { id: '752110 Operador de máquina de estirar vidro', descricao: 'Operador de máquina de estirar vidro' },
  { id: '784115 Operador de máquina de etiquetar', descricao: 'Operador de máquina de etiquetar' },
  { id: '711220 Operador de máquina de extração contínua (minas de carvão)', descricao: 'Operador de máquina de extração contínua (minas de carvão)' },
  { id: '811815 Operador de máquina de fabricação de cosméticos', descricao: 'Operador de máquina de fabricação de cosméticos' },
  { id: '811820 Operador de máquina de fabricação de produtos de higiene e limpeza (sabão, sabonete, detergente,', descricao: 'Operador de máquina de fabricação de produtos de higiene e limpeza (sabão, sabonete, detergente,' },
  { id: '842210 Operador de máquina de fabricar charutos e cigarrilhas', descricao: 'Operador de máquina de fabricar charutos e cigarrilhas' },
  { id: '842125 Operador de máquina de fabricar cigarros', descricao: 'Operador de máquina de fabricar cigarros' },
  { id: '862505 Operador de máquina de fabricar gelo', descricao: 'Operador de máquina de fabricar gelo' },
  { id: '722310 Operador de máquina de fabricar machos (ar comprimido)', descricao: 'Operador de máquina de fabricar machos (ar comprimido)' },
  { id: '832125 Operador de máquina de fabricar papel', descricao: 'Operador de máquina de fabricar papel' },
  { id: '832115 Operador de máquina de fabricar papel (fase úmida)', descricao: 'Operador de máquina de fabricar papel (fase úmida)' },
  { id: '832120 Operador de máquina de fabricar papel (fase seca)', descricao: 'Operador de máquina de fabricar papel (fase seca)' },
  { id: '832125 Operador de máquina de fabricar papel e papelão', descricao: 'Operador de máquina de fabricar papel e papelão' },
  { id: '811320 Operador de máquina de filtro rotativo (tratamentos químicos)', descricao: 'Operador de máquina de filtro rotativo (tratamentos químicos)' },
  { id: '842125 Operador de máquina de filtros (cigarros)', descricao: 'Operador de máquina de filtros (cigarros)' },
  { id: '722220 Operador de máquina de fundição', descricao: 'Operador de máquina de fundição' },
  { id: '722225 Operador de máquina de fundir sob pressão', descricao: 'Operador de máquina de fundir sob pressão' },
  { id: '821315 Operador de máquina de laminação (a quente)', descricao: 'Operador de máquina de laminação (a quente)' },
  { id: '841505 Operador de máquina de laticínios', descricao: 'Operador de máquina de laticínios' },
  { id: '761110 Operador de máquina de lavanderia de lã', descricao: 'Operador de máquina de lavanderia de lã' },
  { id: '761430 Operador de máquina de lavar fios e tecidos', descricao: 'Operador de máquina de lavar fios e tecidos' },
  { id: '516310 Operador de máquina de lavar roupas, em geral', descricao: 'Operador de máquina de lavar roupas, em geral' },
  { id: '722310 Operador de máquina de machos', descricao: 'Operador de máquina de machos' },
  { id: '848215 Operador de máquina de manteiga', descricao: 'Operador de máquina de manteiga' },
  { id: '722330 Operador de máquina de moldar automatizada', descricao: 'Operador de máquina de moldar automatizada' },
  { id: '722205 Operador de máquina de moldar lingoteira', descricao: 'Operador de máquina de moldar lingoteira' },
  { id: '823250 Operador de máquina de moldar vidro', descricao: 'Operador de máquina de moldar vidro' },
  { id: '848205 Operador de máquina de pasteurizar', descricao: 'Operador de máquina de pasteurizar' },
  { id: '711225 Operador de máquina de perfurar (minas e pedreiras)', descricao: 'Operador de máquina de perfurar (minas e pedreiras)' },
  { id: '723330 Operador de máquina de pintar contínua', descricao: 'Operador de máquina de pintar contínua' },
  { id: '842135 Operador de máquina de preparação de matéria prima para produção de cigarros', descricao: 'Operador de máquina de preparação de matéria prima para produção de cigarros' },
  { id: '773415 Operador de máquina de produção na usinagem de madeira', descricao: 'Operador de máquina de produção na usinagem de madeira' },
  { id: '811805 Operador de máquina de produtos farmacêuticos', descricao: 'Operador de máquina de produtos farmacêuticos' },
  { id: '784115 Operador de máquina de rotular', descricao: 'Operador de máquina de rotular' },
  { id: '831125 Operador de máquina de secar celulose', descricao: 'Operador de máquina de secar celulose' },
  { id: '773110 Operador de máquina de serrar', descricao: 'Operador de máquina de serrar' },
  { id: '773120 Operador de máquina de serrar madeira', descricao: 'Operador de máquina de serrar madeira' },
  { id: '773120 Operador de máquina de serraria', descricao: 'Operador de máquina de serraria' },
  { id: '821110 Operador de máquina de sinterizar', descricao: 'Operador de máquina de sinterizar' },
  { id: '724315 Operador de máquina de solda a ultrassom', descricao: 'Operador de máquina de solda a ultrassom' },
  { id: '724325 Operador de máquina de solda elétrica', descricao: 'Operador de máquina de solda elétrica' },
  { id: '724315 Operador de máquina de solda eletrônica', descricao: 'Operador de máquina de solda eletrônica' },
  { id: '724315 Operador de máquina de soldar', descricao: 'Operador de máquina de soldar' },
  { id: '724315 Operador de máquina de soldar automática', descricao: 'Operador de máquina de soldar automática' },
  { id: '823240 Operador de máquina de soprar vidro', descricao: 'Operador de máquina de soprar vidro' },
  { id: '715125 Operador de máquina de terraplenagem', descricao: 'Operador de máquina de terraplenagem' },
  { id: '841625 Operador de máquina de torrefação de cacau', descricao: 'Operador de máquina de torrefação de cacau' },
  { id: '773415 Operador de máquina de usinagem de madeira (produção em série)', descricao: 'Operador de máquina de usinagem de madeira (produção em série)' },
  { id: '773325 Operador de máquina de usinagem madeira, em geral', descricao: 'Operador de máquina de usinagem madeira, em geral' },
  { id: '752110 Operador de máquina de vidraria', descricao: 'Operador de máquina de vidraria' },
  { id: '724510 Operador de máquina de virar chapas', descricao: 'Operador de máquina de virar chapas' },
  { id: '721420 Operador de máquina eletroerosão, à fio, com comando numérico', descricao: 'Operador de máquina eletroerosão, à fio, com comando numérico' },
  { id: '823245 Operador de máquina extrusora de fibra de vidro', descricao: 'Operador de máquina extrusora de fibra de vidro' },
  { id: '823245 Operador de máquina extrusora de varetas e tubos de vidro', descricao: 'Operador de máquina extrusora de varetas e tubos de vidro' },
  { id: '823245 Operador de máquina extrusora de vidro', descricao: 'Operador de máquina extrusora de vidro' },
  { id: '773205 Operador de máquina intercaladora de placas (compensados)', descricao: 'Operador de máquina intercaladora de placas (compensados)' },
  { id: '811110 Operador de máquina misturadeira (tratamentos químicos e afins)', descricao: 'Operador de máquina misturadeira (tratamentos químicos e afins)' },
  { id: '823320 Operador de máquina na fabricação de artefatos de cimento', descricao: 'Operador de máquina na fabricação de artefatos de cimento' },
  { id: '841810 Operador de máquina na fabricação de macarrão.', descricao: 'Operador de máquina na fabricação de macarrão.' },
  { id: '766215 Operador de máquina ofsete', descricao: 'Operador de máquina ofsete' },
  { id: '711225 Operador de máquina perfuradora (minas e pedreiras)', descricao: 'Operador de máquina perfuradora (minas e pedreiras)' },
  { id: '711130 Operador de máquina perfuradora - na mineração', descricao: 'Operador de máquina perfuradora - na mineração' },
  { id: '711230 Operador de máquina perfuratriz', descricao: 'Operador de máquina perfuratriz' },
  { id: '711230 Operador de máquina perfuratriz a fio diamantado', descricao: 'Operador de máquina perfuratriz a fio diamantado' },
  { id: '784110 Operador de máquina pneumática', descricao: 'Operador de máquina pneumática' },
  { id: '723230 Operador de máquina recobridora de arame', descricao: 'Operador de máquina recobridora de arame' },
  { id: '415130 Operador de máquina reprográfica', descricao: 'Operador de máquina reprográfica' },
  { id: '761333 Operador de máquina retilínea de malharia', descricao: 'Operador de máquina retilínea de malharia' },
  { id: '782120 Operador de máquina rodoferroviária', descricao: 'Operador de máquina rodoferroviária' },
  { id: '715125 Operador de máquina rodoviária', descricao: 'Operador de máquina rodoviária' },
  { id: '632125 Operador de máquina transportadora (madeira)', descricao: 'Operador de máquina transportadora (madeira)' },
  { id: '811115 Operador de máquina trituradora (tratamentos químicos e afins)', descricao: 'Operador de máquina trituradora (tratamentos químicos e afins)' },
  { id: '782205 Operador de máquina-elevador', descricao: 'Operador de máquina-elevador' },
  { id: '811310 Operador de máquinas (exploração de petróleo)', descricao: 'Operador de máquinas (exploração de petróleo)' },
  { id: '641010 Operador de máquinas agrícolas', descricao: 'Operador de máquinas agrícolas' },
  { id: '641010 Operador de máquinas de beneficiamento de produtos agrícolas', descricao: 'Operador de máquinas de beneficiamento de produtos agrícolas' },
  { id: '811305 Operador de máquinas de centrifugar (tratamentos químicos e afins)', descricao: 'Operador de máquinas de centrifugar (tratamentos químicos e afins)' },
  { id: '773415 Operador de máquinas de confeccionar fósforo', descricao: 'Operador de máquinas de confeccionar fósforo' },
  { id: '715125 Operador de máquinas de construção civil e mineração', descricao: 'Operador de máquinas de construção civil e mineração' },
  { id: '761405 Operador de máquinas de cozinhamento e alvejamento de fibras soltas, fios e tecidos', descricao: 'Operador de máquinas de cozinhamento e alvejamento de fibras soltas, fios e tecidos' },
  { id: '841210 Operador de máquinas de embalagem de sal', descricao: 'Operador de máquinas de embalagem de sal' },
  { id: '841815 Operador de máquinas de fabricação de chocolates e achocolatados', descricao: 'Operador de máquinas de fabricação de chocolates e achocolatados' },
  { id: '841810 Operador de máquinas de fabricação de doces, salgados e massas alimentícias', descricao: 'Operador de máquinas de fabricação de doces, salgados e massas alimentícias' },
  { id: '761415 Operador de máquinas de flanelar (peletizar, lixar) tecidos', descricao: 'Operador de máquinas de flanelar (peletizar, lixar) tecidos' },
  { id: '761425 Operador de máquinas de impermeabilizar tecidos', descricao: 'Operador de máquinas de impermeabilizar tecidos' },
  { id: '761430 Operador de máquinas de lavar fibras soltas, fios, tecidos e peças confeccionadas', descricao: 'Operador de máquinas de lavar fibras soltas, fios, tecidos e peças confeccionadas' },
  { id: '731180 Operador de máquinas de linha de montagem (aparelhos eletrônicos)', descricao: 'Operador de máquinas de linha de montagem (aparelhos eletrônicos)' },
  { id: '761425 Operador de máquinas de mercerizar fios e tecidos', descricao: 'Operador de máquinas de mercerizar fios e tecidos' },
  { id: '711130 Operador de máquinas de mineração', descricao: 'Operador de máquinas de mineração' },
  { id: '773510 Operador de máquinas de usinar madeira (cnc)', descricao: 'Operador de máquinas de usinar madeira (cnc)' },
  { id: '762325 Operador de máquinas do acabamento de couros e peles', descricao: 'Operador de máquinas do acabamento de couros e peles' },
  { id: '782120 Operador de máquinas e equipamentos rodoferroviários', descricao: 'Operador de máquinas e equipamentos rodoferroviários' },
  { id: '991115 Operador de máquinas e veículos especiais na conservação de vias permanentes (trilhos)', descricao: 'Operador de máquinas e veículos especiais na conservação de vias permanentes (trilhos)' },
  { id: '991115 Operador de máquinas especiais em conservação de via permanente (trilhos)', descricao: 'Operador de máquinas especiais em conservação de via permanente (trilhos)' },
  { id: '862150 Operador de máquinas fixas, em geral', descricao: 'Operador de máquinas fixas, em geral' },
  { id: '642005 Operador de máquinas florestais (colheitadeira)', descricao: 'Operador de máquinas florestais (colheitadeira)' },
  { id: '642015 Operador de máquinas florestais (tratores)', descricao: 'Operador de máquinas florestais (tratores)' },
  { id: '642010 Operador de máquinas florestais estáticas', descricao: 'Operador de máquinas florestais estáticas' },
  { id: '721210 Operador de máquinas operatrizes', descricao: 'Operador de máquinas operatrizes' },
  { id: '721215 Operador de máquinas-ferramenta convencionais', descricao: 'Operador de máquinas-ferramenta convencionais' },
  { id: '712135 Operador de maracá - no garimpo', descricao: 'Operador de maracá - no garimpo' },
  { id: '828110 Operador de maromba', descricao: 'Operador de maromba' },
  { id: '717010 Operador de martelete', descricao: 'Operador de martelete' },
  { id: '412115 Operador de mensagens de telecomunicações (correios)', descricao: 'Operador de mensagens de telecomunicações (correios)' },
  { id: '373105 Operador de mesa (rádio)', descricao: 'Operador de mesa (rádio)' },
  { id: '253305 Operador de mesa bolsa de valores', descricao: 'Operador de mesa bolsa de valores' },
  { id: '724440 Operador de mesa de corte (serralharia)', descricao: 'Operador de mesa de corte (serralharia)' },
  { id: '722210 Operador de mesa de recebimento de lingotes', descricao: 'Operador de mesa de recebimento de lingotes' },
  { id: '422205 Operador de mesa telefônica', descricao: 'Operador de mesa telefônica' },
  { id: '841310 Operador de mexedeira na refinação de açúcar', descricao: 'Operador de mexedeira na refinação de açúcar' },
  { id: '412110 Operador de microcomputador', descricao: 'Operador de microcomputador' },
  { id: '373145 Operador de microfone', descricao: 'Operador de microfone' },
  { id: '373105 Operador de mídia audiovisual', descricao: 'Operador de mídia audiovisual' },
  { id: '766405 Operador de mini-lab', descricao: 'Operador de mini-lab' },
  { id: '811110 Operador de mistura (tratamentos químicos e afins)', descricao: 'Operador de mistura (tratamentos químicos e afins)' },
  { id: '722325 Operador de misturador de areia para fundição', descricao: 'Operador de misturador de areia para fundição' },
  { id: '841315 Operador de moenda na fabricação de açúcar', descricao: 'Operador de moenda na fabricação de açúcar' },
  { id: '712105 Operador de moinho (beneficiamento de minérios)', descricao: 'Operador de moinho (beneficiamento de minérios)' },
  { id: '841115 Operador de moinho (processo de moagem)', descricao: 'Operador de moinho (processo de moagem)' },
  { id: '811105 Operador de moinho (tratamentos químicos e afins)', descricao: 'Operador de moinho (tratamentos químicos e afins)' },
  { id: '712105 Operador de moinho de bolas (minério)', descricao: 'Operador de moinho de bolas (minério)' },
  { id: '841110 Operador de moinho de especiarias', descricao: 'Operador de moinho de especiarias' },
  { id: '841105 Operador de moinho de farinha crua', descricao: 'Operador de moinho de farinha crua' },
  { id: '841115 Operador de moinho e mistura', descricao: 'Operador de moinho e mistura' },
  { id: '823115 Operador de moinhos (argila)', descricao: 'Operador de moinhos (argila)' },
  { id: '773330 Operador de molduradora (usinagem de madeira)', descricao: 'Operador de molduradora (usinagem de madeira)' },
  { id: '782125 Operador de monta-cargas (construção civil)', descricao: 'Operador de monta-cargas (construção civil)' },
  { id: '821330 Operador de montagem de cilindros e mancais', descricao: 'Operador de montagem de cilindros e mancais' },
  { id: '641010 Operador de motobomba', descricao: 'Operador de motobomba' },
  { id: '715130 Operador de motoniveladora', descricao: 'Operador de motoniveladora' },
  { id: '711235 Operador de motoniveladora (extração de minerais sólidos)', descricao: 'Operador de motoniveladora (extração de minerais sólidos)' },
  { id: '632120 Operador de motosserra', descricao: 'Operador de motosserra' },
  { id: '414110 Operador de movimentação e armazenamento de carga', descricao: 'Operador de movimentação e armazenamento de carga' },
  { id: '253225 Operador de negócios', descricao: 'Operador de negócios' },
  { id: '715130 Operador de niveladora e de scraper', descricao: 'Operador de niveladora e de scraper' },
  { id: '862140 Operador de oleoduto', descricao: 'Operador de oleoduto' },
  { id: '832125 Operador de onduladora de papel', descricao: 'Operador de onduladora de papel' },
  { id: '761245 Operador de open-end', descricao: 'Operador de open-end' },
  { id: '623115 Operador de ordenhadeira', descricao: 'Operador de ordenhadeira' },
  { id: '724310 Operador de oxicorte', descricao: 'Operador de oxicorte' },
  { id: '715135 Operador de pá carregadeira', descricao: 'Operador de pá carregadeira' },
  { id: '715135 Operador de pá carregadeira de esteira', descricao: 'Operador de pá carregadeira de esteira' },
  { id: '715135 Operador de pá carregadeira de pneu', descricao: 'Operador de pá carregadeira de pneu' },
  { id: '715135 Operador de pá carregadeira e tratores', descricao: 'Operador de pá carregadeira e tratores' },
  { id: '715135 Operador de pá mecânica', descricao: 'Operador de pá mecânica' },
  { id: '715135 Operador de pá mecânica em subsolo', descricao: 'Operador de pá mecânica em subsolo' },
  { id: '422205 Operador de pabx', descricao: 'Operador de pabx' },
  { id: '811630 Operador de painel de controle', descricao: 'Operador de painel de controle' },
  { id: '811505 Operador de painel de controle (refinação de petróleo)', descricao: 'Operador de painel de controle (refinação de petróleo)' },
  { id: '773205 Operador de passadeira na fabricação de compensados', descricao: 'Operador de passadeira na fabricação de compensados' },
  { id: '761250 Operador de passador (fiação)', descricao: 'Operador de passador (fiação)' },
  { id: '841505 Operador de pasteurizador', descricao: 'Operador de pasteurizador' },
  { id: '715130 Operador de patrol (niveladora)', descricao: 'Operador de patrol (niveladora)' },
  { id: '711235 Operador de patrola', descricao: 'Operador de patrola' },
  { id: '715140 Operador de pavimentadora (asfalto, concreto e materiais similares)', descricao: 'Operador de pavimentadora (asfalto, concreto e materiais similares)' },
  { id: '711115 Operador de pedreira', descricao: 'Operador de pedreira' },
  { id: '712135 Operador de pelotização', descricao: 'Operador de pelotização' },
  { id: '712135 Operador de peneiras hidráulicas', descricao: 'Operador de peneiras hidráulicas' },
  { id: '712135 Operador de peneiras vibratórias', descricao: 'Operador de peneiras vibratórias' },
  { id: '761255 Operador de penteadeira', descricao: 'Operador de penteadeira' },
  { id: '773415 Operador de perfiladeira', descricao: 'Operador de perfiladeira' },
  { id: '414115 Operador de pesagem de matéria prima', descricao: 'Operador de pesagem de matéria prima' },
  { id: '723330 Operador de pistola de pintura', descricao: 'Operador de pistola de pintura' },
  { id: '723330 Operador de pistola rotativa', descricao: 'Operador de pistola rotativa' },
  { id: '773335 Operador de plaina desengrossadeira', descricao: 'Operador de plaina desengrossadeira' },
  { id: '711325 Operador de plataforma (petróleo)', descricao: 'Operador de plataforma (petróleo)' },
  { id: '752235 Operador de polidora de cristais de ótica', descricao: 'Operador de polidora de cristais de ótica' },
  { id: '752230 Operador de polidora de vidros e cristais', descricao: 'Operador de polidora de vidros e cristais' },
  { id: '782130 Operador de ponte rolante', descricao: 'Operador de ponte rolante' },
  { id: '782135 Operador de pórtico rolante', descricao: 'Operador de pórtico rolante' },
  { id: '712115 Operador de precipitador eletrostático', descricao: 'Operador de precipitador eletrostático' },
  { id: '842110 Operador de prensa', descricao: 'Operador de prensa' },
  { id: '722115 Operador de prensa (ferramentaria)', descricao: 'Operador de prensa (ferramentaria)' },
  { id: '775110 Operador de prensa (folha de recobrimento de móveis)', descricao: 'Operador de prensa (folha de recobrimento de móveis)' },
  { id: '828110 Operador de prensa - em olaria', descricao: 'Operador de prensa - em olaria' },
  { id: '833125 Operador de prensa a fabricação de papel', descricao: 'Operador de prensa a fabricação de papel' },
  { id: '773420 Operador de prensa de alta freqüência na usinagem de madeira', descricao: 'Operador de prensa de alta freqüência na usinagem de madeira' },
  { id: '722325 Operador de prensa de areia para fundição', descricao: 'Operador de prensa de areia para fundição' },
  { id: '773215 Operador de prensa de compensados', descricao: 'Operador de prensa de compensados' },
  { id: '833125 Operador de prensa de embutir papelão', descricao: 'Operador de prensa de embutir papelão' },
  { id: '784125 Operador de prensa de enfardamento', descricao: 'Operador de prensa de enfardamento' },
  { id: '784125 Operador de prensa de fardos', descricao: 'Operador de prensa de fardos' },
  { id: '519215 Operador de prensa de material reciclável', descricao: 'Operador de prensa de material reciclável' },
  { id: '823250 Operador de prensa de moldar vidro', descricao: 'Operador de prensa de moldar vidro' },
  { id: '764105 Operador de prensa na indústria de calçados', descricao: 'Operador de prensa na indústria de calçados' },
  { id: '841464 Operador de prensadora de frutas', descricao: 'Operador de prensadora de frutas' },
  { id: '841460 Operador de preparação de grãos vegetais (óleos e gorduras)', descricao: 'Operador de preparação de grãos vegetais (óleos e gorduras)' },
  { id: '841810 Operador de preparo de massas (alimentícias)', descricao: 'Operador de preparo de massas (alimentícias)' },
  { id: '811635 Operador de preservação de área carboquímicos', descricao: 'Operador de preservação de área carboquímicos' },
  { id: '811635 Operador de preservação e controle térmico', descricao: 'Operador de preservação e controle térmico' },
  { id: '811510 Operador de processamento (transferência e estocagem de petróleo)', descricao: 'Operador de processamento (transferência e estocagem de petróleo)' },
  { id: '811505 Operador de processo (petróleo)', descricao: 'Operador de processo (petróleo)' },
  { id: '813120 Operador de processo (química, petroquímica e afins)', descricao: 'Operador de processo (química, petroquímica e afins)' },
  { id: '813120 Operador de processo de golfragem', descricao: 'Operador de processo de golfragem' },
  { id: '841115 Operador de processo de moagem', descricao: 'Operador de processo de moagem' },
  { id: '784205 Operador de processo de produção', descricao: 'Operador de processo de produção' },
  { id: '766150 Operador de processo de tratamento de imagem', descricao: 'Operador de processo de tratamento de imagem' },
  { id: '723235 Operador de processo de zincagem', descricao: 'Operador de processo de zincagem' },
  { id: '811310 Operador de processos (exploração de petróleo)', descricao: 'Operador de processos (exploração de petróleo)' },
  { id: '781105 Operador de processos automatizados de pintura', descricao: 'Operador de processos automatizados de pintura' },
  { id: '831110 Operador de processos de semibranqueamento de pasta/celulose', descricao: 'Operador de processos de semibranqueamento de pasta/celulose' },
  { id: '811005 Operador de processos químicos e petroquímicos', descricao: 'Operador de processos químicos e petroquímicos' },
  { id: '813125 Operador de produção (química, petroquímica e afins)', descricao: 'Operador de produção (química, petroquímica e afins)' },
  { id: '811425 Operador de produção de álcool', descricao: 'Operador de produção de álcool' },
  { id: '914305 Operador de produção metroferroviária', descricao: 'Operador de produção metroferroviária' },
  { id: '342410 Operador de programação (ferrovia)', descricao: 'Operador de programação (ferrovia)' },
  { id: '374305 Operador de projetor cinematográfico', descricao: 'Operador de projetor cinematográfico' },
  { id: '374305 Operador de projetor cinematográfico e áudio', descricao: 'Operador de projetor cinematográfico e áudio' },
  { id: '861110 Operador de quadro de alimentação (subestação de distribuição de energia elétrica)', descricao: 'Operador de quadro de alimentação (subestação de distribuição de energia elétrica)' },
  { id: '861110 Operador de quadro de distribuição de energia elétrica', descricao: 'Operador de quadro de distribuição de energia elétrica' },
  { id: '848210 Operador de queijeira', descricao: 'Operador de queijeira' },
  { id: '373105 Operador de rádio', descricao: 'Operador de rádio' },
  { id: '422220 Operador de rádio (telefonia)', descricao: 'Operador de rádio (telefonia)' },
  { id: '422220 Operador de rádio-chamada', descricao: 'Operador de rádio-chamada' },
  { id: '422220 Operador de radiotelefonia', descricao: 'Operador de radiotelefonia' },
  { id: '372210 Operador de radiotelégrafo', descricao: 'Operador de radiotelégrafo' },
  { id: '324115 Operador de raio-x', descricao: 'Operador de raio-x' },
  { id: '761435 Operador de rameuse', descricao: 'Operador de rameuse' },
  { id: '342560 Operador de rampa ( transporte aéreo)', descricao: 'Operador de rampa ( transporte aéreo)' },
  { id: '712125 Operador de reagentes', descricao: 'Operador de reagentes' },
  { id: '811420 Operador de reator (produtos químicos exceto petróleo)', descricao: 'Operador de reator (produtos químicos exceto petróleo)' },
  { id: '811640 Operador de reator de coque de petróleo', descricao: 'Operador de reator de coque de petróleo' },
  { id: '861120 Operador de reator nuclear', descricao: 'Operador de reator nuclear' },
  { id: '811125 Operador de reatores (fabricação de vernizes e resinas)', descricao: 'Operador de reatores (fabricação de vernizes e resinas)' },
  { id: '832135 Operador de rebobinadeira na fabricação de papel e papelão', descricao: 'Operador de rebobinadeira na fabricação de papel e papelão' },
  { id: '821240 Operador de recebimento de gusa', descricao: 'Operador de recebimento de gusa' },
  { id: '372205 Operador de rede de teleprocessamento', descricao: 'Operador de rede de teleprocessamento' },
  { id: '372205 Operador de rede de transmissão de dados', descricao: 'Operador de rede de transmissão de dados' },
  { id: '841210 Operador de refinaria de sal', descricao: 'Operador de refinaria de sal' },
  { id: '811645 Operador de refrigeração (coqueria)', descricao: 'Operador de refrigeração (coqueria)' },
  { id: '862510 Operador de refrigeração com amônia', descricao: 'Operador de refrigeração com amônia' },
  { id: '253305 Operador de renda fixa', descricao: 'Operador de renda fixa' },
  { id: '253305 Operador de renda variável', descricao: 'Operador de renda variável' },
  { id: '724610 Operador de repassadeira', descricao: 'Operador de repassadeira' },
  { id: '721425 Operador de retificadora com comando numérico', descricao: 'Operador de retificadora com comando numérico' },
  { id: '761260 Operador de retorcedeira', descricao: 'Operador de retorcedeira' },
  { id: '715115 Operador de retro-escavadeira', descricao: 'Operador de retro-escavadeira' },
  { id: '822125 Operador de revérbero', descricao: 'Operador de revérbero' },
  { id: '781105 Operador de robô de pintura', descricao: 'Operador de robô de pintura' },
  { id: '781110 Operador de robô de soldagem', descricao: 'Operador de robô de soldagem' },
  { id: '715705 Operador de rolo (impermeabilização)', descricao: 'Operador de rolo (impermeabilização)' },
  { id: '715110 Operador de rolo compactador', descricao: 'Operador de rolo compactador' },
  { id: '715110 Operador de rolo compressor', descricao: 'Operador de rolo compressor' },
  { id: '711130 Operador de rompedor', descricao: 'Operador de rompedor' },
  { id: '781305 Operador de rov', descricao: 'Operador de rov' },
  { id: '841730 Operador de sala de brassagem', descricao: 'Operador de sala de brassagem' },
  { id: '711130 Operador de sala de controle - na mineração', descricao: 'Operador de sala de controle - na mineração' },
  { id: '811010 Operador de sala de controle de instalações químicas, petroquímicas e afins', descricao: 'Operador de sala de controle de instalações químicas, petroquímicas e afins' },
  { id: '862110 Operador de sala de máquinas (embarcações)', descricao: 'Operador de sala de máquinas (embarcações)' },
  { id: '711410 Operador de salina (sal marinho)', descricao: 'Operador de salina (sal marinho)' },
  { id: '711240 Operador de schutthecar', descricao: 'Operador de schutthecar' },
  { id: '831110 Operador de sdcd', descricao: 'Operador de sdcd' },
  { id: '641010 Operador de secadeiras no beneficiamento de produtos agrícolas', descricao: 'Operador de secadeiras no beneficiamento de produtos agrícolas' },
  { id: '641010 Operador de secador (produtos agrícolas)', descricao: 'Operador de secador (produtos agrícolas)' },
  { id: '828110 Operador de secador da fabricação cerâmica', descricao: 'Operador de secador da fabricação cerâmica' },
  { id: '722325 Operador de secador de areia para fundição', descricao: 'Operador de secador de areia para fundição' },
  { id: '841630 Operador de secador de mate', descricao: 'Operador de secador de mate' },
  { id: '641010 Operador de secador de resíduos', descricao: 'Operador de secador de resíduos' },
  { id: '841315 Operador de secador na fabricação de açúcar', descricao: 'Operador de secador na fabricação de açúcar' },
  { id: '841305 Operador de secador na fabricação e refino de açúcar', descricao: 'Operador de secador na fabricação e refino de açúcar' },
  { id: '516310 Operador de secadora de roupas', descricao: 'Operador de secadora de roupas' },
  { id: '773415 Operador de seccionadeira na usinagem de madeira', descricao: 'Operador de seccionadeira na usinagem de madeira' },
  { id: '766205 Operador de serigrafia', descricao: 'Operador de serigrafia' },
  { id: '773120 Operador de serra automática', descricao: 'Operador de serra automática' },
  { id: '773125 Operador de serra circular', descricao: 'Operador de serra circular' },
  { id: '773125 Operador de serra circular automática', descricao: 'Operador de serra circular automática' },
  { id: '773125 Operador de serra circular múltipla', descricao: 'Operador de serra circular múltipla' },
  { id: '773120 Operador de serra de disco', descricao: 'Operador de serra de disco' },
  { id: '773130 Operador de serra de fita', descricao: 'Operador de serra de fita' },
  { id: '773110 Operador de serra elétrica', descricao: 'Operador de serra elétrica' },
  { id: '773110 Operador de serra esquadrejadeira', descricao: 'Operador de serra esquadrejadeira' },
  { id: '773110 Operador de serra manual', descricao: 'Operador de serra manual' },
  { id: '773110 Operador de serra mecânica', descricao: 'Operador de serra mecânica' },
  { id: '773110 Operador de serra vertical automática', descricao: 'Operador de serra vertical automática' },
  { id: '773120 Operador de serra-cinta', descricao: 'Operador de serra-cinta' },
  { id: '632120 Operador de serras (exploração florestal)', descricao: 'Operador de serras (exploração florestal)' },
  { id: '773340 Operador de serras (usinagem de madeira)', descricao: 'Operador de serras (usinagem de madeira)' },
  { id: '773110 Operador de serras no desdobramento de madeira', descricao: 'Operador de serras no desdobramento de madeira' },
  { id: '848505 Operador de setor de matanças de gado', descricao: 'Operador de setor de matanças de gado' },
  { id: '782110 Operador de ship loader', descricao: 'Operador de ship loader' },
  { id: '766205 Operador de silkscreen', descricao: 'Operador de silkscreen' },
  { id: '821110 Operador de sinterização', descricao: 'Operador de sinterização' },
  { id: '781310 Operador de sistema de aeronaves remotamente pilotadas (rpas)', descricao: 'Operador de sistema de aeronaves remotamente pilotadas (rpas)' },
  { id: '862130 Operador de sistema de ar comprimido', descricao: 'Operador de sistema de ar comprimido' },
  { id: '722325 Operador de sistema de areia para fundição', descricao: 'Operador de sistema de areia para fundição' },
  { id: '811205 Operador de sistema de calcinação', descricao: 'Operador de sistema de calcinação' },
  { id: '862515 Operador de sistema de climatização', descricao: 'Operador de sistema de climatização' },
  { id: '317205 Operador de sistema de computador', descricao: 'Operador de sistema de computador' },
  { id: '862405 Operador de sistema de gás', descricao: 'Operador de sistema de gás' },
  { id: '712115 Operador de sistema de precipitação', descricao: 'Operador de sistema de precipitação' },
  { id: '862505 Operador de sistema de refrigeração', descricao: 'Operador de sistema de refrigeração' },
  { id: '811650 Operador de sistema de reversão (coqueria)', descricao: 'Operador de sistema de reversão (coqueria)' },
  { id: '317205 Operador de sistemas computacionais em rede', descricao: 'Operador de sistemas computacionais em rede' },
  { id: '862205 Operador de sistemas de água', descricao: 'Operador de sistemas de água' },
  { id: '372205 Operador de sistemas de informática (teleprocessamento)', descricao: 'Operador de sistemas de informática (teleprocessamento)' },
  { id: '766145 Operador de sistemas de prova (analógico e digital)', descricao: 'Operador de sistemas de prova (analógico e digital)' },
  { id: '711305 Operador de sonda de percussão', descricao: 'Operador de sonda de percussão' },
  { id: '711320 Operador de sonda manual', descricao: 'Operador de sonda manual' },
  { id: '711320 Operador de sonda pesada', descricao: 'Operador de sonda pesada' },
  { id: '711310 Operador de sonda rotativa', descricao: 'Operador de sonda rotativa' },
  { id: '821255 Operador de sopragem', descricao: 'Operador de sopragem' },
  { id: '841630 Operador de soque', descricao: 'Operador de soque' },
  { id: '861205 Operador de subestação', descricao: 'Operador de subestação' },
  { id: '832105 Operador de supercalandra', descricao: 'Operador de supercalandra' },
  { id: '723210 Operador de superfosfato', descricao: 'Operador de superfosfato' },
  { id: '422320 Operador de suporte técnico (telemarketing)', descricao: 'Operador de suporte técnico (telemarketing)' },
  { id: '782140 Operador de talha elétrica', descricao: 'Operador de talha elétrica' },
  { id: '811320 Operador de tambor rotativo', descricao: 'Operador de tambor rotativo' },
  { id: '761312 Operador de tear de jacquard', descricao: 'Operador de tear de jacquard' },
  { id: '768105 Operador de tear manual', descricao: 'Operador de tear manual' },
  { id: '761315 Operador de tear mecânico de maquineta', descricao: 'Operador de tear mecânico de maquineta' },
  { id: '761318 Operador de tear mecânico de xadrez', descricao: 'Operador de tear mecânico de xadrez' },
  { id: '761321 Operador de tear mecânico liso', descricao: 'Operador de tear mecânico liso' },
  { id: '761324 Operador de tear mecânico, exceto jacquad', descricao: 'Operador de tear mecânico, exceto jacquad' },
  { id: '422305 Operador de teleatendimento ativo (telemarketing)', descricao: 'Operador de teleatendimento ativo (telemarketing)' },
  { id: '422310 Operador de teleatendimento híbrido (telemarketing)', descricao: 'Operador de teleatendimento híbrido (telemarketing)' },
  { id: '421310 Operador de telecobrança', descricao: 'Operador de telecobrança' },
  { id: '412115 Operador de telecomunicações de correios', descricao: 'Operador de telecomunicações de correios' },
  { id: '782630 Operador de teleférico (passageiros)', descricao: 'Operador de teleférico (passageiros)' },
  { id: '422310 Operador de telemarketing (operações híbridas)', descricao: 'Operador de telemarketing (operações híbridas)' },
  { id: '422305 Operador de telemarketing ativo', descricao: 'Operador de telemarketing ativo' },
  { id: '422310 Operador de telemarketing ativo e receptivo', descricao: 'Operador de telemarketing ativo e receptivo' },
  { id: '422320 Operador de telemarketing bilíngüe', descricao: 'Operador de telemarketing bilíngüe' },
  { id: '422320 Operador de telemarketing especializado', descricao: 'Operador de telemarketing especializado' },
  { id: '422315 Operador de telemarketing receptivo', descricao: 'Operador de telemarketing receptivo' },
  { id: '422320 Operador de telemarketing técnico', descricao: 'Operador de telemarketing técnico' },
  { id: '372205 Operador de teleprocessamento', descricao: 'Operador de teleprocessamento' },
  { id: '421210 Operador de teleturfe', descricao: 'Operador de teleturfe' },
  { id: '412115 Operador de telex', descricao: 'Operador de telex' },
  { id: '821440 Operador de tesoura desbastadora, no acabamento chapas e metais', descricao: 'Operador de tesoura desbastadora, no acabamento chapas e metais' },
  { id: '821440 Operador de tesoura elétrica, no acabamento de chapas e metais', descricao: 'Operador de tesoura elétrica, no acabamento de chapas e metais' },
  { id: '821440 Operador de tesoura mecânica e máquina de corte, no acabamento de chapas e metais', descricao: 'Operador de tesoura mecânica e máquina de corte, no acabamento de chapas e metais' },
  { id: '821440 Operador de tesoura pendular, no acabamento de chapas e metais', descricao: 'Operador de tesoura pendular, no acabamento de chapas e metais' },
  { id: '821440 Operador de tesoura rotativa, no acabamento de chapas e metais', descricao: 'Operador de tesoura rotativa, no acabamento de chapas e metais' },
  { id: '821440 Operador de tesoura sucata', descricao: 'Operador de tesoura sucata' },
  { id: '821440 Operador de tesoura transversal, no acabamento de chapas e metais', descricao: 'Operador de tesoura transversal, no acabamento de chapas e metais' },
  { id: '821440 Operador de tesoura volante e guilhotina, no acabamento de chapas e metais', descricao: 'Operador de tesoura volante e guilhotina, no acabamento de chapas e metais' },
  { id: '725510 Operador de time de montagem', descricao: 'Operador de time de montagem' },
  { id: '773345 Operador de torno automático (usinagem de madeira)', descricao: 'Operador de torno automático (usinagem de madeira)' },
  { id: '721430 Operador de torno com comando numérico', descricao: 'Operador de torno com comando numérico' },
  { id: '752315 Operador de torno semi-automático (cerâmica)', descricao: 'Operador de torno semi-automático (cerâmica)' },
  { id: '711105 Operador de torre de amostragem - na mineração', descricao: 'Operador de torre de amostragem - na mineração' },
  { id: '342505 Operador de torre de controle (aéreo)', descricao: 'Operador de torre de controle (aéreo)' },
  { id: '517220 Operador de tráfego', descricao: 'Operador de tráfego' },
  { id: '811510 Operador de transferência e estocagem - na refinação do petróleo', descricao: 'Operador de transferência e estocagem - na refinação do petróleo' },
  { id: '342110 Operador de transporte multimodal', descricao: 'Operador de transporte multimodal' },
  { id: '862305 Operador de tratamento de água e esgoto', descricao: 'Operador de tratamento de água e esgoto' },
  { id: '841320 Operador de tratamento de calda na refinação de açúcar', descricao: 'Operador de tratamento de calda na refinação de açúcar' },
  { id: '862305 Operador de tratamento de esgoto', descricao: 'Operador de tratamento de esgoto' },
  { id: '811635 Operador de tratamento de gás', descricao: 'Operador de tratamento de gás' },
  { id: '811615 Operador de tratamento de gás (coque)', descricao: 'Operador de tratamento de gás (coque)' },
  { id: '811215 Operador de tratamento químico de materiais radioativos', descricao: 'Operador de tratamento químico de materiais radioativos' },
  { id: '811615 Operador de tratamento residual', descricao: 'Operador de tratamento residual' },
  { id: '723125 Operador de tratamento térmico', descricao: 'Operador de tratamento térmico' },
  { id: '723125 Operador de tratamento termoelétrico', descricao: 'Operador de tratamento termoelétrico' },
  { id: '711245 Operador de trator (minas e pedreiras)', descricao: 'Operador de trator (minas e pedreiras)' },
  { id: '715145 Operador de trator - exclusive na agropecuária', descricao: 'Operador de trator - exclusive na agropecuária' },
  { id: '715130 Operador de trator de esteira', descricao: 'Operador de trator de esteira' },
  { id: '715145 Operador de trator de lâmina', descricao: 'Operador de trator de lâmina' },
  { id: '642015 Operador de trator florestal', descricao: 'Operador de trator florestal' },
  { id: '715125 Operador de tratores diversos', descricao: 'Operador de tratores diversos' },
  { id: '722415 Operador de trefila', descricao: 'Operador de trefila' },
  { id: '811775 Operador de trefila (borracha)', descricao: 'Operador de trefila (borracha)' },
  { id: '782605 Operador de trem de metrô', descricao: 'Operador de trem de metrô' },
  { id: '782615 Operador de trem metropolitano', descricao: 'Operador de trem metropolitano' },
  { id: '415210 Operador de triagem e transbordo', descricao: 'Operador de triagem e transbordo' },
  { id: '632125 Operador de triturador de toras', descricao: 'Operador de triturador de toras' },
  { id: '773350 Operador de tupia (usinagem de madeira)', descricao: 'Operador de tupia (usinagem de madeira)' },
  { id: '841305 Operador de turbina a vapor na fabricação e refino de açúcar', descricao: 'Operador de turbina a vapor na fabricação e refino de açúcar' },
  { id: '841310 Operador de turbina a vapor na refinação de açúcar', descricao: 'Operador de turbina a vapor na refinação de açúcar' },
  { id: '861105 Operador de turbinas de central elétrica', descricao: 'Operador de turbinas de central elétrica' },
  { id: '354810 Operador de turismo', descricao: 'Operador de turismo' },
  { id: '781310 Operador de ua (unmanned aircraft)', descricao: 'Operador de ua (unmanned aircraft)' },
  { id: '842110 Operador de umidificação', descricao: 'Operador de umidificação' },
  { id: '373145 Operador de unidade portátil externa', descricao: 'Operador de unidade portátil externa' },
  { id: '761357 Operador de urdideira', descricao: 'Operador de urdideira' },
  { id: '768110 Operador de urdideira de tapetes', descricao: 'Operador de urdideira de tapetes' },
  { id: '715420 Operador de usina de asfalto', descricao: 'Operador de usina de asfalto' },
  { id: '861105 Operador de usina elétrica', descricao: 'Operador de usina elétrica' },
  { id: '861105 Operador de usina hidrelétrica', descricao: 'Operador de usina hidrelétrica' },
  { id: '861205 Operador de usina hidroelétrica', descricao: 'Operador de usina hidroelétrica' },
  { id: '861205 Operador de usina nuclear', descricao: 'Operador de usina nuclear' },
  { id: '861115 Operador de usina térmica', descricao: 'Operador de usina térmica' },
  { id: '861205 Operador de usina termoelétrica', descricao: 'Operador de usina termoelétrica' },
  { id: '861115 Operador de usina termogás', descricao: 'Operador de usina termogás' },
  { id: '861120 Operador de usina termonuclear', descricao: 'Operador de usina termonuclear' },
  { id: '721220 Operador de usinagem convencional por abrasão', descricao: 'Operador de usinagem convencional por abrasão' },
  { id: '862155 Operador de utilidade (produção e distribuição de vapor, gás, óleo, combustível, energia, oxigênio)', descricao: 'Operador de utilidade (produção e distribuição de vapor, gás, óleo, combustível, energia, oxigênio)' },
  { id: '811505 Operador de utilidades (petróleo)', descricao: 'Operador de utilidades (petróleo)' },
  { id: '862305 Operador de utilidades no tratamento de água e efluentes', descricao: 'Operador de utilidades no tratamento de água e efluentes' },
  { id: '722230 Operador de vazamento (lingotamento)', descricao: 'Operador de vazamento (lingotamento)' },
  { id: '781310 Operador de veículos aéreos não tripulados (vant)', descricao: 'Operador de veículos aéreos não tripulados (vant)' },
  { id: '991115 Operador de veículos especiais na conservação de vias permanentes (trilhos)', descricao: 'Operador de veículos especiais na conservação de vias permanentes (trilhos)' },
  { id: '781305 Operador de veículos subaquáticos controlados remotamente', descricao: 'Operador de veículos subaquáticos controlados remotamente' },
  { id: '521110 Operador de vendas (lojas)', descricao: 'Operador de vendas (lojas)' },
  { id: '717025 Operador de vibrador de concreto', descricao: 'Operador de vibrador de concreto' },
  { id: '373105 Operador de vídeo', descricao: 'Operador de vídeo' },
  { id: '373130 Operador de videoteipe', descricao: 'Operador de videoteipe' },
  { id: '724510 Operador de viradeira (aço)', descricao: 'Operador de viradeira (aço)' },
  { id: '724510 Operador de viradeira de chapas', descricao: 'Operador de viradeira de chapas' },
  { id: '724510 Operador de viradeira de tubos', descricao: 'Operador de viradeira de tubos' },
  { id: '724510 Operador de viradeiras de fitas de aço', descricao: 'Operador de viradeiras de fitas de aço' },
  { id: '711230 Operador de water jet', descricao: 'Operador de water jet' },
  { id: '723235 Operador de zincagem', descricao: 'Operador de zincagem' },
  { id: '723235 Operador de zincagem (processo eletrolítico)', descricao: 'Operador de zincagem (processo eletrolítico)' },
  { id: '377215 Operador dos vinte e quatro segundos (basquete)', descricao: 'Operador dos vinte e quatro segundos (basquete)' },
  { id: '954125 Operador eletromecânico', descricao: 'Operador eletromecânico' },
  { id: '813115 Operador especializado de extrusora', descricao: 'Operador especializado de extrusora' },
  { id: '253305 Operador financeiro', descricao: 'Operador financeiro' },
  { id: '766310 Operador gráfico de corte e vinco na impressão', descricao: 'Operador gráfico de corte e vinco na impressão' },
  { id: '813110 Operador industrial (química, petroquímica e afins)', descricao: 'Operador industrial (química, petroquímica e afins)' },
  { id: '811505 Operador industrial especializado (petróleo)', descricao: 'Operador industrial especializado (petróleo)' },
  { id: '811010 Operador industrial nas instalações químicas, petroquímicas e afins', descricao: 'Operador industrial nas instalações químicas, petroquímicas e afins' },
  { id: '422210 Operador internacional (telefonia)', descricao: 'Operador internacional (telefonia)' },
  { id: '842135 Operador junior (cigarros)', descricao: 'Operador junior (cigarros)' },
  { id: '842135 Operador malto (cigarros)', descricao: 'Operador malto (cigarros)' },
  { id: '862405 Operador mantanedor de compressores de gases', descricao: 'Operador mantanedor de compressores de gases' },
  { id: '842135 Operador mecânico (cigarros)', descricao: 'Operador mecânico (cigarros)' },
  { id: '862405 Operador mecânico de gás', descricao: 'Operador mecânico de gás' },
  { id: '761205 Operador misturador de fibras', descricao: 'Operador misturador de fibras' },
  { id: '723125 Operador multifuncional de tratamento térmico', descricao: 'Operador multifuncional de tratamento térmico' },
  { id: '761250 Operador passadista (fiação)', descricao: 'Operador passadista (fiação)' },
  { id: '761250 Operador passadorista (fiação)', descricao: 'Operador passadorista (fiação)' },
  { id: '715130 Operador patroleiro', descricao: 'Operador patroleiro' },
  { id: '761005 Operador polivalente da indústria têxtil', descricao: 'Operador polivalente da indústria têxtil' },
  { id: '374310 Operador-mantenedor de projetor cinematográfico', descricao: 'Operador-mantenedor de projetor cinematográfico' },
  { id: '7612 OPERADORES DA FIAÇÃO', descricao: 'OPERADORES DA FIAÇÃO' },
  { id: '8112 OPERADORES DE CALCINAÇÃO E DE TRATAMENTOS QUÍMICOS DE MATERIAIS RADIOATIVOS', descricao: 'OPERADORES DE CALCINAÇÃO E DE TRATAMENTOS QUÍMICOS DE MATERIAIS RADIOATIVOS' },
  { id: '8214 OPERADORES DE EQUIPAMENTOS DE ACABAMENTO DE CHAPAS E METAIS', descricao: 'OPERADORES DE EQUIPAMENTOS DE ACABAMENTO DE CHAPAS E METAIS' },
  { id: '8116 OPERADORES DE EQUIPAMENTOS DE COQUEIFICAÇÃO', descricao: 'OPERADORES DE EQUIPAMENTOS DE COQUEIFICAÇÃO' },
  { id: '8114 OPERADORES DE EQUIPAMENTOS DE DESTILAÇÃO, EVAPORAÇÃO E REAÇÃO', descricao: 'OPERADORES DE EQUIPAMENTOS DE DESTILAÇÃO, EVAPORAÇÃO E REAÇÃO' },
  { id: '4121 OPERADORES DE EQUIPAMENTOS DE ENTRADA E TRANSMISSÃO DE DADOS', descricao: 'OPERADORES DE EQUIPAMENTOS DE ENTRADA E TRANSMISSÃO DE DADOS' },
  { id: '8232 OPERADORES DE EQUIPAMENTOS DE FABRICAÇÃO E BENEFICIAMENTO DE CRISTAIS, VIDROS, CERÂMICAS,', descricao: 'OPERADORES DE EQUIPAMENTOS DE FABRICAÇÃO E BENEFICIAMENTO DE CRISTAIS, VIDROS, CERÂMICAS,' },
  { id: '8113 OPERADORES DE EQUIPAMENTOS DE FILTRAGEM E SEPARAÇÃO', descricao: 'OPERADORES DE EQUIPAMENTOS DE FILTRAGEM E SEPARAÇÃO' },
  { id: '8213 OPERADORES DE EQUIPAMENTOS DE LAMINAÇÃO', descricao: 'OPERADORES DE EQUIPAMENTOS DE LAMINAÇÃO' },
  { id: '8111 OPERADORES DE EQUIPAMENTOS DE MOAGEM E MISTURA DE MATERIAIS (TRATAMENTOS QUÍMICOS E AFINS)', descricao: 'OPERADORES DE EQUIPAMENTOS DE MOAGEM E MISTURA DE MATERIAIS (TRATAMENTOS QUÍMICOS E AFINS)' },
  { id: '7822 OPERADORES DE EQUIPAMENTOS DE MOVIMENTAÇÃO DE CARGAS', descricao: 'OPERADORES DE EQUIPAMENTOS DE MOVIMENTAÇÃO DE CARGAS' },
  { id: '8115 OPERADORES DE EQUIPAMENTOS DE PRODUÇÃO E REFINO DE PETRÓLEO E GÁS', descricao: 'OPERADORES DE EQUIPAMENTOS DE PRODUÇÃO E REFINO DE PETRÓLEO E GÁS' },
  { id: '8418 OPERADORES DE EQUIPAMENTOS NA FABRICAÇÃO DE PÃES, MASSAS ALIMENTÍCIAS, DOCES, CHOCOLATES E', descricao: 'OPERADORES DE EQUIPAMENTOS NA FABRICAÇÃO DE PÃES, MASSAS ALIMENTÍCIAS, DOCES, CHOCOLATES E' },
  { id: '7813 OPERADORES DE EQUIPAMENTOS PILOTADOS REMOTAMENTE', descricao: 'OPERADORES DE EQUIPAMENTOS PILOTADOS REMOTAMENTE' },
  { id: '8212 OPERADORES DE FORNOS DE PRIMEIRA FUSÃO E ACIARIA', descricao: 'OPERADORES DE FORNOS DE PRIMEIRA FUSÃO E ACIARIA' },
  { id: '8623 OPERADORES DE INSTALAÇÕES DE CAPTAÇÃO E ESGOTOS', descricao: 'OPERADORES DE INSTALAÇÕES DE CAPTAÇÃO E ESGOTOS' },
  { id: '8622 OPERADORES DE INSTALAÇÕES DE CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA', descricao: 'OPERADORES DE INSTALAÇÕES DE CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA' },
  { id: '8612 OPERADORES DE INSTALAÇÕES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA', descricao: 'OPERADORES DE INSTALAÇÕES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA' },
  { id: '8624 OPERADORES DE INSTALAÇÕES DE EXTRAÇÃO, PROCESSAMENTO, ENVASAMENTO E DISTRIBUIÇÃO DE GASES', descricao: 'OPERADORES DE INSTALAÇÕES DE EXTRAÇÃO, PROCESSAMENTO, ENVASAMENTO E DISTRIBUIÇÃO DE GASES' },
  { id: '8611 OPERADORES DE INSTALAÇÕES DE GERAÇÃO E DISTRIBUIÇÃO DE ENERGIA ELÉTRICA, HIDRÁULICA, TÉRMICA', descricao: 'OPERADORES DE INSTALAÇÕES DE GERAÇÃO E DISTRIBUIÇÃO DE ENERGIA ELÉTRICA, HIDRÁULICA, TÉRMICA' },
  { id: '8625 OPERADORES DE INSTALAÇÕES DE REFRIGERAÇÃO E AR-CONDICIONADO', descricao: 'OPERADORES DE INSTALAÇÕES DE REFRIGERAÇÃO E AR-CONDICIONADO' },
  { id: '8211 OPERADORES DE INSTALAÇÕES DE SINTERIZAÇÃO', descricao: 'OPERADORES DE INSTALAÇÕES DE SINTERIZAÇÃO' },
  { id: '8233 OPERADORES DE INSTALAÇÕES E EQUIPAMENTOS DE FABRICAÇÃO DE MATERIAIS DE CONSTRUÇÃO', descricao: 'OPERADORES DE INSTALAÇÕES E EQUIPAMENTOS DE FABRICAÇÃO DE MATERIAIS DE CONSTRUÇÃO' },
  { id: '8117 OPERADORES DE INSTALAÇÕES E MÁQUINAS DE PRODUTOS PLÁSTICOS, DE BORRACHA E MOLDADORES DE', descricao: 'OPERADORES DE INSTALAÇÕES E MÁQUINAS DE PRODUTOS PLÁSTICOS, DE BORRACHA E MOLDADORES DE' },
  { id: '7734 OPERADORES DE MÁQUINA DE USINAR MADEIRA (PRODUÇÃO EM SÉRIE)', descricao: 'OPERADORES DE MÁQUINA DE USINAR MADEIRA (PRODUÇÃO EM SÉRIE)' },
  { id: '8621 OPERADORES DE MÁQUINAS A VAPOR E UTILIDADES', descricao: 'OPERADORES DE MÁQUINAS A VAPOR E UTILIDADES' },
  { id: '7732 OPERADORES DE MÁQUINAS DE AGLOMERAÇÃO E PRENSAGEM DE CHAPAS', descricao: 'OPERADORES DE MÁQUINAS DE AGLOMERAÇÃO E PRENSAGEM DE CHAPAS' },
  { id: '7245 OPERADORES DE MÁQUINAS DE CONFORMAÇÃO DE METAIS', descricao: 'OPERADORES DE MÁQUINAS DE CONFORMAÇÃO DE METAIS' },
  { id: '7642 OPERADORES DE MÁQUINAS DE COSTURAR E MONTAR CALÇADOS', descricao: 'OPERADORES DE MÁQUINAS DE COSTURAR E MONTAR CALÇADOS' },
  { id: '7731 OPERADORES DE MÁQUINAS DE DESDOBRAMENTO DA MADEIRA', descricao: 'OPERADORES DE MÁQUINAS DE DESDOBRAMENTO DA MADEIRA' },
  { id: '8321 OPERADORES DE MÁQUINAS DE FABRICAR PAPEL E PAPELÃO', descricao: 'OPERADORES DE MÁQUINAS DE FABRICAR PAPEL E PAPELÃO' },
  { id: '7214 OPERADORES DE MÁQUINAS DE USINAGEM CNC', descricao: 'OPERADORES DE MÁQUINAS DE USINAGEM CNC' },
  { id: '7735 OPERADORES DE MÁQUINAS DE USINAGEM DE MADEIRA CNC', descricao: 'OPERADORES DE MÁQUINAS DE USINAGEM DE MADEIRA CNC' },
  { id: '7821 OPERADORES DE MÁQUINAS E EQUIPAMENTOS DE ELEVAÇÃO', descricao: 'OPERADORES DE MÁQUINAS E EQUIPAMENTOS DE ELEVAÇÃO' },
  { id: '8118 OPERADORES DE MÁQUINAS E INSTALAÇÕES DE PRODUTOS FARMACÊUTICOS, COSMÉTICOS E AFINS', descricao: 'OPERADORES DE MÁQUINAS E INSTALAÇÕES DE PRODUTOS FARMACÊUTICOS, COSMÉTICOS E AFINS' },
  { id: '7653 OPERADORES DE MÁQUINAS NA CONFECÇÃO DE ARTEFATOS DE COURO', descricao: 'OPERADORES DE MÁQUINAS NA CONFECÇÃO DE ARTEFATOS DE COURO' },
  { id: '8331 OPERADORES DE MÁQUINAS NA FABRICAÇÃO DE PRODUTOS DE PAPEL E PAPELÃO', descricao: 'OPERADORES DE MÁQUINAS NA FABRICAÇÃO DE PRODUTOS DE PAPEL E PAPELÃO' },
  { id: '7633 OPERADORES DE MÁQUINAS PARA BORDADO E ACABAMENTO DE ROUPAS', descricao: 'OPERADORES DE MÁQUINAS PARA BORDADO E ACABAMENTO DE ROUPAS' },
  { id: '7632 OPERADORES DE MÁQUINAS PARA COSTURA DE PEÇAS DO VESTUÁRIO', descricao: 'OPERADORES DE MÁQUINAS PARA COSTURA DE PEÇAS DO VESTUÁRIO' },
  { id: '8131 OPERADORES DE PROCESSOS DAS INDÚSTRIAS DE TRANSFORMAÇÃO DE PRODUTOS QUÍMICOS,', descricao: 'OPERADORES DE PROCESSOS DAS INDÚSTRIAS DE TRANSFORMAÇÃO DE PRODUTOS QUÍMICOS,' },
  { id: '3722 OPERADORES DE REDE DE TELEPROCESSAMENTO E AFINS', descricao: 'OPERADORES DE REDE DE TELEPROCESSAMENTO E AFINS' },
  { id: '7613 OPERADORES DE TEAR E MÁQUINAS SIMILARES', descricao: 'OPERADORES DE TEAR E MÁQUINAS SIMILARES' },
  { id: '4222 OPERADORES DE TELEFONIA', descricao: 'OPERADORES DE TELEFONIA' },
  { id: '4223 OPERADORES DE TELEMARKETING E AFINS', descricao: 'OPERADORES DE TELEMARKETING E AFINS' },
  { id: '862205 Operadores de tratamento e bombeamento de água', descricao: 'Operadores de tratamento e bombeamento de água' },
  { id: '7733 OPERADORES DE USINAGEM CONVENCIONAL DE MADEIRA', descricao: 'OPERADORES DE USINAGEM CONVENCIONAL DE MADEIRA' },
  { id: '7826 OPERADORES DE VEÍCULOS SOBRE TRILHOS E CABOS AÉREOS', descricao: 'OPERADORES DE VEÍCULOS SOBRE TRILHOS E CABOS AÉREOS' },
  { id: '5211 OPERADORES DO COMÉRCIO EM LOJAS E MERCADOS', descricao: 'OPERADORES DO COMÉRCIO EM LOJAS E MERCADOS' },
  { id: '8231 OPERADORES NA PREPARAÇÃO DE MASSAS PARA ABRASIVO, VIDRO, CERÂMICA, PORCELANA E MATERIAIS DE', descricao: 'OPERADORES NA PREPARAÇÃO DE MASSAS PARA ABRASIVO, VIDRO, CERÂMICA, PORCELANA E MATERIAIS DE' },
  { id: '8110 OPERADORES POLIVALENTES DE EQUIPAMENTOS EM INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS E AFINS', descricao: 'OPERADORES POLIVALENTES DE EQUIPAMENTOS EM INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS E AFINS' },
  { id: '322305 Óptico contatólogo', descricao: 'Óptico contatólogo' },
  { id: '322305 Óptico oftálmico', descricao: 'Óptico oftálmico' },
  { id: '322305 Óptico optometrista', descricao: 'Óptico optometrista' },
  { id: '322305 Óptico protesista', descricao: 'Óptico protesista' },
  { id: '623115 Ordenhador - na pecuária', descricao: 'Ordenhador - na pecuária' },
  { id: '513410 Organizador de degustações e harmonizações de bebidas', descricao: 'Organizador de degustações e harmonizações de bebidas' },
  { id: '354820 Organizador de evento', descricao: 'Organizador de evento' },
  { id: '375130 Organizador profissional', descricao: 'Organizador profissional' },
  { id: '239410 Orientador de disciplina e área de estudo', descricao: 'Orientador de disciplina e área de estudo' },
  { id: '239410 Orientador de ensino', descricao: 'Orientador de ensino' },
  { id: '620105 Orientador de plantio', descricao: 'Orientador de plantio' },
  { id: '519925 Orientador de tráfego para estacionamento', descricao: 'Orientador de tráfego para estacionamento' },
  { id: '239410 Orientador educacional', descricao: 'Orientador educacional' },
  { id: '239410 Orientador escolar', descricao: 'Orientador escolar' },
  { id: '224105 Orientador fisiocorporal', descricao: 'Orientador fisiocorporal' },
  { id: '239410 Orientador profissional', descricao: 'Orientador profissional' },
  { id: '515305 Orientador sócio educativo', descricao: 'Orientador sócio educativo' },
  { id: '239410 Orientador vocacional e profissional', descricao: 'Orientador vocacional e profissional' },
  { id: '612105 Orizicultor', descricao: 'Orizicultor' },
  { id: '712215 Ornamentador', descricao: 'Ornamentador' },
  { id: '375120 Ornamentador de eventos', descricao: 'Ornamentador de eventos' },
  { id: '712215 Ornamentista de pedra', descricao: 'Ornamentista de pedra' },
  { id: '203010 Ornitólogo', descricao: 'Ornitólogo' },
  { id: '262610 Orquestrador', descricao: 'Orquestrador' },
  { id: '322505 Ortesista', descricao: 'Ortesista' },
  { id: '223240 Ortodontista', descricao: 'Ortodontista' },
  { id: '223240 Ortodontólogo', descricao: 'Ortodontólogo' },
  { id: '225270 Ortopedista', descricao: 'Ortopedista' },
  { id: '223240 Ortopedista maxilar', descricao: 'Ortopedista maxilar' },
  { id: '223910 Ortoptista', descricao: 'Ortoptista' },
  { id: '263105 Osho', descricao: 'Osho' },
  { id: '226110 Osteopata', descricao: 'Osteopata' },
  { id: '2261 OSTEOPATAS E QUIROPRAXISTAS', descricao: 'OSTEOPATAS E QUIROPRAXISTAS' },
  { id: '631320 Ostreicultor', descricao: 'Ostreicultor' },
  { id: '225275 Otorrino', descricao: 'Otorrino' },
  { id: '225275 Otorrinolaringologista', descricao: 'Otorrinolaringologista' },
  { id: '751125 Ourives', descricao: 'Ourives' },
  { id: '751125 Ourives de bijouteria', descricao: 'Ourives de bijouteria' },
  { id: '751125 Ourives montador', descricao: 'Ourives montador' },
  { id: '5199 OUTROS TRABALHADORES DOS SERVIÇOS', descricao: 'OUTROS TRABALHADORES DOS SERVIÇOS' },
  { id: '142340 Ouvidor', descricao: 'Ouvidor' },
  { id: '613210 Ovinocultor - empregador', descricao: 'Ovinocultor - empregador' },
  { id: '623210 Ovinocultor - exclusive conta própria', descricao: 'Ovinocultor - exclusive conta própria' },
  { id: '613210 Ovinocultor cabanheiro', descricao: 'Ovinocultor cabanheiro' },
  { id: '613210 Ovinocultor matrizeiro', descricao: 'Ovinocultor matrizeiro' },
  { id: '724310 Oxicortador', descricao: 'Oxicortador' },
  { id: '724310 Oxicortador a mão e a máquina', descricao: 'Oxicortador a mão e a máquina' },
  { id: '723240 Oxidador', descricao: 'Oxidador' },
  { id: '848305 Padeiro', descricao: 'Padeiro' },
  { id: '848310 Padeiro confeiteiro', descricao: 'Padeiro confeiteiro' },
  { id: '8483 PADEIROS, CONFEITEIROS E AFINS', descricao: 'PADEIROS, CONFEITEIROS E AFINS' },
  { id: '515110 Padioleiro-enfermeiro', descricao: 'Padioleiro-enfermeiro' },
  { id: '263105 Padre', descricao: 'Padre' },
  { id: '263105 Padrinho de umbanda', descricao: 'Padrinho de umbanda' },
  { id: '765010 Padronista de chapéus', descricao: 'Padronista de chapéus' },
  { id: '765225 Padronizador de velas de embarcações, toldos e barracas', descricao: 'Padronizador de velas de embarcações, toldos e barracas' },
  { id: '263105 Pagé', descricao: 'Pagé' },
  { id: '768620 Paginador', descricao: 'Paginador' },
  { id: '214120 Paisagista', descricao: 'Paisagista' },
  { id: '516205 Pajém (baby-sitter em início de carreira)', descricao: 'Pajém (baby-sitter em início de carreira)' },
  { id: '762335 Palecionador de couros e peles', descricao: 'Palecionador de couros e peles' },
  { id: '251105 Paleetnólogo', descricao: 'Paleetnólogo' },
  { id: '213430 Paleontólogo', descricao: 'Paleontólogo' },
  { id: '376245 Palhaço', descricao: 'Palhaço' },
  { id: '612720 Palmicultor', descricao: 'Palmicultor' },
  { id: '632420 Palmiteiro', descricao: 'Palmiteiro' },
  { id: '519905 Panfleteiro', descricao: 'Panfleteiro' },
  { id: '848305 Panificador', descricao: 'Panificador' },
  { id: '613130 Pantaneiro', descricao: 'Pantaneiro' },
  { id: '623125 Pantaneiro - exclusive conta própria e empregador', descricao: 'Pantaneiro - exclusive conta própria e empregador' },
  { id: '766145 Pantografista', descricao: 'Pantografista' },
  { id: '141410 Papeleiro (comércio varejista)', descricao: 'Papeleiro (comércio varejista)' },
  { id: '351815 Papiloscopista policial', descricao: 'Papiloscopista policial' },
  { id: '376230 Paradista', descricao: 'Paradista' },
  { id: '811335 Parafinador', descricao: 'Parafinador' },
  { id: '811335 Parafineiro', descricao: 'Parafineiro' },
  { id: '763005 Paramenteiro - alfaite', descricao: 'Paramenteiro - alfaite' },
  { id: '516810 Paranormal', descricao: 'Paranormal' },
  { id: '516810 Parapsicólogo', descricao: 'Parapsicólogo' },
  { id: '613305 Parceiro - na criação de aves - empregador', descricao: 'Parceiro - na criação de aves - empregador' },
  { id: '613110 Parceiro - na criação de gado bovino - empregador', descricao: 'Parceiro - na criação de gado bovino - empregador' },
  { id: '623420 Parceiro do bicho-da-seda', descricao: 'Parceiro do bicho-da-seda' },
  { id: '611005 Parceiro na agropecuária - conta própria', descricao: 'Parceiro na agropecuária - conta própria' },
  { id: '621005 Parceiro na agropecuária - exclusive conta própria e empregador', descricao: 'Parceiro na agropecuária - exclusive conta própria e empregador' },
  { id: '613310 Parceiro na criação de coelhos', descricao: 'Parceiro na criação de coelhos' },
  { id: '613005 Parceiro na pecuária', descricao: 'Parceiro na pecuária' },
  { id: '612005 Parceiro polivalente', descricao: 'Parceiro polivalente' },
  { id: '263105 Pároco', descricao: 'Pároco' },
  { id: '841315 Parozeiro', descricao: 'Parozeiro' },
  { id: '515115 Parteira', descricao: 'Parteira' },
  { id: '515115 Parteira leiga', descricao: 'Parteira leiga' },
  { id: '515115 Parteira prática', descricao: 'Parteira prática' },
  { id: '223575 Parteira profissional', descricao: 'Parteira profissional' },
  { id: '351410 Partidor judicial', descricao: 'Partidor judicial' },
  { id: '376215 Partner (circo)', descricao: 'Partner (circo)' },
  { id: '763325 Passadeira de peças confeccionadas', descricao: 'Passadeira de peças confeccionadas' },
  { id: '516415 Passador - no serviço do vestuário', descricao: 'Passador - no serviço do vestuário' },
  { id: '516415 Passador a ferro a vapor', descricao: 'Passador a ferro a vapor' },
  { id: '516415 Passador de amostras de roupas', descricao: 'Passador de amostras de roupas' },
  { id: '513405 Passador de guarnição', descricao: 'Passador de guarnição' },
  { id: '516415 Passador de roupas', descricao: 'Passador de roupas' },
  { id: '516325 Passador de roupas à prensa', descricao: 'Passador de roupas à prensa' },
  { id: '516325 Passador de roupas em calandra', descricao: 'Passador de roupas em calandra' },
  { id: '516325 Passador de roupas em geral', descricao: 'Passador de roupas em geral' },
  { id: '516325 Passador de roupas em manequim', descricao: 'Passador de roupas em manequim' },
  { id: '516415 Passador de roupas, à mão', descricao: 'Passador de roupas, à mão' },
  { id: '516415 Passador de tecidos', descricao: 'Passador de tecidos' },
  { id: '761360 Passamaneiro a máquina', descricao: 'Passamaneiro a máquina' },
  { id: '848315 Pasteleiro', descricao: 'Pasteleiro' },
  { id: '848205 Pasteurizador', descricao: 'Pasteurizador' },
  { id: '716515 Pastilheiro', descricao: 'Pastilheiro' },
  { id: '263110 Pastor', descricao: 'Pastor' },
  { id: '623210 Pastor - na pecuária', descricao: 'Pastor - na pecuária' },
  { id: '263105 Pastor evangélico', descricao: 'Pastor evangélico' },
  { id: '263110 Pastor evangelista', descricao: 'Pastor evangelista' },
  { id: '848315 Patisseiro', descricao: 'Patisseiro' },
  { id: '225325 Patologista', descricao: 'Patologista' },
  { id: '225335 Patologista clínico', descricao: 'Patologista clínico' },
  { id: '341220 Patrão de pesca de alto-mar', descricao: 'Patrão de pesca de alto-mar' },
  { id: '341225 Patrão de pesca na navegação interior', descricao: 'Patrão de pesca na navegação interior' },
  { id: '715130 Patroleiro', descricao: 'Patroleiro' },
  { id: '715130 Patroleiro de pavimentação', descricao: 'Patroleiro de pavimentação' },
  { id: '715130 Patroleiro de terraplanagem', descricao: 'Patroleiro de terraplanagem' },
  { id: '715140 Pavimentador', descricao: 'Pavimentador' },
  { id: '783230 Peador (trabalhador portuário)', descricao: 'Peador (trabalhador portuário)' },
  { id: '623105 Peão (asininos e muares)', descricao: 'Peão (asininos e muares)' },
  { id: '623120 Peão (bubalinos)', descricao: 'Peão (bubalinos)' },
  { id: '623125 Peão (eqüinos)', descricao: 'Peão (eqüinos)' },
  { id: '621005 Peão - na agropecuária', descricao: 'Peão - na agropecuária' },
  { id: '377105 Peão de boiadeiro', descricao: 'Peão de boiadeiro' },
  { id: '623015 Peão de cavalo', descricao: 'Peão de cavalo' },
  { id: '623015 Peão de estábulo', descricao: 'Peão de estábulo' },
  { id: '623110 Peão de pecuária', descricao: 'Peão de pecuária' },
  { id: '613110 Pecuário - na criação de gado bovino - empregador', descricao: 'Pecuário - na criação de gado bovino - empregador' },
  { id: '613005 Pecuarista', descricao: 'Pecuarista' },
  { id: '613105 Pecuarista - na criação de asininos', descricao: 'Pecuarista - na criação de asininos' },
  { id: '613205 Pecuarista - na criação de caprinos - empregador', descricao: 'Pecuarista - na criação de caprinos - empregador' },
  { id: '613130 Pecuarista - na criação de eqüinos - empregador', descricao: 'Pecuarista - na criação de eqüinos - empregador' },
  { id: '613110 Pecuarista - na criação de gado bovino - empregador', descricao: 'Pecuarista - na criação de gado bovino - empregador' },
  { id: '613105 Pecuarista - na criação de muares', descricao: 'Pecuarista - na criação de muares' },
  { id: '613210 Pecuarista - na criação de ovinos', descricao: 'Pecuarista - na criação de ovinos' },
  { id: '613215 Pecuarista - na criação de suínos empregador', descricao: 'Pecuarista - na criação de suínos empregador' },
  { id: '613115 Pecuarista de leite', descricao: 'Pecuarista de leite' },
  { id: '632405 Pecunheiro', descricao: 'Pecunheiro' },
  { id: '239415 Pedagogo', descricao: 'Pedagogo' },
  { id: '239225 Pedagogo em educação especial de cegos', descricao: 'Pedagogo em educação especial de cegos' },
  { id: '239205 Pedagogo em educação especial de surdos', descricao: 'Pedagogo em educação especial de surdos' },
  { id: '239210 Pedagogo especializado em deficiência física', descricao: 'Pedagogo especializado em deficiência física' },
  { id: '239215 Pedagogo especializado em deficiência mental', descricao: 'Pedagogo especializado em deficiência mental' },
  { id: '239205 Pedagogo especializado em deficientes da áudio-comunicação', descricao: 'Pedagogo especializado em deficientes da áudio-comunicação' },
  { id: '225124 Pediatra', descricao: 'Pediatra' },
  { id: '516140 Pedicure', descricao: 'Pedicure' },
  { id: '516140 Pedicuro', descricao: 'Pedicuro' },
  { id: '715210 Pedreiro', descricao: 'Pedreiro' },
  { id: '715215 Pedreiro (chaminés industriais)', descricao: 'Pedreiro (chaminés industriais)' },
  { id: '715220 Pedreiro (material refratário)', descricao: 'Pedreiro (material refratário)' },
  { id: '715225 Pedreiro (mineração)', descricao: 'Pedreiro (mineração)' },
  { id: '715210 Pedreiro de acabamento', descricao: 'Pedreiro de acabamento' },
  { id: '715230 Pedreiro de alvenaria', descricao: 'Pedreiro de alvenaria' },
  { id: '715215 Pedreiro de chaminés', descricao: 'Pedreiro de chaminés' },
  { id: '715210 Pedreiro de concreto', descricao: 'Pedreiro de concreto' },
  { id: '992220 Pedreiro de conservação de vias permanentes (exceto trilhos)', descricao: 'Pedreiro de conservação de vias permanentes (exceto trilhos)' },
  { id: '715230 Pedreiro de edificações', descricao: 'Pedreiro de edificações' },
  { id: '715210 Pedreiro de fachada', descricao: 'Pedreiro de fachada' },
  { id: '715220 Pedreiro de forno', descricao: 'Pedreiro de forno' },
  { id: '715210 Pedreiro de manutenção e conservação', descricao: 'Pedreiro de manutenção e conservação' },
  { id: '715225 Pedreiro de mineração', descricao: 'Pedreiro de mineração' },
  { id: '715210 Pedreiro de reforma geral', descricao: 'Pedreiro de reforma geral' },
  { id: '621005 Pegador de animais - na agropecuária', descricao: 'Pegador de animais - na agropecuária' },
  { id: '519920 Pegador de bola', descricao: 'Pegador de bola' },
  { id: '263105 Pegigan', descricao: 'Pegigan' },
  { id: '141410 Peixeiro (comércio varejista)', descricao: 'Peixeiro (comércio varejista)' },
  { id: '141410 Peleteiro (comércio varejista)', descricao: 'Peleteiro (comércio varejista)' },
  { id: '241025 Penalista', descricao: 'Penalista' },
  { id: '621005 Peneirador - na agropecuária', descricao: 'Peneirador - na agropecuária' },
  { id: '632315 Peneirador de vinho de bacaba', descricao: 'Peneirador de vinho de bacaba' },
  { id: '722325 Peneireiro - na fundição', descricao: 'Peneireiro - na fundição' },
  { id: '613005 Pequeno produtor rural', descricao: 'Pequeno produtor rural' },
  { id: '632360 Pequizeiro', descricao: 'Pequizeiro' },
  { id: '325015 Perfumista', descricao: 'Perfumista' },
  { id: '761366 Perfurador de cartões jacquard', descricao: 'Perfurador de cartões jacquard' },
  { id: '711320 Perfurador de poços artesianos', descricao: 'Perfurador de poços artesianos' },
  { id: '223570 Perfusionista', descricao: 'Perfusionista' },
  { id: '223248 Periodontista', descricao: 'Periodontista' },
  { id: '252215 Perito assistente (contador)', descricao: 'Perito assistente (contador)' },
  { id: '354415 Perito avaliador de bens móveis', descricao: 'Perito avaliador de bens móveis' },
  { id: '252215 Perito contábil', descricao: 'Perito contábil' },
  { id: '252215 Perito contador', descricao: 'Perito contador' },
  { id: '204105 Perito criminal', descricao: 'Perito criminal' },
  { id: '204105 Perito criminal federal', descricao: 'Perito criminal federal' },
  { id: '204105 Perito criminalístico', descricao: 'Perito criminalístico' },
  { id: '204105 Perito criminalístico engenheiro', descricao: 'Perito criminalístico engenheiro' },
  { id: '204105 Perito criminalístico químico', descricao: 'Perito criminalístico químico' },
  { id: '252215 Perito de balanço', descricao: 'Perito de balanço' },
  { id: '251205 Perito econômico', descricao: 'Perito econômico' },
  { id: '351815 Perito em identificação', descricao: 'Perito em identificação' },
  { id: '204110 Perito forense de formação específica', descricao: 'Perito forense de formação específica' },
  { id: '204110 Perito judicial', descricao: 'Perito judicial' },
  { id: '204110 Perito judicial acidentólogo, de trânsito e transporte', descricao: 'Perito judicial acidentólogo, de trânsito e transporte' },
  { id: '204110 Perito judicial áudio e imagem', descricao: 'Perito judicial áudio e imagem' },
  { id: '204110 Perito judicial avaliador de bens móveis', descricao: 'Perito judicial avaliador de bens móveis' },
  { id: '204110 Perito judicial contábil', descricao: 'Perito judicial contábil' },
  { id: '204110 Perito judicial de formação específica', descricao: 'Perito judicial de formação específica' },
  { id: '204110 Perito judicial de incêndio automotivo e explosões', descricao: 'Perito judicial de incêndio automotivo e explosões' },
  { id: '204110 Perito judicial de incêndio de edificações', descricao: 'Perito judicial de incêndio de edificações' },
  { id: '204110 Perito judicial em engenharia', descricao: 'Perito judicial em engenharia' },
  { id: '204110 Perito judicial grafotécnico documentoscópico', descricao: 'Perito judicial grafotécnico documentoscópico' },
  { id: '204110 Perito judicial técnico industrial', descricao: 'Perito judicial técnico industrial' },
  { id: '252215 Perito liquidador (contador)', descricao: 'Perito liquidador (contador)' },
  { id: '204105 Perito oficial', descricao: 'Perito oficial' },
  { id: '351815 Perito papiloscopista', descricao: 'Perito papiloscopista' },
  { id: '2041 PERITOS CRIMINAIS E JUDICIAIS', descricao: 'PERITOS CRIMINAIS E JUDICIAIS' },
  { id: '375125 Personal stylist', descricao: 'Personal stylist' },
  { id: '224120 Personal treanning', descricao: 'Personal treanning' },
  { id: '414115 Pesador', descricao: 'Pesador' },
  { id: '631015 Pescador artesanal de lagostas com covos', descricao: 'Pescador artesanal de lagostas com covos' },
  { id: '631105 Pescador artesanal de água doce', descricao: 'Pescador artesanal de água doce' },
  { id: '631020 Pescador artesanal de camarões', descricao: 'Pescador artesanal de camarões' },
  { id: '631015 Pescador artesanal de lagostas', descricao: 'Pescador artesanal de lagostas' },
  { id: '631015 Pescador artesanal de lagostas com gaiolas', descricao: 'Pescador artesanal de lagostas com gaiolas' },
  { id: '631020 Pescador artesanal de peixes', descricao: 'Pescador artesanal de peixes' },
  { id: '631020 Pescador artesanal de peixes e camaões com rede de calão', descricao: 'Pescador artesanal de peixes e camaões com rede de calão' },
  { id: '631020 Pescador artesanal de peixes e camarões', descricao: 'Pescador artesanal de peixes e camarões' },
  { id: '631020 Pescador artesanal de peixes e camarões com covos', descricao: 'Pescador artesanal de peixes e camarões com covos' },
  { id: '631020 Pescador artesanal de peixes e camarões com espinhel', descricao: 'Pescador artesanal de peixes e camarões com espinhel' },
  { id: '631020 Pescador artesanal de peixes e camarões com redes e linhas', descricao: 'Pescador artesanal de peixes e camarões com redes e linhas' },
  { id: '631020 Pescador artesanal de peixes e camarões em currais', descricao: 'Pescador artesanal de peixes e camarões em currais' },
  { id: '631105 Pescador de anzol', descricao: 'Pescador de anzol' },
  { id: '631020 Pescador de espinhel (embarcações de pesca)', descricao: 'Pescador de espinhel (embarcações de pesca)' },
  { id: '631015 Pescador de lagostas', descricao: 'Pescador de lagostas' },
  { id: '631020 Pescador de linhas (embarcações de pesca)', descricao: 'Pescador de linhas (embarcações de pesca)' },
  { id: '631020 Pescador de peixes e camarões com redes', descricao: 'Pescador de peixes e camarões com redes' },
  { id: '631020 Pescador de peixes e camarões em embarcações de pequeno porte', descricao: 'Pescador de peixes e camarões em embarcações de pequeno porte' },
  { id: '631020 Pescador de tarrafa (peixes e camarões)', descricao: 'Pescador de tarrafa (peixes e camarões)' },
  { id: '631205 Pescador industrial', descricao: 'Pescador industrial' },
  { id: '631015 Pescador lagosteiro', descricao: 'Pescador lagosteiro' },
  { id: '631210 Pescador profissional', descricao: 'Pescador profissional' },
  { id: '6312 PESCADORES DE ÁGUA COSTEIRA E ALTO MAR', descricao: 'PESCADORES DE ÁGUA COSTEIRA E ALTO MAR' },
  { id: '6310 PESCADORES POLIVALENTES', descricao: 'PESCADORES POLIVALENTES' },
  { id: '6311 PESCADORES PROFISSIONAIS ARTESANAIS DE ÁGUA DOCE', descricao: 'PESCADORES PROFISSIONAIS ARTESANAIS DE ÁGUA DOCE' },
  { id: '848520 Pescoceiro em matadouro', descricao: 'Pescoceiro em matadouro' },
  { id: '203025 Pesquisador botânico', descricao: 'Pesquisador botânico' },
  { id: '203405 Pesquisador das ciências agrárias', descricao: 'Pesquisador das ciências agrárias' },
  { id: '203305 Pesquisador de clínica médica', descricao: 'Pesquisador de clínica médica' },
  { id: '203205 Pesquisador de engenharia civil', descricao: 'Pesquisador de engenharia civil' },
  { id: '203210 Pesquisador de engenharia e tecnologia (outras áreas da engenharia)', descricao: 'Pesquisador de engenharia e tecnologia (outras áreas da engenharia)' },
  { id: '203215 Pesquisador de engenharia elétrica e eletrônica', descricao: 'Pesquisador de engenharia elétrica e eletrônica' },
  { id: '203220 Pesquisador de engenharia mecânica', descricao: 'Pesquisador de engenharia mecânica' },
  { id: '203225 Pesquisador de engenharia metalúrgica, de minas e de materiais', descricao: 'Pesquisador de engenharia metalúrgica, de minas e de materiais' },
  { id: '203230 Pesquisador de engenharia química', descricao: 'Pesquisador de engenharia química' },
  { id: '261215 Pesquisador de informações de rede', descricao: 'Pesquisador de informações de rede' },
  { id: '203310 Pesquisador de medicina básica', descricao: 'Pesquisador de medicina básica' },
  { id: '424120 Pesquisador de preços', descricao: 'Pesquisador de preços' },
  { id: '251205 Pesquisador econômico', descricao: 'Pesquisador econômico' },
  { id: '203005 Pesquisador em biologia ambiental', descricao: 'Pesquisador em biologia ambiental' },
  { id: '203010 Pesquisador em biologia animal', descricao: 'Pesquisador em biologia animal' },
  { id: '203015 Pesquisador em biologia de microorganismos e parasitas', descricao: 'Pesquisador em biologia de microorganismos e parasitas' },
  { id: '203020 Pesquisador em biologia humana', descricao: 'Pesquisador em biologia humana' },
  { id: '203025 Pesquisador em biologia vegetal', descricao: 'Pesquisador em biologia vegetal' },
  { id: '203405 Pesquisador em ciências agronômicas', descricao: 'Pesquisador em ciências agronômicas' },
  { id: '203105 Pesquisador em ciências da computação e informática', descricao: 'Pesquisador em ciências da computação e informática' },
  { id: '203515 Pesquisador em ciências da educação', descricao: 'Pesquisador em ciências da educação' },
  { id: '203410 Pesquisador em ciências da pesca e aqüicultura', descricao: 'Pesquisador em ciências da pesca e aqüicultura' },
  { id: '203110 Pesquisador em ciências da terra e meio ambiente', descricao: 'Pesquisador em ciências da terra e meio ambiente' },
  { id: '203415 Pesquisador em ciências da zootecnia', descricao: 'Pesquisador em ciências da zootecnia' },
  { id: '203420 Pesquisador em ciências florestais', descricao: 'Pesquisador em ciências florestais' },
  { id: '203505 Pesquisador em ciências sociais e humanas', descricao: 'Pesquisador em ciências sociais e humanas' },
  { id: '203510 Pesquisador em economia', descricao: 'Pesquisador em economia' },
  { id: '203115 Pesquisador em física', descricao: 'Pesquisador em física' },
  { id: '203520 Pesquisador em história', descricao: 'Pesquisador em história' },
  { id: '203120 Pesquisador em matemática', descricao: 'Pesquisador em matemática' },
  { id: '203315 Pesquisador em medicina veterinária', descricao: 'Pesquisador em medicina veterinária' },
  { id: '201205 Pesquisador em metrologia', descricao: 'Pesquisador em metrologia' },
  { id: '262620 Pesquisador em música', descricao: 'Pesquisador em música' },
  { id: '203525 Pesquisador em psicologia', descricao: 'Pesquisador em psicologia' },
  { id: '203125 Pesquisador em química', descricao: 'Pesquisador em química' },
  { id: '203320 Pesquisador em saúde coletiva', descricao: 'Pesquisador em saúde coletiva' },
  { id: '2030 PESQUISADORES DAS CIÊNCIAS BIOLÓGICAS', descricao: 'PESQUISADORES DAS CIÊNCIAS BIOLÓGICAS' },
  { id: '2034 PESQUISADORES DAS CIÊNCIAS DA AGRICULTURA', descricao: 'PESQUISADORES DAS CIÊNCIAS DA AGRICULTURA' },
  { id: '2033 PESQUISADORES DAS CIÊNCIAS DA SAÚDE', descricao: 'PESQUISADORES DAS CIÊNCIAS DA SAÚDE' },
  { id: '2031 PESQUISADORES DAS CIÊNCIAS NATURAIS E EXATAS', descricao: 'PESQUISADORES DAS CIÊNCIAS NATURAIS E EXATAS' },
  { id: '2035 PESQUISADORES DAS CIÊNCIAS SOCIAIS E HUMANAS', descricao: 'PESQUISADORES DAS CIÊNCIAS SOCIAIS E HUMANAS' },
  { id: '2032 PESQUISADORES DE ENGENHARIA E TECNOLOGIA', descricao: 'PESQUISADORES DE ENGENHARIA E TECNOLOGIA' },
  { id: '612505 Pessicultor', descricao: 'Pessicultor' },
  { id: '716605 Petintal (calafetação)', descricao: 'Petintal (calafetação)' },
  { id: '213435 Petrógrafo', descricao: 'Petrógrafo' },
  { id: '632365 Piaçaveiro', descricao: 'Piaçaveiro' },
  { id: '848525 Picador de carne em matadouro', descricao: 'Picador de carne em matadouro' },
  { id: '848510 Picador em açougue', descricao: 'Picador em açougue' },
  { id: '724435 Picheleiro', descricao: 'Picheleiro' },
  { id: '761366 Picotador de cartões jacquard', descricao: 'Picotador de cartões jacquard' },
  { id: '821440 Picotador de flandres', descricao: 'Picotador de flandres' },
  { id: '511510 Piloteiro de turismo de pesca', descricao: 'Piloteiro de turismo de pesca' },
  { id: '341120 Piloto agrícola', descricao: 'Piloto agrícola' },
  { id: '341105 Piloto comercial', descricao: 'Piloto comercial' },
  { id: '341105 Piloto comercial (exceto linhas aéreas)', descricao: 'Piloto comercial (exceto linhas aéreas)' },
  { id: '341110 Piloto comercial de helicóptero (exceto linhas aéreas)', descricao: 'Piloto comercial de helicóptero (exceto linhas aéreas)' },
  { id: '215305 Piloto de aeronaves', descricao: 'Piloto de aeronaves' },
  { id: '781310 Piloto de aeronaves não tripuladas', descricao: 'Piloto de aeronaves não tripuladas' },
  { id: '781310 Piloto de aeronaves pilotadas remotamente', descricao: 'Piloto de aeronaves pilotadas remotamente' },
  { id: '215305 Piloto de avião', descricao: 'Piloto de avião' },
  { id: '377135 Piloto de competição automobilística', descricao: 'Piloto de competição automobilística' },
  { id: '781310 Piloto de drone', descricao: 'Piloto de drone' },
  { id: '215310 Piloto de ensaios em vôo', descricao: 'Piloto de ensaios em vôo' },
  { id: '215305 Piloto de helicóptero', descricao: 'Piloto de helicóptero' },
  { id: '215305 Piloto de linha aérea regular', descricao: 'Piloto de linha aérea regular' },
  { id: '215310 Piloto de provas', descricao: 'Piloto de provas' },
  { id: '781305 Piloto de rov', descricao: 'Piloto de rov' },
  { id: '781310 Piloto de sistema de aeronaves remotamente pilotadas (rpas)', descricao: 'Piloto de sistema de aeronaves remotamente pilotadas (rpas)' },
  { id: '782605 Piloto de trem de metrô', descricao: 'Piloto de trem de metrô' },
  { id: '781310 Piloto de ua (unmanned aircraft)', descricao: 'Piloto de ua (unmanned aircraft)' },
  { id: '781310 Piloto de veículos aéreos não tripulados (vant)', descricao: 'Piloto de veículos aéreos não tripulados (vant)' },
  { id: '341230 Piloto fluvial', descricao: 'Piloto fluvial' },
  { id: '3411 PILOTOS DE AVIAÇÃO COMERCIAL, MECÂNICOS DE VÔO E AFINS', descricao: 'PILOTOS DE AVIAÇÃO COMERCIAL, MECÂNICOS DE VÔO E AFINS' },
  { id: '761815 Pinçador (produção têxtil)', descricao: 'Pinçador (produção têxtil)' },
  { id: '262405 Pintor (artes visuais)', descricao: 'Pintor (artes visuais)' },
  { id: '723310 Pintor (retoques)', descricao: 'Pintor (retoques)' },
  { id: '723310 Pintor a pincel e rolo (exceto obras e estruturas metálicas)', descricao: 'Pintor a pincel e rolo (exceto obras e estruturas metálicas)' },
  { id: '723330 Pintor a revólver', descricao: 'Pintor a revólver' },
  { id: '723220 Pintor a revólver (pintura eletrostática)', descricao: 'Pintor a revólver (pintura eletrostática)' },
  { id: '716610 Pintor de alvenaria', descricao: 'Pintor de alvenaria' },
  { id: '723320 Pintor de automóveis', descricao: 'Pintor de automóveis' },
  { id: '723320 Pintor de aviões', descricao: 'Pintor de aviões' },
  { id: '723330 Pintor de brinquedos', descricao: 'Pintor de brinquedos' },
  { id: '723320 Pintor de carros', descricao: 'Pintor de carros' },
  { id: '716610 Pintor de casas', descricao: 'Pintor de casas' },
  { id: '752420 Pintor de cerâmica a pistola', descricao: 'Pintor de cerâmica a pistola' },
  { id: '752430 Pintor de cerâmica manual', descricao: 'Pintor de cerâmica manual' },
  { id: '752430 Pintor de cerâmica, a pincel', descricao: 'Pintor de cerâmica, a pincel' },
  { id: '716610 Pintor de construções cênicas', descricao: 'Pintor de construções cênicas' },
  { id: '311725 Pintor de couros e peles à mão', descricao: 'Pintor de couros e peles à mão' },
  { id: '311725 Pintor de couros e peles à pistola', descricao: 'Pintor de couros e peles à pistola' },
  { id: '716610 Pintor de decoração de fundo (cinema e teatro)', descricao: 'Pintor de decoração de fundo (cinema e teatro)' },
  { id: '716610 Pintor de edifícios', descricao: 'Pintor de edifícios' },
  { id: '723315 Pintor de estruturas metálicas', descricao: 'Pintor de estruturas metálicas' },
  { id: '723330 Pintor de geladeira', descricao: 'Pintor de geladeira' },
  { id: '768625 Pintor de letreiros', descricao: 'Pintor de letreiros' },
  { id: '723330 Pintor de máquinas industriais', descricao: 'Pintor de máquinas industriais' },
  { id: '723315 Pintor de metais a pistola', descricao: 'Pintor de metais a pistola' },
  { id: '723320 Pintor de motos', descricao: 'Pintor de motos' },
  { id: '723330 Pintor de móveis - a pistola', descricao: 'Pintor de móveis - a pistola' },
  { id: '716610 Pintor de obras', descricao: 'Pintor de obras' },
  { id: '716610 Pintor de paredes', descricao: 'Pintor de paredes' },
  { id: '723320 Pintor de vagões tanques', descricao: 'Pintor de vagões tanques' },
  { id: '723320 Pintor de veículos (fabricação)', descricao: 'Pintor de veículos (fabricação)' },
  { id: '991315 Pintor de veículos (reparação)', descricao: 'Pintor de veículos (reparação)' },
  { id: '723325 Pintor especializado em pintura acrílica', descricao: 'Pintor especializado em pintura acrílica' },
  { id: '723320 Pintor especializado em pintura acrílica (veículos)', descricao: 'Pintor especializado em pintura acrílica (veículos)' },
  { id: '723315 Pintor industrial', descricao: 'Pintor industrial' },
  { id: '723325 Pintor por imersão', descricao: 'Pintor por imersão' },
  { id: '723330 Pintor por pulverização (exceto veículos)', descricao: 'Pintor por pulverização (exceto veículos)' },
  { id: '723330 Pintor, a pistola (exceto obras e estruturas metálicas)', descricao: 'Pintor, a pistola (exceto obras e estruturas metálicas)' },
  { id: '7166 PINTORES DE OBRAS E REVESTIDORES DE INTERIORES (REVESTIMENTOS FLEXÍVEIS)', descricao: 'PINTORES DE OBRAS E REVESTIDORES DE INTERIORES (REVESTIMENTOS FLEXÍVEIS)' },
  { id: '524310 Pipoqueiro ambulante', descricao: 'Pipoqueiro ambulante' },
  { id: '631105 Pirangueiro', descricao: 'Pirangueiro' },
  { id: '511510 Pirangueiro de turismo de pesca', descricao: 'Pirangueiro de turismo de pesca' },
  { id: '376215 Pirofagista', descricao: 'Pirofagista' },
  { id: '812105 Pirotécnico', descricao: 'Pirotécnico' },
  { id: '631325 Piscicultor', descricao: 'Piscicultor' },
  { id: '514330 Piscineiro', descricao: 'Piscineiro' },
  { id: '716525 Piseiro (mármore e granito)', descricao: 'Piseiro (mármore e granito)' },
  { id: '752420 Pistoleiro (cerâmica)', descricao: 'Pistoleiro (cerâmica)' },
  { id: '513610 Pizzaiolo', descricao: 'Pizzaiolo' },
  { id: '721215 Plainador de metais (plaina limadora)', descricao: 'Plainador de metais (plaina limadora)' },
  { id: '764305 Planchador de calçados', descricao: 'Planchador de calçados' },
  { id: '214910 Planejador de controle de qualidade', descricao: 'Planejador de controle de qualidade' },
  { id: '262905 Planejador de interiores', descricao: 'Planejador de interiores' },
  { id: '314205 Planejador de processos (fabricação de ferramentas)', descricao: 'Planejador de processos (fabricação de ferramentas)' },
  { id: '314210 Planejador de processos (manutenção de ferramentas)', descricao: 'Planejador de processos (manutenção de ferramentas)' },
  { id: '391125 Planejador de produção (técnico)', descricao: 'Planejador de produção (técnico)' },
  { id: '122520 Planejador de turismo (turismólogo)', descricao: 'Planejador de turismo (turismólogo)' },
  { id: '214125 Planejador urbano', descricao: 'Planejador urbano' },
  { id: '3911 PLANEJADORES, PROGRAMADORES E CONTROLADORES DE PRODUÇÃO E MANUTENÇÃO', descricao: 'PLANEJADORES, PROGRAMADORES E CONTROLADORES DE PRODUÇÃO E MANUTENÇÃO' },
  { id: '391120 Planejista', descricao: 'Planejista' },
  { id: '622020 Plantador - exclusive conta própria e empregador', descricao: 'Plantador - exclusive conta própria e empregador' },
  { id: '622615 Plantador da matecultura', descricao: 'Plantador da matecultura' },
  { id: '612225 Plantador de agave', descricao: 'Plantador de agave' },
  { id: '612205 Plantador de algodão', descricao: 'Plantador de algodão' },
  { id: '622205 Plantador de algodão - exclusive conta própria e empregador', descricao: 'Plantador de algodão - exclusive conta própria e empregador' },
  { id: '622105 Plantador de arroz - conta própria', descricao: 'Plantador de arroz - conta própria' },
  { id: '622105 Plantador de arroz - empregador', descricao: 'Plantador de arroz - empregador' },
  { id: '622315 Plantador de beterraba', descricao: 'Plantador de beterraba' },
  { id: '622110 Plantador de cana-de-açúcar - conta própria', descricao: 'Plantador de cana-de-açúcar - conta própria' },
  { id: '622110 Plantador de cana-de-açúcar - empregador', descricao: 'Plantador de cana-de-açúcar - empregador' },
  { id: '622020 Plantador de cultura permanente', descricao: 'Plantador de cultura permanente' },
  { id: '622020 Plantador de cultura temporária', descricao: 'Plantador de cultura temporária' },
  { id: '612210 Plantador de curauá', descricao: 'Plantador de curauá' },
  { id: '612215 Plantador de juta', descricao: 'Plantador de juta' },
  { id: '632340 Plantador de malva', descricao: 'Plantador de malva' },
  { id: '622115 Plantador de milho e sorgo - conta própria', descricao: 'Plantador de milho e sorgo - conta própria' },
  { id: '622115 Plantador de milho e sorgo - empregador', descricao: 'Plantador de milho e sorgo - empregador' },
  { id: '612220 Plantador de rami', descricao: 'Plantador de rami' },
  { id: '612225 Plantador de sisal', descricao: 'Plantador de sisal' },
  { id: '622210 Plantador de sisal ou agave - exclusive conta própria e empregador', descricao: 'Plantador de sisal ou agave - exclusive conta própria e empregador' },
  { id: '622120 Plantador de trigo - conta própria', descricao: 'Plantador de trigo - conta própria' },
  { id: '622120 Plantador de trigo - empregador', descricao: 'Plantador de trigo - empregador' },
  { id: '519905 Plaqueiro', descricao: 'Plaqueiro' },
  { id: '711325 Plataformista (petróleo)', descricao: 'Plataformista (petróleo)' },
  { id: '723215 Platinador', descricao: 'Platinador' },
  { id: '225127 Pneumologista', descricao: 'Pneumologista' },
  { id: '225127 Pneumotisiologista', descricao: 'Pneumotisiologista' },
  { id: '632520 Poaieiro', descricao: 'Poaieiro' },
  { id: '717015 Poceiro', descricao: 'Poceiro' },
  { id: '717015 Poceiro (edificações)', descricao: 'Poceiro (edificações)' },
  { id: '622020 Podador agrícola', descricao: 'Podador agrícola' },
  { id: '622605 Podador da cultura de cacau', descricao: 'Podador da cultura de cacau' },
  { id: '992225 Podador de árvores na conservação de vias permanentes', descricao: 'Podador de árvores na conservação de vias permanentes' },
  { id: '622615 Podador de erva-mate', descricao: 'Podador de erva-mate' },
  { id: '622625 Podador de guaraná', descricao: 'Podador de guaraná' },
  { id: '322110 Podólogo', descricao: 'Podólogo' },
  { id: '261525 Poeta', descricao: 'Poeta' },
  { id: '352205 Polícia florestal e de mananciais', descricao: 'Polícia florestal e de mananciais' },
  { id: '5172 POLICIAIS, GUARDAS-CIVIS MUNICIPAIS E AGENTES DE TRÂNSITO', descricao: 'POLICIAIS, GUARDAS-CIVIS MUNICIPAIS E AGENTES DE TRÂNSITO' },
  { id: '517225 Policial legislativo', descricao: 'Policial legislativo' },
  { id: '517225 Policial legislativo federal', descricao: 'Policial legislativo federal' },
  { id: '517230 Policial penal', descricao: 'Policial penal' },
  { id: '517210 Policial rodoviário federal', descricao: 'Policial rodoviário federal' },
  { id: '519915 Polidor de artefatos de couro', descricao: 'Polidor de artefatos de couro' },
  { id: '519935 Polidor de automóveis', descricao: 'Polidor de automóveis' },
  { id: '519915 Polidor de calçados', descricao: 'Polidor de calçados' },
  { id: '764305 Polidor de calçados (na fabricação)', descricao: 'Polidor de calçados (na fabricação)' },
  { id: '752235 Polidor de cristais', descricao: 'Polidor de cristais' },
  { id: '752235 Polidor de cristais de óptica, a máquina', descricao: 'Polidor de cristais de óptica, a máquina' },
  { id: '721325 Polidor de ferramentas', descricao: 'Polidor de ferramentas' },
  { id: '721325 Polidor de fieiras', descricao: 'Polidor de fieiras' },
  { id: '712220 Polidor de granito', descricao: 'Polidor de granito' },
  { id: '752235 Polidor de lentes', descricao: 'Polidor de lentes' },
  { id: '712220 Polidor de mármore', descricao: 'Polidor de mármore' },
  { id: '716525 Polidor de mármore e granito (na construção)', descricao: 'Polidor de mármore e granito (na construção)' },
  { id: '712220 Polidor de marmorite', descricao: 'Polidor de marmorite' },
  { id: '721325 Polidor de matrizes', descricao: 'Polidor de matrizes' },
  { id: '721325 Polidor de metais', descricao: 'Polidor de metais' },
  { id: '721325 Polidor de metais a mão', descricao: 'Polidor de metais a mão' },
  { id: '712220 Polidor de pedras', descricao: 'Polidor de pedras' },
  { id: '712220 Polidor de pedras, a mão', descricao: 'Polidor de pedras, a mão' },
  { id: '712220 Polidor de pedras, a máquina', descricao: 'Polidor de pedras, a máquina' },
  { id: '721325 Polidor de rodas dentadas a máquina', descricao: 'Polidor de rodas dentadas a máquina' },
  { id: '519935 Polidor de veículos', descricao: 'Polidor de veículos' },
  { id: '752230 Polidor de vidros', descricao: 'Polidor de vidros' },
  { id: '752230 Polidor de vidros e cristais', descricao: 'Polidor de vidros e cristais' },
  { id: '263105 Pontífice', descricao: 'Pontífice' },
  { id: '263105 Pope', descricao: 'Pope' },
  { id: '782145 Portaló nas operações de máquinas e equipamentos de elevação', descricao: 'Portaló nas operações de máquinas e equipamentos de elevação' },
  { id: '517410 Porteiro', descricao: 'Porteiro' },
  { id: '517405 Porteiro (hotel)', descricao: 'Porteiro (hotel)' },
  { id: '517410 Porteiro de edifícios', descricao: 'Porteiro de edifícios' },
  { id: '517415 Porteiro de locais de diversão', descricao: 'Porteiro de locais de diversão' },
  { id: '517410 Porteiro industrial', descricao: 'Porteiro industrial' },
  { id: '5174 PORTEIROS, VIGIAS E AFINS', descricao: 'PORTEIROS, VIGIAS E AFINS' },
  { id: '374415 Pós-produtor de tv e vídeo', descricao: 'Pós-produtor de tv e vídeo' },
  { id: '611005 Posseiro na agropecuária - conta própria', descricao: 'Posseiro na agropecuária - conta própria' },
  { id: '10305 Praça da aeronáutica', descricao: 'Praça da aeronáutica' },
  { id: '10315 Praça da marinha', descricao: 'Praça da marinha' },
  { id: '10310 Praça do exército', descricao: 'Praça do exército' },
  { id: '103 PRAÇAS DAS FORÇAS ARMADAS', descricao: 'PRAÇAS DAS FORÇAS ARMADAS' },
  { id: '723215 Prateador', descricao: 'Prateador' },
  { id: '723235 Prateador (processo eletrolítico)', descricao: 'Prateador (processo eletrolítico)' },
  { id: '752425 Prateador de espelho', descricao: 'Prateador de espelho' },
  { id: '215145 Prático de portos da marinha mercante', descricao: 'Prático de portos da marinha mercante' },
  { id: '111250 Prefeito', descricao: 'Prefeito' },
  { id: '354405 Pregoeiro', descricao: 'Pregoeiro' },
  { id: '263105 Prelado', descricao: 'Prelado' },
  { id: '752330 Prensador cerâmico', descricao: 'Prensador cerâmico' },
  { id: '762330 Prensador de couros e peles', descricao: 'Prensador de couros e peles' },
  { id: '841464 Prensador de frutas (exceto oleaginosas)', descricao: 'Prensador de frutas (exceto oleaginosas)' },
  { id: '784125 Prensador de sacos', descricao: 'Prensador de sacos' },
  { id: '841460 Prensador de sementes e frutas oleaginosas', descricao: 'Prensador de sementes e frutas oleaginosas' },
  { id: '519215 Prenseiro', descricao: 'Prenseiro' },
  { id: '519215 Prensista', descricao: 'Prensista' },
  { id: '724515 Prensista (operador de prensa)', descricao: 'Prensista (operador de prensa)' },
  { id: '773210 Prensista de aglomerado de madeira', descricao: 'Prensista de aglomerado de madeira' },
  { id: '773210 Prensista de aglomerados', descricao: 'Prensista de aglomerados' },
  { id: '811750 Prensista de borracha', descricao: 'Prensista de borracha' },
  { id: '773215 Prensista de compensados', descricao: 'Prensista de compensados' },
  { id: '773215 Prensista de lâmina de madeira', descricao: 'Prensista de lâmina de madeira' },
  { id: '751125 Prensista de ourivesaria', descricao: 'Prensista de ourivesaria' },
  { id: '811760 Prensista de plástico', descricao: 'Prensista de plástico' },
  { id: '823230 Prensista de rebolo de diamante', descricao: 'Prensista de rebolo de diamante' },
  { id: '828110 Prensista de telhas e tijolos', descricao: 'Prensista de telhas e tijolos' },
  { id: '823130 Preparador de aditivos', descricao: 'Preparador de aditivos' },
  { id: '823130 Preparador de aditivos e massas refratárias', descricao: 'Preparador de aditivos e massas refratárias' },
  { id: '711105 Preparador de aglomerados de minérios e rochas', descricao: 'Preparador de aglomerados de minérios e rochas' },
  { id: '773220 Preparador de aglomerantes', descricao: 'Preparador de aglomerantes' },
  { id: '711105 Preparador de amostras de minérios e rochas', descricao: 'Preparador de amostras de minérios e rochas' },
  { id: '811335 Preparador de anéis de parafina', descricao: 'Preparador de anéis de parafina' },
  { id: '821245 Preparador de área de corrida', descricao: 'Preparador de área de corrida' },
  { id: '722325 Preparador de areia - em fundição', descricao: 'Preparador de areia - em fundição' },
  { id: '823115 Preparador de argila', descricao: 'Preparador de argila' },
  { id: '224115 Preparador de atleta', descricao: 'Preparador de atleta' },
  { id: '823120 Preparador de barbotina', descricao: 'Preparador de barbotina' },
  { id: '764115 Preparador de calçados', descricao: 'Preparador de calçados' },
  { id: '773220 Preparador de cola para madeira', descricao: 'Preparador de cola para madeira' },
  { id: '823110 Preparador de composição de matéria-prima (vidro)', descricao: 'Preparador de composição de matéria-prima (vidro)' },
  { id: '762340 Preparador de couros curtidos', descricao: 'Preparador de couros curtidos' },
  { id: '513420 Preparador de drinques e bebidas', descricao: 'Preparador de drinques e bebidas' },
  { id: '823125 Preparador de esmalte vítreo', descricao: 'Preparador de esmalte vítreo' },
  { id: '823125 Preparador de esmaltes (cerâmica)', descricao: 'Preparador de esmaltes (cerâmica)' },
  { id: '842105 Preparador de essência de fumo', descricao: 'Preparador de essência de fumo' },
  { id: '724220 Preparador de estruturas de aço', descricao: 'Preparador de estruturas de aço' },
  { id: '724220 Preparador de estruturas metálicas', descricao: 'Preparador de estruturas metálicas' },
  { id: '711120 Preparador de explosivos bombeados - detonador (blaster)', descricao: 'Preparador de explosivos bombeados - detonador (blaster)' },
  { id: '721225 Preparador de ferramentas para máquinas-ferramenta', descricao: 'Preparador de ferramentas para máquinas-ferramenta' },
  { id: '766125 Preparador de fotolitos', descricao: 'Preparador de fotolitos' },
  { id: '721225 Preparador de fresadora copiadora', descricao: 'Preparador de fresadora copiadora' },
  { id: '721225 Preparador de fresadora de engrenagens', descricao: 'Preparador de fresadora de engrenagens' },
  { id: '848605 Preparador de fumo', descricao: 'Preparador de fumo' },
  { id: '842205 Preparador de fumo na fabricação de charutos', descricao: 'Preparador de fumo na fabricação de charutos' },
  { id: '721225 Preparador de furadeira', descricao: 'Preparador de furadeira' },
  { id: '773220 Preparador de goma para colagem de madeira', descricao: 'Preparador de goma para colagem de madeira' },
  { id: '841408 Preparador de guarnições', descricao: 'Preparador de guarnições' },
  { id: '841408 Preparador de ingredientes (conservação de alimentos)', descricao: 'Preparador de ingredientes (conservação de alimentos)' },
  { id: '822125 Preparador de ligas na metalurgia', descricao: 'Preparador de ligas na metalurgia' },
  { id: '763105 Preparador de lotes e pacotes', descricao: 'Preparador de lotes e pacotes' },
  { id: '721225 Preparador de máquina de tarraxar', descricao: 'Preparador de máquina de tarraxar' },
  { id: '721225 Preparador de máquina de transferência', descricao: 'Preparador de máquina de transferência' },
  { id: '722105 Preparador de máquinas de forjar', descricao: 'Preparador de máquinas de forjar' },
  { id: '721225 Preparador de máquinas-ferramenta', descricao: 'Preparador de máquinas-ferramenta' },
  { id: '823105 Preparador de massa (fabricação de abrasivos)', descricao: 'Preparador de massa (fabricação de abrasivos)' },
  { id: '823110 Preparador de massa (fabricação de vidro)', descricao: 'Preparador de massa (fabricação de vidro)' },
  { id: '823105 Preparador de massa abrasiva', descricao: 'Preparador de massa abrasiva' },
  { id: '823120 Preparador de massa cerâmica', descricao: 'Preparador de massa cerâmica' },
  { id: '823115 Preparador de massa de argila', descricao: 'Preparador de massa de argila' },
  { id: '823115 Preparador de massa de argila em cerâmica', descricao: 'Preparador de massa de argila em cerâmica' },
  { id: '823115 Preparador de massa de argila em olaria', descricao: 'Preparador de massa de argila em olaria' },
  { id: '831105 Preparador de massa para fabricação de papel', descricao: 'Preparador de massa para fabricação de papel' },
  { id: '823115 Preparador de massa refratária (revestimento de forno)', descricao: 'Preparador de massa refratária (revestimento de forno)' },
  { id: '841810 Preparador de massas alimentícias - na fabricação', descricao: 'Preparador de massas alimentícias - na fabricação' },
  { id: '766325 Preparador de matrizes de corte e vinco', descricao: 'Preparador de matrizes de corte e vinco' },
  { id: '842105 Preparador de melado e essência de fumo', descricao: 'Preparador de melado e essência de fumo' },
  { id: '823105 Preparador de mistura abrasiva', descricao: 'Preparador de mistura abrasiva' },
  { id: '752325 Preparador de moldes (cerâmica)', descricao: 'Preparador de moldes (cerâmica)' },
  { id: '722320 Preparador de moldes de fundição, à máquina', descricao: 'Preparador de moldes de fundição, à máquina' },
  { id: '764120 Preparador de palmilhas', descricao: 'Preparador de palmilhas' },
  { id: '722235 Preparador de panelas (lingotamento)', descricao: 'Preparador de panelas (lingotamento)' },
  { id: '311715 Preparador de pastas (na fabricação de tintas)', descricao: 'Preparador de pastas (na fabricação de tintas)' },
  { id: '723205 Preparador de peça para decapagem', descricao: 'Preparador de peça para decapagem' },
  { id: '723205 Preparador de peça para imersão', descricao: 'Preparador de peça para imersão' },
  { id: '721225 Preparador de prensa mecânica de metais', descricao: 'Preparador de prensa mecânica de metais' },
  { id: '763315 Preparador de produção de peças para bordar', descricao: 'Preparador de produção de peças para bordar' },
  { id: '623015 Preparador de ração natural para gado', descricao: 'Preparador de ração natural para gado' },
  { id: '841468 Preparador de rações', descricao: 'Preparador de rações' },
  { id: '721225 Preparador de retificadora sem centro', descricao: 'Preparador de retificadora sem centro' },
  { id: '764120 Preparador de saltos para calçados', descricao: 'Preparador de saltos para calçados' },
  { id: '764120 Preparador de solas e palmilhas', descricao: 'Preparador de solas e palmilhas' },
  { id: '821445 Preparador de sucata e aparas', descricao: 'Preparador de sucata e aparas' },
  { id: '752410 Preparador de tela serigráfica de vidro', descricao: 'Preparador de tela serigráfica de vidro' },
  { id: '752405 Preparador de tela serigráfica para cerâmica', descricao: 'Preparador de tela serigráfica para cerâmica' },
  { id: '311715 Preparador de tintas', descricao: 'Preparador de tintas' },
  { id: '311720 Preparador de tintas (fábrica de tecidos)', descricao: 'Preparador de tintas (fábrica de tecidos)' },
  { id: '721225 Preparador de torno automático', descricao: 'Preparador de torno automático' },
  { id: '721225 Preparador de torno copiador', descricao: 'Preparador de torno copiador' },
  { id: '721225 Preparador de torno revólver', descricao: 'Preparador de torno revólver' },
  { id: '711330 Preparador e instalador de lodo para sondagem', descricao: 'Preparador e instalador de lodo para sondagem' },
  { id: '224120 Preparador físico', descricao: 'Preparador físico' },
  { id: '224120 Preparador fisiocorporal', descricao: 'Preparador fisiocorporal' },
  { id: '8311 PREPARADORES DE PASTA PARA FABRICAÇÃO DE PAPEL', descricao: 'PREPARADORES DE PASTA PARA FABRICAÇÃO DE PAPEL' },
  { id: '7212 PREPARADORES E OPERADORES DE MÁQUINAS-FERRAMENTA CONVENCIONAIS', descricao: 'PREPARADORES E OPERADORES DE MÁQUINAS-FERRAMENTA CONVENCIONAIS' },
  { id: '354405 Preposto de leiloeiro', descricao: 'Preposto de leiloeiro' },
  { id: '263105 Presbítero', descricao: 'Presbítero' },
  { id: '111205 Presidente da república', descricao: 'Presidente da república' },
  { id: '376235 Prestidigitador', descricao: 'Prestidigitador' },
  { id: '263105 Primaz', descricao: 'Primaz' },
  { id: '860105 Primeiro oficial de manutenção eletromecânica (utilidades)', descricao: 'Primeiro oficial de manutenção eletromecânica (utilidades)' },
  { id: '860115 Primeiro oficial de manutenção na geração, transmissão e distribuição de energia elétrica', descricao: 'Primeiro oficial de manutenção na geração, transmissão e distribuição de energia elétrica' },
  { id: '215210 Primeiro oficial de máquinas da marinha mercante', descricao: 'Primeiro oficial de máquinas da marinha mercante' },
  { id: '215140 Primeiro oficial de náutica', descricao: 'Primeiro oficial de náutica' },
  { id: '215115 Primeiro oficial de náutica (comandante)', descricao: 'Primeiro oficial de náutica (comandante)' },
  { id: '215125 Primeiro oficial de náutica (imediato)', descricao: 'Primeiro oficial de náutica (imediato)' },
  { id: '20305 Primeiro tenente de polícia militar', descricao: 'Primeiro tenente de polícia militar' },
  { id: '10305 Primeiro-sargento da aeronáutica', descricao: 'Primeiro-sargento da aeronáutica' },
  { id: '10315 Primeiro-sargento da marinha', descricao: 'Primeiro-sargento da marinha' },
  { id: '10310 Primeiro-sargento do exército', descricao: 'Primeiro-sargento do exército' },
  { id: '30305 Primeiro-tenente bombeiro militar', descricao: 'Primeiro-tenente bombeiro militar' },
  { id: '10205 Primeiro-tenente da aeronáutica', descricao: 'Primeiro-tenente da aeronáutica' },
  { id: '10215 Primeiro-tenente da marinha', descricao: 'Primeiro-tenente da marinha' },
  { id: '30305 Primeiro-tenente do corpo de bombeiros', descricao: 'Primeiro-tenente do corpo de bombeiros' },
  { id: '10210 Primeiro-tenente do exército', descricao: 'Primeiro-tenente do exército' },
  { id: '263105 Prior', descricao: 'Prior' },
  { id: '263105 Prioressa', descricao: 'Prioressa' },
  { id: '842110 Processador de fumo', descricao: 'Processador de fumo' },
  { id: '225280 Proctologista', descricao: 'Proctologista' },
  { id: '241210 Procurador autárquico', descricao: 'Procurador autárquico' },
  { id: '242410 Procurador da assistência judiciária', descricao: 'Procurador da assistência judiciária' },
  { id: '241215 Procurador da fazenda nacional', descricao: 'Procurador da fazenda nacional' },
  { id: '242205 Procurador da república', descricao: 'Procurador da república' },
  { id: '241235 Procurador de fundação', descricao: 'Procurador de fundação' },
  { id: '242210 Procurador de justiça', descricao: 'Procurador de justiça' },
  { id: '242215 Procurador de justiça militar', descricao: 'Procurador de justiça militar' },
  { id: '241215 Procurador distrital', descricao: 'Procurador distrital' },
  { id: '241220 Procurador do estado', descricao: 'Procurador do estado' },
  { id: '241225 Procurador do município', descricao: 'Procurador do município' },
  { id: '242220 Procurador do trabalho', descricao: 'Procurador do trabalho' },
  { id: '241230 Procurador federal', descricao: 'Procurador federal' },
  { id: '241235 Procurador fundacional', descricao: 'Procurador fundacional' },
  { id: '241225 Procurador municipal', descricao: 'Procurador municipal' },
  { id: '241215 Procurador na Justiça', descricao: 'Procurador na Justiça' },
  { id: '242225 Procurador regional da república', descricao: 'Procurador regional da república' },
  { id: '242230 Procurador regional do trabalho', descricao: 'Procurador regional do trabalho' },
  { id: '2412 PROCURADORES E ADVOGADOS PÚBLICOS', descricao: 'PROCURADORES E ADVOGADOS PÚBLICOS' },
  { id: '613005 Produtor em pecuária familiar', descricao: 'Produtor em pecuária familiar' },
  { id: '612005 Produtor agrícola polivalente', descricao: 'Produtor agrícola polivalente' },
  { id: '611005 Produtor agropecuário, em geral', descricao: 'Produtor agropecuário, em geral' },
  { id: '262110 Produtor cinematográfico', descricao: 'Produtor cinematográfico' },
  { id: '262105 Produtor cultural', descricao: 'Produtor cultural' },
  { id: '612705 Produtor da cultura de amendoim', descricao: 'Produtor da cultura de amendoim' },
  { id: '612710 Produtor da cultura de canola', descricao: 'Produtor da cultura de canola' },
  { id: '612715 Produtor da cultura de coco-da-baia', descricao: 'Produtor da cultura de coco-da-baia' },
  { id: '612720 Produtor da cultura de dendê', descricao: 'Produtor da cultura de dendê' },
  { id: '612725 Produtor da cultura de girassol', descricao: 'Produtor da cultura de girassol' },
  { id: '612730 Produtor da cultura de linho', descricao: 'Produtor da cultura de linho' },
  { id: '612735 Produtor da cultura de mamona', descricao: 'Produtor da cultura de mamona' },
  { id: '612720 Produtor da cultura de palma', descricao: 'Produtor da cultura de palma' },
  { id: '612740 Produtor da cultura de soja', descricao: 'Produtor da cultura de soja' },
  { id: '612505 Produtor de abacate', descricao: 'Produtor de abacate' },
  { id: '612510 Produtor de abacaxi', descricao: 'Produtor de abacaxi' },
  { id: '612225 Produtor de agave', descricao: 'Produtor de agave' },
  { id: '612205 Produtor de algodão', descricao: 'Produtor de algodão' },
  { id: '612705 Produtor de amendoim', descricao: 'Produtor de amendoim' },
  { id: '612105 Produtor de arroz', descricao: 'Produtor de arroz' },
  { id: '612505 Produtor de árvores frutíferas', descricao: 'Produtor de árvores frutíferas' },
  { id: '612115 Produtor de aveia', descricao: 'Produtor de aveia' },
  { id: '612505 Produtor de banana', descricao: 'Produtor de banana' },
  { id: '612610 Produtor de cacau', descricao: 'Produtor de cacau' },
  { id: '612605 Produtor de café', descricao: 'Produtor de café' },
  { id: '612505 Produtor de caju', descricao: 'Produtor de caju' },
  { id: '612110 Produtor de cana-de-açúcar', descricao: 'Produtor de cana-de-açúcar' },
  { id: '612710 Produtor de canola', descricao: 'Produtor de canola' },
  { id: '612505 Produtor de caqui', descricao: 'Produtor de caqui' },
  { id: '612115 Produtor de centeio', descricao: 'Produtor de centeio' },
  { id: '612115 Produtor de cereais de inverno', descricao: 'Produtor de cereais de inverno' },
  { id: '612115 Produtor de cevada', descricao: 'Produtor de cevada' },
  { id: '612715 Produtor de coco', descricao: 'Produtor de coco' },
  { id: '612710 Produtor de colza', descricao: 'Produtor de colza' },
  { id: '253410 Produtor de conteúdo digital', descricao: 'Produtor de conteúdo digital' },
  { id: '612210 Produtor de curauá', descricao: 'Produtor de curauá' },
  { id: '612720 Produtor de dendê', descricao: 'Produtor de dendê' },
  { id: '612615 Produtor de erva-mate', descricao: 'Produtor de erva-mate' },
  { id: '612805 Produtor de especiarias', descricao: 'Produtor de especiarias' },
  { id: '612510 Produtor de espécies frutíferas rasteiras', descricao: 'Produtor de espécies frutíferas rasteiras' },
  { id: '612515 Produtor de espécies frutíferas trepadeiras', descricao: 'Produtor de espécies frutíferas trepadeiras' },
  { id: '612405 Produtor de flores de corte', descricao: 'Produtor de flores de corte' },
  { id: '612410 Produtor de flores em vaso', descricao: 'Produtor de flores em vaso' },
  { id: '612415 Produtor de forrações', descricao: 'Produtor de forrações' },
  { id: '612515 Produtor de framboesa', descricao: 'Produtor de framboesa' },
  { id: '612620 Produtor de fumo', descricao: 'Produtor de fumo' },
  { id: '612725 Produtor de girassol', descricao: 'Produtor de girassol' },
  { id: '612120 Produtor de gramíneas forrageiras', descricao: 'Produtor de gramíneas forrageiras' },
  { id: '612625 Produtor de guaraná', descricao: 'Produtor de guaraná' },
  { id: '613415 Produtor de húmus de minhoca', descricao: 'Produtor de húmus de minhoca' },
  { id: '262110 Produtor de imagem (cinema)', descricao: 'Produtor de imagem (cinema)' },
  { id: '262125 Produtor de imagem (televisão)', descricao: 'Produtor de imagem (televisão)' },
  { id: '612215 Produtor de juta', descricao: 'Produtor de juta' },
  { id: '612515 Produtor de kiwi', descricao: 'Produtor de kiwi' },
  { id: '612505 Produtor de laranja', descricao: 'Produtor de laranja' },
  { id: '613115 Produtor de leite - na criação de gado bovino - empregador', descricao: 'Produtor de leite - na criação de gado bovino - empregador' },
  { id: '612730 Produtor de linhaça', descricao: 'Produtor de linhaça' },
  { id: '612730 Produtor de linho', descricao: 'Produtor de linho' },
  { id: '612505 Produtor de maçã', descricao: 'Produtor de maçã' },
  { id: '612505 Produtor de mamão', descricao: 'Produtor de mamão' },
  { id: '612735 Produtor de mamona', descricao: 'Produtor de mamona' },
  { id: '612505 Produtor de manga', descricao: 'Produtor de manga' },
  { id: '612515 Produtor de maracujá', descricao: 'Produtor de maracujá' },
  { id: '612510 Produtor de melancia', descricao: 'Produtor de melancia' },
  { id: '612510 Produtor de melão', descricao: 'Produtor de melão' },
  { id: '612125 Produtor de milho', descricao: 'Produtor de milho' },
  { id: '612125 Produtor de milho e sorgo', descricao: 'Produtor de milho e sorgo' },
  { id: '375125 Produtor de moda', descricao: 'Produtor de moda' },
  { id: '612510 Produtor de morango', descricao: 'Produtor de morango' },
  { id: '632015 Produtor de mudas (florestas)', descricao: 'Produtor de mudas (florestas)' },
  { id: '612720 Produtor de palma', descricao: 'Produtor de palma' },
  { id: '612505 Produtor de pêra', descricao: 'Produtor de pêra' },
  { id: '612505 Produtor de pêssego', descricao: 'Produtor de pêssego' },
  { id: '613305 Produtor de pinto de corte', descricao: 'Produtor de pinto de corte' },
  { id: '612810 Produtor de plantas aromáticas e medicinais', descricao: 'Produtor de plantas aromáticas e medicinais' },
  { id: '612420 Produtor de plantas ornamentais', descricao: 'Produtor de plantas ornamentais' },
  { id: '262125 Produtor de programa', descricao: 'Produtor de programa' },
  { id: '262115 Produtor de rádio', descricao: 'Produtor de rádio' },
  { id: '612220 Produtor de rami', descricao: 'Produtor de rami' },
  { id: '612120 Produtor de sementes forrageiras', descricao: 'Produtor de sementes forrageiras' },
  { id: '612225 Produtor de sisal', descricao: 'Produtor de sisal' },
  { id: '612740 Produtor de soja', descricao: 'Produtor de soja' },
  { id: '262110 Produtor de som (cinema)', descricao: 'Produtor de som (cinema)' },
  { id: '262115 Produtor de som (rádio)', descricao: 'Produtor de som (rádio)' },
  { id: '262120 Produtor de som (teatro)', descricao: 'Produtor de som (teatro)' },
  { id: '262125 Produtor de som (televisão)', descricao: 'Produtor de som (televisão)' },
  { id: '612125 Produtor de sorgo', descricao: 'Produtor de sorgo' },
  { id: '262120 Produtor de teatro', descricao: 'Produtor de teatro' },
  { id: '262125 Produtor de televisão', descricao: 'Produtor de televisão' },
  { id: '261130 Produtor de texto', descricao: 'Produtor de texto' },
  { id: '612115 Produtor de trigo', descricao: 'Produtor de trigo' },
  { id: '612115 Produtor de triticale', descricao: 'Produtor de triticale' },
  { id: '612515 Produtor de uva', descricao: 'Produtor de uva' },
  { id: '612320 Produtor na olericultura de frutos e sementes', descricao: 'Produtor na olericultura de frutos e sementes' },
  { id: '612305 Produtor na olericultura de legumes', descricao: 'Produtor na olericultura de legumes' },
  { id: '612310 Produtor na olericultura de raízes, bulbos e tubérculos', descricao: 'Produtor na olericultura de raízes, bulbos e tubérculos' },
  { id: '612315 Produtor na olericultura de talos, folhas e flores', descricao: 'Produtor na olericultura de talos, folhas e flores' },
  { id: '613110 Produtor rural de gado de corte', descricao: 'Produtor rural de gado de corte' },
  { id: '613005 Produtor rural em pecuária', descricao: 'Produtor rural em pecuária' },
  { id: '611005 Produtor rural na agropecuária', descricao: 'Produtor rural na agropecuária' },
  { id: '612005 Produtor rural polivalente na agricultura - conta própria', descricao: 'Produtor rural polivalente na agricultura - conta própria' },
  { id: '612005 Produtor rural polivalente na agricultura - empregador', descricao: 'Produtor rural polivalente na agricultura - empregador' },
  { id: '6121 PRODUTORES AGRÍCOLAS NA CULTURA DE GRAMÍNEAS', descricao: 'PRODUTORES AGRÍCOLAS NA CULTURA DE GRAMÍNEAS' },
  { id: '6126 PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS ESTIMULANTES', descricao: 'PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS ESTIMULANTES' },
  { id: '6122 PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS FIBROSAS', descricao: 'PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS FIBROSAS' },
  { id: '6127 PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS OLEAGINOSAS', descricao: 'PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS OLEAGINOSAS' },
  { id: '6125 PRODUTORES AGRÍCOLAS NA FRUTICULTURA', descricao: 'PRODUTORES AGRÍCOLAS NA FRUTICULTURA' },
  { id: '6123 PRODUTORES AGRÍCOLAS NA OLERICULTURA', descricao: 'PRODUTORES AGRÍCOLAS NA OLERICULTURA' },
  { id: '6124 PRODUTORES AGRÍCOLAS NO CULTIVO DE FLORES E PLANTAS ORNAMENTAIS', descricao: 'PRODUTORES AGRÍCOLAS NO CULTIVO DE FLORES E PLANTAS ORNAMENTAIS' },
  { id: '6120 PRODUTORES AGRÍCOLAS POLIVALENTES', descricao: 'PRODUTORES AGRÍCOLAS POLIVALENTES' },
  { id: '6110 PRODUTORES AGROPECUÁRIOS EM GERAL', descricao: 'PRODUTORES AGROPECUÁRIOS EM GERAL' },
  { id: '2621 PRODUTORES ARTÍSTICOS E CULTURAIS', descricao: 'PRODUTORES ARTÍSTICOS E CULTURAIS' },
  { id: '6133 PRODUTORES DA AVICULTURA E CUNICULTURA', descricao: 'PRODUTORES DA AVICULTURA E CUNICULTURA' },
  { id: '6134 PRODUTORES DE ANIMAIS E INSETOS ÚTEIS', descricao: 'PRODUTORES DE ANIMAIS E INSETOS ÚTEIS' },
  { id: '6128 PRODUTORES DE ESPECIARIAS E DE PLANTAS AROMÁTICAS E MEDICINAIS', descricao: 'PRODUTORES DE ESPECIARIAS E DE PLANTAS AROMÁTICAS E MEDICINAIS' },
  { id: '6131 PRODUTORES EM PECUÁRIA DE ANIMAIS DE GRANDE PORTE', descricao: 'PRODUTORES EM PECUÁRIA DE ANIMAIS DE GRANDE PORTE' },
  { id: '6132 PRODUTORES EM PECUÁRIA DE ANIMAIS DE MÉDIO PORTE', descricao: 'PRODUTORES EM PECUÁRIA DE ANIMAIS DE MÉDIO PORTE' },
  { id: '6130 PRODUTORES EM PECUÁRIA POLIVALENTE', descricao: 'PRODUTORES EM PECUÁRIA POLIVALENTE' },
  { id: '631415 Proeiro', descricao: 'Proeiro' },
  { id: '332105 Professor assistente de regência de classe', descricao: 'Professor assistente de regência de classe' },
  { id: '231205 Professor da educação de jovens e adultos do ensino fundamental (primeira a quarta série)', descricao: 'Professor da educação de jovens e adultos do ensino fundamental (primeira a quarta série)' },
  { id: '233105 Professor da área de meio ambiente', descricao: 'Professor da área de meio ambiente' },
  { id: '231210 Professor das séries iniciais', descricao: 'Professor das séries iniciais' },
  { id: '239205 Professor de língua portuguesa na modalidade escrita (ensino especial)', descricao: 'Professor de língua portuguesa na modalidade escrita (ensino especial)' },
  { id: '234810 Professor de administração', descricao: 'Professor de administração' },
  { id: '234810 Professor de administração financeira', descricao: 'Professor de administração financeira' },
  { id: '234810 Professor de administração pública', descricao: 'Professor de administração pública' },
  { id: '234604 Professor de alemão', descricao: 'Professor de alemão' },
  { id: '234110 Professor de álgebra linear (no ensino superior)', descricao: 'Professor de álgebra linear (no ensino superior)' },
  { id: '239220 Professor de aluno surdo-cego', descricao: 'Professor de aluno surdo-cego' },
  { id: '239205 Professor de alunos com deficiência auditiva e surdos', descricao: 'Professor de alunos com deficiência auditiva e surdos' },
  { id: '239210 Professor de alunos com deficiência física', descricao: 'Professor de alunos com deficiência física' },
  { id: '239215 Professor de alunos com deficiência mental', descricao: 'Professor de alunos com deficiência mental' },
  { id: '239220 Professor de alunos com deficiência múltipla', descricao: 'Professor de alunos com deficiência múltipla' },
  { id: '239225 Professor de alunos com deficiência visual', descricao: 'Professor de alunos com deficiência visual' },
  { id: '239215 Professor de alunos com deficiências mentais', descricao: 'Professor de alunos com deficiências mentais' },
  { id: '239205 Professor de alunos com distúrbios da áudio-comunicação', descricao: 'Professor de alunos com distúrbios da áudio-comunicação' },
  { id: '234115 Professor de amostragem estatística (no ensino superior)', descricao: 'Professor de amostragem estatística (no ensino superior)' },
  { id: '234815 Professor de análise de custos', descricao: 'Professor de análise de custos' },
  { id: '234705 Professor de antropologia do ensino superior', descricao: 'Professor de antropologia do ensino superior' },
  { id: '234305 Professor de arquitetura', descricao: 'Professor de arquitetura' },
  { id: '234710 Professor de arquivologia do ensino superior', descricao: 'Professor de arquivologia do ensino superior' },
  { id: '234905 Professor de artes cênicas no ensino superior', descricao: 'Professor de artes cênicas no ensino superior' },
  { id: '231310 Professor de artes do ensino fundamental de de 5ª a 8ª série', descricao: 'Professor de artes do ensino fundamental de de 5ª a 8ª série' },
  { id: '234905 Professor de artes do espetáculo no ensino superior', descricao: 'Professor de artes do espetáculo no ensino superior' },
  { id: '231310 Professor de artes na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de artes na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série' },
  { id: '232105 Professor de artes no ensino médio', descricao: 'Professor de artes no ensino médio' },
  { id: '234910 Professor de artes visuais no ensino superior (artes plásticas e multimídia)', descricao: 'Professor de artes visuais no ensino superior (artes plásticas e multimídia)' },
  { id: '234215 Professor de astronomia (ensino superior)', descricao: 'Professor de astronomia (ensino superior)' },
  { id: '234815 Professor de auditoria contábil', descricao: 'Professor de auditoria contábil' },
  { id: '234715 Professor de biblioteconomia do ensino superior', descricao: 'Professor de biblioteconomia do ensino superior' },
  { id: '232110 Professor de biologia no ensino médio', descricao: 'Professor de biologia no ensino médio' },
  { id: '239225 Professor de braile', descricao: 'Professor de braile' },
  { id: '234105 Professor de cálculo numérico (no ensino superior)', descricao: 'Professor de cálculo numérico (no ensino superior)' },
  { id: '239225 Professor de cegos', descricao: 'Professor de cegos' },
  { id: '234720 Professor de ciência política do ensino superior', descricao: 'Professor de ciência política do ensino superior' },
  { id: '234405 Professor de ciências biológicas do ensino superior', descricao: 'Professor de ciências biológicas do ensino superior' },
  { id: '231305 Professor de ciências exatas e naturais do ensino fundamental', descricao: 'Professor de ciências exatas e naturais do ensino fundamental' },
  { id: '231305 Professor de ciências na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de ciências na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série' },
  { id: '231305 Professor de ciências naturais no ensino fundamental de 5ª a 8ª série', descricao: 'Professor de ciências naturais no ensino fundamental de 5ª a 8ª série' },
  { id: '234905 Professor de circo no ensino superior', descricao: 'Professor de circo no ensino superior' },
  { id: '234435 Professor de clínica cirúrgica (ensino superior)', descricao: 'Professor de clínica cirúrgica (ensino superior)' },
  { id: '234435 Professor de clínica médica (ensino superior)', descricao: 'Professor de clínica médica (ensino superior)' },
  { id: '234120 Professor de computação (no ensino superior)', descricao: 'Professor de computação (no ensino superior)' },
  { id: '234725 Professor de comunicação social do ensino superior', descricao: 'Professor de comunicação social do ensino superior' },
  { id: '234815 Professor de contabilidade', descricao: 'Professor de contabilidade' },
  { id: '234815 Professor de contabilidade bancária', descricao: 'Professor de contabilidade bancária' },
  { id: '234815 Professor de contabilidade financeira', descricao: 'Professor de contabilidade financeira' },
  { id: '234815 Professor de contabilidade industrial', descricao: 'Professor de contabilidade industrial' },
  { id: '234815 Professor de contabilidade internacional', descricao: 'Professor de contabilidade internacional' },
  { id: '234815 Professor de contabilidade pública', descricao: 'Professor de contabilidade pública' },
  { id: '234815 Professor de contabilidade societária', descricao: 'Professor de contabilidade societária' },
  { id: '231110 Professor de creche', descricao: 'Professor de creche' },
  { id: '234676 Professor de crítica textual', descricao: 'Professor de crítica textual' },
  { id: '262830 Professor de dança', descricao: 'Professor de dança' },
  { id: '234905 Professor de dança no ensino superior', descricao: 'Professor de dança no ensino superior' },
  { id: '233110 Professor de desenho técnico', descricao: 'Professor de desenho técnico' },
  { id: '234805 Professor de desenvolvimento econômico', descricao: 'Professor de desenvolvimento econômico' },
  { id: '234505 Professor de didática (ensino superior)', descricao: 'Professor de didática (ensino superior)' },
  { id: '234730 Professor de direito do ensino superior', descricao: 'Professor de direito do ensino superior' },
  { id: '232115 Professor de disciplinas pedagógicas no ensino médio', descricao: 'Professor de disciplinas pedagógicas no ensino médio' },
  { id: '234805 Professor de econometria', descricao: 'Professor de econometria' },
  { id: '234805 Professor de economia', descricao: 'Professor de economia' },
  { id: '234805 Professor de economia brasileira', descricao: 'Professor de economia brasileira' },
  { id: '234805 Professor de economia internacional', descricao: 'Professor de economia internacional' },
  { id: '231310 Professor de educação artística do ensino fundamental', descricao: 'Professor de educação artística do ensino fundamental' },
  { id: '231315 Professor de educação física do ensino fundamental', descricao: 'Professor de educação física do ensino fundamental' },
  { id: '231315 Professor de educação física na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de educação física na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série' },
  { id: '232120 Professor de educação física no ensino médio', descricao: 'Professor de educação física no ensino médio' },
  { id: '234410 Professor de educação física no ensino superior', descricao: 'Professor de educação física no ensino superior' },
  { id: '234415 Professor de enfermagem do ensino superior', descricao: 'Professor de enfermagem do ensino superior' },
  { id: '234310 Professor de engenharia', descricao: 'Professor de engenharia' },
  { id: '239220 Professor de ensino especial na área de deficiência múltipla', descricao: 'Professor de ensino especial na área de deficiência múltipla' },
  { id: '331205 Professor de ensino fundamental - séries iniciais', descricao: 'Professor de ensino fundamental - séries iniciais' },
  { id: '331205 Professor de ensino fundamental de primeira a quarta séries', descricao: 'Professor de ensino fundamental de primeira a quarta séries' },
  { id: '331205 Professor de ensino fundamental nas quatro primeiras séries', descricao: 'Professor de ensino fundamental nas quatro primeiras séries' },
  { id: '231105 Professor de ensino pré-escolar', descricao: 'Professor de ensino pré-escolar' },
  { id: '231105 Professor de ensino pré-primário', descricao: 'Professor de ensino pré-primário' },
  { id: '233110 Professor de ensino profissionalizante no ensino de nível médio', descricao: 'Professor de ensino profissionalizante no ensino de nível médio' },
  { id: '234505 Professor de ensino superior na área de didática', descricao: 'Professor de ensino superior na área de didática' },
  { id: '234520 Professor de ensino superior na área de estágio supervisionado', descricao: 'Professor de ensino superior na área de estágio supervisionado' },
  { id: '234515 Professor de ensino superior na área de metodologia da pesquisa', descricao: 'Professor de ensino superior na área de metodologia da pesquisa' },
  { id: '234515 Professor de ensino superior na área de metodologia da pesquisa em educação', descricao: 'Professor de ensino superior na área de metodologia da pesquisa em educação' },
  { id: '234510 Professor de ensino superior na área de orientação educacional', descricao: 'Professor de ensino superior na área de orientação educacional' },
  { id: '234515 Professor de ensino superior na área de pesquisa educacional', descricao: 'Professor de ensino superior na área de pesquisa educacional' },
  { id: '234520 Professor de ensino superior na área de prática de ensino', descricao: 'Professor de ensino superior na área de prática de ensino' },
  { id: '234520 Professor de ensino superior na área de prática de ensino e estágio supervisionado', descricao: 'Professor de ensino superior na área de prática de ensino e estágio supervisionado' },
  { id: '331105 Professor de escolinha (maternal)', descricao: 'Professor de escolinha (maternal)' },
  { id: '234620 Professor de espanhol', descricao: 'Professor de espanhol' },
  { id: '234115 Professor de estatística (no ensino superior)', descricao: 'Professor de estatística (no ensino superior)' },
  { id: '239205 Professor de estimulação da língua portuguesa modalidade oral ( ensino especial)', descricao: 'Professor de estimulação da língua portuguesa modalidade oral ( ensino especial)' },
  { id: '234420 Professor de farmácia e bioquímica', descricao: 'Professor de farmácia e bioquímica' },
  { id: '234676 Professor de filologia', descricao: 'Professor de filologia' },
  { id: '234676 Professor de filologia e crítica textual', descricao: 'Professor de filologia e crítica textual' },
  { id: '234676 Professor de filologia germânica', descricao: 'Professor de filologia germânica' },
  { id: '234676 Professor de filologia portuguesa', descricao: 'Professor de filologia portuguesa' },
  { id: '234676 Professor de filologia românica', descricao: 'Professor de filologia românica' },
  { id: '234735 Professor de filosofia do ensino superior', descricao: 'Professor de filosofia do ensino superior' },
  { id: '232125 Professor de filosofia no ensino médio', descricao: 'Professor de filosofia no ensino médio' },
  { id: '234810 Professor de finanças (administração)', descricao: 'Professor de finanças (administração)' },
  { id: '234805 Professor de finanças públicas', descricao: 'Professor de finanças públicas' },
  { id: '234205 Professor de física (ensino superior)', descricao: 'Professor de física (ensino superior)' },
  { id: '232130 Professor de física no ensino médio', descricao: 'Professor de física no ensino médio' },
  { id: '234425 Professor de fisioterapia', descricao: 'Professor de fisioterapia' },
  { id: '234430 Professor de fonoaudiologia', descricao: 'Professor de fonoaudiologia' },
  { id: '234612 Professor de francês', descricao: 'Professor de francês' },
  { id: '224135 Professor de futebol', descricao: 'Professor de futebol' },
  { id: '234315 Professor de geofísica', descricao: 'Professor de geofísica' },
  { id: '231320 Professor de geografia do ensino fundamental', descricao: 'Professor de geografia do ensino fundamental' },
  { id: '234740 Professor de geografia do ensino superior', descricao: 'Professor de geografia do ensino superior' },
  { id: '231320 Professor de geografia na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de geografia na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série' },
  { id: '232135 Professor de geografia no ensino médio', descricao: 'Professor de geografia no ensino médio' },
  { id: '234320 Professor de geologia', descricao: 'Professor de geologia' },
  { id: '234810 Professor de gestão (administração)', descricao: 'Professor de gestão (administração)' },
  { id: '231325 Professor de história do ensino fundamental', descricao: 'Professor de história do ensino fundamental' },
  { id: '234745 Professor de história do ensino superior', descricao: 'Professor de história do ensino superior' },
  { id: '234805 Professor de história do pensamento econômico', descricao: 'Professor de história do pensamento econômico' },
  { id: '234805 Professor de história econômica', descricao: 'Professor de história econômica' },
  { id: '231325 Professor de história na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de história na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série' },
  { id: '232140 Professor de história no ensino médio', descricao: 'Professor de história no ensino médio' },
  { id: '234120 Professor de informática (no ensino superior)', descricao: 'Professor de informática (no ensino superior)' },
  { id: '234616 Professor de inglês', descricao: 'Professor de inglês' },
  { id: '234608 Professor de italiano', descricao: 'Professor de italiano' },
  { id: '331105 Professor de jardim da infância', descricao: 'Professor de jardim da infância' },
  { id: '231110 Professor de jardim de infância (nível superior)', descricao: 'Professor de jardim de infância (nível superior)' },
  { id: '234750 Professor de jornalismo', descricao: 'Professor de jornalismo' },
  { id: '234604 Professor de língua alemã', descricao: 'Professor de língua alemã' },
  { id: '232145 Professor de língua e literatura brasileira no ensino médio', descricao: 'Professor de língua e literatura brasileira no ensino médio' },
  { id: '234620 Professor de língua espanhola', descricao: 'Professor de língua espanhola' },
  { id: '231330 Professor de língua estrangeira moderna no ensino supletivo do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de língua estrangeira moderna no ensino supletivo do ensino fundamental de 5ª a 8ª série' },
  { id: '231330 Professor de língua estrangeira moderna do ensino fundamental', descricao: 'Professor de língua estrangeira moderna do ensino fundamental' },
  { id: '232150 Professor de língua estrangeira moderna no ensino médio', descricao: 'Professor de língua estrangeira moderna no ensino médio' },
  { id: '234612 Professor de língua francesa', descricao: 'Professor de língua francesa' },
  { id: '234616 Professor de língua inglesa', descricao: 'Professor de língua inglesa' },
  { id: '234608 Professor de língua italiana', descricao: 'Professor de língua italiana' },
  { id: '234624 Professor de língua portuguesa', descricao: 'Professor de língua portuguesa' },
  { id: '231335 Professor de língua portuguesa do ensino fundamental', descricao: 'Professor de língua portuguesa do ensino fundamental' },
  { id: '231335 Professor de língua portuguesa no ensino supletivo do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de língua portuguesa no ensino supletivo do ensino fundamental de 5ª a 8ª série' },
  { id: '234668 Professor de línguas estrangeiras modernas', descricao: 'Professor de línguas estrangeiras modernas' },
  { id: '234672 Professor de lingüística', descricao: 'Professor de lingüística' },
  { id: '234672 Professor de lingüística aplicada', descricao: 'Professor de lingüística aplicada' },
  { id: '234672 Professor de lingüística e lingüística aplicada', descricao: 'Professor de lingüística e lingüística aplicada' },
  { id: '234676 Professor de lingüística românica', descricao: 'Professor de lingüística românica' },
  { id: '234636 Professor de literatura alemã', descricao: 'Professor de literatura alemã' },
  { id: '234628 Professor de literatura brasileira', descricao: 'Professor de literatura brasileira' },
  { id: '234640 Professor de literatura comparada', descricao: 'Professor de literatura comparada' },
  { id: '234660 Professor de literatura de línguas estrangeiras modernas', descricao: 'Professor de literatura de línguas estrangeiras modernas' },
  { id: '234644 Professor de literatura espanhola', descricao: 'Professor de literatura espanhola' },
  { id: '234648 Professor de literatura francesa', descricao: 'Professor de literatura francesa' },
  { id: '234652 Professor de literatura inglesa', descricao: 'Professor de literatura inglesa' },
  { id: '234656 Professor de literatura italiana', descricao: 'Professor de literatura italiana' },
  { id: '234632 Professor de literatura portuguesa', descricao: 'Professor de literatura portuguesa' },
  { id: '234805 Professor de macroeconomia', descricao: 'Professor de macroeconomia' },
  { id: '234810 Professor de marketing', descricao: 'Professor de marketing' },
  { id: '234110 Professor de matemática (no ensino superior)', descricao: 'Professor de matemática (no ensino superior)' },
  { id: '234105 Professor de matemática aplicada (no ensino superior)', descricao: 'Professor de matemática aplicada (no ensino superior)' },
  { id: '231340 Professor de matemática do ensino fundamental', descricao: 'Professor de matemática do ensino fundamental' },
  { id: '234810 Professor de matemática financeira (administração)', descricao: 'Professor de matemática financeira (administração)' },
  { id: '234805 Professor de matemática financeira (economia)', descricao: 'Professor de matemática financeira (economia)' },
  { id: '234105 Professor de matemática financeira (no ensino superior)', descricao: 'Professor de matemática financeira (no ensino superior)' },
  { id: '231340 Professor de matemática na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série', descricao: 'Professor de matemática na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série' },
  { id: '232155 Professor de matemática no ensino médio', descricao: 'Professor de matemática no ensino médio' },
  { id: '234110 Professor de matemática pura (no ensino superior)', descricao: 'Professor de matemática pura (no ensino superior)' },
  { id: '331105 Professor de maternal', descricao: 'Professor de maternal' },
  { id: '231110 Professor de maternal (nivel superior)', descricao: 'Professor de maternal (nivel superior)' },
  { id: '234435 Professor de medicina', descricao: 'Professor de medicina' },
  { id: '234440 Professor de medicina veterinária', descricao: 'Professor de medicina veterinária' },
  { id: '234805 Professor de microeconomia', descricao: 'Professor de microeconomia' },
  { id: '231110 Professor de minimaternal', descricao: 'Professor de minimaternal' },
  { id: '234755 Professor de museologia do ensino superior', descricao: 'Professor de museologia do ensino superior' },
  { id: '234915 Professor de música no ensino superior', descricao: 'Professor de música no ensino superior' },
  { id: '331105 Professor de nível médio na educação infantil', descricao: 'Professor de nível médio na educação infantil' },
  { id: '331205 Professor de nível médio no ensino fundamental', descricao: 'Professor de nível médio no ensino fundamental' },
  { id: '331305 Professor de nível médio no ensino profissionalizante', descricao: 'Professor de nível médio no ensino profissionalizante' },
  { id: '231210 Professor de nível superior do ensino fundamental (primeira a quarta série)', descricao: 'Professor de nível superior do ensino fundamental (primeira a quarta série)' },
  { id: '231105 Professor de nível superior na educação infantil (quatro a seis anos)', descricao: 'Professor de nível superior na educação infantil (quatro a seis anos)' },
  { id: '231110 Professor de nível superior na educação infantil (zero a três anos)', descricao: 'Professor de nível superior na educação infantil (zero a três anos)' },
  { id: '234445 Professor de nutrição', descricao: 'Professor de nutrição' },
  { id: '234450 Professor de odontologia', descricao: 'Professor de odontologia' },
  { id: '234810 Professor de organização e métodos (administração)', descricao: 'Professor de organização e métodos (administração)' },
  { id: '239225 Professor de orientação e mobilidade de cegos', descricao: 'Professor de orientação e mobilidade de cegos' },
  { id: '239410 Professor de orientação educacional', descricao: 'Professor de orientação educacional' },
  { id: '234664 Professor de outras línguas e literaturas', descricao: 'Professor de outras línguas e literaturas' },
  { id: '234905 Professor de performance no ensino superior', descricao: 'Professor de performance no ensino superior' },
  { id: '234815 Professor de perícia contábil', descricao: 'Professor de perícia contábil' },
  { id: '234805 Professor de pesquisa econômica', descricao: 'Professor de pesquisa econômica' },
  { id: '234515 Professor de pesquisa educacional (ensino superior)', descricao: 'Professor de pesquisa educacional (ensino superior)' },
  { id: '234125 Professor de pesquisa operacional (no ensino superior)', descricao: 'Professor de pesquisa operacional (no ensino superior)' },
  { id: '234810 Professor de planejamento e desenvolvimento', descricao: 'Professor de planejamento e desenvolvimento' },
  { id: '234810 Professor de planejamento empresarial', descricao: 'Professor de planejamento empresarial' },
  { id: '234624 Professor de português', descricao: 'Professor de português' },
  { id: '234520 Professor de prática de ensino (ensino superior)', descricao: 'Professor de prática de ensino (ensino superior)' },
  { id: '331105 Professor de pré-escola', descricao: 'Professor de pré-escola' },
  { id: '234760 Professor de psicologia do ensino superior', descricao: 'Professor de psicologia do ensino superior' },
  { id: '232160 Professor de psicologia no ensino médio', descricao: 'Professor de psicologia no ensino médio' },
  { id: '234210 Professor de química (ensino superior)', descricao: 'Professor de química (ensino superior)' },
  { id: '234210 Professor de química inorgânica (ensino superior)', descricao: 'Professor de química inorgânica (ensino superior)' },
  { id: '232165 Professor de química no ensino médio', descricao: 'Professor de química no ensino médio' },
  { id: '234210 Professor de química orgânica (ensino superior)', descricao: 'Professor de química orgânica (ensino superior)' },
  { id: '239225 Professor de reabilitação funcional de cego', descricao: 'Professor de reabilitação funcional de cego' },
  { id: '239225 Professor de reabilitação visual', descricao: 'Professor de reabilitação visual' },
  { id: '234680 Professor de semiótica', descricao: 'Professor de semiótica' },
  { id: '234765 Professor de serviço social do ensino superior', descricao: 'Professor de serviço social do ensino superior' },
  { id: '234770 Professor de sociologia do ensino superior', descricao: 'Professor de sociologia do ensino superior' },
  { id: '232170 Professor de sociologia no ensino médio', descricao: 'Professor de sociologia no ensino médio' },
  { id: '239225 Professor de sorobã', descricao: 'Professor de sorobã' },
  { id: '231205 Professor de suplência do ensino fundamental (primeira a quarta série)', descricao: 'Professor de suplência do ensino fundamental (primeira a quarta série)' },
  { id: '234905 Professor de teatro no ensino superior', descricao: 'Professor de teatro no ensino superior' },
  { id: '234905 Professor de teatro-educação no ensino superior', descricao: 'Professor de teatro-educação no ensino superior' },
  { id: '233115 Professor de técnicas agrícolas', descricao: 'Professor de técnicas agrícolas' },
  { id: '233120 Professor de técnicas comerciais e secretariais', descricao: 'Professor de técnicas comerciais e secretariais' },
  { id: '233125 Professor de técnicas de enfermagem', descricao: 'Professor de técnicas de enfermagem' },
  { id: '239420 Professor de técnicas e recursos audiovisuais', descricao: 'Professor de técnicas e recursos audiovisuais' },
  { id: '233130 Professor de técnicas industriais', descricao: 'Professor de técnicas industriais' },
  { id: '233135 Professor de tecnologia e cálculo técnico', descricao: 'Professor de tecnologia e cálculo técnico' },
  { id: '234810 Professor de teoria da administração', descricao: 'Professor de teoria da administração' },
  { id: '234684 Professor de teoria da literatura', descricao: 'Professor de teoria da literatura' },
  { id: '234805 Professor de teoria econômica', descricao: 'Professor de teoria econômica' },
  { id: '234105 Professor de teoria matemática de sistemas (no ensino superior)', descricao: 'Professor de teoria matemática de sistemas (no ensino superior)' },
  { id: '234455 Professor de terapia ocupacional', descricao: 'Professor de terapia ocupacional' },
  { id: '234460 Professor de zootecnia do ensino superior', descricao: 'Professor de zootecnia do ensino superior' },
  { id: '231210 Professor dos ciclos iniciais (ensino fundamental)', descricao: 'Professor dos ciclos iniciais (ensino fundamental)' },
  { id: '239220 Professor em educação especial de DMu (deficiências múltiplas)', descricao: 'Professor em educação especial de DMu (deficiências múltiplas)' },
  { id: '239205 Professor especializado em deficiência auditiva e surdos', descricao: 'Professor especializado em deficiência auditiva e surdos' },
  { id: '239215 Professor especializado em excepcionais', descricao: 'Professor especializado em excepcionais' },
  { id: '331205 Professor i - ensino fundamental', descricao: 'Professor i - ensino fundamental' },
  { id: '332105 Professor indigenista bilingüe', descricao: 'Professor indigenista bilingüe' },
  { id: '233220 Professor instrutor de ensino e aprendizagem agroflorestal', descricao: 'Professor instrutor de ensino e aprendizagem agroflorestal' },
  { id: '233225 Professor instrutor de ensino e aprendizagem em serviços', descricao: 'Professor instrutor de ensino e aprendizagem em serviços' },
  { id: '332105 Professor leigo em classe multisseriada', descricao: 'Professor leigo em classe multisseriada' },
  { id: '332105 Professor leigo em regência de classe', descricao: 'Professor leigo em regência de classe' },
  { id: '332105 Professor leigo no ensino fundamental', descricao: 'Professor leigo no ensino fundamental' },
  { id: '332105 Professor leigo responsável por escolas rurais', descricao: 'Professor leigo responsável por escolas rurais' },
  { id: '239210 Professor na área de deficiência física', descricao: 'Professor na área de deficiência física' },
  { id: '332105 Professor não habilitado no ensino fundamental', descricao: 'Professor não habilitado no ensino fundamental' },
  { id: '332205 Professor prático no ensino profissionalizante', descricao: 'Professor prático no ensino profissionalizante' },
  { id: '332105 Professor sem formação para o magistério', descricao: 'Professor sem formação para o magistério' },
  { id: '234520 Professor universitário de estágio supervisionado', descricao: 'Professor universitário de estágio supervisionado' },
  { id: '234520 Professor universitário de metodologia do ensino', descricao: 'Professor universitário de metodologia do ensino' },
  { id: '234515 Professor universitário em metodologia de pesquisa em educação', descricao: 'Professor universitário em metodologia de pesquisa em educação' },
  { id: '234505 Professor universitário na área de didática', descricao: 'Professor universitário na área de didática' },
  { id: '234510 Professor universitário na área de orientação educacional', descricao: 'Professor universitário na área de orientação educacional' },
  { id: '234515 Professor universitário na área de pesquisa educacional', descricao: 'Professor universitário na área de pesquisa educacional' },
  { id: '234520 Professor universitário na área de prática de ensino', descricao: 'Professor universitário na área de prática de ensino' },
  { id: '233210 Professor-instrutor de cursos de treinamento industrial', descricao: 'Professor-instrutor de cursos de treinamento industrial' },
  { id: '233210 Professor-instrutor de educação profissional industrial', descricao: 'Professor-instrutor de educação profissional industrial' },
  { id: '2343 PROFESSORES DE ARQUITETURA E URBANISMO, ENGENHARIA, GEOFÍSICA E GEOLOGIA DO ENSINO SUPERIOR', descricao: 'PROFESSORES DE ARQUITETURA E URBANISMO, ENGENHARIA, GEOFÍSICA E GEOLOGIA DO ENSINO SUPERIOR' },
  { id: '2349 PROFESSORES DE ARTES DO ENSINO SUPERIOR', descricao: 'PROFESSORES DE ARTES DO ENSINO SUPERIOR' },
  { id: '2344 PROFESSORES DE CIÊNCIAS BIOLÓGICAS E DA SAÚDE DO ENSINO SUPERIOR', descricao: 'PROFESSORES DE CIÊNCIAS BIOLÓGICAS E DA SAÚDE DO ENSINO SUPERIOR' },
  { id: '2348 PROFESSORES DE CIÊNCIAS ECONÔMICAS, ADMINISTRATIVAS E CONTÁBEIS DO ENSINO SUPERIOR', descricao: 'PROFESSORES DE CIÊNCIAS ECONÔMICAS, ADMINISTRATIVAS E CONTÁBEIS DO ENSINO SUPERIOR' },
  { id: '2342 PROFESSORES DE CIÊNCIAS FÍSICAS, QUÍMICAS E AFINS DO ENSINO SUPERIOR', descricao: 'PROFESSORES DE CIÊNCIAS FÍSICAS, QUÍMICAS E AFINS DO ENSINO SUPERIOR' },
  { id: '2347 PROFESSORES DE CIÊNCIAS HUMANAS DO ENSINO SUPERIOR', descricao: 'PROFESSORES DE CIÊNCIAS HUMANAS DO ENSINO SUPERIOR' },
  { id: '333115 Professores de cursos livres', descricao: 'Professores de cursos livres' },
  { id: '2392 PROFESSORES DE EDUCAÇÃO ESPECIAL', descricao: 'PROFESSORES DE EDUCAÇÃO ESPECIAL' },
  { id: '2341 PROFESSORES DE MATEMÁTICA, ESTATÍSTICA E INFORMÁTICA DO ENSINO SUPERIOR', descricao: 'PROFESSORES DE MATEMÁTICA, ESTATÍSTICA E INFORMÁTICA DO ENSINO SUPERIOR' },
  { id: '3311 PROFESSORES DE NÍVEL MÉDIO NA EDUCAÇÃO INFANTIL', descricao: 'PROFESSORES DE NÍVEL MÉDIO NA EDUCAÇÃO INFANTIL' },
  { id: '3312 PROFESSORES DE NÍVEL MÉDIO NO ENSINO FUNDAMENTAL', descricao: 'PROFESSORES DE NÍVEL MÉDIO NO ENSINO FUNDAMENTAL' },
  { id: '3313 PROFESSORES DE NÍVEL MÉDIO NO ENSINO PROFISSIONALIZANTE', descricao: 'PROFESSORES DE NÍVEL MÉDIO NO ENSINO PROFISSIONALIZANTE' },
  { id: '2312 PROFESSORES DE NÍVEL SUPERIOR DO ENSINO FUNDAMENTAL (PRIMEIRA A QUARTA SÉRIES)', descricao: 'PROFESSORES DE NÍVEL SUPERIOR DO ENSINO FUNDAMENTAL (PRIMEIRA A QUARTA SÉRIES)' },
  { id: '2311 PROFESSORES DE NÍVEL SUPERIOR NA EDUCAÇÃO INFANTIL', descricao: 'PROFESSORES DE NÍVEL SUPERIOR NA EDUCAÇÃO INFANTIL' },
  { id: '2313 PROFESSORES DE NÍVEL SUPERIOR NO ENSINO FUNDAMENTAL DE QUINTA A OITAVA SÉRIE', descricao: 'PROFESSORES DE NÍVEL SUPERIOR NO ENSINO FUNDAMENTAL DE QUINTA A OITAVA SÉRIE' },
  { id: '2321 PROFESSORES DO ENSINO MÉDIO', descricao: 'PROFESSORES DO ENSINO MÉDIO' },
  { id: '2331 PROFESSORES DO ENSINO PROFISSIONAL', descricao: 'PROFESSORES DO ENSINO PROFISSIONAL' },
  { id: '3321 PROFESSORES LEIGOS NO ENSINO FUNDAMENTAL', descricao: 'PROFESSORES LEIGOS NO ENSINO FUNDAMENTAL' },
  { id: '2345 PROFESSORES NA ÁREA DE FORMAÇÃO PEDAGÓGICA DO ENSINO SUPERIOR', descricao: 'PROFESSORES NA ÁREA DE FORMAÇÃO PEDAGÓGICA DO ENSINO SUPERIOR' },
  { id: '2346 PROFESSORES NAS ÁREAS DE LÍNGUA E LITERATURA DO ENSINO SUPERIOR', descricao: 'PROFESSORES NAS ÁREAS DE LÍNGUA E LITERATURA DO ENSINO SUPERIOR' },
  { id: '3322 PROFESSORES PRÁTICOS NO ENSINO PROFISSIONALIZANTE', descricao: 'PROFESSORES PRÁTICOS NO ENSINO PROFISSIONALIZANTE' },
  { id: '2526 PROFISSIONAIS DA ADMINISTRAÇÃO DOS SERVIÇOS DE SEGURANÇA', descricao: 'PROFISSIONAIS DA ADMINISTRAÇÃO DOS SERVIÇOS DE SEGURANÇA' },
  { id: '2011 PROFISSIONAIS DA BIOTECNOLOGIA', descricao: 'PROFISSIONAIS DA BIOTECNOLOGIA' },
  { id: '2241 PROFISSIONAIS DA EDUCAÇÃO FÍSICA', descricao: 'PROFISSIONAIS DA EDUCAÇÃO FÍSICA' },
  { id: '2615 PROFISSIONAIS DA ESCRITA', descricao: 'PROFISSIONAIS DA ESCRITA' },
  { id: '2545 PROFISSIONAIS DA FISCALIZAÇÃO DE ATIVIDADES URBANAS', descricao: 'PROFISSIONAIS DA FISCALIZAÇÃO DE ATIVIDADES URBANAS' },
  { id: '2612 PROFISSIONAIS DA INFORMAÇÃO', descricao: 'PROFISSIONAIS DA INFORMAÇÃO' },
  { id: '2429 PROFISSIONAIS DA INTELIGÊNCIA', descricao: 'PROFISSIONAIS DA INTELIGÊNCIA' },
  { id: '2111 PROFISSIONAIS DA MATEMÁTICA', descricao: 'PROFISSIONAIS DA MATEMÁTICA' },
  { id: '2012 PROFISSIONAIS DA METROLOGIA', descricao: 'PROFISSIONAIS DA METROLOGIA' },
  { id: '2153 PROFISSIONAIS DA PILOTAGEM AERONÁUTICA', descricao: 'PROFISSIONAIS DA PILOTAGEM AERONÁUTICA' },
  { id: '2133 PROFISSIONAIS DAS CIÊNCIAS ATMOSFÉRICAS E ESPACIAIS E DE ASTRONOMIA', descricao: 'PROFISSIONAIS DAS CIÊNCIAS ATMOSFÉRICAS E ESPACIAIS E DE ASTRONOMIA' },
  { id: '2263 PROFISSIONAIS DAS TERAPIAS CRIATIVAS,EQUOTERÁPICAS E NATUROLÓGICAS', descricao: 'PROFISSIONAIS DAS TERAPIAS CRIATIVAS,EQUOTERÁPICAS E NATUROLÓGICAS' },
  { id: '2525 PROFISSIONAIS DE ADMINISTRAÇÃO ECÔNOMICO-FINANCEIRA', descricao: 'PROFISSIONAIS DE ADMINISTRAÇÃO ECÔNOMICO-FINANCEIRA' },
  { id: '2532 PROFISSIONAIS DE COMERCIALIZACÃO E CONSULTORIA DE SERVIÇOS BANCÁRIOS', descricao: 'PROFISSIONAIS DE COMERCIALIZACÃO E CONSULTORIA DE SERVIÇOS BANCÁRIOS' },
  { id: '3524 PROFISSIONAIS DE DIREITOS AUTORAIS E DE AVALIACÃO DE PRODUTOS DOS MEIOS DE COMUNICAÇÃO', descricao: 'PROFISSIONAIS DE DIREITOS AUTORAIS E DE AVALIACÃO DE PRODUTOS DOS MEIOS DE COMUNICAÇÃO' },
  { id: '2112 PROFISSIONAIS DE ESTATÍSTICA', descricao: 'PROFISSIONAIS DE ESTATÍSTICA' },
  { id: '2534 PROFISSIONAIS DE MÍDIAS DIGITAIS E AFINS', descricao: 'PROFISSIONAIS DE MÍDIAS DIGITAIS E AFINS' },
  { id: '2527 PROFISSIONAIS DE PLANEJAMENTO, PROGRAMAÇÃO E CONTROLES LOGISTICOS', descricao: 'PROFISSIONAIS DE PLANEJAMENTO, PROGRAMAÇÃO E CONTROLES LOGISTICOS' },
  { id: '2531 PROFISSIONAIS DE PUBLICIDADE', descricao: 'PROFISSIONAIS DE PUBLICIDADE' },
  { id: '2524 PROFISSIONAIS DE RECURSOS HUMANOS', descricao: 'PROFISSIONAIS DE RECURSOS HUMANOS' },
  { id: '142345 Profissionais de relações institucionais', descricao: 'Profissionais de relações institucionais' },
  { id: '2611 PROFISSIONAIS DO JORNALISMO', descricao: 'PROFISSIONAIS DO JORNALISMO' },
  { id: '5198 PROFISSIONAIS DO SEXO', descricao: 'PROFISSIONAIS DO SEXO' },
  { id: '2511 PROFISSIONAIS EM PESQUISA E ANÁLISE ANTROPOLÓGICA SOCIOLÓGICA', descricao: 'PROFISSIONAIS EM PESQUISA E ANÁLISE ANTROPOLÓGICA SOCIOLÓGICA' },
  { id: '2513 PROFISSIONAIS EM PESQUISA E ANÁLISE GEOGRÁFICA', descricao: 'PROFISSIONAIS EM PESQUISA E ANÁLISE GEOGRÁFICA' },
  { id: '7630 PROFISSIONAIS POLIVALENTES DA CONFECÇÃO DE ROUPAS', descricao: 'PROFISSIONAIS POLIVALENTES DA CONFECÇÃO DE ROUPAS' },
  { id: '377140 Profissional de atletismo', descricao: 'Profissional de atletismo' },
  { id: '224140 Profissional de educação física na saúde', descricao: 'Profissional de educação física na saúde' },
  { id: '377115 Profissional de golfe', descricao: 'Profissional de golfe' },
  { id: '375130 Profissional de organização (personal organizer)', descricao: 'Profissional de organização (personal organizer)' },
  { id: '252550 Profissional de relações com investidores', descricao: 'Profissional de relações com investidores' },
  { id: '252550 Profissional de relações com o mercado', descricao: 'Profissional de relações com o mercado' },
  { id: '142345 Profissional de relações governamentais', descricao: 'Profissional de relações governamentais' },
  { id: '142345 Profissional de relações institucionais e governamentais', descricao: 'Profissional de relações institucionais e governamentais' },
  { id: '142325 Profissional de relações públicas', descricao: 'Profissional de relações públicas' },
  { id: '252550 Profissional de ri (relações com investidores)', descricao: 'Profissional de ri (relações com investidores)' },
  { id: '342505 Profissional de tráfego aéreo', descricao: 'Profissional de tráfego aéreo' },
  { id: '519805 Profissional do sexo', descricao: 'Profissional do sexo' },
  { id: '142350 Profissonal de relações internacionais', descricao: 'Profissonal de relações internacionais' },
  { id: '317120 Programador de aplicativos educacionais e de entretenimento', descricao: 'Programador de aplicativos educacionais e de entretenimento' },
  { id: '317120 Programador de comunicação de sistemas', descricao: 'Programador de comunicação de sistemas' },
  { id: '317120 Programador de comunicação visual e sonora de sistemas', descricao: 'Programador de comunicação visual e sonora de sistemas' },
  { id: '391125 Programador de controle de produção', descricao: 'Programador de controle de produção' },
  { id: '763120 Programador de encaixe (cad)', descricao: 'Programador de encaixe (cad)' },
  { id: '766145 Programador de fotocomposição', descricao: 'Programador de fotocomposição' },
  { id: '317105 Programador de internet', descricao: 'Programador de internet' },
  { id: '317115 Programador de máquinas - ferramenta com comando numérico', descricao: 'Programador de máquinas - ferramenta com comando numérico' },
  { id: '317120 Programador de multimídia', descricao: 'Programador de multimídia' },
  { id: '763120 Programador de risco e corte', descricao: 'Programador de risco e corte' },
  { id: '317110 Programador de sistemas de computador', descricao: 'Programador de sistemas de computador' },
  { id: '317110 Programador de sistemas de informação', descricao: 'Programador de sistemas de informação' },
  { id: '342110 Programador de transporte multimodal', descricao: 'Programador de transporte multimodal' },
  { id: '342305 Programador de transporte rodoviário', descricao: 'Programador de transporte rodoviário' },
  { id: '314110 Programador de usinagem', descricao: 'Programador de usinagem' },
  { id: '391135 Programador e controlador de materiais', descricao: 'Programador e controlador de materiais' },
  { id: '710210 Programador ferroviário', descricao: 'Programador ferroviário' },
  { id: '374155 Programador musical', descricao: 'Programador musical' },
  { id: '766155 Programador visual gráfico', descricao: 'Programador visual gráfico' },
  { id: '2394 PROGRAMADORES, AVALIADORES E ORIENTADORES DE ENSINO', descricao: 'PROGRAMADORES, AVALIADORES E ORIENTADORES DE ENSINO' },
  { id: '374305 Projecionista', descricao: 'Projecionista' },
  { id: '214105 Projetista (arquiteto)', descricao: 'Projetista (arquiteto)' },
  { id: '374135 Projetista de áudio', descricao: 'Projetista de áudio' },
  { id: '318705 Projetista de eletricidade', descricao: 'Projetista de eletricidade' },
  { id: '318610 Projetista de ferramentas', descricao: 'Projetista de ferramentas' },
  { id: '314715 Projetista de fundição em siderurgia', descricao: 'Projetista de fundição em siderurgia' },
  { id: '318705 Projetista de instalações elétricas', descricao: 'Projetista de instalações elétricas' },
  { id: '318705 Projetista de instrumentos elétricos', descricao: 'Projetista de instrumentos elétricos' },
  { id: '318610 Projetista de matrizes', descricao: 'Projetista de matrizes' },
  { id: '318610 Projetista de moldes', descricao: 'Projetista de moldes' },
  { id: '314125 Projetista de motores', descricao: 'Projetista de motores' },
  { id: '318805 Projetista de móveis', descricao: 'Projetista de móveis' },
  { id: '374135 Projetista de sistemas de áudio', descricao: 'Projetista de sistemas de áudio' },
  { id: '374120 Projetista de som', descricao: 'Projetista de som' },
  { id: '313110 Projetista elétrico', descricao: 'Projetista elétrico' },
  { id: '318705 Projetista eletroeletrônico', descricao: 'Projetista eletroeletrônico' },
  { id: '318705 Projetista eletrotécnico', descricao: 'Projetista eletrotécnico' },
  { id: '239435 Projetista instrucional', descricao: 'Projetista instrucional' },
  { id: '318505 Projetista na arquitetura', descricao: 'Projetista na arquitetura' },
  { id: '318510 Projetista na construção civil', descricao: 'Projetista na construção civil' },
  { id: '242235 Promotor de justiça', descricao: 'Promotor de justiça' },
  { id: '521115 Promotor de vendas', descricao: 'Promotor de vendas' },
  { id: '354130 Promotor de vendas especializado', descricao: 'Promotor de vendas especializado' },
  { id: '519905 Propagandista', descricao: 'Propagandista' },
  { id: '354150 Propagandista de produtos famacêuticos', descricao: 'Propagandista de produtos famacêuticos' },
  { id: '613405 Proprietário - na criação de abelhas - empregador', descricao: 'Proprietário - na criação de abelhas - empregador' },
  { id: '613420 Proprietário - na criação de bicho-da-seda - empregador', descricao: 'Proprietário - na criação de bicho-da-seda - empregador' },
  { id: '613310 Proprietário - na criação de coelhos', descricao: 'Proprietário - na criação de coelhos' },
  { id: '613130 Proprietário - na criação de eqüinos', descricao: 'Proprietário - na criação de eqüinos' },
  { id: '613110 Proprietário - na criação de gado bovino - empregador', descricao: 'Proprietário - na criação de gado bovino - empregador' },
  { id: '613105 Proprietário - na criação de muares', descricao: 'Proprietário - na criação de muares' },
  { id: '613210 Proprietário - na criação de ovinos', descricao: 'Proprietário - na criação de ovinos' },
  { id: '613215 Proprietário - na criação de suínos empregador', descricao: 'Proprietário - na criação de suínos empregador' },
  { id: '141510 Proprietário - nos serviços de alimentação - empregador', descricao: 'Proprietário - nos serviços de alimentação - empregador' },
  { id: '141505 Proprietário - nos serviços de alojamento - empregador', descricao: 'Proprietário - nos serviços de alojamento - empregador' },
  { id: '141505 Proprietário - nos serviços de hospedagem - empregador', descricao: 'Proprietário - nos serviços de hospedagem - empregador' },
  { id: '141205 Proprietário de areal - empregador', descricao: 'Proprietário de areal - empregador' },
  { id: '141515 Proprietário de bar - empregador', descricao: 'Proprietário de bar - empregador' },
  { id: '141515 Proprietário de bar, lanchonete, restaurante - conta própria', descricao: 'Proprietário de bar, lanchonete, restaurante - conta própria' },
  { id: '141410 Proprietário de casa funerária (comércio varejista)', descricao: 'Proprietário de casa funerária (comércio varejista)' },
  { id: '141410 Proprietário de fliperama', descricao: 'Proprietário de fliperama' },
  { id: '613305 Proprietário de granja - na criação de aves', descricao: 'Proprietário de granja - na criação de aves' },
  { id: '613215 Proprietário de granja de suínos', descricao: 'Proprietário de granja de suínos' },
  { id: '141505 Proprietário de hotel - conta própria', descricao: 'Proprietário de hotel - conta própria' },
  { id: '141505 Proprietário de hotel - empregador', descricao: 'Proprietário de hotel - empregador' },
  { id: '141410 Proprietário de jogos eletrônicos', descricao: 'Proprietário de jogos eletrônicos' },
  { id: '141515 Proprietário de lanchonete - empregador', descricao: 'Proprietário de lanchonete - empregador' },
  { id: '141410 Proprietário de lavanderia', descricao: 'Proprietário de lavanderia' },
  { id: '141410 Proprietário de lavanderia automática (comércio varejista)', descricao: 'Proprietário de lavanderia automática (comércio varejista)' },
  { id: '141410 Proprietário de loja (comércio varejista)', descricao: 'Proprietário de loja (comércio varejista)' },
  { id: '141505 Proprietário de motel - conta própria', descricao: 'Proprietário de motel - conta própria' },
  { id: '141420 Proprietário de oficina mecânica', descricao: 'Proprietário de oficina mecânica' },
  { id: '141410 Proprietário de pebolim (comércio varejista)', descricao: 'Proprietário de pebolim (comércio varejista)' },
  { id: '141205 Proprietário de pedreira - empregador', descricao: 'Proprietário de pedreira - empregador' },
  { id: '141520 Proprietário de pensão - conta própria', descricao: 'Proprietário de pensão - conta própria' },
  { id: '141520 Proprietário de pensão - empregador', descricao: 'Proprietário de pensão - empregador' },
  { id: '141205 Proprietário de porto de areia - empregador', descricao: 'Proprietário de porto de areia - empregador' },
  { id: '141510 Proprietário de restaurante - empregador', descricao: 'Proprietário de restaurante - empregador' },
  { id: '141205 Proprietário de saibreira - empregador', descricao: 'Proprietário de saibreira - empregador' },
  { id: '141205 Proprietário de salina - empregador', descricao: 'Proprietário de salina - empregador' },
  { id: '141410 Proprietário de sinuquinha (comércio varejista)', descricao: 'Proprietário de sinuquinha (comércio varejista)' },
  { id: '141410 Proprietário de tinturaria (comércio varejista)', descricao: 'Proprietário de tinturaria (comércio varejista)' },
  { id: '141410 Proprietário de totó (comércio varejista)', descricao: 'Proprietário de totó (comércio varejista)' },
  { id: '611005 Proprietário na agropecuária - conta própria', descricao: 'Proprietário na agropecuária - conta própria' },
  { id: '613205 Proprietário na criação de caprinos', descricao: 'Proprietário na criação de caprinos' },
  { id: '141510 Proprietário no serviço de alimentação - conta própria', descricao: 'Proprietário no serviço de alimentação - conta própria' },
  { id: '612005 Proprietário polivalente na agricultura - conta própria', descricao: 'Proprietário polivalente na agricultura - conta própria' },
  { id: '612005 Proprietário polivalente na agricultura - empregador', descricao: 'Proprietário polivalente na agricultura - empregador' },
  { id: '261515 Prosador', descricao: 'Prosador' },
  { id: '316320 Prospector', descricao: 'Prospector' },
  { id: '214705 Prospector de jazidas', descricao: 'Prospector de jazidas' },
  { id: '214705 Prospector de minerais', descricao: 'Prospector de minerais' },
  { id: '214705 Prospector de minérios', descricao: 'Prospector de minérios' },
  { id: '519805 Prostituta', descricao: 'Prostituta' },
  { id: '223256 Protesista', descricao: 'Protesista' },
  { id: '322505 Protesista (técnico)', descricao: 'Protesista (técnico)' },
  { id: '223252 Protesista bucomaxilofacial', descricao: 'Protesista bucomaxilofacial' },
  { id: '322410 Protético dentário', descricao: 'Protético dentário' },
  { id: '771105 Prototipista (marceneiro)', descricao: 'Prototipista (marceneiro)' },
  { id: '848420 Provador de bebidas', descricao: 'Provador de bebidas' },
  { id: '848415 Provador de cacau', descricao: 'Provador de cacau' },
  { id: '848405 Provador de café', descricao: 'Provador de café' },
  { id: '848410 Provador de chá', descricao: 'Provador de chá' },
  { id: '848420 Provador de licores', descricao: 'Provador de licores' },
  { id: '848420 Provador de vinhos', descricao: 'Provador de vinhos' },
  { id: '766145 Provista (provas analógicas e digitais)', descricao: 'Provista (provas analógicas e digitais)' },
  { id: '766145 Provista de clicheria (em cores)', descricao: 'Provista de clicheria (em cores)' },
  { id: '766145 Provista tipógrafo', descricao: 'Provista tipógrafo' },
  { id: '251550 Psicanalista', descricao: 'Psicanalista' },
  { id: '251555 Psicólogo acupunturista', descricao: 'Psicólogo acupunturista' },
  { id: '251510 Psicólogo clínico', descricao: 'Psicólogo clínico' },
  { id: '251525 Psicólogo criminal', descricao: 'Psicólogo criminal' },
  { id: '251505 Psicólogo da educação', descricao: 'Psicólogo da educação' },
  { id: '251510 Psicólogo da saúde', descricao: 'Psicólogo da saúde' },
  { id: '251515 Psicólogo desportivo', descricao: 'Psicólogo desportivo' },
  { id: '251515 Psicólogo do esporte', descricao: 'Psicólogo do esporte' },
  { id: '251540 Psicólogo do trabalho', descricao: 'Psicólogo do trabalho' },
  { id: '251535 Psicólogo do trânsito', descricao: 'Psicólogo do trânsito' },
  { id: '251505 Psicólogo educacional', descricao: 'Psicólogo educacional' },
  { id: '251505 Psicólogo escolar', descricao: 'Psicólogo escolar' },
  { id: '251525 Psicólogo forense', descricao: 'Psicólogo forense' },
  { id: '251520 Psicólogo hospitalar', descricao: 'Psicólogo hospitalar' },
  { id: '251525 Psicólogo jurídico', descricao: 'Psicólogo jurídico' },
  { id: '251540 Psicólogo organizacional', descricao: 'Psicólogo organizacional' },
  { id: '251530 Psicólogo social', descricao: 'Psicólogo social' },
  { id: '2515 PSICÓLOGOS E PSICANALISTAS', descricao: 'PSICÓLOGOS E PSICANALISTAS' },
  { id: '223915 Psicomotricista', descricao: 'Psicomotricista' },
  { id: '239425 Psicopedagogo', descricao: 'Psicopedagogo' },
  { id: '251510 Psicoterapeuta', descricao: 'Psicoterapeuta' },
  { id: '225133 Psiquiatra', descricao: 'Psiquiatra' },
  { id: '253115 Publicitário', descricao: 'Publicitário' },
  { id: '377145 Pugilista', descricao: 'Pugilista' },
  { id: '377145 Pugilista de luta livre', descricao: 'Pugilista de luta livre' },
  { id: '723220 Pulverizador de metal, à pistola', descricao: 'Pulverizador de metal, à pistola' },
  { id: '632420 Pupunheiro', descricao: 'Pupunheiro' },
  { id: '622210 Puxador de sisal - na cultura', descricao: 'Puxador de sisal - na cultura' },
  { id: '848520 Quarteador em matadouro', descricao: 'Quarteador em matadouro' },
  { id: '848525 Quarteiro em matadouro', descricao: 'Quarteiro em matadouro' },
  { id: '622605 Quebrador - na cultura de cacau', descricao: 'Quebrador - na cultura de cacau' },
  { id: '711115 Quebrador - na extração de pedras', descricao: 'Quebrador - na extração de pedras' },
  { id: '622725 Quebrador de mamona', descricao: 'Quebrador de mamona' },
  { id: '632355 Quebrador de ouricuri', descricao: 'Quebrador de ouricuri' },
  { id: '848210 Queijeiro na fabricação de laticínio', descricao: 'Queijeiro na fabricação de laticínio' },
  { id: '828110 Queimador - em olaria', descricao: 'Queimador - em olaria' },
  { id: '631330 Quelonicultor', descricao: 'Quelonicultor' },
  { id: '213205 Químico', descricao: 'Químico' },
  { id: '213205 Químico (fisico-química)', descricao: 'Químico (fisico-química)' },
  { id: '213210 Químico (produtos aromáticos)', descricao: 'Químico (produtos aromáticos)' },
  { id: '213205 Químico (química inorgânica)', descricao: 'Químico (química inorgânica)' },
  { id: '213205 Químico (química nuclear)', descricao: 'Químico (química nuclear)' },
  { id: '213205 Químico (química orgânica)', descricao: 'Químico (química orgânica)' },
  { id: '213205 Químico (tratamento de água)', descricao: 'Químico (tratamento de água)' },
  { id: '213205 Químico agrícola', descricao: 'Químico agrícola' },
  { id: '213205 Químico ambiental', descricao: 'Químico ambiental' },
  { id: '213205 Químico analista', descricao: 'Químico analista' },
  { id: '213205 Químico bromatologista', descricao: 'Químico bromatologista' },
  { id: '213205 Químico consultor', descricao: 'Químico consultor' },
  { id: '213210 Químico cosmetólogo', descricao: 'Químico cosmetólogo' },
  { id: '213205 Químico de alimentos', descricao: 'Químico de alimentos' },
  { id: '213210 Químico de cerâmica', descricao: 'Químico de cerâmica' },
  { id: '213205 Químico de controle de qualidade', descricao: 'Químico de controle de qualidade' },
  { id: '213210 Químico de curtume', descricao: 'Químico de curtume' },
  { id: '213205 Químico de embalagens', descricao: 'Químico de embalagens' },
  { id: '213205 Químico de laboratório', descricao: 'Químico de laboratório' },
  { id: '213205 Químico de laboratório de controle', descricao: 'Químico de laboratório de controle' },
  { id: '213205 Químico de laboratórios de solos', descricao: 'Químico de laboratórios de solos' },
  { id: '213210 Químico de petróleo', descricao: 'Químico de petróleo' },
  { id: '213210 Químico de polímeros', descricao: 'Químico de polímeros' },
  { id: '213210 Químico de processos', descricao: 'Químico de processos' },
  { id: '213210 Químico de produtos naturais', descricao: 'Químico de produtos naturais' },
  { id: '213210 Químico galvanoplasta', descricao: 'Químico galvanoplasta' },
  { id: '213210 Químico industrial', descricao: 'Químico industrial' },
  { id: '213210 Químico perfumista', descricao: 'Químico perfumista' },
  { id: '213210 Químico têxtil', descricao: 'Químico têxtil' },
  { id: '2132 QUÍMICOS', descricao: 'QUÍMICOS' },
  { id: '516805 Quirólogo', descricao: 'Quirólogo' },
  { id: '516805 Quiromante', descricao: 'Quiromante' },
  { id: '226105 Quiropraxista', descricao: 'Quiropraxista' },
  { id: '141410 Quitandeiro (comércio varejista)', descricao: 'Quitandeiro (comércio varejista)' },
  { id: '263105 Rabino', descricao: 'Rabino' },
  { id: '762125 Rachador de couros e peles', descricao: 'Rachador de couros e peles' },
  { id: '632605 Rachador de lenha para carvão', descricao: 'Rachador de lenha para carvão' },
  { id: '261730 Rádio repórter', descricao: 'Rádio repórter' },
  { id: '262505 Rádio-ator', descricao: 'Rádio-ator' },
  { id: '225330 Radio-oncologista', descricao: 'Radio-oncologista' },
  { id: '516805 Radioestesista', descricao: 'Radioestesista' },
  { id: '223445 Radiofarmacêutico', descricao: 'Radiofarmacêutico' },
  { id: '261125 Radiojornalista', descricao: 'Radiojornalista' },
  { id: '225320 Radiologista', descricao: 'Radiologista' },
  { id: '225355 Radiologista intervencionista não vascular', descricao: 'Radiologista intervencionista não vascular' },
  { id: '225355 Radiologista intervencionista percutâneo', descricao: 'Radiologista intervencionista percutâneo' },
  { id: '225355 Radiologista intervencionista vascular', descricao: 'Radiologista intervencionista vascular' },
  { id: '422220 Radioperador', descricao: 'Radioperador' },
  { id: '954210 Radiotécnico', descricao: 'Radiotécnico' },
  { id: '372210 Radiotelegrafista', descricao: 'Radiotelegrafista' },
  { id: '225330 Radioterapeuta', descricao: 'Radioterapeuta' },
  { id: '632010 Raizeiro', descricao: 'Raizeiro' },
  { id: '841115 Ralador de mandioca', descricao: 'Ralador de mandioca' },
  { id: '612220 Ramicultor', descricao: 'Ramicultor' },
  { id: '613420 Rancheiro', descricao: 'Rancheiro' },
  { id: '622005 Rancheiro - na cultura', descricao: 'Rancheiro - na cultura' },
  { id: '631335 Ranicultor', descricao: 'Ranicultor' },
  { id: '716605 Raspador', descricao: 'Raspador' },
  { id: '716520 Raspador de taco', descricao: 'Raspador de taco' },
  { id: '848525 Raspador de tripas em matadouro', descricao: 'Raspador de tripas em matadouro' },
  { id: '725020 Rasqueteador de metais', descricao: 'Rasqueteador de metais' },
  { id: '715205 Rasteleiro de asfalto', descricao: 'Rasteleiro de asfalto' },
  { id: '711120 Rastilheiro', descricao: 'Rastilheiro' },
  { id: '951310 Rastreador de satélite', descricao: 'Rastreador de satélite' },
  { id: '223256 Reabilitador oral', descricao: 'Reabilitador oral' },
  { id: '762220 Rebaixador de base (couros)', descricao: 'Rebaixador de base (couros)' },
  { id: '762220 Rebaixador de couros', descricao: 'Rebaixador de couros' },
  { id: '821450 Rebarbador de metal', descricao: 'Rebarbador de metal' },
  { id: '724230 Rebatedor de metais', descricao: 'Rebatedor de metais' },
  { id: '724215 Rebitador a martelo pneumático', descricao: 'Rebitador a martelo pneumático' },
  { id: '724230 Rebitador, a mão', descricao: 'Rebitador, a mão' },
  { id: '832135 Rebobinador na fabricação de papel e papelão', descricao: 'Rebobinador na fabricação de papel e papelão' },
  { id: '768205 Rebordadeira', descricao: 'Rebordadeira' },
  { id: '421205 Recebedor de apostas (loteria)', descricao: 'Recebedor de apostas (loteria)' },
  { id: '421210 Recebedor de apostas (turfe)', descricao: 'Recebedor de apostas (turfe)' },
  { id: '424105 Recenseador', descricao: 'Recenseador' },
  { id: '422105 Recepcionista atendente', descricao: 'Recepcionista atendente' },
  { id: '422105 Recepcionista auxiliar de secretária', descricao: 'Recepcionista auxiliar de secretária' },
  { id: '422105 Recepcionista bilíngüe', descricao: 'Recepcionista bilíngüe' },
  { id: '422120 Recepcionista caixa', descricao: 'Recepcionista caixa' },
  { id: '422125 Recepcionista de banco', descricao: 'Recepcionista de banco' },
  { id: '519945 Recepcionista de casas de espetáculos', descricao: 'Recepcionista de casas de espetáculos' },
  { id: '422110 Recepcionista de consultório médico ou dentário', descricao: 'Recepcionista de consultório médico ou dentário' },
  { id: '422105 Recepcionista de crediário', descricao: 'Recepcionista de crediário' },
  { id: '422105 Recepcionista de empresa de navegação aérea', descricao: 'Recepcionista de empresa de navegação aérea' },
  { id: '422110 Recepcionista de hospital', descricao: 'Recepcionista de hospital' },
  { id: '422120 Recepcionista de hotel', descricao: 'Recepcionista de hotel' },
  { id: '422115 Recepcionista de seguro saúde', descricao: 'Recepcionista de seguro saúde' },
  { id: '422105 Recepcionista secretária', descricao: 'Recepcionista secretária' },
  { id: '422105 Recepcionista telefonista', descricao: 'Recepcionista telefonista' },
  { id: '422105 Recepcionista vendedor de passagens aéreas', descricao: 'Recepcionista vendedor de passagens aéreas' },
  { id: '422105 Recepcionista, em geral', descricao: 'Recepcionista, em geral' },
  { id: '4221 RECEPCIONISTAS', descricao: 'RECEPCIONISTAS' },
  { id: '764120 Recortador de palmilhas', descricao: 'Recortador de palmilhas' },
  { id: '723110 Recozedor e normalizador de metais', descricao: 'Recozedor e normalizador de metais' },
  { id: '371410 Recreacionista', descricao: 'Recreacionista' },
  { id: '371410 Recreador', descricao: 'Recreador' },
  { id: '371405 Recreador de acantonamento', descricao: 'Recreador de acantonamento' },
  { id: '3714 RECREADORES', descricao: 'RECREADORES' },
  { id: '421310 Recuperador de ativos', descricao: 'Recuperador de ativos' },
  { id: '421310 Recuperador de crédito', descricao: 'Recuperador de crédito' },
  { id: '821335 Recuperador de guias e cilindros', descricao: 'Recuperador de guias e cilindros' },
  { id: '421305 Recuperador externo', descricao: 'Recuperador externo' },
  { id: '421310 Recuperador interno', descricao: 'Recuperador interno' },
  { id: '261530 Redator de anais', descricao: 'Redator de anais' },
  { id: '261530 Redator de jornal', descricao: 'Redator de jornal' },
  { id: '261530 Redator de manuais técnicos', descricao: 'Redator de manuais técnicos' },
  { id: '253110 Redator de publicidade', descricao: 'Redator de publicidade' },
  { id: '261530 Redator de textos científicos', descricao: 'Redator de textos científicos' },
  { id: '261530 Redator de textos comerciais', descricao: 'Redator de textos comerciais' },
  { id: '261530 Redator de textos técnicos', descricao: 'Redator de textos técnicos' },
  { id: '768120 Redeiro', descricao: 'Redeiro' },
  { id: '631420 Redeiro (pesca)', descricao: 'Redeiro (pesca)' },
  { id: '766320 Refilador gráfico', descricao: 'Refilador gráfico' },
  { id: '841472 Refinador de óleo e gordura', descricao: 'Refinador de óleo e gordura' },
  { id: '831105 Refinador de pasta/celulose para fabricação de papel', descricao: 'Refinador de pasta/celulose para fabricação de papel' },
  { id: '841210 Refinador de sal', descricao: 'Refinador de sal' },
  { id: '222120 Reflorestador', descricao: 'Reflorestador' },
  { id: '765235 Reformador de móveis', descricao: 'Reformador de móveis' },
  { id: '911320 Reformador de pentes (máquinas têxteis)', descricao: 'Reformador de pentes (máquinas têxteis)' },
  { id: '763015 Reformadora de roupas', descricao: 'Reformadora de roupas' },
  { id: '715720 Refratarista (caldeira e tubulações)', descricao: 'Refratarista (caldeira e tubulações)' },
  { id: '715220 Refratarista (pedreiro)', descricao: 'Refratarista (pedreiro)' },
  { id: '622010 Regador - na cultura', descricao: 'Regador - na cultura' },
  { id: '262615 Regente assistente', descricao: 'Regente assistente' },
  { id: '262615 Regente auxiliar', descricao: 'Regente auxiliar' },
  { id: '262615 Regente de banda', descricao: 'Regente de banda' },
  { id: '262615 Regente de coral', descricao: 'Regente de coral' },
  { id: '262615 Regente de orquestra', descricao: 'Regente de orquestra' },
  { id: '262615 Regente interno', descricao: 'Regente interno' },
  { id: '241310 Registrador civil de pessoas jurídicas', descricao: 'Registrador civil de pessoas jurídicas' },
  { id: '241315 Registrador civil de pessoas naturais', descricao: 'Registrador civil de pessoas naturais' },
  { id: '415305 Registrador de câncer', descricao: 'Registrador de câncer' },
  { id: '241305 Registrador de contratos marítimos', descricao: 'Registrador de contratos marítimos' },
  { id: '241330 Registrador de títulos e documentos', descricao: 'Registrador de títulos e documentos' },
  { id: '241325 Registrador imobiliário', descricao: 'Registrador imobiliário' },
  { id: '351730 Regulador de sinistros', descricao: 'Regulador de sinistros' },
  { id: '263105 Reitor', descricao: 'Reitor' },
  { id: '716540 Rejuntador (construção civil)', descricao: 'Rejuntador (construção civil)' },
  { id: '716540 Rejuntador cerâmico', descricao: 'Rejuntador cerâmico' },
  { id: '716540 Rejuntador de revestimentos', descricao: 'Rejuntador de revestimentos' },
  { id: '142325 Relações públicas', descricao: 'Relações públicas' },
  { id: '263105 Religiosa', descricao: 'Religiosa' },
  { id: '263105 Religioso leigo', descricao: 'Religioso leigo' },
  { id: '141410 Relojoeiro (comércio varejista)', descricao: 'Relojoeiro (comércio varejista)' },
  { id: '741120 Relojoeiro (fabricação)', descricao: 'Relojoeiro (fabricação)' },
  { id: '741125 Relojoeiro (reparação)', descricao: 'Relojoeiro (reparação)' },
  { id: '631020 Remador, na pesca de peixes e camarões', descricao: 'Remador, na pesca de peixes e camarões' },
  { id: '768210 Remendeira', descricao: 'Remendeira' },
  { id: '761363 Remetedor de fios', descricao: 'Remetedor de fios' },
  { id: '621005 Rendeiro na agropecuária - exclusive conta própria e empregador', descricao: 'Rendeiro na agropecuária - exclusive conta própria e empregador' },
  { id: '731330 Reparador de aparelhos de telecomunicações em laboratório', descricao: 'Reparador de aparelhos de telecomunicações em laboratório' },
  { id: '954205 Reparador de aparelhos eletrodomésticos (exceto imagem e som)', descricao: 'Reparador de aparelhos eletrodomésticos (exceto imagem e som)' },
  { id: '919310 Reparador de bicicletas', descricao: 'Reparador de bicicletas' },
  { id: '914410 Reparador de empilhadeiras', descricao: 'Reparador de empilhadeiras' },
  { id: '954305 Reparador de equipamentos de escritório', descricao: 'Reparador de equipamentos de escritório' },
  { id: '300305 Reparador de equipamentos elétricos e eletrônicos', descricao: 'Reparador de equipamentos elétricos e eletrônicos' },
  { id: '915405 Reparador de equipamentos fotográficos', descricao: 'Reparador de equipamentos fotográficos' },
  { id: '731320 Reparador de instalações telefônicas', descricao: 'Reparador de instalações telefônicas' },
  { id: '915210 Reparador de instrumentos musicais', descricao: 'Reparador de instrumentos musicais' },
  { id: '732120 Reparador de linhas elétricas', descricao: 'Reparador de linhas elétricas' },
  { id: '731320 Reparador de linhas e aparelhos', descricao: 'Reparador de linhas e aparelhos' },
  { id: '911305 Reparador de máquinas', descricao: 'Reparador de máquinas' },
  { id: '731320 Reparador de pabx', descricao: 'Reparador de pabx' },
  { id: '954210 Reparador de rádio, tv e som', descricao: 'Reparador de rádio, tv e som' },
  { id: '731320 Reparador de telefone', descricao: 'Reparador de telefone' },
  { id: '914420 Reparador de tratores', descricao: 'Reparador de tratores' },
  { id: '914405 Reparador de veículos automotores', descricao: 'Reparador de veículos automotores' },
  { id: '313205 Reparador técnico', descricao: 'Reparador técnico' },
  { id: '9542 REPARADORES DE APARELHOS ELETRODOMÉSTICOS', descricao: 'REPARADORES DE APARELHOS ELETRODOMÉSTICOS' },
  { id: '9913 REPARADORES DE CARROCERIAS DE VEÍCULOS', descricao: 'REPARADORES DE CARROCERIAS DE VEÍCULOS' },
  { id: '9543 REPARADORES DE EQUIPAMENTOS DE ESCRITÓRIO', descricao: 'REPARADORES DE EQUIPAMENTOS DE ESCRITÓRIO' },
  { id: '9154 REPARADORES DE EQUIPAMENTOS FOTOGRÁFICOS', descricao: 'REPARADORES DE EQUIPAMENTOS FOTOGRÁFICOS' },
  { id: '623125 Repassador - na pecuária', descricao: 'Repassador - na pecuária' },
  { id: '261135 Repórter (exclusive rádio e televisão)', descricao: 'Repórter (exclusive rádio e televisão)' },
  { id: '261135 Repórter cinematográfico', descricao: 'Repórter cinematográfico' },
  { id: '261135 Repórter correspondente', descricao: 'Repórter correspondente' },
  { id: '261135 Repórter de área', descricao: 'Repórter de área' },
  { id: '261730 Repórter de mídias audiovisuais', descricao: 'Repórter de mídias audiovisuais' },
  { id: '261730 Repórter de rádio', descricao: 'Repórter de rádio' },
  { id: '261730 Repórter de rádio e tv', descricao: 'Repórter de rádio e tv' },
  { id: '261730 Repórter de televisão', descricao: 'Repórter de televisão' },
  { id: '261135 Repórter de web', descricao: 'Repórter de web' },
  { id: '261135 Repórter especial', descricao: 'Repórter especial' },
  { id: '261820 Repórter fotográfico', descricao: 'Repórter fotográfico' },
  { id: '521125 Repositor - em supermercados', descricao: 'Repositor - em supermercados' },
  { id: '521125 Repositor de mercadorias', descricao: 'Repositor de mercadorias' },
  { id: '354705 Representante comercial autônomo', descricao: 'Representante comercial autônomo' },
  { id: '421305 Representante de cobrança', descricao: 'Representante de cobrança' },
  { id: '351725 Representante de controle de perdas', descricao: 'Representante de controle de perdas' },
  { id: '354150 Representante de produtos farmacêuticos', descricao: 'Representante de produtos farmacêuticos' },
  { id: '354135 Representante técnico de vendas', descricao: 'Representante técnico de vendas' },
  { id: '3547 REPRESENTANTES COMERCIAIS AUTÔNOMOS', descricao: 'REPRESENTANTES COMERCIAIS AUTÔNOMOS' },
  { id: '766115 Reprodutor de clichê impressão (reprodução plástica)', descricao: 'Reprodutor de clichê impressão (reprodução plástica)' },
  { id: '766145 Reprodutor de desenhos sobre pedras litográficas', descricao: 'Reprodutor de desenhos sobre pedras litográficas' },
  { id: '515135 Resgatista', descricao: 'Resgatista' },
  { id: '515135 Resgatista socorrista', descricao: 'Resgatista socorrista' },
  { id: '622210 Resideiro de sisal', descricao: 'Resideiro de sisal' },
  { id: '725030 Responsável operacional de manutenção e recarga de extintor de incêndio', descricao: 'Responsável operacional de manutenção e recarga de extintor de incêndio' },
  { id: '201210 Responsável técnico da calibração metrológica', descricao: 'Responsável técnico da calibração metrológica' },
  { id: '201215 Responsável técnico de ensaios metrológicos', descricao: 'Responsável técnico de ensaios metrológicos' },
  { id: '214115 Restaurador de edificações', descricao: 'Restaurador de edificações' },
  { id: '915205 Restaurador de instrumentos musicais (exceto cordas arcadas)', descricao: 'Restaurador de instrumentos musicais (exceto cordas arcadas)' },
  { id: '768710 Restaurador de livros', descricao: 'Restaurador de livros' },
  { id: '768710 Restaurador de livros e documentos', descricao: 'Restaurador de livros e documentos' },
  { id: '262415 Restaurador de obras de arte', descricao: 'Restaurador de obras de arte' },
  { id: '9152 RESTAURADORES DE INSTRUMENTOS MUSICAIS', descricao: 'RESTAURADORES DE INSTRUMENTOS MUSICAIS' },
  { id: '848525 Retalhador de carne', descricao: 'Retalhador de carne' },
  { id: '141410 Retalhista (comércio varejista)', descricao: 'Retalhista (comércio varejista)' },
  { id: '721425 Retificador cnc', descricao: 'Retificador cnc' },
  { id: '721220 Retificador (retificadora cilíndrica externa e interna)', descricao: 'Retificador (retificadora cilíndrica externa e interna)' },
  { id: '721220 Retificador (retificadora de árvore de manivelas)', descricao: 'Retificador (retificadora de árvore de manivelas)' },
  { id: '721220 Retificador (retificadora plana)', descricao: 'Retificador (retificadora plana)' },
  { id: '721220 Retificador de fieiras', descricao: 'Retificador de fieiras' },
  { id: '914405 Retificador de motores a álcool', descricao: 'Retificador de motores a álcool' },
  { id: '914405 Retificador de motores a diesel', descricao: 'Retificador de motores a diesel' },
  { id: '914405 Retificador de motores a gasolina', descricao: 'Retificador de motores a gasolina' },
  { id: '914405 Retificador de motores de automóveis', descricao: 'Retificador de motores de automóveis' },
  { id: '914405 Retificador de motores de carros', descricao: 'Retificador de motores de carros' },
  { id: '914405 Retificador de motores de explosão', descricao: 'Retificador de motores de explosão' },
  { id: '914405 Retificador de motores de veículos', descricao: 'Retificador de motores de veículos' },
  { id: '721220 Retificador, em geral', descricao: 'Retificador, em geral' },
  { id: '623120 Retireiro', descricao: 'Retireiro' },
  { id: '623205 Retireiro (caprinos)', descricao: 'Retireiro (caprinos)' },
  { id: '623115 Retireiro - na pecuária', descricao: 'Retireiro - na pecuária' },
  { id: '623115 Retireiro inseminador', descricao: 'Retireiro inseminador' },
  { id: '766150 Retocador convencional', descricao: 'Retocador convencional' },
  { id: '764305 Retocador de calçados', descricao: 'Retocador de calçados' },
  { id: '766145 Retocador de chapas de impressão (fotogravura)', descricao: 'Retocador de chapas de impressão (fotogravura)' },
  { id: '716610 Retocador de pintura', descricao: 'Retocador de pintura' },
  { id: '766415 Retoquista (revelação fotográfica)', descricao: 'Retoquista (revelação fotográfica)' },
  { id: '261815 Retratista', descricao: 'Retratista' },
  { id: '225136 Reumatologista', descricao: 'Reumatologista' },
  { id: '766415 Revelador de filmes fotográficos, em cores', descricao: 'Revelador de filmes fotográficos, em cores' },
  { id: '766410 Revelador de filmes fotográficos, em preto e branco', descricao: 'Revelador de filmes fotográficos, em preto e branco' },
  { id: '263105 Reverendo', descricao: 'Reverendo' },
  { id: '716615 Revestidor de interiores (papel e material plástico)', descricao: 'Revestidor de interiores (papel e material plástico)' },
  { id: '716615 Revestidor de interiores (papel, material plástico e emborrachados)', descricao: 'Revestidor de interiores (papel, material plástico e emborrachados)' },
  { id: '775110 Revestidor de móveis de madeira', descricao: 'Revestidor de móveis de madeira' },
  { id: '716110 Revestidor de superfícies de concreto', descricao: 'Revestidor de superfícies de concreto' },
  { id: '7161 REVESTIDORES DE CONCRETO', descricao: 'REVESTIDORES DE CONCRETO' },
  { id: '766145 Revisor - na indústria gráfica', descricao: 'Revisor - na indústria gráfica' },
  { id: '413110 Revisor contábil', descricao: 'Revisor contábil' },
  { id: '731150 Revisor de aparelhos eletrônicos', descricao: 'Revisor de aparelhos eletrônicos' },
  { id: '731320 Revisor de aparelhos telefônicos', descricao: 'Revisor de aparelhos telefônicos' },
  { id: '391215 Revisor de equipamentos (controle de qualidade)', descricao: 'Revisor de equipamentos (controle de qualidade)' },
  { id: '761810 Revisor de fios (produção têxtil)', descricao: 'Revisor de fios (produção têxtil)' },
  { id: '731180 Revisor de linha de montagem (aparelhos eletrônicos)', descricao: 'Revisor de linha de montagem (aparelhos eletrônicos)' },
  { id: '763315 Revisor de produção de peças para bordar', descricao: 'Revisor de produção de peças para bordar' },
  { id: '764305 Revisor de qualidade de calçados', descricao: 'Revisor de qualidade de calçados' },
  { id: '761805 Revisor de silk-screen', descricao: 'Revisor de silk-screen' },
  { id: '761815 Revisor de tecidos acabados', descricao: 'Revisor de tecidos acabados' },
  { id: '761820 Revisor de tecidos crus', descricao: 'Revisor de tecidos crus' },
  { id: '261140 Revisor de texto', descricao: 'Revisor de texto' },
  { id: '766145 Revisor gráfico', descricao: 'Revisor gráfico' },
  { id: '761815 Revisor têxtil', descricao: 'Revisor têxtil' },
  { id: '263105 Rimban (reitor de templo provincial)', descricao: 'Rimban (reitor de templo provincial)' },
  { id: '752410 Riscador de copos', descricao: 'Riscador de copos' },
  { id: '724225 Riscador de estruturas de aço', descricao: 'Riscador de estruturas de aço' },
  { id: '724225 Riscador de estruturas metálicas', descricao: 'Riscador de estruturas metálicas' },
  { id: '764120 Riscador de palmilhas', descricao: 'Riscador de palmilhas' },
  { id: '763120 Riscador de roupas', descricao: 'Riscador de roupas' },
  { id: '763120 Riscador de tecidos', descricao: 'Riscador de tecidos' },
  { id: '612105 Rizicultor', descricao: 'Rizicultor' },
  { id: '622105 Rizicultor - conta própria', descricao: 'Rizicultor - conta própria' },
  { id: '622105 Rizicultor - empregador', descricao: 'Rizicultor - empregador' },
  { id: '612105 Rizipiscicultor', descricao: 'Rizipiscicultor' },
  { id: '374110 Roadie (assistente de palco)', descricao: 'Roadie (assistente de palco)' },
  { id: '781110 Robotista (soldagem)', descricao: 'Robotista (soldagem)' },
  { id: '622020 Roçador - na cultura', descricao: 'Roçador - na cultura' },
  { id: '621005 Roceiro - na agropecuária - exclusive conta própria e empregador', descricao: 'Roceiro - na agropecuária - exclusive conta própria e empregador' },
  { id: '611005 Roceiro na agropecuária - conta própria', descricao: 'Roceiro na agropecuária - conta própria' },
  { id: '519920 Rodoboy', descricao: 'Rodoboy' },
  { id: '261515 Romancista', descricao: 'Romancista' },
  { id: '632110 Romaneador', descricao: 'Romaneador' },
  { id: '517330 Ronda - organizações particulares de segurança', descricao: 'Ronda - organizações particulares de segurança' },
  { id: '622020 Ronda de formiga (combate às formigas)', descricao: 'Ronda de formiga (combate às formigas)' },
  { id: '517330 Rondante - organizações particulares de segurança', descricao: 'Rondante - organizações particulares de segurança' },
  { id: '517235 Rondante de guarda portuária', descricao: 'Rondante de guarda portuária' },
  { id: '263105 Roshi', descricao: 'Roshi' },
  { id: '261125 Roteirista de jornal', descricao: 'Roteirista de jornal' },
  { id: '261125 Roteirista na imprensa', descricao: 'Roteirista na imprensa' },
  { id: '766145 Rotogravador', descricao: 'Rotogravador' },
  { id: '784115 Rotulador', descricao: 'Rotulador' },
  { id: '513325 Roupeiro de cinema', descricao: 'Roupeiro de cinema' },
  { id: '513305 Roupeiro de teatro', descricao: 'Roupeiro de teatro' },
  { id: '513310 Roupeiro de televisão', descricao: 'Roupeiro de televisão' },
  { id: '516805 Rumenal', descricao: 'Rumenal' },
  { id: '828110 Sabugueiro', descricao: 'Sabugueiro' },
  { id: '263105 Sacerdote', descricao: 'Sacerdote' },
  { id: '263105 Sacerdotisa', descricao: 'Sacerdotisa' },
  { id: '514115 Sacristão', descricao: 'Sacristão' },
  { id: '622020 Safrista', descricao: 'Safrista' },
  { id: '513505 Saladeiro', descricao: 'Saladeiro' },
  { id: '848115 Salameiro na conservação de alimentos', descricao: 'Salameiro na conservação de alimentos' },
  { id: '513505 Salgadeiro', descricao: 'Salgadeiro' },
  { id: '848110 Salgador - em charqueada', descricao: 'Salgador - em charqueada' },
  { id: '848110 Salgador de alimentos', descricao: 'Salgador de alimentos' },
  { id: '848110 Salgador de pescados', descricao: 'Salgador de pescados' },
  { id: '848110 Salgador de tripas', descricao: 'Salgador de tripas' },
  { id: '141205 Salineiro (empregador)', descricao: 'Salineiro (empregador)' },
  { id: '711410 Salineiro (sal marinho)', descricao: 'Salineiro (sal marinho)' },
  { id: '141205 Salineiro - empregador', descricao: 'Salineiro - empregador' },
  { id: '141205 Salineiro - na extração - empregador', descricao: 'Salineiro - na extração - empregador' },
  { id: '848110 Salmorador na conservação de alimentos', descricao: 'Salmorador na conservação de alimentos' },
  { id: '848115 Salsicheiro (fabricação de lingüiça, salsicha e produtos similares)', descricao: 'Salsicheiro (fabricação de lingüiça, salsicha e produtos similares)' },
  { id: '848115 Salsicheiros na conservação de alimentos', descricao: 'Salsicheiros na conservação de alimentos' },
  { id: '376205 Saltador', descricao: 'Saltador' },
  { id: '376215 Saltimbanco', descricao: 'Saltimbanco' },
  { id: '517115 Salva-surf', descricao: 'Salva-surf' },
  { id: '517115 Salva-vidas', descricao: 'Salva-vidas' },
  { id: '376105 Sambista', descricao: 'Sambista' },
  { id: '848505 Sangrador (magarefe)', descricao: 'Sangrador (magarefe)' },
  { id: '131225 Sanitarista', descricao: 'Sanitarista' },
  { id: '768320 Sapateiro (calçados sob medida)', descricao: 'Sapateiro (calçados sob medida)' },
  { id: '141410 Sapateiro (comércio varejista)', descricao: 'Sapateiro (comércio varejista)' },
  { id: '768320 Sapateiro (confecção de calçados)', descricao: 'Sapateiro (confecção de calçados)' },
  { id: '768320 Sapateiro (sapatos de balé)', descricao: 'Sapateiro (sapatos de balé)' },
  { id: '768320 Sapateiro de confecção sob medida', descricao: 'Sapateiro de confecção sob medida' },
  { id: '768320 Sapateiro montador', descricao: 'Sapateiro montador' },
  { id: '322505 Sapateiro ortopédico', descricao: 'Sapateiro ortopédico' },
  { id: '31110 Sargento bombeiro militar', descricao: 'Sargento bombeiro militar' },
  { id: '21110 Sargento da policia militar', descricao: 'Sargento da policia militar' },
  { id: '31110 Sargento do corpo de bombeiros militar', descricao: 'Sargento do corpo de bombeiros militar' },
  { id: '722325 Secador de areia', descricao: 'Secador de areia' },
  { id: '622105 Secador de arroz', descricao: 'Secador de arroz' },
  { id: '761110 Secador de lã', descricao: 'Secador de lã' },
  { id: '772115 Secador de madeira', descricao: 'Secador de madeira' },
  { id: '841735 Secador de malte', descricao: 'Secador de malte' },
  { id: '351505 Secretária (técnico em secretariado - português)', descricao: 'Secretária (técnico em secretariado - português)' },
  { id: '252315 Secretária trilíngüe', descricao: 'Secretária trilíngüe' },
  { id: '252305 Secretária(o) executiva(o)', descricao: 'Secretária(o) executiva(o)' },
  { id: '2523 SECRETÁRIAS(OS) EXECUTIVAS(OS) E AFINS', descricao: 'SECRETÁRIAS(OS) EXECUTIVAS(OS) E AFINS' },
  { id: '252310 Secretário bilíngüe', descricao: 'Secretário bilíngüe' },
  { id: '252310 Secretário bilíngüe de diretoria', descricao: 'Secretário bilíngüe de diretoria' },
  { id: '252310 Secretário bilíngüe de gabinete', descricao: 'Secretário bilíngüe de gabinete' },
  { id: '252310 Secretário bilíngüe de presidência', descricao: 'Secretário bilíngüe de presidência' },
  { id: '252305 Secretário de diretoria', descricao: 'Secretário de diretoria' },
  { id: '252305 Secretário de gabinete', descricao: 'Secretário de gabinete' },
  { id: '252305 Secretário de presidência', descricao: 'Secretário de presidência' },
  { id: '252305 Secretário pleno', descricao: 'Secretário pleno' },
  { id: '252310 Secretário pleno bilíngüe', descricao: 'Secretário pleno bilíngüe' },
  { id: '252315 Secretário pleno trilíngüe', descricao: 'Secretário pleno trilíngüe' },
  { id: '252310 Secretário senior bilíngüe', descricao: 'Secretário senior bilíngüe' },
  { id: '252315 Secretário sênior trilíngüe', descricao: 'Secretário sênior trilíngüe' },
  { id: '252315 Secretário trilíngüe de diretoria', descricao: 'Secretário trilíngüe de diretoria' },
  { id: '252315 Secretário trilíngüe de gabinete', descricao: 'Secretário trilíngüe de gabinete' },
  { id: '252315 Secretário trilíngüe de presidência', descricao: 'Secretário trilíngüe de presidência' },
  { id: '111405 Secretário (diplomacia)', descricao: 'Secretário (diplomacia)' },
  { id: '351505 Secretário (técnico de nível médio)', descricao: 'Secretário (técnico de nível médio)' },
  { id: '111220 Secretário - executivo', descricao: 'Secretário - executivo' },
  { id: '252320 Secretário de escola(tecnólogo)', descricao: 'Secretário de escola(tecnólogo)' },
  { id: '111405 Secretário de estado (serviço público federal)', descricao: 'Secretário de estado (serviço público federal)' },
  { id: '252320 Secretário escolar(tecnólogo)', descricao: 'Secretário escolar(tecnólogo)' },
  { id: '111410 Secretário estadual e distrital (serviço público estadual e distrital)', descricao: 'Secretário estadual e distrital (serviço público estadual e distrital)' },
  { id: '111415 Secretário municipal (serviço público municipal)', descricao: 'Secretário municipal (serviço público municipal)' },
  { id: '252305 Secretário sênior', descricao: 'Secretário sênior' },
  { id: '351505 Secretário-assistente administrativo (técnico)', descricao: 'Secretário-assistente administrativo (técnico)' },
  { id: '114305 Secretário-executivo de entidade religiosa', descricao: 'Secretário-executivo de entidade religiosa' },
  { id: '354505 Securitário', descricao: 'Securitário' },
  { id: '215215 Segundo oficial de máquinas da marinha mercante', descricao: 'Segundo oficial de máquinas da marinha mercante' },
  { id: '215140 Segundo oficial de náutica', descricao: 'Segundo oficial de náutica' },
  { id: '215115 Segundo oficial de náutica (comandante)', descricao: 'Segundo oficial de náutica (comandante)' },
  { id: '215125 Segundo oficial de náutica (imediato)', descricao: 'Segundo oficial de náutica (imediato)' },
  { id: '20310 Segundo tenente de polícia militar', descricao: 'Segundo tenente de polícia militar' },
  { id: '10310 Segundo-sargento', descricao: 'Segundo-sargento' },
  { id: '10305 Segundo-sargento da aeronáutica', descricao: 'Segundo-sargento da aeronáutica' },
  { id: '10315 Segundo-sargento da marinha', descricao: 'Segundo-sargento da marinha' },
  { id: '30305 Segundo-tenente bombeiro militar', descricao: 'Segundo-tenente bombeiro militar' },
  { id: '10205 Segundo-tenente da aeronáutica', descricao: 'Segundo-tenente da aeronáutica' },
  { id: '10215 Segundo-tenente da marinha', descricao: 'Segundo-tenente da marinha' },
  { id: '30305 Segundo-tenente do corpo de bombeiros', descricao: 'Segundo-tenente do corpo de bombeiros' },
  { id: '10210 Segundo-tenente do exército', descricao: 'Segundo-tenente do exército' },
  { id: '517310 Segurança comunitário', descricao: 'Segurança comunitário' },
  { id: '517310 Segurança de evento', descricao: 'Segurança de evento' },
  { id: '517310 Segurança pessoal', descricao: 'Segurança pessoal' },
  { id: '784115 Seladeira', descricao: 'Seladeira' },
  { id: '848405 Selecionador de café', descricao: 'Selecionador de café' },
  { id: '764305 Selecionador de calçados', descricao: 'Selecionador de calçados' },
  { id: '632410 Selecionador de castanha de caju', descricao: 'Selecionador de castanha de caju' },
  { id: '415210 Selecionador de correspondências (correios e telégrafos)', descricao: 'Selecionador de correspondências (correios e telégrafos)' },
  { id: '519210 Selecionador de material reciclável', descricao: 'Selecionador de material reciclável' },
  { id: '623325 Selecionador de pintos por sexo', descricao: 'Selecionador de pintos por sexo' },
  { id: '821445 Selecionador de sucatas', descricao: 'Selecionador de sucatas' },
  { id: '622020 Selecionador e embalador de colheitas agrícolas', descricao: 'Selecionador e embalador de colheitas agrícolas' },
  { id: '768325 Seleiro', descricao: 'Seleiro' },
  { id: '141410 Seleiro (comércio varejista)', descricao: 'Seleiro (comércio varejista)' },
  { id: '768325 Seleiro (equipamentos para equitação)', descricao: 'Seleiro (equipamentos para equitação)' },
  { id: '141205 Seleiro - na fabricação - empregador', descricao: 'Seleiro - na fabricação - empregador' },
  { id: '768325 Seleiro na fabricação', descricao: 'Seleiro na fabricação' },
  { id: '622020 Semeador', descricao: 'Semeador' },
  { id: '612120 Sementeiro', descricao: 'Sementeiro' },
  { id: '622020 Sementeiro - na cultura', descricao: 'Sementeiro - na cultura' },
  { id: '263105 Seminarista', descricao: 'Seminarista' },
  { id: '111105 Senador', descricao: 'Senador' },
  { id: '415210 Separador de cargas postais (correios e telégrafos)', descricao: 'Separador de cargas postais (correios e telégrafos)' },
  { id: '848525 Separador de carnes em matadouro', descricao: 'Separador de carnes em matadouro' },
  { id: '415210 Separador de correspondências (correios e telégrafos)', descricao: 'Separador de correspondências (correios e telégrafos)' },
  { id: '519210 Separador de material reciclável', descricao: 'Separador de material reciclável' },
  { id: '519210 Separador de sucata', descricao: 'Separador de sucata' },
  { id: '722415 Separador de trefilados', descricao: 'Separador de trefilados' },
  { id: '516610 Sepultador', descricao: 'Sepultador' },
  { id: '623420 Sericicultor - exclusive conta própria e empregador', descricao: 'Sericicultor - exclusive conta própria e empregador' },
  { id: '613420 Sericultor', descricao: 'Sericultor' },
  { id: '613420 Sericultor - conta própria', descricao: 'Sericultor - conta própria' },
  { id: '623420 Sericultor - exclusive conta própria e empregador', descricao: 'Sericultor - exclusive conta própria e empregador' },
  { id: '768625 Serigrafista', descricao: 'Serigrafista' },
  { id: '766205 Serigrafista (gráfico)', descricao: 'Serigrafista (gráfico)' },
  { id: '752205 Serigrafista em vidros', descricao: 'Serigrafista em vidros' },
  { id: '632205 Seringueiro', descricao: 'Seringueiro' },
  { id: '623405 Serpentarista', descricao: 'Serpentarista' },
  { id: '841710 Serpenteador', descricao: 'Serpenteador' },
  { id: '724115 Serpentineiro', descricao: 'Serpentineiro' },
  { id: '773340 Serrador (usinagem da madeira)', descricao: 'Serrador (usinagem da madeira)' },
  { id: '773120 Serrador - em serrarias', descricao: 'Serrador - em serrarias' },
  { id: '632120 Serrador de árvores - na extração de madeira', descricao: 'Serrador de árvores - na extração de madeira' },
  { id: '773115 Serrador de bordas no desdobramento de madeira', descricao: 'Serrador de bordas no desdobramento de madeira' },
  { id: '848525 Serrador de chifres', descricao: 'Serrador de chifres' },
  { id: '632120 Serrador de lenha', descricao: 'Serrador de lenha' },
  { id: '773120 Serrador de madeira', descricao: 'Serrador de madeira' },
  { id: '773125 Serrador de madeira (serra circular múltipla)', descricao: 'Serrador de madeira (serra circular múltipla)' },
  { id: '773130 Serrador de madeira (serra de fita múltipla)', descricao: 'Serrador de madeira (serra de fita múltipla)' },
  { id: '712205 Serrador de mármore', descricao: 'Serrador de mármore' },
  { id: '848525 Serrador de mocotó', descricao: 'Serrador de mocotó' },
  { id: '848525 Serrador de parte dianteira', descricao: 'Serrador de parte dianteira' },
  { id: '848525 Serrador de parte traseira', descricao: 'Serrador de parte traseira' },
  { id: '711230 Serrador de pedra (extração)', descricao: 'Serrador de pedra (extração)' },
  { id: '712205 Serrador de pedras (beneficiamento)', descricao: 'Serrador de pedras (beneficiamento)' },
  { id: '848525 Serrador de peito', descricao: 'Serrador de peito' },
  { id: '848525 Serrador de ponta-de-agulha', descricao: 'Serrador de ponta-de-agulha' },
  { id: '848525 Serrador de porcos', descricao: 'Serrador de porcos' },
  { id: '773120 Serrador de toras', descricao: 'Serrador de toras' },
  { id: '724440 Serralheiro', descricao: 'Serralheiro' },
  { id: '724440 Serralheiro de alumínio', descricao: 'Serralheiro de alumínio' },
  { id: '724440 Serralheiro de ferro', descricao: 'Serralheiro de ferro' },
  { id: '724440 Serralheiro de manutenção', descricao: 'Serralheiro de manutenção' },
  { id: '724440 Serralheiro de metal', descricao: 'Serralheiro de metal' },
  { id: '724440 Serralheiro de produção', descricao: 'Serralheiro de produção' },
  { id: '724440 Serralheiro de protótipo', descricao: 'Serralheiro de protótipo' },
  { id: '724440 Serralheiro industrial', descricao: 'Serralheiro industrial' },
  { id: '724440 Serralheiro modelista', descricao: 'Serralheiro modelista' },
  { id: '724440 Serralheiro montador', descricao: 'Serralheiro montador' },
  { id: '724440 Serralheiro preparador', descricao: 'Serralheiro preparador' },
  { id: '717020 Servente (construção civil)', descricao: 'Servente (construção civil)' },
  { id: '513435 Servente de lanche', descricao: 'Servente de lanche' },
  { id: '514320 Servente de limpeza', descricao: 'Servente de limpeza' },
  { id: '848525 Servente de limpeza e desossa em açougue', descricao: 'Servente de limpeza e desossa em açougue' },
  { id: '717020 Servente de obras', descricao: 'Servente de obras' },
  { id: '717020 Servente de pedreiro', descricao: 'Servente de pedreiro' },
  { id: '992225 Servente de pedreiro na conservação de vias permanentes (exceto trilhos)', descricao: 'Servente de pedreiro na conservação de vias permanentes (exceto trilhos)' },
  { id: '632125 Servente de reflorestamento', descricao: 'Servente de reflorestamento' },
  { id: '992225 Servente de serviços gerais na conservação de vias permanentes (exceto trilhos)', descricao: 'Servente de serviços gerais na conservação de vias permanentes (exceto trilhos)' },
  { id: '3514 SERVENTUÁRIOS DA JUSTIÇA E AFINS', descricao: 'SERVENTUÁRIOS DA JUSTIÇA E AFINS' },
  { id: '623325 Sexador', descricao: 'Sexador' },
  { id: '623325 Sexador de pintos', descricao: 'Sexador de pintos' },
  { id: '263105 Sheikh', descricao: 'Sheikh' },
  { id: '414110 Sileiro', descricao: 'Sileiro' },
  { id: '782145 Sinaleiro (orientação de guindastes e equipamentos similares)', descricao: 'Sinaleiro (orientação de guindastes e equipamentos similares)' },
  { id: '782145 Sinaleiro (ponte-rolante)', descricao: 'Sinaleiro (ponte-rolante)' },
  { id: '782145 Sinaleiro de campo nas operações de máquinas e equipamentos de elevações', descricao: 'Sinaleiro de campo nas operações de máquinas e equipamentos de elevações' },
  { id: '341245 Sinaleiro naval', descricao: 'Sinaleiro naval' },
  { id: '510110 Síndico (edifícios)', descricao: 'Síndico (edifícios)' },
  { id: '632205 Sirgueiro', descricao: 'Sirgueiro' },
  { id: '631005 Sirieiro', descricao: 'Sirieiro' },
  { id: '612225 Sisaleiro', descricao: 'Sisaleiro' },
  { id: '611005 Sitiante', descricao: 'Sitiante' },
  { id: '613105 Sitiante - na criação de asininos e muares', descricao: 'Sitiante - na criação de asininos e muares' },
  { id: '613205 Sitiante - na criação de caprinos', descricao: 'Sitiante - na criação de caprinos' },
  { id: '613130 Sitiante - na criação de eqüinos', descricao: 'Sitiante - na criação de eqüinos' },
  { id: '613215 Sitiante - na criação de suínos', descricao: 'Sitiante - na criação de suínos' },
  { id: '611005 Sitiante na agropecuária - conta própria', descricao: 'Sitiante na agropecuária - conta própria' },
  { id: '613210 Sitiante na criação de ovinos - empregador', descricao: 'Sitiante na criação de ovinos - empregador' },
  { id: '612005 Sitiante polivalente', descricao: 'Sitiante polivalente' },
  { id: '263110 Sóchó', descricao: 'Sóchó' },
  { id: '263105 Sóchó (superior de missão)', descricao: 'Sóchó (superior de missão)' },
  { id: '253405 Social media', descricao: 'Social media' },
  { id: '613420 Sócio - proprietário - na criação de bicho-da-seda - empregador', descricao: 'Sócio - proprietário - na criação de bicho-da-seda - empregador' },
  { id: '141410 Sócio proprietário (comércio varejista)', descricao: 'Sócio proprietário (comércio varejista)' },
  { id: '613105 Sócio proprietário - na criação de asininos', descricao: 'Sócio proprietário - na criação de asininos' },
  { id: '613105 Sócio proprietário - na criação de muares', descricao: 'Sócio proprietário - na criação de muares' },
  { id: '141510 Sócio proprietário - no serviço de alimentação - conta própria', descricao: 'Sócio proprietário - no serviço de alimentação - conta própria' },
  { id: '141510 Sócio proprietário - no serviço de alimentação - empregador', descricao: 'Sócio proprietário - no serviço de alimentação - empregador' },
  { id: '141515 Sócio proprietário de bar - empregador', descricao: 'Sócio proprietário de bar - empregador' },
  { id: '141515 Sócio proprietário de bar, lanchonete - conta própria', descricao: 'Sócio proprietário de bar, lanchonete - conta própria' },
  { id: '141515 Sócio proprietário de lanchonete - empregador', descricao: 'Sócio proprietário de lanchonete - empregador' },
  { id: '141520 Sócio proprietário de pensão', descricao: 'Sócio proprietário de pensão' },
  { id: '141520 Sócio proprietário de pensão - conta própria', descricao: 'Sócio proprietário de pensão - conta própria' },
  { id: '141510 Sócio proprietário de restaurante - conta própria', descricao: 'Sócio proprietário de restaurante - conta própria' },
  { id: '141510 Sócio proprietário de restaurante - empregador', descricao: 'Sócio proprietário de restaurante - empregador' },
  { id: '613010 Sócio- proprietário - na criação de pequenos animais - empregador', descricao: 'Sócio- proprietário - na criação de pequenos animais - empregador' },
  { id: '613405 Sócio-proprietário - na criação de abelhas - empregador', descricao: 'Sócio-proprietário - na criação de abelhas - empregador' },
  { id: '613215 Sócio-proprietário - na criação de suínos empregador', descricao: 'Sócio-proprietário - na criação de suínos empregador' },
  { id: '141205 Sócio-proprietário - na extração mineral - empregador', descricao: 'Sócio-proprietário - na extração mineral - empregador' },
  { id: '141505 Sócio-proprietário - nos serviços de alojamento - empregador', descricao: 'Sócio-proprietário - nos serviços de alojamento - empregador' },
  { id: '141505 Sócio-proprietário - nos serviços de hospedagem - empregador', descricao: 'Sócio-proprietário - nos serviços de hospedagem - empregador' },
  { id: '141205 Sócio-proprietário de areal - empregador', descricao: 'Sócio-proprietário de areal - empregador' },
  { id: '141505 Sócio-proprietário de hotel - conta-própria', descricao: 'Sócio-proprietário de hotel - conta-própria' },
  { id: '141505 Sócio-proprietário de hotel - empregador', descricao: 'Sócio-proprietário de hotel - empregador' },
  { id: '141505 Sócio-proprietário de motel - conta própria', descricao: 'Sócio-proprietário de motel - conta própria' },
  { id: '141505 Sócio-proprietário de motel - empregador', descricao: 'Sócio-proprietário de motel - empregador' },
  { id: '141205 Sócio-proprietário de pedreira - empregador', descricao: 'Sócio-proprietário de pedreira - empregador' },
  { id: '141205 Sócio-proprietário de porto de areia - empregador', descricao: 'Sócio-proprietário de porto de areia - empregador' },
  { id: '141205 Sócio-proprietário de saibreira - empregador', descricao: 'Sócio-proprietário de saibreira - empregador' },
  { id: '141205 Sócio-proprietário de salina - empregador', descricao: 'Sócio-proprietário de salina - empregador' },
  { id: '611005 Sócio-proprietário na agropecuária - conta própria', descricao: 'Sócio-proprietário na agropecuária - conta própria' },
  { id: '612005 Sócio-proprietário polivalente na agricultura - conta própria', descricao: 'Sócio-proprietário polivalente na agricultura - conta própria' },
  { id: '612005 Sócio-proprietário polivalente na agricultura - empregador', descricao: 'Sócio-proprietário polivalente na agricultura - empregador' },
  { id: '515325 Sócioeducador', descricao: 'Sócioeducador' },
  { id: '251120 Sociólogo', descricao: 'Sociólogo' },
  { id: '515135 Socorrista (exceto médicos e enfermeiros)', descricao: 'Socorrista (exceto médicos e enfermeiros)' },
  { id: '612740 Sojicultor', descricao: 'Sojicultor' },
  { id: '263105 Sokan', descricao: 'Sokan' },
  { id: '31210 Soldado bombeiro militar', descricao: 'Soldado bombeiro militar' },
  { id: '10305 Soldado da aeronáutica', descricao: 'Soldado da aeronáutica' },
  { id: '21210 Soldado da polícia militar', descricao: 'Soldado da polícia militar' },
  { id: '10310 Soldado do exército', descricao: 'Soldado do exército' },
  { id: '10315 Soldado fuzileiro naval', descricao: 'Soldado fuzileiro naval' },
  { id: '724315 Soldador', descricao: 'Soldador' },
  { id: '724320 Soldador a oxiacetileno', descricao: 'Soldador a oxiacetileno' },
  { id: '724320 Soldador a oxigás', descricao: 'Soldador a oxigás' },
  { id: '724320 Soldador a oxigênio', descricao: 'Soldador a oxigênio' },
  { id: '724325 Soldador a arco elétrico', descricao: 'Soldador a arco elétrico' },
  { id: '991120 Soldador aluminotérmico em conservação de trilhos', descricao: 'Soldador aluminotérmico em conservação de trilhos' },
  { id: '724315 Soldador autógeno', descricao: 'Soldador autógeno' },
  { id: '724325 Soldador com maçarico e arco elétrico', descricao: 'Soldador com maçarico e arco elétrico' },
  { id: '751125 Soldador de jóias', descricao: 'Soldador de jóias' },
  { id: '724315 Soldador de oficina mecânica', descricao: 'Soldador de oficina mecânica' },
  { id: '724305 Soldador de solda à forte', descricao: 'Soldador de solda à forte' },
  { id: '724315 Soldador de solda branca', descricao: 'Soldador de solda branca' },
  { id: '724315 Soldador de solda elétrica e oxiacetileno', descricao: 'Soldador de solda elétrica e oxiacetileno' },
  { id: '991305 Soldador de veículos', descricao: 'Soldador de veículos' },
  { id: '724325 Soldador elétrico', descricao: 'Soldador elétrico' },
  { id: '724305 Soldador manual', descricao: 'Soldador manual' },
  { id: '724315 Soldador mecânico', descricao: 'Soldador mecânico' },
  { id: '510135 Sommalier', descricao: 'Sommalier' },
  { id: '513410 Sommelier', descricao: 'Sommelier' },
  { id: '513410 Sommelier de cachaça', descricao: 'Sommelier de cachaça' },
  { id: '513410 Sommelier de cerveja', descricao: 'Sommelier de cerveja' },
  { id: '513410 Sommelier de saquê', descricao: 'Sommelier de saquê' },
  { id: '513410 Sommelier de vinho', descricao: 'Sommelier de vinho' },
  { id: '711315 Sondador (poços de petróleo e gás)', descricao: 'Sondador (poços de petróleo e gás)' },
  { id: '711105 Sondador - na mineração', descricao: 'Sondador - na mineração' },
  { id: '711320 Sondador de geofísica', descricao: 'Sondador de geofísica' },
  { id: '711320 Sondador de poços (exceto de petróleo e gás)', descricao: 'Sondador de poços (exceto de petróleo e gás)' },
  { id: '711310 Sondador de poços tubulares e sistemas rotativos', descricao: 'Sondador de poços tubulares e sistemas rotativos' },
  { id: '374150 Sonoplasta', descricao: 'Sonoplasta' },
  { id: '821255 Soprador de convertedor', descricao: 'Soprador de convertedor' },
  { id: '752115 Soprador de vidro', descricao: 'Soprador de vidro' },
  { id: '823240 Soprador de vidro (a máquina)', descricao: 'Soprador de vidro (a máquina)' },
  { id: '752115 Soprador de vidro (material de laboratório)', descricao: 'Soprador de vidro (material de laboratório)' },
  { id: '7521 SOPRADORES, MOLDADORES E MODELADORES DE VIDROS E AFINS', descricao: 'SOPRADORES, MOLDADORES E MODELADORES DE VIDROS E AFINS' },
  { id: '632210 Sorveiro', descricao: 'Sorveiro' },
  { id: '848325 Sorveteiro', descricao: 'Sorveteiro' },
  { id: '524305 Sorveteiro (comércio ambulante)', descricao: 'Sorveteiro (comércio ambulante)' },
  { id: '141410 Sorveteiro (comércio varejista)', descricao: 'Sorveteiro (comércio varejista)' },
  { id: '782810 Sota', descricao: 'Sota' },
  { id: '374120 Sound designer', descricao: 'Sound designer' },
  { id: '375125 Stylist', descricao: 'Stylist' },
  { id: '215210 Sub-chefe de máquinas da marinha mercante', descricao: 'Sub-chefe de máquinas da marinha mercante' },
  { id: '510130 Subchefe de bar', descricao: 'Subchefe de bar' },
  { id: '271105 Subchefe de cozinha', descricao: 'Subchefe de cozinha' },
  { id: '720130 Subchefe de galvanização', descricao: 'Subchefe de galvanização' },
  { id: '141415 Subchefe de loja (operações comerciais)', descricao: 'Subchefe de loja (operações comerciais)' },
  { id: '252210 Subcontador', descricao: 'Subcontador' },
  { id: '131305 Subdiretor de ensino privado', descricao: 'Subdiretor de ensino privado' },
  { id: '710220 Subencarregado central de concreto', descricao: 'Subencarregado central de concreto' },
  { id: '410105 Subencarregado de escritório', descricao: 'Subencarregado de escritório' },
  { id: '710215 Subencarregado de terraplenagem', descricao: 'Subencarregado de terraplenagem' },
  { id: '141505 Subgerente de hotel', descricao: 'Subgerente de hotel' },
  { id: '141415 Subgerente de loja (operações comerciais)', descricao: 'Subgerente de loja (operações comerciais)' },
  { id: '141420 Subgerente de oficina (assistência técnica)', descricao: 'Subgerente de oficina (assistência técnica)' },
  { id: '141510 Subgerente de restaurante', descricao: 'Subgerente de restaurante' },
  { id: '141605 Subgerente nos transportes', descricao: 'Subgerente nos transportes' },
  { id: '622715 Subidor de coqueiro', descricao: 'Subidor de coqueiro' },
  { id: '10305 Suboficial da aeronáutica', descricao: 'Suboficial da aeronáutica' },
  { id: '10315 Suboficial da marinha', descricao: 'Suboficial da marinha' },
  { id: '242240 Subprocurador de justiça militar', descricao: 'Subprocurador de justiça militar' },
  { id: '242245 Subprocurador-geral da república', descricao: 'Subprocurador-geral da república' },
  { id: '242250 Subprocurador-geral do trabalho', descricao: 'Subprocurador-geral do trabalho' },
  { id: '351705 Subscritor assistente de seguros', descricao: 'Subscritor assistente de seguros' },
  { id: '111405 Subsecretário de estado (serviço público federal)', descricao: 'Subsecretário de estado (serviço público federal)' },
  { id: '31105 Subtenente bombeiro militar', descricao: 'Subtenente bombeiro militar' },
  { id: '21105 Subtenente da policia militar', descricao: 'Subtenente da policia militar' },
  { id: '31105 Subtenente do corpo de bombeiros militar', descricao: 'Subtenente do corpo de bombeiros militar' },
  { id: '10310 Subtenente do exército', descricao: 'Subtenente do exército' },
  { id: '211 SUBTENENTES E SARGENTOS DA POLICIA MILITAR', descricao: 'SUBTENENTES E SARGENTOS DA POLICIA MILITAR' },
  { id: '311 SUBTENENTES E SARGENTOS DO CORPO DE BOMBEIROS MILITAR', descricao: 'SUBTENENTES E SARGENTOS DO CORPO DE BOMBEIROS MILITAR' },
  { id: '632210 Sucubeiro', descricao: 'Sucubeiro' },
  { id: '613215 Suinocultor - criador de leitão', descricao: 'Suinocultor - criador de leitão' },
  { id: '613215 Suinocultor - empregador', descricao: 'Suinocultor - empregador' },
  { id: '623215 Suinocultor - exclusive conta própria', descricao: 'Suinocultor - exclusive conta própria' },
  { id: '613215 Suinocultor cabanheiro', descricao: 'Suinocultor cabanheiro' },
  { id: '613215 Suinocultor crecheiro', descricao: 'Suinocultor crecheiro' },
  { id: '123105 Superintendente administrativo', descricao: 'Superintendente administrativo' },
  { id: '123110 Superintendente administrativo e financeiro', descricao: 'Superintendente administrativo e financeiro' },
  { id: '123305 Superintendente comercial', descricao: 'Superintendente comercial' },
  { id: '122305 Superintendente de contratos de operações de obras pública e civil', descricao: 'Superintendente de contratos de operações de obras pública e civil' },
  { id: '263105 Superintendente de culto religioso', descricao: 'Superintendente de culto religioso' },
  { id: '123705 Superintendente de desenvolvimento de produtos e processos', descricao: 'Superintendente de desenvolvimento de produtos e processos' },
  { id: '123115 Superintendente de finanças', descricao: 'Superintendente de finanças' },
  { id: '123805 Superintendente de manutenção', descricao: 'Superintendente de manutenção' },
  { id: '123310 Superintendente de marketing', descricao: 'Superintendente de marketing' },
  { id: '122205 Superintendente de operações industriais', descricao: 'Superintendente de operações industriais' },
  { id: '131105 Superintendente de relações institucionais', descricao: 'Superintendente de relações institucionais' },
  { id: '131115 Superintendente de seção de estudos e programação', descricao: 'Superintendente de seção de estudos e programação' },
  { id: '131115 Superintendente de seção de estudos especiais', descricao: 'Superintendente de seção de estudos especiais' },
  { id: '131105 Superintendente de serviços culturais', descricao: 'Superintendente de serviços culturais' },
  { id: '710220 Superintendente de usina central de concreto', descricao: 'Superintendente de usina central de concreto' },
  { id: '123115 Superintendente financeiro', descricao: 'Superintendente financeiro' },
  { id: '215220 Superintendente técnico no transporte aquaviário', descricao: 'Superintendente técnico no transporte aquaviário' },
  { id: '263105 Superior de culto religioso', descricao: 'Superior de culto religioso' },
  { id: '263105 Superior geral', descricao: 'Superior geral' },
  { id: '263105 Superiora de culto religioso', descricao: 'Superiora de culto religioso' },
  { id: '760405 Supervisor (indústria de calçados e artefatos de couro)', descricao: 'Supervisor (indústria de calçados e artefatos de couro)' },
  { id: '720210 Supervisor (indústria de automotores e de material de transporte)', descricao: 'Supervisor (indústria de automotores e de material de transporte)' },
  { id: '410105 Supervisor administrativo', descricao: 'Supervisor administrativo' },
  { id: '410105 Supervisor administrativo de escritório', descricao: 'Supervisor administrativo de escritório' },
  { id: '410105 Supervisor administrativo de pessoal', descricao: 'Supervisor administrativo de pessoal' },
  { id: '410105 Supervisor administrativo interno', descricao: 'Supervisor administrativo interno' },
  { id: '520110 Supervisor comercial', descricao: 'Supervisor comercial' },
  { id: '342540 Supervisor da administração de aeroportos', descricao: 'Supervisor da administração de aeroportos' },
  { id: '630105 Supervisor da aqüicultura', descricao: 'Supervisor da aqüicultura' },
  { id: '630110 Supervisor da área florestal', descricao: 'Supervisor da área florestal' },
  { id: '760505 Supervisor da confecção de artefatos de tecidos, couros e afins', descricao: 'Supervisor da confecção de artefatos de tecidos, couros e afins' },
  { id: '840110 Supervisor da indústria de bebidas', descricao: 'Supervisor da indústria de bebidas' },
  { id: '840115 Supervisor da indústria de fumo', descricao: 'Supervisor da indústria de fumo' },
  { id: '750205 Supervisor da indústria de minerais não metálicos (exceto os derivados de petróleo e carvão)', descricao: 'Supervisor da indústria de minerais não metálicos (exceto os derivados de petróleo e carvão)' },
  { id: '910205 Supervisor da manutenção e reparação de veículos leves', descricao: 'Supervisor da manutenção e reparação de veículos leves' },
  { id: '910210 Supervisor da manutenção e reparação de veículos pesados', descricao: 'Supervisor da manutenção e reparação de veículos pesados' },
  { id: '740105 Supervisor da mecânica de precisão', descricao: 'Supervisor da mecânica de precisão' },
  { id: '760605 Supervisor das artes gráficas (indústria editorial e gráfica)', descricao: 'Supervisor das artes gráficas (indústria editorial e gráfica)' },
  { id: '760405 Supervisor de acabamento de confecção de calçados', descricao: 'Supervisor de acabamento de confecção de calçados' },
  { id: '848510 Supervisor de açougue', descricao: 'Supervisor de açougue' },
  { id: '410105 Supervisor de administração', descricao: 'Supervisor de administração' },
  { id: '410105 Supervisor de administração e arquivo técnico', descricao: 'Supervisor de administração e arquivo técnico' },
  { id: '410205 Supervisor de almoxarifado', descricao: 'Supervisor de almoxarifado' },
  { id: '760405 Supervisor de alta freqüência de confecção de calçados', descricao: 'Supervisor de alta freqüência de confecção de calçados' },
  { id: '820115 Supervisor de alto-forno', descricao: 'Supervisor de alto-forno' },
  { id: '510115 Supervisor de andar', descricao: 'Supervisor de andar' },
  { id: '710105 Supervisor de apoio operacional na mineração', descricao: 'Supervisor de apoio operacional na mineração' },
  { id: '860115 Supervisor de área de operação elétrica', descricao: 'Supervisor de área de operação elétrica' },
  { id: '420115 Supervisor de arrecadação (apostas e jogos)', descricao: 'Supervisor de arrecadação (apostas e jogos)' },
  { id: '420105 Supervisor de atendimento (caixas e bilheteiros)', descricao: 'Supervisor de atendimento (caixas e bilheteiros)' },
  { id: '420135 Supervisor de atendimento ao cliente', descricao: 'Supervisor de atendimento ao cliente' },
  { id: '410220 Supervisor de ativo fixo', descricao: 'Supervisor de ativo fixo' },
  { id: '510130 Supervisor de bar', descricao: 'Supervisor de bar' },
  { id: '510105 Supervisor de bilheteira de transportes', descricao: 'Supervisor de bilheteira de transportes' },
  { id: '510305 Supervisor de bombeiros', descricao: 'Supervisor de bombeiros' },
  { id: '710115 Supervisor de britagem (mineração)', descricao: 'Supervisor de britagem (mineração)' },
  { id: '420105 Supervisor de caixas e bilheteiros (exceto caixa de banco)', descricao: 'Supervisor de caixas e bilheteiros (exceto caixa de banco)' },
  { id: '820110 Supervisor de calcinação', descricao: 'Supervisor de calcinação' },
  { id: '410210 Supervisor de câmbio', descricao: 'Supervisor de câmbio' },
  { id: '342315 Supervisor de carga e descarga', descricao: 'Supervisor de carga e descarga' },
  { id: '770110 Supervisor de carpintaria', descricao: 'Supervisor de carpintaria' },
  { id: '770110 Supervisor de carpinteiro de manutenção', descricao: 'Supervisor de carpinteiro de manutenção' },
  { id: '710125 Supervisor de carregamento de minérios', descricao: 'Supervisor de carregamento de minérios' },
  { id: '420135 Supervisor de central de atendimento', descricao: 'Supervisor de central de atendimento' },
  { id: '342410 Supervisor de centro de controle (ferrovia e metrô)', descricao: 'Supervisor de centro de controle (ferrovia e metrô)' },
  { id: '420110 Supervisor de cobrança', descricao: 'Supervisor de cobrança' },
  { id: '410225 Supervisor de cobrança de serviço financeiro, de câmbio e controle', descricao: 'Supervisor de cobrança de serviço financeiro, de câmbio e controle' },
  { id: '420120 Supervisor de coleta', descricao: 'Supervisor de coleta' },
  { id: '420115 Supervisor de coletadores de apostas e de jogos', descricao: 'Supervisor de coletadores de apostas e de jogos' },
  { id: '354210 Supervisor de compras', descricao: 'Supervisor de compras' },
  { id: '710205 Supervisor de conservação de obras', descricao: 'Supervisor de conservação de obras' },
  { id: '710205 Supervisor de construção civil', descricao: 'Supervisor de construção civil' },
  { id: '720220 Supervisor de construção de fornos', descricao: 'Supervisor de construção de fornos' },
  { id: '710205 Supervisor de construção e conservação', descricao: 'Supervisor de construção e conservação' },
  { id: '720205 Supervisor de construção naval', descricao: 'Supervisor de construção naval' },
  { id: '710205 Supervisor de construções e manutenção', descricao: 'Supervisor de construções e manutenção' },
  { id: '252210 Supervisor de contabilidade', descricao: 'Supervisor de contabilidade' },
  { id: '410215 Supervisor de contas a pagar', descricao: 'Supervisor de contas a pagar' },
  { id: '710210 Supervisor de controle de linhas ferroviárias', descricao: 'Supervisor de controle de linhas ferroviárias' },
  { id: '261210 Supervisor de controle de processos documentais', descricao: 'Supervisor de controle de processos documentais' },
  { id: '720160 Supervisor de controle de tratamento térmico', descricao: 'Supervisor de controle de tratamento térmico' },
  { id: '261210 Supervisor de controle documental', descricao: 'Supervisor de controle documental' },
  { id: '410220 Supervisor de controle patrimonial', descricao: 'Supervisor de controle patrimonial' },
  { id: '820110 Supervisor de convertedor', descricao: 'Supervisor de convertedor' },
  { id: '760405 Supervisor de corte de confecção de calçados', descricao: 'Supervisor de corte de confecção de calçados' },
  { id: '760305 Supervisor de corte na confecção do vestuário', descricao: 'Supervisor de corte na confecção do vestuário' },
  { id: '760310 Supervisor de costura do vestuário', descricao: 'Supervisor de costura do vestuário' },
  { id: '271105 Supervisor de cozinha', descricao: 'Supervisor de cozinha' },
  { id: '410225 Supervisor de crédito', descricao: 'Supervisor de crédito' },
  { id: '410225 Supervisor de crédito e cobrança', descricao: 'Supervisor de crédito e cobrança' },
  { id: '760205 Supervisor de curtimento', descricao: 'Supervisor de curtimento' },
  { id: '820110 Supervisor de dessulfuração', descricao: 'Supervisor de dessulfuração' },
  { id: '412120 Supervisor de digitação', descricao: 'Supervisor de digitação' },
  { id: '412120 Supervisor de digitação e operação', descricao: 'Supervisor de digitação e operação' },
  { id: '810305 Supervisor de embalagem (produção farmacêutica)', descricao: 'Supervisor de embalagem (produção farmacêutica)' },
  { id: '780105 Supervisor de embalagem e etiquetagem', descricao: 'Supervisor de embalagem e etiquetagem' },
  { id: '342545 Supervisor de empresa aérea em aeroportos', descricao: 'Supervisor de empresa aérea em aeroportos' },
  { id: '780105 Supervisor de ensacamento', descricao: 'Supervisor de ensacamento' },
  { id: '239430 Supervisor de ensino', descricao: 'Supervisor de ensino' },
  { id: '420120 Supervisor de entrevistadores e recenseadores', descricao: 'Supervisor de entrevistadores e recenseadores' },
  { id: '780105 Supervisor de envasamento', descricao: 'Supervisor de envasamento' },
  { id: '710115 Supervisor de escavação e transporte (mineração)', descricao: 'Supervisor de escavação e transporte (mineração)' },
  { id: '342405 Supervisor de estação (metrô e ferrovia)', descricao: 'Supervisor de estação (metrô e ferrovia)' },
  { id: '510105 Supervisor de estação ferroviária e rodoviária', descricao: 'Supervisor de estação ferroviária e rodoviária' },
  { id: '620105 Supervisor de exploração agrícola', descricao: 'Supervisor de exploração agrícola' },
  { id: '620110 Supervisor de exploração agropecuária', descricao: 'Supervisor de exploração agropecuária' },
  { id: '620115 Supervisor de exploração pecuária', descricao: 'Supervisor de exploração pecuária' },
  { id: '710110 Supervisor de extração de sal', descricao: 'Supervisor de extração de sal' },
  { id: '810305 Supervisor de fabricação (produção farmacêutica)', descricao: 'Supervisor de fabricação (produção farmacêutica)' },
  { id: '740105 Supervisor de fabricação de instrumentos de precisão', descricao: 'Supervisor de fabricação de instrumentos de precisão' },
  { id: '740110 Supervisor de fabricação de instrumentos musicais', descricao: 'Supervisor de fabricação de instrumentos musicais' },
  { id: '820205 Supervisor de fabricação de produtos cerâmicos, porcelanatos e afins', descricao: 'Supervisor de fabricação de produtos cerâmicos, porcelanatos e afins' },
  { id: '820210 Supervisor de fabricação de produtos de vidro', descricao: 'Supervisor de fabricação de produtos de vidro' },
  { id: '830105 Supervisor de fibras e utilidades', descricao: 'Supervisor de fibras e utilidades' },
  { id: '820110 Supervisor de forno-panela', descricao: 'Supervisor de forno-panela' },
  { id: '820125 Supervisor de forno-poço', descricao: 'Supervisor de forno-poço' },
  { id: '510120 Supervisor de hospedagem', descricao: 'Supervisor de hospedagem' },
  { id: '760405 Supervisor de injeção de confecção de calçados', descricao: 'Supervisor de injeção de confecção de calçados' },
  { id: '750105 Supervisor de joalheria', descricao: 'Supervisor de joalheria' },
  { id: '766405 Supervisor de laboratório fotográfico', descricao: 'Supervisor de laboratório fotográfico' },
  { id: '820125 Supervisor de laminador de bloco e tarugos', descricao: 'Supervisor de laminador de bloco e tarugos' },
  { id: '820125 Supervisor de laminador desbastador', descricao: 'Supervisor de laminador desbastador' },
  { id: '510205 Supervisor de lavanderia', descricao: 'Supervisor de lavanderia' },
  { id: '710120 Supervisor de lavra (mineração)', descricao: 'Supervisor de lavra (mineração)' },
  { id: '820110 Supervisor de lingotamento', descricao: 'Supervisor de lingotamento' },
  { id: '820125 Supervisor de linhas de acabamento', descricao: 'Supervisor de linhas de acabamento' },
  { id: '424205 Supervisor de local de aplicação (concurso, avaliação, exame)', descricao: 'Supervisor de local de aplicação (concurso, avaliação, exame)' },
  { id: '410240 Supervisor de logística', descricao: 'Supervisor de logística' },
  { id: '910110 Supervisor de manutenção de aparelhos térmicos, de climatização e de refrigeração', descricao: 'Supervisor de manutenção de aparelhos térmicos, de climatização e de refrigeração' },
  { id: '910115 Supervisor de manutenção de bombas, motores, compressores e equipamentos de transmissão', descricao: 'Supervisor de manutenção de bombas, motores, compressores e equipamentos de transmissão' },
  { id: '313115 Supervisor de manutenção de máquinas e equipamentos', descricao: 'Supervisor de manutenção de máquinas e equipamentos' },
  { id: '910120 Supervisor de manutenção de máquinas gráficas', descricao: 'Supervisor de manutenção de máquinas gráficas' },
  { id: '910125 Supervisor de manutenção de máquinas industriais têxteis', descricao: 'Supervisor de manutenção de máquinas industriais têxteis' },
  { id: '910130 Supervisor de manutenção de máquinas operatrizes e de usinagem', descricao: 'Supervisor de manutenção de máquinas operatrizes e de usinagem' },
  { id: '910210 Supervisor de manutenção de veículos de carga', descricao: 'Supervisor de manutenção de veículos de carga' },
  { id: '910205 Supervisor de manutenção de veículos de passeio', descricao: 'Supervisor de manutenção de veículos de passeio' },
  { id: '910910 Supervisor de manutenção de vias férreas', descricao: 'Supervisor de manutenção de vias férreas' },
  { id: '950105 Supervisor de manutenção elétrica de alta tensão industrial', descricao: 'Supervisor de manutenção elétrica de alta tensão industrial' },
  { id: '950305 Supervisor de manutenção eletromecânica', descricao: 'Supervisor de manutenção eletromecânica' },
  { id: '860105 Supervisor de manutenção eletromecânica (utilidades)', descricao: 'Supervisor de manutenção eletromecânica (utilidades)' },
  { id: '950110 Supervisor de manutenção eletromecânica industrial, comercial e predial', descricao: 'Supervisor de manutenção eletromecânica industrial, comercial e predial' },
  { id: '910910 Supervisor de manutenção em lubrificação de vias permanentes', descricao: 'Supervisor de manutenção em lubrificação de vias permanentes' },
  { id: '950305 Supervisor de manutenção industrial', descricao: 'Supervisor de manutenção industrial' },
  { id: '910105 Supervisor de manutenção mecânica de sistemas operacionais', descricao: 'Supervisor de manutenção mecânica de sistemas operacionais' },
  { id: '860115 Supervisor de manutenção na geração ,transmissão e distribuição de energia elétrica', descricao: 'Supervisor de manutenção na geração ,transmissão e distribuição de energia elétrica' },
  { id: '710215 Supervisor de máquina de terraplenagem', descricao: 'Supervisor de máquina de terraplenagem' },
  { id: '830105 Supervisor de máquinas para fabricar papel', descricao: 'Supervisor de máquinas para fabricar papel' },
  { id: '410205 Supervisor de materiais em almoxarifado', descricao: 'Supervisor de materiais em almoxarifado' },
  { id: '760405 Supervisor de matrizaria de confecção de calçados', descricao: 'Supervisor de matrizaria de confecção de calçados' },
  { id: '711130 Supervisor de mina', descricao: 'Supervisor de mina' },
  { id: '760405 Supervisor de modelagem de confecção de calçados', descricao: 'Supervisor de modelagem de confecção de calçados' },
  { id: '313115 Supervisor de montagem', descricao: 'Supervisor de montagem' },
  { id: '760405 Supervisor de montagem de confecção de calçados', descricao: 'Supervisor de montagem de confecção de calçados' },
  { id: '730105 Supervisor de montagem e instalação eletroeletrônica', descricao: 'Supervisor de montagem e instalação eletroeletrônica' },
  { id: '510105 Supervisor de movimento de trens', descricao: 'Supervisor de movimento de trens' },
  { id: '810105 Supervisor de operação (indústria petroquímica e carboquímica)', descricao: 'Supervisor de operação (indústria petroquímica e carboquímica)' },
  { id: '860110 Supervisor de operação de fluidos (distribuição, captação, tratamento de água, gases, vapor)', descricao: 'Supervisor de operação de fluidos (distribuição, captação, tratamento de água, gases, vapor)' },
  { id: '860115 Supervisor de operação elétrica (geração, transmissão e distribuição de energia elétrica)', descricao: 'Supervisor de operação elétrica (geração, transmissão e distribuição de energia elétrica)' },
  { id: '373225 Supervisor de operações (mídias audiovisuais)', descricao: 'Supervisor de operações (mídias audiovisuais)' },
  { id: '373220 Supervisor de operações de sistemas de televisão e produtoras de vídeo', descricao: 'Supervisor de operações de sistemas de televisão e produtoras de vídeo' },
  { id: '410240 Supervisor de operações logísticas', descricao: 'Supervisor de operações logísticas' },
  { id: '391205 Supervisor de operações na área de controle de produção', descricao: 'Supervisor de operações na área de controle de produção' },
  { id: '342610 Supervisor de operações portuárias', descricao: 'Supervisor de operações portuárias' },
  { id: '354810 Supervisor de operações turísticas', descricao: 'Supervisor de operações turísticas' },
  { id: '410230 Supervisor de orçamento', descricao: 'Supervisor de orçamento' },
  { id: '830105 Supervisor de papel e acabamento', descricao: 'Supervisor de papel e acabamento' },
  { id: '830105 Supervisor de pátio de madeira', descricao: 'Supervisor de pátio de madeira' },
  { id: '820110 Supervisor de pátio e sucata', descricao: 'Supervisor de pátio e sucata' },
  { id: '710115 Supervisor de perfuração e desmonte', descricao: 'Supervisor de perfuração e desmonte' },
  { id: '760405 Supervisor de pesponto de confecção de calçados', descricao: 'Supervisor de pesponto de confecção de calçados' },
  { id: '720135 Supervisor de pintura (tratamento de superfícies)', descricao: 'Supervisor de pintura (tratamento de superfícies)' },
  { id: '760405 Supervisor de pré-frezado de confecção de calçados', descricao: 'Supervisor de pré-frezado de confecção de calçados' },
  { id: '810305 Supervisor de processamento (produção farmacêutica)', descricao: 'Supervisor de processamento (produção farmacêutica)' },
  { id: '830105 Supervisor de processo de fibras, químicos e madeira', descricao: 'Supervisor de processo de fibras, químicos e madeira' },
  { id: '760505 Supervisor de produção (artefatos de couro)', descricao: 'Supervisor de produção (artefatos de couro)' },
  { id: '730105 Supervisor de produção (indústria de material elétrico e eletrônico)', descricao: 'Supervisor de produção (indústria de material elétrico e eletrônico)' },
  { id: '810305 Supervisor de produção (produção farmacêutica)', descricao: 'Supervisor de produção (produção farmacêutica)' },
  { id: '840105 Supervisor de produção da indústria alimentícia', descricao: 'Supervisor de produção da indústria alimentícia' },
  { id: '830105 Supervisor de produção de celulose', descricao: 'Supervisor de produção de celulose' },
  { id: '810305 Supervisor de produção de líquidos (produção farmacêutica)', descricao: 'Supervisor de produção de líquidos (produção farmacêutica)' },
  { id: '830105 Supervisor de produção de papel', descricao: 'Supervisor de produção de papel' },
  { id: '810305 Supervisor de produção de semi-sólidos (produção farmacêutica)', descricao: 'Supervisor de produção de semi-sólidos (produção farmacêutica)' },
  { id: '810305 Supervisor de produção de sólidos (produção farmacêutica)', descricao: 'Supervisor de produção de sólidos (produção farmacêutica)' },
  { id: '710120 Supervisor de produção e lavra (mineração)', descricao: 'Supervisor de produção e lavra (mineração)' },
  { id: '820205 Supervisor de produção na fabricação de produtos de cerâmica, porcelanatos e afins', descricao: 'Supervisor de produção na fabricação de produtos de cerâmica, porcelanatos e afins' },
  { id: '820210 Supervisor de produção na fabricação de produtos de vidro', descricao: 'Supervisor de produção na fabricação de produtos de vidro' },
  { id: '710120 Supervisor de produção na mineração', descricao: 'Supervisor de produção na mineração' },
  { id: '810110 Supervisor de produção química', descricao: 'Supervisor de produção química' },
  { id: '510120 Supervisor de recepção de hotel', descricao: 'Supervisor de recepção de hotel' },
  { id: '420125 Supervisor de recepcionistas', descricao: 'Supervisor de recepcionistas' },
  { id: '830105 Supervisor de recuperação de produtos químicos', descricao: 'Supervisor de recuperação de produtos químicos' },
  { id: '910905 Supervisor de reparos linhas férreas', descricao: 'Supervisor de reparos linhas férreas' },
  { id: '410105 Supervisor de seção de serviços gerais', descricao: 'Supervisor de seção de serviços gerais' },
  { id: '510310 Supervisor de segurança (vigilância)', descricao: 'Supervisor de segurança (vigilância)' },
  { id: '351605 Supervisor de segurança do trabalho', descricao: 'Supervisor de segurança do trabalho' },
  { id: '510310 Supervisor de segurança patrimonial', descricao: 'Supervisor de segurança patrimonial' },
  { id: '770105 Supervisor de serraria', descricao: 'Supervisor de serraria' },
  { id: '420135 Supervisor de teleatendimento', descricao: 'Supervisor de teleatendimento' },
  { id: '420130 Supervisor de telefonistas', descricao: 'Supervisor de telefonistas' },
  { id: '420135 Supervisor de telemarketing e atendimento', descricao: 'Supervisor de telemarketing e atendimento' },
  { id: '420135 Supervisor de televendas', descricao: 'Supervisor de televendas' },
  { id: '410235 Supervisor de tesouraria', descricao: 'Supervisor de tesouraria' },
  { id: '420115 Supervisor de tesouraria de apostas e jogos', descricao: 'Supervisor de tesouraria de apostas e jogos' },
  { id: '342305 Supervisor de tráfego rodoviário', descricao: 'Supervisor de tráfego rodoviário' },
  { id: '710125 Supervisor de transporte na mineração', descricao: 'Supervisor de transporte na mineração' },
  { id: '510105 Supervisor de transportes', descricao: 'Supervisor de transportes' },
  { id: '510105 Supervisor de trens', descricao: 'Supervisor de trens' },
  { id: '710120 Supervisor de turno (mineração)', descricao: 'Supervisor de turno (mineração)' },
  { id: '710220 Supervisor de usina de concreto', descricao: 'Supervisor de usina de concreto' },
  { id: '520110 Supervisor de vendas comercial', descricao: 'Supervisor de vendas comercial' },
  { id: '520105 Supervisor de vendas de serviços', descricao: 'Supervisor de vendas de serviços' },
  { id: '520110 Supervisor de vendas no atacado', descricao: 'Supervisor de vendas no atacado' },
  { id: '520110 Supervisor de vendas no varejo', descricao: 'Supervisor de vendas no varejo' },
  { id: '510310 Supervisor de vigilantes', descricao: 'Supervisor de vigilantes' },
  { id: '780105 Supervisor do setor de embalagem', descricao: 'Supervisor do setor de embalagem' },
  { id: '239430 Supervisor educacional', descricao: 'Supervisor educacional' },
  { id: '630110 Supervisor florestal', descricao: 'Supervisor florestal' },
  { id: '342405 Supervisor geral de operação (metrô)', descricao: 'Supervisor geral de operação (metrô)' },
  { id: '510205 Supervisor operacional de lavanderia', descricao: 'Supervisor operacional de lavanderia' },
  { id: '342115 Supervisor operacional dos serviços de máquinas e veículos', descricao: 'Supervisor operacional dos serviços de máquinas e veículos' },
  { id: '239430 Supervisor pedagógico', descricao: 'Supervisor pedagógico' },
  { id: '373230 Supervisor técnico (mídias audiovisuais)', descricao: 'Supervisor técnico (mídias audiovisuais)' },
  { id: '860110 Supervisor técnico de aplicação e distribuição de gás', descricao: 'Supervisor técnico de aplicação e distribuição de gás' },
  { id: '860115 Supervisor técnico de manutenção elétrica (distribuição de energia)', descricao: 'Supervisor técnico de manutenção elétrica (distribuição de energia)' },
  { id: '860110 Supervisor técnico de operação de água e esgoto', descricao: 'Supervisor técnico de operação de água e esgoto' },
  { id: '860115 Supervisor técnico de operação de sistema de geração, transmissão e distribuição de energia elétrica', descricao: 'Supervisor técnico de operação de sistema de geração, transmissão e distribuição de energia elétrica' },
  { id: '373220 Supervisor técnico operacional de sistemas de televisão e produtoras de vídeo', descricao: 'Supervisor técnico operacional de sistemas de televisão e produtoras de vídeo' },
  { id: '4101 SUPERVISORES ADMINISTRATIVOS', descricao: 'SUPERVISORES ADMINISTRATIVOS' },
  { id: '7605 SUPERVISORES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS, COUROS E AFINS', descricao: 'SUPERVISORES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS, COUROS E AFINS' },
  { id: '7102 SUPERVISORES DA CONSTRUÇÃO CIVIL', descricao: 'SUPERVISORES DA CONSTRUÇÃO CIVIL' },
  { id: '7101 SUPERVISORES DA EXTRAÇÃO MINERAL', descricao: 'SUPERVISORES DA EXTRAÇÃO MINERAL' },
  { id: '8401 SUPERVISORES DA FABRICAÇÃO DE ALIMENTOS, BEBIDAS E FUMO', descricao: 'SUPERVISORES DA FABRICAÇÃO DE ALIMENTOS, BEBIDAS E FUMO' },
  { id: '8301 SUPERVISORES DA FABRICAÇÃO DE CELULOSE E PAPEL', descricao: 'SUPERVISORES DA FABRICAÇÃO DE CELULOSE E PAPEL' },
  { id: '7202 SUPERVISORES DA FABRICAÇÃO E MONTAGEM METALMECÂNICA', descricao: 'SUPERVISORES DA FABRICAÇÃO E MONTAGEM METALMECÂNICA' },
  { id: '7601 SUPERVISORES DA INDÚSTRIA TÊXTIL', descricao: 'SUPERVISORES DA INDÚSTRIA TÊXTIL' },
  { id: '7401 SUPERVISORES DA MECÂNICA DE PRECISÃO E INSTRUMENTOS MUSICAIS', descricao: 'SUPERVISORES DA MECÂNICA DE PRECISÃO E INSTRUMENTOS MUSICAIS' },
  { id: '8601 SUPERVISORES DA PRODUÇÃO DE UTILIDADES', descricao: 'SUPERVISORES DA PRODUÇÃO DE UTILIDADES' },
  { id: '7606 SUPERVISORES DAS ARTES GRÁFICAS', descricao: 'SUPERVISORES DAS ARTES GRÁFICAS' },
  { id: '4201 SUPERVISORES DE ATENDIMENTO AO PÚBLICO E DE PESQUISA', descricao: 'SUPERVISORES DE ATENDIMENTO AO PÚBLICO E DE PESQUISA' },
  { id: '7501 SUPERVISORES DE JOALHERIA E AFINS', descricao: 'SUPERVISORES DE JOALHERIA E AFINS' },
  { id: '5102 SUPERVISORES DE LAVANDERIA', descricao: 'SUPERVISORES DE LAVANDERIA' },
  { id: '910105 Supervisores de manutenção de máquinas e equipamentos comerciais e residenciais', descricao: 'Supervisores de manutenção de máquinas e equipamentos comerciais e residenciais' },
  { id: '9501 SUPERVISORES DE MANUTENÇÃO ELETROELETRÔNICA INDUSTRIAL, COMERCIAL E PREDIAL', descricao: 'SUPERVISORES DE MANUTENÇÃO ELETROELETRÔNICA INDUSTRIAL, COMERCIAL E PREDIAL' },
  { id: '9502 SUPERVISORES DE MANUTENÇÃO ELETROELETRÔNICA VEICULAR', descricao: 'SUPERVISORES DE MANUTENÇÃO ELETROELETRÔNICA VEICULAR' },
  { id: '9503 SUPERVISORES DE MANUTENÇÃO ELETROMECÂNICA', descricao: 'SUPERVISORES DE MANUTENÇÃO ELETROMECÂNICA' },
  { id: '7301 SUPERVISORES DE MONTAGENS E INSTALAÇÕES ELETROELETRÔNICAS', descricao: 'SUPERVISORES DE MONTAGENS E INSTALAÇÕES ELETROELETRÔNICAS' },
  { id: '9109 SUPERVISORES DE OUTROS TRABALHADORES DE SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E MANUTENÇÃO', descricao: 'SUPERVISORES DE OUTROS TRABALHADORES DE SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E MANUTENÇÃO' },
  { id: '8103 SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS DE PRODUTOS FARMACÊUTICOS, COSMÉTICOS E AFINS', descricao: 'SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS DE PRODUTOS FARMACÊUTICOS, COSMÉTICOS E AFINS' },
  { id: '8102 SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS DE TRANSFORMAÇÃO DE PLÁSTICOS E BORRACHAS', descricao: 'SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS DE TRANSFORMAÇÃO DE PLÁSTICOS E BORRACHAS' },
  { id: '8101 SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS E AFINS', descricao: 'SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS E AFINS' },
  { id: '8201 SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS SIDERÚRGICAS', descricao: 'SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS SIDERÚRGICAS' },
  { id: '4102 SUPERVISORES DE SERVIÇOS FINANCEIROS, DE CÂMBIO E DE CONTROLE', descricao: 'SUPERVISORES DE SERVIÇOS FINANCEIROS, DE CÂMBIO E DE CONTROLE' },
  { id: '7801 SUPERVISORES DE TRABALHADORES DE EMBALAGEM E ETIQUETAGEM', descricao: 'SUPERVISORES DE TRABALHADORES DE EMBALAGEM E ETIQUETAGEM' },
  { id: '7201 SUPERVISORES DE USINAGEM, CONFORMAÇÃO E TRATAMENTO DE METAIS', descricao: 'SUPERVISORES DE USINAGEM, CONFORMAÇÃO E TRATAMENTO DE METAIS' },
  { id: '5201 SUPERVISORES DE VENDAS E DE PRESTAÇÃO DE SERVIÇOS', descricao: 'SUPERVISORES DE VENDAS E DE PRESTAÇÃO DE SERVIÇOS' },
  { id: '7502 SUPERVISORES DE VIDRARIA, CERÂMICA E AFINS', descricao: 'SUPERVISORES DE VIDRARIA, CERÂMICA E AFINS' },
  { id: '5103 SUPERVISORES DOS SERVIÇOS DE PROTEÇÃO, SEGURANÇA E OUTROS', descricao: 'SUPERVISORES DOS SERVIÇOS DE PROTEÇÃO, SEGURANÇA E OUTROS' },
  { id: '5101 SUPERVISORES DOS SERVIÇOS DE TRANSPORTE, TURISMO, HOTELARIA E ADMINISTRAÇÃO DE EDIFÍCIOS', descricao: 'SUPERVISORES DOS SERVIÇOS DE TRANSPORTE, TURISMO, HOTELARIA E ADMINISTRAÇÃO DE EDIFÍCIOS' },
  { id: '7701 SUPERVISORES EM INDÚSTRIA DE MADEIRA, MOBILIÁRIO E DA CARPINTARIA VEICULAR', descricao: 'SUPERVISORES EM INDÚSTRIA DE MADEIRA, MOBILIÁRIO E DA CARPINTARIA VEICULAR' },
  { id: '9101 SUPERVISORES EM SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E EQUIPAMENTOS INDUSTRIAIS,', descricao: 'SUPERVISORES EM SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E EQUIPAMENTOS INDUSTRIAIS,' },
  { id: '9102 SUPERVISORES EM SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO VEICULAR', descricao: 'SUPERVISORES EM SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO VEICULAR' },
  { id: '6301 SUPERVISORES NA ÁREA FLORESTAL E AQÜICULTURA', descricao: 'SUPERVISORES NA ÁREA FLORESTAL E AQÜICULTURA' },
  { id: '7604 SUPERVISORES NA CONFECÇÃO DE CALÇADOS', descricao: 'SUPERVISORES NA CONFECÇÃO DE CALÇADOS' },
  { id: '7603 SUPERVISORES NA CONFECÇÃO DO VESTUÁRIO', descricao: 'SUPERVISORES NA CONFECÇÃO DO VESTUÁRIO' },
  { id: '6201 SUPERVISORES NA EXPLORAÇÃO AGROPECUÁRIA', descricao: 'SUPERVISORES NA EXPLORAÇÃO AGROPECUÁRIA' },
  { id: '8202 SUPERVISORES NA FABRICAÇÃO DE MATERIAIS PARA CONSTRUÇÃO (VIDROS E CERÂMICAS)', descricao: 'SUPERVISORES NA FABRICAÇÃO DE MATERIAIS PARA CONSTRUÇÃO (VIDROS E CERÂMICAS)' },
  { id: '7602 SUPERVISORES NA INDÚSTRIA DO CURTIMENTO', descricao: 'SUPERVISORES NA INDÚSTRIA DO CURTIMENTO' },
  { id: '3732 SUPERVISORES OPERACIONAIS E TÉCNICOS EM MÍDIAS AUDIOVISUAIS', descricao: 'SUPERVISORES OPERACIONAIS E TÉCNICOS EM MÍDIAS AUDIOVISUAIS' },
  { id: '752235 Surfassagista', descricao: 'Surfassagista' },
  { id: '513615 Sushiman', descricao: 'Sushiman' },
  { id: '263105 Swami', descricao: 'Swami' },
  { id: '263110 Swami (missionário)', descricao: 'Swami (missionário)' },
  { id: '2413 TABELIÃES E REGISTRADORES', descricao: 'TABELIÃES E REGISTRADORES' },
  { id: '351415 Tabelião da justiça', descricao: 'Tabelião da justiça' },
  { id: '241305 Tabelião de contratos marítimos', descricao: 'Tabelião de contratos marítimos' },
  { id: '241335 Tabelião de notas', descricao: 'Tabelião de notas' },
  { id: '241340 Tabelião de protesto de letras e títulos', descricao: 'Tabelião de protesto de letras e títulos' },
  { id: '241340 Tabelião de protestos', descricao: 'Tabelião de protestos' },
  { id: '351415 Tabelião substituto', descricao: 'Tabelião substituto' },
  { id: '511115 Taifeiro (exceto militares)', descricao: 'Taifeiro (exceto militares)' },
  { id: '10305 Taifeiro da aeronáutica', descricao: 'Taifeiro da aeronáutica' },
  { id: '10310 Taifeiro do exército', descricao: 'Taifeiro do exército' },
  { id: '10305 Taifeiro-mor da aeronáutica', descricao: 'Taifeiro-mor da aeronáutica' },
  { id: '10310 Taifeiro-mor do exército', descricao: 'Taifeiro-mor do exército' },
  { id: '141205 Talabarteiro - empregador (selas)', descricao: 'Talabarteiro - empregador (selas)' },
  { id: '848525 Talhador de carne', descricao: 'Talhador de carne' },
  { id: '763110 Talhador de roupas', descricao: 'Talhador de roupas' },
  { id: '848510 Talhador em açougue', descricao: 'Talhador em açougue' },
  { id: '848505 Talhador em matadouro', descricao: 'Talhador em matadouro' },
  { id: '771120 Tanoeiro', descricao: 'Tanoeiro' },
  { id: '768110 Tapeceiro a mão (tapetes)', descricao: 'Tapeceiro a mão (tapetes)' },
  { id: '765240 Tapeceiro de autos', descricao: 'Tapeceiro de autos' },
  { id: '765230 Tapeceiro de aviões', descricao: 'Tapeceiro de aviões' },
  { id: '765235 Tapeceiro de móveis', descricao: 'Tapeceiro de móveis' },
  { id: '765240 Tapeceiro de veículos', descricao: 'Tapeceiro de veículos' },
  { id: '991310 Tapeceiro de veículos', descricao: 'Tapeceiro de veículos' },
  { id: '716535 Taqueiro', descricao: 'Taqueiro' },
  { id: '351510 Taquígrafo', descricao: 'Taquígrafo' },
  { id: '351510 Taquígrafo em línguas estrangeiras', descricao: 'Taquígrafo em línguas estrangeiras' },
  { id: '351510 Taquígrafo judiciário', descricao: 'Taquígrafo judiciário' },
  { id: '351510 Taquígrafo parlamentar', descricao: 'Taquígrafo parlamentar' },
  { id: '351510 Taquígrafo revisor', descricao: 'Taquígrafo revisor' },
  { id: '622020 Tarefeiro - na cultura', descricao: 'Tarefeiro - na cultura' },
  { id: '622615 Tarefeiro na cultura de erva-mate', descricao: 'Tarefeiro na cultura de erva-mate' },
  { id: '516805 Tarólogo', descricao: 'Tarólogo' },
  { id: '631020 Tarrafeador na pesca de peixes e camarões', descricao: 'Tarrafeador na pesca de peixes e camarões' },
  { id: '263105 Tata kisaba', descricao: 'Tata kisaba' },
  { id: '263105 Tata nkisi', descricao: 'Tata nkisi' },
  { id: '263105 Tateto ndenge', descricao: 'Tateto ndenge' },
  { id: '141515 Taverneiro', descricao: 'Taverneiro' },
  { id: '782315 Taxeiro', descricao: 'Taxeiro' },
  { id: '328110 Taxidermista', descricao: 'Taxidermista' },
  { id: '782315 Taxista', descricao: 'Taxista' },
  { id: '768105 Tecedor - exclusive de tapetes', descricao: 'Tecedor - exclusive de tapetes' },
  { id: '761303 Tecedor de redes', descricao: 'Tecedor de redes' },
  { id: '768120 Tecedor de redes - inclusive de punho, mamucaba, varanda', descricao: 'Tecedor de redes - inclusive de punho, mamucaba, varanda' },
  { id: '768110 Tecedor de tapetes', descricao: 'Tecedor de tapetes' },
  { id: '761303 Tecelão (redes)', descricao: 'Tecelão (redes)' },
  { id: '761306 Tecelão (rendas e bordados)', descricao: 'Tecelão (rendas e bordados)' },
  { id: '761309 Tecelão (tear automático)', descricao: 'Tecelão (tear automático)' },
  { id: '761312 Tecelão (tear jacquard)', descricao: 'Tecelão (tear jacquard)' },
  { id: '768105 Tecelão (tear manual)', descricao: 'Tecelão (tear manual)' },
  { id: '761315 Tecelão (tear mecânico de maquineta)', descricao: 'Tecelão (tear mecânico de maquineta)' },
  { id: '761318 Tecelão (tear mecânico de xadrez)', descricao: 'Tecelão (tear mecânico de xadrez)' },
  { id: '761321 Tecelão (tear mecânico liso)', descricao: 'Tecelão (tear mecânico liso)' },
  { id: '761324 Tecelão (tear mecânico, exceto jacquard)', descricao: 'Tecelão (tear mecânico, exceto jacquard)' },
  { id: '768105 Tecelão - exclusive de tapetes', descricao: 'Tecelão - exclusive de tapetes' },
  { id: '768110 Tecelão de alfombras, a mão', descricao: 'Tecelão de alfombras, a mão' },
  { id: '761306 Tecelão de bordados', descricao: 'Tecelão de bordados' },
  { id: '761360 Tecelão de fitas', descricao: 'Tecelão de fitas' },
  { id: '761330 Tecelão de malhas (máquina circular)', descricao: 'Tecelão de malhas (máquina circular)' },
  { id: '761333 Tecelão de malhas (máquina retilínea)', descricao: 'Tecelão de malhas (máquina retilínea)' },
  { id: '761327 Tecelão de malhas, a máquina', descricao: 'Tecelão de malhas, a máquina' },
  { id: '768115 Tecelão de malhas, a mão', descricao: 'Tecelão de malhas, a mão' },
  { id: '761339 Tecelão de meias (máquina circular)', descricao: 'Tecelão de meias (máquina circular)' },
  { id: '761342 Tecelão de meias (máquina retilínea)', descricao: 'Tecelão de meias (máquina retilínea)' },
  { id: '761336 Tecelão de meias, a máquina', descricao: 'Tecelão de meias, a máquina' },
  { id: '768120 Tecelão de redes - inclusive de punho, mamucaba, varanda', descricao: 'Tecelão de redes - inclusive de punho, mamucaba, varanda' },
  { id: '761306 Tecelão de rendas', descricao: 'Tecelão de rendas' },
  { id: '768110 Tecelão de tapeçaria em tear manual', descricao: 'Tecelão de tapeçaria em tear manual' },
  { id: '768110 Tecelão de tapetes, a mão', descricao: 'Tecelão de tapetes, a mão' },
  { id: '761345 Tecelão de tapetes, a máquina', descricao: 'Tecelão de tapetes, a máquina' },
  { id: '761360 Tecelão de tear de fita', descricao: 'Tecelão de tear de fita' },
  { id: '513325 Técnica de cinema', descricao: 'Técnica de cinema' },
  { id: '314310 Técnico aeronáutico', descricao: 'Técnico aeronáutico' },
  { id: '321105 Técnico agrícola', descricao: 'Técnico agrícola' },
  { id: '321105 Técnico agrícola executor de operações aéreas agrícolas', descricao: 'Técnico agrícola executor de operações aéreas agrícolas' },
  { id: '321110 Técnico agropecuário', descricao: 'Técnico agropecuário' },
  { id: '321210 Técnico ambiental', descricao: 'Técnico ambiental' },
  { id: '391210 Técnico analista de controle de produtos manufaturados', descricao: 'Técnico analista de controle de produtos manufaturados' },
  { id: '391210 Técnico analista de controle estatístico de qualidade', descricao: 'Técnico analista de controle estatístico de qualidade' },
  { id: '391105 Técnico analista de estudos de tempo', descricao: 'Técnico analista de estudos de tempo' },
  { id: '391135 Técnico analista de materiais', descricao: 'Técnico analista de materiais' },
  { id: '391210 Técnico analista de padrões de qualidade', descricao: 'Técnico analista de padrões de qualidade' },
  { id: '391125 Técnico analista de pcp', descricao: 'Técnico analista de pcp' },
  { id: '391210 Técnico analista de pesquisa de controle de qualidade', descricao: 'Técnico analista de pesquisa de controle de qualidade' },
  { id: '391105 Técnico analista de processos de produção', descricao: 'Técnico analista de processos de produção' },
  { id: '391125 Técnico analista de produção', descricao: 'Técnico analista de produção' },
  { id: '391125 Técnico analista de programação', descricao: 'Técnico analista de programação' },
  { id: '391210 Técnico analista de qualidade', descricao: 'Técnico analista de qualidade' },
  { id: '391210 Técnico analista de recebimento de peças compradas', descricao: 'Técnico analista de recebimento de peças compradas' },
  { id: '314305 Técnico automotivo', descricao: 'Técnico automotivo' },
  { id: '311305 Técnico ceramista', descricao: 'Técnico ceramista' },
  { id: '322105 Técnico corporal em medicina tradicional chinesa', descricao: 'Técnico corporal em medicina tradicional chinesa' },
  { id: '254110 Técnico da receita federal', descricao: 'Técnico da receita federal' },
  { id: '862160 Técnico de abastecimento de combustível de aeronave', descricao: 'Técnico de abastecimento de combustível de aeronave' },
  { id: '311515 Técnico de abastecimento, captação e distribuição de água', descricao: 'Técnico de abastecimento, captação e distribuição de água' },
  { id: '314705 Técnico de acabamento em siderurgia', descricao: 'Técnico de acabamento em siderurgia' },
  { id: '314710 Técnico de aciaria em siderurgia', descricao: 'Técnico de aciaria em siderurgia' },
  { id: '325205 Técnico de alimentos', descricao: 'Técnico de alimentos' },
  { id: '314710 Técnico de alto-forno em siderurgia', descricao: 'Técnico de alto-forno em siderurgia' },
  { id: '301115 Técnico de análise de óleos e graxas', descricao: 'Técnico de análise de óleos e graxas' },
  { id: '311105 Técnico de análise química', descricao: 'Técnico de análise química' },
  { id: '312105 Técnico de analista de custos (construção civil)', descricao: 'Técnico de analista de custos (construção civil)' },
  { id: '322505 Técnico de aparelhos ortopédicos', descricao: 'Técnico de aparelhos ortopédicos' },
  { id: '301205 Técnico de apoio à bioengenharia', descricao: 'Técnico de apoio à bioengenharia' },
  { id: '317210 Técnico de apoio ao usuário de informática (helpdesk)', descricao: 'Técnico de apoio ao usuário de informática (helpdesk)' },
  { id: '395105 Técnico de apoio em pesquisa e desenvolvimento (exceto agropecuário e florestal)', descricao: 'Técnico de apoio em pesquisa e desenvolvimento (exceto agropecuário e florestal)' },
  { id: '395110 Técnico de apoio em pesquisa e desenvolvimento agropecuário florestal', descricao: 'Técnico de apoio em pesquisa e desenvolvimento agropecuário florestal' },
  { id: '395105 Técnico de apoio em pesquisa e desenvolvimento de processos', descricao: 'Técnico de apoio em pesquisa e desenvolvimento de processos' },
  { id: '395105 Técnico de apoio em pesquisa e desenvolvimento de produtos', descricao: 'Técnico de apoio em pesquisa e desenvolvimento de produtos' },
  { id: '395105 Técnico de apoio em pesquisa e desenvolvimento de tecnologias energéticas', descricao: 'Técnico de apoio em pesquisa e desenvolvimento de tecnologias energéticas' },
  { id: '395105 Técnico de apoio em pesquisa e desenvolvimento em telecomunicações', descricao: 'Técnico de apoio em pesquisa e desenvolvimento em telecomunicações' },
  { id: '371210 Técnico de arquivo (museologia)', descricao: 'Técnico de arquivo (museologia)' },
  { id: '371310 Técnico de artes gráficas', descricao: 'Técnico de artes gráficas' },
  { id: '373105 Técnico de áudio (rádio)', descricao: 'Técnico de áudio (rádio)' },
  { id: '311515 Técnico de avaliação de capacidade de estação de tratamento e distribuição de água e esgoto', descricao: 'Técnico de avaliação de capacidade de estação de tratamento e distribuição de água e esgoto' },
  { id: '313215 Técnico de balanças (eletrônicas)', descricao: 'Técnico de balanças (eletrônicas)' },
  { id: '325205 Técnico de bebidas', descricao: 'Técnico de bebidas' },
  { id: '371110 Técnico de biblioteca', descricao: 'Técnico de biblioteca' },
  { id: '320105 Técnico de biotério', descricao: 'Técnico de biotério' },
  { id: '954305 Técnico de calculadora eletrônica', descricao: 'Técnico de calculadora eletrônica' },
  { id: '321310 Técnico de camarão', descricao: 'Técnico de camarão' },
  { id: '373130 Técnico de captação de som', descricao: 'Técnico de captação de som' },
  { id: '325205 Técnico de carnes e derivados', descricao: 'Técnico de carnes e derivados' },
  { id: '311110 Técnico de celulose e papel', descricao: 'Técnico de celulose e papel' },
  { id: '311515 Técnico de central térmica', descricao: 'Técnico de central térmica' },
  { id: '311105 Técnico de ciências químicas', descricao: 'Técnico de ciências químicas' },
  { id: '313110 Técnico de comandos e controle', descricao: 'Técnico de comandos e controle' },
  { id: '313305 Técnico de comunicação de dados', descricao: 'Técnico de comunicação de dados' },
  { id: '313315 Técnico de comutação telefônica', descricao: 'Técnico de comutação telefônica' },
  { id: '312105 Técnico de construção civil', descricao: 'Técnico de construção civil' },
  { id: '312205 Técnico de construção de estradas', descricao: 'Técnico de construção de estradas' },
  { id: '351105 Técnico de contabilidade', descricao: 'Técnico de contabilidade' },
  { id: '252210 Técnico de controladoria', descricao: 'Técnico de controladoria' },
  { id: '314710 Técnico de controle de lingoteira', descricao: 'Técnico de controle de lingoteira' },
  { id: '311505 Técnico de controle de meio ambiente', descricao: 'Técnico de controle de meio ambiente' },
  { id: '313205 Técnico de controle de processo de manutenção eletrônica', descricao: 'Técnico de controle de processo de manutenção eletrônica' },
  { id: '391205 Técnico de controle de qualidade', descricao: 'Técnico de controle de qualidade' },
  { id: '325205 Técnico de controle de qualidade de alimentos', descricao: 'Técnico de controle de qualidade de alimentos' },
  { id: '391220 Técnico de controle interno na produção', descricao: 'Técnico de controle interno na produção' },
  { id: '314710 Técnico de convertedor de aciaria em siderurgia', descricao: 'Técnico de convertedor de aciaria em siderurgia' },
  { id: '954305 Técnico de copiadora', descricao: 'Técnico de copiadora' },
  { id: '311105 Técnico de desenvolvimento de análise química', descricao: 'Técnico de desenvolvimento de análise química' },
  { id: '317110 Técnico de desenvolvimento de sistemas (ti)', descricao: 'Técnico de desenvolvimento de sistemas (ti)' },
  { id: '317110 Técnico de desenvolvimento de software', descricao: 'Técnico de desenvolvimento de software' },
  { id: '224125 Técnico de desporto individual e coletivo (exceto futebol)', descricao: 'Técnico de desporto individual e coletivo (exceto futebol)' },
  { id: '261210 Técnico de documentação', descricao: 'Técnico de documentação' },
  { id: '371110 Técnico de documentação e informação', descricao: 'Técnico de documentação e informação' },
  { id: '312105 Técnico de edificações', descricao: 'Técnico de edificações' },
  { id: '312105 Técnico de edificações, estradas e saneamento', descricao: 'Técnico de edificações, estradas e saneamento' },
  { id: '313130 Técnico de eletricidade', descricao: 'Técnico de eletricidade' },
  { id: '322205 Técnico de enfermagem', descricao: 'Técnico de enfermagem' },
  { id: '322210 Técnico de enfermagem de terapia intensiva', descricao: 'Técnico de enfermagem de terapia intensiva' },
  { id: '322215 Técnico de enfermagem do trabalho', descricao: 'Técnico de enfermagem do trabalho' },
  { id: '322220 Técnico de enfermagem em saúde mental', descricao: 'Técnico de enfermagem em saúde mental' },
  { id: '322215 Técnico de enfermagem em saúde ocupacional', descricao: 'Técnico de enfermagem em saúde ocupacional' },
  { id: '322215 Técnico de enfermagem ocupacional', descricao: 'Técnico de enfermagem ocupacional' },
  { id: '322220 Técnico de enfermagem psiquiátrica', descricao: 'Técnico de enfermagem psiquiátrica' },
  { id: '312105 Técnico de engenharia civil', descricao: 'Técnico de engenharia civil' },
  { id: '313105 Técnico de ensaios elétricos', descricao: 'Técnico de ensaios elétricos' },
  { id: '314610 Técnico de ensaios mecânicos (caldeiraria)', descricao: 'Técnico de ensaios mecânicos (caldeiraria)' },
  { id: '313130 Técnico de equipamentos elétricos', descricao: 'Técnico de equipamentos elétricos' },
  { id: '312205 Técnico de estradas', descricao: 'Técnico de estradas' },
  { id: '373145 Técnico de externas (operações audiovisuais)', descricao: 'Técnico de externas (operações audiovisuais)' },
  { id: '373130 Técnico de externas (sistemas audiovisuais)', descricao: 'Técnico de externas (sistemas audiovisuais)' },
  { id: '311615 Técnico de fiação', descricao: 'Técnico de fiação' },
  { id: '325205 Técnico de frutas e hortaliças', descricao: 'Técnico de frutas e hortaliças' },
  { id: '314715 Técnico de fundição em siderurgia', descricao: 'Técnico de fundição em siderurgia' },
  { id: '391210 Técnico de garantia da qualidade', descricao: 'Técnico de garantia da qualidade' },
  { id: '710115 Técnico de geologia', descricao: 'Técnico de geologia' },
  { id: '311505 Técnico de gestão do meio ambiente', descricao: 'Técnico de gestão do meio ambiente' },
  { id: '325205 Técnico de grãos e cereais', descricao: 'Técnico de grãos e cereais' },
  { id: '374105 Técnico de gravação de áudio', descricao: 'Técnico de gravação de áudio' },
  { id: '351815 Técnico de identificação (papiloscopista)', descricao: 'Técnico de identificação (papiloscopista)' },
  { id: '322605 Técnico de imobilização ortopédica', descricao: 'Técnico de imobilização ortopédica' },
  { id: '954305 Técnico de impressora (matricial)', descricao: 'Técnico de impressora (matricial)' },
  { id: '311205 Técnico de indústria petroquímica', descricao: 'Técnico de indústria petroquímica' },
  { id: '313215 Técnico de indústria eletrônica', descricao: 'Técnico de indústria eletrônica' },
  { id: '311105 Técnico de indústria química', descricao: 'Técnico de indústria química' },
  { id: '314620 Técnico de inspeção da indústria metalúrgica', descricao: 'Técnico de inspeção da indústria metalúrgica' },
  { id: '312205 Técnico de inspeção e especificação de materiais e equipamentos de construção de estradas', descricao: 'Técnico de inspeção e especificação de materiais e equipamentos de construção de estradas' },
  { id: '312210 Técnico de inspeção e especificação de materiais e equipamentos de obras de saneamento', descricao: 'Técnico de inspeção e especificação de materiais e equipamentos de obras de saneamento' },
  { id: '311105 Técnico de laboratório - exclusive análises clínicas', descricao: 'Técnico de laboratório - exclusive análises clínicas' },
  { id: '311105 Técnico de laboratório de água e esgotos', descricao: 'Técnico de laboratório de água e esgotos' },
  { id: '324205 Técnico de laboratório de análises clínicas', descricao: 'Técnico de laboratório de análises clínicas' },
  { id: '301110 Técnico de laboratório de análises físico-químicas (materiais de construção)', descricao: 'Técnico de laboratório de análises físico-químicas (materiais de construção)' },
  { id: '301115 Técnico de laboratório de análises físico-químicas (petróleo)', descricao: 'Técnico de laboratório de análises físico-químicas (petróleo)' },
  { id: '313215 Técnico de laboratório de eletrônica de automação', descricao: 'Técnico de laboratório de eletrônica de automação' },
  { id: '224130 Técnico de laboratório e fiscalização desportiva', descricao: 'Técnico de laboratório e fiscalização desportiva' },
  { id: '324205 Técnico de laboratório em patologia clínica', descricao: 'Técnico de laboratório em patologia clínica' },
  { id: '301105 Técnico de laboratório industrial', descricao: 'Técnico de laboratório industrial' },
  { id: '324205 Técnico de laboratório médico', descricao: 'Técnico de laboratório médico' },
  { id: '314720 Técnico de laminação em siderurgia', descricao: 'Técnico de laminação em siderurgia' },
  { id: '325205 Técnico de laticínios', descricao: 'Técnico de laticínios' },
  { id: '314710 Técnico de lingotamento', descricao: 'Técnico de lingotamento' },
  { id: '314705 Técnico de linhas de acabamento em siderurgia', descricao: 'Técnico de linhas de acabamento em siderurgia' },
  { id: '313315 Técnico de manipulação de tráfego telefônico', descricao: 'Técnico de manipulação de tráfego telefônico' },
  { id: '314310 Técnico de manutenção aeronáutica', descricao: 'Técnico de manutenção aeronáutica' },
  { id: '314310 Técnico de manutenção de aeronaves', descricao: 'Técnico de manutenção de aeronaves' },
  { id: '313205 Técnico de manutenção de computador', descricao: 'Técnico de manutenção de computador' },
  { id: '313315 Técnico de manutenção de equipamento de comutação telefônica', descricao: 'Técnico de manutenção de equipamento de comutação telefônica' },
  { id: '313320 Técnico de manutenção de equipamento de transmissão', descricao: 'Técnico de manutenção de equipamento de transmissão' },
  { id: '312105 Técnico de manutenção de obras', descricao: 'Técnico de manutenção de obras' },
  { id: '314310 Técnico de manutenção de sistemas de aeronaves', descricao: 'Técnico de manutenção de sistemas de aeronaves' },
  { id: '314405 Técnico de manutenção de sistemas e instrumentos', descricao: 'Técnico de manutenção de sistemas e instrumentos' },
  { id: '313120 Técnico de manutenção elétrica', descricao: 'Técnico de manutenção elétrica' },
  { id: '313125 Técnico de manutenção elétrica de máquina', descricao: 'Técnico de manutenção elétrica de máquina' },
  { id: '313205 Técnico de manutenção eletrônica', descricao: 'Técnico de manutenção eletrônica' },
  { id: '313210 Técnico de manutenção eletrônica (circuitos de máquinas com comando numérico)', descricao: 'Técnico de manutenção eletrônica (circuitos de máquinas com comando numérico)' },
  { id: '313120 Técnico de manutenção industrial', descricao: 'Técnico de manutenção industrial' },
  { id: '954305 Técnico de máquina autenticadora', descricao: 'Técnico de máquina autenticadora' },
  { id: '954305 Técnico de máquina de escrever elétrica e manual', descricao: 'Técnico de máquina de escrever elétrica e manual' },
  { id: '954305 Técnico de máquina de escrever eletrônica', descricao: 'Técnico de máquina de escrever eletrônica' },
  { id: '919205 Técnico de máquinas (a explosão)', descricao: 'Técnico de máquinas (a explosão)' },
  { id: '954305 Técnico de máquinas eletrônicas', descricao: 'Técnico de máquinas eletrônicas' },
  { id: '325205 Técnico de massas alimentícias', descricao: 'Técnico de massas alimentícias' },
  { id: '391135 Técnico de matéria-prima e material', descricao: 'Técnico de matéria-prima e material' },
  { id: '316110 Técnico de metalografia e petrografia', descricao: 'Técnico de metalografia e petrografia' },
  { id: '311510 Técnico de meteorologia', descricao: 'Técnico de meteorologia' },
  { id: '316305 Técnico de mineração', descricao: 'Técnico de mineração' },
  { id: '316305 Técnico de mineração (águas subterrâneas)', descricao: 'Técnico de mineração (águas subterrâneas)' },
  { id: '316305 Técnico de mineração (minerais sólidos)', descricao: 'Técnico de mineração (minerais sólidos)' },
  { id: '316310 Técnico de mineração (óleo e petróleo)', descricao: 'Técnico de mineração (óleo e petróleo)' },
  { id: '316110 Técnico de mineração (pesquisa geológica)', descricao: 'Técnico de mineração (pesquisa geológica)' },
  { id: '316305 Técnico de mineração (tóriom zircônio e outros minerais raros)', descricao: 'Técnico de mineração (tóriom zircônio e outros minerais raros)' },
  { id: '374130 Técnico de mixagem', descricao: 'Técnico de mixagem' },
  { id: '374110 Técnico de montagem', descricao: 'Técnico de montagem' },
  { id: '314610 Técnico de montagem (caldeiraria)', descricao: 'Técnico de montagem (caldeiraria)' },
  { id: '312105 Técnico de obras', descricao: 'Técnico de obras' },
  { id: '312105 Técnico de obras civis', descricao: 'Técnico de obras civis' },
  { id: '322505 Técnico de oficina ortopédica', descricao: 'Técnico de oficina ortopédica' },
  { id: '813130 Técnico de operação (química, petroquímica e afins)', descricao: 'Técnico de operação (química, petroquímica e afins)' },
  { id: '342110 Técnico de operação de transporte', descricao: 'Técnico de operação de transporte' },
  { id: '313105 Técnico de operação eletrotécnica', descricao: 'Técnico de operação eletrotécnica' },
  { id: '811005 Técnico de operação em processos químicos e petroquímicos', descricao: 'Técnico de operação em processos químicos e petroquímicos' },
  { id: '316325 Técnico de operação em refino de petróleo e gás', descricao: 'Técnico de operação em refino de petróleo e gás' },
  { id: '342115 Técnico de operações de serviços de máquinas e veículos', descricao: 'Técnico de operações de serviços de máquinas e veículos' },
  { id: '313315 Técnico de operações de telecomunicações', descricao: 'Técnico de operações de telecomunicações' },
  { id: '353205 Técnico de operações e serviços bancários - câmbio', descricao: 'Técnico de operações e serviços bancários - câmbio' },
  { id: '353210 Técnico de operações e serviços bancários - crédito imobiliário', descricao: 'Técnico de operações e serviços bancários - crédito imobiliário' },
  { id: '353215 Técnico de operações e serviços bancários - crédito rural', descricao: 'Técnico de operações e serviços bancários - crédito rural' },
  { id: '353220 Técnico de operações e serviços bancários - leasing', descricao: 'Técnico de operações e serviços bancários - leasing' },
  { id: '353225 Técnico de operações e serviços bancários - renda fixa e variável', descricao: 'Técnico de operações e serviços bancários - renda fixa e variável' },
  { id: '239410 Técnico de orientação profissional', descricao: 'Técnico de orientação profissional' },
  { id: '322505 Técnico de ortopedia', descricao: 'Técnico de ortopedia' },
  { id: '391220 Técnico de painel de controle', descricao: 'Técnico de painel de controle' },
  { id: '374215 Técnico de palco', descricao: 'Técnico de palco' },
  { id: '325205 Técnico de panificação', descricao: 'Técnico de panificação' },
  { id: '316305 Técnico de perfuração (minas)', descricao: 'Técnico de perfuração (minas)' },
  { id: '325205 Técnico de pescado e derivados', descricao: 'Técnico de pescado e derivados' },
  { id: '316110 Técnico de pesquisa mineral', descricao: 'Técnico de pesquisa mineral' },
  { id: '314615 Técnico de planejamento da indústria metalúrgica (estruturas metálicas)', descricao: 'Técnico de planejamento da indústria metalúrgica (estruturas metálicas)' },
  { id: '314620 Técnico de planejamento da indústria metalúrgica (soldagem)', descricao: 'Técnico de planejamento da indústria metalúrgica (soldagem)' },
  { id: '312105 Técnico de planejamento de obras', descricao: 'Técnico de planejamento de obras' },
  { id: '391125 Técnico de planejamento de produção', descricao: 'Técnico de planejamento de produção' },
  { id: '391130 Técnico de planejamento e programação da manutenção', descricao: 'Técnico de planejamento e programação da manutenção' },
  { id: '316325 Técnico de processamento em refino de petróleo e gás', descricao: 'Técnico de processamento em refino de petróleo e gás' },
  { id: '311205 Técnico de processamento industrial (petroquímica)', descricao: 'Técnico de processamento industrial (petroquímica)' },
  { id: '391125 Técnico de processo de fabricação', descricao: 'Técnico de processo de fabricação' },
  { id: '311205 Técnico de processo petroquímico', descricao: 'Técnico de processo petroquímico' },
  { id: '811005 Técnico de processo petroquímico (operador de equipamentos)', descricao: 'Técnico de processo petroquímico (operador de equipamentos)' },
  { id: '311405 Técnico de processos (borracha)', descricao: 'Técnico de processos (borracha)' },
  { id: '311410 Técnico de processos (plástico)', descricao: 'Técnico de processos (plástico)' },
  { id: '316315 Técnico de processos minerais', descricao: 'Técnico de processos minerais' },
  { id: '391125 Técnico de produção', descricao: 'Técnico de produção' },
  { id: '311205 Técnico de produção (gás combustível)', descricao: 'Técnico de produção (gás combustível)' },
  { id: '311205 Técnico de produção (petroquímica)', descricao: 'Técnico de produção (petroquímica)' },
  { id: '325205 Técnico de produção de alimentos', descricao: 'Técnico de produção de alimentos' },
  { id: '313205 Técnico de produção eletrônica', descricao: 'Técnico de produção eletrônica' },
  { id: '316325 Técnico de produção em refino de petróleo', descricao: 'Técnico de produção em refino de petróleo' },
  { id: '321210 Técnico de produção florestal', descricao: 'Técnico de produção florestal' },
  { id: '316310 Técnico de produção na extração de petróleo', descricao: 'Técnico de produção na extração de petróleo' },
  { id: '391125 Técnico de programação de produção', descricao: 'Técnico de programação de produção' },
  { id: '391125 Técnico de programação e controle da produção e expedição', descricao: 'Técnico de programação e controle da produção e expedição' },
  { id: '391130 Técnico de programação e controle de serviço de manutenção', descricao: 'Técnico de programação e controle de serviço de manutenção' },
  { id: '313110 Técnico de projeto (eletrotécnico)', descricao: 'Técnico de projeto (eletrotécnico)' },
  { id: '312205 Técnico de projetos e obras de infra-estrutura de estradas', descricao: 'Técnico de projetos e obras de infra-estrutura de estradas' },
  { id: '313130 Técnico de projetos elétricos', descricao: 'Técnico de projetos elétricos' },
  { id: '314110 Técnico de projetos mecânicos', descricao: 'Técnico de projetos mecânicos' },
  { id: '322505 Técnico de prótese e órtese', descricao: 'Técnico de prótese e órtese' },
  { id: '322505 Técnico de prótese ortopédica', descricao: 'Técnico de prótese ortopédica' },
  { id: '954210 Técnico de rádio (reparo)', descricao: 'Técnico de rádio (reparo)' },
  { id: '313215 Técnico de rádio e televisão', descricao: 'Técnico de rádio e televisão' },
  { id: '324115 Técnico de radioterapia', descricao: 'Técnico de radioterapia' },
  { id: '316305 Técnico de recursos minerais', descricao: 'Técnico de recursos minerais' },
  { id: '313310 Técnico de rede (telecomunicações)', descricao: 'Técnico de rede (telecomunicações)' },
  { id: '312210 Técnico de redes de água e esgoto', descricao: 'Técnico de redes de água e esgoto' },
  { id: '314725 Técnico de redução na siderurgia (primeira fusão)', descricao: 'Técnico de redução na siderurgia (primeira fusão)' },
  { id: '321210 Técnico de reflorestamento', descricao: 'Técnico de reflorestamento' },
  { id: '314730 Técnico de refratário em siderurgia', descricao: 'Técnico de refratário em siderurgia' },
  { id: '314115 Técnico de refrigeração (fabricação)', descricao: 'Técnico de refrigeração (fabricação)' },
  { id: '725705 Técnico de refrigeração (instalação)', descricao: 'Técnico de refrigeração (instalação)' },
  { id: '351735 Técnico de resseguros', descricao: 'Técnico de resseguros' },
  { id: '312210 Técnico de saneamento', descricao: 'Técnico de saneamento' },
  { id: '311505 Técnico de saneamento e controle ambiental', descricao: 'Técnico de saneamento e controle ambiental' },
  { id: '351740 Técnico de seguros', descricao: 'Técnico de seguros' },
  { id: '351710 Técnico de sinistros', descricao: 'Técnico de sinistros' },
  { id: '313215 Técnico de sistema automação industrial', descricao: 'Técnico de sistema automação industrial' },
  { id: '373130 Técnico de sistema de televisão', descricao: 'Técnico de sistema de televisão' },
  { id: '311105 Técnico de sistema de tratamento de água', descricao: 'Técnico de sistema de tratamento de água' },
  { id: '373130 Técnico de sistemas audiovisuais', descricao: 'Técnico de sistemas audiovisuais' },
  { id: '311205 Técnico de sistemas industriais (petroquímica)', descricao: 'Técnico de sistemas industriais (petroquímica)' },
  { id: '351910 Técnico de suporte à inteligência (grupo apoio)', descricao: 'Técnico de suporte à inteligência (grupo apoio)' },
  { id: '317210 Técnico de suporte ao usuário de tecnologia da informação', descricao: 'Técnico de suporte ao usuário de tecnologia da informação' },
  { id: '317210 Técnico de suporte de ti', descricao: 'Técnico de suporte de ti' },
  { id: '374215 Técnico de teatro', descricao: 'Técnico de teatro' },
  { id: '313315 Técnico de telecomunicações (telefonia)', descricao: 'Técnico de telecomunicações (telefonia)' },
  { id: '313315 Técnico de telefonia', descricao: 'Técnico de telefonia' },
  { id: '313305 Técnico de teleprocessamento', descricao: 'Técnico de teleprocessamento' },
  { id: '954210 Técnico de televisão', descricao: 'Técnico de televisão' },
  { id: '391105 Técnico de tempos e métodos', descricao: 'Técnico de tempos e métodos' },
  { id: '313320 Técnico de transmissão (telecomunicações)', descricao: 'Técnico de transmissão (telecomunicações)' },
  { id: '373130 Técnico de transmissor de rádio', descricao: 'Técnico de transmissor de rádio' },
  { id: '312205 Técnico de transporte ferroviário', descricao: 'Técnico de transporte ferroviário' },
  { id: '314620 Técnico de tratamento térmico (metalurgia)', descricao: 'Técnico de tratamento térmico (metalurgia)' },
  { id: '254415 Técnico de tributos estadual', descricao: 'Técnico de tributos estadual' },
  { id: '254420 Técnico de tributos municipal', descricao: 'Técnico de tributos municipal' },
  { id: '314610 Técnico de tubulação (caldeiraria)', descricao: 'Técnico de tubulação (caldeiraria)' },
  { id: '311515 Técnico de utilidade (produção e distribuição de vapor, gases, óleos, combustíveis, energia)', descricao: 'Técnico de utilidade (produção e distribuição de vapor, gases, óleos, combustíveis, energia)' },
  { id: '354135 Técnico de vendas', descricao: 'Técnico de vendas' },
  { id: '319205 Técnico do mobiliário', descricao: 'Técnico do mobiliário' },
  { id: '324135 Técnico do sono', descricao: 'Técnico do sono' },
  { id: '313130 Técnico eletricista', descricao: 'Técnico eletricista' },
  { id: '313215 Técnico eletrônico', descricao: 'Técnico eletrônico' },
  { id: '313210 Técnico eletrônico de manutenção industrial', descricao: 'Técnico eletrônico de manutenção industrial' },
  { id: '313205 Técnico eletrônico de processo', descricao: 'Técnico eletrônico de processo' },
  { id: '313215 Técnico eletrônico em geral', descricao: 'Técnico eletrônico em geral' },
  { id: '325205 Técnico em açúcar e álcool', descricao: 'Técnico em açúcar e álcool' },
  { id: '322105 Técnico em acupuntura', descricao: 'Técnico em acupuntura' },
  { id: '351305 Técnico em administração', descricao: 'Técnico em administração' },
  { id: '351310 Técnico em administração de comércio exterior', descricao: 'Técnico em administração de comércio exterior' },
  { id: '322255 Técnico em agente comunitário de saúde', descricao: 'Técnico em agente comunitário de saúde' },
  { id: '321105 Técnico em agricultura', descricao: 'Técnico em agricultura' },
  { id: '312305 Técnico em agrimensura', descricao: 'Técnico em agrimensura' },
  { id: '321110 Técnico em agropecuária', descricao: 'Técnico em agropecuária' },
  { id: '391125 Técnico em análise de controle de produção', descricao: 'Técnico em análise de controle de produção' },
  { id: '324205 Técnico em análises clínicas', descricao: 'Técnico em análises clínicas' },
  { id: '322605 Técnico em aparelho gessado', descricao: 'Técnico em aparelho gessado' },
  { id: '325010 Técnico em aromas', descricao: 'Técnico em aromas' },
  { id: '354140 Técnico em atendimento e vendas', descricao: 'Técnico em atendimento e vendas' },
  { id: '300105 Técnico em automação industrial', descricao: 'Técnico em automação industrial' },
  { id: '314305 Técnico em automobilística', descricao: 'Técnico em automobilística' },
  { id: '323105 Técnico em avicultura', descricao: 'Técnico em avicultura' },
  { id: '324220 Técnico em banco de sangue', descricao: 'Técnico em banco de sangue' },
  { id: '371110 Técnico em biblioteconomia', descricao: 'Técnico em biblioteconomia' },
  { id: '325305 Técnico em biotecnologia', descricao: 'Técnico em biotecnologia' },
  { id: '320105 Técnico em bioterismo', descricao: 'Técnico em bioterismo' },
  { id: '311405 Técnico em borracha', descricao: 'Técnico em borracha' },
  { id: '319105 Técnico em calçados e artefatos de couro', descricao: 'Técnico em calçados e artefatos de couro' },
  { id: '314610 Técnico em caldeiraria', descricao: 'Técnico em caldeiraria' },
  { id: '313405 Técnico em calibração', descricao: 'Técnico em calibração' },
  { id: '312105 Técnico em canteiro de obras de construção civil', descricao: 'Técnico em canteiro de obras de construção civil' },
  { id: '324105 Técnico em captações bioelétricas do cérebro', descricao: 'Técnico em captações bioelétricas do cérebro' },
  { id: '321310 Técnico em carcinicultura', descricao: 'Técnico em carcinicultura' },
  { id: '311305 Técnico em cerâmica', descricao: 'Técnico em cerâmica' },
  { id: '324215 Técnico em citopatologia', descricao: 'Técnico em citopatologia' },
  { id: '319110 Técnico em confecções do vestuário', descricao: 'Técnico em confecções do vestuário' },
  { id: '312210 Técnico em construção civil (obras de saneamento)', descricao: 'Técnico em construção civil (obras de saneamento)' },
  { id: '312205 Técnico em construção civil de obras de infra-estrutura de estradas', descricao: 'Técnico em construção civil de obras de infra-estrutura de estradas' },
  { id: '320105 Técnico em criação de animais de laboratório', descricao: 'Técnico em criação de animais de laboratório' },
  { id: '311115 Técnico em curtimento', descricao: 'Técnico em curtimento' },
  { id: '760205 Técnico em curtimento (supervisor de primeira linha)', descricao: 'Técnico em curtimento (supervisor de primeira linha)' },
  { id: '312105 Técnico em desenho de construção civil', descricao: 'Técnico em desenho de construção civil' },
  { id: '314205 Técnico em desenvolvimento de processos (fabricação de ferramentas)', descricao: 'Técnico em desenvolvimento de processos (fabricação de ferramentas)' },
  { id: '314210 Técnico em desenvolvimento de processos (manutenção de ferramentas)', descricao: 'Técnico em desenvolvimento de processos (manutenção de ferramentas)' },
  { id: '314205 Técnico em desenvolvimento de produtos (ferramentas mecânicas)', descricao: 'Técnico em desenvolvimento de produtos (ferramentas mecânicas)' },
  { id: '352420 Técnico em direitos autorais', descricao: 'Técnico em direitos autorais' },
  { id: '371110 Técnico em documentação', descricao: 'Técnico em documentação' },
  { id: '324110 Técnico em eletrocardiografia', descricao: 'Técnico em eletrocardiografia' },
  { id: '324110 Técnico em eletrocardiograma', descricao: 'Técnico em eletrocardiograma' },
  { id: '324105 Técnico em eletroencefalografia', descricao: 'Técnico em eletroencefalografia' },
  { id: '324105 Técnico em eletroencefalograma', descricao: 'Técnico em eletroencefalograma' },
  { id: '300305 Técnico em eletromecânica', descricao: 'Técnico em eletromecânica' },
  { id: '515135 Técnico em enfermagem socorrista', descricao: 'Técnico em enfermagem socorrista' },
  { id: '325005 Técnico em enologia', descricao: 'Técnico em enologia' },
  { id: '313505 Técnico em equipamentos ópticos', descricao: 'Técnico em equipamentos ópticos' },
  { id: '324130 Técnico em espirometria', descricao: 'Técnico em espirometria' },
  { id: '424125 Técnico em estatística (escriturário)', descricao: 'Técnico em estatística (escriturário)' },
  { id: '322130 Técnico em estética', descricao: 'Técnico em estética' },
  { id: '314615 Técnico em estruturas metálicas', descricao: 'Técnico em estruturas metálicas' },
  { id: '316310 Técnico em exploração de petróleo', descricao: 'Técnico em exploração de petróleo' },
  { id: '325115 Técnico em farmácia', descricao: 'Técnico em farmácia' },
  { id: '3251 TÉCNICO EM FARMÁCIA E EM MANIPULAÇÃO FARMACÊUTICA', descricao: 'TÉCNICO EM FARMÁCIA E EM MANIPULAÇÃO FARMACÊUTICA' },
  { id: '313125 Técnico em ferramentas elétricas', descricao: 'Técnico em ferramentas elétricas' },
  { id: '313505 Técnico em fibras ópticas', descricao: 'Técnico em fibras ópticas' },
  { id: '313505 Técnico em fotônica', descricao: 'Técnico em fotônica' },
  { id: '312310 Técnico em geodésia e cartografia', descricao: 'Técnico em geodésia e cartografia' },
  { id: '316105 Técnico em geofísica', descricao: 'Técnico em geofísica' },
  { id: '316110 Técnico em geologia', descricao: 'Técnico em geologia' },
  { id: '316115 Técnico em geoquímica', descricao: 'Técnico em geoquímica' },
  { id: '316120 Técnico em geotecnia', descricao: 'Técnico em geotecnia' },
  { id: '322605 Técnico em gesso hospitalar', descricao: 'Técnico em gesso hospitalar' },
  { id: '322605 Técnico em gesso ortopédico', descricao: 'Técnico em gesso ortopédico' },
  { id: '374105 Técnico em gravação de áudio', descricao: 'Técnico em gravação de áudio' },
  { id: '322210 Técnico em hemodiálise', descricao: 'Técnico em hemodiálise' },
  { id: '324115 Técnico em hemodinâmica', descricao: 'Técnico em hemodinâmica' },
  { id: '324220 Técnico em hemoterapia', descricao: 'Técnico em hemoterapia' },
  { id: '322205 Técnico em hemotransfusão', descricao: 'Técnico em hemotransfusão' },
  { id: '312315 Técnico em hidrografia', descricao: 'Técnico em hidrografia' },
  { id: '312210 Técnico em hidrometria (obras de saneamento)', descricao: 'Técnico em hidrometria (obras de saneamento)' },
  { id: '351610 Técnico em higiene ocupacional', descricao: 'Técnico em higiene ocupacional' },
  { id: '320110 Técnico em histologia', descricao: 'Técnico em histologia' },
  { id: '322605 Técnico em imobilizações do aparelho locomotor', descricao: 'Técnico em imobilizações do aparelho locomotor' },
  { id: '322605 Técnico em imobilizações gessadas', descricao: 'Técnico em imobilizações gessadas' },
  { id: '325310 Técnico em imunobiológicos', descricao: 'Técnico em imunobiológicos' },
  { id: '311405 Técnico em injeção (borracha)', descricao: 'Técnico em injeção (borracha)' },
  { id: '311410 Técnico em injeção (plástico)', descricao: 'Técnico em injeção (plástico)' },
  { id: '374110 Técnico em instalação de equipamentos de áudio', descricao: 'Técnico em instalação de equipamentos de áudio' },
  { id: '313505 Técnico em instalação de sistemas ópticos', descricao: 'Técnico em instalação de sistemas ópticos' },
  { id: '313410 Técnico em instrumentação', descricao: 'Técnico em instrumentação' },
  { id: '915210 Técnico em instrumentos de sopro', descricao: 'Técnico em instrumentos de sopro' },
  { id: '351910 Técnico em inteligência (grupo informações)', descricao: 'Técnico em inteligência (grupo informações)' },
  { id: '311405 Técnico em laboratório (borracha)', descricao: 'Técnico em laboratório (borracha)' },
  { id: '311410 Técnico em laboratório (plástico)', descricao: 'Técnico em laboratório (plástico)' },
  { id: '325110 Técnico em laboratório de farmácia', descricao: 'Técnico em laboratório de farmácia' },
  { id: '312105 Técnico em laboratório e campo de construção civil', descricao: 'Técnico em laboratório e campo de construção civil' },
  { id: '313505 Técnico em laboratório óptico', descricao: 'Técnico em laboratório óptico' },
  { id: '313505 Técnico em laser', descricao: 'Técnico em laser' },
  { id: '321205 Técnico em madeira', descricao: 'Técnico em madeira' },
  { id: '319205 Técnico em madeira e mobiliário', descricao: 'Técnico em madeira e mobiliário' },
  { id: '321315 Técnico em malococultura', descricao: 'Técnico em malococultura' },
  { id: '324115 Técnico em mamografia', descricao: 'Técnico em mamografia' },
  { id: '725605 Técnico em manutenção aeronáutica', descricao: 'Técnico em manutenção aeronáutica' },
  { id: '914105 Técnico em manutenção de aeronaves', descricao: 'Técnico em manutenção de aeronaves' },
  { id: '915115 Técnico em manutenção de balanças', descricao: 'Técnico em manutenção de balanças' },
  { id: '313220 Técnico em manutenção de equipamentos de informática', descricao: 'Técnico em manutenção de equipamentos de informática' },
  { id: '915305 Técnico em manutenção de equipamentos e instrumentos médico-hospitalares', descricao: 'Técnico em manutenção de equipamentos e instrumentos médico-hospitalares' },
  { id: '915110 Técnico em manutenção de hidrômetros', descricao: 'Técnico em manutenção de hidrômetros' },
  { id: '915105 Técnico em manutenção de instrumentos de medição e precisão', descricao: 'Técnico em manutenção de instrumentos de medição e precisão' },
  { id: '314410 Técnico em manutenção de máquinas', descricao: 'Técnico em manutenção de máquinas' },
  { id: '914110 Técnico em manutenção de sistema hidráulico de aeronaves', descricao: 'Técnico em manutenção de sistema hidráulico de aeronaves' },
  { id: '300105 Técnico em manutenção mecatrônica', descricao: 'Técnico em manutenção mecatrônica' },
  { id: '374115 Técnico em masterização de áudio', descricao: 'Técnico em masterização de áudio' },
  { id: '313505 Técnico em materiais ópticos', descricao: 'Técnico em materiais ópticos' },
  { id: '311305 Técnico em materiais, produtos cerâmicos e vidros', descricao: 'Técnico em materiais, produtos cerâmicos e vidros' },
  { id: '314105 Técnico em mecânica de precisão', descricao: 'Técnico em mecânica de precisão' },
  { id: '300105 Técnico em mecatrônica - automação da manufatura', descricao: 'Técnico em mecatrônica - automação da manufatura' },
  { id: '300110 Técnico em mecatrônica - robótica', descricao: 'Técnico em mecatrônica - robótica' },
  { id: '324115 Técnico em medicina nuclear', descricao: 'Técnico em medicina nuclear' },
  { id: '313505 Técnico em medidas ópticas', descricao: 'Técnico em medidas ópticas' },
  { id: '351605 Técnico em meio ambiente, segurança e saúde', descricao: 'Técnico em meio ambiente, segurança e saúde' },
  { id: '314620 Técnico em metalurgia (soldagem)', descricao: 'Técnico em metalurgia (soldagem)' },
  { id: '314205 Técnico em métodos e processos (fabricação de ferramentas)', descricao: 'Técnico em métodos e processos (fabricação de ferramentas)' },
  { id: '324110 Técnico em métodos eletrográficos em cardiologia', descricao: 'Técnico em métodos eletrográficos em cardiologia' },
  { id: '324105 Técnico em métodos eletrográficos em encefalografia', descricao: 'Técnico em métodos eletrográficos em encefalografia' },
  { id: '324110 Técnico em métodos gráficos em cardiologia', descricao: 'Técnico em métodos gráficos em cardiologia' },
  { id: '324110 Técnico em métodos não invasivos em cardiologia', descricao: 'Técnico em métodos não invasivos em cardiologia' },
  { id: '321315 Técnico em mexilhões', descricao: 'Técnico em mexilhões' },
  { id: '321315 Técnico em mitilicultura', descricao: 'Técnico em mitilicultura' },
  { id: '374130 Técnico em mixagem de áudio', descricao: 'Técnico em mixagem de áudio' },
  { id: '321315 Técnico em moluscos', descricao: 'Técnico em moluscos' },
  { id: '725605 Técnico em montagem aeronáutica', descricao: 'Técnico em montagem aeronáutica' },
  { id: '319205 Técnico em móveis (fabricação)', descricao: 'Técnico em móveis (fabricação)' },
  { id: '319205 Técnico em móveis e esquadrias', descricao: 'Técnico em móveis e esquadrias' },
  { id: '371210 Técnico em museologia', descricao: 'Técnico em museologia' },
  { id: '325210 Técnico em nutrição', descricao: 'Técnico em nutrição' },
  { id: '325210 Técnico em nutrição e dietética', descricao: 'Técnico em nutrição e dietética' },
  { id: '322305 Técnico em óptica e optometria', descricao: 'Técnico em óptica e optometria' },
  { id: '321315 Técnico em ostras', descricao: 'Técnico em ostras' },
  { id: '321110 Técnico em ovinocaprinocultura', descricao: 'Técnico em ovinocaprinocultura' },
  { id: '324205 Técnico em patologia clínica', descricao: 'Técnico em patologia clínica' },
  { id: '323105 Técnico em pecuária', descricao: 'Técnico em pecuária' },
  { id: '316320 Técnico em pesquisa mineral', descricao: 'Técnico em pesquisa mineral' },
  { id: '311205 Técnico em petroquímica', descricao: 'Técnico em petroquímica' },
  { id: '321305 Técnico em piscicultura', descricao: 'Técnico em piscicultura' },
  { id: '316330 Técnico em planejamento de lavra de minas', descricao: 'Técnico em planejamento de lavra de minas' },
  { id: '312205 Técnico em planejamento de obras de infra-estrutura de estradas', descricao: 'Técnico em planejamento de obras de infra-estrutura de estradas' },
  { id: '312210 Técnico em planejamento de obras de infra-estrutura de saneamento', descricao: 'Técnico em planejamento de obras de infra-estrutura de saneamento' },
  { id: '311410 Técnico em plástico', descricao: 'Técnico em plástico' },
  { id: '322110 Técnico em podologia', descricao: 'Técnico em podologia' },
  { id: '313505 Técnico em polimento de superfície óptica', descricao: 'Técnico em polimento de superfície óptica' },
  { id: '324135 Técnico em polissonografia', descricao: 'Técnico em polissonografia' },
  { id: '316325 Técnico em processamento mineral', descricao: 'Técnico em processamento mineral' },
  { id: '316315 Técnico em processamento mineral (exceto petróleo)', descricao: 'Técnico em processamento mineral (exceto petróleo)' },
  { id: '317110 Técnico em programação de computador', descricao: 'Técnico em programação de computador' },
  { id: '371305 Técnico em programação visual', descricao: 'Técnico em programação visual' },
  { id: '374135 Técnico em projeto de aúdio', descricao: 'Técnico em projeto de aúdio' },
  { id: '324130 Técnico em prova de função pulmonar', descricao: 'Técnico em prova de função pulmonar' },
  { id: '325205 Técnico em química de alimentos', descricao: 'Técnico em química de alimentos' },
  { id: '322115 Técnico em quiropraxia', descricao: 'Técnico em quiropraxia' },
  { id: '324115 Técnico em radiologia', descricao: 'Técnico em radiologia' },
  { id: '324115 Técnico em radiologia e imagenologia', descricao: 'Técnico em radiologia e imagenologia' },
  { id: '324115 Técnico em radiologia médica', descricao: 'Técnico em radiologia médica' },
  { id: '324115 Técnico em radiologia odontológica', descricao: 'Técnico em radiologia odontológica' },
  { id: '321320 Técnico em ranicultura', descricao: 'Técnico em ranicultura' },
  { id: '311205 Técnico em refinação de petróleo', descricao: 'Técnico em refinação de petróleo' },
  { id: '415310 Técnico em registro e informação em saúde', descricao: 'Técnico em registro e informação em saúde' },
  { id: '915405 Técnico em reparação de equipamentos fotográficos', descricao: 'Técnico em reparação de equipamentos fotográficos' },
  { id: '915405 Técnico em reparação de máquinas fotográficas', descricao: 'Técnico em reparação de máquinas fotográficas' },
  { id: '324115 Técnico em ressonância magnética', descricao: 'Técnico em ressonância magnética' },
  { id: '322405 Técnico em saúde bucal', descricao: 'Técnico em saúde bucal' },
  { id: '351505 Técnico em secretariado', descricao: 'Técnico em secretariado' },
  { id: '351505 Técnico em secretariado (português)', descricao: 'Técnico em secretariado (português)' },
  { id: '351605 Técnico em segurança do trabalho', descricao: 'Técnico em segurança do trabalho' },
  { id: '351605 Técnico em segurança industrial', descricao: 'Técnico em segurança industrial' },
  { id: '341245 Técnico em sinais navais', descricao: 'Técnico em sinais navais' },
  { id: '341235 Técnico em sinalização náutica', descricao: 'Técnico em sinalização náutica' },
  { id: '314620 Técnico em soldagem', descricao: 'Técnico em soldagem' },
  { id: '312210 Técnico em sondagem (obras de saneamento)', descricao: 'Técnico em sondagem (obras de saneamento)' },
  { id: '374125 Técnico em sonorização', descricao: 'Técnico em sonorização' },
  { id: '261210 Técnico em suporte de documentação', descricao: 'Técnico em suporte de documentação' },
  { id: '915210 Técnico em teclado musical', descricao: 'Técnico em teclado musical' },
  { id: '324115 Técnico em tomografia', descricao: 'Técnico em tomografia' },
  { id: '311520 Técnico em tratamento de efluentes', descricao: 'Técnico em tratamento de efluentes' },
  { id: '354805 Técnico em turismo', descricao: 'Técnico em turismo' },
  { id: '322210 Técnico em uti', descricao: 'Técnico em uti' },
  { id: '311305 Técnico em vidros', descricao: 'Técnico em vidros' },
  { id: '321105 Técnico em vitivinicultura', descricao: 'Técnico em vitivinicultura' },
  { id: '323105 Técnico em zootecnia', descricao: 'Técnico em zootecnia' },
  { id: '322605 Técnico engessador', descricao: 'Técnico engessador' },
  { id: '316315 Técnico especializado em produção mineral', descricao: 'Técnico especializado em produção mineral' },
  { id: '321210 Técnico florestal', descricao: 'Técnico florestal' },
  { id: '322605 Técnico gessista', descricao: 'Técnico gessista' },
  { id: '371310 Técnico gráfico', descricao: 'Técnico gráfico' },
  { id: '311510 Técnico hidrometrista', descricao: 'Técnico hidrometrista' },
  { id: '313205 Técnico instrumentista', descricao: 'Técnico instrumentista' },
  { id: '915105 Técnico instrumentista (manutenção de instrumentos de medição e precisão)', descricao: 'Técnico instrumentista (manutenção de instrumentos de medição e precisão)' },
  { id: '321205 Técnico madeireiro', descricao: 'Técnico madeireiro' },
  { id: '314110 Técnico mecânico', descricao: 'Técnico mecânico' },
  { id: '314310 Técnico mecânico (aeronaves)', descricao: 'Técnico mecânico (aeronaves)' },
  { id: '314115 Técnico mecânico (calefação, ventilação e refrigeração)', descricao: 'Técnico mecânico (calefação, ventilação e refrigeração)' },
  { id: '314315 Técnico mecânico (embarcações)', descricao: 'Técnico mecânico (embarcações)' },
  { id: '314110 Técnico mecânico (energia nuclear)', descricao: 'Técnico mecânico (energia nuclear)' },
  { id: '314110 Técnico mecânico (equipamentos médicos e odontológicos)', descricao: 'Técnico mecânico (equipamentos médicos e odontológicos)' },
  { id: '314120 Técnico mecânico (máquinas)', descricao: 'Técnico mecânico (máquinas)' },
  { id: '314125 Técnico mecânico (motores)', descricao: 'Técnico mecânico (motores)' },
  { id: '725605 Técnico mecânico aeronáutico', descricao: 'Técnico mecânico aeronáutico' },
  { id: '314110 Técnico mecânico de processos', descricao: 'Técnico mecânico de processos' },
  { id: '314115 Técnico mecânico em ar condicionado', descricao: 'Técnico mecânico em ar condicionado' },
  { id: '314110 Técnico mecânico em automação', descricao: 'Técnico mecânico em automação' },
  { id: '314110 Técnico mecânico montador', descricao: 'Técnico mecânico montador' },
  { id: '314205 Técnico mecânico na fabricação de ferramentas', descricao: 'Técnico mecânico na fabricação de ferramentas' },
  { id: '314105 Técnico mecânico na fabricação de máquinas, sistemas e instrumentos', descricao: 'Técnico mecânico na fabricação de máquinas, sistemas e instrumentos' },
  { id: '314210 Técnico mecânico na manutenção de ferramentas', descricao: 'Técnico mecânico na manutenção de ferramentas' },
  { id: '314105 Técnico mecânico na montagem de máquinas, sistemas e instrumentos', descricao: 'Técnico mecânico na montagem de máquinas, sistemas e instrumentos' },
  { id: '314315 Técnico mecânico naval', descricao: 'Técnico mecânico naval' },
  { id: '314310 Técnico montador de aeronaves', descricao: 'Técnico montador de aeronaves' },
  { id: '319205 Técnico moveleiro', descricao: 'Técnico moveleiro' },
  { id: '316310 Técnico na extração de petróleo e gás natural', descricao: 'Técnico na extração de petróleo e gás natural' },
  { id: '314315 Técnico naval', descricao: 'Técnico naval' },
  { id: '391230 Técnico operacional de serviços de correios', descricao: 'Técnico operacional de serviços de correios' },
  { id: '322305 Técnico optometrista', descricao: 'Técnico optometrista' },
  { id: '312105 Técnico orçamentista de obras na construção civil', descricao: 'Técnico orçamentista de obras na construção civil' },
  { id: '322505 Técnico ortopédico', descricao: 'Técnico ortopédico' },
  { id: '311205 Técnico petroquímico', descricao: 'Técnico petroquímico' },
  { id: '311105 Técnico químico', descricao: 'Técnico químico' },
  { id: '311410 Técnico químico (plástico)', descricao: 'Técnico químico (plástico)' },
  { id: '311105 Técnico químico - exclusive análises químicas', descricao: 'Técnico químico - exclusive análises químicas' },
  { id: '301115 Técnico químico de petróleo', descricao: 'Técnico químico de petróleo' },
  { id: '311405 Técnico químico em borracha', descricao: 'Técnico químico em borracha' },
  { id: '311115 Técnico químico em couro', descricao: 'Técnico químico em couro' },
  { id: '311115 Técnico químico em curtimento', descricao: 'Técnico químico em curtimento' },
  { id: '311105 Técnico químico industrial', descricao: 'Técnico químico industrial' },
  { id: '321210 Técnico silvicultor', descricao: 'Técnico silvicultor' },
  { id: '311605 Técnico têxtil', descricao: 'Técnico têxtil' },
  { id: '311610 Técnico têxtil (beneficiamento)', descricao: 'Técnico têxtil (beneficiamento)' },
  { id: '311610 Técnico têxtil (tratamentos químicos)', descricao: 'Técnico têxtil (tratamentos químicos)' },
  { id: '311615 Técnico têxtil de fiação', descricao: 'Técnico têxtil de fiação' },
  { id: '311620 Técnico têxtil de malharia', descricao: 'Técnico têxtil de malharia' },
  { id: '311625 Técnico têxtil de tecelagem', descricao: 'Técnico têxtil de tecelagem' },
  { id: '3211 TÉCNICOS AGRÍCOLAS', descricao: 'TÉCNICOS AGRÍCOLAS' },
  { id: '3519 TÉCNICOS DA INTELIGÊNCIA', descricao: 'TÉCNICOS DA INTELIGÊNCIA' },
  { id: '3012 TÉCNICOS DE APOIO À BIOENGENHARIA', descricao: 'TÉCNICOS DE APOIO À BIOENGENHARIA' },
  { id: '3253 TÉCNICOS DE APOIO À BIOTECNOLOGIA', descricao: 'TÉCNICOS DE APOIO À BIOTECNOLOGIA' },
  { id: '3951 TÉCNICOS DE APOIO EM PESQUISA E DESENVOLVIMENTO', descricao: 'TÉCNICOS DE APOIO EM PESQUISA E DESENVOLVIMENTO' },
  { id: '3912 TÉCNICOS DE CONTROLE DA PRODUÇÃO', descricao: 'TÉCNICOS DE CONTROLE DA PRODUÇÃO' },
  { id: '3171 TÉCNICOS DE DESENVOLVIMENTO DE SISTEMAS E APLICAÇÕES', descricao: 'TÉCNICOS DE DESENVOLVIMENTO DE SISTEMAS E APLICAÇÕES' },
  { id: '3226 TÉCNICOS DE IMOBILIZAÇÕES ORTOPÉDICAS', descricao: 'TÉCNICOS DE IMOBILIZAÇÕES ORTOPÉDICAS' },
  { id: '3011 TÉCNICOS DE LABORATÓRIO INDUSTRIAL', descricao: 'TÉCNICOS DE LABORATÓRIO INDUSTRIAL' },
  { id: '3242 TÉCNICOS DE LABORATÓRIOS DE SAÚDE E BANCOS DE SANGUE', descricao: 'TÉCNICOS DE LABORATÓRIOS DE SAÚDE E BANCOS DE SANGUE' },
  { id: '3224 TÉCNICOS DE ODONTOLOGIA', descricao: 'TÉCNICOS DE ODONTOLOGIA' },
  { id: '3731 TÉCNICOS DE OPERAÇÃO DE REGISTROS SONORO/AUDIOVISUAIS', descricao: 'TÉCNICOS DE OPERAÇÃO DE REGISTROS SONORO/AUDIOVISUAIS' },
  { id: '3112 TÉCNICOS DE PRODUÇÃO DE INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS, REFINO DE PETRÓLEO, GÁS E AFINS', descricao: 'TÉCNICOS DE PRODUÇÃO DE INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS, REFINO DE PETRÓLEO, GÁS E AFINS' },
  { id: '3517 TÉCNICOS DE SEGUROS E AFINS', descricao: 'TÉCNICOS DE SEGUROS E AFINS' },
  { id: '3172 TÉCNICOS DE SUPORTE E MONITORAÇÃO AO USUÁRIO DE TECNOLOGIA DA INFORMAÇÃO.', descricao: 'TÉCNICOS DE SUPORTE E MONITORAÇÃO AO USUÁRIO DE TECNOLOGIA DA INFORMAÇÃO.' },
  { id: '3192 TÉCNICOS DO MOBILIÁRIO E AFINS', descricao: 'TÉCNICOS DO MOBILIÁRIO E AFINS' },
  { id: '3191 TÉCNICOS DO VESTUÁRIO', descricao: 'TÉCNICOS DO VESTUÁRIO' },
  { id: '3222 TÉCNICOS E AUXILIARES DE ENFERMAGEM', descricao: 'TÉCNICOS E AUXILIARES DE ENFERMAGEM' },
  { id: '3513 TÉCNICOS EM ADMINISTRAÇÃO', descricao: 'TÉCNICOS EM ADMINISTRAÇÃO' },
  { id: '3213 TÉCNICOS EM AQÜICULTURA', descricao: 'TÉCNICOS EM AQÜICULTURA' },
  { id: '3713 TÉCNICOS EM ARTES GRÁFICAS', descricao: 'TÉCNICOS EM ARTES GRÁFICAS' },
  { id: '3741 TÉCNICOS EM ÁUDIO', descricao: 'TÉCNICOS EM ÁUDIO' },
  { id: '3711 TÉCNICOS EM BIBLIOTECONOMIA', descricao: 'TÉCNICOS EM BIBLIOTECONOMIA' },
  { id: '3201 TÉCNICOS EM BIOLOGIA', descricao: 'TÉCNICOS EM BIOLOGIA' },
  { id: '3134 TÉCNICOS EM CALIBRAÇÃO E INSTRUMENTAÇÃO', descricao: 'TÉCNICOS EM CALIBRAÇÃO E INSTRUMENTAÇÃO' },
  { id: '3742 TÉCNICOS EM CENOGRAFIA', descricao: 'TÉCNICOS EM CENOGRAFIA' },
  { id: '3121 TÉCNICOS EM CONSTRUÇÃO CIVIL (EDIFICAÇÕES)', descricao: 'TÉCNICOS EM CONSTRUÇÃO CIVIL (EDIFICAÇÕES)' },
  { id: '3122 TÉCNICOS EM CONSTRUÇÃO CIVIL (OBRAS DE INFRAESTRUTURA)', descricao: 'TÉCNICOS EM CONSTRUÇÃO CIVIL (OBRAS DE INFRAESTRUTURA)' },
  { id: '3511 TÉCNICOS EM CONTABILIDADE', descricao: 'TÉCNICOS EM CONTABILIDADE' },
  { id: '3115 TÉCNICOS EM CONTROLE AMBIENTAL, UTILIDADES E TRATAMENTO DE EFLUENTES', descricao: 'TÉCNICOS EM CONTROLE AMBIENTAL, UTILIDADES E TRATAMENTO DE EFLUENTES' },
  { id: '3131 TÉCNICOS EM ELETRICIDADE E ELETROTÉCNICA', descricao: 'TÉCNICOS EM ELETRICIDADE E ELETROTÉCNICA' },
  { id: '3003 TÉCNICOS EM ELETROMECÂNICA', descricao: 'TÉCNICOS EM ELETROMECÂNICA' },
  { id: '3132 TÉCNICOS EM ELETRÔNICA', descricao: 'TÉCNICOS EM ELETRÔNICA' },
  { id: '3114 TÉCNICOS EM FABRICAÇÃO DE PRODUTOS PLÁSTICOS E DE BORRACHA', descricao: 'TÉCNICOS EM FABRICAÇÃO DE PRODUTOS PLÁSTICOS E DE BORRACHA' },
  { id: '3135 TÉCNICOS EM FOTÔNICA', descricao: 'TÉCNICOS EM FOTÔNICA' },
  { id: '3161 TÉCNICOS EM GEOLOGIA', descricao: 'TÉCNICOS EM GEOLOGIA' },
  { id: '3123 TÉCNICOS EM GEOMÁTICA', descricao: 'TÉCNICOS EM GEOMÁTICA' },
  { id: '341240 Técnicos em manobras em equipamentos de convés', descricao: 'Técnicos em manobras em equipamentos de convés' },
  { id: '9153 TÉCNICOS EM MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS BIOMÉDICOS', descricao: 'TÉCNICOS EM MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS BIOMÉDICOS' },
  { id: '9151 TÉCNICOS EM MANUTENÇÃO E REPARAÇÃO DE INSTRUMENTOS DE MEDIÇÃO E PRECISÃO', descricao: 'TÉCNICOS EM MANUTENÇÃO E REPARAÇÃO DE INSTRUMENTOS DE MEDIÇÃO E PRECISÃO' },
  { id: '3113 TÉCNICOS EM MATERIAIS, PRODUTOS CERÂMICOS E VIDROS', descricao: 'TÉCNICOS EM MATERIAIS, PRODUTOS CERÂMICOS E VIDROS' },
  { id: '3143 TÉCNICOS EM MECÂNICA VEICULAR', descricao: 'TÉCNICOS EM MECÂNICA VEICULAR' },
  { id: '3001 TÉCNICOS EM MECATRÔNICA', descricao: 'TÉCNICOS EM MECATRÔNICA' },
  { id: '3146 TÉCNICOS EM METALURGIA (ESTRUTURAS METÁLICAS)', descricao: 'TÉCNICOS EM METALURGIA (ESTRUTURAS METÁLICAS)' },
  { id: '3163 TÉCNICOS EM MINERAÇÃO', descricao: 'TÉCNICOS EM MINERAÇÃO' },
  { id: '3744 TÉCNICOS EM MONTAGEM, EDIÇÃO E FINALIZAÇÃO DE MÍDIA AUDIOVISUAL', descricao: 'TÉCNICOS EM MONTAGEM, EDIÇÃO E FINALIZAÇÃO DE MÍDIA AUDIOVISUAL' },
  { id: '3712 TÉCNICOS EM MUSEOLOGIA E AFINS', descricao: 'TÉCNICOS EM MUSEOLOGIA E AFINS' },
  { id: '3281 TÉCNICOS EM NECRÓPSIA E TAXIDERMISTAS', descricao: 'TÉCNICOS EM NECRÓPSIA E TAXIDERMISTAS' },
  { id: '3743 TÉCNICOS EM OPERAÇÃO DE APARELHOS DE PROJEÇÃO', descricao: 'TÉCNICOS EM OPERAÇÃO DE APARELHOS DE PROJEÇÃO' },
  { id: '3532 TÉCNICOS EM OPERAÇÕES E SERVIÇOS BANCÁRIOS', descricao: 'TÉCNICOS EM OPERAÇÕES E SERVIÇOS BANCÁRIOS' },
  { id: '3223 TÉCNICOS EM ÓPTICA E OPTOMETRIA', descricao: 'TÉCNICOS EM ÓPTICA E OPTOMETRIA' },
  { id: '3231 TÉCNICOS EM PECUÁRIA', descricao: 'TÉCNICOS EM PECUÁRIA' },
  { id: '3252 TÉCNICOS EM PRODUÇÃO, CONSERVAÇÃO E DE QUALIDADE DE ALIMENTOS', descricao: 'TÉCNICOS EM PRODUÇÃO, CONSERVAÇÃO E DE QUALIDADE DE ALIMENTOS' },
  { id: '3225 TÉCNICOS EM PRÓTESES ORTOPÉDICAS', descricao: 'TÉCNICOS EM PRÓTESES ORTOPÉDICAS' },
  { id: '3515 TÉCNICOS EM SECRETARIADO, TAQUÍGRAFOS E ESTENOTIPISTAS', descricao: 'TÉCNICOS EM SECRETARIADO, TAQUÍGRAFOS E ESTENOTIPISTAS' },
  { id: '3516 TÉCNICOS EM SEGURANÇA DO TRABALHO', descricao: 'TÉCNICOS EM SEGURANÇA DO TRABALHO' },
  { id: '3548 TÉCNICOS EM SERVIÇOS DE TURISMO E ORGANIZAÇÃO DE EVENTOS', descricao: 'TÉCNICOS EM SERVIÇOS DE TURISMO E ORGANIZAÇÃO DE EVENTOS' },
  { id: '3147 TÉCNICOS EM SIDERURGIA', descricao: 'TÉCNICOS EM SIDERURGIA' },
  { id: '3133 TÉCNICOS EM TELECOMUNICAÇÕES', descricao: 'TÉCNICOS EM TELECOMUNICAÇÕES' },
  { id: '3425 TÉCNICOS EM TRANSPORTES AÉREOS', descricao: 'TÉCNICOS EM TRANSPORTES AÉREOS' },
  { id: '3424 TÉCNICOS EM TRANSPORTES METROFERROVIÁRIOS', descricao: 'TÉCNICOS EM TRANSPORTES METROFERROVIÁRIOS' },
  { id: '3426 TÉCNICOS EM TRANSPORTES POR VIAS NAVEGÁVEIS E OPERAÇÕES PORTUÁRIAS', descricao: 'TÉCNICOS EM TRANSPORTES POR VIAS NAVEGÁVEIS E OPERAÇÕES PORTUÁRIAS' },
  { id: '3423 TÉCNICOS EM TRANSPORTES RODOVIÁRIOS', descricao: 'TÉCNICOS EM TRANSPORTES RODOVIÁRIOS' },
  { id: '3212 TÉCNICOS FLORESTAIS', descricao: 'TÉCNICOS FLORESTAIS' },
  { id: '3413 TÉCNICOS MARÍTIMOS E FLUVIÁRIOS DE MÁQUINAS', descricao: 'TÉCNICOS MARÍTIMOS E FLUVIÁRIOS DE MÁQUINAS' },
  { id: '3412 TÉCNICOS MARÍTIMOS, FLUVIÁRIOS E PESCADORES DE CONVÉS', descricao: 'TÉCNICOS MARÍTIMOS, FLUVIÁRIOS E PESCADORES DE CONVÉS' },
  { id: '3142 TÉCNICOS MECÂNICOS (FERRAMENTAS)', descricao: 'TÉCNICOS MECÂNICOS (FERRAMENTAS)' },
  { id: '3141 TÉCNICOS MECÂNICOS NA FABRICAÇÃO E MONTAGEM DE MÁQUINAS, SISTEMAS E INSTRUMENTOS', descricao: 'TÉCNICOS MECÂNICOS NA FABRICAÇÃO E MONTAGEM DE MÁQUINAS, SISTEMAS E INSTRUMENTOS' },
  { id: '3144 TÉCNICOS MECÂNICOS NA MANUTENÇÃO DE MÁQUINAS, SISTEMAS E INSTRUMENTOS', descricao: 'TÉCNICOS MECÂNICOS NA MANUTENÇÃO DE MÁQUINAS, SISTEMAS E INSTRUMENTOS' },
  { id: '3111 TÉCNICOS QUÍMICOS', descricao: 'TÉCNICOS QUÍMICOS' },
  { id: '3116 TÉCNICOS TÊXTEIS', descricao: 'TÉCNICOS TÊXTEIS' },
  { id: '222105 Tecnólogo de engenharia rural', descricao: 'Tecnólogo de engenharia rural' },
  { id: '354820 Tecnólogo de evento', descricao: 'Tecnólogo de evento' },
  { id: '222215 Tecnólogo em agroindústria', descricao: 'Tecnólogo em agroindústria' },
  { id: '222125 Tecnólogo em agronegócio', descricao: 'Tecnólogo em agronegócio' },
  { id: '222125 Tecnólogo em agronegócio digital', descricao: 'Tecnólogo em agronegócio digital' },
  { id: '222215 Tecnólogo em alimentos', descricao: 'Tecnólogo em alimentos' },
  { id: '212405 Tecnólogo em análise de desenvolvimento de sistema', descricao: 'Tecnólogo em análise de desenvolvimento de sistema' },
  { id: '202120 Tecnólogo em automação', descricao: 'Tecnólogo em automação' },
  { id: '202120 Tecnólogo em automação industrial', descricao: 'Tecnólogo em automação industrial' },
  { id: '212305 Tecnólogo em banco de dados', descricao: 'Tecnólogo em banco de dados' },
  { id: '214280 Tecnólogo em construção civil', descricao: 'Tecnólogo em construção civil' },
  { id: '214280 Tecnólogo em construção civil-modalidade edifícios', descricao: 'Tecnólogo em construção civil-modalidade edifícios' },
  { id: '214280 Tecnólogo em construção civil-modalidade hidráulica', descricao: 'Tecnólogo em construção civil-modalidade hidráulica' },
  { id: '214280 Tecnólogo em construção civil-modalidade movimento de terra e pavimentação', descricao: 'Tecnólogo em construção civil-modalidade movimento de terra e pavimentação' },
  { id: '214280 Tecnólogo em construção de edifícios', descricao: 'Tecnólogo em construção de edifícios' },
  { id: '214280 Tecnólogo em controle de obras', descricao: 'Tecnólogo em controle de obras' },
  { id: '322130 Tecnólogo em cosmetologia e estética', descricao: 'Tecnólogo em cosmetologia e estética' },
  { id: '322130 Tecnólogo em cosmetologia e estética facial e corporal', descricao: 'Tecnólogo em cosmetologia e estética facial e corporal' },
  { id: '262420 Tecnólogo em design de jóias', descricao: 'Tecnólogo em design de jóias' },
  { id: '262425 Tecnólogo em design de moda', descricao: 'Tecnólogo em design de moda' },
  { id: '262420 Tecnólogo em design de móveis', descricao: 'Tecnólogo em design de móveis' },
  { id: '262420 Tecnólogo em design de produtos', descricao: 'Tecnólogo em design de produtos' },
  { id: '262410 Tecnólogo em design gráfico', descricao: 'Tecnólogo em design gráfico' },
  { id: '214280 Tecnólogo em edificações', descricao: 'Tecnólogo em edificações' },
  { id: '214360 Tecnólogo em eletricidade', descricao: 'Tecnólogo em eletricidade' },
  { id: '214365 Tecnólogo em eletrônica', descricao: 'Tecnólogo em eletrônica' },
  { id: '325005 Tecnólogo em enologia', descricao: 'Tecnólogo em enologia' },
  { id: '322130 Tecnólogo em estética', descricao: 'Tecnólogo em estética' },
  { id: '322130 Tecnólogo em estética corporal, facial e capilar', descricao: 'Tecnólogo em estética corporal, facial e capilar' },
  { id: '322130 Tecnólogo em estética e cosmética', descricao: 'Tecnólogo em estética e cosmética' },
  { id: '214280 Tecnólogo em estradas', descricao: 'Tecnólogo em estradas' },
  { id: '214435 Tecnólogo em fabricação mecânica', descricao: 'Tecnólogo em fabricação mecânica' },
  { id: '271110 Tecnólogo em gastronomia', descricao: 'Tecnólogo em gastronomia' },
  { id: '142120 Tecnólogo em gestão administrativo- financeira', descricao: 'Tecnólogo em gestão administrativo- financeira' },
  { id: '214010 Tecnólogo em gestão ambiental', descricao: 'Tecnólogo em gestão ambiental' },
  { id: '142535 Tecnólogo em gestão da tecnologia da informação', descricao: 'Tecnólogo em gestão da tecnologia da informação' },
  { id: '142120 Tecnólogo em gestão de cooperativas', descricao: 'Tecnólogo em gestão de cooperativas' },
  { id: '252605 Tecnólogo em gestão de segurança empresarial', descricao: 'Tecnólogo em gestão de segurança empresarial' },
  { id: '252605 Tecnólogo em gestão de segurança privada', descricao: 'Tecnólogo em gestão de segurança privada' },
  { id: '142535 Tecnólogo em gestão de sistema de informação', descricao: 'Tecnólogo em gestão de sistema de informação' },
  { id: '141525 Tecnólogo em gestão de turismo', descricao: 'Tecnólogo em gestão de turismo' },
  { id: '214930 Tecnólogo em gestão dos processos produtivos do vestuário', descricao: 'Tecnólogo em gestão dos processos produtivos do vestuário' },
  { id: '142115 Tecnólogo em gestão financeira', descricao: 'Tecnólogo em gestão financeira' },
  { id: '131215 Tecnólogo em gestão hospitalar', descricao: 'Tecnólogo em gestão hospitalar' },
  { id: '142120 Tecnólogo em gestão pública', descricao: 'Tecnólogo em gestão pública' },
  { id: '141505 Tecnólogo em hotelaria', descricao: 'Tecnólogo em hotelaria' },
  { id: '222215 Tecnólogo em laticínios', descricao: 'Tecnólogo em laticínios' },
  { id: '342125 Tecnólogo em logística de transporte', descricao: 'Tecnólogo em logística de transporte' },
  { id: '214435 Tecnólogo em mecânica', descricao: 'Tecnólogo em mecânica' },
  { id: '202115 Tecnólogo em mecatrônica', descricao: 'Tecnólogo em mecatrônica' },
  { id: '202115 Tecnólogo em mecatrônica industrial', descricao: 'Tecnólogo em mecatrônica industrial' },
  { id: '214010 Tecnólogo em meio ambiente', descricao: 'Tecnólogo em meio ambiente' },
  { id: '214615 Tecnólogo em metalurgia', descricao: 'Tecnólogo em metalurgia' },
  { id: '324125 Tecnólogo em oftalmologia', descricao: 'Tecnólogo em oftalmologia' },
  { id: '214745 Tecnólogo em petróleo e gás', descricao: 'Tecnólogo em petróleo e gás' },
  { id: '222215 Tecnólogo em processamento de carnes', descricao: 'Tecnólogo em processamento de carnes' },
  { id: '212405 Tecnólogo em processamento de dados', descricao: 'Tecnólogo em processamento de dados' },
  { id: '214435 Tecnólogo em processo de produção', descricao: 'Tecnólogo em processo de produção' },
  { id: '214435 Tecnólogo em processo de produção e usinagem', descricao: 'Tecnólogo em processo de produção e usinagem' },
  { id: '214010 Tecnólogo em processos ambientais', descricao: 'Tecnólogo em processos ambientais' },
  { id: '214615 Tecnólogo em processos metalúrgicos', descricao: 'Tecnólogo em processos metalúrgicos' },
  { id: '213215 Tecnólogo em processos químicos', descricao: 'Tecnólogo em processos químicos' },
  { id: '213215 Tecnólogo em processos químicos industriais', descricao: 'Tecnólogo em processos químicos industriais' },
  { id: '262135 Tecnólogo em produção audiovisual', descricao: 'Tecnólogo em produção audiovisual' },
  { id: '262135 Tecnólogo em produção audiovisual( cinema e vídeo)', descricao: 'Tecnólogo em produção audiovisual( cinema e vídeo)' },
  { id: '262135 Tecnólogo em produção audiovisual( rádio e tv)', descricao: 'Tecnólogo em produção audiovisual( rádio e tv)' },
  { id: '214535 Tecnólogo em produção de açúcar e álcool', descricao: 'Tecnólogo em produção de açúcar e álcool' },
  { id: '262130 Tecnólogo em produção de música eletrônica', descricao: 'Tecnólogo em produção de música eletrônica' },
  { id: '214930 Tecnólogo em produção de vestuário', descricao: 'Tecnólogo em produção de vestuário' },
  { id: '262130 Tecnólogo em produção fonográfica', descricao: 'Tecnólogo em produção fonográfica' },
  { id: '214930 Tecnólogo em produção gráfica', descricao: 'Tecnólogo em produção gráfica' },
  { id: '214930 Tecnólogo em produção industrial', descricao: 'Tecnólogo em produção industrial' },
  { id: '214930 Tecnólogo em produção joalheira', descricao: 'Tecnólogo em produção joalheira' },
  { id: '214930 Tecnólogo em produção moveleira', descricao: 'Tecnólogo em produção moveleira' },
  { id: '262135 Tecnólogo em produção multimídia', descricao: 'Tecnólogo em produção multimídia' },
  { id: '262130 Tecnólogo em produção musical', descricao: 'Tecnólogo em produção musical' },
  { id: '214535 Tecnólogo em produção sulcroalcooleira', descricao: 'Tecnólogo em produção sulcroalcooleira' },
  { id: '324120 Tecnólogo em radiologia', descricao: 'Tecnólogo em radiologia' },
  { id: '212310 Tecnólogo em redes de computadores', descricao: 'Tecnólogo em redes de computadores' },
  { id: '214370 Tecnólogo em redes de telecomunicações', descricao: 'Tecnólogo em redes de telecomunicações' },
  { id: '214750 Tecnólogo em rochas ornamentais', descricao: 'Tecnólogo em rochas ornamentais' },
  { id: '214010 Tecnólogo em saneamento ambiental', descricao: 'Tecnólogo em saneamento ambiental' },
  { id: '142710 Tecnólogo em saúde', descricao: 'Tecnólogo em saúde' },
  { id: '252305 Tecnólogo em secretariado', descricao: 'Tecnólogo em secretariado' },
  { id: '252320 Tecnólogo em secretariado escolar', descricao: 'Tecnólogo em secretariado escolar' },
  { id: '212320 Tecnólogo em segurança da informação', descricao: 'Tecnólogo em segurança da informação' },
  { id: '214935 Tecnólogo em segurança do trabalho', descricao: 'Tecnólogo em segurança do trabalho' },
  { id: '142710 Tecnólogo em sistemas biomédicos', descricao: 'Tecnólogo em sistemas biomédicos' },
  { id: '214370 Tecnólogo em sistemas de telecomunicações', descricao: 'Tecnólogo em sistemas de telecomunicações' },
  { id: '212405 Tecnólogo em sistemas para internet', descricao: 'Tecnólogo em sistemas para internet' },
  { id: '314625 Tecnólogo em soldagem', descricao: 'Tecnólogo em soldagem' },
  { id: '214370 Tecnólogo em telecomunicações', descricao: 'Tecnólogo em telecomunicações' },
  { id: '214370 Tecnólogo em telemática', descricao: 'Tecnólogo em telemática' },
  { id: '325005 Tecnólogo em viticultura e enologia', descricao: 'Tecnólogo em viticultura e enologia' },
  { id: '214930 Tecnólogo gráfico', descricao: 'Tecnólogo gráfico' },
  { id: '214615 Tecnólogo metalurgista', descricao: 'Tecnólogo metalurgista' },
  { id: '324125 Tecnólogo oftálmico', descricao: 'Tecnólogo oftálmico' },
  { id: '3241 TECNÓLOGOS E TÉCNICOS EM MÉTODOS DE DIAGNÓSTICOS E TERAPÊUTICA', descricao: 'TECNÓLOGOS E TÉCNICOS EM MÉTODOS DE DIAGNÓSTICOS E TERAPÊUTICA' },
  { id: '3221 TECNÓLOGOS E TÉCNICOS EM TERAPIAS COMPLEMENTARES E ESTÉTICAS', descricao: 'TECNÓLOGOS E TÉCNICOS EM TERAPIAS COMPLEMENTARES E ESTÉTICAS' },
  { id: '422330 Teleatendente de emergência', descricao: 'Teleatendente de emergência' },
  { id: '262505 Teleator', descricao: 'Teleator' },
  { id: '262505 Teleatriz', descricao: 'Teleatriz' },
  { id: '422205 Telefonista', descricao: 'Telefonista' },
  { id: '422205 Telefonista bilíngüe', descricao: 'Telefonista bilíngüe' },
  { id: '422335 Telefonista-líder', descricao: 'Telefonista-líder' },
  { id: '422335 Telefonista-monitor', descricao: 'Telefonista-monitor' },
  { id: '422210 Teleoperador', descricao: 'Teleoperador' },
  { id: '422305 Teleoperador ativo (telemarketing)', descricao: 'Teleoperador ativo (telemarketing)' },
  { id: '422320 Teleoperador de apoio (telemarketing)', descricao: 'Teleoperador de apoio (telemarketing)' },
  { id: '422310 Teleoperador de marketing (híbrido)', descricao: 'Teleoperador de marketing (híbrido)' },
  { id: '422320 Teleoperador de suporte técnico (telemarketing)', descricao: 'Teleoperador de suporte técnico (telemarketing)' },
  { id: '422315 Teleoperador receptivo (telemarketing)', descricao: 'Teleoperador receptivo (telemarketing)' },
  { id: '716205 Telhador (telhas de argila e materiais similares)', descricao: 'Telhador (telhas de argila e materiais similares)' },
  { id: '716210 Telhador (telhas de cimento-amianto)', descricao: 'Telhador (telhas de cimento-amianto)' },
  { id: '716215 Telhador (telhas metálicas)', descricao: 'Telhador (telhas metálicas)' },
  { id: '716220 Telhador (telhas plásticas)', descricao: 'Telhador (telhas plásticas)' },
  { id: '7162 TELHADORES (REVESTIMENTOS RÍGIDOS)', descricao: 'TELHADORES (REVESTIMENTOS RÍGIDOS)' },
  { id: '828105 Telheiro', descricao: 'Telheiro' },
  { id: '723125 Temperador de metais e de compósitos', descricao: 'Temperador de metais e de compósitos' },
  { id: '823255 Temperador de vidro', descricao: 'Temperador de vidro' },
  { id: '30305 Tenente do corpo de bombeiros militar', descricao: 'Tenente do corpo de bombeiros militar' },
  { id: '10105 Tenente-brigadeiro', descricao: 'Tenente-brigadeiro' },
  { id: '30115 Tenente-coronel bombeiro militar', descricao: 'Tenente-coronel bombeiro militar' },
  { id: '10205 Tenente-coronel da aeronáutica', descricao: 'Tenente-coronel da aeronáutica' },
  { id: '20110 Tenente-coronel da polícia militar', descricao: 'Tenente-coronel da polícia militar' },
  { id: '30115 Tenente-coronel do corpo de bombeiros militar', descricao: 'Tenente-coronel do corpo de bombeiros militar' },
  { id: '10210 Tenente-coronel do exército', descricao: 'Tenente-coronel do exército' },
  { id: '203 TENENTES DA POLÍCIA MILITAR', descricao: 'TENENTES DA POLÍCIA MILITAR' },
  { id: '303 TENENTES DO CORPO DE BOMBEIROS MILITAR', descricao: 'TENENTES DO CORPO DE BOMBEIROS MILITAR' },
  { id: '377125 Tenista profissional', descricao: 'Tenista profissional' },
  { id: '263115 Teóloga', descricao: 'Teóloga' },
  { id: '263115 Teólogo', descricao: 'Teólogo' },
  { id: '251510 Terapeuta', descricao: 'Terapeuta' },
  { id: '322125 Terapeuta alternativo', descricao: 'Terapeuta alternativo' },
  { id: '322125 Terapeuta holístico', descricao: 'Terapeuta holístico' },
  { id: '322125 Terapeuta naturalista', descricao: 'Terapeuta naturalista' },
  { id: '223905 Terapeuta ocupacional', descricao: 'Terapeuta ocupacional' },
  { id: '2239 TERAPEUTAS OCUPACIONAIS, ORTOPTISTAS E PSICOMOTRICISTAS', descricao: 'TERAPEUTAS OCUPACIONAIS, ORTOPTISTAS E PSICOMOTRICISTAS' },
  { id: '10305 Terceiro-sargento da aeronáutica', descricao: 'Terceiro-sargento da aeronáutica' },
  { id: '10315 Terceiro-sargento da marinha', descricao: 'Terceiro-sargento da marinha' },
  { id: '10310 Terceiro-sargento do exército', descricao: 'Terceiro-sargento do exército' },
  { id: '261415 Terminógrafo', descricao: 'Terminógrafo' },
  { id: '261415 Terminólogo', descricao: 'Terminólogo' },
  { id: '622610 Terreirista de café', descricao: 'Terreirista de café' },
  { id: '622610 Terrereiro de café', descricao: 'Terrereiro de café' },
  { id: '353230 Tesoureiro', descricao: 'Tesoureiro' },
  { id: '353230 Tesoureiro de banco', descricao: 'Tesoureiro de banco' },
  { id: '263105 Testemunha qualificada do matrimônio', descricao: 'Testemunha qualificada do matrimônio' },
  { id: '828110 Tijoleiro', descricao: 'Tijoleiro' },
  { id: '771120 Tineiro', descricao: 'Tineiro' },
  { id: '311725 Tingidor de couros e peles', descricao: 'Tingidor de couros e peles' },
  { id: '311710 Tingidor de fios', descricao: 'Tingidor de fios' },
  { id: '516330 Tingidor de roupas', descricao: 'Tingidor de roupas' },
  { id: '311710 Tingidor de tecidos', descricao: 'Tingidor de tecidos' },
  { id: '141410 Tintureiro lavagem de roupas (comércio varejista)', descricao: 'Tintureiro lavagem de roupas (comércio varejista)' },
  { id: '5163 TINTUREIROS, LAVADEIROS E AFINS, A MÁQUINA', descricao: 'TINTUREIROS, LAVADEIROS E AFINS, A MÁQUINA' },
  { id: '768605 Tipógrafo', descricao: 'Tipógrafo' },
  { id: '768605 Tipógrafo chapista', descricao: 'Tipógrafo chapista' },
  { id: '622605 Tirador - na cultura de cacau', descricao: 'Tirador - na cultura de cacau' },
  { id: '632205 Tirador de látex', descricao: 'Tirador de látex' },
  { id: '613115 Tirador de leite - produtor', descricao: 'Tirador de leite - produtor' },
  { id: '722310 Tirador de machos - na fundição', descricao: 'Tirador de machos - na fundição' },
  { id: '632335 Tirador de óleo de copaíba', descricao: 'Tirador de óleo de copaíba' },
  { id: '622020 Tirador de palha - na cultura', descricao: 'Tirador de palha - na cultura' },
  { id: '632520 Tirador de poaia', descricao: 'Tirador de poaia' },
  { id: '632215 Tirador de resinas', descricao: 'Tirador de resinas' },
  { id: '225127 Tisiologista', descricao: 'Tisiologista' },
  { id: '376250 Titeriteiro', descricao: 'Titeriteiro' },
  { id: '623110 Tocador de gado - na pecuária', descricao: 'Tocador de gado - na pecuária' },
  { id: '225250 Tocoginecologista', descricao: 'Tocoginecologista' },
  { id: '612320 Tomatecultor', descricao: 'Tomatecultor' },
  { id: '622110 Tombador de cana-de-açúcar', descricao: 'Tombador de cana-de-açúcar' },
  { id: '771120 Toneleiro', descricao: 'Toneleiro' },
  { id: '376245 Tony de soirée', descricao: 'Tony de soirée' },
  { id: '312320 Topógrafo', descricao: 'Topógrafo' },
  { id: '632205 Toqueiro - seringueiro', descricao: 'Toqueiro - seringueiro' },
  { id: '712225 Torneiro (lavra de pedra)', descricao: 'Torneiro (lavra de pedra)' },
  { id: '721215 Torneiro ajustador', descricao: 'Torneiro ajustador' },
  { id: '752315 Torneiro ceramista', descricao: 'Torneiro ceramista' },
  { id: '721430 Torneiro cnc', descricao: 'Torneiro cnc' },
  { id: '721215 Torneiro ferramenteiro', descricao: 'Torneiro ferramenteiro' },
  { id: '721215 Torneiro mecânico', descricao: 'Torneiro mecânico' },
  { id: '773355 Torneiro na usinagem convencional de madeira', descricao: 'Torneiro na usinagem convencional de madeira' },
  { id: '721210 Torneiro repuxador', descricao: 'Torneiro repuxador' },
  { id: '841625 Torrador de cacau', descricao: 'Torrador de cacau' },
  { id: '841610 Torrador de café', descricao: 'Torrador de café' },
  { id: '841630 Torrador de chá', descricao: 'Torrador de chá' },
  { id: '622625 Torrador de guaraná', descricao: 'Torrador de guaraná' },
  { id: '711330 Torrista (petróleo)', descricao: 'Torrista (petróleo)' },
  { id: '711330 Torrista - na extração de petróleo e gás', descricao: 'Torrista - na extração de petróleo e gás' },
  { id: '623210 Tosador', descricao: 'Tosador' },
  { id: '519320 Tosador de animais domésticos', descricao: 'Tosador de animais domésticos' },
  { id: '623210 Tosquiador', descricao: 'Tosquiador' },
  { id: '848110 Toucinheiro na conservação de alimentos', descricao: 'Toucinheiro na conservação de alimentos' },
  { id: '263105 Toy hunji', descricao: 'Toy hunji' },
  { id: '263105 Toy vodunnon', descricao: 'Toy vodunnon' },
  { id: '823320 Trabalhador na fabricação de artefatos de cimento amianto', descricao: 'Trabalhador na fabricação de artefatos de cimento amianto' },
  { id: '823265 Trabalhador na fabricação de pedras abrasivas', descricao: 'Trabalhador na fabricação de pedras abrasivas' },
  { id: '631325 Trabalhador - na criação de peixe - exclusive empregador', descricao: 'Trabalhador - na criação de peixe - exclusive empregador' },
  { id: '631325 Trabalhador - na criação de peixes - exclusive conta própria', descricao: 'Trabalhador - na criação de peixes - exclusive conta própria' },
  { id: '623115 Trabalhador - na pecuária - exclusive conta própria e empregador', descricao: 'Trabalhador - na pecuária - exclusive conta própria e empregador' },
  { id: '622020 Trabalhador agrícola polivalente', descricao: 'Trabalhador agrícola polivalente' },
  { id: '621005 Trabalhador agropecuário em geral', descricao: 'Trabalhador agropecuário em geral' },
  { id: '621005 Trabalhador braçal - na agropecuária - conta própria', descricao: 'Trabalhador braçal - na agropecuária - conta própria' },
  { id: '621005 Trabalhador braçal - na agropecuária - exclusive conta própria', descricao: 'Trabalhador braçal - na agropecuária - exclusive conta própria' },
  { id: '621005 Trabalhador braçal - na agropecuária - exclusive empregador', descricao: 'Trabalhador braçal - na agropecuária - exclusive empregador' },
  { id: '623305 Trabalhador da avicultura de corte', descricao: 'Trabalhador da avicultura de corte' },
  { id: '623310 Trabalhador da avicultura de postura', descricao: 'Trabalhador da avicultura de postura' },
  { id: '623205 Trabalhador da caprinocultura', descricao: 'Trabalhador da caprinocultura' },
  { id: '621005 Trabalhador da coleta de sementes', descricao: 'Trabalhador da coleta de sementes' },
  { id: '631320 Trabalhador da criação de ostras', descricao: 'Trabalhador da criação de ostras' },
  { id: '622505 Trabalhador da cultura de abacate', descricao: 'Trabalhador da cultura de abacate' },
  { id: '622510 Trabalhador da cultura de abacaxi', descricao: 'Trabalhador da cultura de abacaxi' },
  { id: '622505 Trabalhador da cultura de acerola', descricao: 'Trabalhador da cultura de acerola' },
  { id: '622205 Trabalhador da cultura de algodão', descricao: 'Trabalhador da cultura de algodão' },
  { id: '622505 Trabalhador da cultura de ameixa', descricao: 'Trabalhador da cultura de ameixa' },
  { id: '622505 Trabalhador da cultura de amora', descricao: 'Trabalhador da cultura de amora' },
  { id: '622105 Trabalhador da cultura de arroz', descricao: 'Trabalhador da cultura de arroz' },
  { id: '622505 Trabalhador da cultura de atemóia', descricao: 'Trabalhador da cultura de atemóia' },
  { id: '622505 Trabalhador da cultura de banana', descricao: 'Trabalhador da cultura de banana' },
  { id: '622605 Trabalhador da cultura de cacau', descricao: 'Trabalhador da cultura de cacau' },
  { id: '622610 Trabalhador da cultura de café', descricao: 'Trabalhador da cultura de café' },
  { id: '622505 Trabalhador da cultura de cajá', descricao: 'Trabalhador da cultura de cajá' },
  { id: '622505 Trabalhador da cultura de caju', descricao: 'Trabalhador da cultura de caju' },
  { id: '622110 Trabalhador da cultura de cana-de-açúcar', descricao: 'Trabalhador da cultura de cana-de-açúcar' },
  { id: '622505 Trabalhador da cultura de caqui', descricao: 'Trabalhador da cultura de caqui' },
  { id: '622505 Trabalhador da cultura de carambola', descricao: 'Trabalhador da cultura de carambola' },
  { id: '622505 Trabalhador da cultura de cítricos', descricao: 'Trabalhador da cultura de cítricos' },
  { id: '622505 Trabalhador da cultura de cupuaçu', descricao: 'Trabalhador da cultura de cupuaçu' },
  { id: '622615 Trabalhador da cultura de erva-mate', descricao: 'Trabalhador da cultura de erva-mate' },
  { id: '622805 Trabalhador da cultura de especiarias', descricao: 'Trabalhador da cultura de especiarias' },
  { id: '622305 Trabalhador da cultura de feijão, lentilha e ervilha', descricao: 'Trabalhador da cultura de feijão, lentilha e ervilha' },
  { id: '622515 Trabalhador da cultura de framboesa', descricao: 'Trabalhador da cultura de framboesa' },
  { id: '622505 Trabalhador da cultura de fruta-pão', descricao: 'Trabalhador da cultura de fruta-pão' },
  { id: '622620 Trabalhador da cultura de fumo', descricao: 'Trabalhador da cultura de fumo' },
  { id: '622505 Trabalhador da cultura de goiaba', descricao: 'Trabalhador da cultura de goiaba' },
  { id: '622505 Trabalhador da cultura de graviola', descricao: 'Trabalhador da cultura de graviola' },
  { id: '622625 Trabalhador da cultura de guaraná', descricao: 'Trabalhador da cultura de guaraná' },
  { id: '622505 Trabalhador da cultura de jaca', descricao: 'Trabalhador da cultura de jaca' },
  { id: '622505 Trabalhador da cultura de jenipapo', descricao: 'Trabalhador da cultura de jenipapo' },
  { id: '622505 Trabalhador da cultura de laranja e outros cítricos', descricao: 'Trabalhador da cultura de laranja e outros cítricos' },
  { id: '622505 Trabalhador da cultura de maçã', descricao: 'Trabalhador da cultura de maçã' },
  { id: '632340 Trabalhador da cultura de malva', descricao: 'Trabalhador da cultura de malva' },
  { id: '622505 Trabalhador da cultura de manga', descricao: 'Trabalhador da cultura de manga' },
  { id: '622515 Trabalhador da cultura de maracujá', descricao: 'Trabalhador da cultura de maracujá' },
  { id: '622510 Trabalhador da cultura de melancia', descricao: 'Trabalhador da cultura de melancia' },
  { id: '622510 Trabalhador da cultura de melão', descricao: 'Trabalhador da cultura de melão' },
  { id: '622115 Trabalhador da cultura de milho e sorgo', descricao: 'Trabalhador da cultura de milho e sorgo' },
  { id: '622510 Trabalhador da cultura de morango', descricao: 'Trabalhador da cultura de morango' },
  { id: '622505 Trabalhador da cultura de nectarina', descricao: 'Trabalhador da cultura de nectarina' },
  { id: '622505 Trabalhador da cultura de pêra', descricao: 'Trabalhador da cultura de pêra' },
  { id: '622505 Trabalhador da cultura de pêssego', descricao: 'Trabalhador da cultura de pêssego' },
  { id: '622505 Trabalhador da cultura de pinha', descricao: 'Trabalhador da cultura de pinha' },
  { id: '622505 Trabalhador da cultura de pitanga', descricao: 'Trabalhador da cultura de pitanga' },
  { id: '622810 Trabalhador da cultura de plantas aromáticas e medicinais', descricao: 'Trabalhador da cultura de plantas aromáticas e medicinais' },
  { id: '622425 Trabalhador da cultura de plantas ornamentais', descricao: 'Trabalhador da cultura de plantas ornamentais' },
  { id: '622210 Trabalhador da cultura de sisal', descricao: 'Trabalhador da cultura de sisal' },
  { id: '622505 Trabalhador da cultura de tamarindo', descricao: 'Trabalhador da cultura de tamarindo' },
  { id: '622120 Trabalhador da cultura de trigo, aveia, cevada e triticale', descricao: 'Trabalhador da cultura de trigo, aveia, cevada e triticale' },
  { id: '622505 Trabalhador da cultura de umbu', descricao: 'Trabalhador da cultura de umbu' },
  { id: '622515 Trabalhador da cultura de uva', descricao: 'Trabalhador da cultura de uva' },
  { id: '622215 Trabalhador da cultura do rami', descricao: 'Trabalhador da cultura do rami' },
  { id: '632215 Trabalhador da cultura oiticica', descricao: 'Trabalhador da cultura oiticica' },
  { id: '623320 Trabalhador da cunicultura', descricao: 'Trabalhador da cunicultura' },
  { id: '823320 Trabalhador da elaboração de pré-fabricados (cimento amianto)', descricao: 'Trabalhador da elaboração de pré-fabricados (cimento amianto)' },
  { id: '823325 Trabalhador da elaboração de pré-fabricados (concreto armado)', descricao: 'Trabalhador da elaboração de pré-fabricados (concreto armado)' },
  { id: '632510 Trabalhador da exploração de abútua', descricao: 'Trabalhador da exploração de abútua' },
  { id: '632505 Trabalhador da exploração de açacuzeiro', descricao: 'Trabalhador da exploração de açacuzeiro' },
  { id: '632405 Trabalhador da exploração de açaí', descricao: 'Trabalhador da exploração de açaí' },
  { id: '632505 Trabalhador da exploração de amapá', descricao: 'Trabalhador da exploração de amapá' },
  { id: '632505 Trabalhador da exploração de ameixeira', descricao: 'Trabalhador da exploração de ameixeira' },
  { id: '632505 Trabalhador da exploração de amesca', descricao: 'Trabalhador da exploração de amesca' },
  { id: '632305 Trabalhador da exploração de andiroba', descricao: 'Trabalhador da exploração de andiroba' },
  { id: '632515 Trabalhador da exploração de angico', descricao: 'Trabalhador da exploração de angico' },
  { id: '632505 Trabalhador da exploração de aroeira', descricao: 'Trabalhador da exploração de aroeira' },
  { id: '632505 Trabalhador da exploração de árvores e arbustos produtores de substâncias aromát., Medic. E tóxicas', descricao: 'Trabalhador da exploração de árvores e arbustos produtores de substâncias aromát., Medic. E tóxicas' },
  { id: '632310 Trabalhador da exploração de babaçu', descricao: 'Trabalhador da exploração de babaçu' },
  { id: '632315 Trabalhador da exploração de bacaba', descricao: 'Trabalhador da exploração de bacaba' },
  { id: '632505 Trabalhador da exploração de bacupari', descricao: 'Trabalhador da exploração de bacupari' },
  { id: '632505 Trabalhador da exploração de bacuri', descricao: 'Trabalhador da exploração de bacuri' },
  { id: '632515 Trabalhador da exploração de barbatimão', descricao: 'Trabalhador da exploração de barbatimão' },
  { id: '632215 Trabalhador da exploração de breu', descricao: 'Trabalhador da exploração de breu' },
  { id: '632320 Trabalhador da exploração de buriti', descricao: 'Trabalhador da exploração de buriti' },
  { id: '632355 Trabalhador da exploração de butiá', descricao: 'Trabalhador da exploração de butiá' },
  { id: '632505 Trabalhador da exploração de canela', descricao: 'Trabalhador da exploração de canela' },
  { id: '632505 Trabalhador da exploração de capituí', descricao: 'Trabalhador da exploração de capituí' },
  { id: '632505 Trabalhador da exploração de carapanaúba', descricao: 'Trabalhador da exploração de carapanaúba' },
  { id: '632325 Trabalhador da exploração de carnaúba', descricao: 'Trabalhador da exploração de carnaúba' },
  { id: '632410 Trabalhador da exploração de castanha', descricao: 'Trabalhador da exploração de castanha' },
  { id: '632505 Trabalhador da exploração de catuaba', descricao: 'Trabalhador da exploração de catuaba' },
  { id: '632505 Trabalhador da exploração de caxinguba', descricao: 'Trabalhador da exploração de caxinguba' },
  { id: '632505 Trabalhador da exploração de cedro', descricao: 'Trabalhador da exploração de cedro' },
  { id: '632505 Trabalhador da exploração de cerejeira', descricao: 'Trabalhador da exploração de cerejeira' },
  { id: '632510 Trabalhador da exploração de cipó mil homens', descricao: 'Trabalhador da exploração de cipó mil homens' },
  { id: '632510 Trabalhador da exploração de cipó-cravo', descricao: 'Trabalhador da exploração de cipó-cravo' },
  { id: '632510 Trabalhador da exploração de cipó-de-alho', descricao: 'Trabalhador da exploração de cipó-de-alho' },
  { id: '632510 Trabalhador da exploração de cipó-escada', descricao: 'Trabalhador da exploração de cipó-escada' },
  { id: '632510 Trabalhador da exploração de cipó-macaco', descricao: 'Trabalhador da exploração de cipó-macaco' },
  { id: '632510 Trabalhador da exploração de cipó-seda', descricao: 'Trabalhador da exploração de cipó-seda' },
  { id: '632510 Trabalhador da exploração de cipó-sume', descricao: 'Trabalhador da exploração de cipó-sume' },
  { id: '632510 Trabalhador da exploração de cipó-tuína', descricao: 'Trabalhador da exploração de cipó-tuína' },
  { id: '632510 Trabalhador da exploração de cipós produtores de substâncias aromáticas, medicinais e tóxicas', descricao: 'Trabalhador da exploração de cipós produtores de substâncias aromáticas, medicinais e tóxicas' },
  { id: '632330 Trabalhador da exploração de coco-da-praia', descricao: 'Trabalhador da exploração de coco-da-praia' },
  { id: '632335 Trabalhador da exploração de copaíba', descricao: 'Trabalhador da exploração de copaíba' },
  { id: '632210 Trabalhador da exploração de coquirana', descricao: 'Trabalhador da exploração de coquirana' },
  { id: '632505 Trabalhador da exploração de craveira da terra', descricao: 'Trabalhador da exploração de craveira da terra' },
  { id: '632510 Trabalhador da exploração de croapé', descricao: 'Trabalhador da exploração de croapé' },
  { id: '632505 Trabalhador da exploração de cumaru', descricao: 'Trabalhador da exploração de cumaru' },
  { id: '632210 Trabalhador da exploração de espécies produtoras de gomas não elásticas', descricao: 'Trabalhador da exploração de espécies produtoras de gomas não elásticas' },
  { id: '632505 Trabalhador da exploração de favela', descricao: 'Trabalhador da exploração de favela' },
  { id: '632505 Trabalhador da exploração de graviola', descricao: 'Trabalhador da exploração de graviola' },
  { id: '632505 Trabalhador da exploração de inharé', descricao: 'Trabalhador da exploração de inharé' },
  { id: '632505 Trabalhador da exploração de ipê da flor amarela', descricao: 'Trabalhador da exploração de ipê da flor amarela' },
  { id: '632505 Trabalhador da exploração de ipê da flor roxa', descricao: 'Trabalhador da exploração de ipê da flor roxa' },
  { id: '632520 Trabalhador da exploração de ipecacuanha', descricao: 'Trabalhador da exploração de ipecacuanha' },
  { id: '632505 Trabalhador da exploração de jaborandi', descricao: 'Trabalhador da exploração de jaborandi' },
  { id: '632505 Trabalhador da exploração de jacareúba', descricao: 'Trabalhador da exploração de jacareúba' },
  { id: '632505 Trabalhador da exploração de janaguba', descricao: 'Trabalhador da exploração de janaguba' },
  { id: '632505 Trabalhador da exploração de jatobá', descricao: 'Trabalhador da exploração de jatobá' },
  { id: '632215 Trabalhador da exploração de jatobá (resinas)', descricao: 'Trabalhador da exploração de jatobá (resinas)' },
  { id: '632505 Trabalhador da exploração de jenipapo', descricao: 'Trabalhador da exploração de jenipapo' },
  { id: '632215 Trabalhador da exploração de jotaicica', descricao: 'Trabalhador da exploração de jotaicica' },
  { id: '632505 Trabalhador da exploração de jucá', descricao: 'Trabalhador da exploração de jucá' },
  { id: '632355 Trabalhador da exploração de licuri', descricao: 'Trabalhador da exploração de licuri' },
  { id: '632505 Trabalhador da exploração de macaca poranga', descricao: 'Trabalhador da exploração de macaca poranga' },
  { id: '632515 Trabalhador da exploração de madeiras tanantes', descricao: 'Trabalhador da exploração de madeiras tanantes' },
  { id: '632340 Trabalhador da exploração de malva (pãina)', descricao: 'Trabalhador da exploração de malva (pãina)' },
  { id: '632505 Trabalhador da exploração de mangabeira', descricao: 'Trabalhador da exploração de mangabeira' },
  { id: '632505 Trabalhador da exploração de marapoama', descricao: 'Trabalhador da exploração de marapoama' },
  { id: '632505 Trabalhador da exploração de moreira', descricao: 'Trabalhador da exploração de moreira' },
  { id: '632515 Trabalhador da exploração de murici-da-casca-vermelha', descricao: 'Trabalhador da exploração de murici-da-casca-vermelha' },
  { id: '632345 Trabalhador da exploração de murumuru', descricao: 'Trabalhador da exploração de murumuru' },
  { id: '632505 Trabalhador da exploração de mururé', descricao: 'Trabalhador da exploração de mururé' },
  { id: '632350 Trabalhador da exploração de oiticica', descricao: 'Trabalhador da exploração de oiticica' },
  { id: '632355 Trabalhador da exploração de ouricuri', descricao: 'Trabalhador da exploração de ouricuri' },
  { id: '632340 Trabalhador da exploração de pãina', descricao: 'Trabalhador da exploração de pãina' },
  { id: '632515 Trabalhador da exploração de pau-para-tudo', descricao: 'Trabalhador da exploração de pau-para-tudo' },
  { id: '632505 Trabalhador da exploração de pau-rosa', descricao: 'Trabalhador da exploração de pau-rosa' },
  { id: '632360 Trabalhador da exploração de pequi', descricao: 'Trabalhador da exploração de pequi' },
  { id: '632365 Trabalhador da exploração de piaçava', descricao: 'Trabalhador da exploração de piaçava' },
  { id: '632415 Trabalhador da exploração de pinhão', descricao: 'Trabalhador da exploração de pinhão' },
  { id: '632505 Trabalhador da exploração de piquiá', descricao: 'Trabalhador da exploração de piquiá' },
  { id: '632505 Trabalhador da exploração de preciosa', descricao: 'Trabalhador da exploração de preciosa' },
  { id: '632420 Trabalhador da exploração de pupunha', descricao: 'Trabalhador da exploração de pupunha' },
  { id: '632505 Trabalhador da exploração de quina', descricao: 'Trabalhador da exploração de quina' },
  { id: '632505 Trabalhador da exploração de quinarana', descricao: 'Trabalhador da exploração de quinarana' },
  { id: '632520 Trabalhador da exploração de raízes produtoras de substâncias aromáticas, medicinais e tóxicas', descricao: 'Trabalhador da exploração de raízes produtoras de substâncias aromáticas, medicinais e tóxicas' },
  { id: '632215 Trabalhador da exploração de resinas', descricao: 'Trabalhador da exploração de resinas' },
  { id: '632505 Trabalhador da exploração de sacaca', descricao: 'Trabalhador da exploração de sacaca' },
  { id: '632505 Trabalhador da exploração de sangue de grado', descricao: 'Trabalhador da exploração de sangue de grado' },
  { id: '632505 Trabalhador da exploração de santa maria', descricao: 'Trabalhador da exploração de santa maria' },
  { id: '632505 Trabalhador da exploração de sucuba', descricao: 'Trabalhador da exploração de sucuba' },
  { id: '632215 Trabalhador da exploração de sucuruba', descricao: 'Trabalhador da exploração de sucuruba' },
  { id: '632505 Trabalhador da exploração de tatajuba', descricao: 'Trabalhador da exploração de tatajuba' },
  { id: '632520 Trabalhador da exploração de timbó', descricao: 'Trabalhador da exploração de timbó' },
  { id: '632370 Trabalhador da exploração de tucum', descricao: 'Trabalhador da exploração de tucum' },
  { id: '711130 Trabalhador da extração de pedras abrasivas', descricao: 'Trabalhador da extração de pedras abrasivas' },
  { id: '632525 Trabalhador da extração de substâncias aromáticas', descricao: 'Trabalhador da extração de substâncias aromáticas' },
  { id: '632525 Trabalhador da extração de substâncias aromáticas, medicinais e tóxicas, em geral', descricao: 'Trabalhador da extração de substâncias aromáticas, medicinais e tóxicas, em geral' },
  { id: '632525 Trabalhador da extração de substâncias medicinais', descricao: 'Trabalhador da extração de substâncias medicinais' },
  { id: '632525 Trabalhador da extração de substâncias tóxicas', descricao: 'Trabalhador da extração de substâncias tóxicas' },
  { id: '848525 Trabalhador da extração de tripas e demais vísceras', descricao: 'Trabalhador da extração de tripas e demais vísceras' },
  { id: '841505 Trabalhador da fabricação de laticínio', descricao: 'Trabalhador da fabricação de laticínio' },
  { id: '823265 Trabalhador da fabricação de lixa', descricao: 'Trabalhador da fabricação de lixa' },
  { id: '812110 Trabalhador da fabricação de munição e explosivos', descricao: 'Trabalhador da fabricação de munição e explosivos' },
  { id: '823330 Trabalhador da fabricação de pedras artificiais', descricao: 'Trabalhador da fabricação de pedras artificiais' },
  { id: '811125 Trabalhador da fabricação de resinas e vernizes', descricao: 'Trabalhador da fabricação de resinas e vernizes' },
  { id: '514325 Trabalhador da manutenção de edificações', descricao: 'Trabalhador da manutenção de edificações' },
  { id: '622615 Trabalhador da matecultura', descricao: 'Trabalhador da matecultura' },
  { id: '623210 Trabalhador da ovinocultura', descricao: 'Trabalhador da ovinocultura' },
  { id: '623105 Trabalhador da pecuária (asininos e muares)', descricao: 'Trabalhador da pecuária (asininos e muares)' },
  { id: '623110 Trabalhador da pecuária (bovinos corte)', descricao: 'Trabalhador da pecuária (bovinos corte)' },
  { id: '623115 Trabalhador da pecuária (bovinos leite)', descricao: 'Trabalhador da pecuária (bovinos leite)' },
  { id: '623120 Trabalhador da pecuária (bubalinos)', descricao: 'Trabalhador da pecuária (bubalinos)' },
  { id: '623125 Trabalhador da pecuária (eqüinos)', descricao: 'Trabalhador da pecuária (eqüinos)' },
  { id: '621005 Trabalhador da produção de sementes agrícolas', descricao: 'Trabalhador da produção de sementes agrícolas' },
  { id: '623215 Trabalhador da suinocultura', descricao: 'Trabalhador da suinocultura' },
  { id: '751125 Trabalhador de confecções de bijouterias e jóias de fantasia', descricao: 'Trabalhador de confecções de bijouterias e jóias de fantasia' },
  { id: '621005 Trabalhador de enxada - na agropecuária', descricao: 'Trabalhador de enxada - na agropecuária' },
  { id: '632125 Trabalhador de extração florestal, em geral', descricao: 'Trabalhador de extração florestal, em geral' },
  { id: '768325 Trabalhador de fabricação de arreios', descricao: 'Trabalhador de fabricação de arreios' },
  { id: '768315 Trabalhador de fabricação de bolas de couro', descricao: 'Trabalhador de fabricação de bolas de couro' },
  { id: '811810 Trabalhador de fabricação de comprimidos e drágeas', descricao: 'Trabalhador de fabricação de comprimidos e drágeas' },
  { id: '841476 Trabalhador de fabricação de margarina', descricao: 'Trabalhador de fabricação de margarina' },
  { id: '848325 Trabalhador de fabricação de sorvete', descricao: 'Trabalhador de fabricação de sorvete' },
  { id: '811130 Trabalhador de fabricação de tintas', descricao: 'Trabalhador de fabricação de tintas' },
  { id: '841720 Trabalhador de fabricação de vinhos', descricao: 'Trabalhador de fabricação de vinhos' },
  { id: '622505 Trabalhador de fruticultura em geral', descricao: 'Trabalhador de fruticultura em geral' },
  { id: '841484 Trabalhador de limpeza de pescado - na industrialização', descricao: 'Trabalhador de limpeza de pescado - na industrialização' },
  { id: '991105 Trabalhador de linha férrea', descricao: 'Trabalhador de linha férrea' },
  { id: '514325 Trabalhador de manutenção de edifícios', descricao: 'Trabalhador de manutenção de edifícios' },
  { id: '623210 Trabalhador de manutenção e preparação de tosqueadeiras', descricao: 'Trabalhador de manutenção e preparação de tosqueadeiras' },
  { id: '715140 Trabalhador de pavimentação (rodovias)', descricao: 'Trabalhador de pavimentação (rodovias)' },
  { id: '623015 Trabalhador de pecuária polivalente', descricao: 'Trabalhador de pecuária polivalente' },
  { id: '841484 Trabalhador de preparação de pescados (limpeza)', descricao: 'Trabalhador de preparação de pescados (limpeza)' },
  { id: '514225 Trabalhador de serviços de limpeza e conservação de áreas públicas', descricao: 'Trabalhador de serviços de limpeza e conservação de áreas públicas' },
  { id: '768110 Trabalhador de tapetes de nós, a mão', descricao: 'Trabalhador de tapetes de nós, a mão' },
  { id: '841505 Trabalhador de tratamento de leite', descricao: 'Trabalhador de tratamento de leite' },
  { id: '841505 Trabalhador de tratamento do leite e fabricação de laticínios e afins', descricao: 'Trabalhador de tratamento do leite e fabricação de laticínios e afins' },
  { id: '841720 Trabalhador de vinhos achampanhados', descricao: 'Trabalhador de vinhos achampanhados' },
  { id: '765405 Trabalhador do acabamento de artefatos de tecidos e couros', descricao: 'Trabalhador do acabamento de artefatos de tecidos e couros' },
  { id: '848605 Trabalhador do beneficiamento de fumo', descricao: 'Trabalhador do beneficiamento de fumo' },
  { id: '622010 Trabalhador do plantio e trato de árvores ornamentais', descricao: 'Trabalhador do plantio e trato de árvores ornamentais' },
  { id: '519805 Trabalhador do sexo', descricao: 'Trabalhador do sexo' },
  { id: '623405 Trabalhador em criatórios de animais produtores de veneno', descricao: 'Trabalhador em criatórios de animais produtores de veneno' },
  { id: '623410 Trabalhador na apicultura', descricao: 'Trabalhador na apicultura' },
  { id: '631005 Trabalhador na captura de crustáceos', descricao: 'Trabalhador na captura de crustáceos' },
  { id: '712210 Trabalhador na colocação de inscrições em pedras', descricao: 'Trabalhador na colocação de inscrições em pedras' },
  { id: '768305 Trabalhador na confecção de peças de couro', descricao: 'Trabalhador na confecção de peças de couro' },
  { id: '514325 Trabalhador na conservação de edifícios', descricao: 'Trabalhador na conservação de edifícios' },
  { id: '631315 Trabalhador na criação de mexilhões', descricao: 'Trabalhador na criação de mexilhões' },
  { id: '631335 Trabalhador na criação de rãs', descricao: 'Trabalhador na criação de rãs' },
  { id: '622210 Trabalhador na cultura de agave', descricao: 'Trabalhador na cultura de agave' },
  { id: '622705 Trabalhador na cultura de amendoim', descricao: 'Trabalhador na cultura de amendoim' },
  { id: '622315 Trabalhador na cultura de batata-doce', descricao: 'Trabalhador na cultura de batata-doce' },
  { id: '622315 Trabalhador na cultura de batata-inglesa', descricao: 'Trabalhador na cultura de batata-inglesa' },
  { id: '622315 Trabalhador na cultura de beterraba', descricao: 'Trabalhador na cultura de beterraba' },
  { id: '622710 Trabalhador na cultura de canola', descricao: 'Trabalhador na cultura de canola' },
  { id: '622315 Trabalhador na cultura de cebola', descricao: 'Trabalhador na cultura de cebola' },
  { id: '622715 Trabalhador na cultura de coco-da-baía', descricao: 'Trabalhador na cultura de coco-da-baía' },
  { id: '622720 Trabalhador na cultura de dendê', descricao: 'Trabalhador na cultura de dendê' },
  { id: '622320 Trabalhador na cultura de hortaliças', descricao: 'Trabalhador na cultura de hortaliças' },
  { id: '622725 Trabalhador na cultura de mamona', descricao: 'Trabalhador na cultura de mamona' },
  { id: '622315 Trabalhador na cultura de mandioca', descricao: 'Trabalhador na cultura de mandioca' },
  { id: '622805 Trabalhador na cultura de pimenta-do-reino', descricao: 'Trabalhador na cultura de pimenta-do-reino' },
  { id: '622505 Trabalhador na cultura de romã', descricao: 'Trabalhador na cultura de romã' },
  { id: '622730 Trabalhador na cultura de soja', descricao: 'Trabalhador na cultura de soja' },
  { id: '622305 Trabalhador na cultura de tomate', descricao: 'Trabalhador na cultura de tomate' },
  { id: '622735 Trabalhador na cultura do girassol', descricao: 'Trabalhador na cultura do girassol' },
  { id: '622740 Trabalhador na cultura do linho', descricao: 'Trabalhador na cultura do linho' },
  { id: '823325 Trabalhador na fabricação de artefatos de cimento', descricao: 'Trabalhador na fabricação de artefatos de cimento' },
  { id: '823265 Trabalhador na fabricação de esmeris', descricao: 'Trabalhador na fabricação de esmeris' },
  { id: '823265 Trabalhador na fabricação de produtos abrasivos', descricao: 'Trabalhador na fabricação de produtos abrasivos' },
  { id: '823265 Trabalhador na fabricação de rebolos', descricao: 'Trabalhador na fabricação de rebolos' },
  { id: '622420 Trabalhador na floricultura (cultivo de mudas)', descricao: 'Trabalhador na floricultura (cultivo de mudas)' },
  { id: '622405 Trabalhador na floricultura (flores e folhagens de corte)', descricao: 'Trabalhador na floricultura (flores e folhagens de corte)' },
  { id: '622410 Trabalhador na floricultura (flores em vaso)', descricao: 'Trabalhador na floricultura (flores em vaso)' },
  { id: '622415 Trabalhador na floricultura (forrações)', descricao: 'Trabalhador na floricultura (forrações)' },
  { id: '622425 Trabalhador na floricultura (plantas ornamentais)', descricao: 'Trabalhador na floricultura (plantas ornamentais)' },
  { id: '621005 Trabalhador na formação de pastagem', descricao: 'Trabalhador na formação de pastagem' },
  { id: '631310 Trabalhador na jacarecultura', descricao: 'Trabalhador na jacarecultura' },
  { id: '711405 Trabalhador na lavra de ouro', descricao: 'Trabalhador na lavra de ouro' },
  { id: '623415 Trabalhador na minhocultura', descricao: 'Trabalhador na minhocultura' },
  { id: '622305 Trabalhador na olericultura (frutos e sementes)', descricao: 'Trabalhador na olericultura (frutos e sementes)' },
  { id: '622310 Trabalhador na olericultura (legumes)', descricao: 'Trabalhador na olericultura (legumes)' },
  { id: '622315 Trabalhador na olericultura (raízes, bulbos e tubérculos)', descricao: 'Trabalhador na olericultura (raízes, bulbos e tubérculos)' },
  { id: '622320 Trabalhador na olericultura (talos, folhas e flores)', descricao: 'Trabalhador na olericultura (talos, folhas e flores)' },
  { id: '643005 Trabalhador na operação de sistema de irrigação localizada (microaspersão e gotejamento)', descricao: 'Trabalhador na operação de sistema de irrigação localizada (microaspersão e gotejamento)' },
  { id: '643010 Trabalhador na operação de sistema de irrigação por aspersão (pivô central)', descricao: 'Trabalhador na operação de sistema de irrigação por aspersão (pivô central)' },
  { id: '643015 Trabalhador na operação de sistemas convencionais de irrigação por aspersão', descricao: 'Trabalhador na operação de sistemas convencionais de irrigação por aspersão' },
  { id: '643020 Trabalhador na operação de sistemas de irrigação e aspersão (alto propelido)', descricao: 'Trabalhador na operação de sistemas de irrigação e aspersão (alto propelido)' },
  { id: '643020 Trabalhador na operação de sistemas de irrigação e aspersão (canhão)', descricao: 'Trabalhador na operação de sistemas de irrigação e aspersão (canhão)' },
  { id: '643025 Trabalhador na operação de sistemas de irrigação por superfície e drenagem', descricao: 'Trabalhador na operação de sistemas de irrigação por superfície e drenagem' },
  { id: '631005 Trabalhador na pesca de crustáceo', descricao: 'Trabalhador na pesca de crustáceo' },
  { id: '631325 Trabalhador na piscicultura', descricao: 'Trabalhador na piscicultura' },
  { id: '622015 Trabalhador na produção de mudas e sementes', descricao: 'Trabalhador na produção de mudas e sementes' },
  { id: '623420 Trabalhador na sericicultura', descricao: 'Trabalhador na sericicultura' },
  { id: '622505 Trabalhador no cultivo de árvores frutíferas', descricao: 'Trabalhador no cultivo de árvores frutíferas' },
  { id: '622510 Trabalhador no cultivo de espécies frutíferas rasteiras', descricao: 'Trabalhador no cultivo de espécies frutíferas rasteiras' },
  { id: '622405 Trabalhador no cultivo de flores e folhagens de corte', descricao: 'Trabalhador no cultivo de flores e folhagens de corte' },
  { id: '622410 Trabalhador no cultivo de flores em vaso', descricao: 'Trabalhador no cultivo de flores em vaso' },
  { id: '622415 Trabalhador no cultivo de forrações', descricao: 'Trabalhador no cultivo de forrações' },
  { id: '622420 Trabalhador no cultivo de mudas', descricao: 'Trabalhador no cultivo de mudas' },
  { id: '622425 Trabalhador no cultivo de plantas ornamentais', descricao: 'Trabalhador no cultivo de plantas ornamentais' },
  { id: '622515 Trabalhador no cultivo de quiui (kiwi)', descricao: 'Trabalhador no cultivo de quiui (kiwi)' },
  { id: '622515 Trabalhador no cultivo de trepadeiras frutíferas', descricao: 'Trabalhador no cultivo de trepadeiras frutíferas' },
  { id: '622515 Trabalhador no cultivo de uva de mesa', descricao: 'Trabalhador no cultivo de uva de mesa' },
  { id: '622515 Trabalhador no cultivo de uva de vinho e suco', descricao: 'Trabalhador no cultivo de uva de vinho e suco' },
  { id: '764005 Trabalhador polivalente da confecção de calçados', descricao: 'Trabalhador polivalente da confecção de calçados' },
  { id: '762005 Trabalhador polivalente do curtimento de couros e peles', descricao: 'Trabalhador polivalente do curtimento de couros e peles' },
  { id: '783230 Trabalhador portuário de bloco', descricao: 'Trabalhador portuário de bloco' },
  { id: '783235 Trabalhador portuário de capatazia', descricao: 'Trabalhador portuário de capatazia' },
  { id: '623110 Trabalhador rural', descricao: 'Trabalhador rural' },
  { id: '623120 Trabalhador rural (bubalinos)', descricao: 'Trabalhador rural (bubalinos)' },
  { id: '621005 Trabalhador rural - na agropecuária - exclusive conta própria', descricao: 'Trabalhador rural - na agropecuária - exclusive conta própria' },
  { id: '621005 Trabalhador rural - na agropecuária - exclusive empregador', descricao: 'Trabalhador rural - na agropecuária - exclusive empregador' },
  { id: '623420 Trabalhador sericícola', descricao: 'Trabalhador sericícola' },
  { id: '622020 Trabalhador volante da agricultura', descricao: 'Trabalhador volante da agricultura' },
  { id: '6228 TRABALHADORES AGRÍCOLAS DA CULTURA DE ESPECIARIAS E DE PLANTAS AROMÁTICAS E MEDICINAIS', descricao: 'TRABALHADORES AGRÍCOLAS DA CULTURA DE ESPECIARIAS E DE PLANTAS AROMÁTICAS E MEDICINAIS' },
  { id: '6221 TRABALHADORES AGRÍCOLAS NA CULTURA DE GRAMÍNEAS', descricao: 'TRABALHADORES AGRÍCOLAS NA CULTURA DE GRAMÍNEAS' },
  { id: '6222 TRABALHADORES AGRÍCOLAS NA CULTURA DE PLANTAS FIBROSAS', descricao: 'TRABALHADORES AGRÍCOLAS NA CULTURA DE PLANTAS FIBROSAS' },
  { id: '6227 TRABALHADORES AGRÍCOLAS NA CULTURA DE PLANTAS OLEAGINOSAS', descricao: 'TRABALHADORES AGRÍCOLAS NA CULTURA DE PLANTAS OLEAGINOSAS' },
  { id: '6225 TRABALHADORES AGRÍCOLAS NA FRUTICULTURA', descricao: 'TRABALHADORES AGRÍCOLAS NA FRUTICULTURA' },
  { id: '6223 TRABALHADORES AGRÍCOLAS NA OLERICULTURA', descricao: 'TRABALHADORES AGRÍCOLAS NA OLERICULTURA' },
  { id: '6226 TRABALHADORES AGRÍCOLAS NAS CULTURAS DE PLANTAS ESTIMULANTES', descricao: 'TRABALHADORES AGRÍCOLAS NAS CULTURAS DE PLANTAS ESTIMULANTES' },
  { id: '6224 TRABALHADORES AGRÍCOLAS NO CULTIVO DE FLORES E PLANTAS ORNAMENTAIS', descricao: 'TRABALHADORES AGRÍCOLAS NO CULTIVO DE FLORES E PLANTAS ORNAMENTAIS' },
  { id: '6210 TRABALHADORES AGROPECUÁRIOS EM GERAL', descricao: 'TRABALHADORES AGROPECUÁRIOS EM GERAL' },
  { id: '7827 TRABALHADORES AQUAVIÁRIOS', descricao: 'TRABALHADORES AQUAVIÁRIOS' },
  { id: '7683 TRABALHADORES ARTESANAIS DA CONFECÇÃO DE CALÇADOS E ARTEFATOS DE COUROS E PELES', descricao: 'TRABALHADORES ARTESANAIS DA CONFECÇÃO DE CALÇADOS E ARTEFATOS DE COUROS E PELES' },
  { id: '7682 TRABALHADORES ARTESANAIS DA CONFECÇÃO DE PEÇAS E TECIDOS', descricao: 'TRABALHADORES ARTESANAIS DA CONFECÇÃO DE PEÇAS E TECIDOS' },
  { id: '8332 TRABALHADORES ARTESANAIS DE PRODUTOS DE PAPEL E PAPELÃO', descricao: 'TRABALHADORES ARTESANAIS DE PRODUTOS DE PAPEL E PAPELÃO' },
  { id: '8481 TRABALHADORES ARTESANAIS NA CONSERVAÇÃO DE ALIMENTOS', descricao: 'TRABALHADORES ARTESANAIS NA CONSERVAÇÃO DE ALIMENTOS' },
  { id: '8486 TRABALHADORES ARTESANAIS NA INDÚSTRIA DO FUMO', descricao: 'TRABALHADORES ARTESANAIS NA INDÚSTRIA DO FUMO' },
  { id: '8482 TRABALHADORES ARTESANAIS NA PASTEURIZAÇÃO DO LEITE E NA FABRICAÇÃO DE LATICÍNIOS E AFINS', descricao: 'TRABALHADORES ARTESANAIS NA PASTEURIZAÇÃO DO LEITE E NA FABRICAÇÃO DE LATICÍNIOS E AFINS' },
  { id: '5166 TRABALHADORES AUXILIARES DOS SERVIÇOS FUNERÁRIOS', descricao: 'TRABALHADORES AUXILIARES DOS SERVIÇOS FUNERÁRIOS' },
  { id: '5135 TRABALHADORES AUXILIARES NOS SERVIÇOS DE ALIMENTAÇÃO', descricao: 'TRABALHADORES AUXILIARES NOS SERVIÇOS DE ALIMENTAÇÃO' },
  { id: '7611 TRABALHADORES DA CLASSIFICAÇÃO DE FIBRAS TÊXTEIS E LAVAGEM DE LÃ', descricao: 'TRABALHADORES DA CLASSIFICAÇÃO DE FIBRAS TÊXTEIS E LAVAGEM DE LÃ' },
  { id: '5192 TRABALHADORES DA COLETA E SELEÇÃO DE MATERIAL RECICLÁVEL', descricao: 'TRABALHADORES DA COLETA E SELEÇÃO DE MATERIAL RECICLÁVEL' },
  { id: '7652 TRABALHADORES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS, COUROS E SINTÉTICOS', descricao: 'TRABALHADORES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS, COUROS E SINTÉTICOS' },
  { id: '7113 TRABALHADORES DA EXTRAÇÃO DE MINERAIS LÍQUIDOS E GASOSOS', descricao: 'TRABALHADORES DA EXTRAÇÃO DE MINERAIS LÍQUIDOS E GASOSOS' },
  { id: '7111 TRABALHADORES DA EXTRAÇÃO DE MINERAIS SÓLIDOS', descricao: 'TRABALHADORES DA EXTRAÇÃO DE MINERAIS SÓLIDOS' },
  { id: '8281 TRABALHADORES DA FABRICAÇÃO DE CERÂMICA ESTRUTURAL PARA CONSTRUÇÃO', descricao: 'TRABALHADORES DA FABRICAÇÃO DE CERÂMICA ESTRUTURAL PARA CONSTRUÇÃO' },
  { id: '8121 TRABALHADORES DA FABRICAÇÃO DE MUNIÇÃO E EXPLOSIVOS QUÍMICOS', descricao: 'TRABALHADORES DA FABRICAÇÃO DE MUNIÇÃO E EXPLOSIVOS QUÍMICOS' },
  { id: '7662 TRABALHADORES DA IMPRESSÃO GRÁFICA', descricao: 'TRABALHADORES DA IMPRESSÃO GRÁFICA' },
  { id: '8411 TRABALHADORES DA INDÚSTRIA DE BENEFICIAMENTO DE GRÃOS, CEREAIS E AFINS', descricao: 'TRABALHADORES DA INDÚSTRIA DE BENEFICIAMENTO DE GRÃOS, CEREAIS E AFINS' },
  { id: '6430 TRABALHADORES DA IRRIGAÇÃO E DRENAGEM', descricao: 'TRABALHADORES DA IRRIGAÇÃO E DRENAGEM' },
  { id: '6410 TRABALHADORES DA MECANIZAÇÃO AGRÍCOLA', descricao: 'TRABALHADORES DA MECANIZAÇÃO AGRÍCOLA' },
  { id: '6420 TRABALHADORES DA MECANIZAÇÃO FLORESTAL', descricao: 'TRABALHADORES DA MECANIZAÇÃO FLORESTAL' },
  { id: '7233 TRABALHADORES DA PINTURA DE EQUIPAMENTOS, VEÍCULOS, ESTRUTURAS METÁLICAS E DE COMPÓSITOS', descricao: 'TRABALHADORES DA PINTURA DE EQUIPAMENTOS, VEÍCULOS, ESTRUTURAS METÁLICAS E DE COMPÓSITOS' },
  { id: '7661 TRABALHADORES DA PRÉ-IMPRESSÃO GRÁFICA', descricao: 'TRABALHADORES DA PRÉ-IMPRESSÃO GRÁFICA' },
  { id: '7641 TRABALHADORES DA PREPARAÇÃO DA CONFECÇÃO DE CALÇADOS', descricao: 'TRABALHADORES DA PREPARAÇÃO DA CONFECÇÃO DE CALÇADOS' },
  { id: '7631 TRABALHADORES DA PREPARAÇÃO DA CONFECÇÃO DE ROUPAS', descricao: 'TRABALHADORES DA PREPARAÇÃO DA CONFECÇÃO DE ROUPAS' },
  { id: '7651 TRABALHADORES DA PREPARAÇÃO DE ARTEFATOS DE TECIDOS, COUROS E TAPEÇARIA', descricao: 'TRABALHADORES DA PREPARAÇÃO DE ARTEFATOS DE TECIDOS, COUROS E TAPEÇARIA' },
  { id: '7621 TRABALHADORES DA PREPARAÇÃO DO CURTIMENTO DE COUROS E PELES', descricao: 'TRABALHADORES DA PREPARAÇÃO DO CURTIMENTO DE COUROS E PELES' },
  { id: '7522 TRABALHADORES DA TRANSFORMAÇÃO DE VIDROS PLANOS', descricao: 'TRABALHADORES DA TRANSFORMAÇÃO DE VIDROS PLANOS' },
  { id: '7643 TRABALHADORES DE ACABAMENTO DE CALÇADOS', descricao: 'TRABALHADORES DE ACABAMENTO DE CALÇADOS' },
  { id: '7614 TRABALHADORES DE ACABAMENTO, TINGIMENTO E ESTAMPARIA DAS INDÚSTRIAS TÊXTEIS', descricao: 'TRABALHADORES DE ACABAMENTO, TINGIMENTO E ESTAMPARIA DAS INDÚSTRIAS TÊXTEIS' },
  { id: '6220 TRABALHADORES DE APOIO À AGRICULTURA', descricao: 'TRABALHADORES DE APOIO À AGRICULTURA' },
  { id: '6314 TRABALHADORES DE APOIO À PESCA', descricao: 'TRABALHADORES DE APOIO À PESCA' },
  { id: '7751 TRABALHADORES DE ARTE E DO ACABAMENTO EM MADEIRA DO MOBILIÁRIO', descricao: 'TRABALHADORES DE ARTE E DO ACABAMENTO EM MADEIRA DO MOBILIÁRIO' },
  { id: '5153 TRABALHADORES DE ATENÇÃO, DEFESA E PROTEÇÃO A PESSOAS EM SITUAÇÃO DE RISCO E ADOLESCENTES', descricao: 'TRABALHADORES DE ATENÇÃO, DEFESA E PROTEÇÃO A PESSOAS EM SITUAÇÃO DE RISCO E ADOLESCENTES' },
  { id: '7121 TRABALHADORES DE BENEFICIAMENTO DE MINÉRIOS', descricao: 'TRABALHADORES DE BENEFICIAMENTO DE MINÉRIOS' },
  { id: '7122 TRABALHADORES DE BENEFICIAMENTO DE PEDRAS ORNAMENTAIS', descricao: 'TRABALHADORES DE BENEFICIAMENTO DE PEDRAS ORNAMENTAIS' },
  { id: '7244 TRABALHADORES DE CALDEIRARIA E SERRALHERIA', descricao: 'TRABALHADORES DE CALDEIRARIA E SERRALHERIA' },
  { id: '7832 TRABALHADORES DE CARGAS E DESCARGAS DE MERCADORIAS', descricao: 'TRABALHADORES DE CARGAS E DESCARGAS DE MERCADORIAS' },
  { id: '715140 Trabalhadores de conservação de rodovias', descricao: 'Trabalhadores de conservação de rodovias' },
  { id: '7841 TRABALHADORES DE EMBALAGEM E DE ETIQUETAGEM', descricao: 'TRABALHADORES DE EMBALAGEM E DE ETIQUETAGEM' },
  { id: '7152 TRABALHADORES DE ESTRUTURAS DE ALVENARIA', descricao: 'TRABALHADORES DE ESTRUTURAS DE ALVENARIA' },
  { id: '7112 TRABALHADORES DE EXTRAÇÃO DE MINERAIS SÓLIDOS (OPERADORES DE MÁQUINAS)', descricao: 'TRABALHADORES DE EXTRAÇÃO DE MINERAIS SÓLIDOS (OPERADORES DE MÁQUINAS)' },
  { id: '7221 TRABALHADORES DE FORJAMENTO DE METAIS', descricao: 'TRABALHADORES DE FORJAMENTO DE METAIS' },
  { id: '7222 TRABALHADORES DE FUNDIÇÃO DE METAIS PUROS E DE LIGAS METÁLICAS', descricao: 'TRABALHADORES DE FUNDIÇÃO DE METAIS PUROS E DE LIGAS METÁLICAS' },
  { id: '7156 TRABALHADORES DE INSTALAÇÕES ELÉTRICAS', descricao: 'TRABALHADORES DE INSTALAÇÕES ELÉTRICAS' },
  { id: '7664 TRABALHADORES DE LABORATÓRIO FOTOGRÁFICO E RADIOLÓGICO', descricao: 'TRABALHADORES DE LABORATÓRIO FOTOGRÁFICO E RADIOLÓGICO' },
  { id: '7831 TRABALHADORES DE MANOBRAS DE TRANSPORTES SOBRE TRILHOS', descricao: 'TRABALHADORES DE MANOBRAS DE TRANSPORTES SOBRE TRILHOS' },
  { id: '9192 TRABALHADORES DE MANUTENÇÃO DE ROÇADEIRAS, MOTOSERRAS E SIMILARES', descricao: 'TRABALHADORES DE MANUTENÇÃO DE ROÇADEIRAS, MOTOSERRAS E SIMILARES' },
  { id: '7223 TRABALHADORES DE MOLDAGEM DE METAIS E DE LIGAS METÁLICAS', descricao: 'TRABALHADORES DE MOLDAGEM DE METAIS E DE LIGAS METÁLICAS' },
  { id: '7155 TRABALHADORES DE MONTAGEM DE ESTRUTURAS DE MADEIRA, METAL E COMPÓSITOS EM OBRAS CIVIS', descricao: 'TRABALHADORES DE MONTAGEM DE ESTRUTURAS DE MADEIRA, METAL E COMPÓSITOS EM OBRAS CIVIS' },
  { id: '5111 TRABALHADORES DE SEGURANÇA E ATENDIMENTO AOS USUÁRIOS NOS TRANSPORTES', descricao: 'TRABALHADORES DE SEGURANÇA E ATENDIMENTO AOS USUÁRIOS NOS TRANSPORTES' },
  { id: '5193 TRABALHADORES DE SERVIÇOS VETERINÁRIOS, DE HIGIENE E ESTÉTICA DE ANIMAIS DOMÉSTICOS', descricao: 'TRABALHADORES DE SERVIÇOS VETERINÁRIOS, DE HIGIENE E ESTÉTICA DE ANIMAIS DOMÉSTICOS' },
  { id: '7243 TRABALHADORES DE SOLDAGEM E CORTE DE LIGAS METÁLICAS', descricao: 'TRABALHADORES DE SOLDAGEM E CORTE DE LIGAS METÁLICAS' },
  { id: '7681 TRABALHADORES DE TECELAGEM MANUAL, TRICÔ, CROCHÊ, RENDAS E AFINS', descricao: 'TRABALHADORES DE TECELAGEM MANUAL, TRICÔ, CROCHÊ, RENDAS E AFINS' },
  { id: '7242 TRABALHADORES DE TRAÇAGEM E MONTAGEM DE ESTRUTURAS METÁLICAS E DE COMPÓSITOS', descricao: 'TRABALHADORES DE TRAÇAGEM E MONTAGEM DE ESTRUTURAS METÁLICAS E DE COMPÓSITOS' },
  { id: '7232 TRABALHADORES DE TRATAMENTO DE SUPERFÍCIES DE METAIS E DE COMPÓSITOS (TERMOQUÍMICOS)', descricao: 'TRABALHADORES DE TRATAMENTO DE SUPERFÍCIES DE METAIS E DE COMPÓSITOS (TERMOQUÍMICOS)' },
  { id: '7721 TRABALHADORES DE TRATAMENTO E PREPARAÇÃO DA MADEIRA', descricao: 'TRABALHADORES DE TRATAMENTO E PREPARAÇÃO DA MADEIRA' },
  { id: '7231 TRABALHADORES DE TRATAMENTO TÉRMICO DE METAIS', descricao: 'TRABALHADORES DE TRATAMENTO TÉRMICO DE METAIS' },
  { id: '7224 TRABALHADORES DE TREFILAÇÃO E ESTIRAMENTO DE METAIS PUROS E LIGAS METÁLICAS', descricao: 'TRABALHADORES DE TREFILAÇÃO E ESTIRAMENTO DE METAIS PUROS E LIGAS METÁLICAS' },
  { id: '7654 TRABALHADORES DO ACABAMENTO DE ARTEFATOS DE TECIDOS E COUROS', descricao: 'TRABALHADORES DO ACABAMENTO DE ARTEFATOS DE TECIDOS E COUROS' },
  { id: '7623 TRABALHADORES DO ACABAMENTO DE COUROS E PELES', descricao: 'TRABALHADORES DO ACABAMENTO DE COUROS E PELES' },
  { id: '7663 TRABALHADORES DO ACABAMENTO GRÁFICO', descricao: 'TRABALHADORES DO ACABAMENTO GRÁFICO' },
  { id: '7622 TRABALHADORES DO CURTIMENTO DE COUROS E PELES', descricao: 'TRABALHADORES DO CURTIMENTO DE COUROS E PELES' },
  { id: '5121 TRABALHADORES DOS SERVIÇOS DOMÉSTICOS EM GERAL', descricao: 'TRABALHADORES DOS SERVIÇOS DOMÉSTICOS EM GERAL' },
  { id: '5165 TRABALHADORES DOS SERVIÇOS FUNERÁRIOS', descricao: 'TRABALHADORES DOS SERVIÇOS FUNERÁRIOS' },
  { id: '9921 TRABALHADORES ELEMENTARES DE SERVIÇOS DE MANUTENÇÃO VEICULAR', descricao: 'TRABALHADORES ELEMENTARES DE SERVIÇOS DE MANUTENÇÃO VEICULAR' },
  { id: '4153 TRABALHADORES EM REGISTROS E INFORMAÇÕES EM SAÚDE', descricao: 'TRABALHADORES EM REGISTROS E INFORMAÇÕES EM SAÚDE' },
  { id: '5151 TRABALHADORES EM SERVIÇOS DE PROMOÇÃO E APOIO À SAÚDE', descricao: 'TRABALHADORES EM SERVIÇOS DE PROMOÇÃO E APOIO À SAÚDE' },
  { id: '6320 TRABALHADORES FLORESTAIS POLIVALENTES', descricao: 'TRABALHADORES FLORESTAIS POLIVALENTES' },
  { id: '6233 TRABALHADORES NA AVICULTURA E CUNICULTURA', descricao: 'TRABALHADORES NA AVICULTURA E CUNICULTURA' },
  { id: '6234 TRABALHADORES NA CRIAÇÃO DE INSETOS E ANIMAIS ÚTEIS', descricao: 'TRABALHADORES NA CRIAÇÃO DE INSETOS E ANIMAIS ÚTEIS' },
  { id: '8484 TRABALHADORES NA DEGUSTAÇÃO E CLASSIFICAÇÃO DE GRÃOS E AFINS', descricao: 'TRABALHADORES NA DEGUSTAÇÃO E CLASSIFICAÇÃO DE GRÃOS E AFINS' },
  { id: '8417 TRABALHADORES NA FABRICAÇÃO DE CACHAÇA, CERVEJA, VINHOS E OUTRAS BEBIDAS', descricao: 'TRABALHADORES NA FABRICAÇÃO DE CACHAÇA, CERVEJA, VINHOS E OUTRAS BEBIDAS' },
  { id: '8414 TRABALHADORES NA FABRICAÇÃO E CONSERVAÇÃO DE ALIMENTOS', descricao: 'TRABALHADORES NA FABRICAÇÃO E CONSERVAÇÃO DE ALIMENTOS' },
  { id: '8413 TRABALHADORES NA FABRICAÇÃO E REFINO DE AÇÚCAR', descricao: 'TRABALHADORES NA FABRICAÇÃO E REFINO DE AÇÚCAR' },
  { id: '8416 TRABALHADORES NA INDUSTRIALIZAÇÃO DE CAFÉ, CACAU, MATE E DE PRODUTOS AFINS', descricao: 'TRABALHADORES NA INDUSTRIALIZAÇÃO DE CAFÉ, CACAU, MATE E DE PRODUTOS AFINS' },
  { id: '7154 TRABALHADORES NA OPERAÇÃO DE MÁQUINAS DE CONCRETO USINADO E AFINS', descricao: 'TRABALHADORES NA OPERAÇÃO DE MÁQUINAS DE CONCRETO USINADO E AFINS' },
  { id: '7151 TRABALHADORES NA OPERAÇÃO DE MÁQUINAS DE TERRAPLENAGEM E FUNDAÇÕES', descricao: 'TRABALHADORES NA OPERAÇÃO DE MÁQUINAS DE TERRAPLENAGEM E FUNDAÇÕES' },
  { id: '8415 TRABALHADORES NA PASTEURIZAÇÃO DO LEITE E NA FABRICAÇÃO DE LATICÍNIOS E AFINS', descricao: 'TRABALHADORES NA PASTEURIZAÇÃO DO LEITE E NA FABRICAÇÃO DE LATICÍNIOS E AFINS' },
  { id: '6231 TRABALHADORES NA PECUÁRIA DE ANIMAIS DE GRANDE PORTE', descricao: 'TRABALHADORES NA PECUÁRIA DE ANIMAIS DE GRANDE PORTE' },
  { id: '6232 TRABALHADORES NA PECUÁRIA DE ANIMAIS DE MÉDIO PORTE', descricao: 'TRABALHADORES NA PECUÁRIA DE ANIMAIS DE MÉDIO PORTE' },
  { id: '5134 TRABALHADORES NO ATENDIMENTO EM ESTABELECIMENTOS DE SERVIÇOS DE ALIMENTAÇÃO, BEBIDAS E', descricao: 'TRABALHADORES NO ATENDIMENTO EM ESTABELECIMENTOS DE SERVIÇOS DE ALIMENTAÇÃO, BEBIDAS E' },
  { id: '8412 TRABALHADORES NO BENEFICIAMENTO DO SAL', descricao: 'TRABALHADORES NO BENEFICIAMENTO DO SAL' },
  { id: '5143 TRABALHADORES NOS SERVIÇOS DE MANUTENÇÃO DE EDIFICAÇÕES', descricao: 'TRABALHADORES NOS SERVIÇOS DE MANUTENÇÃO DE EDIFICAÇÕES' },
  { id: '5141 TRABALHADORES NOS SERVIÇOS DE ADMINISTRAÇÃO DE EDIFÍCIOS', descricao: 'TRABALHADORES NOS SERVIÇOS DE ADMINISTRAÇÃO DE EDIFÍCIOS' },
  { id: '4152 TRABALHADORES NOS SERVIÇOS DE CLASSIFICAÇÃO E ENTREGAS DE CORRESPONDÊNCIAS, ENCOMENDAS E', descricao: 'TRABALHADORES NOS SERVIÇOS DE CLASSIFICAÇÃO E ENTREGAS DE CORRESPONDÊNCIAS, ENCOMENDAS E' },
  { id: '5142 TRABALHADORES NOS SERVIÇOS DE COLETA DE RESÍDUOS, DE LIMPEZA E CONSERVAÇÃO DE ÁREAS PÚBLICAS', descricao: 'TRABALHADORES NOS SERVIÇOS DE COLETA DE RESÍDUOS, DE LIMPEZA E CONSERVAÇÃO DE ÁREAS PÚBLICAS' },
  { id: '5161 TRABALHADORES NOS SERVIÇOS DE EMBELEZAMENTO E HIGIENE', descricao: 'TRABALHADORES NOS SERVIÇOS DE EMBELEZAMENTO E HIGIENE' },
  { id: '9922 TRABALHADORES OPERACIONAIS DE CONSERVAÇÃO DE VIAS PERMANENTES (EXCETO TRILHOS)', descricao: 'TRABALHADORES OPERACIONAIS DE CONSERVAÇÃO DE VIAS PERMANENTES (EXCETO TRILHOS)' },
  { id: '7650 TRABALHADORES POLIVALENTES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS E COUROS', descricao: 'TRABALHADORES POLIVALENTES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS E COUROS' },
  { id: '7640 TRABALHADORES POLIVALENTES DA CONFECÇÃO DE CALÇADOS', descricao: 'TRABALHADORES POLIVALENTES DA CONFECÇÃO DE CALÇADOS' },
  { id: '7610 TRABALHADORES POLIVALENTES DAS INDÚSTRIAS TÊXTEIS', descricao: 'TRABALHADORES POLIVALENTES DAS INDÚSTRIAS TÊXTEIS' },
  { id: '7620 TRABALHADORES POLIVALENTES DO CURTIMENTO DE COUROS E PELES', descricao: 'TRABALHADORES POLIVALENTES DO CURTIMENTO DE COUROS E PELES' },
  { id: '7817 TRABALHADORES SUBAQUÁTICOS', descricao: 'TRABALHADORES SUBAQUÁTICOS' },
  { id: '7686 TRABALHADORES TIPOGRÁFICOS LINOTIPISTAS E AFINS', descricao: 'TRABALHADORES TIPOGRÁFICOS LINOTIPISTAS E AFINS' },
  { id: '724410 Traçador de caldeiraria', descricao: 'Traçador de caldeiraria' },
  { id: '724225 Traçador de estruturas metálicas', descricao: 'Traçador de estruturas metálicas' },
  { id: '712230 Traçador de pedras', descricao: 'Traçador de pedras' },
  { id: '752210 Traçador de vidros', descricao: 'Traçador de vidros' },
  { id: '261420 Tradutor', descricao: 'Tradutor' },
  { id: '261425 Tradutor de libras', descricao: 'Tradutor de libras' },
  { id: '261420 Tradutor de textos eletrônicos', descricao: 'Tradutor de textos eletrônicos' },
  { id: '261420 Tradutor de textos escritos', descricao: 'Tradutor de textos escritos' },
  { id: '261420 Tradutor público juramentado', descricao: 'Tradutor público juramentado' },
  { id: '261410 Tradutor simultâneo', descricao: 'Tradutor simultâneo' },
  { id: '261425 Tradutor-intérprete de libras', descricao: 'Tradutor-intérprete de libras' },
  { id: '768125 Trançador - na fabricação de chapéus de palha', descricao: 'Trançador - na fabricação de chapéus de palha' },
  { id: '724610 Trançador de cabos de aço', descricao: 'Trançador de cabos de aço' },
  { id: '768325 Trançador de couro', descricao: 'Trançador de couro' },
  { id: '7246 TRANÇADORES E LACEIROS DE CABOS DE AÇO', descricao: 'TRANÇADORES E LACEIROS DE CABOS DE AÇO' },
  { id: '752120 Transformador de tubos de vidro', descricao: 'Transformador de tubos de vidro' },
  { id: '342105 Transitário de cargas', descricao: 'Transitário de cargas' },
  { id: '632125 Transportador de árvores', descricao: 'Transportador de árvores' },
  { id: '632125 Transportador de madeiras', descricao: 'Transportador de madeiras' },
  { id: '376255 Trapezista', descricao: 'Trapezista' },
  { id: '623105 Tratador (asininos e muares)', descricao: 'Tratador (asininos e muares)' },
  { id: '623125 Tratador (eqüinos)', descricao: 'Tratador (eqüinos)' },
  { id: '623020 Tratador - na pecuária', descricao: 'Tratador - na pecuária' },
  { id: '623020 Tratador de animais', descricao: 'Tratador de animais' },
  { id: '623020 Tratador de animais (jardim zoológico)', descricao: 'Tratador de animais (jardim zoológico)' },
  { id: '623205 Tratador de animais - caprinos', descricao: 'Tratador de animais - caprinos' },
  { id: '623020 Tratador de animais - na pecuária', descricao: 'Tratador de animais - na pecuária' },
  { id: '371110 Tratador de documentos (biblioteconomia)', descricao: 'Tratador de documentos (biblioteconomia)' },
  { id: '6230 TRATADORES POLIVALENTES DE ANIMAIS', descricao: 'TRATADORES POLIVALENTES DE ANIMAIS' },
  { id: '641015 Tratorista operador de roçadeira', descricao: 'Tratorista operador de roçadeira' },
  { id: '715145 Tratorista - exclusive na agropecuária', descricao: 'Tratorista - exclusive na agropecuária' },
  { id: '641015 Tratorista agrícola', descricao: 'Tratorista agrícola' },
  { id: '642015 Tratorista florestal', descricao: 'Tratorista florestal' },
  { id: '641015 Tratorista operador de semeadeira', descricao: 'Tratorista operador de semeadeira' },
  { id: '225270 Traumatologista', descricao: 'Traumatologista' },
  { id: '751130 Trefilador (joalheria e ourivesaria)', descricao: 'Trefilador (joalheria e ourivesaria)' },
  { id: '722415 Trefilador à máquina', descricao: 'Trefilador à máquina' },
  { id: '722415 Trefilador de barras de metal, à máquina', descricao: 'Trefilador de barras de metal, à máquina' },
  { id: '811775 Trefilador de borracha', descricao: 'Trefilador de borracha' },
  { id: '751130 Trefilador de metais preciosos, a máquina', descricao: 'Trefilador de metais preciosos, a máquina' },
  { id: '722415 Trefilador de metais, à máquina', descricao: 'Trefilador de metais, à máquina' },
  { id: '623105 Treinador (asininos e muares)', descricao: 'Treinador (asininos e muares)' },
  { id: '623125 Treinador (eqüinos)', descricao: 'Treinador (eqüinos)' },
  { id: '224125 Treinador assistente de modalidade esportiva', descricao: 'Treinador assistente de modalidade esportiva' },
  { id: '224125 Treinador auxiliar de modalidade esportiva', descricao: 'Treinador auxiliar de modalidade esportiva' },
  { id: '376225 Treinador de animais (circense)', descricao: 'Treinador de animais (circense)' },
  { id: '623005 Treinador de animais domésticos', descricao: 'Treinador de animais domésticos' },
  { id: '224125 Treinador esportivo', descricao: 'Treinador esportivo' },
  { id: '224135 Treinador profissional de futebol', descricao: 'Treinador profissional de futebol' },
  { id: '761205 Triador de lã', descricao: 'Triador de lã' },
  { id: '519210 Triador de material reciclável', descricao: 'Triador de material reciclável' },
  { id: '519210 Triador de sucata', descricao: 'Triador de sucata' },
  { id: '768115 Tricotador, à mão', descricao: 'Tricotador, à mão' },
  { id: '768115 Tricoteiro, à mão', descricao: 'Tricoteiro, à mão' },
  { id: '762110 Trinchador', descricao: 'Trinchador' },
  { id: '848525 Tripeiro em matadouro', descricao: 'Tripeiro em matadouro' },
  { id: '612115 Triticultor', descricao: 'Triticultor' },
  { id: '622120 Triticultor - conta própria', descricao: 'Triticultor - conta própria' },
  { id: '622120 Triticultor - empregador', descricao: 'Triticultor - empregador' },
  { id: '511215 Trocador', descricao: 'Trocador' },
  { id: '782810 Tropeiro', descricao: 'Tropeiro' },
  { id: '622605 Tropeiro - na cultura de cacau', descricao: 'Tropeiro - na cultura de cacau' },
  { id: '261525 Trovador', descricao: 'Trovador' },
  { id: '914305 Truqueiro (ferrovias)', descricao: 'Truqueiro (ferrovias)' },
  { id: '841310 Turbineiro na refinação de açúcar', descricao: 'Turbineiro na refinação de açúcar' },
  { id: '122520 Turismólogo', descricao: 'Turismólogo' },
  { id: '113005 Tuxaua', descricao: 'Tuxaua' },
  { id: '263105 Upasaka', descricao: 'Upasaka' },
  { id: '263105 Upasika', descricao: 'Upasika' },
  { id: '214130 Urbanista', descricao: 'Urbanista' },
  { id: '225285 Urologista', descricao: 'Urologista' },
  { id: '623020 Vacinador', descricao: 'Vacinador' },
  { id: '519945 Vagalume', descricao: 'Vagalume' },
  { id: '622020 Valeiro - na cultura', descricao: 'Valeiro - na cultura' },
  { id: '715120 Valeteiro - exclusive na agropecuária', descricao: 'Valeteiro - exclusive na agropecuária' },
  { id: '762345 Vaqueador de couros e peles', descricao: 'Vaqueador de couros e peles' },
  { id: '623110 Vaqueiro', descricao: 'Vaqueiro' },
  { id: '623115 Vaqueiro (bovinhos leite)', descricao: 'Vaqueiro (bovinhos leite)' },
  { id: '623110 Vaqueiro (bovinos corte)', descricao: 'Vaqueiro (bovinos corte)' },
  { id: '623120 Vaqueiro (bubalinos)', descricao: 'Vaqueiro (bubalinos)' },
  { id: '623110 Vaqueiro - na agropecuária - exclusive conta própria e empregador', descricao: 'Vaqueiro - na agropecuária - exclusive conta própria e empregador' },
  { id: '623115 Vaqueiro inseminador', descricao: 'Vaqueiro inseminador' },
  { id: '623110 Vaqueiro inseminador (bovinos corte)', descricao: 'Vaqueiro inseminador (bovinos corte)' },
  { id: '768120 Varandeira', descricao: 'Varandeira' },
  { id: '514215 Varredor de rua', descricao: 'Varredor de rua' },
  { id: '992225 Varredor na conservação de vias permanentes (exceto trilhos)', descricao: 'Varredor na conservação de vias permanentes (exceto trilhos)' },
  { id: '776430 Vassoureiro', descricao: 'Vassoureiro' },
  { id: '722230 Vazador de metais', descricao: 'Vazador de metais' },
  { id: '823250 Vazador de vidro fundido', descricao: 'Vazador de vidro fundido' },
  { id: '823250 Vazador de vidro fundido em moldes', descricao: 'Vazador de vidro fundido em moldes' },
  { id: '262505 Vedete', descricao: 'Vedete' },
  { id: '524215 Vendedor permissionário', descricao: 'Vendedor permissionário' },
  { id: '521110 Vendedor - no comércio de mercadorias', descricao: 'Vendedor - no comércio de mercadorias' },
  { id: '524305 Vendedor ambulante', descricao: 'Vendedor ambulante' },
  { id: '421210 Vendedor de apostas de turfe', descricao: 'Vendedor de apostas de turfe' },
  { id: '524305 Vendedor de cachorro quente', descricao: 'Vendedor de cachorro quente' },
  { id: '521110 Vendedor de comércio varejista', descricao: 'Vendedor de comércio varejista' },
  { id: '524105 Vendedor de consórcio', descricao: 'Vendedor de consórcio' },
  { id: '354120 Vendedor de informações comerciais', descricao: 'Vendedor de informações comerciais' },
  { id: '524210 Vendedor de jornais e revistas', descricao: 'Vendedor de jornais e revistas' },
  { id: '524105 Vendedor de livros', descricao: 'Vendedor de livros' },
  { id: '524310 Vendedor de pipocas ambulante', descricao: 'Vendedor de pipocas ambulante' },
  { id: '524105 Vendedor de plano de saúde', descricao: 'Vendedor de plano de saúde' },
  { id: '421210 Vendedor de poule', descricao: 'Vendedor de poule' },
  { id: '524105 Vendedor de serviços', descricao: 'Vendedor de serviços' },
  { id: '521105 Vendedor em comércio atacadista', descricao: 'Vendedor em comércio atacadista' },
  { id: '524105 Vendedor em domicílio', descricao: 'Vendedor em domicílio' },
  { id: '521110 Vendedor interno', descricao: 'Vendedor interno' },
  { id: '524105 Vendedor orçamentista', descricao: 'Vendedor orçamentista' },
  { id: '524105 Vendedor por catálogos', descricao: 'Vendedor por catálogos' },
  { id: '524105 Vendedor porta a porta', descricao: 'Vendedor porta a porta' },
  { id: '354145 Vendedor pracista', descricao: 'Vendedor pracista' },
  { id: '5243 VENDEDORES AMBULANTES', descricao: 'VENDEDORES AMBULANTES' },
  { id: '5242 VENDEDORES EM BANCAS, QUIOSQUES E BARRACAS', descricao: 'VENDEDORES EM BANCAS, QUIOSQUES E BARRACAS' },
  { id: '5241 VENDEDORES EM DOMICÍLIO', descricao: 'VENDEDORES EM DOMICÍLIO' },
  { id: '141410 Vendeiro (comércio varejista)', descricao: 'Vendeiro (comércio varejista)' },
  { id: '376215 Ventríloquo', descricao: 'Ventríloquo' },
  { id: '111120 Vereador', descricao: 'Vereador' },
  { id: '223305 Veterinário', descricao: 'Veterinário' },
  { id: '2233 VETERINÁRIOS E ZOOTECNISTAS', descricao: 'VETERINÁRIOS E ZOOTECNISTAS' },
  { id: '717025 Vibradorista', descricao: 'Vibradorista' },
  { id: '10115 Vice-almirante', descricao: 'Vice-almirante' },
  { id: '131310 Vice-diretor de centro de educação infantil público', descricao: 'Vice-diretor de centro de educação infantil público' },
  { id: '131310 Vice-diretor de centro de ensino especial público', descricao: 'Vice-diretor de centro de ensino especial público' },
  { id: '131310 Vice-diretor de centro de ensino fundamental público', descricao: 'Vice-diretor de centro de ensino fundamental público' },
  { id: '131310 Vice-diretor de centro de ensino médio público', descricao: 'Vice-diretor de centro de ensino médio público' },
  { id: '131310 Vice-diretor de centro de ensino médio, profissionalizante público', descricao: 'Vice-diretor de centro de ensino médio, profissionalizante público' },
  { id: '131310 Vice-diretor de centro de ensino supletivo público', descricao: 'Vice-diretor de centro de ensino supletivo público' },
  { id: '131310 Vice-diretor de centro interescolar de línguas público', descricao: 'Vice-diretor de centro interescolar de línguas público' },
  { id: '131310 Vice-diretor de colégio público', descricao: 'Vice-diretor de colégio público' },
  { id: '131310 Vice-diretor de escola de classe pública', descricao: 'Vice-diretor de escola de classe pública' },
  { id: '131310 Vice-diretor de escola pública', descricao: 'Vice-diretor de escola pública' },
  { id: '131310 Vice-diretor de grupo escolar público', descricao: 'Vice-diretor de grupo escolar público' },
  { id: '111240 Vice-governador de estado', descricao: 'Vice-governador de estado' },
  { id: '111245 Vice-governador do distrito federal', descricao: 'Vice-governador do distrito federal' },
  { id: '111255 Vice-prefeito', descricao: 'Vice-prefeito' },
  { id: '123305 Vice-presidente comercial', descricao: 'Vice-presidente comercial' },
  { id: '111210 Vice-presidente da república', descricao: 'Vice-presidente da república' },
  { id: '123310 Vice-presidente de marketing', descricao: 'Vice-presidente de marketing' },
  { id: '123305 Vice-presidente de vendas', descricao: 'Vice-presidente de vendas' },
  { id: '516805 Vidente', descricao: 'Vidente' },
  { id: '716305 Vidraceiro', descricao: 'Vidraceiro' },
  { id: '141410 Vidraceiro (comércio varejista)', descricao: 'Vidraceiro (comércio varejista)' },
  { id: '716310 Vidraceiro (edificações)', descricao: 'Vidraceiro (edificações)' },
  { id: '716305 Vidraceiro (painéis de vidro)', descricao: 'Vidraceiro (painéis de vidro)' },
  { id: '716315 Vidraceiro (vitrais)', descricao: 'Vidraceiro (vitrais)' },
  { id: '716305 Vidraceiro colocador de vidros', descricao: 'Vidraceiro colocador de vidros' },
  { id: '716310 Vidraceiro de clarabóias', descricao: 'Vidraceiro de clarabóias' },
  { id: '141410 Vidraceiro de quadros e molduras (comércio varejista)', descricao: 'Vidraceiro de quadros e molduras (comércio varejista)' },
  { id: '7163 VIDRACEIROS (REVESTIMENTOS RÍGIDOS)', descricao: 'VIDRACEIROS (REVESTIMENTOS RÍGIDOS)' },
  { id: '752420 Vidrador-esmaltador em cerâmica', descricao: 'Vidrador-esmaltador em cerâmica' },
  { id: '823215 Vidreiro', descricao: 'Vidreiro' },
  { id: '7524 VIDREIROS E CERAMISTAS (ARTE E DECORAÇÃO)', descricao: 'VIDREIROS E CERAMISTAS (ARTE E DECORAÇÃO)' },
  { id: '263105 Vigário', descricao: 'Vigário' },
  { id: '517420 Vigia', descricao: 'Vigia' },
  { id: '631415 Vigia da proa', descricao: 'Vigia da proa' },
  { id: '517420 Vigia de rua', descricao: 'Vigia de rua' },
  { id: '517320 Vigia florestal', descricao: 'Vigia florestal' },
  { id: '517420 Vigia noturno', descricao: 'Vigia noturno' },
  { id: '517325 Vigia portuário', descricao: 'Vigia portuário' },
  { id: '517330 Vigilante', descricao: 'Vigilante' },
  { id: '517330 Vigilante bancário', descricao: 'Vigilante bancário' },
  { id: '517305 Vigilante de aeroporto', descricao: 'Vigilante de aeroporto' },
  { id: '517305 Vigilante de proteção de aeroporto', descricao: 'Vigilante de proteção de aeroporto' },
  { id: '515120 Vigilante de saúde', descricao: 'Vigilante de saúde' },
  { id: '5173 VIGILANTES E GUARDAS DE SEGURANÇA', descricao: 'VIGILANTES E GUARDAS DE SEGURANÇA' },
  { id: '776405 Vimeiro', descricao: 'Vimeiro' },
  { id: '841740 Vinagreiro', descricao: 'Vinagreiro' },
  { id: '841720 Vinhateiro', descricao: 'Vinhateiro' },
  { id: '612515 Vinicultor', descricao: 'Vinicultor' },
  { id: '764115 Virador de calçados', descricao: 'Virador de calçados' },
  { id: '724510 Virador de chapas', descricao: 'Virador de chapas' },
  { id: '848525 Virador em matadouro', descricao: 'Virador em matadouro' },
  { id: '515105 Visitador de saúde', descricao: 'Visitador de saúde' },
  { id: '515105 Visitador de saúde em domicílio', descricao: 'Visitador de saúde em domicílio' },
  { id: '515120 Visitador sanitário', descricao: 'Visitador sanitário' },
  { id: '515120 Visitador sanitário domiciliar', descricao: 'Visitador sanitário domiciliar' },
  { id: '951320 Vistoriador de alarmes', descricao: 'Vistoriador de alarmes' },
  { id: '351725 Vistoriador de risco de auto', descricao: 'Vistoriador de risco de auto' },
  { id: '351730 Vistoriador de sinistros', descricao: 'Vistoriador de sinistros' },
  { id: '215150 Vistoriador naval', descricao: 'Vistoriador naval' },
  { id: '375115 Visual merchandiser', descricao: 'Visual merchandiser' },
  { id: '612515 Viticultor', descricao: 'Viticultor' },
  { id: '716315 Vitralista (vitrais)', descricao: 'Vitralista (vitrais)' },
  { id: '752420 Vitrificador', descricao: 'Vitrificador' },
  { id: '375110 Vitrinista', descricao: 'Vitrinista' },
  { id: '632015 Viveirista (mudas)', descricao: 'Viveirista (mudas)' },
  { id: '622015 Viveirista agrícola', descricao: 'Viveirista agrícola' },
  { id: '612420 Viveirista de flores e plantas ornamentais', descricao: 'Viveirista de flores e plantas ornamentais' },
  { id: '632015 Viveirista florestal', descricao: 'Viveirista florestal' },
  { id: '261415 Vocabularista', descricao: 'Vocabularista' },
  { id: '263105 Voduno ( ministro de culto religioso)', descricao: 'Voduno ( ministro de culto religioso)' },
  { id: '263105 Vodunsi (ministro de culto religioso)', descricao: 'Vodunsi (ministro de culto religioso)' },
  { id: '263105 Vodunsi poncilê (ministro de culto religioso)', descricao: 'Vodunsi poncilê (ministro de culto religioso)' },
  { id: '622020 Volante na agricultura', descricao: 'Volante na agricultura' },
  { id: '841745 Xaropeiro', descricao: 'Xaropeiro' },
  { id: '841745 Xaropeiro - na indústria de bebidas', descricao: 'Xaropeiro - na indústria de bebidas' },
  { id: '263105 Xeramõe (ministro de culto religioso)', descricao: 'Xeramõe (ministro de culto religioso)' },
  { id: '263105 Xondaria (ministro de culto religioso)', descricao: 'Xondaria (ministro de culto religioso)' },
  { id: '263105 Xondáro (ministro de culto religioso)', descricao: 'Xondáro (ministro de culto religioso)' },
  { id: '263105 Ywyrájá (ministro de culto religioso)', descricao: 'Ywyrájá (ministro de culto religioso)' },
  { id: '377110 Zagueiro - jogador de futebol', descricao: 'Zagueiro - jogador de futebol' },
  { id: '514120 Zelador', descricao: 'Zelador' },
  { id: '514120 Zelador de edifício', descricao: 'Zelador de edifício' },
  { id: '263110 Zenji (missionário)', descricao: 'Zenji (missionário)' },
  { id: '723235 Zincador', descricao: 'Zincador' },
  { id: '203010 Zoologista', descricao: 'Zoologista' },
  { id: '203010 Zoólogo', descricao: 'Zoólogo' },
  { id: '223310 Zootecnista', descricao: 'Zootecnista' },
];

export const profissaoOptions = profArray.filter(function (item, pos, self) {
  return self.indexOf(item) == pos;
});



export const comprovanteOptions = [
  {
    id: 'CP',
    descricao: 'CP',
  },
  {
    id: 'CP+HP',
    descricao: 'CP+HP',
  },
  {
    id: 'CP+Extratos Bancários',
    descricao: 'CP+Extratos Bancários',
  },
  {
    id: 'Extratos Bancários',
    descricao: 'Extratos Bancários',
  },
  {
    id: 'Benefício INSS',
    descricao: 'Benefício INSS',
  },
  {
    id: 'Aposentadoria',
    descricao: 'Aposentadoria',
  },
];

export const bancoOptions = [
  {
    id: 1152,
    descricao: 'Banco Agibank S.A.',
  },
  {
    id: 336,
    descricao: 'Banco C6 Bank',
  },
  {
    id: 6,
    descricao: 'Banco do Brasil S.A.',
  },
  {
    id: 1150,
    descricao: 'Banco Western Union do Brasil S.A.',
  },
];

export const tipoRelacaoOptions = [
  {
    id: 1,
    descricao: 'Cliente',
  },
  {
    id: 2,
    descricao: 'Fornecedor(a)',
  },
];

export const parentescoOptions = [
  {
    id: 1,
    descricao: 'Pai',
  },
  {
    id: 2,
    descricao: 'Mãe',
  },
  {
    id: 3,
    descricao: 'Primo(a)',
  },
  {
    id: 4,
    descricao: 'Filho(a)',
  },
  {
    id: 5,
    descricao: 'Sobrinho(a)',
  },
  {
    id: 6,
    descricao: 'Amigo(a)',
  },
  {
    id: 7,
    descricao: 'Outros',
  },
  {
    id: 8,
    descricao: 'Parente',
  },
  {
    id: 9,
    descricao: 'Cliente',
  },
  {
    id: 10,
    descricao: 'Fornecedor(a)',
  },
  {
    id: 11,
    descricao: 'Vizinho(a)',
  },
];

export const status = [
  {
    descricao: 'AGUARDANDO ANÁLISE',
    id: '1',
  },
  {
    descricao: 'CANCELADO',
    id: '27',
  },
  {
    descricao: 'EM ANÁLISE',
    id: '2',
  },
  {
    descricao: 'RECUSADO',
    id: '4',
  },
  {
    descricao: 'APROVADO',
    id: '3',
  },
  {
    descricao: 'RECUSADO AUTOMATICAMENTE',
    id: '12',
  },
  {
    descricao: 'APROVADO AUTOMATICAMENTE',
    id: '6',
  },
  {
    descricao: 'SOLICITA DOCUMENTAÇÃO DE APOIO',
    id: '13',
  },
  {
    descricao: 'APROVADO COM ALTERAÇÃO',
    id: '7',
  },
  {
    descricao: 'APROVADO SOMENTE NA CONDIÇÃO À VISTA',
    id: '36',
  }
];
