export const camposAvalistaEndereco = [
  {
    label: 'CEP',
    name: 'avalista-cep-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'avalista-logradouro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'avalista-bairro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'avalista-complemento-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  /* {
    label: 'Codigo IBGE',
    name: 'avalista-codigo-ibge-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
  {
    label: 'Número',
    name: 'avalista-num-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'avalista-cidade-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'avalista-uf-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const camposAvalistaProposta = [
  /* {
    label: 'Categoria',
    name: 'categoria-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, 
  {
    label: 'Modalidade',
    name: 'avalista-modalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Parecer',
    name: 'avalista-parecer',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
];

export const camposAvalistaIdentificacao = [
  {
    label: 'CPF',
    name: 'avalista-cpf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Nome',
    name: 'avalista-nome',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'RG',
    name: 'avalista-rg',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Estado civil',
    name: 'avalista-estado-civil',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nacionalidade',
    name: 'avalista-nacionalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'E-mail',
    name: 'avalista-email',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNH',
    name: 'avalista-cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nº Segurança CNH',
    name: 'avalista-numero_serguranca_cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Data de nascimento',
    name: 'avalista-dt-nascimento',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Sexo',
    name: 'avalista-sexo',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nome da mãe',
    name: 'avalista-nome-mae',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fone residencial',
    name: 'avalista-fone-residencial',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Fone celular',
    name: 'avalista-fone-celular',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  /* {
    label: 'Nome do Cônjuge',
    name: 'avalista-conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CPF do Cônjuge',
    name: 'avalista-cpf-conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  }, */
];

export const camposAvalistaDadosComerciais = [
  /* {
    label: 'Natureza ocupação',
    name: 'avalista-natureza-ocupacao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, */
  {
    label: 'Profissão',
    name: 'avalista-profissao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Admissão',
    name: 'avalista-data-admissao',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Renda mensal',
    name: 'avalista-renda-mensal',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  /* {
    label: 'Comprovante',
    name: 'avalista-comprovante-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Empresa',
    name: 'avalista-empresa-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
  {
    label: 'CNPJ',
    name: 'avalista-cnpj-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  /* {
    label: 'Telefone',
    name: 'avalista-tel-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Ramal',
    name: 'avalista-ramal-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CEP',
    name: 'avalista-cep-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'avalista-logradouro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'avalista-num-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'avalista-complemento-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'avalista-codigo-ibge-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'avalista-bairro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'avalista-cidade-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'avalista-uf-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
];

/* export const mockCamposAvalistaReferenciasBancarias = [
  {
    label: 'Banco',
    name: 'avalista-banco-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'avalista-agencia-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'avalista-conta-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'avalista-desde-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Banco',
    name: 'avalista-banco-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'avalista-agencia-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'avalista-conta-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'avalista-desde-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
]; */

/* export const camposAvalistaReferenciasPessoais = [
  {
    label: 'Nome',
    name: 'avalista-nome-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'avalista-tel-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Grau Parentesco',
    name: 'avalista-grau-parentesco-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nome',
    name: 'avalista-nome-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'avalista-tel-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Grau Parentesco',
    name: 'avalista-grau-parentesco-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
]; */

export const camposAvalistaIntegracao = [
  /* {
    label: 'Categoria',
    name: 'avalista-categoria-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, 
  {
    label: 'Modalidade',
    name: 'avalista-modalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Parecer',
    name: 'avalista-parecer',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
  {
    label: 'CPF',
    name: 'avalista-cpf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Nome',
    name: 'avalista-nome',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'RG',
    name: 'avalista-rg',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Estado civil',
    name: 'avalista-estado-civil',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nacionalidade',
    name: 'avalista-nacionalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'E-mail',
    name: 'avalista-email',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNH',
    name: 'avalista-cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nº Segurança CNH',
    name: 'avalista-numero_serguranca_cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Data de nascimento',
    name: 'avalista-dt-nascimento',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Sexo',
    name: 'avalista-sexo',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nome da mãe',
    name: 'avalista-nome-mae',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fone residencial',
    name: 'avalista-fone-residencial',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Fone celular',
    name: 'avalista-fone-celular',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  /* {
    label: 'Nome do Cônjuge',
    name: 'avalista-conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CPF do Cônjuge',
    name: 'avalista-cpf-conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  }, */
  {
    label: 'CEP',
    name: 'avalista-cep-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'avalista-logradouro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'avalista-bairro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'avalista-complemento-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  /* {
    label: 'Codigo IBGE',
    name: 'avalista-codigo-ibge-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
  {
    label: 'Número',
    name: 'avalista-num-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'avalista-cidade-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'avalista-uf-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  /* {
    label: 'Natureza ocupação',
    name: 'avalista-natureza-ocupacao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, */
  {
    label: 'Profissão',
    name: 'avalista-profissao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Admissão',
    name: 'avalista-data-admissao',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Renda mensal',
    name: 'avalista-renda-mensal',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  /* {
    label: 'Comprovante',
    name: 'avalista-comprovante-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Empresa',
    name: 'avalista-empresa-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
  {
    label: 'CNPJ',
    name: 'avalista-cnpj-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  /* {
    label: 'Telefone',
    name: 'avalista-tel-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Ramal',
    name: 'avalista-ramal-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CEP',
    name: 'avalista-cep-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'avalista-logradouro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'avalista-num-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'avalista-complemento-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'avalista-codigo-ibge-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'avalista-bairro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'avalista-cidade-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'avalista-uf-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  }, */
];

export default camposAvalistaIntegracao;
