/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Input } from '@components/Form';
import { Button, message, Modal, Input as AntdInput } from 'antd';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import api from '@services/api';
import Login from '@components/Spinner/Login';
import { GlobalContext } from '@contexts/GlobalContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Props = { handleRedefinePass: () => void }

const ERRORS_CONSTANTS = {
  captcha: 'Falha na validação do Captcha, tente novamente',
  data: 'Login ou Senha Inválidos',
};

const CardLogin = ({ handleRedefinePass }: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const history = useHistory();
  const { subdominio } = useParams<{ subdominio: string }>();
  const { configuracoes, setInstituicao } = useContext(GlobalContext);
  const [entrarColor, setEntrarColor] = useState(null);

  const handleRecaptcha = async () => {
    // Execute o reCAPTCHA v3 e obtenha o token
    if (!executeRecaptcha) return;
    const token = await executeRecaptcha();
    sessionStorage.setItem('tokenCaptcha', token);
  };  // Execute o reCAPTCHA v3 assim que o componente for montado
  handleRecaptcha();

  useEffect(() => {
    if (configuracoes) setEntrarColor(configuracoes.interface.corPrimaria);
  }, [configuracoes]);

  /* useEffect(() => { // sempre que acessar a tela de login, limpa o token e a sessão
    let b2eInstituicao = sessionStorage.getItem('b2e:instituicao');
    sessionStorage.clear()

    if (b2eInstituicao)
      sessionStorage.setItem('b2e:instituicao', b2eInstituicao);
  }, []); */


  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: { username: '', password: '', captcha: '', },
    onSubmit: (data) => {
      setLoading(true);
      api.post('usuario', { ...data, captcha: sessionStorage.getItem('tokenCaptcha') || '' })
        .then((res) => {
          sessionStorage.setItem('loginLogged', formik.values.username);
          sessionStorage.setItem('tokenExp', res.data.expiringAt);

          if (res.status == 202) {
            sessionStorage.setItem('tipoRedefinir', 'Criar Nova Senha (1º Acesso)');
            sessionStorage.setItem('session1Acesso', res.data.session);
            sessionStorage.setItem('usernameHandle', formik.values.username)
            handleRedefinePass();
          } else {
            localStorage.setItem('token', res.data.accessToken);
            setInstituicao(sessionStorage.getItem('b2e:instituicao') || '');
            window.location.href = subdominio ? `/${subdominio}/cadastrar` : '/configuracao';
          }
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              return setLoginError(err.response?.data?.error || ERRORS_CONSTANTS.data);

            case 423:
              return setLoginError(ERRORS_CONSTANTS.captcha);

            default:
              return setLoginError(ERRORS_CONSTANTS.data);
          }
        })
        .finally(() => {
          setLoading(false);
          //sessionStorage.removeItem('newPassword');
        });
    },
    validate: (values) => {
      let errors = {};
      setLoginError('');

      if (!values.password)
        errors = { ...errors, password: 'Senha obrigatória', };

      return errors;
    },
  });

  return (
    <div className="card-login">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="col-lg-12">
              <h3>Entrar</h3>
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-12">
              <Input name="username" label="Login" placeholder="Digite o login" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-12 input-wrapper">
              <label htmlFor='password'>Senha</label>
              <AntdInput.Password id='password' placeholder='Digite a senha'
                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                value={formik.values.password} />
            </div>
          </div>
          <div className="login-error">
            {formik.touched.captcha && loginError}
          </div>
          {/* window.location.hostname !== 'localhost' && */ <div>
            <div className="form-row mt-2">
              <div className="col-lg-12">
                <div className="recaptcha">
                </div>
              </div>
            </div>
          </div>}
          <div className="form-row">
            <div className="col-lg-12">
              <Button type="primary" htmlType="submit" id="btnLogin"
                block shape="round" style={{ backgroundColor: entrarColor || '#881220', border: 0 }}>
                {!loading ? 'Entrar' : <Login />}
              </Button>
            </div>
          </div>
          <div className="redefine-pass">
            Esqueceu a senha?
            <a style={{ color: entrarColor || '#881220' }} className="ml-1"
              onClick={() => {
                sessionStorage.setItem('usernameHandle', formik.values.username)
                sessionStorage.setItem('tipoRedefinir', 'Criar Nova Senha')
                handleRedefinePass()
              }}>
              Redefinir
            </a>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default CardLogin;
