import {
  camposDadosComerciais,
  camposEndereco,
  camposIdentificacao,
  camposProposta,
  mockCamposReferenciasBancarias,
  camposReferenciasPessoais,
} from '@pages/Configuracao/camposIntegracao';

export const BlocoPF = [
  {
    title: 'Proposta',
    campos: camposProposta,
  },
  {
    title: 'Identificação',
    campos: camposIdentificacao,
  },
  {
    title: 'Endereço',
    campos: camposEndereco,
  },
  {
    title: 'Dados Comerciais',
    campos: camposDadosComerciais,
  },
  {
    title: 'Referências Bancárias',
    campos: mockCamposReferenciasBancarias,
  },
  {
    title: 'Referências Pessoais',
    campos: camposReferenciasPessoais,
  },
];
