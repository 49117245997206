export default [
	{
		"id": 1096,
		"descricao": "172 - Albatross Corretora de Câmbio e Valores S.A "
	},
	{
		"id": 1186,
		"descricao": "188 - Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores"
	},
	{
		"id": 1254,
		"descricao": "654 - Banco A.J. Renner S.A."
	},
	{
		"id": 1204,
		"descricao": "246 - Banco ABC Brasil S.A."
	},
	{
		"id": 1120,
		"descricao": "75 - Banco ABN Amro S.A."
	},
	{
		"id": 1152,
		"descricao": "121 - Banco Agibank S.A."
	},
	{
		"id": 1109,
		"descricao": "25 - Banco Alfa S.A."
	},
	{
		"id": 1250,
		"descricao": "641 - Banco Alvorada S.A."
	},
	{
		"id": 1279,
		"descricao": "065 - Banco AndBank (Brasil) S.A."
	},
	{
		"id": 1117,
		"descricao": "65 - Banco Andbank (Brasil) S.A."
	},
	{
		"id": 1196,
		"descricao": "213 - Banco Arbi S.A."
	},
	{
		"id": 1108,
		"descricao": "24 - Banco Bandepe S.A."
	},
	{
		"id": 1261,
		"descricao": "740 - Banco Barclays S.A."
	},
	{
		"id": 1137,
		"descricao": "96 - Banco BM&FBovespa"
	},
	{
		"id": 1217,
		"descricao": "318 - Banco BMG S.A."
	},
	{
		"id": 1267,
		"descricao": "752 - Banco BNP Paribas Brasil S.A."
	},
	{
		"id": 1205,
		"descricao": "248 - Banco Boavista Interatlântico S.A."
	},
	{
		"id": 1144,
		"descricao": "107 - Banco Bocom BBM S.A. "
	},
	{
		"id": 1115,
		"descricao": "63 - Banco Bradescard S.A."
	},
	{
		"id": 1111,
		"descricao": "36 - Banco Bradesco BBI S.A."
	},
	{
		"id": 1153,
		"descricao": "122 - Banco Bradesco BERJ S.A."
	},
	{
		"id": 1193,
		"descricao": "204 - Banco Bradesco Cartões S.A."
	},
	{
		"id": 1223,
		"descricao": "394 - Banco Bradesco Financiamentos S.A."
	},
	{
		"id": 1155,
		"descricao": "125 - Banco Brasil Plural S.A. – Banco Múltiplo"
	},
	{
		"id": 1125,
		"descricao": "81 - Banco Brasileiro de Negócios S.A. – BBN"
	},
	{
		"id": 1198,
		"descricao": "218 - Banco BS2 S.A."
	},
	{
		"id": 1194,
		"descricao": "208 - Banco BTG Pactual S.A."
	},
	{
		"id": 336,
		"descricao": "336 - Banco C6 Bank"
	},
	{
		"id": 1227,
		"descricao": "473 - Banco Caixa Geral – Brasil S.A."
	},
	{
		"id": 1224,
		"descricao": "412 - Banco Capital S.A."
	},
	{
		"id": 1278,
		"descricao": "040 - Banco Cargill S.A."
	},
	{
		"id": 1212,
		"descricao": "266 - Banco Cédula S.A."
	},
	{
		"id": 1260,
		"descricao": "739 - Banco Cetelem S.A."
	},
	{
		"id": 1201,
		"descricao": "233 - Banco Cifra S.A."
	},
	{
		"id": 1202,
		"descricao": "241 - Banco Clássico S.A."
	},
	{
		"id": 1136,
		"descricao": "95 - Banco Confidence de Câmbio S.A."
	},
	{
		"id": 1295,
		"descricao": "756 - Banco Cooperativo Sicoob S.A."
	},
	{
		"id": 1199,
		"descricao": "222 - Banco Credit Agricole Brasil S.A."
	},
	{
		"id": 1235,
		"descricao": "505 - Banco Credit Suisse (Brasil) S.A."
	},
	{
		"id": 1280,
		"descricao": "069 - Banco Crefisa S.A."
	},
	{
		"id": 1097,
		"descricao": "3 - Banco da Amazônia S.A."
	},
	{
		"id": 1283,
		"descricao": "083 - Banco da China Brasil S.A."
	},
	{
		"id": 1256,
		"descricao": "707 - Banco Daycoval S.A."
	},
	{
		"id": 1207,
		"descricao": "250 - Banco de Crédito e Varejo S.A. – BCV"
	},
	{
		"id": 1216,
		"descricao": "300 - Banco de La Nacion Argentina"
	},
	{
		"id": 1234,
		"descricao": "495 - Banco de La Provincia de Buenos Aires"
	},
	{
		"id": 1233,
		"descricao": "494 - Banco de La Republica Oriental del Uruguay"
	},
	{
		"id": 6,
		"descricao": "001 - Banco do Brasil S.A."
	},
	{
		"id": 7,
		"descricao": "047 - Banco do Estado de Sergipe S.A."
	},
	{
		"id": 1107,
		"descricao": "21 - Banco do Estado do Espírito Santo – Baneste S.A."
	},
	{
		"id": 9,
		"descricao": "037 - Banco do Estado do Pará S.A. "
	},
	{
		"id": 10,
		"descricao": "041 - Banco do Estado do Rio Grande do Sul S.A."
	},
	{
		"id": 77,
		"descricao": "004 - Banco do Nordeste do Brasil"
	},
	{
		"id": 1211,
		"descricao": "265 - Banco Fator S.A."
	},
	{
		"id": 1200,
		"descricao": "224 - Banco Fibra S.A."
	},
	{
		"id": 1245,
		"descricao": "626 - Banco Ficsa S.A."
	},
	{
		"id": 1287,
		"descricao": "094 - Banco Finaxis S.A."
	},
	{
		"id": 1242,
		"descricao": "612 - Banco Guanabara S.A."
	},
	{
		"id": 334,
		"descricao": "063 - Banco Ibi S.A. Banco Múltiplo"
	},
	{
		"id": 1101,
		"descricao": "12 - Banco Inbursa S.A."
	},
	{
		"id": 1239,
		"descricao": "604 - Banco Industrial do Brasil S.A."
	},
	{
		"id": 1253,
		"descricao": "653 - Banco Indusval S.A."
	},
	{
		"id": 1281,
		"descricao": "077 - Banco Inter S.A."
	},
	{
		"id": 1246,
		"descricao": "630 - Banco Intercap S.A."
	},
	{
		"id": 1258,
		"descricao": "719 - Banco Internacional do Funchal (Brasil) S.A. – Banif"
	},
	{
		"id": 1206,
		"descricao": "249 - Banco Investcred Unibanco S.A."
	},
	{
		"id": 1185,
		"descricao": "184 - Banco Itaú BBA S.A."
	},
	{
		"id": 1110,
		"descricao": "29 - Banco Itaú Consignado S.A."
	},
	{
		"id": 1252,
		"descricao": "652 - Banco Itaú Unibanco Holding S.A."
	},
	{
		"id": 1229,
		"descricao": "479 - Banco ItauBank S.A"
	},
	{
		"id": 1221,
		"descricao": "376 - Banco J. P. Morgan S.A."
	},
	{
		"id": 1197,
		"descricao": "217 - Banco John Deere S.A."
	},
	{
		"id": 1121,
		"descricao": "76 - Banco KDB S.A."
	},
	{
		"id": 1271,
		"descricao": "757 - Banco Keb Hana do Brasil S.A."
	},
	{
		"id": 1238,
		"descricao": "600 - Banco Luso Brasileiro S.A."
	},
	{
		"id": 1203,
		"descricao": "243 - Banco Máxima S.A."
	},
	{
		"id": 1222,
		"descricao": "389 - Banco Mercantil do Brasil S.A."
	},
	{
		"id": 1220,
		"descricao": "370 - Banco Mizuho do Brasil S.A."
	},
	{
		"id": 1264,
		"descricao": "746 - Banco Modal S.A."
	},
	{
		"id": 1118,
		"descricao": "66 - Banco Morgan Stanley S.A."
	},
	{
		"id": 1225,
		"descricao": "456 - Banco MUFG Brasil S.A."
	},
	{
		"id": 1098,
		"descricao": "7 - Banco Nacional de Desenvolvimento Econômico e Social – BNDES"
	},
	{
		"id": 1259,
		"descricao": "735 - Banco Neon S.A."
	},
	{
		"id": 1179,
		"descricao": "169 - Banco Olé Bonsucesso Consignado S.A."
	},
	{
		"id": 1123,
		"descricao": "79 - Banco Original do Agronegócio S.A."
	},
	{
		"id": 1195,
		"descricao": "212 - Banco Original S.A."
	},
	{
		"id": 1257,
		"descricao": "712 - Banco Ourinvest S.A."
	},
	{
		"id": 1244,
		"descricao": "623 - Banco Pan S.A."
	},
	{
		"id": 1241,
		"descricao": "611 - Banco Paulista S.A."
	},
	{
		"id": 1251,
		"descricao": "643 - Banco Pine S.A."
	},
	{
		"id": 1265,
		"descricao": "747 - Banco Rabobank International Brasil S.A."
	},
	{
		"id": 1130,
		"descricao": "88 - Banco Randon S.A."
	},
	{
		"id": 1247,
		"descricao": "633 - Banco Rendimento S.A."
	},
	{
		"id": 1262,
		"descricao": "741 - Banco Ribeirão Preto S.A."
	},
	{
		"id": 1151,
		"descricao": "120 - Banco Rodobens S.A."
	},
	{
		"id": 1263,
		"descricao": "743 - Banco Semear S.A."
	},
	{
		"id": 1269,
		"descricao": "754 - Banco Sistema S.A."
	},
	{
		"id": 1219,
		"descricao": "366 - Banco Société Générale Brasil S.A."
	},
	{
		"id": 1249,
		"descricao": "637 - Banco Sofisa S.A."
	},
	{
		"id": 1226,
		"descricao": "464 - Banco Sumitomo Mitsui Brasileiro S.A."
	},
	{
		"id": 1126,
		"descricao": "82 - Banco Topázio S.A."
	},
	{
		"id": 1248,
		"descricao": "634 - Banco Triângulo S.A."
	},
	{
		"id": 1275,
		"descricao": "018 - Banco Tricury S.A."
	},
	{
		"id": 1255,
		"descricao": "655 - Banco Votorantim S.A."
	},
	{
		"id": 1240,
		"descricao": "610 - Banco VR S.A."
	},
	{
		"id": 1150,
		"descricao": "119 - Banco Western Union do Brasil S.A."
	},
	{
		"id": 1154,
		"descricao": "124 - Banco Woori Bank do Brasil S.A."
	},
	{
		"id": 1276,
		"descricao": "021 - Banestes S.A. Banco do Estado do Espírito Santo"
	},
	{
		"id": 1270,
		"descricao": "755 - Bank of America Merrill Lynch Banco Múltiplo S.A."
	},
	{
		"id": 1213,
		"descricao": "268 - Barigui Companhia Hipotecária"
	},
	{
		"id": 1172,
		"descricao": "144 - Bexs Banco de Câmbio S.A. "
	},
	{
		"id": 1208,
		"descricao": "253 - Bexs Corretora de Câmbio S.A."
	},
	{
		"id": 1164,
		"descricao": "134 - BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda. "
	},
	{
		"id": 1105,
		"descricao": "17 - BNY Mellon Banco S.A."
	},
	{
		"id": 1156,
		"descricao": "126 - BR Partners Banco de Investimento S.A. "
	},
	{
		"id": 2,
		"descricao": "237 - Bradesco S.A."
	},
	{
		"id": 15,
		"descricao": "070 - BRB - Banco de Brasília S.A."
	},
	{
		"id": 1133,
		"descricao": "92 - Brickell (BRK) S.A. Crédito, Financiamento e Investimento"
	},
	{
		"id": 1180,
		"descricao": "173 - BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A. "
	},
	{
		"id": 1170,
		"descricao": "142 - Broker Brasil Corretora de Câmbio Ltda. "
	},
	{
		"id": 1293,
		"descricao": "292 - BS2 Distribuidora de Títulos e Valores Mobiliários S.A"
	},
	{
		"id": 1124,
		"descricao": "80 - BT Corretora de Câmbio Ltda. "
	},
	{
		"id": 16,
		"descricao": "104 - Caixa Econômica Federal"
	},
	{
		"id": 1160,
		"descricao": "130 - Caruana S.A. Sociedade de Crédito, Financiamento e Investimento "
	},
	{
		"id": 1177,
		"descricao": "159 - Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor"
	},
	{
		"id": 1148,
		"descricao": "114 - Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP"
	},
	{
		"id": 1132,
		"descricao": "91 - Central de Cooperativas de Economia e Crédito Mútuo do Estado RS – Unicred "
	},
	{
		"id": 1218,
		"descricao": "320 - China Construction Bank (Brasil) Banco Múltiplo S.A."
	},
	{
		"id": 1228,
		"descricao": "477 - Citibank N.A."
	},
	{
		"id": 3,
		"descricao": "745 - Citibank S.A."
	},
	{
		"id": 1157,
		"descricao": "127 - Codepe Corretora de Valores e Câmbio S.A. "
	},
	{
		"id": 1178,
		"descricao": "163 - Commerzbank Brasil S.A. – Banco Múltiplo"
	},
	{
		"id": 1113,
		"descricao": "60 - Confidence Corretora de Câmbio S.A."
	},
	{
		"id": 1285,
		"descricao": "085 - Cooperativa Central de Crédito - Ailos"
	},
	{
		"id": 1288,
		"descricao": "097 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi"
	},
	{
		"id": 1138,
		"descricao": "97 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda. (CentralCredi)"
	},
	{
		"id": 1129,
		"descricao": "85 - Cooperativa Central de Crédito Urbano – Cecred"
	},
	{
		"id": 1104,
		"descricao": "16 - Cooperativa de Crédito Mútuo dos Despachantes de Trânsito de Sta.Catarina e RS"
	},
	{
		"id": 1131,
		"descricao": "89 - Cooperativa de Crédito Rural da Região da Mogiana"
	},
	{
		"id": 1291,
		"descricao": "279 - Cooperativa de Crédito Rural de Primavera do Leste"
	},
	{
		"id": 45,
		"descricao": "756 - Cooperativo do Brasil S.A. - BANCOOB"
	},
	{
		"id": 46,
		"descricao": "748 - Cooperativo Sicredi S.A."
	},
	{
		"id": 1294,
		"descricao": "403 - CORA SCD S.A"
	},
	{
		"id": 1289,
		"descricao": "098 - Credialiança Cooperativa de Crédito Rural"
	},
	{
		"id": 1273,
		"descricao": "010 - Credicoamo Crédito Rural Cooperativa"
	},
	{
		"id": 1100,
		"descricao": "11 - Credit Suisse Hedging-Griffo Corretora de Valores S.A."
	},
	{
		"id": 1163,
		"descricao": "133 - Cresol – Confederação Nacional Cooperativas Centrais de Crédito e Econ Familiar"
	},
	{
		"id": 1183,
		"descricao": "182 - Dacasa Financeira S/A – Sociedade de Crédito, Financiamento e Investimento "
	},
	{
		"id": 1230,
		"descricao": "487 - Deutsche Bank S.A. – Banco Alemão"
	},
	{
		"id": 1169,
		"descricao": "140 - Easynvest – Título Corretora de Valores SA "
	},
	{
		"id": 1175,
		"descricao": "149 - Facta Financeira S.A. – Crédito Financiamento e Investimento"
	},
	{
		"id": 1191,
		"descricao": "196 - Fair Corretora de Câmbio S.A. "
	},
	{
		"id": 1167,
		"descricao": "138 - Get Money Corretora de Câmbio S.A."
	},
	{
		"id": 1116,
		"descricao": "64 - Goldman Sachs do Brasil Banco Múltiplo S.A."
	},
	{
		"id": 1165,
		"descricao": "135 - Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A. "
	},
	{
		"id": 1182,
		"descricao": "177 - Guide Investimentos S.A. Corretora de Valores"
	},
	{
		"id": 1174,
		"descricao": "146 - Guitta Corretora de Câmbio Ltda. "
	},
	{
		"id": 1122,
		"descricao": "78 - Haitong Banco de Investimento do Brasil S.A. "
	},
	{
		"id": 1114,
		"descricao": "62 - Hipercard Banco Múltiplo S.A."
	},
	{
		"id": 1187,
		"descricao": "189 - HS Financeira S/A Crédito, Financiamento e Investimentos"
	},
	{
		"id": 18,
		"descricao": "399 - HSBC Bank Brasil S.A"
	},
	{
		"id": 1214,
		"descricao": "269 - HSBC Brasil S.A. Banco de Investimento"
	},
	{
		"id": 1215,
		"descricao": "271 - IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda. "
	},
	{
		"id": 1176,
		"descricao": "157 - ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda. "
	},
	{
		"id": 1162,
		"descricao": "132 - ICBC do Brasil Banco Múltiplo S.A."
	},
	{
		"id": 1232,
		"descricao": "492 - ING Bank N.V."
	},
	{
		"id": 1168,
		"descricao": "139 - Intesa Sanpaolo Brasil S.A. – Banco Múltiplo"
	},
	{
		"id": 17,
		"descricao": "341 - Itaú Unibanco S.A."
	},
	{
		"id": 76,
		"descricao": "074 - J. Safra S.A."
	},
	{
		"id": 1231,
		"descricao": "488 - JPMorgan Chase Bank, National Association"
	},
	{
		"id": 1143,
		"descricao": "105 - Lecca Crédito, Financiamento e Investimento S/A"
	},
	{
		"id": 1173,
		"descricao": "145 - Levycam – Corretora de Câmbio e Valores Ltda. "
	},
	{
		"id": 1147,
		"descricao": "113 - Magliano S.A. Corretora de Cambio e Valores Mobiliarios "
	},
	{
		"id": 1158,
		"descricao": "128 - MS Bank S.A. Banco de Câmbio"
	},
	{
		"id": 1166,
		"descricao": "137 - Multimoney Corretora de Câmbio Ltda"
	},
	{
		"id": 1102,
		"descricao": "14 - Natixis Brasil S.A. Banco Múltiplo"
	},
	{
		"id": 1189,
		"descricao": "191 - Nova Futura Corretora de Títulos e Valores Mobiliários Ltda. "
	},
	{
		"id": 1268,
		"descricao": "753 - Novo Banco Continental S.A. – Banco Múltiplo"
	},
	{
		"id": 1210,
		"descricao": "260 - Nu Pagamentos S.A"
	},
	{
		"id": 1146,
		"descricao": "111 - Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A. "
	},
	{
		"id": 1243,
		"descricao": "613 - Omni Banco S.A. "
	},
	{
		"id": 333,
		"descricao": "290 - Pagseguro Internet S.A"
	},
	{
		"id": 1209,
		"descricao": "254 - Paraná Banco S.A."
	},
	{
		"id": 1190,
		"descricao": "194 - Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda. "
	},
	{
		"id": 1181,
		"descricao": "174 - Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento"
	},
	{
		"id": 1140,
		"descricao": "100 - Planner Corretora de Valores S.A."
	},
	{
		"id": 1134,
		"descricao": "93 - Pólocred Sociedade de Crédito ao Microempreendedor e Empresa de Pequeno Porte"
	},
	{
		"id": 1145,
		"descricao": "108 - PortoCred S.A. Crédito, Financiamento e Investimento"
	},
	{
		"id": 1141,
		"descricao": "101 - Renascença Distribuidora de Títulos e Valores Mobiliários Ltda. "
	},
	{
		"id": 12,
		"descricao": "422 - Safra S.A."
	},
	{
		"id": 14,
		"descricao": "033 - Santander (Brasil) S.A."
	},
	{
		"id": 1266,
		"descricao": "751 - Scotiabank Brasil S.A. Banco Múltiplo"
	},
	{
		"id": 1237,
		"descricao": "545 - Senso Corretora de Câmbio e Valores Mobiliários S.A. "
	},
	{
		"id": 1188,
		"descricao": "190 - Servicoop – Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio"
	},
	{
		"id": 1184,
		"descricao": "183 - Socred S.A. – Sociedade de Crédito ao Microempreendedor"
	},
	{
		"id": 1149,
		"descricao": "118 - Standard Chartered Bank (Brasil) S.A. Banco de Investimento"
	},
	{
		"id": 1192,
		"descricao": "197 - Stone Pagamentos S.A. "
	},
	{
		"id": 1171,
		"descricao": "143 - Treviso Corretora de Câmbio S.A. "
	},
	{
		"id": 1161,
		"descricao": "131 - Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda. "
	},
	{
		"id": 1159,
		"descricao": "129 - UBS Brasil Banco de Investimento S.A."
	},
	{
		"id": 1103,
		"descricao": "15 - UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
	},
	{
		"id": 78,
		"descricao": "136 - Unicred "
	},
	{
		"id": 1139,
		"descricao": "99 - Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda."
	},
	{
		"id": 1284,
		"descricao": "084 - Uniprime Norte do Paraná - Cooperativa de Crédito Ltda."
	},
	{
		"id": 1128,
		"descricao": "84 - Uniprime Norte do Paraná – Cooperativa de Crédito Ltda. "
	},
	{
		"id": 1142,
		"descricao": "102 - XP Investimentos Corretora de Câmbio, Títulos e Valores Mobiliários S/A"
	}
]