export const camposEndereco = [
  {
    label: 'CEP',
    name: 'cep-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'logradouro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'bairro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'complemento-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'codigo-ibge-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'num-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'cidade-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'uf-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const camposProposta = [
  {
    label: 'Valor solicitado',
    name: 'valor-solicitado',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  /* {
    label: 'Categoria',
    name: 'categoria-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, */
  {
    label: 'Modalidade',
    name: 'modalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Forma de pagamento',
    name: 'forma-pagamento',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Parecer',
    name: 'parecer',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const camposIdentificacao = [
  {
    label: 'CPF',
    name: 'cpf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Nome',
    name: 'nome',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'RG',
    name: 'rg',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Estado civil',
    name: 'estado-civil',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nacionalidade',
    name: 'nacionalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'E-mail',
    name: 'email',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNH',
    name: 'cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nº Segurança CNH',
    name: 'numero_serguranca_cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Data de nascimento',
    name: 'dt-nascimento',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Sexo',
    name: 'sexo',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Renda mensal',
    name: 'renda-mensal',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Nome da mãe',
    name: 'nome-mae',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fone residencial',
    name: 'fone-residencial',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Fone celular',
    name: 'fone-celular',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Nome do Cônjuge',
    name: 'conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CPF do Cônjuge',
    name: 'cpf-conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
];

export const camposDadosComerciais = [
  {
    label: 'Natureza ocupação',
    name: 'natureza-ocupacao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Profissão',
    name: 'profissao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Admissão',
    name: 'data-admissao',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  /* {
    label: 'Renda mensal',
    name: 'renda-mensal',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  }, */
  {
    label: 'Comprovante',
    name: 'comprovante-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Empresa',
    name: 'empresa-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNPJ',
    name: 'cnpj-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Telefone',
    name: 'tel-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Ramal',
    name: 'ramal-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CEP',
    name: 'cep-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'logradouro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'num-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'complemento-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'codigo-ibge-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'bairro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'cidade-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'uf-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
];

export const mockCamposReferenciasBancarias = [
  {
    label: 'Banco',
    name: 'banco-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'agencia-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'conta-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'desde-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Banco',
    name: 'banco-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'agencia-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'conta-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'desde-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
];

export const camposReferenciasPessoais = [
  {
    label: 'Nome',
    name: 'nome-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'tel-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Grau Parentesco',
    name: 'grau-parentesco-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nome',
    name: 'nome-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'tel-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Grau Parentesco',
    name: 'grau-parentesco-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
];

export const camposIntegracao = [
  {
    label: 'Valor solicitado',
    name: 'valor-solicitado',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  /* {
    label: 'Categoria',
    name: 'categoria-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  }, */
  {
    label: 'Modalidade',
    name: 'modalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Forma de pagamento',
    name: 'forma-pagamento',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Parecer',
    name: 'parecer',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CPF',
    name: 'cpf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Nome',
    name: 'nome',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'RG',
    name: 'rg',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Estado civil',
    name: 'estado-civil',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nacionalidade',
    name: 'nacionalidade',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'E-mail',
    name: 'email',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNH',
    name: 'cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Nº Segurança CNH',
    name: 'numero_serguranca_cnh',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Data de nascimento',
    name: 'dt-nascimento',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Sexo',
    name: 'sexo',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nome da mãe',
    name: 'nome-mae',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Fone residencial',
    name: 'fone-residencial',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Fone celular',
    name: 'fone-celular',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Nome do Cônjuge',
    name: 'conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CPF do Cônjuge',
    name: 'cpf-conjuge',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'CEP',
    name: 'cep-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'logradouro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'bairro-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'complemento-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'codigo-ibge-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'num-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'cidade-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'uf-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Natureza ocupação',
    name: 'natureza-ocupacao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Profissão',
    name: 'profissao',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Admissão',
    name: 'data-admissao',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Renda mensal',
    name: 'renda-mensal',
    habilitar: false,
    obrigatorio: false,
    type: 'currency',
  },
  {
    label: 'Comprovante',
    name: 'comprovante-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Empresa',
    name: 'empresa-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CNPJ',
    name: 'cnpj-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Telefone',
    name: 'tel-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Ramal',
    name: 'ramal-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'CEP',
    name: 'cep-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Logradouro',
    name: 'logradouro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Número',
    name: 'num-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Complemento',
    name: 'complemento-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Codigo IBGE',
    name: 'codigo-ibge-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Bairro',
    name: 'bairro-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Cidade',
    name: 'cidade-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'UF',
    name: 'uf-dadoscomerciais-pf',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'B Banco',
    name: 'banco-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'agencia-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'conta-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'desde-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Banco',
    name: 'banco-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Agência',
    name: 'agencia-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Conta',
    name: 'conta-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Desde',
    name: 'desde-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'date',
  },
  {
    label: 'Nome',
    name: 'nome-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'tel-referencia-1',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Grau Parentesco',
    name: 'grau-parentesco-1',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
  {
    label: 'Nome',
    name: 'nome-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'text',
  },
  {
    label: 'Telefone',
    name: 'tel-referencia-2',
    habilitar: false,
    obrigatorio: false,
    type: 'mask',
  },
  {
    label: 'Grau Parentesco',
    name: 'grau-parentesco-2',
    habilitar: false,
    obrigatorio: false,
    type: 'select',
  },
];

export default camposIntegracao;
