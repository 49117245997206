import { useContext, useEffect, useState } from 'react';
import { Upload as UploadAntd, message, Button, Modal, Select as SelectAntd } from 'antd'; import { UploadOutlined } from '@ant-design/icons';
import { Select, Option } from '@components/Form';
import { useFormikContext } from 'formik';
import { CameraOutlined, InboxOutlined } from '@ant-design/icons';

import ModalCameraUploadDinamico from '@components/Modal/ModalCameraUploadDinamico';
import { initialValues } from './initialValues';
import api from '@services/api';
import parse from 'html-react-parser'

// @ts-ignore 
import FileViewer from 'react-file-viewer';
import ModalLoading from '@components/Modal/ModalLoading';
import mt from 'mime-types';

const { Dragger } = UploadAntd;
type Props = { arrayOptions: any; msgHelp: any };

const UploadDinamico = ({ arrayOptions, msgHelp = '' }: Props) => {
  const [loading, setLoading] = useState({ status: false, message: '' })
  const getUploadedFiles = () => setUploadedFiles(Math.random())

  useEffect(() => {
    const timerUploadedFiles = setInterval(async () => {
      getUploadedFiles()
    }, 333);

    return () => clearInterval(timerUploadedFiles);
  }, []);

  const { values, setFieldValue } = useFormikContext<typeof initialValues>();
  const [files, setFiles] = useState<any>([]);
  const [modalCameraVisible, setModalCameraVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const uploadFileDragger = async (info: any) => {
    try {
      setLoading({ status: true, message: 'Enviando arquivo...' })
      const formData: any = new FormData()
      const sendFile = async () => {
        const { data: timestamp } = await api.post(`Upload?instituicao=${sessionStorage.getItem('b2e:instituicao')}&path_arquivos=${sessionStorage.getItem('path_arquivos') || ''}`,
          formData, { headers: { 'Content-Type': 'multipart/form-data' } })

        message.success(`Arquivo anexado com sucesso.`);

        if (values.path_arquivos) return
        setFieldValue('path_arquivos', timestamp); // setando para usar ao enviar proposta WIZARD
        sessionStorage.setItem('path_arquivos', timestamp); // setando para usar ao enviar proposta ONEPAGE
      }

      let uploadedFiles: any = sessionStorage.getItem('uploadedFiles')?.split(',') || [];

      if (!sessionStorage.getItem('docTipo')) {
        formData.append('f', info.file);

        await sendFile()
        setFiles((files: any) => [...files, info.file]);

        uploadedFiles.push(info.file.name.split('.').shift()); // remove extensão do nome do arquivo
      } else { // se algum nome padrão for definido
        let newFile = new File([info.file], `${sessionStorage.getItem('docTipo')}.${info.file.name.split('.').pop()}`,);
        formData.append('f', info.file, `${sessionStorage.getItem('docTipo')}.${info.file.name.split('.').pop()}`);

        await sendFile()
        setFiles((files: any) => [...files, newFile]);

        uploadedFiles.push(sessionStorage.getItem('docTipo') || '');
      }

      sessionStorage.setItem('uploadedFiles', uploadedFiles.toString()); // setando para usar ao enviar proposta

    } catch (error) {
      //console.log("error: ", error);
      message.error(`Falha ao anexar arquivo`, 3);
    } finally {
      setLoading({ status: false, message: '' })
    }
  }

  const uploadFileCamera = async (newFile: File) => {
    try {
      setLoading({ status: true, message: 'Enviando arquivo...' })
      const formData: any = new FormData()
      formData.append('f', newFile);
      const { data: timestamp } = await api.post(`Upload?instituicao=${sessionStorage.getItem('b2e:instituicao')}&path_arquivos=${sessionStorage.getItem('path_arquivos') || ''}`,
        formData, { headers: { 'Content-Type': 'multipart/form-data' } })

      message.success(`Arquivo anexado com sucesso.`);
      setFiles((files: any) => [...files, newFile]);

      let uploadedFiles: any = sessionStorage.getItem('uploadedFiles')?.split(',') || [];
      uploadedFiles.push(newFile.name.split('.').shift()); // remove extensão do nome do arquivo
      uploadedFiles.push(sessionStorage.getItem('docTipo') || '');
      sessionStorage.setItem('uploadedFiles', uploadedFiles.toString()); // setando para usar ao enviar proposta

      if (values.path_arquivos) return
      setFieldValue('path_arquivos', timestamp); // setando para usar ao enviar proposta WIZARD
      sessionStorage.setItem('path_arquivos', timestamp); // setando para usar ao enviar proposta ONEPAGE

    } catch (error) {
      //console.log("error: ", error);
      message.error(`Falha ao anexar ${newFile.name}`, 3);
    } finally {
      setLoading({ status: false, message: '' })
    }
  }

  const propsUpload = {
    name: 'file',
    multiple: true,
    fileList: files,
    customRequest: (info: any) => {
      Modal.confirm({
        title: 'Selecione o tipo do documento enviado',
        wrapClassName: 'remove-antd-footer',
        content:
          <SelectAntd className="w-100" optionFilterProp="children"
            showSearch placeholder="Selecione uma opção"
            onChange={(docTipo) => {
              //console.log("docTipo: ", docTipo);
              sessionStorage.setItem('docTipo', docTipo)
              // após selecionar o tipo do documento, exibe o footer (Cancel, Ok)
              // only in last element (last modal) - na tela o último é o 1º
              let modals = document.querySelectorAll('.remove-antd-footer')
              modals?.[modals.length - 1]?.classList.remove('remove-antd-footer')
            }}>
            {(arrayOptions?.length == 0 || arrayOptions == undefined) ?
              <Option value={'Documento'}>Documento</Option>
              :
              arrayOptions?.split(',').map((option: any) =>
                <Option key={option} value={option} children={option} />)}
          </SelectAntd>,
        async onOk() { await uploadFileDragger(info) },
        afterClose() { sessionStorage.removeItem('docTipo') },
      });
    },
  };

  return (
    <>
      {msgHelp == '' ? null :
        <div className='col-lg-4'>
          {parse(`${msgHelp}`)}
        </div>}
      <div className={`${msgHelp == '' ? 'col-lg-12' : 'col-lg-8'}`}>
        <Button type="primary" icon={<CameraOutlined />}
          block onClick={() => setModalCameraVisible(true)}>
          Clique aqui para tirar foto do documento
        </Button>
        <ModalCameraUploadDinamico
          arrayOptions={arrayOptions}
          open={modalCameraVisible}
          uploadFile={uploadFileCamera}
          onCancel={() => setModalCameraVisible(false)}
          onOk={() => setModalCameraVisible(false)}
          destroyOnClose={true}
        />
        <div className="uploadArea mt-2">
          <Dragger {...propsUpload} listType="picture"
            showUploadList={{
              showPreviewIcon: true,
              showRemoveIcon: true,
              showDownloadIcon: false,
            }}
            onRemove={(file: any) => {
              setFiles(files.filter((f: any) => f.uid !== file.uid));

              // removendo do uploadedFiles também
              let uploadedFiles: any = sessionStorage.getItem('uploadedFiles')?.split(',') || [];

              // remove only first occurrence
              let position = uploadedFiles.indexOf(file.name.split('.').shift());
              uploadedFiles.splice(position, 1);

              sessionStorage.setItem('uploadedFiles', uploadedFiles.toString()); // setando para usar ao enviar proposta
            }}
            onPreview={(file: any) => {
              let fileExtension = file.name.split('.').pop();
              //alert(fileExtension)
              window.open(URL.createObjectURL(new File([file], fileExtension, { type: mt.lookup(fileExtension) || '' })));
            }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Ou clique aqui ou arraste para anexar os documentos
            </p>
          </Dragger>
          <b key={uploadedFiles}>
            Anexos Enviados: {arrayOptions?.split(',').filter((o: any) => (sessionStorage.getItem('uploadedFiles')?.split(',') || []).includes(o)).join(', ') || ''}
          </b>
        </div>
      </div>

      <ModalLoading open={loading.status} message={loading.message} />
    </>
  );
};

export default UploadDinamico;