import toolbarReactQuill from '@utils/toolbarReactQuill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FC, useState, useEffect, useCallback, useContext } from 'react';
import { Button, Select as AntdSelect, Input as AntdInput, Modal, Switch, message, Tooltip } from 'antd';
import { formaPagamentoOptions as f } from '@services/mock';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import InputMask, { Props as InputProps } from 'react-input-mask';

import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { Input, Select, Option } from '@components/Form';
import { EtapaContext } from '@contexts/EtapaContext';
import { GlobalContext } from '@contexts/GlobalContext';

// @ts-ignore
import Tags from "@yaireo/tagify/dist/react.tagify";
import ReactIntlCurrencyInput from 'react-intl-currency-input';
import { currencyConfig } from '@pages/Cadastrar/currencyConfig';
import CurrencyInput from 'react-currency-input-field';

const Parcelamento: FC = () => {
  const { etapaPessoa, etapa, etapaIdx, tipoPessoa } = useContext(EtapaContext);
  const { configuracoes: c } = useContext(GlobalContext);

  const { setFieldValue, values } = useFormikContext<ConfigTypes>();

  const [fields, setFields] = useState<Campo[]>([]);
  const [operators, setOperators] = useState<boolean>(true);

  const valuesCamposAdicionais = { name: '', label: '', type: 'text', value: '0', habilitar: false, obrigatorio: false, };

  const handleAddVar = () => {
    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [
      ...etapaPessoa[etapaIdx].campos, valuesCamposAdicionais,]);
  };

  const handleDelVar = async (idx: any) => {
    const filteredArray = etapaPessoa[etapaIdx].campos.filter((item, index) => index !== idx)
    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, filteredArray);
    loadFields();
  };

  const loadFields = useCallback(() => {
    const campos = etapaPessoa[etapaIdx]?.campos;

    setFields(campos);
  }, [values.etapas, etapa]);

  useEffect(() => { if (etapa) loadFields(); }, [loadFields, etapa]);

  useEffect(() => { // inicialmente, sempre trazer valores já definidos
    if (etapaPessoa[etapaIdx]?.campos?.length != 0) return

    if (etapaPessoa[etapaIdx]?.tipoCalculo == 0) // Memória Cálculo
      setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [
        { name: `vlrSolicitado`, label: `2,00`, value: '1,00', type: 'currency', habilitar: true, obrigatorio: true },
        { name: `qtdParcelas`, label: `Parcelamento`, value: '12', type: 'text', habilitar: false, obrigatorio: false },
        { name: `entrada`, label: `Valor Fixo`, opcoes: [], value: "1", type: 'opcoes', habilitar: false, obrigatorio: false },
        { name: `diaVencimento`, label: `Dia Fixo`, opcoes: [], value: "", type: 'opcoes', habilitar: false, obrigatorio: false },
        { name: `iof`, label: `IOF`, opcoes: [], value: "", type: 'text', habilitar: false, obrigatorio: false },
        { name: `juros`, label: `Juros`, opcoes: [], value: "", type: 'text', habilitar: false, obrigatorio: false },
        { name: `forma-pagamento`, label: `Forma de Pagamento`, opcoes: [], value: "", type: 'text', habilitar: false, obrigatorio: false },
      ])

    else // Integração Money+
      setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [
        { name: `vlrSolicitado`, label: `2,00`, value: '1,00', type: 'currency', habilitar: true, obrigatorio: true },
        { name: `percJurosNegociado`, label: `Percentual Juros Negociado`, value: '', type: 'number', habilitar: false, obrigatorio: false },
        { name: `vlrTac`, label: `Valor TAC`, value: '', type: 'number', habilitar: false, obrigatorio: false },
        { name: `diaVencimento`, label: `Dia Fixo`, opcoes: [], value: "", type: 'opcoes', habilitar: false, obrigatorio: false },
      ]);

  }, [etapaPessoa[etapaIdx]?.tipoCalculo]);

  return (
    <>
      <div className='select-wrapper w-25 mb-3 ml-2'>
        <label>Tipo Cálculo</label>
        <AntdSelect value={etapaPessoa[etapaIdx]?.tipoCalculo} placeholder="Selecione o tipo de cálculo"
          onChange={(value: any) => {
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.tipoCalculo`, value);
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, []);
          }}>
          <Option value={0}>Memória de Cálculo</Option>
          {['F18850D0-9EB4-4B0B-8FF0-5CE51FC5F308', '9C68F913-CB5C-48C7-8E8C-272BA0B81435']
            .includes(sessionStorage.getItem('b2e:instituicao') || '') &&
            <Option value={1}>Integração Money+</Option>}
        </AntdSelect>
      </div>

      {'tipoCalculo' in etapaPessoa[etapaIdx] && <>
        <div className="form-row">
          <div className="input-wrapper col-md-3">
            <label>Solicitação Mínima</label>
            <ReactIntlCurrencyInput defaultValue={1.00}
              value={parseFloat(fields[0]?.value || '1,00')}
              max={999999999.99} currency='BRL'
              config={currencyConfig} autoSelect={true}
              onBlur={(_, currentValue: number) => {
                if (currentValue == 0)
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[0]}.value`, '1,00');
              }}
              onChange={(_, currentValue: number) => {
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[0]}.value`, `${currentValue}`);
              }} />
          </div>

          <div className="input-wrapper col-md-3">
            <label>Solicitação Máxima</label>
            <ReactIntlCurrencyInput defaultValue={2.00}
              value={parseFloat(fields[0]?.label || '2,00')}
              max={999999999.99} currency='BRL'
              config={currencyConfig} autoSelect={true}
              onBlur={(_, currentValue: number) => {
                if (currentValue == 0)
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[0]}.label`, '2,00');
              }}
              onChange={(_, currentValue: number) => {
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[0]}.label`, `${currentValue}`);
              }} />
          </div>

          {etapaPessoa[etapaIdx]?.tipoCalculo == 0 && <>
            <div className="input-wrapper col-lg-2">
              <label>Prazo Máximo</label>
              <input value={fields[1]?.value}
                onBlur={({ target: { value: value } }) => {
                  if (value.trim() == '')
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.value`, '12');
                }}
                onChange={({ target: { value: value } }) => {
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.value`, value.replace(/[^0-9,]/g, ''));
                }} />
            </div>
            <div className="input-wrapper col-lg-8">
              <label>Parcelas Permitidas</label>
              <Select dropdownMatchSelectWidth={false}
                label="" mode="tags" placeholder="Insira as parcelas que serão permitidas"
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.opcoes`}
                onChange={(value: any) => { // the newNumber needs be between 1 and fields[1]?.value
                  const element: string = (value[value.length - 1] || '0').replace(/[^0-9]/g, '');
                  const newNumber: number = parseInt(element)
                  const prazoMax: number = parseInt(fields[1]?.value || '12');

                  if (newNumber > prazoMax)
                    return message.error(`O valor máximo permitido é ${prazoMax}`);
                  else if (value.length == 1 && newNumber == 0) // caso o usuário digite 0 como 1º elemento do array
                    return message.error(`O valor mínimo permitido é 1`);
                  else if (newNumber < 1 && value.length > 1)
                    return message.error(`O valor mínimo permitido é 1`);

                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.opcoes`,
                    [...fields[1]?.opcoes || [], element]
                      .filter((v: any, i: any, a: any) => a.indexOf(v) === i) // remove duplicates
                      .sort((a: any, b: any) => a - b)) // sort ascending
                }}
                onDeselect={(removedValue) => {
                  // Logic to remove the deselected value from the state
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.opcoes`,
                    fields[1]?.opcoes?.filter((option) => option !== removedValue)
                  );
                }}
              />
            </div>
            <div className="col-lg-auto" />
          </>}

          {etapaPessoa[etapaIdx]?.tipoCalculo == 1 &&
            <>
              <div className="col-lg-auto" />

              <div className="input-wrapper col-lg-4">
                <label>Percentual Juros Negociado</label>
                <input value={fields[1]?.value}
                  onChange={({ target: { value: value } }) => {
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[1]}.value`, value.replace(/[^0-9,]/g, ''));
                  }} />
              </div>

              <div className="input-wrapper col-lg-4">
                <label>Valor TAC</label>
                <input value={fields[2]?.value}
                  onChange={({ target: { value: value } }) => {
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[2]}.value`, value.replace(/[^0-9,]/g, ''));
                  }} />
              </div>
              <div className="col-lg-auto" />
            </>}

          <>
            <div className="select-wrapper col-lg-4">
              <label>Dia de Vencimento</label>
              <AntdSelect value={fields[3]?.label} placeholder="Selecione uma opção"
                onChange={(value: any) => {
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[3]}.label`, value);
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[3]}.opcoes`, []);
                }}>
                <Option value='Carência'>Carência</Option>
                <Option value='Dia Fixo'>Dia Fixo</Option>
              </AntdSelect>
            </div>
            <div className="input-wrapper col-lg-4 mt-auto">
              <Select label="" mode="tags" placeholder="Insira os dias"
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[3]}.opcoes`}
                onChange={(value: any) => {
                  //console.log(fields[3]?.label == 'Dia Fixo');
                  if (/[^0-9]/.test(value.join(''))) return message.error("Dia inválido");
                  if (fields[3]?.label == 'Dia Fixo' && value.some((v: number) => v > 31 || v == 0)) return message.error("Dia inválido");
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[3]}.opcoes`, value?.sort((a: number, b: number) => a - b));
                }}
              />
            </div>
            <div className="col-lg-auto" />
          </>

          {etapaPessoa[etapaIdx]?.tipoCalculo == 0 && <>
            <div className='select-wrapper col-lg-4'>
              <label>Formas de Pagamento</label>
              <AntdSelect placeholder="Selecione a forma de pagamento"
                value={JSON.parse(etapaPessoa[etapaIdx]?.expressao || '[]')}
                mode="multiple" showSearch optionFilterProp="children"
                onChange={(value) => {
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.expressao`, JSON.stringify(value));
                }} // Integração IUGU: Cobrança Faturada: bank_slip, pix. Cobrança Direta: credit_card
              >
                {f.map((t) =>
                  <Option key={t.id} value={t.id}>
                    {t.descricao}
                  </Option>
                )}
              </AntdSelect>
            </div>

            <div className="select-wrapper col-lg-4">
              <label>Entrada</label>
              <AntdSelect value={fields[2]?.label} placeholder="Selecione uma opção"
                onChange={(value: any) => {
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[2]}.label`, value);
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[2]}.opcoes`, []);
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[2]}.value`, '');
                }}>
                <Option value='Sem entrada (zero)'>Sem entrada (zero)</Option>
                <Option value='Percentual'>Percentual</Option>
                <Option value='Valor Fixo'>Valor Fixo</Option>
                <Option value='1ª Parcela'>1ª Parcela</Option>
              </AntdSelect>
            </div>

            {fields[2]?.label == 'Percentual' ?
              <div className='input-wrapper col-md-3'>
                <label>Percentual</label>
                <div>
                  <CurrencyInput className='w-50' disableGroupSeparators={true}
                    decimalSeparator=',' decimalScale={2} groupSeparator='.'
                    allowNegativeValue={false} value={fields[2]?.value}
                    onValueChange={(value: any) => {
                      setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[2]}.value`, value);
                    }} /> %
                </div>
              </div>

              : fields[2]?.label == 'Valor Fixo' ?
                <div className="input-wrapper col-md-4">
                  <label>Valor Entrada</label>
                  <div>
                    <ReactIntlCurrencyInput defaultValue={1.00}
                      value={parseFloat(fields[2]?.value || '1,00')}
                      max={999999999.99} currency='BRL'
                      config={currencyConfig} autoSelect={true}
                      onBlur={(_, currentValue: number) => {
                        if (currentValue == 0)
                          setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[2]}.value`, '1,00');
                      }}
                      onChange={(_, currentValue: number) => {
                        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[2]}.value`, `${currentValue}`);
                      }} />
                  </div>
                </div>

                : <div className="col-lg-4" />}

            <div className="input-wrapper pr-0 col-md-2">
              <label>IOF</label>
              <div>
                <CurrencyInput className='w-75 ' disableGroupSeparators={true}
                  decimalSeparator=',' decimalScale={2} groupSeparator='.'
                  allowNegativeValue={false} value={fields[4]?.value?.replace('.', ',')}
                  onValueChange={(value: any) => {
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[4]}.value`, value?.replace(',', '.'));
                  }} /> %
              </div>
            </div>

            <div className="input-wrapper pl-0 col-md-3">
              <label>Juros</label>
              <div>
                <CurrencyInput className='w-50' disableGroupSeparators={true}
                  decimalSeparator=',' decimalScale={2} groupSeparator='.'
                  allowNegativeValue={false} value={fields[5]?.value?.replace('.', ',')}
                  onValueChange={(value: any) => {
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${Object.keys(fields)[5]}.value`, value?.replace(',', '.'));
                  }} /> % a.m.
              </div>
            </div>
          </>}

          {fields?.map((field, index) => {
            if ((index >= 7 && etapaPessoa[etapaIdx]?.tipoCalculo == 0) // Memória Cálculo
              || (index >= 5 && etapaPessoa[etapaIdx]?.tipoCalculo == 1)) // Integração Money+
              return !['diaVencimento', 'entrada'].includes(field.name) && // campos defualts para ignorar
                <div key={index} className="form-row col-lg-12">
                  <div className="input-wrapper col-lg-4 pl-1">
                    <label>Nome da Variável</label>
                    <AntdInput required value={field.name.replace('TX_', '')}
                      onChange={({ target: { value: value } }) => {
                        // only allow letters and numbers and underscores:
                        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.label`, value.replace(/[^a-zA-Z0-9_]/g, ''));
                        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.name`, value.replace(/[^a-zA-Z0-9_]/g, ''));
                      }}
                      onBlur={({ target: { value: value } }) => {
                        if (value.includes('TX_')) return
                        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.label`, 'TX_' + value.replace(/[^a-zA-Z0-9_]/g, ''));
                        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.name`, 'TX_' + value.replace(/[^a-zA-Z0-9_]/g, ''));
                      }} />
                  </div>

                  <div className="input-wrapper col-lg-3">
                    <label>Valor</label>
                    <ReactIntlCurrencyInput defaultValue={1.00}
                      value={parseFloat(field.value || '1,00')}
                      max={999999999.99} currency='BRL'
                      config={currencyConfig} autoSelect={true}
                      onBlur={(_, currentValue: number) => {
                        if (currentValue == 0)
                          setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.value`, '1,00');
                      }}
                      onChange={(_, currentValue: number) => {
                        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.value`, `${currentValue}`);
                      }} />
                  </div>

                  <Button type="dashed" className="float-right my-auto ml-4"
                    onClick={() => handleDelVar(index)}
                    icon={<MinusCircleOutlined />} />

                  {/*não exibir hr em última var.: index != fields.length - 1 && <hr /> */}
                </div>
          })}
        </div>

        <style>
          {`.btn-calc {
            width: 40px !important;
            height: 40px !important;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            box-sizing: content-box;
            background-color: ${c?.interface?.corPrimaria || '#881220'};
            border: 5px solid white;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            font-weight: 600;
          }
          .btn-calc.vars {
            width: 140px !important;
          }
          .btn-calc.ac {
            width: 90px !important;
          }
          label.disabled {
            cursor: not-allowed;
            background-color: #ccc;
          }
        `}
        </style>

        <div className="form-row">
          <div className="col-lg-12">
            <Tooltip title="Permite somar valores ao valor final do cálculo" placement="bottom">
              <Button type="dashed" block onClick={handleAddVar} icon={<PlusOutlined />}>
                Adicionar Variável
              </Button>
            </Tooltip>
          </div>
        </div>

        <br />
        <div className='col-lg-12'>
          <label className='col-lg-9 p-0 m-0 mb-1'>Observação Rodapé</label>
          <div style={{ minHeight: '200px' }}>
            <ReactQuill modules={toolbarReactQuill}
              value={etapaPessoa[etapaIdx]?.servico}
              onChange={(value) => {
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.servico`, value);
              }} />
          </div>
        </div>
      </>}
    </>
  );
};

export default Parcelamento;
