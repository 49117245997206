import styled from 'styled-components';

export const Container = styled.div<{ active: boolean }>`
  background: ${(props) => props.active && props.theme.colors.secondary};
  border: 1px solid #e7eaec;
  padding: 10px 15px;
  margin-bottom: 5px;
  cursor: move;
  color: ${(props) => props.active && '#eee'};

  .ant-btn {
    color: ${(props) => props.active && '#eee'};
  }

  display: flex;
  align-items: center;
  flex: 1;

  .title-menu {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .title {
    cursor: pointer;
    font-weight: 600;
    color: ${(props) => (props.active ? '#eee' : '#636363')};
  }
`;
