const initialValues = {
  instituicao: {
    codigo: '',
    subdominio: '',
    apiProposta: {
      username: '',
      password: '',
    },
    twoFa: {
      habilitar: false,
      sms: false,
      email: false,
    },
  },
  interface: {
    logo: '',
    corPrimaria: '',
    corSecundaria: '',
    corTextoMenu: '',
    corBackground: '',
    respostaCadastro: {
      mensagemResposta: {
        habilitar: true
      }
    }
  },
  etapas: {
    pf: [],
    pj: [],
    dlinkpj: [],
    dlinkpf: [],
  },
  followUp: {
    exibirMenu: 'pfpj',
    camposFiltro: [],
    colunasGrid: [],
    revisao: {
      habilitar: false,
      idStatus: [],
      nome: '',
    },
    redirect: {
      habilitar: false,
      nome: '',
      idStatus: [],
      urlRota: '',
    },
    analisarProposta: false,
  },
};

export default initialValues as any;
