import { GlobalContext } from '@contexts/GlobalContext';
import { FC, useEffect, useContext } from 'react';

const Wrapper = ({ children }: { children: React.ReactNode }) => {

  const { setSubdominio, configuracoes } = useContext(GlobalContext);

  useEffect(() => {
    if (configuracoes)
      document.body.style.backgroundColor = configuracoes.interface.corBackground
  }, [configuracoes])

  return (
    <div className="custom-bg">
      {children}
    </div>
  );
};

export default Wrapper;
