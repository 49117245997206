export const tiposEtapa = [
  {
    id: 0,
    nome: 'Pessoa Física',
  },
  {
    id: 4,
    nome: 'Pessoa Jurídica',
  },
  {
    id: 41,
    nome: 'Endereço Comercial',
  },
  {
    id: 42,
    nome: 'Endereço Entrega',
  },
  {
    id: 43,
    nome: 'Dados Comerciais',
  },
  {
    id: 44,
    nome: 'Sócios',
  },
  {
    id: 45,
    nome: 'Referências Bancárias',
  },
  {
    id: 46,
    nome: 'Referências Comerciais',
  },
  {
    id: 2,
    nome: 'Etapa adicional',
  },
  {
    id: 1,
    nome: 'Integração',
  },
  {
    id: 3,
    nome: 'Upload de Arquivos',
  },
  {
    id: 5,
    nome: 'Parcelamento',
  },
  {
    id: 6,
    nome: 'Condições e Termos de Uso',
  },
  {
    id: 7,
    nome: 'Código de Verificação',
  },
  {
    id: 8,
    nome: 'Produto',
  },
  {
    id: 9,
    nome: 'Avalista',
  },
  {
    id: 15,
    nome: 'Etapa Informativa',
  },
];
