/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import { GlobalContext } from '@contexts/GlobalContext';
import { useHistory, useParams } from 'react-router-dom';
import { Input } from '@components/Form';
import { Button, Modal, Input as AntdInput, message } from 'antd';
import api from '@services/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Login from '@components/Spinner/Login';
import { validateBr } from 'js-brasil';

type Props = {
  handleRedefinePass: () => void;
};

const CardRedefinePass = ({ handleRedefinePass }: Props) => {
  const { pathname: url, href } = window.location;
  const redefineSuccess = false;
  const history = useHistory();
  const { subdominio } = useParams<{ subdominio: string }>();

  const { configuracoes, setInstituicao } = useContext(GlobalContext);
  const [entrarColor, setEntrarColor] = useState(null);
  const [xhrAction, setXhrAction] = useState(false);
  const [criarNovaSenha, setCriarNovaSenha] = useState({ codigoEnviado: false, codigo: '', novaSenha: '', repitaSenha: '' });

  useEffect(() => {
    if (configuracoes) setEntrarColor(configuracoes.interface.corPrimaria);
  }, [configuracoes]);

  const [redefineError, setRedefineError] = useState('');

  const formik = useFormik({
    initialValues: { email: '', username: sessionStorage.getItem('usernameHandle') || '' },
    validateOnChange: false,
    onSubmit: async () => {
      try {
        await api.post(`/Usuario/EsqueciMinhaSenha`, {
          email: formik.values.email
        }, { headers: { 'Client-Url': history.location.pathname + '?rec=' + btoa(formik.values.email) } });
        //setRedefineError('Código enviado para o e-mail informado!');
        message.success('Código enviado para o e-mail informado!');
        setCriarNovaSenha({ ...criarNovaSenha, codigoEnviado: true });

      } catch (err) {
        //setRedefineError('Erro ao redefinir senha, tente novamente mais tarde!');
        message.error('Erro ao redefinir senha, tente novamente mais tarde!');
      } finally {
        /* sessionStorage.removeItem('codigoReset');
        sessionStorage.removeItem('novaSenhaReset');
        sessionStorage.removeItem('usernameHandle'); */
      }
    },
    validate: (values) => {
      let errors = {};
      setRedefineError('');

      if (!validateBr.email(values.email))
        errors = { ...errors, email: 'Digite um e-mail válido', };

      return errors;
    },
  });

  const RedefinirSenha = async () => {
    setXhrAction(true);
    try {
      if (criarNovaSenha.codigo == '' || criarNovaSenha.novaSenha == '' || criarNovaSenha.repitaSenha == '') {
        return message.error('Preencha todos os campos')

      } else if (new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})').test(criarNovaSenha.novaSenha.trim() || '') == false) {
        return message.error('Senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial')

      } else if (criarNovaSenha.novaSenha.trim() != criarNovaSenha.repitaSenha.trim()) {
        return message.error('As senhas não conferem')
      }

      const urlParams = new URLSearchParams(window.location.search);

      await api.post(`/Usuario/ConfirmarEsqueciMinhaSenha`, {
        email: urlParams.get('rec') ?
          urlParams.get('rec')?.slice(-1) == '/' ?
            atob(urlParams.get('rec')?.slice(0, -1) || '')
            : atob(urlParams.get('rec') || '')
          : formik.values.email,
        codigoConfirmacao: criarNovaSenha.codigo,
        novaSenha: criarNovaSenha.novaSenha,
      })

      message.success('Senha redefinida com sucesso! Faça login para continuar');

      setTimeout(() => {
        window.location.href = subdominio ? `/${subdominio}` : '/login';
      }, 3333);

    } catch (err) {
      console.log("err: ", err);
      message.error('Erro ao redefinir senha, tente novamente!');

    } finally {
      setXhrAction(false)
    }
  }

  const PrimeiroAcesso = async () => {
    setXhrAction(true);
    try {
      if (criarNovaSenha.novaSenha == '' || criarNovaSenha.repitaSenha == '') {
        return message.error('Preencha todos os campos')

      } else if (new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})').test(criarNovaSenha.novaSenha.trim() || '') == false) {
        return message.error('Senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial')

      } else if (criarNovaSenha.novaSenha.trim() != criarNovaSenha.repitaSenha.trim()) {
        return message.error('As senhas não conferem')
      }

      await api.put(`/Usuario/NewPasswordRequired`, {
        username: formik.values.username,
        novaSenha: criarNovaSenha.novaSenha,
        session: sessionStorage.getItem('session1Acesso'),
      })

      message.success('Senha definida com sucesso! Faça login para continuar');

      setTimeout(() => {
        window.location.href = subdominio ? `/${subdominio}` : '/login';
      }, 3333);

    } catch (err) {
      //console.log("err: ", err);
      message.error('Erro ao redefinir senha, tente novamente!');

    } finally {
      setXhrAction(false)
    }
  }

  const TestPasswordForte = (password: any) => {
    var password_strength = document.getElementById("password-text") as HTMLElement;

    //TextBox left blank.
    if (password && password.length == 0) {
      password_strength.innerHTML = "";
      return false;
    }

    //Regular Expressions.
    var regex = new Array();
    regex.push("[A-Z]"); //Uppercase Alphabet.
    regex.push("[a-z]"); //Lowercase Alphabet.
    regex.push("[0-9]"); //Digit.
    regex.push("[$@$!%*#?&]"); //Special Character.

    var passed = 0;

    //Validate for each Regular Expression.
    for (var i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++;
      }
    }

    //Display status.
    switch (passed) {
      case 0 || 1 || 2:
        password_strength.innerHTML = "<small class='progress-bar bg-danger' style='width: 40%'>Fraca</small>";
        return false;
      case 3:
        password_strength.innerHTML = "<small class='progress-bar bg-warning' style='width: 60%'>Média</small>";
        return false;
      case 4:
        password_strength.innerHTML = "<small class='progress-bar bg-success' style='width: 100%'>Forte</small>";
        return true;
      default:
        return false;
    }
  }

  return (
    <div className="card-login">
      {/* abaixo será renderizado caso seja clicado esqueci minha senha */}
      {criarNovaSenha.codigoEnviado == false &&
        sessionStorage.getItem('tipoRedefinir') != 'Criar Nova Senha (1º Acesso)' &&
        href.includes('?rec=') == false ?
        <FormikProvider value={formik}>
          <Form>
            <div className="form-row mt-3">
              <div className="col-lg-12">
                <h3>Recuperar Senha</h3>
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-12">
                <div className='input-wrapper'>
                  <label>E-mail</label>
                  <AntdInput value={formik.values.email}
                    autoComplete='off' placeholder="Digite o e-mail"
                    onChange={({ target: { value: v } }) =>
                      formik.setFieldValue('email', `${v}`?.replace(/[^a-zA-Z0-9@._-]/g, ''))} />
                </div>
              </div>
            </div>

            <div className="login-error">{redefineError}</div>

            <Button id='btnLogin' block type="primary" htmlType="submit" disabled={formik.isSubmitting} shape="round"
              style={{ backgroundColor: entrarColor || '#881220', border: 0 }} >
              {formik.isSubmitting ? <Login /> : 'Enviar'}
            </Button>

            <b onClick={() => window.location.href = href.split('?rec=')[0]}
              className="d-flex justify-content-center mt-3"
              style={{
                backgroundColor: 'transparent', border: 0,
                color: entrarColor || '#881220', cursor: 'pointer'
              }}>
              Voltar
            </b>
          </Form>
        </FormikProvider>

        : <> {/* abaixo será renderizado caso seja clicado 1º acesso e esqueci minha senha (alguns campos) */}
          <div className="form-row mt-3">
            <div className="col-lg-12">
              <h3>{sessionStorage.getItem('tipoRedefinir')}</h3>
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-12">
              {sessionStorage.getItem('tipoRedefinir') != 'Criar Nova Senha (1º Acesso)' &&
                <div className='mb-3'>
                  <label>Código</label>
                  <AntdInput autoComplete='off' value={criarNovaSenha.codigo || ''}
                    placeholder="Digite o código" id='codigo'
                    onChange={({ target: { value: v } }) => setCriarNovaSenha({ ...criarNovaSenha, codigo: v })}
                    style={{ border: '1px solid #d9d9d9', borderRadius: '3px' }} />
                </div>}

              <label>Nova Senha</label>
              <AntdInput.Password autoComplete='off' value={criarNovaSenha.novaSenha || ''}
                style={{ border: '1px solid #d9d9d9', borderRadius: '3px' }} className='mb-3'
                placeholder="Digite a nova senha" id='novaSenha'
                onChange={({ target: { value: v } }) => {
                  setCriarNovaSenha({ ...criarNovaSenha, novaSenha: v })
                  TestPasswordForte(v);
                }} />
              <small className="help-block" id="password-text"></small>
              <div>
                <b>Requisitos:</b>
                <ul>
                  <li>Letras Maiúsculas</li>
                  <li>Letras Minúsculas</li>
                  <li>Números</li>
                  <li>Caracteres Especiais</li>
                </ul>
              </div>

              <label>Repita a Senha</label>
              <AntdInput.Password autoComplete='off' value={criarNovaSenha.repitaSenha || ''}
                placeholder="Digite a nova senha novamente" id='repitaSenha'
                onChange={({ target: { value: v } }) => setCriarNovaSenha({ ...criarNovaSenha, repitaSenha: v })}
                style={{ border: '1px solid #d9d9d9', borderRadius: '3px' }} />
            </div>
          </div>

          <Button id='btnLogin' block shape="round"
            style={{ backgroundColor: entrarColor || '#881220', border: 0, color: '#fff' }}
            disabled={xhrAction || criarNovaSenha.repitaSenha != criarNovaSenha.novaSenha || !TestPasswordForte(criarNovaSenha.repitaSenha)}
            onClick={() => {
              sessionStorage.getItem('tipoRedefinir') != 'Criar Nova Senha (1º Acesso)' ? RedefinirSenha() : PrimeiroAcesso();
            }}>
            {xhrAction ? <Login /> : 'Enviar'}
          </Button>

          <b onClick={() => window.location.href = href.split('?rec=')[0]}
            className="d-flex justify-content-center my-3"
            style={{
              backgroundColor: 'transparent', border: 0,
              color: entrarColor || '#881220', cursor: 'pointer'
            }}>
            Voltar
          </b>

          {sessionStorage.getItem('tipoRedefinir') != 'Criar Nova Senha (1º Acesso)' &&
            <div className='text-center'>caso não recebeu o código clique em
              <b style={{ color: entrarColor || '#881220', cursor: 'pointer' }}
                onClick={formik.submitForm}> reenviar</b>
            </div>}
        </>}
    </div >
  );
};

export default CardRedefinePass;