type Campo = {
  [field: string]: string;
};

const masks: Campo = {
  cpf: '999.999.999-99',
  'cpf-conjuge': '999.999.999-99',
  'socio-cpf': '999.999.999-99',
  'cep-pf': '99999-999',
  'cep-dadoscomerciais-pf': '99999-999',
  'cep-cobranca-pj': '99999-999',
  'cep-entrega-pj': '99999-999',
  'socio-cep': '99999-999',
  cnpj: '99.999.999/9999-99',
  'cnpj-pf': '99.999.999/9999-99',
  'fone-residencial': '(99) 999999999',
  'fone-celular': '(99) 999999999',
  'fone-comercial': '(99) 999999999',
  'socio-celular': '(99) 999999999',
  'socio-telefone': '(99) 999999999',
  'tel-dadoscomerciais-pf': '(99) 999999999',
  'tel-referencia-1': '(99) 999999999',
  'tel-referencia-2': '(99) 999999999',
};

export default masks;
