import toolbarReactQuill from '@utils/toolbarReactQuill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FC, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Select, Option } from '@components/Form';
import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { EtapaContext } from '@contexts/EtapaContext';
import { integracoes } from '@constants/Integracoes';
import { Switch } from 'antd';

const CodigoVerif: FC = () => {
  const { tipoPessoa, etapaIdx, etapaPessoa } = useContext(EtapaContext);
  const { setFieldValue } = useFormikContext<ConfigTypes>();

  return <>
    <div className='d-flex justify-content-between mb-2 pr-2'>
      {['E-mail', 'SMS'].map((item, idx) =>
        <div key={item} className="switch-wrapper">
          <div>
            <Switch checked={etapaPessoa[etapaIdx]?.servico == item ? true : false}
              onChange={() => { setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.servico`, item) }} />
            <span>Verificar por {item}</span>
          </div>
        </div>)}

      <div className="switch-wrapper">
        <div>
          <Switch checked={etapaPessoa[etapaIdx]?.habilitar}
            onChange={(value) => {
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.habilitar`, value)
            }} />
          <span>Obrigatório</span>
        </div>
      </div>
    </div>

    <div>Mensagem Explicativa:</div>
    <div className="switch-wrapper my-2">
      <div>
        <Switch checked={etapaPessoa[etapaIdx]?.tipoCalculo == 1 ? true : false}
          onChange={(value) => {
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.tipoCalculo`, value ? 1 : 0)

            if (!value)
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.expressao`, '')
          }} />
        <span>Utilizar mensagem padrão</span>
      </div>
    </div>

    {!etapaPessoa[etapaIdx]?.tipoCalculo &&
      <div style={{ minHeight: '200px' }}>
        <ReactQuill modules={toolbarReactQuill}
          value={etapaPessoa[etapaIdx]?.expressao}
          onChange={(value) => {
            setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.expressao`, value);
          }} />
      </div>}
  </>
};

export default CodigoVerif;