export const trimDecimalZero = (inputString: string) => {
  // Convert the string to a number
  const numberValue = parseFloat(inputString.replace(',', '.'));

  // Check if it's a valid number
  if (!isNaN(numberValue)) {
    // Check if the decimal part is .00
    const decimalPart = numberValue % 1 === 0 ? '.00' : '';

    // Return the formatted string without the decimal part if it's .00
    return numberValue % 1 === 0 ? numberValue.toFixed(0).replace('.', ',') : numberValue.toFixed(2).replace('.', ',');
  }

  // Return the original string if not a valid number
  return inputString;
}