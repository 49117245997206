import { FC, useState, useEffect } from 'react';
import { Modal, ModalProps, message } from 'antd';
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import ModalBase from '..';
import { Select, Option } from '@components/Form';
import './styles.scss';

interface Props extends ModalProps {
  uploadFile: Function;
  arrayOptions: any;
}

const ModalCameraUploadDinamico = ({ arrayOptions, uploadFile, ...props }: any) => {
  const [docName, setDocName] = useState('');
  const [dataUri, setDataUri] = useState('');
  const [cameraError, setCameraError] = useState<any>('');
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');

  const resetModalCamera = () => {
    setDocName('');
    setDataUri('');
    setCameraError('');
  }

  useEffect(() => {
    resetModalCamera()

    if (props.visible) {
      setCameraError('');
      (async function requestCamera() {
        try {
          await navigator.mediaDevices.getUserMedia({
            video: true,
          });
        } catch (err) {
          handleErrorPhoto(err);
        }
      })();
    }
  }, [props.visible]);

  function urltoFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  async function invertImageHorizontally(dataUri: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = function () {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d')!;
        canvas.width = image.width;
        canvas.height = image.height;

        // Invert the image horizontally
        context.translate(image.width, 0);
        context.scale(-1, 1);
        context.drawImage(image, 0, 0, image.width, image.height);

        // Convert the canvas to data URI
        const invertedDataUri = canvas.toDataURL('image/jpeg'); // Change the format if needed

        resolve(invertedDataUri);
      };

      image.onerror = function () {
        reject(new Error('Failed to load the image.'));
      };

      image.src = dataUri;
    });
  }

  function isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  async function handleTakePhoto(dataUri: string) {
    setDataUri(dataUri);
  }

  async function handleErrorPhoto(err: any) {
    if (`${err}`.includes('Permission denied'))
      setCameraError('Sem permissão de acesso a câmera, para usá-la, faça a liberação no seu navegador.')
    else
      setCameraError('Erro ao acessar sua câmera, verifique se ela está conectada corretamente e tente novamente.')
  }

  return (
    <ModalBase destroyOnClose
      title="Capturar foto de documento"
      afterClose={() => {
        resetModalCamera()
      }}
      {...props}
      style={{ top: 20 }}
      onOk={async () => {
        if (cameraError != '')
          props.onCancel();
        else {
          if (!docName)
            return message.error('Selecione um documento');
          else if (!dataUri)
            return message.error('Clique no botão de captura, e tire uma foto do documento');

          props.onCancel();
          const newFile = await urltoFile(dataUri, `${docName}.jpg`, 'image/jpeg');
          await uploadFile(newFile);

          resetModalCamera()
        }
      }}
    >
      <div className="row mb-3">
        <div className="col-lg-12">
          <Select
            showSearch
            optionFilterProp="children"
            name="docName"
            label="Selecione o documento"
            placeholder="Selecione uma opção"
            onChange={(docName: string) => {
              setDocName(docName);
              setDataUri('');
            }}
          >
            {(arrayOptions?.length == 0 || arrayOptions == undefined) ?
              <Option value={'Documento'}>Documento</Option>
              :
              arrayOptions?.split(',').map((option: any) =>
                <Option key={option} value={option} children={option} />)}
          </Select>
        </div>
        {!cameraError && !dataUri && docName && isMobile() &&
          <div className="col-lg-12 ml-2 mt-4" style={{ marginBottom: -200, zIndex: 9999 }}>
            <button className='btn btn-primary' onClick={() =>
              setFacingMode(facingMode === 'user' ? 'environment' : 'user')}>
              <i className="fa fa-camera-rotate" />
            </button>
          </div>}
      </div>
      <div className="upload-box">
        {!cameraError ? (
          !dataUri ? (
            docName && <Camera
              onTakePhotoAnimationDone={(dataUri) => {
                if (facingMode === 'environment')
                  handleTakePhoto(dataUri);
                else invertImageHorizontally(dataUri)
                  .then((invertedDataUri) => {
                    handleTakePhoto(invertedDataUri);
                  })
                  .catch((error) => {
                    console.error('Error:', error.message);
                    message.error('Erro ao processar a imagem, tente novamente.');
                  });
              }}
              onCameraError={(err) => {
                handleErrorPhoto(err);
              }}
              imageType={IMAGE_TYPES.JPG}
              isDisplayStartCameraError={true}
              idealFacingMode={facingMode}
            />
          ) : (
            <img src={dataUri} />
          )
        ) : (
          <p>{cameraError}</p>
        )}
      </div>
    </ModalBase>
  );
};

export default ModalCameraUploadDinamico;
