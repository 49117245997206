import validaCep from './validaCep';
import validaCPF from './validaCpf';
import validaCNH from './ValidaCNH'
import validaEmail from './validaEmail';
import validaCNPJ from './validaCNPJ';

type FieldType = {
  [field: string]: Function;
};

function customValidation(field: string, value: any) {
  //console.log('entrou no customValidation', field);
  const validate: FieldType = {
    cpf: validaCPF,
    'socio-cpf': validaCPF,
    'cpf-conjuge': validaCPF,
    cnpj: validaCNPJ,
    'cnpj-pf': validaCNPJ,
    'cep-pf': validaCep,
    'cep-dadoscomerciais-pf': validaCep,
    email: validaEmail,
    'email-pj': validaEmail,
    'socio-email': validaEmail,
  };
  //console.log('validating', field, value);

  if (validate[field]) {
    //console.log('validating', field, value);
    return validate[field](value);
  }

  return true;
}

export default customValidation;
