import { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Input, InputGroup, SectionTitle } from '@components/Form';
import { Select, Option, Switch } from '@components/Form';
import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { GlobalContext } from '@contexts/GlobalContext';
import { useInstituicoes } from '@services/Instituicoes';
import { ConfigProvider, message, Modal, Input as AntdInput, Button, Tooltip, Select as AntdSelect, Switch as AntdSwitch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import api from '@services/api';
import ModalLoading from '@components/Modal/ModalLoading';
import moment from 'moment';

const baseURL = window.location.origin;

export default function Integracao() {
  const novaInstituicaoModel: any = { nome: "", guid: "" }
  const { data: instituicoes } = useInstituicoes({ refetchOnWindowFocus: false, staleTime: 1000 * 60, });
  const instituicaoStoraged = sessionStorage.getItem('b2e:instituicao');

  const { setFieldValue, values } = useFormikContext<ConfigTypes>();
  const [modalHabInstituicao, setModalHabInstituicao] = useState(false);
  const [novaInstituicao, setNovaInstituicao] = useState(novaInstituicaoModel);

  const { subdominio, setInstituicao, loading, configuracoes: c, pareceresInstituicao, setPareceresInstituicao } = useContext(GlobalContext);

  const [listTemplates, setListTemplates] = useState([]);
  const [idTemplateSelected, setIdTemplateSelected] = useState(-1);
  const [modalAddTemplate, setModalAddTemplate] = useState(false);

  const [travaProposta, setTravaProposta] = useState({ "id": 0, "guid": null, "travaDocumento": false, "travaDias": 0 });

  const [totalPropostas, setTotalPropostas] = useState(0);
  const [idStatusSelected, setIdStatusSelected] = useState(-1);

  const addStatusModel: any = { idStatus: '', descricao: '' };
  const [addStatus, setAddStatusModel]: any = useState(addStatusModel);

  const [expHorasPropostaPendente, setExpHorasPropostaPendente] = useState(0);

  const getTotalPropostas = async () => {
    let { data: idInstituicao } = await api.get(`ConfiguracaoForm/InstituicoesGuid?guidInstituicao=${instituicaoStoraged || values.instituicao.codigo}`)
    let { data: total } = await api.get(`Proposta/TotalPropostas?id_tb_instituicao=${idInstituicao}`)
    setTotalPropostas(total)
  }

  const getListTemplates = async () => {
    let { data } = await api.get(`ConfiguracaoForm/TemplateConfiguracao`) || [];
    setListTemplates(data);
    setIdTemplateSelected(-1);
    sessionStorage.removeItem('nomeNewTemplate');
  }

  const salvarTemplateInstituicao = async () => {
    try {
      let nome = `${sessionStorage.getItem('nomeNewTemplate')} | ${moment().format('YYYY-MM-DD HH:mm:ss')}`
      let { data: atualConfig }: any = await api.get(`ConfiguracaoForm?instituicao=${instituicaoStoraged}`);
      await api.post(`ConfiguracaoForm/TemplateConfiguracao`, { nome, ...atualConfig })
      message.success('Template salvo com sucesso')
      getListTemplates()
      Modal.destroyAll();
    } catch (error) {
      console.log("error: ", error);
      message.error('Falha ao salvar template')
    }
  }

  const carregarTemplateInstituicao = async (guid: string = sessionStorage.getItem('b2e:instituicao') || '') => {
    try {
      // se houver template selecionado, insere configurações do template:
      var templateConfig: any = {};
      if (idTemplateSelected >= 0) {
        var { data: templateConfig }: any = await api.get(`ConfiguracaoForm/TemplateConfiguracao/${idTemplateSelected}`);
      } else {
        var { data: templateConfig }: any = await api.get(`ConfiguracaoForm?instituicao=templateVazio`);
      }

      ['instituicao', 'gestaoAcesso'].map((key) => { delete templateConfig[key]; });

      let { data: atualConfig }: any = await api.get(`ConfiguracaoForm?instituicao=${guid}`);
      await api.post(`ConfiguracaoForm`, { ...atualConfig, ...templateConfig });

      setInstituicao(guid);
      sessionStorage.setItem('b2e:instituicao', guid);
      setFieldValue('instituicao.codigo', guid);

      message.success('Operação realizada com sucesso');
      window.location.reload();
    } catch (error) {
      console.log("error: ", error);
      message.error('Falha ao salvar template')
    }
  }

  const addInstituicao = async () => {
    if (novaInstituicao.nome.trim() == '') return message.error('Nome da instituição é obrigatório');
    if (novaInstituicao.guid.trim() == '') return message.error('Guid da instituição é obrigatório');

    try { // verifica se o guid existe no sistema CRED:
      await api.get(`ConfiguracaoForm/InstituicoesGuid?guidInstituicao=${novaInstituicao.guid}`)
    } catch (error) {
      return message.error('Guid inexistente no sistema CRED');
    }

    try { // verifica se o guid já está habilitado no Onboarding:
      let { data: all }: any = await api.get(`ConfiguracaoForm/Instituicoes`);
      if (all.some((i: any) => i.instituicaoGuid == novaInstituicao.guid))
        return message.error('Instiuição já habilitada no Onboarding');

      // inserindo instituição:
      await api.post(`ConfiguracaoForm/HabilitarInstituicao?guidInstituicao=${novaInstituicao.guid}&nome=${novaInstituicao.nome}`);

      carregarTemplateInstituicao(novaInstituicao.guid);
    } catch (error) {
      console.log("error: ", error);
      message.error('Ocorreu um erro ao salvar instituição!');
    }
  };

  const addStatusInstituicao = async () => {
    if (!instituicaoStoraged) return message.error('Instituição não definida');
    if (!addStatus?.idStatus || addStatus?.idStatus < 0) return message.error('Id status inválido');
    if (!addStatus?.descricao || addStatus?.descricao?.trim() == '') return message.error('Descrição de status obrigatório');

    try {
      await api.post(`ConfiguracaoForm/InserirStatusInstituicao?guidInstituicao=${instituicaoStoraged}&idStatus=${addStatus.idStatus}&descricaoStatus=${addStatus.descricao}`)
      loadPareceresInstituicao();
      setAddStatusModel(addStatusModel);
      message.success('Parecer inserido com sucesso');
    } catch (error) {
      message.error('Falha ao inserir parecer. Verifique se o Id informado já não existe para instituição.')
    }
  };

  const loadPareceresInstituicao = async () => {
    try {
      let { data } = await api.get(`ConfiguracaoForm/ObterStatusInstituicao?guidInstituicao=${instituicaoStoraged}`)
      setPareceresInstituicao(data)
    } catch (error) {
      console.log("error: ", error);
      //message.error('Falha ao atualizar lista de Status, recarregue a página')
    }
  }

  const loadExpHorasPropostaPendente = async () => {
    try {
      let { data, status } = await api.get(`ProcessoProposta/ObterConfiguracaoInstituicao/${instituicaoStoraged}`)
      if (status == 200) setExpHorasPropostaPendente(data.tempoExpiracaoHoras)
    } catch (error) {
      console.log("error: ", error);
      //message.error('Falha ao atualizar Tempo de Expiração Proposta não enviada, recarregue a página')
    }
  }

  const loadTravaProposta = async () => {
    try {
      let { data, status } = await api.get(`TravaProposta/ObterTravaProposta?guid=${instituicaoStoraged}`)
      if (status == 200) setTravaProposta(data)
    } catch (error) {
      console.log("error: ", error);
      //message.error('Falha ao atualizar Tempo de Expiração Proposta não enviada, recarregue a página')
    }
  }

  /* useEffect(() => {
    // on change, Block Special Characters
    values.instituicao.subdominio = values.instituicao.subdominio.replace(/[^a-zA-Z0-9]/g, '');
  }, [values.instituicao.subdominio]); */

  useEffect(() => {
    getListTemplates()

    if (instituicaoStoraged) {
      getTotalPropostas()
      loadExpHorasPropostaPendente();
      loadTravaProposta();
      setFieldValue('instituicao.codigo', instituicaoStoraged);
      setInstituicao(instituicaoStoraged);
    } else {
      setFieldValue('instituicao.codigo', '');
    }
  }, [instituicaoStoraged, setFieldValue]);

  /* useEffect(() => {
    setFieldValue('instituicao.nome', sessionStorage.getItem('b2e:instituicaoNome'));
  }, [instituicoes]); */

  return <>
    <SectionTitle title="Integração/Instituição" />
    <div className="row">
      <div className="col-lg-12">
        <div className="form-row">
          {!subdominio && <>
            <div className="col-lg-10">
              <Select
                showSearch
                optionFilterProp="children"
                name="instituicao.codigo"
                label="Selecione uma instituição"
                placeholder="Selecione uma instituição"
                onChange={(value) => {
                  if (value.toString() == "add")
                    setModalHabInstituicao(true);
                  else {
                    setInstituicao(value);
                    sessionStorage.setItem('b2e:instituicao', value);
                    setFieldValue('instituicao.codigo', value);
                    window.location.reload();
                  }
                }}>
                <Option key="add" value="add">
                  <b><PlusOutlined /> HABILITAR INSTITUIÇÃO</b>
                </Option>
                {instituicoes
                  ?.sort((a, b) => a.nome.localeCompare(b.nome))
                  ?.map(({ instituicaoGuid: g, nome }: any) => <Option key={g} value={g}>{nome}</Option>)}
              </Select>
            </div>
            {instituicaoStoraged && <>
              <div className='col-lg-2'>
                <Tooltip title="Salvar Template Instituição" placement="top">
                  <i className="fa fa-save col-md-auto"
                    style={{ cursor: 'pointer', marginTop: '35px' }}
                    onClick={() =>
                      Modal.confirm({
                        title: 'Digite o nome do template:',
                        wrapClassName: 'remove-antd-footer',
                        content: <div>
                          <div className='input-wrapper'>
                            <AntdInput placeholder="Nome do Template"
                              onChange={({ target: { value: v } }) => {
                                if (v?.trim() == '')
                                  return sessionStorage.removeItem('nomeNewTemplate');

                                sessionStorage.setItem('nomeNewTemplate', v);
                              }} />
                          </div>
                          <div className='float-right mt-4'>
                            <Button className='mr-2' onClick={() => Modal.destroyAll()}>
                              Cancelar
                            </Button>
                            <Button type='primary' onClick={() => {
                              if (!sessionStorage.getItem('nomeNewTemplate'))
                                return message.error('Informe o nome do template');

                              salvarTemplateInstituicao();
                            }}>
                              Salvar
                            </Button>
                          </div>
                        </div>
                      })} />
                </Tooltip>
                {listTemplates.length > 0 &&
                  <Tooltip title="Gerenciador de Templates" placement="top">
                    <i className="fa fa-server col-md-auto ml-4"
                      style={{ cursor: 'pointer', marginTop: '35px' }}
                      onClick={() => setModalAddTemplate(true)} />
                  </Tooltip>}
              </div>
            </>}
          </>}
        </div>

        <div className="form-row d-flex align-items-center">
          <div className="col-lg-2 d-flex justify-content-end">
            URL Área Logada
          </div>
          <div className="col-lg-6">
            <InputGroup addon={<span className="input-group-addon">{baseURL}</span>}
              name="instituicao.subdominio" label="" placeholder="Insira o subdomínio"
              onClick={() => { document?.getElementById('salvarConfigs')?.setAttribute('disabled', 'true'); }}
              onBlur={async ({ target: { value: v } }) => {
                // verifica se o subdomínio foi alterado:
                if (sessionStorage.getItem('b2e:subdominio') != v)
                  try {
                    await api.get(`ConfiguracaoForm/${v}`)
                    message.error('Subdomínio já utilizado');
                    setFieldValue('instituicao.subdominio', sessionStorage.getItem('b2e:subdominio'));
                  } catch (error) {
                    console.log("error: ", error);
                    //message.success('Subdomínio disponível');
                  } finally {
                    document?.getElementById('salvarConfigs')?.removeAttribute('disabled');
                  }
              }} />
          </div>
          <div className="col-lg-4"
            onMouseEnter={({ currentTarget: c }) => c.style.cursor = 'pointer'}>
            <i className="fa fa-clipboard col-md-auto"
              onClick={() => {
                navigator.clipboard.writeText(baseURL + '/' + values.instituicao.subdominio);
                message.success('Copiado para área de transferência');
              }}
            />
            <i className="fa fa-external-link col-md-auto"
              onClick={() => window.open(baseURL + '/' + values.instituicao.subdominio)}
            />
          </div>
        </div>

        <div className="form-row d-flex align-items-center">
          <div className="col-lg-2 d-flex justify-content-end">
            URL Site Externo
          </div>
          <div className="col-lg-6">
            <Input label="" name="_" readOnly
              disabled={!values.interface.site}
              value={baseURL + '/' + values?.instituicao?.subdominio + '/site'}
            />
          </div>
          <div className="form-row d-flex align-items-center">
            <div className="col-md-auto">
              <Switch name="_" label="Habilitar"
                checked={values.interface.site}
                onChange={(value) => setFieldValue('interface.site', value)}
              />
            </div>
            <i className="fa fa-clipboard col-md-auto"
              onMouseEnter={({ currentTarget: c }) => c.style.cursor = 'pointer'}
              onClick={() => {
                navigator.clipboard.writeText(baseURL + '/' + values.instituicao.subdominio + '/site');
                message.success('Copiado para área de transferência');
              }}
            />
            <i className="fa fa-external-link col-md-auto"
              onMouseEnter={({ currentTarget: c }) => c.style.cursor = 'pointer'}
              onClick={() => window.open(baseURL + '/' + values.instituicao.subdominio + '/site')}
            />
          </div>
        </div>

        <div className="form-row d-flex align-items-center">
          <div className="col-lg-2 d-flex justify-content-end">
            URL Frame
          </div>
          <div className="col-lg-6">
            <Input label="" name="_" readOnly
              disabled={!values.interface.iframe}
              value={baseURL + '/' + values?.instituicao?.subdominio + '/frame'}
            />
          </div>
          <div className="form-row d-flex align-items-center">
            <div className="col-md-auto">
              <Switch name="_" label="Habilitar"
                checked={values.interface.iframe}
                onChange={(value) =>
                  setFieldValue('interface.iframe', value)
                }
              />
            </div>
            <i className="fa fa-clipboard col-md-auto"
              onMouseEnter={({ currentTarget: c }) => c.style.cursor = 'pointer'}
              onClick={() => {
                navigator.clipboard.writeText(baseURL + '/' + values.instituicao.subdominio + '/frame');
                message.success('Copiado para área de transferência');
              }}
            />
            <i className="fa fa-external-link col-md-auto"
              onMouseEnter={({ currentTarget: c }) => c.style.cursor = 'pointer'}
              onClick={() => window.open(baseURL + '/' + values.instituicao.subdominio + '/frame')}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-lg-6">
            <SectionTitle title="Inserir Dados de Autenticação API Proposta:" />
            <div className="form-row">
              <div className="col-lg-12">
                <Input autoComplete='off' label="Username"
                  name="instituicao.apiProposta.username"
                  placeholder="Insira nome de usuário" type="password"
                  value={values.instituicao.apiProposta.username}
                  onClick={() => {
                    if (values.instituicao.apiProposta.username == '*********')
                      setFieldValue('instituicao.apiProposta.username', '')
                  }}
                  onBlur={() => {
                    if (values.instituicao.apiProposta.username?.trim() == '')
                      setFieldValue('instituicao.apiProposta.username', '*********')
                  }} />
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-12">
                <Input autoComplete='off' label="Password"
                  name="instituicao.apiProposta.password"
                  placeholder="Insira o password" type="password"
                  value={values.instituicao.apiProposta.password}
                  onClick={() => {
                    if (values.instituicao.apiProposta.password == '*********')
                      setFieldValue('instituicao.apiProposta.password', '')
                  }}
                  onBlur={() => {
                    if (values.instituicao.apiProposta.password?.trim() == '')
                      setFieldValue('instituicao.apiProposta.password', '*********')
                  }} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <SectionTitle title="Configurar Status:" />
            <div className="form-row">
              <div className="col-lg-9 select-wrapper">
                <label>Status Habilitados</label>
                <AntdSelect value={idStatusSelected}
                  onChange={(e) => { setIdStatusSelected(e) }}>
                  <Option disabled value={-1}>Selecione um Status</Option>
                  {pareceresInstituicao?.sort((a, b) => a.idStatus - b.idStatus).map(
                    (option: any) => (
                      <Option key={option.idStatus} value={option.idStatus}>
                        {option.idStatus} - {option.descricao}
                      </Option>
                    ))}
                </AntdSelect>
              </div>
              {idStatusSelected >= 0 ?
                <div className="col-lg-2">
                  <Tooltip title="Editar Descrição" placement="top">
                    <i className='fa fa-pencil' style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220', marginTop: '35px' }}
                      onClick={() => {
                        const onOk = async () => {
                          if (!sessionStorage.getItem('novaDescricao')?.trim())
                            return message.error('Descrição inválida');

                          try {
                            await api.get(`ConfiguracaoForm/AlterarDescricaoStatus?guidInstituicao=${instituicaoStoraged}&idStatus=${idStatusSelected}&descricao=${sessionStorage.getItem('novaDescricao')}`)
                            message.success('Descrição alterada com sucesso')
                            Modal.destroyAll();
                          } catch (error) {
                            console.log("error: ", error);
                            message.error('Falha ao alterar descrição')
                            return;
                          } finally {
                            sessionStorage.removeItem('novaDescricao');
                          }

                          loadPareceresInstituicao();
                        }

                        Modal.confirm({
                          title: 'Digite a nova descrição:',
                          wrapClassName: 'remove-antd-footer',
                          content: <div>
                            <div className='input-wrapper'>
                              <AntdInput defaultValue={pareceresInstituicao?.find((p: any) => p.idStatus == idStatusSelected)?.descricao}
                                onChange={({ target: { value: v } }) => sessionStorage.setItem('novaDescricao', v)}
                                placeholder="Descrição" />
                            </div>
                            <div className='mt-4 float-right'>
                              <button onClick={() => Modal.destroyAll()} className='btn btn-default mr-2'>
                                Cancelar
                              </button>
                              <button onClick={onOk} className='btn btn-primary'>
                                Salvar
                              </button>
                            </div>
                          </div>
                        });
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Desabilitar Status" placement="top">
                    <i className='fa fa-trash ml-4' style={{ cursor: 'pointer', color: c?.interface?.corPrimaria || '#881220', marginTop: '35px' }}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Tem certeza que deseja desabilitar este status? Se já configurado pela instituição, poderá ocasionar a perda de dados.',
                          okText: 'Sim, Desabilitar',
                          async onOk() {
                            try {
                              await api.get(`ConfiguracaoForm/RemoverStatus?guidInstituicao=${instituicaoStoraged}&idStatus=${idStatusSelected}`)
                              setIdStatusSelected(-1);
                              message.success('Status desabilitado com sucesso')
                            } catch (error) {
                              console.log("error: ", error);
                              message.error('Falha ao desabilitar status')
                            }

                            loadPareceresInstituicao();
                          },
                        });
                      }}
                    />
                  </Tooltip>
                </div>
                : null}
            </div>

            <div className="form-row">
              <div className="col-lg-2">
                <Input autoComplete='off'
                  name="add-parecer-id"
                  label="Id"
                  type="number"
                  placeholder="Id"
                  value={addStatus?.idStatus}
                  onChange={(e) => setAddStatusModel({ ...addStatus, idStatus: e.target.value })} />
              </div>
              <div className="col">
                <Input autoComplete='off'
                  name="add-parecer-desc"
                  label="Descrição"
                  type="text"
                  placeholder="Descrição"
                  value={addStatus?.descricao}
                  onChange={(e) => setAddStatusModel({ ...addStatus, descricao: e.target.value })} />
              </div>
              <div>
                <Button id='add-parecer-btn' type="primary" className="mt-4"
                  onClick={addStatusInstituicao}
                  disabled={!instituicaoStoraged || !addStatus?.idStatus || !addStatus?.descricao}>
                  Incluir Parecer
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-lg-12">
            <SectionTitle title="Propostas Cadastro:" />
            <div className='form-row'>
              <div className="col-lg-6">
                <Input
                  name="interface.limitePropostas"
                  placeholder='Não definido' type="number"
                  value={values.interface.limitePropostas}
                  label="Limite de Envio Proposta"
                  onChange={({ target: t }) => setFieldValue('interface.limitePropostas', t.value)}
                />
              </div>
              <div className="col-lg-6">
                <Input readOnly disabled
                  name="interface.totalPropostas"
                  value={totalPropostas}
                  label="Total de Propostas Enviadas"
                />
              </div>
            </div>
            <div className='form-row'>
              <div className="col-lg-6">
                <Input name="_" value={expHorasPropostaPendente}
                  label="Tempo de Expiração Proposta não Enviada (Horas)"
                  onChange={({ target: { value: v } }) => {
                    if (isNaN(+v)) return;
                    setExpHorasPropostaPendente(+v)
                    sessionStorage.setItem('expHorasPropostaPendente', JSON.stringify(+v))
                  }} />
              </div>
              <div className="col-lg-3 input-wrapper">
                <label>Trava de Dias Documento</label>
                <AntdInput id="travaDias" value={travaProposta.travaDias}
                  onChange={({ target: { value: v } }) => {
                    if (isNaN(+v)) return;
                    setTravaProposta({ ...travaProposta, travaDias: +v })
                    sessionStorage.setItem('travaProposta', JSON.stringify({ ...travaProposta, travaDias: +v }))
                  }} />
              </div>
              <div className="col-lg-3 d-flex align-items-top mt-4 switch-wrapper">
                <div>
                  <AntdSwitch id="travaDocumento" checked={travaProposta.travaDocumento}
                    onChange={async (v) => {
                      setTravaProposta({ ...travaProposta, travaDocumento: v })
                      sessionStorage.setItem('travaProposta', JSON.stringify({ ...travaProposta, travaDocumento: v }))
                    }} />
                  <span>Habilitar</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal title="Habilitar Instituição" open={modalHabInstituicao} footer={null} closable={false}>
          <div className="input-wrapper">
            <label>Nome</label>
            <input value={novaInstituicao.nome} placeholder="Nome Instituicao"
              onChange={(e) => setNovaInstituicao({ ...novaInstituicao, nome: e.target.value })} />
          </div>

          <div className="input-wrapper my-3">
            <label>Guid</label>
            <input value={novaInstituicao.guid} placeholder="Guid"
              onChange={(e) => setNovaInstituicao({ ...novaInstituicao, guid: e.target.value })} />
          </div>

          <div className="select-wrapper">
            <label>Template</label>
            <AntdSelect showSearch optionFilterProp="children"
              value={idTemplateSelected}
              onChange={(e) => setIdTemplateSelected(e)}>
              <Option value={-1}>Vazio (Sem Template)</Option>
              {listTemplates
                ?.sort((a: any, b: any) => a.nome.localeCompare(b.nome))
                ?.map((option: any) =>
                  <Option key={option.id} value={option.id}>
                    {option.nome.replace(/"/g, '')}
                  </Option>)}
            </AntdSelect>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <Button className="mr-2" style={{ background: '#fff', color: '#000' }}
              onClick={() => {
                setNovaInstituicao({ nome: "", guid: "" });
                setModalHabInstituicao(false);
                setIdTemplateSelected(-1);
              }}>
              Cancelar
            </Button>

            <Button onClick={addInstituicao} style={{ background: c?.interface?.corPrimaria || '#881220', color: '#fff' }}>
              Habilitar
            </Button>
          </div>
        </Modal>

        <Modal title="Gerenciador de Templates"
          open={modalAddTemplate} okText="Usar Template"
          onOk={() => {
            Modal.confirm({
              title: 'Tem certeza que deseja carregar este template? Todas as configurações atuais serão substituídas (abas Interface, Etapas, Follow Up)',
              okText: 'Sim, Carregar',
              afterClose: () => Modal.destroyAll(),
              async onOk() { carregarTemplateInstituicao() },
            });
          }}
          onCancel={(() => {
            setModalAddTemplate(false);
            setIdTemplateSelected(-1);
          })}>
          <div className="select-wrapper">
            <label>Selecione um Template</label>
            <AntdSelect showSearch optionFilterProp="children"
              value={idTemplateSelected}
              onChange={(e) => setIdTemplateSelected(e)}>
              <Option value={-1}>Vazio (Sem Template)</Option>
              {listTemplates
                ?.sort((a: any, b: any) => a.nome.localeCompare(b.nome))
                ?.map((option: any) =>
                  <Option key={option.id} value={option.id}>
                    {option.nome.replace(/"/g, '')}
                    <i className="fa fa-trash ml-2"
                      style={{ cursor: 'pointer', color: '#881220' }}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Tem certeza que deseja excluir este template?',
                          okText: 'Sim, Excluir',
                          async onOk() {
                            try {
                              await api.delete(`ConfiguracaoForm/TemplateConfiguracao/${option.id}`);
                              message.success('Template excluído com sucesso');
                              getListTemplates();
                            } catch (error) {
                              console.log("error: ", error);
                              message.error('Falha ao excluir template')
                            }
                          },
                        });
                      }}
                    />
                  </Option>)}
            </AntdSelect>
          </div>
        </Modal>
      </div >
    </div >
  </>
}
