import Icons from '../../assets/svg/icons.svg';

type Props = {
  name: string;
  size: number;
  color?: string;
  className?: string;
};

const Icon = ({ name, color, size, className }: Props) => (
  <svg
    className={`icon icon-${name} ${className}`}
    fill={color}
    width={size}
    height={size}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

export default Icon;
