import toolbarReactQuill from '@utils/toolbarReactQuill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FC, useState, useEffect, useCallback, useContext } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';

import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { Input, Switch, Select, Option } from '@components/Form';
import { EtapaContext } from '@contexts/EtapaContext';

export default function () {
  const { etapaPessoa, etapa, etapaIdx, tipoPessoa } = useContext(EtapaContext);
  const { setFieldValue, values } = useFormikContext<ConfigTypes>();
  const [fields, setFields] = useState<Campo[]>([]);

  const valuesCamposAdicionais = {
    label: 'Upload de Arquivos',
    type: 'opcoes',
    name: `adicional${fields?.length}`,
    habilitar: true,
    obrigatorio: true,
  };

  const handleAddCamposAdicionais = () => {
    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [
      ...etapaPessoa[etapaIdx].campos,
      valuesCamposAdicionais,
    ]);
  };

  const loadFields = useCallback(() => {
    const campos = etapaPessoa[etapaIdx]?.campos;

    setFields(campos);
  }, [values.etapas, etapa]);

  useEffect(() => { if (etapa) loadFields(); }, [loadFields, etapa]);

  useEffect(() => {
    // adicionando valor inicial em 'Insira as opções'
    if (etapaPessoa[etapaIdx].campos.length === 0)
      handleAddCamposAdicionais()
  }, []);

  return <div className='col-lg-12' >
    <label className='col-lg-9 p-0 m-0 mb-1'>Mensagem Explicativa</label>
    <div style={{ minHeight: '200px' }}>
      <ReactQuill modules={toolbarReactQuill}
        value={etapaPessoa[etapaIdx]?.servico}
        onChange={(value) => {
          setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.servico`, value);
        }} />
    </div>
  </div>
};