import { formaPagamentoOptions as f } from '@services/mock';
import { SectionTitle } from '@components/Form'
import { Card, Input, Space, Button, message, Modal, Select } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '@contexts/GlobalContext';
import ReactInputMask from 'react-input-mask';
import { handleURL } from '@utils/handleURL';
import { validateBr } from 'js-brasil';
import api from '@services/api';
import Countdown from 'antd/lib/statistic/Countdown';
import VerificationInput from "react-verification-input";
import ModalLoading from '@components/Modal/ModalLoading';
import ModalPagamentoIugu from '@components/Modal/ModalPagamentoIugu';

export default function () {
  const { pathname: url, href } = window.location;
  const [loading, setLoading] = useState({ status: false, message: '' })
  const { instituicao, configuracoes: c, loadFormOptions } = useContext(GlobalContext);
  const [titleName, setTitleName] = useState<any>('2ª via de faturas')
  const [dadosProponente, setDadosProponente] = useState<any>({ documento: '', celular: '', email: '' })
  const [showForm, setShowForm] = useState<any>({ form1: true, form2: false, form3: false })
  const [codigoVerif, setCodigoVerif] = useState<any>({
    dados: { // setados como true inicialmente para realizar o 1º envio com sucesso
      emailEnviado: { sucessoEnvio: true },
      smsEnviado: { sucessoEnvio: true }
    },
    enviado: false,
    validado: false
  })
  const [faturas, setFaturas] = useState<any>([])
  const [modalInvoice, setModalInvoice] = useState<boolean>(false)
  const [invoices, setInvoices] = useState<any>([])
  const [invoiceSelected, setInvoiceSelected] = useState<any>('about:blank')

  useEffect(() => {
    if (c?.interface)
      Object.keys(c?.interface).forEach((key) => {
        if (key?.includes('URL') && handleURL(c?.interface?.[key] || '') == handleURL(href))
          setTitleName(c.interface[key.replace('URL', 'Nome')])
      })

    sessionStorage.removeItem('cronoCodigoVerif')
  }, [])

  const getCacheCrono = () => {
    let crono = parseInt(sessionStorage.getItem('cronoCodigoVerif') || '0')
    return crono > 0 ? crono : 0
  }

  const handleGetCode = async () => {
    if (!validateBr.cpf(dadosProponente.documento))
      return message.error('CPF inválido')

    else if (dadosProponente.celular.trim().length != 15 && dadosProponente.celular.trim() != '')
      return message.error('Celular inválido')

    else if (!validateBr.email(dadosProponente.email) && dadosProponente.email.trim() != '')
      return message.error('Email inválido')

    else
      try {
        setLoading({ status: true, message: 'Enviando código de validação...' })

        const dadosProponenteTemp: any = {
          documento: dadosProponente.documento,
          // ifs abaixos úteis ao reenviar o código de verificação (2º envio, 3º envio...)
          celular: codigoVerif.dados.smsEnviado.sucessoEnvio ? dadosProponente.celular : '',
          email: codigoVerif.dados.emailEnviado.sucessoEnvio ? dadosProponente.email : ''
        }

        let { data }: any = await api.post('/Notificacao/ObterCodigoValidacaoPorDadosProponente', {
          ...dadosProponenteTemp, guidInstituicao: (instituicao || sessionStorage.getItem('b2e:instituicao'))
        })

        if (data.emailEnviado.sucessoEnvio == false && data.smsEnviado.sucessoEnvio == false)
          throw new Error()

        setCodigoVerif({ dados: data, enviado: true, validado: false })
        setShowForm({ form1: false, form2: true })

        // motivo ifs: caso o campo nem seja preenchido, não exibir mensagem de sucesso/erro
        if (dadosProponenteTemp.email.trim())
          if (data.emailEnviado.sucessoEnvio)
            message.success('Código de validação enviado com sucesso para o email informado', 6)
          else message.error('Não foi possível enviar o código de validação para o email informado', 6)

        if (dadosProponenteTemp.celular.trim())
          if (data.smsEnviado.sucessoEnvio)
            message.success('Código de validação enviado com sucesso para o celular informado', 6)
          else message.error('Não foi possível enviar o código de validação para o celular informado', 6)

      } catch (error) {
        message.error('Não existem propostas com os dados informados, tente novamente')
      } finally {
        setLoading({ status: false, message: '' })
      }
  }

  const resetEnvioCodigoVerif = () => {
    setCodigoVerif({ ...codigoVerif, enviado: false, validado: false })
    sessionStorage.removeItem('cronoCodigoVerif')
  }

  const validarCodigoVerif = async (value: any) => {
    try {
      setLoading({ status: true, message: 'Validando código de verificação...' })
      let { data }: any = await api.post(`/Notificacao/ValidarCodigoPorDadosProponente?codigoValidacao=${value}`, {
        ...dadosProponente, guidInstituicao: (instituicao || sessionStorage.getItem('b2e:instituicao'))
      })

      if (data.sucesso == false)
        throw new Error()
      else {
        message.success('Código de verificação validado com sucesso')
        setCodigoVerif({ ...codigoVerif, validado: true })
        setShowForm({ form1: false, form2: false, form3: true })
        getFaturas()
      }
    } catch (error) {
      message.error('Erro ao validar código de verificação, tente novamente')
      setLoading({ status: false, message: '' })
    }
  }

  const getFaturas = async () => {
    try {
      setLoading({ status: true, message: 'Obtendo Faturas...' })
      let { data }: any = await api.post(`/Faturamento/ObterFaturasPorDadosProponente`, {
        ...dadosProponente, guidInstituicao: (instituicao || sessionStorage.getItem('b2e:instituicao'))
      })

      if (data.length == 0)
        throw new Error()
      else {
        setFaturas(data)
        setShowForm({ form1: false, form2: false, form3: true })
        setLoading({ status: false, message: '' })
      }
    } catch (error) {
      setLoading({ status: false, message: '' })
      message.error('Erro ao obter faturas, ou nenhum resultado encontrado, tente novamente')
      setShowForm({ form1: true, form2: false, form3: false })
    }
  }

  return <div className='d-flex justify-content-center'>
    <ModalLoading open={loading.status} message={loading.message} />
    <div className='bg-white pl-3 pb-3 pr-3 mb-3' style={{ width: '100%', maxWidth: '400px' }}>
      <SectionTitle title={titleName} />
      {showForm.form1 && codigoVerif.enviado == false ?
        <>
          <h3>Insira os dados referente a fatura desejada:</h3>
          <div className='input-wrapper'>
            <label>CPF</label>
            <ReactInputMask mask="999.999.999-99" maskChar={''}
              value={dadosProponente.documento} placeholder='Digite seu CPF'
              onChange={({ target: { value } }) => setDadosProponente({ ...dadosProponente, documento: value })}
            />
          </div>
          <div className='input-wrapper my-2'>
            <label>Celular</label>
            <ReactInputMask mask="(99) 99999-9999" maskChar={''}
              value={dadosProponente.celular} placeholder='Digite seu celular'
              onChange={({ target: { value } }) => setDadosProponente({ ...dadosProponente, celular: value })}
            />
          </div>
          <div className='input-wrapper'>
            <label>E-mail</label>
            <Input value={dadosProponente.email} placeholder='Digite seu e-mail'
              onChange={({ target: { value: v } }) =>
                setDadosProponente({ ...dadosProponente, email: `${v}`?.replace(/[^a-zA-Z0-9@._-]/g, '') })} />
          </div>
          <div className='float-right mt-2'>
            <Button type="primary" icon={<ArrowRightOutlined />} onClick={handleGetCode}>
              Continuar
            </Button>
          </div>
        </>
        : showForm.form2 ?
          <>
            {codigoVerif.enviado ? <h3>Confirme o código de verificação</h3> : <h3>Clique abaixo para enviar o código de verificação:</h3>}
            {codigoVerif.enviado && !codigoVerif.validado ?
              <div className="mt-1">
                <span style={{ color: 'red' }}>Código de verificação expira em: </span>
                <Countdown format="mm:ss" value={getCacheCrono() ?
                  Date.now() + getCacheCrono() || '0' : Date.now() + 60000}
                  onChange={(v: any) => { sessionStorage.setItem('cronoCodigoVerif', v) }}
                  onFinish={() => {
                    if (codigoVerif.validado) return
                    resetEnvioCodigoVerif()
                    message.error('Código expirado, solicite um novo envio')
                  }} />

                <VerificationInput autoFocus validChars='0-9' onComplete={(v) => validarCodigoVerif(v)} />
              </div>
              :
              <div className="mt-1">
                {codigoVerif.dados.emailEnviado.sucessoEnvio && <a onClick={handleGetCode}>
                  <i className="fa fa-envelope mr-2" />Enviar código por E-mail</a>}
                <br />
                {codigoVerif.dados.smsEnviado.sucessoEnvio && <a onClick={handleGetCode}>
                  <i className="fa fa-sms mr-2" />Enviar código por SMS</a>}
              </div>}
          </>
          : // showForm.form3 ?
          <>
            {faturas.length ? <h3>Clique para obter detalhes da fatura:</h3> : null}
            {faturas?.map((obj: any) =>
              <div className='bg-light p-2 mt-2 row' style={{ cursor: 'pointer' }} key={obj.codigoProposta}>
                <div className='col-md-3'>
                  <i className="fas fa-file-invoice mr-2" style={{ fontSize: '6rem' }} />
                </div>
                <div className='col-md-9'
                  onClick={async () => {
                    try {
                      setLoading({ status: true, message: 'Carregando faturas...' });
                      let { data: obterFaturas }: any = await api.get(`Faturamento/ObterFaturasPorCodigoProposta?codigoProposta=${obj.codigoProposta}`);
                      if (obterFaturas.length > 0) {
                        setInvoices(obterFaturas);
                        setModalInvoice(true);

                      } else {// se não houver faturas, gerar:
                        setLoading({ status: true, message: 'Gerando faturas...' });
                        let { data: gerarFatura }: any = await api.post(`Proposta/GerarFaturaIugu?guid=${sessionStorage.getItem('b2e:instituicao')}&codigoProposta=${obj.codigoProposta}&formasPagamento=${f.find((f: any) => f.id == obj.formaPagamento)?.ref || ''}`);

                        if (gerarFatura.length == 0)
                          throw new Error();

                        setInvoices(gerarFatura);
                        setModalInvoice(true);
                      }

                    } catch (error) {
                      console.log("error: ", error);
                      message.error('Não foram encontradas faturas para essa proposta');
                    } finally {
                      setLoading({ ...loading, status: false, });
                    }
                  }}>
                  <p>Código: {obj.codigoProposta}</p>
                  <p>Data Emissão: {obj.dataProposta.split('T')[0].split('-').reverse().join('/')} {obj.dataProposta.split('T')[1].split('.')[0]}</p>
                  <p>Valor Total: R$ {obj.valorSolicitado.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</p>
                </div>
              </div>
            )}

            {/* faturas.length ? <div className='mt-2'>
              <Button type="primary" icon={<ArrowLeftOutlined />}
                onClick={() => {
                  setShowForm({ form1: true, form2: false, form3: false })
                  setCodigoVerif({ ...codigoVerif, enviado: false, validado: false })
                  setFaturas([])
                  setInvoices([])
                }}>
                Voltar
              </Button>
            </div> : null */}
          </>
      }
    </div>
    <ModalPagamentoIugu invoices={invoices} modalInvoice={modalInvoice} setModalInvoice={setModalInvoice} />
  </div>
}