import { createGlobalStyle } from 'styled-components';

import { base } from './inspinia/base';
import { navigation } from './inspinia/navigation';
import { media } from './inspinia/media';
import { buttons } from './inspinia/buttons';
import { custom } from './inspinia/custom';
import { metismenu } from './inspinia/metismenu';
import { tipography } from './inspinia/tipography';
import { elements } from './inspinia/elements';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Roboto:wght@300;400;700&display=swap');

${tipography};
${navigation};
${buttons};
${elements};
${base};
${metismenu};
${media};
${custom};

@media print {
  nav.navbar-static-side {
    display: none;
  }

  body {
    overflow: visible !important;
  }

  #page-wrapper {
    margin: 0;
  }
}
`;
