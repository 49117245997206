import { FC, useContext } from 'react';
import { EtapaContext } from '@contexts/EtapaContext';

import BlocoCamposProposta from './BlocoCamposProposta';
import BlocoCamposPropostaTabs from './BlocoCamposPropostaTabs';
import { BlocoAvalista } from '@constants/BlocoAvalista';

const Avalista: FC = () => {
  const { errorEtapaPessoa, etapaIdx } = useContext(EtapaContext);
  ////console.log("BlocoPF: ", BlocoPF);

  return (
    <>
      {errorEtapaPessoa && errorEtapaPessoa[etapaIdx] && (
        <div className="form-row">
          <div className="col-lg-12">
            <small className="error">
              {(errorEtapaPessoa[etapaIdx] as any).campos}
            </small>
          </div>
        </div>
      )}

      {BlocoAvalista.map((bloco) => {
        if (['Referências Bancárias', 'Referências Pessoais'].includes(bloco.title))
          return (<>
            <BlocoCamposPropostaTabs title={bloco.title} camposArray={bloco.campos} />
            <hr />
          </>)

        return (<>
          <BlocoCamposProposta title={bloco.title} camposArray={bloco.campos} />
          <hr />
        </>)
      })}
    </>
  );
};

export default Avalista;