import {
  camposIdentificacao,
  camposProposta, camposSocios,
} from '@pages/Configuracao/camposPessoaJuridica';

export const BlocoPJ = [
  {
    title: 'Proposta',
    campos: camposProposta,
  },
  {
    title: 'Identificação',
    campos: camposIdentificacao,
  },
  // {
  //   title: 'Endereço de Cobrança',
  //   campos: camposEnderecoCobranca,
  // },
  // {
  //   title: 'Endereço de Entrega',
  //   campos: camposEnderecoEntrega,
  // },
   /* {
     title: 'Sócios',
     campos: camposSocios,
   }, */
  // {
  //   title: 'Dados Comerciais',
  //   campos: camposDadosComerciais,
  // },
  // {
  //   title: 'Referências bancárias',
  //   campos: camposReferenciasBancarias,
  // },
  // {
  //   title: 'Referências comerciais',
  //   campos: camposReferenciasComerciais,
  // },
];
