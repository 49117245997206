import { useContext } from 'react';
import { GlobalContext } from '@contexts/GlobalContext';

type Option = {
  id: string | number;
  descricao: string;
};

type Campo = {
  [field: string]: Option[];
};

const Options = (campo: string) => {
  const {
    regimeTributario,
    bancos,
    modalidades,
    formasPagamento,
    estadosCivil,
    comprovantes,
    nacionalidades,
    naturezasOcupacao,
    profissoes,
    sexos,
    parentescos,
    tipoRelacao,
  } = useContext(GlobalContext);

  const renderOptions: Campo = {
    'regime-tributario': regimeTributario,
    'banco-referencia-1': bancos,
    'banco-referencia-2': bancos,
    'forma-pagamento': formasPagamento,
    'estado-civil': estadosCivil,
    'natureza-ocupacao': naturezasOcupacao,
    'comprovante-pf': comprovantes,
    'grau-parentesco-1': parentescos,
    'grau-parentesco-2': parentescos,
    'tipo-relacao-1': tipoRelacao,
    'tipo-relacao-2': tipoRelacao,
    nacionalidade: nacionalidades,
    sexo: sexos,
    profissao: profissoes,
    modalidade: modalidades,
  };

  return renderOptions[campo];
};

export default Options;
