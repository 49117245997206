/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useParams } from 'react-router-dom';
import Icon from '../../Icon';

import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@contexts/GlobalContext';
import { Tooltip, message } from 'antd';

// @ts-ignore
import { BlipChat } from "blip-chat-widget";
import api from '@services/api';
var blipClient = new BlipChat()

const Navbar = () => {
  const { pathname: url, href } = window.location;
  const { logo, configuracoes: c } = useContext(GlobalContext);
  const { subdominio } = useParams<{ subdominio: string }>();
  const [propostaPendente, setPropostaPendente] = useState(false);

  const handlePropostaPendente = () => window.location.href = subdominio ? `/${subdominio}/cadastrar` : `/cadastrar`;
  const getPropostaPendente = async () => {
    try {
      let { status } = await api.get(`/ProcessoProposta/ObterPropostaProcesso/${sessionStorage.getItem('b2e:instituicao')}`);
      if (status === 200) setPropostaPendente(true);
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    if (!href.includes('cadastrar') && !propostaPendente)
      getPropostaPendente()
  }, [href]);

  const startChat = async () => { // é necessário ter subdomínio na url para o chat funcionar
    if (document.getElementById('blip-chat-container')) return; // se o chat já estiver carregado, não carrega novamente
    if (c?.interface?.takeBlip?.habilitar && !['configuracao', 'follow-up', 'cadastrar'].includes(url.split('/')[1]))
      try {
        await blipClient
          .withCustomStyle(`body .bubble.right, body #blip-chat-header, body #blip-send-message { background-color: ${c?.interface?.corPrimaria || '#891423'} !important; }`)
          .withAppKey(c?.interface?.takeBlip?.appKey)
          .withButton({ color: c?.interface?.corPrimaria || '#891423' })
          .withCustomCommonUrl(c?.interface?.takeBlip?.commonUrl)
          .withAuth({
            authType: BlipChat.DEV_AUTH,
            userIdentity: sessionStorage.getItem('loginLogged') || 'Logado',
            userPassword: sessionStorage.getItem('loginLogged') || 'Logado',
          })
          .build()

      } catch (error) {
        console.log(error)
      }
  }

  useEffect(() => { startChat() }, [c]);

  return <div className="row border-bottom">
    <nav className="navbar navbar-static-top white-bg mb-0">
      <div className="navbar-header d-flex align-items-center">
        <a id="menu-toggle" href="#"
          className="navbar-minimalize minimalize-styl-2 btn btn-primary my-2"
          style={{ backgroundColor: c?.interface?.corPrimaria || '#8B2622' }}>
          <i className="fa fa-bars" />
        </a>
        {propostaPendente && <Tooltip title={<div>Você possui uma proposta não enviada,
          <b style={{ cursor: 'pointer' }} onClick={handlePropostaPendente}> clique aqui </b>
          para continuar..</div>}>
          <div style={{ cursor: 'pointer' }} className="spinner-grow mr-1" onClick={handlePropostaPendente} />
        </Tooltip>}
      </div>
      <ul className="nav navbar-top-links navbar-right">
        <li className="logout-menu d-flex align-items-center">
          <Tooltip title={sessionStorage.getItem('loginLogged') || 'Logado'} placement="left">
            <i className="fa fa-user-circle-o" style={{ fontSize: '1.2rem' }} />
          </Tooltip>
          <div style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.clear();
              localStorage.clear();
              window.location.href = `/${subdominio ? subdominio : 'login'}`;
            }}>
            <div className='mx-2'>SAIR</div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
};

export default Navbar;
