import { FC } from 'react';
import { ModalProps } from 'antd';
import Spinner from '@components/Spinner/PreAnalise';

import ModalBase from '..';

import './styles.scss';

interface ModalLoadingProps extends ModalProps {
  message?: string;
}

const ModalLoading: FC<ModalLoadingProps> = ({ message, ...props }) => {
  return (
    <ModalBase centered footer={null} closable={false} width={475} {...props}>
      <div className="modal-loading">
        <Spinner />
        <p>{message}</p>
      </div>
    </ModalBase>
  );
};

export default ModalLoading;
