import { FC } from 'react';

type Props = {
  title?: string;
};

const Card = ({ title, children }: any) => {
  return (
    <div className="ibox float-e-margins">
      {title && (
        <div className="ibox-title">
          <h5>{title}</h5>
        </div>
      )}
      <div className="ibox-content">{children}</div>
    </div>
  );
};

export default Card;