import React, { useState, useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';

import { Switch, Option as OptionAntd } from '@components/Form';
import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { EtapaContext } from '@contexts/EtapaContext';
import { Modal, Select, Tooltip, message } from 'antd';
import api from '@services/api';

type BlocoCamposPropostaProps = { camposArray: any; title: string; };

const BlocoCamposProposta: React.FC<BlocoCamposPropostaProps> = ({
  camposArray,
  title,
}) => {
  const { etapaPessoa, tipoPessoa, etapaIdx } = useContext(EtapaContext);

  const { setFieldValue, values } = useFormikContext<ConfigTypes>();
  const [camposHabilitados, setCamposHabilitados] = useState<Boolean[]>([]);
  const [camposRequeridos, setCamposRequeridos] = useState<Boolean[]>([]);

  const habilitarCampo = (habilitar: boolean, field: Campo) => {
    setFieldValue(
      `etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field)}`,
      {
        ...field,
        habilitar,
      },
    );
  };

  const requererCampo = (obrigatorio: boolean, field: Campo) => {
    setFieldValue(
      `etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field)}.obrigatorio`,
      obrigatorio,
    );
  };

  const getIndex = (field: Campo) =>
    etapaPessoa[etapaIdx]?.campos.findIndex((el) => el.name === field.name);

  const selectAll = (habilitar: boolean) => {
    camposArray.forEach((campo: Campo) => habilitarCampo(habilitar, campo));
  };

  const requireAll = (obrigatorio: boolean) => {
    camposArray.forEach((campo: Campo) => requererCampo(obrigatorio, campo));
  };

  const [integracoes, setIntegracoes]: any = useState([]);
  const loadIntegracoes = async () => {
    try {
      const { data }: any = await api.get(`Integracao/ObterTiposIntegracao?instituicao=${sessionStorage.getItem('b2e:instituicao')}`);
      setIntegracoes(data);
    } catch (error) {
      console.log("error: ", error);
    }
  }

  useEffect(() => {
    const camposNomes = camposArray.map((campo: Campo) => campo.name);
    const camposHabilitados = etapaPessoa[etapaIdx]?.campos
      .filter((campo: Campo) => camposNomes.includes(campo.name))
      .map((campo: Campo) => campo.habilitar);

    const camposRequeridos = etapaPessoa[etapaIdx]?.campos
      .filter((campo: Campo) => camposNomes.includes(campo.name))
      .map((campo: Campo) => campo.obrigatorio);

    setCamposHabilitados(camposHabilitados);
    setCamposRequeridos(camposRequeridos);
  }, [values.etapas]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <h3>{title}</h3>

        </div>
        <div className="col-lg-3">
          <Switch
            name="habilitarTodos"
            checked={camposHabilitados.every((el) => el)}
            label="Selecionar Todos"
            onChange={(value) => selectAll(value)}
          />
        </div>
        <div className="col-lg-3 row">
          <Switch
            name="obrigarTodos"
            checked={camposRequeridos.every((el) => el)}
            label="Requerer Todos"
            disabled={!camposHabilitados.every((el) => el)}
            onChange={(value) => requireAll(value)}
          />
        </div>
      </div>
      {camposArray.map((field: Campo, index: number) => {
        //console.log(camposArray);

        return <div className="form-row" key={index}>
          <div className="col-lg-6">
            <label>{field.label}</label>
            {field.name === 'cnpj' &&
              ['A9E81968-FC85-4536-9AA4-567DB69611A8',
                'C15BC82F-EB61-40F3-A583-9C9497FDEFE2',
                '14859885-3C16-4D0D-89A5-8D898F639358']
                .includes(sessionStorage.getItem('b2e:instituicao') || '') &&
              <Tooltip placement='right' title="Autopreencher dados da empresa digitada">
                <i className='ml-3 fa fa-cog' onClick={async () => {
                  await loadIntegracoes();

                  Modal.info({
                    title: 'Selecione o tipo de integração:',
                    content: <Select className="w-100"
                      showSearch optionFilterProp="children"
                      placeholder="Selecione uma opção"
                      defaultValue={etapaPessoa[etapaIdx]?.integracao}
                      onChange={(value: any) => {
                        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.integracao`, value);
                      }}>
                      <OptionAntd value={0} key={0}>Selecione uma opção</OptionAntd>
                      {integracoes.map((m: any) =>
                        <OptionAntd value={m.id} key={m.id}>{m.descricao}</OptionAntd>)}
                    </Select>
                  })
                }} />
              </Tooltip>}
          </div>
          <div className="col-lg-3">
            <Switch label="Habilitar"
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field,)}.habilitar`}
              checked={etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.habilitar}
              onChange={(value) => habilitarCampo(value, field)} />
          </div>
          <div className="col-lg-3 row">
            <Switch label="Obrigatório"
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${getIndex(field,)}.obrigatorio`}
              checked={etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.obrigatorio}
              disabled={!etapaPessoa[etapaIdx]?.campos[getIndex(field)]?.habilitar}
              onChange={(value) => {
                // código ibge n pode ser obrigatório, pois este dado só tem na api do viaCep, se ela falhar trava a proposta
                if (value == true && field.name.includes('codigo-ibge'))
                  return message.warn('O campo Código IBGE não pode ser obrigatório.');

                requererCampo(value, field)
              }} />
          </div>
        </div>
      })}
    </>
  );
};

export default BlocoCamposProposta;