import { FC, useContext } from 'react';

import BlocoCamposProposta from './BlocoCamposProposta';
import { EtapaContext } from '@contexts/EtapaContext';
import { camposEnderecoComercial } from '@pages/Configuracao/camposPessoaJuridica';

const EnderecoComercial: FC = () => {
  const { errorEtapaPessoa, etapaIdx } = useContext(EtapaContext);

  return (
    <>
      {errorEtapaPessoa && errorEtapaPessoa[etapaIdx] && (
        <div className="form-row">
          <div className="col-lg-12">
            <small className="error">
              {(errorEtapaPessoa[etapaIdx] as any).campos}
            </small>
          </div>
        </div>
      )}

      <BlocoCamposProposta
        title="Endereço Comercial"
        camposArray={camposEnderecoComercial}
      />
    </>
  );
};

export default EnderecoComercial;
