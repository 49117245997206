type ValueType = {
  [value: string]: any;
};

export const initialValues: ValueType = {
  'codigo-instituicao': '',
  data: '',
  'codigo-proposta': '',
  // 'valor-solicitado': 0,
  // 'temp-valor-solicitado': 0,
  // modalidade: '',
  // 'categoria-1': '',
  // 'forma-pagamento': '',
  // cpf: '',
  // nome: '',
  // rg: '',
  // 'estado-civil': '',
  // nacionalidade: '',
  // email: '',
  // cnh: '',
  // numero_serguranca_cnh: '',
  // 'dt-nascimento': '',
  // sexo: '',
  // 'nome-mae': '',
  // 'fone-residencial': '',
  // 'fone-celular': '',
  // 'cep-pf': '',
  // 'logradouro-pf': '',
  // 'num-pf': '',
  // 'complemento-pf': '',
  // 'uf-pf': '',
  // 'cidade-pf': '',
  // 'bairro-pf': '',
  // conjuge: '',
  // 'cpf-conjuge': '',
  // 'natureza-ocupacao': '',
  // profissao: '',
  // 'data-admissao': '',
  'renda-mensal': "0",
  'temp-rendaMensal': "0",
  // 'comprovante-pf': '',
  // 'empresa-pf': '',
  // 'cnpj-pf': '',
  // 'tel-dadoscomerciais-pf': '',
  // 'ramal-dadoscomerciais-pf': '',
  // rendaMensalAdicional: 0,
  // 'temp-rendaMensalAdicional': 0,
  // comprovanteAdicional: '',
  // 'cep-dadoscomerciais-pf': '',
  // 'logradouro-dadoscomerciais-pf': '',
  // 'num-dadoscomerciais-pf': '',
  // 'complemento-dadoscomerciais-pf': '',
  // 'uf-dadoscomerciais-pf': '',
  // 'cidade-dadoscomerciais-pf': '',
  // 'bairro-dadoscomerciais-pf': '',
  // 'banco-referencia-1': '',
  'agencia-referencia-1': '',
  'conta-referencia-1': '',
  // 'desde-referencia-1': '',
  // 'banco-referencia-2': '',
  // 'agencia-referencia-2': '',
  // 'conta-referencia-2': '',
  // 'desde-referencia-2': '',
  // 'nome-referencia-1': '',
  // 'tel-referencia-1': '',
  // 'grau-parentesco-1': '',
  // 'nome-referencia-2': '',
  // 'tel-referencia-2': '',
  // 'grau-parentesco-2': '',
  // parecer: '','
  'socio-nome': '',
  'socio-percentual': '',
  acessoBioId: '',
  path_arquivos: '',
  'cep-entrega-pj': '',
  'logradouro-entrega-pj': '',
  'bairro-entrega-pj': '',
  'complemento-entrega-pj': '',
  'codigo-ibge-entrega-pj': '',
  'num-entrega-pj': '',
  'cidade-entrega-pj': '',
  'uf-entrega-pj': '',
  'cep-cobranca-pj': '',
  'logradouro-cobranca-pj': '',
  'bairro-cobranca-pj': '',
  'complemento-cobranca-pj': '',
  'codigo-ibge-cobranca-pj': '',
  'num-cobranca-pj': '',
  'cidade-cobranca-pj': '',
  'uf-cobranca-pj': '',
};

export default initialValues;
