import { css } from 'styled-components';

export const metismenu = css`
  .metismenu .plus-minus,
  .metismenu .plus-times {
    float: right;
  }

  .metismenu .arrow {
    float: right;
    line-height: 1.42857;
  }

  .metismenu .glyphicon.arrow:before {
    content: '\e079';
  }

  .metismenu .active > a > .glyphicon.arrow:before {
    content: '\e114';
  }

  .metismenu .fa.arrow:before {
    content: '\f104';
  }

  .metismenu .active > a > .fa.arrow:before {
    content: '\f107';
  }

  .metismenu .ion.arrow:before {
    content: '\f3d2';
  }

  .metismenu .active > a > .ion.arrow:before {
    content: '\f3d0';
  }

  .metismenu .fa.plus-minus:before,
  .metismenu .fa.plus-times:before {
    content: '\f067';
  }

  .metismenu .active > a > .fa.plus-times {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .metismenu .active > a > .fa.plus-minus:before {
    content: '\f068';
  }

  .metismenu .collapse {
    display: none;
  }

  .metismenu .collapse.in {
    display: block;
  }

  .metismenu .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
  }

  .mini-navbar {
    .metismenu .collapse {
      opacity: 0;
    }
    .metismenu .collapse.in {
      opacity: 1;
    }
    .metismenu .collapse a {
      display: none;
    }
    .metismenu .collapse.in a {
      display: block;
    }
  }
`;
