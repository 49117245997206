import { FC, useContext } from 'react';
import { Divider, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';

import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { Input, Select, Option, Switch } from '@components/Form';

import { EtapaContext } from '@contexts/EtapaContext';

import PessoaFisica from './pessoaFisica';
import EtapaAdicional from './etapaAdicional';
import Parcelamento from './parcelamento';
import Produto from './produto';
import Avalista from './avalista';
import CondicoesTermos from './condicoesTermos';
import UploadArquivos from './uploadArquivos';
import EtapaInformativa from './etapaInformativa';
import Integracao from './integracao';
import CodigoVerif from './codigoVerif';
import PessoaJuridica from './pessoaJuridica';
import { tiposEtapa } from '@constants/TiposEtapa';
import camposIntegracao, { mockCamposReferenciasBancarias, } from '@pages/Configuracao/camposIntegracao';
import camposAvalistaIntegracao from '@pages/Configuracao/camposAvalistaIntegracao';
import {
  camposDadosComerciais,
  camposEnderecoComercial,
  camposEnderecoEntrega,
  camposPessoaJuridica,
  camposReferenciasComerciais,
  camposSocios,
} from '@pages/Configuracao/camposPessoaJuridica';
import Socios from './Socios';
import EnderecoCobranca from './EnderecoCobranca';
import DadosComerciaisPJ from './DadosComerciaisPJ';
import ReferenciasBancariasPJ from './ReferenciasBancariasPJ';
import ReferenciasComerciais from './ReferenciasComerciais';
import EnderecoEntrega from './EnderecoEntrega';

type TipoEtapa = { [tipoEtapa: number]: JSX.Element; };

const AddEditEtapa: FC = () => {
  const { tipoPessoa, etapaPessoa, etapa, etapaIdx } = useContext(EtapaContext);

  const textoBotaoEnvio = 'Permite habilitar o envio dos dados de cadastro na etapa em que esse botão estiver ativo.';

  const { setFieldValue } = useFormikContext<ConfigTypes>();

  const renderTiposEtapa: TipoEtapa = {
    0: <PessoaFisica />,
    1: <Integracao />,
    2: <EtapaAdicional />,
    3: <UploadArquivos />,
    4: <PessoaJuridica />,
    5: <Parcelamento />,
    6: <CondicoesTermos />,
    7: <CodigoVerif />,
    8: <Produto />,
    9: <Avalista />,
    15: <EtapaInformativa />,
    41: <EnderecoCobranca />,
    42: <EnderecoEntrega />,
    43: <DadosComerciaisPJ />,
    44: <Socios />,
    45: <ReferenciasBancariasPJ />,
    46: <ReferenciasComerciais />,
  };

  const handleChangeTipoEtapa = (value: string | number) => {
    switch (+value) {
      case 0: // Pessoa Física
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposIntegracao,);
        break;
      case 9: // Avalista
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposAvalistaIntegracao,);
        break;
      case 4:  // Pessoa Jurídica
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposPessoaJuridica,);
        break;
      case 41: // Endereço de Cobrança
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposEnderecoComercial,);
        break;
      case 42: // Endereço de Entrega
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposEnderecoEntrega,);
        break;
      case 43: // Dados Comerciais PJ
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposDadosComerciais,);
        break;
      case 44:  // Sócios
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposSocios);
        ////console.log("camposSocios: ", camposSocios);
        break;
      case 45: // Referências Bancárias PJ
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, mockCamposReferenciasBancarias,);
        break;
      case 46: // Referências Comerciais
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, camposReferenciasComerciais,);
        break;
      default:
        setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, []);
    }

    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.tipo`, value);
  };

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <Input name={`etapas.${tipoPessoa}.${etapaIdx}.nome`} label="Nome da etapa" />
        </div>
        <div className="col-lg-6" id='tipoEtapa'>
          <Select showSearch optionFilterProp="children"
            name={`etapas.${tipoPessoa}.${etapaIdx}.tipo`}
            placeholder="Selecione o tipo de etapa"
            onChange={handleChangeTipoEtapa} label="Tipo">
            {tiposEtapa
              .filter((tipoEtapa) =>
                ['pf', 'dlinkpf'].includes(tipoPessoa)
                  ? tipoEtapa.id !== 4 && tipoEtapa.id < 41
                  : tipoEtapa.id !== 0 && tipoEtapa.id !== 7 && tipoEtapa.id !== 9)
              .map((tipoEtapa) => (
                <Option key={tipoEtapa.id} value={tipoEtapa.id}>
                  {tipoEtapa.nome}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Tooltip placement="topLeft" title={textoBotaoEnvio}>
            <Switch
              name={`etapas.${tipoPessoa}.${etapa}.botaoEnvio`}
              checked={etapaPessoa[etapaIdx]?.botaoEnvio}
              label="Habilitar Envio"
              onChange={(value) => {
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.botaoEnvio`, value);

                if (value) return;

                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}['Pre-Analise']`, false);
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}['Aguardar-Resposta']`, false);
              }} />
          </Tooltip>
        </div>
        <div className="col-lg-auto">
          <Switch
            name={`etapas.${tipoPessoa}.${etapa}['Pre-Analise']`}
            checked={etapaPessoa[etapaIdx]?.['Pre-Analise']}
            label="Pré Análise" disabled={!etapaPessoa[etapaIdx]?.botaoEnvio}
            onChange={(value) =>
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}['Pre-Analise']`, value)
            } />
        </div>
        <div className="ml-auto mr-4">
          <Switch
            name={`etapas.${tipoPessoa}.${etapa}['Aguardar-Resposta']`}
            checked={etapaPessoa[etapaIdx]?.['Aguardar-Resposta']}
            label="Aguardar Resposta" disabled={!etapaPessoa[etapaIdx]?.botaoEnvio}
            onChange={(value) =>
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}['Aguardar-Resposta']`, value)
            } />
        </div>
      </div>

      <Divider />

      <ReactPerfectScrollbar options={{ suppressScrollX: true }}>
        <div style={{ height: 280 }}>
          {renderTiposEtapa[etapaPessoa[etapaIdx]?.tipo]}
        </div>
      </ReactPerfectScrollbar>
    </>
  );
};

export default AddEditEtapa;
