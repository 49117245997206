import { FC } from 'react';
import { ModalProps } from 'antd';
import { useFormikContext } from 'formik';
import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';

import ModalBase from '..';

import './styles.scss';

const tabsLabel: { [field: string]: string } = {
  instituicao: 'Instituição',
  interface: 'Interface',
  etapas: 'Etapas',
  followUp: 'Follow UP',
};

interface Props extends ModalProps {
  register?: boolean;
}

const ModalValidacao: FC<Props> = ({ register, ...props }) => {
  const { errors } = useFormikContext<ConfigTypes>();
  const title = register
    ? 'Campos pendentes ou inválidos:'
    : 'Abas com campos pendentes ou inválidos:';

  return (
    <ModalBase centered footer={null} width={475} {...props}>
      <div className="modal-validacao">
        <h3>{title}</h3>

        {register //asc sort
          ? Object.values(errors)?.sort()?.map((error: any, index) => (
            <p style={{ color: '#800000' }} key={index}>{error}</p>
          ))
          : Object.keys(errors)?.sort()?.map((error: any, index) => (
            <h4 style={{ color: '#800000' }} key={index}>{tabsLabel[error]}</h4>
          ))}
      </div>
    </ModalBase>
  );
};

export default ModalValidacao;
