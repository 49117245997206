import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from 'antd';
import { DatePicker, Input, Select, Option } from '@components/Form';
import { status } from '@services/mock';
import { GlobalContext } from '@contexts/GlobalContext';
import Icon from '@components/Icon';
import { useContext } from 'react';
import { capitalizeAllWords } from '@utils/initCaps';
import ReactIntlCurrencyInput from 'react-intl-currency-input';
import { currencyConfig } from '@pages/Cadastrar/currencyConfig';

type FilterOption = {
  label: string;
  name: string;
};

type FilterProps = {
  filterOptions: FilterOption[];
  resetFollowUp: Function;
  filter: any;
  setFilter: Function;
};

const Filter = ({
  filterOptions,
  resetFollowUp,
  filter,
  setFilter
}: FilterProps) => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: (data: any) => {
      data = { ...data, status: +data.status, };

      if ('data-solicitacao-inicio' in data)
        data['data-solicitacao-inicio'] = data['data-solicitacao-inicio'].format('YYYY-MM-DDT00:00:00') + '.000Z';

      if ('data-solicitacao-fim' in data)
        data['data-solicitacao-fim'] = data['data-solicitacao-fim'].format('YYYY-MM-DDT23:59:59') + '.000Z';

      setFilter({ ...filter, ...data });
    },
  });

  const { setFieldValue, setValues } = formik;
  const { pareceresInstituicao } = useContext(GlobalContext);

  return (
    <div className="row mb-3">
      <div className="col-lg-12">
        <FormikProvider value={formik}>
          <Form>
            <div className="form-row">
              <div className="col-lg-3">
                <Input name="codigo-proposta" label="Código da proposta" placeholder="Código da proposta" />
              </div>
              <div className="col-lg-3">
                <DatePicker
                  name="data-solicitacao-inicio"
                  label="Data de solicitação Início"
                  placeholder="11/11/1111"
                  suffixIcon={<Icon name="date" size={15} color="#3A434D" />}
                  onChange={(value) => setFieldValue('data-solicitacao-inicio', value)}
                />
              </div>
              <div className="col-lg-3">
                <DatePicker
                  name="data-solicitacao-fim"
                  label="Data de solicitação Fim"
                  placeholder="11/11/1111"
                  suffixIcon={<Icon name="date" size={15} color="#3A434D" />}
                  onChange={(value: any) => setFieldValue('data-solicitacao-fim', value)}
                />
              </div>
              <div className="col-lg-3">
                <Select
                  showSearch
                  optionFilterProp="children"
                  name="status"
                  label="Status"
                  placeholder="Selecione uma opção"
                  onChange={(value) => setFieldValue('status', value)}
                >
                  {pareceresInstituicao.map((option) => (
                    <Option key={option.idStatus} value={option.idStatus}>
                      {option.descricao}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="form-row">
              {Array.from(new Set(filterOptions.map(a => a.name)))
                .map(name => { return filterOptions.find(a => a.name === name) })
                .map((field: any) => {
                  if (!field.name) return <></>;
                  const label: any = capitalizeAllWords(field.name).replace(/-/g, " ") || '';

                  return <div className="input-wrapper col-lg-3" key={field.name}>
                    <label>{label}</label>
                    {['limite-sugerido', 'limite-concedido'].includes(field.name) ?
                      <ReactIntlCurrencyInput defaultValue={0}
                        /* value={parseFloat(fields[0]?.value || '1,00')} */
                        max={999999999.99} currency='BRL'
                        config={currencyConfig} autoSelect={true}
                        onBlur={(_, currentValue: number) => {
                          if (currentValue == 0)
                            setFieldValue(field.name, '');
                        }}
                        onChange={(_, currentValue: number) => {
                          setFieldValue(field.name, `${currentValue.toFixed(2)}`);
                        }} />
                      :
                      <input name={field.name} placeholder={label}
                        onChange={(e) => setFieldValue(field.name, e.target.value)} />}
                  </div>

                })}
            </div>
            <div className="form-row">
              <div className="col-lg-12 d-flex justify-content-end">
                <Button
                  type="default"
                  htmlType="reset"
                  className="mr-2"
                  onClick={() => {
                    setValues({});
                    resetFollowUp();
                  }}
                >
                  Limpar resultados
                </Button>
                <Button id='atualizar' type="primary" htmlType="submit">
                  Atualizar
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default Filter;
