import { array, lazy, number, object, string } from 'yup';

export const validationSchema = object().shape({
  instituicao: object().shape({
    codigo: string().required('Código da instituição obrigatório').nullable(),
    subdominio: string().required('Subdominio obrigatório').nullable(),
    apiProposta: object().shape({
      username: string().required('Username obrigatório').nullable(),
      password: string().required('Password obrigatório').nullable(),
    }),
  }),
  // interface: object().shape({
  //   logo: string().required('Logo obrigatória').nullable(),
  //   corPrimaria: string().required('Cor primária obrigatória').nullable(),
  //   corSecundaria: string().required('Cor secundaria obrigatória').nullable(),
  //   corBackground: string().required('Cor de fundo obrigatória').nullable(),
  // }),
  etapas: object().shape({
    pf: lazy(
      () =>
        array().of(
          object().shape({
            nome: string().required('Nome da etapa obrigatório').nullable(),
            tipo: number().required('Tipo de etapa obrigatório').nullable(),
            integracao: number().when('tipo', {
              is: (val: number) => val === 1,
              then: number().required('Selecione uma integração').nullable(),
            }),
            /*campos: array().when('tipo', {
              is: (val: number) => val !== 1 && val !== 3 && val !== 7 && val !== 8,
              then: array().min(1, 'Obrigatório selecionar pelo menos 1 campo'),
            }),*/
          }),
        ),
      // .min(1, 'É obrigatório adicionar no minimo 1 etapa'),
    ),
    pj: lazy(
      () =>
        array().of(
          object().shape({
            nome: string().required('Nome da etapa obrigatório').nullable(),
            tipo: number().required('Tipo de etapa obrigatório').nullable(),
            integracao: number().when('tipo', {
              is: (val: number) => val === 1,
              then: number().required('Selecione uma integração').nullable(),
            }),
            /*campos: array().when('tipo', {
              is: (val: number) => val !== 1 && val !== 3 && val !== 8,
              then: array().min(1, 'Obrigatório selecionar pelo menos 1 campo'),
            }),*/
          }),
        ),
      // .min(1, 'É obrigatório adicionar no minimo 1 etapa'),
    ),
  }),
  followUp: object().shape({
    camposFiltro: array().max(5, 'É permitido no máximo 5 campos'),
    colunasGrid: array().max(10, 'É permitido no máximo 10 campos'),
    // revisao: object().shape({
    //   idStatus: string().required('ID status obrigatório').nullable(),
    // }),
    // redirect: object().shape({
    //   nome: string().required('Nome do botão obrigatório').nullable(),
    //   idStatus: string().required('ID status obrigatório').nullable(),
    //   urlRota: string().required('URL rota obrigatório').nullable(),
    // }),
  }),
});
