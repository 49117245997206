import { useState, useEffect, useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Input, Option, Switch, Select, SectionTitle } from '@components/Form';
import { Bloco, Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { GlobalContext } from '@contexts/GlobalContext';
import { handleURL } from '@utils/handleURL';


export default function FollowUP() {
  const { values, setFieldValue } = useFormikContext<ConfigTypes>();
  const [fields, setFields] = useState<Campo[]>([]);
  const [tipoPessoa, setTipoPessoa] = useState('');
  const { pareceresInstituicao } = useContext(GlobalContext);
  // const loadFields = useCallback(() => {
  //   const campos: Campo[] = [];

  //   if (tipoPessoa === 'pf') {
  //     values.etapas.pf.forEach((bloco: Bloco) =>
  //       bloco.campos.forEach((campo: Campo) => {
  //         campos.push(campo);
  //       }),
  //     );
  //   }

  //   if (tipoPessoa === 'pj') {
  //     values.etapas.pj.forEach((bloco: Bloco) =>
  //       bloco.campos.forEach((campo: Campo) => {
  //         campos.push(campo);
  //       }),
  //     );
  //   }

  //   const enableFields = campos.filter((campo: Campo) => campo?.habilitar);
  //   setFields(enableFields);
  // }, [values.etapas]);

  // useEffect(() => {
  //   loadFields();
  // }, [loadFields]);
  const filterCampos = (v: string) => {
    const campos: Campo[] = [];
    if (v === 'pf') {
      values.etapas.pf.forEach((bloco: Bloco) =>
        bloco.campos.forEach((campo: Campo) => {
          campos.push(campo);
        }),
      );
    }

    if (v === 'pj') {
      values.etapas.pj.forEach((bloco: Bloco) =>
        bloco.campos.forEach((campo: Campo) => {
          campos.push(campo);
        }),
      );
    }
    const enableFields = campos.filter((campo: Campo) => campo?.habilitar);
    setFields(enableFields);
  }
  const handleChangeTipoPessoa = (value: string) => {
    setTipoPessoa(value);
    filterCampos(value);
  };
  return (
    <>
      <SectionTitle title="Follow UP" />
      <div className='select-container col-md-10 pl-0'>
        <Select showSearch optionFilterProp="children"
          name="tipoPessoa" onChange={handleChangeTipoPessoa}
          label="Selecione o tipo de onboarding"
          placeholder="Pessoa Física ou Jurídica">
          <Option value="pf">Pessoa Física</Option>
          <Option value="pj">Pessoa Jurídica</Option>
        </Select>
      </div>
      <br />
      {tipoPessoa !== '' &&
        (<div className="row">
          <div className="col-lg-12">
            <div className="form-row">
              <div className="col-lg-12">
                {tipoPessoa == 'pj' && <Switch
                  name="followUp.exibirMenu" label="Exibir no menu"
                  checked={`${values.followUp.exibirMenu}`.includes('pj')}
                  onChange={() => {
                    let vCheck = values.followUp.exibirMenu.toString().replace('true', '').replace('false', '')
                    vCheck.includes('pj') ?
                      setFieldValue('followUp.exibirMenu', vCheck.replace('pj', '')) :
                      setFieldValue('followUp.exibirMenu', `${vCheck}pj`)
                  }} />}
                {tipoPessoa == 'pf' && <Switch
                  name="followUp.exibirMenu" label="Exibir no menu"
                  checked={`${values.followUp.exibirMenu}`.includes('pf')}
                  onChange={() => {
                    let vCheck = values.followUp.exibirMenu.toString().replace('true', '').replace('false', '')
                    vCheck.includes('pf') ?
                      setFieldValue('followUp.exibirMenu', vCheck.replace('pf', '')) :
                      setFieldValue('followUp.exibirMenu', `${vCheck}pf`)
                  }} />}
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-6">
                <Select showSearch mode="multiple"
                  optionFilterProp="children"
                  name={`followUp.${tipoPessoa}.camposFiltro`}
                  label="Incluir campos no filtro"
                  placeholder="Selecione os campos"
                  onChange={(value) => setFieldValue(`followUp.${tipoPessoa}.camposFiltro`, value)}
                >
                  {[...fields, { label: 'Data de solicitação', name: 'data-solicitacao' }, { label: 'Código da proposta', name: 'codigo-proposta' }, { label: 'Status', name: 'status' }]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((field: any, index: number) => (
                      <Option key={index} value={field.name}>
                        {field.name}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="col-lg-6">
                <Select showSearch mode="multiple"
                  optionFilterProp="children"
                  name={`followUp.${tipoPessoa}.colunasGrid`}
                  label="Incluir colunas no grid"
                  placeholder="Selecione os campos"
                  onChange={(value) => setFieldValue(`followUp.${tipoPessoa}.colunasGrid`, value)}
                >
                  {[...fields, { label: 'Usuário Envio', name: 'usuario-logado' }, { label: 'Limite Sugerido', name: 'limite-sugerido' },
                  { label: 'Limite concedido', name: 'limite-concedido' }, { label: 'Data de solicitação', name: 'data-solicitacao' },
                  { label: 'Código da proposta', name: 'codigo-proposta' }, { label: 'Status', name: 'status' }, { label: 'Pagamentos', name: 'pagamentos' }]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((field: any, index: number) => (
                      <Option key={index} value={field.name}>
                        {field.name}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-12">
                <Switch name={`followUp.${tipoPessoa}.revisao.habilitar`} label="Habilitar botão revisão"
                  checked={tipoPessoa === 'pf' ? values?.followUp?.pf?.revisao?.habilitar : values?.followUp?.pj?.revisao?.habilitar}
                  onChange={(value) => setFieldValue(`followUp.${tipoPessoa}.revisao.habilitar`, value)} />
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-4">
                <Input name={`followUp.${tipoPessoa}.revisao.nome`}
                  label={`Inserir "nome" do botão`}
                  disabled={tipoPessoa === 'pf' ? !values?.followUp?.pf?.revisao?.habilitar : !values?.followUp?.pj?.revisao?.habilitar}
                />
              </div>
              <div className="col-lg-4">
                <Select showSearch mode="multiple" optionFilterProp="children"
                  name={`followUp.${tipoPessoa}.revisao.idStatus`}
                  label="Selecionar status para exibir botão"
                  placeholder="Selecione os status"
                  onChange={(value) => setFieldValue(`followUp.${tipoPessoa}.revisao.idStatus`, value)}
                  disabled={tipoPessoa === 'pf' ? !values?.followUp?.pf?.revisao?.habilitar : !values?.followUp?.pj?.revisao?.habilitar}
                >
                  {pareceresInstituicao.map((option) => (
                    <Option key={option.descricao} value={option.descricao}>
                      {option.descricao}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-12">
                <Switch name={`followUp.${tipoPessoa}.redirect.habilitar`}
                  checked={tipoPessoa === 'pf' ? values?.followUp?.pf?.redirect?.habilitar : values?.followUp?.pj?.redirect?.habilitar}
                  label="Habilitar botão redirect"
                  onChange={(value) => setFieldValue(`followUp.${tipoPessoa}.redirect.habilitar`, value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-4">
                <Input name={`followUp.${tipoPessoa}.redirect.nome`}
                  label={`Inserir "nome" do botão`}
                  disabled={tipoPessoa === 'pf' ? !values?.followUp?.pf?.redirect?.habilitar : !values?.followUp?.pj?.redirect?.habilitar}
                />
              </div>
              <div className="col-lg-4">
                <Select showSearch mode="multiple" optionFilterProp="children"
                  name={`followUp.${tipoPessoa}.redirect.idStatus`}
                  label="Selecionar status para exibir botão"
                  placeholder="Selecione os status"
                  onChange={(value) => setFieldValue(`followUp.${tipoPessoa}.redirect.idStatus`, value)}
                  disabled={tipoPessoa === 'pf' ? !values?.followUp?.pf?.redirect?.habilitar : !values?.followUp?.pj?.redirect?.habilitar}
                >
                  {pareceresInstituicao.map((option) => (
                    <Option key={option.descricao} value={option.descricao}>
                      {option.descricao}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="col-lg-4">
                <Input name={`followUp.${tipoPessoa}.redirect.urlRota`} label="Inserir URL da rota"
                  disabled={tipoPessoa === 'pf' ? !values?.followUp?.pf?.redirect?.habilitar : !values?.followUp?.pj?.redirect?.habilitar}
                  onBlur={({ target: { value } }) => {
                    setFieldValue(`followUp.${tipoPessoa}.redirect.urlRota`, value ? handleURL(value) : '')
                  }} />
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-4">
                <Switch name={`followUp.${tipoPessoa}.analisarProposta`} label="Habilitar Analisar Proposta"
                  checked={tipoPessoa === 'pf' ? values?.followUp?.pf?.analisarProposta : values?.followUp?.pj?.analisarProposta}
                  onChange={(value) => {
                    setFieldValue(`followUp.${tipoPessoa}.analisarProposta`, value)
                    if (value) setFieldValue(`followUp.${tipoPessoa}.exibirResultado`, !value)
                  }} />
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-4">
                <Switch name={`followUp.${tipoPessoa}.exibirResultado`} label="Habilitar Exibir Resultado"
                  checked={tipoPessoa === 'pf' ? values?.followUp?.pf?.exibirResultado : values?.followUp?.pj?.exibirResultado}
                  onChange={(value) => {
                    setFieldValue(`followUp.${tipoPessoa}.exibirResultado`, value)
                    if (value) setFieldValue(`followUp.${tipoPessoa}.analisarProposta`, !value)
                  }} />
              </div>
            </div>
          </div>
        </div>)}
    </>
  );
}
