import { FC } from 'react';
import { useField } from 'formik';

import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser'
interface Props extends InputProps {
  name: string;
  label: string;
  maskChar?: string | null;
  obrigatorioAlt: any;
}

const InputMask: FC<Props> = ({ obrigatorioAlt, label, ...rest }) => {
  const history = useHistory();
  const params = useParams<{ codProposta: string }>();
  const [field, meta] = useField(rest as any);
  const ableToEdit = (['cnpj', 'cpf'].includes(rest.name) &&
    (params.codProposta || history.location.pathname.includes('dlink'))) ? true : false;

  return (
    <div className="input-wrapper">
      {label.includes('required-field') ? parse(label) : <label htmlFor={rest.name}>{parse(label)}</label>}
      <ReactInputMask id={rest.name} {...field} {...rest} alt={`${obrigatorioAlt}`}
        disabled={ableToEdit} readOnly={ableToEdit} />
      {meta.touched && meta.error ? (
        <span className="error">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default InputMask;
