import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.navy};
  width: 100vw;
  height: 100vh;
  padding: 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    img {
      max-width: 400px;
    }
  }

  .wrapper-card-login {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .card-login {
    background: #fcfcfc;
    border-radius: 5px;
    padding: 20px 10px;
    width: 374px;

    h3 {
      font-size: 22px;
      color: #201a1a;
    }

    .recaptcha {
      margin: 0 auto;
      width: 300px;
    }
  }
  .login-error {
    width: 345px;
    color: #dc3546;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .redefine-pass {
    text-align: center;
    font-weight: 400;
    font-size: 14px;

    a {
      color: ${(props) => props.theme.colors.navy};
      font-weight: 600;
    }
  }
`;
