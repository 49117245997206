import GlobalProvider from './contexts/GlobalContext';
import GlobalStyle from '@styles/global';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Routes from './routes';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <GlobalStyle />
        </GlobalProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
