import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import Login from '../pages/Login';
import Cadastrar from '../pages/Cadastrar';
import Pagamentos from '../pages/Pagamentos';
import Configuracao from '../pages/Configuracao';
import FollowUP from '../pages/FollowUP';
//import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />

    <Route exact path="/">
      <Redirect to="/login" />
    </Route>

    <PublicRoute exact path="/expired" component={Cadastrar} />
    <PublicRoute exact path="/cadastrar/:codProposta?" component={Cadastrar} />
    <PublicRoute exact path="/configuracao" component={Configuracao} />
    <PublicRoute exact path="/follow-up" component={FollowUP} />

    <Route path="/:subdominio" component={SubRoutes} />
  </Switch>
);

const SubRoutes = () => {
  const { subdominio } = useParams<{ subdominio: string }>();

  return (
    <Switch>
      <Route exact path={`/:subdominio`} component={Login} />

      <PublicRoute path={`/${subdominio}/cadastrar/:codProposta?`} component={Cadastrar} />
      <PublicRoute path={`/${subdominio}/pagamentos`} component={Pagamentos} />
      <PublicRoute path={`/${subdominio}/frame`} component={Cadastrar} />
      <PublicRoute path={`/${subdominio}/site`} component={Cadastrar} />
      <PublicRoute path={`/${subdominio}/dlink`} component={Cadastrar} />
      <PublicRoute path={`/${subdominio}/dlinkpj-preview`} component={Cadastrar} />
      <PublicRoute path={`/${subdominio}/dlinkpf-preview`} component={Cadastrar} />
      <PublicRoute path={`/${subdominio}/configuracao`} component={Configuracao} />
      <PublicRoute path={`/${subdominio}/follow-up`} component={FollowUP} />
    </Switch>
  );
};

export default Routes;
