// @ts-nocheck
import { Button } from 'antd';
import Card from '@components/Card';
import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { Input, SectionTitle, Switch, Select, Option } from '@components/Form';

type Props = {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  corTextoMenu: string;
  logo: string;
};

export default function Preview({
  primaryColor,
  secondaryColor,
  backgroundColor,
  corTextoMenu,
  logo,
}: Props) {

  const { values, setFieldValue } = useFormikContext<ConfigTypes>();
  const genericLogo =
    'https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo_TV_2015.png';

  return (
    <>
      {values.interface.tipo === 'site' &&
        <nav className=" navbar navbar-expand-lg"
          style={{
            width: '100%', paddingLeft: '180px', backgroundColor: secondaryColor,
            borderTop: '1px solid #E7E9EC', borderLeft: '1px solid #E7E9EC',
            borderRight: '1px solid #E7E9EC'
          }}>
          <img src={logo || genericLogo} style={{ width: '50px' }} />
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              {[1, 2, 3].map((i: any) =>
                <a key={i} className="nav-item nav-link"
                  href={values.interface?.[`link${i}URL`]} target="_blank"
                  style={{ color: corTextoMenu || primaryColor || '#8B2622' }}>
                  {values.interface?.[`link${i}Nome`]}
                </a>)}
            </div>
          </div>
        </nav>}

      <div className="preview" style={{ justifyContent: 'center' }}>
        {values.interface.tipo === 'area_logada' &&
          <div className="sidebar" style={{ background: secondaryColor || '#800000' }}>
            <div className="logo">
              <img src={logo || genericLogo} alt="logo" style={{ width: '100%' }} />
            </div>
            <div className="menu">
              {[1, 2, 3].map((i) => <i key={i} className="fa fa-fw fa-md fa-bars" style={{ color: corTextoMenu || primaryColor || '#8B2622' }} />)}
            </div>
          </div>}

        <div className="content" style={{ background: backgroundColor || '#eee', marginTop: '0px' }}>
          <Card>
            <SectionTitle title="Título" />
            <div className="form-row">
              <div className="col-lg-6">
                <Input name="test1" label="Campo 1" placeholder="Campo 1" />
              </div>
              <div className="col-lg-6">
                <Input name="test2" label="Campo 2" placeholder="Campo 2" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-12 d-flex justify-content-end">
                <Button type="primary" htmlType="button" style={{ background: primaryColor, border: primaryColor }}>
                  Botão
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}