import {
  camposAvalistaDadosComerciais,
  camposAvalistaEndereco,
  camposAvalistaIdentificacao,
  camposAvalistaProposta,
  /*mockCamposAvalistaReferenciasBancarias, 
  camposAvalistaReferenciasPessoais,*/
} from '@pages/Configuracao/camposAvalistaIntegracao';

export const BlocoAvalista = [
  /* {
    title: 'Proposta',
    campos: camposAvalistaProposta,
  }, */
  {
    title: 'Identificação',
    campos: camposAvalistaIdentificacao,
  },
  {
    title: 'Endereço',
    campos: camposAvalistaEndereco,
  },
  {
    title: 'Dados Comerciais',
    campos: camposAvalistaDadosComerciais,
  },
  /* {
    title: 'Referências Bancárias',
    campos: mockCamposAvalistaReferenciasBancarias,
  },
  {
    title: 'Referências Pessoais',
    campos: camposAvalistaReferenciasPessoais,
  }, */
];
