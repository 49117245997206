import { FC, useState, useEffect, useCallback, useContext } from 'react';
import { Button, Input as InputAntd } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';

import { Campo, ConfigTypes } from '@pages/Configuracao/ConfigTypes';
import { Input, Switch, Select, Option } from '@components/Form';
import { EtapaContext } from '@contexts/EtapaContext';

const EtapaAdicional: FC = () => {
  const { etapaPessoa, etapa, etapaIdx, tipoPessoa } = useContext(EtapaContext);

  const types = [
    {
      label: 'Texto',
      value: 'text',
    },
    {
      label: 'Monetário',
      value: 'currency',
    },
    {
      label: 'Data',
      value: 'date',
    },
    {
      label: 'Data (Calendário)',
      value: 'dateFromToday',
    },
    {
      label: 'Opções',
      value: 'opcoes',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'CNPJ',
      value: 'cnpj',
    },
  ];

  const { setFieldValue, values } = useFormikContext<ConfigTypes>();

  const [fields, setFields] = useState<Campo[]>([]);

  const valuesCamposAdicionais = {
    label: '',
    type: '',
    name: `adicional${fields?.length}`,
    habilitar: true,
    obrigatorio: true,
  };

  const handleAddCamposAdicionais = () => {
    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, [
      ...etapaPessoa[etapaIdx].campos,
      valuesCamposAdicionais,
    ]);
  };

  const handleRemoveCampoAdicional = async (field: Campo) => {

    ////console.log("etapaPessoa[etapaIdx].campos: ", etapaPessoa[etapaIdx].campos);
    ////console.log("field: ", field);

    const filteredArray = etapaPessoa[etapaIdx].campos.filter(
      (el) => el.name !== field.name,
    );

    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos`, filteredArray);
    loadFields();
  };

  const loadFields = useCallback(() => {
    ////console.log('passou aqui');
    const campos = etapaPessoa[etapaIdx]?.campos;

    setFields(campos);
  }, [values.etapas, etapa]);

  useEffect(() => { if (etapa) loadFields(); }, [loadFields, etapa]);

  return (
    <>
      <div className="row">
        <div className='col-lg-3 pr-0'>
          <Switch checked={etapaPessoa[etapaIdx]?.expressao ? true : false}
            label="Habilitar Abas" name={`habilitarAbas`} onChange={(value) => {
              setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.expressao`, value ? 'true|' : null)
            }} />
        </div>
        {etapaPessoa[etapaIdx]?.expressao?.includes('true') &&
          <div className='col-lg-6 pl-0'>
            <InputAntd value={etapaPessoa[etapaIdx]?.expressao?.split('|')[1]}
              placeholder='Nome das Abas' onChange={({ target: { value: v } }: any) =>
                setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.expressao`, `true|${v}`)} />
          </div>}
      </div>

      <br />

      {fields?.map((field, index) => (
        <div className="form-row" key={index}>
          <div className="col-lg-3">
            <Input
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.label`}
              label="Nome do campo"
            />
          </div>
          <div className="col-lg-4">
            <Select
              showSearch
              optionFilterProp="children"
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.type`}
              label="Tipo"
              placeholder="Selecione o tipo"
              onChange={(value) =>
                setFieldValue(
                  `etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.type`,
                  value,
                )
              }
            >
              {types.map((type, idx) => (
                <Option key={idx} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </div>

          {field.type === 'opcoes' && <>
            <div className="col-lg-3 mt-4">
              <Switch label="Multiselect"
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.tipoDado`}
                checked={etapaPessoa[etapaIdx]?.campos[index]?.tipoDado == 'multiselect' ? true : false}
                onChange={(value) =>
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.tipoDado`, value ? 'multiselect' : 'select')
                } />
            </div>
            <div className="col-lg-7">
              <Select
                name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.opcoes`}
                label="Insira as opções" mode="tags"
                placeholder="Insira as opções"
                onChange={(value) => {
                  setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.opcoes`, value);

                  if (value.length > 0) {
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.habilitar`, true);
                  } else {
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.habilitar`, false)
                    setFieldValue(`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.obrigatorio`, false)
                  }
                }}
              />
            </div>
          </>}

          <div className="col-lg-3">
            <label />
            <Switch
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.habilitar`}
              checked={etapaPessoa[etapaIdx]?.campos[index]?.habilitar}
              label="Habilitar"
              onChange={(value) =>
                setFieldValue(
                  `etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.habilitar`,
                  value,
                )
              }
              size="small"
            />

            <Switch
              name={`etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.obrigatorio`}
              checked={etapaPessoa[etapaIdx]?.campos[index]?.obrigatorio}
              label="Obrigatório"
              onChange={(value) =>
                setFieldValue(
                  `etapas.${tipoPessoa}.${etapaIdx}.campos.${index}.obrigatorio`,
                  value,
                )
              }
              size="small"
            />
          </div>
          <div className="col-lg-1">
            <Button
              type="dashed"
              className="btn-remove-adicional"
              onClick={() => handleRemoveCampoAdicional(field)}
              block
              icon={<MinusCircleOutlined />}
            />
          </div>
        </div>
      ))}

      <div className="form-row">
        <div className="col-lg-12">
          <Button
            type="dashed"
            onClick={handleAddCamposAdicionais}
            block
            icon={<PlusOutlined />}
          >
            Adicionar Campo
          </Button>
        </div>
      </div>
    </>
  );
};

export default EtapaAdicional;