import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@contexts/GlobalContext';
import { Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom';

import logoMin from '../../../assets/images/b2e-default.png';
import { LoadingOutlined } from '@ant-design/icons';

const Sidebar = () => {
  const { logo, configuracoes } = useContext(GlobalContext);
  const [linkColor, setLinkColor] = useState(null);
  const [sideColor, setSideColor] = useState(null);
  const [loadingSide, setLoadingSide] = useState(false);
  const { subdominio } = useParams<{ subdominio: string }>();

  useEffect(() => {
    if (configuracoes) {
      setLinkColor(configuracoes.interface.corTextoMenu || configuracoes.interface.corPrimaria)
      setSideColor(configuracoes.interface.corSecundaria)
    }

    setTimeout(() => { setLoadingSide(true) }, 6666)
  }, [configuracoes])

  return (
    <nav className="mx-2 navbar-default navbar-static-side" role="navigation"
      style={{
        borderRight: 'none',
        backgroundColor: sideColor || '#ffffff',
      }}>
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="profile-element">
              <img src={logo || logoMin} alt="B2e Group" className="logo" />
            </div>
            <div className="logo-element">
              <img src={logo || logoMin} alt="logo-min" width="100%" />
            </div>
          </li>
          <>
            {configuracoes?.acessoUser?.enviarCadastro &&
              (<Tooltip title="Cadastro" placement="right">
                <li>
                  <Link to={subdominio ? `/${subdominio}/cadastrar` : '/cadastrar'}>
                    <i className="fa fa-fw fa-lg fa-edit" style={{ color: linkColor || '#8B2622' }} />
                    <span className="nav-label" style={{ color: linkColor || '#8B2622' }}>Cadastro</span>
                  </Link>
                </li>
              </Tooltip>)}

            {(`${configuracoes?.followUp?.exibirMenu}`.includes('pf') || `${configuracoes?.followUp?.exibirMenu}`.includes('pj')) &&
              configuracoes?.acessoUser?.verFollowUp?.status &&
              (<Tooltip title="Follow UP" placement="right">
                <li>
                  <Link to={subdominio ? `/${subdominio}/follow-up` : '/follow-up'}>
                    <i className="fa fa-fw fa-lg fa-list" style={{ color: linkColor || '#8B2622' }} />
                    <span className="nav-label" style={{ color: linkColor || '#8B2622' }}>Follow UP</span>
                  </Link>
                </li>
              </Tooltip>)}

            {configuracoes?.acessoUser?.exibirConfiguracoes &&
              (<Tooltip title="Configuração" placement="right">
                <li>
                  <Link to={subdominio ? `/${subdominio}/configuracao` : '/configuracao'}>
                    <i className="fa fa-fw fa-lg fa-sliders" style={{ color: linkColor || '#8B2622' }} />
                    <span className="nav-label" style={{ color: linkColor || '#8B2622' }}>Configuração</span>
                  </Link>
                </li>
              </Tooltip>)}
          </>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;